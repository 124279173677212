import { RawDraftContentBlock } from 'draft-js'
/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react'
import Tooltip from 'react-tooltip-lite'
import styled from 'styled-components/macro'

import { ContextOutputTree } from '@/Flows/canvas/ContextPopup'
import { ResponseOutput } from '@/Flows/canvas/types'
import { convertAsaSingleString, convertToServerFormat3 } from '@/Flows/canvas/utils'
import { VideoLink } from '@/Videos/Videos'
import { AddIcon, CloseIcon, FlowCanvasContextIcons, InfoIcon, MinusIcon, QaIconWithCircle } from '@/common/Icons/Icons'
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons'
//ICONS IMPORT
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
//COMMON MODULE IMPORTS
import { Loader } from '@/common/components/Loader'
import { Modal } from '@/common/components/Modal'
import lazyGA from '@/common/google-analytics/tracking'
import logoPng from '@/common/images/logo.png'
import { Button as ButtonForModal } from '@/common/styled/Dialog.BotDetails.Dumb'
import {
    ButtonContainer,
    EmptyDiv,
    Header,
    HeaderLeft,
    Input,
    InputContainer,
    InputContent,
    Paragraph,
    PopupContainer,
    PopupScrollHeight,
    PopupWrapper,
    SmallButton,
    Wrapper
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { DraftInputWrapper } from '@/common/styled/DraftJs.Dumb'
import { HeaderRight } from '@/common/styled/Subscription.Xtra.Dumb'

import { getValuesBetweenBraces } from '@/Flows/_utils'
import { Button } from '../../Common/Effective'
// TYPE IMPORTS
import {
    AdaptiveServerFieldValueT,
    AdaptiveServerValueT,
    AdaptiveT,
    AdaptiveValueT,
    AutomationT,
    TextValueT
} from '../../Dialogue/kind'
import { DraftJST, ExpressionTypeE, converterToServer } from '../../Editor/Action'
//Rich Editor Imports
import Editor from '../../Editor/Editor'
import { createResponseDialogue, updateResponseDialogue } from '../../Mechanisms/action'
//CONTEXT IMPORTS
import { ContextVariableT, DialogueContext, VariableContext, VariableContextT } from '../Context'
import { findPathViaRes } from '../Variable'
import { ADAPTIVE_RESPONSE, ErrorState, ErrorT, VIEW } from '../kind'
import { logEvent } from '@/common/utils/api-utils'
import { useScrollHook } from '@/common/hooks/useScrollHook'

const OptionalInput = styled.div`
    width: 100%;
    float: left;

    .target.customTip {
        width: 9px;
        height: 9px;
        pointer-events: all;
        cursor: pointer;
        left: 3px;
        svg {
            width: 9px;
            height: 9px;
            pointer-events: all;
            cursor: pointer;
        }
    }
`
const InputGrid = styled.div`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: 180px 180px 1fr;
    grid-gap: 10px;

    .target.customTip {
        width: 9px;
        pointer-events: all;
        height: 9px;
        cursor: pointer;
        left: 3px;
        svg {
            width: 9px;
            pointer-events: all;
            height: 9px;
        }
    }
    .parent_node_no {
        width: 18px;
        height: 18px;
        background: #fff;
        margin: 0;
        position: relative;
        top: 16px;
        left: 2px;
        border-radius: 50%;
        span {
            width: 18px;
            height: 18px;
            border: 1px solid #999;
            border-radius: 50%;
            display: flex;
            align-itmes: center;
            justify-content: center;
            svg {
                width: 8px;
                height: 8px;
                color: #999;
                fill: #999;
            }
        }
        :hover {
            background: #ffe5d3;
            cursor: pointer;
            span {
                border: 1px solid #ffe5d3;
            }
            svg {
                color: #333;
                fill: #333;
            }
        }
    }

    .adaptiveCard_message_toolbar_link ${DraftInputWrapper} .message_toolbar_container span svg{
        left: 120px;
    }
`
const BgWrapper = styled.div`
    width: 100%;
    float: left;
    padding: 20px 20px 0px;
    background: #f4f4f4;
    margin-bottom: 10px;

    .plus_addmore {
        margin-top: -8px;
        margin-bottom: 20px;
    }
    .select_application {
        width: 100%;
        float: left;
        margin-bottom: 20px;
    }

    .target.customTip {
        width: 9px;
        pointer-events: all;
        height: 9px;
        cursor: pointer;
        left: 3px;
        svg {
            width: 9px;
            pointer-events: all;
            height: 9px;
        }
    }
    .opations_label h4 {
        margin-bottom: 15px;
    }
`
const AdaptiveTips = () => (
    <>
        <p>
            Use{' '}
            <a
                className="sidepanel_href_color"
                target="_blank"
                href="https://help.workativ.com/knowledgebase/adaptive-cards/"
            >
                Adaptive card
            </a>{' '}
            when you want the bot to display information in a card, to the user.
            <br />
            <br />
            <p>
                You can choose what data or information needs to be shown to the user in the card, and also have buttons
                for users to click or make selection.
            </p>
        </p>
    </>
)

const AdaptiveResponse = () => {
    const { workspaceName, assistantID, setPageState, PageState, fetchCallback, dialogueName } = useContext(
        DialogueContext
    )

    let { data } = PageState as ADAPTIVE_RESPONSE

    const [error, setError] = useState<ErrorT>(ErrorState)

    const [loading, setLoading] = useState<boolean>(false)

    const [cardType, setcardType] = useState(data.collection.path ? 'carousal' : 'single')

    const [contextPopupValues, setContextPopupValues] = useState<any>({
        show: data.collection.path && data.collectionData ? true : false,
        collectionPicker: {
            isCollectionPicker: data.collection.path && data.collectionData ? true : false,
            selectedCollection:
                data.collection.path && data.collectionData
                    ? data.collectionData.values
                    : {
                          name: '',
                          value: {
                              $id: '',
                              type: 'collection',
                              item: {
                                  type: 'object',
                                  properties: {}
                              },
                              title: '',
                              properties: {}
                          }
                      },
            app: ResponseOutput
        },
        app: data.collection.path && data.collectionData ? data.collectionData.app : ResponseOutput,
        colletionPath: data.collection.path ? data.collection.path : []
    })

    const [scrollableDivRef, scrollTo] = useScrollHook()
    
    const handleSave = async ({
        buttons,
        fields,
        basicInfo
    }: {
        buttons: AdaptiveValueT[]
        fields: AdaptiveValueT[]
        basicInfo: { title: string; description: DraftJST; image: string }
    }) => {
        try {
            //show Loading
            setLoading(true)

            if (data.id == '') {
                let reqData = {
                    ...data,
                    action_buttons: buttons
                        .filter(
                            b =>
                                b.label.value.length > 0 &&
                                b.value.blocks.length > 0 &&
                                b.value.blocks[0].text.length > 0
                        )

                        .map(button => ({
                            title: button.label,
                            value: converterToServer(button.value)
                        })),
                    fields: fields.map(button => {
                        return {
                            title: button.label,
                            value: converterToServer(button.value)
                        } as AdaptiveServerFieldValueT
                    }),
                    title: { type: 'text', value: basicInfo.title },
                    description: converterToServer(basicInfo.description),
                    image: basicInfo.image.length > 0 ? { type: 'text', value: basicInfo.image } : null
                } as AdaptiveServerValueT

                reqData =
                    cardType == 'carousal'
                        ? {
                              ...reqData,
                              collection: { path: contextPopupValues.colletionPath }
                          }
                        : {
                              ...reqData,
                              collection: null
                          }
                await createResponseDialogue(workspaceName, assistantID, data.parent, reqData, dialogueName)
                logEvent(workspaceName, {
                    event:"EVENT::DIALOG::ADD_ADAPTIVE_CARD", event_meta:{
                    name: dialogueName,
                    description: ""
                }})
                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Adaptive Card Node Created'
                })
            } else {
                let reqData = {
                    ...data,
                    action_buttons: buttons
                        .filter(
                            b =>
                                b.label.value.length > 0 &&
                                b.value.blocks.length > 0 &&
                                b.value.blocks[0].text.length > 0
                        )
                        .map(button => ({
                            title: button.label,
                            value: converterToServer(button.value)
                        })),
                    fields: fields.map(button => {
                        return {
                            title: button.label,
                            value: converterToServer(button.value)
                        } as AdaptiveServerFieldValueT
                    }),
                    title: { type: 'text', value: basicInfo.title },
                    description: converterToServer(basicInfo.description),
                    image: basicInfo.image.length > 0 ? { type: 'text', value: basicInfo.image } : null
                } as AdaptiveServerValueT

                reqData =
                    cardType == 'carousal'
                        ? {
                              ...reqData,
                              collection: { path: contextPopupValues.colletionPath }
                          }
                        : {
                              ...reqData,
                              collection: null
                          }

                await updateResponseDialogue(workspaceName, assistantID, data.parent, data.id, reqData, dialogueName)
            }

            fetchCallback(() => setLoading(false))
        } catch (err) {
            setError({
                error: true,
                info: `Server error!<br />Please try again after sometime.`
            })
            setLoading(false)
        }
    }

    return (
        <Wrapper>
            <PopupWrapper>
                <PopupContainer>
                    <Header>
                        <HeaderLeft minWidth={300}>
                            Adaptive card
                            <Tooltip
                                className="target customTip"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content={<AdaptiveTips />}
                                distance={5}
                            >
                                <InfoIcon />
                            </Tooltip>
                            <a href="https://help.workativ.com/knowledgebase/adaptive-cards" target="_blank">
                                <Tooltip
                                    className="target customTip"
                                    zIndex={10000}
                                    arrowSize={8}
                                    tagName="span"
                                    content="View help document"
                                    distance={5}
                                >
                                    <QaIconWithCircle />
                                </Tooltip>
                            </a>
                        </HeaderLeft>
                        <HeaderRight>
                            <VideoLink id={'ADD_AN_ADAPTIVE_CARD'} text={'How to'} />
                        </HeaderRight>
                    </Header>
                    <Paragraph>Create your own card template to display in chat.</Paragraph>
                    <PopupScrollHeight ref={scrollableDivRef}>
                        <EmptyDiv>
                            <AdaptiveResponseUI
                                data={data}
                                handleSave={handleSave}
                                loading={loading}
                                setLoading={setLoading}
                                error={error}
                                setError={setError}
                                showSaveButtons={true}
                                cardType={cardType}
                                setcardType={setcardType}
                                contextPopupValues={contextPopupValues}
                                setContextPopupValues={setContextPopupValues}
                                parentScrollCallback={((shouldScroll:boolean) => scrollTo('bottom', shouldScroll)) as (arg:boolean) => void}
                            />
                        </EmptyDiv>
                    </PopupScrollHeight>
                </PopupContainer>
            </PopupWrapper>
        </Wrapper>
    )
}

export const AdaptiveResponseUI = (props: any) => {
    const { workspaceName, assistantID, setPageState, PageState, fetchCallback, dialogueName } = useContext(
        DialogueContext
    )

    const variableContext: VariableContextT = React.useContext(VariableContext)

    const {
        handleSave,
        loading,
        setLoading,
        error,
        setError,
        showSaveButtons,
        cardType,
        setcardType,
        contextPopupValues,
        setContextPopupValues
    } = props

    const data = props.data as AdaptiveT

    const [removedEle, setRemovedEle] = useState<boolean>(false)
    const [showCollectionUpdatePopup, setShowCollectionUpdatePopup] = useState<boolean>(false)

    const [currentFocusedElement, setCurrentFocusedElement] = useState('')

    const [basicInfo, setBasicInfo] = useState<{ title: string; description: DraftJST; image: string }>({
        title: data.title.value,
        description: data.description,
        image: data.image ? ((typeof data.image == 'object' ? data.image.value : data.image) as any) : ''
    })

    const [fields, setFields] = useState<AdaptiveValueT[]>(data.fields)

    const [buttons, setButtons] = useState<AdaptiveValueT[]>(
        data.action_buttons.length == 0
            ? [
                  {
                      label: {
                          type: 'text',
                          value: ''
                      },
                      value: { blocks: [], entityMap: {} }
                  }
              ]
            : data.action_buttons
    )

    useEffect(() => {
        // to use in FAQ
        if (!showSaveButtons) {
            handleSave({
                buttons,
                fields,
                basicInfo: {
                    ...basicInfo,
                    title: {
                        type: ExpressionTypeE.TEXT,
                        value: basicInfo.title
                    }
                }
            })
        }
    }, [buttons, fields, basicInfo])
    

    const lableHandleChange = (value: TextValueT, index: number, name: string) =>
        name == 'fields'
            ? setFields(fields =>
                  fields.map((field, count) =>
                      count == index
                          ? {
                                ...field,
                                label: value
                            }
                          : field
                  )
              )
            : setButtons(buttons =>
                  buttons.map((button, count) =>
                      count == index
                          ? {
                                ...button,
                                label: value
                            }
                          : button
                  )
              )

    const valueHandleChange = (value: DraftJST, index: number, name: string) =>
        name == 'fields'
            ? setFields(fields =>
                  fields.map((field, count) =>
                      count == index
                          ? {
                                ...field,
                                value: value
                            }
                          : field
                  )
              )
            : setButtons(buttons =>
                  buttons.map((button, count) =>
                      count == index
                          ? {
                                ...button,
                                value: value
                            }
                          : button
                  )
              )

    const countSet = (count: number) => (count < 10 ? '0' + count.toString() : count)
    var IsValidURL = (url: string) =>
        url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)

    // to update editor state while removing element
    const removedElement = () => {
        setRemovedEle(false)
    }
    const { workspaceDescendents, assistant } = useContext(DialogueContext)
    const { assignVariable, forAutomationVariables, popupExposed, setForAutomationVariables } = useContext(
        VariableContext
    )
    const assistantName = assistant.name

    function updateInputValuesWithContext(values: any) {
        const value = values.value
        if (forAutomationVariables == 'input') {
            const flowDialogNode = workspaceDescendents.reduce((accum: any, val) => {
                const result =
                    val.type === 'if_node' ? val.responses.filter(e => e.id == contextPopupValues.app.appId)[0] : null
                if (result) {
                    return result
                }
                return accum
            }, null)

            if (
                flowDialogNode &&
                Object.keys(contextPopupValues.app).length &&
                contextPopupValues.app.id != undefined
            ) {
                const automationNode: AutomationT = flowDialogNode as AutomationT

                const actionOutputPath = value.$id.split('/').slice(2)

                let variableValue = {
                    type: 'context',
                    variable: actionOutputPath.slice(actionOutputPath.length - 1).pop(),
                    dummypath: [
                        assistantName,
                        ...findPathViaRes(workspaceDescendents, automationNode.id, []),
                        automationNode.automation_name
                    ].concat(actionOutputPath),
                    path: ['$'].concat(actionOutputPath),
                    icon: logoPng,
                    dataType: value.type
                } as any

                assignVariable(variableValue)
            }
        }
    }

    const selectedCollection = contextPopupValues && contextPopupValues.collectionPicker.selectedCollection

    const paths =
        contextPopupValues &&
        contextPopupValues.collectionPicker.selectedCollection.value &&
        contextPopupValues.collectionPicker.selectedCollection.value.$id.length > 0 &&
        contextPopupValues.collectionPicker.selectedCollection.value.$id
            .split('/')
            .filter((x: any) => x.length !== 0 && x !== 'properties')
    const context: VariableContextT = React.useContext(VariableContext)
    const collectionRef: any = React.useRef(null)

    const setCollectionValues = (context: VariableContextT, from?: boolean) => {
        const variable = context.variable as ContextVariableT

        if (from) {
            const updatedFields = fields.map(f => {
                const updatedBlocks = f.value.blocks.reduce((accum: any, val) => {
                    const converted = convertToServerFormat3({
                        blocks: [val],
                        entityMap: f.value.entityMap
                    } as any)
                    const valueAlwaysString = converted.text
                    const splittedValue = getValuesBetweenBraces(valueAlwaysString)
                    const acc = splittedValue.reduce((acc: string, curr: string) => {
                        return acc.replace('${' + curr + '}', '')
                    }, valueAlwaysString)
                    return [
                        ...accum,
                        {
                            ...val,
                            text:  acc ? acc : "",
                            entityRanges: val.entityRanges.filter(e => converted.removeIndex.indexOf(e.key) == -1)
                        }
                    ]
                }, [])

                return { ...f, value: { blocks: updatedBlocks, entityMap: f.value.entityMap } }
            })

            const updatedButtons = buttons.map(f => {
                const updatedBlocks = f.value.blocks.reduce((accum: any, val) => {
                    const converted = convertToServerFormat3({
                        blocks: [val],
                        entityMap: f.value.entityMap
                    } as any)
                    const valueAlwaysString = converted.text
                    const splittedValue = getValuesBetweenBraces(valueAlwaysString)
                    const acc = splittedValue.reduce((acc: string, curr: string) => {
                        return acc.replace('${' + curr + '}', '')
                    }, valueAlwaysString)
                    return [
                        ...accum,
                        {
                            ...val,
                            text: acc ? acc : "",
                            entityRanges: val.entityRanges.filter(e => converted.removeIndex.indexOf(e.key) == -1)
                        }
                    ]
                }, [])

                return { ...f, value: { blocks: updatedBlocks, entityMap: f.value.entityMap } }
            })

            setFields(updatedFields)

            setButtons(updatedButtons)
        }

        setContextPopupValues({
            ...contextPopupValues,
            collectionPicker: {
                ...contextPopupValues.collectionPicker,
                selectedCollection: variable.collection && variable.collection.values
            },
            app: variable.collection && (variable.collection as any).app,
            colletionPath: variable.path
        })
        context.assignVariable(null)
        context.setForAutomationVariables('variable')
        context.assignPopupExposed(false)
        setShowCollectionUpdatePopup(false)
    }

    React.useEffect(() => {
        if (
            context &&
            context.variable !== null &&
            context.variable.type === 'context' &&
            forAutomationVariables == 'collection'
        ) {
            if (contextPopupValues.colletionPath.length > 0) {
                collectionRef.current = context
                setShowCollectionUpdatePopup(true)
            } else {
                setCollectionValues(context)
            }
        }

        // for selecting variable from collection
        popupExposed == false &&
            forAutomationVariables == 'input' &&
            setTimeout(() => setForAutomationVariables('variable'))
    }, [context])

    function showCollectionUpdateModal() {
        return (
            <Modal showPopupLoader={false}>
                <React.Fragment>
                    <h2>Update Collection?</h2>
                    <p>Are you sure you want to Update the collection?</p>{' '}
                </React.Fragment>

                <ButtonContainer style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <ButtonForModal
                        primary
                        type="button"
                        onClick={() => {
                            collectionRef.current = null
                            setShowCollectionUpdatePopup(false)
                        }}
                        marginbottom
                    >
                        <label>No</label>
                    </ButtonForModal>
                    <ButtonForModal
                        type="button"
                        onClick={() => {
                            setCollectionValues(collectionRef.current, true)
                        }}
                        marginbottom
                    >
                        <label>Yes</label>
                    </ButtonForModal>
                </ButtonContainer>
            </Modal>
        )
    }

    return (
        <>
            <ErrorBoundary
                error={error}
                render={(err: any, info: any) => {
                    return err && <Error.Delete onClick={() => setPageState(VIEW)} info={info} />
                }}
            >
                {loading ? (
                    <div className="popup_loader">
                        <Loader.PopupLoader show={loading} />
                    </div>
                ) : (
                    <React.Fragment>
                        <BgWrapper className={'adaptive_card'}>
                            {showCollectionUpdatePopup && showCollectionUpdateModal()}
                            {showSaveButtons && (
                                <div className="col-md-12 entity_radio adaptive_card_radio">
                                    <div className="radio">
                                        <label>
                                            <input
                                                type="radio"
                                                className="radio-warning"
                                                checked={cardType == 'single'}
                                                onClick={() => {
                                                    setcardType('single')
                                                    setContextPopupValues({
                                                        show: false,
                                                        collectionPicker: {
                                                            isCollectionPicker: false,
                                                            selectedCollection: {
                                                                name: '',
                                                                value: {
                                                                    $id: '',
                                                                    type: 'collection',
                                                                    item: {
                                                                        type: 'object',
                                                                        properties: {}
                                                                    },
                                                                    title: '',
                                                                    properties: {}
                                                                }
                                                            },
                                                            app: ResponseOutput
                                                        },
                                                        app: ResponseOutput,
                                                        colletionPath: []
                                                    })
                                                    context.setForAutomationVariables('variable')
                                                }}
                                            />
                                        </label>

                                        <span>Single Card</span>
                                    </div>

                                    <div className="radio">
                                        <label>
                                            <input
                                                type="radio"
                                                checked={cardType == 'carousal'}
                                                onClick={() => {
                                                    setcardType('carousal')
                                                }}
                                            />
                                        </label>

                                        <span>Carousel</span>
                                    </div>
                                </div>
                            )}

                            {cardType == 'carousal' && (
                                <div className="automation_global_form">
                                    <div
                                        className="select_work_form"
                                        style={
                                            selectedCollection && selectedCollection.name.length > 0
                                                ? {}
                                                : { marginBottom: 20 }
                                        }
                                    >
                                        <div
                                            className={
                                                contextPopupValues.collectionPicker.isCollectionPicker &&
                                                contextPopupValues.show
                                                    ? 'form_req_step_two_global_form _select_a_variable input_selected'
                                                    : 'form_req_step_two_global_form _select_a_variable'
                                            }
                                            onClick={() => {
                                                setContextPopupValues({
                                                    ...contextPopupValues,
                                                    show: true,
                                                    collectionPicker: {
                                                        ...contextPopupValues.collectionPicker,
                                                        isCollectionPicker: true
                                                    }
                                                })
                                                context.assignPopupExposed(true)
                                                setTimeout(() => context.setForAutomationVariables('collection'))
                                            }}
                                        >
                                            <div className="automation_global_form_edit adaptive_card_select_collection">
                                                {selectedCollection && selectedCollection.name.length > 0 ? (
                                                    <React.Fragment>
                                                        <span className="conetxt_variable_input">
                                                            <div className="context_popup_buton_content_span">
                                                                <div className="button_auto">
                                                                    <img src={contextPopupValues.app.icon} />
                                                                    {
                                                                        contextPopupValues.collectionPicker
                                                                            .selectedCollection.value.title
                                                                    }
                                                                </div>

                                                                <div className="button_auto_hover">
                                                                    {[
                                                                        contextPopupValues.app.appName,
                                                                        contextPopupValues.app.action
                                                                    ]
                                                                        .concat(paths)
                                                                        .map((value, j) => (
                                                                            <React.Fragment key={j}>
                                                                                {j !== 0 && (
                                                                                    <div>
                                                                                        <FlowCanvasContextIcons.NextIcon />
                                                                                    </div>
                                                                                )}
                                                                                <div>{value}</div>
                                                                            </React.Fragment>
                                                                        ))}
                                                                </div>
                                                            </div>
                                                        </span>
                                                        <div
                                                            className={
                                                                contextPopupValues.collectionPicker.isCollectionPicker
                                                                    ? 'button_auto_input_click'
                                                                    : 'button_auto_input_click'
                                                            }
                                                        >
                                                            Select a collection
                                                        </div>
                                                    </React.Fragment>
                                                ) : (
                                                    <InputContainer className="mb-0">
                                                        <Input
                                                            type="text"
                                                            className={
                                                                currentFocusedElement == 'collection'
                                                                    ? 'input_selected_dialog'
                                                                    : ''
                                                            }
                                                            value={''}
                                                            onFocus={() => {
                                                                setCurrentFocusedElement('collection')
                                                            }}
                                                            onBlur={() => {
                                                                setCurrentFocusedElement('')
                                                            }}
                                                        />
                                                        <InputContent>Select a collection </InputContent>
                                                    </InputContainer>
                                                )}
                                            </div>
                                        </div>
                                        {selectedCollection && selectedCollection.value.$id.length > 0 && (
                                            <>
                                                <div
                                                    className={
                                                        'form_req_step_two_global_form adptive_carousal_card_title _select_a_variable'
                                                    }
                                                    style={{ marginTop: 20 }}
                                                >
                                                    <div className="collection_picker_in_input_page">
                                                        <ContextOutputTree
                                                            output={
                                                                contextPopupValues.collectionPicker.selectedCollection
                                                            }
                                                            showOnlyCollection={
                                                                context.forAutomationVariables == 'input'
                                                                    ? {
                                                                          showOnlyCollection: false,
                                                                          contextPopupValues: contextPopupValues
                                                                      }
                                                                    : {
                                                                          showOnlyCollection:
                                                                              contextPopupValues.collectionPicker
                                                                                  .isCollectionPicker &&
                                                                              contextPopupValues.show,
                                                                          contextPopupValues: contextPopupValues
                                                                      }
                                                            }
                                                            onClick={updateInputValuesWithContext}
                                                            usingForInputs={true}
                                                            forDialog
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            contextPopupValues.collectionPicker.isCollectionPicker
                                                                ? 'button_auto_input_click'
                                                                : 'button_auto_input'
                                                        }
                                                    >
                                                        Collection data
                                                    </div>
                                                </div>
                                            </>
                                        )}{' '}
                                    </div>
                                </div>
                            )}
                            <div className="select_application">
                                <div className="select_work_form select_adaptive">
                                    <InputContainer className="mb-0">
                                        <Input
                                            type="text"
                                            className={currentFocusedElement == 'title' ? 'input_selected_dialog' : ''}
                                            value={basicInfo.title}
                                            onFocus={() => {
                                                setCurrentFocusedElement('title')
                                            }}
                                            onBlur={() => {
                                                setCurrentFocusedElement('')
                                            }}
                                            onChange={(event: any) =>
                                                setBasicInfo({
                                                    ...basicInfo,
                                                    title: event.target.value
                                                })
                                            }
                                        />
                                        <InputContent>
                                            Card title{' '}
                                            {/* <Tooltip
                                                                className="target customTip"
                                                                zIndex={10000}
                                                                arrowSize={8}
                                                                tagName="span"
                                                                content={'ji'}
                                                                distance={5}
                                                            >
                                                                <InfoIcon />
                                                            </Tooltip> */}
                                        </InputContent>
                                    </InputContainer>
                                </div>
                            </div>
                            <div className="select_application">
                                {/* <div className="select_work_form select_adaptive"> */}
                                <div className="select_adaptive">
                                    <DraftInputWrapper>
                                        <Editor
                                            focusContent={'Card description'}
                                            DraftMap={basicInfo.description}
                                            setDraftMap={(draft: DraftJST) =>
                                                setBasicInfo({ ...basicInfo, description: draft })
                                            }
                                            focus={
                                                convertAsaSingleString(
                                                    basicInfo.description.blocks as RawDraftContentBlock[]
                                                ).replace(/\s/g, '').length
                                                    ? 'focus_input'
                                                    : ''
                                            }
                                        />
                                    </DraftInputWrapper>
                                </div>
                            </div>
                            <div className="select_application">
                                <div className="select_work_form select_adaptive">
                                    {/* <h6 className="options_popup_h6" style={{ display: 'inline-flex' }}>
                                                        Image Attachment URL:{' '}
                                                        <span style={{ color: '#ff7900' }}>(optional)</span>
                                                        {basicInfo.image && IsValidURL(basicInfo.image) === null && (
                                                            <span className="info_icon">
                                                                <WarningAlertIcon />
                                                                <span className="side_menu_hvr">
                                                                    Invalid Image Attachment URL.
                                                                </span>
                                                            </span>
                                                        )}
                                                    </h6> */}

                                    <OptionalInput>
                                        <InputContainer className="mb-0">
                                            <Input
                                                type="text"
                                                className={
                                                    currentFocusedElement == 'iamge' ? 'input_selected_dialog' : ''
                                                }
                                                value={basicInfo.image}
                                                onFocus={() => {
                                                    setCurrentFocusedElement('iamge')
                                                }}
                                                onBlur={() => {
                                                    setCurrentFocusedElement('')
                                                }}
                                                onChange={(event: any) =>
                                                    setBasicInfo({
                                                        ...basicInfo,
                                                        image: event.target.value
                                                    })
                                                }
                                            />
                                            <InputContent>
                                                Logo URL (Optional){' '}
                                                <Tooltip
                                                    className="target customTip"
                                                    zIndex={10000}
                                                    arrowSize={8}
                                                    tagName="span"
                                                    content={'Display logo or image in the card'}
                                                    distance={5}
                                                >
                                                    <InfoIcon />
                                                </Tooltip>
                                            </InputContent>
                                            {basicInfo.image && IsValidURL(basicInfo.image) === null && (
                                                <span className="info_icon info_icon_Adaptive">
                                                    <Tooltip
                                                        className="target customTip"
                                                        zIndex={10000}
                                                        arrowSize={8}
                                                        tagName="span"
                                                        content={'Invalid Image Attachment URL.'}
                                                        distance={5}
                                                    >
                                                        <WarningAlertIcon />
                                                    </Tooltip>
                                                </span>
                                            )}
                                        </InputContainer>
                                    </OptionalInput>
                                </div>
                            </div>
                            <div className="opations_label">
                                <Header style={{ fontSize: 14 }}>Add fields to the card: </Header>
                            </div>
                            {fields.map((field, index) => {
                                return (
                                    <div className="opations_label1" key={index}>
                                        <InputGrid>
                                            <InputContainer>
                                                <Input
                                                    type="text"
                                                    value={field.label.value}
                                                    onChange={(event: any) => {
                                                        lableHandleChange(
                                                            {
                                                                type: 'text',
                                                                value: event.target.value
                                                            },
                                                            index,
                                                            'fields'
                                                        )
                                                        // console.log("DOING :::", index === fields.length -1)
                                                        // props.parentScrollCallback && props.parentScrollCallback(index === fields.length -1)
                                                    }}
                                                />

                                                <InputContent>
                                                    Field names{' '}
                                                    {/* <Tooltip
                                                                        className="target customTip"
                                                                        zIndex={10000}
                                                                        arrowSize={8}
                                                                        tagName="span"
                                                                        content={'ji'}
                                                                        distance={5}
                                                                    >
                                                                        <InfoIcon />
                                                                    </Tooltip>{' '} */}
                                                </InputContent>
                                            </InputContainer>
                                            {/* <span>{countSet(index + 1)}</span> */}

                                            {/* <div className="options_value adaptive_value">
                                            <div className="if_dropdown">
                                                <Editor
                                                    DraftMap={field.value}
                                                    setDraftMap={(draft: DraftJST) => {
                                                        setRemovedEle(false)
                                                        valueHandleChange(draft, index, 'fields')
                                                    }}
                                                    removedElement={removedEle ? removedElement : undefined}
                                                />

                                                {fields.length > 1 ? (
                                                    <div
                                                        className="parent_node_no"
                                                        onClick={() => {
                                                            const newFields = fields
                                                                .map((field, count) => {
                                                                    return count != index ? field : null
                                                                })
                                                                .filter(Boolean) as AdaptiveValueT[]

                                                            setFields(newFields)
                                                            setRemovedEle(true)
                                                        }}
                                                    >
                                                        <span className="parent_svg">
                                                            <MinusIcon />
                                                        </span>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div> */}
                                        <span className="adaptiveCard_message_toolbar_link">
                                            <DraftInputWrapper>
                                                <Editor
                                                    focusTooltip
                                                    focusTooltipContent={
                                                        'Click to select a variable from variable picker'
                                                    }
                                                    focusContent={'Value'}
                                                    DraftMap={field.value}
                                                    setDraftMap={(draft: DraftJST) => {
                                                        setRemovedEle(false)
                                                        valueHandleChange(draft, index, 'fields')
                                                    }}
                                                    automation
                                                    removedElement={removedEle ? removedElement : undefined}
                                                    updated={showCollectionUpdatePopup}
                                                    focus={
                                                        convertAsaSingleString(
                                                            field.value.blocks as RawDraftContentBlock[]
                                                        ).replace(/\s/g, '').length
                                                            ? 'focus_input'
                                                            : ''
                                                    }
                                                    // onFocus={() => {
                                                    //     props.parentScrollCallback && props.parentScrollCallback(true)
                                                    // }}
                                                />
                                            </DraftInputWrapper>
                                            </span>

                                            {fields.length > 1 ? (
                                                <div
                                                    className="parent_node_no"
                                                    onClick={() => {
                                                        const newFields = fields
                                                            .map((field, count) => {
                                                                return count != index ? field : null
                                                            })
                                                            .filter(Boolean) as AdaptiveValueT[]

                                                        setFields(newFields)
                                                        setRemovedEle(true)
                                                    }}
                                                >
                                                    <span className="parent_svg">
                                                        <CloseIcon />
                                                    </span>
                                                </div>
                                            ) : null}
                                        </InputGrid>
                                    </div>
                                )
                            })}

                            {fields.filter(
                                data =>
                                    (data.value.blocks.length > 0 && data.value.blocks[0].text == '') ||
                                data.label.value == ''
                            ).length == 0 && fields.length < 11 ? (
                                <div className="plus_addmore">
                                    <div
                                        className="plus_add_more"
                                        onClick={() => {
                                            setFields(
                                                fields.concat({
                                                    label: { type: 'text', value: '' },
                                                    value: { entityMap: {}, blocks: [] }
                                                })
                                            )
                                            props.parentScrollCallback && props.parentScrollCallback(buttons.length <=3)
                                        }}
                                    >
                                        <SmallButton primary>
                                            <span>
                                                <AddIcon />
                                            </span>
                                            <label>Add More</label>
                                        </SmallButton>
                                    </div>
                                </div>
                            ) : null}
                        </BgWrapper>

                        <BgWrapper className={'adaptive_card'}>
                            <div className="opations_label">
                                <Header style={{ fontSize: 16 }}>Add buttons to the card: </Header>
                            </div>
                            {buttons.map((button, index) => {
                                return (
                                    <div className="opations_label1" key={index}>
                                        <InputGrid>
                                            <InputContainer>
                                                <Input
                                                    type="text"
                                                    value={button.label.value}
                                                    onChange={(event: any) => {
                                                        lableHandleChange(
                                                            {
                                                                type: 'text',
                                                                value: event.target.value
                                                            },
                                                            index,
                                                            'buttons'
                                                        )
                                                    }}
                                                />
                                                <InputContent>Button name</InputContent>
                                            </InputContainer>
                                            {/* <span>{countSet(index + 1)}</span> */}

                                            <DraftInputWrapper>
                                                <Editor
                                                    focusTooltip
                                                    focusTooltipContent={
                                                        'Click to select a variable from variable picker'
                                                    }
                                                    focusContent={'Value'}
                                                    DraftMap={button.value}
                                                    setDraftMap={(draft: DraftJST) => {
                                                        setRemovedEle(false)
                                                        valueHandleChange(draft, index, 'button')
                                                    }}
                                                    hideToolbar
                                                    automation
                                                    removedElement={removedEle ? removedElement : undefined}
                                                    updated={showCollectionUpdatePopup}
                                                    focus={
                                                        convertAsaSingleString(
                                                            button.value.blocks as RawDraftContentBlock[]
                                                        ).replace(/\s/g, '').length
                                                            ? 'focus_input'
                                                            : ''
                                                    }
                                                />
                                            </DraftInputWrapper>
                                            {buttons.length > 1 ? (
                                                <div
                                                    className="parent_node_no"
                                                    onClick={() => {
                                                        setButtons(buttons =>
                                                            buttons.filter((button, count) => count != index)
                                                        )
                                                        setRemovedEle(true)
                                                    }}
                                                >
                                                    <span className="parent_svg">
                                                        <CloseIcon />
                                                    </span>
                                                </div>
                                            ) : null}
                                        </InputGrid>
                                    </div>
                                )
                            })}
                            {buttons.filter(
                                data =>
                                    (data.value.blocks.length > 0 && data.value.blocks[0].text == '') ||
                                data.label.value == ''
                            ).length == 0 && buttons.length < 11 ? (
                                <div className="plus_addmore">
                                    <div
                                        className="plus_add_more"
                                        onClick={() => {
                                            setButtons(
                                                buttons.concat({
                                                    label: { type: 'text', value: '' },
                                                    value: { entityMap: {}, blocks: [] }
                                                })
                                            )
                                            props.parentScrollCallback && props.parentScrollCallback(true)
                                        }}
                                    >
                                        <SmallButton primary>
                                            <span>
                                                <AddIcon />
                                            </span>
                                            <label>Add More</label>
                                        </SmallButton>
                                    </div>
                                </div>
                            ) : null}
                        </BgWrapper>
                    </React.Fragment>
                )}
            </ErrorBoundary>
            {showSaveButtons && !loading && (
                <Button 
                    className="footer_button_fixed"
                    disable={
                        buttons.filter(
                            data =>
                            (data.value.blocks.length > 0 &&
                                data.value.blocks[0].text.length > 0 &&
                                data.label.value.length == 0) ||
                            (data.label.value.length > 0 &&
                                data.value.blocks.length > 0 &&
                                data.value.blocks[0].text.length == 0)
                    ).length > 0 ||
                    fields.filter(
                        data =>
                            (data.value.blocks.length > 0 &&
                                data.value.blocks[0].text.length > 0 &&
                                data.label.value.length == 0) ||
                            (data.label.value.length > 0 &&
                                data.value.blocks.length > 0 &&
                                data.value.blocks[0].text.length == 0)
                    ).length > 0 ||
                        basicInfo.title.length == 0 ||
                        (basicInfo.description.blocks.length > 0 && basicInfo.description.blocks[0].text.length == 0) ||
                        (basicInfo.image.length !== 0 && IsValidURL(basicInfo.image) === null) ||
                        (cardType == 'carousal' &&
                            contextPopupValues.collectionPicker.selectedCollection.name.length == 0)
                            || variableContext.isUrlOpen
                    }
                    onCancel={() => setPageState(VIEW)}
                    onSave={() => handleSave({ buttons, fields, basicInfo })}
                />
            )}
        </>
    )
}

export default AdaptiveResponse
