/*eslint-disable*/

import React, { useEffect, useState } from 'react'

import { BotDetailsT } from '@/DialogEditor/Canvas/Components/WelcomePageWithJourney'
import { useJourney } from '@/Journey/JourneyContext'
import { getJson } from '@/common/utils/axios-utils'

import { reloadWithoutParams } from '@/common/utils/utils'
import { logEvent, watsonLogsApiURL } from '@/common/utils/api-utils'
import { DefaultDialogue, DialogueT } from '../Dialogue/kind'
import { fetchFlowNodes1 } from '../DialogueCrew'
import { sortBySibling } from '../DialogueCrew/action'
import { EntityT } from '../Entity/kind'
import { IntentT } from '../Intent/kind'
import {
    createAssistant,
    deleteAssistant,
    getAssistants,
    getDialogueRootDescendants,
    getDialogueRoots,
    getDialogues,
    getEntities,
    getIntents,
    publishAssistant,
    unpublishAssistant,
    updateAssistant
} from '../Mechanisms/action'
import { ROUTER_PROPS_T } from '../kind'
import BotHomeListing from './Constructor'
import { ActionT, AssistantT, PageStateT } from './kind'

//TYPE IMPORTS

//ACTION IMPORTS

//COMPONENT IMPORTS

// import useContext from 'react';
// import { JourneyContext } from '@/Journey/JourneyMachine'

export type DialogDetailsT = {
    dialogues: DialogueT[][]
    welcomeNode: DialogueT
    fallbackNode: DialogueT
    intents: IntentT[]
    entities: EntityT[]
    defaultNodes: DialogueT[]
    assistantId: string
}

type FetchBotsFn = (downloadedBot?: { type: 'downloaded-new-not'; bot_id: string }) => void

export const BotsDataProvider = React.createContext<{ refetchBots: FetchBotsFn }>({
    refetchBots: () => {}
})

export const useBotsDataProvider = () => React.useContext(BotsDataProvider)

const AssistantConstructor = (props: ROUTER_PROPS_T) => {
    const crateFromMarketplace = new URLSearchParams(window.location.search).get('download-from-marketplace')
    // const downloadedBotFromMarketplace = new URLSearchParams(window.location.search).get('new-bot-from-marketplace')
    // console.log('crateFromMarketplace', crateFromMarketplace)
    const { workspacename } = props.match.params

    const journeyData = useJourney()

    const [AssistantState, setAssistantState] = useState<PageStateT>({
        action: ActionT.Loading
    })

    const [checkNull, setCheckNull] = useState([{ id: '', nullable: false }])
    const [dialogues, setDialogues] = useState<DialogDetailsT[]>([])

    const [chatChannelWS, setChatChannelWS] = useState([])

    const fetchAllBots = (addtionalActionsForBots?: { type: 'downloaded-new-not'; bot_id: string }) => {
        // console.log('hasRecentlyDownloaded', downloadedBot)
        setAssistantState({ action: ActionT.Loading })
        getAssistants(workspacename)
            .then(res => {
                if (res.length === 0 && crateFromMarketplace != 'true') {
                    setAssistantState({
                        action: ActionT.NoDataFound,
                        showAddPopup: false
                    })
                } else {
                    Promise.all(
                        res.map(assistant =>
                            getJson(
                                watsonLogsApiURL(
                                    `/${props.match.params.workspacename}/analytics/bot_summary_report/${assistant.id}/30`
                                )
                            )
                        )
                    ).then(data => {
                        Promise.all(res.map((assistant: AssistantT) => getDialogues(workspacename, assistant.id))).then(
                            async (result: any) => {
                                let flowNodes: any = fetchFlowNodes1(result)
                                var assistantNull = res.map((assistant: any, i: number) => {
                                    const nullData =
                                        flowNodes[i].filter((dialog: any) => dialog.automation === null).length > 0
                                    return {
                                        nullable: nullData,
                                        id: assistant.id
                                    }
                                })
                                await fetchDailogs(undefined, res, data)
                                setCheckNull(assistantNull)

                                // fetchWorkspaces(workspacename)
                                // Promise.resolve([
                                //     {
                                //         data: [
                                //             {
                                //                 workspace: 'nirup',
                                //                 assistance_id: '25b56031-6719-486b-b103-908331b20467',
                                //                 name: 'Workativ Assistant',
                                //                 description: 'Workativ Assistant',
                                //                 client_id: 'fgj',
                                //                 client_secrect: 'jnjb',
                                //                 verification_token: 'knkl',
                                //                 slack_deploy: null
                                //             }
                                //         ]
                                //     },
                                //     {
                                //         data: [
                                //             {
                                //                 workspace: 'nirup',
                                //                 bot_id: 'dc432576-df3a-4e67-a237-12fd0c5fceed',
                                //                 name: 'Workativ Assistant',
                                //                 description: 'Workativ Assistant',
                                //                 client_id: 'fgj',
                                //                 client_secrect: 'jnjb',
                                //                 verification_token: 'knkl',
                                //                 teams_deploy: 1
                                //             }
                                //         ]
                                //     }
                                // ]).then((op: any) => {
                                //     let filterslackBot = op[0].data.filter((data: any) => data.slack_deploy)
                                //     let filterteamsBot = op[1].data.filter((data: any) => data.teams_deploy)
                                //     let filterLiveBot = [...filterslackBot, ...filterteamsBot]
                                //     setChatChannelWS(filterLiveBot as any)
                                // })
                            }
                        )

                        // if (
                        //     res.length > 0 &&
                        //     addtionalActionsForBots != undefined &&
                        //     addtionalActionsForBots.type == 'downloaded-new-not' &&
                        //     addtionalActionsForBots.bot_id
                        // ) {
                        //     // console.log('res', res)

                        //     const updatedRes = res.map(x => ({ ...x, created: DateFns.parse(x.created) }))
                        //     type UnWrapArray<T> = T extends Array<infer U>
                        //         ? { 0: UnWrapArray<U>; 1: U }[U extends Array<any> ? 0 : 1]
                        //         : T

                        //     type AssistantWithDate = UnWrapArray<typeof updatedRes>

                        //     const sortedBots = R.sort(
                        //         R.descend<AssistantWithDate>(({ created }) => created.getTime())
                        //     )(updatedRes)

                        //     // console.log('sorted', sortedBots)

                        //     const latestBot = sortedBots[0]
                        //     if (journeyData.type === 'success') {
                        //         journeyData.value.send({
                        //             type: 'EVENT::BOT::DOWNLOADED',
                        //             tag: 'BOT_EVENT',
                        //             botId: latestBot.id,
                        //             botName: latestBot.name,
                        //             journeyType: 'Main'
                        //         })

                        //         journeyData.value.send({
                        //             type: 'EVENT::BOT::CREATED',
                        //             tag: 'BOT_EVENT',
                        //             botId: latestBot.id,
                        //             botName: latestBot.name,
                        //             journeyType: 'Main'
                        //         })

                        //         getDialogueRoots(workspacename, latestBot.id).then(async (roots: DialogueT[]) => {
                        //             const hasIfNode = roots.find(x => x.type === 'if_node') !== undefined
                        //             const hasFaqNode = roots.find(x => x.type === 'faq_node') !== undefined

                        //             if (hasFaqNode) {
                        //                 journeyData.value.send({
                        //                     type: 'EVENT::FAQ::CREATED',
                        //                     tag: 'GENERIC',
                        //                     journeyType: 'FAQ',
                        //                     refId: latestBot.id
                        //                 })
                        //             }

                        //             if (hasIfNode) {
                        //                 journeyData.value.send({
                        //                     type: 'EVENT::DIALOG::CREATED',
                        //                     tag: 'GENERIC',
                        //                     journeyType: 'Dialog',
                        //                     refId: latestBot.id
                        //                 })
                        //             }
                        //             if (crateFromMarketplace) {
                        //                 openAssistantDialogs({ ...latestBot, created: latestBot.created.toString() })
                        //             }

                        //             // const rootDes: any = roots.map((d: DialogueT) =>
                        //             //     getDialogueRootDescendants(workspacename, latestBot.id, d.uuid)
                        //             // )
                        //             // const dialoguess = await Promise.all(rootDes)
                        //             //     .then(resp => resp)
                        //             //     .catch(() => [])

                        //             // const intents = await getIntents(workspacename, latestBot.id)

                        //             // const entities = await getEntities(workspacename, latestBot.id)

                        //             // const defaultNodes = sortBySibling(roots).filter((node: DialogueT) => {
                        //             //     if (
                        //             //         node.conditions &&
                        //             //         (node.conditions.type === 'WelcomeExpression' ||
                        //             //             node.conditions.type === 'FallBackExpression')
                        //             //     )
                        //             //         return false
                        //             //     return true
                        //             // })

                        //             // const welcomeNode = roots.filter((node: DialogueT) => {
                        //             //     if (node.conditions && node.conditions.type === 'WelcomeExpression') return true
                        //             //     return false
                        //             // }).length
                        //             //     ? roots.filter((node: DialogueT) => {
                        //             //           if (node.conditions && node.conditions.type === 'WelcomeExpression')
                        //             //               return true
                        //             //           return false
                        //             //       })[0]
                        //             //     : DefaultDialogue

                        //             // const fallbackNode = roots.filter((node: DialogueT) => {
                        //             //     if (node.conditions && node.conditions.type === 'FallBackExpression')
                        //             //         return true
                        //             //     return false
                        //             // }).length
                        //             //     ? roots.filter((node: DialogueT) => {
                        //             //           if (node.conditions && node.conditions.type === 'FallBackExpression')
                        //             //               return true
                        //             //           return false
                        //             //       })[0]
                        //             //     : DefaultDialogue

                        //             // setDialogues([
                        //             //     ...dialogues,
                        //             //     {
                        //             //         dialogues: dialoguess as any,
                        //             //         intents,
                        //             //         entities,
                        //             //         defaultNodes,
                        //             //         welcomeNode,
                        //             //         fallbackNode,
                        //             //         assistantId: latestBot.id
                        //             //     }
                        //             // ])
                        //         })
                        //     }

                        //     // openAssistantDialogs

                        //     removeURLParams('download-from-marketplace')
                        // }
                    })
                }
            })
            .catch(error => {
                console.log('error', error)
                if (error.response && error.response.status == 403) {
                    setAssistantState({
                        action: ActionT.Unauthorized
                    })
                }
            })
    }

    async function fetchDailogs(latestBot: any, bots: AssistantT[], data: any[]) {
        Promise.all(
            bots.map(async (assistant: AssistantT) => {
                return { dialogRoots: await getDialogueRoots(workspacename, assistant.id), id: assistant.id }
            })
        ).then(async (response: { dialogRoots: DialogueT[]; id: string }[]) => {
            Promise.all(
                response.map(async (resp: { dialogRoots: DialogueT[]; id: string }, i) => {
                    const roots = resp.dialogRoots
                    const rootDes: any = roots.map((d: DialogueT) =>
                        getDialogueRootDescendants(workspacename, resp.id, d.uuid)
                    )
                    const dialogues = await Promise.all(rootDes)
                        .then(resp => resp)
                        .catch(() => [])

                    const intents = await getIntents(workspacename, resp.id)

                    const entities = await getEntities(workspacename, resp.id)

                    const defaultNodes = sortBySibling(roots).filter((node: DialogueT) => {
                        if (
                            node.conditions &&
                            (node.uuid === (resp as any).welcome_node ||
                                node.conditions.type === 'FallBackExpression')
                        )
                            return false
                        return true
                    })

                    const welcomeNode = roots.filter((node: DialogueT) => {
                        if (node.uuid === (resp as any).welcome_node) return true
                        return false
                    }).length
                        ? roots.filter((node: DialogueT) => {
                              if (node.uuid === (resp as any).welcome_node) return true
                              return false
                          })[0]
                        : DefaultDialogue

                    const fallbackNode = roots.filter((node: DialogueT) => {
                        if (node.conditions && node.conditions.type === 'FallBackExpression') return true
                        return false
                    }).length
                        ? roots.filter((node: DialogueT) => {
                              if (node.conditions && node.conditions.type === 'FallBackExpression') return true
                              return false
                          })[0]
                        : DefaultDialogue

                    return {
                        dialogues: dialogues as any,
                        intents,
                        entities,
                        defaultNodes,
                        welcomeNode,
                        fallbackNode,
                        assistantId: resp.id
                    }
                })
            ).then(response => {
                setDialogues(response as any)
                setAssistantState({
                    action: ActionT.View,
                    Assistants: bots,
                    summary: data.map((sumary, id) => ({
                        ...sumary,
                        botId: bots[id].id
                    })) as BotDetailsT[]
                })
            })
        })
    }

    useEffect(() => {
        // if (downloadedBotFromMarketplace != undefined) {
        //     // console.log("download called")
        //     fetchAllBots({ bot_id: downloadedBotFromMarketplace })
        //     removeURLParams('new-bot-from-marketplace')
        // } else {
        fetchAllBots()
        // }
    }, [])

    const newAssistant = (name: string, lang_name: string, cb: Function) => {
        createAssistant(workspacename, { name, lang_name })
            .then((res: AssistantT) => {
                // console.log('value', journeyData)
                if (journeyData.type === 'success') {
                    journeyData.value.send({
                        type: 'EVENT::BOT::CREATED',
                        tag: 'BOT_EVENT',
                        botId: res.id,
                        botName: name,
                        journeyType: 'Main'
                    })
                }

                logEvent(workspacename, {
                    event:"EVENT::ONBOARDING::CREATE_BOT",event_meta:{
                   name: name,
                   description: ""
                }})
    
                setTimeout(() => {
                    reloadWithoutParams()
                    // window.location.reload()
                }, 1000)
                // window.location.reload()
            })
            .catch(error => {
                // console.log('eerror', error)
                cb(error)
            })
    }
    const updateBot = (id: string, name: string, success: Function, failure: Function) => {
        updateAssistant(workspacename, id, { name }, name)
            .then((res: AssistantT) => {
                reloadWithoutParams()
                // window.location.reload()
            })
            .catch(_ => {
                failure()
            })
    }
    const deleteBot = (id: string, success: Function, failure: Function) => {
        deleteAssistant(workspacename, id)
            .then((res: AssistantT) => {
                reloadWithoutParams()
                // window.location.reload()
            })
            .catch(_ => {
                failure()
            })
    }
    const unpublishbot = (id: string, success: Function, failure: Function, name:string) => {
        unpublishAssistant(workspacename, id)
            .then(res => {
                logEvent(workspacename, {
                    event:"EVENT::CHATBOT::UNPUBLISH_BOT", event_meta:{
                    name: name,
                    description: ""
                 }}).finally(()=> {
                    reloadWithoutParams()
                })
            })
            .catch(err => {
                failure()
            })
    }
    const publishbot = (id: string, success: Function, failure: Function, name:string) => {
        publishAssistant(workspacename, id)
            .then(res => {
                logEvent(workspacename, {
                   event:"EVENT::CHATBOT::PUBLISH_BOT", event_meta:{
                   name: name,
                   description: ""
                }}).finally(()=>{
                    reloadWithoutParams()
                })
                
            })
            .catch(err => {
                failure()
            })
    }
    const openAssistant = (assistant: AssistantT) =>
        props.history.push(`/workspace/${workspacename}/dialog/${assistant.name}/summary`)

    const openAssistantDialogs = (assistant: AssistantT) =>
        props.history.push(`/workspace/${workspacename}/dialog/${assistant.name}/dialogs`)

    return (
        <BotsDataProvider.Provider
            value={{
                refetchBots: fetchAllBots
            }}
        >
            <BotHomeListing
                state={AssistantState}
                setState={setAssistantState}
                retry={fetchAllBots}
                openAssistant={openAssistant}
                newAssistant={newAssistant}
                updateAssistant={updateBot}
                deleteBot={deleteBot}
                unpublishbot={unpublishbot}
                publishbot={publishbot}
                checkNull={checkNull}
                chatChannelWS={chatChannelWS}
                props={props}
                dialogues={dialogues}
            />
        </BotsDataProvider.Provider>
    )
}

export default AssistantConstructor
