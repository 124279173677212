/*eslint-disable*/
import * as React from 'react'
import Tooltip from 'react-tooltip-lite'

import {
    AddIcon,
    CheckIcon,
    CopyIocn,
    DeleteIcon,
    DialogCardIcon,
    DialogIcon,
    EditIcon,
    EntityIcon,
    FaqICon,
    IntentIcon,
    SuccessIcon
} from '../Icons/Icons'
import { WarningAlertIcon } from '../Icons/Workflow.Icons'
import { formatAMPM, formatDateInCard } from '../utils/utils'
import { SelectBox } from './SelectBox'

let Parser = require('html-react-parser')

export const ToolBox: React.FunctionComponent = ({ children }) => {
    return <div className="entity_hvr">{children}</div>
}

type CardS = {
    show: boolean
    hoverCopy:boolean
    showCopied:boolean
}
type CardP = {
    title?: string
    isSelected: Function
    move?: Function
    searchWord?: string
    select: Function
    showSelection: Function
    onClick: Function
    delete: Function
    edit: Function
    id: string
    uuid: string
    label: string
    modified: string
    setOpenPastePopup?:Function
    description?: string
    info?: string
    examplesCount?: number
    dialogIndex?: number
    showToolbox: boolean
    disabled: boolean
    showInputError?: boolean
    entityLength?: number
    type?: string
    permissions?:{[key:string]:boolean}
}
export class Card extends React.Component<CardP, CardS> {
    state: CardS = {
        show: false,
        hoverCopy:false,
        showCopied:false
    }
    componentDidUpdate() {
        let modal = document.getElementById('modal-root')
        if (modal && modal.children.length > 0 && !this.props.showSelection() && this.state.show) {
            this.setState({ show: false })
        }
    }
    isSelected = () => this.props.isSelected(this.props.uuid ? this.props.uuid : this.props.id)

    handleSelected = () => this.props.select(this.props.uuid ? this.props.uuid : this.props.id)

    showSelection = () => this.props.showSelection() || this.state.show

    deleteCard = () => this.props.delete({ uuid: this.props.uuid, title: this.props.title, type: this.props.type })

    moveCard = () => this.props.move && this.props.move(this.props.id)

    editCard = () => this.props.edit(this.props.id)

    handleDrag = () => {
        this.setState(({ show }) => ({
            show: !show
        }))
    }

    setShow = () => {
        this.setState(({ show }) => ({
            show: true
        }))
    }

    clearShow = () => {
        this.setState(({ show }) => ({
            show: false
        }))
    }

    handleClick = () => this.props.onClick(this.props.id)
    renderCards = (props: any) => {
        const {
            label,
            info,
            type,
            examplesCount,
            dialogIndex,
            showToolbox,
            modified,
            disabled,
            entityLength,
            description,
            permissions
        } = props
        return dialogIndex == undefined ? (
            <div className="col-3 dialog_grids">
                <div
                    className={'entity_cards_layer dialog_cards_layer'}
                    onMouseOver={this.setShow}
                    onMouseLeave={this.clearShow}
                    onClick={() => (!disabled ? this.handleClick() : {})}
                >
                    <div className="dialog_top_section">
                      <div className="dialog_icon">
                           <span>
                                {examplesCount != undefined ? <IntentIcon /> : <EntityIcon />}
                            </span>
                        </div>
                     
                        {!props.label.includes('workativ-') && (
                            <SelectBox
                                show={this.showSelection()}
                                isSelected={this.isSelected()}
                                handleSelected={this.handleSelected}
                            />
                        )}
                        {props.label.includes('workativ-') && (
                            <SelectBox show={false} isSelected={this.isSelected()} handleSelected={this.handleSelected} />
                        )}
                        {showToolbox && (
                            <ToolBox>
                                {permissions.delete && <span
                                    className="home_edit"
                                    onClick={e => {
                                        e.stopPropagation()
                                        this.deleteCard()
                                    }}
                                >
                                    <Tooltip
                                    className="target customTip "
                                    zIndex={10000}
                                    arrowSize={8}
                                    tagName="span"
                                    content={'Delete'}
                                    distance={5}
                                >
                                    <DeleteIcon />
                                </Tooltip>
                                    
                                </span>}
                                {permissions.write && <span
                                    className="home_edit"
                                    onClick={e => {
                                        e.stopPropagation()
                                        this.editCard()
                                    }}
                                >
                                    <Tooltip
                                    className="target customTip "
                                    zIndex={10000}
                                    arrowSize={8}
                                    tagName="span"
                                    content={'Edit'}
                                    distance={5}
                                >
                                    <EditIcon />
                                </Tooltip>
                                    
                                </span>}
                            </ToolBox>
                        )}
                    </div>
                    <div>
                        {dialogIndex != undefined ? (
                            <React.Fragment>
                                <div className="d_card_wrapper">
                                    <div className="d_card_left">
                                        <span>
                                            <IntentIcon />
                                        </span>
                                    </div>
                                    <div className="d_card_right">
                                        <div className="search-card-title d-inline searching_dialog_title">
                                            {Parser(label)}
                                        </div>
                                        <p>{info}</p>
                                    </div>
                                </div>
                                {/* <h6>
                                    <span>{dialogIndex}</span>
                                </h6>
                                <div className="search-card-title d-inline searching_dialog_title">{Parser(label)}</div> */}
                            </React.Fragment>
                        ) : (
                            <div className="search-card-title d-inline">
                                <div className="d_card_wrapper">
                                    {/* <div className="d_card_left">
                                        <span>{examplesCount != undefined ? <IntentIcon /> : <EntityIcon />}</span>
                                    </div> */}
                                    <div className="d_card_right">
                                        <div>
                                            <div
                                                className={
                                                    examplesCount != undefined
                                                        ? 'search-card-title d-inline searching_dialog_title intent_title'
                                                        : 'search-card-title d-inline searching_dialog_title entity_title'
                                                }
                                            >
                                                {Parser(label)}
                                            </div>
                                            <p>{info}</p>
                                        </div>

                                        {/* Only Intent Usage | start*/}
                                        {examplesCount != undefined ? (
                                            examplesCount == 0 ? (
                                                <span className="entity_span">No Example</span>
                                            ) : (
                                                <span className="entity_span">
                                                    Examples <span>({examplesCount})</span>
                                                </span>
                                            )
                                        ) : entityLength > 10 ? (
                                            <span className="entity_span">
                                                Values <span>({entityLength})</span>
                                            </span>
                                        ) : (
                                            <span className="entity_span">
                                                Values <span>(0{entityLength})</span>
                                            </span>
                                        )}

                                        {/* Only Intent Usage | end */}
                                    </div>
                                </div>
                                {/* <div className="intent_card_h5">{Parser(label)}</div> */}
                            </div>
                        )}
                        {/* {<p className="m_t_15_entity">{info}</p>} */}
                        <div className="error_icon_wrapper">
                            <div className="error_icon_wrapper_left">
                                <span className="entity_span">
                                    {formatDateInCard(modified.includes('Z') ? modified : modified + 'Z')}
                                </span>
                                <span className="entity_span">
                                    {formatAMPM(modified.includes('Z') ? modified : modified + 'Z')}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div
                className={
                    !this.showSelection()
                        ? this.props.searchWord === ''
                            ? 'col-lg-2 col-md-2 col-sm-6 col-12 dialog_cards_layer'
                            : 'col-lg-2 col-md-2 col-sm-6 col-12 dialog_cards_layer search_works'
                        : this.props.searchWord === ''
                        ? 'col-lg-2 col-md-2 col-sm-6 col-12 dialog_cards_layer searching_dialog'
                        : 'col-lg-2 col-md-2 col-sm-6 col-12 dialog_cards_layer searching_dialog search_works'
                }
                onMouseOver={this.setShow}
                onMouseLeave={this.clearShow}
                onClick={() => (!disabled ? this.handleClick() : {})}
            >
                <div className="dialog_top_section">
                    <div className="dialog_icon">
                        <span>
                            {type == 'if_node' ? <DialogCardIcon /> : <FaqICon />}
                        </span>
                    </div>
                    <SelectBox
                        show={this.showSelection()}
                        isSelected={this.isSelected()}
                        handleSelected={this.handleSelected}
                    />
                    {showToolbox && (
                        <ToolBox>
                            {(!this.props.permissions||this.props.permissions['delete']) &&<span
                                className="home_edit"
                                onClick={e => {
                                    e.stopPropagation()
                                    this.deleteCard()
                                }}
                            >
                                <Tooltip
                                    className="target customTip "
                                    zIndex={10000}
                                    arrowSize={8}
                                    tagName="span"
                                    content={'Delete'}
                                    distance={5}
                                >
                                    <DeleteIcon />
                                </Tooltip>
                                
                            </span>}
                            {(!this.props.permissions||this.props.permissions['write']) &&<span
                                className="home_edit"
                                onClick={e => {
                                    e.stopPropagation()
                                    this.editCard()
                                }}
                            >
                                <Tooltip
                                    className="target customTip "
                                    zIndex={10000}
                                    arrowSize={8}
                                    tagName="span"
                                    content={'Edit'}
                                    distance={5}
                                >
                                    <EditIcon />
                                </Tooltip>
                                
                            </span>}
                            {type == 'if_node'&&(!this.props.permissions||this.props.permissions['write']) &&
                            <span className="home_edit" onClick={(e)=>{
                                e.preventDefault()
                                e.stopPropagation()
                                this.props.setOpenPastePopup && this.props.setOpenPastePopup()
                                this.setState({showCopied:true})
                                setTimeout(()=>{
                                    this.setState({showCopied:false})
                                },1000)
                                }}
                            >
                                <Tooltip
                                    className="target customTip "
                                    zIndex={10000}
                                    arrowSize={8}
                                    tagName="span"
                                    content={'Copy'}
                                    distance={5}
                                >
                                <CopyIocn />
                                </Tooltip>
                            </span>
                            }
                            {this.state.showCopied&&
                            <span className="copy_function_copied">
                                <SuccessIcon />
                                <p>Copied</p>
                            </span>
                            }
                            {(!this.props.permissions||this.props.permissions['write']) &&<span
                                className="home_edit"
                                onClick={e => {
                                    e.stopPropagation()
                                    this.moveCard()
                                }}
                            >
                                <Tooltip
                                    className="target customTip "
                                    zIndex={10000}
                                    arrowSize={8}
                                    tagName="span"
                                    content={'Move'}
                                    distance={5}
                                >
                                <svg version="1.1" id="Essentials__x2F__Interface" x="0px" y="0px" viewBox="0 0 48 48">
                                    <g>
                                        <g>
                                            <g>
                                                <path d="M38,48c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1v46C39,47.6,38.6,48,38,48z" />
                                            </g>
                                            <g>
                                                <path
                                                    d="M38,48c-0.3,0-0.5-0.1-0.7-0.3l-9-9c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l8.3,8.3l8.3-8.3c0.4-0.4,1-0.4,1.4,0
                    c0.4,0.4,0.4,1,0,1.4l-9,9C38.5,47.9,38.3,48,38,48z"
                                                />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M10,48c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1v46C11,47.6,10.6,48,10,48z" />
                                            </g>
                                            <g>
                                                <path
                                                    d="M19,11c-0.3,0-0.5-0.1-0.7-0.3L10,2.4l-8.3,8.3c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4l9-9c0.4-0.4,1-0.4,1.4,0l9,9
                    c0.4,0.4,0.4,1,0,1.4C19.5,10.9,19.3,11,19,11z"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                </Tooltip>
                            </span>}
                        </ToolBox>
                    )}
                 </div>
                <div className="search_word_highligter">
                    {dialogIndex != undefined ? (
                        <React.Fragment>
                            <div className="d_card_wrapper">
                                {/* <div className="d_card_left">
                                    <span>{type == 'if_node' ? <DialogCardIcon /> : <FaqICon />}</span>
                                </div> */}
                                <div className="d_card_right">
                                    <div className="search-card-title d-inline searching_dialog_title">
                                        {Parser(label)}
                                    </div>
                                    <p>{description}</p>
                                </div>
                            </div>
                        </React.Fragment>
                    ) : (
                        <div className="search-card-title d-inline">{Parser(label)}</div>
                    )}
                    <div className="error_icon_wrapper">
                        <div className="error_icon_wrapper_left">
                            <span className="entity_span">
                                {formatDateInCard(modified.includes('Z') ? modified : modified + 'Z')}
                            </span>
                            <span className="entity_span">|</span>
                            <span className="entity_span">
                                {formatAMPM(modified.includes('Z') ? modified : modified + 'Z')}
                            </span>
                        </div>
                        <div className="error_icon_wrapper_right">
                            {this.props.showInputError && (
                                <div className="error_input_show parent">
                                    <Tooltip
                                        className="target customTip"
                                        zIndex={10000}
                                        arrowSize={8}
                                        tagName="span"
                                        content="Input data is missing."
                                        distance={5}
                                    >
                                        <WarningAlertIcon />
                                    </Tooltip>
                                </div>
                            )}
                            <label className="dialog_count">
                                <span>{dialogIndex}</span>
                            </label>
                        </div>
                    </div>

                    {/* Only Intent Usage | start*/}
                    {examplesCount != undefined && (
                        <span className="entity_span">
                            {examplesCount == 0
                                ? 'No Example'
                                : examplesCount > 1
                                ? `${examplesCount} Examples`
                                : `${examplesCount} Example`}
                        </span>
                    )}
                    {/* Only Intent Usage | end */}

                    {info && <p>{info}</p>}
                </div>
            </div>
        )
    }

    render() {
        let { label, info, modified, id } = this.props

        if (!id.includes('workativ-')) {
            return this.renderCards(this.props)
        } else {
            return (
                <div className="col-3 dialog_grids">
                    <div className="entity_cards_layer" onClick={() => this.handleClick()}>
                        <input
                            type="radio"
                            className="option-input radio"
                            style={{ visibility: 'hidden' }}
                            onChange={() => {}}
                        />
                        <div className="entity_hvr" />
                        <div style={{ marginTop: this.showSelection() ? 7 : 22 }}>
                            <div className="search-card-title d-inline testing1">
                                <div className="intent_card_h5">{Parser(label)}</div>
                                {info && <p className="m_t_15_entity">{info}</p>}
                            </div>
                            <span className="entity_span">
                                {formatDateInCard(modified.includes('Z') ? modified : modified + 'Z')}
                            </span>
                            <span className="entity_span">
                                {formatAMPM(modified.includes('Z') ? modified : modified + 'Z')}
                            </span>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export const NewCard: React.SFC<any> = ({ onClick, disabled }) => {
    return (
        <div className="col-3 dialog_grids" style={{ display: 'none' }}>
            <div
                className={!disabled ? 'entity_cards_layer1' : 'entity_cards_layer1 hide_create_card'}
                onClick={!disabled ? onClick : null}
            >
                <div className="CREATED_DIALOG">
                    <AddIcon />
                    <h6>Create New</h6>
                </div>
            </div>
        </div>
    )
}

const DefaultNode: React.SFC<any> = props => {
    return (
        <>
            <div
                className={
                    !props.disabled
                        ? 'col-lg-2 col-md-2 col-sm-6 col-12  dialog_cards_layer start_layer'
                        : 'col-lg-2 col-md-2 col-sm-6 col-12  dialog_cards_layer start_layer hide_create_card'
                }
                onClick={props.disabled ? () => {} : () => props.onClick(props.dialog.dialog_node)}
            >
                <div className="search_word_highligter">
                    <div className="d_card_wrapper">
                        <div className="d_card_left">
                            <span>
                                <DialogCardIcon />
                            </span>
                        </div>
                        <div className="d_card_right">
                            <div className="search-card-title d-inline searching_dialog_title">
                                <h3 style={{ paddingTop: 0 }}>{props.isStart ? 'Welcome' : 'Fallback'} </h3>
                            </div>
                            <p>
                                {props.isStart
                                    ? 'Add welcome message for your users'
                                    : 'Add fallback message for your users'}
                            </p>
                        </div>
                    </div>
                    <div className="error_icon_wrapper">
                        <div className="error_icon_wrapper_left">
                            {props.dialog ? (
                                <span className="entity_span">
                                    {props.dialog.updated ? formatDateInCard(props.dialog.updated) : ''}
                                </span>
                                
                            ) : null}
                            {props.dialog ? (
                                <span className="entity_span">
                                    {props.dialog.updated ? formatAMPM(props.dialog.updated) : ''}
                                </span>
                            ) : null}
                        </div>
                        <div className="error_icon_wrapper_right">
                            <label>
                                <span>Start</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const StartCard: React.SFC<any> = props => {
    return <DefaultNode isStart={true} {...props} />
}

export const EndCard: React.SFC<any> = props => {
    return <DefaultNode isStart={false} {...props} />
}

export const DialogCard: React.SFC<any> = props => {
    return <Card {...props} />
}
export const FAQCard: React.SFC<any> = props => {
    return <Card {...props} />
}
