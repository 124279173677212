import { useCombobox, useSelect } from 'downshift'
import { RawDraftContentBlock } from 'draft-js'
import React, { useEffect, useRef, useState } from 'react'

import { sortBySibling } from '@/Assistant/DialogueCrew/action'
import Editor from '@/Assistant/Editor/Editor'
import { convertAsaSingleString } from '@/Flows/canvas/utils'
import { DropdownWithCircle } from '@/common/Icons/Icons'
import {
    CancelButton,
    DropdownLi,
    DropdownUl,
    DropdownUlWrapper,
    Input,
    InputContainer,
    InputContent,
    InputWrapper
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { DraftInputWrapper } from '@/common/styled/DraftJs.Dumb'

import { DialogT } from '../useFaqState'
import { DropDownWrapperT } from './FaqPopup'
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons'
import { sortAnArrayByName } from '@/common/utils/common.utils'

export default function NextStep({
    then,
    setThen,
    dialog,
    setDialog,
    GetDialogs,
    dialogs,
    message,
    setMessage,
    workspacename,
    dialougeId,
    updateId,
    forFaq,
    showNextStepError
}: DropDownWrapperT) {
    return (
        <>
            <UseSelectThen value={then} onChange={setThen} showNextStepError={showNextStepError} />
            {then === 'jump_to' ? (
                <UseComboboxJumpTo
                    GetDialogs={GetDialogs}
                    dialogs={sortBySibling(dialogs)}
                    workspacename={workspacename}
                    dialougeId={dialougeId}
                    value={dialog}
                    onChange={(value: string) => setDialog(value)}
                    updateId={updateId}
                />
            ) : (
                <InputWrapper style={{ padding: 0 }}>
                    <DraftInputWrapper>
                        <div className="select_work_form">
                            <Editor
                                focusContent={'Send message to user'}
                                DraftMap={message}
                                setDraftMap={setMessage}
                                hideToolbar={forFaq}
                                focus={
                                    convertAsaSingleString(message.blocks as RawDraftContentBlock[]).replace(/\s/g, '')
                                        .length
                                        ? 'focus_input'
                                        : ''
                                }
                            />
                        </div>
                    </DraftInputWrapper>
                </InputWrapper>
            )}
        </>
    )
}
function UseComboboxJumpTo({ updateId, GetDialogs, dialogs, workspacename, dialougeId, value, onChange }: any) {
    const displayItem = (item: DialogT | null) => (item ? item.title : '')
    dialogs = sortAnArrayByName(dialogs,"title").filter((dialog: any) => dialog.id !== updateId)
    const searchBox: any = useRef(null)
    const [inputValue, setInputValue] = useState<string>('')
    const [inputItems, setInputItems] = useState(dialogs)
    const {
        isOpen,
        selectedItem,
        getToggleButtonProps,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        getItemProps
    } = useCombobox({
        items: inputItems,
        selectedItem: value,
        itemToString: displayItem,
        onSelectedItemChange: ({ selectedItem }) => {
            onChange(selectedItem)
        },
        inputValue,
        onInputValueChange: ({ inputValue }: any) => {
            setInputItems(
                dialogs.filter(({ title: item }: { title: string }) =>
                    item.toLowerCase().startsWith(inputValue.toLowerCase())
                )
            )
            setInputValue(inputValue)
        }
    })
    useEffect(() => {
        if (isOpen && searchBox.current) {
            searchBox.current.focus()
            setInputValue('')
            setInputItems(dialogs)
        }
        GetDialogs(workspacename, dialougeId)
    }, [isOpen])
    return (
        <InputContainer>
            <div {...getComboboxProps()}>
                {!isOpen ? (
                    <Input {...getToggleButtonProps()} value={selectedItem && displayItem(selectedItem)} />
                ) : (
                    <Input
                        {...getInputProps({
                            ref: searchBox
                        })}
                    />
                )}
                <InputContent>Select dialog or faq</InputContent>
                <CancelButton>
                    <DropdownWithCircle />
                </CancelButton>
            </div>
            <DropdownUlWrapper>
                <DropdownUl style={{ position: 'relative' }} {...getMenuProps()}>
                    {isOpen ? (
                        inputItems.length !== 0 ? (
                            (sortAnArrayByName(inputItems,"title")as DialogT[]).map((item: DialogT, index: number) => {
                                return (
                                    <DropdownLi key={`${item.title}${index}`} {...getItemProps({ item, index })}>
                                        {item.title}
                                    </DropdownLi>
                                )
                            })
                        ) : (
                            <DropdownLi>No match found</DropdownLi>
                        )
                    ) : null}
                </DropdownUl>
            </DropdownUlWrapper>
        </InputContainer>
    )
}
function UseSelectThen({ value, onChange, showNextStepError }: { value: string; onChange: (e: any) => void, showNextStepError?:boolean }) {
    const items = ['jump_to', 'exit']
    const { isOpen, selectedItem, getToggleButtonProps, getLabelProps, getMenuProps, getItemProps } = useSelect<string>(
        {
            items,
            selectedItem: value,
            onSelectedItemChange: ({ selectedItem }) => onChange(selectedItem)
        }
    )
    const displayString = (item: string | null) =>
        item === 'jump_to' ? 'Jump to specific dialog or faq' : item === 'exit' ? 'Exit the conversation' : ''
    return (
        <InputContainer>
            <Input type="text" value={displayString(selectedItem)} {...getToggleButtonProps()} />
            <InputContent {...getLabelProps()}>Then</InputContent>
            {showNextStepError&& 
            <div className="error_input_show dialog_node svg-15px">
                <WarningAlertIcon />
                  <span className="error_menu_hvr">Input data is missing.</span>
            </div>}
            <CancelButton {...getToggleButtonProps()}>
                <DropdownWithCircle />
            </CancelButton>
            <DropdownUlWrapper>
                <DropdownUl {...getMenuProps()}>
                    {isOpen &&
                        items.map((item, index) => (
                            <DropdownLi key={item} {...getItemProps({ item, index })}>
                                {displayString(item)}
                            </DropdownLi>
                        ))}
                </DropdownUl>
            </DropdownUlWrapper>
        </InputContainer>
    )
}
