import * as R from 'ramda'

// Dialog-editor

export const findDuplicateInSamerray = R.pipe(
    R.reduce(
        ({ results, foundOnce }, item) =>
            R.contains(item, foundOnce)
                ? { results: R.append(item, results), foundOnce }
                : { results, foundOnce: R.append(item, foundOnce) },
        { results: [], foundOnce: [] } as any
    ),
    R.prop('results')
)
