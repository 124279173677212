import 'react-circular-progressbar/dist/styles.css'

/*eslint-disable*/
import React, { useState } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'

import {
    ArrowIcon,
    AutomateInfoIcon,
    BasicIcon,
    DownarrowWithCircle,
    InfoIcon,
    InformationIcon,
    PriceEnterpriseIcon,
    PriceFreeIcon,
    PriceProIcon,
    PriceStandardIcon,
    PricingNoIcon,
    PricingYesIcon
} from '@/common/Icons/Icons'
import {
    AmountContent,
    BackButton,
    BodyWrapper,
    Border,
    Button,
    ButtonWrapper,
    CardCircle,
    CardContent,
    CheckBox,
    Container,
    ContentWrapper,
    Count,
    CurrentPlanCard,
    CurrentPlanCardWrapper,
    CurrentPlanDetail,
    CurrentPlanHeader,
    CurrentPlanWrapper,
    DropdownLiWrapper,
    DropdownUl,
    DropdownWrapper,
    Header,
    HeaderContent,
    HeaderLinkRight,
    HeaderName,
    HeaderWidthAuto,
    HoverUserIcon,
    ImgWrapper,
    Information,
    InformationContent,
    MessageContainer,
    MessageHeader,
    MessageLeft,
    MessageRight,
    MessageWrapper,
    Paragraph,
    PlanContetnWrapper,
    PriceEnterpriseWrapper,
    PricingCard,
    PricingContainer,
    PricingHeader,
    PricingHeaderLeft,
    PricingHeaderRight,
    PricingSection,
    QuestionPlan,
    QuestionPlanLink,
    Span,
    Switch,
    Toggle,
    ToggleSwitch,
    Toggler,
    TopHeaderWrapper,
    TrialDate,
    UpgradeButton,
    UpgradeHeader,
    UpgradePlanLeft,
    UpgradePlanRight,
    UpgradePlanWrapper,
    WorkflowCount,
    WorkflowHeader,
    WorkflowWrapper,
    Wrapper
} from '@/common/styled/Subscription.Dumb'

// CODE

export const BackButtonWrapper = () => {
    return (
        <BackButton>
            <span>
                <ArrowIcon />
            </span>{' '}
            <label>Back</label>
        </BackButton>
    )
}

export const PlansWrapper = () => {
    return (
        <div className="plansWrapper">
            <AlertWrapper />
            <PlansHeader name={'Usage'} />
            <PlansBodyWrapper />
        </div>
    )
}

export const PlansHeader = (props: any) => {
    return (
        <Wrapper>
            <Header>
                <Container flexDirection>
                    <TopHeaderWrapper>
                        <HeaderName>
                            {props.backButton && <BackButtonWrapper />}
                            <div>{props.name}</div>
                        </HeaderName>
                        <QuestionYourPlan />
                    </TopHeaderWrapper>
                </Container>
            </Header>
        </Wrapper>
    )
}

export const PlansBodyWrapper = () => {
    return (
        <BodyWrapper scrollHeight>
            <Wrapper>
                <Container>
                    <Plan />
                    <UpgradePlan />
                    <PricingWrapper />
                </Container>
            </Wrapper>
        </BodyWrapper>
    )
}

const Plan = () => {
    return (
        <CurrentPlanCardWrapper>
            <CurrentPlanCard>
                <>
                    <PricingPlanCards
                        plan={'Your Current Plan'}
                        CurrentPlan={'Pro Trial'}
                        CurrentPlanCounts={'12 days remaining'}
                    />
                    <WorkflowCards name={'Active Workflows'} count={'2/2'} value={'100'} />
                    <WorkflowCards name={'Workflow Executions'} count={'36/100'} value={'36'} />
                    <WorkflowCards name={'FAQ Answers'} count={'108/300'} value={'36'} />
                    <WorkflowCards name={'Messages'} count={'308/500'} value={'63'} />
                </>
            </CurrentPlanCard>

            <HeaderLinkRight>
                <span>View Previous Consumptions</span>
            </HeaderLinkRight>
        </CurrentPlanCardWrapper>
    )
}

export const CurrentPlanCards = (props: any) => {
    return (
        <CurrentPlanWrapper>
            <ImgWrapper>
                <PriceProIcon />
            </ImgWrapper>
            <PlanContetnWrapper>
                <HeaderWidthAuto>
                    <CurrentPlanHeader>{props.plan}</CurrentPlanHeader>
                    <CurrentPlanDetail>
                        {' '}
                        {props.CurrentPlan}
                        <Span> {props.CurrentPlanCounts}</Span>
                    </CurrentPlanDetail>
                    <TrialDate> Feb 14, 2021 - Mar 07, 2021</TrialDate>
                </HeaderWidthAuto>
                {props.upgradeButton ? (
                    <UpgradeButton>
                        <Button>{props.btnName}</Button>
                    </UpgradeButton>
                ) : null}
                {props.progressBar ? (
                    <CurrentPlanDetail>
                        {' '}
                        {props.CurrentPlan}
                        <Span> {props.CurrentPlanCounts}</Span>
                        <progress value="32" max="100">
                            {' '}
                            32%{' '}
                        </progress>
                    </CurrentPlanDetail>
                ) : null}
            </PlanContetnWrapper>
        </CurrentPlanWrapper>
    )
}
export const PricingPlanCards = (props: any) => {
    return (
        <CurrentPlanWrapper progressWidth>
            <ImgWrapper>
                <PriceProIcon />
            </ImgWrapper>
            <PlanContetnWrapper>
                <HeaderWidthAuto progressWidth>
                    <CurrentPlanHeader>{props.plan}</CurrentPlanHeader>
                    <CurrentPlanDetail className="d-flex justify-content-between mb-1">
                        {' '}
                        {props.CurrentPlan}
                        <Span>{props.CurrentPlanCounts}</Span>
                    </CurrentPlanDetail>
                    <progress value="32" max="100">
                        {' '}
                        32%{' '}
                    </progress>
                </HeaderWidthAuto>
            </PlanContetnWrapper>
        </CurrentPlanWrapper>
    )
}
const WorkflowCards = (props: any) => {
    return (
        <WorkflowWrapper>
            <CardContent>
                <WorkflowHeader>{props.name}</WorkflowHeader>
                <WorkflowCount>{props.count}</WorkflowCount>
            </CardContent>
            <CardCircle>
                <CircularProgressbar value={props.value} />
            </CardCircle>
        </WorkflowWrapper>
    )
}

export const UpgradePlan = () => {
    return (
        <UpgradePlanWrapper>
            <UpgradePlanLeft>
                <UpgradeHeader>Select Plan</UpgradeHeader>
            </UpgradePlanLeft>
            <UpgradePlanRight>
                <SubscriptionPlanChange />
                <Border />
                <Dropdown />
                <HoverUserIcon>
                    <span className="info_icon">
                        <InfoIcon />
                        <span className="side_menu_hvr">
                            Actual price may vary based on the exchange rate in place between USD and EUR at the time of
                            payment processing or invoicing. Prices exclude all taxes, levies and duties.
                        </span>
                    </span>
                </HoverUserIcon>
            </UpgradePlanRight>
        </UpgradePlanWrapper>
    )
}

export const SubscriptionPlanChange = (props: any) => {
    const [isToggled, setIsToggled] = useState(props.activeToggle)
    const onToggle = () => setIsToggled(!isToggled)

    return (
        <Toggler>
            <Toggle id="filt-monthly">Bill Monthly</Toggle>
            <ToggleSwitch>
                <CheckBox id="checkbox" type="checkbox" onChange={onToggle} checked={isToggled} />
                <Switch></Switch>
            </ToggleSwitch>
            <Toggle id="filt-hourly">Bill Yearly</Toggle>
        </Toggler>
    )
}

export const QuestionYourPlan = () => {
    return (
        <QuestionPlan>
            <WorkflowHeader>Have questions about your plan?</WorkflowHeader>
            <QuestionPlanLink>Learn about plans and pricing</QuestionPlanLink>
        </QuestionPlan>
    )
}

const Dropdown = () => {
    return (
        <DropdownWrapper>
            <Toggle>Choose Currency</Toggle>
            <DropdownUl>
                <label>
                    <img src={'https://flagcdn.com/24x18/us.png'} alt="Workflows" title="Workflows" />
                    <Span>USD $</Span>
                </label>
                <Span className="arrow_circle">
                    <DownarrowWithCircle />
                </Span>

                <DropdownLiWrapper>
                    <ul>
                        <li>
                            <label>
                                <img src={'https://flagcdn.com/24x18/us.png'} alt="Workflows" title="Workflows" />
                                <Span>IND $</Span>
                            </label>
                        </li>
                        <li>
                            <label>
                                <img src={'https://flagcdn.com/24x18/us.png'} alt="Workflows" title="Workflows" />
                                <Span>ENG $</Span>
                            </label>
                        </li>
                    </ul>
                </DropdownLiWrapper>
            </DropdownUl>
        </DropdownWrapper>
    )
}

const PricingWrapper = () => {
    return (
        <>
            <PricingSection>
                <PricingContainer>
                    {YearlyPlans.map(data => (
                        <PricingCard>
                            <PricingHeader>
                                <PricingHeaderLeft>
                                    <HeaderContent>{data.plans}</HeaderContent>
                                    <AmountContent>
                                        <sup>{data.dollar}</sup>
                                        {data.price}
                                        <sub>/month</sub>
                                    </AmountContent>
                                    <Paragraph>{data.additionalMessage}</Paragraph>
                                </PricingHeaderLeft>
                                <PricingHeaderRight>
                                    <ImgWrapper>{data.image}</ImgWrapper>
                                </PricingHeaderRight>
                            </PricingHeader>
                            <MessageWrapper>
                                {data.topMessages.map(data => (
                                    <MessageContainer>
                                        <MessageLeft>
                                            <MessageHeader>
                                                <label>
                                                    {data.category}
                                                    <label>
                                                        <span>
                                                            <InfoIcon />
                                                        </span>
                                                        <span data-tooltip={data.hoverContent}></span>
                                                    </label>
                                                </label>
                                            </MessageHeader>
                                        </MessageLeft>
                                        <MessageRight>{data.bolder}</MessageRight>
                                    </MessageContainer>
                                ))}
                                <ButtonWrapper>
                                    <Button>Select Plan </Button>
                                    <HoverUserIcon>
                                        <span className="info_icon">
                                            <AutomateInfoIcon />
                                            <span className="side_menu_hvr">Downgrade not available</span>
                                        </span>
                                    </HoverUserIcon>
                                </ButtonWrapper>
                            </MessageWrapper>

                            <ContentWrapper className={data.plans === 'Pro' ? 'two_btn_wrapper' : null}>
                                <MessageWrapper>
                                    {data.steps.map(steps => (
                                        <MessageContainer>
                                            <MessageLeft>
                                                <MessageHeader>
                                                    <label>
                                                        {steps.category}{' '}
                                                        <sup style={{ top: '-.2em', color: '#E05959' }}>*</sup>
                                                        <label>
                                                            <span>
                                                                <InfoIcon />
                                                            </span>
                                                            <span data-tooltip={steps.hoverContent}></span>
                                                        </label>
                                                    </label>
                                                </MessageHeader>
                                            </MessageLeft>
                                            <MessageRight>
                                                {steps.count && <span>{steps.icon}</span> ? (
                                                    <span>
                                                        {steps.icon}
                                                        <Count className="badge">{steps.count}</Count>
                                                    </span>
                                                ) : (
                                                    <span>{steps.icon}</span>
                                                )}
                                            </MessageRight>
                                        </MessageContainer>
                                    ))}
                                </MessageWrapper>
                            </ContentWrapper>
                        </PricingCard>
                    ))}
                    <PriceEnterpriseWrapper>
                        <PricingCard>
                            <PricingHeader>
                                <PricingHeaderLeft>
                                    <HeaderContent>Enterprise</HeaderContent>
                                    <AmountContent>{/* <sup>$</sup>-<sub>/month</sub> */}</AmountContent>
                                </PricingHeaderLeft>
                                <PricingHeaderRight>
                                    <ImgWrapper>
                                        <PriceEnterpriseIcon />
                                    </ImgWrapper>
                                </PricingHeaderRight>
                            </PricingHeader>
                            <MessageWrapper>
                                <MessageContainer>
                                    <Paragraph>
                                        <b>Looking for More</b> <br /> Enterprise plans are customised. Please reach out
                                        to us at <a href="mailto:sales@workativ.com">sales@workativ.com</a> and we’d be
                                        happy to discuss.
                                    </Paragraph>
                                    <ButtonWrapper>
                                        <button>Contact Sales</button>
                                    </ButtonWrapper>
                                </MessageContainer>
                            </MessageWrapper>
                        </PricingCard>
                    </PriceEnterpriseWrapper>
                </PricingContainer>
            </PricingSection>
            {/* <ComingSoonWrapper>
                <span style={{ color: 'red' }}>* </span> Coming soon
            </ComingSoonWrapper> */}
        </>
    )
}

export const YearlyPlans = [
    {
        plans: 'Free',
        referenceName: 'BASIC',
        type: 'Monthly',
        priority: 1,
        image: <PriceFreeIcon />,
        dollar: '$',
        price: '0',
        additionalMessage: '',
        topMessages: [
            { bolder: '1', category: 'Admin', hoverContent: 'No of admins who can work on the application' },
            {
                bolder: '1',
                category: ' App Workflows',
                hoverContent:
                    ' At a time, the number of active Workflows available for use in the Chatbot Builder. Admin will be able to disable existing and enable new Workflows as per choice. '
            },
            {
                bolder: '300',
                category: ' Faq Answers',
                hoverContent:
                    'The total number of Workflows that can be executed over one month / 30 day period by the users.'
            },
            {
                bolder: '100',
                category: ' Workflow executions',
                hoverContent:
                    'The total number of Workflows that can be executed over one month / 30 day period by the users.'
            },

            {
                bolder: '500',
                category: ' Messages',
                hoverContent:
                    'The total number of messages sent to the chatbot over a one month / 30 day period by the users.'
            }
        ],
        steps: [
            {
                category: 'Chatbot Builder',
                hoverContent: 'No-code chatbot builder tools to create and manage chatbot.',
                icon: <PricingYesIcon />
            },
            {
                category: 'Chatbot Automation',
                hoverContent: 'No-code workflow builder to create app based workflow automations for chatbot.',
                icon: <PricingYesIcon />
            },
            {
                category: 'FAQ Builder',
                hoverContent: 'No-code workflow builder to create app based workflow automations for chatbot.',
                icon: <PricingYesIcon />
            },
            {
                category: 'Single Chatbot',
                hoverContent: 'No-code workflow builder to create app based workflow automations for chatbot.',
                icon: <PricingYesIcon />
            },
            {
                category: 'App Integrations',
                hoverContent: 'Pre-built app integrations or connectors for chatbot.',
                icon: <PricingYesIcon />
            },

            {
                category: 'Marketplace',
                hoverContent: 'Pre-built app based workflows for chatbot.',
                icon: <PricingYesIcon />
            },

            {
                category: 'Integrate with Chat Channels',
                hoverContent: 'Supports multiple chatbot creation.',
                icon: <PricingNoIcon />
            },
            {
                category: 'Agent Handover',
                hoverContent:
                    'Cost of each additional bot session, and each app workflow execution over the monthly limit. ',
                icon: <PricingYesIcon />
            },
            {
                category: 'On-prem Connect',
                hoverContent: 'Connect chatbot with on-prem applications.',
                icon: <PricingNoIcon />
            },
            {
                category: 'Approval Management',
                hoverContent: 'Connect chatbot with on-prem applications.',
                icon: <PricingNoIcon />
            },
            {
                category: ' Add-On Consumptions',
                hoverContent: 'Connect chatbot with on-prem applications.',
                icon: <PricingNoIcon />
            },
            {
                category: 'Email, Help Doc Support',
                hoverContent: 'Phone support is outbound calls only. ',
                icon: <PricingYesIcon />
            }
        ]
    },
    {
        plans: 'Basic',
        referenceName: 'BASIC',
        type: 'Monthly',
        priority: 1,
        image: <BasicIcon />,
        dollar: '$',
        price: '399',
        additionalMessage: '',
        topMessages: [
            { bolder: '1', category: 'Admin', hoverContent: 'No of admins who can work on the application' },
            {
                bolder: '2',
                category: ' App Workflows',
                hoverContent:
                    ' At a time, the number of active Workflows available for use in the Chatbot Builder. Admin will be able to disable existing and enable new Workflows as per choice. '
            },
            {
                bolder: '600',
                category: ' Faq Answers',
                hoverContent:
                    'The total number of Workflows that can be executed over one month / 30 day period by the users.'
            },
            {
                bolder: '600',
                category: ' Workflow executions',
                hoverContent:
                    'The total number of Workflows that can be executed over one month / 30 day period by the users.'
            },

            {
                bolder: '3000',
                category: ' Messages',
                hoverContent:
                    'The total number of messages sent to the chatbot over a one month / 30 day period by the users.'
            }
        ],
        steps: [
            {
                category: 'Chatbot Builder',
                hoverContent: 'No-code chatbot builder tools to create and manage chatbot.',
                icon: <PricingYesIcon />
            },
            {
                category: 'Chatbot Automation',
                hoverContent: 'No-code workflow builder to create app based workflow automations for chatbot.',
                icon: <PricingYesIcon />
            },
            {
                category: 'FAQ Builder',
                hoverContent: 'No-code workflow builder to create app based workflow automations for chatbot.',
                icon: <PricingYesIcon />
            },
            {
                category: 'Single Chatbot',
                hoverContent: 'No-code workflow builder to create app based workflow automations for chatbot.',
                icon: <PricingYesIcon />
            },
            {
                category: 'App Integrations',
                hoverContent: 'Pre-built app integrations or connectors for chatbot.',
                icon: <PricingYesIcon />
            },

            {
                category: 'Marketplace',
                hoverContent: 'Pre-built app based workflows for chatbot.',
                icon: <PricingYesIcon />
            },

            {
                category: 'Integrate with Chat Channels',
                hoverContent: 'Supports multiple chatbot creation.',
                icon: <PricingNoIcon />
            },
            {
                category: 'Agent Handover',
                hoverContent:
                    'Cost of each additional bot session, and each app workflow execution over the monthly limit. ',
                icon: <PricingYesIcon />
            },
            {
                category: 'On-prem Connect',
                hoverContent: 'Connect chatbot with on-prem applications.',
                icon: <PricingNoIcon />
            },
            {
                category: 'Approval Management',
                hoverContent: 'Connect chatbot with on-prem applications.',
                icon: <PricingNoIcon />
            },
            {
                category: ' Add-On Consumptions',
                hoverContent: 'Connect chatbot with on-prem applications.',
                icon: <PricingNoIcon />
            },
            {
                category: 'Email, Help Doc Support',
                hoverContent: 'Phone support is outbound calls only. ',
                icon: <PricingYesIcon />
            }
        ]
    },
    {
        plans: 'Standard',
        priority: 2,
        referenceName: 'STANDARD',
        type: 'Monthly',
        image: <PriceStandardIcon />,
        dollar: '$',
        price: '599',
        additionalMessage: '',
        topMessages: [
            {
                bolder: '2',
                category: ' Admins',
                hoverContent: 'No of admins who can work on the application'
            },
            {
                bolder: '5',
                category: ' App Workflows',
                hoverContent:
                    ' At a time, the number of active Workflows available for use in the Chatbot Builder. Admin will be able to disable existing and enable new Workflows as per choice. '
            },
            {
                bolder: '2000',
                category: ' Faq Answers',
                hoverContent:
                    'The total number of Workflows that can be executed over one month / 30 day period by the users.'
            },
            {
                bolder: '1500',
                category: ' Workflow executions',
                hoverContent:
                    'The total number of Workflows that can be executed over one month / 30 day period by the users.'
            },

            {
                bolder: '7000',

                category: ' Messages',
                hoverContent:
                    'The total number of messages sent to the chatbot over a one month / 30 day period by the users.'
            }
        ],
        steps: [
            {
                category: 'Chatbot Builder',
                hoverContent: 'No-code chatbot builder tools to create and manage chatbot.',
                icon: <PricingYesIcon />
            },
            {
                category: 'Chatbot Automation',
                hoverContent: 'No-code workflow builder to create app based workflow automations for chatbot.',
                icon: <PricingYesIcon />
            },
            {
                category: 'FAQ Builder',
                hoverContent: 'No-code workflow builder to create app based workflow automations for chatbot.',
                icon: <PricingYesIcon />
            },
            {
                category: 'Chatbots',
                hoverContent: 'Supports multiple chatbot creation.',
                icon: <PricingYesIcon />,
                count: '2'
            },
            {
                category: 'App Integrations',
                hoverContent: 'Pre-built app integrations or connectors for chatbot.',
                icon: <PricingYesIcon />
            },

            {
                category: 'Marketplace',
                hoverContent: 'Pre-built app based workflows for chatbot.',
                icon: <PricingYesIcon />
            },

            {
                category: 'Integrate with Chat Channels',
                hoverContent: 'Supports multiple chatbot creation.',
                icon: <PricingNoIcon />
            },
            {
                category: 'Agent Handover',
                hoverContent:
                    'Cost of each additional bot session, and each app workflow execution over the monthly limit. ',
                icon: <PricingYesIcon />
            },
            {
                category: 'On-prem Connect',
                hoverContent: 'Connect chatbot with on-prem applications.',
                icon: <PricingNoIcon />
            },
            {
                category: 'Approval Management',
                hoverContent: 'Connect chatbot with on-prem applications.',
                icon: <PricingNoIcon />
            },
            {
                category: ' Add-On Consumptions',
                hoverContent: 'Connect chatbot with on-prem applications.',
                icon: <PricingNoIcon />
            },
            {
                category: 'Email, Help Doc Support',
                hoverContent: 'Phone support is outbound calls only. ',
                icon: <PricingYesIcon />
            }
        ]
    },
    {
        plans: 'Pro',
        priority: 3,
        referenceName: 'PRO',
        type: 'Monthly',
        image: <PriceProIcon />,
        dollar: '$',
        price: '899',
        additionalMessage: '',

        topMessages: [
            {
                bolder: '3',
                category: ' Admins',
                hoverContent: 'No of admins who can work on the application'
            },
            {
                bolder: '8',
                category: ' App Workflows',
                hoverContent:
                    ' At a time, the number of active Workflows available for use in the Chatbot Builder. Admin will be able to disable existing and enable new Workflows as per choice. '
            },
            {
                bolder: '5000',
                category: ' Faq Answers',
                hoverContent:
                    'The total number of Workflows that can be executed over one month / 30 day period by the users.'
            },

            {
                bolder: '3000',
                category: ' Workflow executions',
                hoverContent:
                    'The total number of Workflows that can be executed over one month / 30 day period by the users.'
            },

            {
                bolder: '17000',

                category: ' Messages',
                hoverContent:
                    'The total number of messages sent to the chatbot over a one month / 30 day period by the users.'
            }
        ],
        steps: [
            {
                category: 'Chatbot Builder',
                hoverContent: 'No-code chatbot builder tools to create and manage chatbot.',
                icon: <PricingYesIcon />
            },
            {
                category: 'Chatbot Automation',
                hoverContent: 'No-code workflow builder to create app based workflow automations for chatbot.',
                icon: <PricingYesIcon />
            },
            {
                category: 'FAQ Builder',
                hoverContent: 'No-code workflow builder to create app based workflow automations for chatbot.',
                icon: <PricingYesIcon />
            },
            {
                category: 'Chatbots',
                hoverContent: 'Supports multiple chatbot creation.',
                icon: <PricingYesIcon />,
                count: '2'
            },
            {
                category: 'App Integrations',
                hoverContent: 'Pre-built app integrations or connectors for chatbot.',
                icon: <PricingYesIcon />
            },

            {
                category: 'Marketplace',
                hoverContent: 'Pre-built app based workflows for chatbot.',
                icon: <PricingYesIcon />
            },

            {
                category: 'Integrate with Chat Channels',
                hoverContent: 'Supports multiple chatbot creation.',
                icon: <PricingNoIcon />
            },
            {
                category: 'Agent Handover',
                hoverContent:
                    'Cost of each additional bot session, and each app workflow execution over the monthly limit. ',
                icon: <PricingYesIcon />
            },
            {
                category: 'On-prem Connect',
                hoverContent: 'Connect chatbot with on-prem applications.',
                icon: <PricingNoIcon />
            },
            {
                category: 'Approval Management',
                hoverContent: 'Connect chatbot with on-prem applications.',
                icon: <PricingNoIcon />
            },
            {
                category: ' Add-On Consumptions',
                hoverContent: 'Connect chatbot with on-prem applications.',
                icon: <PricingNoIcon />
            },
            {
                category: 'Email, Help Doc Support',
                hoverContent: 'Phone support is outbound calls only. ',
                icon: <PricingYesIcon />
            }
        ]
    }
]
const AlertWrapper = (props: any) => {
    return (
        <Information>
            {props.alertWrapper ? (
                <InformationContent alertColor>
                    <HoverUserIcon>
                        <span className="info_icon">
                            <InformationIcon />
                        </span>
                        <label className="m-0">
                            “Your payment is past due date. Please pay before{' '}
                            <label className="content_medium m-0"> 21 March 2021 </label> to avoid account suspension”
                        </label>
                        <label className="side_menu_hvr">
                            <label>
                                If you don’t resolve the payment by{' '}
                                <label className="content_medium"> 21 March 2021 </label>, your account will enter our
                                suspension process. Suspension means, you will no longer have access to workativ chatbot
                                services, preventing you from managing existing or creating new chatbots. While
                                suspended, you will still have access to billing page to make your due payment.
                            </label>
                        </label>

                        <Button bgColor>Pay Now</Button>
                    </HoverUserIcon>
                </InformationContent>
            ) : (
                <InformationContent>
                    <HoverUserIcon>
                        <span className="info_icon">
                            <InformationIcon />
                        </span>
                        <label className="m-0">There was an error processing your credit card for payment.</label>
                        <span className="side_menu_hvr">
                            <label>
                                Please update your credit card or make your payment manually (by clicking pay now)
                                before <label className="content_medium">21 March 2021</label>
                            </label>
                        </span>
                        <Button bgColor>Pay Now</Button>
                    </HoverUserIcon>
                </InformationContent>
            )}
        </Information>
    )
}
