/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react'

import { AddIcon, CheckIcon, CrossIcon, DeleteIcon, EditIcon } from '@/common/Icons/Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { Loader } from '@/common/components/Loader'
import lazyGA from '@/common/google-analytics/tracking'

import { createTextResponseDialog, updateDailouge } from '../../Actions/AsyncOperations'
import { PageContext } from '../../Actions/ReactContext'
import { PageView, TextResponseDialogPT } from '../../Types/PageT'
import { ErrorT } from '../../Types/Types'
import {
    AskQueryDialogT,
    JumpToSelectorT,
    RecognizeDialogT,
    SlotContainerDialogT,
    TextResponseDialogT
} from '../../Types/WatsonT'
import { Button } from '../Common'
import { DraftContent, metaParser } from '../DraftContent'

const Ramda = require('ramda')

export const TextResponse = () => {
    const [loading, setLoading] = useState<boolean>(false)

    const [alreadyOpen, setAlreadyOpen] = useState<boolean>(false)

    const [error, setError] = useState<ErrorT>({ error: false, info: '' })

    const { workspace, setPageState, PageState, fetchCallback } = useContext(PageContext)

    const { input } = PageState as TextResponseDialogPT

    const [valuesMeta, setValuesMeta] = useState<any[]>(input.metadata.RICH_TEXT)

    const [selectionPolicy, setSelectionPolicy] = useState<'sequential' | 'random'>(input.selection_policy)

    const handleSave = async () => {
        setLoading(true)
        try {
            const { input, nextSibling, previousSibling, parent } = PageState as TextResponseDialogPT

            if (input.dialog_node == '') {
                const response: TextResponseDialogT = await createTextResponseDialog(workspace, {
                    type: 'text-response',
                    selection_policy: selectionPolicy,
                    values: valuesMeta.map(data => metaParser(data)),
                    parent: input.parent,
                    previous_sibling: input.previous_sibling,
                    metadata: { RICH_TEXT: valuesMeta }
                } as TextResponseDialogT)

                const result: TextResponseDialogT = await updateDailouge(workspace, {
                    ...response,
                    metadata: { ...input.metadata, RICH_TEXT: valuesMeta }
                } as TextResponseDialogT)

                if (previousSibling) {
                    updateDailouge(workspace, {
                        ...previousSibling,
                        type: previousSibling.type,
                        jump_to: {
                            dialog_node: result.dialog_node,
                            selector: JumpToSelectorT.body
                        },
                        metadata: { ...previousSibling.metadata, hide_jump_to: true }
                    } as TextResponseDialogT | AskQueryDialogT | SlotContainerDialogT)
                } else if (parent) {
                    updateDailouge(workspace, {
                        ...parent,
                        type: parent.type,
                        jump_to: {
                            dialog_node: result.dialog_node,
                            selector: JumpToSelectorT.body
                        },
                        metadata: { ...parent.metadata, hide_jump_to: true }
                    } as RecognizeDialogT)
                }

                if (nextSibling) {
                    if (nextSibling.type == 'if') {
                        updateDailouge(workspace, {
                            ...result,
                            type: result.type,
                            jump_to: {
                                dialog_node: nextSibling.dialog_node,
                                selector: JumpToSelectorT.user_input
                            },
                            metadata: { ...result.metadata, hide_jump_to: true }
                        } as TextResponseDialogT)
                    } else {
                        updateDailouge(workspace, {
                            ...result,
                            type: result.type,
                            jump_to: {
                                dialog_node: nextSibling.dialog_node,
                                selector: JumpToSelectorT.body
                            },
                            metadata: { ...result.metadata, hide_jump_to: true }
                        } as TextResponseDialogT)
                    }
                }
                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Send Message Node Created'
                })
            } else {
                updateDailouge(workspace, {
                    ...input,
                    selection_policy: selectionPolicy,
                    values: valuesMeta.map(data => metaParser(data)),
                    metadata: { ...input.metadata, RICH_TEXT: valuesMeta }
                } as TextResponseDialogT)

                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Send Message Node Updated'
                })
            }
            fetchCallback(() => setLoading(false))
        } catch (error) {
            setLoading(false)
            console.error(error)
            setError({ error: true, info: 'Something went wrong. Please try again!' })
        }
    }

    const countSet = (count: number) => (count < 10 ? '0' + count.toString() : count)

    return (
        <div className="right-panel slots_panel">
            <ErrorBoundary
                error={error}
                render={(err: any, info: any) => {
                    return err && <Error.Delete onClick={() => setPageState(PageView)} info={info} />
                }}
            >
                <h5>Send a Message</h5>
                <p>Make your chatbot send a text message to the user</p>
                {loading ? (
                    <div className="popup_loader">
                        <Loader.PopupLoader show={loading} />
                    </div>
                ) : (
                    <React.Fragment>
                        <div className="right_panel_ht">
                            <div className="right_panel_radio">
                                <div className="col-md-12 entity_radio">
                                    <div className="radio">
                                        <label>
                                            <input
                                                type="radio"
                                                className="radio-warning"
                                                onChange={() => {
                                                    setSelectionPolicy('sequential')
                                                }}
                                                checked={selectionPolicy == 'sequential'}
                                            />
                                        </label>
                                        <span>Display sequentially</span>
                                    </div>
                                    <div className="radio">
                                        <label>
                                            <input
                                                type="radio"
                                                onChange={() => {
                                                    setSelectionPolicy('random')
                                                }}
                                                checked={selectionPolicy == 'random'}
                                            />
                                        </label>
                                        <span>Display randomly</span>
                                    </div>
                                </div>
                            </div>

                            {valuesMeta.map((text: any, i: number) => (
                                <TextResponseCard
                                    key={i}
                                    index={countSet(i + 1)}
                                    values={Ramda.clone(valuesMeta)}
                                    value={Ramda.clone(text)}
                                    onchange={(newValue: string) =>
                                        setValuesMeta(values =>
                                            values.map((value: string, index: number) =>
                                                index == i ? newValue : value
                                            )
                                        )
                                    }
                                    deleteValue={() => {
                                        const data = valuesMeta.reduce((accum, val, index) => {
                                            if (index === i) {
                                                return accum
                                            } else {
                                                return accum.concat(val)
                                            }
                                        }, [])

                                        setValuesMeta(Ramda.clone(data))
                                    }}
                                    alreadyOpen={alreadyOpen}
                                    setAlreadyOpen={setAlreadyOpen}
                                />
                            ))}
                            {valuesMeta.filter(data => metaParser(data) == '').length == 0 &&
                            valuesMeta.length < 100 &&
                            alreadyOpen == false ? (
                                <div
                                    className="send_message_addmore"
                                    // className={
                                    //     valuesMeta.filter(data => metaParser(data) == '').length > 0
                                    //         ? ' text_response hide_create_card'
                                    //         : 'text_response'
                                    // }
                                >
                                    <div
                                        className="plus_add_more"
                                        onClick={() =>
                                            setValuesMeta(values =>
                                                values.concat({
                                                    blocks: [
                                                        {
                                                            text: '',
                                                            type: 'unstyled'
                                                        }
                                                    ],
                                                    entityMap: {}
                                                })
                                            )
                                        }
                                    >
                                        <h4>
                                            <div className="parent_node_no">
                                                <span className="parent_svg">
                                                    <AddIcon />
                                                </span>
                                            </div>
                                        </h4>
                                        <p>Add More</p>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <Button
                            disable={
                                valuesMeta.filter(data => metaParser(data) != '').length != valuesMeta.length ||
                                alreadyOpen
                            }
                            onCancel={() => setPageState(PageView)}
                            onSave={handleSave}
                        />
                    </React.Fragment>
                )}
            </ErrorBoundary>
        </div>
    )
}

const TextResponseCard = ({ value, values, index, deleteValue, onchange, alreadyOpen, setAlreadyOpen }: any) => {
    const [mode, setMode] = useState<'view' | 'edit'>('view')
    const [initValue, setInitValue] = useState(null)

    const [kaduppu, setKaduppu] = useState(true)

    useEffect(() => {
        if (metaParser(value) == '') {
            setMode('edit')
            setAlreadyOpen(true)
        }
        setInitValue(value)
    }, [])

    return (
        <div className="text_response">
            {mode == 'view' ? (
                <div className="plus_addmore plus_addmore_save">
                    <div className="plus_add_more">
                        <h4>
                            <div className="parent_node_no">
                                <span className="parent_svg">
                                    <p>{index}</p>
                                </span>
                            </div>
                        </h4>
                        <div className="right_pop_icons">
                            {values.length > 1 ? (
                                <span
                                    onClick={() => {
                                        setKaduppu(false)
                                        deleteValue()
                                        setTimeout(() => {
                                            setKaduppu(true)
                                        })
                                    }}
                                >
                                    <DeleteIcon />
                                </span>
                            ) : null}
                            <span
                                onClick={() => {
                                    if (!alreadyOpen) {
                                        setMode('edit')
                                        setAlreadyOpen(true)
                                    } else {
                                        console.log('already open some input box')
                                    }
                                }}
                            >
                                <EditIcon />
                            </span>
                        </div>
                    </div>
                    <p> {kaduppu ? <DraftContent rawMeta={Ramda.clone(value)} setRawMeta={() => {}} /> : null}</p>
                </div>
            ) : (
                <div className="plus_addmore plus_addmore_edit">
                    <div className="plus_add_more">
                        <h4>
                            <div className="parent_node_no">
                                <span className="parent_svg">
                                    <p>{index}</p>
                                </span>
                            </div>
                        </h4>
                        <div className="right_pop_icons">
                            <span
                                onClick={() => {
                                    setMode('view')
                                    setAlreadyOpen(false)
                                    setInitValue(value)
                                }}
                            >
                                <CheckIcon />
                            </span>
                            <span
                                onClick={() => {
                                    setMode('view')
                                    setAlreadyOpen(false)
                                    onchange(initValue)
                                }}
                            >
                                <CrossIcon />
                            </span>
                        </div>
                    </div>
                    <div className="right_pop_content">
                        <div className="textarea_dropdown">
                            <div className="send_msg_dialog">
                                <DraftContent rawMeta={Ramda.clone(value)} setRawMeta={onchange} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
