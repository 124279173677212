import { RecognizeDialogT, JumpToSelectorT } from './WatsonT'

export type AutomationT = {
    id: string
    name: string
    description: string
    deployed_status: string
    active_status: boolean
    modified_date: string
    group: string
}

// -----------------------------------------------------------------------------------------

//MOCK DATA'S
export const MOCK_AUTOMATION: AutomationT = {
    id: '',
    name: '',
    description: '',
    deployed_status: '',
    active_status: false,
    modified_date: '',
    group: ''
}

export const MOCK_IF_NODE: RecognizeDialogT = {
    type: 'if',
    title: '',
    conditions: '',
    parent: null,
    previous_sibling: null,
    dialog_node: '',
    children: [],
    metadata: { hide_jump_to: false, RICH_TEXT: [] },
    jump_to: {
        dialog_node: 'fallback',
        selector: JumpToSelectorT.user_input
    },
    context: {},
    created: 'created',
    updated: 'updated'
}

// -----------------------------------------------------------------------------------------

// ERROR STATE START
export type ErrorT = {
    error: boolean
    info: String
}

export const ErrorState: ErrorT = {
    error: false,
    info: ''
}

// ERROR STATE END

// -----------------------------------------------------------------------------------------
