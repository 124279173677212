// React common import's
/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react'
/*eslint-disable*/
import ReactTooltip from 'react-tooltip'

import { AddIcon, CheckIcon, CloseIcon, DownArrowCircle, MinusIcon, NodeHoverMenuIcons } from '@/common/Icons/Icons'
import { LoadingSvgImage } from '@/common/Icons/LoadingSvgImage'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
// Loader import
import { Loader } from '@/common/components/Loader'
import lazyGA from '@/common/google-analytics/tracking'
import {
    Input,
    InputContainer,
    InputContent,
    InputWrapper,
    SmallButton
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb'

import { ExistT as EntityT, MOCK_ENTITY } from '../../../Entity/types'
import { UpdateIntent, createIntent, createRecogniseDialog, updateDailouge } from '../../Actions/AsyncOperations'
// common function's import
//Page Context
import { PageContext } from '../../Actions/ReactContext'
//Page type's import
import { PageView, RecognizeDialogPT } from '../../Types/PageT'
import {
    JumpToSelectorT,
    NewRecognizeDialogT,
    OptionsResponseDialogT,
    RecognizeDialogT,
    TextResponseDialogT
} from '../../Types/WatsonT'
// common component's and icon's import
import { Button, DropDown } from '../Common'
import { DraftContent, SelectContextVariable } from '../DraftContent'

const Ramda = require('ramda')

const DEFAULT_ENTITIES: string[] = [
    'cognidesk-email',
    'cognidesk-mobilenumber',
    'cognidesk-ticketid',
    'sys-currency',
    'sys-date',
    'sys-time'
]
interface DialogNameComponentP {
    dialogName: string
    setDialogName: Function
}

const DialogNameComponent = ({ dialogName, setDialogName }: DialogNameComponentP) => {
    const [insertKey, setInsertKey] = useState(13)
    let title: any
    useEffect(() => {
        title.focus()
    }, [])
    return (
        <div className="test_buton_result">
            <div className="test_buton_view">
                <div className="automation_form_req automation_form_req_step_two">
                    <div className="automation_condition_input">
                        <h4>Dialog name</h4>
                    </div>
                </div>
                <div className="automation_condition_form">
                    <div className="select_work_form">
                        <input
                            type="text"
                            value={dialogName}
                            onKeyDown={e => setInsertKey(e.keyCode)}
                            onChange={e => {
                                if (
                                    (insertKey > 64 && insertKey < 91) ||
                                    (insertKey > 47 && insertKey < 58) ||
                                    insertKey == 32 ||
                                    insertKey == 8
                                ) {
                                    setDialogName(e.target.value)
                                }
                            }}
                            ref={el => {
                                title = el
                            }}
                        />
                        {dialogName.length > 26 ? <p className="error_msg">Text limit is Exceeded</p> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

interface CombinatorComponentP {
    condition: combinatorConditionT
    onChange: Function
}

const CombinatorCompoenent = ({ condition, onChange }: CombinatorComponentP) => (
    <div className="condition_trigger">
        <div
            className="radio"
            onClick={e =>
                onChange({
                    type: conditionTYPES.COMBINATOR,
                    value: combinatorValueT.AND
                })
            }
        >
            <label>
                <input
                    type="radio"
                    className="radio-warning"
                    checked={condition.value == combinatorValueT.AND}
                    onChange={() => {}}
                />
            </label>
            <span>And</span>
        </div>
        <div
            className="radio"
            onClick={e =>
                onChange({
                    type: conditionTYPES.COMBINATOR,
                    value: combinatorValueT.OR
                })
            }
        >
            <label>
                <input type="radio" checked={condition.value == combinatorValueT.OR} onChange={() => {}} />
            </label>
            <span>Or</span>
        </div>
    </div>
)

const IntentCompoenent = ({ condition, onChange }: any) => {
    const [chooser, setChooser] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('')
    const [keycode, setKeycode] = useState<number>(13)
    const [addExample, setAddExample] = useState<boolean>(false)
    const [examples, setExamples] = useState<string[]>([])
    const pageState = useContext(PageContext)
    const [loading, setLoading] = useState<boolean>(false)

    const addIntent = () => {
        setLoading(true)
        createIntent(pageState.workspace, { intent: search, description: '' }).then((intentdata: any) => {
            UpdateIntent(pageState.workspace, {
                ...intentdata,
                examples: examples
                    .filter(e => e != '')
                    .map(e => ({
                        text: e
                    }))
            }).then(intentExample => {
                setAddExample(false)
                pageState.setIntents((data: any[]) => data.concat(intentExample))
                setChooser(false)
                onChange({
                    ...condition,
                    name: search,
                    operator: operatorsT.IS_PRESENT
                })
                setLoading(false)
            })
        })
    }

    const findDublicate = (data: string[]) => {
        // examples.filter(e => e.trim() == '').length == 0 &&

        if (data.filter(e => e.trim() == '').length != 0) {
            return false
        } else if (
            data.map(e => examples.filter(event => e.trim() == event.trim()).length == 1).filter(e => e == true)
                .length != data.length
        ) {
            return false
        }
        return true
    }
    return (
        <PageContext.Consumer>
            {({ intents }) => (
                <div className="automation_condition_form dialog_recognise_dialog">
                    <div className="select_work_form">
                        <InputWrapper>
                            <InputContainer>
                                <Input
                                    type="text"
                                    value={chooser ? search : condition.name}
                                    onKeyDown={(e: any) => setKeycode(e.keyCode)}
                                    onChange={(e: any) => {
                                        if (keycode != 32) {
                                            setSearch(e.target.value)
                                        }
                                    }}
                                    onClick={(e: any) => {
                                        if (chooser == false) {
                                            setSearch(condition.name)
                                        }
                                        setChooser(true)
                                    }}
                                />
                                <InputContent>Search Intent</InputContent>
                            </InputContainer>
                        </InputWrapper>

                        <DropDown isOpen={chooser} onOuterClick={() => setChooser(false)}>
                            <div className="automation_scroly">
                                <ul>
                                    {intents
                                        .filter(e => e.intent.toLowerCase().includes(search.toLowerCase()))
                                        .map((intent, index) => (
                                            <li
                                                key={index}
                                                onClick={e => {
                                                    setChooser(false)
                                                    onChange({
                                                        ...condition,
                                                        name: intent.intent,
                                                        operator: operatorsT.IS_PRESENT
                                                    })
                                                }}
                                            >
                                                {intent.intent}
                                            </li>
                                        ))}
                                    {intents.filter(e => e.intent.toLowerCase().includes(search.toLowerCase()))
                                        .length == 0 ? (
                                        <li
                                            onClick={e => {
                                                if (search != '') {
                                                    setChooser(false)
                                                    setAddExample(true)
                                                    setExamples([''])
                                                }
                                            }}
                                        >
                                            {search == ''
                                                ? intents.length == 0
                                                    ? 'No intent found, type to create new intent'
                                                    : `Create an intent called '${search}'`
                                                : `Create an intent called '${search}'`}
                                        </li>
                                    ) : null}
                                </ul>
                            </div>
                        </DropDown>

                        <div className="arrow_up_form">
                            <DownArrowCircle />
                        </div>

                        {addExample ? (
                            <div className="dialog_scroll">
                                <p>
                                    <span className="dialog_add_examples_left">{search}</span>
                                    {loading ? (
                                        <span className="dialog_add_span_svg connect_account_loading">
                                            <img src={LoadingSvgImage} />
                                        </span>
                                    ) : (
                                        <>
                                            {findDublicate(examples) ? (
                                                <span className="dialog_add_span_svg" onClick={e => addIntent()}>
                                                    <CheckIcon />
                                                </span>
                                            ) : null}

                                            <span className="dialog_add_span_svg" onClick={e => setAddExample(false)}>
                                                <CloseIcon />
                                            </span>
                                        </>
                                    )}
                                </p>
                                <div className="dialog_add_examples">{`Add Examples for '${search}'`}</div>

                                {examples.map((value, count) => (
                                    <div className="dialog_scroll_div" key={count}>
                                        <input
                                            type="text"
                                            placeholder="Type here"
                                            value={value}
                                            onChange={event => {
                                                const string = event.target.value
                                                setExamples(data =>
                                                    data.map((val, ind) => (ind == count ? string : val))
                                                )
                                            }}
                                        />
                                        {examples.filter((e, i) => i != count).filter(e => e.trim() == value.trim())
                                            .length > 0 ? (
                                            <div className="hover_dashboard_content" data-tip data-for="sadFace">
                                                <span className="error_dublicate_entry">
                                                    <svg x="0px" y="0px" viewBox="0 0 483.5 483.5" xmlSpace="preserve">
                                                        <path d="M480 425L269.1 29.9c-5.3-9.9-15.6-16.1-26.8-16.1-11.2 0-21.5 6.2-26.8 16.1L3.6 425c-5 9.5-4.8 20.9.7 30 5.5 9.1 15.4 14.8 26.1 14.8h422.7c10.7 0 20.6-5.6 26.1-14.8 5.5-9.2 5.8-20.5.8-30zm-237.8-16c-16.8 0-30.4-13.6-30.4-30.4s13.6-30.4 30.4-30.4c16.8 0 30.4 13.6 30.4 30.4S259 409 242.2 409zm30.5-121.1c0 16.8-13.6 30.4-30.4 30.4-16.8 0-30.4-13.6-30.4-30.4V166.3c0-16.8 13.6-30.4 30.4-30.4 16.8 0 30.4 13.6 30.4 30.4v121.6z" />
                                                    </svg>
                                                </span>
                                                <ReactTooltip
                                                    className="error_dublicate_entry_hover"
                                                    id="sadFace"
                                                    type="warning"
                                                    effect="solid"
                                                >
                                                    Duplicate Entry Found
                                                </ReactTooltip>
                                            </div>
                                        ) : null}

                                        {examples.length != 1 ? (
                                            <span
                                                onClick={e =>
                                                    setExamples(data => data.filter((val, ind) => ind != count))
                                                }
                                            >
                                                <MinusIcon />
                                            </span>
                                        ) : null}
                                    </div>
                                ))}
                                {examples.length < 5 && examples.filter(e => e.trim() == '').length == 0 ? (
                                    <div className="plus_addmore">
                                        <div
                                            className="plus_add_more"
                                            onClick={e => setExamples(data => data.concat(''))}
                                        >
                                            <h4>
                                                <div className="parent_node_no">
                                                    <span className="parent_svg">
                                                        <AddIcon />
                                                    </span>
                                                </div>
                                                Add More
                                            </h4>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                    {condition.name != '' ? (
                        <div className="automation_condition_form">
                            <div className="select_work_form">
                                <input
                                    type="text"
                                    value={`is recognized`}
                                    onChange={() => {}}
                                    style={{ pointerEvents: 'none' }}
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
            )}
        </PageContext.Consumer>
    )
}

const EntityCompoenent = ({ condition, onChange }: any) => {
    const [entityChooser, setEntitChooser] = useState<boolean>(false)
    const [conditionChooser, setConditionChooser] = useState<boolean>(false)
    const [valueChooser, setValueChooser] = useState<boolean>(false)
    const [currentEntity, setCurrentEntity] = useState<EntityT>(MOCK_ENTITY)

    const { entities } = useContext(PageContext)

    useEffect(() => {
        if (condition.name != '') {
            const entity = entities.filter(e => e.entity == condition.name.trim()).pop()
            if (entity) {
                setCurrentEntity(entity)
            } else {
                onChange({
                    ...condition,
                    name: '',
                    value: ''
                })
            }
        }
    }, [condition])
    return (
        <PageContext.Consumer>
            {({ entities }) => (
                <React.Fragment>
                    <div className="automation_condition_form">
                        <div className="select_work_form">
                            <input
                                type="text"
                                value={condition.name}
                                placeholder={'Select Entity'}
                                onChange={() => {}}
                                onClick={e => setEntitChooser(true)}
                            />
                            {entityChooser ? (
                                <DropDown isOpen={entityChooser} onOuterClick={() => setEntitChooser(false)}>
                                    <div className="automation_scroly">
                                        <ul>
                                            {entities.map((entity, index) => (
                                                <li
                                                    key={index}
                                                    onClick={e => {
                                                        setCurrentEntity(entity)
                                                        setEntitChooser(false)
                                                        onChange({
                                                            ...condition,
                                                            name: entity.entity,
                                                            operator: operatorsT.SELECT,
                                                            value: ''
                                                        })
                                                    }}
                                                >
                                                    {entity.entity}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </DropDown>
                            ) : null}
                            <div className="arrow_up_form">
                                <DownArrowCircle />
                            </div>
                        </div>
                    </div>
                    {condition.name != '' ? (
                        <React.Fragment>
                            {/* condition area */}
                            <div className="automation_condition_form">
                                <div className="select_work_form">
                                    <input
                                        type="text"
                                        value={
                                            condition.operator == operatorsT.IS
                                                ? 'value is'
                                                : condition.operator == operatorsT.IS_NOT
                                                ? 'value is not'
                                                : condition.operator == operatorsT.IS_PRESENT
                                                ? 'is recognized'
                                                : condition.operator == operatorsT.IS_NOT_PRESENT
                                                ? 'is not recognized'
                                                : ''
                                        }
                                        placeholder={'Select Logic'}
                                        onChange={() => {}}
                                        onClick={e => setConditionChooser(true)}
                                    />
                                    {conditionChooser ? (
                                        <DropDown
                                            isOpen={conditionChooser}
                                            onOuterClick={() => setConditionChooser(false)}
                                        >
                                            <div className="automation_scroly">
                                                <ul>
                                                    <li
                                                        onClick={e => {
                                                            setConditionChooser(false)
                                                            onChange({
                                                                ...condition,
                                                                operator: operatorsT.IS_PRESENT,
                                                                value: ''
                                                            })
                                                        }}
                                                    >
                                                        {'is recognized'}
                                                    </li>
                                                    {DEFAULT_ENTITIES.find(e => e == condition.name.trim()) ==
                                                    undefined ? (
                                                        <>
                                                            <li
                                                                onClick={e => {
                                                                    setConditionChooser(false)
                                                                    onChange({
                                                                        ...condition,
                                                                        operator: operatorsT.IS,
                                                                        value: ''
                                                                    })
                                                                }}
                                                            >
                                                                {'value is'}
                                                            </li>
                                                            <li
                                                                onClick={e => {
                                                                    setConditionChooser(false)
                                                                    onChange({
                                                                        ...condition,
                                                                        operator: operatorsT.IS_NOT,
                                                                        value: ''
                                                                    })
                                                                }}
                                                            >
                                                                {'value is not'}
                                                            </li>{' '}
                                                        </>
                                                    ) : null}
                                                </ul>
                                            </div>
                                        </DropDown>
                                    ) : null}
                                    <div className="arrow_up_form">
                                        <DownArrowCircle />
                                    </div>
                                </div>
                            </div>
                            {/* value area */}
                            {condition.operator != operatorsT.SELECT &&
                            condition.operator != operatorsT.IS_NOT_PRESENT &&
                            condition.operator != operatorsT.IS_PRESENT ? (
                                <div className="automation_condition_form">
                                    <div className="select_work_form">
                                        <input
                                            type="text"
                                            value={condition.value}
                                            placeholder={'Select Value'}
                                            onChange={() => {}}
                                            onClick={e => setValueChooser(true)}
                                        />
                                        {valueChooser ? (
                                            <DropDown isOpen={valueChooser} onOuterClick={() => setValueChooser(false)}>
                                                <div className="automation_scroly">
                                                    <ul>
                                                        {currentEntity.values.map((value: any, index: number) => (
                                                            <li
                                                                key={index}
                                                                onClick={e => {
                                                                    setValueChooser(false)
                                                                    onChange({
                                                                        ...condition,
                                                                        value: value.value
                                                                    })
                                                                }}
                                                            >
                                                                {value.value}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </DropDown>
                                        ) : null}
                                        <div className="arrow_up_form">
                                            <DownArrowCircle />
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </React.Fragment>
                    ) : null}
                </React.Fragment>
            )}
        </PageContext.Consumer>
    )
}

export enum operatorsT {
    IS = 'IS', //entity only
    IS_NOT = 'IS_NOT', //entity only

    IS_PRESENT = 'IS_PRESENT', //entity and intent and context
    IS_NOT_PRESENT = 'IS_NOT_PRESENT', //entity and intent and context

    EQUALS_TEXT = 'EQUALS_TEXT', //context only
    DOES_NOT_EQUALS_TEXT = 'DOES_NOT_EQUALS_TEXT', //context only
    GREATER_THAN = 'GREATER_THAN', //context only
    LESS_THAN = 'LESS_THAN', //context only
    IS_TRUE = 'IS_TRUE', //context only
    IS_FALSE = 'IS_FALSE', //context only
    EQUALS_NUMBER = 'EQUALS_NUMBER', //context only
    DOES_NOT_EQUALS_NUMBER = 'DOES_NOT_EQUALS_NUMBER', //context only

    SELECT = 'SELECT'
}

export enum conditionTYPES {
    SELECT = 'Select Type',
    INTENT = 'Intent',
    ENTITY = 'Entity',
    CONTEXT = 'Variable',
    COMBINATOR = 'COMBINATOR'
}

enum combinatorValueT {
    AND = 'AND',
    OR = 'OR'
}

export interface combinatorConditionT {
    type: conditionTYPES.COMBINATOR
    value: combinatorValueT
}
export interface selectConditionT {
    type: conditionTYPES.SELECT
}

export interface intentConditionT {
    type: conditionTYPES.INTENT
    name: string
    operator: operatorsT
}

export interface entityConditionT {
    type: conditionTYPES.ENTITY
    name: string
    operator: operatorsT
    value: string
}

export interface variableConditionT {
    type: conditionTYPES.CONTEXT
    LHS: string
    LHS_META: any
    operator: operatorsT
    RHS: string
    RHS_META: any
}

export type conditionT =
    | selectConditionT
    | intentConditionT
    | combinatorConditionT
    | entityConditionT
    | variableConditionT

export const RecogniseDialog = () => {
    const { workspace, PageState, setPageState, fetchCallback } = useContext(PageContext)

    const [dialogName, setDialogName] = useState<string>('')

    const [loading, setLoading] = useState<boolean>(false)

    const [error, setError] = useState<any>({ error: null, info: null })

    const [conditions, setConditions] = useState<conditionT[]>([])

    const onChange = (index: number, condition: any) =>
        setConditions(element => element.map((e, i) => (i == index ? condition : e)))

    const remove = (index: number) =>
        setConditions(condition =>
            condition.filter((e: any, i: number) => {
                const conditionIndex = index == 0 ? index + 1 : index - 1
                if (i != index && i != conditionIndex) return e
                return false
            })
        )
    const addMore = () =>
        setConditions(condition =>
            condition.concat([
                {
                    type: conditionTYPES.COMBINATOR,
                    value: combinatorValueT.AND
                },
                {
                    type: conditionTYPES.SELECT
                }
            ])
        )

    const fetchOperators = (operator: operatorsT, type: conditionTYPES) => {
        if (type == conditionTYPES.ENTITY) {
            if (operator == operatorsT.IS || operator == operatorsT.IS_NOT) {
                return ':'
            }
        } else if (type == conditionTYPES.CONTEXT) {
        }
        return ''
    }

    const mergeContextVariable = (data: variableConditionT): string => {
        if (data.operator == operatorsT.IS_TRUE) {
            return `${data.LHS_META.value} == true`
        } else if (data.operator == operatorsT.IS_FALSE) {
            return `${data.LHS_META.value}  == false`
        } else if (data.operator == operatorsT.GREATER_THAN) {
            return `${data.LHS_META.value}.toDouble() > ${data.RHS}.toDouble()`
        } else if (data.operator == operatorsT.LESS_THAN) {
            return `${data.LHS_META.value}.toDouble() < ${data.RHS}.toDouble()`
        } else if (data.operator == operatorsT.EQUALS_NUMBER) {
            return `${data.LHS_META.value}.toDouble() == ${data.RHS}.toDouble()`
        } else if (data.operator == operatorsT.DOES_NOT_EQUALS_NUMBER) {
            return `${data.LHS_META.value}.toDouble() != ${data.RHS}.toDouble()`
        } else if (data.operator == operatorsT.IS_PRESENT) {
            return `${data.LHS_META.value}`
        } else if (data.operator == operatorsT.IS_NOT_PRESENT) {
            return `!${data.LHS_META.value}`
        } else if (data.operator == operatorsT.EQUALS_TEXT) {
            return `${data.LHS_META.value} == ${data.RHS}`
        } else if (data.operator == operatorsT.DOES_NOT_EQUALS_TEXT) {
            return `${data.LHS_META.value} != ${data.RHS}`
        }
        return ''
    }

    const convertWatsonString = (data: conditionT[]) =>
        data.reduce((accumulator: string, value: conditionT) => {
            if (value.type == conditionTYPES.INTENT) {
                return accumulator.concat(`${value.operator == operatorsT.IS_PRESENT ? '' : '!'}#${value.name}`.trim())
            } else if (value.type == conditionTYPES.ENTITY) {
                return accumulator.concat(
                    `${value.operator == operatorsT.IS_NOT ? '!' : ''}${
                        value.operator == operatorsT.IS_NOT_PRESENT ? '!' : ''
                    }@${value.name}${fetchOperators(value.operator, value.type)}${value.value}`.trim()
                )
            } else if (value.type == conditionTYPES.COMBINATOR) {
                return accumulator.concat(`${value.value == combinatorValueT.AND ? ' && ' : ' || '}`)
            } else if (value.type == conditionTYPES.CONTEXT) {
                return accumulator.concat(mergeContextVariable(value))
            }
            return accumulator
        }, '')

    const onSave = async () => {
        try {
            setLoading(true)
            const condition: string = convertWatsonString(conditions)

            const { input, parent, previousSibling } = PageState as RecognizeDialogPT

            if (input.dialog_node == '') {
                const watsonData: NewRecognizeDialogT = {
                    type: 'if',
                    conditions: condition,
                    title: dialogName,
                    parent: parent ? parent.dialog_node : null,
                    previous_sibling: previousSibling ? previousSibling.dialog_node : null
                }
                const resultData: RecognizeDialogT = await createRecogniseDialog(workspace, watsonData)

                const result: RecognizeDialogT = await updateDailouge(workspace, {
                    ...resultData,
                    metadata: { ...input.metadata, RICH_TEXT: conditions }
                } as RecognizeDialogT)

                if (previousSibling) {
                    if (
                        previousSibling.type == 'text-response' ||
                        previousSibling.type == 'option-response' ||
                        previousSibling.type == 'slot-container' ||
                        previousSibling.type == 'qa' ||
                        (previousSibling.type === 'action' &&
                            previousSibling.context.SYS_ACTIONS &&
                            previousSibling.context.SYS_ACTIONS.length == 2)
                    ) {
                        await updateDailouge(workspace, {
                            ...previousSibling,
                            jump_to: {
                                dialog_node: result.dialog_node,
                                selector: JumpToSelectorT.user_input
                            },
                            metadata: { ...previousSibling.metadata, hide_jump_to: true }
                        } as TextResponseDialogT | OptionsResponseDialogT)
                    } else {
                        console.log('no jump require', previousSibling)
                    }
                }
                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Recognise Dialog Created'
                })
            } else {
                await updateDailouge(workspace, {
                    ...input,
                    conditions: condition,
                    title: dialogName,
                    metadata: { ...input.metadata, RICH_TEXT: conditions }
                } as RecognizeDialogT)

                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Recognise Dialog Updated'
                })
            }

            fetchCallback(() => setLoading(false))
        } catch (error) {
            setLoading(false)
            setError({
                error: true,
                info: `Server error!<br />Please try again after sometime.`
            })
        }
    }

    useEffect(() => {
        const { input } = PageState as RecognizeDialogPT
        if (input.dialog_node == '') {
            setConditions(condition =>
                condition.concat([
                    {
                        type: conditionTYPES.SELECT
                    }
                ])
            )
        } else {
            setDialogName(input.title)
            if (input.conditions == '') {
                setConditions(condition =>
                    condition.concat([
                        {
                            type: conditionTYPES.SELECT
                        }
                    ])
                )
            } else {
                setConditions(input.metadata.RICH_TEXT)
            }
        }
    }, [])

    return (
        <div className="right-panel recognise_popup_right_panel">
            <ErrorBoundary
                error={error}
                render={(err: any, info: any) => {
                    return err && <Error.Delete onClick={() => setPageState(PageView)} info={info} />
                }}
            >
                <h5>Recognize Dialog</h5>
                <p className="mb_15">Specify condition(s) for your chatbot to execute this dialog</p>
                {loading ? (
                    <div className="popup_loader">
                        <Loader.PopupLoader show={loading} />
                    </div>
                ) : (
                    <>
                        <div className="right_panel_ht">
                            <div className="automation_canvas_right_popup_form">
                                <div className="recognise_popup_right">
                                    <div className="automation_rightside_form automation_rightside_step_two">
                                        <DialogNameComponent dialogName={dialogName} setDialogName={setDialogName} />
                                        {dialogName != ''
                                            ? conditions.map((element, index) =>
                                                  element.type == conditionTYPES.COMBINATOR ? (
                                                      <CombinatorCompoenent
                                                          key={index}
                                                          condition={element}
                                                          onChange={(condition: any) => onChange(index, condition)}
                                                      />
                                                  ) : (
                                                      <ConditionalStatements
                                                          condition={element}
                                                          conditions={conditions}
                                                          key={index}
                                                          onChange={(condition: any) => onChange(index, condition)}
                                                          remove={() => remove(index)}
                                                      />
                                                  )
                                              )
                                            : null}
                                        {conditions.filter(cond => cond.type == conditionTYPES.SELECT).length == 0 ? (
                                            <div className="add_connection_automation">
                                                <div className=" show_options">
                                                    <SmallButton primary onClick={() => addMore()}>
                                                        <span>
                                                            <AddIcon />
                                                        </span>
                                                        <label>Add More</label>
                                                    </SmallButton>
                                                </div>
                                            </div>
                                        ) : null}

                                        {conditions.filter(cond => cond.type == conditionTYPES.SELECT).length == 0 ? (
                                            <div className="automation_form_req automation_form_req_condition">
                                                <div className="automation_global_form">
                                                    <div className="select_work_form">
                                                        <h4>Condition Summary</h4>
                                                        <div className="dialog_recognise_none">
                                                            {conditions.map((val: conditionT) => {
                                                                if (val.type == conditionTYPES.INTENT) {
                                                                    return (
                                                                        <span>{`Intent: "${val.name}" Is recognized`}</span>
                                                                    )
                                                                } else if (val.type == conditionTYPES.COMBINATOR) {
                                                                    return (
                                                                        <span>{` "${
                                                                            val.value == combinatorValueT.AND
                                                                                ? 'And'
                                                                                : 'Or'
                                                                        }" `}</span>
                                                                    )
                                                                } else if (val.type == conditionTYPES.ENTITY) {
                                                                    if (val.operator == operatorsT.IS_PRESENT) {
                                                                        return (
                                                                            <span>
                                                                                {`Entity: "${val.name}" is recognized`}
                                                                            </span>
                                                                        )
                                                                    } else if (val.operator == operatorsT.IS) {
                                                                        return (
                                                                            <span>
                                                                                {`Entity: "${val.name}" value is "${val.value}"`}
                                                                            </span>
                                                                        )
                                                                    } else if (val.operator == operatorsT.IS_NOT) {
                                                                        return (
                                                                            <span>
                                                                                {`Entity: "${val.name}" value is not "${val.value}"`}
                                                                            </span>
                                                                        )
                                                                    }
                                                                } else if (val.type == conditionTYPES.CONTEXT) {
                                                                    if (val.operator == operatorsT.EQUALS_TEXT) {
                                                                        return (
                                                                            <span>
                                                                                {`Variable: "${val.LHS}" equals to (text) "`}
                                                                                {val.RHS_META.blocks[0].text}
                                                                                {'"'}
                                                                            </span>
                                                                        )
                                                                    } else if (
                                                                        val.operator == operatorsT.DOES_NOT_EQUALS_TEXT
                                                                    ) {
                                                                        return (
                                                                            <span>
                                                                                {`Variable: "${val.LHS}" does not equals to (text) "`}
                                                                                {val.RHS_META.blocks[0].text}
                                                                                {'"'}
                                                                            </span>
                                                                        )
                                                                    } else if (
                                                                        val.operator == operatorsT.EQUALS_NUMBER
                                                                    ) {
                                                                        return (
                                                                            <span>
                                                                                {`Variable: "${val.LHS}" equals to (number) "`}
                                                                                {val.RHS_META.blocks[0].text}
                                                                                {'"'}
                                                                            </span>
                                                                        )
                                                                    } else if (
                                                                        val.operator ==
                                                                        operatorsT.DOES_NOT_EQUALS_NUMBER
                                                                    ) {
                                                                        return (
                                                                            <span>
                                                                                {`Variable: "${val.LHS}" does not equals to (number) "`}
                                                                                {val.RHS_META.blocks[0].text}
                                                                                {'"'}
                                                                            </span>
                                                                        )
                                                                    } else if (
                                                                        val.operator == operatorsT.GREATER_THAN
                                                                    ) {
                                                                        return (
                                                                            <span>
                                                                                {`Variable: "${val.LHS}" greater than "`}
                                                                                {val.RHS_META.blocks[0].text}
                                                                                {'"'}
                                                                            </span>
                                                                        )
                                                                    } else if (val.operator == operatorsT.LESS_THAN) {
                                                                        return (
                                                                            <span>
                                                                                {`Variable: "${val.LHS}" less than "`}
                                                                                {val.RHS_META.blocks[0].text}
                                                                                {'"'}
                                                                            </span>
                                                                        )
                                                                    } else if (val.operator == operatorsT.IS_TRUE) {
                                                                        return (
                                                                            <span>
                                                                                {`Variable: "${val.LHS}" is true`}
                                                                            </span>
                                                                        )
                                                                    } else if (val.operator == operatorsT.IS_FALSE) {
                                                                        return (
                                                                            <span>
                                                                                {`Variable: "${val.LHS}" is false`}
                                                                            </span>
                                                                        )
                                                                    }
                                                                    return <span>{`Variable: "${val.LHS}"`}</span>
                                                                }
                                                                return <span />
                                                            })}
                                                        </div>
                                                        <p>Condition to execute this dialog will be displayed here.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Button
                            disable={
                                dialogName == '' ||
                                dialogName.length > 26 ||
                                conditions.filter(data => {
                                    if (data.type == conditionTYPES.INTENT) {
                                        return data.name == ''
                                    } else if (data.type == conditionTYPES.ENTITY) {
                                        if (data.operator == operatorsT.SELECT) {
                                            return true
                                        }
                                        if (data.name != '') {
                                            if (data.operator == operatorsT.IS || data.operator == operatorsT.IS_NOT) {
                                                return data.value == ''
                                            }
                                        } else {
                                            return true
                                        }
                                    } else if (data.type == conditionTYPES.SELECT) {
                                        return true
                                    } else if (data.type == conditionTYPES.CONTEXT) {
                                        if (data.operator == operatorsT.SELECT) {
                                            return true
                                        }
                                        if (data.LHS != '') {
                                            if (
                                                data.operator == operatorsT.IS_TRUE ||
                                                data.operator == operatorsT.IS_FALSE ||
                                                data.operator == operatorsT.IS_PRESENT ||
                                                data.operator == operatorsT.IS_NOT_PRESENT
                                            ) {
                                                return false
                                            } else {
                                                return data.RHS == ''
                                            }
                                        } else {
                                            return true
                                        }
                                    }
                                    return false
                                }).length > 0
                            }
                            onCancel={() => setPageState(PageView)}
                            onSave={() => onSave()}
                        />
                    </>
                )}
            </ErrorBoundary>
        </div>
    )
}

interface ConditionalStatementsP {
    condition: conditionT
    conditions: conditionT[]
    onChange: Function
    remove: Function
}

const ConditionalStatements = ({ condition, conditions, onChange, remove }: ConditionalStatementsP) => {
    const [chooser, setChooser] = useState<boolean>(false)

    return (
        <div className="test_buton_result">
            <div className="test_buton_view">
                <div className="automation_form_req automation_form_req_step_two">
                    <div className="automation_condition_input">
                        <h4>Select Condition Type</h4>
                        {conditions.length > 1 ? (
                            <span className="automation_condition_span" onClick={e => remove()}>
                                <NodeHoverMenuIcons.DeleteIcon />
                            </span>
                        ) : (
                            <span />
                        )}
                    </div>
                </div>

                <div className="automation_condition_form recognise_content_input">
                    <div className="select_work_form">
                        <input type="text" value={condition.type} onChange={e => {}} onClick={e => setChooser(true)} />
                        {chooser ? (
                            <DropDown isOpen={chooser} onOuterClick={() => setChooser(false)}>
                                <div className="automation_scroly">
                                    <ul>
                                        <li
                                            onClick={e => {
                                                setChooser(false)
                                                onChange({
                                                    type: conditionTYPES.INTENT,
                                                    name: '',
                                                    operator: operatorsT.SELECT
                                                })
                                            }}
                                        >
                                            Intent
                                        </li>
                                        <li
                                            onClick={e => {
                                                setChooser(false)
                                                onChange({
                                                    type: conditionTYPES.ENTITY,
                                                    name: '',
                                                    operator: operatorsT.SELECT,
                                                    value: ''
                                                })
                                            }}
                                        >
                                            Entity
                                        </li>
                                        <li
                                            onClick={e => {
                                                setChooser(false)
                                                onChange({
                                                    type: conditionTYPES.CONTEXT,
                                                    LHS: '',
                                                    LHS_META: {},
                                                    operator: operatorsT.SELECT,
                                                    RHS: '',
                                                    RHS_META: {
                                                        blocks: [
                                                            {
                                                                text: '',
                                                                type: 'unstyled'
                                                            }
                                                        ],
                                                        entityMap: {}
                                                    }
                                                })
                                            }}
                                        >
                                            Variable
                                        </li>
                                    </ul>
                                </div>
                            </DropDown>
                        ) : null}
                        <div className="arrow_up_form">
                            <DownArrowCircle />
                        </div>
                        {condition.type == conditionTYPES.INTENT && (
                            <p>Select a pre-existing Intent or create a new Intent</p>
                        )}
                        {condition.type == conditionTYPES.SELECT && <p>Select the type to setup the condition</p>}
                        {condition.type == conditionTYPES.ENTITY && <p>Select a pre-existing Entity</p>}
                        {condition.type == conditionTYPES.CONTEXT && <p>Select the variable to setup the condition</p>}
                    </div>
                </div>
                {condition.type == conditionTYPES.CONTEXT ? (
                    <ContextStatement condition={condition} onChange={onChange} />
                ) : null}
                {condition.type == conditionTYPES.INTENT ? (
                    <IntentCompoenent condition={condition} onChange={onChange} />
                ) : null}
                {condition.type == conditionTYPES.ENTITY ? (
                    <EntityCompoenent condition={condition} onChange={onChange} />
                ) : null}
            </div>
        </div>
    )
}

const ContextStatement = ({ condition, onChange }: { condition: variableConditionT; onChange: Function }) => {
    const [conditionChooser, setConditionChooser] = useState<boolean>(false)

    const reduceFunction = (accum: any, currentValue: any) => {
        if (currentValue.start != 0) {
            const value = accum.result
                .substring(0, currentValue.start + accum.adjustedLength)
                .concat(
                    '.append(',
                    currentValue.value.substring(3, currentValue.value.length - 3),
                    ').append(',
                    accum.result.substring(currentValue.end + accum.adjustedLength),
                    ')'
                )
            return {
                result: value,
                adjustedLength: accum.adjustedLength + value.length - accum.result.length
            }
        } else if (currentValue.start == 0) {
            const value = currentValue.value
                .substring(3, currentValue.value.length - 3)
                .concat('.append(', accum.result.substring(currentValue.end + accum.adjustedLength), ')')
            return {
                result: value,
                adjustedLength: accum.adjustedLength + value.length - accum.result.length
            }
        }
        return accum
    }

    const eventChange = (place: 'LHS_META' | 'RHS_META', meta: any) => {
        const text = meta.blocks[0].text
        const entityRanges = Ramda.sortBy(
            Ramda.prop('start'),
            meta.blocks[0].entityRanges.map((event: any) => ({
                start: event.offset,
                end: event.offset + event.length,
                value: meta.entityMap[event.key].data.value
            }))
        )

        const str = entityRanges.reduce(reduceFunction, { result: text, adjustedLength: 0 })
        if (place == 'LHS_META') onChange({ ...condition, LHS_META: meta, LHS: str.result })
        else if (place == 'RHS_META') onChange({ ...condition, RHS_META: meta, RHS: str.result })
    }
    return (
        <React.Fragment>
            {/* LHS PART */}
            <div className="automation_condition_form">
                <div className="select_work_form">
                    <SelectContextVariable
                        rawMeta={condition.LHS_META}
                        setRawMeta={(meta: any) => onChange({ ...condition, LHS_META: meta, LHS: meta.visible })}
                    />
                </div>
            </div>
            {condition.LHS != '' ? (
                <React.Fragment>
                    <div className="automation_condition_form">
                        <div className="select_work_form">
                            <input
                                type="text"
                                value={
                                    condition.operator == operatorsT.EQUALS_TEXT
                                        ? 'equals to (text)'
                                        : condition.operator == operatorsT.DOES_NOT_EQUALS_TEXT
                                        ? 'does not equals to (text)'
                                        : condition.operator == operatorsT.IS_PRESENT
                                        ? 'is present'
                                        : condition.operator == operatorsT.IS_NOT_PRESENT
                                        ? 'is not present'
                                        : condition.operator == operatorsT.GREATER_THAN
                                        ? 'greater than'
                                        : condition.operator == operatorsT.LESS_THAN
                                        ? 'less than'
                                        : condition.operator == operatorsT.IS_TRUE
                                        ? 'is true'
                                        : condition.operator == operatorsT.IS_FALSE
                                        ? 'is false'
                                        : condition.operator == operatorsT.EQUALS_NUMBER
                                        ? 'equals to (number)'
                                        : condition.operator == operatorsT.DOES_NOT_EQUALS_NUMBER
                                        ? 'does not equals to (number)'
                                        : ''
                                }
                                placeholder={'Select Logic'}
                                onChange={event => onChange({ ...condition, name: event.target.value })}
                                onClick={e => setConditionChooser(true)}
                            />
                            {conditionChooser ? (
                                <DropDown isOpen={conditionChooser} onOuterClick={() => setConditionChooser(false)}>
                                    <div className="automation_scroly">
                                        <ul>
                                            {condition.LHS_META.dataType == 'string' ||
                                            condition.LHS_META.dataType == 'all' ? (
                                                <React.Fragment>
                                                    <li
                                                        onClick={() => {
                                                            setConditionChooser(false)
                                                            onChange({ ...condition, operator: operatorsT.EQUALS_TEXT })
                                                        }}
                                                    >
                                                        equals to (text)
                                                    </li>
                                                    <li
                                                        onClick={() => {
                                                            setConditionChooser(false)
                                                            onChange({
                                                                ...condition,
                                                                operator: operatorsT.DOES_NOT_EQUALS_TEXT
                                                            })
                                                        }}
                                                    >
                                                        does not equals to (text)
                                                    </li>
                                                </React.Fragment>
                                            ) : null}
                                            {condition.LHS_META.dataType == 'number' ||
                                            condition.LHS_META.dataType == 'all' ? (
                                                <React.Fragment>
                                                    <li
                                                        onClick={() => {
                                                            setConditionChooser(false)
                                                            onChange({
                                                                ...condition,
                                                                operator: operatorsT.EQUALS_NUMBER
                                                            })
                                                        }}
                                                    >
                                                        equals to (number)
                                                    </li>
                                                    <li
                                                        onClick={() => {
                                                            setConditionChooser(false)
                                                            onChange({
                                                                ...condition,
                                                                operator: operatorsT.DOES_NOT_EQUALS_NUMBER
                                                            })
                                                        }}
                                                    >
                                                        does not equals to (number)
                                                    </li>

                                                    <li
                                                        onClick={() => {
                                                            setConditionChooser(false)
                                                            onChange({
                                                                ...condition,
                                                                operator: operatorsT.GREATER_THAN
                                                            })
                                                        }}
                                                    >
                                                        greater than
                                                    </li>
                                                    <li
                                                        onClick={() => {
                                                            setConditionChooser(false)
                                                            onChange({ ...condition, operator: operatorsT.LESS_THAN })
                                                        }}
                                                    >
                                                        less than
                                                    </li>
                                                </React.Fragment>
                                            ) : null}
                                            {condition.LHS_META.dataType == 'boolean' ||
                                            condition.LHS_META.dataType == 'all' ? (
                                                <React.Fragment>
                                                    <li
                                                        onClick={() => {
                                                            setConditionChooser(false)
                                                            onChange({ ...condition, operator: operatorsT.IS_TRUE })
                                                        }}
                                                    >
                                                        is true
                                                    </li>
                                                    <li
                                                        onClick={() => {
                                                            setConditionChooser(false)
                                                            onChange({ ...condition, operator: operatorsT.IS_FALSE })
                                                        }}
                                                    >
                                                        is false
                                                    </li>
                                                </React.Fragment>
                                            ) : null}
                                        </ul>
                                    </div>
                                </DropDown>
                            ) : null}

                            <div className="arrow_up_form">
                                <DownArrowCircle />
                            </div>
                        </div>
                    </div>
                    {condition.operator != operatorsT.SELECT
                        ? condition.operator != operatorsT.IS_TRUE &&
                          condition.operator != operatorsT.IS_FALSE &&
                          condition.operator != operatorsT.IS_PRESENT &&
                          condition.operator != operatorsT.IS_NOT_PRESENT && (
                              <div className="automation_condition_form">
                                  <div className="select_work_form">
                                      <DraftContent
                                          rawMeta={condition.RHS_META}
                                          setRawMeta={(meta: any) => eventChange('RHS_META', meta)}
                                      />
                                  </div>
                              </div>
                          )
                        : null}
                </React.Fragment>
            ) : null}
        </React.Fragment>
    )
}
