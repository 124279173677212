export enum Action {
    View,
    Add,
    Edit,
    Select,
    Delete,
    Loading,
    NoDataFound
}
export type PageState = ViewT | AddT | EditT | SelectT | DeleteT | LoadingT | NoDataFoundT

export type ViewT = {
    action: Action.View
}
export type SelectT = {
    action: Action.Select
    selectedItems: string[]
}
export type DeleteT = {
    action: Action.Delete
    selectedItems: string[]
    name: string
}
export type AddT = {
    action: Action.Add
    title: string
}
export type EditT = {
    action: Action.Edit
    title: string
    dialog_node: string
}
export type LoadingT = {
    action: Action.Loading
}
export type NoDataFoundT = {
    action: Action.NoDataFound
    showAddPopup: boolean
    title: string
}
export const View: ViewT = {
    action: Action.View
}
export const Select: SelectT = {
    action: Action.Select,
    selectedItems: []
}
export const Delete: DeleteT = {
    action: Action.Delete,
    selectedItems: [],
    name: ''
}

export const Add: AddT = {
    action: Action.Add,
    title: ''
}
export const Edit: EditT = { action: Action.Edit, title: '', dialog_node: '' }

export const Loading: LoadingT = {
    action: Action.Loading
}

export const NoDataFound: NoDataFoundT = {
    action: Action.NoDataFound,
    showAddPopup: false,
    title: ''
}

export type DialogT = {
    type: string
    dialog_node: string
    parent: string
    previous_sibling: string | null
    metadata: any
    jump_to: any
    children?: DialogT[]

    isJumpTo?: boolean
    isExit?: boolean
    isFlow?: boolean

    context?: any

    created?: string
    updated?: String
}

export interface IfNodeT extends DialogT {
    title: string
    conditions: string
}

export interface RespondWithTextNodeT extends DialogT {
    selection_policy: string
    values: string[]
}

export type options = {
    label: string
    value: string
}

export interface RespondWithOptionsNodeT extends DialogT {
    option_title: string
    options: options[]
}

export interface QuestionAndAnswerNodeT extends DialogT {
    question: any
    variable: string
}

export interface FlowNodeT extends DialogT {
    flow: string
    flow_input: any
    flow_output: string
}

export type SlotT = {
    type: string
    parent: string
    previous_sibling: string | null
    dialog_node: string
    variable: string
    condition: string
    prompt: string
}

export interface SlotsContainerT extends DialogT {
    slots: SlotT[]
}
