/*eslint-disable*/
import React from 'react'

import { DetailViewICon, IconViewICon } from '@/common/Icons/Icons'
//COMMON COMPONENTS IMPORT
import { SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'

//TYPE IMPORT
import { DialogueT } from '../../Dialogue/kind'

interface HeaderP {
    currentDialog: DialogueT
    DialogIndex: string
    isDetailView?: boolean
    setDetailView?: any
}

const Header = ({ currentDialog, DialogIndex, isDetailView, setDetailView }: HeaderP) => (
    // isDetailView ? (
    <div className="dialog__header">
        <div className="flex_canvas1 intent_inner_page">
            <div className="flex_menu1">
                <div className="indent_dialog_left">
                    <div className="indent_title">
                        <SmallButton onClick={() => window.history.back()}>
                            <span className="rotate_back_button">
                                <svg x="0px" y="0px" viewBox="0 0 31.5 31.5">
                                    <path d="M21.2 5c-.4-.4-1.1-.4-1.6 0-.4.4-.4 1.1 0 1.6l8 8H1.1c-.6 0-1.1.5-1.1 1.1s.5 1.1 1.1 1.1h26.6l-8 8c-.4.4-.4 1.2 0 1.6.4.4 1.1.4 1.6 0l10-10c.4-.4.4-1.1 0-1.6L21.2 5z" />
                                </svg>{' '}
                            </span>
                            <label>Back</label>
                        </SmallButton>
                        <div className="buton_back_content">
                            <div>
                                <h2>
                                    {currentDialog.title}
                                    {currentDialog.conditions ? (
                                        currentDialog.conditions.type === 'WelcomeExpression' ? null : currentDialog
                                              .conditions.type === 'FallBackExpression' ? null : (
                                            <>{/* <span className="dialog_number">{DialogIndex}</span> */}</>
                                        )
                                    ) : (
                                        <>{/* <span className="dialog_number">{DialogIndex}</span> */}</>
                                    )}
                                    {/* <IconButton>
                                        <span>
                                            <EditIconWithCircle />
                                        </span>
                                    </IconButton> */}
                                </h2>
                                {currentDialog.description &&
                                <p>{currentDialog.description}</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex_side_menu1 intent_home flow_group_home">
                <div className="intent_dialog_right">
                    {isDetailView ? (
                        <button className="btn btn-primary" onClick={() => setDetailView(false)}>
                            <IconViewICon />
                            <span>Icon View</span>
                        </button>
                    ) : (
                        <button className="btn btn-primary" onClick={() => setDetailView(true)}>
                            <DetailViewICon />
                            <span>Detail View</span>
                        </button>
                    )}
                </div>
            </div>
        </div>
    </div>
)
// ) : (
//     <CommonHeader>
//         <HeaderChild type="first">
//             <h2 className="">
//                 <span className="buton_back inner_pages" onClick={() => window.history.back()}>
//                     <ArrowIcon /> Back
//                 </span>
//                 {currentDialog.title}
//                 {currentDialog.conditions ? (
//                     currentDialog.conditions.type === 'WelcomeExpression' ? null : currentDialog.conditions.type ===
//                       'FallBackExpression' ? null : (
//                         <span className="dialog_number">{DialogIndex}</span>
//                     )
//                 ) : (
//                     <span className="dialog_number">{DialogIndex}</span>
//                 )}
//             </h2>
//         </HeaderChild>
//         <HeaderChild type="second">
//             {/* {isDetailView &&
//                 (isDetailView == true ? (
//                     <button className="btn btn-primary entity_right_icon" onClick={() => setDetailView(true)}>
//                         <GroupIcon />
//                         <span>Detail View</span>
//                     </button>
//                 ) : (
//                     <button className="btn btn-primary entity_right_icon" onClick={() => setDetailView(false)}>
//                         <ListviewIcon />
//                         <span>Icon View</span>
//                     </button>
//                 ))} */}
//         </HeaderChild>
//     </CommonHeader>
// )

export default Header
