/*eslint-disable*/
import React, { useContext, useState } from 'react'

import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { Loader } from '@/common/components/Loader'
import lazyGA from '@/common/google-analytics/tracking'

import { createActionDialog, updateDailouge } from '../../Actions/AsyncOperations'
import { PageContext } from '../../Actions/ReactContext'
import { ExitDialogPT, PageView } from '../../Types/PageT'
import {
    AskQueryDialogT,
    ExitDialogT,
    JumpToSelectorT,
    RecognizeDialogT,
    SlotContainerDialogT,
    TextResponseDialogT
} from '../../Types/WatsonT'
import { Button } from '../Common'
import { DraftContent, metaParser } from '../DraftContent'

export function Exit() {
    const [loader, setLoader] = useState<boolean>(false)

    const [error, setError] = useState<any>({ error: null, info: null })

    const { workspace, setPageState, PageState, fetchCallback } = useContext(PageContext)

    const { input } = PageState as ExitDialogPT

    const [messageMeta, setMessageMeta] = useState(input.metadata.RICH_TEXT)

    const [needFeedBack, setNeedFeedBack] = useState<boolean>(
        input.dialog_node == '' ? true : input.jump_to.dialog_node == 'feedback'
    )
    const save = async () => {
        try {
            setLoader(true)
            const { input, previousSibling, nextSibling, parent } = PageState as ExitDialogPT

            const message = metaParser(messageMeta)

            const result = {
                ...input,
                context: { SYS_EXIT_MESSAGE: message },
                jump_to: {
                    dialog_node: needFeedBack ? 'feedback' : 'exit',
                    selector: JumpToSelectorT.body
                },
                metadata: { ...input.metadata, RICH_TEXT: messageMeta }
            } as ExitDialogT

            if (input.dialog_node == '') {
                const Create_response: ExitDialogT = await createActionDialog(workspace, result)
                const response: ExitDialogT = await updateDailouge(workspace, {
                    ...Create_response,
                    metadata: { ...input.metadata, RICH_TEXT: messageMeta }
                })
                if (previousSibling) {
                    await updateDailouge(workspace, {
                        ...previousSibling,
                        type: previousSibling.type,
                        jump_to: {
                            dialog_node: response.dialog_node,
                            selector: JumpToSelectorT.body
                        },
                        metadata: { ...previousSibling.metadata, hide_jump_to: true }
                    } as TextResponseDialogT | AskQueryDialogT | SlotContainerDialogT)
                } else if (parent) {
                    await updateDailouge(workspace, {
                        ...parent,
                        type: parent.type,
                        jump_to: {
                            dialog_node: response.dialog_node,
                            selector: JumpToSelectorT.body
                        },
                        metadata: { ...parent.metadata, hide_jump_to: true }
                    } as RecognizeDialogT)
                }

                if (nextSibling) {
                    // don't want jump to for next dialog becos next dialog won't execute
                    console.log("dialog don't execute", nextSibling)
                    // await updateDailouge(workspace, {
                    //     ...response,
                    //     jump_to: {
                    //         dialog_node: nextSibling.dialog_node,
                    //         selector: JumpToSelectorT.body
                    //     },
                    //     metadata: { ...response.metadata, hide_jump_to: true }
                    // } as ExitDialogT)
                }
                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Exit Node Created'
                })
            } else if (input.dialog_node != '') {
                await updateDailouge(workspace, result)
                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Exit Node Updated'
                })
            }

            fetchCallback(() => {
                setLoader(false)
            })
        } catch (err) {
            setError({
                error: {
                    error: true,
                    info: `Server error!<br />Please try again after sometime.`
                },
                showPopupLoader: false
            })
        }
    }
    return (
        <ErrorBoundary
            error={error}
            render={(err: any, info: any) => {
                return err && <Error.Delete onClick={() => setPageState(PageView)} info={info} />
            }}
        >
            <div className="right-panel ask_ques_editor">
                <h5>Exit</h5>
                <p className="ask_ques_editor_p">Make the chatbot exit from session with a message for end user</p>
                {loader ? (
                    <div className="popup_loader">
                        <Loader.PopupLoader show={loader} />
                    </div>
                ) : (
                    <React.Fragment>
                        <div className="right_panel_ht">
                            <span className="form-input">
                                <div className="typemsg_input">Exit Message:</div>
                                <DraftContent rawMeta={messageMeta} setRawMeta={setMessageMeta} />
                                <p className="text_left_p_0">Type your exit message </p>
                            </span>
                            <div className="typemsg_input_popup">
                                <p>Switch this ON to get the user's feedback</p>

                                <div className="popup_show_hide">
                                    <div className="show_hide">
                                        <div className="switch_box box_1">
                                            <input
                                                type="checkbox"
                                                className="switch_1"
                                                checked={needFeedBack}
                                                onChange={() => setNeedFeedBack(!needFeedBack)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span className="bottom_typemsg_input_popup">
                                Note: You can modify the feedback message that the chatbot displays to the user on the
                                Chatbot Builder's Settings Page.
                            </span>
                        </div>
                        <Button
                            disable={metaParser(messageMeta) == ''}
                            onCancel={() => setPageState(PageView)}
                            onSave={save}
                        />
                    </React.Fragment>
                )}
            </div>
        </ErrorBoundary>
    )
}
