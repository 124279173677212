import * as React from 'react';
/*eslint-disable*/
import {
  Action,
  BotDataT,
  Edit,
  ErrorPage,
  Loading,
  Modes,
  UnauthorizedPage,
  View,
  NoDataFound,
} from '@/Integrations/types';
import { VideoButtonB, VideoLink } from '@/Videos/Videos';
import {
  AddIcon,
  CloseIcon,
  DeleteIcon,
  DownArrowCircle,
  EditIcon,
  EditIconCircle,
  HelpIcon,
  InfoIcon,
  MinusIcon,
  QaIconWithCircle,
  SubPanelIcons,
  TeamsIcon,
} from '@/common/Icons/Icons';
import { LoadingSvgImage } from '@/common/Icons/LoadingSvgImage';
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons';
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary';
import { Header, HeaderChild } from '@/common/components/Header';
import { Loader } from '@/common/components/Loader';
import { Modal } from '@/common/components/Modal';
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb';
import { ButtonContainer } from '@/common/styled/Dialog.Canvas.Nodes.Dumb';
import {
  Input,
  InputContainer,
  InputWrapper,
} from '@/common/styled/Workflow.Analytics.Dumb';

import { formatAMPM, formatDateInCard } from '@/common/utils/utils';
import {
  agenHandoffUrl,
  agentHandoffBackendIntegraion,
  deleteJson,
  postJson,
  putJson,
} from '@/common/utils/api-utils';
import { AgentHandoffWorkspace } from './types';
import Downshift from 'downshift';
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@/common/styled/Workflow.Analytics.Dumb';
import { List } from '@/common/components/List';
import { ListSelectBox } from '@/common/components/SelectBox';
import Tooltip from 'react-tooltip-lite';
import { Delete } from '@/Flows/onPremTypes';
import styled from 'styled-components/macro';
import {
  EmptyDiv,
  HeaderLeft,
  Paragraph,
  PopupContainer,
  PopupScrollHeight,
  PopupWrapper,
  SmallButton,
  Wrapper,
  Header as PopupHeader,
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb';
import { HeaderRight } from '@/common/styled/FaqPopup.Dumb';
import * as R from 'ramda';
import { useSignal } from '@preact/signals-react';
import { usePermissionSignal } from '@/common/utils/auth-token-api-utils';
import { AssistantT } from '../Construction/kind';
export const Textarea = styled.textarea.attrs((boolean: any) => ({
  required: true,
}))`
  width: 100%;
  float: left;
  padding: 26px 12px 8px 12px;
  border: 1px solid #b9b9b9;
  border-radius: 6px;
  background: transparent;
  min-height: 94px;
  font-size: 12px;
  font-family: 'Gordita-Regular';
  color: #000000;
  outline: 0;
  word-break: break-word;
  resize: none;
  cursor: auto;
  overflow-y: hidden;

  :hover {
    outline: 0;
    border: 1px solid #eb974e;
    box-shadow: 0px 3px 6px #00000029;
  }

  :active {
    outline: 0;
    border: 1px solid #eb974e;
    box-shadow: none;
  }
`;
export const InputText = styled.textarea.attrs((boolean: any) => ({
  required: true,
}))`
  width: 100%;
  float: left;
  padding: 24px 12px 0px 12px;
  border: 1px solid #b9b9b9;
  border-radius: 6px;
  background: #fff;
  height: 48px;
  font-size: 12px;
  font-family: 'Gordita-Regular';
  color: #000000;
  outline: 0;
  word-break: break-word;
  resize: none;
  cursor: auto;

  :hover {
    outline: 0;
    border: 1px solid #eb974e;
    box-shadow: 0px 3px 6px #00000029;
  }

  :active {
    outline: 0;
    border: 1px solid #eb974e;
    box-shadow: none;
  }
`;
export const InputContent = styled.div`
  color: #999;
  font-size: 12px;
  font-family: 'Gordita-Regular';
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 10px;
  transition: 0.26s ease-in;
  ${Input}:focus ~ &,
    ${Input}:not(:placeholder-shown).input:not(:focus) ~ .label,
    ${Input}:valid ~ & {
    top: 10px;
    font-size: 10px;
    transition: 0.26s ease-out;
    line-height: 10px;
  }
  ${Textarea}:focus ~ &,
    ${Textarea}:not(:placeholder-shown).input:not(:focus) ~ .label,
    ${Textarea}:valid ~ & {
    top: 1px;
    font-size: 10px;
    transition: 0.26s ease-out;
    line-height: 10px;
    background: transparent;
    width: 95%;
    text-align: left;
    height: 24px;
    padding-top: 8px;
  }
  ${InputText}:focus ~ &,
    ${InputText}:not(:placeholder-shown).input:not(:focus) ~ .label,
    ${InputText}:valid ~ & {
    top: 1px;
    font-size: 10px;
    transition: 0.26s ease-out;
    line-height: 10px;
    background: transparent;
    width: 95%;
    text-align: left;
    height: 24px;
    padding-top: 8px;
  }
`;

const TabelWrapper = styled.div`
  width: 100%;
  float: left;
  .list_flow_hr label {
    float: left;
    display: flex;
    position: absolute;
    top: 18px;
    width: 16px;
    height: 16px;
    margin-bottom: 0px;
    left: -22px;
  }
  .option-input.radio {
    top: -2px;
    position: absolute;
  }

  ${Table} {
    margin-top: 0px;
  }
  ${Thead} {
    ${Tr} {
      padding-left: 32px;
      padding-right: 20px;

      ${Th} {
        :last-child {
          justify-content: flex-end;
        }
      }
    }
  }
  ${Tbody} {
    border-radius: 0px 0px 10px 10px;
    max-height: calc(100vh - 84px);
    height: auto;
    ${Tr} {
      height: 48px;
      padding-left: 32px;
      padding-right: 20px;
      .list_flow_span .entity_span {
        :first-child {
          margin-right: 3px;
          color: #000;
          font-size: 11px;
        }
        :last-child {
          margin-left: 3px;
          color: #000;
          font-size: 11px;
        }
      }
      :hover {
        ${Td}:last-child {
          display: flex;
        }
      }

      ${Td} {
        padding: 0px;
        height: 48px;
        font-size: 12px;
        font-family: 'Gordita-Regular';
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        color: #000;
        span {
          margin-right: 0px;
        }

        .list_flow_span_example span {
          font-size: 12px;
          font-family: 'Gordita-Regular';
          color: #000;

          span {
            color: #f07f31;
          }
        }
        :nth-child(1) {
          display: flex;
          padding-right: 20px;
          cursor: pointer;
          position: relative;
        }

        :nth-child(2) {
          display: flex;
          padding-right: 20px;
        }
        :last-child {
          display: none;
        }
      }
    }
  }
`;
const initialWorkspaceData = {
  id: '',
  is_active: false,
  client_id: '',
  tenant: '',
  workspace: '',
  client_secrect: '',
  created_at: '',
  updated_at: '',
  bot_data: {
    bot_full_desc: '',
    bot_full_name: '',
    bot_name: '',
    bot_short_desc: '',
    is_active: false,
    is_generated: false,
  },
  bot_id: '',
  is_authorized: false,
  teams_deploy: null,
  tenant_type: 'multi_tenant' as any,
  name: '',
  client_secret_active: true,
};

type IntegrateS = {
  PageState:
    | Modes
    | {
        action: 'RESTRICTED PLAN';
      };
  workspaces: AgentHandoffWorkspace[];
  disableButton: boolean;
  PageError: any;
  bots: any[];
  addMode: boolean;
  openBotDropDown: boolean;
  editIndex: number | null;
  tokens: {
    workspace: string;
    assistance_id: string;
    client_id: string;
    tenant: string;
  };
  currentTeamCredentials: {
    client_id: string;
    client_secrect: string;
    tenant: string;
    tenant_type: 'multi_tenant' | 'single_tenant';
    name: string;
  };
  botDetails: {
    name: string;
    fullName: string;
    desc: string;
    fullDesc: string;
  };
  redirectionClipBoard: string | null;
  endpointClipBoard: string | null;
  popUp: { error: null | string; loader: boolean };
  editAppId: string;
  editBotIndex: number | null;
  showDeleteModal: boolean;
  popup: {
    showPopupLoader: boolean;
    modalError: { error: boolean; info: null | string };
  };
  currentEditingTeamCredentials: AgentHandoffWorkspace;
  openTenantDropDown: boolean;
  editNameIndex: number | null;
  selectedWorkspace: AgentHandoffWorkspace[];
  clonedWorkspace: AgentHandoffWorkspace[];
  showAppDetails: boolean;
  showDetails: boolean;
  showWorkspaceDetails: boolean;
  showChatDetails: boolean;
};

export function AgentHandoff(props: any) {
  const [state, setState] = React.useState<IntegrateS>({
    PageState: View,
    workspaces: [],
    disableButton: false,
    addMode: false,
    bots: [],
    PageError: {},
    openBotDropDown: false,
    currentTeamCredentials: {
      client_id: '',
      client_secrect: '',
      tenant: '',
      tenant_type: 'multi_tenant',
      name: '',
    },
    editIndex: null,
    botDetails: { name: '', fullName: '', desc: '', fullDesc: '' },
    tokens: {
      workspace: props.match.params.workspacename,
      assistance_id: '',
      client_id: '',
      tenant: '',
    },
    redirectionClipBoard: null,
    endpointClipBoard: null,
    popUp: { error: null, loader: false },
    editAppId: '',
    editBotIndex: null,
    showDeleteModal: false,
    popup: { showPopupLoader: false, modalError: { error: false, info: null } },
    currentEditingTeamCredentials: initialWorkspaceData,
    openTenantDropDown: false,
    editNameIndex: null,
    selectedWorkspace: [],
    clonedWorkspace: [],
    showAppDetails: true,
    showDetails: true,
    showWorkspaceDetails: true,
    showChatDetails: true,
  });
  const workspaceName: string = props.match.params.workspacename;
  React.useEffect(() => {
    if (props.state.type == 'loading') {
      setState({ ...state, PageState: Loading });
    } else if (props.state.type == 'error') {
      if (
        props.state.error.response &&
        props.state.error.response.status == 403
      ) {
        setState({
          ...state,
          PageState: UnauthorizedPage,
        });
      } else {
        setState({
          ...state,
          PageState: ErrorPage,
          PageError: {
            error: true,
            info: 'There was a problem in loading Page! <br/> Please click on "Retry"',
          },
        });
      }
    } else if (props.state.type == 'success') {
      // console.log('data', props.state.data)
      if (props.state.data.hasAgentaccess === false) {
        // console.log('restricted');
        setState({ ...state, PageState: { action: 'RESTRICTED PLAN' } });
        return;
      }
      const result: any = props.state.data && props.state.data.agent;
      const bots: any = props.state.data && props.state.data.bots;
      const assistantname = props.match.params.assistantname;
      //const result =[{"id":"17aa24af-c61f-44a8-87a3-1b165beb7e77","workspace":"vinoddev","bot_id":"00318c03-cfa3-4f30-80d2-069de4654488","client_id":"65b1c05e-4e56-4912-a2ee-34b8f370939d","client_secrect":"jfO8Q~4kCg2U4E9mx_PAM_3AeuuC8Jts6INrZa4F","name":"IT Agents","description":"","is_active":false,"is_authorized":true,"tenant":"fe8f0076-8dbf-4684-9f73-b389c33b2158","teams_deploy":null,"bot_data":null}] as any
      result.length > 0
        ? setState({
            ...state,
            workspaces: result.map((res: AgentHandoffWorkspace) =>
              res.is_authorized && !res.bot_data
                ? {
                    ...res,
                    bot_data: {
                      bot_name: '',
                      bot_full_name: '',
                      bot_full_desc: '',
                      bot_short_desc: '',
                      is_active: false,
                      is_generated: false,
                    },
                  }
                : res
            ),
            PageState: View,
            addMode: false,
            bots: bots ? bots : [],
            tokens: {
              ...state.tokens,
              assistance_id:
                bots && bots.filter((b: any) => b.name == assistantname)[0].id,
            },
          })
        : setState({
            ...state,
            addMode: false,
            PageState: NoDataFound,
            bots: bots,
            tokens: {
              ...state.tokens,
              assistance_id:
                bots && bots.filter((b: any) => b.name == assistantname)[0].id,
            },
          });
      bot.value = bots
        ? `CHAT_BOT_MODULE/BOT/${
            bots.filter((b: AssistantT) => b.name === assistantname)[0].id
          }/AGENT_BOT`
        : '';
    }
  }, [props.state]);

  const toggleActivation = async (data: AgentHandoffWorkspace) => {
    // console.log(data,'toggledata')
    // setState({ ...state, popUp: { loader: true, error: null } });

    const { client_id, client_secrect, tenant } = data;

    if (state.workspaces.length > 0 && data.teams_deploy) {
      const workspaces = state.workspaces as AgentHandoffWorkspace[];

      const teams_deploy = data.teams_deploy as {
        is_active: boolean;
        team_name: string;
        team_id: string;
        added_by: string | null;
        email_id: string | null;
        date_added: string;
      };

      putJson(
        agenHandoffUrl(
          workspaceName,
          `/${data.bot_id}/agent/activate/${data.id}/${!teams_deploy.is_active}`
        )
      )()({
        team_id: teams_deploy.team_id,
      })
        .then((res) => res.data)
        .then((res) => {
          setState({
            ...state,
            addMode: false,
            currentTeamCredentials: {
              client_id: '',
              client_secrect: '',
              tenant: '',
              tenant_type: 'multi_tenant',
              name: '',
            },
            workspaces: workspaces.map((workspace) => {
              if (workspace.id !== data.id) {
                return workspace;
              }
              return {
                ...workspace,
                teams_deploy: {
                  ...teams_deploy,
                  is_active: !teams_deploy.is_active,
                },
              };
            }),
            PageState: View,
            popUp: {
              error: null,
              loader: false,
            },
          });
        })
        .catch((err) => {
          setState({
            ...state,
            popUp: {
              error:
                err.response && err.response.data && err.response.data.message
                  ? err.response.data.message
                  : 'Something went wrong! Please try again after some time.',
              loader: false,
            },
          });
        });
    }
  };

  const onSave = () => {
    const botId = state.bots.find((e) => e.id === state.tokens.assistance_id);

    const { client_id, client_secrect, tenant, tenant_type, name } =
      state.currentTeamCredentials;

    setState({ ...state, popUp: { loader: true, error: null } });

    postJson(
      agenHandoffUrl(
        workspaceName,
        `/${state.tokens.assistance_id}/agent/save/`
      )
    )({
      client_id: client_id,
      client_secrect: client_secrect,
      tenant,
      bot_id: botId.id,
      workspace: workspaceName,
      tenant_type: tenant_type,
      is_active: false,
      name,
      description: '',
    })
      .then((res: any) => {
        setState({
          ...state,
          addMode: false,
          currentTeamCredentials: {
            client_id: '',
            client_secrect: '',
            tenant: '',
            tenant_type: 'multi_tenant',
            name: '',
          },
          workspaces: [
            ...state.workspaces,
            {
              ...res.data,
              is_authorized: false,
            },
          ],
          PageState: View,
          popUp: {
            error: null,
            loader: false,
          },
        });
      })
      .catch((err) => {
        setState({
          ...state,
          popUp: {
            error:
              err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : 'Something went wrong! Please try again after some time.',
            loader: false,
          },
        });
      });
  };
  const disablesave = () => {
    const omittedWorkspace = R.omit(
      ['bot_data', 'is_authorized'],
      state.selectedWorkspace[0]
    );
    const omittedClonedSpace = R.omit(
      ['bot_data', 'is_authorized'],
      state.clonedWorkspace[0]
    );

    return (
      JSON.stringify(omittedWorkspace) !== JSON.stringify(omittedClonedSpace)
    );
  };

  const editCRED = async (data: AgentHandoffWorkspace) => {
    // setState({ ...state, popUp: { loader: true, error: null, loaderIndex: index,  } })
    // setState({ ...state, popUp: { loader: true, error: null } });
    const omittednameWorkspace = JSON.stringify(
      R.omit(['name', 'description'], state.selectedWorkspace[0])
    );
    const omittednameClonedSpace = JSON.stringify(
      R.omit(['name', 'description'], state.clonedWorkspace[0])
    );
    const botid = data.bot_id;
    if (omittednameWorkspace === omittednameClonedSpace) {
      postJson(
        agenHandoffUrl(workspaceName, `/${data.bot_id}/agent/update/` + data.id)
      )({
        client_id: data.client_id,
        client_secrect: data.client_secrect,
        tenant: data.tenant,
        bot_id: data.bot_id,
        workspace: workspaceName,
        tenant_type: data.tenant_type,
        is_active: data.is_active,
        name: data.name,
        description: '',
      })
        .then((data) =>
          postJson(agenHandoffUrl(workspaceName, `/${botid}/agent/getAgent/`))({
            bot_id: botid,
          })
        )
        .then((res) => {
          // console.log(res.data,'result')
          const result = res.data;
          const updatedWorkspace = result.filter(
            (workspace: AgentHandoffWorkspace) => workspace.id === data.id
          );
          setState({
            ...state,
            addMode: false,
            currentTeamCredentials: {
              client_id: '',
              client_secrect: '',
              tenant: '',
              tenant_type: 'multi_tenant',
              name: '',
            },
            selectedWorkspace: JSON.parse(JSON.stringify(updatedWorkspace)),
            clonedWorkspace: JSON.parse(JSON.stringify(updatedWorkspace)),
            editIndex: null,
            editNameIndex: null,
            workspaces: result.map((w) =>
              w.id != data.id
                ? w
                : {
                    ...w,
                    name: data.name,
                    client_id: data.client_id,
                    client_secrect: data.client_secrect,
                    tenant: data.tenant,
                    tenant_type: data.tenant_type,
                    is_authorized: false,
                  }
            ),
            // PageState: View,
            popUp: {
              error: null,
              loader: false,
            },
          });
        })
        .catch((err) => {
          setState({
            ...state,
            popUp: {
              error:
                err.response && err.response.data && err.response.data.message
                  ? err.response.data.message
                  : 'Something went wrong! Please try again after some time.',
              loader: false,
            },
          });
        });
    } else {
      postJson(
        agenHandoffUrl(workspaceName, `/${data.bot_id}/agent/update/` + data.id)
      )({
        client_id: data.client_id,
        client_secrect: data.client_secrect,
        tenant: data.tenant,
        bot_id: data.bot_id,
        workspace: workspaceName,
        tenant_type: data.tenant_type,
        is_active: data.is_active,
        name: data.name,
        description: '',
        is_authorized: false,
      })
        .then((data) =>
          postJson(agenHandoffUrl(workspaceName, `/${botid}/agent/getAgent/`))({
            bot_id: botid,
          })
        )
        .then((res) => {
          // console.log(res.data,'result')
          const result = res.data;
          const updatedWorkspace = result.filter(
            (workspace: AgentHandoffWorkspace) => workspace.id === data.id
          );
          setState({
            ...state,
            addMode: false,
            currentTeamCredentials: {
              client_id: '',
              client_secrect: '',
              tenant: '',
              tenant_type: 'multi_tenant',
              name: '',
            },
            selectedWorkspace: JSON.parse(JSON.stringify(updatedWorkspace)),
            clonedWorkspace: JSON.parse(JSON.stringify(updatedWorkspace)),
            editIndex: null,
            editNameIndex: null,
            workspaces: result.map((w) =>
              w.id != data.id
                ? w
                : {
                    ...w,
                    name: data.name,
                    client_id: data.client_id,
                    client_secrect: data.client_secrect,
                    tenant: data.tenant,
                    tenant_type: data.tenant_type,
                    is_authorized: false,
                  }
            ),
            // PageState: View,
            popUp: {
              error: null,
              loader: false,
            },
          });
        })
        .catch((err) => {
          setState({
            ...state,
            popUp: {
              error:
                err.response && err.response.data && err.response.data.message
                  ? err.response.data.message
                  : 'Something went wrong! Please try again after some time.',
              loader: false,
            },
          });
        });
    }
  };

  const editName = async (data: AgentHandoffWorkspace) => {
    // setState({ ...state, popUp: { loader: true, error: null, loaderIndex: index,  } })
    setState({ ...state, popUp: { loader: true, error: null } });

    postJson(
      agenHandoffUrl(workspaceName, `/${data.bot_id}/agent/update/` + data.id)
    )({
      client_id: data.client_id,
      client_secrect: data.client_secrect,
      tenant: data.tenant,
      bot_id: data.bot_id,
      workspace: workspaceName,
      tenant_type: data.tenant_type,
      is_active: data.is_active,
      name: data.name,
      description: '',
    })
      .then((res) => {
        setState({
          ...state,
          addMode: false,
          currentTeamCredentials: {
            client_id: '',
            client_secrect: '',
            tenant: '',
            tenant_type: 'multi_tenant',
            name: '',
          },
          editIndex: null,
          editNameIndex: null,
          workspaces: state.workspaces.map((w) =>
            w.id != data.id
              ? w
              : {
                  ...w,
                  name: data.name,
                  client_id: data.client_id,
                  client_secrect: data.client_secrect,
                  tenant: data.tenant,
                  tenant_type: data.tenant_type,
                  // is_authorized: false,
                }
          ),
          PageState: View,
          popUp: {
            error: null,
            loader: false,
          },
        });
      })
      .catch((err) => {
        setState({
          ...state,
          popUp: {
            error:
              err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : 'Something went wrong! Please try again after some time.',
            loader: false,
          },
        });
      });
  };

  const addToTeams = async (data: AgentHandoffWorkspace) => {
    setState({ ...state, popUp: { loader: true, error: null } });

    const bot_data = data.bot_data as BotDataT;
    const req = {
      bot_id: data.bot_id,
      workspace: data.workspace,
      client_id: data.client_id,
      client_secrect: data.client_secrect,
      tenant: data.tenant,
      bot_short_name: bot_data.bot_name,
      bot_full_name: bot_data.bot_full_name,
      short_des: bot_data.bot_short_desc,
      full_des: bot_data.bot_full_desc,
    };

    await postJson(
      agenHandoffUrl(
        data.workspace,
        `/${data.bot_id}/agent/addToTeams/${data.id}`
      )
    )(req)
      .then((res) => {
        window.open(
          window.location.origin +
            process.env.REACT_APP_AGENT_HANDOFF +
            '/manifestdownload/' +
            res.data.file_path,
          '_blank'
        );

        const workspaces = state.workspaces.map((w) =>
          w.id != data.id
            ? w
            : {
                ...w,
                bot_data: { ...bot_data, is_active: false, is_generated: true },
              }
        );

        setState({
          ...state,
          workspaces: workspaces,
          popUp: { error: null, loader: false },
          editIndex: null,
          PageState: View,
          editBotIndex: null,
        });
      })
      .catch((err) => {
        setState({
          ...state,
          popUp: {
            error:
              err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : 'Something went wrong! Please try again after some time.',
            loader: false,
          },
        });
      });
  };

  const authorizeTeams = async (
    data: AgentHandoffWorkspace,
    redirectUrl: string
  ) => {
    setState({ ...state, popUp: { loader: true, error: null } });
    const req = {
      client_id: data.client_id,
      client_secrect: data.client_secrect,
      tenant: data.tenant,
      redirectUrl: redirectUrl,
      // redirectUrl: 'https://dev-assistant.workativ.ai/teams-v2/oauth/redirect/success​​​​​​​'
    };

    await postJson(
      agenHandoffUrl(
        data.workspace,
        `/${data.bot_id}/agent/grantConsent/${data.id}`
      )
    )(req)
      .then((res) => {
        setState({ ...state, popUp: { loader: false, error: null } });

        var childWindow: any;

        const receiveMessage = (event: any) => {
          if (event.origin === 'http://localhost:3000') return;
          window.removeEventListener('message', receiveMessage, false);
          if (event.data === 'succeeded') {
            postJson(
              agenHandoffUrl(workspaceName, `/${data.bot_id}/agent/getAgent/`)
            )({ bot_id: data.bot_id }).then((res) => {
              const result = res.data;
              const workspaces = result.map((w) =>
                w.id !== data.id
                  ? w
                  : {
                      ...w,
                      bot_data: {
                        bot_name: w.bot_data?.bot_name
                          ? w.bot_data.bot_name
                          : '',
                        bot_full_name: w.bot_data?.bot_full_name
                          ? w.bot_data.bot_full_name
                          : '',
                        bot_full_desc: w.bot_data?.bot_full_desc
                          ? w.bot_data.bot_full_desc
                          : '',
                        bot_short_desc: w.bot_data?.bot_short_desc
                          ? w.bot_data.bot_short_desc
                          : '',
                        is_active: w.bot_data?.is_active
                          ? w.bot_data.is_active
                          : false,
                        is_generated: w.bot_data?.is_generated
                          ? w.bot_data.is_generated
                          : false,
                      },
                      is_authorized: true,
                    }
              );
              const updatedWorkspace = workspaces.filter(
                (workspace: AgentHandoffWorkspace) => workspace.id === data.id
              );
              setState({
                ...state,
                workspaces: workspaces,
                selectedWorkspace: JSON.parse(JSON.stringify(updatedWorkspace)),
                clonedWorkspace: JSON.parse(JSON.stringify(updatedWorkspace)),
              });
            });
          } else {
            setState({
              ...state,
              popUp: {
                error:
                  'Authorization failed due to invalid credentials/invalid redirection url',
                loader: false,
              },
            });
          }
          childWindow.close();
        };

        childWindow = window.open(
          res.data.url,
          'authwind',
          'width=550,height=500,0,status=0,'
        );

        window.addEventListener('message', receiveMessage, false);
      })
      .catch((err) => {
        setState({
          ...state,
          popUp: {
            error:
              err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : 'Something went wrong! Please try again after some time.',
            loader: false,
          },
        });
      });
  };

  const copyToClipBoard = (target: string) => {
    const copyText = document.getElementById(target);

    if (copyText) {
      const el = copyText as HTMLInputElement;
      el.select();
      el.setSelectionRange(0, 99999);
      document.execCommand('copy');
      target == 'redirectionInput'
        ? setState({
            ...state,
            redirectionClipBoard: el.value,
            endpointClipBoard: null,
          })
        : setState({
            ...state,
            endpointClipBoard: el.value,
            redirectionClipBoard: null,
          });
    }
  };

  function deleteWorkspace() {
    setState({ ...state, popup: { ...state.popup, showPopupLoader: true } });
    deleteJson(
      agenHandoffUrl(
        workspaceName,
        `/${state.tokens.assistance_id}/agent/delete/` + state.editAppId
      )
    )()
      .then((res) => {
        setState({
          ...state,
          currentTeamCredentials: {
            client_id: '',
            client_secrect: '',
            tenant: '',
            tenant_type: 'multi_tenant',
            name: '',
          },
          popup: { ...state.popup, showPopupLoader: false },
          showDeleteModal: false,
          editIndex: null,
          workspaces: workspaces.filter((w) => w.id != state.editAppId),
          addMode: false,
          tokens: { ...state.tokens, client_id: '', tenant: '' },
        });
      })
      .catch(() => {
        setState({
          ...state,
          popup: {
            showPopupLoader: false,
            modalError: {
              info: 'Something went wrong! Please try again.',
              error: true,
            },
          },
        });
      });
  }

  function renderDeleteModal() {
    if (state.showDeleteModal) {
      return (
        <Modal showPopupLoader={state.popup.showPopupLoader}>
          <ErrorBoundary
            error={state.popup.modalError}
            render={(err: any, info: any) => {
              return (
                err && (
                  <Error.Delete
                    onClick={() =>
                      setState({
                        ...state,
                        showDeleteModal: false,
                        editIndex: null,
                        popup: {
                          showPopupLoader: false,
                          modalError: {
                            info: null,
                            error: false,
                          },
                        },
                      })
                    }
                    info={info}
                  />
                )
              );
            }}
          >
            <React.Fragment>
              <h2>Delete Configuration</h2>
              <p>Are you sure to delete selected Configuration?</p>{' '}
            </React.Fragment>

            <ButtonContainer>
              <Button
                primary
                type="button"
                onClick={() =>
                  setState({
                    ...state,
                    showDeleteModal: false,
                    editIndex: null,
                    tokens: { ...state.tokens, client_id: '', tenant: '' },
                  })
                }
              >
                <label>No</label>
              </Button>
              <Button type="button" onClick={() => deleteWorkspace()}>
                <label>Yes</label>
              </Button>
            </ButtonContainer>
          </ErrorBoundary>
        </Modal>
      );
    }

    return null;
  }
  // const setInputFocus=()=> {
  //   inputRef && inputRef.focus();
  // }
  const setDeleteMode = (value: string) => {
    const { PageState, workspaces } = state;
    const selected = workspaces.filter(
      (x: AgentHandoffWorkspace) => x.id === value
    );
    const deleteSingle = {
      action: Action.Delete,
      name: selected[0].name,
    };

    setState({
      ...state,
      editAppId: value,
      showDeleteModal: true,
      tokens: {
        ...state.tokens,
        assistance_id: selected[0].bot_id,
        client_id: selected[0].client_id,
        tenant: selected[0].tenant,
      },
    });
    // const deleteMultiple: LineDeleteT = {
    //   action: Action.LineDelete,
    //   selectedItems:
    //     PageState.action == Action.Select ? PageState.selectedItems : [],
    //   name: '',
    // };

    //

    // const setMode =
    //   PageState.action == Action.Select ? deleteMultiple : deleteSingle;

    // setState({ ...state, PageState: Delete });
  };
  const setToEditMode = (value: string) => {
    // const { workspaces } = state;
    const selected: AgentHandoffWorkspace[] = workspaces.filter(
      (x: AgentHandoffWorkspace) => x.id === value
    );
    // const action = {
    //   action: Action.Edit,
    //   name: selected[0].name,
    // };
    const findIndex: number = workspaces.findIndex(
      (x) => x.id === selected[0].id
    );
    setState(
      {
        ...state,
        PageState: Edit,
        selectedWorkspace: JSON.parse(JSON.stringify(selected)),
        clonedWorkspace: JSON.parse(JSON.stringify(selected)),
        editAppId: selected[0].id,
        editBotIndex: findIndex,
        // selectedBotData: [R.clone(selected[0].bot_data)],
      }
      // () => setInputFocus()
    );
  };
  const { workspaces, PageState } = state;

  const botData = state.currentEditingTeamCredentials.bot_data as any;

  const bot = useSignal('');

  const writePermission = usePermissionSignal(
    props.match.params.workspacename,
    bot,
    'WRITE'
  );
  const deletePermission = usePermissionSignal(
    props.match.params.workspacename,
    bot,
    'DELETE'
  );

  if (PageState.action === Action.Loading)
    return <Loader.PageLoader show={true} />;

  return (
    <React.Fragment>
      <Header>
        {[
          <HeaderChild type="first" key={0}>
            <div className="indent_left">
              <div className="indent_title">
                {/* <span className="buton_back" onClick={() => window.history.back()}>
                                    <RightArrowWithOutCircle />
                                </span>{' '} */}
                Agent handover
              </div>
              <div className="indent_sub_title">
                Catalog (
                {state.workspaces.length == 0
                  ? '0'
                  : state.workspaces.length < 10
                  ? '0' + state.workspaces.length
                  : state.workspaces.length}
                )
              </div>
            </div>
            {/* {PageState.action === Action.View &&
            state.workspaces.length > 0 &&
            state.editIndex == null &&
            state.editNameIndex == null &&
            state.editBotIndex == null ? ( */}
            {writePermission.value && (
              <Button
                style={{ marginRight: 0 }}
                primary
                onClick={() =>
                  setState({ ...state, addMode: true, PageState: View })
                }
              >
                <span className="add_teams_header">
                  <AddIcon />
                </span>
                <label style={{ top: '1.5px' }}>Add agent bot</label>
              </Button>
            )}
            {/* ) : null} */}
          </HeaderChild>,
          <HeaderChild type="second">
            <VideoButtonB id={'CONFIGURE_AGENT_HANDOVER_USE_IN_MS_TEAMS'} />
          </HeaderChild>,
        ]}
      </Header>
      <section className="slack_buton agent_handoff">
        <div className="col-md-12 col-lg-12 col-sm-12 col-12 slack_right_btn">
          <ErrorBoundary
            unauthorized={PageState.action === Action.Unauthorized}
            noDataFound={{
              show:
                PageState.action === 'RESTRICTED PLAN' ||
                PageState.action === Action.NoDataFound,
              noDatainfo:
                PageState.action === 'RESTRICTED PLAN'
                  ? '<p>Agent Handover is not available in your current plan. Upgrade to ‘Professional Plan’ to use this feature.</p>'
                  : '<p>Experience seamless ‘agent handover’ of chatbot to <br></br>live agents with complete user context and <br></br>conversation history. </p>',
              onClick: () => {},
              ButtonElement:
                PageState.action === 'RESTRICTED PLAN' ? (
                  <button
                    className="nodata_found_button"
                    onClick={() => {
                      props.history.push(
                        `/workspace/${props.match.params.workspacename}/billing/plans?from=billing`
                      );
                    }}
                    style={{
                      width: 'max-content',
                      padding: '0px 20px',
                    }}
                  >
                    <span>Upgrade Now</span>
                  </button>
                ) : (
                  <Button
                    onClick={() =>
                      setState({ ...state, addMode: true, PageState: View })
                    }
                  >
                    <span
                      className="add_teams_header"
                      style={{ width: 18, height: 18 }}
                    >
                      <AddIcon />
                    </span>
                    <label style={{ color: '#333' }}>
                      Configure agent handover
                    </label>
                  </Button>
                ),
            }}
            // ):(
            //   noDataFound={{
            //     show: PageState.action === Action.NoDataFound,
            //     noDatainfo:
            //       '<p>Extend your chatbot experience on MS teams.<br/>Deploy your chatbot on your MS Teams easily with simple steps. Click "add to teams" to get started. </p>',
            //     onClick: () => {},
            //     ButtonElement: (
            //       <Button
            //         onClick={() =>
            //           setState({ ...state, addMode: true, PageState: View })
            //         }
            //       >
            //         <span
            //           className="add_teams"
            //           style={{ width: 18, height: 18 }}
            //         >
            //           <TeamsIcon />
            //         </span>
            //         <label style={{ color: '#333' }}>Add to teams</label>
            //       </Button>
            //     ),
            //   }}
            // )}
            error={
              PageState.action !== Action.ErrorPage
                ? { error: null, info: null }
                : {
                    error: true,
                    info: 'Server error!<br />Please try again after sometime.',
                  }
            }
            render={(err: any, info: any) => {
              return (
                err && (
                  <Error.PageLoadingError
                    onClick={() => {
                      props.retry();
                    }}
                    info={info}
                    btnName="Retry"
                  />
                )
              );
            }}
          >
            {/* ) : PageState.action === Action.NoDataFound && (
              // <ErrorBoundary
              unauthorized={PageState.action === Action.Unauthorized}
              noDataFound={{
                show: PageState.action === Action.NoDataFound,
                noDatainfo:
                  '<p>Extend your chatbot experience on MS teams.<br/>Deploy your chatbot on your MS Teams easily with simple steps. Click "add to teams" to get started. </p>',
                onClick: () => {},
                ButtonElement: (
                  <Button
                    onClick={() =>
                      setState({ ...state, addMode: true, PageState: View })
                    }
                  >
                    <span
                      className="add_teams"
                      style={{ width: 18, height: 18 }}
                    >
                      <TeamsIcon />
                    </span>
                    <label style={{ color: '#333' }}>Add to teams</label>
                  </Button>
                ),
              }}
              error={
                PageState.action !== Action.ErrorPage
                  ? { error: null, info: null }
                  : {
                      error: true,
                      info: 'Server error!<br />Please try again after sometime.',
                    }
              }
              render={(err: any, info: any) => {
                return (
                  err && (
                    <Error.PageLoadingError
                      onClick={() => {
                        props['retry']();
                      }}
                      info={info}
                      btnName="Retry"
                    />
                  )
                );
              }}
            >
          )} */}
            <TabelWrapper>
              <TableContainer>
                <Table>
                  <Thead>
                    <Tr>
                      <Th size="4">Name</Th>
                      {/* <Th size="3">Description</Th> */}
                      <Th size="3">Status</Th>
                      <Th size="3">Date</Th>
                      <Th size="2">Options</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {state.workspaces.map(
                      (data: AgentHandoffWorkspace, i: number) => {
                        // let label = searching
                        //   ? `<h5 style='padding-top:${
                        //       PageState.action == Action.Select ? 18 : 0
                        //     }px'>` +
                        //     data.name +
                        //     '</h5>'
                        //   : `<h5 style='padding-top:${
                        //       PageState.action == Action.Select ? 18 : 0
                        //     }px'>` +
                        //     data.name +
                        //     '</h5>';
                        return (
                          <Tr
                            className={
                              data.id == state.editAppId ? 'is_active' : null
                            }
                            onClick={() =>
                              writePermission.value
                                ? setToEditMode(data.id)
                                : console.log('notworking')
                            }
                          >
                            <List
                              key={i}
                              render={(show: boolean, ToolBox: any) => {
                                return (
                                  <>
                                    <Td size="4" className={'list_flow_hr'}>
                                      {/* <ListSelectBox
                                              show={show}
                                              isSelected={isSelected(
                                                data.name
                                              )}
                                              onClick={selectTeam}
                                              value={data.name}
                                            >
                                              {data.name}
                                            </ListSelectBox> */}
                                      <div
                                        className="search_card_list"
                                        onClick={() =>
                                          writePermission.value &&
                                          setToEditMode(data.id)
                                        }
                                      >
                                        {/* {Parser(label)} */}
                                        {data.name}
                                      </div>
                                    </Td>
                                    {/* <Td size="3" className="list_flow_p">
                                            <p className="listite_centre">
                                              {data.description}
                                            </p>
                                          </Td> */}
                                    <Td size="3" className="list_flow_p">
                                      <p className="listite_centre">
                                        {data.is_authorized === false
                                          ? 'Draft'
                                          : !data.teams_deploy
                                          ? 'Draft'
                                          : data.teams_deploy.is_active
                                          ? 'Active'
                                          : 'Inactive'}
                                      </p>
                                    </Td>
                                    <Td size="3" className="list_flow_span">
                                      <span className="entity_span">
                                        {/* {data.teams_deploy ?
                                                  formatDateInCard(
                                                   data.teams_deploy.date_added)
                                                    :
                                                    "--"
                                                } */}
                                        {formatDateInCard(data.updated_at)}
                                      </span>
                                      <span>|</span>
                                      <span className="entity_span">
                                        {/* {data.teams_deploy ? 
                                                formatAMPM(
                                                    data.teams_deploy.date_added)
                                                    :
                                                    "--"
                                                } */}
                                        {formatAMPM(data.updated_at)}
                                      </span>
                                      {data.client_secret_active === false ? (
                                        <span className="alerticon">
                                          <Tooltip
                                            className="target customTip"
                                            zIndex={10000}
                                            arrowSize={8}
                                            tagName="span"
                                            content={
                                              'The client secret has expired/changed. Please update it for the bot to work.'
                                            }
                                            distance={5}
                                          >
                                            <WarningAlertIcon />
                                          </Tooltip>
                                        </span>
                                      ) : (
                                        ''
                                      )}
                                    </Td>
                                    <Td size="2">
                                      {state.PageState.action !==
                                        Action.Select &&
                                        state.PageState.action !==
                                          Action.LineDelete && (
                                          <ToolBox>
                                            {writePermission.value && (
                                              <span
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setToEditMode(data.id);
                                                }}
                                              >
                                                <EditIcon />
                                              </span>
                                            )}
                                            {data.teams_deploy === null &&
                                              deletePermission.value && (
                                                <span
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    setDeleteMode(data.id);
                                                  }}
                                                >
                                                  <DeleteIcon />
                                                </span>
                                              )}
                                          </ToolBox>
                                        )}
                                    </Td>
                                  </>
                                );
                              }}
                            />
                          </Tr>
                        );
                      }
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </TabelWrapper>

            {state.addMode ? (
              <Wrapper className="create_dialog_popup">
                <PopupWrapper>
                  <PopupHeader>
                    <HeaderLeft minWidth={300}>
                      Agent handover
                      <Tooltip
                        className="target customTip"
                        zIndex={10000}
                        arrowSize={8}
                        tagName="span"
                        content="Experience seamless ‘agent handover’ of chatbot to live agents with complete user context and conversation history."
                        distance={5}
                      >
                        <InfoIcon />
                      </Tooltip>
                      <a
                        href="https://help.workativ.com/knowledgebase/microsoft-teams/"
                        target="_blank"
                      >
                        <Tooltip
                          className="target customTip"
                          zIndex={10000}
                          arrowSize={8}
                          tagName="span"
                          content="View help document"
                          distance={5}
                        >
                          <QaIconWithCircle />
                        </Tooltip>
                      </a>
                    </HeaderLeft>
                    <HeaderRight>
                      {/* <span
                            className={'handoff-icon'}
                            style={{
                              background: '#DFDDFB',
                              borderRadius: '8px',
                              padding: '6px 12px',
                              marginLeft: '10px',
                            }}
                          >
                            <SubPanelIcons.AgentHandoff />
                            <label>Teams</label>
                          </span>
                            <div className="help_link_icon">
                              <a
                                href="https://help.workativ.com/knowledgebase/configure-agent-handover/"
                                target="_blank"
                              >
                                <div className="item help_tab">
                                  <div className="img">
                                    <HelpIcon />
                                  </div>
                                  <span className="side_menu_hvr">Help</span>
                                </div>
                              </a>
                            </div> */}
                      <VideoLink
                        // id={'SET_CONDITIONS_FOR_A_DIALOG'}
                        text={'How to create'}
                      />
                    </HeaderRight>
                  </PopupHeader>
                  <Paragraph>
                    Configure agent handover for your chatbot
                  </Paragraph>
                  <div className="m_h_cc_container">
                    <InputWrapper className="bg_slack_cc bg_slack_cc_one ">
                      <div className="slack_buton_8">
                        {/* <p>Name your agent handover chatbot</p> */}
                      </div>
                      <InputContainer>
                        <Input
                          value={state.currentTeamCredentials.name}
                          onChange={(event: any) =>
                            setState({
                              ...state,
                              currentTeamCredentials: {
                                ...state.currentTeamCredentials,
                                name: event.target.value,
                              },
                              popUp: {
                                error: null,
                                loader: false,
                              },
                            })
                          }
                          id="agentName"
                        />
                        <InputContent>Chatbot name</InputContent>
                      </InputContainer>
                    </InputWrapper>
                    <div className="bg_slack_cc bg_slack_cc_three">
                      <div className="chat_channel_subTitle">
                        <h4>
                          Add following details in your azure portal
                          <Tooltip
                            className="target customTip"
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content="You will need to add the following urls in your azure portal when creating teams app"
                            distance={5}
                          >
                            <InfoIcon />
                          </Tooltip>
                        </h4>
                        {state.showDetails ? (
                          <div
                            className="img"
                            onClick={() => {
                              setState({
                                ...state,
                                showDetails: false,
                              });
                            }}
                          >
                            <MinusIcon />
                          </div>
                        ) : (
                          <div
                            className="img"
                            onClick={() => {
                              setState({
                                ...state,
                                showDetails: true,
                              });
                            }}
                          >
                            <AddIcon />
                          </div>
                        )}
                      </div>
                      {state.showDetails && (
                        <>
                          <InputContainer className="slack_buton_box_12">
                            <Textarea
                              disabled
                              type="text"
                              name="redirectionURL"
                              className={
                                state.currentTeamCredentials.client_id.length
                                  ? ''
                                  : 'editor_btn_disabled'
                              }
                              value={
                                window.location.origin +
                                process.env.REACT_APP_AGENT_EXTERNAL_ROUTE +
                                '/' +
                                'oauth/redirect/success' +
                                '/' +
                                workspaceName +
                                '/' +
                                state.tokens.assistance_id +
                                '/' +
                                state.currentTeamCredentials.client_id
                              }
                              // disabled={
                              //   !state.currentTeamCredentials.client_id.length
                              // }
                              id="redirectionInput"
                            />
                            <InputContent>Redirection URL :</InputContent>
                          </InputContainer>
                          {/* <InputWrapper className="bg_slack_cc bg_slack_cc_one "> */}
                          <InputContainer>
                            <Textarea
                              disabled
                              type="text"
                              name="redirectionURL"
                              className={
                                state.currentTeamCredentials.client_id.length
                                  ? ''
                                  : 'editor_btn_disabled'
                              }
                              value={
                                window.location.origin +
                                agentHandoffBackendIntegraion(
                                  workspaceName,
                                  state.tokens.assistance_id
                                )
                              }
                              id="endpointInput"
                              // disabled
                            />
                            <InputContent>Message Endpoint :</InputContent>
                          </InputContainer>
                        </>
                      )}
                    </div>

                    <div className="bg_slack_cc bg_slack_cc_three">
                      <div className="chat_channel_subTitle">
                        <h4>
                          Provide app details from your azure portal
                          <Tooltip
                            className="target customTip"
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content="You will need to add the following details from your azure portal available in your teams app"
                            distance={5}
                          >
                            <InfoIcon />
                          </Tooltip>
                        </h4>

                        {state.showAppDetails ? (
                          <div
                            className="img"
                            onClick={() => {
                              setState({
                                ...state,
                                showAppDetails: false,
                              });
                            }}
                          >
                            <MinusIcon />
                          </div>
                        ) : (
                          <div
                            className="img"
                            onClick={() => {
                              setState({
                                ...state,
                                showAppDetails: true,
                              });
                            }}
                          >
                            <AddIcon />
                          </div>
                        )}
                      </div>
                      {state.showAppDetails && (
                        <>
                          <InputContainer>
                            <Input
                              type="text"
                              value={state.currentTeamCredentials.client_id}
                              onChange={(event: any) =>
                                setState({
                                  ...state,
                                  currentTeamCredentials: {
                                    ...state.currentTeamCredentials,
                                    client_id: event.target.value,
                                  },
                                  popUp: {
                                    error: null,
                                    loader: false,
                                  },
                                })
                              }
                            />
                            <InputContent>Client ID :</InputContent>
                          </InputContainer>
                          <InputContainer>
                            <Input
                              type="text"
                              value={
                                state.currentTeamCredentials.client_secrect
                              }
                              onChange={(event: any) =>
                                setState({
                                  ...state,
                                  currentTeamCredentials: {
                                    ...state.currentTeamCredentials,
                                    client_secrect: event.target.value,
                                  },
                                  popUp: {
                                    error: null,
                                    loader: false,
                                  },
                                })
                              }
                            />
                            <InputContent>Client Secret :</InputContent>
                          </InputContainer>
                          <InputContainer>
                            <Input
                              type="text"
                              value={state.currentTeamCredentials.tenant}
                              onChange={(event: any) =>
                                setState({
                                  ...state,
                                  currentTeamCredentials: {
                                    ...state.currentTeamCredentials,
                                    tenant: event.target.value,
                                  },
                                  popUp: {
                                    error: null,
                                    loader: false,
                                  },
                                })
                              }
                            />
                            <InputContent>Tenant ID :</InputContent>
                          </InputContainer>
                          <InputContainer className="dialog_name_alert_content">
                            <div>
                              <Input
                                type="text"
                                name="appId"
                                value={
                                  state.currentTeamCredentials.tenant_type ==
                                  'multi_tenant'
                                    ? 'Multi tenant'
                                    : 'Single tenant'
                                }
                                onClick={() =>
                                  setState({
                                    ...state,
                                    openTenantDropDown: true,
                                  })
                                }
                              />
                              <div className="arrow_up_form">
                                <DownArrowCircle />
                              </div>
                            </div>
                            <InputContent
                              style={{
                                top: 10,
                                fontSize: 10,
                              }}
                            >
                              Select Tenant Type
                            </InputContent>
                          </InputContainer>
                          {state.openTenantDropDown && (
                            <Downshift
                              isOpen={true}
                              onOuterClick={() => {
                                setTimeout(() => {
                                  setState({
                                    ...state,
                                    openTenantDropDown: false,
                                  });
                                });
                              }}
                            >
                              {() => (
                                <div className="slack_buton_4 integration_dropdown">
                                  <ul>
                                    <li
                                      onClick={() => {
                                        setState({
                                          ...state,
                                          currentTeamCredentials: {
                                            ...state.currentTeamCredentials,
                                            tenant_type: 'multi_tenant',
                                          },
                                          openTenantDropDown: false,
                                        });
                                      }}
                                    >
                                      Multi tenant
                                    </li>
                                    <li
                                      onClick={() => {
                                        setState({
                                          ...state,
                                          currentTeamCredentials: {
                                            ...state.currentTeamCredentials,
                                            tenant_type: 'single_tenant',
                                          },
                                          openTenantDropDown: false,
                                        });
                                      }}
                                    >
                                      Single tenant
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </Downshift>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <ButtonContainer
                    style={{ background: 'none', paddingBottom: 0 }}
                  >
                    <Button
                      primary
                      onClick={() => {
                        state.workspaces.length
                          ? setState({
                              ...state,
                              addMode: false,
                              popUp: {
                                error: null,
                                loader: false,
                              },
                              currentTeamCredentials: {
                                client_id: '',
                                client_secrect: '',
                                tenant: '',
                                tenant_type: 'multi_tenant',
                                name: '',
                              },
                            })
                          : setState({
                              ...state,
                              addMode: false,
                              PageState: NoDataFound,
                              popUp: {
                                error: null,
                                loader: false,
                              },
                              currentTeamCredentials: {
                                client_id: '',
                                client_secrect: '',
                                tenant: '',
                                tenant_type: 'multi_tenant',
                                name: '',
                              },
                            });
                      }}
                      // className={
                      //     state.currentTeamCredentials.client_id !== '' ||
                      //     state.currentTeamCredentials.client_secrect !== '' ||
                      //     state.currentTeamCredentials.tenant !== ''
                      //         ? ''
                      //         : 'btn-deactivaed'
                      // }
                    >
                      <label>Cancel</label>
                    </Button>
                    <Button
                      disabled={state.disableButton}
                      className={
                        state.currentTeamCredentials.client_id !== '' &&
                        state.currentTeamCredentials.client_secrect !== '' &&
                        state.currentTeamCredentials.tenant !== '' &&
                        state.currentTeamCredentials.name !== '' &&
                        state.tokens.assistance_id !== '' &&
                        !state.popUp.error
                          ? ''
                          : ' btn-deactivaed'
                      }
                      onClick={() => onSave()}
                    >
                      <label>Save</label>
                    </Button>

                    {state.popUp.loader && state.addMode && (
                      <span className="connect_account_loading">
                        <img
                          src={LoadingSvgImage}
                          style={{
                            marginLeft: 10,
                            marginTop: 0,
                          }}
                        />
                      </span>
                    )}
                    {state.popUp.error && state.addMode && (
                      <span className="connect_account_failed">
                        <div className="error_input_show error_input_show_add">
                          <WarningAlertIcon />
                          <span
                            className="error_menu_hvr"
                            style={{
                              top: -5,
                              left: 20,
                            }}
                          >
                            {state.popUp.error}
                          </span>
                        </div>
                      </span>
                    )}
                  </ButtonContainer>
                  {/* </div>
                    </div>
                    </div>
            </div> */}
                </PopupWrapper>
              </Wrapper>
            ) : (
              state.PageState.action === Action.Edit &&
              Object.keys(state.selectedWorkspace).length > 0 &&
              workspaceUI()
            )}
          </ErrorBoundary>
        </div>
      </section>
      {renderDeleteModal()}
    </React.Fragment>
  );

  function workspaceUI() {
    const workspaces = state.selectedWorkspace as AgentHandoffWorkspace[];
    return workspaces.map((data, i) => (
      // <div className="box_slack" key={i}>
      <Wrapper className="create_dialog_popup">
        <PopupWrapper>
          {/* <div className="row"> */}
          {/* <div className="slack_buton_box_12 cc_header_icons"> */}
          <PopupHeader>
            <HeaderLeft minWidth={300}>
              {/* <span className="color_header_left">
                {(i + 1).toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })}
              </span>{' '} */}
              Agent handover
              <Tooltip
                className="target customTip"
                zIndex={10000}
                arrowSize={8}
                tagName="span"
                content="Experience seamless ‘agent handover’ of chatbot to live agents with complete user context and conversation history."
                distance={5}
              >
                <InfoIcon />
              </Tooltip>
              <a
                href="https://help.workativ.com/knowledgebase/microsoft-teams/"
                target="_blank"
              >
                <Tooltip
                  className="target customTip"
                  zIndex={10000}
                  arrowSize={8}
                  tagName="span"
                  content="View help document"
                  distance={5}
                >
                  <QaIconWithCircle />
                </Tooltip>
              </a>
            </HeaderLeft>
            <HeaderRight>
              <VideoLink
                // id={'SET_CONDITIONS_FOR_A_DIALOG'}
                text={'How to create'}
              />
              {/* Configure agent handover <br></br>
              for your chatbot
              </HeaderLeft>
              <HeaderRight>
              <span
                className={'handoff-icon'}
                style={{
                  background: '#DFDDFB',
                  borderRadius: '8px',
                  padding: '6px 12px',
                  marginLeft: '10px',
                }}
              >
                <SubPanelIcons.AgentHandoff />
                <label>Teams</label>
              </span>
            
            <div className="item_icons_right"> */}
              {/* {state.editIndex == null &&
              state.editNameIndex == null &&
              !data.is_authorized ? (
                <div className="item help_tab">
                  <div className="item help_tab">
                    <div
                      className="img"
                      onClick={() =>
                        setState({
                          ...state,
                          // editIndex: true,
                          showDeleteModal: true,
                          editAppId: data.id,
                          tokens: {
                            ...state.tokens,
                            assistance_id: data.bot_id,
                            client_id: data.client_id,
                            tenant: data.tenant,
                          },
                        })
                      }
                    >
                      <DeleteIcon />
                    </div>
                    <span className="side_menu_hvr">Delete</span>
                  </div>
                </div>
              ) : null} */}
              {/* <div className="help_link_icon">
                <a
                  href="https://help.workativ.com/knowledgebase/configure-agent-handover/"
                  target="_blank"
                >
                  <div className="item help_tab">
                    <div className="img">
                      <HelpIcon />
                    </div>
                    <span className="side_menu_hvr">Help</span>
                  </div>
                </a>
              </div>
            </div> */}
            </HeaderRight>
            <span
              className="slack_close_btn"
              onClick={() => {
                setState({
                  ...state,
                  PageState: View,
                  selectedWorkspace: [],
                  // showDetails: true,
                  showAppDetails: true,
                  // showWorkspaceDetails: true,
                  editIndex: null,
                  clonedWorkspace: [],
                  editAppId: '',
                  // selectedBotData: '',
                  popUp: {
                    error: null,
                    loader: false,
                    // loaderIndex: null,
                    // errorIndex: null,
                  },
                });
              }}
            >
              <CloseIcon />
            </span>
          </PopupHeader>
          <Paragraph>Configure agent handover for your chatbot</Paragraph>
          {/* </div> */}
          <div className="m_h_cc_container">
            <InputWrapper className="bg_slack_cc bg_slack_cc_one ">
              {/* <div className="slack_buton_8">
                                                            <p>Name your agent handover chatbot</p>
                                                        </div> */}

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {/* <h4>Name your agent handover chatbot</h4> */}
                {/* <div className="slack_buton_8">
                  <p>Name your agent handover chatbot</p>
                </div> */}
                {/* {state.editNameIndex == null 
                // && data.is_authorized == false 
                ? (
                  <div className="item help_tab agent_handover_edit">
                    <div
                      className="img"
                      onClick={() =>
                        setState({
                          ...state,
                          editNameIndex: i,
                          editIndex: null,
                          editBotIndex: null,
                          currentEditingTeamCredentials: data,
                          popUp: { error: null, loader: false },
                        })
                      }
                    >
                      <EditIconCircle />
                    </div>
                    {/* <span className="side_menu_hvr">Edit</span> */}
                {/* </div>
                ) : null} */}
              </div>
              <InputContainer>
                <Input
                  value={data.name}
                  onChange={(e: any) =>
                    setState({
                      ...state,
                      selectedWorkspace: state.selectedWorkspace.map((yes) =>
                        Object.assign(yes, {
                          name: e.target.value,
                        })
                      ),
                    })
                  }
                  // currentEditingTeamCredentials: {
                  //   ...state.currentEditingTeamCredentials,
                  //   name: event.target.value,
                  // },

                  id="agentName"
                />
                <InputContent>Chatbot Name</InputContent>
              </InputContainer>
              {/* {state.editNameIndex != i ? (
                <InputContainer>
                  <Input
                    type="text"
                    value={data.name}
                    className="btn_deactivated"
                  />
                  <InputContent>Enter name</InputContent>
                </InputContainer>
              ) : (
                <InputContainer>
                  <Input
                    value={state.currentEditingTeamCredentials.name}
                    onChange={(event: any) =>
                      setState({
                        ...state,
                        currentEditingTeamCredentials: {
                          ...state.currentEditingTeamCredentials,
                          name: event.target.value,
                        },
                      })
                    }
                    id="agentName"
                  />
                  <InputContent>Enter name</InputContent>
                </InputContainer>
              )} */}
            </InputWrapper>
            <div className="bg_slack_cc bg_slack_cc_three">
              <div className="chat_channel_subTitle">
                {/* <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              > */}
                <h4>
                  Provide app details from your azure portal
                  <Tooltip
                    className="target customTip"
                    zIndex={10000}
                    arrowSize={8}
                    tagName="span"
                    content="You will need to add the following details from your azure portal available in your teams app"
                    distance={5}
                  >
                    <InfoIcon />
                  </Tooltip>
                </h4>
                {state.showAppDetails ? (
                  <div
                    className="img"
                    onClick={() => {
                      setState({
                        ...state,
                        showAppDetails: false,
                      });
                    }}
                  >
                    <MinusIcon />
                  </div>
                ) : (
                  <div
                    className="img"
                    onClick={() => {
                      setState({
                        ...state,
                        showAppDetails: true,
                      });
                    }}
                  >
                    <AddIcon />
                  </div>
                )}
                {/* {state.editIndex === null ? (
                  //  && data.is_authorized == false
                  <div className="item help_tab">
                    <div
                      className="img"
                      onClick={() =>
                        setState({
                          ...state,
                          editIndex: i,
                          editAppId: data.client_id,
                          editNameIndex: null,
                          editBotIndex: null,
                          currentEditingTeamCredentials: data,
                          popUp: { error: null, loader: false },
                        })
                      }
                    >
                      <EditIconCircle />
                    </div>
                    {/* <span className="side_menu_hvr">Edit</span> */}
                {/* </div>
                ) : null}  */}
              </div>
              {state.showAppDetails && (
                <>
                  {state.selectedWorkspace[0].teams_deploy ? (
                    <InputContainer className="dialog_name_alert_content">
                      <InputText
                        type="text"
                        name="clientId"
                        value={data.client_id}
                        disabled
                      />
                      <InputContent className="client_input_content">
                        Client ID
                      </InputContent>
                    </InputContainer>
                  ) : (
                    <InputContainer className="dialog_name_alert_content">
                      <Input
                        type="text"
                        name="clientId"
                        value={data.client_id}
                        onChange={(e) =>
                          setState({
                            ...state,
                            selectedWorkspace: state.selectedWorkspace.map(
                              (yes) =>
                                Object.assign(yes, {
                                  client_id: e.target.value,
                                })
                            ),
                          })
                        }
                      />
                      <InputContent>Client ID</InputContent>
                    </InputContainer>
                  )}
                  {/* <div className="slack_buton_box_12">
                <div className="">
                  {state.editIndex != i ||
                  state.currentEditingTeamCredentials.teams_deploy != null ? (
                    <InputWrapper>
                      <InputContainer>
                        <Input
                          type="text"
                          value={data.client_id}
                          className="btn_deactivated"
                        />
                        <InputContent>Client ID</InputContent>
                      </InputContainer>
                    </InputWrapper>
                  ) : (
                    <InputWrapper>
                      <InputContainer>
                        <Input
                          type="text"
                          value={state.currentEditingTeamCredentials.client_id}
                          onChange={(e: any) =>
                            setState({
                              ...state,
                              currentEditingTeamCredentials: {
                                ...state.currentEditingTeamCredentials,
                                client_id: e.target.value,
                              },
                              popUp: {
                                error: null,
                                loader: false,
                              },
                            })
                          }
                        />
                        <InputContent>Client ID</InputContent>
                      </InputContainer>
                    </InputWrapper>
                  )}
                </div>
              </div> */}
                  <InputContainer className="dialog_name_alert_content">
                    <Input
                      type="text"
                      name="clientSecret"
                      value={data.client_secrect}
                      onChange={(e) =>
                        setState({
                          ...state,
                          selectedWorkspace: state.selectedWorkspace.map(
                            (yes) =>
                              Object.assign(yes, {
                                client_secrect: e.target.value,
                              })
                          ),
                        })
                      }
                    />
                    <InputContent>Client Secret</InputContent>
                    {data.client_secret_active === false ? (
                      <span className="info_icon info_icon_Adaptive alerticon">
                        <Tooltip
                          className="target customTip"
                          zIndex={10000}
                          arrowSize={8}
                          tagName="span"
                          content={
                            'The client secret has expired/changed. Please update it for the bot to work.'
                          }
                          distance={5}
                        >
                          <WarningAlertIcon />
                        </Tooltip>
                      </span>
                    ) : (
                      ''
                    )}
                  </InputContainer>
                  {/* <div className="slack_buton_box_12">
                <div className="">
                  {state.editIndex != i ? (
                    <InputWrapper>
                      <InputContainer>
                        <Input
                          type="text"
                          value={data.client_secrect}
                          className="btn_deactivated"
                        />
                        <InputContent>Client Secret</InputContent>
                      </InputContainer>
                    </InputWrapper>
                  ) : (
                    <InputWrapper>
                      <InputContainer>
                        <Input
                          type="text"
                          value={
                            state.currentEditingTeamCredentials.client_secrect
                          }
                          onChange={(e: any) =>
                            setState({
                              ...state,
                              currentEditingTeamCredentials: {
                                ...state.currentEditingTeamCredentials,
                                client_secrect: e.target.value,
                              },
                            })
                          }
                        />
                        <InputContent>Client Secret</InputContent>
                      </InputContainer>
                    </InputWrapper>
                  )} */}
                  {/* </div>
              </div> */}
                  {state.selectedWorkspace[0].teams_deploy ? (
                    <InputContainer className="dialog_name_alert_content">
                      <InputText
                        type="text"
                        name="tenentID"
                        value={data.tenant}
                        disabled
                      />
                      <InputContent className="client_input_content">
                        Tenant ID
                      </InputContent>
                    </InputContainer>
                  ) : (
                    <InputContainer className="dialog_name_alert_content">
                      <Input
                        type="text"
                        name="tenentID"
                        value={data.tenant}
                        onChange={(e) =>
                          setState({
                            ...state,
                            selectedWorkspace: state.selectedWorkspace.map(
                              (yes) =>
                                Object.assign(yes, {
                                  tenant: e.target.value,
                                })
                            ),
                          })
                        }
                      />
                      <InputContent>Tenant ID</InputContent>
                    </InputContainer>
                  )}
                  {/* <div className="slack_buton_box_12">
                <div className="">
                  {state.editIndex != i ||
                  state.currentEditingTeamCredentials.teams_deploy != null ? (
                    <InputWrapper>
                      <InputContainer>
                        <Input
                          type="text"
                          value={data.tenant}
                          className="btn_deactivated"
                        />
                        <InputContent>Tenant ID</InputContent>
                      </InputContainer>
                    </InputWrapper>
                  ) : (
                    <InputWrapper>
                      <InputContainer>
                        <Input
                          type="text"
                          value={state.currentEditingTeamCredentials.tenant}
                          onChange={(e: any) =>
                            setState({
                              ...state,
                              currentEditingTeamCredentials: {
                                ...state.currentEditingTeamCredentials,
                                tenant: e.target.value,
                              },
                            })
                          }
                        />
                        <InputContent>Tenant ID</InputContent>
                      </InputContainer>
                    </InputWrapper>
                  )}
                </div>
              </div> */}
                  {/* <div className="slack_buton_box_12"> */}
                  <InputContainer className="dialog_name_alert_content">
                    <div>
                      <Input
                        type="text"
                        name="appId"
                        value={
                          data.tenant_type == 'multi_tenant'
                            ? 'Multi tenant'
                            : 'Single tenant'
                        }
                        onClick={() =>
                          setState({
                            ...state,
                            openTenantDropDown: true,
                          })
                        }
                      />
                      <div className="arrow_up_form">
                        <DownArrowCircle />
                      </div>
                    </div>
                    <InputContent
                      style={{
                        top: 10,
                        fontSize: 10,
                      }}
                    >
                      Select Tenant Type
                    </InputContent>
                  </InputContainer>
                  {state.openTenantDropDown && (
                    <Downshift
                      isOpen={true}
                      onOuterClick={() => {
                        setTimeout(() => {
                          setState({
                            ...state,
                            openTenantDropDown: false,
                          });
                        });
                      }}
                    >
                      {() => (
                        <div className="slack_buton_4 integration_dropdown">
                          <ul>
                            <li
                              onClick={(e) => {
                                setState({
                                  ...state,
                                  selectedWorkspace:
                                    state.selectedWorkspace.map((yes) =>
                                      Object.assign(yes, {
                                        tenant_type: 'multi_tenant',
                                      })
                                    ),
                                  openTenantDropDown: false,
                                });
                              }}
                            >
                              Multi tenant
                            </li>
                            <li
                              onClick={(e) => {
                                setState({
                                  ...state,
                                  selectedWorkspace:
                                    state.selectedWorkspace.map((yes) =>
                                      Object.assign(yes, {
                                        tenant_type: 'single_tenant',
                                      })
                                    ),
                                  openTenantDropDown: false,
                                });
                              }}
                            >
                              Single tenant
                            </li>
                          </ul>
                        </div>
                      )}
                    </Downshift>
                  )}
                  {/* </div>                                                       */}
                  {/* {state.editIndex != i ||
              state.currentEditingTeamCredentials.teams_deploy != null ? (
                <div className="slack_buton_box_12">
                  <div className="slack_buton_8">
                    <p>Select Tenant Type</p>
                  </div>
                  <div className="slack_buton_4 btn_deactivated">
                    <h6 className="input_integration">
                      {data.tenant_type == 'multi_tenant'
                        ? 'Multi tenant'
                        : 'Single tenant'}
                    </h6>
                    <div className="arrow_up_form">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 10 5.75"
                      >
                        <title />
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Layer_1-2" data-name="Layer 1">
                            <path d="M5,5.75H5a.78.78,0,0,1-.53-.21L.22,1.29a.74.74,0,0,1,0-1,.75.75,0,0,1,1,0L5,4,8.74.22a.74.74,0,0,1,1,1L5.51,5.54A.78.78,0,0,1,5,5.75Z" />
                          </g>
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
              ) : (
                <MultiTenantDropDown
                  state={state}
                  setState={setState}
                  value={state.currentEditingTeamCredentials.tenant_type}
                  onChange={(value: 'single_tenant' | 'multi_tenant') => {
                    setState({
                      ...state,
                      currentEditingTeamCredentials: {
                        ...state.currentEditingTeamCredentials,
                        tenant_type: value,
                      },
                      openTenantDropDown: false,
                    });
                  }}
                />
              )} */}
                </>
              )}
            </div>

            <div className="bg_slack_cc bg_slack_cc_three">
              <div className="chat_channel_subTitle">
                <h4>
                  Add following details in your azure portal
                  <Tooltip
                    className="target customTip"
                    zIndex={10000}
                    arrowSize={8}
                    tagName="span"
                    content="You will need to add the following urls in your azure portal when creating teams app"
                    distance={5}
                  >
                    <InfoIcon />
                  </Tooltip>
                </h4>

                {state.showDetails ? (
                  <div
                    className="img"
                    onClick={() => {
                      setState({
                        ...state,
                        showDetails: false,
                      });
                    }}
                  >
                    <MinusIcon />
                  </div>
                ) : (
                  <div
                    className="img"
                    onClick={() => {
                      setState({
                        ...state,
                        showDetails: true,
                      });
                    }}
                  >
                    <AddIcon />
                  </div>
                )}
              </div>
              {state.showDetails && (
                <>
                  <InputContainer className="dialog_name_alert_content bg-grey">
                    <Textarea
                      disabled
                      type="text"
                      name="redirectionURL"
                      value={
                        window.location.origin +
                        process.env.REACT_APP_AGENT_EXTERNAL_ROUTE +
                        '/' +
                        'oauth/redirect/success' +
                        '/' +
                        workspaceName +
                        '/' +
                        data.bot_id +
                        '/' +
                        data.client_id
                      }
                    />
                    <InputContent>Redirection URL :</InputContent>
                  </InputContainer>

                  <InputContainer className="dialog_name_alert_content bg-grey">
                    <Textarea
                      type="text"
                      disabled
                      name="messageEndpoint"
                      value={
                        window.location.origin +
                        agentHandoffBackendIntegraion(
                          workspaceName,
                          data.bot_id
                        )
                      }
                    />
                    <InputContent>Message Endpoint :</InputContent>
                  </InputContainer>
                  {/* <div className="slack_buton_box_12">
                <div className="slack_buton_8">
                  <p></p>
                </div>
                <div className="slack_buton_4">
                  <div className="content_slack_input">
                    {window.location.origin +
                      process.env.REACT_APP_AGENT_EXTERNAL_ROUTE +
                      '/' +
                      'oauth/redirect/success' +
                      '/' +
                      workspaceName +
                      '/' +
                      data.bot_id +
                      '/' +
                      data.client_id}
                  </div>
                  <div className="floating_content">Redirection URL :</div>
                </div>
              </div>

              <div className="slack_buton_box_12">
                <div className="slack_buton_4">
                  <div className="content_slack_input">
                    {window.location.origin +
                      agentHandoffBackendIntegraion(workspaceName, data.bot_id)}
                  </div>
                  <div className="floating_content">Message Endpoint :</div>
                </div>
              </div> */}
                </>
              )}
            </div>
            {data.teams_deploy && (
              <div className="bg_slack_cc bg_slack_cc_three">
                <div className="chat_channel_subTitle">
                  <h4>Workspace Details</h4>
                  {state.showWorkspaceDetails ? (
                    <div
                      className="img"
                      onClick={() => {
                        setState({
                          ...state,
                          showWorkspaceDetails: false,
                        });
                      }}
                    >
                      <MinusIcon />
                    </div>
                  ) : (
                    <div
                      className="img"
                      onClick={() => {
                        setState({
                          ...state,
                          showWorkspaceDetails: true,
                        });
                      }}
                    >
                      <AddIcon />
                    </div>
                  )}
                </div>
                {state.showWorkspaceDetails && (
                  <>
                    {/* <div className="slack_buton_box_12">
                    <div className=""> */}
                    <InputWrapper>
                      <InputContainer className="dialog_name_alert_content">
                        <Input
                          type="text"
                          value={data.teams_deploy.team_name}
                          className="btn_deactivated"
                        />
                        <InputContent>Team Name</InputContent>
                      </InputContainer>
                    </InputWrapper>
                    {/* </div>
                  </div> */}
                    {/* <div className="slack_buton_box_12">
                    <div className=""> */}
                    <InputWrapper>
                      <InputContainer>
                        <Input
                          type="text"
                          value={data.teams_deploy.added_by}
                          className="btn_deactivated"
                        />
                        <InputContent>Added By</InputContent>
                      </InputContainer>
                    </InputWrapper>
                    {/* </div>
                  </div> */}
                    {/* <div className="slack_buton_box_12">
                    <div className=""> */}
                    <InputWrapper>
                      <InputContainer>
                        <Input
                          type="text"
                          value={data.teams_deploy.email_id}
                          className="btn_deactivated"
                        />
                        <InputContent>Email ID</InputContent>
                      </InputContainer>
                    </InputWrapper>
                    {/* </div>
                  </div> */}
                    {data.teams_deploy.date_added && (
                      // <div className="slack_buton_box_12">
                      //   <div className="">
                      <InputWrapper>
                        <InputContainer>
                          <Input
                            type="text"
                            value={formatDateInCard(
                              data.teams_deploy.date_added
                            )}
                            className="btn_deactivated"
                          />
                          <InputContent>Deploy Date</InputContent>
                        </InputContainer>
                      </InputWrapper>
                      //   </div>
                      // </div>
                    )}
                  </>
                )}
              </div>
            )}

            {data.bot_data && (
              <div className="bg_slack_cc bg_slack_cc_three">
                <div className="chat_channel_subTitle">
                  {/* <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                > */}
                  <h4>Agent Bot Details</h4>
                  {state.showChatDetails ? (
                    <div
                      className="img"
                      onClick={() => {
                        setState({
                          ...state,
                          showChatDetails: false,
                        });
                      }}
                    >
                      <MinusIcon />
                    </div>
                  ) : (
                    <div
                      className="img"
                      onClick={() => {
                        setState({
                          ...state,
                          showChatDetails: true,
                        });
                      }}
                    >
                      <AddIcon />
                    </div>
                  )}
                  {/* {data.bot_data &&
                  (!data.teams_deploy || data.teams_deploy == null) &&
                  state.editBotIndex == null ? (
                      <div className="item help_tab">
                      <div
                        className="img"
                        onClick={() =>
                          setState({
                            ...state,
                            editBotIndex: i,
                            currentEditingTeamCredentials: data,
                            popUp: { error: null, loader: false },
                          })
                        }
                      >
                        <EditIconCircle />
                      </div>
                      {/* <span className="side_menu_hvr">Edit</span> */}
                  {/* </div>
                  ) : null} */}
                </div>
                {state.showChatDetails && (
                  <>
                    {/* <div className="slack_buton_box_12">
                  <div className=""> */}
                    {/* {(data.bot_data.bot_name.length == 0 || */}
                    {data.teams_deploy !== null ? (
                      // state.editBotIndex != i ? (
                      <InputWrapper>
                        <InputContainer>
                          <Input
                            type="text"
                            value={data.bot_data.bot_name}
                            className="btn_deactivated"
                          />
                          <InputContent>Short Name</InputContent>
                        </InputContainer>
                      </InputWrapper>
                    ) : (
                      <InputWrapper>
                        <InputContainer>
                          <Input
                            type="text"
                            value={data.bot_data.bot_name}
                            onChange={(e: any) =>
                              setState({
                                ...state,
                                selectedWorkspace: state.selectedWorkspace.map(
                                  (yes) =>
                                    Object.assign(yes, {
                                      bot_data: {
                                        ...data.bot_data,
                                        bot_name: e.target.value,
                                      },
                                    })
                                ),
                              })
                            }
                          />
                          <InputContent>Short Name</InputContent>
                        </InputContainer>
                      </InputWrapper>
                    )}
                    {/* </div>
                </div> */}
                    {/* <div className="slack_buton_box_12">
                  <div className=""> */}
                    {/* {(data.bot_data.bot_full_name.length == 0 ||
                      data.bot_data.is_generated) ?( */}
                    {data.teams_deploy !== null ? (
                      // state.editBotIndex != i ? (
                      <InputWrapper>
                        <InputContainer>
                          <Input
                            type="text"
                            value={data.bot_data.bot_full_name}
                            className="btn_deactivated"
                          />
                          <InputContent>Full Name</InputContent>
                        </InputContainer>
                      </InputWrapper>
                    ) : (
                      <InputWrapper>
                        <InputContainer>
                          <Input
                            type="text"
                            value={data.bot_data.bot_full_name}
                            onChange={(e: any) =>
                              setState({
                                ...state,
                                selectedWorkspace: state.selectedWorkspace.map(
                                  (yes) =>
                                    Object.assign(yes, {
                                      bot_data: {
                                        ...data.bot_data,
                                        bot_full_name: e.target.value,
                                      },
                                    })
                                ),
                              })
                            }
                          />
                          <InputContent>Full Name</InputContent>
                        </InputContainer>
                      </InputWrapper>
                    )}
                    {/* </div>
                </div> */}
                    {/* <div className="slack_buton_box_12">
                  <div className=""> */}
                    {/* {(data.bot_data.bot_short_desc.length == 0 ||
                      data.bot_data.is_generated) ?( */}
                    {data.teams_deploy !== null ? (
                      // state.editBotIndex != i ? (
                      <InputWrapper>
                        <InputContainer>
                          <Input
                            type="text"
                            value={data.bot_data.bot_short_desc}
                            className="btn_deactivated"
                          />
                          <InputContent>
                            Short Description (80 characters or less)
                          </InputContent>
                        </InputContainer>
                      </InputWrapper>
                    ) : (
                      <InputWrapper>
                        <InputContainer>
                          <Input
                            type="text"
                            maxLength={80}
                            value={data.bot_data.bot_short_desc}
                            onChange={(e: any) =>
                              setState({
                                ...state,
                                selectedWorkspace: state.selectedWorkspace.map(
                                  (yes) =>
                                    Object.assign(yes, {
                                      bot_data: {
                                        ...data.bot_data,
                                        bot_short_desc: e.target.value,
                                      },
                                    })
                                ),
                              })
                            }
                          />
                          <InputContent>
                            Short Description (80 characters or less)
                          </InputContent>
                        </InputContainer>
                      </InputWrapper>
                    )}
                    {/* </div>
                </div> */}
                    {/* <div className="slack_buton_box_12"> */}
                    {/* <div className="slack_buton_4 slack_textarea_wrapper agent_textarea"> */}
                    {/* {(data.bot_data.bot_full_desc.length == 0 ||
                  data.bot_data.is_generated) ?( */}
                    {data.teams_deploy !== null ? (
                      // state.editBotIndex != i ? (
                      <InputWrapper>
                        <InputContainer>
                          <Textarea
                            type="text"
                            value={data.bot_data.bot_full_desc}
                            className="btn_deactivated"
                          />
                          <InputContent style={{ background: 'transparent' }}>
                            Long Description (4000 characters or less)
                          </InputContent>
                        </InputContainer>
                      </InputWrapper>
                    ) : (
                      <InputWrapper>
                        <InputContainer>
                          <Textarea
                            className="agent_bot_long_desc_box"
                            style={{ background: '#fff' }}
                            maxLength={4000}
                            value={data.bot_data.bot_full_desc}
                            onChange={(e: any) =>
                              setState({
                                ...state,
                                selectedWorkspace: state.selectedWorkspace.map(
                                  (yes) =>
                                    Object.assign(yes, {
                                      bot_data: {
                                        ...data.bot_data,
                                        bot_full_desc: e.target.value,
                                      },
                                    })
                                ),
                              })
                            }
                          />
                          <InputContent className="agent client_input_content teams_above_80chars inputContent">
                            Long Description (4000 characters or less)
                          </InputContent>
                        </InputContainer>
                      </InputWrapper>
                    )}
                  </>
                )}
                {/* </div> */}
                {/* </div> */}
              </div>
            )}
          </div>
          {/* </div> */}

          <div className="slack_buton_center slack_btn_icon slack_buton_error">
            <div className="slack_buton_center">
              <Button
                onClick={() => editCRED(data)}
                className={
                  disablesave()
                    ? // this.state.popUp.errors
                      ''
                    : 'hidden'
                }
              >
                <span className="slack-save-btn">Save</span>
              </Button>
            </div>
            {/* {console.log(state.editIndex != null || state.editNameIndex != null,
          state.editBotIndex == null ,
          !state.showDeleteModal && state.editIndex == i,"saveworking" )}
          {(state.editIndex != null || state.editNameIndex != null) &&
          //   (!data.teams_deploy || data.teams_deploy == null) &&
          state.editBotIndex == null &&
          !state.showDeleteModal && state.editIndex == i 
            // || state.editNameIndex == i)
             ? (
            <ButtonContainer>
              <Button
                primary
                onClick={() => {
                  setState({
                    ...state,
                    editIndex: null,
                    editNameIndex: null,
                    currentEditingTeamCredentials: initialWorkspaceData,
                    popUp: {
                      error: null,
                      loader: false,
                    },
                  });
                }}
              >
                <label>Cancel</label>
              </Button>
              <Button
                onClick={() => editCRED(state.currentEditingTeamCredentials)}
                disabled={
                  state.addMode ||
                  state.editBotIndex != null ||
                  state.currentEditingTeamCredentials.client_id == '' ||
                  state.currentEditingTeamCredentials.client_secrect == '' ||
                  state.currentEditingTeamCredentials.tenant == '' ||
                  state.currentEditingTeamCredentials.bot_id == '' ||
                  state.currentEditingTeamCredentials.name == '' ||
                  state.popUp.error != null
                }
                className={
                  // state.addMode ||
                  // state.editBotIndex != null ||
                  // state.currentEditingTeamCredentials.client_id === '' ||
                  // state.currentEditingTeamCredentials.client_secrect == '' ||
                  // state.currentEditingTeamCredentials.tenant == '' ||
                  // state.currentEditingTeamCredentials.bot_id == '' ||
                  // state.currentEditingTeamCredentials.name == '' ||
                  // state.popUp.error
                  disablesave()
                    ? 'btn_deactivated'
                    : ''
                }
              >
                <label>Save</label>
              </Button>
              {state.popUp.error && !state.addMode && (
                <span className="connect_account_failed">
                  <div className="error_input_show">
                    <WarningAlertIcon />
                    <span
                      className="error_menu_hvr"
                      style={{
                        top: 0,
                        left: 20,
                      }}
                    >
                      {state.popUp.error}
                    </span>
                  </div>
                </span>
              )}
              {state.popUp.loader && !state.addMode && (
                <span className="connect_account_loading">
                  <img src={LoadingSvgImage} />
                </span>
              )}
            </ButtonContainer>
          ) : state.editNameIndex == i ? (
            <ButtonContainer>
              <Button
                primary
                onClick={() => {
                  setState({
                    ...state,
                    editIndex: null,
                    editNameIndex: null,
                    currentEditingTeamCredentials: initialWorkspaceData,
                    popUp: {
                      error: null,
                      loader: false,
                    },
                  });
                }}
              >
                <label>Cancel</label>
              </Button>
              <Button
                onClick={() => editName(state.currentEditingTeamCredentials)}
                disabled={
                  state.addMode ||
                  state.editBotIndex != null ||
                  state.currentEditingTeamCredentials.client_id == '' ||
                  state.currentEditingTeamCredentials.client_secrect == '' ||
                  state.currentEditingTeamCredentials.tenant == '' ||
                  state.currentEditingTeamCredentials.bot_id == '' ||
                  state.currentEditingTeamCredentials.name == '' ||
                  state.popUp.error != null
                }
                className={
                  // state.addMode ||
                  // state.editBotIndex != null ||
                  // state.currentEditingTeamCredentials.client_id === '' ||
                  // state.currentEditingTeamCredentials.client_secrect == '' ||
                  // state.currentEditingTeamCredentials.tenant == '' ||
                  // state.currentEditingTeamCredentials.bot_id == '' ||
                  // state.currentEditingTeamCredentials.name == '' ||
                  // state.popUp.error
                  disablesave()
                    ? 'btn_deactivated'
                    : ''
                }
              >
                <label>Save</label>
              </Button>
              {state.popUp.error && !state.addMode && (
                <span className="connect_account_failed">
                  <div className="error_input_show">
                    <WarningAlertIcon />
                    <span
                      className="error_menu_hvr"
                      style={{
                        top: 0,
                        left: 20,
                      }}
                    >
                      {state.popUp.error}
                    </span>
                  </div>
                </span>
              )}
              {state.popUp.loader && !state.addMode && (
                <span className="connect_account_loading">
                  <img src={LoadingSvgImage} />
                </span>
              )}
            </ButtonContainer>
          ) :  */}
            {!data.is_authorized ? (
              <>
                <Button
                  style={{ position: 'relative', top: 6 }}
                  onClick={() =>
                    authorizeTeams(
                      data,
                      window.location.origin +
                        process.env.REACT_APP_AGENT_EXTERNAL_ROUTE +
                        '/' +
                        'oauth/redirect/success' +
                        '/' +
                        workspaceName +
                        '/' +
                        data.bot_id +
                        '/' +
                        data.client_id
                    )
                  }
                  // disabled={state.addMode || state.editBotIndex != null}
                  // className={
                  //   state.addMode || state.editBotIndex != null
                  //     ? 'btn-deactivaed'
                  //     : ''
                  // }
                >
                  <label>Authorize</label>
                </Button>
                {state.popUp.error && !state.addMode && (
                  <span className="connect_account_failed">
                    <div className="error_input_show">
                      <WarningAlertIcon />
                      <span
                        className="error_menu_hvr"
                        style={{
                          top: 0,
                          left: 20,
                        }}
                      >
                        {state.popUp.error}
                      </span>
                    </div>
                  </span>
                )}
                {state.popUp.loader && !state.addMode && (
                  <span className="connect_account_loading">
                    <img src={LoadingSvgImage} />
                  </span>
                )}
              </>
            ) : data.bot_data &&
              (!data.teams_deploy || data.teams_deploy == null) ? (
              state.editBotIndex != null && state.editBotIndex == i ? (
                <>
                  {/* // <ButtonContainer> */}
                  {/* <Button
                  primary
                  onClick={() => {
                    setState({
                      ...state,
                      editBotIndex: null,
                      currentEditingTeamCredentials: initialWorkspaceData,
                      popUp: {
                        error: null,
                        loader: false,
                      },
                    });
                  }}
                >
                  <label>Cancel</label>
                </Button> */}
                  <Button
                    onClick={() => addToTeams(data)}
                    disabled={
                      data.bot_data.bot_name.length == 0 ||
                      data.bot_data.bot_full_name.length == 0 ||
                      data.bot_data.bot_short_desc.length == 0 ||
                      data.bot_data.bot_full_desc.length == 0
                    }
                    className={
                      data.bot_data.bot_name.length == 0 ||
                      data.bot_data.bot_full_name.length == 0 ||
                      data.bot_data.bot_short_desc.length == 0 ||
                      data.bot_data.bot_full_desc.length == 0
                        ? 'btn-deactivaed'
                        : ''
                    }
                  >
                    {/* <TeamsIcon /> */}
                    <label>Download</label>
                  </Button>

                  {state.popUp.error && !state.addMode && (
                    <span className="connect_account_failed">
                      <div className="error_input_show">
                        <WarningAlertIcon />
                        <span
                          className="error_menu_hvr"
                          style={{
                            top: 0,
                            left: 20,
                          }}
                        >
                          {state.popUp.error}
                        </span>
                      </div>
                    </span>
                  )}
                  {state.popUp.loader && !state.addMode && (
                    <span className="connect_account_loading">
                      <img src={LoadingSvgImage} />
                    </span>
                  )}
                </>
              ) : (
                <>
                  <Button
                    onClick={() => addToTeams(data)}
                    disabled={
                      data.bot_data.bot_name.length == 0 ||
                      data.bot_data.bot_full_name.length == 0 ||
                      data.bot_data.bot_short_desc.length == 0 ||
                      data.bot_data.bot_full_desc.length == 0
                    }
                    className={
                      data.bot_data.bot_name.length == 0 ||
                      data.bot_data.bot_full_name.length == 0 ||
                      data.bot_data.bot_short_desc.length == 0 ||
                      data.bot_data.bot_full_desc.length == 0
                        ? 'btn-deactivaed'
                        : ''
                    }
                  >
                    {/* <TeamsIcon /> */}
                    <label>Download</label>
                  </Button>
                  {state.popUp.error && !state.addMode && (
                    <span className="connect_account_failed">
                      <div className="error_input_show">
                        <WarningAlertIcon />
                        <span
                          className="error_menu_hvr"
                          style={{
                            top: 0,
                            left: 20,
                          }}
                        >
                          {state.popUp.error}
                        </span>
                      </div>
                    </span>
                  )}
                  {state.popUp.loader && !state.addMode && (
                    <span className="connect_account_loading">
                      <img src={LoadingSvgImage} />
                    </span>
                  )}
                </>
              )
            ) : data.teams_deploy &&
              data.teams_deploy.is_active &&
              data.teams_deploy.is_active ? (
              <>
                <Button
                  onClick={() => {
                    toggleActivation(data);
                  }}
                  // className={
                  //   state.addMode || state.editBotIndex != null
                  //     ? 'btn-deactivaed'
                  //     : ''
                  // }
                  // disabled={state.addMode || state.editBotIndex != null}
                >
                  <label>Deactivate</label>
                </Button>
                {state.popUp.error && !state.addMode && (
                  <span className="connect_account_failed">
                    <div className="error_input_show">
                      <WarningAlertIcon />
                      <span
                        className="error_menu_hvr"
                        style={{
                          top: 0,
                          left: 20,
                        }}
                      >
                        {state.popUp.error}
                      </span>
                    </div>
                  </span>
                )}
                {state.popUp.loader && !state.addMode && (
                  <span className="connect_account_loading">
                    <img src={LoadingSvgImage} />
                  </span>
                )}
              </>
            ) : (
              <>
                <Button
                  // className={
                  //   state.addMode || state.editBotIndex != null
                  //     ? 'btn-deactivaed'
                  //     : ''
                  // }
                  onClick={() => toggleActivation(data)}
                  // disabled={state.addMode || state.editBotIndex != null}
                >
                  <label>Activate</label>
                </Button>
                {state.popUp.error && !state.addMode && (
                  <span className="connect_account_failed">
                    <div className="error_input_show">
                      <WarningAlertIcon />
                      <span
                        className="error_menu_hvr"
                        style={{
                          top: 0,
                          left: 20,
                        }}
                      >
                        {state.popUp.error}
                      </span>
                    </div>
                  </span>
                )}
                {state.popUp.loader && !state.addMode && (
                  <span className="connect_account_loading">
                    <img src={LoadingSvgImage} />
                  </span>
                )}
              </>
            )}
          </div>
        </PopupWrapper>
      </Wrapper>
      // {/* </div> */}
    ));
  }
}

function MultiTenantDropDown(props: {
  state: any;
  setState: any;
  value: 'multi_tenant' | 'single_tenant';
  onChange: any;
}) {
  const { setState, state, value, onChange } = props;

  return (
    <div className="slack_buton_box_12">
      <div className="slack_buton_8">
        <p>Select Tenant Type</p>
      </div>
      <div
        className="slack_buton_4 "
        onClick={() =>
          setState({
            ...state,
            openTenantDropDown: true,
          })
        }
      >
        <h6 className="input_integration">
          {value == 'multi_tenant' ? 'Multi tenant' : 'Single tenant'}
        </h6>
        <div className="arrow_up_form">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 5.75">
            <title />
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <path d="M5,5.75H5a.78.78,0,0,1-.53-.21L.22,1.29a.74.74,0,0,1,0-1,.75.75,0,0,1,1,0L5,4,8.74.22a.74.74,0,0,1,1,1L5.51,5.54A.78.78,0,0,1,5,5.75Z" />
              </g>
            </g>
          </svg>
        </div>
      </div>

      {state.openTenantDropDown && (
        <Downshift
          isOpen={true}
          onOuterClick={() => {
            setTimeout(() => {
              setState({
                ...state,
                openTenantDropDown: false,
              });
            });
          }}
        >
          {() => (
            <div className="integration_dropdown">
              <ul>
                <li
                  onClick={() => {
                    onChange('multi_tenant');
                  }}
                >
                  Multi tenant
                </li>
                <li
                  onClick={() => {
                    onChange('single_tenant');
                  }}
                >
                  Single tenant
                </li>
              </ul>
            </div>
          )}
        </Downshift>
      )}
    </div>
  );
}
