import { CollectionOutputListTypeT, ObjectOutputListTypeT, OutputListTypeT } from '@/Flows/canvas/types'

import { convertObjectToArray } from '@/common/utils/utils'

//const resp = {
//data:
//'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzcsImVtYWlsIjoic2hyaS5naGF5YXRocmlAc2tpdHRlci5pbiIsIndzcyI6W3siaWQiOjE3NiwibmFtZSI6Im5pcnVwIiwib3duZXJJZCI6Mzd9LHsiaWQiOjE4OSwibmFtZSI6InNraXR0ZXIiLCJvd25lcklkIjozMn1dLCJpYXQiOjE1OTQwNDU3MzgsImV4cCI6MTU5NDA0NjYzOH0.BDu869SUFQz_8GG1_H1a59p8hR5cFqR_H-1f-FPp1dM'
//}
//Flows

export function getOutputValueRecursive(
    input: {output: ObjectOutputListTypeT | OutputListTypeT | CollectionOutputListTypeT,errors:{[key:string]:{code:string,message:string}}},
    contextValue: string[],
    collection?: CollectionOutputListTypeT
): OutputListTypeT | CollectionOutputListTypeT | null {
    if (contextValue.length == 1) {
        const inputChanged = input.output as ObjectOutputListTypeT
        if (!inputChanged || Object.keys(inputChanged).length === 0) {
            return null
        }
        if (inputChanged.properties[contextValue[0]] == undefined && collection && collection.item.properties) {
            const insideCollection = convertObjectToArray(collection.item.properties).filter(
                it => it.value.type == 'collection'
            )
            return collection.item.properties[contextValue[0]]
                ? collection.item.properties[contextValue[0]]
                : insideCollection.length > 0
                ? insideCollection[0].value.item.properties[contextValue[0]]
                : undefined
        }

        const inputWithProperties = inputChanged.properties as any
        return inputWithProperties.type == 'file' && inputWithProperties.properties
            ? inputWithProperties.properties[contextValue[0]]
            : input.errors && input.errors[contextValue[0]]? input.errors[contextValue[0]]: inputChanged.properties[contextValue[0]]
    } else {
        const [head, ...remaining] = contextValue
        const inputChanged = input.output as ObjectOutputListTypeT
        if (!inputChanged || Object.keys(inputChanged).length === 0) {
            return null
        }
        if (inputChanged.properties[head] == undefined && collection && collection.item.properties) {
            return getOutputValueRecursive({output:collection.item.properties[head] as OutputListTypeT,errors:{}}, remaining)
        }
        return getOutputValueRecursive({output:inputChanged.properties[head] as OutputListTypeT,errors:{}}, remaining)
    }
}
export function getValuesBetweenBraces(input: string) {
    let matches: string[] = []

    // const pattern = /\${(.*?)\}/g;
    // let match;
    // while ((match = pattern.exec(input)) != null) {
    //     matches.push(match[1]);
    // }
    // return matches
    let c = 0

    input && input
        .toString()
        .split(/\${(.*?)\}/g)
        .filter(Boolean)
        .forEach(e =>
            // Increase / decrease counter and push desired values to an array
            e == '(' ? c++ : e == ')' ? c-- : c > 0 ? matches.push('(' + e + ')') : matches.push(e)
        )

    return matches
}
