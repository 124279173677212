/*eslint-disable*/
import Downshift from 'downshift';
import * as React from 'react';
import { List } from '@/common/components/List';
import { ListSelectBox } from '@/common/components/SelectBox';
import { getAssistants } from '@/Assistant/Mechanisms/action';
import { makeEventEntry } from '@/Journey/JourneyMachine';
import { VideoButtonB } from '@/Videos/Videos';
import {
  ArrowIcon,
  DeleteIcon,
  EditIconCircle,
  HelpIcon,
  SlackIconCC,
  EditIcon,
  InfoIcon,
  QaIconWithCircle,
  CloseIcon,
  DropdownWithCircle,
  AddIcon,
  MinusIcon,
} from '@/common/Icons/Icons';
import { LoadingSvgImage } from '@/common/Icons/LoadingSvgImage';
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons';
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary';
import { Header, HeaderChild } from '@/common/components/Header';
import { Loader } from '@/common/components/Loader';
import { Modal } from '@/common/components/Modal';
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb';
import { MOCK_SLACK, slackResponse, slackValue } from './MockFile';
import { formatDateInCard, formatAMPM } from '@/common/utils/utils';
import { HeaderRight } from '@/common/styled/FaqPopup.Dumb';
import {
  getJson,
  postJson,
  slackIntegrationUrl,
  slackRedirectionUrl,
  deleteJson,
  watsonApiURL,
} from '@/common/utils/api-utils';
import Tooltip from 'react-tooltip-lite';
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@/common/styled/Workflow.Analytics.Dumb';
import {
  EmptyDiv,
  HeaderLeft,
  Paragraph,
  PopupContainer,
  PopupScrollHeight,
  PopupWrapper,
  SmallButton,
  Wrapper,
  Header as PopupHeader,
  Input,
  InputContainer,
  InputWrapper,
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb';
import {
  sortAnArrayByDate,
  sortAnArrayByName,
} from '@/common/utils/common.utils';
import styled from 'styled-components/macro';
import NodesWithContext from '../../../../libs/feature/assistant/src/DialogueCrew/DialogueMap/Dialogues';
import {
  Action,
  ErrorPage,
  Loading,
  Modes,
  NoDataFound,
  RequestT,
  UnauthorizedPage,
  View,
  WorkspaceT,
  Edit,
  Add,
  EditT,
  LineDeleteT,
  DeleteT,
  SlackD,
} from './types';
import { VideoLink } from '@/Videos/Videos';
import { updateNewLineValueUsingLens } from '@/Assistant/Editor/Action';
import classNames from 'classnames';
import { usePermissions } from '@/common/utils/auth-token-api-utils';

let Parser = require('html-react-parser');

export const Textarea = styled.textarea.attrs((boolean: any) => ({
  required: true
}))`
  width: 100%;
  float: left;
  padding: 26px 12px 8px 12px;
  border: 1px solid #b9b9b9;
  border-radius: 6px;
  background: transparent;
  min-height: 80px;
  font-size: 12px;
  font-family: 'Gordita-Regular';
  color: #000000;
  outline: 0;
  word-break: break-all;
  resize: none;
  cursor: auto;
  overflow: hidden;

  :hover {
      outline: 0;
      border: 1px solid #eb974e;
      box-shadow: 0px 3px 6px #00000029;
  }

  :active {
      outline: 0;
      border: 1px solid #eb974e;
      box-shadow: none;
  }
`
export const InputText = styled.textarea.attrs((boolean: any) => ({
  required: true
}))`
  width: 100%;
  float: left;
  padding: 24px 12px 8px 12px;
  border: 1px solid #b9b9b9;
  border-radius: 6px;
  background: #fff;
  height: 48px;
  font-size: 12px;
  font-family: 'Gordita-Regular';
  color: #000000;
  outline: 0;
  word-break: break-all;
  resize: none;
  cursor : auto;

  :hover {
      outline: 0;
      border: 1px solid #eb974e;
      box-shadow: 0px 3px 6px #00000029;
  }

  :active {
      outline: 0;
      border: 1px solid #eb974e;
      box-shadow: none;
  }

`

export const InputContent = styled.div`
    color: #999;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 10px;
    transition: 0.26s ease-in;
    ${Input}:focus ~ &,
    ${Input}:not(:placeholder-shown).input:not(:focus) ~ .label,
    ${Input}:valid ~ & {
        top: 10px;
        font-size: 10px;
        transition: 0.26s ease-out;
        line-height: 10px;
    }
    ${Textarea}:focus ~ &,
    ${Textarea}:not(:placeholder-shown).input:not(:focus) ~ .label,
    ${Textarea}:valid ~ & {
        top: 1px;
        font-size: 10px;
        transition: 0.26s ease-out;
        line-height: 10px;
        background: transparent;
        width: 95%;
        text-align: left;
        height: 24px;
        padding-top: 8px;
    }
`

const TabelWrapper = styled.div`
  width: 100%;
  float: left;
  .list_flow_hr label {
    float: left;
    display: flex;
    position: absolute;
    top: 18px;
    width: 16px;
    height: 16px;
    margin-bottom: 0px;
    left: -22px;
  }
  .option-input.radio {
    top: -2px;
    position: absolute;
  }

  ${Table} {
    margin-top: 0px;
  }
  ${Thead} {
    ${Tr} {
      padding-left: 32px;
      padding-right: 20px;

      ${Th} {
        :last-child {
          justify-content: flex-end;
        }
      }
    }
  }
  ${Tbody} {
    border-radius: 0px 0px 10px 10px;
    max-height: calc(100vh - 84px);
    height: auto;
    ${Tr} {
      height: 48px;
      padding-left: 32px;
      padding-right: 20px;
      .list_flow_span .entity_span {
        :first-child {
          margin-right: 3px;
          color: #000;
          font-size: 11px;
        }
        :last-child {
          margin-left: 3px;
          color: #000;
          font-size: 11px;
        }
      }
      :hover {
        ${Td}:last-child {
          display: flex;
        }
      }
      ${Td} {
        padding: 0px;
        height: 48px;
        font-size: 12px;
        font-family: 'Gordita-Regular';
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        color: #000;
        span {
          margin-right: 0px;
        }

        .list_flow_span_example span {
          font-size: 12px;
          font-family: 'Gordita-Regular';
          color: #000;

          span {
            color: #f07f31;
          }
        }
        :nth-child(1) {
          display: flex;
          padding-right: 20px;
          cursor: pointer;
          position: relative;
        }

        :nth-child(2) {
          display: flex;
          padding-right: 20px;
        }
        :last-child {
          display: none;
        }
      }
      .is_active {
        background: #ffe5d3;
      }
    }
  }
`;

type IntegrateS = {
  PageState: Modes;
  workspaces: WorkspaceT[];
  disableButton: boolean;
  addMode: boolean;
  editIndex: any;
  PageError: any;
  showErrorPopup: { error: boolean; info: string };
  openBotDropDown: boolean;
  bots: any[];
  tokens: {
    name: string;
    description: string;
    workspace: string;
    assistance_id: string;
    verification_token: string;
    client_secret: string;
    client_id: string;
  };
  editClientId: null | string;
  popUp: {
    error: null | string;
    loader: boolean;
    loaderIndex: number | null;
    errorIndex: number | null;
  };
  showDeleteModal: boolean;
  searching: boolean;
  popup: {
    showPopupLoader: boolean;
    modalError: { error: boolean; info: null | string };
  };
  name: string;
  description: string;
  selectedWorkspace: WorkspaceT[];
  clonedWorkspace:WorkspaceT[];
  showDetails: boolean;
  showAppDetails: boolean;
  showWorkspaceDetails: boolean;
  showAddButton: boolean;
};
export class SlackIntegration extends React.Component<any, IntegrateS> {
  newWindow: any = null;
  timer: any;
  workspaceName: string = this.props.match.params.workspacename;
  inputRef: any;

  state: IntegrateS = {
    PageState: Loading,
    workspaces: [],
    disableButton: false,
    addMode: false,
    editIndex: null,
    PageError: {},
    showErrorPopup: { error: false, info: '' },
    bots: [],
    openBotDropDown: false,
    tokens: {
      workspace: this.props['match'].params.workspacename,
      assistance_id: '',
      verification_token: '',
      client_secret: '',
      client_id: '',
      name: '',
      description: '',
    },
    editClientId: null,
    popUp: {
      error: '',
      loader: false,
      loaderIndex: null,
      errorIndex: null,
    },
    showDeleteModal: false,
    popup: { showPopupLoader: false, modalError: { error: false, info: null } },
    selectedWorkspace: [],
    clonedWorkspace:[],
    showDetails: true,
    showAppDetails: true,
    showWorkspaceDetails: true,
    showAddButton:this.props['permission'] as boolean,
  };

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { props } = this;

    if (props.state.type != nextProps.state.type) {
      if (nextProps.state.type == 'loading') {
        this.setState({ PageState: Loading });
      } else if (nextProps.state.type == 'error') {
        if (
          nextProps.state.error.response &&
          nextProps.state.error.response.status == 403
        ) {
          this.setState({
            PageState: UnauthorizedPage,
          });
        } else {
          this.setState({
            PageState: ErrorPage,
            PageError: {
              error: true,
              info: 'There was a problem in loading Page! <br/> Please click on "Retry"',
            },
          });
        }
      } else if (nextProps.state.type == 'success') {
        getAssistants(this.workspaceName).then((data) => {
          const res: any = nextProps.state.data;

          if (res.length > 0) {
            this.setState({
              workspaces: res,
              PageState: View,
              bots: data,
              tokens: {
                ...this.state.tokens,
              },
            });
          } else {
            this.setState({
              workspaces: res,
              PageState: NoDataFound,
              bots: data,
              tokens: {
                ...this.state.tokens,
              },
            });
          }
        });
      }
    }

    var url = new URL(window.location as any);
    var c = url.searchParams.get('error');
    if (c != null) {
      this.setState({ showErrorPopup: { error: true, info: c } });
    }
  }

  onSave = () => {
    this.setState({
      popUp: { loader: true, error: null, loaderIndex: null, errorIndex: null },
    });
    postJson(slackIntegrationUrl('/credentials'))(this.state.tokens)
      // .then((data) => slackValue)
      .then((data) =>
        getJson(slackIntegrationUrl(`/clients/${this.workspaceName}`))
      )
      .then((res) => {
        this.setState({
          addMode: false,
          workspaces: res,
          PageState: View,
          popUp: {
            error: null,
            loader: false,
            loaderIndex: null,
            errorIndex: null,
          },
          showDetails: true,
          showAppDetails: true,
          showWorkspaceDetails: true,
        });
      })

      .catch((err) => {
        this.setState({
          popUp: {
            error:
              err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : 'Something went wrong! Please try again after some time.',
            loader: false,
            loaderIndex: null,
            errorIndex: null,
          },
        });
      });
  };

  openSlack = (ws: any, index: number) => {
    const scope =
      'groups:read,usergroups:read,im:read,incoming-webhook,channels:read,users:read,bot,commands,chat:write:bot,im:read,users:read.email,team:read,files:write:user';
    if (!this.newWindow || this.newWindow.closed) {
      const strWindowFeatures =
        'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
      const url = `https://slack.com/oauth/authorize?scope=${scope}&client_id=${ws.client_id}&state=${ws.workspace}$$${ws.assistance_id}$$${ws.client_id}$$${ws.client_secrect}`;
      // console.log(url);
      this.newWindow = window.open(url, 'Welcome', strWindowFeatures);

      // this.timer = setInterval(() => {
      //     if (this.newWindow.closed) {
      //         getJson(slackIntegrationUrl(`/clients/${this.workspaceName}`)).then(res => {
      //             this.setState({
      //                 addMode: false,
      //                 workspaces: res,
      //                 PageState: View
      //             })
      //             clearInterval(this.timer)
      //             this.newWindow = null
      //         })
      //     }
      // }, 300)

      const receiveMessage = (event: any) => {
        // console.log(event, 'event');
        if (event.origin === 'http://localhost:3000') return;
        window.removeEventListener('message', receiveMessage, false);
        if (event.data == 'succeeded') {
          getJson(slackIntegrationUrl(`/clients/${this.workspaceName}`)).then(
            (res) => {
              this.setState({
                addMode: false,
                workspaces: res,
                PageState: View,
              });
            }
          );
        } else {
          this.setState({
            popUp: {
              error: event.data ? event.data : 'Authorization failed',
              loader: false,
              loaderIndex: null,
              errorIndex: index,
            },
          });
        }
        this.newWindow.close();
      };

      window.addEventListener('message', receiveMessage, false);
    } else {
      alert('Window is already opened.');
    }
  };

  editCRED = async (data: any, index: number) => {
    // console.log(data,index,this.state.editClientId,'editcredprops')
    // this.setState({
    //   popUp: {
    //     loader: true,
    //     error: null,
    //     loaderIndex: index,
    //     errorIndex: null,
    //   },
    // });
    const res = {
      client_id: data.client_id,
      workspace: this.workspaceName,
      assistance_id: data.assistance_id,
      client_secret: data.client_secrect,
      verification_token: data.verification_token,
      name: data.name,
      description: data.description,
    };

    await postJson(
      slackIntegrationUrl(
        `/credentials/${data.workspace}/${data.assistance_id}/${this.state.editClientId}`
      )
    )(res)
      .then(
        (data) => getJson(slackIntegrationUrl(`/clients/${this.workspaceName}`))
        // slackValue
      )
      .then((res) => {
        const updatedWorkspace = res.filter((workspace:WorkspaceT)=> workspace.client_id === this.state.editClientId)
        // console.log(updatedWorkspace,this.state.selectedWorkspace,'updatedWorkspace')
        this.setState({
          workspaces: res,
          // PageState: View,
          editIndex: null,
          // editClientId: null,
          popUp: {
            error: null,
            loader: false,
            loaderIndex: null,
            errorIndex: null,
          },
          showDetails: true,
          showAppDetails: true,
          showWorkspaceDetails: true,
          clonedWorkspace:JSON.parse(JSON.stringify(updatedWorkspace)),
          selectedWorkspace:JSON.parse(JSON.stringify(updatedWorkspace)),
        });
      })
      .catch((err) => {
        this.setState({
          popUp: {
            error:
              err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : 'Something went wrong! Please try again after some time.',
            loader: false,
            loaderIndex: null,
            errorIndex: index,
          },
          disableButton: false,
        });
      });
  };

  toggleActivation = async (
    botId: string,
    slack_id: string,
    activate: string,
    index: number
  ) => {
    this.setState({
      popUp: {
        loader: true,
        error: null,
        loaderIndex: index,
        errorIndex: null,
      },
    });

    await postJson(
      slackIntegrationUrl(
        `/slack/activate/${this.workspaceName}/${botId}/${slack_id}/${activate}`
      )
    )({})
      .then(
        (data) => getJson(slackIntegrationUrl(`/clients/${this.workspaceName}`))
        // slackValue
      )
      .then((res) => {
        this.setState({
          workspaces: res,
          PageState: View,
          popUp: {
            error: null,
            loader: false,
            loaderIndex: null,
            errorIndex: null,
          },
        });
        if (activate) {
          makeEventEntry(this.workspaceName, {
            event: 'EVENT::CHATCHANNEL::DEPLOYED',
            refId: botId,
          });
        }
      })
      .catch((err) => {
        this.setState({
          popUp: {
            error:
              err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : 'Something went wrong! Please try again after some time.',
            loader: false,
            loaderIndex: null,
            errorIndex: index,
          },
          disableButton: false,
        });
      });
  };

  renderErrorPopup = () => {
    const { showErrorPopup } = this.state;

    if (showErrorPopup.error) {
      return (
        <Modal error={showErrorPopup} showPopupLoader={false}>
          <ErrorBoundary
            error={showErrorPopup}
            render={(err: any, info: any) => {
              return (
                err && (
                  <Error.Delete
                    onClick={() => {
                      this.setState({
                        showErrorPopup: { error: false, info: '' },
                      });
                      this.props.history.replace(this.props.location.pathname);
                    }}
                    info={info}
                  />
                )
              );
            }}
          />
        </Modal>
      );
    } else return null;
  };

  deleteWorkspace(selected: WorkspaceT[]) {
    this.setState({ popup: { ...this.state.popup, showPopupLoader: true } });
    postJson(
      slackIntegrationUrl(
        `/delete/${this.workspaceName}/${selected[0].assistance_id}/${selected[0].client_id}`
      )
    )({})
      .then((data) =>
        getJson(slackIntegrationUrl(`/clients/${this.workspaceName}`))
      )
      .then((res) => {
        this.setState({
          popup: { ...this.state.popup, showPopupLoader: false },
          showDeleteModal: false,
          editClientId: '',
          editIndex: null,
          tokens: {
            ...this.state.tokens,
            assistance_id: '',
          },
          workspaces: res,
          PageState: View
        });
        // this.resetToViewMode();
      })
      .catch(() => {
        this.setState({
          popup: {
            showPopupLoader: false,
            modalError: {
              info: 'Something went wrong! Please try again.',
              error: true,
            },
          },
        });
      });
  }
  resetToViewMode = () => {
    this.setState({ PageState: View, editClientId: '' });
  };
  renderDeleteModal() {
    // if (this.state.showDeleteModal)
    const { PageState, selectedWorkspace, workspaces } =
      this.state;

    if (
      PageState.action == Action.Delete ||
      PageState.action == Action.LineDelete
    ) {
      const { editClientId } = this.state;
      const selected = workspaces.filter(
        (x: WorkspaceT) => x.client_id === editClientId
      );
      return (
        <Modal showPopupLoader={this.state.popup.showPopupLoader}>
          <ErrorBoundary
            error={this.state.popup.modalError}
            render={(err: any, info: any) => {
              return (
                err && (
                  <Error.Delete
                    onClick={() =>
                      this.setState({
                        showDeleteModal: false,
                        editClientId: '',
                        editIndex: null,
                        tokens: {
                          ...this.state.tokens,
                          assistance_id: '',
                        },
                        popup: {
                          showPopupLoader: false,
                          modalError: {
                            info: null,
                            error: false,
                          },
                        },
                      })
                    }
                    info={info}
                  />
                )
              );
            }}
          >
            <React.Fragment>
              <h2>Delete {PageState.name ? PageState.name : 'Chatbot'} </h2>
              <br></br>
              <p>
                Are you sure you want to delete{' '}
                {PageState.name ? PageState.name : 'Chatbot'} {' '}
                integration?
              </p>{' '}
            </React.Fragment>

            <div className="d-flex">
              <span className="mr-4">
                <Button
                  primary
                  className="text-center mx-auto"
                  type="button"
                  onClick={this.resetToViewMode}
                >
                  <span className="mx-auto intergration-delete-button-text">
                    No
                  </span>
                </Button>
              </span>
              <Button
                // className="btn btn-primary"
                className="text-center"
                type="button"
                onClick={() => this.deleteWorkspace(selected)}
              >
                <span className="mx-auto intergration-delete-button-text">
                  Yes
                </span>
              </Button>
            </div>
          </ErrorBoundary>
        </Modal>
      );
    }

    return null;
  }
  setInputFocus() {
    this.inputRef && this.inputRef.focus();
  }
  setDeleteMode = (slack: string) => {
    let { PageState,workspaces } = this.state;
    const selected = workspaces.filter(
        (x: WorkspaceT) => x.client_id === slack
      );
    this.setState({ editClientId: slack });
    const deleteMultiple: LineDeleteT = {
      action: Action.LineDelete,
      selectedItems:
        PageState.action == Action.Select ? PageState.selectedItems : [],
      name: '',
    };

    const deleteSingle: DeleteT = {
      action: Action.Delete,
      name: selected[0].name,
    };

    const setMode =
      PageState.action == Action.Select ? deleteMultiple : deleteSingle;

    this.setState({ PageState: setMode });
  };

  editSlack = (name: string) => {
    let { workspaces } = this.state;
    const selected: WorkspaceT[] = workspaces.filter(
      (x: WorkspaceT) => x.name === name
    );
    const action: EditT = {
      action: Action.Edit,
      name: selected[0].name,
      description: selected[0].description,
    };
    // this.props.history.push(
    //   `/workspace/${this.props.match.params.workspacename}/dialog/intent/${name}`
    //  );
    this.setState({ PageState: action });
  };

  // showSelection = () => {
  //   const { PageState } = this.state;
  //   PageState.action === Action.Select ||
  //     (PageState.action === Action.LineDelete &&
  //       this.state.PageState.selectedItems.length > 0);
  // };

  isSelected = (id: string) => {
    const { PageState } = this.state;
    if (
      PageState.action === Action.Select ||
      this.state.PageState.action === Action.LineDelete
    ) {
      return PageState.selectedItems.includes(id);
    }
    return false;
  };

  selectSlack = (slack: string) => {
    const modes = this.state.PageState;

    switch (modes.action) {
      case Action.Select:
        const selected: string[] = modes.selectedItems.includes(slack)
          ? modes.selectedItems.filter((x: string) => x !== slack)
          : [...modes.selectedItems, slack];

        if (selected.length) {
          this.setState({
            PageState: {
              ...modes,
              action: Action.Select,
              selectedItems: selected,
            },
          });
        } else {
          this.setState({ PageState: View });
        }
        break;

      default:
        const newSelected = [slack];
        this.setState({
          PageState: {
            ...modes,
            action: Action.Select,
            selectedItems: newSelected,
          },
        });
        break;
    }
  };

  setDialog = (nameInput: string, descriptionInput: string) => {
    this.setState({
      name: nameInput,
      description: descriptionInput,
    });
  };

  setToEditMode = (value: string) => {
    let { workspaces } = this.state;
    const selected = workspaces.filter(
      (x: WorkspaceT) => x.client_id === value
    );
    const action: EditT = {
      action: Action.Edit,
      name: selected[0].name,
      description: selected[0].description,
    };

    this.setState(
      {
        PageState: action,
        selectedWorkspace: JSON.parse(JSON.stringify(selected)),
        clonedWorkspace:JSON.parse(JSON.stringify(selected)),
        editClientId: selected[0].client_id,
      },
      () => this.setInputFocus()
    );

  };

  render() {
    const { workspaces, PageState, searching } = this.state;

    if (PageState.action === Action.Loading)
      return <Loader.PageLoader show={true} />;

    return (
      <React.Fragment>
        <Header className="chat_channel_slack">
          {[
            <HeaderChild type="first">
              <div className="indent_left">
                <div className="indent_title">
                  {/* <span
                    className="buton_back inner_pages"
                    onClick={() =>
                      this.props.history.push(
                        `/workspace/${this.props.match.params.workspacename}/integration/chat`
                      )
                    }
                  >
                    <ArrowIcon />
                    <span>Back</span>
                  </span> */}
                  <div className="indent_left">
                    <div className="indent_title">Slack Chatbot</div>
                    <div className="indent_sub_title">
                    Catalog (
                      {this.state.workspaces.length == 0
                        ? '0'
                        : this.state.workspaces.length < 10
                        ? '0' + this.state.workspaces.length
                        : this.state.workspaces.length}
                      )
                    </div>
                  </div>
                </div>
                <div className="indent_sub_title" />
              </div>
              {/* {PageState.action === Action.View ? ( */}
              <div className="btn_width_wrapper">
                {this.state.showAddButton &&<Button
                  primary
                  className="btn btn-primary add-slack"
                  // className="btn btn-primary slack_button teams_buttom_for_slack"
                  onClick={() =>
                    this.setState({
                      PageState: View,
                      addMode: true,
                      popUp: {
                        error: '',
                        errorIndex: null,
                        loader: false,
                        loaderIndex: null,
                      },
                      tokens: {
                        ...this.state.tokens,
                        assistance_id: '',
                        client_id: '',
                        client_secret: '',
                        verification_token: '',
                      },
                    })
                  }
                >
                  <span className="slack-icon slack-content-button">
                    <SlackIconCC />
                  </span>
                  <span style={{width:0, marginRight: 3}}>Add to slack</span>
                </Button>}
              </div>
              {/* // ) : null} */}
            </HeaderChild>,
            <HeaderChild type="second">
              <VideoLink id={'DEPLOY_BOT_IN_SLACK'} text={'How to'} />
            </HeaderChild>,
          ]}
        </Header>
        <section className="slack_buton">
          <div className="col-md-12 col-lg-12 col-sm-12 col-12 slack_right_btn">
            <ErrorBoundary
              unauthorized={PageState.action === Action.Unauthorized}
              error={
                PageState.action !== Action.ErrorPage
                  ? { error: null, info: null }
                  : {
                      error: true,
                      info: 'Server error!<br />Please try again after sometime.',
                    }
              }
              noDataFound={{
                show: PageState.action === Action.NoDataFound,
                noDatainfo:
                  '<p>Extend your chatbot experience on Slack.<br/>Deploy your chatbot on your Slack easily with simple steps. Click "add to slack" to get started.</p>',
                onClick: () => {},
                ButtonElement: (
                  this.state.showAddButton? <div className="btn_width_wrapper">
                    <Button
                      primary
                      className="btn btn-primary add-slack"
                      // className="btn btn-primary slack_button teams_add_workspace_btn"
                      onClick={() =>
                        this.setState({
                          PageState: View,
                          addMode: true,
                          popUp: {
                            error: '',
                            errorIndex: null,
                            loader: false,
                            loaderIndex: null,
                          },
                          tokens: {
                            ...this.state.tokens,
                            assistance_id: '',
                            client_id: '',
                            client_secret: '',
                            verification_token: '',
                          },
                        })
                      }
                    >
                      <span
                        className="slack-icon"
                        style={{ width: 18, height: 18 }}
                      >
                        <SlackIconCC />
                      </span>
                      <span style={{ marginTop: 2 }}>Add to slack</span>
                    </Button>
                  </div> :null
                ),
              }}
              render={(err: any, info: any) => {
                return (
                  err && (
                    <Error.PageLoadingError
                      onClick={() => {
                        this.props.retry();
                      }}
                      info={info}
                      btnName={'Retry'}
                    />
                  )
                );
              }}
            >
              <div className="slack_buton_box slack_inter_box">
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-sm-12 col-12 slack_buton_12">
                    {this.state.addMode ? (
                      <Wrapper className="create_dialog_popup">
                        <PopupWrapper className="slack_popup">
                          <PopupHeader>
                            <HeaderLeft minWidth={300}>
                              Chatbot deployment
                              <Tooltip
                                className="target customTip"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content={<DilogTips />}
                                distance={5}
                              >
                                <InfoIcon />
                              </Tooltip>
                              <a
                                href="https://help.workativ.com/knowledgebase/building-a-dialog/"
                                target="_blank"
                              >
                                <Tooltip
                                  className="target customTip"
                                  zIndex={10000}
                                  arrowSize={8}
                                  tagName="span"
                                  content="View help document"
                                  distance={5}
                                >
                                  <QaIconWithCircle />
                                </Tooltip>
                              </a>
                            </HeaderLeft>
                            <HeaderRight>
                              <VideoLink
                                // id={'SET_CONDITIONS_FOR_A_DIALOG'}
                                text={'How to create'}
                              />
                            </HeaderRight>
                          </PopupHeader>
                          <Paragraph>
                            Deploy chatbot in Slack.
                          </Paragraph>
                          <div className="row ">
                            <div className="m_h_cc_container">
                              <EmptyDiv>
                                <div className="bg_slack_cc bg_slack_cc_one ">
                                  <InputContainer className="dialog_name_alert_content">
                                    <div>
                                      <Input
                                        type="text"
                                        value={
                                          this.state.bots.find(
                                            (e) =>
                                              e.id ===
                                              this.state.tokens.assistance_id
                                          )
                                            ? this.state.bots.find(
                                                (e) =>
                                                  e.id ===
                                                  this.state.tokens
                                                    .assistance_id
                                              ).name
                                            : 'Select Chatbot'
                                        }
                                        onClick={() =>
                                          this.setState({
                                            openBotDropDown: true,
                                          })
                                        }
                                      />
                                      <div className="arrow_up_form">
                                        <DropdownWithCircle />
                                      </div>
                                    </div>
                                    <InputContent
                                      style={{ top: 10, fontSize: 10 }}
                                    >
                                      Select chatbot to deploy
                                    </InputContent>
                                    {this.state.openBotDropDown && (
                                      <Downshift
                                        isOpen={true}
                                        onOuterClick={() => {
                                          this.setState({
                                            openBotDropDown: false,
                                          });
                                        }}
                                      >
                                        {() => (
                                          <div className="slack_buton_4 integration_dropdown">
                                            <ul>
                                              {this.state.bots.filter(
                                                (b) => b.published_at != null
                                              ).length > 0 ? (
                                                this.state.bots
                                                  .filter(
                                                    (b) =>
                                                      b.published_at != null
                                                  )
                                                  .map((data, index) => (
                                                    <li
                                                      key={index}
                                                      onClick={() => {
                                                        this.setState({
                                                          openBotDropDown:
                                                            false,
                                                          tokens: {
                                                            ...this.state
                                                              .tokens,
                                                            assistance_id:
                                                              data.id,
                                                          },
                                                        });
                                                      }}
                                                    >
                                                      {data.name}
                                                    </li>
                                                  ))
                                              ) : (
                                                <li>No bots available</li>
                                              )}
                                            </ul>
                                          </div>
                                        )}
                                      </Downshift>
                                    )}
                                  </InputContainer>
                                  <InputContainer className="dialog_name_alert_content">
                                    <Input
                                      type="text"
                                      name="name"
                                      value={this.state.name}
                                      onChange={(event) =>
                                        this.setState({
                                          tokens: {
                                            ...this.state.tokens,
                                            name: event.target.value,
                                          },
                                        })
                                      }
                                    />
                                    <InputContent>Name</InputContent>
                                  </InputContainer>
                                  <InputContainer className="dialog_name_alert_content" style={{marginBottom: 0}}>
                                    <Input
                                      type="text"
                                      name="Description"
                                      value={this.state.description}
                                      onChange={(event) =>
                                        this.setState({
                                          tokens: {
                                            ...this.state.tokens,
                                            description: event.target.value,
                                          },
                                        })
                                      }
                                    />
                                    <InputContent>Description</InputContent>
                                  </InputContainer>
                                </div>
                              </EmptyDiv>

                              <div className="bg_slack_cc bg_slack_cc_two slack_popup">
                                <div className="chat_channel_subTitle">
                                <h4>Add following details in your Slack app
                                  {/* <Tooltip
                                    className="target customTip"
                                    zIndex={10000}
                                    arrowSize={8}
                                    tagName="span"
                                    content={"Test Content"}
                                    distance={5}
                                  >
                                    <InfoIcon />
                                  </Tooltip> */}
                                </h4>
                                {this.state.showDetails ? (
                                  <div
                                    className="img"
                                    onClick={() => {
                                      this.setState({
                                        showDetails: false,
                                      });
                                    }}
                                  >
                                    <MinusIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="img"
                                    onClick={() => {
                                      this.setState({
                                        showDetails: true,
                                      });
                                    }}
                                  >
                                    <AddIcon />
                                  </div>
                                )}
                                </div>
                                {this.state.showDetails && (
                                  <>
                                    <InputContainer className="dialog_name_alert_content bg-grey">
                                      <Textarea
                                        disabled
                                        type="text"
                                        value={
                                          window.location.origin +
                                          slackRedirectionUrl(
                                            `/slack/interactions/${
                                              this.workspaceName
                                            }/${
                                              this.state.bots.find(
                                                (e) =>
                                                  e.id ===
                                                  this.state.tokens
                                                    .assistance_id
                                              )
                                                ? this.state.bots.find(
                                                    (e) =>
                                                      e.id ===
                                                      this.state.tokens
                                                        .assistance_id
                                                  ).id
                                                : ''
                                            }`
                                          )
                                        }
                                      />
                                      <InputContent>
                                        Interactivity & Shortcuts - Request URL
                                      </InputContent>
                                    </InputContainer>
                                    <InputContainer className="dialog_name_alert_content bg-grey">
                                      <Textarea
                                        type="text"
                                        disabled
                                        value={
                                          window.location.origin +
                                          slackRedirectionUrl(
                                            `/slack/command/restart/${this.workspaceName}/${this.state.tokens.assistance_id}`
                                          )
                                        }
                                      />
                                      <InputContent>
                                        Slash Command - Restart
                                      </InputContent>
                                    </InputContainer>
                                    <InputContainer className="dialog_name_alert_content bg-grey">
                                      <Textarea
                                        type="text"
                                        disabled
                                        value={
                                          window.location.origin +
                                          slackRedirectionUrl(
                                            `/slack/command/cancel/${this.workspaceName}/${this.state.tokens.assistance_id}`
                                          )
                                        }
                                      />
                                      <InputContent>
                                        Slash Command - Cancel
                                      </InputContent>
                                    </InputContainer>
                                    <InputContainer className="dialog_name_alert_content bg-grey">
                                      <Textarea
                                        type="text"
                                        disabled
                                        value={
                                          window.location.origin +
                                          slackRedirectionUrl(
                                            `/slack/command/exit/${this.workspaceName}/${this.state.tokens.assistance_id}`
                                          )
                                        }
                                      />
                                      <InputContent>
                                        Slash Command - Exit
                                      </InputContent>
                                    </InputContainer>
                                    <InputContainer className="dialog_name_alert_content bg-grey">
                                      <Textarea
                                        type="text"
                                        disabled
                                        value={
                                          window.location.origin +
                                          slackRedirectionUrl(
                                            `/redirect/onboard`
                                          )
                                        }

                                      />
                                      <InputContent>
                                        OAuth & Permissions - Redirect URL
                                      </InputContent>
                                    </InputContainer>
                                    {/* <div className="slack_buton_box_12">
                                  <div className="slack_buton_8">
                                    <p>OAuth & Permissions - Redirect URL</p>
                                  </div>
                                  <div className="slack_buton_4">
                                    <h6>
                                      {window.location.origin +
                                        slackRedirectionUrl(
                                          `/redirect/onboard`
                                        )}
                                    </h6>
                                  </div>
                                </div> */}
                                    <InputContainer className="dialog_name_alert_content bg-grey">
                                      <Textarea
                                        type="text"
                                        disabled
                                        value={
                                          window.location.origin +
                                          slackRedirectionUrl(
                                            `/slack/event/${this.workspaceName}/${this.state.tokens.assistance_id}`
                                          )
                                        }
                                      />
                                      <InputContent>
                                        Event Subscriptions - Request URL
                                      </InputContent>
                                    </InputContainer>
                                  </>
                                )}
                              </div>
                              <div className="bg_slack_cc bg_slack_cc_three ">
                                <div className="chat_channel_subTitle">
                                <h4>Provide app details from your Slack app</h4>
                                {this.state.showAppDetails ? (
                                      <div
                                        className="img"
                                        onClick={() => {
                                          this.setState({
                                            showAppDetails: false,
                                          });
                                        }}
                                      >
                                        <MinusIcon />
                                      </div>
                                    ) : (
                                      <div
                                        className="img"
                                        onClick={() => {
                                          this.setState({
                                            showAppDetails: true,
                                          });
                                        }}
                                      >
                                        <AddIcon />
                                      </div>
                                    )}
                                    </div>
                                    {this.state.showAppDetails && (
                                      <>
                                <InputContainer className="dialog_name_alert_content">
                                  <Input
                                    type="text"
                                    name="clientId"
                                    value={this.state.tokens.client_id}
                                    onChange={(event) =>
                                      this.setState({
                                        tokens: {
                                          ...this.state.tokens,
                                          client_id: event.target.value,
                                        },
                                      })
                                    }
                                  />
                                  <InputContent>Client ID</InputContent>
                                </InputContainer>
                                <InputContainer className="dialog_name_alert_content">
                                  <Input
                                    type="text"
                                    name="clientSecret"
                                    value={this.state.tokens.client_secret}
                                    onChange={(event) =>
                                      this.setState({
                                        tokens: {
                                          ...this.state.tokens,
                                          client_secret: event.target.value,
                                        },
                                      })
                                    }
                                  />
                                  <InputContent>Client Secret</InputContent>
                                </InputContainer>
                                <InputContainer className="dialog_name_alert_content" style={{marginBottom: 0}}>
                                  <Input
                                    type="text"
                                    name="verificaionToken"
                                    value={this.state.tokens.verification_token}
                                    onChange={(event) =>
                                      this.setState({
                                        tokens: {
                                          ...this.state.tokens,
                                          verification_token:
                                            event.target.value,
                                        },
                                      })
                                    }
                                  />
                                  <InputContent>
                                    Verification Token
                                  </InputContent>
                                </InputContainer>
                                </>
                                    )}
                              </div>
                            </div>
                            <div className="slack_buton_center slack_buton_error">
                              <Button
                                primary
                                onClick={() => {
                                  this.state.workspaces.length
                                    ? this.setState({
                                        addMode: false,
                                        tokens: {
                                          ...this.state.tokens,
                                          assistance_id: '',
                                          client_id: '',
                                          client_secret: '',
                                          verification_token: '',
                                        },
                                        showDetails: true,
                                        showAppDetails: true,
                                        showWorkspaceDetails: true
                                      })
                                    : this.setState({
                                        addMode: false,
                                        PageState: NoDataFound,
                                        tokens: {
                                          ...this.state.tokens,
                                          assistance_id: '',
                                          client_id: '',
                                          client_secret: '',
                                          verification_token: '',
                                        },
                                        showDetails: true,
                                        showAppDetails: true,
                                        showWorkspaceDetails: true
                                      });
                                }}
                              >
                                <span className="slack-cancel-btn">Cancel</span>
                              </Button>
                              <Button
                                className={
                                  this.state.tokens.client_secret !== '' &&
                                  this.state.tokens.verification_token !== '' &&
                                  this.state.tokens.client_id !== '' &&
                                  this.state.tokens.assistance_id !== '' &&
                                  this.state.tokens.name !== '' &&
                                  this.state.tokens.description !== ''
                                    ? ''
                                    : 'editor_btn_disabled'
                                }
                                onClick={() => this.onSave()}
                              >
                                <span className="slack-save-btn">Save</span>
                              </Button>
                              {this.state.popUp.loader && this.state.addMode && (
                                <span className="connect_account_loading slack_loader">
                                  <img src={LoadingSvgImage} />
                                </span>
                              )}
                              {this.state.popUp.error && this.state.addMode && (
                                <span className="connect_account_failed">
                                  <div className="error_input_show error_input_show_add">
                                    <WarningAlertIcon />
                                    <span className="error_menu_hvr">
                                      {this.state.popUp.error}
                                    </span>
                                  </div>
                                </span>
                              )}
                            </div>
                          </div>
                        </PopupWrapper>
                      </Wrapper>
                    ) : null}
                    <React.Fragment>
                      <TabelWrapper>
                        <TableContainer>
                          <Table>
                            <Thead>
                              <Tr>
                                <Th size="3">Name</Th>
                                <Th size="3">Description</Th>
                                <Th size="2.5">Status</Th>
                                <Th size="2.5">Date</Th>
                                <Th size="1">Options</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {this.state.workspaces.map(
                                (data: WorkspaceT, i: number) => {
                                  let label = searching
                                    ? `<h5 style='padding-top:${
                                        PageState.action == Action.Select
                                          ? 18
                                          : 0
                                      }px'>` +
                                      data.name +
                                      '</h5>'
                                    : `<h5 style='padding-top:${
                                        PageState.action == Action.Select
                                          ? 18
                                          : 0
                                      }px'>` +
                                      data.name +
                                      '</h5>';
                                  return (
                                      <ReturnList data={data} editClientId={this.state.editClientId} editSlack={this.editSlack} i={i} isSelected={this.isSelected} label={label} selectSlack={this.selectSlack} setDeleteMode={this.setDeleteMode} setToEditMode={this.setToEditMode} state={this.state} workspacename={this.workspaceName} key={i} showAddButton={(show:boolean)=>this.setState({showAddButton:show})}/>
                                  );
                                }
                              )}
                            </Tbody>
                          </Table>
                        </TableContainer>
                      </TabelWrapper>
                    </React.Fragment>
                    {/* <section className="entity_cards">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-2 intent_list_description caret_before">
                            <span>Name</span>
                          </div>
                          <div className="col-md-2 intent_list_description">
                            <span>Description</span>
                          </div>
                          <div className="col-md-2 intent_list_description">
                            <span>Status</span>
                          </div>
                          <div className="col-md-2 intent_list_description">
                            <span>Date</span>
                          </div>
                          <div className="col-md-2 intent_list_description">
                            <span>Options</span>
                          </div>

                          {this.state.workspaces.map(
                            (data: WorkspaceT, index: number) => {
                              return (
                                <List
                                  key={index}
                                  showCheckBox
                                  checkBoxShow={false}
                                  render={(show: boolean, ToolBox: any) => {
                                    return (
                                      <div className="col-md-12 list-view-sub">
                                        <div className="main_sub">
                                          <div
                                            className={'col-md-2 list_flow_hr'}
                                          >
                                            <ListSelectBox
                                              show={show}
                                              isSelected={this.isSelected(
                                                data.name
                                              )}
                                              onClick={this.selectSlack}
                                              value={data.name}
                                            />
                                          </div>
                                          {/* <div className="col-md-2 list_flow_p">
                                            <p className="listite_centre">
                                              {data.name}
                                            </p>
                                          </div> */}
                    {/* <div className="col-md-2 list_flow_p">
                                            <p className="listite_centre">
                                              {data.description}
                                            </p>
                                          </div>
                                          <div className="col-md-2 list_flow_p">
                                            <p className="listite_centre">
                                              {data.active}
                                            </p>
                                          </div>
                                          <div className="col-md-2  list_flow_span">
                                            <span className="entity_span">
                                              {formatDateInCard(
                                                data.date_added
                                              )}
                                            </span>
                                          </div>
                                          {this.state.PageState.action !==
                                            Action.Select &&
                                            this.state.PageState.action !==
                                              Action.LineDelete && (
                                              <ToolBox>
                                                <span
                                                  onClick={() =>
                                                    this.setToEditMode(
                                                      data.name
                                                    )
                                                  }
                                                >
                                                  <EditIcon />
                                                </span>
                                                <span
                                                  onClick={() =>
                                                    this.setDeleteMode(
                                                      data.name
                                                    )
                                                  }
                                                >
                                                  <DeleteIcon />
                                                </span>
                                              </ToolBox>
                                            )}
                                        </div>
                                      </div>
                                    );
                                  }}
                                />
                              );
                            }
                          )}
                        </div>
                      </div>
                    </section> */}
                    {this.state.PageState.action === Action.Edit &&
                      Object.keys(this.state.selectedWorkspace).length > 0 &&
                      this.state.selectedWorkspace?.map(
                        (data:WorkspaceT, index: number) => (
                          <Wrapper className="create_dialog_popup">
                            <PopupWrapper className="slack_popup">
                              <PopupHeader>
                                <HeaderLeft minWidth={300}>
                                  Chatbot deployment
                                  <Tooltip
                                    className="target customTip"
                                    zIndex={10000}
                                    arrowSize={8}
                                    tagName="span"
                                    content={<DilogTips />}
                                    distance={5}
                                  >
                                    <InfoIcon />
                                  </Tooltip>
                                  <a
                                    href="https://help.workativ.com/knowledgebase/slack/"
                                    target="_blank"
                                  >
                                    <Tooltip
                                      className="target customTip"
                                      zIndex={10000}
                                      arrowSize={8}
                                      tagName="span"
                                      content="View help document"
                                      distance={5}
                                    >
                                      <QaIconWithCircle />
                                    </Tooltip>
                                  </a>
                                </HeaderLeft>
                                <HeaderRight>
                                  <VideoLink
                                    // id={'SET_CONDITIONS_FOR_A_DIALOG'}
                                    text={'How to create'}
                                  />
                                </HeaderRight>
                                      <span
                                         onClick={() =>{
                                          this.setState({
                                            selectedWorkspace: [],
                                            editClientId: '',
                                            showDetails: true,
                                            showAppDetails: true,
                                            showWorkspaceDetails: true
                                             })
                                         }}
                                         className="slack_close_btn"
                                     >
                                         <CloseIcon />
                                     </span>
                              </PopupHeader>
                              <Paragraph>Deploy Chatbot in Slack.</Paragraph>
                              <div className="row">
                                <div className="m_h_cc_container">
                                  <EmptyDiv>
                                    <div>
                                      <div className="automation_canvas_right_popup_form">
                                        <div className="recognise_popup_right">
                                          <div className="bg_slack_cc bg_slack_cc_two">
                                            <InputContainer className="dialog_name_alert_content">
                                              <div>
                                                <InputText
                                                  type="text"
                                                  disabled
                                                  value={
                                                    this.state.bots.find(
                                                      (e) =>
                                                        e.id ==
                                                        data.assistance_id
                                                    ).name
                                                  }
                                                />
                                              </div>
                                              <InputContent
                                                style={{
                                                  top: 10,
                                                  fontSize: 10,
                                                }}
                                              >
                                                Chatbot Name
                                              </InputContent>
                                            </InputContainer>
                                            <InputContainer className="dialog_name_alert_content">
                                              <Input
                                                type="text"
                                                name="name"
                                                value={data.name}
                                                onChange={(e) =>
                                                  this.setState({
                                                    selectedWorkspace:
                                                      this.state.selectedWorkspace.map(
                                                        (yes: any) =>
                                                          Object.assign(yes, {
                                                            name: e.target
                                                              .value,
                                                          })
                                                      ),
                                                  })
                                                }
                                              />
                                              <InputContent>Name</InputContent>
                                            </InputContainer>
                                            <InputContainer className="dialog_name_alert_content">
                                              <Input
                                                type="text"
                                                name="description"
                                                value={data.description}
                                                onChange={(e) =>
                                                  this.setState({
                                                    selectedWorkspace:
                                                      this.state.selectedWorkspace.map(
                                                        (yes: any) =>
                                                          Object.assign(yes, {
                                                            description:
                                                              e.target.value,
                                                          })
                                                      ),
                                                  })
                                                }
                                                maxLength="80"
                                              />
                                              <InputContent>
                                                Description
                                              </InputContent>
                                            </InputContainer>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </EmptyDiv>

                                  {/* <div className="slack_buton_box_12 cc_header_icons">
                                <h4>
                                  <span className="color_header_left">
                                    {(index + 1).toLocaleString('en-US', {
                                      minimumIntegerDigits: 2,
                                      useGrouping: false,
                                    })}
                                  </span>{' '}
                                  Chatbot Integration Details{' '}
                                </h4>
                                <div className="item_icons_right">
                                  {this.state.editIndex === null ? (
                                    <>
                                      <div className="item help_tab">
                                        <div
                                          className="img"
                                          onClick={() =>
                                            this.setState({
                                              editIndex: index,
                                              editClientId: data.client_id,
                                            })
                                          }
                                        >
                                          <EditIconCircle />
                                        </div>
                                        <span className="side_menu_hvr">
                                          Edit
                                        </span>
                                      </div>
                                      {data.slack_deploy === null && (
                                        <div className="item help_tab">
                                          <div
                                            className="img"
                                            onClick={() =>
                                              this.setState({
                                                editIndex: index,
                                                editClientId: data.client_id,
                                                showDeleteModal: true,
                                                tokens: {
                                                  ...this.state.tokens,
                                                  assistance_id:
                                                    data.assistance_id,
                                                },
                                              })
                                            }
                                          >
                                            <DeleteIcon />
                                          </div>
                                          <span className="side_menu_hvr">
                                            Delete
                                          </span>
                                        </div>
                                      )}
                                    </>
                                  ) : null}
                                  <div className="help_link_icon">
                                    <a
                                      href="https://help.workativ.com/knowledgebase/slack/"
                                      target="_blank"
                                    >
                                      <div className="item help_tab">
                                        <div className="img">
                                          <HelpIcon />
                                        </div>
                                        <span className="side_menu_hvr">
                                          Help
                                        </span>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              </div> */}

                                  {/* <div className="bg_slack_cc bg_slack_cc_one">
                                    <div className="slack_buton_box_12">
                                      <div className="slack_buton_8">
                                        <p>Select your chatbot to integrate</p>
                                      </div>

                                      <div className="slack_buton_4">
                                        <h6>
                                          {this.state.bots.find(
                                            (e) => e.id === data.assistance_id
                                          )
                                            ? this.state.bots.find(
                                                (e) =>
                                                  e.id === data.assistance_id
                                              ).name
                                            : ''}
                                        </h6>
                                      </div>
                                    </div>
                                  </div> */}
                                  {data.slack_deploy !== null && (
                                    <div className="bg_slack_cc bg_slack_cc_three">
                                      <div className="chat_channel_subTitle">
                                        <h4>Workspace Details</h4>
                                        {this.state.showWorkspaceDetails ? (
                                            <div
                                              className="img"
                                              onClick={() => {
                                                this.setState({
                                                  showWorkspaceDetails: false,
                                                });
                                              }}
                                            >
                                              <MinusIcon />
                                            </div>
                                        ) : (
                                            <div
                                              className="img"
                                              onClick={() => {
                                                this.setState({
                                                  showWorkspaceDetails: true,
                                                });
                                              }}
                                            >
                                              <AddIcon />
                                            </div>
                                             )}
                                        </div>
                                      
                                      {this.state.showWorkspaceDetails && (
                                        <>
                                        <InputContainer className="dialog_name_alert_content">
                                          <InputText
                                            type="text"
                                            disabled
                                            value={
                                              data.slack_deploy.slack_workspace
                                            }
                                          />
                                          <InputContent className="client_input_content">
                                            Slack Workspace Name
                                          </InputContent>
                                        </InputContainer>
                                        <InputContainer className="dialog_name_alert_content">
                                          <InputText
                                            type="text"
                                            disabled
                                            value={data.slack_deploy.mail_id}
                                          />
                                          <InputContent className="client_input_content">
                                            Deployed By : User Email
                                          </InputContent>
                                        </InputContainer>
                                        <InputContainer className="dialog_name_alert_content">
                                          <InputText
                                            type="text"
                                            disabled
                                            value={data.slack_deploy.added_by}
                                          />
                                          <InputContent className="client_input_content">
                                            Deployed By : User Name
                                          </InputContent>
                                        </InputContainer>
                                        <InputContainer className="dialog_name_alert_content">
                                          <InputText
                                            type="text"
                                            disabled
                                            value={
                                              data.slack_deploy.integrated_at
                                            }
                                          />
                                          <InputContent className="client_input_content" >
                                            Deployed Date
                                          </InputContent>
                                        </InputContainer>
                                      </>
                                      )}
                                      
                                    </div>
                                  )}
                                  <div className="bg_slack_cc bg_slack_cc_two slack_popup">
                                    <div className="chat_channel_subTitle">
                                    <h4>
                                      Add following details in your Slack app
                                      {/* <Tooltip
                                        className="target customTip"
                                        zIndex={10000}
                                        arrowSize={8}
                                        tagName="span"
                                        content={"Test Content"}
                                        distance={5}
                                      >
                                        <InfoIcon />
                                      </Tooltip> */}
                                    </h4>
                                    {this.state.showDetails ? (
                                  <div
                                    className="img"
                                    onClick={() => {
                                      this.setState({
                                        showDetails: false,
                                      });
                                    }}
                                  >
                                    <MinusIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="img"
                                    onClick={() => {
                                      this.setState({
                                        showDetails: true,
                                      });
                                    }}
                                  >
                                    <AddIcon />
                                  </div>
                                )}
                                </div>
                                {this.state.showDetails && (
                                  <>
                                    <InputContainer className="dialog_name_alert_content bg-grey">
                                      <Textarea
                                        type="text"
                                        disabled
                                        value={
                                          window.location.origin +
                                          slackRedirectionUrl(
                                            `/slack/interactions/${data.workspace}/${data.assistance_id}`
                                          )
                                        }
                                      />
                                      <InputContent>
                                        Interactivity & Shortcuts - Request URL
                                      </InputContent>
                                    </InputContainer>
                                    <InputContainer className="dialog_name_alert_content bg-grey">
                                      <Textarea
                                        type="text"
                                        disabled
                                        value={
                                          window.location.origin +
                                          slackRedirectionUrl(
                                            `/slack/command/restart/${data.workspace}/${data.assistance_id}`
                                          )
                                        }
                                      />
                                      <InputContent>
                                        Slash Command - Restart
                                      </InputContent>
                                    </InputContainer>
                                    <InputContainer className="dialog_name_alert_content bg-grey">
                                      <Textarea
                                        type="text"
                                        disabled
                                        value={
                                          window.location.origin +
                                          slackRedirectionUrl(
                                            `/slack/command/exit/${data.workspace}/${data.assistance_id}`
                                          )
                                        }
                                      />
                                      <InputContent>
                                        Slash Command - Exit
                                      </InputContent>
                                    </InputContainer>
                                    <InputContainer className="dialog_name_alert_content bg-grey">
                                      <Textarea
                                        type="text"
                                        disabled
                                        value={
                                          window.location.origin +
                                          slackRedirectionUrl(
                                            `/redirect/onboard`
                                          )
                                        }
                                      />
                                      <InputContent>
                                        OAuth & Permissions - Redirect URL
                                      </InputContent>
                                    </InputContainer>
                                    <InputContainer style={{marginBottom: 0}} className="dialog_name_alert_content bg-grey">
                                      <Textarea
                                        type="text"
                                        disabled
                                        value={
                                          window.location.origin +
                                          slackRedirectionUrl(
                                            `/slack/event/${data.workspace}/${data.assistance_id}`
                                          )
                                        }
                                      />
                                      <InputContent>
                                        Event Subscriptions - Request URL
                                      </InputContent>
                                    </InputContainer>
                                    </>
                                )}
                                  </div>
                                  <div className="bg_slack_cc bg_slack_cc_three">
                                    <div className="chat_channel_subTitle">
                                    <h4>
                                      Provide app details from your Slack app
                                    </h4>
                                    {this.state.showAppDetails ? (
                                      <div
                                        className="img"
                                        onClick={() => {
                                          this.setState({
                                            showAppDetails: false,
                                          });
                                        }}
                                      >
                                        <MinusIcon />
                                      </div>
                                    ) : (
                                      <div
                                        className="img"
                                        onClick={() => {
                                          this.setState({
                                            showAppDetails: true,
                                          });
                                        }}
                                      >
                                        <AddIcon />
                                      </div>
                                    )}
                                    </div>
                                  {this.state.showAppDetails && (
                                      <>
                                  {this.state.selectedWorkspace[0].slack_deploy ? 
                                      <InputContainer className="dialog_name_alert_content ">
                                      <InputText
                                        type="text"
                                        name="name"
                                        value={data.client_id}
                                        disabled
                                        // onChange={(e) =>
                                        //   this.setState({
                                        //     selectedWorkspace:
                                        //       this.state.selectedWorkspace.map(
                                        //         (yes: any) =>
                                        //           Object.assign(yes, {
                                        //             client_id: e.target.value,
                                        //           })
                                        //       ),
                                        //   })
                                        // }
                                      />
                                      <InputContent className="client_input_content">Client ID</InputContent>
                                    </InputContainer> 
                                    :
                                    <InputContainer className="dialog_name_alert_content">
                                      <Input
                                        type="text"
                                        name="name"
                                        value={data.client_id}
                                        onChange={(e) =>
                                          this.setState({
                                            selectedWorkspace:
                                              this.state.selectedWorkspace.map(
                                                (yes: any) =>
                                                  Object.assign(yes, {
                                                    client_id: e.target.value,
                                                  })
                                              ),
                                          })
                                        }
                                      />
                                      <InputContent>Client ID</InputContent>
                                    </InputContainer>
                                      }
                                    <InputContainer className="dialog_name_alert_content">
                                      <Input
                                        type="text"
                                        name="name"
                                        value={data.client_secrect}
                                        onChange={(e) =>
                                          this.setState({
                                            selectedWorkspace:
                                              this.state.selectedWorkspace.map(
                                                (yes: any) =>
                                                  Object.assign(yes, {
                                                    client_secrect:
                                                      e.target.value,
                                                  })
                                              ),
                                          })
                                        }
                                      />
                                      <InputContent>
                                          Client Secret
                                      </InputContent>
                                      {data.client_secret_active === false ? (
                                      <span className="info_icon info_icon_Adaptive alerticon">
                                                    <Tooltip
                                                        className="target customTip"
                                                        zIndex={10000}
                                                        arrowSize={8}
                                                        tagName="span"
                                                        content={'The client secret has expired/changed. Please update it for the bot to work.'}
                                                        distance={5}
                                                    >
                                                        <WarningAlertIcon />
                                                    </Tooltip>
                                                </span>
                                      ):""}
                                    </InputContainer>
                                    <InputContainer className="dialog_name_alert_content">
                                      <Input
                                        type="text"
                                        name="name"
                                        value={data.verification_token}
                                        onChange={(e) =>
                                          this.setState({
                                            selectedWorkspace:
                                              this.state.selectedWorkspace.map(
                                                (yes: any) =>
                                                  Object.assign(yes, {
                                                    verification_token:
                                                      e.target.value,
                                                  })
                                              ),
                                          })
                                        }
                                      />
                                      <InputContent>
                                        Verification Token
                                      </InputContent>
                                      {data.verification_token_active === false ? (
                                        <span className="info_icon info_icon_Adaptive alerticon">
                                           <Tooltip 
                                              className="target customTip"
                                              zIndex={10000}
                                              arrowSize={8}
                                              tagName="span"
                                              content={'The Verification Token has expired/changed. Please update it for the bot to work.'}
                                              distance={5}
                                             >
                                             <WarningAlertIcon />
                                             </Tooltip>
                                             </span>                 
                                                    
                                        ):""}
                                    </InputContainer>
                                    </>
                                     )}
                                  </div>
                                </div>
                              </div>
                              <div className="slack_buton_center slack_btn_icon slack_buton_error">
                                <div className="slack_buton_center">
                                  {/* {console.log(data.client_secrect !== '' &&
                                      data.verification_token !== '' &&
                                      data.client_id !== '' &&
                                      data.assistance_id !== '' &&
                                      data.name !== '' &&
                                      data.description !== '' &&
                                      JSON.stringify (this.state.clonedWorkspace) !== JSON.stringify (this.state.selectedWorkspace),
                                      this.state.clonedWorkspace,this.state.selectedWorkspace,
                                      JSON.stringify (this.state.clonedWorkspace) !== JSON.stringify (this.state.selectedWorkspace),"savebuttonClass")} */}
                                  <Button
                                    className={
                                      data.client_secrect !== '' &&
                                      data.verification_token !== '' &&
                                      data.client_id !== '' &&
                                      data.assistance_id !== '' &&
                                      data.name !== '' &&
                                      data.description !== '' &&
                                      JSON.stringify (this.state.clonedWorkspace) !== JSON.stringify (this.state.selectedWorkspace)
                                        ? ''
                                        : 'hidden'
                                    }
                                    onClick={() => this.editCRED(data, index)}
                                  >
                                    Save
                                  </Button>
                                </div>
                                {this.state.editIndex === index &&
                                !this.state.showDeleteModal ? (
                                  <div className="slack_buton_center">
                                    <Button
                                      className={
                                        data.name !== '' &&
                                        data.description !== '' &&
                                        data.client_secrect !== '' &&
                                        data.verification_token !== '' &&
                                        data.client_id !== '' &&
                                        data.assistance_id !== ''
                                          ? ''
                                          : 'hidden'
                                      }
                                      onClick={() => this.editCRED(data, index)}
                                    >
                                      Save
                                    </Button>
                                  </div>
                                ) : data.slack_deploy === null ? (
                                  <div className="btn_width_wrapper">
                                    <Button
                                      // primary
                                      // className="btn btn-primary add-slack"
                                      // style={{ paddingTop: 10 }}
                                      // className="btn btn-success add_to_slack"
                                      onClick={() => {
                                        this.setState({
                                          popUp: {
                                            error: '',
                                            errorIndex: null,
                                            loader: false,
                                            loaderIndex: null,
                                          },
                                        });

                                        this.openSlack(data, index);
                                      }}
                                      disabled={
                                        this.state.addMode ||
                                        this.state.editIndex != null
                                      }
                                    >
                                      {/* <span className="slack-content">
                                        <span className="slack-icon">
                                          <SlackIconCC />
                                        </span>
                                        <span>Add to slack</span>
                                      </span> */}
                                      <span>
                                        Authorize
                                      </span>
                                    </Button>
                                  </div>
                                ) : data.slack_deploy.is_active ? (
                                  <Button
                                    // className={
                                    //     this.state.addMode || this.state.editIndex != null
                                    //         ? 'add_to_slack btn-deactivaed'
                                    //         : 'add_to_slack'
                                    // }
                                    onClick={() =>
                                      this.toggleActivation(
                                        data.assistance_id,
                                        (data.slack_deploy as SlackD).slack_id,
                                        'false',
                                        index
                                      )
                                    }
                                    className={
                                      this.state.addMode ||
                                      this.state.editIndex != null ||
                                      this.state.popUp.loader
                                        ? 'editor_btn_disabled'
                                        : ''
                                    }
                                  >
                                    <span style={{ top: 0 }}>Deactivate</span>
                                  </Button>
                                ) : (
                                  <Button
                                    // className={
                                    //     this.state.addMode || this.state.editIndex != null
                                    //         ? 'btn btn-success add_to_slack btn-deactivaed'
                                    //         : 'btn btn-success add_to_slack'
                                    // }
                                    onClick={() =>
                                      this.toggleActivation(
                                        data.assistance_id,
                                        (data.slack_deploy as SlackD).slack_id,
                                        'true',
                                        index
                                      )
                                    }
                                    className={
                                      this.state.addMode ||
                                      this.state.editIndex != null ||
                                      this.state.popUp.loader
                                        ? 'editor_btn_disabled'
                                        : ''
                                    }
                                  >
                                    <span className="slack_integration">
                                      Activate
                                    </span>
                                  </Button>
                                )}
                                {this.state.popUp.loader &&
                                  !this.state.addMode &&
                                  typeof this.state.popUp.loaderIndex ==
                                    'number' &&
                                  this.state.popUp.loaderIndex == index && (
                                    <span className="connect_account_loading">
                                      <img src={LoadingSvgImage} />
                                    </span>
                                  )}
                                {this.state.popUp.error &&
                                  !this.state.addMode &&
                                  this.state.popUp.errorIndex == index && (
                                    <span className="connect_account_failed">
                                      <div className="error_input_show">
                                        <WarningAlertIcon />
                                        <span className="error_menu_hvr">
                                          {this.state.popUp.error}
                                        </span>
                                      </div>
                                    </span>
                                  )}
                              </div>
                            </PopupWrapper>
                          </Wrapper>
                        )
                      )}
                  </div>
                </div>
              </div>
            </ErrorBoundary>
          </div>
        </section>
        {this.renderErrorPopup()}
        {this.renderDeleteModal()}
      </React.Fragment>
    );
  }
}

// Local code

// UNSAFE_componentWillReceiveProps(nextProps: any) {
//   const { props } = this;

//   if (props.state.type != nextProps.state.type) {
//     if (nextProps.state.type == 'loading') {
//       this.setState({ PageState: Loading });
//     } else if (nextProps.state.type == 'error') {
//       getAssistants(this.workspaceName).then((data) => {
//         const res: any = [
//           {
//             workspace: 'nirup',
//             assistance_id: '25b56031-6719-486b-b103-908331b20467',
//             name: 'Workativ Assistant',
//             description: 'Workativ Assistant',
//             client_id: 'fgj',
//             client_secrect: 'jnjb',
//             verification_token: 'knkl',
//             slack_deploy: null,
//           },
//         ];
//         console.log(res);
//         if (res.length > 0) {
//           this.setState({
//             workspaces: res,
//             PageState: View,
//             bots: data,
//             tokens: {
//               ...this.state.tokens,
//             },
//           });
//         } else {
//           this.setState({
//             workspaces: res,
//             PageState: NoDataFound,
//             bots: data,
//             tokens: {
//               ...this.state.tokens,
//             },
//           });
//         }
//       });
//       // if (nextProps.state.error.response && nextProps.state.error.response.status == 403) {
//       //     this.setState({
//       //         PageState: UnauthorizedPage
//       //     })
//       // } else {
//       //     this.setState({
//       //         PageState: ErrorPage,
//       //         PageError: {
//       //             error: true,
//       //             info: 'There was a problem in loading Page! <br/> Please click on "Retry"'
//       //         }
//       //     })
//       // }
//     } else if (nextProps.state.type == 'success') {
//       getAssistants(this.workspaceName).then((data) => {
//         const res: any = nextProps.state.data;
//         console.log(res);
//         if (res.length > 0) {
//           this.setState({
//             workspaces: res,
//             PageState: View,
//             bots: data,
//             tokens: {
//               ...this.state.tokens,
//             },
//           });
//         } else {
//           this.setState({
//             workspaces: res,
//             PageState: NoDataFound,
//             bots: data,
//             tokens: {
//               ...this.state.tokens,
//             },
//           });
//         }
//       });
//     }
//   }

//   var url = new URL(window.location as any);
//   var c = url.searchParams.get('error');
//   if (c != null) {
//     this.setState({ showErrorPopup: { error: true, info: c } });
//   }
// }
const DilogTips = () => (
  <div className="">
    <p>
      <a
        className="sidepanel_href_color"
        target="_blank"
        href="https://help.workativ.com/knowledgebase/building-a-dialog/"
      >
        Chatbot deployment
      </a>{' '}
      helps the bot recognize user utterance or intent (to start a conversation
      or dialog).
    </p>
    <p>
      {' '}
      You can specify conditions on how the dialog is executed using the 'create
      dialog’ pop up. Based on the user's intent and condition, the bot can then
      initiate an appropriate conversation or take action on user’s intent or
      query.
    </p>
    {/* <p> The chatbot can then check and choose the dialog that the user's input satisfies.</p> */}
  </div>
);

function ReturnList(props:{data:WorkspaceT,editClientId:any,setToEditMode:(params:any)=>void,i:number,isSelected:any,selectSlack:any,editSlack:any,label:any,state:any,workspacename:string,setDeleteMode:(params:any)=>void,showAddButton:(params:boolean)=>void}){
  const {data,editClientId,setToEditMode,i,isSelected,selectSlack,editSlack,label,state,workspacename,setDeleteMode,showAddButton} = props
  const writePermission = usePermissions(workspacename,`CHAT_CHANNEL_MODULE/CHANNEL/SLACK/INTEGRATION/${data.id}`,"WRITE")
  const deletePermission = usePermissions(workspacename,`CHAT_CHANNEL_MODULE/CHANNEL/SLACK/INTEGRATION/${data.id}`,"DELETE")
  
  return(
    <Tr
                                      className={
                                        data.client_id ==
                                        editClientId
                                          ? 'is_active'
                                          : null
                                      }
                                      onClick={() =>
                                        writePermission.value && setToEditMode(data.client_id)
                                      }
                                    >
                                      <List
                                        key={i}
                                        showCheckBox={false}
                                        checkBoxShow={false}
                                        render={(
                                          show: boolean,
                                          ToolBox: any
                                        ) => {
                                          return (
                                            <>
                                              <Td
                                                size="3"
                                                className={'list_flow_hr'}
                                              >
                                                <ListSelectBox
                                                  show={show}
                                                  isSelected={isSelected(
                                                    data.name
                                                  )}
                                                  onClick={selectSlack}
                                                  value={data.name}
                                                >
                                                  {data.name}
                                                </ListSelectBox>
                                                <div
                                                  className="search_card_list"
                                                  onClick={() =>
                                                    editSlack(data.name)
                                                  }
                                                >
                                                  {Parser(label)}
                                                </div>
                                              </Td>
                                              <Td
                                                size="3"
                                                className="list_flow_p"
                                              >
                                                <p className="listite_centre">
                                                  {data.description}
                                                </p>
                                              </Td>
                                              <Td
                                                size="2.5"
                                                className="list_flow_p"
                                              >
                                                <p className="listite_centre">
                                                  {data.slack_deploy === null
                                                    ? 'Draft'
                                                    : data.slack_deploy
                                                        .is_active === true
                                                    ? 'Active'
                                                    : 'Inactive'}
                                                </p>
                                              </Td>
                                              <Td
                                                size="2.5"
                                                className="list_flow_span"
                                              >
                                                <span className="entity_span">
                                                  {
                                                    formatDateInCard(data.updated_at)
                                                    // data.slack_deploy
                                                    //   ? formatDateInCard(
                                                    //       data.slack_deploy
                                                    //         .integrated_at
                                                    //     )
                                                    //   : '--'
                                                    // ? data.slack_deploy
                                                    //     ?.integrated_at
                                                    // : data.slack_deploy
                                                    //     ?.integrated_at + 'Z'
                                                  }
                                                </span>
                                                <span>|</span>
                                                <span className="entity_span">
                                                  {
                                                    formatAMPM(data.updated_at)
                                                    // data.slack_deploy
                                                    //   ? formatAMPM(
                                                    //       data.slack_deploy
                                                    //         .integrated_at
                                                    //     )
                                                    //   : '--'
                                                    // .includes(
                                                    //   'Z'
                                                    // )
                                                    //   ? data.slack_deploy
                                                    //       ?.integrated_at
                                                    //   : data.slack_deploy
                                                    //       ?.integrated_at + 'Z'
                                                  }
                                                </span>
                                                {data.client_secret_active === false ? (
                                                <span className="alerticon">
                                                    <Tooltip
                                                        className="target customTip"
                                                        zIndex={10000}
                                                        arrowSize={8}
                                                        tagName="span"
                                                        content={'The client secret has expired/changed. Please update it for the bot to work.'}
                                                        distance={5}
                                                    >
                                                        <WarningAlertIcon />
                                                    </Tooltip>
                                                </span>
                                             ):""}
                                             {data.verification_token_active === false ? (
                                              <span className={data.client_secret_active === true ?"alerticon":"tokenalerticon"}>
                                                <Tooltip 
                                                    className="target customTip"
                                                    zIndex={10000}
                                                    arrowSize={8}
                                                    tagName="span"
                                                    content={'The Verification Token has expired/changed. Please update it for the bot to work.'}
                                                    distance={5}
                                                  >
                                                  <WarningAlertIcon />
                                                  </Tooltip>
                                                  </span>                 
                                                          
                                              ):""}
                                              </Td>
                                              <Td size="1">
                                                {state.PageState.action !==
                                                  Action.Select &&
                                                  state.PageState
                                                    .action !==
                                                    Action.LineDelete && (
                                                    <ToolBox>
                                                      {writePermission.value&&<span
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          setToEditMode(
                                                            data.client_id
                                                          );
                                                        }}
                                                      >
                                                        <EditIcon />
                                                      </span>}
                                                      {data.slack_deploy ===
                                                        null && deletePermission.value && (
                                                        <span
                                                          onClick={(e) => {
                                                            e.stopPropagation();
                                                            setDeleteMode(
                                                              data.client_id
                                                            );
                                                          }}
                                                        >
                                                          <DeleteIcon />
                                                        </span>
                                                      )}
                                                    </ToolBox>
                                                  )}
                                              </Td>
                                            </>
                                          );
                                        }}
                                      />
                                      </Tr>
  )
}