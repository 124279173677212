/*eslint-disable*/
import React from 'react'

import {
    DeleteIcon,
    DialogIcon,
    EditIcon,
    ExitIcon,
    FlowIcon,
    JumpIcon,
    NextIconCanvas,
    OptionIcon,
    QaIcon,
    ResponseIcon,
    RightArrowWithCircle,
    SlotsIcon
} from '@/common/Icons/Icons'

import { DialogTreeContextT, PageContextT } from '../Actions/ReactContext'
import { OPERATIONS, PageState } from '../Types/PageT'
import { MOCK_AUTOMATION, MOCK_IF_NODE } from '../Types/Types'
import {
    AskQueryDialogT,
    AutomationDialogT,
    DialogT,
    ExitDialogT,
    JumpDialogT,
    OptionsResponseDialogT,
    RecognizeDialogT,
    SlotContainerDialogT,
    TextResponseDialogSelectionE,
    TextResponseDialogT
} from '../Types/WatsonT'
import Tooltip from 'react-tooltip-lite'

const R = require('ramda')

// const PageContextD = useContext(PageContext)
// const DialogTreecontextD = useContext(DialogTreeContext)

const fetchPrevious = (PageContext: PageContextT, element: RecognizeDialogT) =>
    PageContext.fetchChild(element)
        .filter((e: DialogT) => e.type != 'if')
        .pop()

export const CreateRecognizeDialog = (
    PageContext: PageContextT,
    DialogTreeContext: DialogTreeContextT,
    previousSibling: DialogT,
    nextSibling: DialogT,
    parent: RecognizeDialogT
) => {
    const proof: RecognizeDialogT = {
        ...MOCK_IF_NODE,
        jump_to: previousSibling ? previousSibling.jump_to : parent.jump_to,
        metadata: { hide_jump_to: false, RICH_TEXT: [] },
        context: {},
        type: 'if',
        title: '',
        parent: parent.dialog_node,
        dialog_node: '',
        previous_sibling: previousSibling ? previousSibling.dialog_node : null
    }

    PageContext.setPageState({
        operation: OPERATIONS.RecognizeDialogPT,
        input: proof,
        previousSibling: previousSibling,
        nextSibling: nextSibling,
        parent: parent
    })
    DialogTreeContext.setAppendChild(null)
}

export const CreateTextDialog = (
    PageContext: PageContextT,
    DialogTreeContext: DialogTreeContextT,
    previousSibling: DialogT,
    nextSibling: DialogT,
    parent: RecognizeDialogT
) => {
    const proof: TextResponseDialogT = {
        jump_to: previousSibling ? previousSibling.jump_to : parent.jump_to,
        metadata: {
            hide_jump_to: true,
            RICH_TEXT: [
                {
                    blocks: [
                        {
                            text: '',
                            type: 'unstyled'
                        }
                    ],
                    entityMap: {}
                }
            ]
        },
        context: previousSibling ? previousSibling.context : parent.context,
        type: 'text-response',
        values: [''],
        selection_policy: TextResponseDialogSelectionE.sequential,
        parent: parent.dialog_node,
        dialog_node: '',
        previous_sibling: previousSibling ? previousSibling.dialog_node : null,
        children: [],
        created: '',
        updated: ''
    }

    PageContext.setPageState({
        operation: OPERATIONS.TextResponseDialogPT,
        input: proof,
        previousSibling: previousSibling,
        nextSibling: nextSibling,
        parent: parent
    })
}

export const CreateQueryDialog = (
    PageContext: PageContextT,
    DialogTreeContext: DialogTreeContextT,
    previousSibling: DialogT,
    nextSibling: DialogT,
    parent: RecognizeDialogT
) => {
    const proof: AskQueryDialogT = {
        jump_to: previousSibling ? previousSibling.jump_to : parent.jump_to,
        metadata: {
            hide_jump_to: true,
            RICH_TEXT: {
                blocks: [
                    {
                        text: '',
                        type: 'unstyled'
                    }
                ],
                entityMap: {}
            }
        },
        context: previousSibling ? previousSibling.context : parent.context,
        type: 'qa',
        question: { text: '' },
        variable: '',
        parent: parent.dialog_node,
        dialog_node: '',
        previous_sibling: previousSibling ? previousSibling.dialog_node : null,
        children: [],
        created: '',
        updated: ''
    }
    PageContext.setPageState({
        operation: OPERATIONS.AskQueryDialogPT,
        input: proof,
        previousSibling: previousSibling,
        nextSibling: nextSibling,
        parent: parent
    })
}

export const CreateOptionsDialog = (
    PageContext: PageContextT,
    DialogTreeContext: DialogTreeContextT,
    previousSibling: DialogT,
    nextSibling: DialogT,
    parent: RecognizeDialogT
) => {
    const proof: OptionsResponseDialogT = {
        jump_to: previousSibling ? previousSibling.jump_to : parent.jump_to,
        metadata: {
            hide_jump_to: true,
            RICH_TEXT: {
                blocks: [
                    {
                        text: '',
                        type: 'unstyled'
                    }
                ],
                entityMap: {}
            }
        },
        context: previousSibling ? previousSibling.context : parent.context,
        type: 'option-response',
        options: [],
        option_title: '',
        parent: parent.dialog_node,
        dialog_node: '',
        previous_sibling: previousSibling ? previousSibling.dialog_node : null,
        children: [],
        created: '',
        updated: ''
    }

    PageContext.setPageState({
        operation: OPERATIONS.OptionsResponseDialogPT,
        input: proof,
        previousSibling: previousSibling,
        nextSibling: nextSibling,
        parent: parent
    })
}

export const CreateJumpToDialog = (
    PageContext: PageContextT,
    DialogTreeContext: DialogTreeContextT,
    previousSibling: DialogT,
    nextSibling: DialogT,
    parent: RecognizeDialogT
) => {
    PageContext.setPageState({
        operation: OPERATIONS.JumpDialogPT,
        input: {
            jump_to: previousSibling ? previousSibling.jump_to : parent.jump_to,
            metadata: previousSibling ? previousSibling.metadata : parent.metadata,
            context: previousSibling ? previousSibling.context : parent.context,
            parent: parent.dialog_node,
            dialog_node: '',
            previous_sibling: previousSibling ? previousSibling.dialog_node : null,
            children: [],
            created: '',
            updated: '',
            type: 'action'
        } as JumpDialogT,
        previousSibling: previousSibling,
        nextSibling: nextSibling,
        parent: parent
    })
}

export const CreateSlotContainerDialog = (
    PageContext: PageContextT,
    DialogTreeContext: DialogTreeContextT,
    previousSibling: DialogT,
    nextSibling: DialogT,
    parent: RecognizeDialogT
) => {
    PageContext.setPageState({
        operation: OPERATIONS.SlotContainerDialogPT,
        input: {
            jump_to: previousSibling ? previousSibling.jump_to : parent.jump_to,
            metadata: previousSibling ? previousSibling.metadata : parent.metadata,
            context: previousSibling ? previousSibling.context : parent.context,
            parent: parent.dialog_node,
            dialog_node: '',
            previous_sibling: previousSibling ? previousSibling.dialog_node : null,
            children: [],
            created: '',
            updated: '',
            type: 'slot-container',
            slots: []
        } as SlotContainerDialogT,
        previousSibling: previousSibling,
        nextSibling: nextSibling,
        parent: parent
    })
}
export const CreateAutomationDialog = (
    PageContext: PageContextT,
    DialogTreeContext: DialogTreeContextT,
    previousSibling: DialogT,
    nextSibling: DialogT,
    parent: RecognizeDialogT
) => {
    PageContext.setPageState({
        operation: OPERATIONS.AutomationDialogPT,
        input: {
            jump_to: previousSibling ? previousSibling.jump_to : parent.jump_to,
            metadata: {
                hide_jump_to: true,
                automationName: '',
                RICH_TEXT: {
                    blocks: [
                        {
                            text: '',
                            type: 'unstyled'
                        }
                    ],
                    entityMap: {}
                }
            },
            context: {
                SYS_ACTIONS: [
                    { action: 'flush_response', data: {} },
                    {
                        action: 'execute_flow',
                        data: {
                            output_variable: '',
                            automation: '',
                            automationName: '',
                            automationNode: MOCK_AUTOMATION,
                            input: {}
                        }
                    }
                ]
            },
            parent: parent.dialog_node,
            dialog_node: '',
            previous_sibling: previousSibling ? previousSibling.dialog_node : null,
            children: [],
            created: '',
            updated: '',
            type: 'action'
        } as AutomationDialogT,
        previousSibling: previousSibling,
        nextSibling: nextSibling,
        parent: parent
    })
}

export const CreateExitDialog = (
    PageContext: PageContextT,
    DialogTreeContext: DialogTreeContextT,
    previousSibling: DialogT,
    nextSibling: DialogT,
    parent: RecognizeDialogT
) => {
    PageContext.setPageState({
        operation: OPERATIONS.ExitDialogPT,
        input: {
            jump_to: previousSibling ? previousSibling.jump_to : parent.jump_to,
            metadata: {
                hide_jump_to: false,
                RICH_TEXT: {
                    blocks: [
                        {
                            text: '',
                            type: 'unstyled'
                        }
                    ],
                    entityMap: {}
                }
            },
            context: { SYS_EXIT_MESSAGE: '' },
            parent: parent.dialog_node,
            dialog_node: '',
            previous_sibling: previousSibling ? previousSibling.dialog_node : null,
            children: [],
            created: '',
            updated: '',
            type: 'action'
        } as ExitDialogT,
        previousSibling: previousSibling,
        nextSibling: nextSibling,
        parent: parent
    })
}
export const EditDialog = (PageContext: PageContextT, DialogTreeContext: DialogTreeContextT, data: DialogT) => {
    const Dialog = R.clone(data)
    switch (Dialog.type) {
        case 'action':
            if (Dialog.context.SYS_ACTIONS && Dialog.context.SYS_ACTIONS.length == 2) {
                PageContext.setPageState({
                    operation: OPERATIONS.AutomationDialogPT,
                    input: Dialog as AutomationDialogT,
                    previousSibling: null,
                    nextSibling: null,
                    parent: null
                })
            } else if (Dialog.jump_to == null) {
                PageContext.setPageState({
                    operation: OPERATIONS.JumpDialogPT,
                    input: Dialog as JumpDialogT,
                    previousSibling: null,
                    nextSibling: null,
                    parent: null
                })
            } else if (Dialog.jump_to.dialog_node == 'feedback' || Dialog.jump_to.dialog_node == 'exit') {
                PageContext.setPageState({
                    operation: OPERATIONS.ExitDialogPT,
                    input: Dialog as ExitDialogT,
                    previousSibling: null,
                    nextSibling: null,
                    parent: null
                })
            } else if (Dialog.jump_to.dialog_node != 'feedback' && Dialog.jump_to.dialog_node != 'exit') {
                PageContext.setPageState({
                    operation: OPERATIONS.JumpDialogPT,
                    input: Dialog as JumpDialogT,
                    previousSibling: null,
                    nextSibling: null,
                    parent: null
                })
            }
            return null
        case 'if':
            PageContext.setPageState({
                operation: OPERATIONS.RecognizeDialogPT,
                input: Dialog as RecognizeDialogT,
                previousSibling: null,
                nextSibling: null,
                parent: null
            })
            return null
        case 'text-response':
            PageContext.setPageState({
                operation: OPERATIONS.TextResponseDialogPT,
                input: Dialog as TextResponseDialogT,
                previousSibling: null,
                nextSibling: null,
                parent: null
            })
            return null
        case 'qa':
            PageContext.setPageState({
                operation: OPERATIONS.AskQueryDialogPT,
                input: Dialog as AskQueryDialogT,
                previousSibling: null,
                nextSibling: null,
                parent: null
            })
            return null

        case 'option-response':
            PageContext.setPageState({
                operation: OPERATIONS.OptionsResponseDialogPT,
                input: Dialog as OptionsResponseDialogT,
                previousSibling: null,
                nextSibling: null,
                parent: null
            })
            return null
        case 'slot-container':
            PageContext.setPageState({
                operation: OPERATIONS.SlotContainerDialogPT,
                input: Dialog as SlotContainerDialogT,
                previousSibling: null,
                nextSibling: null,
                parent: null
            })
            return null
        default:
            console.error(`Sorry, we are find this type of node :(`)
            return null
    }
}

interface DesignerP {
    dialog: DialogT
    onClick: Function
}

export const Designer = (props: DesignerP) => {
    const { dialog, onClick } = props
    if (dialog.type === 'action' && dialog.jump_to && dialog.jump_to.dialog_node == 'feedback') {
        return (
            <div className={'child_node_no'} onClick={() => onClick()}>
                <span>
                    <ExitIcon />
                </span>
            </div>
        )
    } else if (dialog.type === 'action' && dialog.jump_to && dialog.jump_to.dialog_node == 'exit') {
        return (
            <div className={'child_node_no'} onClick={() => onClick()}>
                <span>
                    <ExitIcon />
                </span>
            </div>
        )
    } else if (
        dialog.type === 'action' &&
        dialog.context.SYS_ACTIONS &&
        dialog.context.SYS_ACTIONS.length == 2 &&
        'Automation'
    ) {
        return (
            <div className={'child_node_no'} onClick={() => onClick()}>
                <span>
                    <FlowIcon />
                </span>
            </div>
        )
    } else if (
        dialog.type === 'action' &&
        dialog.metadata.hide_jump_to == false &&
        dialog.jump_to &&
        dialog.jump_to.dialog_node != 'feedback' &&
        dialog.jump_to.dialog_node != 'exit' &&
        'Jump To'
    ) {
        return (
            <div className={'child_node_no'} onClick={() => onClick()}>
                <span>
                    <JumpIcon />
                </span>
            </div>
        )
    } else if (dialog.type === 'action' && dialog.metadata.hide_jump_to == false && dialog.jump_to == null) {
        return (
            <div className={'child_node_no'} onClick={() => onClick()}>
                <span>
                    <JumpIcon />
                </span>
            </div>
        )
    }
    switch (dialog.type) {
        case 'if':
            return (
                <div className={'child_node_no'} onClick={() => onClick()}>
                    <span>
                        <DialogIcon />
                    </span>
                </div>
            )
        case 'text-response':
            return (
                <div className={'child_node_no'} onClick={() => onClick()}>
                    <span>
                        <ResponseIcon />
                    </span>
                </div>
            )
        case 'option-response':
            return (
                <div className={'child_node_no'} onClick={() => onClick()}>
                    <span>
                        <OptionIcon />
                    </span>
                </div>
            )
        case 'qa':
            return (
                <div className={'child_node_no'} onClick={() => onClick()}>
                    <span>
                        <QaIcon />
                    </span>
                </div>
            )
        case 'slot-container':
            return (
                <div className={'child_node_no'} onClick={() => onClick()}>
                    <span>
                        <SlotsIcon />
                    </span>
                </div>
            )
        default:
            return (
                <div className={'child_node_no'} onClick={() => onClick()}>
                    <span>
                        <DialogIcon />
                    </span>
                </div>
            )
    }
}

interface EditDeleteP {
    onEdit: Function
    onDelete: Function
}

export const EditDelete = ({ onEdit, onDelete }: EditDeleteP) => (
    <React.Fragment>
        <span className="child_svg" onClick={() => onEdit()}>
            <Tooltip
                    className="target customTip "
                    zIndex={10000}
                    arrowSize={8}
                    tagName="span"
                    content={'Edit'}
                    distance={5}
                >
                <EditIcon />
            </Tooltip>
        </span>
        <span className="child_svg" onClick={() => onDelete()}>
            <Tooltip
                    className="target customTip "
                    zIndex={10000}
                    arrowSize={8}
                    tagName="span"
                    content={'Delete'}
                    distance={5}
                >
                <DeleteIcon />
            </Tooltip>
        </span>
    </React.Fragment>
)

export const isSelected = (PageState: PageState, dialogNode: string) => {
    switch (PageState.operation) {
        case OPERATIONS.RecognizeDialogPT:
            return PageState.input.dialog_node == dialogNode
        case OPERATIONS.TextResponseDialogPT:
            return PageState.input.dialog_node == dialogNode
        case OPERATIONS.OptionsResponseDialogPT:
            return PageState.input.dialog_node == dialogNode
        case OPERATIONS.AskQueryDialogPT:
            return PageState.input.dialog_node == dialogNode
        case OPERATIONS.JumpDialogPT:
            return PageState.input.dialog_node == dialogNode
        case OPERATIONS.SlotContainerDialogPT:
            return PageState.input.dialog_node == dialogNode
        case OPERATIONS.AutomationDialogPT:
            return PageState.input.dialog_node == dialogNode
        case OPERATIONS.ExitDialogPT:
            return PageState.input.dialog_node == dialogNode

        default:
            return false
    }
}

export const RequireHook = (dialog: RecognizeDialogT, fetchChilds: Function) => {
    if (dialog.conditions == '') {
        return false
    }
    const proof = fetchChilds(dialog)
        .filter((e: DialogT) => e.type !== 'if')
        .pop()
    if (proof) {
        if (proof.type == 'action') {
            return false
        } else if (proof.type == 'option-response') {
            return false
        }
    }
    return true
}

interface FlowItemP {
    flow: any
    onClick: Function
}

export const FlowItem = ({ flow, onClick }: FlowItemP) => (
    <div className="context_dialog_popup_accordian" onClick={e => onClick()}>
        <div className="context_dialog_join_output">
            <h4>
                <span className="dialog_context_number">
                    <span className="dialog_context_span_number">
                        <FlowIcon />
                    </span>
                </span>
                <span className="w_90">
                    <span className="context_path">{flow.name}</span>
                    <span className="context_path">
                        <NextIconCanvas />
                        <span className="context_path_content">Output</span>
                    </span>
                </span>
                <span className="dialog_open_icon">
                    <div className="arrow_up_form closed_state_arrow">
                        <RightArrowWithCircle />
                    </div>
                </span>
            </h4>
        </div>
    </div>
)

interface DialogItemP {
    dialog: RecognizeDialogT
    index: number
    onclick: Function
}

export const DialogItem = ({ dialog, index, onclick }: DialogItemP) => (
    <div className="context_dialog_popup_accordian context_dialog_popup_accordian_connection ">
        <div className="context_dialog_join_output">
            <h4 onClick={e => onclick()}>
                <span className="dialog_context_number">
                    <span className="dialog_context_span_number">{index}</span>
                </span>
                <span className="context_path">{dialog.title}</span>
                <span>
                    <div className="arrow_up_form closed_state_arrow">
                        <RightArrowWithCircle />
                    </div>
                </span>
            </h4>
        </div>
    </div>
)
