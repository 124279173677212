import { SyntheticEvent, useContext, useState } from 'react'

import { AdaptiveServerFieldValueT, AdaptiveValueT, DialogueT } from '@/Assistant/Dialogue/kind'
import { DialogueContext } from '@/Assistant/DialogueCrew/Context'
import { reverseCond } from '@/Assistant/DialogueCrew/Nodes/RecogniseDialog/action'
import { conditionT, conditionTYPES } from '@/Assistant/DialogueCrew/Nodes/RecogniseDialog/kind'
import { DraftJST, ExpressionT, ExpressionTypeE, converterToEditor } from '@/Assistant/Editor/Action'
import { getDialogueRoots } from '@/Assistant/Mechanisms/action'

export type FAQType = {
    title: string
    description: string
    condition: string
}
export type DialogT = {
    id: string
    title: string
    uuid: string
}
export const useFaqState = (state: any) => {
    const context = useContext(DialogueContext)

    const [faq, setFaq] = useState<FAQType>({
        title: state.title,
        description: state.description,
        condition: ''
    })
    const [newConditions, setNewConditions] = useState<conditionT[]>(
        Array.isArray(state.conditions)
            ? [
                  {
                      type: conditionTYPES.FAQ,
                      queries: state.conditions
                  }
              ]
            : reverseCond(state.conditions)
    )

    const [then, setThen] = useState<string>(state.nextstep.then)
    const [dialogs, setDialogs] = useState<DialogueT[]>([])
    const [dialog, setDialog] = useState<DialogT>({ ...state.nextstep.jumpto, uuid: state.nextstep.jumpto.id })
    const onChangeFaqState = (e: SyntheticEvent) => {
        let { name, value } = e.target as HTMLInputElement
        setFaq({ ...faq, [name]: value })
    }
    const GetDialogs = async (workspacename: string, dialogId: string) =>
        await getDialogueRoots(workspacename, dialogId).then(data => setDialogs(data))
    const [draft, setDraft] = useState<DraftJST>(
        state.responses.type == 'text_message' && state.responses.textexp
            ? converterToEditor(state.responses.textexp[0])
            : { blocks: [], entityMap: {} }
    )
    const [message, setMessage] = useState<DraftJST>(state.nextstep.exit)

    const infoExp = state.responses.type == 'information_card' && state.responses.infoexp

    const [basicInfo, setBasicInfo] = useState<{
        title: {
            type: ExpressionTypeE.TEXT
            value: string
        }
        description: DraftJST
        image: string
    }>(
        infoExp
            ? {
                  title: {
                      type: ExpressionTypeE.TEXT,
                      value: infoExp[0].title.value
                  },
                  description: converterToEditor(infoExp[0].description),
                  image: infoExp[0].image == null ? '' : infoExp[0].image.value
              }
            : {
                  title: {
                      type: ExpressionTypeE.TEXT,
                      value: ''
                  },
                  description: { blocks: [], entityMap: {} },
                  image: ''
              }
    )

    const [fields, setFields] = useState<AdaptiveValueT[]>(
        infoExp && infoExp[0].fields.length > 0
            ? infoExp[0].fields.map((b: AdaptiveServerFieldValueT) => ({
                  label: b.title,
                  value: converterToEditor(b.value as ExpressionT, context, context.dialogueNode)
              }))
            : [
                  {
                      label: {
                          type: 'text',
                          value: ''
                      },
                      value: { blocks: [], entityMap: {} }
                  }
              ]
    )

    const [buttons, setButtons] = useState<AdaptiveValueT[]>(
        infoExp && infoExp[0].action_buttons.length > 0
            ? infoExp[0].action_buttons.map((b: AdaptiveServerFieldValueT) => ({
                  label: b.title,
                  value: converterToEditor(b.value as ExpressionT, context, context.dialogueNode)
              }))
            : [
                  {
                      label: {
                          type: 'text',
                          value: ''
                      },
                      value: { blocks: [], entityMap: {} }
                  }
              ]
    )

    return {
        faq,
        draft,
        setDraft,
        then,
        setThen,
        dialogs,
        GetDialogs,
        onChangeFaqState,
        dialog,
        setDialog,
        message,
        setMessage,
        basicInfo,
        fields,
        buttons,
        setBasicInfo,
        setFields,
        setButtons,
        newConditions,
        setNewConditions
    }
}
