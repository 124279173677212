import { ActionT } from '../kind'

export interface IntentT {
    id: string
    intent: string
    description: string
    created: string
    updated: string
    examples: any[]
}

export const DefaultIntent: IntentT = {
    id: '',
    intent: '',
    description: '',
    created: '',
    updated: '',
    examples: []
}

export interface IntentRequestT {
    intent: string
    description: string
}

export interface VIEW_T {
    action: ActionT.VIEW
}

export const VIEW: VIEW_T = {
    action: ActionT.VIEW
}

export interface LOADING_T {
    action: ActionT.LOADING
}

export const LOADING: LOADING_T = {
    action: ActionT.LOADING
}

export interface UN_AUTHORIZED_T {
    action: ActionT.UN_AUTHORIZED
}

export interface RESOURCE_NOT_FOUND_T {
    action: ActionT.RESOURCE_NOT_FOUND
    resource: 'WORKSPACE' | 'ASSISTANT' | 'INTENT' | 'DIALOG'
}

export interface NO_DATA_FOUND_T {
    action: ActionT.NO_DATA_FOUND
    loading: boolean
    intent: string
    description: string
}

export interface ERROR_T {
    action: ActionT.ERROR
    reason: string
}

export interface ADD_T {
    action: ActionT.ADD
    intent: string
    description: string
}

export interface EDIT_T {
    action: ActionT.EDIT
    id: string
    intent: string
    description: string
}

export interface DELETE_T {
    action: ActionT.DELETE
    data: IntentT
}

export interface MULTIPLE_DELETE_T {
    action: ActionT.MULTIPLE_DELETE
    items: string[]
}

export interface SELECT_T {
    action: ActionT.SELECT
    items: string[]
}

export type PageStateT =
    | VIEW_T
    | LOADING_T
    | UN_AUTHORIZED_T
    | RESOURCE_NOT_FOUND_T
    | NO_DATA_FOUND_T
    | ERROR_T
    | ADD_T
    | EDIT_T
    | DELETE_T
    | MULTIPLE_DELETE_T
    | SELECT_T
