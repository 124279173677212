import styled, { StyledComponent } from 'styled-components/macro'

export const Wrapper = styled.section`
    border-radius: 6px;
    width: 100%;
    display: flex;
`
export const Container: StyledComponent<'div', any, any, never> = styled.div`
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: ${props => (props.flexDirection ? 'row' : 'column')};
`

export const HeaderName = styled.h4`
    width: 100%;
    float: left;
    font-family: 'Gordita-Medium';
    font-size: 18px;
    color: #000;
    margin-bottom: 0px;
    display: flex;
    div {
        position: relative;
        top: 2px;
        margin: 0px;
        padding-left: 6px;
        width: auto;
    }
`
export const HeaderLinkRight = styled.div`
    width: 100%;
    float: left;
    font-family: 'Gordita-Medium';
    font-size: 16px;
    color: #466af3;
    margin-bottom: 0px;
    text-align: right;
    padding-top: 10px;
    padding-bottom: 0px;
    position: relative;

    @media (max-width: 1600px) {
        font-size: 14px;
    }
`
export const CurrentPlanCardWrapper = styled.div`
    width: 100%;
    float: left;
    width: 100%;
    float: left;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 5px #16161629;
    border-radius: 10px;
    padding: 20px;
    display: grid;

    @media (max-width: 1600px) {
        padding: 15px 20px;
    }
`
export const CurrentPlanCard = styled.div`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: 35.3% 15% 15% 15% 15%;
    grid-gap: 20px;
    align-items: center;

    @media (max-width: 1600px) {
        grid-gap: 15px;
    }
    @media (max-width: 1500px) {
        grid-gap: 12px;
        grid-template-columns: 32% 16% 16% 16% 16%;
    }
    @media (max-width: 1400px) {
        grid-gap: 10px;
    }
    @media (max-width: 1400px) {
        grid-gap: 10px;
    }
`

export const BackButton = styled.button`
    background: ${props => (props.primary ? '#fff' : '#2468f6')};
    border-radius: 18px;
    border: ${props => (props.primary ? '1px solid #cecece' : '1px solid #2468f6')};
    outline: 0;
    font-size: 12px;
    color: ${props => (props.primary ? '#333' : '#fff')};
    font-family: 'Gordita-Regular';
    display: flex;
    align-items: center;
    min-width: 64px;
    height: 24px;
    transition: 0.346s ease;
    padding: 0px 10px;
    cursor: pointer;

    label {
        margin-bottom: 0px;
        position: relative;
        top: 1px;
        cursor: pointer;
    }
    :hover {
        border: 1px solid #ffe5d3;
        background: #ffe5d3;
        color: #333;
        fill: #333;
        outline: 0;
        cursor: pointer;
        span svg {
            fill: ${props => (props.primary ? '#333' : '#333')};
            color: ${props => (props.primary ? '#333' : '#333')};
        }
    }
    :active {
        background: ${props => (props.primary ? '#FFE5D3' : '#FFE5D3')}!important;
        border: ${props => (props.primary ? '1px solid #eb974e' : '1px solid #eb974e')} !important;
        color: ${props => (props.primary ? '#333' : '#333')}!important;
    }

    span {
        width: 10px;
        height: 10px;
        float: left;
        margin-right: 3px;
        display: flex;
        align-items: center;
        position: relative;
        top: 0px;
        svg {
            width: 10px;
            height: 10px;
            fill: ${props => (props.primary ? '#333' : '#fff')};
            color: ${props => (props.primary ? '#333' : '#fff')};
            transform: rotate(180deg);
        }
    }
`

export const BodyWrapper = styled.section`
    width: 100%;
    float: left;
    padding: 0px 20px 20px 20px;
    height: ${props => (props.scrollHeight ? 'calc(100vh - 100px)' : 'calc(100vh - 120px)')};
    overflow-y: scroll;
    padding-top: 20px;
`

export const Button = styled.button`
    height: 35px;
    border: 1px solid #466af3;
    border-radius: 18px;
    background: ${props => (props.bgColor ? '#fff' : '#466af3')};
    color: ${props => (props.bgColor ? '#466af3' : '#fff')};
    cursor: pointer;
    font-size: 16px;
    font-family: 'Gordita-Medium';
    padding: 0px 20px;
    transition: 0.3s linear;

    :hover {
        background: ${props => (props.bgColor ? '#466af3' : '#fff')};
        color: ${props => (props.bgColor ? '#fff' : '#466af3')};
    }

    @media (max-width: 1600px) {
        font-size: 15px;
        height: 42px;
    }
    @media (max-width: 1500px) {
        font-size: 15px;
        height: 36px;
    }
    @media (max-width: 1400px) {
        font-size: 14px;
        padding: 0px 15px;
    }
`
export const HeaderWidthAuto = styled.div`
    width: ${props => (props.progressWidth ? '100%' : 'auto')};
    float: left;
    display: flex;
    flex-direction: column;

    h4,
    h5,
    h6 {
        width: auto;
    }
`
export const UpgradeButton = styled.div`
    width: max-content;
    align-items: flex-end;
    display: flex;
    position: relative;
    bottom: 2px;

    button {
        margin-right: 0px;
        margin-left: 15px;
    }
`
export const ImgWrapper = styled.span`
    width: 100px;
    height: 100px;
    float: left;
    border: 1px solid #f0af83;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
    background: #ffe5d3;
    svg {
        fill: #000;
        color: #000;
    }

    @media (max-width: 1600px) {
        width: 84px;
        height: 84px;
        padding: 12px;
    }
    @media (max-width: 1500px) {
        width: 72px;
        height: 72px;
    }
    @media (max-width: 1300px) {
        width: 64px;
        height: 64px;
    }
`
export const PlanContetnWrapper = styled.div`
    float: left;
    padding-left: 15px;
    width: ${props => (props.progressWidth ? '60%' : '75%')} @media (max-width: 1600px) {
        padding-left: 10px;
    }
    @media (max-width: 1300px) {
        padding-left: 7px;
    }
`
export const CurrentPlanWrapper = styled.div`
    width: auto;
    float: left;
    display: flex;
    align-items: center;
    ${PlanContetnWrapper} {
        display: flex;
        width: ${props => (props.progressWidth ? '60%' : 'max-content')}
    }
    progress {
        text-align: right;
        color: white;
        background: #f4f4f4;
        width: 100%;
        border-radius: 5px;
        float: left;
        height: 10px;
      }
      
      progress::-webkit-progress-value {
        background: #F4CAAD;
        border-radius: 5px;
      }
      
      progress::-moz-progress-bar {
        background: #f4f4f4;
        border-radius: 5px;
      }
      
      progress::-webkit-progress-value {
        background: #F4CAAD;
        border-radius: 5px;
      }
      
      progress::-webkit-progress-bar {
        background: #f4f4f4;
        border-radius: 5px;
      }
    }
`
export const CurrentPlanHeader = styled.h4`
    width: 100%;
    float: left;
    font-family: 'Gordita-Medium';
    font-size: 16px;
    color: #000;
    margin-bottom: 12px;
`
export const Span = styled.span`
    width: auto;
`
export const CurrentPlanDetail = styled.h5`
    width: 100%;
    float: left;
    font-family: 'Gordita-Medium';
    font-size: 16px;
    color: #466af3;
    margin-bottom: 7px;

    ${Span} {
        font-size: 14px;
        color: #355d8b;
        font-family: 'Gordita-Regular';
        display: flex;
        align-items: center;
    }

    @media (max-width: 1600px) {
        ${Span} {
            font-size: 12px;
        }
    }

    @media (max-width: 1300px) {
        ${Span} {
            font-size: 11px;
        }
    }
`
export const TrialDate = styled.h6`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    font-size: 12px;
    color: #333;
    margin-bottom: 0px;
    ${Span} {
        font-size: 14px;
        color: #e88600;
        font-family: 'Gordita-Regular';
        cursor: pointer;
    }
    @media (max-width: 1600px) {
        ${Span} {
            font-size: 12px;
        }
    }

    @media (max-width: 1300px) {
        ${Span} {
            font-size: 11px;
        }
    }
`
export const CardContent = styled.div`
    width: max-content;
    float: left;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-right: 7px;
`
export const CardCircle = styled.div`
    width: 48px;
    height: 48px;
    svg {
        width: 100%;
        height: 100%;
    }
    @media (max-width: 1600px) {
        width: 40px;
        height: 40px;
    }
    @media (max-width: 1500px) {
        width: 36px;
        height: 36px;
    }
    @media (max-width: 1300px) {
        width: 30px;
        height: 30px;
    }
`
export const WorkflowWrapper = styled.div`
    background: #f1f3f5 0% 0% no-repeat padding-box;
    border-radius: 6px;
    height: 100px;
    padding: 20px;
    width: 100%;
    float: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .CircularProgressbar .CircularProgressbar-path {
        stroke: #466af3;
    }

    @media (max-width: 1600px) {
        height: 84px;
        padding: 15px;
    }
    @media (max-width: 1500px) {
        height: 72px;
        padding: 12px;
    }
    @media (max-width: 1400px) {
        padding: 10px;
    }
`
export const WorkflowHeader = styled.h5`
    width: 100%;
    float: left;
    font-size: 16px;
    color: #000;
    font-family: 'Gordita-Regular';
    margin-bottom: 10px;

    @media (max-width: 1600px) {
        font-size: 14px;
    }
    @media (max-width: 1400px) {
        font-size: 13px;
    }
    @media (max-width: 1300px) {
        font-size: 11px;
    }
`
export const WorkflowCount = styled.h6`
    width: 100%;
    float: left;
    font-size: 16px;
    font-family: 'Gordita-Regular';
    color: #526a86;
    margin-bottom: 0px;
    @media (max-width: 1600px) {
        font-size: 13px;
    }
    @media (max-width: 1500px) {
        font-size: 12px;
    }
    @media (max-width: 1400px) {
        font-size: 10px;
    }
`

export const QuestionPlan = styled.div`
    border: 1px solid #c6c6c6;
    border-radius: 6px;
    height: 100px;
    width: 100%;
    float: left;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 1600px) {
        padding: 15px;
        height: 84px;
    }
    @media (max-width: 1500px) {
        padding: 12px;
        height: 72px;
    }
    @media (max-width: 1400px) {
        padding: 10px;
    }

    ${WorkflowHeader} {
        @media (max-width: 1600px) {
            font-size: 13px;
        }

        @media (max-width: 1500px) {
            font-size: 12px;
        }

        @media (max-width: 1400px) {
            font-size: 10px;
        }
    }
`
export const QuestionPlanLink = styled.h6`
    width: 100%;
    float: left;
    font-size: 16px;
    font-family: 'Gordita-Medium';
    color: #466af3;
    margin-bottom: 0px;

    @media (max-width: 1600px) {
        font-size: 13px;
    }

    @media (max-width: 1500px) {
        font-size: 12px;
    }

    @media (max-width: 1400px) {
        font-size: 10px;
    }
`

export const Header = styled.div`
    width: 100%;
    float: left;
    height: 64px;
    border-bottom: 0.5px solid #e2e2e2;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background: #f4f4f4;
    padding: 0px 45px;
    ${QuestionPlan} {
        height: auto;
        border: none;
        width: 100%;
        display: flex;
        align-items: flex-end;

        h5,
        h6 {
            width: auto;
        }
        ${WorkflowHeader} {
            margin-bottom: 7px;

            @media (max-width: 1600px) {
                font-size: 16px;
            }

            @media (max-width: 1500px) {
                font-size: 14px;
            }

            @media (max-width: 1400px) {
                font-size: 14px;
            }
        }
        ${QuestionPlanLink} {
            @media (max-width: 1600px) {
                font-size: 16px;
            }

            @media (max-width: 1500px) {
                font-size: 14px;
            }

            @media (max-width: 1400px) {
                font-size: 14px;
            }
        }
    }
`
// Select Plan
export const UpgradePlanWrapper = styled.section`
    width: 100%;
    float: left;
    padding: 16px 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 5px #16161629;
    border-radius: 10px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const UpgradeHeader = styled.h4`
    width: 100%;
    float: left;
    font-family: 'Gordita-Medium';
    font-size: 22px;
    color: #000;
    margin-bottom: 0px;

    @media (max-width: 1600px) {
        font-size: 20px;
    }
    @media (max-width: 1500px) {
        font-size: 18px;
    }
    @media (max-width: 1400px) {
        font-size: 16px;
    }
`
export const UpgradePlanLeft = styled.div`
    width: auto;
    float: left;
`
export const UpgradePlanRight = styled.div`
    width: auto;
    float: right;
    display: flex;
`

export const HoverUserIcon = styled.span`
width:auto;
float:left;
display: flex;
align-items: center;

.info_icon {
    position: relative;
    width: auto;
    display: flex;
    align-items: center;
}
.info_icon {
    svg {
        width: 12px;
        height: 12px;
        color: #FF9922;
        cursor: pointer;
        margin-left: 7px;
        transition: all 0.8s ease 0s;
    }
    .side_menu_hvr {
        position: absolute;
        width: max-content;
        background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
        box-shadow: rgb(0 0 0 / 16%) 0px 10px 20px;
        padding: 6px 12px;
        font-size: 9px;
        border-radius: 4px;
        color: rgb(49, 81, 118);
        max-width: 315px;
        bottom: 25px;
        right: -23px;
        font-family: Gordita-Regular;
        display: none;
        :before {
            position: absolute;
            content: ' ';
            background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
            bottom: -11px;
            border-width: 6px;
            border-style: solid;
            border-color: transparent transparent white;
            top: inherit;
            right: 25px;
            transform: rotate(180deg);
        }
    }
}
.info_icon:hover .side_menu_hvr {
    display: flex;
}
@media (max-width:1600px){
    font-size: 14px;
    .info_icon {
        svg {
            width: 14px;
            height: 14px;
            position: relative;
            top: -1px;
        }
        .side_menu_hvr {
            bottom: 25px;
            right: -24px;
            font-size: 14px;
            max-width: 290px;
        }
}

`
export const Border = styled.span`
    width: auto;
    float: left;
    margin: 0px 10px;
    border-right: 1px solid #c6c6c6;
`
export const Toggler = styled.div`
    width: auto;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`
export const Toggle = styled.span`
    color: #000;
    transition: 0.2s;
    display: inline-block;
    vertical-align: middle;
    width: auto;
    float: left;
    font-family: 'Gordita-Regular';
    font-size: 20px;

    @media (max-width: 1700px) {
        font-size: 16px;
    }
    @media (max-width: 1500px) {
        font-size: 15px;
    }
`
export const ToggleSwitch = styled.span`
    position: relative;
    width: 48px;
    float: left;
    height: 22px;
    border-radius: 100px;
    background-color: #fff;
    overflow: hidden;
    border: 1px solid #2468f6;
    margin: 0px 10px;
`
export const Switch = styled.span`
    position: absolute;
    left: 2px;
    top: 2px;
    bottom: 2px;
    right: 57.5%;
    background-color: #2468f6;
    border-radius: 36px;
    z-index: 1;
    transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition-property: left, right;
    transition-delay: 0s, 0.08s;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    width: 16px;
    height: 16px;
`
export const CheckBox = styled.input`
    position: absolute;
    display: block;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 6;
    &:checked ~ ${Switch} {
        right: 2px;
        left: 61.5%;
        transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        transition-property: left, right;
        transition-delay: 0.08s, 0s;
    }
`
export const DropdownWrapper = styled.div`
    width: max-content;
    float: left;
    display: flex;
    align-items: center;
`
export const DropdownLiWrapper = styled.div`
    width: 100%;
    position: absolute;
    border-radius: 8px;
    right: 0px;
    left: 0%;
    top: 30px;

    ul {
        padding: 0px;
        height: 100%;
        overflow-y: scroll;
        max-height: 305px;
        margin-bottom: 0px;
        box-shadow: rgb(0 0 0 / 16%) 0 3px 6px;
        border-radius: 6px;
        position: relative;
        width: 100%;
        float: left;
        z-index: 1;

        li {
            width: 100%;
            float: left;
            display: inline-flex;
            align-items: center;
            border-bottom: 0.5px solid #e6e6e6;
            font-size: 14px;
            font-family: 'Gordita-light';
            height: 30px;
            padding: 0 10px;
            background: #fff;
            cursor: pointer;

            label {
                margin-bottom: 0px;
                cursor: pointer;
                width: auto;
            }
            img {
                width: 22px;
                height: 12px;
                margin-right: 7px;
                cursor: pointer;
            }
        }
        li:hover {
            background: #0a89e8;
            color: #fff;
            border-bottom: 0.5px solid #0a89e8;
        }
        li:last-child:hover {
            background: #0a89e8;
            color: #fff;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            border-bottom: 0.5px solid #0a89e8;
        }
        li:last-child {
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            background: #fff;
            border: none;
        }
        li:first-child:hover {
            background: #0a89e8;
            color: #fff;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            border-bottom: 0.5px solid #0a89e8;
        }
        li:first-child {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            background: #fff;
        }
    }
`
export const DropdownUl = styled.ul`
    width: 124px;
    height: 28px;
    background: #f1f3f5 0% 0% no-repeat padding-box;
    border-radius: 6px;
    display: flex;
    float: left;
    font-family: 'Gordita-Medium';
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    padding: 0px 10px;
    justify-content: space-between;
    margin-left: 10px;
    cursor: pointer;
    position: relative;
    label {
        margin-bottom: 0px;
    }
    img {
        width: 22px;
        height: 12px;
        margin-right: 7px;
    }
    .arrow_circle {
        display: flex;
        align-items: center;
        transform: rotate(90deg);
    }
    svg {
        width: 18px;
        height: 18px;
    }
`
// Pricing Plan Ui
export const PricingHeaderRight = styled.div`
    width: auto;
    float: left;
    ${ImgWrapper} {
        width: 80px;
        height: 80px;
    }

    @media (max-width: 1600px) {
        ${ImgWrapper} {
            width: 64px;
            height: 64px;
        }
    }
`
export const Paragraph = styled.p`
    width: 100%;
    float: left;
    text-align: left;
    font-family: 'Gordita-Regular';
    color: #526a86;
    font-size: 14px;
    margin-bottom: 0px;

    @media (max-width: 1600px) {
        font-size: 12px;
    }
    @media (max-width: 1400px) {
        font-size: 11px;
    }
`
export const AmountContent = styled.div`
    width: 100%;
    float: left;
    text-align: left;
    font-family: 'Gordita-Regular';
    font-size: 34px;
    color: #000;

    sub {
        font-family: 'Gordita-Regular';
        font-size: 18px;
        color: #000;
    }
    sup {
        font-family: 'Gordita-Regular';
        font-size: 22px;
        color: #000;
    }

    @media (max-width: 1600px) {
        font-size: 30px;
        sub {
            font-size: 16px;
        }
        sup {
            font-size: 20px;
        }
    }

    @media (max-width: 1500px) {
        font-size: 28px;
        sub {
            font-size: 14px;
        }
        sup {
            font-size: 18px;
        }
    }
    @media (max-width: 1400px) {
        font-size: 24px;
        sub {
            font-size: 14px;
        }
        sup {
            font-size: 15px;
        }
    }
`
export const HeaderContent = styled.div`
    width: 100%;
    float: left;
    text-align: left;
    font-family: 'Gordita-Medium';
    color: #526a86;
    font-size: 20px;

    @media (max-width: 1700px) {
        font-size: 16px;
    }

    @media (max-width: 1500px) {
        font-size: 15px;
    }
    @media (max-width: 1400px) {
        font-size: 14px;
    }
`
export const PricingHeaderLeft = styled.div`
    width: auto;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`
export const PricingHeader = styled.div`
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
    @media (max-width: 1400px) {
        padding: 0px 15px;
    }
`
export const PricingContainer = styled.div`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18%, 1fr));
    grid-auto-flow: column;
    grid-auto-columns: minmax(18%, 1fr);
    overflow-x: auto;
    overflow-y: hidden;
    grid-gap: 20px;
    padding: 0px 20px 5px 5px;
    position: relative;
    left: -5px;

    @media (max-width: 1600px) {
        grid-template-columns: repeat(auto-fill, minmax(22%, 1fr));
        grid-auto-flow: column;
        grid-auto-columns: minmax(22%, 1fr);
        overflow-x: auto;
        grid-gap: 20px;
    }
`
export const PricingSection = styled.section`
    width: 100%;
    float: left;
    margin-top: 20px;
    padding-bottom: 0px;
`
const ComingSoonWrapper = styled.div`
    width: 100%;
    float: left;
    display: flex;
    justify-content: left;
    font-family: 'Gordita-Regular';
    color: #000;
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 10px;

    @media (max-width: 1600px) {
        font-size: 14px;
        margin-bottom: 20px;
    }
`
export const MessageRight = styled.div`
    width: auto;
    float: left;
    font-family: 'Gordita-Medium';
    font-size: 16px;
    color: #333;
    display: flex;
    align-items: center;
    @media (max-width: 1600px) {
        font-size: 14px;
    }
    @media (max-width: 1500px) {
        font-size: 13px;
    }
    @media (max-width: 1400px) {
        font-size: 11px;
    }
`
export const MessageHeader = styled.h6`
    font-family: 'Gordita-Regular';
    font-size: 16px;
    color: #333;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    line-height: inherit;
    .tooltiptext_hover {
        max-width: 270px;
        box-shadow: none;
    }
    label {
        margin-bottom: 0px;
        display: flex;
        align-items: center;
        color: #333333;
    }

    span {
        width: 12px;
        height: 12px;
        float: right;
        margin-left: 5px;
        position: relative;
        cursor: pointer;
        display: flex;

        svg {
            width: 12px;
            height: 12px;
            color: #333333;
        }
    }

    /* START TOOLTIP STYLES */
    [data-tooltip] {
        position: absolute;
    }

    /* Applies to all tooltips */
    [data-tooltip]::before,
    [data-tooltip]::after {
        text-transform: none;
        font-size: 0.9em;
        line-height: 1;
        user-select: none;
        pointer-events: none;
        position: absolute;
        display: none;
        opacity: 0;
    }

    [data-tooltip]::before {
        content: '';
        border: 5px solid transparent;
        z-index: 1001;
    }

    [data-tooltip]::after {
        content: attr(data-tooltip);
        font-family: 'Gordita-Regular';
        color: #333;
        font-size: 16px;
        max-width: 270px;
        width: max-content;
        line-height: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
        background: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 10px;
        z-index: 1000;
        white-space: pre-wrap;
    }

    /* Make the tooltips respond to hover */
    [data-tooltip]:hover::before,
    [data-tooltip]:hover::after {
        display: block;
    }

    /* don't show empty tooltips */
    [data-tooltip='']::before,
    [data-tooltip='']::after {
        display: none !important;
    }

    /* FLOW: UP */
    [data-tooltip]:not([flow])::before,
    [data-tooltip][flow^='up']::before {
        bottom: 100%;
        border-bottom-width: 0;
        border-top-color: #fff;
    }

    [data-tooltip]:not([flow])::after,
    [data-tooltip][flow^='up']::after {
        bottom: calc(100% + 5px);
    }

    [data-tooltip]:not([flow])::before,
    [data-tooltip]:not([flow])::after,
    [data-tooltip][flow^='up']::before,
    [data-tooltip][flow^='up']::after {
        left: 50%;
        transform: translate(-50%, -0.5em);
    }

    /* KEYFRAMES */
    @keyframes tooltips-vert {
        to {
            opacity: 1;
            transform: translate(-50%, 0);
        }
    }

    @keyframes tooltips-horz {
        to {
            opacity: 1;
            transform: translate(0, -50%);
        }
    }

    /* FX All The Things */
    [data-tooltip]:not([flow]):hover::before,
    [data-tooltip]:not([flow]):hover::after,
    [data-tooltip][flow^='up']:hover::before,
    [data-tooltip][flow^='up']:hover::after,
    [data-tooltip][flow^='down']:hover::before,
    [data-tooltip][flow^='down']:hover::after {
        animation: tooltips-vert 300ms ease-out forwards;
    }

    [data-tooltip][flow^='left']:hover::before,
    [data-tooltip][flow^='left']:hover::after,
    [data-tooltip][flow^='right']:hover::before,
    [data-tooltip][flow^='right']:hover::after {
        animation: tooltips-horz 300ms ease-out forwards;
    }

    @media (max-width: 1600px) {
        font-size: 14px;
        [data-tooltip]::after {
            font-size: 14px;
        }
    }
    @media (max-width: 1500px) {
        font-size: 13px;
        [data-tooltip]::after {
            font-size: 13px;
        }
        span {
            svg {
                width: 11px;
                height: 11px;
                color: #333333;
            }
        }
    }
    @media (max-width: 1400px) {
        font-size: 12px;
        [data-tooltip]::after {
            font-size: 12px;
        }
    }
    @media (max-width: 1300px) {
        font-size: 12px;
        [data-tooltip]::after {
            font-size: 12px;
        }
    }
`
export const MessageLeft = styled.div`
    width: auto;
    float: left;
    display: flex;
    align-items: center;
`
export const MessageContainer = styled.div`
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;

    ${Paragraph} {
        font-size: 16px;
        font-family: 'Gordita-Regular';
        color: #333333;
        text-align: center;
        padding: 0px 20px;

        a {
            color: #466af3;
            :hover {
                text-decoration: none;
            }
        }
    }
    :last-child {
        margin-bottom: 0px;
    }
    @media (max-width: 1600px) {
        ${Paragraph} {
            font-size: 14px;
            padding: 10px 15px;
        }
    }
    @media (max-width: 1400px) {
        ${Paragraph} {
            font-size: 12px;
        }
    }
    @media (max-width: 1300px) {
        margin-bottom: 4px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        ${Paragraph} {
            font-size: 11px;
            padding: 20px 15px;
        }
    }
`
export const MessageWrapper = styled.div`
    width: 100%;
    float: left;
    background: #f8f8f8;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 15px;

    @media (max-width: 1400px) {
        padding: 15px;
    }
`
export const Count = styled.div`
    position: absolute;
    background: #eb974e;
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    font-size: 8px;
    font-family: 'Gordita-Medium';
    z-index: 1;
    right: -5px;
    top: -5px;
`
export const ContentWrapper = styled.div`
    width: 100%;
    float: left;
    ${MessageWrapper} {
        margin-top: 0px;
        background:#fff;
        padding-top:0px;
        padding-bottom:0px;
    }
    ${MessageRight} {
        position:relative;
        span {
        width: 16px;
        height: 16px;
        position:relative;
        svg {
            width: 16px;
            height: 16px;
        }
    }
    @media (max-width: 1500px) {
     
            span {
            width: 15px;
            height: 15px;
            position:relative;
            svg {
                width: 15px;
                height: 15px;
            }
    }
   
`
// Button
export const ButtonWrapper = styled.div`
    width: 100%;
    float: left;
    padding: 0px 20px;
    position: relative;
    ${HoverUserIcon} {
        position: absolute;
        top: 17px;
        right: 35px;
    }
    button {
        width: 100%;
        border: 1px solid #f0af83;
        border-radius: 21px;
        float: left;
        height: 42px;
        text-align: center;
        font-family: 'Gordita-Medium';
        font-size: 16px;
        color: #000;
        outline: 0;
        background: #ffe5d3;
        margin-bottom: 0px;
        margin-top: 5px;
        cursor: ${props => (props.dontAllowClick ? 'none' : 'pointer')};
        transition: 0.3s linear;

        :hover {
            background: #fff;
            color: #000;
        }
        focus: {
            outline: 0;
        }
    }
    @media (max-width: 1600px) {
        button {
            height: 36px;
            margin-top: 5px;
            font-size: 14px;
        }
    }

    @media (max-width: 1400px) {
        button {
            height: 36px;
            margin-top: 5px;
            font-size: 14px;
        }
        padding: 0px 15px;
    }
`
const FreeTrialButton = styled.div`
    button {
        background: #fff;
        color: #466af3;
        border: 1px solid #466af3;
        margin-bottom: 0px;
        margin-top: 15px;

        :hover {
            background: #466af3;
            color: #fff;
        }
    }
`
export const PricingCard = styled.div`
    width: 100%;
    float: left;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 5px #00000029;
    border-radius: 10px;
    padding: 15px 0px;
    padding-bottom: 5px;
    ${ContentWrapper} {
        margin-top: 0px;
        background: none;
    }
    ${MessageWrapper} {
        padding: 10px 20px;
        background: none;
    }

    ${PricingHeader} {
        ${PricingHeaderRight} {
            ${ImgWrapper} {
                background: #ffffff 0% 0% no-repeat padding-box;
                border: 1px solid #466af3;
                border-radius: 6px;
                svg {
                    fill: #466af3;
                }
            }
        }
    }
    :hover {
        background: #ffe5d3 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 5px #00000029;
        border-radius: 10px;
        ${MessageWrapper} {
            background: #fff5ef;
        }
        ${ContentWrapper} {
            padding-right: 3px;
            background: none;
            ${MessageWrapper} {
                background: #ffe5d3;

                ::-webkit-scrollbar-track {
                    background: #ffe5d3;
                }
            }
        }
        ${ButtonWrapper} {
            button {
                background: #fff;
                color: #000;
            }
            ${FreeTrialButton} {
                button {
                    background: #ffe5d3;
                    color: #000;
                    border: 1px solid #f0af83;
                }
            }
        }
        ${PricingHeader} {
            ${PricingHeaderRight} {
                ${ImgWrapper} {
                    background: #ffffff 0% 0% no-repeat padding-box;
                    border: 1px solid #f0af83;
                    border-radius: 6px;
                    svg {
                        fill: #000;
                    }
                }
            }
        }
    }

    @media (max-width: 1800px) and (min-width: 1601px) {
        ${MessageWrapper} {
            padding: 15px;
        }
        ${ButtonWrapper} {
            padding: 0px 15px;
        }
        ${MessageHeader} {
            font-size: 15px;
        }
    }

    @media (max-width: 1700px) {
        ${ContentWrapper} {
            ${MessageWrapper} {
                max-height: 240px;
                overflow-y: scroll;
                width: 100%;
                float: left;
                ::-webkit-scrollbar {
                    width: 4px;
                    display: block;
                }
                ::-webkit-scrollbar-thumb {
                    background: #f0af83;
                    height: 20px;
                    border-radius: 2px;
                }
                ::-webkit-scrollbar-track {
                    background: #fff;
                }
            }
            .two_btn_wrapper {
                max-height: 200px;
                overflow-y: scroll;
            }
        }
    }
`
export const PriceEnterpriseWrapper = styled.div`
    width: 100%;
    float: left;
    display:grid;

    button{
        margin-top:20px;
        margin-bottom:20px;
    }
    ${MessageContainer}{
        flex-wrap:wrap;
    }
    ${PricingHeaderLeft}{
        justify-content: center;
    }

    // @media (max-width: 1600px) {
    //     grid-column-start: 1;
    //     grid-column-end: 5;
    //     grid-row-start: 5;
    //     grid-row-end: 5;
    //     grid-template-columns: 100%;
    //     align-items: center;
    //     margin-top: 30px;

    //     ${PricingCard} {
    //         grid-template-columns: 25% 45% 25%;
    //         display: grid;
    //         justify-content: space-between;
    //         align-items: center;

    //         ${MessageWrapper} {
    //             margin-top: 0px;
    //         }
    //         ${PricingHeader} {
    //             justify-content: flex-start;
    //             align-items:center;
    //             ${PricingHeaderLeft}{
    //                 display: flex;
    //                 flex-direction: column;
    //                 margin-right:15px;
    //                 ${HeaderContent}{
    //                     width:auto;
    //                 }
    //                 ${AmountContent}{
    //                     AmountContent
    //                 }
    //             }
    //         }
    //         ${ButtonWrapper} {
    //             display: flex;
    //             justify-content: flex-end;

    //             button {
    //                 width: 140px;
    //             }
    //         }
    //     }
    // }
`
export const InformationContent = styled.p`
    width: 100%;
    float: left;
    font-family: 'Gordita-Medium';
    font-size: 16px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => (props.alertColor ? '#CE2D2D' : '#D97500')};
    ${Button} {
        height: 28px;
        margin-left: 15px;
        font-size: 14px;
    }
    ${HoverUserIcon} {
        width: max-content;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .info_icon {
            cursor: pointer;
            position: relative;
            width: auto;
            height: auto;
            margin-right: 0px;
            top: 0px;
            svg {
                margin-left: 0px;
                margin-right: 10px;
                cursor: pointer;
            }
        }
        .side_menu_hvr {
            font-size: 14px;
            max-width: calc(100% - -132px);
            z-index: 9;
            height: fit-content;
            top: 34px;
            position: absolute;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 10px 20px #00000029;
            border-radius: 6px;
            padding: 12px;
            left: -28px;
            color: #365579;
            font-family: 'Gordita-Regular';
            display: none;
            transition: 0.5s ease;
        }
        .info_icon:hover ~ .side_menu_hvr {
            display: flex;
        }
        .content_medium {
            font-family: 'Gordita-Medium';
            color: #000;
            margin-bottom: 0px;
        }
        .info_icon svg {
            fill: ${props => (props.alertColor ? '#F44949' : '#FF9922')};
        }
        .side_menu_hvr label {
            margin-bottom: 0px;
        }

        .side_menu_hvr:before {
            position: fixed;
            content: ' ';
            background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
            border-width: 15px;
            border-style: solid;
            border-color: #fff transparent transparent;
            margin-top: -35px;
            margin-left: 9px;
            bottom: unset;
            top: unset;
            right: unset;
            transform: rotate(180deg);
        }
    }
`
export const Information = styled.div`
    background: #ffefef 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 5px #16161629;
    border-radius: 0px;
    width: 100%;
    float: left;
    margin-top: 0px;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    @media (max-width: 1500px) {
        padding: 0px 15px;
    }
`
export const TopHeaderWrapper = styled.div`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
`
