import { useCombobox, useSelect } from 'downshift'
import { RawDraftContentBlock } from 'draft-js'
/*eslint-disable*/
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import Tooltip from 'react-tooltip-lite'

import { fetchActionConfig } from '@/DialogEditor/Canvas/Actions/AsyncOperations'
import { DialogT } from '@/DialogEditor/Canvas/Components/useFaqState'
import { NodeT, TreeT } from '@/Flows/canvas/types'
import { convertAsaSingleString } from '@/Flows/canvas/utils'
import { VideoLink } from '@/Videos/Videos'
import {
    DropdownWithCircle,
    HelpIconMenu,
    InfoIcon,
    QaIconWithCircle,
    RightArrowForAutomationErrors,
    SubPanelIcons
} from '@/common/Icons/Icons'
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
//COMMON MODULE IMPORTS
import { Loader } from '@/common/components/Loader'
import lazyGA from '@/common/google-analytics/tracking'
import {
    CancelButton,
    DropdownLi,
    DropdownUl,
    DropdownUlWrapper,
    DropdownWrapper,
    EmptyDiv,
    Header,
    HeaderLeft,
    HoverInputContainer,
    Input,
    InputContainer,
    InputContent,
    InputWrapper,
    Paragraph,
    PopupContainer,
    PopupScrollHeight,
    PopupWrapper,
    Wrapper
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { DraftInputWrapper } from '@/common/styled/DraftJs.Dumb'
import { HeaderRight } from '@/common/styled/Subscription.Xtra.Dumb'

import { AutomationApi, automationAPIURLWithWorkspace, logEvent, wrapperGetJson } from '@/common/utils/api-utils'
import { Button } from '../../Common/Effective'
// TYPE IMPORTS
import { AutomationT } from '../../Dialogue/kind'
import { DraftJST, ExpressionTypeE, converterToEditor, converterToServer } from '../../Editor/Action'
//Rich Editor Imports
import Editor from '../../Editor/Editor'
//ACTION IMPORTS
import {
    createResponseDialogue,
    fetchAutomationConfig,
    fetchAutomationList,
    updateResponseDialogue
} from '../../Mechanisms/action'
//CONTEXT IMPORTS
import { DialogueContext, VariableContext, VariableContextT } from '../Context'
import { ErrorState, ErrorT, FLOW_DIALOGUE, VIEW } from '../kind'
import { sortAnArrayByName } from '@/common/utils/common.utils'

const { v4: uuidv4 } = require('uuid')
const Ramda = require('ramda')

interface AutomationWrapperP {
    children: React.ReactChild
}

const FlowTips = () => (
    <>
        <p>
            Use{' '}
            <a
                target="_blank"
                className="sidepanel_href_color"
                href="https://help.workativ.com/index.php/knowledgebase/call-an-automation/"
            >
                Call Automation
            </a>{' '}
            when you want the bot to execute an app-based workflow automation.
        </p>
        <p>
            You will need to select <b>context variables</b> as workflow <b>input values</b>. This enables the workflow
            to receive user's input (to execute the workflow).
        </p>
        <p>
            Once you save the workflow, the bot will create <b>‘Succes’</b> and <b>‘Failure’</b> sub-dialogs
            automatically so you can(admin) can then add dialog flow for success and failure sub-dialogs.
        </p>
    </>
)

const AutomationWrapper = (props: AutomationWrapperP) => (
    <div className="right-panel slots_panel">
        <h5 className="popup_header">
            Call an Automation{' '}
            <span className="help_doc_span">
                <a target="_blank" href="https://help.workativ.com/knowledgebase/call-an-automation/">
                    <HelpIconMenu />
                </a>
                <span className="side_menu_hvr">Help</span>
            </span>
        </h5>
        <p className="call_automation_para">Select workflow automation to execute in this dialog </p>
        {props.children}
    </div>
)

interface FlowInputP {
    input: string
    value: DraftJST
    onChange: Function
}

const FlowInput = ({ input, value, onChange }: FlowInputP) => (
    <InputWrapper style={{ padding: 0 }}>
        <DraftInputWrapper>
            <div className="select_work_form">
                <Editor
                    focusContent={input}
                    DraftMap={value}
                    setDraftMap={onChange}
                    hideToolbar
                    focus={
                        convertAsaSingleString(value.blocks as RawDraftContentBlock[]).replace(/\s/g, '').length
                            ? 'focus_input'
                            : ''
                    }
                />
            </div>
        </DraftInputWrapper>
    </InputWrapper>
)

interface FlowT {
    id: string
    name: string
    hasApproval: boolean
}

type failureSubnodeT = {
    title: string;
    variable: string;
    rhs: boolean;
}

const MOCK_AUTOMATION: { id: string; name: string } = {
    id: '',
    name: ''
}
const Automation = () => {
    const context = useContext(DialogueContext)

    const variableContext: VariableContextT = React.useContext(VariableContext)

    const queryClient = useQueryClient()

    const { workspaceName, assistantID, PageState, setPageState, fetchCallback, dialogueName, rootDialogs } = context

    const [loading, setLoading] = useState<boolean>(true)

    const [error, setError] = useState<ErrorT>(ErrorState)

    const [changeFlow, setChangeFlow] = useState<boolean>(false) // flow change drop down open or close

    const [automations, setAutomations] = useState<FlowT[]>([]) // flow list

    const [selectedAutomation, setSelectedAutomation] = useState<{ id: string; name: string }>(MOCK_AUTOMATION)

    const [automationName, setAutomationName] = useState<string>('') // automation name

    const [successChildNode, setSuccessChildNode] = useState<boolean>(true)

    const [failureChildNode, setFailureChildNode] = useState<boolean>(true)

    const [subNodes, setSubnodes] = useState<{ title: string; variable: string; rhs: boolean; action_id?: string }[]>([
        {
            title: 'success',
            variable: 'success',
            rhs: true
        }
    ])

    const [ failiureSubNode, setFailureSubNode ] = useState< {} | failureSubnodeT>({
        title: 'failure',
        variable: 'success',
        rhs: false
    })

    const [inputObj, setInputObj] = useState<any>({})

    const [automationConfig, setAutomationConfig] = useState<any[]>([])
    const [automationType, setAutomationType] = useState<string>('without approval')

    const [failureThen, setFailureThen] = useState<string>('')
    const [failureDialog, setFailureDialog] = useState<DialogT>({ id: '', title: '', uuid: '' })
    const [failureMssage, setFailureMssage] = useState<DraftJST>({ blocks: [], entityMap: {} })

    const [successThen, setSuccessThen] = useState<string>('')
    const [successDialog, setSuccessDialog] = useState<DialogT>({ id: '', title: '', uuid: '' })
    const [successMessage, setSuccessMessage] = useState<DraftJST>({ blocks: [], entityMap: {} })
    const [flow, setFlow] = useState({})

    useEffect(() => {
        const { data } = PageState as FLOW_DIALOGUE
        setLoading(true)

        fetchAutomationList(workspaceName)
            .then(res => {
                setAutomations(res.output.automations)
                if (data.id !== '') {
                    const selectAutomation = res.output.automations.filter(
                        (flow: AutomationT) => flow.id == data.automation
                    )[0]
                    setFailureChildNode(false)
                    setSuccessChildNode(false)
                    if (selectAutomation) {
                        setSelectedAutomation(selectAutomation)
                        setAutomationName(data.name)
                        setAutomationType(data.automation_type == 'automation' ? 'without approval' : 'approval')

                        if (data.automation_type == 'approval') {
                            const d = data as any
                            setSuccessMessage(converterToEditor(d.on_sucess.response, context, context.dialogueNode))
                            setFailureMssage(converterToEditor(d.on_failure.response, context, context.dialogueNode))
                            setSuccessThen(d.on_sucess.next_step['jump_to'] ? 'jump_to' : 'exit')
                            setFailureThen(d.on_failure.next_step['jump_to'] ? 'jump_to' : 'exit')
                            d.on_sucess.next_step['jump_to'] &&
                                setSuccessDialog({
                                    id: d.on_sucess.next_step['jump_to'].to,
                                    title: rootDialogs.filter(dial => dial.id == d.on_sucess.next_step['jump_to'].to || dial.uuid == d.on_sucess.next_step['jump_to'].to)[0]
                                        ? rootDialogs.filter(dial => dial.id == d.on_sucess.next_step['jump_to'].to || dial.uuid == d.on_sucess.next_step['jump_to'].to)[0]
                                              .title
                                        : '',
                                    uuid: d.on_sucess.next_step['jump_to'].to
                                })
                            d.on_failure.next_step['jump_to'] &&
                                setFailureDialog({
                                    id: d.on_failure.next_step['jump_to'].to,
                                    title: rootDialogs.filter(
                                        dial => dial.id == d.on_failure.next_step['jump_to'].to || dial.uuid == d.on_failure.next_step['jump_to'].to
                                    )[0]
                                        ? rootDialogs.filter(dial => dial.id == d.on_failure.next_step['jump_to'].to || dial.uuid == d.on_failure.next_step['jump_to'].to)[0]
                                              .title
                                        : '',
                                    uuid: d.on_failure.next_step['jump_to'].to
                                })
                        }
                        fetchAutomationConfig(workspaceName, selectAutomation.id).then(res => {
                            setInputObj(
                                Object.keys(data.input).reduce(
                                    (acuum: any, val: any) =>
                                        Ramda.assoc(
                                            val,
                                            converterToEditor(data.input[val], context, context.dialogueNode),
                                            acuum
                                        ),
                                    {}
                                )
                            )
                            setAutomationConfig(res.output)
                            setFailureChildNode(false)
                            setSuccessChildNode(false)
                            setLoading(false)
                        })
                    } else {
                        setLoading(false)
                    }
                } else {
                    setLoading(false)
                }
            })
            .catch(error => {
                setError({ error: true, info: 'Something went wrong. Please try again!' })
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        const { data } = PageState as FLOW_DIALOGUE

        if (selectedAutomation.id.length > 0 && automationType != 'approval' && data.id.length == 0) {
            wrapperGetJson(
                automationAPIURLWithWorkspace(workspaceName, `${AutomationApi.GetDetail}/${selectedAutomation.id}`)
            ).then((response: any) => {
                console.log(response, 'response')
                setFlow(response.data.output)
            })
        }
    }, [selectedAutomation])

    const changeAutomation = (automation: { name: any; id: any }) => {
        setInputObj({})
        fetchAutomationConfig(workspaceName, automation.id).then(data => {
            const input = Object.keys(data.output).reduce(
                (acc: any, key: string) =>
                    Ramda.assoc(
                        key,
                        converterToEditor({
                            type: ExpressionTypeE.TEXT,
                            value: ''
                        }),
                        acc
                    ),
                {}
            )
            setInputObj(input)
            setSelectedAutomation(automation)
            setAutomationConfig(data.output)
        })
    }

    function returnChilds(
        subNodes: {
            title: string
            variable: string
            rhs: boolean
            action_id?: string
        }[],
        node: {
            output_variable: string
        }
    ) {
        if (automationType == 'approval') return []

        if (subNodes.length > 0) {
            return (Object.keys(failiureSubNode).length > 0 ? subNodes.concat(failiureSubNode as failureSubnodeT) : subNodes).map(s => ({
                title: s.title,
                conditions: {
                    type: 'ComparisionExpression',
                    op: 'equals',

                    lhs: {
                        path: s.action_id
                            ? [node.output_variable, 'output', 'errorContext', s.action_id, s.variable]
                            : [node.output_variable, 'output', 'executionContext', 'output', s.variable]
                    },
                    rhs: s.rhs
                }
            }))
        } else return []
    }

    const handleSave = async () => {
        setLoading(true)

        try {
            const { data } = PageState as FLOW_DIALOGUE

            const inputMeta = Object.keys(inputObj).reduce((accum: any, val: any) => {
                return Ramda.assoc(val, converterToServer(inputObj[val]), accum)
            }, {})
            const node = {
                output_variable: data.id == '' ? 'SYS_' + uuidv4().replace(/-/g, '_') : data.output_variable
            }

            // let node: any = null

            const req =
                automationType != 'approval'
                    ? ({
                          ...data,
                          name: automationName,
                          automation_name: selectedAutomation.name,
                          automation: selectedAutomation.id,
                          input: inputMeta,
                          automation_type: 'automation',
                          output_variable: node.output_variable
                      } as AutomationT)
                    : ({
                          ...data,
                          output_variable: node.output_variable,
                          name: automationName,
                          automation_name: selectedAutomation.name,
                          automation: selectedAutomation.id,
                          input: inputMeta,
                          automation_type: 'approval',
                          on_sucess: {
                              response: converterToServer(successMessage),
                              next_step:
                                  successThen == 'jump_to'
                                      ? {
                                            jump_to: {
                                                to: successDialog.id,
                                                selector: 'body'
                                            }
                                        }
                                      : {
                                            exit: null
                                        }
                          },
                          on_failure: {
                              response: converterToServer(failureMssage),
                              next_step:
                                  failureThen == 'jump_to'
                                      ? {
                                            jump_to: {
                                                to: failureDialog.id,
                                                selector: 'body'
                                            }
                                        }
                                      : {
                                            exit: null
                                        }
                          }
                      } as AutomationT)
            if (data.id == '') {
                await createResponseDialogue(
                    workspaceName,
                    assistantID,
                    data.parent,
                    req,
                    dialogueName,
                    returnChilds(subNodes, node)
                )
                // INVALIDATE CACHE USED IN WORKFLOW PAGE
                queryClient.refetchQueries('workflow-bots')

                logEvent(workspaceName, {
                    event:"EVENT::DIALOG::ADD_CALL_AUTOMATION", event_meta:{
                    name: dialogueName,
                    description: ""
                }})

                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Automation Node Created'
                })
            } else {
                await updateResponseDialogue(workspaceName, assistantID, data.parent, data.id, req, dialogueName)
                // INVALIDATE CACHE USED IN WORKFLOW PAGE
                queryClient.refetchQueries('workflow-bots')

                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Automation Node Updated'
                })
            }

            fetchCallback(() => setLoading(false))
        } catch (error) {
            setLoading(false)
            setError({ error: true, info: 'Something went wrong. Please try again!' })
        }
    }

    const PopupHeader = ({ state }: any) => {
        return (
            <>
                <Header>
                    <HeaderLeft minWidth={300}>
                        Call automation
                        <Tooltip
                            className="target customTip"
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content={<FlowTips />}
                            distance={5}
                        >
                            <InfoIcon />
                        </Tooltip>
                        <Tooltip
                            className="target customTip"
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content="View help document"
                            distance={5}
                        >
                            <a href="https://help.workativ.com/knowledgebase/call-an-automation/" target="_blank">
                                <QaIconWithCircle />
                            </a>
                        </Tooltip>
                    </HeaderLeft>
                    {/* <HeaderRight>
                        <Paragraph cursor>
                            <VideoIcon />
                            How to create
                        </Paragraph>
                    </HeaderRight> */}
                    <HeaderRight>
                        <VideoLink id={'CALL_AUTOMATION_APP_WORKFLOW'} text={'How to'} />
                    </HeaderRight>
                </Header>
                <Paragraph>Execute app workflow.</Paragraph>
            </>
        )
    }

    const PopupInputWrapper = () => {
        const { data } = PageState as FLOW_DIALOGUE
        const { automation } = data
        const redirectToWorkflow = () => {
            window.location.href = `/workspace/${workspaceName}/automation/view/home`
        }
        return (
            <InputWrapper>
                <DropdownWrapper>
                    <UseComboboxJumpTo
                        items={[
                            { title: 'Approval', value: 'approval' },
                            { title: 'Without Approval', value: 'without approval' }
                        ]}
                        value={
                            [
                                { title: 'Approval', value: 'approval' },
                                { title: 'Without Approval', value: 'without approval' }
                            ].find(a => a.value == automationType) as any
                        }
                        onChange={(value: any) => {
                            setAutomationName('')
                            setSelectedAutomation(MOCK_AUTOMATION)
                            setInputObj({})
                            if (value.value == 'approval') {
                                setSuccessThen('')
                                setFailureThen('')
                                setSuccessMessage({ blocks: [], entityMap: {} })
                                setFailureMssage({ blocks: [], entityMap: {} })
                                setSuccessDialog({ id: '', title: '', uuid: '' })
                                setFailureDialog({ id: '', title: '', uuid: '' })
                            }
                            setAutomationType(value.value)
                        }}
                        title={'Workflow type'}
                    />
                    <UseComboboxJumpTo
                        items={automations
                            .filter((auto: FlowT) =>
                                automationType == 'approval' ? auto.hasApproval : !auto.hasApproval
                            )
                            .map((automation: FlowT, index: number) => ({
                                title: automation.name,
                                value: automation.id
                            }))}
                        value={{ title: selectedAutomation.name, value: selectedAutomation.id }}
                        onChange={(value: any) => {
                            changeAutomation({ name: value.title, id: value.value })
                            setSubnodes([
                                {
                                    title: 'success',
                                    variable: 'success',
                                    rhs: true
                                }  
                            ])
                            setFailureSubNode({
                                title: 'failure',
                                variable: 'success',
                                rhs: false
                            })
                            setFlow({})
                        }}
                        title={'Select workflow'}
                        isNull={automation}
                        fallbackCallback={redirectToWorkflow}
                    />
                    <InputContainer>
                        <Input
                            type="text"
                            value={automationName}
                            // placeholder="Enter Name"
                            onChange={(e: any) => setAutomationName(e.target.value)}
                        />
                        <InputContent>Name this workflow</InputContent>
                    </InputContainer>
                </DropdownWrapper>
            </InputWrapper>
        )
    }

    function disablesave() {
        return (
            automationName == '' ||
            selectedAutomation.id == '' ||
            Object.keys(inputObj).filter(
                key => convertAsaSingleString(inputObj[key].blocks as RawDraftContentBlock[]).replace(/\s/g, '').length
            ).length != Object.keys(inputObj).length ||
            (automationType != 'approval'
                ? false
                : !convertAsaSingleString(successMessage.blocks as RawDraftContentBlock[]).replace(/\s/g, '').length ||
                  !convertAsaSingleString(failureMssage.blocks as RawDraftContentBlock[]).replace(/\s/g, '').length ||
                  (successThen == 'jump_to' && successDialog.uuid == '') ||
                  (failureThen == 'jump_to' && failureDialog.uuid == ''))
        )
    }
    const { data } = PageState as FLOW_DIALOGUE

    return (
        // <AutomationWrapper>
        <Wrapper className="faq_popup">
            <PopupWrapper>
                <PopupContainer>
                    <PopupHeader state={{}} />

                    {loading ? (
                        <div className="popup_loader">
                            <Loader.PopupLoader show={loading} />
                        </div>
                    ) : (
                        <ErrorBoundary
                            error={error}
                            render={(err: any, info: any) => {
                                return err && <Error.Delete onClick={() => setPageState(VIEW)} info={info} />
                            }}
                        >
                            <React.Fragment>
                                <PopupScrollHeight>
                                    <EmptyDiv>
                                        {PopupInputWrapper()}
                                        {data.id.length == 0 && automationType != 'approval' && (
                                            <InputWrapper>
                                                <div className="dialog_call_automation_checkbox">
                                                    <div className="select_work_form">
                                                        <Header fontMedium style={{ fontSize: 14, alignItems: 'center'}}>
                                                            <span className="call_automation_bot_icon">
                                                                <SubPanelIcons.BotIcon />
                                                            </span>
                                                            Create sub-dialogs to handle following scenarios{' '}
                                                            <Tooltip
                                                                className="target customTip"
                                                                zIndex={10000}
                                                                arrowSize={8}
                                                                tagName="span"
                                                                content="Check boxes to create child or sub-dialogs for workflow ‘success’ and ‘failure’ scenarios."
                                                                distance={5}
                                                            >
                                                                <InfoIcon />
                                                            </Tooltip>
                                                        </Header>
                                                        <div className="checkbox_inline_width call_automation_checkbox_container">
                                                            <label className="checkbox_inline">
                                                                <input
                                                                    className="checkbox_inline_input"
                                                                    type="checkbox"
                                                                    checked={
                                                                        subNodes.filter(s => s.title == 'success')
                                                                            .length > 0
                                                                    }
                                                                    onChange={e => {}}
                                                                    onClick={e => {
                                                                        setSubnodes(
                                                                            subNodes.filter(s => s.title == 'success')
                                                                                .length > 0
                                                                                ? subNodes.filter(
                                                                                      s => s.title != 'success'
                                                                                  )
                                                                                : [
                                                                                      ...subNodes,
                                                                                      {
                                                                                          title: 'success',
                                                                                          variable: 'success',
                                                                                          rhs: true
                                                                                      }
                                                                                  ]
                                                                        )
                                                                    }}
                                                                />
                                                                <span className="check_mark"></span>
                                                                <span>App workflow success</span>
                                                            </label>
                                                            <label className="checkbox_inline">
                                                                <input
                                                                    className="checkbox_inline_input"
                                                                    type="checkbox"
                                                                    checked={
                                                                        Object.keys(failiureSubNode).length>0
                                                                    }
                                                                    onChange={e => {}}
                                                                    onClick={e => {
                                                                        setFailureSubNode(
                                                                            Object.keys(failiureSubNode).length > 0
                                                                                ? {}
                                                                                : {
                                                                                          title: 'failure',
                                                                                          variable: 'success',
                                                                                          rhs: false
                                                                                }
                                                                        )
                                                                    }}
                                                                />
                                                                <span className="check_mark"></span>
                                                                <span>App workflow failure</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                {Object.keys(flow).length > 0 && (
                                                    <div className="main_menu_container">
                                                        <ul className="main_menu">
                                                            <ActionsList
                                                                flowList={
                                                                    (flow as any).custom_json.children[0].children
                                                                }
                                                                workspaceName={workspaceName}
                                                                subNodes={subNodes}
                                                                setSubnodes={setSubnodes}
                                                            />
                                                        </ul>
                                                    </div>
                                                )}
                                            </InputWrapper>
                                        )}
                                        {selectedAutomation.id.length > 0 && Object.keys(inputObj).length > 0 && (
                                            <InputWrapper>
                                                <Header>Workflow inputs </Header>
                                                <HoverInputContainer>
                                                    {Object.keys(inputObj).map((input: any, index: number) => {
                                                        return (
                                                            <FlowInput
                                                                key={index}
                                                                input={
                                                                    automationConfig[input]
                                                                        ? automationConfig[input].title
                                                                        : input
                                                                }
                                                                value={inputObj[input]}
                                                                onChange={(value: string) =>
                                                                    setInputObj((automation: any) =>
                                                                        Ramda.assoc(input, value, automation)
                                                                    )
                                                                }
                                                            />
                                                        )
                                                    })}
                                                </HoverInputContainer>
                                            </InputWrapper>
                                        )}
                                        {selectedAutomation.id.length > 0 && automationType == 'approval' && (
                                            <>
                                                <InputWrapper>
                                                    <Header>
                                                        Workflow success{' '}
                                                        <Tooltip
                                                            className="target customTip"
                                                            zIndex={10000}
                                                            arrowSize={8}
                                                            tagName="span"
                                                            content="Setup how you want the bot to respond if workflow executed successfully"
                                                            distance={3}
                                                        >
                                                            <InfoIcon />
                                                        </Tooltip>
                                                    </Header>
                                                    <HoverInputContainer>
                                                        <InputContainer>
                                                            <FlowInput
                                                                input={'Send message to user'}
                                                                value={successMessage}
                                                                onChange={(value: DraftJST) => {
                                                                    setSuccessMessage(value)
                                                                }}
                                                            />
                                                        </InputContainer>
                                                        <NextStep
                                                            then={successThen}
                                                            dialog={successDialog}
                                                            setDialog={setSuccessDialog as any}
                                                            GetDialogs={() => {}}
                                                            dialogs={rootDialogs}
                                                            setThen={setSuccessThen as any}
                                                            workspacename={workspaceName}
                                                            dialougeId={successDialog.id}
                                                        />
                                                    </HoverInputContainer>
                                                </InputWrapper>
                                                <InputWrapper>
                                                    <Header>
                                                        Workflow failure{' '}
                                                        <Tooltip
                                                            className="target customTip"
                                                            zIndex={10000}
                                                            arrowSize={8}
                                                            tagName="span"
                                                            content="Setup how you want the bot to respond if workflow execution failed"
                                                            distance={3}
                                                        >
                                                            <InfoIcon />
                                                        </Tooltip>
                                                    </Header>
                                                    <HoverInputContainer>
                                                        <InputContainer>
                                                            <FlowInput
                                                                input={'Send message to user'}
                                                                value={failureMssage}
                                                                onChange={(value: DraftJST) => {
                                                                    setFailureMssage(value)
                                                                }}
                                                            />
                                                        </InputContainer>
                                                        <NextStep
                                                            then={failureThen}
                                                            dialog={failureDialog}
                                                            setDialog={setFailureDialog as any}
                                                            GetDialogs={() => {}}
                                                            dialogs={rootDialogs}
                                                            setThen={setFailureThen as any}
                                                            workspacename={workspaceName}
                                                            dialougeId={failureDialog.id}
                                                        />
                                                    </HoverInputContainer>
                                                </InputWrapper>
                                            </>
                                        )}
                                    </EmptyDiv>

                                    <Button
                                        className="footer_button_fixed"
                                        disable={disablesave()|| variableContext.isUrlOpen}
                                        onCancel={() => setPageState(VIEW)}
                                        onSave={() => handleSave()}
                                    />
                                </PopupScrollHeight>
                            </React.Fragment>
                        </ErrorBoundary>
                    )}
                </PopupContainer>
            </PopupWrapper>
        </Wrapper>
    )
}

function ActionsList(props: {
    flowList: TreeT<NodeT>[]
    workspaceName: string
    subNodes: {
        title: string
        variable: string
        rhs: boolean
    }[]
    setSubnodes: Function
}) {
    const { flowList, workspaceName, subNodes, setSubnodes } = props

    const [errors, setErrors] = useState({})
    const [selectedAction, setSelectedAction] = useState<any>({})

    const [isOpen, setIsOpen] = useState(false)

    const selectAction = (action: any) => {
        console.log(action, 'action')
        setSelectedAction(action)

        // const result = actionMixture[action.meta_data.action_id]
        // if (result) {
        //     setSelectedAction(action)
        //     setOutput(result.output)
        // }
    }

    useEffect(() => {
        if (selectedAction.id && selectedAction.id.length > 0 && Object.keys(errors).length == 0) {
            fetchActionConfig(workspaceName, selectedAction.meta_data.action_id).then((response: any) => {
                console.log(response, 'response')
                setErrors(response.output.errors)
            })
        }
    }, [selectedAction])

    return (
        <>
            {flowList.map((flow: TreeT<NodeT>, index: number) =>
                flow.value.kind == 'Action' ? (
                    <li key={index}>
                        <span
                            className={isOpen ? 'call_automation_downarrow_img' : 'call_automation_arrowImg'}
                            onClick={() => {
                                setIsOpen(!isOpen)
                                selectAction(flow.value)
                            }}
                        >
                            <RightArrowForAutomationErrors />
                        </span>
                        <span>
                            <img src={flow.value.icon} />{' '}
                        </span>
                        {flow.value.name}

                        <ul className="sub_menu_1">
                            {isOpen &&
                                Object.values(errors).map((er: any, i: number) => (
                                    <li key={i}>
                                        <input
                                            className="call_automation_checkbox"
                                            type="checkbox"
                                            checked={subNodes.filter(s => s.variable == er.code).length > 0}
                                            onChange={e => {}}
                                            onClick={e => {
                                                setSubnodes(
                                                    subNodes.filter(s => s.variable == er.code).length > 0
                                                        ? subNodes.filter(s => s.variable != er.code)
                                                        : [
                                                              ...subNodes,
                                                              {
                                                                  title: er.message,
                                                                  variable: er.code,
                                                                  rhs: true,
                                                                  action_id: flow.value.id
                                                              }
                                                          ]
                                                )
                                            }}
                                        />
                                        <span className="check_mark"></span>
                                        {er.message}
                                    </li>
                                ))}
                            {flow.children && flow.children.length > 0 ? (
                                <ActionsList
                                    flowList={flow.children}
                                    workspaceName={workspaceName}
                                    subNodes={subNodes}
                                    setSubnodes={setSubnodes}
                                />
                            ) : null}
                        </ul>
                    </li>
                ) : (
                    flow.children && flow.children.length > 0 &&
                    <li key={index}>
                        <ul className="sub_menu_1">
                            <ActionsList
                                flowList={flow.children}
                                workspaceName={workspaceName}
                                subNodes={subNodes}
                                setSubnodes={setSubnodes}
                            />
                        </ul>
                    </li>
                )
            )}
        </>
    )
}

function UseComboboxJumpTo({
    items,
    value,
    onChange,
    title,
    isNull,
    fallbackCallback
}: {
    items: { title: string; value: string }[]
    value: { title: string; value: string }
    onChange: any
    title: string
    isNull?: string | null
    fallbackCallback?: Function
}) {
    const displayItem = (item: { title: string; value: string } | null) => (item ? item.title : '')
    const searchBox: any = useRef(null)
    const [inputValue, setInputValue] = useState<string>('')
    const [inputItems, setInputItems] = useState(items)
    const {
        isOpen,
        selectedItem,
        getToggleButtonProps,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        getItemProps
    } = useCombobox({
        items: inputItems,
        selectedItem: value,
        itemToString: displayItem,
        onSelectedItemChange: ({ selectedItem }) => {
            onChange(selectedItem)
        },
        inputValue,
        onInputValueChange: ({ inputValue }: any) => {
            setInputItems(
                items.filter(
                    (item: { title: string; value: string }) =>
                        item.title.toLowerCase().indexOf(inputValue.toLowerCase()) != -1
                )
            )
            setInputValue(inputValue)
        }
    })

    useEffect(() => {
        if (isOpen && searchBox.current) {
            searchBox.current.focus()
            setInputValue('')
            setInputItems(items)
        }
    }, [isOpen])

    return (
        <InputContainer>
            <div {...getComboboxProps()}>
                {!isOpen ? (
                    <Input {...getToggleButtonProps()} value={selectedItem && displayItem(selectedItem)} />
                ) : (
                    <Input
                        {...getInputProps({
                            ref: searchBox
                        })}
                    />
                )}
                <InputContent>{title}</InputContent>
                <CancelButton>
                    {isNull === null && selectedItem && selectedItem.title === '' && (
                        <div className="error_input_show parent" style={{ top: 0, right: 25 }}>
                            <WarningAlertIcon />
                            <span
                                className="error_menu_hvr"
                                style={{
                                    left: -125,
                                    top: -40
                                }}
                            >
                                Select workflow to proceed.
                            </span>
                        </div>
                    )}
                    <DropdownWithCircle />
                </CancelButton>
            </div>
            <DropdownUlWrapper>
                <DropdownUl {...getMenuProps()}>
                    {isOpen ? (
                        inputItems.length !== 0 ? (
                            inputItems.map((item: { title: string; value: string }, index: number) => {
                                return (
                                    <DropdownLi key={`${item.title}${index}`} {...getItemProps({ item, index })}>
                                        {item.title}
                                    </DropdownLi>
                                )
                            })
                        ) : (
                            <DropdownLi className="d-flex flex-column h-auto p-2 align-items-start">
                                <div>No workflow found. Create new Workflows</div>
                                <div
                                    className="workflow_link"
                                    onClick={(event: any) => {
                                        event.stopPropagation()
                                        fallbackCallback && fallbackCallback()
                                    }}
                                >
                                    <a
                                        href="#"
                                        // onClick={() => {
                                        //     window.open(
                                        //         'https://help.workativ.com/kb/workativ-assistant/workflow-builder'
                                        //     )
                                        //     return false
                                        // }}
                                    >
                                        <div className="drodown_li_span">
                                            <span>
                                                <svg
                                                    id="Layer_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 24 24"
                                                    xmlSpace="preserve"
                                                >
                                                    <style
                                                        dangerouslySetInnerHTML={{
                                                            __html: '.swt0{fill:currentColor}'
                                                        }}
                                                    />
                                                    <g id="call_automation_" transform="translate(-874 -20139)">
                                                        <g id="Group_8117" transform="translate(825.112 20144.637)">
                                                            <path
                                                                id="Path_16"
                                                                className="swt0"
                                                                d="M62.5 11.4h-2.6c-1.9 0-2.2-1.3-2.2-2.5v-6h.8v6c0 1.3.3 1.7 1.3 1.7h2.6l.1.8z"
                                                            />
                                                            <path
                                                                id="Rectangle_4"
                                                                className="swt0"
                                                                d="M58.2 5.8H62.400000000000006V6.6H58.2z"
                                                            />
                                                            <path
                                                                id="Path_17"
                                                                className="swt0"
                                                                d="M58.1 3.2c-.8 0-1.5-.7-1.5-1.5S57.3.2 58.1.2s1.5.7 1.5 1.5-.7 1.5-1.5 1.5zm-.7-2c-.3.3-.3.8 0 1.3.4.3.9.4 1.3-.1.4-.4.2-.9-.1-1.3-.4-.3-.7-.3-1.1-.1l-.1.2z"
                                                            />
                                                            <path
                                                                id="Path_18"
                                                                className="swt0"
                                                                d="M63.7 7.7c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5c0 1-.7 1.5-1.5 1.5zm0-2.3c-.5 0-.9.4-.9.9s.4.9.9.9.8-.3.9-.8v-.1c0-.6-.5-.9-.9-.9z"
                                                            />
                                                            <path
                                                                id="Path_19"
                                                                className="swt0"
                                                                d="M63.7 12.5c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5c0 1-.7 1.5-1.5 1.5zm0-2.4c-.5 0-.9.4-.9.9s.4.9.9.9.9-.4.9-.9c-.1-.5-.5-.9-.9-.9z"
                                                            />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </span>
                                        </div>
                                        Go to Workflow Builder
                                    </a>
                                </div>
                            </DropdownLi>
                        )
                    ) : null}
                </DropdownUl>
            </DropdownUlWrapper>
        </InputContainer>
    )
}

function NextStep({ then, setThen, dialog, setDialog, GetDialogs, dialogs, workspacename, dialougeId }: any) {
    return (
        <>
            <UseSelectThen value={then} onChange={setThen} />
            {then === 'jump_to' && (
                <UseComboboxJumpToDropDown
                    GetDialogs={GetDialogs}
                    dialogs={dialogs}
                    workspacename={workspacename}
                    dialougeId={dialougeId}
                    value={dialog}
                    onChange={(value: string) => setDialog(value)}
                />
            )}
        </>
    )
}

function UseComboboxJumpToDropDown({ GetDialogs, dialogs, workspacename, dialougeId, value, onChange }: any) {
    const displayItem = (item: DialogT | null) => (item ? item.title : '')
    dialogs = sortAnArrayByName(dialogs,"title")
    const searchBox: any = useRef(null)
    const [inputValue, setInputValue] = useState<string>('')
    const [inputItems, setInputItems] = useState(dialogs)
    const {
        isOpen,
        selectedItem,
        getToggleButtonProps,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        getItemProps
    } = useCombobox({
        items: inputItems,
        selectedItem: value,
        itemToString: displayItem,
        onSelectedItemChange: ({ selectedItem }) => {
            onChange(selectedItem)
        },
        inputValue,
        onInputValueChange: ({ inputValue }: any) => {
            setInputItems(
                dialogs.filter(({ title: item }: { title: string }) =>
                    item.toLowerCase().startsWith(inputValue.toLowerCase())
                )
            )
            setInputValue(inputValue)
        }
    })
    useEffect(() => {
        if (isOpen && searchBox.current) {
            searchBox.current.focus()
            setInputValue('')
            setInputItems(dialogs)
        }
    }, [isOpen])
    return (
        <InputContainer>
            <div {...getComboboxProps()}>
                {!isOpen ? (
                    <Input {...getToggleButtonProps()} value={selectedItem && displayItem(selectedItem)} />
                ) : (
                    <Input
                        {...getInputProps({
                            ref: searchBox
                        })}
                    />
                )}
                <InputContent>Select dialog or faq</InputContent>
            </div>
            <CancelButton>
                <DropdownWithCircle />
            </CancelButton>
            <DropdownUlWrapper>
                <DropdownUl relative {...getMenuProps()}>
                    {isOpen &&
                       (sortAnArrayByName(inputItems,"title") as DialogT[]).map((item: DialogT, index: number) => {
                            return (
                                <DropdownLi key={`${item.title}${index}`} {...getItemProps({ item, index })}>
                                    {item.title}
                                </DropdownLi>
                            )
                        })}
                </DropdownUl>
            </DropdownUlWrapper>
        </InputContainer>
    )
}
function UseSelectThen({ value, onChange }: { value: string; onChange: (e: any) => void }) {
    const items = ['jump_to', 'exit']
    const { isOpen, selectedItem, getToggleButtonProps, getLabelProps, getMenuProps, getItemProps } = useSelect<string>(
        {
            items,
            selectedItem: value,
            onSelectedItemChange: ({ selectedItem }) => onChange(selectedItem)
        }
    )
    const displayString = (item: string | null) =>
        item === 'jump_to' ? 'Jump to specific dialog or faq' : 'Exit the conversation'

    return (
        <InputContainer>
            <Input type="text" value={displayString(selectedItem)} {...getToggleButtonProps()} />
            <InputContent {...getLabelProps()}>Then</InputContent>
            {/* <CancelButton   {...getToggleButtonProps()}>
                <DropdownWithCircle />
            </CancelButton> */}
            <CancelButton className="d-flex">
                <DropdownWithCircle />
            </CancelButton>
            <DropdownUlWrapper className="test">
                <DropdownUl relative {...getMenuProps()}>
                    {isOpen &&
                        items.map((item, index) => (
                            <DropdownLi key={item} {...getItemProps({ item, index })}>
                                {displayString(item)}
                            </DropdownLi>
                        ))}
                </DropdownUl>
            </DropdownUlWrapper>
        </InputContainer>
    )
}

export default Automation
