import * as ramda from 'ramda'

import {
    ActionInputsListT,
    AppsListT,
    AuthType,
    CollectionOutputListTypeT,
    ConditionInitialState,
    ConditionResponseT,
    DefaultConditionValues,
    InputFieldT,
    InputRulesArray,
    NodeAddT,
    NodeEditT,
    NodeMode,
    NodeModes,
    NodeT,
    NodeView,
    ObjectOutputListTypeT,
    PathIdType,
    PopupError,
    ResponseOutputT,
    RightPanelStateContext,
    RightPanelStateT,
    RulesArray,
    Tree,
    TreeT,
    hasCombinatorT,
    updateNode
} from './types'
import {
    AddIcon,
    CloseIcon,
    ConditionRulesDeleteIcon,
    DownarrowWithCircle,
    FlowCanvasContextIcons,
    InfoIcon,
    QaIconWithCircle
} from '@/common/Icons/Icons'
import {
    Button,
    ButtonContainer,
    Wrapper as ConditionWrapper,
    DropdownWrapper,
    EmptyDiv,
    Header,
    HeaderLeft,
    HeaderRight,
    InputContainer,
    InputContent,
    InputWrapper,
    Input as Inputfield,
    LinkTag,
    Paragraph,
    PopupContainer,
    PopupScrollHeight,
    PopupWrapper
} from '@/common/styled/Workflow.Dumb'
import { ContextPopup, configApiCall } from './ContextPopup'
import { Editor, decorator } from './InputandVariable'
import { EditorState, RawDraftContentBlock, RawDraftEntityRange, convertFromRaw, convertToRaw } from 'draft-js'
import {
    InsertContext,
    converToEditorFormat,
    convertAsaSingleString,
    convertToServerFormat2,
    emptyEditorState,
    isBinaryOperator
} from './utils'
/*eslint-disable*/
import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { automationAPIURLWithWorkspace, getJson } from '@/common/utils/api-utils'
import { convertObjectToArray, getProductId } from '@/common/utils/utils'
import { getOutputValueRecursive, getValuesBetweenBraces } from '@/Flows/_utils'

import { CancelButtonInput } from './ActionPanel'
import { CanvasRightPanel } from './CanvasRightPanel'
import { DraftInputWrapper } from '@/common/styled/DraftJs.Dumb'
import { Error } from '@/common/components/ErrorBoundary'
import { FileDefaultObject } from './NoTriggerPanel'
import { Loader } from '@/common/components/Loader'
import { NoTriggerVariable } from '@/common/Icons/Workflow.Icons'
import { SidePanelContext } from './Canvas'
import { SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import Tooltip from 'react-tooltip-lite'
import { VideoLinkSimple } from '@/Videos/Videos'
import produce from 'immer'
import styled from 'styled-components/macro'

const ConditionPopup = styled.div`
    width: 100%;
    float: left;
    margin-bottom: 10px;

    // p {
    //     display: none;
    // }
    .button_auto_input_click {
        color: #999;
        top: 8px;
        font-size: 10px;
        -webkit-transition: 0.26s ease-out;
        transition: 0.26s ease-out;
        line-height: 10px;
        font-family: 'Gordita-Regular';
        position: absolute;
        pointer-events: none;
        left: 12px;
    }
    .automation_global_form_edit._select_a_variable:focus .button_auto_input {
        top: 8px;
        font-size: 10px;
        transition: 0.26s ease-out;
        line-height: 10px;
    }

    .automation_global_form_edit._select_a_variable {
        width: 100%;
        padding: 20px 12px 1px 12px;
        border: 1px solid #b9b9b9;
        border-radius: 6px;
        background: #fff;
        min-height: 48px;
        font-size: 12px;
        font-family: 'Gordita-Regular';
        color: #000000;
        position: relative;
        margin-right: 0px;

        .button_auto_input {
            color: #999;
            font-size: 12px;
            font-family: 'Gordita-Regular';
            position: absolute;
            pointer-events: none;
            left: 12px;
            top: 16px;
            -webkit-transition: 0.26s ease-in;
            transition: 0.26s ease-in;
        }
    }
`

export type ConditionPanelStateT = {
    show: boolean
    parentNode: TreeT<NodeT>
    mode: NodeModes
    currentNode: ConditionResponseT
}

export type ValueTypeT = { id: string; value: string; type: 'string' | 'context'; valuePath: string[]; icon: string }

export type ContextInputValueTypeT = {
    nodeInput: { index: number; left: ValueTypeT[]; right: string | EditorState; type: string; operatorValue: string }
}

export type ConditionPanelStateContextT = {
    conditionPanelState: ConditionPanelStateT
    setConditionPanelState: (object: any) => void
    setContextPopupValues: (object: any) => void
    contextPopupValues: {
        show: boolean
        inputWithValues: ContextInputValueTypeT[]
        currentInput: { index: number; keyName: string }
    }
}

export const ConditionPanelStateContext = createContext<ConditionPanelStateContextT>({
    conditionPanelState: {
        show: false,
        mode: NodeAddT,
        parentNode: { value: Tree, children: [] },
        currentNode: ConditionInitialState
    },
    setConditionPanelState: (object: any) => undefined,
    setContextPopupValues: (object: any) => undefined,
    contextPopupValues: {
        show: false,
        inputWithValues: [
            { nodeInput: { index: 0, left: [], right: emptyEditorState, type: 'string', operatorValue: 'operator' } }
        ],
        currentInput: { index: 0, keyName: '' }
    }
})

export function buildContextValuesForEdit(leftOrRight: string, combiningResponseWithTriggerOutput: ActionInputsListT[],treeData:{tree:TreeT<NodeT>}) {
    const triggerNodeOutput = treeData.tree.value

    let output = getValuesBetweenBraces(leftOrRight).map(e => {
        // to split context value from input value
        if (e.includes('.output.') || e.includes('.input.')) {
            const contextValue = e.includes('.output.')
                ? e.split('.output.')[1].split('.')
                : e.split('.input.')[1].split('.')
            const nodeId = e.includes('.output.') ? e.split('.output.')[0] : e.split('.input.')[0]

            const filteredValue = (config: ActionInputsListT[]) => {
                const filt = config
                    .map(resp => {
                        if (resp.output.app && resp.output.app.pathId.nodeId == nodeId) {
                            if (resp.output.forEachCollection) {
                                const op = resp.output as unknown as { output:ObjectOutputListTypeT, errors:{[key:string]:{ code:string,message:string }} }
                                const value = getOutputValueRecursive(
                                    op,
                                    contextValue,
                                    Object.values(resp.output.output.properties)[0] as CollectionOutputListTypeT
                                )
                                if (value) return { value: value, app: resp.output.app }
                            } else {
                                const op = (resp.output as any).type?resp.output.output as unknown as { output:ObjectOutputListTypeT, errors:{[key:string]:{ code:string,message:string }} }:resp.output as any

                                const outputHasFile =
                                    resp.output.output.properties &&
                                    convertObjectToArray(resp.output.output.properties).filter(
                                        o => o.value.type == 'file'
                                    ).length > 0

                                const newConfig = outputHasFile
                                    ? {
                                          ...op,
                                          output:{
                                            ...op.output,
                                            properties: {
                                                ...op.output.properties,
                                                [`${contextValue[0]}`]: {
                                                    ...op.output.properties[`${contextValue[0]}`],
                                                    properties: FileDefaultObject(contextValue[0])
                                                }
                                            }
                                        }
                                      }
                                    : op

                                const value = getOutputValueRecursive(newConfig, contextValue)
                                if (value) return { value: value, app: resp.output.app }
                            }
                        }
                        return null
                    })
                    .filter(Boolean)
                return filt[0]
            }

            const finalValue = filteredValue(combiningResponseWithTriggerOutput)
            if (finalValue) {
                const app = finalValue.app as ResponseOutputT & PathIdType
                const errVal = finalValue.value as any

                if(errVal.code){
                    return {
                        id: e,
                        value: errVal.message.replace(/\&nbsp;/g, ' '),
                        type: 'context',
                        valuePath:
                        app
                        ? [app.appName, app.action, errVal.message]
                        : [],
                        icon: app.icon
                    }
                }else{
                    const path = finalValue.value.$id.split('/').filter(x => x.length !== 0 && x !== 'properties')
                    return {
                        id: e,
                        value: finalValue.value.title,
                        type: 'context',
                        valuePath:
                            app.type != 'trigger' || triggerNodeOutput.kind !== 'NoTrigger'
                                ? (app.pathId.approvalId ? ['Approval', 'Output'] : [app.appName, app.action])
                                    .concat(path.filter((v, i) => i + 1 != path.length))
                                    .concat(finalValue.value.title)
                                : ['Triggered by Chatbot', 'Output', finalValue.value.title],
                        icon: app.icon
                    }
            }
        }
    }

        return {
            id: '',
            value: e ? e : '',
            type: 'string',
            valuePath: []
        }
    })

    if (output.length > 0 && output[output.length - 1].type === 'string') {
        output = output.filter((x, i) => i !== output.length - 1)
    }

    return output
}

export function reduceInputs(inputs: ValueTypeT[]): string {
    const reducedString = inputs.reduce((acc, curr) => {
        switch (curr.type) {
            case 'string':
                return acc + (curr.value ? curr.value : '')
            case 'context':
                return acc + '${' + curr.id + '}'
        }
        return acc
    }, '')

    return reducedString
}

export const ConditionPanel = (props: any) => {
    const { rightPanelState } = useContext(SidePanelContext)

    const [conditionPanelState, setConditionPanelState] = useState({
        ...rightPanelState,
        currentNode: rightPanelState.currentNode as ConditionResponseT
    })

    const [contextPopupValues, setContextPopupValues] = useState({
        show: false,
        inputWithValues: [
            { nodeInput: { index: 0, left: [], right: emptyEditorState, type: 'string', operatorValue: 'operator' } }
        ],
        currentInput: { index: 0, keyName: '' }
    })

    return (
        <ConditionPanelStateContext.Provider
            value={{
                conditionPanelState,
                setConditionPanelState,
                contextPopupValues,
                setContextPopupValues
            }}
        >
            <ConditionActualComponent {...props} />
        </ConditionPanelStateContext.Provider>
    )
}

const ConditionActualComponent = (props: any) => {
    // for comparing props
    const ConditionElement = useRef<any>(null)

    const {
        rightPanelState,
        setRightPanelState,
        saveTree,
        treeData,
        setEditTreeData,
        editTreeData,
        hasLinkedDialogs,
        active_automation, 
        automationMeta
    } = useContext(SidePanelContext)

    const { conditionPanelState, setConditionPanelState, contextPopupValues, setContextPopupValues } = useContext(
        ConditionPanelStateContext
    )

    const consPopup = contextPopupValues as any

    const currentNode = conditionPanelState.currentNode as ConditionResponseT

    // console.log("DOING :: currentNode",currentNode)

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const currentMode = rightPanelState.mode

        // compare props and update changes based on following condition
        if (
            !ConditionElement.current ||
            (ConditionElement.current &&
                (rightPanelState.mode.mode !== ConditionElement.current.mode.mode ||
                    (currentMode.mode === NodeMode.Edit &&
                        currentMode.nodeEditInput.id !== ConditionElement.current.mode.nodeEditInput.id)))
        ) {
            if (currentMode.mode === NodeMode.Add || currentMode.mode === NodeMode.Edit) {
                // next time to compare with previous props
                ConditionElement.current = rightPanelState

                //show loader while changing modes (such as edit->add or add->edit)

                // update current action panel context state for further child usage
                setConditionPanelState({
                    ...rightPanelState,
                    currentNode: rightPanelState.currentNode as ConditionResponseT
                })

                const mode = currentMode
                // call initial API everytime mode changed
                callApplistAPI({
                    ...rightPanelState,
                    currentNode:
                        mode.mode == NodeMode.Edit
                            ? ({
                                  ...rightPanelState.currentNode,
                                  subflowMetaData:
                                      mode.nodeEditInput.meta_data && mode.nodeEditInput.meta_data.subflow
                                          ? { subflow: mode.nodeEditInput.meta_data.subflow }
                                          : { subflow: { hasSubFlow: false } }
                              } as ConditionResponseT)
                            : (rightPanelState.currentNode as ConditionResponseT)
                })
            } else {
                ConditionElement.current = rightPanelState
                currentMode.mode === NodeMode.Loading ? setLoading(true) : setLoading(false)

                setConditionPanelState({
                    ...rightPanelState,
                    currentNode: rightPanelState.currentNode as ConditionResponseT
                })
            }
        } else if (
            rightPanelState.mode.mode === NodeMode.Edit &&
            ConditionElement.current.mode.mode === NodeMode.Edit &&
            rightPanelState.mode.nodeEditInput.id === ConditionElement.current.mode.nodeEditInput.id
        ) {
            setConditionPanelState({
                ...conditionPanelState,
                show: true
            })
        }
    }, [rightPanelState.mode])

    // to change ConditionElement.current values while updating state in each step
    useEffect(() => {
        ConditionElement.current = conditionPanelState

        // to see the details in left side panel while adding or editing
        setRightPanelState({ ...rightPanelState, currentNode: conditionPanelState.currentNode })
    }, [conditionPanelState.currentNode])

    async function callApplistAPI(state: ConditionPanelStateT) {
        const workspaceName = props.match.params.workspacename

        await getJson(automationAPIURLWithWorkspace(workspaceName, '/actions'))
            .then((data: AppsListT) => {
                const currentNode = state.currentNode as ConditionResponseT

                const nextState = produce(currentNode, draftState => {
                    draftState.appsList = data
                })

                setConditionPanelState({
                    ...state,
                    currentNode: nextState
                })

                if (state.mode.mode === NodeMode.Edit) {
                    const triggerNodeOutput = treeData.tree.value

                    const parentNode =
                        triggerNodeOutput.kind === 'AppTrigger' || triggerNodeOutput.kind === 'EventTrigger'
                            ? ({
                                  ...state.parentNode,
                                  value: {
                                      ...state.parentNode.value,
                                      path: state.parentNode.value.path
                                          ? [
                                                {
                                                    actionId: triggerNodeOutput.meta_data.action_id,
                                                    nodeId: triggerNodeOutput.id
                                                }
                                            ].concat(state.parentNode.value.path)
                                          : [
                                                {
                                                    actionId: triggerNodeOutput.meta_data.action_id,
                                                    nodeId: triggerNodeOutput.id
                                                }
                                            ]
                                  }
                              } as TreeT<NodeT>)
                            : state.parentNode

                    configApiCall(data, parentNode, false, workspaceName, currentNode.addingInsideForEach).then(
                        configResponse => {
                            callEditFunction(
                                {
                                    ...state,
                                    currentNode: nextState,
                                    approvalAccess: rightPanelState.approvalAccess,
                                    multiActionAccess: rightPanelState.multiActionAccess,
                                    currentTrigger:rightPanelState.currentTrigger,
                                },
                                configResponse
                            )
                        }
                    )
                } else {
                    setLoading(false)
                }
            })
            .catch(err => {
                setLoading(false)

                setConditionPanelState({
                    ...state,
                    mode: {
                        ...PopupError,
                        error: 'Something went wrong!<br/>Please try again after sometime.'
                    }
                })
            })
    }

    function callEditFunction(rightPanelState: RightPanelStateT, configResponse: ActionInputsListT[]) {
        const mode = rightPanelState.mode as NodeEditT

        const triggerNodeOutput = treeData.tree.value
        let combiningResponseWithTriggerOutput: any = []

        if (triggerNodeOutput.kind !== 'AppTrigger' && triggerNodeOutput.kind !== 'EventTrigger') {
            const triggerOutputResponse: ActionInputsListT[] = [
                {
                    type: 'success',
                    output: {
                        name: triggerNodeOutput.name,
                        input: [],
                        app: {
                            appName: triggerNodeOutput.name,
                            action: triggerNodeOutput.kind,
                            pathId: {
                                nodeId: triggerNodeOutput.id,
                                actionId: ''
                            },
                            appId: '',
                            createdDate: '',
                            file: '',
                            type: 'trigger',
                            authentication_type: AuthType.None,
                            id: '',
                            scope: '',
                            icon: NoTriggerVariable,
                            latest: false,
                            version: ''
                        },
                        output: triggerNodeOutput.output
                    }
                }
            ]

            combiningResponseWithTriggerOutput = triggerOutputResponse.concat(configResponse)
        } else {
            combiningResponseWithTriggerOutput = configResponse
        }

        const currentNode = rightPanelState.currentNode as ConditionResponseT

        const input = mode.nodeEditInput.input as (InputRulesArray | hasCombinatorT)[]
        const operatorRules = input.filter(x => x.type === 'operator') as RulesArray[]
        const combinators = input.filter(x => x.type !== 'operator')

        const currentNodeParent = rightPanelState.parentNode

        const isErrorHandlingCondition =
            rightPanelState.mode.mode == NodeMode.Edit
                ? currentNodeParent.value.kind == 'ErrorHandlingCondition'
                : false

        const selectedApplication = currentNode.appsList.output.filter(
            app =>
                isErrorHandlingCondition &&
                (app.type == 'action'||app.type == 'event_trigger' || app.type == 'trigger') &&
                app.id == currentNodeParent.value.meta_data.action_id
        )[0]

        const errorHandlingNodeValue = isErrorHandlingCondition
            ? {
                  id: '',
                  value: 'Success',
                  type: 'context',
                  valuePath: [selectedApplication.appName.toString(), selectedApplication.action.toString(), 'Success'],
                  icon: selectedApplication.icon
              }
            : {}

        const inputWithValues = operatorRules
            .map((x, index) => {
                if (x.valType && x.valType.length > 0) {
                    const val = x as RulesArray
                    const operatorValue = DefaultConditionValues[val.valType].values.filter(
                        x => x.value === val.operator
                    )
                    const isCurrentOperatorBinary = isBinaryOperator(operatorValue[0].name ? operatorValue[0].name : '')


                    return {
                        nodeInput: {
                            index: index,
                            type: val.valType,
                            left: isErrorHandlingCondition
                                ? [{ ...errorHandlingNodeValue, id: val.left }]
                                : buildContextValuesForEdit(val.left, combiningResponseWithTriggerOutput,treeData),
                            operatorValue: operatorValue[0].name,
                            right:
                                (val.valType !== 'boolean' && isCurrentOperatorBinary)
                                    ? (converToEditorFormat(
                                          val.right as string,
                                          combiningResponseWithTriggerOutput,
                                          triggerNodeOutput,
                                          {
                                              config: { kind: 'text' },
                                              description: '',
                                              displayTitle: '',
                                              key: '',
                                              required: false,
                                              value: val.right
                                          },
                                          { inputs: [], contextPopupValues: consPopup, setContextPopupValues }
                                      ) as EditorState)
                                    : emptyEditorState
                        }
                    }
                }
                return undefined
            })
            .filter(Boolean)

        const rules = operatorRules.map((y: RulesArray, i) => {
            const right = y.right as string
            const splittedValue = getValuesBetweenBraces(right)
            const lastInputValue = splittedValue[splittedValue.length - 1]

            // if index is odd add combinator
            if (i !== 0) {
                const x1 = {
                    ...y,
                    hasCombinator: combinators[i - 1],
                    right:
                        right.length > 0
                            ? (converToEditorFormat(
                                  right as string,
                                  configResponse,
                                  triggerNodeOutput,
                                  {
                                      config: { kind: 'text' },
                                      description: '',
                                      displayTitle: '',
                                      key: '',
                                      required: false,
                                      value: right
                                  },
                                  { inputs: [], contextPopupValues: consPopup, setContextPopupValues }
                              ) as EditorState)
                            : emptyEditorState,
                    rightHasValue: right.length > 0 && true
                }

                return x1
            }

            const x3 = {
                ...y,
                hasCombinator: null,
                right:
                    right.length > 0
                        ? (converToEditorFormat(
                              right as string,
                              configResponse,
                              triggerNodeOutput,
                              {
                                  config: { kind: 'text' },
                                  description: '',
                                  displayTitle: '',
                                  key: '',
                                  required: false,
                                  value: right
                              },
                              { inputs: [], contextPopupValues: consPopup, setContextPopupValues }
                          ) as EditorState)
                        : emptyEditorState,
                rightHasValue: right.length > 0 && true
            }

            return x3
        }) as RulesArray[]

        const popupValues = produce(currentNode, draftState => {
            draftState.conditionName = mode.nodeEditInput.name
            draftState.description = mode.nodeEditInput.description
            draftState.rules = rules
        })

        setConditionPanelState({ ...rightPanelState, currentNode: popupValues })
        Promise.resolve('delay effect change due to overlapping state update calls').then(() => {
            setContextPopupValues({ ...contextPopupValues, inputWithValues: inputWithValues })
        })

        setLoading(false)
    }

    // disable or enable save button
    function enableSave() {
        const hasInputValue =
            contextPopupValues.inputWithValues.length === currentNode.rules.length
                ? contextPopupValues.inputWithValues.filter((x, i) => {
                      const rightValue = currentNode.rules[i].right
                      const nodeRightInput = x.nodeInput.right
                      return (
                          x.nodeInput.left.length > 0 &&
                          ((x.nodeInput.type === 'boolean' && currentNode.rules[i].operator !== 'Select') ||
                           ((!isBinaryOperator(x.nodeInput.operatorValue) && currentNode.rules[i].operator !== 'Select')) ||
                              (typeof nodeRightInput == 'string'
                                  ? nodeRightInput.length > 0 && nodeRightInput.replace(/\s/g, '').length
                                  : convertAsaSingleString(
                                        convertToRaw(nodeRightInput.getCurrentContent()).blocks
                                    ).replace(/\s/g, '').length) ||
                              (typeof rightValue == 'string'
                                  ? rightValue.length > 0 && rightValue.replace(/\s/g, '').length
                                  : convertAsaSingleString(convertToRaw(rightValue.getCurrentContent()).blocks).replace(
                                        /\s/g,
                                        ''
                                    ).length))
                      )
                  })
                : []

        return (
            hasInputValue.length > 0 &&
            hasInputValue.length === contextPopupValues.inputWithValues.length &&
            currentNode.conditionName.length > 0
        )
    }

    function isInputhaveDifferentValues(){
        if(conditionPanelState.mode.mode===NodeMode.Edit){
            const mode = conditionPanelState.mode as NodeEditT

            const hasInputValue =
            currentNode.rules.length === mode.nodeEditInput.input.length
                    ? currentNode.rules.map((x, i) => {
                        const nodeRightInput = x.right

                        return (
                            x.left == (mode.nodeEditInput.input[i] as InputRulesArray).left &&
                            (x.operator == (mode.nodeEditInput.input[i] as InputRulesArray).operator) &&
                                (typeof nodeRightInput == 'string'
                                    ? nodeRightInput != (mode.nodeEditInput.input[i] as InputRulesArray).right
                                    : convertAsaSingleString(
                                            convertToRaw(nodeRightInput.getCurrentContent()).blocks
                                        ) == (mode.nodeEditInput.input[i] as InputRulesArray).right) 
                        )
                    })
                    : [false]
            return hasInputValue.some((s)=>!s)
        }else{
            return true
        }
    }

    function enableAddmore() {
        const hasInputValue =
            contextPopupValues.inputWithValues.length === currentNode.rules.length
                ? contextPopupValues.inputWithValues.filter((x, i) => {
                      const rightValue = currentNode.rules[i].right
                      const nodeRightInput = x.nodeInput.right

                      return (
                          x.nodeInput.left.length > 0 &&
                          ((x.nodeInput.type === 'boolean' && currentNode.rules[i].operator !== 'Select') ||
                          ((!isBinaryOperator(x.nodeInput.operatorValue) && currentNode.rules[i].operator !== 'Select')) ||
                              (typeof nodeRightInput == 'string'
                                  ? nodeRightInput.length > 0 && nodeRightInput.replace(/\s/g, '').length
                                  : convertAsaSingleString(
                                        convertToRaw(nodeRightInput.getCurrentContent()).blocks
                                    ).replace(/\s/g, '').length) ||
                              (typeof rightValue == 'string'
                                  ? rightValue.length > 0 && rightValue.replace(/\s/g, '').length
                                  : convertAsaSingleString(convertToRaw(rightValue.getCurrentContent()).blocks).replace(
                                        /\s/g,
                                        ''
                                    ).length))
                      )
                  })
                : []

        return hasInputValue.length > 0 && hasInputValue.length === contextPopupValues.inputWithValues.length
    }

    function seperateOptionalFields(array: InputFieldT[], required: boolean) {
        return array.filter((input: InputFieldT) => {
            return required ? input.required : !input.required
        })
    }

    // const replaceBetween = (str: string, start: number, end: number, what: string, isLast: boolean) => {
    //     return isLast ? str.substring(0, start) + what : str.substring(0, start) + what + str.substring(end)
    // }

    // const replaceBetween = (str: string, start: number, end: number, what: string, isLast: boolean) => {
    //     const replaced = isLast
    //         ? str.substring(0, start) + what + str.substring(end)
    //         : str.substring(0, start) + what + str.substring(end)
    //     // console.log('replace between', str, start, end, isLast, str.substring(0, start), 'what', str.substring(end))
    //     // console.log('replaced', replaced)
    //     return replaced
    // }

    const replaceBetween2 = (str: string, start: number, end: number, what: string, isLast: boolean) => {
        const replaced = isLast
            ? str.substring(0, start) + what + str.substring(end)
            : str.substring(0, start) + what + str.substring(end)
        // console.log('replace between', str, start, end, isLast, str.substring(0, start), 'what', str.substring(end))
        // console.log('replaced', replaced)
        return replaced
    }

    async function updateVariableFromInput(text: any, old: any, cb: Function) {
        const leftValue: any = contextPopupValues.inputWithValues
            .map((l, i) => {
                console.log(old, l.nodeInput.left)
                return l.nodeInput.left[0].value == old.title ? i : null
            })
            .filter(v => v != null)

        const rightValue = contextPopupValues.inputWithValues
            .map((r, i) => {
                const rightHasValue = r.nodeInput.right as EditorState
                return convertAsaSingleString(convertToRaw(rightHasValue.getCurrentContent()).blocks).includes(
                    old.title
                )
                    ? i
                    : null
            })
            .filter(v => v != null)

        if (leftValue.length > 0 || rightValue.length > 0) {
            await cb('edit')
            const newValue = produce(contextPopupValues, draft => {
                draft.inputWithValues = draft.inputWithValues.map((inp, index) => {
                    const left = inp.nodeInput.left.map(l => {
                        l.valuePath[l.valuePath.length - 1] = text.title
                        return {
                            ...l,
                            value: text.title
                        }
                    })

                    const right = inp.nodeInput.right as EditorState
                    const inpValue = convertToRaw(right.getCurrentContent())
                    let omittedMap = {}

                    const newMap = Object.entries(inpValue.entityMap).reduce((acc: any, curr: any) => {
                        // console.log(curr, 'curr')
                        if ((curr[1].data.variable ? curr[1].data.variable : curr[1].data.value) != old.title) {
                            const { [`${curr[0]}`]: omit, ...rest } = acc
                            omittedMap[`${curr[0]}`] = omit
                            return rest
                        }
                        curr[1].data.path[curr[1].data.path.length - 1] = text.title

                        acc[`${curr[0]}`] = curr[1].data.variable
                            ? {
                                  ...curr[1],
                                  data: { ...curr[1].data, variable: text.title }
                              }
                            : {
                                  ...curr[1],
                                  data: { ...curr[1].data, value: text.title, visible: text.title }
                              }
                        return acc
                    }, inpValue.entityMap)

                    const minus = text.title.length < old.title.length

                    const newLength = minus
                        ? old.title.length - text.title.length
                        : text.title.length - old.title.length

                    let itemsProcessed = 0
                    const newBlocks = inpValue.blocks.map(b => {
                        const filtered = b.entityRanges.filter(e => {
                            return Object.keys(newMap).indexOf(e.key.toString()) != -1
                        })
                        return {
                            ...b,
                            text: filtered.reduce((acc, curr, i) => {
                                if (i == 0) {
                                    const start = curr.offset - (old.title.length - text.title.length) * i
                                    return replaceBetween2(
                                        acc,
                                        curr.offset,
                                        start + old.title.length,
                                        text.title,
                                        filtered.length == i + 1
                                    )
                                } else {
                                    const start = curr.offset - (old.title.length - text.title.length) * i
                                    return replaceBetween2(
                                        acc,
                                        start,
                                        start + old.title.length,
                                        text.title,
                                        filtered.length == i + 1
                                    )
                                }
                            }, b.text),
                            entityRanges: b.entityRanges
                                .reduce((acc: any, curr: any, i: number) => {
                                    if (
                                        Object.keys(newMap)
                                            .map(x => parseInt(x))
                                            .some(x => curr.key >= x)
                                    ) {
                                        if (Object.keys(newMap).indexOf(curr.key.toString()) != -1) {
                                            const newOffsetDifference =
                                                itemsProcessed *
                                                (Object.keys(newMap).length > 0
                                                    ? old.title.length - text.title.length
                                                    : 0)
                                            itemsProcessed = itemsProcessed + 1
                                            return acc.concat({
                                                ...curr,
                                                offset: i == 0 ? curr.offset : curr.offset - newOffsetDifference,
                                                length: curr.length - (old.title.length - text.title.length)
                                            })
                                        } else {
                                            const newOffsetDifference =
                                                itemsProcessed *
                                                (Object.keys(newMap).length > 0
                                                    ? old.title.length - text.title.length
                                                    : 0)
                                            return acc.concat({
                                                ...curr,
                                                offset: i == 0 ? curr.offset : curr.offset - newOffsetDifference,
                                                length: curr.length
                                            })
                                        }
                                    }

                                    return acc.concat(curr)
                                }, [])
                                .filter((x: any) => x.length > 0)
                        }
                    })

                    return {
                        ...inp,
                        nodeInput: {
                            ...inp.nodeInput,
                            left: leftValue.indexOf(index) != -1 ? left : inp.nodeInput.left,
                            operatorValue:
                                leftValue.indexOf(index) != -1 && old.type != text.type
                                    ? ''
                                    : inp.nodeInput.operatorValue,
                            right:
                                rightValue.indexOf(index) != -1
                                    ? left.length == 0 || old.type != text.type
                                        ? emptyEditorState
                                        : EditorState.createWithContent(
                                              convertFromRaw({
                                                  blocks: newBlocks,
                                                  entityMap: { ...omittedMap, ...newMap }
                                              }),
                                              decorator
                                          )
                                    : inp.nodeInput.right
                        }
                    }
                })
            })
            setContextPopupValues(newValue)
        } else {
            await cb('no-edit')
        }
    }

    async function removeVariableFromInput(text: any, cb: Function) {
        // const leftValue = contextPopupValues.inputWithValues[contextPopupValues.currentInput.index].nodeInput.left.find(
        //     l => l.value == text.title
        // )
        // const rightHasValue = contextPopupValues.inputWithValues[contextPopupValues.currentInput.index].nodeInput
        //     .right as EditorState
        // const rightValue = convertAsaSingleString(convertToRaw(rightHasValue.getCurrentContent()).blocks).includes(
        //     text.title
        // )

        const leftValue: any = contextPopupValues.inputWithValues
            .map((l, i) => {
                return l.nodeInput.left[0].value == text.title ? i : null
            })
            .filter(v => v != null)

        const rightValue = contextPopupValues.inputWithValues
            .map((r, i) => {
                const rightHasValue = r.nodeInput.right as EditorState
                return convertAsaSingleString(convertToRaw(rightHasValue.getCurrentContent()).blocks).includes(
                    text.title
                )
                    ? i
                    : null
            })
            .filter(v => v != null)

        if (leftValue.length > 0 || rightValue.length > 0) {
            await cb('delete')
            const newValue = produce(contextPopupValues, draft => {
                draft.inputWithValues = draft.inputWithValues.map((inp, index) => {
                    const left = inp.nodeInput.left.filter(l => l.value != text.title)
                    const right = inp.nodeInput.right as EditorState
                    const inpValue = convertToRaw(right.getCurrentContent())

                    const newMap = Object.entries(inpValue.entityMap).reduce((acc: any, curr: any) => {
                        if ((curr[1].data.variable ? curr[1].data.variable : curr[1].data.value) == text.title) {
                            const { [`${curr[0]}`]: omit, ...rest } = acc
                            return rest
                        }
                        return acc
                    }, inpValue.entityMap)

                    const newBlocks = inpValue.blocks.map(b => {
                        const filtered = b.entityRanges.filter(e => {
                            return Object.keys(newMap).indexOf(e.key.toString()) == -1
                        })
                        return {
                            ...b,
                            text: b.entityRanges
                                .filter(e => {
                                    return Object.keys(newMap).indexOf(e.key.toString()) == -1
                                })
                                .reduce((acc, curr, i) => {
                                    if (i == 0) {
                                        return replaceBetween2(
                                            acc,
                                            curr.offset,
                                            curr.offset + text.title.length,
                                            '',
                                            curr.offset + text.title.length >= acc
                                        )
                                    } else {
                                        const start = curr.offset - text.title.length * i
                                        return replaceBetween2(
                                            acc,
                                            start,
                                            start + text.title.length,
                                            '',
                                            filtered.length == i + 1
                                        )
                                    }
                                }, b.text),
                            entityRanges: b.entityRanges
                                .reduce((acc, current, i: number) => {
                                    if (Object.keys(newMap).indexOf(current.key.toString()) != -1) {
                                        const newOffsetDifference =
                                            acc.filter(x => x.length === 0).length *
                                            (Object.keys(newMap).length > 0 ? text.title.length : 0)
                                        // console.log('newOffsetDifference', newOffsetDifference, newMap.length, newMap)
                                        return [
                                            ...acc,
                                            {
                                                ...current,
                                                offset: i == 0 ? current.offset : current.offset - newOffsetDifference
                                            }
                                        ]
                                    } else {
                                        return [
                                            ...acc,
                                            {
                                                ...current,
                                                // offset: i == 0 ? current.offset : acc[i - 1].offset,
                                                length: 0
                                            }
                                        ]
                                    }
                                    return [...acc, current]
                                }, [] as RawDraftEntityRange[])

                                .filter(e => e.length !== 0)
                        }
                    })
                    return {
                        ...inp,
                        nodeInput: {
                            ...inp.nodeInput,
                            left: leftValue.indexOf(index) != -1 ? left : inp.nodeInput.left,
                            operatorValue: left.length == 0 ? '' : inp.nodeInput.operatorValue,
                            right:
                                rightValue.indexOf(index) != -1 || left.length == 0
                                    ? left.length == 0
                                        ? emptyEditorState
                                        : EditorState.createWithContent(
                                              convertFromRaw({
                                                  blocks: newBlocks,
                                                  entityMap: newMap
                                              }),
                                              decorator
                                          )
                                    : inp.nodeInput.right
                        }
                    }
                })
            })
            setContextPopupValues(newValue)
        } else {
            await cb('delete')
        }
    }

    function updateInputValuesWithContext(props: {
        path: string
        value: string
        valuePath: string[]
        type: string
        icon: string
        examples: string
    }) {
        const { path, value, valuePath, type, icon, examples } = props
        const currentIndex = contextPopupValues.currentInput.index

        if (contextPopupValues.inputWithValues.length === 0 || !contextPopupValues.inputWithValues[currentIndex]) {
            const nextInputValues = produce(contextPopupValues, draftState => {
                draftState.inputWithValues.push({
                    nodeInput: {
                        index: currentIndex,
                        type: type,
                        left: [
                            {
                                id: path,
                                value: value,
                                type: 'context',
                                valuePath: valuePath,
                                icon: icon
                            }
                        ],
                        operatorValue: '',
                        right: emptyEditorState
                    }
                })

                draftState.show = false
            })

            setContextPopupValues(nextInputValues)

            const nextValue = produce(currentNode, draftState => {
                draftState.rules[currentIndex].left = path
                draftState.rules[currentIndex].valType = type
            })

            setConditionPanelState({
                ...conditionPanelState,
                currentNode: nextValue
            })
        } else {
            let nextInputValues

            if (contextPopupValues.currentInput.keyName === 'left') {
                nextInputValues = produce(contextPopupValues, draftState => {
                    const nodeInput = draftState.inputWithValues[draftState.currentInput.index].nodeInput
                    draftState.inputWithValues[draftState.currentInput.index].nodeInput = {
                        ...nodeInput,
                        type: type,
                        left: [
                            {
                                id: path,
                                value: value,
                                type: 'context',
                                valuePath: valuePath,
                                icon: icon
                            }
                        ],
                        operatorValue: '',
                        right: emptyEditorState
                    }
                    draftState.show = false
                })

                const newNode = produce(currentNode, draftState => {
                    draftState.rules[currentIndex].operator = 'Select'
                    draftState.rules[currentIndex].right = emptyEditorState
                    draftState.rules[currentIndex].rightHasValue = false
                })

                setConditionPanelState({ ...conditionPanelState, currentNode: newNode })
            } else {
                const textInputvalue = contextPopupValues.inputWithValues[currentIndex].nodeInput.right

                nextInputValues = produce(contextPopupValues, draftState => {
                    const nodeInput = draftState.inputWithValues[draftState.currentInput.index].nodeInput

                    draftState.inputWithValues[draftState.currentInput.index].nodeInput = {
                        ...nodeInput,
                        right: InsertContext(
                            value,
                            {
                                dataType: 'string',
                                path: valuePath,
                                icon: icon,
                                variable: value,
                                id: path,
                                examples
                            },
                            textInputvalue as any
                        ) as any
                    }
                })

                const currentEditedNode = (typeof textInputvalue == 'string'
                  ? textInputvalue.length > 0
                  : convertAsaSingleString(convertToRaw(textInputvalue.getCurrentContent()).blocks).replace(/\s/g, '')
                        .length)
                    ? produce(currentNode, draftState => {
                          draftState.rules[currentIndex].right = emptyEditorState
                          draftState.rules[currentIndex].rightHasValue = true
                      })
                    : produce(currentNode, draftState => {
                          draftState.rules[currentIndex].rightHasValue = true
                      })

                setConditionPanelState({ ...conditionPanelState, currentNode: currentEditedNode })
            }
            console.log(nextInputValues, 'nextInputValues')
            setContextPopupValues(nextInputValues)
        }
    }

    function saveNode() {


        const properInputToSave = currentNode.rules.map((y, index) => {
            const filteredMacthedInput = contextPopupValues.inputWithValues.filter((x, i) => i === index)

            if (filteredMacthedInput.length > 0) {
                const convertedBlocks = filteredMacthedInput[0].nodeInput.right
                return {
                    ...y,
                    valType: filteredMacthedInput[0].nodeInput.type,
                    left: `${reduceInputs(filteredMacthedInput[0].nodeInput.left)}`,
                    right:
                        typeof convertedBlocks == 'string'
                            ? convertedBlocks
                            : convertToRaw(convertedBlocks.getCurrentContent())
                                  .blocks.map(b =>
                                      convertToServerFormat2(
                                          convertToRaw(convertedBlocks.getCurrentContent()).entityMap,
                                          b
                                      )
                                  )
                                  .reduce((acc, ele, i) => (i == 0 ? (acc += ele) : (acc += '\n' + ele)), '')
                }
            }

            return y
        })

        const flattenedProperInputToSave = ramda.flatten(
            properInputToSave.map(z => {
                if (z.hasCombinator) {
                    const combinator = z.hasCombinator
                    const { hasCombinator, rightHasValue, ...rest } = z
                    return [combinator, rest]
                } else {
                    const { hasCombinator, rightHasValue, ...rest } = z
                    return rest
                }
            })
        ) as (InputRulesArray | hasCombinatorT)[]

        const updatedNodeValue = produce(currentNode, draftState => {
            draftState.input = flattenedProperInputToSave
        })

        saveTree(updatedNodeValue)
    }

    function reduceFormInputs(inputs: ContextInputValueTypeT, key: string) {
        return inputs.nodeInput[key].reduce((acc: any, curr: any) => {
            return acc + (curr.value ? `${curr.value}` : '')
        }, '')
    }

    useEffect(() => {
        if (currentNode.rules.length === contextPopupValues.inputWithValues.length) {
            const description = contextPopupValues.inputWithValues
                .filter((x, i) => x.nodeInput.left.length > 0)
                .map((s, i) => {
                    const currentRightInputValue = ` ${convertAsaSingleString(
                        convertToRaw((s.nodeInput.right as EditorState).getCurrentContent()).blocks
                    )}`
                    const combinator = i > 0 ? (currentNode.rules[i].hasCombinator as any) : ''
                    return (
                        `${i == 0 ? '' : ' ' + combinator.combinator + ' '}${reduceFormInputs(s, 'left')}` +
                        ` ${contextPopupValues.inputWithValues[i].nodeInput.operatorValue}` +
                        currentRightInputValue
                    )
                })

            const nextState = produce(currentNode, draftState => {
                draftState.description = description.join('')
            })

            setConditionPanelState({
                ...conditionPanelState,
                currentNode: nextState
            })
        }
    }, [contextPopupValues.inputWithValues, currentNode.rules])

    const SidePanelContextValue = useContext(SidePanelContext)

    // to show loading whenever mode changed
    if (loading) {
        return (
            <div className="automation_canvas_right">
                <Wrapper>
                    <div className="popup_inside_loader">
                        <Loader.PopupLoader show={true} />
                    </div>
                </Wrapper>
            </div>
        )
    } else if (conditionPanelState.mode.mode === NodeMode.Error) {
        return (
            <div className="automation_canvas_right">
                <Wrapper>
                    <div className="flow_canvas_popup_error">
                        <Error.AddorEdit
                            info={conditionPanelState.mode.error}
                            onClick={() => {
                                cancelCurrentPopup({
                                    conditionPanelState,
                                    SidePanelContext: SidePanelContextValue
                                })
                            }}
                        />
                    </div>
                </Wrapper>
            </div>
        )
    }

    const isAssitant = getProductId() == 'ASSISTANT'

    const isErrorHandlingCondition =
        rightPanelState.mode.mode == NodeMode.Edit
            ? (isAssitant ? automationMeta.type== 'no_trigger'? false : active_automation:active_automation) ||
              rightPanelState.parentNode.value.kind == 'ErrorHandlingCondition'
            : false
    

    return (
        <React.Fragment>
            <ConditionWrapper>
                <PopupWrapper>
                    <PopupContainer>
                        <ConditionPanel.Header />
                        <PopupScrollHeight>
                            <EmptyDiv>
                                <div className="automation_canvas_right">
                                    <div className="automation_canvas_right_popup automation_condition_popup">
                                        <div className="automation_canvas_right_popup_form">
                                            <div className="automation_rightside_form automation_rightside_step_two edit_action_height">
                                                <div className="edit_action_height_condition">
                                                    <ConditionPopup>
                                                        <InputWrapper className="connection_new_autoamtion connection_new_condition ">
                                                            <Input
                                                                showHeader
                                                                disabled={isErrorHandlingCondition || (isAssitant? automationMeta.type== 'no_trigger'?hasLinkedDialogs:active_automation:active_automation)}
                                                                onChange={val => {
                                                                    const nextState = produce(
                                                                        conditionPanelState.currentNode,
                                                                        draftState => {
                                                                            draftState.conditionName = val
                                                                        }
                                                                    )
                                                                    setConditionPanelState({
                                                                        ...conditionPanelState,
                                                                        currentNode: nextState
                                                                    })
                                                                }}
                                                                value={conditionPanelState.currentNode.conditionName}
                                                                onFocus={val => {}}
                                                                isErrorHandlingCondition={isErrorHandlingCondition}
                                                                input={
                                                                    {
                                                                        config: { kind: 'text' },
                                                                        value: '',
                                                                        displayTitle: 'Condition name',
                                                                        // description:
                                                                        //     'Provide a name to identify your condition',
                                                                        key: 'condition_name_key',
                                                                        required: true
                                                                    } as InputFieldT
                                                                }
                                                                render={() => {}}
                                                            />
                                                        </InputWrapper>
                                                    </ConditionPopup>
                                                    <ConditionPopup>
                                                        {currentNode.rules.map((rule, i) => (
                                                            <ConditionPanel.Rules
                                                                key={i}
                                                                index={i}
                                                                rule={rule}
                                                                showAndOr={false}
                                                                isErrorHandlingCondition={isErrorHandlingCondition}
                                                            />
                                                        ))}
                                                    </ConditionPopup>

                                                    {!isErrorHandlingCondition && (
                                                        <SmallButton
                                                            primary
                                                            style={{ margin: '2px 0px 20px 0px' }}
                                                            onClick={() => {
                                                                const newRule = produce(currentNode, draftState => {
                                                                    draftState.rules.push({
                                                                        left: '',
                                                                        operator: 'Select',
                                                                        right: emptyEditorState,
                                                                        valType: '',
                                                                        type: 'operator',
                                                                        hasCombinator: {
                                                                            combinator: 'and',
                                                                            type: 'combinator'
                                                                        },
                                                                        rightHasValue: false
                                                                    })
                                                                })

                                                                setConditionPanelState({
                                                                    ...conditionPanelState,
                                                                    currentNode: newRule
                                                                })
                                                            }}
                                                            className={enableAddmore() && (isAssitant ? automationMeta.type =='no_trigger' ?true : !active_automation : !active_automation) ? '' : 'editor_btn_disabled'}
                                                        >
                                                            <span>
                                                                <AddIcon />
                                                            </span>
                                                            <label>Add More</label>
                                                        </SmallButton>
                                                    )}

                                                    <div className="automation_global_form">
                                                        <div className="select_work_form">
                                                            <InputWrapper>
                                                                <h4>Condition summary</h4>
                                                                <InputContainer className={(isAssitant ? automationMeta.type =='no_trigger' ?!hasLinkedDialogs : !active_automation : !active_automation)?"":"editor_btn_disabled"}>
                                                                    <Inputfield
                                                                        type="text"
                                                                        className={
                                                                            isErrorHandlingCondition
                                                                                ? 'disabled_with_blue'
                                                                                : ''
                                                                        }
                                                                        value={currentNode.description}
                                                                        onChange={(e: any) => {
                                                                            const nextState = produce(
                                                                                currentNode,
                                                                                draftState => {
                                                                                    draftState.description =
                                                                                        e.target.value
                                                                                }
                                                                            )

                                                                            setConditionPanelState({
                                                                                ...conditionPanelState,
                                                                                currentNode: nextState
                                                                            })
                                                                        }}
                                                                    />
                                                                        {  getProductId() === "ASSISTANT" ? 
                                                                    
                                                                            <InputContent>
                                                                                Description{' '}
                                                                                <Tooltip
                                                                                    className="target customTip _description"
                                                                                    zIndex={10000}
                                                                                    tagName="span"
                                                                                    content="This description will be displayed on your condition info icon in the canvas page."
                                                                                    distance={15}
                                                                                    forceDirection={true}
                                                                                    direction="up-start"
                                                                                >
                                                                                    <InfoIcon />
                                                                                </Tooltip>
                                                                            </InputContent>
                                                                            : 
                                                                            <InputContent>
                                                                                Description{' '}
                                                                                <Tooltip
                                                                                    className="target customTip _description"
                                                                                    zIndex={10000}
                                                                                    tagName="span"
                                                                                    content="This description will be displayed as information for the condition on the canvas page."
                                                                                    distance={15}
                                                                                    forceDirection={true}
                                                                                    direction="up-start"
                                                                                >
                                                                                    <InfoIcon />
                                                                                </Tooltip>
                                                                            </InputContent>
                                                                        }
                                                                    
                                                                </InputContainer>
                                                            </InputWrapper>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {contextPopupValues.show && (
                                    <ContextPopup
                                        {...props}
                                        mode={rightPanelState.mode}
                                        tree={treeData.tree}
                                        parentNode={rightPanelState.parentNode}
                                        actionAppsList={currentNode.appsList}
                                        triggerNode={treeData.tree.value}
                                        updateInputValuesWithContext={updateInputValuesWithContext}
                                        addingFlowInsideForEach={currentNode.addingInsideForEach}
                                        removeVariableFromInput={(text: any, cb: Function) =>
                                            removeVariableFromInput(text, cb)
                                        }
                                        updateVariableFromInput={(newText: any, oldText: any, cb: Function) =>
                                            updateVariableFromInput(newText, oldText, cb)
                                        }
                                        closeContextPopup={() =>
                                            setContextPopupValues({
                                                ...contextPopupValues,
                                                show: false,
                                                currentInput: ''
                                            })
                                        }
                                    />
                                )}
                            </EmptyDiv>
                            <ButtonContainer className="footer_button_fixed">
                                <div className="popup_footer_button_container">
                                    <Button
                                        primary
                                        className="automation_condition_button_btn"
                                        onClick={() => {
                                            cancelCurrentPopup({
                                                conditionPanelState,
                                                SidePanelContext: SidePanelContextValue
                                            })
                                        }}
                                    >
                                        <label className="condition_button_style">Cancel</label>
                                    </Button>
                                    {(isAssitant? automationMeta.type== 'no_trigger'? isInputhaveDifferentValues() : !active_automation:!active_automation) && !isErrorHandlingCondition && (
                                        <Button
                                            className={
                                                enableSave()
                                                    ? 'automation_condition_button_btn'
                                                    : 'automation_condition_button_btn editor_btn_disabled'
                                            }
                                            disabled={!enableSave()}
                                            onClick={() => saveNode()}
                                        >
                                            <label className="condition_button_style">Save</label>
                                        </Button>
                                    )}
                                </div>
                            </ButtonContainer>
                        </PopupScrollHeight>
                    </PopupContainer>
                </PopupWrapper>
            </ConditionWrapper>
        </React.Fragment>
    )
}

function cancelCurrentPopup(props: {
    conditionPanelState: ConditionPanelStateT
    SidePanelContext: RightPanelStateContext
}) {
    const { conditionPanelState, SidePanelContext } = props

    const { rightPanelState, setRightPanelState, treeData, setEditTreeData, editTreeData } = SidePanelContext

    const tree =
        conditionPanelState.parentNode.value.kind == 'ForEach' &&
        conditionPanelState.currentNode.addingInsideForEach &&
        conditionPanelState.parentNode.value.subflow
            ? conditionPanelState.parentNode.value.subflow.children.filter(x => x.value.kind === 'ParallelPath')
            : conditionPanelState.parentNode.children.filter(x => x.value.kind === 'ParallelPath')

    const cancellingNode = conditionPanelState.currentNode.parallelPathNode.currentParallelPathNode

    const currentNode = conditionPanelState.currentNode

    if (tree.length < 2) {
        setRightPanelState({
            ...rightPanelState,
            show: false,
            mode: NodeView,
            parentNode: { value: Tree, children: [] },
            currentNode: {
                ...currentNode,
                parallelPathNode: {
                    ...currentNode.parallelPathNode,
                    currentParallelPathNode: null
                }
            }
        })

        const newTree = cancellingNode
            ? updateNode(
                  { ...cancellingNode, name: '' },
                  treeData.tree,
                  cancellingNode.meta_data && cancellingNode.meta_data.subflow
                      ? {
                            addingInsideForEach: currentNode.addingInsideForEach,
                            forEachNodeId: cancellingNode.meta_data.subflow.forEachNodeId,
                            parentNode: cancellingNode.meta_data.subflow.parentId
                        }
                      : undefined
              )
            : treeData.tree

        setEditTreeData({ ...treeData, tree: newTree })
    } else {
        const node = cancellingNode as NodeT
        const addingFlowInsideForEach = {
            addingInsideForEach: node.meta_data.subflow.hasSubFlow as boolean,
            forEachNodeId: node.meta_data.subflow.forEachNodeId as string,
            parentNode: rightPanelState.parentNode.value
        }

        // for while adding new parallel path
        setRightPanelState({
            ...rightPanelState,
            show: false,
            currentNode: {
                ...currentNode,
                parallelPathNode: {
                    ...currentNode.parallelPathNode,
                    currentParallelPathNode: null
                }
            }
        })

        const newTree = cancellingNode
            ? updateNode({ ...cancellingNode, name: '' }, editTreeData.tree, addingFlowInsideForEach)
            : editTreeData.tree
        setEditTreeData({ ...editTreeData, tree: newTree })
    }
}

const Wrapper = (props: any) => (
    <ConditionWrapper>
        <PopupWrapper>
            <PopupContainer>
                <PopupScrollHeight>
                    <div className="automation_canvas_right_popup automation_condition_popup">
                        <div className="automation_canvas_right_popup_form">
                            <div className="automation_rightside_form automation_rightside_step_two edit_action_height">
                                {props.children}
                            </div>
                        </div>
                    </div>
                </PopupScrollHeight>
            </PopupContainer>
        </PopupWrapper>
    </ConditionWrapper>
)
const ConditionTip = () => (
    <>
    {getProductId() === "ASSISTANT" ? (
            <>
                <p>
                    Use{' '}
                    <a
                        className="sidepanel_href_color"
                        target="_blank"
                        href="https://help.workativ.com/knowledgebase/add-condition/"
                    >
                        Condition
                    </a>{' '}
                    if you need to check and execute workflow only if the condition is satisfied.
                </p>
            </>
        ) : ( 
            <>
                <p>
                    Use{' '}
                    <a
                        className="sidepanel_href_color"
                        target="_blank"
                        href="https://help.workativ.com/knowledgebase/condition/"
                    >
                        Condition
                    </a>{' '}
                    if you need to check and execute workflow only if the condition is satisfied.
                </p>
            </>
        )}
    </>

)


ConditionPanel.Header = (props: any) => {
    return (
        <div className="automation_form_header">
        {getProductId() === "ASSISTANT" ? (
            <Header>
            <HeaderLeft>
                Condition
                <Tooltip
                    className="target customTip"
                    zIndex={10000}
                    arrowSize={8}
                    tagName="span"
                    content={<ConditionTip />}
                    distance={5}
                >
                    <InfoIcon />
                </Tooltip>
                <LinkTag href={'https://help.workativ.com/knowledgebase/add-condition/'} target="_blank">
                    <QaIconWithCircle />
                </LinkTag>
            </HeaderLeft>
            <HeaderRight className="header_right_popup">
                {
                getProductId() === "ASSISTANT" ? <VideoLinkSimple id={'SET_CONDITION_IN_APP_WORKFLOW'} text={'How to'} /> : 
                <VideoLinkSimple id={'SET_CONDITION_IN_APP_WORKFLOW'} text={'How to set condition '} />
                }
            </HeaderRight>
            </Header>
         ) : (
            <Header>
            <HeaderLeft>
                Condition
                <Tooltip
                    className="target customTip"
                    zIndex={10000}
                    arrowSize={8}
                    tagName="span"
                    content={<ConditionTip />}
                    distance={5}
                >
                    <InfoIcon />
                </Tooltip>
                <a 
                    target="_blank"
                    href="https://help.workativ.com/knowledgebase/condition/"
                    >
                    <Tooltip
                        className="target customTip"
                        zIndex={10000}
                        arrowSize={8}
                        tagName="span"
                        content={"Help document"}
                        distance={5}
                    >
                   
                        <QaIconWithCircle />
                    </Tooltip>
                </a>
                
                {/* <LinkTag href={'https://help.workativ.com/knowledgebase/condition/'} target="_blank">
                    <QaIconWithCircle />
                </LinkTag> */}
            </HeaderLeft>
            <HeaderRight className="header_right_popup">
                {
                getProductId() === "ASSISTANT" ? <VideoLinkSimple id={'SET_CONDITION_IN_APP_WORKFLOW'} text={'How to'} /> : 
                <VideoLinkSimple id={'SET_CONDITION_IN_APP_WORKFLOW'} text={'How to set condition '} />
                }
            </HeaderRight>
            </Header>
          ) }

            {/* <Header>
                <HeaderLeft>
                    Condition
                    <Tooltip
                        className="target customTip"
                        zIndex={10000}
                        arrowSize={8}
                        tagName="span"
                        content={<ConditionTip />}
                        distance={5}
                    >
                        <InfoIcon />
                    </Tooltip>
                    <LinkTag href={'https://help.workativ.com/knowledgebase/add-condition/'} target="_blank">
                        <QaIconWithCircle />
                    </LinkTag>
                </HeaderLeft>
                <HeaderRight className="header_right_popup">
                    {
                    getProductId() === "ASSISTANT" ? <VideoLinkSimple id={'SET_CONDITION_IN_APP_WORKFLOW'} text={'How to'} /> : 
                    <VideoLinkSimple id={'SET_CONDITION_IN_APP_WORKFLOW'} text={'How to set condition '} />
                    }
                </HeaderRight>
            </Header> */}
            {
            getProductId() === "ASSISTANT" ?<Paragraph>Setup condition in your workflow.</Paragraph>
            : <Paragraph>Set condition in your workflow.</Paragraph>

            }       
        </div>
    )
}

ConditionPanel.Rules = (props: {
    showAndOr: boolean
    rule: RulesArray
    index: number
    isErrorHandlingCondition: boolean
}) => {
    const { rule, index, isErrorHandlingCondition } = props

    const { conditionPanelState, setConditionPanelState, contextPopupValues, setContextPopupValues } = useContext(
        ConditionPanelStateContext
    )

    const { treeData, active_automation, hasLinkedDialogs, automationMeta } = useContext(SidePanelContext)

    const currentNode = conditionPanelState.currentNode as ConditionResponseT

    const completedFirstInput =
        contextPopupValues.inputWithValues.length > 0 &&
        contextPopupValues.inputWithValues.length > index &&
        contextPopupValues.inputWithValues[index].nodeInput.left.length > 0

    const isAssitant = getProductId() == 'ASSISTANT'

    return (
        <React.Fragment>
            {rule.hasCombinator && (
                <div className="condition_trigger">
                    <div className="radio">
                        <label>
                            <input
                                type="radio"
                                checked={rule.hasCombinator.combinator === 'and'}
                                className={(isAssitant ? automationMeta.type =='no_trigger' ?!hasLinkedDialogs : !active_automation : !active_automation)?"radio-warning":"radio-warning editor_btn_disabled"}
                                onClick={() => {
                                    if((isAssitant ? automationMeta.type =='no_trigger' ?!hasLinkedDialogs : !active_automation : !active_automation)){
                                        const changeCombinator = produce(currentNode, draftState => {
                                            draftState.rules[index].hasCombinator = {
                                                combinator: 'and',
                                                type: 'combinator'
                                            }
                                        })
                                        setConditionPanelState({ ...conditionPanelState, currentNode: changeCombinator })
                                    }
                                }}
                                onChange={() => {}}
                            />
                        </label>
                        <span>And</span>
                    </div>
                    <div className="radio">
                        <label>
                            <input
                                type="radio"
                                className={(isAssitant ? automationMeta.type =='no_trigger' ?!hasLinkedDialogs : !active_automation : !active_automation)?"":"editor_btn_disabled"}
                                checked={rule.hasCombinator.combinator === 'or'}
                                onClick={() => {
                                    if((isAssitant ? automationMeta.type =='no_trigger' ?!hasLinkedDialogs : !active_automation : !active_automation)){
                                    const changeCombinator = produce(currentNode, draftState => {
                                        draftState.rules[index].hasCombinator = { combinator: 'or', type: 'combinator' }
                                    })
                                    setConditionPanelState({ ...conditionPanelState, currentNode: changeCombinator })
                                }
                                }}
                                onChange={() => {}}
                            />
                        </label>
                        <span>Or</span>
                    </div>
                </div>
            )}
            <InputWrapper>
                <InputContainer>
                    <div className="test_buton_result connection_button_condition">
                        <Header>{index + 1}. Condition inputs</Header>
                        <div className="test_buton_view">
                            <div className="automation_form_req automation_form_req_step_two">
                                <div className="automation_condition_input">
                                    {/* <h4>Condition {!isErrorHandlingCondition && <span>(Required)</span>}</h4> */}
                                    {(index > 0 || contextPopupValues.inputWithValues.length > 1) && (isAssitant ? automationMeta.type =='no_trigger' ? true : !active_automation : !active_automation) && (
                                        <span
                                            className="automation_condition_span"
                                            onClick={() => {
                                                const deletedRule = produce(currentNode, draftState => {
                                                    const rules = currentNode.rules.filter((x, i) => i !== index)

                                                    draftState.rules = rules.map((rule: RulesArray, i: number) => {
                                                        const { hasCombinator, ...rest } = rule
                                                        if (i === 0 && hasCombinator !== null) {
                                                            return { ...rest, hasCombinator: null }
                                                        }
                                                        return rule
                                                    })
                                                })

                                                setConditionPanelState({
                                                    ...conditionPanelState,
                                                    currentNode: deletedRule
                                                })

                                                const deletedInput = produce(contextPopupValues, draftState => {
                                                    draftState.inputWithValues = contextPopupValues.inputWithValues.filter(
                                                        (x, i) => i !== index
                                                    )
                                                })

                                                setContextPopupValues(deletedInput)
                                            }}
                                        >
                                            <span>
                                                <CloseIcon />
                                            </span>
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div
                                className={'automation_form_req automation_form_req_step_two'}
                                style={{ marginBottom: 20 }}
                            >
                                <div
                                    className={
                                        (!isErrorHandlingCondition && (isAssitant ? automationMeta.type =='no_trigger' ? true : !active_automation : !active_automation))
                                            ? 'form_req_step_two_global_form'
                                            : 'form_req_step_two_global_form disabled_with_blue'
                                    }
                                    onClick={() => {
                                        if (isErrorHandlingCondition || (automationMeta.type !='no_trigger' && active_automation)) return

                                        if (
                                            treeData.tree.value.kind === 'AppTrigger' ||
                                            treeData.tree.value.kind === 'EventTrigger' ||
                                            treeData.tree.value.kind === 'WebhookTrigger' ||
                                            (treeData.tree.value.output &&
                                                Object.keys(treeData.tree.value.output).length > 0) ||
                                            (conditionPanelState.parentNode.value.path &&
                                                conditionPanelState.parentNode.value.path.length > 0)
                                        ) {
                                            setContextPopupValues({
                                                ...contextPopupValues,
                                                show: true,
                                                currentInput: { index: index, keyName: 'left' }
                                            })
                                        } else {
                                            setContextPopupValues({
                                                ...contextPopupValues,
                                                currentInput: { index: index, keyName: 'left' }
                                            })
                                        }
                                    }}
                                >
                                    <div className="automation_global_form_edit _select_a_variable">
                                        {completedFirstInput &&
                                            contextPopupValues.inputWithValues[index].nodeInput.left.map((val, i) => {
                                                if (val.type === 'context') {
                                                    return (
                                                        <React.Fragment key={i}>
                                                            <span className="conetxt_variable_input">
                                                                <div
                                                                    className="context_popup_buton_content_span"
                                                                    style={
                                                                        isErrorHandlingCondition
                                                                            ? { pointerEvents: 'all' }
                                                                            : {}
                                                                    }
                                                                >
                                                                    <div className="button_auto">
                                                                        <img src={val.icon} />
                                                                        {val.value}
                                                                    </div>

                                                                    {val.valuePath.length > 0 && (
                                                                        <div className="button_auto_hover">
                                                                            {val.valuePath.map((value, j) => (
                                                                                <React.Fragment key={j}>
                                                                                    {j !== 0 && (
                                                                                        <div>
                                                                                            <FlowCanvasContextIcons.NextIcon />
                                                                                        </div>
                                                                                    )}
                                                                                    <div
                                                                                        style={
                                                                                            j + 1 ==
                                                                                            val.valuePath.length
                                                                                                ? {
                                                                                                      whiteSpace:
                                                                                                          'initial',
                                                                                                      width:
                                                                                                          'max-content'
                                                                                                  }
                                                                                                : {}
                                                                                        }
                                                                                    >
                                                                                        {value}
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </span>
                                                        </React.Fragment>
                                                    )
                                                } else {
                                                    return <p key={i}>{val.value}</p>
                                                }
                                            })}
                                        <div
                                            className={
                                                completedFirstInput ? 'button_auto_input_click' : 'button_auto_input'
                                            }
                                            // data-text={completedFirstInput ? '' : 'Select a variable'}
                                        >
                                            Select condition
                                        </div>
                                    </div>
                                </div>
                                {/* <p>Select output from previous node</p> */}
                            </div>

                            <div className="automation_condition_form automate_product" style={{ marginBottom: 0 }}>
                                <div className="select_application">
                                    <div className="select_work_form">
                                        <CanvasRightPanel.AppsDropDown
                                            inputs={{
                                                inputs:
                                                    completedFirstInput &&
                                                    contextPopupValues.inputWithValues[index].nodeInput.type !== 'date'
                                                        ? DefaultConditionValues[
                                                              contextPopupValues.inputWithValues[index].nodeInput.type
                                                          ].values.map(x => ({ name : x.name , value : x.value}))
                                                        : [],
                                                key: 'name',
                                                idKey: 'value',
                                                showIcon: false
                                            }}
                                            addDropDown={
                                                completedFirstInput &&
                                                contextPopupValues.inputWithValues[index].nodeInput.type !== 'date'
                                            }
                                            updateSelection={(selectedOperatorValue: string, id: string) => {
                                                const nextValue = produce(currentNode, draftState => {
                                                    draftState.rules[index].operator = id
                                                })

                                                setConditionPanelState({
                                                    ...conditionPanelState,
                                                    currentNode: nextValue
                                                })

                                                const nextInput = produce(contextPopupValues, draftState => {
                                                    draftState.inputWithValues[
                                                        index
                                                    ].nodeInput.operatorValue = selectedOperatorValue
                                                })

                                                setContextPopupValues(nextInput)
                                            }}
                                            render={(makeDropDownVisible: () => void) =>
                                                contextPopupValues.inputWithValues.length === 0 ||
                                                !completedFirstInput ||
                                                (completedFirstInput &&
                                                    contextPopupValues.inputWithValues[index].nodeInput.type !==
                                                        'date') ? (
                                                    <React.Fragment>
                                                        <DropdownWrapper className={(isAssitant ? automationMeta.type =='no_trigger' ?true : !active_automation : !active_automation)?"function_popup_section":"function_popup_section disabled_with_blue"}>
                                                            <Inputfield
                                                                type="text"
                                                                value={
                                                                    contextPopupValues.inputWithValues[index] &&
                                                                    contextPopupValues.inputWithValues[index].nodeInput
                                                                        .operatorValue.length > 0
                                                                        ? contextPopupValues.inputWithValues[index]
                                                                              .nodeInput.operatorValue
                                                                        : 'Select'
                                                                }
                                                                onClick={makeDropDownVisible}
                                                                onChange={() => {}}
                                                                className={
                                                                    !completedFirstInput || isErrorHandlingCondition
                                                                        ? 'disabled_with_blue without_caret_input'
                                                                        : 'without_caret_input'
                                                                }
                                                            />
                                                            <InputContent>Select condition criteria</InputContent>

                                                            <CancelButtonInput rotate onClick={makeDropDownVisible}>
                                                                <DownarrowWithCircle />
                                                            </CancelButtonInput>
                                                        </DropdownWrapper>
                                                    </React.Fragment>
                                                ) : (
                                                    <div className="condition_date_error">
                                                        Date and Time type cannot be used as a condition
                                                    </div>
                                                )
                                            }
                                        />
                                        {/* <p>Select a condition from the dropdown.</p> */}
                                    </div>
                                </div>
                            </div>

                            {/* <Input
                                showHeader={false}
                                disabled={
                                    !completedFirstInput ||
                                    currentNode.rules[index].operator === 'Select' ||
                                    contextPopupValues.inputWithValues[index].nodeInput.type === 'boolean' ||
                                    isErrorHandlingCondition
                                }
                                onChange={value => {
                                    //to update state without changing other value. produce is to use instead of doing {...something}
                                    const nextValue = produce(currentNode, draftState => {
                                        draftState.rules[index].right = value
                                    })

                                    setConditionPanelState({
                                        ...conditionPanelState,
                                        currentNode: nextValue
                                    })
                                }}
                                onFocus={(input: string) => {
                                    if (
                                        treeData.tree.value.kind === 'AppTrigger' ||
                                        treeData.tree.value.kind === 'EventTrigger' ||
                                        (treeData.tree.value.output &&
                                            Object.keys(treeData.tree.value.output).length > 0) ||
                                        (conditionPanelState.parentNode.value.path &&
                                            conditionPanelState.parentNode.value.path.length > 0)
                                    ) {
                                        setContextPopupValues({
                                            ...contextPopupValues,
                                            show: true,
                                            currentInput: { index: index, keyName: input }
                                        })
                                    } else {
                                        setContextPopupValues({
                                            ...contextPopupValues,
                                            currentInput: { index: index, keyName: input }
                                        })
                                    }
                                }}
                                value={currentNode.rules[index].right}
                                onKeyDown={e => {
                                    if (e.keyCode == 8) {
                                        e.stopPropagation()
                                        deleteCurrentInputValue(e)
                                    }
                                }}
                                input={{
                                    description: 'Add/Select the input/output to validate the condition with',
                                    displayTitle: '',
                                    required: false,
                                    key: 'right'
                                }}
                                render={() => {
                                    return (
                                        completedFirstInput &&
                                        contextPopupValues.inputWithValues[index].nodeInput.right.map((val, i) => {
                                            if (val.type === 'context') {
                                                return (
                                                    <React.Fragment key={i}>
                                                        <span className="conetxt_variable_input">
                                                            <div className="context_popup_buton_content_span">
                                                                <div className="button_auto">
                                                                    <img src={val.icon} />
                                                                    {val.value}
                                                                </div>
                                                                {val.valuePath.length > 0 && (
                                                                    <div className="button_auto_hover">
                                                                        {val.valuePath.map((value, j) => (
                                                                            <React.Fragment key={j}>
                                                                                {j !== 0 && (
                                                                                    <div>
                                                                                        <FlowCanvasContextIcons.NextIcon />
                                                                                    </div>
                                                                                )}
                                                                                <div>{value}</div>
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </span>
                                                    </React.Fragment>
                                                )
                                            } else {
                                                return <p key={i}>{val.value}</p>
                                            }
                                        })
                                    )
                                }}
                            /> */}
                            {completedFirstInput && isBinaryOperator(contextPopupValues.inputWithValues[index].nodeInput.operatorValue) &&
                                contextPopupValues.inputWithValues[index].nodeInput.type !== 'boolean' && (
                                    <DraftInputWrapper>
                                        <div className={(isAssitant ? automationMeta.type =='no_trigger' ? true : !active_automation : !active_automation)?"select_work_form":"select_work_form disabled_with_blue"} style={{marginTop:20}}>
                                            <Editor
                                                focus={
                                                    completedFirstInput &&
                                                    convertAsaSingleString(
                                                        convertToRaw(
                                                            (contextPopupValues.inputWithValues[index].nodeInput
                                                                .right as any).getCurrentContent()
                                                        ).blocks as RawDraftContentBlock[]
                                                    ).replace(/\s/g, '').length
                                                        ? 'focus_input'
                                                        : ''
                                                }
                                                focusContent={'Validate condition with'}
                                                infocus={contextPopupValues.currentInput.keyName === 'right'}
                                                disabled={
                                                    !completedFirstInput 
                                                    || currentNode.rules[index].operator === 'Select' 
                                                    || contextPopupValues.inputWithValues[index].nodeInput.type === 'boolean'
                                                    || (!isBinaryOperator(contextPopupValues.inputWithValues[index].nodeInput.operatorValue))
                                                    || isErrorHandlingCondition
                                                }
                                                value={
                                                    (completedFirstInput
                                                        ? contextPopupValues.inputWithValues[index].nodeInput.right
                                                        : emptyEditorState) as EditorState
                                                }
                                                onFocus={() => {
                                                    if (
                                                        treeData.tree.value.kind === 'AppTrigger' ||
                                                        treeData.tree.value.kind === 'EventTrigger' ||
                                                        treeData.tree.value.kind === 'WebhookTrigger' ||
                                                        (treeData.tree.value.output &&
                                                            Object.keys(treeData.tree.value.output).length > 0) ||
                                                        (conditionPanelState.parentNode.value.path &&
                                                            conditionPanelState.parentNode.value.path.length > 0)
                                                    ) {
                                                        setContextPopupValues({
                                                            ...contextPopupValues,
                                                            show: true,
                                                            currentInput: { index: index, keyName: 'right' }
                                                        })
                                                    } else {
                                                        setContextPopupValues({
                                                            ...contextPopupValues,
                                                            currentInput: { index: index, keyName: 'right' }
                                                        })
                                                    }
                                                }}
                                                setValue={(editorState: EditorState) => {
                                                    //to update state without changing other value. produce is to use instead of doing {...something}
                                                    const nextValue = produce(currentNode, draftState => {
                                                        draftState.rules[index].right = editorState
                                                    })
                                                    const nextContextValue = produce(contextPopupValues, draftState => {
                                                        draftState.inputWithValues[index].nodeInput.right = editorState
                                                    })
                                                    setContextPopupValues({
                                                        ...nextContextValue
                                                    })

                                                    setConditionPanelState({
                                                        ...conditionPanelState,
                                                        currentNode: nextValue
                                                    })
                                                }}
                                            />
                                            {/* <p>Add/Select the input/output to validate the condition with</p> */}
                                        </div>
                                    </DraftInputWrapper>
                                )}
                        </div>
                    </div>
                </InputContainer>
            </InputWrapper>
        </React.Fragment>
    )

    // function deleteCurrentInputValue(e: any) {
    //     var removeByIndex = function removeByIndex(array: any, index: number) {
    //         return array.filter(function(el: any, i: number) {
    //             return index !== i
    //         })
    //     }

    //     if (e.target.value.length === 0 && contextPopupValues.inputWithValues.length > 0) {
    //         //  filtering contextPopupValues inputWithValues with current matching input
    //         const currentDeleteInput = contextPopupValues.inputWithValues.filter(
    //             (x, i) => i === contextPopupValues.currentInput.index
    //         )

    //         if (currentDeleteInput.length > 0) {
    //             const afterRemovedElement = removeByIndex(
    //                 currentDeleteInput[0].nodeInput.right,
    //                 currentDeleteInput[0].nodeInput.right.length - 1
    //             )

    //             // after delete, if the last value of array is type of string and set its value to input
    //             if (
    //                 afterRemovedElement.length > 0 &&
    //                 afterRemovedElement[afterRemovedElement.length - 1].type == 'string'
    //             ) {
    //                 const nextValue = produce(currentNode, draftState => {
    //                     draftState.rules = currentNode.rules.map((inputs: RulesArray, index) => {
    //                         if (index === contextPopupValues.currentInput.index) {
    //                             return {
    //                                 ...inputs,
    //                                 right: afterRemovedElement[afterRemovedElement.length - 1].value
    //                             }
    //                         }
    //                         return inputs
    //                     })
    //                 })

    //                 setConditionPanelState({
    //                     ...conditionPanelState,
    //                     currentNode: nextValue
    //                 })

    //                 const newState = produce(contextPopupValues, draftState => {
    //                     draftState.inputWithValues = contextPopupValues.inputWithValues.map(y => {
    //                         if (y.nodeInput.index === currentDeleteInput[0].nodeInput.index) {
    //                             // delete last string value to set as input value to edit
    //                             return {
    //                                 ...y,
    //                                 nodeInput: {
    //                                     ...y.nodeInput,
    //                                     right:
    //                                         afterRemovedElement.length === 1 && afterRemovedElement[0].type === 'string'
    //                                             ? []
    //                                             : afterRemovedElement
    //                                 }
    //                             }
    //                         }
    //                         return y
    //                     })
    //                 })

    //                 setContextPopupValues(newState)
    //             } else {
    //                 // update deleted value in context popup state if last value of array type is context or empty
    //                 const newState = produce(contextPopupValues, draftState => {
    //                     // this is when inputs having two context values when it doesn't have input string
    //                     draftState.inputWithValues = contextPopupValues.inputWithValues.map(y => {
    //                         if (y.nodeInput.index === currentDeleteInput[0].nodeInput.index) {
    //                             return {
    //                                 ...y,
    //                                 nodeInput: {
    //                                     ...y.nodeInput,
    //                                     right: afterRemovedElement
    //                                 }
    //                             }
    //                         }
    //                         return y
    //                     })
    //                 })

    //                 setContextPopupValues(newState)
    //             }
    //         }
    //     }
    // }
}

function Input(props: {
    input: { description: string; displayTitle: string; required: boolean; key: string }
    showHeader: boolean
    value: string
    onChange: (e: string) => void
    onFocus: (key: string) => void
    disabled: boolean
    render: any
    onKeyDown?: (e: React.KeyboardEvent) => void
    isErrorHandlingCondition?: boolean
}) {
    const { input, onChange, onFocus, value, disabled, render, onKeyDown, isErrorHandlingCondition } = props

    return (
        <div className={'automation_form_req automation_form_req_step_two'}>
            {/* {props.showHeader && (
                <h4>
                    {input.displayTitle}
                    {!isErrorHandlingCondition &&
                        (input.required ? <span> (Required)</span> : <span> (optional)</span>)}
                </h4>
            )} */}
            <div
                className={
                    disabled ? 'disabled_with_blue form_req_step_two_global_form' : 'form_req_step_two_global_form'
                }
            >
                <div className="automation_global_form_edit padding-zero border-none">
                    {render()}
                    <InputContainer className="automate_condition">
                        <Inputfield
                            type="text"
                            className={
                                input.key == 'condition_name_key'
                                    ? 'button_auto_input condition_name automate_condition_name'
                                    : 'button_auto_input'
                            }
                            value={value}
                            onFocus={() => {
                                onFocus(input.key)
                            }}
                            onChange={(e: any) => {
                                onChange(e.target.value)
                            }}
                            onKeyDown={(e: any) => (onKeyDown ? onKeyDown(e) : {})}
                        />
                        <InputContent>{input.displayTitle}</InputContent>
                    </InputContainer>
                </div>
            </div>
            <p>{input.description}</p>
        </div>
    )
}
