/*eslint-disable*/
import produce from 'immer'
import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import Tooltip from 'react-tooltip-lite'
import styled from 'styled-components/macro'

import { VideoLinkSimple } from '@/Videos/Videos'
import { FlowCanvasContextIcons, InfoIcon, QaIconWithCircle } from '@/common/Icons/Icons'
import { NoTriggerVariable } from '@/common/Icons/Workflow.Icons'
import { Error } from '@/common/components/ErrorBoundary'
import { Loader } from '@/common/components/Loader'
import { Modal } from '@/common/components/Modal'
import { ButtonWrapper } from '@/common/styled/Dialogue.Dumb'
import {
    Button,
    ButtonContainer,
    EmptyDiv,
    Wrapper as ForEachWrapper,
    Header,
    HeaderLeft,
    HeaderRight,
    InputContainer,
    InputContent,
    InputWrapper,
    Input as Inputfield,
    LinkTag,
    Paragraph,
    PopupContainer,
    PopupScrollHeight,
    PopupWrapper
} from '@/common/styled/Workflow.Dumb'
import { getProductId } from '@/common/utils/utils'
import { getOutputValueRecursive, getValuesBetweenBraces } from '@/Flows/_utils'
import { automationAPIURLWithWorkspace, getJson } from '@/common/utils/api-utils'
import { SidePanelContext } from './Canvas'
import { ContextOutputTree, ContextPopup, configApiCall } from './ContextPopup'
import {
    ActionInputsListT,
    AuthType,
    CollectionOutputListTypeT,
    ForEachInitialState,
    ForEachResponseT,
    FunctionsListT,
    NodeAddT,
    NodeEditT,
    NodeMode,
    NodeModes,
    NodeT,
    NodeView,
    ObjectOutputListTypeT,
    PathIdType,
    PopupError,
    ResponseOutput,
    ResponseOutputT,
    RightPanelStateContext,
    Tree,
    TreeT,
    updateNode
} from './types'

export type FunctionPanelStateT = {
    show: boolean
    parentNode: TreeT<NodeT>
    mode: NodeModes
    currentNode: ForEachResponseT
}

type CollectionPickerT = {
    isCollectionPicker: boolean
    selectedCollection: {
        name: string
        value: CollectionOutputListTypeT
    }
    app: ResponseOutputT & PathIdType
}

export type ContextPopupStateT = {
    show: boolean
    collectionPicker: CollectionPickerT
}

export type FunctionPanelStateContextT = {
    functionPanelState: FunctionPanelStateT
    setFunctionPanelState: (object: any) => void
    setContextPopupValues: (object: any) => void
    contextPopupValues: ContextPopupStateT
    userSwitchedApp: boolean
    setUserSwitchedApp: (switched: boolean) => void
}

export const FunctionPanelStateContext = createContext<FunctionPanelStateContextT>({
    functionPanelState: {
        show: false,
        mode: NodeAddT,
        parentNode: { value: Tree, children: [] },
        currentNode: ForEachInitialState
    },
    setFunctionPanelState: (object: any) => undefined,
    setContextPopupValues: (object: any) => undefined,
    contextPopupValues: {
        show: false,
        collectionPicker: {
            isCollectionPicker: false,
            selectedCollection: {
                name: '',
                value: {
                    $id: '',
                    type: 'collection',
                    item: {
                        type: 'object',
                        properties: {}
                    },
                    title: '',
                    properties: {}
                }
            },
            app: ResponseOutput
        }
    },
    userSwitchedApp: false,
    setUserSwitchedApp: (switched: boolean) => undefined
})

export const ForEachPanel = (props: any) => {
    const { rightPanelState } = useContext(SidePanelContext)

    const [functionPanelState, setFunctionPanelState] = useState({
        ...rightPanelState,
        currentNode: rightPanelState.currentNode as ForEachResponseT
    })

    const [contextPopupValues, setContextPopupValues] = useState<ContextPopupStateT>({
        show: false,
        collectionPicker: {
            isCollectionPicker: false,
            selectedCollection: {
                name: '',
                value: {
                    $id: '',
                    type: 'collection',
                    item: {
                        type: 'object',
                        properties: {}
                    },
                    title: '',
                    properties: {}
                }
            },
            app: ResponseOutput
        }
    })

    const [userSwitchedApp, setUserSwitchedApp] = useState(false)

    return (
        <FunctionPanelStateContext.Provider
            value={{
                functionPanelState,
                setFunctionPanelState,
                contextPopupValues,
                setContextPopupValues,
                userSwitchedApp,
                setUserSwitchedApp
            }}
        >
            <ForEachActualComponent {...props} />
        </FunctionPanelStateContext.Provider>
    )
}

const ForEachActualComponent = (props: any) => {
    // for comparing props
    const FunctionElement = useRef<any>(null)

    const { rightPanelState, setRightPanelState, saveTree, treeData, hasLinkedDialogs,active_automation,automationMeta } = useContext(SidePanelContext)

    const {
        functionPanelState,
        setFunctionPanelState,
        contextPopupValues,
        setContextPopupValues,
        userSwitchedApp,
        setUserSwitchedApp
    } = useContext(FunctionPanelStateContext)

    const currentNode = functionPanelState.currentNode as ForEachResponseT

    const [loading, setLoading] = useState(true)
    const [validation, setValidation] = useState<any>({ show: false, props: null })
    useEffect(() => {
        const currentMode = rightPanelState.mode

        // compare props and update changes based on following condition
        if (
            !FunctionElement.current ||
            (FunctionElement.current &&
                (rightPanelState.mode.mode !== FunctionElement.current.mode.mode ||
                    (currentMode.mode === NodeMode.Edit &&
                        currentMode.nodeEditInput.id !== FunctionElement.current.mode.nodeEditInput.id)))
        ) {
            if (currentMode.mode === NodeMode.Add || currentMode.mode === NodeMode.Edit) {
                // next time to compare with previous props
                FunctionElement.current = rightPanelState

                //show loader while changing modes (such as edit->add or add->edit)
                setLoading(true)

                // update current action panel context state for further child usage
                setFunctionPanelState({
                    ...rightPanelState,
                    currentNode: rightPanelState.currentNode as ForEachResponseT
                })

                // call initial API everytime mode changed
                callFunctionstAPI({
                    ...rightPanelState,
                    currentNode: rightPanelState.currentNode as ForEachResponseT
                })

                setUserSwitchedApp(false)
            } else {
                FunctionElement.current = rightPanelState
                currentMode.mode === NodeMode.Loading ? setLoading(true) : setLoading(false)

                setFunctionPanelState({
                    ...rightPanelState,
                    currentNode: rightPanelState.currentNode as ForEachResponseT
                })
            }
        } else if (
            rightPanelState.mode.mode === NodeMode.Edit &&
            FunctionElement.current.mode.mode === NodeMode.Edit &&
            rightPanelState.mode.nodeEditInput.id === FunctionElement.current.mode.nodeEditInput.id
        ) {
            setFunctionPanelState({
                ...functionPanelState,
                show: true
            })
        }
    }, [rightPanelState.mode])

    // to change FunctionElement.current values while updating state in each step
    useEffect(() => {
        FunctionElement.current = functionPanelState

        // to see the details in left side panel while adding or editing
        setRightPanelState({ ...rightPanelState, currentNode: functionPanelState.currentNode })
    }, [functionPanelState.currentNode])

    // -------------- Api call --------------
    async function callFunctionstAPI(state: FunctionPanelStateT) {
        const workspaceName = props.match.params.workspacename

        await getJson(automationAPIURLWithWorkspace(workspaceName, '/actions'))
            .then((functionsList: FunctionsListT) => {
                if (NodeMode.Edit === state.mode.mode) {
                    const triggerNodeOutput = treeData.tree.value

                    const parentNode =
                        triggerNodeOutput.kind === 'AppTrigger' || triggerNodeOutput.kind === 'EventTrigger'
                            ? ({
                                  ...state.parentNode,
                                  value: {
                                      ...state.parentNode.value,
                                      path: state.parentNode.value.path
                                          ? [
                                                {
                                                    actionId: triggerNodeOutput.meta_data.action_id,
                                                    nodeId: triggerNodeOutput.id
                                                }
                                            ].concat(state.parentNode.value.path)
                                          : [
                                                {
                                                    actionId: triggerNodeOutput.meta_data.action_id,
                                                    nodeId: triggerNodeOutput.id
                                                }
                                            ]
                                  }
                              } as TreeT<NodeT>)
                            : state.parentNode

                    configApiCall(functionsList as any, parentNode, false, workspaceName, false).then(
                        (configResponse: ActionInputsListT[]) => {
                            const mode = state.mode as NodeEditT

                            let combiningResponseWithTriggerOutput: any = []

                            if (triggerNodeOutput.kind !== 'AppTrigger' && triggerNodeOutput.kind !== 'EventTrigger') {
                                const triggerOutputResponse: ActionInputsListT[] = [
                                    {
                                        type: 'success',
                                        output: {
                                            name: triggerNodeOutput.name,
                                            input: [],
                                            app: {
                                                appName: triggerNodeOutput.name,
                                                action: triggerNodeOutput.kind,
                                                pathId: {
                                                    nodeId: triggerNodeOutput.id,
                                                    actionId: ''
                                                },
                                                appId: '',
                                                createdDate: '',
                                                type: 'trigger',
                                                file: '',
                                                authentication_type: AuthType.None,
                                                id: '',
                                                scope: '',
                                                icon: NoTriggerVariable,
                                                latest: false,
                                                version: ''
                                            },
                                            output: triggerNodeOutput.output
                                        }
                                    }
                                ]
                                combiningResponseWithTriggerOutput = triggerOutputResponse.concat(configResponse)
                            } else {
                                combiningResponseWithTriggerOutput = configResponse
                            }

                            const filteredValue = (config: ActionInputsListT[]) => {
                                const filt = config
                                    .map(resp => {
                                        const op = resp.output.output as ObjectOutputListTypeT

                                        const collectionOutput = getOutputValueRecursive(
                                            op,
                                            getValuesBetweenBraces(mode.nodeEditInput.meta_data.collection)[0]
                                                .split('.output.')[1]
                                                .split('.')
                                        )

                                        if (collectionOutput) return { value: collectionOutput, app: resp.output.app }
                                        else return null
                                    })
                                    .filter(Boolean)
                                return filt[0]
                            }

                            const finalValue =
                                mode.nodeEditInput.meta_data.collection &&
                                mode.nodeEditInput.meta_data.collection.length > 0
                                    ? filteredValue(combiningResponseWithTriggerOutput)
                                    : undefined

                            if (finalValue) {
                                setContextPopupValues({
                                    ...contextPopupValues,
                                    collectionPicker: {
                                        isCollectionPicker: false,
                                        selectedCollection: { name: finalValue.value.title, value: finalValue.value },
                                        app: finalValue.app
                                    }
                                })
                            }

                            const addedContextValue = produce(state, draft => {
                                draft.currentNode.functionsList = functionsList
                                draft.currentNode.name = mode.nodeEditInput.name
                                draft.currentNode.description = mode.nodeEditInput.description
                                draft.currentNode.selectedCollection = mode.nodeEditInput.meta_data.collection
                                mode.nodeOutputConfig = combiningResponseWithTriggerOutput
                                draft.mode = mode
                            })

                            setFunctionPanelState(addedContextValue)
                        }
                    )

                    setLoading(false)
                } else {
                    const currentNode = state.currentNode as ForEachResponseT

                    const nextState = produce(currentNode, draftState => {
                        draftState.functionsList = functionsList
                    })

                    setFunctionPanelState({
                        ...state,
                        currentNode: nextState
                    })

                    setLoading(false)
                }
            })
            .catch(err => {
                setLoading(false)

                setFunctionPanelState({
                    ...state,
                    mode: {
                        ...PopupError,
                        error: 'Something went wrong!<br /> Please try again afer sometime.'
                    }
                })
            })
    }

    const SidePanelContextValue = useContext(SidePanelContext)

    // to show loading whenever mode changed
    if (loading) {
        return (
            <div className="automation_canvas_right">
                <Wrapper>
                    <div className="popup_inside_loader">
                        <Loader.PopupLoader show={true} />
                    </div>
                </Wrapper>
            </div>
        )
    } else if (functionPanelState.mode.mode === NodeMode.Error) {
        return (
            <div className="automation_canvas_right">
                <Wrapper>
                    <div className="flow_canvas_popup_error">
                        <Error.AddorEdit
                            info={functionPanelState.mode.error}
                            onClick={() => {
                                cancelCurrentPopup({
                                    functionPanelState,
                                    SidePanelContext: SidePanelContextValue
                                })
                            }}
                        />
                    </div>
                </Wrapper>
            </div>
        )
    }

    // disable or enable save button
    function enableSave() {
        const currentNode = functionPanelState.currentNode
        return currentNode.name.length > 0 && currentNode.selectedCollection.length > 0
    }

    function isInputhaveDifferentValues() {
        const currentNode = functionPanelState.currentNode
        const mode = functionPanelState.mode as NodeEditT
        if(functionPanelState.mode.mode===NodeMode.Edit){
            return currentNode.selectedCollection != mode.nodeEditInput.meta_data.collection
        }else{
            return true
        }
    }


    function updateInputValuesWithContext1(props: {
        path: string
        value: string
        valuePath: string[]
        icon: string
        hidden_item_type: string
        selectedColletion: any
        appForCollection: any
        examples: string
    }) {
        const { appForCollection, selectedColletion, path } = props

        setContextPopupValues({
            ...contextPopupValues,
            show: false,
            collectionPicker: {
                ...contextPopupValues.collectionPicker,
                app: appForCollection,
                selectedCollection: selectedColletion
            }
        })

        setFunctionPanelState({
            ...functionPanelState,
            currentNode: produce(currentNode, draft => {
                draft.selectedCollection = path
            })
        })
        setValidation({ show: false, props: null })
    }

    function updateInputValuesWithContext(props: any) {
        contextPopupValues.collectionPicker.isCollectionPicker &&
        contextPopupValues.collectionPicker.selectedCollection &&
        contextPopupValues.collectionPicker.selectedCollection.name.length > 0
            ? setValidation({ show: true, props })
            : updateInputValuesWithContext1(props)
    }

    function saveNode() {
        saveTree(functionPanelState.currentNode)
    }

    function collectionValidation() {
        return validation.show ? (
            <Modal error={{ error: false, info: '' }} showPopupLoader={false}>
                <React.Fragment>
                    <h2>Do you want to continue?</h2>
                    <Paragraph>
                        Updating the existing Collection will remove the existing Collection Data where ever it is used.
                    </Paragraph>
                </React.Fragment>

                <div>
                    <ButtonWrapper>
                        <Button style={{marginRight: 20}} primary type="button" onClick={() => setValidation({ show: false, props: null })}>
                            <label>No</label>
                        </Button>
                        <Button type="button" onClick={() => updateInputValuesWithContext1(validation.props)}>
                            <label>Yes</label>
                        </Button>
                    </ButtonWrapper>
                </div>
            </Modal>
        ) : null
    }

    return (
        <React.Fragment>
            {collectionValidation()}
            <ForEachWrapper>
                <PopupWrapper>
                    <PopupContainer>
                        <ForEachPanel.Header />
                        <PopupScrollHeight>
                            <EmptyDiv>
                                <div className="automation_canvas_right foreach_popup">
                                    <div className="automation_canvas_right_popup automation_condition_popup">
                                        <div className="automation_canvas_right_popup_form">
                                            <div className="automation_rightside_form automation_rightside_step_two edit_action_height">
                                                <ForEachPanel.EnterName />
                                                <ForEachPanel.Inputs
                                                    {...props}
                                                    title={'Inputs'}
                                                    description="Select/Fill the required input details to perform the foreach."
                                                    state={functionPanelState}
                                                    workspacename={props.match.params.workspacename}
                                                    setPanelState={setFunctionPanelState}
                                                    contextPopupValues={contextPopupValues}
                                                    setContextPopupValues={setContextPopupValues}
                                                    updateInputValuesWithContext={updateInputValuesWithContext}
                                                />
                                                <div className="test_buton_result">
                                                    <div className="test_buton_view">
                                                        <div className="automation_global_form">
                                                            <div className="select_work_form">
                                                                <InputWrapper>
                                                                    <Header>For-Each summary </Header>
                                                                    <InputContainer className={(getProductId() == 'ASSISTANT' ? automationMeta.type =='no_trigger' ?!hasLinkedDialogs:!active_automation:!active_automation)?"":"editor_btn_disabled"}>
                                                                        <Inputfield
                                                                            type="text"
                                                                            className={''}
                                                                            value={currentNode.description}
                                                                            onChange={(e: any) => {
                                                                                const newState = produce(
                                                                                    currentNode,
                                                                                    draft => {
                                                                                        draft.description =
                                                                                            e.target.value
                                                                                    }
                                                                                )

                                                                                setFunctionPanelState({
                                                                                    ...functionPanelState,
                                                                                    currentNode: newState
                                                                                })
                                                                            }}
                                                                        />
                                                                        <InputContent>
                                                                            Description
                                                                            <Tooltip
                                                                                className="target customTip _description"
                                                                                zIndex={10000}
                                                                                tagName="span"
                                                                                content="Provide description for For-Each. It will be displayed as information for the For-Each on the workflow canvas page."
                                                                                distance={15}
                                                                                forceDirection={true}
                                                                                direction="up-start"
                                                                            >
                                                                                <InfoIcon />
                                                                            </Tooltip>
                                                                        </InputContent>
                                                                    </InputContainer>
                                                                </InputWrapper>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {contextPopupValues.show && (
                                        <ContextPopup
                                            {...props}
                                            mode={{ mode: NodeMode.Add }}
                                            tree={treeData.tree}
                                            parentNode={rightPanelState.parentNode}
                                            actionAppsList={currentNode.functionsList}
                                            triggerNode={treeData.tree.value}
                                            updateInputValuesWithContext={updateInputValuesWithContext}
                                            showOnlyCollection={{
                                                showOnlyCollection:
                                                    contextPopupValues.collectionPicker.isCollectionPicker,
                                                contextPopupValues: contextPopupValues
                                            }}
                                            closeContextPopup={() =>
                                                setContextPopupValues({
                                                    ...contextPopupValues,
                                                    show: false,
                                                    collectionPicker: {
                                                        ...contextPopupValues.collectionPicker,
                                                        isCollectionPicker: false
                                                    }
                                                })
                                            }
                                        />
                                    )}
                                </div>
                            </EmptyDiv>
                            <ButtonContainer className="footer_button_fixed">
                                <div className="popup_footer_button_container">
                                    <Button
                                        primary
                                        onClick={() => {
                                            cancelCurrentPopup({
                                                functionPanelState,
                                                SidePanelContext: SidePanelContextValue
                                            })
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    {isInputhaveDifferentValues() && (
                                        <Button
                                            className={enableSave() ? '' : 'editor_btn_disabled'}
                                            disabled={!enableSave()}
                                            onClick={() => saveNode()}
                                        >
                                            Save
                                        </Button>
                                    )}
                                </div>
                            </ButtonContainer>
                        </PopupScrollHeight>
                    </PopupContainer>
                </PopupWrapper>
            </ForEachWrapper>
        </React.Fragment>
    )
}

function cancelCurrentPopup(props: {
    functionPanelState: FunctionPanelStateT
    SidePanelContext: RightPanelStateContext
}) {
    const { functionPanelState, SidePanelContext } = props

    const { rightPanelState, setRightPanelState, treeData, setEditTreeData, editTreeData } = SidePanelContext

    const tree = functionPanelState.parentNode.children.filter(x => x.value.kind === 'ParallelPath')
    const cancellingNode = functionPanelState.currentNode.parallelPathNode.currentParallelPathNode

    const currentNode = functionPanelState.currentNode

    if (tree.length < 2) {
        setRightPanelState({
            ...rightPanelState,
            show: false,
            mode: NodeView,
            parentNode: { value: Tree, children: [] },
            currentNode: {
                ...currentNode,
                parallelPathNode: {
                    ...currentNode.parallelPathNode,
                    currentParallelPathNode: null
                }
            }
        })
        const newTree = cancellingNode ? updateNode({ ...cancellingNode, name: '' }, treeData.tree) : treeData.tree

        setEditTreeData({ ...treeData, tree: newTree })
    } else {
        // for while adding new parallel path
        setRightPanelState({
            ...rightPanelState,
            show: false,
            currentNode: {
                ...currentNode,
                parallelPathNode: {
                    ...currentNode.parallelPathNode,
                    currentParallelPathNode: null
                }
            }
        })

        const newTree = cancellingNode
            ? updateNode({ ...cancellingNode, name: '' }, editTreeData.tree)
            : editTreeData.tree
        setEditTreeData({ ...editTreeData, tree: newTree })
    }
}

const Wrapper = (props: any) => (
    <ForEachWrapper>
        <PopupWrapper>
            <PopupContainer>
                <PopupScrollHeight>
                    <div className="automation_canvas_right_popup automation_condition_popup">
                        <div className="automation_canvas_right_popup_form">
                            <div className="automation_rightside_form automation_rightside_step_two edit_action_height">
                                {props.children}
                            </div>
                        </div>
                    </div>
                </PopupScrollHeight>
            </PopupContainer>
        </PopupWrapper>
    </ForEachWrapper>
)
const ForEachTip = () => (
    <>
    {getProductId() === "ASSISTANT" ? ( 
        <>
            <p>
            Use{' '}
            <a
                className="sidepanel_href_color"
                target="_blank"
                href="https://help.workativ.com/knowledgebase/for-each-function/"
            >
                For-Each
            </a>{' '}
            to repeat workflow execution based on the output of previous action having more than one entry.
        </p>
        <p>
            E.g., Let's say you received data for 10 users by using ‘Get all users’ action, and you would like to
            onboard all 10 users in multiple apps like ITSM, HR, and IAM. You can use For-Each to repeat onboarding for
            each user from the user list.
        </p>
        </>
    ) : ( 
        <>
            <p>
            Use{' '}
            <a
                className="sidepanel_href_color"
                target="_blank"
                href="https://help.workativ.com/knowledgebase/for-each/"
            >
                For-Each
            </a>{' '}
            to repeat workflow execution based on the output of previous action having more than one entry.
        </p>
        <p>
            E.g., Let's say you received data for 10 users by using ‘Get all users’ action, and you would like to
            onboard all 10 users in multiple apps like ITSM, HR, and IAM. You can use For-Each to repeat onboarding for
            each user from the user list.
        </p>
        </>
    ) }
    </>
)

const ForEachHelpTip = () => (
    <>
        <p>
            <a 
            className=""
            target="_blank"
            href="https://help.workativ.com/knowledgebase/for-each/"
            >Help document</a>
        </p>
    </>

)

ForEachPanel.Header = (props: any) => {
    return (
        <>
        {getProductId() === "ASSISTANT" ? ( 
            <Header className="function_popup_header">
            <HeaderLeft>
                For-Each
                <Tooltip
                    className="target customTip"
                    zIndex={10000}
                    arrowSize={8}
                    tagName="span"
                    content={<ForEachTip />}
                    distance={5}
                >
                    <InfoIcon />
                </Tooltip>
                <LinkTag href={'https://help.workativ.com/knowledgebase/for-each-function/'} target="_blank">
                    <QaIconWithCircle />
                </LinkTag>
            </HeaderLeft>
            <HeaderRight>
            {  getProductId() === "ASSISTANT" ? <VideoLinkSimple id={'ADD_FOR_EACH_FUNCTION'} text={'How to'} /> : <VideoLinkSimple id={'ADD_FOR_EACH_FUNCTION'} text={'How to set For-Each'} />}


                
            </HeaderRight>
        </Header>
        ) : ( 
            <Header className="function_popup_header">
                <HeaderLeft>
                    For-Each
                    <Tooltip
                        className="target customTip"
                        zIndex={10000}
                        arrowSize={8}
                        tagName="span"
                        content={<ForEachTip />}
                        distance={5}
                    >
                        <InfoIcon />
                    </Tooltip>
                    <a
                        target="_blank"
                        href="https://help.workativ.com/knowledgebase/for-each/">
                        <Tooltip
                            className="target customTip"
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content={"Help document"}
                            distance={5}
                        >
                        
                            <QaIconWithCircle />
                        </Tooltip>
                    </a>
                   
                    {/* <LinkTag href={'https://help.workativ.com/knowledgebase/for-each-function/'} target="_blank">
                        <QaIconWithCircle />
                    </LinkTag> */}
                </HeaderLeft>
                <HeaderRight>
                {  getProductId() === "ASSISTANT" ? <VideoLinkSimple id={'ADD_FOR_EACH_FUNCTION'} text={'How to'} /> : <VideoLinkSimple id={'ADD_FOR_EACH_FUNCTION'} text={'How to set For-Each'} />}


                    
                </HeaderRight>
            </Header>
        ) }
        {  getProductId() === "ASSISTANT" ? <Paragraph>Setup for-each in your workflow.</Paragraph> : <Paragraph>Set for-each in your workflow.</Paragraph>}
        </>


        // <>
        //     <Header className="function_popup_header">
        //         <HeaderLeft>
        //             For-Each
        //             <Tooltip
        //                 className="target customTip"
        //                 zIndex={10000}
        //                 arrowSize={8}
        //                 tagName="span"
        //                 content={<ForEachTip />}
        //                 distance={5}
        //             >
        //                 <InfoIcon />
        //             </Tooltip>
        //             <LinkTag href={'https://help.workativ.com/knowledgebase/for-each-function/'} target="_blank">
        //                 <QaIconWithCircle />
        //             </LinkTag>
        //         </HeaderLeft>
        //         <HeaderRight>
        //         {  getProductId() === "ASSISTANT" ? <VideoLinkSimple id={'ADD_FOR_EACH_FUNCTION'} text={'How to'} /> : <VideoLinkSimple id={'ADD_FOR_EACH_FUNCTION'} text={'How to set For-Each'} />}


                    
        //         </HeaderRight>
        //     </Header>

            
            
        // </>
    )
}

ForEachPanel.EnterName = (props: any) => {
    const { functionPanelState, setFunctionPanelState } = useContext(FunctionPanelStateContext)

    const { active_automation, automationMeta, hasLinkedDialogs } = useContext(SidePanelContext)


    const { state, ...rest } = props

    const currentNode = functionPanelState.currentNode as ForEachResponseT

    return (
        <div className="autoamtion_function_section">
            <div className="select_application">
                <div className="select_work_form">
                    <InputWrapper>
                        <InputContainer className={(getProductId() == 'ASSISTANT' ? automationMeta.type =='no_trigger' ?!hasLinkedDialogs:!active_automation:!active_automation)?"":"editor_btn_disabled"}>
                            <Inputfield
                                type="text"
                                className={''}
                                value={currentNode.name}
                                onChange={(e: any) => {
                                    const newState = produce(currentNode, draft => {
                                        draft.name = e.target.value
                                    })

                                    setFunctionPanelState({ ...functionPanelState, currentNode: newState })
                                }}
                            />
                            <InputContent>For-Each name</InputContent>
                        </InputContainer>
                    </InputWrapper>
                    {/* <Paragraph>Provide name for the For-Each.</Paragraph> */}
                </div>
            </div>
        </div>
    )
}
const ConditionPopup = styled.div`
    width: 100%;
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 20px;
    background: #f4f4f4;

    .button_auto_input_click {
        color: #999;
        top: 8px;
        font-size: 10px;
        -webkit-transition: 0.26s ease-out;
        transition: 0.26s ease-out;
        line-height: 10px;
        font-family: 'Gordita-Regular';
        position: absolute;
        pointer-events: none;
        left: 12px;
    }
    .form_req_step_two_global_form._select_a_variable:focus .button_auto_input {
        top: 8px;
        font-size: 10px;
        transition: 0.26s ease-out;
        line-height: 10px;
    }

    .form_req_step_two_global_form._select_a_variable {
        width: 100%;
        padding: 20px 12px 1px 12px;
        border: 1px solid #b9b9b9;
        border-radius: 6px;
        background: #fff;
        min-height: 48px;
        font-size: 12px;
        font-family: 'Gordita-Regular';
        color: #000000;

        position: relative;
        margin-right: 0px;

        .button_auto_input {
            color: #999;
            font-size: 12px;
            font-family: 'Gordita-Regular';
            position: absolute;
            pointer-events: none;
            left: 12px;
            top: 16px;
            -webkit-transition: 0.26s ease-in;
            transition: 0.26s ease-in;
        }
    }
    .collection_picker.collection_picker_wrapeer {
        position: relative;
        left: -5px;
        padding-top: 10px;
    }
    .context_popup_buton_content_span .right_panel_ht {
        height: auto;
        overflow-y: scroll;
        margin-top: 0;
        padding-bottom: 0px;
        padding: 0px;
    }
    .form_req_step_two_global_form .context_popup_buton_content_span {
        width: 100%;
        float: left;
        margin-left: 0px;
        margin-bottom: 0px;
        position: relative;
        top: 1px;
        padding-left: 0px !important;
    }
    .collection_picker_in_input_page .popup_tree .tree1 .collection_picker li::after {
        content: '';
        margin-top: -2px;
        left: 1px;
    }
    .collection_picker.collection_picker_wrapeer .object_inside_collection:before {
        left: 30px;
    }
    .test_buton_view .automation_global_form p {
        margin-top: 7px;
        line-height: 12px;
    }
    .context_popup_buton_content_span .button_auto {
        background: #d5ffee;
        border: 1px solid #cecece;
    }
    .context_popup_buton_content_span .treeview_automatiom_li .button_auto {
        background: #f4f4f4;
        border: 1px solid #cecece;
    }
    .treeview_automatiom_li.collection_picker_parent.pointer_events_none .button_auto {
        background: #d5ffee;
        border: 1px solid #cecece;
        padding: 0 6px 0px 3px !important;
    }
`
ForEachPanel.Inputs = (props: any) => {
    const { hasLinkedDialogs, treeData } = useContext(SidePanelContext)

    const { functionPanelState, setFunctionPanelState, contextPopupValues, setContextPopupValues } = useContext(
        FunctionPanelStateContext
    )

    const { state, ...rest } = props

    const currentNode = functionPanelState.currentNode as ForEachResponseT

    return (
        <ConditionPopup>
            <div className="test_buton_result">
                <div className="test_buton_view">
                    <CollectionInput
                        state={functionPanelState}
                        setContextPopupValues={setContextPopupValues}
                        contextPopupValues={contextPopupValues}
                        setPanelState={setFunctionPanelState}
                        workspacename={props.match.params.workspacename}
                        {...rest}
                    />
                </div>
            </div>
        </ConditionPopup>
    )
}

const CollectionInput = (props: any) => {
    const { state, contextPopupValues, setContextPopupValues, updateInputValuesWithContext } = props

    const { treeData } = useContext(SidePanelContext)

    const selectedCollection = contextPopupValues.collectionPicker.selectedCollection

    const paths = contextPopupValues.collectionPicker.selectedCollection.value.$id
        .split('/')
        .filter((x: any) => x.length !== 0 && x !== 'properties')

    return (
        <div className="automation_global_form forEach_popup_section">
            <div className="select_work_form">
                <Header>For-Each inputs</Header>
                <div
                    className={
                        contextPopupValues.collectionPicker.isCollectionPicker && contextPopupValues.show
                            ? 'form_req_step_two_global_form _select_a_variable input_selected padding-zero border-none'
                            : 'form_req_step_two_global_form _select_a_variable padding-zero border-none'
                    }
                    onClick={() => {
                        if (
                            treeData.tree.value.kind === 'AppTrigger' ||
                            treeData.tree.value.kind === 'EventTrigger' ||
                            treeData.tree.value.kind === 'WebhookTrigger' ||
                            (treeData.tree.value.output && Object.keys(treeData.tree.value.output).length > 0) ||
                            (state.parentNode.value.path && state.parentNode.value.path.length > 0)
                        ) {
                            setContextPopupValues({
                                ...contextPopupValues,
                                show: true,
                                collectionPicker: {
                                    ...contextPopupValues.collectionPicker,
                                    isCollectionPicker: true
                                }
                            })
                        }
                    }}
                >
                    <div className="automation_global_form_edit">
                        {contextPopupValues.collectionPicker.selectedCollection &&
                        contextPopupValues.collectionPicker.selectedCollection.name.length > 0 ? (
                            <React.Fragment>
                                <span className="conetxt_variable_input">
                                    <div className="context_popup_buton_content_span">
                                        <div className="button_auto">
                                            <img src={contextPopupValues.collectionPicker.app.icon} />
                                            {contextPopupValues.collectionPicker.selectedCollection.value.title}
                                        </div>

                                        <div className="button_auto_hover">
                                            {[
                                                contextPopupValues.collectionPicker.app.appName,
                                                contextPopupValues.collectionPicker.app.action
                                            ]
                                                .concat(paths)
                                                .map((value, j) => (
                                                    <React.Fragment key={j}>
                                                        {j !== 0 && (
                                                            <div>
                                                                <FlowCanvasContextIcons.NextIcon />
                                                            </div>
                                                        )}
                                                        <div>{value}</div>
                                                    </React.Fragment>
                                                ))}
                                        </div>
                                    </div>
                                </span>
                                <div
                                    className={
                                        contextPopupValues.collectionPicker.isCollectionPicker
                                            ? 'button_auto_input_click'
                                            : 'button_auto_input_click'
                                    }
                                >
                                    Select a collection
                                </div>
                            </React.Fragment>
                        ) : (
                            <div
                                className={
                                    contextPopupValues.collectionPicker.isCollectionPicker
                                        ? 'button_auto_input_click'
                                        : 'button_auto_input'
                                }
                            >
                                Select a collection
                            </div>
                        )}
                    </div>
                </div>
                <p>Select the collection data of previous actions from variable picker.</p>
                {selectedCollection && selectedCollection.value.$id.length > 0 && (
                    <>
                        <div className={'form_req_step_two_global_form _select_a_variable'} style={{ marginTop: 20 }}>
                            <div className="collection_picker_in_input_page">
                                <ContextOutputTree
                                    output={contextPopupValues.collectionPicker.selectedCollection}
                                    showOnlyCollection={{
                                        showOnlyCollection:
                                            contextPopupValues.collectionPicker.isCollectionPicker &&
                                            contextPopupValues.show,
                                        contextPopupValues: contextPopupValues
                                    }}
                                    app={contextPopupValues.collectionPicker.app}
                                    onClick={updateInputValuesWithContext}
                                    usingForInputs={true}
                                />
                            </div>
                            <div
                                className={
                                    contextPopupValues.collectionPicker.isCollectionPicker
                                        ? 'button_auto_input_click'
                                        : 'button_auto_input'
                                }
                            >
                                Collection data
                            </div>
                        </div>
                        {/* <p>
                            Use this collection data input for the actions/conditions used inside the For-Each function.
                        </p> */}
                    </>
                )}{' '}
            </div>
        </div>
    )
}
