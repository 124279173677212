/*eslint-disable*/
import * as React from 'react'
// import { workspace } from '@/Integrations/Widget';
import { QueryClient, QueryClientProvider } from 'react-query'
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { NotFoundPage } from '@/404/NotFound'
import { AgentHandoffWrapper } from '@/Assistant/AgentHandoff'
//DIALOG COMPILER IMPLEMENTAION STARTS
import AssistantConstructor from '@/Assistant/Construction'
import AssistantDialogue from '@/Assistant/Dialogue'
import { BotDetail } from '@/Assistant/Dialogue/BotDetails'
import AssistantDialogueCanvas from '@/Assistant/DialogueCrew'
import AssistantEntity from '@/Assistant/Entity'
import AssistantEntityValues from '@/Assistant/EntityCrew'
import AssistantIntent from '@/Assistant/Intent'
import AssistantIntentExamples from '@/Assistant/IntentCrew'
import { DialogSettings } from '@/Assistant/Settings/Settings'
import { ROUTER_PROPS_T } from '@/Assistant/kind'
import { Dashboard } from '@/Dashboard/Dashboard'
import { Canvas } from '@/DialogEditor/Canvas'
import { AfterBotStarted } from '@/DialogEditor/Canvas/Components/BotSelection'
import { GetStarted } from '@/DialogEditor/Canvas/Components/GetStarted'
import { DialogDetail } from '@/DialogEditor/Home/DialogDetail'
import { DialogWrapper } from '@/DialogEditor/Home/DialogWrapper'
import { DialogLogsHome } from '@/DialogEditor/Logs'
import { WatsonTraining } from '@/DialogEditor/TrainingModule/TrainingModule'
import { DialogAnalyticsHome } from '@/DialogEditor/analytics/home'
import { FlowWrapper } from '@/Flows/FlowWrapper'
import { Group } from '@/Flows/Group'
import { Run } from '@/Flows/Run'
import { FlowAnalyticsHome } from '@/Flows/analytics/home'
import { DetailedAutomationLogsListView } from '@/Flows/analytics/logs'
import { FlowCanvasWrapper } from '@/Flows/canvas/FlowCanvasWrapper'
import { MarketFlowCanvasWrapper } from '@/Flows/canvas/marketplace/MarketFlowCanvasWrapper'
import { onPremConnectionsWrapper } from '@/Flows/onPremConnectionsWrapper'
import { OnPremWrapper } from '@/Flows/onPremWrapper'
import { AppRedirector } from '@/Integrations/AppRedirector'
import { Integration } from '@/Integrations/Integration'
import { ThankYouPage } from '@/Integrations/ThankYouPage'
import { WidgetManipulationWrapper } from '@/Integrations/WidgetManipulationWrapper'
import { WidgetWrapper } from '@/Integrations/WidgetWrapper'
import { IntentExtraction } from '@/IntentExtraction/IntentExtraction'
// import { JourneyContext } from '@/Journey/JourneyMachine'
import { Journey, JourneyState, WorkspaceS } from '@/Journey/Journey'
import { RootJourneyContext, RootWorkspaceContext, UpdateJourneyProvider } from '@/Journey/JourneyContext'
import ManageUsers from '@/ManageUsers/ManageUsers'
import {
    MarketPlaceAppsLoadStateHandlerV2,
    MarketPlaceWorkflowsLoadStateHandlerV2
} from '@/MarketPlace/AutomationMarketPlace'
import { MyUploadsWrapper } from '@/MarketPlace/MyUploads'
import { Profile } from '@/Profile/Profile.Page'
import { profileDataT } from '@/Profile/types'
import { SideBarContext } from '@/SidePanel/SideBarContext'
import { Sidebar } from '@/SidePanel/Sidebar.Automate'
import { ManagePaymentWrapper } from '@/Subscription/PaymentDetails'
import { PlanWrapper } from '@/Subscription/Plans-automate'
import { AlertWrapper } from '@/Subscription/Plans-v2'
import { BillingDataWrapper, BillingEstimationWrapper, SubscriptionWrapper } from '@/Subscription/Subscription'
import SupportPage from '@/Support/index'
import { VideoContext, VideoPlayerComponent, useVideos, defaultVideoData } from '@/Videos/Videos'
import { VideosWrapper } from '@/Videos/VideosWrapper'
import { WelcomePage } from '@/Welcome/Welcome.Automate'
import { WorkSpaceRedirection } from '@/WorkspaceRedirection/WorkspaceRedirection'
/*eslint-disable*/
/*eslint-disable*/
import ReactGALazy, {
    Tracked,
    TrackedAndProtected,
    TrackedAndProtectedWithWorkspace
} from '@/common/google-analytics/tracking'
// import { ReactQueryDevtools } from 'react-query/devtools'
import { usePrevious } from '@/common/hooks/usePrevious'
import { getJson, loginApiURL, userJourneyWithWorkspace } from '@/common/utils/api-utils'
import { postJson } from '@/common/utils/axios-utils'

export const singletonQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
})

//DIALOG COMPILER IMPLEMENTAION ENDS

const initialDummyUser = {
    id: -1,
    first_name: 'dummy',
    last_name: 'user',
    email: 'dummyUser@workativ.com',
    org: null,
    role: null,
    verified: true
}

const redirectPreservingParams =
    (toPath: string) =>
    ({ location }: any) =>
        <Redirect to={{ ...location, pathname: `${location.pathname}${toPath}` }} />

const makeRemountingComponentOnRouteChange =
    (Component: (props: any) => JSX.Element, uniqueKeyFunction: (props: ROUTER_PROPS_T) => string) =>
    (props: ROUTER_PROPS_T) => {
        // console.log('remounting', props)

        // const newProps = Object.assign({ match: props.computedMatch }, props)
        return <Component key={uniqueKeyFunction(props)} {...props} />
    }

const LANDING_ROUTE = '/welcome'

const DefaultLandingRoute = redirectPreservingParams(LANDING_ROUTE)

const components = [
    //DIALOG COMPILER IMPLEMENTAION STARTS
    {
        path: '/workspace/:workspacename/dialog/homepage',
        Component: AssistantConstructor
    },
    {
        path: '/workspace/:workspacename/dialog/:assistantname/summary',
        Component: makeRemountingComponentOnRouteChange(
            BotDetail,
            (props: ROUTER_PROPS_T) => `${props.match.params.workspacename}-${props.match.params.assistantname}`
        )
    },
    {
        path: '/workspace/:workspacename/dialog/:assistantname/dialogs',
        Component: makeRemountingComponentOnRouteChange(AssistantDialogue, (props: ROUTER_PROPS_T) => {
            // console.log(props.computedMatch.params)
            // props.match = props.computedMatch
            return `${props.match.params.workspacename}-${props.match.params.assistantname}`
        })
    },
    {
        path: '/workspace/:workspacename/dialog/:assistantname/detail/:dialogue_node',
        Component: makeRemountingComponentOnRouteChange(AssistantDialogueCanvas, (props: ROUTER_PROPS_T) => {
            // console.log(props.computedMatch.params)
            // props.match = props.computedMatch
            return `${props.match.params.workspacename}-${props.match.params.assistantname}`
        })
    },
    {
        path: '/workspace/:workspacename/dialog/:assistantname/intent',
        Component: makeRemountingComponentOnRouteChange(
            AssistantIntent,
            (props: ROUTER_PROPS_T) => `${props.match.params.workspacename}-${props.match.params.assistantname}`
        )
    },
    {
        path: '/workspace/:workspacename/dialog/:assistantname/intent/:intent',
        Component: makeRemountingComponentOnRouteChange(
            AssistantIntentExamples,
            (props: ROUTER_PROPS_T) =>
                `${props.match.params.workspacename}-${props.match.params.assistantname}-${props.match.params.intent}`
        )
    },
    {
        path: '/workspace/:workspacename/dialog/:assistantname/entity',
        Component: makeRemountingComponentOnRouteChange(
            AssistantEntity,
            (props: ROUTER_PROPS_T) => `${props.match.params.workspacename}-${props.match.params.assistantname}`
        )
    },
    {
        path: '/workspace/:workspacename/dialog/:assistantname/agent-handoff',
        Component: makeRemountingComponentOnRouteChange(
            AgentHandoffWrapper,
            (props: ROUTER_PROPS_T) => `${props.match.params.workspacename}-${props.match.params.assistantname}`
        )
    },
    {
        path: '/workspace/:workspacename/dialog/:assistantname/entity/:entity',
        Component: makeRemountingComponentOnRouteChange(
            AssistantEntityValues,
            (props: ROUTER_PROPS_T) =>
                `${props.match.params.workspacename}-${props.match.params.assistantname}-${props.match.params.entity}`
        )
    },
    {
        path: '/workspace/:workspacename/dialog/:assistantname/analytics',
        Component: makeRemountingComponentOnRouteChange(
            DialogAnalyticsHome,
            (props: ROUTER_PROPS_T) => `${props.match.params.workspacename}-${props.match.params.assistantname}`
        )
    },
    {
        path: '/workspace/:workspacename/dialog/:assistantname/logs',
        Component: makeRemountingComponentOnRouteChange(
            DialogLogsHome,
            (props: ROUTER_PROPS_T) => `${props.match.params.workspacename}-${props.match.params.assistantname}`
        )
    },
    {
        path: '/workspace/:workspacename/dialog/:assistantname/settings',
        Component: makeRemountingComponentOnRouteChange(
            DialogSettings,
            (props: ROUTER_PROPS_T) => `${props.match.params.workspacename}-${props.match.params.assistantname}`
        )
    },
    {
        path: '/workspace/:workspacename/train-assistant/home',
        Component: WatsonTraining
    },

    //DIALOG COMPILER IMPLEMENTAION ENDS

    //SUBSCRIPTION IMPLEMENTAION STARTS
    {
        path: '/workspace/:workspacename/billing',
        Component: SubscriptionWrapper
    },
    {
        path: '/workspace/:workspacename/billing/plans',
        Component: PlanWrapper
    },
    {
        path: '/workspace/:workspacename/billing/history',
        Component: BillingDataWrapper
    },
    {
        path: '/workspace/:workspacename/billing/paymentdetails',
        Component: ManagePaymentWrapper
    },
    {
        path: '/workspace/:workspacename/billing/estimate',
        Component: BillingEstimationWrapper
    },

    //SUBSCRIPTION IMPLEMENTAION ENDS

    {
        path: '/workspace/:workspacename/dashboard',
        Component: Dashboard
    },
    // {
    //     path: '/workspace/:workspacename/marketplace/automation/:step(select-application|list-automations)',
    //     Component: MarketPlaceLoadStateHandler
    // },
    {
        path: '/workspace/:workspacename/marketplace/automation/select-application',
        Component: MarketPlaceAppsLoadStateHandlerV2
    },
    {
        path: '/workspace/:workspacename/marketplace/automation/list-workflows',
        Component: MarketPlaceWorkflowsLoadStateHandlerV2
    },
    {
        path: '/workspace/:workspacename/marketplace/myuploads',
        Component: MyUploadsWrapper
    },
    {
        path: '/workspace/:workspacename/marketplace/automation/detail/:id',
        Component: MarketFlowCanvasWrapper
    },
    {
        path: '/workspace/:workspacename/dialog/home',
        Component: DialogWrapper
    },
    {
        path: '/workspace/:workspacename/dialog/detail/:node',
        Component: Canvas
    },
    {
        // path: '/workspace/:workspacename/automation/view/:view(home|draft|trigger|no-trigger|active|inactive)',
        path: '/workspace/:workspacename/automation/view/:view(home|draft|active|inactive|approvals|app-trigger|user-trigger|webhook-trigger)',
        Component: FlowWrapper
    },
    {
        path: '/workspace/:workspacename/automation/group/:groupId',
        Component: Group
    },
    {
        path: '/workspace/:workspacename/automation/run/:id',
        Component: Run
    },
    {
        path: '/workspace/:workspacename/automation/detail/:id',
        Component: FlowCanvasWrapper
    },
    {
        path: '/workspace/:workspacename/automation/onprem-agent',
        Component: OnPremWrapper
    },
    {
        path: '/workspace/:workspacename/automation/onprem-agent/detail/:id',
        Component: onPremConnectionsWrapper
    },

    // {
    //     path: '/workspace/:workspacename/dialog/entity',
    //     Component: EntityWrapper
    // },
    // {
    //     path: '/workspace/:workspacename/dialog/entity/:name',
    //     Component: DetailViewPage
    // },
    // {
    //     path: '/workspace/:workspacename/dialog/intent',
    //     Component: IntentWrapper
    // },
    // {
    //     path: '/workspace/:workspacename/dialog/intent/:name',
    //     Component: ExampleRoute
    // },
    {
        path: '/workspace/:workspacename/integration/chat',
        Component: Integration
    },
    {
        path: '/workspace/:workspacename/integration/widget',
        Component: WidgetWrapper
    },
    {
        path: '/workspace/:workspacename/integration/widget/:id',
        Component: WidgetManipulationWrapper
    },
    {
        path: '/workspace/:workspacename/integration/chat/:app',
        Component: AppRedirector
    },
    {
        path: '/workspace/:workspacename/testing',
        Component: DialogDetail
    },
    {
        path: '/workspace/:workspacename/welcome',
        Component: WelcomePage
    },
    {
        path: '/workspace/:workspacename/:botId/:botName/bot-created',
        Component: AfterBotStarted
    },
    {
        path: '/workspace/:workspacename/get-started',
        Component: GetStarted
    },

    {
        path: '/workspace/:workspacename/dialog/analytics',
        Component: DialogAnalyticsHome
    },
    {
        path: '/workspace/:workspacename/dialog/logs',
        Component: DialogLogsHome
    },
    {
        path: '/workspace/:workspacename/automation/summary',
        Component: FlowAnalyticsHome
    },
    {
        path: '/workspace/:workspacename/automation/logs',
        Component: DetailedAutomationLogsListView
    },
    {
        path: '/workspace/:workspacename/integration/slack',
        Component: AppRedirector
    },
    {
        path: '/workspace/:workspacename/integration/teams',
        Component: AppRedirector
    },
    { path: '/workspace/:workspacename/profile', Component: Profile },

    {
        path: '/workspace/:workspacename/users',
        Component: ManageUsers
    },
    {
        path: '/workspace/:workspacename/support',
        Component: SupportPage
    },
    {
        path: '/workspace/:workspacename/welcome',
        Component: WelcomePage
    },
    { path: '/workspace/:workspacename/help/videos', Component: VideosWrapper },
    { path: '/workspace/:workspacename/intent-extraction', Component: IntentExtraction }
]

type GlobalAlert = {
    type: 'WARNING' | 'ALERT'
}

export function AppWithCache() {
    return (
        <QueryClientProvider client={singletonQueryClient}>
            <App />
        </QueryClientProvider>
    )
}

function App() {
    const [state, setState] = React.useState({})
    const [journeyState, setJourneyState] = React.useState<JourneyState>({
        type: 'loading'
    })

    const [rootWorkspace, setRootWorkspace] = React.useState<WorkspaceS>({
        type: 'yet-to-load'
    })


    // Videos not included for automate
    // const {
    //     videoURL,
    //     setVideoURL,
    //     lastPlayerSize,
    //     videoLoading,
    //     setVideoLoading,
    //     videosList,
    //     setVideoUrlId,
    //     playerSize,
    //     setPlayerSize,
    //     refetchVideos
    // } = useVideos({ state })

    const x = React.useRef<boolean>(false)

    const pJ = usePrevious(journeyState) as JourneyState

    const fnCallBack = React.useCallback(setJourneyState, [])

    const [isSubPanelOpen, setSubPanelOpen] = React.useState(false)
    const [userData, setUserData] = React.useState<profileDataT>(initialDummyUser)

    React.useEffect(() => {
        if (userData.id != -1) {
            ReactGALazy().set({ userId: userData.email })
            ReactGALazy().set({ dimension1: userData.email })
        }
    }, [userData])

    // console.log('PS', pJ, journeyState)
    if (pJ && pJ.type == 'loading' && journeyState.type === 'success' && x.current == false) {
        // console.log('Added subscription')
        x.current = true
        journeyState.value.subscribe((app) => {
            const value = journeyState.value.state()

            console.log('val', journeyState.value.state())
            window.localStorage.setItem(
                value.context.systemContext.workspace,
                JSON.stringify({ state: value.value, context: value.context.executionContext })
            )
            postJson(userJourneyWithWorkspace(value.context.systemContext.workspace, '/journey/update'), {
                journeyJson: { state: value.value, context: value.context.executionContext }
            })
            setJourneyState({ type: 'success', value: journeyState.value, id: Math.random() })
        })
    }

    function refetchProfileData() {
        const promse = () => getJson(loginApiURL('/user_info'))

        promse()
            .then((user) => {
                setUserData(user)
            })
            .catch((err) => {
                console.log('err')
            })
    }

    return (
        <React.Fragment>
            <UpdateJourneyProvider.Provider value={fnCallBack}>
                <RootWorkspaceContext.Provider value={rootWorkspace}>
                    <RootJourneyContext.Provider value={journeyState}>
                        <SideBarContext.Provider
                            value={{
                                isSubPanelOpen,
                                setSubPanelOpen,
                                userData,
                                refetchProfileData
                            }}
                        >
                            <VideoContext.Provider value={defaultVideoData}>
                                {!userData.verified && <AlertWrapper userData={userData} {...state} />}
                                <div
                                    id="wrapper"
                                    className={!userData.verified ? 'flow_canvas un_verified' : 'flow_canvas'}
                                >
                                    <div className="flex_canvas">
                                        {state && Object.keys(state).length > 0 && (
                                            <Sidebar {...state} setUserData={setUserData} />
                                        )}

                                        {/* app routes */}
                                        <Router>
                                            <React.Fragment>
                                                <Switch>
                                                    <Route
                                                        exact
                                                        path="/workspace/:workspacename"
                                                        component={DefaultLandingRoute}
                                                    />

                                                    <Route
                                                        exact
                                                        path="/workspace/:workspacename/journey"
                                                        setJourneyState={setJourneyState}
                                                        render={(props: any) => (
                                                            <Journey setJourneyState={setJourneyState} {...props} />
                                                        )}
                                                    />

                                                    <Route
                                                        exact
                                                        path="/workspace/:workspacename/get-started"
                                                        setJourneyState={setJourneyState}
                                                        render={(props: any) => <GetStarted {...props} />}
                                                    />

                                                    <TrackedAndProtected
                                                        setJourneyState={setJourneyState}
                                                        exact
                                                        path="/"
                                                        render={(props: any) => <WorkSpaceRedirection {...props} />}
                                                    />

                                                    {components.map(({ path, Component }: any, i: number) => (
                                                        <TrackedAndProtectedWithWorkspace
                                                            key={i}
                                                            exact
                                                            path={path}
                                                            render={(props: any) => {
                                                                // console.log('TrackedAndProtected1 ::: ', props)
                                                                return (
                                                                    <React.Fragment>
                                                                        <StatelessComponent
                                                                            parentProps={props}
                                                                            updateProps={(props) => setState(props)}
                                                                        />
                                                                        <div className="flex_canvas_right">
                                                                            <div
                                                                                className={
                                                                                    path !==
                                                                                    '/workspace/:workspacename/dialog/:assistantname/detail/:dialogue_node'
                                                                                        ? path !==
                                                                                          '/workspace/:workspacename/automation/detail/:id'
                                                                                            ? 'cogni__main'
                                                                                            : ''
                                                                                        : 'cogni__main dialog_editor_canvas'
                                                                                }
                                                                            >
                                                                                <Component {...props} />
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                )
                                                            }}
                                                        />
                                                    ))}

                                                    <Tracked
                                                        exact
                                                        path="*"
                                                        render={(props: any) => <NotFoundPage {...props} />}
                                                    />

                                                    <Tracked
                                                        exact
                                                        path="/success"
                                                        render={(props: any) => {
                                                            if (
                                                                props.location.search.includes('?code=') &&
                                                                props.location.search.includes('&state=')
                                                            ) {
                                                                return (
                                                                    <React.Fragment>
                                                                        <ThankYouPage {...props} />
                                                                    </React.Fragment>
                                                                )
                                                            } else if (
                                                                props.location.search.includes('?error=access_denied')
                                                            ) {
                                                                window.close()
                                                                return null
                                                            } else {
                                                                return (
                                                                    <Redirect
                                                                        to={{
                                                                            pathname: '/',
                                                                            state: {
                                                                                from: props.location
                                                                            }
                                                                        }}
                                                                    />
                                                                )
                                                            }
                                                        }}
                                                    />
                                                </Switch>
                                            </React.Fragment>
                                        </Router>
                                        {/* app routes */}
                                    </div>
                                </div>

                                {/* <VideoPlayerComponent
                                    {...{
                                        videoURL,
                                        playerSize,
                                        setPlayerSize,
                                        videoLoading,
                                        setVideoLoading,
                                        lastPlayerSize,
                                        setVideoURL
                                    }}
                                /> */}
                            </VideoContext.Provider>
                        </SideBarContext.Provider>
                        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                    </RootJourneyContext.Provider>
                </RootWorkspaceContext.Provider>
            </UpdateJourneyProvider.Provider>
        </React.Fragment>
    )
}

function StatelessComponent(props: { parentProps: any; updateProps: (props: any) => void }) {
    const { parentProps, updateProps } = props
    // console.log('StatelessComponent:', props)

    React.useEffect(() => {
        // const newProps = Object.assign({ match: parentProps.computedMatch }, parentProps)
        updateProps(parentProps)
    }, [props.parentProps.location])

    return null
}

export default App
