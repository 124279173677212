import styled from 'styled-components'

export const TabHeader = styled.h4`
    font-family: 'Gordita-Medium';
    font-size: 16px;
    width: auto;
    float: left;
    color: #555555;
    margin-bottom: 6px;
    line-height: 26px;
    position: relative;
    cursor: pointer;
    margin-right: 40px;
    :first-child {
        margin-right: 40px;
    }

    ${({ active }) =>
        active &&
        `
    transition: 0.3255s ease;
    :before{
        content: "";
        position: absolute;
        border-bottom: 3px solid #466AF3;
        width: 100%;
        height: 3px;
        bottom: -7px;
    }
  
    color: #466AF3;
    `}
`
export const TabRow = styled.div`
    width: 100%;
    float: left;
   position:relative;
   :before{
    content: "";
    position: absolute;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
    height: 1px;
    bottom: 0;
    right: 0px;
}
   }
`
export const TabContainer = styled.div`
    width: 100%;
    float: left;
    display: flex;
    height: 70px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 5px #00000029;
    border-radius: 10px;
    padding: 16px 20px;
    margin-bottom: 20px;
    align-items: baseline;
`
