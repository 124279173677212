/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react'

// Icons Import
import {
    AlphabetIcon,
    BooleanIcon,
    FlowCanvasContextIcons,
    NextIconCanvas,
    NumberIcon,
    RightArrowWithCircle
} from '@/common/Icons/Icons'

import { fetchRootDialog } from '../../Actions/ContextManager'
import { Environment } from '../../Actions/EnvironmentBlock'
import { FlowTreeContext, FlowTreeContextT, PageContext } from '../../Actions/ReactContext'
// Types Import
import { AutomationDialogT, RecognizeDialogT } from '../../Types/WatsonT'
// Common Components
import { DialogItem, FlowItem } from '../TreeHelper'

const Ramda = require('ramda')

// React Context Import

const DUMP_CONTEXT = [
    { value: 'SYS_FirstName', visible: 'sys_user_firstname' },
    { value: 'SYS_LastName', visible: 'sys_user_lastname' },
    // { value: 'SYS_ChatChannel', visible: 'sys_chatchannel' },

    { value: 'SYS_UserEmail', visible: 'sys_useremail' },
    { value: 'SYS_platform', visible: 'sys_platform' }
]

export const ContextChooser = () => {
    const [searchDialog, setSearchDialog] = useState<string>('')
    const [selectedDialog, setSelectedDialog] = useState<string>('')
    const [selectedFlow, setSelectedFlow] = useState<string>('')
    const [selectedFlowNode, setSelectedFlowNode] = useState<string>('')
    const [selectedAction, setSelectedAction] = useState({})
    const [output, setOutput] = useState<any>({})
    const pageContext = useContext(PageContext)

    const selectAction = async (action: any) => {
        console.log('action.meta_data.action_id:::', action.meta_data.action_id, pageContext.actionMixture)
        const result = pageContext.actionMixture[action.meta_data.action_id]
        if (result) {
            setSelectedAction(action)
            setOutput(result.output)
            console.log('resultresultresultresultresult:::', result, result.output)
        }
    }

    useEffect(() => setOutput({}), [selectedDialog, selectedFlow, selectedFlowNode])

    return (
        <FlowTreeContext.Provider
            value={
                {
                    selectedDialog,
                    selectedFlow,
                    setSelectedFlow,
                    selectedFlowNode,
                    setSelectedFlowNode,
                    selectedAction,
                    setSelectedAction,
                    output,
                    setOutput,
                    selectAction
                } as FlowTreeContextT
            }
        >
            <PageContext.Consumer>
                {({ workspaceDataWF }) => (
                    <div className="context_dilaog_popup_width">
                        <div className="context_dialog_popup_head">
                            <h3>Variable Picker</h3>
                        </div>

                        {workspaceDataWF
                            .filter((dialog: RecognizeDialogT) =>
                                searchDialog == ''
                                    ? true
                                    : dialog.title.toLowerCase().startsWith(searchDialog.toLowerCase())
                            )
                            .map((dialog: RecognizeDialogT, index: number) =>
                                dialog.dialog_node == selectedDialog ||
                                dialog.dialog_node == pageContext.currentDialog.dialog_node ? (
                                    <React.Fragment key={index}>
                                        {index == 0 ? <div className="automation_content_mt" /> : null}
                                        <DialogDetail
                                            dialog={dialog as RecognizeDialogT}
                                            index={index}
                                            onclick={() => setSelectedDialog('')}
                                        />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment key={index}>
                                        {index == 0 ? <div className="automation_content_mt" /> : null}
                                        <DialogItem
                                            dialog={dialog as RecognizeDialogT}
                                            index={index}
                                            onclick={() => setSelectedDialog(dialog.dialog_node)}
                                        />
                                    </React.Fragment>
                                )
                            )}
                    </div>
                )}
            </PageContext.Consumer>
        </FlowTreeContext.Provider>
    )
}

interface ContextListP {
    context: string[]
}

export const ContextList = ({ context }: ContextListP) => {
    const [search, setSearch] = useState<string>('')

    const EnvironmentContext = useContext(Environment)

    const onChange = (variable: any) => {
        EnvironmentContext.assignInformation({
            what: `<? $${variable.variable} ?>`,
            info: variable.variable,
            where: [variable.node.type == 'qa' ? 'Ask a question' : 'Get Details', variable.variable],
            dataType: 'string'
        })
    }

    return (
        <div className="context_popup_content_without">
            <div className="context_dailog_popup_search">
                <input type="search" value={search} onChange={e => setSearch(e.target.value)} />
            </div>
            {DUMP_CONTEXT.map(context => (
                <div className="context_popup_buton_content">
                    <div
                        className="context_popup_buton_content_span"
                        onClick={() =>
                            EnvironmentContext.assignInformation({
                                what: `<? $${context.value} ?>`,
                                where: ['Assistant', context.visible],
                                info: context.visible,
                                dataType: 'string'
                            })
                        }
                    >
                        <div className="button_auto">{context.visible}</div>
                    </div>
                </div>
            ))}

            {context
                .filter((variable: any) => variable.variable.toLowerCase().includes(search.toLowerCase()))
                .map((variable: any, index: number) => (
                    <div className="context_popup_buton_content" key={index}>
                        <div className="context_popup_buton_content_span" onClick={() => onChange(variable)}>
                            <div className="button_auto">{variable.variable}</div>
                        </div>
                    </div>
                ))}
        </div>
    )
}

interface DialogDetailP {
    dialog: RecognizeDialogT
    index: number
    onclick: Function
}

const DialogDetail = ({ dialog, index, onclick }: DialogDetailP) => {
    const [isContextVariableOpen, setIsContextVariableOpen] = useState<boolean>(false)

    return (
        <FlowTreeContext.Consumer>
            {({ selectedFlow, setSelectedFlow, setSelectedFlowNode }) => (
                <PageContext.Consumer>
                    {({ dialogContext, flowUIJson }) => (
                        <div className="context_popup_dialog_accordian_open node_output_first_child context_dialog_popup_accordian_connection_open">
                            <div className="context_dialog_join_output">
                                <h4 onClick={e => onclick()}>
                                    <span className="dialog_context_number">
                                        <span className="dialog_context_span_number">{index}</span>
                                    </span>
                                    <span className="context_path">{dialog.title}</span>
                                    <span>
                                        <div className="arrow_up_form">
                                            <RightArrowWithCircle />
                                        </div>
                                    </span>
                                </h4>

                                <div className="context_variables_head_para">
                                    <div
                                        className="context_variables_head"
                                        onClick={e => setIsContextVariableOpen(!isContextVariableOpen)}
                                    >
                                        Context Variables{' '}
                                        {dialogContext[dialog.dialog_node].length + DUMP_CONTEXT.length}
                                        <span className="context_variables_head_svg">
                                            <NextIconCanvas />
                                        </span>
                                    </div>
                                    <p>Data collected from the user through the course of the dialog till this point</p>
                                </div>

                                {isContextVariableOpen
                                    ? dialogContext[dialog.dialog_node] && (
                                          <ContextList context={dialogContext[dialog.dialog_node]} />
                                      )
                                    : null}

                                <div className="child_data_context">
                                    {flowUIJson[dialog.dialog_node] &&
                                        Object.keys(flowUIJson[dialog.dialog_node]).map(
                                            (flowDialogID: string, index: number) => {
                                                const flow = flowUIJson[dialog.dialog_node][flowDialogID]
                                                return selectedFlow == flow.id ? (
                                                    <div className="context_dialog_child_list" key={index}>
                                                        <div className="context_popup_dialog_accordian_open context_popup_dialog_accordian_open_margin_zero">
                                                            <FlowItem
                                                                flow={flow}
                                                                onClick={() => {
                                                                    setSelectedFlowNode('')
                                                                    setSelectedFlow('')
                                                                }}
                                                            />
                                                            <FlowDetails flow={flow} />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <FlowItem
                                                        key={index}
                                                        flow={flow}
                                                        onClick={() => {
                                                            setSelectedFlowNode(flowDialogID)
                                                            setSelectedFlow(flow.id)
                                                        }}
                                                    />
                                                )
                                            }
                                        )}
                                </div>
                            </div>
                        </div>
                    )}
                </PageContext.Consumer>
            )}
        </FlowTreeContext.Consumer>
    )
}

interface FlowDetailsP {
    flow: any
}

const FlowDetails = ({ flow }: FlowDetailsP) => {
    return (
        <FlowTreeContext.Consumer>
            {({ selectedAction, setSelectedAction, output, setOutput, selectAction }) => (
                <React.Fragment>
                    <div className="popup_tree dailog_canvas__dropdown_tree">
                        <div className="tree_structure_body">
                            <div className=" treeview_automatiom">
                                <div className="right_panel_ht">
                                    <ul className="tree1 treeview_automatiom_ul">
                                        <div className="dialog_tree_ml_15 ">
                                            <li className="treeview_automatiom_li_parent">
                                                <label className="notrigger_automation_label">
                                                    <div className="canvas_popup_tree_head">
                                                        <span className="notrigger_head">
                                                            <div className="button_auto button_auto_first">
                                                                <span className="dialog_context_popup_img">
                                                                    <img src={flow.custom_json.value.icon} />
                                                                </span>
                                                                <span className="dialog_context_content">
                                                                    Triggered by Chatbot
                                                                </span>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </label>
                                                <RenderChild flowList={flow.custom_json.children[0].children} />
                                            </li>
                                            <li>
                                                <label className="notrigger_automation_label">
                                                    <div className="canvas_popup_tree_head">
                                                        <span className="notrigger_head">
                                                            <div className="button_auto button_auto_first button_auto_remove_before">
                                                                <span className="dialog_context_popup_img">
                                                                    <img
                                                                        src={flow.custom_json.children[1].value.icon}
                                                                    />
                                                                </span>
                                                                <span className="dialog_context_content">
                                                                    Execution Status
                                                                </span>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </label>
                                            </li>
                                        </div>
                                    </ul>
                                    <div className="margin-bottom-75" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {output.properties ? <ActionOutput output={output} /> : null}
                </React.Fragment>
            )}
        </FlowTreeContext.Consumer>
    )
}

interface ActionOutputP {
    output: any
}

const ActionOutput = ({ output }: ActionOutputP) => {
    const [search, setSearch] = useState<string>('')
    const { properties } = output

    console.log('ActionOutput::::', properties, Object.keys(properties))

    const EnvironmentContext = useContext(Environment)
    const selectContext = useContext(FlowTreeContext)
    const wrapperContext = useContext(PageContext)

    const flowOutputClick = (value: any) => {
        const { selectedAction } = selectContext

        const flowDialogNode = wrapperContext.workspaceDataMixture
            .filter(e => e.dialog_node == selectContext.selectedFlowNode)
            .pop()

        if (flowDialogNode) {
            const automationNode: AutomationDialogT = flowDialogNode as AutomationDialogT
            const rootDialogNode = fetchRootDialog(selectContext.selectedFlowNode, wrapperContext.workspaceDataMixture)

            const dialogFlowId = automationNode.context.SYS_ACTIONS[1].data.output_variable
            const dialogFlowName = automationNode.metadata.automationName

            const actionNode = selectedAction
            const actionId = selectedAction.id

            const actionOutputPath = value.$id.split('/').slice(2)
            const what = `<? $${dialogFlowId} ? $${dialogFlowId}.output.executionContext.${actionId} ? $${dialogFlowId}.output.executionContext.${actionId}.output.${actionOutputPath
                .join('.')
                .trim()} : '' : '' ?>`
            EnvironmentContext.assignCollection({
                what,
                info: actionOutputPath.slice(actionOutputPath.length - 1).pop(),
                where: [rootDialogNode.title, dialogFlowName, actionNode.name, ...actionOutputPath],
                icon: selectedAction.icon,
                dataType: value.type
            })
        }
    }

    return (
        <React.Fragment>
            <div className="context_dailog_popup_search">
                <input type="search" value={search} onChange={e => setSearch(e.target.value)} />
            </div>
            <div className="context_popup_buton_content">
                {Object.keys(properties).map((key: string, index: number) =>
                    properties[key].properties ? (
                        <div className="context_popup_buton_content_span" key={index}>
                            {/* <p>test</p> */}
                            <RenderActionRootOutput
                                name={key}
                                value={properties[key]}
                                flowOutputClick={flowOutputClick}
                            />
                        </div>
                    ) : (
                        <div
                            className="context_popup_buton_content_span"
                            key={index}
                            onClick={event => flowOutputClick(properties[key])}
                        >
                            <span className="content_capital">{getIcon(properties[key].type)}</span>
                            <div className="button_auto">{key}</div>
                        </div>
                    )
                )}
            </div>
        </React.Fragment>
    )
}
interface RenderActionRootOutput {
    name: string
    value: any
    flowOutputClick: Function
}

const RenderActionRootOutput = ({ name, value, flowOutputClick }: RenderActionRootOutput) => (
    <div className="dialog_context_tree">
        <div className="dialog_tree_structure_body">
            <div className=" dia_content_treeview_automatiom">
                <div className="right_panel_ht_context">
                    <ul className="tree1 treeview_automatiom_ul_context">
                        <div className="treeview_automatiom_ul_hide">
                            <li className="treeview_automatiom_li_parent">
                                <label className="">
                                    <div className="dialog_context_tree_head">
                                        <h4>
                                            <span className="dialog_context_tree_head_left_svg">
                                                <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 43 16">
                                                    <circle cx="11" cy="8" r="4" />
                                                    <circle cx="22" cy="8" r="4" />
                                                    <circle cx="33" cy="8" r="4" />
                                                </svg>
                                            </span>
                                            {name}
                                            <span className="popup_tree_head_right_svg">
                                                <FlowCanvasContextIcons.DownFatArrow />
                                            </span>
                                        </h4>
                                    </div>
                                </label>
                                <RenderActionOutput properties={value.properties} flowOutputClick={flowOutputClick} />
                            </li>
                        </div>
                    </ul>
                    <div className="margin-bottom-75" />
                </div>
            </div>
        </div>
    </div>
)
const getIcon = (type: string) => {
    if (type == 'string') return <AlphabetIcon />
    else if (type == 'number') return <NumberIcon />
    else if (type == 'boolean') return <BooleanIcon />
    else if (type == 'date')
        return (
            <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 16 16">
                <g>
                    <path
                        className="st_cal"
                        d="M11.13,3.35c0.56,0,1.01-0.45,1.01-1.01V1.29c0-0.56-0.45-1.01-1.01-1.01s-1.01,0.45-1.01,1.01v1.06
    C10.12,2.9,10.58,3.35,11.13,3.35z"
                    />
                    <path
                        className="st_cal"
                        d="M14.72,2.03h-2.03v0.51c0,0.85-0.69,1.54-1.54,1.54c-0.85,0-1.54-0.69-1.54-1.54V2.03h-4.1v0.51
    c0,0.85-0.69,1.54-1.54,1.54S2.44,3.39,2.44,2.54V2.03L0.38,2v12.84h1.03h12.3l1.02,0L14.72,2.03z M13.71,13.82H1.41v-8.2h12.3
    L13.71,13.82L13.71,13.82z"
                    />
                    <path
                        className="st_cal"
                        d="M3.96,3.35c0.56,0,1.01-0.45,1.01-1.01V1.29c0-0.56-0.45-1.01-1.01-1.01S2.95,0.73,2.95,1.29v1.06
    C2.95,2.9,3.4,3.35,3.96,3.35z"
                    />
                    <rect x="5.51" y="6.72" className="st_cal" width="1.62" height="1.43" />
                    <rect x="8.13" y="6.72" className="st_cal" width="1.62" height="1.43" />
                    <rect x="10.55" y="6.72" className="st_cal" width="1.62" height="1.43" />
                    <rect x="5.51" y="9.08" className="st_cal" width="1.62" height="1.43" />
                    <rect x="8.13" y="9.08" className="st_cal" width="1.62" height="1.43" />
                    <rect x="10.55" y="9.08" className="st_cal" width="1.62" height="1.43" />
                    <rect x="5.51" y="11.5" className="st_cal" width="1.62" height="1.43" />
                    <rect x="2.95" y="9.08" className="st_cal" width="1.61" height="1.43" />
                    <rect x="2.95" y="11.5" className="st_cal" width="1.61" height="1.43" />
                    <rect x="8.13" y="11.5" className="st_cal" width="1.62" height="1.43" />
                    <rect x="10.55" y="11.5" className="st_cal" width="1.62" height="1.43" />
                </g>
            </svg>
        )
    return null
}

interface RenderActionOutput {
    properties: any
    flowOutputClick: Function
}

const RenderActionOutput = ({ properties, flowOutputClick }: RenderActionOutput) => {
    return (
        <ul>
            {Object.keys(properties).map((key: string, index: number) => (
                <li
                    className={
                        properties[key].properties
                            ? 'treeview_automatiom_li treeview_automatiom_li_nested'
                            : 'treeview_automatiom_li'
                    }
                    key={index}
                >
                    {properties[key].properties ? (
                        <RenderActionRootOutput
                            name={properties[key].title}
                            value={properties[key]}
                            flowOutputClick={flowOutputClick}
                        />
                    ) : (
                        <label className="treeview_automatiom_label" onClick={() => flowOutputClick(properties[key])}>
                            <div className="button_auto test1">{properties[key].title}</div>
                            <span className="letter_num">{getIcon(properties[key].type)}</span>
                        </label>
                    )}
                </li>
            ))}
        </ul>
    )
}

interface RenderChildP {
    flowList: any[]
}

const RenderChild = ({ flowList }: RenderChildP) => {
    const [expandIds, setExpandIds] = useState<string[]>(flowList.map(e => e.value.id))
    const pageContext = useContext(PageContext)

    return (
        <FlowTreeContext.Consumer>
            {({ selectAction, selectedAction }) => (
                <ul>
                    {flowList.map((flow: any, index: number) => (
                        <li className="treeview_automatiom_li" key={index}>
                            <label
                                className="treeview_automatiom_label"
                                style={
                                    flow.value.kind === 'ErrorHandlingCondition'
                                        ? {
                                              pointerEvents: 'none'
                                          }
                                        : {}
                                }
                            >
                                <div
                                    className={
                                        pageContext.actionMixture[flow.value.meta_data.action_id]
                                            ? `button_auto ${
                                                  selectedAction.id == flow.value.id
                                                      ? 'input_selected_dialog_context'
                                                      : ''
                                              }`
                                            : `button_auto no_click ${
                                                  selectedAction.id == flow.value.id ? 'input_selected_dialog' : ''
                                              }`
                                    }
                                    onClick={() => {
                                        if (flow.value.kind !== 'ErrorHandlingCondition') {
                                            selectAction(flow.value)
                                        }
                                    }}
                                >
                                    <span className="dialog_context_popup_img">
                                        <img src={flow.value.icon} />
                                    </span>
                                    <span className="dialog_context_content">{flow.value.name}</span>

                                    {flow.children.length > 0 ? (
                                        expandIds.includes(flow.value.id) ? (
                                            <span
                                                className="dialog_context_popup_svg onclick_downarrow"
                                                onClick={e => setExpandIds(ids => ids.filter(e => e != flow.value.id))}
                                            >
                                                <FlowCanvasContextIcons.NextIcon />
                                            </span>
                                        ) : (
                                            <span
                                                className="dialog_context_popup_svg"
                                                onClick={e => setExpandIds(ids => ids.concat(flow.value.id))}
                                            >
                                                <FlowCanvasContextIcons.NextIcon />
                                            </span>
                                        )
                                    ) : null}
                                </div>
                            </label>
                            {expandIds.includes(flow.value.id) && flow.children && flow.children.length > 0 ? (
                                <RenderChild flowList={flow.children} />
                            ) : null}
                        </li>
                    ))}
                </ul>
            )}
        </FlowTreeContext.Consumer>
    )
}
