/*eslint-disable*/
import { EditorState, RawDraftEntityRange, convertFromRaw, convertToRaw } from 'draft-js'
import produce from 'immer'
import * as R from 'ramda'
import * as ramda from 'ramda'
import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import Tooltip from 'react-tooltip-lite'

import { VideoLinkSimple } from '@/Videos/Videos'
import { DownarrowWithCircle, InfoIcon, QaIconWithCircle } from '@/common/Icons/Icons'
import { NoTriggerVariable } from '@/common/Icons/Workflow.Icons'
import { Error } from '@/common/components/ErrorBoundary'
import { Loader } from '@/common/components/Loader'
import {
  Button,
  ButtonContainer,
  EmptyDiv,
  Wrapper as FunctionWrapper,
  Header,
  HeaderLeft,
  HeaderRight,
  InputContainer,
  InputContent,
  InputWrapper,
  Input as Inputfield,
  LinkTag,
  Paragraph,
  PopupContainer,
  PopupScrollHeight,
  PopupWrapper
} from '@/common/styled/Workflow.Dumb'

import { automationAPIURLWithWorkspace, getJson } from '@/common/utils/api-utils'
import { CancelButtonInput } from './ActionPanel'
import { SidePanelContext } from './Canvas'
import { CanvasRightPanel } from './CanvasRightPanel'
import { CommonInputs } from './CommonInputs'
import { ContextPopup, configApiCall } from './ContextPopup'
import { decorator } from './InputandVariable'
import {
  ActionInputsListT,
  AuthType,
  ContextPopupStateT,
  FunctionInitialState,
  FunctionResponseT,
  FunctionsListT,
  InputFieldT,
  NodeAddT,
  NodeEditT,
  NodeMode,
  NodeModes,
  NodeT,
  NodeView,
  PopupError,
  ResponseOutput,
  RightPanelStateContext,
  Tree,
  TreeT,
  emptyInputSchema,
  removeValuesToMultipleInputs,
  updateNode
} from './types'
import {
  InsertContext,
  checkForParticularValueinInput,
  configKinds,
  converToEditorFormat,
  convertAsaSingleString,
  convertEditorStateDatatoVariables,
  convertToServerFormat2,
  emptyEditorState,
  getInputsInNestedLevel,
  grouping,
  reduceArrayToString,
  reduceMultipleInputs,
  setContextValuesToMultipleInputs,
  setEmptyContextValuesToMultipleInputs,
  sortAnArrayAlphabetically,
  updateValueUsingLens
} from './utils'
import { getProductId } from '@/common/utils/utils'
import { useGenericCachedRun } from '@/common/hooks/useCachedQuery'

const HtmlParser = require('html-react-parser')

export type FunctionPanelStateT = {
  show: boolean
  parentNode: TreeT<NodeT>
  mode: NodeModes
  currentNode: FunctionResponseT
}

export type FunctionPanelStateContextT = {
  functionPanelState: FunctionPanelStateT
  setFunctionPanelState: (object: any) => void
  setContextPopupValues: (object: any) => void
  contextPopupValues: ContextPopupStateT
  userSwitchedApp: boolean
  setUserSwitchedApp: (switched: boolean) => void
}

export const FunctionPanelStateContext = createContext<FunctionPanelStateContextT>({
  functionPanelState: {
    show: false,
    mode: NodeAddT,
    parentNode: { value: Tree, children: [] },
    currentNode: FunctionInitialState
  },
  setFunctionPanelState: (object: any) => undefined,
  setContextPopupValues: (object: any) => undefined,
  contextPopupValues: {
    show: false,
    inputWithValues: [],
    currentInput: '',
    isMultiple: { path: '', parentKey: '' },
    collectionPicker: {
      isCollectionPicker: false,
      collectionType: emptyInputSchema,
      selectedCollection: {
        name: '',
        value: {
          $id: '',
          type: 'collection',
          item: {
            type: 'object',
            properties: {}
          },
          title: '',
          properties: {}
        }
      },
      app: ResponseOutput
    }
  },
  userSwitchedApp: false,
  setUserSwitchedApp: (switched: boolean) => undefined
})

export const FunctionPanel = (props: any) => {
  const { rightPanelState } = useContext(SidePanelContext)

  const [functionPanelState, setFunctionPanelState] = useState({
    ...rightPanelState,
    currentNode: rightPanelState.currentNode as FunctionResponseT
  })

  const [contextPopupValues, setContextPopupValues] = useState<ContextPopupStateT>({
    show: false,
    inputWithValues: [],
    currentInput: '',
    isMultiple: { path: '', parentKey: '' },
    collectionPicker: {
      isCollectionPicker: false,
      collectionType: emptyInputSchema,
      selectedCollection: {
        name: '',
        value: {
          $id: '',
          type: 'collection',
          item: {
            type: 'object',
            properties: {}
          },
          title: '',
          properties: {}
        }
      },
      app: ResponseOutput
    }
  })

  const [userSwitchedApp, setUserSwitchedApp] = useState(false)

  return (
    <FunctionPanelStateContext.Provider
      value={{
        functionPanelState,
        setFunctionPanelState,
        contextPopupValues,
        setContextPopupValues,
        userSwitchedApp,
        setUserSwitchedApp
      }}
    >
      <FunctionActualComponent {...props} />
    </FunctionPanelStateContext.Provider>
  )
}

const FunctionActualComponent = (props: any) => {
  // for comparing props
  const FunctionElement = useRef<any>(null)

  const { rightPanelState, setRightPanelState, saveTree, treeData, hasLinkedDialogs, active_automation, automationMeta } = useContext(SidePanelContext)

  const {
    functionPanelState,
    setFunctionPanelState,
    contextPopupValues,
    setContextPopupValues,
    userSwitchedApp,
    setUserSwitchedApp
  } = useContext(FunctionPanelStateContext)

  const currentNode = functionPanelState.currentNode as FunctionResponseT
  const workspaceName = props.match.params.workspacename

  // const [unfiltertedAppListCache, isFetching] = useActionsCached(props.match.params.workspacename)

  const actionFetcher = useGenericCachedRun<FunctionsListT>('actions', () =>
    getJson(automationAPIURLWithWorkspace(workspaceName, '/actions'))
  )

  const [loading, setLoading] = useState(true)

  const isAssistant = getProductId() === 'ASSISTANT'

  useEffect(() => {
    const currentMode = rightPanelState.mode

    // compare props and update changes based on following condition
    if (
      !FunctionElement.current ||
      (FunctionElement.current &&
        (rightPanelState.mode.mode !== FunctionElement.current.mode.mode ||
          (currentMode.mode === NodeMode.Edit &&
            currentMode.nodeEditInput.id !== FunctionElement.current.mode.nodeEditInput.id)))
    ) {
      if (currentMode.mode === NodeMode.Add || currentMode.mode === NodeMode.Edit) {
        // next time to compare with previous props
        FunctionElement.current = rightPanelState

        //show loader while changing modes (such as edit->add or add->edit)
        setLoading(true)

        // update current action panel context state for further child usage
        setFunctionPanelState({
          ...rightPanelState,
          currentNode: rightPanelState.currentNode as FunctionResponseT
        })

        const mode = currentMode

        // call initial API everytime mode changed
        callFunctionstAPI({
          ...rightPanelState,
          currentNode:
            mode.mode == NodeMode.Edit
              ? ({
                ...rightPanelState.currentNode,
                subflowMetaData:
                  mode.nodeEditInput.meta_data && mode.nodeEditInput.meta_data.subflow
                    ? { subflow: mode.nodeEditInput.meta_data.subflow }
                    : { subflow: { hasSubFlow: false } }
              } as FunctionResponseT)
              : (rightPanelState.currentNode as FunctionResponseT)
        })

        setUserSwitchedApp(false)
      } else {
        FunctionElement.current = rightPanelState
        currentMode.mode === NodeMode.Loading ? setLoading(true) : setLoading(false)

        setFunctionPanelState({
          ...rightPanelState,
          currentNode: rightPanelState.currentNode as FunctionResponseT
        })
      }
    } else if (
      rightPanelState.mode.mode === NodeMode.Edit &&
      FunctionElement.current.mode.mode === NodeMode.Edit &&
      rightPanelState.mode.nodeEditInput.id === FunctionElement.current.mode.nodeEditInput.id
    ) {
      setFunctionPanelState({
        ...functionPanelState,
        show: true
      })
    }
  }, [rightPanelState.mode])

  // to change FunctionElement.current values while updating state in each step
  useEffect(() => {
    FunctionElement.current = functionPanelState

    // to see the details in left side panel while adding or editing
    setRightPanelState({ ...rightPanelState, currentNode: functionPanelState.currentNode })
  }, [functionPanelState.currentNode])

  // called when the popup mode is add and after action selected
  useEffect(() => {
    ; (functionPanelState.mode.mode !== NodeMode.Edit || userSwitchedApp) &&
      currentNode.selectedFunction.function_id &&
      currentNode.selectedFunction.function_id.length > 0 &&
      getConfigDetails(currentNode.selectedFunction.function_id, functionPanelState)
  }, [currentNode.selectedFunction.function_id])

  // -------------- Api call --------------
  async function callFunctionstAPI(state: FunctionPanelStateT) {
    const workspaceName = props.match.params.workspacename
    // await getJson(automationAPIURLWithWorkspace(workspaceName, '/actions'))
    await actionFetcher()
      .then((functionsList: FunctionsListT) => {
        if (NodeMode.Edit === state.mode.mode) {
          const currentNode = state.currentNode as FunctionResponseT

          const mode = state.mode as NodeEditT

          const selectedFunction = functionsList.output.filter(
            x => x.id === mode.nodeEditInput.meta_data.action_id
          )

          const nextState = produce(currentNode, draftState => {
            draftState.functionsList = functionsList
            draftState.selectedFunction = {
              description: selectedFunction[0].description ? selectedFunction[0].description : '',
              appName: selectedFunction[0].appName,
              name: selectedFunction[0].action,
              id: selectedFunction[0].appId,
              function_id: selectedFunction[0].id,
              version: selectedFunction[0].version
            }
          })

          setFunctionPanelState({
            ...state,
            currentNode: nextState
          })

          getConfigDetails(selectedFunction[0].id, {
            ...state,
            currentNode: nextState
          })
        } else {
          const currentNode = state.currentNode as FunctionResponseT

          const nextState = produce(currentNode, draftState => {
            draftState.functionsList = functionsList
          })

          setFunctionPanelState({
            ...state,
            currentNode: nextState
          })

          setLoading(false)
        }
      })
      .catch(err => {
        setLoading(false)

        setFunctionPanelState({
          ...state,
          mode: {
            ...PopupError,
            error: err.response
              ? err.response.data.error
              : 'Something went wrong!<br /> Please try again afer sometime.'
          }
        })
      })
  }

  async function getConfigDetails(actionId: string, state: FunctionPanelStateT) {
    const workspaceName = props.match.params.workspacename

    await getJson(automationAPIURLWithWorkspace(workspaceName, `/actions/${actionId}/config`))
      .then((responseData: ActionInputsListT) => {
        if (state.mode.mode === NodeMode.Edit) {
          if (
            !userSwitchedApp &&
            functionPanelState.parentNode.value.path &&
            functionPanelState.parentNode.value.path.length > 0
          ) {
            const triggerNodeOutput = treeData.tree.value

            const parentNode =
              triggerNodeOutput.kind === 'AppTrigger' || triggerNodeOutput.kind === 'EventTrigger'
                ? ({
                  ...state.parentNode,
                  value: {
                    ...state.parentNode.value,
                    path: state.parentNode.value.path
                      ? [
                        {
                          actionId: triggerNodeOutput.meta_data.action_id,
                          nodeId: triggerNodeOutput.id
                        }
                      ].concat(state.parentNode.value.path)
                      : [
                        {
                          actionId: triggerNodeOutput.meta_data.action_id,
                          nodeId: triggerNodeOutput.id
                        }
                      ]
                  }
                } as TreeT<NodeT>)
                : state.parentNode

            configApiCall(
              state.currentNode.functionsList as any,
              parentNode,
              rightPanelState.mode.mode === NodeMode.Edit,
              workspaceName,
              currentNode.addingInsideForEach
            )
              .then((configResponse: ActionInputsListT[]) => {
                const mode = state.mode as NodeEditT

                let combiningResponseWithTriggerOutput: any = []

                if (
                  triggerNodeOutput.kind !== 'AppTrigger' &&
                  triggerNodeOutput.kind !== 'EventTrigger'
                ) {
                  const triggerOutputResponse: ActionInputsListT[] = [
                    {
                      type: 'success',
                      output: {
                        name: triggerNodeOutput.name,
                        input: [],
                        app: {
                          appName: triggerNodeOutput.name,
                          action: triggerNodeOutput.kind,
                          pathId: {
                            nodeId: triggerNodeOutput.id,
                            actionId: ''
                          },
                          appId: '',
                          createdDate: '',
                          type: 'trigger',
                          file: '',
                          authentication_type: AuthType.None,
                          id: '',
                          scope: '',
                          icon: NoTriggerVariable,
                          latest: false,
                          version: ''
                        },
                        output: triggerNodeOutput.output
                      }
                    }
                  ]
                  combiningResponseWithTriggerOutput = triggerOutputResponse.concat(configResponse)
                } else {
                  combiningResponseWithTriggerOutput = configResponse
                }

                const addedContextValue = produce(state, draft => {
                  mode.nodeOutputConfig = combiningResponseWithTriggerOutput
                  draft.mode = mode
                })

                const inputs = getInputsInNestedLevel(mode.nodeEditInput.input as InputFieldT[]).filter(
                  input =>
                    input.config.kind == 'text' ||
                    input.config.kind == 'password' ||
                    input.config.kind == 'collection-mapper' ||
                    input.config.kind == 'collection-picker-single'
                )

                const configIinputs = getInputsInNestedLevel(responseData.output.input as InputFieldT[])
                // console.log(inputs, '')
                const panelState = produce(addedContextValue, draft => {
                  const input = mode.nodeEditInput.input as InputFieldT[]

                  draft.currentNode.functionInputsList.output.input = input.map((x: InputFieldT) => {
                    const { value } = x

                    if (x.config.kind == 'multiple') {
                      const items = Object.values(x.config.items) as InputFieldT[]

                      return {
                        ...x,
                        config: {
                          ...x.config,
                          items: setContextValuesToMultipleInputs(
                            items,
                            combiningResponseWithTriggerOutput,
                            triggerNodeOutput,
                            x,
                            {
                              inputs: inputs as InputFieldT[],
                              contextPopupValues,
                              setContextPopupValues
                            },
                            configIinputs
                          ) as any
                        }
                      }
                    } else if (configKinds.some(value => value === x.config.kind)) {
                      const obj = configIinputs.find(y => x.key == y.key) as InputFieldT
                      // console.log({ ...obj, value }, '{ ...obj, value }')
                      return { ...obj, value }
                    } else if (value) {
                      // console.log(x.path)
                      const obj = configIinputs.find(y => x.key == y.key) as InputFieldT

                      return {
                        ...obj,
                        value: converToEditorFormat(
                          value as string,
                          combiningResponseWithTriggerOutput,
                          triggerNodeOutput,
                          x,
                          {
                            inputs: inputs as InputFieldT[],
                            contextPopupValues,
                            setContextPopupValues
                          }
                        ) as EditorState,
                        inputHasValue: true
                      }
                    } else {
                      return { ...x, value: emptyEditorState as any }
                    }
                  }) as InputFieldT[]

                  draft.currentNode.combiningResponseWithTriggerOutput = combiningResponseWithTriggerOutput
                })

                setFunctionPanelState(panelState)
                setLoading(false)
              })
              .catch(err => {
                setLoading(false)

                setFunctionPanelState({
                  ...rightPanelState,
                  mode: {
                    ...PopupError,
                    error: 'Something went wrong!<br /> Please try again afer sometime.'
                  }
                })
              })
          } else {
            const nextState = produce(currentNode, draftState => {
              draftState.functionInputsList.output.input = responseData.output.input.map(x => {
                if (x.config.kind == 'multiple') {
                  const items = Object.values(x.config.items) as InputFieldT[]

                  return {
                    ...x,
                    config: {
                      ...x.config,
                      items: setEmptyContextValuesToMultipleInputs(items) as any
                    }
                  }
                } else if (configKinds.some(value => value === x.config.kind)) {
                  return x
                } else {
                  return { ...x, value: emptyEditorState as any }
                }
              })
            })

            setFunctionPanelState({
              ...functionPanelState,
              currentNode: nextState
            })
          }
        } else {
          let combiningResponseWithTriggerOutput: any = []

          const triggerNodeOutput = treeData.tree.value

          const parentNode =
            triggerNodeOutput.kind === 'AppTrigger' || triggerNodeOutput.kind === 'EventTrigger'
              ? ({
                ...state.parentNode,
                value: {
                  ...state.parentNode.value,
                  path: state.parentNode.value.path
                    ? [
                      {
                        actionId: triggerNodeOutput.meta_data.action_id,
                        nodeId: triggerNodeOutput.id
                      }
                    ].concat(state.parentNode.value.path)
                    : [
                      {
                        actionId: triggerNodeOutput.meta_data.action_id,
                        nodeId: triggerNodeOutput.id
                      }
                    ]
                }
              } as TreeT<NodeT>)
              : state.parentNode

          configApiCall(
            state.currentNode.functionsList as any,
            parentNode,
            rightPanelState.mode.mode === NodeMode.Edit,
            workspaceName,
            currentNode.addingInsideForEach
          ).then((configResponse: ActionInputsListT[]) => {
            const mode = state.mode as NodeEditT

            if (triggerNodeOutput.kind !== 'AppTrigger' && triggerNodeOutput.kind !== 'EventTrigger') {
              const triggerOutputResponse: ActionInputsListT[] = [
                {
                  type: 'success',
                  output: {
                    name: triggerNodeOutput.name,
                    input: [],
                    app: {
                      appName: triggerNodeOutput.name,
                      action: triggerNodeOutput.kind,
                      pathId: {
                        nodeId: triggerNodeOutput.id,
                        actionId: ''
                      },
                      appId: '',
                      createdDate: '',
                      type: 'trigger',
                      file: '',
                      authentication_type: AuthType.None,
                      id: '',
                      scope: '',
                      icon: NoTriggerVariable,
                      latest: false,
                      version: ''
                    },
                    output: triggerNodeOutput.output
                  }
                }
              ]
              combiningResponseWithTriggerOutput = triggerOutputResponse.concat(configResponse)
            } else {
              combiningResponseWithTriggerOutput = configResponse
            }
            const nextState = produce(currentNode, draftState => {
              draftState.functionInputsList.output.input = responseData.output.input.map(x => {
                if (x.config.kind == 'multiple') {
                  const items = Object.values(x.config.items) as InputFieldT[]

                  return {
                    ...x,
                    config: {
                      ...x.config,
                      items: setEmptyContextValuesToMultipleInputs(items) as any
                    }
                  }
                } else if (configKinds.some(value => value === x.config.kind)) {
                  return x
                } else {
                  return { ...x, value: emptyEditorState as any }
                }
              })
              draftState.combiningResponseWithTriggerOutput = combiningResponseWithTriggerOutput
            })

            setFunctionPanelState({
              ...functionPanelState,
              currentNode: nextState
            })
          })
        }
      })
      .catch(err => {
        setLoading(false)

        setFunctionPanelState({
          ...rightPanelState,
          mode: {
            ...PopupError,
            error: err.response
              ? err.response.data.error
              : 'Something went wrong!<br /> Please try again afer sometime.'
          }
        })
      })
  }

  const SidePanelContextValue = useContext(SidePanelContext)

  // to show loading whenever mode changed
  if (loading) {
    return (
      <div className="automation_canvas_right">
        <Wrapper>
          <div className="popup_inside_loader">
            <Loader.PopupLoader show={true} />
          </div>
        </Wrapper>
      </div>
    )
  } else if (functionPanelState.mode.mode === NodeMode.Error) {
    return (
      <div className="automation_canvas_right">
        <Wrapper>
          <div className="flow_canvas_popup_error">
            <Error.AddorEdit
              info={functionPanelState.mode.error}
              onClick={() => {
                cancelCurrentPopup({
                  functionPanelState,
                  SidePanelContext: SidePanelContextValue
                })
              }}
            />
          </div>
        </Wrapper>
      </div>
    )
  }

  // disable or enable save button
  function enableSave() {
    function hasValue(currentValue: any) {
      // to check whether if any context has been selected for this input then enable save
      if (
        currentValue.config.kind == 'hidden' ||
        (currentValue.config.kind == 'switch' && currentValue.config.id == 'allow_numbering')
      ) {
        return true
      } else if (configKinds.some(value => value === currentValue.config.kind)) {
        return currentValue.value.length > 0
      }
      return (
        currentValue.value.getCurrentContent &&
        convertAsaSingleString(convertToRaw(currentValue.value.getCurrentContent()).blocks).replace(/\s/g, '')
          .length
      )
    }

    const input = getInputsInNestedLevel(currentNode.functionInputsList.output.input as InputFieldT[])

    const comparingInput = input.filter((val: InputFieldT) => val.required) as InputFieldT[]

    const filteredInput = input
      .map(val => {
        if (val.required && val.value && hasValue(val)) {
          return val
        }
        return null
      })
      .filter(Boolean)

    return (
      currentNode.selectedFunction.id.length > 0 &&
      currentNode.functionInputsList.output.input.length > 0 &&
      filteredInput.length === comparingInput.length
    )
  }

  function isInputhaveDifferentValues() {
    if (functionPanelState.mode.mode === NodeMode.Edit) {
      const mode = functionPanelState.mode
      console.log(mode, "mode")
      function hasValue(currentValue: any, i: any) {
        const nodeEditInput = getInputsInNestedLevel((mode as NodeEditT).nodeEditInput.input as InputFieldT[])
        console.log(currentValue, "currentValue", i)
        if (
          currentValue.config.kind == 'hidden'
        ) {
          return false
        } else if (configKinds.some(value => value === currentValue.config.kind)) {
          return currentValue.value != (nodeEditInput[i] as InputFieldT).value
        }
        const value = currentValue.value
        const convertedValue = value
          ? (typeof value == 'string' || Array.isArray(value))
            ? value
            : convertAsaSingleString(convertToRaw(value.getCurrentContent()).blocks)
          : ''
        if (!value || convertedValue.length === 0) {
          return false
        }

        const convertedBlocks =
          (typeof value == 'string' || Array.isArray(value))
            ? value
            : convertToRaw(value.getCurrentContent())
              .blocks.map(b =>
                convertToServerFormat2(convertToRaw(value.getCurrentContent()).entityMap, b)
              )
              .reduce((acc, ele, i) => (i == 0 ? (acc += ele) : (acc += '\n' + ele)), '')
        return currentValue.value ? convertedBlocks != (nodeEditInput[i] as InputFieldT).value : false
      }

      const input = getInputsInNestedLevel(currentNode.functionInputsList.output.input as InputFieldT[])


      const AllrequiredFieldsHasValue = (input ? input : []).some(hasValue)

      return AllrequiredFieldsHasValue
    } else {
      return true
    }
  }

  const replaceBetween = (str: string, start: number, end: number, what: string, isLast: boolean) => {
    // console.log(
    //     str.substring(0, start) + what + str.substring(start + (what.length ? what.length - 1 : 0), str.length)
    // )
    // return isLast
    //     ? str.substring(0, start) + what
    //     : str.substring(0, start) + what + str.substring(start + what.length - 1, str.length)
    return str.substring(0, start) + what + str.substring(start + (what.length ? what.length - 1 : 0), str.length)
  }

  const replaceBetween2 = (str: string, start: number, end: number, what: string, isLast: boolean) => {
    const replaced = isLast
      ? str.substring(0, start) + what + str.substring(end)
      : str.substring(0, start) + what + str.substring(end)
    // console.log('replace between', str, start, end, isLast, str.substring(0, start), 'what', str.substring(end))
    // console.log('replaced', replaced)
    return replaced
  }

  function updateVariable(value: any, text: any, old: any) {
    const inpValue = convertToRaw(value.getCurrentContent())
    let omittedMap = {}
    // items not needed to edit in omitted map
    const newMap = Object.entries(inpValue.entityMap).reduce((acc: any, curr: any) => {
      // console.log(curr, 'curr')
      if ((curr[1].data.variable ? curr[1].data.variable : curr[1].data.value) != old.title) {
        const { [`${curr[0]}`]: omit, ...rest } = acc
        omittedMap[`${curr[0]}`] = omit
        return rest
      }
      curr[1].data.path[curr[1].data.path.length - 1] = text.title

      acc[`${curr[0]}`] = curr[1].data.variable
        ? {
          ...curr[1],
          data: { ...curr[1].data, variable: text.title }
        }
        : {
          ...curr[1],
          data: { ...curr[1].data, value: text.title, visible: text.title }
        }
      return acc
    }, inpValue.entityMap)

    const minus = text.title.length < old.title.length

    const newLength = minus ? old.title.length - text.title.length : text.title.length - old.title.length
    let itemsProcessed = 0
    const newBlocks = inpValue.blocks.map(b => {
      const filtered = b.entityRanges.filter(e => {
        return Object.keys(newMap).indexOf(e.key.toString()) != -1
      })

      // console.log(filtered, 'filtered', newMap, 'b.entityRanges', b.entityRanges, value, text, old)
      return {
        ...b,
        text: filtered.reduce((acc, curr, i) => {
          if (i == 0) {
            // console.log(
            //     acc,
            //     curr.offset,
            //     old.title.length,
            //     text.title,
            //     'replace 1',
            //     replaceBetween2(acc, curr.offset, old.title.length, text.title, filtered.length == i + 1)
            // )
            const start = curr.offset - (old.title.length - text.title.length) * i
            return replaceBetween2(
              acc,
              curr.offset,
              start + old.title.length,
              text.title,
              filtered.length == i + 1
            )
          } else {
            // console.log(
            //     replaceBetween2(
            //         acc,
            //         minus ? curr.offset - newLength : curr.offset + newLength,
            //         old.title.length,
            //         text.title,
            //         filtered.length == i + 1
            //     ),
            //     'replace'
            // )
            const start = curr.offset - (old.title.length - text.title.length) * i
            return replaceBetween2(
              acc,
              start,
              start + old.title.length,
              text.title,
              filtered.length == i + 1
            )

            // return acc.substr(curr.offset - text.title.length, text.title.length)
          }
        }, b.text),
        entityRanges: b.entityRanges
          .reduce((acc: any, curr: any, i: number) => {
            // if (Object.keys(newMap).indexOf(curr.key.toString()) != -1) {
            //     console.log('update', curr, b.entityRanges)
            //     return acc.concat(curr)
            // }
            // console.log(
            //     acc,
            //     Object.keys(newMap)
            //         .map(x => parseInt(x))
            //         .filter(x => x >= curr.key),
            //     curr,
            //     'acc'
            // )
            if (
              Object.keys(newMap)
                .map(x => parseInt(x))
                .some(x => curr.key >= x)
            ) {
              if (Object.keys(newMap).indexOf(curr.key.toString()) != -1) {
                const newOffsetDifference =
                  itemsProcessed *
                  (Object.keys(newMap).length > 0 ? old.title.length - text.title.length : 0)
                // console.log( "entity itemsProcessed is not::" ,itemsProcessed,newOffsetDifference)
                itemsProcessed = itemsProcessed + 1
                return acc.concat({
                  ...curr,
                  offset: i == 0 ? curr.offset : curr.offset - newOffsetDifference,
                  length: curr.length - (old.title.length - text.title.length)
                })
              } else {
                const newOffsetDifference =
                  itemsProcessed *
                  (Object.keys(newMap).length > 0 ? old.title.length - text.title.length : 0)
                // console.log( "entity itemsProcessed is::" ,itemsProcessed,newOffsetDifference)
                return acc.concat({
                  ...curr,
                  offset: i == 0 ? curr.offset : curr.offset - newOffsetDifference,
                  length: curr.length
                })
              }
            }

            return acc.concat(curr)
          }, [])
          .filter((x: any) => x.length > 0)
        // .filter(e => Object.keys(newMap).indexOf(e.key.toString()) != -1)
        // .map((r, i) => ({
        //     ...r,
        //     offset: i == 0 ? r.offset : minus ? r.offset - newLength : r.offset + newLength,
        //     length: minus ? r.length - newLength : r.length + newLength
        // }))
      }
    })
    // console.log(
    //     inpValue,
    //     'inpValue :::',
    //     {
    //         blocks: newBlocks,
    //         entityMap: { ...omittedMap, ...newMap }
    //     },
    //     inpValue
    // )

    // console.log(
    //     inpValue,
    //     'entity :::',
    //     { ...omittedMap, ...newMap },
    //     inpValue.entityMap
    // )
    return EditorState.createWithContent(
      convertFromRaw({
        blocks: newBlocks,
        entityMap: { ...omittedMap, ...newMap }
      }),
      decorator
    )
  }

  function reduceMultipleInputsValueUpdate(inputs: InputFieldT[], text: any, old: any): InputFieldT {
    const x = inputs.map(y => {
      const { inputHasValue, ...rest } = y
      if (y.config.kind == 'hidden' || configKinds.some(value => value === y.config.kind)) {
        return { ...rest }
      } else if (y.config.kind !== 'multiple') {
        const value = y.value as EditorState
        const hasValue = convertAsaSingleString(convertToRaw(value.getCurrentContent()).blocks).includes(
          old.title
        )
        // console.log(convertToRaw(value.getCurrentContent()), 'lji', old.title)
        if (
          typeof value != 'string' &&
          Object.keys(convertToRaw(value.getCurrentContent()).entityMap).length > 0 &&
          hasValue
        ) {
          return {
            ...y,
            value: updateVariable(value, text, old)
          } as any
        }

        return y as any
      } else {
        const items = Object.values(y.config.items) as InputFieldT[]

        return {
          ...y,
          config: {
            ...y.config,
            items: reduceMultipleInputsValueUpdate(items, text, old)
          }
        }
      }
    }) as any
    return x.reduce((acc: any, curr: any, i: any) => {
      acc[curr.key] = curr
      return acc
    }, {})
  }

  async function updateVariableFromInput(text: any, old: any, cb: Function) {
    const inputHasValues = R.flatten(reduceMultiples(currentNode.functionInputsList.output.input, old)).filter(
      (inp: any) => inp != null
    )
    if (inputHasValues.length > 0) {
      await cb('edit')
      const newValue = produce(currentNode, draft => {
        draft.functionInputsList.output.input = draft.functionInputsList.output.input.map(inp => {
          if (inp.config.kind == 'multiple') {
            const items = Object.values(inp.config.items) as InputFieldT[]

            return {
              ...inp,
              config: {
                ...inp.config,
                items: reduceMultipleInputsValueUpdate(items, text, old) as any
              }
            }
          } else if (inp.value) {
            const value = inp.value as EditorState
            const hasValue = convertAsaSingleString(
              convertToRaw(value.getCurrentContent()).blocks
            ).includes(old.title)
            if (
              typeof value != 'string' &&
              Object.keys(convertToRaw(value.getCurrentContent()).entityMap).length > 0 &&
              hasValue
            ) {
              return {
                ...inp,
                value: updateVariable(value, text, old)
              } as any
            }
            return inp as any
          } else {
            return inp as any
          }
        })
      })

      setFunctionPanelState({ ...functionPanelState, currentNode: newValue })
    } else {
      await cb('no-edit')
    }
  }

  function removeVariable(value: any, text: any) {
    const inpValue = convertToRaw(value.getCurrentContent())

    const newMap = Object.entries(inpValue.entityMap).reduce((acc: any, curr: any) => {
      if ((curr[1].data.variable ? curr[1].data.variable : curr[1].data.value) == text.title) {
        const { [`${curr[0]}`]: omit, ...rest } = acc
        return rest
      }
      return acc
    }, inpValue.entityMap)

    const newBlocks = inpValue.blocks.map(b => {
      const filtered = b.entityRanges.filter(e => {
        return Object.keys(newMap).indexOf(e.key.toString()) == -1
      })
      return {
        ...b,
        text: b.entityRanges
          .filter(e => {
            return Object.keys(newMap).indexOf(e.key.toString()) == -1
          })
          .reduce((acc, curr, i) => {
            if (i == 0) {
              return replaceBetween(acc, curr.offset, text.title.length, '', filtered.length == i + 1)
            } else {
              return replaceBetween(
                acc,
                curr.offset - text.title.length,
                text.title.length,
                '',
                filtered.length == i + 1
              )

              // return acc.substr(curr.offset - text.title.length, text.title.length)
            }
          }, b.text),
        entityRanges: b.entityRanges
          .filter(e => Object.keys(newMap).indexOf(e.key.toString()) != -1)
          .map(r => ({ ...r, offset: r.offset - text.title.length }))
      }
    })

    return EditorState.createWithContent(
      convertFromRaw({
        blocks: newBlocks,
        entityMap: newMap
      }),
      decorator
    )
  }

  function reduceMultiples(inputs: InputFieldT[], text: any) {
    const x = inputs.map(y => {
      const { inputHasValue, ...rest } = y
      if (y.config.kind == 'hidden' || configKinds.some(value => value === y.config.kind)) {
        return null
      } else if (y.config.kind !== 'multiple') {
        const value = y.value as EditorState
        // console.log(convertToRaw(value.getCurrentContent()).blocks)
        const hasValue = convertAsaSingleString(convertToRaw(value.getCurrentContent()).blocks).includes(
          text.title
        )
        // console.log(convertAsaSingleString(convertToRaw(value.getCurrentContent()).blocks), hasValue, text)
        if (typeof value != 'string' && hasValue) {
          return y
        }

        return null as any
      } else {
        const items = Object.values(y.config.items) as InputFieldT[]

        return reduceMultiples(items, text)
      }
    }) as any
    return x
  }

  function reduceMultipleInputsValueDelete(inputs: InputFieldT[], text: any): InputFieldT {
    const x = inputs.map(y => {
      const { inputHasValue, ...rest } = y
      if (y.config.kind == 'hidden' || configKinds.some(value => value === y.config.kind)) {
        return { ...rest }
      } else if (y.config.kind !== 'multiple') {
        const value = y.value as EditorState
        const hasValue = convertAsaSingleString(convertToRaw(value.getCurrentContent()).blocks).includes(
          text.title
        )
        if (
          typeof value != 'string' &&
          Object.keys(convertToRaw(value.getCurrentContent()).entityMap).length > 0 &&
          hasValue
        ) {
          return {
            ...y,
            value: updateVariable(value, { title: '' }, text)
          } as any
        }

        return y as any
      } else {
        const items = Object.values(y.config.items) as InputFieldT[]

        return {
          ...y,
          config: {
            ...y.config,
            items: reduceMultipleInputsValueDelete(items, text)
          }
        }
      }
    }) as any
    return x.reduce((acc: any, curr: any, i: any) => {
      acc[curr.key] = curr
      return acc
    }, {})
  }
  async function removeVariableFromInput(text: any, cb: Function) {
    // console.log('replace text', text)
    const inputHasValues = currentNode.functionInputsList.output.input
      .map((inp, i) => {
        const value = inp.value as EditorState

        return value &&
          convertAsaSingleString(convertToRaw(value.getCurrentContent()).blocks).includes(text.title)
          ? i
          : null
      })
      .filter(inp => inp != null)
    if (inputHasValues.length > 0) {
      await cb('delete')
      const newValue = produce(currentNode, draft => {
        draft.functionInputsList.output.input = draft.functionInputsList.output.input.map(inp => {
          const value = inp.value as EditorState
          const hasValue = convertAsaSingleString(convertToRaw(value.getCurrentContent()).blocks).includes(
            text.title
          )
          if (
            typeof value != 'string' &&
            Object.keys(convertToRaw(value.getCurrentContent()).entityMap).length > 0 &&
            hasValue
          ) {
            const inpValue = convertToRaw(value.getCurrentContent())

            const newMap = Object.entries(inpValue.entityMap).reduce((acc: any, curr: any) => {
              if ((curr[1].data.variable ? curr[1].data.variable : curr[1].data.value) == text.title) {
                const { [`${curr[0]}`]: omit, ...rest } = acc
                return rest
              }
              return acc
            }, inpValue.entityMap)

            const newBlocks = inpValue.blocks.map(b => {
              const filtered = b.entityRanges.filter(e => {
                return Object.keys(newMap).indexOf(e.key.toString()) == -1
              })

              // console.log(
              //     'filtered',
              //     filtered,
              //     newMap,
              //     b.entityRanges,
              //     b.entityRanges.reduce((acc, current, i: number) => {
              //         if (Object.keys(newMap).indexOf(current.key.toString()) != -1) {
              //             const newOffsetDifference =
              //                 acc.filter(x => x.length === 0).length * newMap.length > 0
              //                     ? newMap[0].length
              //                     : 0
              //             return [
              //                 ...acc,
              //                 {
              //                     ...current,
              //                     offset: i == 0 ? current.offset : current.offset - newOffsetDifference
              //                 }
              //             ]
              //         } else {
              //             return [
              //                 ...acc,
              //                 {
              //                     ...current,
              //                     // offset: i == 0 ? current.offset : acc[i - 1].offset,
              //                     length: 0
              //                 }
              //             ]
              //         }
              //         return [...acc, current]
              //     }, [] as RawDraftEntityRange[])

              //     // .filter(e => e.length === 0)
              // )
              return {
                ...b,
                text: b.entityRanges
                  .filter(e => {
                    return Object.keys(newMap).indexOf(e.key.toString()) == -1
                  })
                  .reduce((acc, curr, i) => {
                    if (i == 0) {
                      return replaceBetween2(
                        acc,
                        curr.offset,
                        curr.offset + text.title.length,
                        '',
                        curr.offset + text.title.length >= acc
                      )
                    } else {
                      const start = curr.offset - text.title.length * i
                      return replaceBetween2(
                        acc,
                        start,
                        start + text.title.length,
                        '',
                        filtered.length == i + 1
                      )

                      // return acc.substr(curr.offset - text.title.length, text.title.length)
                    }
                  }, b.text),
                entityRanges: b.entityRanges
                  .reduce((acc, current, i: number) => {
                    if (Object.keys(newMap).indexOf(current.key.toString()) != -1) {
                      const newOffsetDifference =
                        acc.filter(x => x.length === 0).length *
                        (Object.keys(newMap).length > 0 ? text.title.length : 0)
                      // console.log('newOffsetDifference', newOffsetDifference, newMap.length, newMap)
                      return [
                        ...acc,
                        {
                          ...current,
                          offset:
                            i == 0 ? current.offset : current.offset - newOffsetDifference
                        }
                      ]
                    } else {
                      return [
                        ...acc,
                        {
                          ...current,
                          // offset: i == 0 ? current.offset : acc[i - 1].offset,
                          length: 0
                        }
                      ]
                    }
                    return [...acc, current]
                  }, [] as RawDraftEntityRange[])

                  .filter(e => e.length !== 0)
                // .map((r, i: number) => {
                //     return { ...r, offset: i == 0 ? r.offset : r.offset - text.title.length }
                // })
              }
            })

            // console.log('newBlocks', newBlocks, newMap, inpValue.blocks)
            return {
              ...inp,
              value: EditorState.createWithContent(
                convertFromRaw({
                  blocks: newBlocks,
                  entityMap: newMap
                }),
                decorator
              )
            } as any
          }
          return inp as any
        })
      })

      setFunctionPanelState({ ...functionPanelState, currentNode: newValue })
    } else {
      await cb('delete')
    }
  }

  // async function removeVariableFromInput(text: any, cb: Function) {
  //     // const inputHasValues = currentNode.functionInputsList.output.input
  //     //     .map((inp, i) => {
  //     //         const value = inp.value as EditorState

  //     //         return value &&
  //     //             convertAsaSingleString(convertToRaw(value.getCurrentContent()).blocks).includes(text.title)
  //     //             ? i
  //     //             : null
  //     //     })
  //     //     .filter(inp => inp != null)

  //     const inputHasValues = R.flatten(reduceMultiples(currentNode.functionInputsList.output.input, text)).filter(
  //         (inp: any) => inp != null
  //     )
  //     if (inputHasValues.length > 0) {
  //         // await cb('delete')
  //         const newValue = produce(currentNode, draft => {
  //             draft.functionInputsList.output.input = draft.functionInputsList.output.input.map(inp => {
  //                 const value = inp.value as EditorState

  //                 if (inp.config.kind == 'multiple') {
  //                     const items = Object.values(inp.config.items) as InputFieldT[]

  //                     return {
  //                         ...inp,
  //                         config: {
  //                             ...inp.config,
  //                             items: reduceMultipleInputsValueDelete(items, text) as any
  //                         }
  //                     }
  //                 } else if (inp.value) {
  //                     const value = inp.value as EditorState
  //                     const hasValue = convertAsaSingleString(
  //                         convertToRaw(value.getCurrentContent()).blocks
  //                     ).includes(text.title)
  //                     if (
  //                         typeof value != 'string' &&
  //                         Object.keys(convertToRaw(value.getCurrentContent()).entityMap).length > 0 &&
  //                         hasValue
  //                     ) {
  //                         return {
  //                             ...inp,
  //                             value: updateVariable(value, { title: '' }, text)
  //                         } as any
  //                     }
  //                     return inp as any
  //                 } else {
  //                     return inp as any
  //                 }
  //             })
  //         })

  //         setFunctionPanelState({ ...functionPanelState, currentNode: newValue })
  //     } else {
  //         // await cb('no-delete')
  //     }
  // }

  function updateInputValuesWithContext(props: {
    path: string
    value: string
    valuePath: string[]
    icon: string
    hidden_item_type: string
    selectedColletion: any
    appForCollection: any
    examples: string
  }) {
    const { path, value, valuePath, icon, hidden_item_type, appForCollection, selectedColletion, examples } = props

    const variableValue = value

    const isMultiple = contextPopupValues.isMultiple.parentKey.length > 0

    let textInputvalue = currentNode.functionInputsList.output.input.filter(
      x => x.key == contextPopupValues.currentInput
    )[0]

    if (isMultiple) {
      const objectFromMultiplesValue = currentNode.functionInputsList.output.input.filter(
        x => x.key == contextPopupValues.isMultiple.parentKey
      )

      const pathL = ramda.compose<string, string[], string[]>(
        ramda.tail,
        ramda.split('/')
      )(contextPopupValues.isMultiple.path)

      const pathLens = ramda.lensPath(pathL)
      const data = ramda.view(pathLens, objectFromMultiplesValue[0]) as InputFieldT

      textInputvalue = data
    }

    const editorState = textInputvalue.value as any

    const nextValue = produce(currentNode, draftState => {
      draftState.functionInputsList.output.input = draftState.functionInputsList.output.input.map(
        (inputs: InputFieldT) => {
          if (isMultiple && inputs.key == contextPopupValues.isMultiple.parentKey) {
            if (textInputvalue.config.kind == 'collection-picker-single') {
              let value = updateValueUsingLens(
                contextPopupValues.collectionPicker.collectionType.path as string,
                inputs,
                {
                  value: hidden_item_type,
                  inputHasValue: true
                }
              )

              value = updateValueUsingLens(textInputvalue.path as string, value, {
                inputHasValue: true,
                value: InsertContext(
                  variableValue,
                  {
                    dataType: 'string',
                    path: valuePath,
                    icon: icon,
                    variable: variableValue,
                    examples: examples,
                    id: path
                  },
                  emptyEditorState
                ) as any
              })

              return value
            }

            const value = updateValueUsingLens(textInputvalue.path as string, inputs, {
              inputHasValue: true,
              value: InsertContext(
                variableValue,
                {
                  dataType: 'string',
                  path: valuePath,
                  icon: icon,
                  variable: variableValue,
                  examples: examples,
                  id: path
                },
                editorState
              ) as any
            })

            return value
          } else if (inputs.key === contextPopupValues.currentInput) {
            return {
              ...inputs,
              inputHasValue: true,
              value: InsertContext(
                value,
                {
                  dataType: 'string',
                  path: valuePath,
                  icon: icon,
                  variable: value,
                  examples: examples,
                  id: path
                },
                editorState
              ) as any
            }
          }
          return inputs
        }
      )
    })

    const removedCollectionVariablesState = produce(nextValue, draftState => {
      const slpittedValue = path.split('.')
      const prevCollectionValue = textInputvalue.value as EditorState
      const prevRawValue = convertToRaw(prevCollectionValue.getCurrentContent())
      if (
        prevRawValue.blocks[0].text.length > 0 &&
        prevRawValue.blocks[0].text !== slpittedValue[slpittedValue.length - 1] &&
        contextPopupValues.collectionPicker.isCollectionPicker
      ) {
        const splittedValue = prevRawValue.entityMap['0'].data.id.split('.')
        draftState.functionInputsList.output.input = [
          ...(Object.values(
            removeValuesToMultipleInputs(
              nextValue.functionInputsList.output.input,
              splittedValue[0],
              undefined,
              currentNode.combiningResponseWithTriggerOutput,
              treeData.tree.value,
              contextPopupValues,
              setContextPopupValues,
              true
            )
          ) as InputFieldT[])
        ]
      }
    })

    contextPopupValues.collectionPicker.isCollectionPicker &&
      setContextPopupValues({
        ...contextPopupValues,
        show: false,
        collectionPicker: {
          ...contextPopupValues.collectionPicker,
          app: appForCollection,
          selectedCollection: selectedColletion
        }
      })

    setFunctionPanelState({
      ...functionPanelState,
      currentNode: removedCollectionVariablesState
    })
  }

  function saveNode() {
    const updatedNodeValue = produce(functionPanelState.currentNode, draftState => {
      draftState.functionInputsList.output.input = currentNode.functionInputsList.output.input.map(y => {
        const { inputHasValue, ...rest } = y

        const filteredMacthedInput = contextPopupValues.inputWithValues.filter(x => y.key === x.nodeInput)

        if (y.config.kind == 'multiple') {
          const items = Object.values(y.config.items) as InputFieldT[]

          return {
            ...rest,
            config: {
              ...y.config,
              items: reduceMultipleInputs(items) as any
            }
          }
        } else if (filteredMacthedInput.length > 0) {
          // return {
          //     ...rest,
          //     value: convertToServerFormat(rest.value as any)
          // } as InputFieldT
          const convertedBlocks =
            typeof rest.value == 'string'
              ? rest.value
              : convertToRaw(rest.value.getCurrentContent())
                .blocks.map(b => {
                  const st = rest.value as EditorState
                  return convertToServerFormat2(convertToRaw(st.getCurrentContent()).entityMap, b)
                })
                .reduce((acc, ele, i) => (i == 0 ? (acc += ele) : (acc += '\n' + ele)), '')

          if (!rest.value || convertedBlocks.length === 0) {
            const { value, ...res } = rest

            return {
              ...res
            } as InputFieldT
          }

          return {
            ...rest,
            value: convertedBlocks
          } as InputFieldT
        } else if (rest.value) {
          const convertedBlocks =
            typeof rest.value == 'string'
              ? rest.value
              : convertToRaw(rest.value.getCurrentContent())
                .blocks.map(b => {
                  const st = rest.value as EditorState
                  return convertToServerFormat2(convertToRaw(st.getCurrentContent()).entityMap, b)
                })
                .reduce((acc, ele, i) => (i == 0 ? (acc += ele) : (acc += '\n' + ele)), '')

          if (!rest.value || convertedBlocks.length === 0) {
            const { value, ...res } = rest

            return {
              ...res
            } as InputFieldT
          }

          return {
            ...rest,
            value: convertedBlocks
          } as InputFieldT
        }

        return { ...rest } as InputFieldT
      })
    })

    saveTree(updatedNodeValue)
  }

  // function generateRenderData(renderValue: ContextInputValueTypeT[], mapperInput: InputFieldT) {
  //     const mapperValue = mapperInput.value

  //     if (renderValue.length > 0) {
  //         return [
  //             {
  //                 ...renderValue[0],
  //                 value: renderValue[0].value.concat({
  //                     type: 'string',
  //                     value: mapperValue
  //                         ? typeof mapperValue == 'string'
  //                             ? mapperValue
  //                             : convertToRaw(mapperValue.getCurrentContent()).blocks[0].text
  //                         : ''
  //                 })
  //             }
  //         ] as ContextInputValueTypeT[]
  //     } else if (mapperInput.value && mapperInput.value.length > 0) {
  //         return [
  //             {
  //                 nodeInput: mapperInput.key,
  //                 value: [
  //                     {
  //                         type: 'string',
  //                         value: mapperInput.value
  //                     }
  //                 ],
  //                 multipleValueInputs: {
  //                     collectionType: emptyInputSchema,
  //                     isCollectionPicker: false,
  //                     parentKey: '',
  //                     path: ''
  //                 }
  //             }
  //         ] as ContextInputValueTypeT[]
  //     }

  //     return renderValue
  // }

  const collectionMapper = checkForParticularValueinInput(
    currentNode.functionInputsList.output.input,
    'collection-mapper'
  ) as false | InputFieldT

  return (
    <React.Fragment>
      <FunctionWrapper>
        <PopupWrapper>
          <PopupContainer>
            <FunctionPanel.Header />
            <PopupScrollHeight>
              <EmptyDiv>
                <div className="automation_canvas_right function_popup automation_function_popup">
                  <div className="automation_canvas_right_popup automation_condition_popup">
                    <div className="automation_canvas_right_popup_form">
                      <div className="automation_rightside_form automation_rightside_step_two edit_action_height">
                        <FunctionPanel.SelectFunction />

                        {currentNode.selectedFunction.function_id != '' && (
                          <FunctionPanel.Inputs
                            {...props}
                            title={'Function Inputs'}
                            description="Select or fill required inputs to execute this function"
                            state={functionPanelState}
                            workspacename={props.match.params.workspacename}
                            setPanelState={setFunctionPanelState}
                            contextPopupValues={contextPopupValues}
                            setContextPopupValues={setContextPopupValues}
                            updateInputValuesWithContext={updateInputValuesWithContext}
                          />
                        )}

                        {currentNode.selectedFunction.function_id.length > 0 &&
                          collectionMapper && (
                            <div className="automation_form_req automation_form_req_condition">
                              <div className="automation_global_form _select_a_variable">
                                <div className="select_work_form">
                                  <Header>Function summary</Header>
                                  <div className={(isAssistant ? automationMeta.type === 'no_trigger' ? hasLinkedDialogs : active_automation : active_automation) ? 'function_input_field disabled_with_blue' : 'function_input_field'}>
                                    {(() => {
                                      let mapperInput = collectionMapper as InputFieldT

                                      const allowNumbering = checkForParticularValueinInput(
                                        currentNode.functionInputsList.output
                                          .input,
                                        'allow_numbering'
                                      ) as InputFieldT
                                      // console.log(
                                      //     currentNode.functionInputsList.output
                                      //         .input,
                                      //     'delimiterssss'
                                      // )
                                      const delimiter = checkForParticularValueinInput(
                                        currentNode.functionInputsList.output
                                          .input,
                                        'delimiter'
                                      ) as InputFieldT

                                      const delimiterValue = delimiter.value as string

                                      const value = mapperInput.value as any

                                      const renderValues = convertToRaw(
                                        value.getCurrentContent()
                                      ).blocks.map((rawValue: any) => {
                                        let renderValue = convertEditorStateDatatoVariables(
                                          rawValue.text,
                                          rawValue.entityRanges
                                        ) as any

                                        return {
                                          value: renderValue
                                            .map(
                                              (
                                                range:
                                                  | {
                                                    end: number
                                                    start: number
                                                    type: 'string'
                                                  }
                                                  | {
                                                    end: number
                                                    key: number
                                                    start: number
                                                    type: 'context'
                                                  },
                                                index: number
                                              ) => {
                                                if (
                                                  range.type ==
                                                  'string'
                                                ) {
                                                  return {
                                                    type: 'string',
                                                    value: rawValue.text.slice(
                                                      range.start,
                                                      range.end
                                                    )
                                                  }
                                                }

                                                const rawEntityMap = convertToRaw(
                                                  value.getCurrentContent()
                                                ).entityMap[range.key]

                                                if (rawEntityMap) {
                                                  return {
                                                    type: 'context',

                                                    data: convertToRaw(
                                                      value.getCurrentContent()
                                                    ).entityMap[
                                                      range.key
                                                    ],

                                                    value: rawValue.text.slice(
                                                      range.start,
                                                      range.end
                                                    ),
                                                    examples:
                                                      rawEntityMap.data &&
                                                        rawEntityMap
                                                          .data
                                                          .examples
                                                        ? rawEntityMap
                                                          .data
                                                          .examples
                                                        : rawValue.text.slice(
                                                          range.start,
                                                          range.end
                                                        )
                                                  }
                                                }
                                                return {
                                                  type: 'string',

                                                  value: rawValue.text.slice(
                                                    range.start,
                                                    range.end
                                                  )
                                                }
                                              }
                                            )
                                            .filter(Boolean)
                                        }
                                      })

                                      return renderValues.map(
                                        (value: any, i: number) => {
                                          return (
                                            <div
                                              className="new_line_paragraph"
                                              key={i}
                                            >
                                              <div
                                                className="comma_and_hyphen"
                                                style={{
                                                  flexDirection:
                                                    delimiterValue ==
                                                      '\n'
                                                      ? 'column'
                                                      : 'inherit'
                                                }}
                                              >
                                                <span className="comma_and_hyphen_name">
                                                  {allowNumbering.value &&
                                                    allowNumbering.value ==
                                                    'yes' && (
                                                      <span className="comma_and_hyphen_number">
                                                        {1}.{' '}
                                                      </span>
                                                    )}

                                                  {HtmlParser(
                                                    reduceArrayToString(
                                                      value.value,
                                                      'value'
                                                    )
                                                  )}
                                                </span>{' '}
                                                {delimiterValue &&
                                                  HtmlParser(
                                                    delimiterValue.replace(
                                                      /\n/g,
                                                      '<br/>'
                                                    )
                                                  )}
                                                <span
                                                  className="comma_and_hyphen_name"
                                                  style={{
                                                    paddingLeft:
                                                      delimiterValue !=
                                                        '\n'
                                                        ? 1
                                                        : 0
                                                  }}
                                                >
                                                  {allowNumbering.value &&
                                                    allowNumbering.value ==
                                                    'yes' && (
                                                      <span
                                                        className="comma_and_hyphen_number"
                                                        style={{
                                                          paddingLeft:
                                                            delimiterValue !=
                                                              '\n'
                                                              ? 1
                                                              : 0
                                                        }}
                                                      >
                                                        {2}.{' '}
                                                      </span>
                                                    )}

                                                  {HtmlParser(
                                                    reduceArrayToString(
                                                      value.value,
                                                      'value'
                                                    )
                                                  )}
                                                </span>{' '}
                                                {delimiterValue &&
                                                  HtmlParser(
                                                    delimiterValue.replace(
                                                      /\n/g,
                                                      '<br/>'
                                                    )
                                                  )}
                                                <span
                                                  className="comma_and_hyphen_name"
                                                  style={{
                                                    paddingLeft:
                                                      delimiterValue !=
                                                        '\n'
                                                        ? 1
                                                        : 0
                                                  }}
                                                >
                                                  {allowNumbering.value &&
                                                    allowNumbering.value ==
                                                    'yes' && (
                                                      <span
                                                        className="comma_and_hyphen_number"
                                                        style={{
                                                          paddingLeft:
                                                            delimiterValue !=
                                                              '\n'
                                                              ? 1
                                                              : 0
                                                        }}
                                                      >
                                                        {3}.{' '}
                                                      </span>
                                                    )}

                                                  {HtmlParser(
                                                    reduceArrayToString(
                                                      value.value,
                                                      'value'
                                                    )
                                                  )}
                                                </span>{' '}
                                              </div>
                                            </div>
                                          )
                                        }
                                      )
                                    })()}
                                  </div>
                                  <div className="button_auto_input_click">
                                    Description (How users see your custom output)
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                        {/* <div className="automation_form_req automation_form_req_condition">
                        <div className="automation_global_form">
                            <div className="select_work_form">
                                <h4>Choose a collection:</h4>
                                <input type="text" className={''} value={''} onChange={e => {}} />
                                <p>Select the function type to create a function.</p>
                            </div>
                        </div>
                    </div> */}
                      </div>
                    </div>
                  </div>
                  {contextPopupValues.show && (
                    <ContextPopup
                      {...props}
                      mode={rightPanelState.mode}
                      tree={treeData.tree}
                      parentNode={rightPanelState.parentNode}
                      actionAppsList={currentNode.functionsList}
                      triggerNode={treeData.tree.value}
                      updateInputValuesWithContext={updateInputValuesWithContext}
                      addingFlowInsideForEach={currentNode.addingInsideForEach}
                      showOnlyCollection={contextPopupValues.collectionPicker.isCollectionPicker ? {
                        showOnlyCollection:
                          contextPopupValues.collectionPicker.isCollectionPicker,
                        contextPopupValues: contextPopupValues
                      } : undefined}
                      removeVariableFromInput={(text: any, cb: Function) =>
                        removeVariableFromInput(text, cb)
                      }
                      updateVariableFromInput={(newText: any, oldText: any, cb: Function) =>
                        updateVariableFromInput(newText, oldText, cb)
                      }
                      closeContextPopup={() =>
                        setContextPopupValues({
                          ...contextPopupValues,
                          show: false,
                          currentInput: '',
                          isMultiple: { path: '', parentKey: '' },
                          collectionPicker: {
                            ...contextPopupValues.collectionPicker,
                            isCollectionPicker: false
                          }
                        })
                      }
                    />
                  )}
                </div>
              </EmptyDiv>
              <ButtonContainer className="footer_button_fixed">
                <div className="popup_footer_button_container">
                  <Button
                    primary
                    onClick={() => {
                      cancelCurrentPopup({
                        functionPanelState,
                        SidePanelContext: SidePanelContextValue
                      })
                    }}
                  >
                    Cancel
                  </Button>
                  {(isAssistant ? automationMeta.type === 'no_trigger' ? isInputhaveDifferentValues() : !active_automation : !active_automation) && (
                    <Button
                      className={enableSave() ? '' : 'editor_btn_disabled'}
                      disabled={!enableSave()}
                      onClick={() => saveNode()}
                    >
                      Save
                    </Button>
                  )}
                </div>
              </ButtonContainer>
            </PopupScrollHeight>
          </PopupContainer>
        </PopupWrapper>
      </FunctionWrapper>
    </React.Fragment>
  )
}

function cancelCurrentPopup(props: {
  functionPanelState: FunctionPanelStateT
  SidePanelContext: RightPanelStateContext
}) {
  const { functionPanelState, SidePanelContext } = props

  const { rightPanelState, setRightPanelState, treeData, setEditTreeData, editTreeData } = SidePanelContext

  const tree =
    functionPanelState.parentNode.value.kind == 'ForEach' &&
      functionPanelState.currentNode.addingInsideForEach &&
      functionPanelState.parentNode.value.subflow
      ? functionPanelState.parentNode.value.subflow.children.filter(x => x.value.kind === 'ParallelPath')
      : functionPanelState.parentNode.children.filter(x => x.value.kind === 'ParallelPath')

  const cancellingNode = functionPanelState.currentNode.parallelPathNode.currentParallelPathNode

  const currentNode = functionPanelState.currentNode

  if (tree.length < 2) {
    setRightPanelState({
      ...rightPanelState,
      show: false,
      mode: NodeView,
      parentNode: { value: Tree, children: [] },
      currentNode: {
        ...currentNode,
        parallelPathNode: {
          ...currentNode.parallelPathNode,
          currentParallelPathNode: null
        }
      }
    })
    const newTree = cancellingNode
      ? updateNode(
        { ...cancellingNode, name: '' },
        treeData.tree,
        cancellingNode.meta_data && cancellingNode.meta_data.subflow
          ? {
            addingInsideForEach: currentNode.addingInsideForEach,
            forEachNodeId: cancellingNode.meta_data.subflow.forEachNodeId,
            parentNode: cancellingNode.meta_data.subflow.parentId
          }
          : undefined
      )
      : treeData.tree

    setEditTreeData({ ...treeData, tree: newTree })
  } else {
    const node = cancellingNode as NodeT
    const addingFlowInsideForEach = {
      addingInsideForEach: node.meta_data.subflow.hasSubFlow as boolean,
      forEachNodeId: node.meta_data.subflow.forEachNodeId as string,
      parentNode: rightPanelState.parentNode.value
    }

    // for while adding new parallel path
    setRightPanelState({
      ...rightPanelState,
      show: false,
      currentNode: {
        ...currentNode,
        parallelPathNode: {
          ...currentNode.parallelPathNode,
          currentParallelPathNode: null
        }
      }
    })

    const newTree = cancellingNode
      ? updateNode({ ...cancellingNode, name: '' }, editTreeData.tree, addingFlowInsideForEach)
      : editTreeData.tree
    setEditTreeData({ ...editTreeData, tree: newTree })
  }
}

const Wrapper = (props: any) => (
  <FunctionWrapper>
    <PopupWrapper>
      <PopupContainer>
        <PopupScrollHeight>
          <div className="automation_canvas_right_popup automation_condition_popup">
            <div className="automation_canvas_right_popup_form">
              <div className="automation_rightside_form automation_rightside_step_two edit_action_height">
                {props.children}
              </div>
            </div>
          </div>
        </PopupScrollHeight>
      </PopupContainer>
    </PopupWrapper>
  </FunctionWrapper>
)
const FunctionTip = () => (
  <>
    <p>
      Use{' '}
      <a
        className="sidepanel_href_color"
        target="_blank"
        href="https://help.workativ.com/knowledgebase/add-function/"
      >
        Function
      </a>{' '}
      to modify the output of previous actions. You can perform several operations such as data operations, data
      formatters, date and time operations, and number operations.
    </p>
  </>
)

FunctionPanel.Header = (props: any) => {
  return (
    <div className="automation_form_header">
      {getProductId() === "ASSISTANT" ? (
        <Header className="function_popup_header">
          <HeaderLeft>
            Function
            <Tooltip
              className="target customTip"
              zIndex={10000}
              arrowSize={8}
              tagName="span"
              content={<FunctionTip />}
              distance={5}
            >
              <InfoIcon />
            </Tooltip>
            <LinkTag href={'https://help.workativ.com/knowledgebase/add-function/'} target="_blank">
              <QaIconWithCircle />
            </LinkTag>
          </HeaderLeft>
          <HeaderRight>
            {
              getProductId() === "ASSISTANT" ? <VideoLinkSimple id={'USE_NUMBER_OPERATIONS.'} text={'How to'} /> :
                <VideoLinkSimple id={'USE_NUMBER_OPERATIONS.'} text={'How to set function'} />
            }
          </HeaderRight>
        </Header>
      ) : (
        <Header className="function_popup_header">
          <HeaderLeft>
            Function
            {/* <Tooltip
                        className="target customTip"
                        zIndex={10000}
                        arrowSize={8}
                        tagName="span"
                        content={<FunctionTip />}
                        distance={5}
                    >
                        <InfoIcon />
                    </Tooltip>
                    <LinkTag href={'https://help.workativ.com/knowledgebase/add-function/'} target="_blank">
                        <QaIconWithCircle />
                    </LinkTag> */}
          </HeaderLeft>
          <HeaderRight>
            {
              getProductId() === "ASSISTANT" ? <VideoLinkSimple id={'USE_NUMBER_OPERATIONS.'} text={'How to'} /> :
                <VideoLinkSimple id={'USE_NUMBER_OPERATIONS.'} text={'How to set function'} />
            }
          </HeaderRight>
        </Header>
      )}


      {/* <Header className="function_popup_header">
                <HeaderLeft>
                    Function
                    <Tooltip
                        className="target customTip"
                        zIndex={10000}
                        arrowSize={8}
                        tagName="span"
                        content={<FunctionTip />}
                        distance={5}
                    >
                        <InfoIcon />
                    </Tooltip>
                    <LinkTag href={'https://help.workativ.com/knowledgebase/add-function/'} target="_blank">
                        <QaIconWithCircle />
                    </LinkTag>
                </HeaderLeft>
                <HeaderRight>
                { 
                 getProductId() === "ASSISTANT" ? <VideoLinkSimple id={'USE_NUMBER_OPERATIONS.'} text={'How to'} /> :
                <VideoLinkSimple id={'USE_NUMBER_OPERATIONS.'} text={'How to set function'} />
                }
                </HeaderRight>
            </Header> */}
      {getProductId() === "ASSISTANT" ? <Paragraph>Setup function in your workflow.</Paragraph> :
        <Paragraph style={{ padding: '0 5px 0 2px' }}>Set function in your workflow</Paragraph>
      }

    </div>
  )
}

FunctionPanel.SelectFunction = (props: {}) => {
  const [search, setSearch] = useState({
    searching: false,
    key: '',
    inFocus: false
  })

  const { functionPanelState, setFunctionPanelState, setUserSwitchedApp } = useContext(FunctionPanelStateContext)
  const { hasLinkedDialogs, active_automation, automationMeta } = useContext(SidePanelContext)

  const currentNode = functionPanelState.currentNode as FunctionResponseT

  // const filteredResponse = currentNode.functionsList.output.filter(func => func.latest && func.type == 'function')
  const filteredResponse = grouping(
    currentNode.functionsList.output.filter(func => func.latest && func.type == 'function'),
    'appName'
  )

  function filterAction(array: any[], selectedAppId: string) {
    if (array.length === 0) return []
    return array.filter((x: any) => x.appId === selectedAppId)[0].value
  }

  const apps_ref = useRef(null)

  const isAssistant = getProductId() === 'ASSISTANT'

  // console.log(currentNode)

  return (
    <InputWrapper>
      <div className="autoamtion_function_section automate_product">
        <div className="select_application">
          <div className="select_work_form function_popup_section">
            <CanvasRightPanel.AppsDropDown
              inputs={{
                inputs: [
                  // DefaultSelectedValue,
                  ...(search.searching
                    ? sortAnArrayAlphabetically(filteredResponse, 'name').filter(
                      (app: any) =>
                        app.name.toLowerCase().indexOf(search.key.toLowerCase()) > -1
                    )
                    : sortAnArrayAlphabetically(filteredResponse, 'name'))
                ],
                key: 'name',
                idKey: 'appId',
                showIcon: false
              }}
              functionPopup
              addDropDown
              closeCallBack={() => {
                !search.inFocus && setSearch({ ...search, searching: false })
              }}
              description={currentNode.selectedFunction.description}
              updateSelection={(selectedNewApp: string, id: string) => {
                const updatedState = produce(currentNode, draftState => {
                  const des = draftState.functionsList.output.filter(x => x.appId === id)[0]

                  draftState.selectedFunction =
                    selectedNewApp !== 'Select' && id !== currentNode.selectedFunction.id
                      ? {
                        appName: selectedNewApp,
                        id: id,
                        function_id: '',
                        name: 'Select',
                        version: '',
                        description: des.description ? des.description : ''
                      }
                      : {
                        ...draftState.selectedFunction,
                        appName: selectedNewApp,
                        id: id,
                        description: des.description ? des.description : ''
                      }

                  draftState.functionInputsList =
                    selectedNewApp !== 'Select' && id !== currentNode.selectedFunction.id
                      ? FunctionInitialState.functionInputsList
                      : draftState.functionInputsList
                })

                setFunctionPanelState({ ...functionPanelState, currentNode: updatedState })

                // currentNode.selectedFunction.function_id !== id &&
                //     functionPanelState.mode.mode == NodeMode.Edit &&
                //     setUserSwitchedApp(true)
              }}
              render={(makeDropDownVisible: () => void) => (
                <React.Fragment>
                  <InputContainer style={{ margin: 0 }} className={(isAssistant ? automationMeta.type === 'no_trigger' ? hasLinkedDialogs : active_automation : active_automation) ? 'disabled_with_blue' : ''}>
                    <div className="function_popup_ul">
                      <Inputfield
                        type="text"
                        value={
                          search.searching ? search.key : currentNode.selectedFunction.appName
                        }
                        onFocus={() => {
                          setSearch({
                            inFocus: true,
                            searching: true,
                            key:
                              NodeMode.Edit == functionPanelState.mode.mode ||
                                (currentNode.selectedFunction.appName.length > 0 &&
                                  currentNode.selectedFunction.appName.toLowerCase() !==
                                  'select')
                                ? currentNode.selectedFunction.appName
                                : ''
                          })
                        }}
                        onBlur={() => {
                          setSearch({
                            ...search,
                            inFocus: false
                          })
                        }}
                        onChange={(e: any) => {
                          setSearch({ ...search, key: e.target.value })
                        }}
                        onClick={() => makeDropDownVisible()}
                        style={{ paddingLeft: 10, color: 'transparent', textShadow: '0 0 0 #000' }}
                        ref={apps_ref}
                      />
                      <InputContent>Select function</InputContent>
                    </div>
                    <CancelButtonInput
                      rotate
                      onClick={() => {
                        const ref = apps_ref.current
                        if (ref) {
                          setSearch({
                            inFocus: true,
                            searching: true,
                            key:
                              NodeMode.Edit == functionPanelState.mode.mode ||
                                (currentNode.selectedFunction.appName.length > 0 &&
                                  currentNode.selectedFunction.appName.toLowerCase() !==
                                  'select')
                                ? currentNode.selectedFunction.appName
                                : ''
                          })

                          const iRef = ref as HTMLInputElement
                          iRef.focus()
                          makeDropDownVisible()
                        }
                      }}
                    >
                      <DownarrowWithCircle />
                    </CancelButtonInput>
                    {/* <Paragraph style={{ margin: '7px 0px 0px' }}>
                                            {currentNode.selectedFunction.description
                                                ? currentNode.selectedFunction.description
                                                : 'Select the function type to create a function.'}
                                        </Paragraph> */}
                  </InputContainer>
                </React.Fragment>
              )}
            />
          </div>
          {currentNode.selectedFunction.id != '' && (
            <div className="select_work_form" style={{ marginTop: 20, width: '100%', float: 'left' }}>
              <CanvasRightPanel.AppsDropDown
                inputs={{
                  inputs: [
                    ...sortAnArrayAlphabetically(
                      filterAction(filteredResponse, currentNode.selectedFunction.id),
                      'action'
                    )
                  ],
                  key: 'action',
                  idKey: 'id',
                  showIcon: false
                }}
                addDropDown
                updateSelection={(selectedNewApp: string, id: string) => {
                  const updatedState = produce(currentNode, draftState => {
                    draftState.selectedFunction = {
                      ...draftState.selectedFunction,
                      name: selectedNewApp,
                      function_id: id,
                      version: id
                        ? draftState.functionsList.output.filter(x => x.id === id)[0].version
                        : ''
                    }
                  })

                  setFunctionPanelState({
                    ...functionPanelState,
                    currentNode: updatedState
                  })

                  currentNode.selectedFunction.function_id !== id &&
                    functionPanelState.mode.mode == NodeMode.Edit &&
                    setUserSwitchedApp(true)
                }}
                render={(makeDropDownVisible: () => void) => (
                  <React.Fragment>
                    <InputContainer className={(isAssistant ? automationMeta.type === 'no_trigger' ? hasLinkedDialogs : active_automation : active_automation) ? 'disabled_with_blue' : ''}>
                      <Inputfield
                        type="text"
                        value={currentNode.selectedFunction.name}
                        onChange={(e: any) => {
                          setSearch({ ...search, key: e.target.value })
                        }}
                        onClick={() => makeDropDownVisible()}
                        style={{ paddingLeft: 10, color: 'transparent', textShadow: '0 0 0 #000' }}
                        ref={apps_ref}
                      />
                      <InputContent>Operation type</InputContent>

                      <CancelButtonInput rotate onClick={() => makeDropDownVisible()}>
                        <DownarrowWithCircle />
                      </CancelButtonInput>
                    </InputContainer>
                  </React.Fragment>
                )}
              />
              {/* <Paragraph>
                                Check the{' '}
                                <a href="https://help.workativ.com/" target="_blank">
                                    documentation
                                </a>{' '}
                                for help on each of the options.
                            </Paragraph> */}
            </div>
          )}
        </div>
      </div>
    </InputWrapper>
  )
}

FunctionPanel.Inputs = (props: any) => {
  const { hasLinkedDialogs, treeData } = useContext(SidePanelContext)

  const { functionPanelState, setFunctionPanelState, contextPopupValues, setContextPopupValues } = useContext(
    FunctionPanelStateContext
  )

  const { state, ...rest } = props
  // console.log(functionPanelState.currentNode.functionInputsList, 'functionPanelState.currentNode.functionInputsList')
  return (
    <CommonInputs
      state={functionPanelState}
      setContextPopupValues={setContextPopupValues}
      contextPopupValues={contextPopupValues}
      setPanelState={setFunctionPanelState}
      inputsList={
        functionPanelState.currentNode.functionInputsList &&
          functionPanelState.currentNode.functionInputsList.output
          ? functionPanelState.currentNode.functionInputsList.output.input
          : []
      }
      workspacename={props.match.params.workspacename}
      {...rest}
    />
  )
}
