/*eslint-disable*/
import React, { useContext, useState } from 'react'

import { AddIcon, MinusIcon } from '@/common/Icons/Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { Loader } from '@/common/components/Loader'
import lazyGA from '@/common/google-analytics/tracking'

import { createQueryDialog, updateDailouge } from '../../Actions/AsyncOperations'
import { PageContext } from '../../Actions/ReactContext'
import { AskQueryDialogPT, PageView } from '../../Types/PageT'
import {
    AskQueryDialogT,
    JumpToSelectorT,
    OptionsT,
    RecognizeDialogT,
    SlotContainerDialogT,
    TextResponseDialogT
} from '../../Types/WatsonT'
import { Button } from '../Common'
import { DraftContent, metaParser } from '../DraftContent'

export const Question = () => {
    const [loader, setLoader] = useState<boolean>(false)

    const [error, setError] = useState<any>({ error: null, info: null })

    const { workspace, setPageState, PageState, fetchCallback } = useContext(PageContext)

    const { input } = PageState as AskQueryDialogPT

    const [questionMeta, setQuestionMeta] = useState(input.metadata.RICH_TEXT)

    const [variable, setVariable] = useState<string>(input.variable)

    const [keycode, setKeycode] = useState<number>(13)

    const [metacode, setMetacode] = useState<boolean>(false)

    const [isOptionType, setIsOptionType] = useState<boolean>(input.question.options ? true : false)

    const [optionsQuery, setOptionsQuery] = useState<OptionsT[]>(
        input.question.options ? input.question.options : [{ label: '', value: '' }]
    )

    const save = async () => {
        setLoader(true)
        try {
            const { input, previousSibling, nextSibling, parent } = PageState as AskQueryDialogPT
            const question = metaParser(questionMeta)
            if (input.dialog_node == '') {
                const Create_response: AskQueryDialogT = await createQueryDialog(workspace, {
                    type: 'qa',
                    parent: input.parent,
                    previous_sibling: input.previous_sibling,
                    question: isOptionType ? { text: question, options: optionsQuery } : { text: question },
                    variable: variable,
                    metadata: { RICH_TEXT: questionMeta }
                } as AskQueryDialogT)

                const response: AskQueryDialogT = await updateDailouge(workspace, {
                    ...Create_response,
                    metadata: { ...input.metadata, RICH_TEXT: questionMeta }
                } as AskQueryDialogT)

                if (previousSibling) {
                    await updateDailouge(workspace, {
                        ...previousSibling,
                        type: previousSibling.type,
                        jump_to: {
                            dialog_node: response.dialog_node,
                            selector: JumpToSelectorT.body
                        },
                        metadata: { ...previousSibling.metadata, hide_jump_to: true }
                    } as TextResponseDialogT | AskQueryDialogT | SlotContainerDialogT)
                } else if (parent) {
                    await updateDailouge(workspace, {
                        ...parent,
                        type: parent.type,
                        jump_to: {
                            dialog_node: response.dialog_node,
                            selector: JumpToSelectorT.body
                        },
                        metadata: { ...parent.metadata, hide_jump_to: true }
                    } as RecognizeDialogT)
                }

                if (nextSibling) {
                    if (nextSibling.type == 'if') {
                        await updateDailouge(workspace, {
                            ...response,
                            jump_to: {
                                dialog_node: nextSibling.dialog_node,
                                selector: JumpToSelectorT.user_input
                            },
                            metadata: { ...response.metadata, hide_jump_to: true }
                        } as AskQueryDialogT)
                    } else {
                        await updateDailouge(workspace, {
                            ...response,
                            jump_to: {
                                dialog_node: nextSibling.dialog_node,
                                selector: JumpToSelectorT.body
                            },
                            metadata: { ...response.metadata, hide_jump_to: true }
                        } as AskQueryDialogT)
                    }
                }
                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Ask a question Node Created'
                })
            } else {
                const data: AskQueryDialogT = {
                    ...input,
                    question: isOptionType ? { text: question, options: optionsQuery } : { text: question },
                    variable: variable,
                    metadata: { ...input.metadata, RICH_TEXT: questionMeta }
                }

                await updateDailouge(workspace, data as AskQueryDialogT)

                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Ask a question Node Updated'
                })
            }

            fetchCallback(() => setLoader(false))
        } catch (err) {
            setError({
                error: true,
                info: `Server error!<br />Please try again after sometime.`
            })
        }
    }

    const labelHandleChange = (value: string, index: number) => {
        setOptionsQuery(optionsQuery =>
            optionsQuery.map((e: any, i: number) => {
                if (i == index) {
                    e.label = value
                    e.value = value
                }
                return e
            })
        )
    }

    const removeOption = (index: number) =>
        setOptionsQuery(optionsQuery => optionsQuery.filter((e: any, i: number) => i != index))

    const addMore = () => setOptionsQuery(optionsQuery => optionsQuery.concat({ label: '', value: '' }))

    return (
        <div className="right-panel ask_ques_editor">
            <ErrorBoundary
                error={error}
                render={(err: any, info: any) => {
                    return err && <Error.Delete onClick={() => setPageState(PageView)} info={info} />
                }}
            >
                <h5>Ask a Question</h5>
                <p>Make your chatbot ask a specific question to the user and store the user’s response in a variable</p>

                {loader ? (
                    <div className="popup_loader">
                        <Loader.PopupLoader show={loader} />
                    </div>
                ) : (
                    <React.Fragment>
                        <div className="right_panel_radio">
                            <div className="col-md-12 entity_radio">
                                <div className="radio">
                                    <label>
                                        <input
                                            type="radio"
                                            className="radio-warning"
                                            checked={!isOptionType}
                                            onChange={() => setIsOptionType(false)}
                                        />
                                    </label>
                                    <span>Simple Question</span>
                                </div>
                                <div className="radio">
                                    <label>
                                        <input
                                            type="radio"
                                            checked={isOptionType}
                                            onChange={() => {
                                                setIsOptionType(true)
                                                setOptionsQuery(data =>
                                                    data.length ? data : [{ label: '', value: '' }]
                                                )
                                            }}
                                        />
                                    </label>
                                    <span>Options Question</span>
                                </div>
                            </div>
                        </div>
                        <div className="right_panel_ht">
                            <div className={isOptionType ? 'options_question' : 'simple_question'}>
                                <div className="ask_ques_editor_input">
                                    <h6>Type your question:</h6>
                                    <div className="qeustions_wrapper">
                                        <span className="form-input">
                                            <DraftContent rawMeta={questionMeta} setRawMeta={setQuestionMeta} />
                                        </span>
                                    </div>
                                </div>
                                <div className="ask_ques_editor_input">
                                    <h6>Save users response as:</h6>
                                    <span className="form-input">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Type here"
                                            value={variable}
                                            onKeyDown={e => {
                                                setKeycode(e.keyCode)
                                                if (e.metaKey || e.ctrlKey) setMetacode(true)
                                                else setMetacode(false)
                                            }}
                                            onChange={e => {
                                                if (keycode != 32 && metacode == false) {
                                                    setVariable(e.target.value)
                                                }
                                            }}
                                        />
                                    </span>
                                </div>

                                {isOptionType && optionsQuery ? (
                                    <React.Fragment>
                                        <p className="question_span">Add Options</p>
                                        {optionsQuery.map((e: any, index: number) => (
                                            <div className="options_panel" key={index}>
                                                <div className="if_dropdown">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Text"
                                                        value={e.label}
                                                        onChange={e => labelHandleChange(e.target.value, index)}
                                                    />
                                                    {optionsQuery.length > 1 ? (
                                                        <div className="parent_node_no">
                                                            <span
                                                                className="parent_svg"
                                                                onClick={() => removeOption(index)}
                                                            >
                                                                <MinusIcon />
                                                            </span>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <label className="queston_label_editor">
                                                    {index + 1 < 9
                                                        ? '0' + (index + 1).toString()
                                                        : index + 1 == 9
                                                        ? '09'
                                                        : index + 1}
                                                </label>
                                            </div>
                                        ))}
                                        {optionsQuery.length < 20 &&
                                        optionsQuery.filter(data => data.label == '').length == 0 ? (
                                            <div className="plus_addmore">
                                                <div className="plus_add_more">
                                                    <h4 onClick={() => addMore()}>
                                                        <div className="parent_node_no">
                                                            <span className="parent_svg">
                                                                <AddIcon />
                                                            </span>
                                                        </div>
                                                        Add More
                                                    </h4>
                                                </div>
                                            </div>
                                        ) : null}
                                    </React.Fragment>
                                ) : null}
                            </div>
                        </div>
                        <div className="margin-bottom-75" />
                        <Button
                            disable={
                                metaParser(questionMeta) == '' || variable == '' || isOptionType
                                    ? optionsQuery.filter(data => data.label == '').length > 0
                                    : false
                            }
                            onCancel={() => setPageState(PageView)}
                            onSave={save}
                        />
                    </React.Fragment>
                )}
            </ErrorBoundary>
        </div>
    )
}
