import produce from 'immer'
import * as R from 'ramda'
/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react'
import Tooltip from 'react-tooltip-lite'
import styled from 'styled-components/macro'

import { SerachInput, SerachInputWrapper } from '@/DialogEditor/TrainingModule/TrainingModule'
import { VideoLink } from '@/Videos/Videos'
//ICONS IMPORT
import {
    DropdownWithCircle,
    HelpIconMenu,
    InfoIcon,
    MoreIconJump,
    QaIconWithCircle,
    RightArrowWithCircle,
    SearchIcon
} from '@/common/Icons/Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
//COMMON MODULE IMPORTS
import { Loader } from '@/common/components/Loader'
import lazyGA from '@/common/google-analytics/tracking'
import { Button as JumpButton } from '@/common/styled/Dialog.BotDetails.Dumb'
import {
    ButtonContainer,
    CancelButton,
    EmptyDiv,
    Header,
    HeaderLeft,
    InputWrapper,
    Paragraph,
    PopupContainer,
    PopupScrollHeight,
    PopupWrapper,
    Wrapper as WrapperJummpto
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { HeaderRight } from '@/common/styled/Subscription.Xtra.Dumb'

import { Button } from '../../Common/Effective'
import { DropDown } from '../../Common/Effective'
//TYPE IMPORT
import { DefaultDialogue, DialogueT, IfNodeT, JumpToSelectorT, isJumpTo, JumpToT } from '../../Dialogue/kind'
//ACTION IMPORT
import { getDialogues, updateIfDialogue } from '../../Mechanisms/action'
//CONTEXT IMPORTS
import { DialogueContext } from '../Context'
import { sortBySibling } from '../action'
import { ErrorState, ErrorT, JUMP_DIALOGUE, VIEW } from '../kind'
import { logEvent } from '@/common/utils/api-utils'

const SearchWrapper = styled.div`
    width: 100%;
    float: left;
    padding-left: 10px;
    margin-bottom: 10px;

    ${SerachInputWrapper} {
        width: 100%;
        float: left;

        ${SerachInput} {
            width: 100%;
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #b9b9b9;
            border-radius: 19px;
            box-shadow: none;

            :hover {
                box-shadow: 0px 3px 6px #00000029;
                border: 1px solid #eb974e;
            }
        }
        span {
            top: 10px;
        }
    }
`

const JumpTips = () => (
    <>
        <p>
            Use{' '}
            <a
                className="sidepanel_href_color"
                target="_blank"
                href="https://help.workativ.com/index.php/knowledgebase/jump/"
            >
                Jump to
            </a>{' '}
            when you want the chatbot to jump to a specific dialog.
        </p>
        <p>Use any one of the options:</p>
        <p>
            <b>Jump here and wait for user input—</b>Jumps to this dialog and waits for the user to input. Based on that
            user input, the selected dialog or any one of its successors is executed by the bot.{' '}
        </p>
        <p>
            <b>Jump here and recognize dialog—</b>Jumps to this dialog and executes it only if the user input meets the
            dialog condition. If it doesn't, then the bot checks successive dialogs' conditions against the user's
            input.{' '}
        </p>
        <p>
            <b>Jump here and execute dialog—</b>Jumps to this dialog and executes its dialog and sub-dialogs within. Use
            this this option when you don't want the bot to wait for user's input.
        </p>
    </>
)

const JumpDialogue = () => {
    let SEARCHELEMENT: any

    const {
        workspaceName,
        assistantID,
        PageState,
        setPageState,
        fetchCallback,
        dialogueName,
        rootDialogs,
        assistant
    } = useContext(DialogueContext)

    const { data } = PageState as JUMP_DIALOGUE

    const [searchKey, setSearchKey] = useState<string>('')

    const [selector, setSelector] = useState<JumpToSelectorT>(JumpToSelectorT.body)

    const [selected, setSelected] = useState<DialogueT | null>(null)

    const [loader, setLoader] = useState<boolean>(false)

    const [error, setError] = useState<ErrorT>(ErrorState)

    const [dialogs, setDialogs] = useState<(DialogueT & { showChildren: boolean })[]>([])

    const [rootDialogues, setRootDialogues] = useState<(DialogueT & { showChildren: boolean })[]>([])

    const [WelcomeNode, setWelcomeNode] = useState<DialogueT>(DefaultDialogue)

    const [FallbackNode, setFallbackNode] = useState<DialogueT>(DefaultDialogue)
    const [clearContextVariables,setClearContextVariables] = useState(typeof data.next_step == 'object'&&((data.next_step as any).jump_to as JumpToT).clear_context_variables)
    const statusInitialValue = typeof data.next_step == 'object' ? ((data.next_step as any).jump_to as JumpToT).update_query_status_as : "resolved"
    const [status , setStatus] = useState(statusInitialValue)
    const [mark_ticket_created , setTicketCreated] = useState(typeof data.next_step == 'object' && ((data.next_step as any).jump_to as JumpToT).mark_ticket_created)
    const [collapseStatusSection,setCollapseStatusSection] = useState(true)
    // const [rootDialogState,setrootDialogs] = useState<>(rootDialogs)

    useEffect(() => {
        setLoader(true)

        getDialogues(workspaceName, assistantID)
            .then((allDialogs: DialogueT[]) => {
                const WelcomeNode = allDialogs
                    .filter((e: DialogueT) => (e.conditions ? e.uuid === assistant.welcome_node : false))
                    .pop()

                const FallbackNode = allDialogs
                    .filter((e: DialogueT) => (e.conditions ? e.conditions.type === 'FallBackExpression' : false))
                    .pop()

                setFallbackNode(FallbackNode ? FallbackNode : DefaultDialogue)
                setWelcomeNode(WelcomeNode ? WelcomeNode : DefaultDialogue)

                const RecognizeDialogs = allDialogs.filter((e: DialogueT) => {
                    if (e.conditions) {
                        if (e.uuid === assistant.welcome_node) return false
                        else if (e.conditions.type === 'FallBackExpression') return false
                    }
                    return true
                })
                // console.log('data', data)

                if (isJumpTo(data.next_step)) {
                    const next_step = data.next_step
                    const proof = allDialogs.find(e => e.uuid === next_step.jump_to.to)

                    if (proof) {
                        setSelected(proof)
                        setSelector(next_step.jump_to.selector)
                        const findPath = (acc: string[], current: DialogueT): string[] => {
                            if (current.parent === null) return acc
                            else {
                                const parent = current.parent
                                const toCheck = allDialogs.find(x => x.uuid === current.parent)
                                if (toCheck) {
                                    return findPath([...acc, parent], toCheck)
                                }

                                return acc
                            }
                        }
                        const x = findPath([], proof)
                        setDialogs(
                            allDialogs.map(e => {
                                if (x.includes(e.uuid)) {
                                    return { ...e, showChildren: true as boolean }
                                }
                                return { ...e, showChildren: false as boolean }
                            })
                        )

                        setRootDialogues(
                            rootDialogs
                                // .filter((e: DialogueT) => e.type !== 'faq_node')
                                .map(e => {
                                    if (x.includes(e.uuid)) {
                                        return { ...e, showChildren: true as boolean }
                                    }
                                    return { ...e, showChildren: false as boolean }
                                })
                        )
                        // console.log('x', x)
                    } else {
                        setDialogs(allDialogs.map(e => ({ ...e, showChildren: false as boolean })))

                        setRootDialogues(
                            rootDialogs
                                // .filter((e: DialogueT) => e.type !== 'faq_node')
                                .map(e => ({ ...e, showChildren: false as boolean }))
                        )
                    }
                } else {
                    setDialogs(
                        allDialogs.map(e => {
                            return { ...e, showChildren: false as boolean }
                        })
                    )

                    setRootDialogues(
                        rootDialogs
                            // .filter((e: DialogueT) => e.type !== 'faq_node')
                            .map(e => {
                                return { ...e, showChildren: false as boolean }
                            })
                    )
                }
                setLoader(false)
            })
            .catch(err => {
                console.log('err', err)
                setError({
                    error: true,
                    info: `Server error!<br />Please try again after sometime.`
                })
                setLoader(false)
            })
    }, [])

    const handleSave = async () => {
        try {
            if (selected) {
                setLoader(true)
                const req = {
                    ...data,
                    type: 'if_node',
                    next_step: {
                        jump_to: {
                            to: selected.uuid,
                            selector: selector,
                            clear_context_variables: clearContextVariables,
                            mark_ticket_created: mark_ticket_created,
                            update_query_status_as: status
                        }
                    },
                }

                await updateIfDialogue(workspaceName, assistantID, data.uuid, req as IfNodeT, dialogueName)

                logEvent(workspaceName, {
                    event:"EVENT::DIALOG::ADD_JUMP_TO_DIALOG", event_meta:{
                    name: dialogueName,
                    description: ""
                }})

                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Jump to Node Created'
                })

                fetchCallback(() => {
                    setLoader(false)
                })
            }
        } catch (err) {
            setError({
                error: true,
                info: `Server error!<br />Please try again after sometime.`
            })
            setLoader(false)
        }
    }

    const DownshiftModel = (dialog: DialogueT) => (
        <div className="dropdown_jumpto">
            <div className="dropdown_move">
                <span
                    className={selector == JumpToSelectorT.body ? '_selected_input last-child_jump' : 'last-child_jump'}
                    onClick={() => setSelector(JumpToSelectorT.body)}
                >
                    Jump here and execute this {dialog.type == 'faq_node' ? 'faq' : 'dialog'}
                    <label>
                        <input
                            type="radio"
                            className="option-input radio"
                            name="example"
                            checked = {selector == JumpToSelectorT.body}
                        />
                    </label>
                </span>
                <span
                    className={selector == JumpToSelectorT.user_input ? '_selected_input' : ''}
                    onClick={() => setSelector(JumpToSelectorT.user_input)}
                >
                    Jump here and wait for user input
                    <label>
                        <input
                            type="radio"
                            className="option-input radio"
                            name="example"
                            checked = {selector == JumpToSelectorT.user_input}
                        />
                    </label>
                </span>
                <span
                    className={selector == JumpToSelectorT.condition ? '_selected_input' : ''}
                    onClick={() => setSelector(JumpToSelectorT.condition)}
                >
                    Jump here and recognize {dialog.type == 'faq_node' ? 'faq' : 'dialog'}
                    <label>
                        <input
                            type="radio"
                            className="option-input radio"
                            name="example"
                            checked = {selector == JumpToSelectorT.condition}
                        />
                    </label>
                </span>
            </div>
        </div>
    )

    const hasChildren = <
        T extends DialogueT & {
            showChildren: boolean
        }
    >(
        list: T[],
        nodeToCheck: T
    ) => {
        return list.filter(e => e.parent == nodeToCheck.uuid).length > 0
    }

    const Representation = (
        dialog: DialogueT & {
            showChildren: boolean
        }
    ) => (
        <React.Fragment>
            {dialogs.filter(
                (
                    e: DialogueT & {
                        showChildren: boolean
                    }
                ) => e.parent == dialog.uuid
            ).length > 0 ? (
                <ul>
                    {sortBySibling(
                        dialogs.filter(
                            (
                                e: DialogueT & {
                                    showChildren: boolean
                                }
                            ) => e.parent == dialog.uuid
                        )
                    ).map((node, index: number) => (
                        <li key={index.toString()}>
                            <input type="checkbx" checked onChange={() => {}} />
                            <label className="tree_label1">
                                <div className="jump_to">
                                    <div
                                        className={
                                            selected && selected.uuid == node.uuid
                                                ? 'jump_to_list_selected'
                                                : 'jump_to_list'
                                        }
                                        onClick={() => {
                                            const dialogsUp = produce(dialogs, draftDialog => {
                                                draftDialog.forEach(dialogmap => {
                                                    console.log(
                                                        'dialogsUp',
                                                        dialogmap.uuid === node.uuid,
                                                        dialogmap.uuid,
                                                        dialog.uuid
                                                    )
                                                    if (dialogmap.uuid === node.uuid) {
                                                        dialogmap.showChildren = !dialogmap.showChildren
                                                        console.log(
                                                            'dialogmap.showChildren = !dialogmap.showChildren',
                                                            dialogmap.showChildren
                                                        )
                                                    }
                                                })
                                                return draftDialog
                                            })
                                            console.log(dialogsUp, 'dialogsUp')
                                            setDialogs(dialogsUp as any)
                                        }}
                                    >
                                        <h5>{node.title}</h5>
                                        {hasChildren(dialogs, node) ? (
                                            <span
                                                className={`indicate_children ${node.showChildren ? 'close' : 'open'}`}
                                                onClick={()=>setSelected(null)}
                                            >
                                                <RightArrowWithCircle />
                                            </span>
                                        ) : null}

                                        <div
                                            className="jump_selected_dropdown"
                                            onClick={(e: any) => {
                                                if (selected && selected.id === node.id) {
                                                    setSelected(null)
                                                } else {
                                                    setSelected(node)
                                                }
                                                e.preventDefault()
                                                e.stopPropagation()
                                            }}
                                        >
                                            <span>
                                                <MoreIconJump />
                                            </span>
                                        </div>
                                    </div>
                                    {selected && selected.uuid == node.uuid ? (
                                        <DropDown
                                            isOpen={selected ? selected.uuid == node.uuid : false}
                                            onOuterClick={() => {}}
                                        >
                                            {DownshiftModel(selected)}
                                        </DropDown>
                                    ) : null}
                                </div>
                            </label>
                            {node.showChildren && Representation(node)}
                        </li>
                    ))}
                </ul>
            ) : null}
        </React.Fragment>
    )

    return (
        <WrapperJummpto>
            <PopupWrapper>
                <PopupContainer className="jump_to_move_dialog">
                    <ErrorBoundary
                        error={error}
                        render={(err: any, info: any) => {
                            return err && <Error.Delete onClick={() => setPageState(VIEW)} info={info} />
                        }}
                    >
                        <Header>
                            <HeaderLeft minWidth={300}>
                                Jump to
                                <Tooltip
                                    className="target customTip jumpto"
                                    zIndex={10000}
                                    arrowSize={8}
                                    tagName="span"
                                    content={<JumpTips />}
                                    distance={5}
                                >
                                    <InfoIcon />
                                </Tooltip>
                                <a href="https://help.workativ.com/index.php/knowledgebase/jump/" target="_blank">
                                    <Tooltip
                                        className="target customTip"
                                        zIndex={10000}
                                        arrowSize={8}
                                        tagName="span"
                                        content="View help document "
                                        distance={5}
                                    >
                                        <QaIconWithCircle /> 
                                    </Tooltip>
                                </a>
                            </HeaderLeft>
                            <HeaderRight>
                                <VideoLink id={'JUMP_TO_A_DIALOG'} text={'How to'} />
                            </HeaderRight>
                        </Header>
                        <Paragraph>Make your chatbot jump to specific dialog or faq.</Paragraph>
                        {loader ? (
                            <div className="popup_loader">
                                <Loader.PopupLoader show={loader} />
                            </div>
                        ) : (
                            <React.Fragment>
                                <PopupScrollHeight>
                                    <EmptyDiv>

                                    <EmptyDiv>
                                                <div className="row">
                                                    <div className="get_users_wrapper">
                                                        <div className="slots_right_bot">
                                                            <h4 className='title'>Dialog resolution status
                                                                <CancelButton className="d-flex slots_dropDown" onClick={()=>setCollapseStatusSection(!collapseStatusSection)}>
                                                                    <DropdownWithCircle />
                                                                </CancelButton>
                                                            </h4>
                                                            
                                                            {collapseStatusSection&&
                                                            <>
                                                            <Paragraph className="recognize_dialog_fontsize row">
                                                            <div className="show_hide">
                                                                    <div className="switch_box box_1">
                                                                        <input
                                                                            type="radio"
                                                                            // className={`switch_1`}
                                                                            checked={status=='resolved'}
                                                                            onChange={() => {
                                                                                setStatus("resolved")
                                                                            }}
                                                                            name="status_jump_or_exit"

                                                                        />
                                                                    </div>
                                                                </div>
                                                                Mark dialog as resolved
                                                                <Tooltip
                                                                    className="target customTip verify_user_tooltips tooltips_color"
                                                                    zIndex={10000}
                                                                    arrowSize={4}
                                                                    tagName="span"
                                                                    content="Mark dialog as resolved"
                                                                    distance={5}
                                                                >
                                                                    <InfoIcon />
                                                                </Tooltip>                                                               
                                                            </Paragraph>
                                                            <Paragraph className="recognize_dialog_fontsize row">
                                                            <div className="show_hide">
                                                                    <div className="switch_box box_1">
                                                                        <input
                                                                            type="radio"
                                                                            // className={`switch_1`}
                                                                            checked={status=='unresolved'}
                                                                            onChange={() => {
                                                                                setStatus("unresolved")
                                                                            }}
                                                                            name="status_jump_or_exit"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                Mark dialog as unresolved
                                                                <Tooltip
                                                                    className="target customTip verify_user_tooltips tooltips_color"
                                                                    zIndex={10000}
                                                                    arrowSize={4}
                                                                    tagName="span"
                                                                    content="Mark dialog as unresolved"
                                                                    distance={5}
                                                                >
                                                                    <InfoIcon />
                                                                </Tooltip>                                                               
                                                            </Paragraph>
                                                            <Paragraph className="recognize_dialog_fontsize row jump_to_last_option">
                                                            <div className="show_hide">
                                                                    <div className="switch_box box_1">
                                                                        <input
                                                                            type="radio"
                                                                            // className={`switch_1`}
                                                                            checked={status=='ignore'}
                                                                            onChange={() => {
                                                                                setStatus("ignore")
                                                                            }}
                                                                            name="status_jump_or_exit"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                Do not mark dialog resolution status
                                                                <Tooltip
                                                                    className="target customTip verify_user_tooltips tooltips_color"
                                                                    zIndex={10000}
                                                                    arrowSize={4}
                                                                    tagName="span"
                                                                    content="Do not mark dialog resolution status"
                                                                    distance={5}
                                                                >
                                                                    <InfoIcon />
                                                                </Tooltip>                                                               
                                                            </Paragraph>
                                                            </>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </EmptyDiv>
                                            <EmptyDiv>
                                                <div className="row">
                                                    <div className="get_users_wrapper">
                                                        <div className="slots_right_bot">
                                                            <Paragraph className="recognize_dialog_fontsize row">
                                                            <div className="show_hide">
                                                                    <div className="switch_box box_1">
                                                                        <input
                                                                            type="checkbox"
                                                                            // className={`switch_1`}
                                                                            checked={mark_ticket_created}
                                                                            onChange={() => {
                                                                                setTicketCreated(!mark_ticket_created)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                Ticket raised for this dialog
                                                                <Tooltip
                                                                    className="target customTip verify_user_tooltips tooltips_color"
                                                                    zIndex={10000}
                                                                    arrowSize={4}
                                                                    tagName="span"
                                                                    content="Ticket raised for this dialog"
                                                                    distance={5}
                                                                >
                                                                    <InfoIcon />
                                                                </Tooltip>                                                               
                                                            </Paragraph>
                                                        </div>
                                                    </div>
                                                </div>
                                            </EmptyDiv>
                                            <EmptyDiv>
                                                <div className="row">
                                                    <div className="get_users_wrapper">
                                                        <div className="slots_right_bot">
                                                            <Paragraph className="recognize_dialog_fontsize row">
                                                            <div className="show_hide">
                                                                    <div className="switch_box box_1">
                                                                        <input
                                                                            type="checkbox"
                                                                            // className={`switch_1`}
                                                                            checked={clearContextVariables}
                                                                            onChange={() => {
                                                                                setClearContextVariables(!clearContextVariables)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                Clear the data collected from the user in context variables
                                                                <Tooltip
                                                                    className="target customTip verify_user_tooltips tooltips_color"
                                                                    zIndex={10000}
                                                                    arrowSize={4}
                                                                    tagName="span"
                                                                    content="Data in system variables that starts with SYS_ will not be cleared"
                                                                    distance={5}
                                                                >
                                                                    <InfoIcon />
                                                                </Tooltip>                                                               
                                                            </Paragraph>
                                                        </div>
                                                    </div>
                                                </div>
                                            </EmptyDiv>
                                        <div className="jumpto_wrapper jumpto_search">
                                            <SearchWrapper>
                                                <SerachInputWrapper>
                                                    <SerachInput
                                                        type="search"
                                                        className="form-control jumpto_search"
                                                        placeholder="Search "
                                                        value={searchKey}
                                                        ref={(element: any) => {
                                                            SEARCHELEMENT = element
                                                        }}
                                                        onChange={(e: any) => setSearchKey(e.target.value)}
                                                    />
                                                    <span>
                                                        <SearchIcon />
                                                    </span>
                                                </SerachInputWrapper>
                                            </SearchWrapper>
                                            <div className="">
                                                {rootDialogues.length > 0 ? (
                                                    <ul className="tree1 zontal_lines1">
                                                        {rootDialogues
                                                            .filter(e =>
                                                                e.title
                                                                    ? e.title
                                                                          .toLowerCase()
                                                                          .includes(searchKey.toLowerCase())
                                                                    : false
                                                            )
                                                            .map(
                                                                (
                                                                    dialog: DialogueT & {
                                                                        showChildren: boolean
                                                                    },
                                                                    index: number
                                                                ) => (
                                                                    <li
                                                                        className="first_add_node"
                                                                        key={index.toString()}
                                                                    >
                                                                        <label className="tree_label1">
                                                                            <div className="jump_to">
                                                                                <div
                                                                                    className={
                                                                                        selected &&
                                                                                        selected.uuid == dialog.uuid
                                                                                            ? 'jump_to_list_selected'
                                                                                            : 'jump_to_list'
                                                                                    }
                                                                                    onClick={() => {
                                                                                        const dialogsUp = produce(
                                                                                            rootDialogues,
                                                                                            draftDialog => {
                                                                                                draftDialog.forEach(
                                                                                                    dialogmap => {
                                                                                                        console.log(
                                                                                                            'dialogsUp',
                                                                                                            dialogmap.uuid ===
                                                                                                                dialog.uuid,
                                                                                                            dialogmap.uuid,
                                                                                                            dialog.uuid
                                                                                                        )
                                                                                                        if (
                                                                                                            dialogmap.uuid ===
                                                                                                            dialog.uuid
                                                                                                        ) {
                                                                                                            dialogmap.showChildren = !dialogmap.showChildren
                                                                                                            console.log(
                                                                                                                'dialogmap.showChildren = !dialogmap.showChildren',
                                                                                                                dialogmap.showChildren
                                                                                                            )
                                                                                                        }
                                                                                                    }
                                                                                                )
                                                                                                return draftDialog
                                                                                            }
                                                                                        )
                                                                                        console.log(
                                                                                            dialogsUp,
                                                                                            'dialogsUp'
                                                                                        )
                                                                                        setRootDialogues(
                                                                                            dialogsUp as any
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    {(() => {
                                                                                        if (
                                                                                            dialog.conditions ===
                                                                                                null ||
                                                                                            (dialog.uuid !==
                                                                                                assistant.welcome_node &&
                                                                                                dialog.conditions
                                                                                                    .type !==
                                                                                                    'FallBackExpression')
                                                                                        ) {
                                                                                            return (
                                                                                                <div className="parent_node_no">
                                                                                                    <span>
                                                                                                        {(() => {
                                                                                                            console.log(
                                                                                                                dialog.title,
                                                                                                                index
                                                                                                            )
                                                                                                            if (
                                                                                                                index >
                                                                                                                9
                                                                                                            ) {
                                                                                                                return index
                                                                                                            } else {
                                                                                                                return (
                                                                                                                    '0' +
                                                                                                                    index.toString()
                                                                                                                )
                                                                                                            }
                                                                                                        })()}
                                                                                                    </span>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        return null
                                                                                    })()}
                                                                                    <h4>{dialog.title}</h4>
                                                                                    {hasChildren(dialogs, dialog) ? (
                                                                                        <span
                                                                                            className={`indicate_children ${
                                                                                                dialog.showChildren
                                                                                                    ? 'close'
                                                                                                    : 'open'
                                                                                            }`}
                                                                                            onClick={()=>setSelected(null)}
                                                                                        >
                                                                                            <RightArrowWithCircle />
                                                                                        </span>
                                                                                    ) : null}
                                                                                    <div
                                                                                        className="jump_selected_dropdown"
                                                                                        onClick={(e: any) => {
                                                                                            if (
                                                                                                selected &&
                                                                                                selected.id ===
                                                                                                    dialog.id
                                                                                            ) {
                                                                                                setSelected(null)
                                                                                            } else {
                                                                                                setSelected(dialog)
                                                                                            }
                                                                                            // setSelected(dialog)
                                                                                            e.preventDefault()
                                                                                            e.stopPropagation()
                                                                                        }}
                                                                                    >
                                                                                        <span>
                                                                                            <MoreIconJump />
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                {selected &&
                                                                                selected.uuid == dialog.uuid ? (
                                                                                    <DropDown
                                                                                        isOpen={
                                                                                            selected
                                                                                                ? selected.uuid ==
                                                                                                  dialog.uuid
                                                                                                : false
                                                                                        }
                                                                                        onOuterClick={() => {}}
                                                                                    >
                                                                                        {DownshiftModel(selected)}
                                                                                    </DropDown>
                                                                                ) : null}
                                                                            </div>
                                                                        </label>
                                                                        {dialog.showChildren && Representation(dialog)}
                                                                    </li>
                                                                )
                                                            )}
                                                    </ul>
                                                ) : null}
                                                {/* {FallbackNode && (
                                                <div
                                                    className="jump_to_start"
                                                    onClick={() => setSelected(FallbackNode)}
                                                >
                                                    <div
                                                        className={
                                                            selected
                                                                ? selected.id == FallbackNode.id
                                                                    ? 'jump_to_list_selected fallback first_add_node'
                                                                    : 'jump_to_list fallback first_add_node'
                                                                : 'jump_to_list fallback first_add_node'
                                                        }
                                                    >
                                                        <h4>Fallback</h4>

                                                        {selected && selected.id == FallbackNode.id ? (
                                                            <DropDown
                                                                isOpen={
                                                                    selected ? selected.id == FallbackNode.id : false
                                                                }
                                                                onOuterClick={() => {}}
                                                            >
                                                                {DownshiftModel()}
                                                            </DropDown>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            )} */}
                                                <div className="margin-bottom-75" />
                                            </div>
                                        </div>
                                    </EmptyDiv>
                                    <ButtonContainer className="footer_button_fixed">
                                        <div className="popup_footer_button_container">
                                        <JumpButton
                                            primary
                                            type="button"
                                            data-dismiss="modal"
                                            onClick={() => setPageState(VIEW)}
                                        >
                                            <label>Cancel</label>
                                        </JumpButton>
                                        <JumpButton
                                            className={selected == null ? 'editor_btn_disabled' : ''}
                                            type="button"
                                            data-dismiss="modal"
                                            onClick={() => handleSave()}
                                        >
                                            <label>Jump</label>
                                        </JumpButton>
                                        </div>
                                    </ButtonContainer>
                                </PopupScrollHeight>
                            </React.Fragment>
                        )}
                    </ErrorBoundary>
                </PopupContainer>
            </PopupWrapper>
        </WrapperJummpto>
    )
}

export default JumpDialogue
