import styled from 'styled-components/macro'

import { Button } from './Dialog.BotDetails.Dumb'

export const Wrapper = styled.section`
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    // left: 48px;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1100;
    cursor: auto;
`
export const Paragraph = styled.p`
    text-align: left;
    color: #355d8b;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    margin-bottom: ${props => (props.pMargin ? '0px' : '14px')};
    margin-top: ${props => (props.pMargin ? '7px' : '2px')};
    width: 100%;
    float: left;
    line-height: 14px;
    cursor: ${props => (props.cursor ? 'pointer' : 'inherit')};
    margin-left: 2px;

    svg {
        width: 16px;
        height: 16px;
        margin-right: 6px;
        position: relative;
        top: 3px;
    }
`
export const HeaderLeft = styled.div`
    width: 100%;
    float: left;
    min-width: ${props => (props.minWidth ? `${props.minWidth}px` : '0px')};
`
export const HeaderRight = styled.div`
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    ${Paragraph} {
        color: #007bff;
        margin-bottom: 0px;
    }
`
export const Header = styled.h4`
    text-align: left;
    color: #000;
    font-size: ${props => (props.fontMedium ? '14px' : '18px')};
    font-family: 'Gordita-Medium';
    margin-bottom: 0px;
    width: 100%;
    float: left;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
        margin-left: 6px;
        // top: 3px;
        svg {
            width: 12px;
            height: 12px;
            fill: #999;
            color: #999;
            margin-left: 0px;
            cursor: pointer;
        }
    }
    .target.customTip.upload_popup_qus{
        top: 1.5px;
    }

    .slack_close_btn{
        border-radius: 50%;
        background: rgb(255, 255, 255);
        border: 1px solid rgb(153, 153, 153);
        width: 64px;
        height: 18px;
        display: flex;
        align-items: center;
        margin-right: 0;

        :hover{
            background: #ffe5d3;
        }

        svg{
            padding: 2px;
            position: relative;
            left: 2.5px;

            :hover{
                fill: #000;
                color: #000;
            }
        }
    }
`
export const Textarea = styled.textarea.attrs((boolean: any) => ({
    required: true
}))`
    width: 100%;
    float: left;
    padding: 24px 12px 8px 12px;
    border: 1px solid #b9b9b9;
    border-radius: 6px;
    background: #fff;
    height: 48px;
    font-size: 14px;
    font-family: 'Gordita-Regular';
    color: #000000;
    outline: 0;

    :hover {
        outline: 0;
        border: 1px solid #eb974e;
        box-shadow: 0px 3px 6px #00000029;
    }

    :active {
        outline: 0;
        border: 1px solid #eb974e;
        box-shadow: none;
    }
`
export const Input = styled.input.attrs((boolean: any) => ({
    required: true
}))`
    width: 100%;
    float: left;
    padding: 26px 12px 6px 12px;
    border: 1px solid #b9b9b9;
    border-radius: 6px;
    background: #fff;
    height: 48px;
    font-size: 14px;
    font-family: 'Gordita-Regular';
    color: #000000;

    :hover {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #b9b9b9;
    }
    :focus {
        outline: 0;
        border: 1px solid #eb974e;
        box-shadow: 0px 3px 6px #00000029;
    }
    :active {
        outline: 0;
        border: 1px solid #eb974e;
        box-shadow: none;
    }
`
export const InputContent = styled.div`
    color: #999;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 16px;
    transition: 0.26s ease-in;
    ${Input}:focus ~ &,
    ${Input}:not(:placeholder-shown).input:not(:focus) ~ .label,
    ${Input}:valid ~ & {
        top: 10px;
        font-size: 10px;
        transition: 0.26s ease-out;
        line-height: 10px;
    }
    ${Textarea}:focus ~ &,
    ${Textarea}:not(:placeholder-shown).input:not(:focus) ~ .label,
    ${Textarea}:valid ~ & {
        top: 1px;
        font-size: 10px;
        transition: 0.26s ease-out;
        line-height: 10px;
        background: #fff;
        width: 95%;
        text-align: left;
        height: 24px;
        padding-top: 8px;
    }
`
export const CancelButton = styled.span`
    width: 16px;
    height: 16px;
    position: absolute;
    right: 12px;
    top: 16px;
    display: block;

    svg {
        width: 16px;
        height: 16px;
    }
`
export const InputContainer = styled.div`
    width: 100%;
    float: left;
    position: relative;
    margin-bottom: 20px;

    ${CancelButton} {
        ${({ activeCancelButton }) =>
            activeCancelButton &&
            `
    display:flex;
    top:16px;
    width: 16px;
    height: 16px;
    left:inherit
    svg {
        width: 16px;
        height: 16px;
    }
    `}
    }
`
export const SmallButton = styled.button`
    background: ${props => (props.primary ? '#fff' : '#2468f6')};
    border-radius: 18px;
    border: ${props => (props.primary ? '1px solid #cecece' : '1px solid #2468f6')};
    outline: 0;
    font-size: 12px;
    color: ${props => (props.primary ? '#333' : '#fff')};
    font-family: 'Gordita-Regular';
    display: flex;
    align-items: center;
    min-width: 64px;
    height: 24px;
    transition: 0.346s ease;
    padding: 0px 10px;
    cursor: pointer;

    .rotate_back_button svg {
        transform: rotate(180deg);
    }
    ${({ disabled }) =>
        disabled &&
        `
opacity:0.3;
pointer-events:none;
`}

    label {
        margin-bottom: 0px;
        position: relative;
        top: 1px;
        cursor: pointer;
        font-size: 10px;
        display: flex;
        align-items: center;
        padding: 5px 0;

        p {
            margin-bottom: 0;
            padding-top: 1px;
            font-size: 12px;
        }
    }
    :hover {
        border: 1px solid #ffe5d3;
        background: #ffe5d3;
        color: #333;
        fill: #333;
        outline: 0;
        cursor: pointer;
        span svg {
            fill: ${props => (props.primary ? '#333' : '#333')};
            color: ${props => (props.primary ? '#333' : '#333')};
        }
    }
    :active {
        background: ${props => (props.primary ? '#FFE5D3' : '#FFE5D3')}!important;
        border: ${props => (props.primary ? '1px solid #eb974e' : '1px solid #eb974e')} !important;
        color: ${props => (props.primary ? '#333' : '#333')}!important;
    }

    span {
        width: 10px;
        height: 10px;
        float: left;
        margin-right: 3px;
        display: flex;
        align-items: center;
        position: relative;
        top: 0px;
        svg {
            width: 10px;
            height: 10px;
            fill: ${props => (props.primary ? '#333' : '#fff')};
            color: ${props => (props.primary ? '#333' : '#fff')};
            margin-right: 0px;
        }
    }
    svg {
        width: 12px;
        height: 12px;
        margin-right: 6px;
        position: relative;
    }
`
export const InputWrapper = styled.div`
    width: 100%;
    float: left;
    background: ${props => (props.bgColor ? '#fff' : '#f4f4f4')};
    padding: 20px;
    margin-bottom: 10px;
    margin-top: ${props => (props.marginTop ? '20px' : '0')};

    :last-child {
        margin-bottom: 0px;
    }

    ${InputContainer} {
        :last-child {
            margin-bottom: 0px;
        }
    }
    ${Header} {
        text-align: left;
        color: #000;
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 6px;
        justify-content: left;
        svg {
            position: relative;
            top: 1px;
        }
    }
    .automation_form_req {
        padding: 0 !important;
        margin: 0 !important;
    }
    .automation_condition_span {
        position: relative;
    }
`
export const PopupContainer = styled.div`
    width: 100%;
    float: left;
`
export const DropdownLi = styled.div`
    width: 100%;
    float: left;
    display: inline-flex;
    align-items: center;
    border-bottom: 1px solid #b6b6b6;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    height: 36px;
    padding: 0 10px;
    background: #fff;
    cursor: pointer !important;
    color: #000;

    img {
        width: 14px;
        height: 14px;
        margin-right: 7px;
    }

    :first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        background: #fff;
    }
    :last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        background: #fff;
        border-bottom: 0.5px solid #fff;
    }
    :hover {
        background: #ffe5d3;
        color: #333;
        border-bottom: 1px solid #ffe5d3;
    }
`
export const DropdownUl = styled.div`
    width: 100%;
    position: ${props => (props.relative ? 'relative' : 'absolute')};
    margin-top: 0px;
    right: 0px;
    left: 0%;
    cursor: pointer;
    padding: 0px;
    overflow-y: scroll;
    max-height: 212px;
    margin-bottom: 0px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    z-index: 2;
    margin-bottom: 0px;
    word-break: break-all;
`
export const DropdownUlWrapper = styled.div`
    width: 100%;
    float: left;
    position: relative;
    margin-bottom: 0px;
`
export const DropdownWrapper = styled.div`
    width: 100%;
    float: left;
    ${InputContainer} {
        ${Input} {
            padding-right: 40px;
            :hover {
                outline: 0;
                border: 1px solid #eb974e;
                box-shadow: 0px 3px 6px #00000029;
            }
            :focus {
                background: #ffffff 0% 0% no-repeat padding-box;
                border: 1px solid #b9b9b9;
                box-shadow: none;
            }
            :active {
                background: #ffffff 0% 0% no-repeat padding-box;
                border: 1px solid #b9b9b9;
                box-shadow: none;
            }
        }
        ${CancelButton} {
            width: 16px;
            height: 16px;
            display: flex;
            right: 10px;
            top: 16px;
            svg {
                width: 16px;
                height: 16px;
                color: #555555;
                fill: #555555;
            }
        }
    }
`
export const HoverInputContainer = styled.div`
    width: 100%;
    float: left;
    ${InputContainer} {
        :last-child {
            margin-bottom: 0px;
        }
        :hover {
            ${CancelButton} {
                display: block;
                cursor: pointer;
            }
            ${Input} {
                box-shadow: 0px 3px 6px #00000029;
                border: 1px solid #eb974e;
                transition: 0.5s ease-in;
            }
        }
        :focus {
            ${CancelButton} {
                display: block;
                cursor: pointer;
            }
            ${Input} {
                box-shadow: 0px 3px 6px #00000029;
                border: 1px solid #eb974e;
                transition: 0.5s ease-in;
            }
        }
    }
`
export const PopupWrapper = styled.div`
    width: 480px;
    float: right;
    height: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    position: fixed;
    display: flex;
    height: calc(100vh - 66px);
    right: 2px;
    padding: 20px;
    z-index: 1100;
    bottom: 2px;
    flex-direction: column;
`
export const EmptyDiv = styled.div`
    width: 100%;
    float: left;
`
export const PopupScrollHeight = styled.div`
    width: 100%;
    float: left;
    height: calc(100vh - 225px);
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ::-webkit-scrollbar {
        width: 6px;
        display: block;
    }
    ::-webkit-scrollbar-thumb {
        background: #ccc;
        height: 80px;
        border-radius: 5px;
    }
    ::-webkit-scrollbar-track {
        background: inherit;
    }
`
export const ButtonContainer = styled.div`
    width: 100%;
    float: left;
    position: relative;
    bottom: 0;
    background: #fff;
    display: flex;
    justify-content:  ${props => (props.align && props.align == 'left' ? 'flex-start' : 'center')};
    padding-top: ${props => (props.padding ? '10px' : '20px')};
    padding-bottom: 20px;
    // margin-left: 35px;

        ${Button}{
            text-align:center;
            justify-content: center;
            margin:0px;
            :first-child{
                margin-right:10px;
            }
        }
        ${SmallButton}{
            text-align:center;
            justify-content: center;
            margin:0px;
            :first-child{
                margin-right:10px;
            }
        }
    }
    
`
