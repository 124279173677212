export enum Action {
    View,
    ErrorPage,
    Loading
}

export enum RequestType {
    AllFlows = 'AllFlows',
    IndividualFlow = 'InduvidualFlow'
}

export type ConversationT = {
    query_created_at: string
    conversation_id: string
    feedback: string
    status: ("ongoing" | "resolved" | "escalated" | "abandoned" | "unresolved" | "faq_answered" | "ignored")
    dialog_id: string
    dialog_name: string
    root_req_id:string
    ticket_created:boolean
}

export type EmailConversationT = {
    _id: string
    start_time: string
    end_time: string
    chat_channel: string
    conversation_id: string
    num_of_messages: number
    feedback: string
    status: 'Resolved' | 'Escalated' | 'Abandoned'
    dialog_id: string
    dialog_name: string
    user: {
        _id: string
        bot_id: null | string
        user_id: string
        __v: number
        user_email: string
        user_firstname: string
        user_lastname: string
    }
}

export type MostUsedIntents = {
    intent: string
    count: number
}

export type MostUsedEntities = {
    entity: string
    count: number
}

export type BarchartData = {
    date: string
    num_of_messages: number
    num_of_conversations: number
}

export type BaseConversationT = {
    feedback: (string|null)[],
    dialogCount:number,
    faqDialogCount:number,
    resolvedCount:number,
    abandonedCount:number,
    escalatedCount:number,
    unresolvedCount:number,
    ongoingCount:number,
    faqAnsweredCount:number,
    ignoredCount:number,
    totalCount:number,
    endTime:string,
    conversation_id:string,
    chat_channel:string,
    user_email:string,
    user_id:string
    user: {
        _id: string
        bot_id: null | string
        user_id: string
        __v: number
        user_email: string
        user_firstname: string
        user_lastname: string
    }

}

export type DefaultResponseT = {
    conversations: EmailConversationT[]
    top_intents: MostUsedIntents[]
    top_entities: MostUsedEntities[]
    feedbacks: feedbacksT
    barchart: BarchartData[]
    baseConversation: BaseConversationT[]
    totalEmailCount: number
    totalSessionCount: number
}

export type LogsT = {
    request: WatsonRequestT
    response: WatsonResponseT
    language: string
    workspace_id: string
    request_timestamp: string
    response_timestamp: string
    log_id: string
}

export type ChartDataT = {
    Failure: number
    Success: number
    date: string
}

export type MostandLeastExecuted = {
    totalExecutions: number
    flowId: string
    name: string
}

export type WatsonRequestT = {
    input: {
        text: string
    }
    context: any
}

export type WatsonResponseT = {
    intents: any
    entities: any
    input: {
        text: string
    }
    output: {
        generic: GenericResponseT[]
        text: string[]
        nodes_visited: string[]
        log_messages: string[]
    }
    context: ContexT
}

export type GenericResponseT = {
    response_type: string
    text: string
}

export type ContexT = {
    conversation_id: string
    system: {
        initialized: boolean
        dialog_stack: dialog_stackT[]
        dialog_turn_counter: number
        dialog_request_counter: number
        _node_output_map: any
        branch_exited: boolean
        branch_exited_reason: string
    }
}

type dialog_stackT = {
    dialog_node: string
}

type feedbacksT = { satisfied: number; not_satisfied: number }

export const DefaultResponse: DefaultResponseT = {
    conversations: [],
    top_intents: [],
    top_entities: [],
    feedbacks: { not_satisfied: 0, satisfied: 0 },
    barchart: [],
    totalEmailCount: 0,
    totalSessionCount: 0,
    baseConversation:[]
}

export const Conversation: ConversationT = {
    query_created_at:'',
    conversation_id: '',
    feedback: '',
    dialog_id: '',
    dialog_name: '',
    root_req_id:"",
    status: 'resolved',
    ticket_created:false
}

export const EmailConversation: EmailConversationT = {
    _id: '',
    num_of_messages: 0,
    start_time: '',
    end_time: '',
    chat_channel: '',
    conversation_id: '',
    feedback: '',
    dialog_id: '',
    dialog_name: '',
    status: 'Resolved',
    user: { __v: 0, _id: '', bot_id: '', user_email: '', user_firstname: '', user_id: '', user_lastname: '' }
}

export type PopupResponseT = {
    logs: LogsT[]
    pagination: {
        next_url: string
        next_cursor: string
    }
}

export const PopupResponse: PopupResponseT = {
    logs: [],
    pagination: {
        next_url: '',
        next_cursor: ''
    }
}

export const Logs: LogsT = {
    request: {
        input: {
            text: ''
        },
        context: {}
    },
    response: {
        intents: [],
        entities: [],
        input: {
            text: ''
        },
        output: {
            generic: [],
            text: [],
            nodes_visited: [],
            log_messages: []
        },
        context: {
            conversation_id: '',
            system: {
                initialized: true,
                dialog_stack: [],
                dialog_turn_counter: 0,
                dialog_request_counter: 0,
                _node_output_map: {},
                branch_exited: true,
                branch_exited_reason: ''
            }
        }
    },
    language: '',
    workspace_id: '',
    request_timestamp: '',
    response_timestamp: '',
    log_id: ''
}

export type ReqT = {
    page_limit: number
    sort: 'request_timestamp' | 'client_name'
}

type ReqFlowT = {
    dialog: string
}

export type AllRequestTypeT = {
    type: RequestType.AllFlows
    req: ReqT
}
export type IndividualRequestTypeT = {
    type: RequestType.IndividualFlow
    req: ReqT & ReqFlowT
}

export const All: AllRequestTypeT = {
    type: RequestType.AllFlows,
    req: {
        sort: 'request_timestamp',
        page_limit: 50
    }
}

export const Individual: IndividualRequestTypeT = {
    type: RequestType.IndividualFlow,
    req: {
        sort: 'request_timestamp',
        page_limit: 50,
        dialog: ''
    }
}

export type allFlowsT = {
    type: 'allFlows'
}

export type RequestModes = AllRequestTypeT | IndividualRequestTypeT
