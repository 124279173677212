/*eslint-disable*/
import React from 'react'

import { DownArrowCircle } from '@/common/Icons/Icons'
import { Header, HeaderChild } from '@/common/components/Header'

import { PageContext } from '../Actions/ReactContext'

export const DialogHeader = () => (
    <PageContext.Consumer>
        {({ currentDialog, DialogIndex, isDetailView, setDetailView }) => (
            <Header>
                <HeaderChild type="first">
                    <h2 className="">
                        <span className="buton_back" onClick={() => window.history.back()}>
                            <DownArrowCircle />
                        </span>
                        {currentDialog.title}
                        <span className="dialog_number">{DialogIndex}</span>
                    </h2>
                </HeaderChild>
                <HeaderChild type="second">
                    {/* {!isDetailView ? (
                        <button className="btn btn-primary entity_right_icon" onClick={() => setDetailView(true)}>
                            <GroupIcon />
                            <span>Detail View</span>
                        </button>
                    ) : (
                        <button className="btn btn-primary entity_right_icon" onClick={() => setDetailView(false)}>
                            <ListviewIcon />
                            <span>Icon View</span>
                        </button>
                    )} */}
                </HeaderChild>
            </Header>
        )}
    </PageContext.Consumer>
)
