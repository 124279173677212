/*eslint-disable*/
import React, { useEffect, useRef, useState } from 'react'

import {
    AddIcon,
    EntityIcon,
    HrLeftArrow,
    HrRightArrow,
    MinusIcon,
    NewTrymeIcon,
    TrymeCloseIcon,
    TrymeIcon,
    UserIconAnalyticsMenu
} from '@/common/Icons/Icons'
import { SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { ButtonWrapper } from '@/common/styled/Dialogue.Dumb'

import Typing from '@/common/images/typing.gif'
import AssistantMd from '@skitter/assistant_md'
import { convertToMDText } from '@/common/utils/utils'

type optionsT = {
    label: String
}

type optionT = {
    response_type: 'option'
    title: String
    options: optionsT[]
}

type textT = {
    response_type: 'text'
    text: String
}

type outputT = optionT | textT

type MessageT = {
    text: String
    intents: String[]
    entities: String[]
    context: any
    output: outputT[]
    masked: boolean
    faq:any[]
    req_id?:string
}

type TryItP = {
    message: MessageT[]
    close: Function
    loading: boolean
    scollId?:string
    agentHandoverLog?: boolean
}

function AdaptiveCard({ output } : any){
    return(
        <div className="position-realtive tryit_wrapper_card">
                <div className="tryit_cards">
                    <div className="tryit_cards_top">
                        <div className="tryit_cards_top_left">
                            {output.image && (
                                    <img
                                        src={
                                            output.image.image_url
                                        }
                                        alt={'image'}
                                    />
                                )}
                        </div>
                        <div className="tryit_cards_top_right" style={{ paddingLeft: '20px' }}>
                                <h4 className="word_break">
                                    <AssistantMd mdText={convertToMDText(output.title)} />
                                </h4>
                            {output.description && (
                                <p>
                                    <AssistantMd mdText={convertToMDText(output.description)} />
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="tryit_cards_wrapper">
                        <div className="tryit_cards_left">
                            <div className="tryit_ticket_wrapper tryit_content_wrapper">
                                {output.fields && (
                                    <div className="tryit_ticket_details_wrapper">
                                        {output.fields.map((field: any, key: number) => (
                                            <div className="tryit_ticket_details" key={key}>
                                                <h6>
                                                    <AssistantMd mdText={convertToMDText(field.title)} />
                                                </h6>
                                                <p>
                                                    <AssistantMd mdText={convertToMDText(field.value)} />
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {output.buttons && (
                                    <div className="tryit_ticket_details_wrapper">
                                        {output.buttons.map((field: any, key: number) => {
                                            return field.type === 'button' ? (
                                                <div className="tryit_ticket_details">
                                                    <SmallButton
                                                        style={{ marginTop: 6 }}
                                                        primary
                                                        key={key}
                                                        onClick={() => window.open(field.url.value)}
                                                    >
                                                        <label>
                                                            <AssistantMd mdText={convertToMDText(field.text)} />
                                                        </label>
                                                    </SmallButton>
                                                </div>
                                            ) : null
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <span className="adaptive_tail_icon">
                    {' '}
                    <svg x="0px" y="0px" viewBox="0 0 7.9 7.9" xmlSpace="preserve">
                        <path d="M7.9 8c0-4.4-3.6-8-8-8h8v8z" fill="currentColor" />
                    </svg>
                </span>
            </div>
    )
}

const addOne = (x: any) => x + 1
const minus = (x: any, y: any) => x - y
const minusOne = (x: any) => x - 1
const add = (x: any, y: any) => x + y


const ReceivedCarousalOutput = ({ output }: any) => {
    let [activeSlide, setActiveSlide] = useState({ index: 0, transformWidth: 0 })
    let currentSlideEls: any = useRef([])

    const slide = (getNextSlideIndex: any, getNextSlideTransform: any) => () => {
        let nextIndex = getNextSlideIndex(activeSlide.index)
        let currentEls = currentSlideEls.current
        if (nextIndex >= currentEls.length || nextIndex < 0) {
            return
        }
        let nextEl = currentSlideEls.current[nextIndex] as any
        let width = nextEl.offsetWidth
        setActiveSlide({
            index: nextIndex,
            transformWidth: getNextSlideTransform(activeSlide.transformWidth, width)
        })
    }

    let slideRight = slide(addOne, minus)
    let slideLeft = slide(minusOne, add)

    return (
        <div className="tryit_understand_parent tryit_card_wrapper">
            <span>
                <NewTrymeIcon />
            </span>
            <div className="carousal_box">
                <div className="carousal_container">
                    <div
                        className="carousal_slide_container"
                        style={{
                            transform: `translate3d(${activeSlide.transformWidth}px, 0px, 0px)`
                        }}
                    >
                        {output.values.map((adaptive_card: any, i: number) => (
                            <div
                                className="slide flex justify-between flex-col"
                                ref={el => (currentSlideEls.current[i] = el)}
                            >
                                <AdaptiveCard output={adaptive_card as any}/>
                            </div>
                        ))}
                    </div>
                    <div className="carousal_arrows">
                        <div
                            className="left_arrow"
                            onClick={slideLeft}
                            style={activeSlide.index == 0 ? { visibility: 'hidden' } : {}}
                        >
                            <HrLeftArrow />
                        </div>
                        <div
                            className="right_arrow"
                            onClick={slideRight}
                            style={
                                activeSlide.index == currentSlideEls.current.length - 1
                                    ? { visibility: 'hidden' }
                                    : {}
                            }
                        >
                            <HrRightArrow />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function TryIt(props: TryItP) {
    // console.log('TryIt', props)
    const { message, close, loading } = props

    useEffect(()=>{
        const scrollElement = document.getElementById(props.scollId ?props.scollId:"")
        props.scollId && scrollElement && scrollElement.scrollIntoView({
            behavior: 'smooth'
        });
    },[message])

    const renderResponse = (output: any) => {
        if (output.options) {
            return (
                <React.Fragment>
                    {output.title != '' ? (
                        <div className="tryit_understand_parent">
                            <span>
                                <NewTrymeIcon />
                            </span>
                            <p className="tryit_understand"><AssistantMd mdText={convertToMDText(output.title)}/></p>
                            <label className="tail">
                                <svg x="0px" y="0px" viewBox="0 0 7.9 7.9">
                                    <path d="M7.9 8c0-4.4-3.6-8-8-8h8v8z"></path>
                                </svg>
                            </label>
                        </div>
                    ) : null}
                    <div className="tryit_option">
                        {output.options.map((option: optionsT, index: number) => (
                            <button
                                key={index}
                                className="btn btn-primary logs_tryit_buton_center"
                                style={{ textAlign: 'center' }}
                                onClick={() => {}}
                            >
                                <div className="logs_tryit_buton_center_div"><AssistantMd mdText={convertToMDText(option.label)}/></div>
                            </button>
                        ))}
                    </div>
                </React.Fragment>
            )
        } else if(output.adaptive_card == "adaptive_card" || output.adaptive_card == "carousal"){
            return output.adaptive_card == "carousal" ? 
            <ReceivedCarousalOutput output={output}/> 
            : 
            <div className="tryit_understand_parent tryit_card_wrapper">
                <span>
                    <NewTrymeIcon />
                </span>
                <AdaptiveCard output={output}/>
            </div>
        } else {
            return (
                <div className="tryit_understand_parent ">
                    <span>
                        <NewTrymeIcon />
                    </span>
                    <div className="tryit_bot_msg">
                        <pre className="tryit_understand"><AssistantMd mdText={convertToMDText(output.text.text)}/></pre>
                        <svg x="0px" y="0px" viewBox="0 0 7.9 7.9" xmlSpace="preserve">
                            <path d="M7.9 8c0-4.4-3.6-8-8-8h8v8z" fill="currentColor" />
                        </svg>
                    </div>
                </div>
            )
        }
    }

    const header = (agentHandoverLog?: boolean) => (
        <div className="tryit_header">
            <h4>{agentHandoverLog ? 'Agent Handover Log' : 'Chatbot Log'}</h4>
            <p>Track end user session with chatbot </p>
        </div>
    )

    const identifications = (msg: MessageT) => (
        <div className="user_entity tryit_content_hide_int_entity">
            <p>
                <span>Intent: </span>
                {msg.intents.length ? (
                    msg.intents.map((intent: String) => <h6>{intent}</h6>)
                ) : (
                    <div className="tryit_content_hide_int_entity">
                        <h6>irrelevent</h6>
                    </div>
                )}
            </p>
            <p>
                <span>Entity: </span>
                {msg.entities.length ? (
                    msg.entities.map((entity: String) => (
                        <div className="tryit_content_hide_int_entity">
                            <h6>{entity}</h6>
                        </div>
                    ))
                ) : (
                    <div className="tryit_content_hide_int_entity">
                        <h6>irrelevent</h6>
                    </div>
                )}
            </p>
            <p>
                <span>FAQ: </span>
                <div className="tryit_content_hide_int_entity_faq">
                {msg.faq && msg.faq.length ? (
                    msg.faq.map((faqs: any) => (
                        <div className="tryit_content_hide_int_entity">
                            <h6>{faqs.name}</h6>
                        </div>
                    ))
                ) : (
                    <div className="tryit_content_hide_int_entity">
                        <h6>irrelevent</h6>
                    </div>
                )}
                </div>
            </p>
        </div>
    )

    const mapMessage = (msg: MessageT) => {
        return (
            <React.Fragment>
                <UserText msg={msg} identifications={identifications(msg)} />

                <div className="tryit_understand_parent">
                    {msg.context && msg.context.SYS_FLOW_EXECUTION_INFO ? (
                        <div className="tryme_autoamtion">
                            <div className="tryme_autoamtion_header">
                                <h5>Automation Executed</h5>
                            </div>
                            <div className="tryme_autoamtion_content">
                                <div className="tryme_autoamtion_content_span">
                                    <div className="tryme_autoamtion_left">Selected Automation</div>
                                    <div className="tryme_autoamtion_right">
                                        <div className="tryme_autoamtion_center">:</div>
                                        {msg.context.SYS_FLOW_EXECUTION_INFO.automationNode.name}
                                    </div>
                                </div>
                                <div className="tryme_autoamtion_content_span">
                                    <div className="tryme_autoamtion_left">Automation Name</div>
                                    <div className="tryme_autoamtion_right">
                                        <div className="tryme_autoamtion_center">:</div>
                                        {msg.context.SYS_FLOW_EXECUTION_INFO.automationName}
                                    </div>
                                </div>
                                <div className="tryme_autoamtion_content_span">
                                    <div className="tryme_autoamtion_left">Execution Status</div>
                                    <div className="tryme_autoamtion_right">
                                        <div className="tryme_autoamtion_center">:</div>
                                        {msg.context[msg.context.SYS_FLOW_EXECUTION_INFO.output_variable].output
                                            .executionContext.output.success
                                            ? 'Success'
                                            : 'Failure'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
                {msg.output.map((res: outputT) => renderResponse(res))}
            </React.Fragment>
        )
    }

    return (
        <>
            <div className="tryit_popup-over-overlay"></div>
            <div className="tryit_popup">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="tryit_panel">
                            <div className="tryit_close" onClick={() => close()}>
                                <TrymeCloseIcon />
                            </div>

                            {header(props.agentHandoverLog)}
                            {/* <div className="tryit_message module_tryit_message">
                                <div className="tryit_understand_parent">
                                    <span>
                                        <NewTrymeIcon />
                                    </span>

                                    <div className="tryit_bot_msg">
                                        <pre className="tryit_understand">Hi Vinod, how may i help you?</pre>
                                        <svg x="0px" y="0px" viewBox="0 0 7.9 7.9" xmlSpace="preserve">
                                            <path d="M7.9 8c0-4.4-3.6-8-8-8h8v8z" fill="currentColor" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="tryit_dropdown_user">
                                    <div className="tryit_password_parent">
                                        <div className="user_data_input">
                                            <div className="tryit_password tryit_password_p">
                                                <div className="tryit_user_msg">
                                                    <p>hi</p>
                                                    <svg x="0px" y="0px" viewBox="0 0 7.9 7.9" xmlSpace="preserve">
                                                        <path d="M-.1 8c0-4.4 3.6-8 8-8h-8v8z" fill="currentColor" />
                                                    </svg>
                                                </div>
                                                <div className="show_details_user">
                                                    <div className="tryit_content_show_one">
                                                        <div>
                                                            <h6>
                                                                <span>
                                                                    <AddIcon />
                                                                </span>
                                                                Show Details
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="width_17_user">
                                                <span className="entity_name_user_span">
                                                    <UserIconAnalyticsMenu />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tryit_dropdown_user">
                                    <div className="tryit_password_parent">
                                        <div className="user_data_input">
                                            <div className="tryit_password tryit_password_p">
                                                <div className="tryit_user_msg">
                                                    <p>request details</p>
                                                    <svg x="0px" y="0px" viewBox="0 0 7.9 7.9" xmlSpace="preserve">
                                                        <path d="M-.1 8c0-4.4 3.6-8 8-8h-8v8z" fill="currentColor" />
                                                    </svg>
                                                </div>
                                                <div className="hide_details_user">
                                                    <div className="tryit_content_show_one">
                                                        <div>
                                                            <h6>
                                                                <span>
                                                                    <MinusIcon />
                                                                </span>
                                                                Hide Details
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className="user_entity">
                                                        <p>
                                                            <span>Intent: </span>Sysaid_request_details
                                                        </p>
                                                        <p>
                                                            <span>Entitiy: </span>Irrelevant
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="width_17_user">
                                                <span className="entity_name_user_span">
                                                    <UserIconAnalyticsMenu />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tryit_understand_parent ">
                                    <span>
                                        <NewTrymeIcon />
                                    </span>
                                    <div className="tryit_bot_msg">
                                        <pre className="tryit_understand">
                                            Sure! Vinod I will help you unlock your account.
                                        </pre>
                                        <svg x="0px" y="0px" viewBox="0 0 7.9 7.9" xmlSpace="preserve">
                                            <path d="M7.9 8c0-4.4-3.6-8-8-8h8v8z" fill="currentColor" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="tryit_understand_parent tryit_card_wrapper">
                                    <span>
                                        <NewTrymeIcon />
                                    </span>
                                    <div className="position-realtive tryit_wrapper_card">
                                        <div className="tryit_cards">
                                            <div className="tryit_cards_top">
                                                <div className="tryit_cards_top_left">
                                                    <img src="" />
                                                </div>
                                                <div className="tryit_cards_top_right">
                                                    <h4>Ticket Details</h4>
                                                    <p>Please find the ticket details below</p>
                                                    <div className="time_stamp">10:12 am</div>
                                                </div>
                                            </div>
                                            <div className="tryit_cards_wrapper">
                                                <div className="tryit_cards_left">
                                                    <div className="tryit_ticket_wrapper tryit_content_wrapper">
                                                        <div className="tryit_ticket_details_wrapper">
                                                            <div className="tryit_ticket_details">
                                                                <h6>Ticket ID:</h6>
                                                                <p> 342423</p>
                                                            </div>
                                                            <div className="tryit_ticket_details">
                                                                <h6>Subject: </h6>
                                                                <p>Account Locked</p>
                                                            </div>
                                                        </div>
                                                        <div className="tryit_ticket_details_wrapper">
                                                            <div className="tryit_ticket_details">
                                                                <h6>Ticket Details:</h6>
                                                                <p>
                                                                    {' '}
                                                                    I’m locked out of my account. Please unlock my
                                                                    account.
                                                                </p>
                                                                <SmallButton style={{ marginTop: 6 }} primary>
                                                                    <label>View Ticket</label>
                                                                </SmallButton>
                                                            </div>
                                                            <div className="tryit_ticket_details">
                                                                <h6>Status: </h6>
                                                                <p>Account Locked</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="adaptive_tail_icon">
                                            {' '}
                                            <svg x="0px" y="0px" viewBox="0 0 7.9 7.9" xmlSpace="preserve">
                                                <path d="M7.9 8c0-4.4-3.6-8-8-8h8v8z" fill="currentColor" />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                                <div className="tryit_understand_parent">
                                    <span>
                                        <NewTrymeIcon />
                                    </span>
                                    <div className="tryit_bot_msg">
                                        <pre className="tryit_understand">
                                            Pls provide your email to unlock your account.
                                        </pre>
                                        <svg x="0px" y="0px" viewBox="0 0 7.9 7.9" xmlSpace="preserve">
                                            <path d="M7.9 8c0-4.4-3.6-8-8-8h8v8z" fill="currentColor" />
                                        </svg>
                                    </div>
                                </div>

                                <div className="tryit_understand_parent">
                                    <span>
                                        <NewTrymeIcon />
                                    </span>

                                    <div className="tryit_bot_msg">
                                        <pre className="tryit_understand">
                                            Pls provide your email to unlock your account.
                                        </pre>
                                        <svg x="0px" y="0px" viewBox="0 0 7.9 7.9" xmlSpace="preserve">
                                            <path d="M7.9 8c0-4.4-3.6-8-8-8h8v8z" fill="currentColor" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="tryit_something">
                                    <div className="tryit_something_input">
                                        <input type="text" name="Enter Text" placeholder="Enter Text" />
                                        <span className="selectFileButton">
                                            <input id="___SelectFileButton___" type="file" hidden />
                                            <span>
                                                <svg x="0px" y="0px" viewBox="0 0 24 24" xmlSpace="preserve">
                                                    <path
                                                        d="M4.6 22.3c-1 0-2-.4-2.7-1.1-.8-.8-1.3-1.9-1.2-3 .1-1.2.6-2.3 1.7-3.3L13.8 3.4c1.1-1.1 2.5-1.6 4-1.6s2.9.6 4 1.6c2.2 2.2 2.2 5.7 0 7.9l-10 10c-.3.3-.7.3-1 0-.3-.3-.3-.7 0-1l10-10c1.6-1.6 1.6-4.3 0-5.9-.8-.8-1.8-1.2-3-1.2-1.1 0-2.2.4-3 1.2L3.3 15.8c-.8.8-1.2 1.6-1.2 2.4 0 .7.2 1.4.8 2 1.1 1.1 3 .9 4.3-.5l9.4-9.4c.3-.3.4-.6.4-.9 0-.3-.1-.7-.4-.9-.5-.5-1.3-.5-1.8 0l-8 8c-.3.3-.7.3-1 0-.3-.3-.3-.7 0-1l8-8c1.1-1.1 2.8-1.1 3.8 0 .5.5.8 1.2.8 1.9s-.3 1.4-.8 1.9l-9.4 9.4c-1 1-2.3 1.6-3.6 1.6z"
                                                        fill="fillcolor"
                                                    />
                                                </svg>
                                            </span>
                                            <span className="_send_icon">
                                                <span className="btn-deactivaed">
                                                    <svg x="0px" y="0px" viewBox="0 0 32 32" xmlSpace="preserve">
                                                        <path d="M24 30c-.2 0-.3 0-.5-.1l-12-7c-.5-.3-.6-.9-.4-1.4.3-.5.9-.6 1.4-.4l10.8 6.3 6.1-24.6L3.2 16l5.6 3.1c.5.3.7.9.4 1.4-.3.5-.9.7-1.4.4l-7.3-4c-.3-.2-.5-.5-.5-.9s.2-.7.6-.9l30-15c.3-.2.8-.1 1.1.1.3.2.4.6.3 1l-7 28c-.1.3-.3.6-.6.7-.1.1-.3.1-.4.1z" />
                                                        <path d="M12 32h-.3c-.4-.1-.7-.5-.7-1v-9c0-.6.4-1 1-1s1 .4 1 1v5.7l2.2-3.3c.3-.5.9-.6 1.4-.3.5.3.6.9.3 1.4l-4 6c-.3.3-.6.5-.9.5z" />
                                                        <path d="M12 23c-.2 0-.5-.1-.7-.3-.4-.4-.4-1-.1-1.4l19-21c.4-.4 1-.4 1.4-.1.4.4.4 1 .1 1.4l-19 21c-.2.3-.4.4-.7.4z" />
                                                    </svg>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div style={{ float: 'left', clear: 'both', marginBottom: 15, width: '100%' }} />
                            </div> */}

                            <div className="tryit_message module_tryit_message">
                                {loading ? (
                                    <div className="tryit_understand_parent">
                                        <span>
                                            <TrymeIcon />
                                        </span>
                                        <p className="tryit_understand_typing">
                                            <div className="snippet" data-title=".dot-flashing">
                                                <div className="stage">
                                                    <div className="dot-flashing"></div>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                ) : message.length == 0 ?
                                    <div
                                        className={'bot_training_wrapper'}
                                    >
                                        <p style={{width: "70%" }}>{props.agentHandoverLog ?"No conversation happened between the agent and the user":"No conversation to show"}</p>{' '}
                                    </div>
                                : (
                                    message.map((msg: MessageT, i: number) => mapMessage(msg))
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

type userTextP = {
    msg: MessageT
    identifications: any
}

const UserText = (props: userTextP) => {
    const { msg, identifications } = props
    const [showDetails, setShowDetails] = useState<boolean>(false)
    return (
        <div className="tryit_dropdown_user" id={msg.req_id ? msg.req_id : ""}>
            <div className="tryit_password_parent">
                <div className="user_data_input">
                    <div className="tryit_password tryit_password_p">
                        <div className="tryit_user_msg">
                            <p>
                                {msg.text == ''
                                    ? msg.context && msg.context.SYS_FLOW_EXECUTION_INFO
                                        ? 'Execute Automation'
                                        : msg.context && msg.context.SYS_UPLOADED_FILE
                                        ? 'File uploaded by user'
                                        : msg.masked && msg.masked == true
                                        ? '*******'
                                        : 'Start Session'
                                    : msg.text}
                            </p>
                            <svg x="0px" y="0px" viewBox="0 0 7.9 7.9" xmlSpace="preserve">
                                <path d="M-.1 8c0-4.4 3.6-8 8-8h-8v8z" fill="currentColor" />
                            </svg>
                        </div>
                        {/* Show Details Start */}
                        {msg.text != '' ? (
                            <>
                                {showDetails ? (
                                    <div className="hide_details_user">
                                        <div className="tryit_content_show_one">
                                            <div>
                                                <h6 onClick={() => setShowDetails(false)}>
                                                    <span>
                                                        <MinusIcon />
                                                    </span>
                                                    Hide Details
                                                </h6>
                                            </div>
                                        </div>
                                        {identifications}
                                    </div>
                                ) : (
                                    <div className="show_details_user">
                                        <div className="tryit_content_show_one">
                                            <div>
                                                <h6 onClick={() => setShowDetails(true)}>
                                                    <span>
                                                        <AddIcon />
                                                    </span>
                                                    Show Details
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : null}
                        {/* Show Details End */}
                    </div>
                    <div className="width_17_user">
                        <span className="entity_name_user_span">
                            <UserIconAnalyticsMenu />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
