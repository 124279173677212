import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

// import { workspace } from '@/Integrations/Widget';
// import { SelectLanguage } from '@/Assistant/Construction/BotCreation'
import { DropdownSearchWrapper } from '@/Assistant/Construction/Constructor'
import { AssistantT } from '@/Assistant/Construction/kind'
import { createAssistant } from '@/Assistant/Mechanisms/action'
import { JourneyState, LoadedState } from '@/Journey/Journey'
import { useJourney } from '@/Journey/JourneyContext'
import { DialogIcon, QaIconWithCircle, SupportIcon, UserIconAnalytics } from '@/common/Icons/Icons'
import { Loader } from '@/common/components/Loader'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import {
    BotGridsContainer,
    BotWrapper,
    BoxWrapperBg,
    ButtonWithImage,
    ButtonWrapper,
    DownloadBotHelp,
    DownloadContent,
    DropdownContainer,
    ImageSpan,
    OrSticky,
    WrapperBgFixed
} from '@/common/styled/GetStarted.Dumb'

import { MOCK_USER_API, formatDateInCard, getProductId } from '@/common/utils/utils'
import { getJson, loginApiURL } from '@/common/utils/api-utils'
import { DropdownWithSearch } from '../../TrainingModule/TrainingModule'
import { MainHeader } from '../Components/WelcomePageWithJourney'
import {
    BoxWrapper,
    BoxWrapperTop,
    CurrentPlanWrapper,
    DateAndTime,
    DialogCount,
    DropdownWrapper,
    HeaderWidthAuto,
    ImgWrapper,
    Input,
    InputContent,
    PlanContetnWrapper
} from './WelcomePageWithJourney'

export interface UserApi {
    phone_number: PhoneNumber
    id: number
    first_name: string
    last_name: string
    email: string
    org: string
    role: string
    country: string
}

export interface PhoneNumber {
    id: number
    mobileNo: string
    verified: boolean
    valid: boolean
    createdAt: string
    updatedAt: string
    code: string
    userId: number
}

type User = { first_name: string; last_name: string; email: string }

type LoadingUser = LoadedState<User, any>

const languages = [
    { name: 'English (United States)', type: 'english' },
    { name: 'Spanish', type: 'spanish' },
    { name: 'Greek', type: 'greek' },
    { name: 'Portuguese', type: 'portuguese' }
]

export const GetStarted = (props: RouteComponentProps<{ workspacename: string }>) => {
    const product = getProductId()

    const [userInfo, setUser] = useState<LoadingUser>({ type: 'yet-to-load' })

    // const workspace = props.match.params.workspacename
    const router = props.history

    const [name, setName] = useState<string>('IT Support Bot')
    const [selectedLanguage, setSelectedLanguage] = useState({ name: 'English (United States)', type: 'english' })

    const { workspacename: workspace } = props.match.params

    const journeyData = useJourney()

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    const greet = ['Morning', 'Good morning', 'Good afternoon', 'Good evening', 'Go to bed']

    const secondCounter = new Date().getHours()
    const minuteCounter = new Date().getMinutes()
    const secondsCounter = new Date().getSeconds()

    const computedHour = String(secondCounter).length === 1 ? `0${secondCounter}` : secondCounter
    const computedMinute = String(minuteCounter).length === 1 ? `0${minuteCounter}` : minuteCounter
    const computedSeconds = String(secondsCounter).length === 1 ? `0${secondsCounter}` : secondsCounter

    const greetMessage = (hrs: number) => {
        console.log('hrs', hrs)
        let msg = 'Morning'
        // Before 6am
        if (hrs < 7) return 'Morning'
        // After 6am
        else if (hrs < 13) return 'Good Morning'
        // After 12pm
        else if (hrs < 18) return 'Good afternoon'
        // After 5pm
        else if (hrs < 23) return 'Good evening'
        else return 'Go to bed'
        return msg
    }

    const [day, setDay] = useState<any>({ hour: computedHour, minute: computedMinute, date: new Date() })
    let isEmptyField = name !== '' && name.length < 30 && selectedLanguage.name !== ''
    useEffect(() => {
        let intervalId: number

        intervalId = setInterval(() => {
            const hourCounter = new Date().getHours()
            const minuteCounter = new Date().getMinutes()
            const secondsCounter = new Date().getSeconds()

            const computedHour = String(hourCounter).length === 1 ? `0${hourCounter}` : hourCounter
            const computedMinute = String(minuteCounter).length === 1 ? `0${minuteCounter}` : minuteCounter
            const computedSeconds = String(secondsCounter).length === 1 ? `0${secondsCounter}` : secondsCounter
            console.log('hour', {
                hour: computedHour,
                minute: computedMinute,
                seconds: computedSeconds,
                date: new Date()
            })

            setDay({ hour: computedHour, minute: computedMinute, seconds: computedSeconds, date: new Date() })
        }, 1000)

        return () => clearInterval(intervalId)
    }, [])

    useEffect(() => {
        callApi()
    }, [])

    function callApi() {
        if (MOCK_USER_API) {
            const userNeededDetails: User = {
                email: 'selva.g@skitter.in',
                first_name: 'selva',
                last_name: 'G'
            }
            setUser({ type: 'success', value: userNeededDetails })
        } else {
            getJson(loginApiURL('/user_info'))
                .then((user: UserApi) => {
                    const userNeededDetails: User = {
                        email: user.email,
                        first_name: user.first_name,
                        last_name: user.last_name
                    }
                    setUser({ type: 'success', value: userNeededDetails })
                })
                .catch(err => {
                    setUser({ type: 'error', error: err })
                })
        }
    }

    const createBot = () => {
        if (journeyData.type === 'success') {
            const interperter = journeyData.value

            createAssistant(workspace, { name, lang_name: selectedLanguage.type })
                .then((res: AssistantT) => {
                    console.log('value', res)
                    const name = res.name
                    const id = res.id
                    interperter.send({
                        type: 'EVENT::BOT::CREATED',
                        tag: 'BOT_EVENT',
                        botId: id,
                        botName: name,
                        journeyType: 'Main'
                    })
                    // window.location.reload()
                })
                .catch(error => {
                    // console.log('eerror', error)
                    // cb(error)
                })
        }
    }

    if (userInfo.type === 'loading' || userInfo.type === 'yet-to-load') {
        return <Loader.PageLoader show={true} />
    }

    if (product === "AUTOMATE") {
        router.push(`/workspace/${workspace}/welcome`)
    }

    if (journeyData.type !== 'success' && product !== "AUTOMATE") {
        router.push(`/workspace/${workspace}/journey`)
    }

    if (journeyData.type === 'success' && journeyData.value.state().value.type === 'SUB'  && product !== "AUTOMATE") {
        router.push(`/workspace/${workspace}/welcome`)
    }

    // if (journeyData.type == 'success' && journeyData.value.state().value.type === 'MAIN') {
    //     const journeyD = journeyData.value.state().value
    //     if (journeyD.type === 'MAIN' && journeyD.JOURNEY !== 'JOURNEY::BOT_CREATED') {
    //         setTimeout(() => router.push(`/workspace/${workspace}/welcome`), 1000)
    //         return <Loader.PageLoader show={true} />
    //     }
    // }

    if (journeyData.type === 'success' && journeyData.value.state().value.type === 'MAIN'  && product !== "AUTOMATE") {
        const journeyD = journeyData.value.state().value
        if (
            journeyD.type === 'MAIN' &&
            journeyD.JOURNEY !== 'JOURNEY::NOT_STARTED' &&
            journeyD.type === 'MAIN' &&
            journeyD.JOURNEY !== 'JOURNEY::BOT::DOWNLOAD::INITIATED'
        ) {
            setTimeout(() => router.push(`/workspace/${workspace}/welcome`), 3000)
            return <Loader.PageLoader show={true} />
        }

        // if (journeyD.type === 'MAIN' && journeyD.JOURNEY !== 'JOURNEY::BOT_CREATED') {
        //     setTimeout(() => router.push(`/workspace/${workspace}/welcome`), 1000)
        //     return <Loader.PageLoader show={true} />
        // }
    }

    if (userInfo.type === 'success') {
        const currentDate = day.date
        return (
            <>
                <MainHeader header={'Workativ Assistant'} />
                <WrapperBgFixed>
                    <BoxWrapperBg>
                        <BoxWrapper style={{ padding: '30px 20px 20px', boxShadow: 'none', background: '#F2F2F2' }}>
                            <BoxWrapperTop>
                                <div className="box_wrapper_top">
                                    <h2>
                                        {greetMessage(day.hour)}, {userInfo.value.first_name} <span></span>
                                    </h2>
                                    <p>Welcome and let’s start building your new bot.</p>
                                </div>
                                <DateAndTime>
                                    <h5>
                                        {' '}
                                        {days[currentDate.getDay()]}, {formatDateInCard(currentDate.toString())}
                                    </h5>
                                    <h6>
                                        {day.hour > 12 ? day.hour % 12 : day.hour}:{day.minute}:{day.seconds}{' '}
                                        {day.hour >= 12 ? 'PM' : 'AM'}
                                    </h6>
                                </DateAndTime>
                            </BoxWrapperTop>
                            <CurrentPlanWrapper>
                                <ImgWrapper>
                                    <UserIconAnalytics />
                                </ImgWrapper>
                                <PlanContetnWrapper>
                                    <HeaderWidthAuto>
                                        <h3>
                                            {' '}
                                            {userInfo.value.first_name} {userInfo.value.last_name}
                                        </h3>
                                        <p style={{ marginBottom: 0 }}>{userInfo.value.email}</p>
                                    </HeaderWidthAuto>
                                </PlanContetnWrapper>
                            </CurrentPlanWrapper>
                        </BoxWrapper>
                        <BotWrapper style={{ padding: '40px 20px 0px' }}>
                            <div className="box_wrapper_top">
                                <p>You can create a new bot from scratch or download pre-built bot from marketplace</p>
                            </div>
                            <BotGridsContainer>
                                <DropdownContainer>
                                    <DropdownWrapper>
                                        <Input
                                            padding
                                            type="text"
                                            value={name}
                                            onChange={(event: any) => {
                                                // if (keycode != 32 && metacode == false) {
                                                setName(event.target.value)
                                                // }
                                            }}
                                        />
                                        <InputContent>Bot name</InputContent>

                                        <DialogCount>
                                            <DialogIcon />
                                        </DialogCount>
                                    </DropdownWrapper>
                                    <DropdownWrapper last={true}>
                                        <DropdownSearchWrapper>
                                            <DropdownWithSearch
                                                header={'Bot Language'}
                                                keys={'name'}
                                                returnAllData
                                                placholder={'Search'}
                                                value={selectedLanguage.name}
                                                content={languages}
                                                onClick={(language: any) => {
                                                    setSelectedLanguage(language)
                                                }}
                                                size={"responsive"}
                                            />
                                        </DropdownSearchWrapper>
                                    </DropdownWrapper>
                                    <Button
                                        margin={'0px'}
                                        onClick={() => createBot()}
                                        className={!isEmptyField ? 'editor_btn_disabled' : null}
                                    >
                                        Create your Bot
                                    </Button>
                                    <DownloadContent top={'small'}>
                                        <DownloadBotHelp
                                            href="https://help.workativ.com/knowledgebase/onboarding-guide-how-to-create-a-bot-manually/"
                                            target="_blank"
                                            style={{ marginTop: '-10px' }}
                                        >
                                            <ImageSpan>
                                                <QaIconWithCircle />
                                            </ImageSpan>
                                            How to create Bot
                                        </DownloadBotHelp>
                                    </DownloadContent>
                                </DropdownContainer>

                                <OrSticky>Or</OrSticky>
                                <ButtonWrapper>
                                    <ButtonWithImage
                                        onClick={() => {
                                            if (journeyData.type === 'success') {
                                                const interperter = journeyData.value
                                                interperter.send({
                                                    type: 'EVENT::BOT::DOWNLOAD::INITIATED',
                                                    tag: 'GENERIC',
                                                    journeyType: 'Main',
                                                    refId: ''
                                                })
                                                setTimeout(() => {
                                                    router.push(
                                                        `/workspace/${workspace}/dialog/homepage?download-from-marketplace=true`
                                                    )
                                                }, 2000)
                                            }
                                        }}
                                    >
                                        <span>
                                            <SupportIcon />
                                        </span>
                                        Download from Marketplace
                                    </ButtonWithImage>
                                    <DownloadContent top={'small'}>
                                        <DownloadBotHelp
                                            href="https://help.workativ.com/knowledgebase/onboarding-guide-download-bot/"
                                            target="_blank"
                                        >
                                            <ImageSpan>
                                                <QaIconWithCircle />
                                            </ImageSpan>
                                            How to download Bot
                                        </DownloadBotHelp>
                                    </DownloadContent>
                                </ButtonWrapper>
                            </BotGridsContainer>
                        </BotWrapper>
                    </BoxWrapperBg>
                </WrapperBgFixed>
            </>
        )
    }

    return null
}
// export const GetStarted = () => {
//     return (
//         <>
//             {/* <MainHeader header={'Workativ Assistant'} />
//             <WrapperBgFixed>
//                 <BoxUser />
//             </WrapperBgFixed> */}
//             <CreatePopup />
//         </>
//     )
// }

const BoxUser = () => {
    return (
        <>
            <BoxWrapperBg>
                <BoxWrapper style={{ padding: '30px 20px 20px', boxShadow: 'none', background: '#F2F2F2' }}>
                    <BoxWrapperTop>
                        <div className="box_wrapper_top">
                            <h2>
                                Good Morning, , <span>Aaron!</span>
                            </h2>
                            <p>Welcome and let’s start building your new bot.</p>
                        </div>
                        <DateAndTime>
                            <h5>Friday, 7 May 2021</h5>
                            <h6>10:06 AM</h6>
                        </DateAndTime>
                    </BoxWrapperTop>
                    <CurrentPlanWrapper>
                        <ImgWrapper>
                            <UserIconAnalytics />
                        </ImgWrapper>
                        <PlanContetnWrapper>
                            <HeaderWidthAuto>
                                <h3>Aaron Smith</h3>
                                <p style={{ marginBottom: 0 }}> aaron.s@directinteractions.com</p>
                            </HeaderWidthAuto>
                        </PlanContetnWrapper>
                    </CurrentPlanWrapper>
                </BoxWrapper>
                {/* <CreateWorkflow /> */}
            </BoxWrapperBg>
        </>
    )
}

export interface ImageSpanProps {
    /* This prop is optional, since TypeScript won't know that it's passed by the wrapper */
    imagePadding?: 'small'
}

export interface DownloadContentProps {
    /* This prop is optional, since TypeScript won't know that it's passed by the wrapper */
    top?: 'small'
}

export interface CreateWorkflowP {
    downloadFromMarketPlace: Function
    saveWorkflow: (journey?: JourneyState) => void
}

export const CreateWorkflow = (props: CreateWorkflowP) => {
    return (
        <BotWrapper>
            <div className="box_wrapper_top">
                <p>You can create a new bot from scratch or download pre-built bot from marketplace</p>
            </div>
            <BotGridsContainer>
                <DropdownContainer>
                    <DropdownWrapper>
                        <Input padding type="text" />
                        <InputContent>Bot name</InputContent>
                        <DialogCount>
                            <DialogIcon />
                        </DialogCount>
                    </DropdownWrapper>
                    <Button margin={'0px'} onClick={props.saveWorkflow}>
                        Create your Workflow
                    </Button>
                </DropdownContainer>
                <OrSticky>Or</OrSticky>
                <ButtonWrapper>
                    <ButtonWithImage onClick={props.downloadFromMarketPlace}>
                        <span>
                            <SupportIcon />
                        </span>
                        Download from Marketplace
                    </ButtonWithImage>
                    <DownloadContent top={'small'}>
                        {' '}
                        <ImageSpan>
                            <QaIconWithCircle />
                        </ImageSpan>
                        How to download Bot from marketplace
                    </DownloadContent>
                </ButtonWrapper>
            </BotGridsContainer>
        </BotWrapper>
    )
}
