/*eslint-disable*/
import React, { useState } from 'react'
import styled from 'styled-components/macro'

import { DeleteIcon, EditIcon } from '@/common/Icons/Icons'
//ICONS IMPORT
import { Card, NewCard } from '@/common/components/Card'
//COMMON COMPONENTS IMPORT
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { List } from '@/common/components/List'
import { Loader } from '@/common/components/Loader'
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@/common/styled/Workflow.Analytics.Dumb'
import { sortAnArrayByDate, sortAnArrayByName } from '@/common/utils/common.utils'

//ACTIONS IMPORT
import { formatAMPM, formatDateInCard } from '@/common/utils/utils'
//TYPE IMPORTS
import { ActionT, ListView, PageViewsT, ViewTypeT } from '../kind'
import { IntentDelete, IntentForm } from './Appears'
// COMPONENT IMPORT
import Header from './Header'
import { DELETE_T, EDIT_T, IntentT, MULTIPLE_DELETE_T, PageStateT, VIEW } from './kind'
import Tooltip from 'react-tooltip-lite'

let Parser = require('html-react-parser')

const Wrapper = styled.div`
    width: 100%;
    float: left;
    .list_flow_span span {
        font-size: 9px;
    }
`
const TabelWrapper = styled.div`
    width: 100%;
    float: left;
    .list_flow_hr label {
        float: left;
        display: flex;
        position: absolute;
        top: 18px;
        width: 16px;
        height: 16px;
        margin-bottom: 0px;
        left: -22px;
    }
    .option-input.radio {
        top: 6px;
        position: absolute;
    }

    ${Table} {
        margin-top: 0px;
    }
    ${Thead} {
        ${Tr} {
            padding-left: 32px;
            padding-right: 20px;

            ${Th} {
                :last-child {
                    justify-content: flex-end;
                }
            }
        }
    }
    ${Tbody} {
        border-radius: 0px 0px 10px 10px;
        max-height: calc(100vh - 200px);
        height: auto;
        ${Tr} {
            height: 48px;
            padding-left: 32px;
            padding-right: 20px;
            .list_flow_span .entity_span {
                :first-child {
                    margin-right: 3px;
                    color: #000;
                    font-size: 11px;
                }
                :last-child {
                    margin-left: 3px;
                    color: #000;
                    font-size: 11px;
                }
            }
            :hover {
                ${Td}:last-child {
                    display: flex;
                }
            }

            ${Td} {
                padding: 0px;
                height: 48px;
                font-size: 12px;
                font-family: 'Gordita-Regular';
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                color: #000;
                span {
                    margin-right: 0px;
                }

                .list_flow_span_example span {
                    font-size: 12px;
                    font-family: 'Gordita-Regular';
                    color: #000;

                    span {
                        color: #f07f31;
                    }
                }
                :nth-child(1) {
                    display: flex;
                    padding-right: 20px;
                    cursor: pointer;
                    position: relative;
                }

                :nth-child(2) {
                    display: flex;
                    padding-right: 20px;
                }
                :last-child {
                    display: none;
                }
            }
        }
    }
`

interface IntentsP {
    PageState: PageStateT
    setPageState: React.Dispatch<React.SetStateAction<PageStateT>>
    intents: IntentT[]
    retry: Function
    editIntent: Function
    removeIntent: Function
    saveIntent: Function
    permissions:{
        write: boolean | null;
        delete: boolean | null;
    }
}

const Intents = ({ PageState, setPageState, intents, retry, editIntent, removeIntent, saveIntent,permissions }: IntentsP) => {
    const [viewType, setviewType] = useState<PageViewsT>(ListView)

    const [searching, setSearching] = useState<boolean>(false)

    const [searchWord, setSearchWord] = useState<string>('')

    const search = (name: string) => {
        setSearching(name.length > 0)
        setSearchWord(name)
    }
    const closeSearch = () => {
        setSearching(false)
        setSearchWord('')
    }

    const setDeleteMode = (intent: IntentT) => {
        const deleteMultiple: MULTIPLE_DELETE_T = {
            action: ActionT.MULTIPLE_DELETE,
            items: PageState.action == ActionT.SELECT ? PageState.items : []
        }

        const deleteSingle: DELETE_T = {
            action: ActionT.DELETE,
            data: intent
        }

        const setMode = PageState.action == ActionT.SELECT ? deleteMultiple : deleteSingle

        setPageState(setMode)
    }

    const isSelected = (id: string) => {
        if (PageState.action === ActionT.SELECT || PageState.action === ActionT.MULTIPLE_DELETE) {
            return PageState.items.filter((intent: string) => intent === id).length > 0
        }
        return false
    }

    const selectIntents = (intent: string) => {
        switch (PageState.action) {
            case ActionT.SELECT:
                const items: string[] =
                    PageState.items.filter((id: string) => id === intent).length > 0
                        ? PageState.items.filter((id: string) => id !== intent)
                        : [...PageState.items, intent]

                if (items.length)
                    setPageState({
                        action: ActionT.SELECT,
                        items
                    })
                else setPageState(VIEW)
                break
            default:
                setPageState({
                    action: ActionT.SELECT,
                    items: [intent]
                })

                break
        }
    }

    const showSelection = () =>
        PageState.action === ActionT.SELECT ||
        (PageState.action === ActionT.MULTIPLE_DELETE && PageState.items.length > 0)

    const setToEditMode = (id: string) => {
        const selected: IntentT[] = intents.filter((x: IntentT) => x.id === id)
        const action: EDIT_T = {
            action: ActionT.EDIT,
            id: selected[0].id,
            intent: selected[0].intent,
            description: selected[0].description
        }
        setPageState(action)
    }

    return PageState.action === ActionT.LOADING ? (
        <Loader.PageLoader show={true} />
    ) : (
        <React.Fragment>
            <IntentForm
                intents={intents}
                PageState={PageState}
                setPageState={setPageState}
                save={saveIntent}
                reset={() => {
                    if (intents.length == 0) {
                        setPageState({
                            action: ActionT.NO_DATA_FOUND,
                            loading: false,
                            intent: '',
                            description: ''
                        })
                    } else {
                        setPageState(VIEW)
                    }
                }}
            />
            <ErrorBoundary
                unauthorized={PageState.action === ActionT.UN_AUTHORIZED}
                noDataFound={{
                    show: PageState.action === ActionT.NO_DATA_FOUND,
                    noDatainfo: '<p>Empty as wind. Create your first Intent now!',
                    onClick: () => {
                        console.log('::::::::Empty as wind. Create your first Intent now!')
                        setPageState(state => ({ ...state, loading: true }))
                    },
                    btnName: 'Create an Intent'
                }}
                error={
                    PageState.action === ActionT.ERROR
                        ? { error: true, info: PageState.reason }
                        : { error: null, info: null }
                }
                render={(err: any, info: any) => {
                    return err && <Error.PageLoadingError onClick={retry} info={info} btnName={'Retry'} />
                }}
            >
                <IntentDelete PageState={PageState} remove={removeIntent} reset={() => setPageState(VIEW)} />
                <Header
                    count={intents.length}
                    changeView={setviewType}
                    PageState={PageState}
                    deselect={() => setPageState(VIEW)}
                    showModal={() => setPageState({ action: ActionT.ADD, intent: '', description: '' })}
                    viewType={viewType}
                    search={search}
                    closeSearch={closeSearch}
                    setDeleteMode={setDeleteMode}
                    permissions={permissions}
                />
                <Wrapper>
                    <section className="entity_cards" style={{height: 'calc(100vh - 80px)'}}>
                        <div className="col-md-12">
                            <div className={viewType.View === ViewTypeT.GridView ? 'row card_grid_view' : 'row'}>
                                {viewType.View == ViewTypeT.GridView ? (
                                    !searching && (
                                        <NewCard
                                            onClick={() =>
                                                setPageState({ action: ActionT.ADD, intent: '', description: '' })
                                            }
                                            disabled={PageState.action == ActionT.SELECT}
                                        />
                                    )
                                ) : (
                                    <React.Fragment>
                                        <TabelWrapper>
                                            <TableContainer>
                                                <Table>
                                                    <Thead>
                                                        <Tr>
                                                            <Th size="3">Name</Th>
                                                            <Th size="3">Description</Th>
                                                            <Th size="2.5">No. of examples</Th>
                                                            <Th size="2.5">Date</Th>
                                                            <Th size="1">Options </Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {intents &&
                                                            sortAnArrayByName(intents, 'intent')
                                                                .filter((e:IntentT) =>
                                                                    e.intent
                                                                        .toLowerCase()
                                                                        .trim()
                                                                        .includes(searchWord.toLowerCase().trim())
                                                                )
                                                                .map((intent: IntentT, i: number) => {
                                                                    // for making search keyword bold
                                                                    let reg = new RegExp(searchWord, 'gi')
                                                                    let label = searching
                                                                        ? `<h5 style='padding-top:${
                                                                              PageState.action == ActionT.SELECT
                                                                                  ? 18
                                                                                  : 0
                                                                          }px'>` +
                                                                          intent.intent +
                                                                          '</h5>'
                                                                        : `<h5 style='padding-top:${
                                                                              PageState.action == ActionT.SELECT
                                                                                  ? 18
                                                                                  : 0
                                                                          }px'>` +
                                                                          intent.intent +
                                                                          '</h5>'
                                                                    return (
                                                                        <Tr onClick={() => editIntent(intent.intent)}>
                                                                            <List
                                                                                key={i}
                                                                                showCheckBox
                                                                                checkBoxShow={showSelection()}
                                                                                render={(
                                                                                    show: boolean,
                                                                                    ToolBox: any
                                                                                ) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Td
                                                                                                size="3"
                                                                                                className={
                                                                                                    'list_flow_hr'
                                                                                                }
                                                                                            >
                                                                                                <label>
                                                                                                    {show && (
                                                                                                        <input
                                                                                                            type="radio"
                                                                                                            className="option-input radio"
                                                                                                            checked={isSelected(
                                                                                                                intent.id
                                                                                                            )}
                                                                                                            onClick={e => {
                                                                                                                e.stopPropagation()
                                                                                                                selectIntents(
                                                                                                                    intent.id
                                                                                                                )
                                                                                                            }}
                                                                                                            onChange={() => {}}
                                                                                                            style={{
                                                                                                                visibility: show
                                                                                                                    ? 'visible'
                                                                                                                    : 'hidden',
                                                                                                                border:
                                                                                                                    show &&
                                                                                                                    !isSelected
                                                                                                                        ? '#666 solid 1px'
                                                                                                                        : '#666 solid 1px'
                                                                                                            }}
                                                                                                        />
                                                                                                    )}
                                                                                                </label>
                                                                                                <div className="search_card_list">
                                                                                                    {Parser(label)}
                                                                                                </div>
                                                                                            </Td>
                                                                                            <Td
                                                                                                size="3"
                                                                                                className="list_flow_p"
                                                                                            >
                                                                                                <p className="listite_centre">
                                                                                                    {intent.description}
                                                                                                </p>
                                                                                            </Td>

                                                                                            <Td size="2.5">
                                                                                                {false ? (
                                                                                                    <div className="list_flow_span">
                                                                                                        {/* <span>{intent.examples.length}</span> */}
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <div className="list_flow_span_example">
                                                                                                        {intent.examples
                                                                                                            .length ==
                                                                                                        0 ? (
                                                                                                            <span>
                                                                                                                No
                                                                                                                Example
                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            <span>
                                                                                                                Examples{' '}
                                                                                                                <span>
                                                                                                                    (
                                                                                                                    {
                                                                                                                        intent
                                                                                                                            .examples
                                                                                                                            .length
                                                                                                                    }
                                                                                                                    )
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        )}
                                                                                                        {/* <span>
                                                                                                        {intent.examples
                                                                                                            .length == 0
                                                                                                            ? 'No Example'
                                                                                                            : intent
                                                                                                                  .examples
                                                                                                                  .length >
                                                                                                              1
                                                                                                            ? `${intent.examples.length} Examples`
                                                                                                            : `${intent.examples.length} Example`}
                                                                                                    </span> */}
                                                                                                    </div>
                                                                                                )}
                                                                                            </Td>
                                                                                            <Td
                                                                                                size="2.5"
                                                                                                className="list_flow_span"
                                                                                            >
                                                                                                <span className="entity_span">
                                                                                                    {formatDateInCard(
                                                                                                        intent.updated.includes(
                                                                                                            'Z'
                                                                                                        )
                                                                                                            ? intent.updated
                                                                                                            : intent.updated +
                                                                                                                  'Z'
                                                                                                    )}
                                                                                                </span>
                                                                                                <span>|</span>
                                                                                                <span className="entity_span">
                                                                                                    {formatAMPM(
                                                                                                        intent.updated.includes(
                                                                                                            'Z'
                                                                                                        )
                                                                                                            ? intent.updated
                                                                                                            : intent.updated +
                                                                                                                  'Z'
                                                                                                    )}
                                                                                                </span>
                                                                                            </Td>
                                                                                            <Td size="1">
                                                                                                {PageState.action !==
                                                                                                    ActionT.SELECT &&
                                                                                                    PageState.action !==
                                                                                                        ActionT.MULTIPLE_DELETE && (
                                                                                                        <ToolBox>
                                                                                                            {permissions.write &&<span
                                                                                                                onClick={e => {
                                                                                                                    e.stopPropagation()
                                                                                                                    setToEditMode(
                                                                                                                        intent.id
                                                                                                                    )
                                                                                                                }}
                                                                                                            >
                                                                                                                {/* <Tooltip
                                                                                                                className="target customTip "
                                                                                                                zIndex={10000}
                                                                                                                arrowSize={8}
                                                                                                                tagName="span"
                                                                                                                content={'Edit'}
                                                                                                                distance={5}
                                                                                                            > */}
                                                                                                                <EditIcon />
                                                                                                            {/* </Tooltip> */}
                                                                                                                
                                                                                                            </span>}
                                                                                                            {permissions.delete && <span
                                                                                                                onClick={e => {
                                                                                                                    e.stopPropagation()
                                                                                                                    setDeleteMode(
                                                                                                                        intent
                                                                                                                    )
                                                                                                                }}
                                                                                                            >
                                                                                                                {/* <Tooltip
                                                                                                                className="target customTip "
                                                                                                                zIndex={10000}
                                                                                                                arrowSize={8}
                                                                                                                tagName="span"
                                                                                                                content={'Delete'}
                                                                                                                distance={5}
                                                                                                            > */}
                                                                                                                <DeleteIcon />
                                                                                                            {/* </Tooltip> */}
                                                                                                                
                                                                                                            </span>}
                                                                                                        </ToolBox>
                                                                                                    )}
                                                                                            </Td>
                                                                                        </>
                                                                                    )
                                                                                }}
                                                                            />
                                                                        </Tr>
                                                                    )
                                                                })}
                                                    </Tbody>
                                                </Table>
                                            </TableContainer>
                                        </TabelWrapper>
                                        {/* <div className="col-md-3 intent_list_description caret_before">
                                        <span>Name</span>
                                    </div>
                                    <div className="col-md-3 intent_list_description">
                                        <span>Description</span>
                                    </div>
                                    <div className="col-md-2 intent_list_description">
                                        <span>Date</span>
                                    </div>
                                    <div className="col-md-2 intent_list_description">
                                        <span>No. of examples</span>
                                    </div> */}
                                    </React.Fragment>
                                )}

                                {intents &&
                                    sortAnArrayByName(intents, 'intent')
                                        .filter((e:IntentT) =>
                                            e.intent
                                                .toLowerCase()
                                                .trim()
                                                .includes(searchWord.toLowerCase().trim())
                                        )
                                        .map((intent: IntentT, i: number) => {
                                            // for making search keyword bold
                                            let reg = new RegExp(searchWord, 'gi')
                                            let label = searching
                                                ? `<h5 style='padding-top:${
                                                      PageState.action == ActionT.SELECT ? 18 : 0
                                                  }px'>` +
                                                  intent.intent +
                                                  '</h5>'
                                                : `<h5 style='padding-top:${
                                                      PageState.action == ActionT.SELECT ? 18 : 0
                                                  }px'>` +
                                                  intent.intent +
                                                  '</h5>'

                                            if (viewType.View == ViewTypeT.GridView) {
                                                return (
                                                    <Card
                                                        key={i}
                                                        id={intent.id}
                                                        uuid={intent.id}
                                                        modified={intent.updated}
                                                        isSelected={isSelected}
                                                        select={selectIntents}
                                                        showSelection={showSelection}
                                                        delete={() => setDeleteMode(intent)}
                                                        edit={setToEditMode}
                                                        label={label}
                                                        info={intent.description}
                                                        onClick={() => editIntent(intent.intent)}
                                                        examplesCount={intent.examples.length}
                                                        showToolbox={PageState.action !== ActionT.SELECT}
                                                        disabled={PageState.action == ActionT.SELECT}
                                                        permissions={permissions as {
                                                            write: boolean 
                                                            delete: boolean
                                                        }}
                                                    />
                                                )
                                            } else {
                                                return (
                                                    // <List
                                                    //     key={i}
                                                    //     showCheckBox
                                                    //     checkBoxShow={showSelection()}
                                                    //     render={(show: boolean, ToolBox: any) => {
                                                    //         return (
                                                    //             <div className="col-md-12 list-view-sub">
                                                    //                 <div className="main_sub">
                                                    //                     <div className={'col-md-3 list_flow_hr'}>
                                                    //                         <label>
                                                    //                             {show && (
                                                    //                                 <input
                                                    //                                     type="radio"
                                                    //                                     className="option-input radio"
                                                    //                                     checked={isSelected(intent.id)}
                                                    //                                     onClick={() =>
                                                    //                                         selectIntents(intent.id)
                                                    //                                     }
                                                    //                                     onChange={() => {}}
                                                    //                                     style={{
                                                    //                                         visibility: show
                                                    //                                             ? 'visible'
                                                    //                                             : 'hidden',
                                                    //                                         border:
                                                    //                                             show && !isSelected
                                                    //                                                 ? '#494949 solid 1px'
                                                    //                                                 : '#fff'
                                                    //                                     }}
                                                    //                                 />
                                                    //                             )}
                                                    //                         </label>
                                                    //                         <div
                                                    //                             className="search_card_list"
                                                    //                             onClick={() => editIntent(intent.intent)}
                                                    //                         >
                                                    //                             {Parser(label)}
                                                    //                         </div>
                                                    //                     </div>
                                                    //                     <div className="col-md-3 list_flow_p">
                                                    //                         <p className="listite_centre">
                                                    //                             {intent.description}
                                                    //                         </p>
                                                    //                     </div>
                                                    //                     <div className="col-md-2  list_flow_span">
                                                    //                         <span className="entity_span">
                                                    //                             {formatDateInCard(
                                                    //                                 intent.updated.includes('Z')
                                                    //                                     ? intent.updated
                                                    //                                     : intent.updated + 'Z'
                                                    //                             )}
                                                    //                         </span>
                                                    //                         <span>|</span>
                                                    //                         <span className="entity_span">
                                                    //                             {formatAMPM(
                                                    //                                 intent.updated.includes('Z')
                                                    //                                     ? intent.updated
                                                    //                                     : intent.updated + 'Z'
                                                    //                             )}
                                                    //                         </span>
                                                    //                     </div>

                                                    //                     {false ? (
                                                    //                         <div className="col-md-2 list_flow_span">
                                                    //                             {/* <span>{intent.examples.length}</span> */}
                                                    //                         </div>
                                                    //                     ) : (
                                                    //                         <div className="col-md-2 list_flow_span">
                                                    //                             <span>
                                                    //                                 {intent.examples.length == 0
                                                    //                                     ? 'No Example'
                                                    //                                     : intent.examples.length > 1
                                                    //                                     ? `${intent.examples.length} Examples`
                                                    //                                     : `${intent.examples.length} Example`}
                                                    //                             </span>
                                                    //                         </div>
                                                    //                     )}

                                                    //                     {PageState.action !== ActionT.SELECT &&
                                                    //                         PageState.action !==
                                                    //                             ActionT.MULTIPLE_DELETE && (
                                                    //                             <ToolBox>
                                                    //                                 <span
                                                    //                                     onClick={() =>
                                                    //                                         setToEditMode(intent.id)
                                                    //                                     }
                                                    //                                 >
                                                    //                                     <EditIcon />
                                                    //                                 </span>
                                                    //                                 <span
                                                    //                                     onClick={() =>
                                                    //                                         setDeleteMode(intent)
                                                    //                                     }
                                                    //                                 >
                                                    //                                     <DeleteIcon />
                                                    //                                 </span>
                                                    //                             </ToolBox>
                                                    //                         )}
                                                    //                 </div>
                                                    //             </div>
                                                    //         )
                                                    //     }}
                                                    // />
                                                    <></>
                                                )
                                            }
                                        })}
                            </div>
                        </div>
                    </section>
                </Wrapper>
                {/* <section className="intent_bottom">
                    <div>&nbsp;</div>
                </section> */}
            </ErrorBoundary>
        </React.Fragment>
    )
}

export default Intents
