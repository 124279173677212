import Downshift from 'downshift'
import { EditorState, RawDraftContentBlock, convertToRaw } from 'draft-js'
import produce from 'immer'
/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react'
import Tooltip from 'react-tooltip-lite'
import styled from 'styled-components/macro'

import {
    AddIcon,
    AttachmentIcon,
    DownarrowWithCircle,
    FlowCanvasContextIcons,
    InfoIcon,
    MinusIcon
} from '@/common/Icons/Icons'
import { Calendar } from '@/common/datepicker/dateAndTimePicker'
import {
    Header,
    InputContainer,
    InputContent,
    Input as InputField,
    InputWrapper,
    Paragraph,
    SmallButton
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { DraftInputWrapper } from '@/common/styled/DraftJs.Dumb'

import { automationAPIURLWithWorkspace, getProductId, postJson } from '@/common/utils/api-utils'
import { ActionPanelStateT } from './ActionPanel'
import { CancelButtonInput } from './ActionPanel'
import { AppTriggerPanelStateT, TriggerPanelStateT } from './AppTriggerPanel'
import { SidePanelContext } from './Canvas'
import { CanvasRightPanel } from './CanvasRightPanel'
import { ContextOutputTree } from './ContextPopup'
import { FunctionPanelStateT } from './FunctionPopup'
import { Editor, decorator } from './InputandVariable'
import { FileDefaultObject } from './NoTriggerPanel'
import { useDropDown } from './Tree'
import { DictionaryInput } from './commonDynamicInputs'
import {
    ActionsResponseT,
    AppTriggerResponseT,
    CollectionOutputListTypeT,
    ConfigKinds,
    ContextPopupStateT,
    DefaultInputKeysT,
    FunctionResponseT,
    InputEnum,
    InputFieldT,
    LookUpEnum,
    MultipleLookUpEnum,
    MultiSelectEnum,
    NodeMode,
    Nodes
} from './types'
import { convertAsaSingleString, convertToServerFormat, getFirstMatched, updateValueUsingLens } from './utils'
import { components } from "react-select";
import { default as ReactSelect } from "react-select";
import { NotificationPanelStateT } from './SendNotificationPopup'

const OptionWithCheckboxs = (props:any) => {
  return (
    <div className="action_service_desk_list_contanier">
      <components.Option {...props} className={props.isSelected ? "action_service_desk_checked":"action_service_desk_list"}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          className="action_check_box"
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const NoOptionsMessage = (props:any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">No items to select</span> 
      </components.NoOptionsMessage>
    );
  };

const ramda = require('ramda')

const FocusContent = styled.div`
    width: 100%;
    float: left;
    color: #999999;
    font-size: 10px;
    font-family: 'Gordita-Regular';
    position: absolute;
    left: 12px;
    top: 30px;
    line-height: 10px;
`
// const DraftInputWrapper = styled.div`
//     width: 100%;
//     float: left;
//     .dialog_input_edit_true.dialog_context_input_edit_true {
//         background: #ffffff 0% 0% no-repeat padding-box;
//         border: 1px solid #b9b9b9;
//         border-radius: 6px;
//         min-height: 48px;
//         padding: 0px 12px 6px 12px;
//         cursor: text;
//     }
//     .draft_input_text {
//         position: relative;
//         top: 17px;
//     }

//     .context_variable_button img {
//         width: 14px;
//         height: 14px;
//         margin-right: 3px;
//         position: relative;
//         top: -1px;
//     }
//     .buton_dialog_auto_hvr .context_variable_button {
//         padding: 2px 5px;
//     }
//     .buton_dialog_auto_hvr .context_variable_button span {
//         font-size: 9px;
//         font-family: 'Gordita-Medium';
//         position: relative;
//         top: -2px;
//     }
//     .buton_dialog_auto_hvr span[data-text='true'] {
//         position: relative;
//         top: -1px;
//     }
//     .select_work_form {
//         position: relative;
//     }
// `
const InputBgWrapper = styled.div`
    width: 100%;
    float: left;

    ${DraftInputWrapper} {
        // margin-bottom: 5px;
        margin-top: 10px;
        :first-child{
            margin-top: 0px;
        }
        :last-child {
            margin-bottom: 0px;
        }
        p {
            margin-top: 7px;
            margin-left: 2px;
        }
    }
    .test_buton_view {
        .automation_form_req.automation_form_req_step_two {
            margin-bottom: 20px;
            :last-child {
                margin-bottom: 0px;
            }
            ${InputContainer} {
                margin-bottom: 0px;
                :last-child {
                    margin-bottom: 0px;
                    // margin-top: 20px;
                }
            }
        }
    }
`

export function seperateOptionalFields(array: InputFieldT[], required: boolean) {
    return array.filter((input: InputFieldT) => {
        return required ? input.required : !input.required
    })
}

export const CommonInputs = (props: {
    state: ActionPanelStateT | FunctionPanelStateT | NotificationPanelStateT
    title: string
    description: string
    inputsList: InputFieldT[]
    workspacename: string
    setPanelState: (state: ActionPanelStateT | FunctionPanelStateT) => void
    contextPopupValues: any
    setContextPopupValues: any
    updateInputValuesWithContext: any
    showToolbar?:boolean
    forTestAction?:boolean
    showFoldIcon?:boolean
    foldInputsView?:()=>void
}) => {
    const { state, inputsList, workspacename, title, description, forTestAction, ...rest } = props

    const [showDropDown, makeDropDownVisible, makeDropDownHidden] = useDropDown()

    let InputsArray = inputsList

    return (
        <InputBgWrapper className="test_buton_result">
            <InputWrapper>
                <div className="test_buton_view">
                    <div className='test_button_header_box'>
                        <div className='test_button_header'>
                            <h4>{title}</h4>
                            <p style={{ marginBottom: '10' }}>{description}</p>
                        </div>                    
                        {props.showFoldIcon && 
                            <div>
                                <span
                                    className="connect_popup_minusIcon"
                                    onClick={() => props.foldInputsView && props.foldInputsView()}
                                >
                                    <MinusIcon />
                                </span>
                            </div>
                        }
                    </div>

                    {seperateOptionalFields(InputsArray, true).map((input: InputFieldT, i: number) => (
                        <React.Fragment key={i}>
                            {PopulateInputsUI({ ...rest, input, i, state, workspacename, forTestAction })}
                        </React.Fragment>
                    ))}

                    {seperateOptionalFields(InputsArray, false).length > 0 && (
                        <div className="add_connection_automation show_options display-block-important">
                            <SmallButton
                                primary
                                className={showDropDown ? 'small_btn_bottom' : ''}
                                style={{ marginBottom: 12 }}
                                onClick={() => (showDropDown ? makeDropDownHidden() : makeDropDownVisible())}
                            >
                                <span>{!showDropDown ? <AddIcon /> : <MinusIcon />}</span>{' '}
                                <label>
                                    {!showDropDown
                                        ? `Show optional fields (${seperateOptionalFields(InputsArray, false).length})`
                                        : 'Hide optional fields '}
                                </label>
                            </SmallButton>
                            {showDropDown &&
                                seperateOptionalFields(InputsArray, false).map((input: InputFieldT, i: number) => (
                                    <React.Fragment key={i}>
                                        {PopulateInputsUI({ ...rest, input, i, state, workspacename, forTestAction })}
                                    </React.Fragment>
                                ))}
                        </div>
                    )}
                </div>
            </InputWrapper>
        </InputBgWrapper>
    )
}
//HERE
function PopulateInputsUI(props: any): any {
    const { i, input, state, workspacename, forTestAction, ...rest } = props

    const inp = input as InputFieldT
    if (inp.config.kind == 'dictionary') {
        return <DictionaryInput input={inp} key={i} {...props} />
    } else if (inp.config.kind === 'file') {
        return <FileInput input={inp} key={i} {...props} />
    } else if (inp.config.kind === 'text' || inp.config.kind === 'password' || inp.config.kind == 'collection-mapper') {
        return <Input input={inp} key={i} {...props} />
    } else if (inp.config.kind == 'collection-picker-single') {
        return <CollectionInput input={inp} key={i} index={i} {...props} />
    } else if (inp.config.kind == 'enum' || inp.config.kind=='multiselect-enum') {
        return <EnumDropDown input={inp} key={i} {...props} />
    } else if (inp.config.kind == 'date') {
        return <DateAndTimePicker input={inp} key={i} index={i} {...props} />
    } else if (inp.config.kind == 'multiple') {
        const items = Object.values(inp.config.items) as InputFieldT[]

        const sortGreater = function(a: any, b: any) {
            return parseInt(a.order) - parseInt(b.order)
        }
        return ramda
            .sort(sortGreater, items)
            .map((mulInput: InputFieldT, index: number) => (
                <React.Fragment key={mulInput.key + index}>
                    {PopulateInputsUI({ ...rest, input: mulInput, i: index, state, workspacename })}
                </React.Fragment>
            ))
    } else if (inp.config.kind == 'switch') {
        return <Switch input={inp} key={i} index={i} {...props} />
    } else if (inp.config.kind == 'hidden') {
        return null
    }

    return <LookUpEnumDropDown input={inp} workspacename={workspacename} key={i} {...props} />
}

function Input(props: {
    input: InputFieldT
    state: ActionPanelStateT | FunctionPanelStateT | TriggerPanelStateT | NotificationPanelStateT
    setPanelState: (state: ActionPanelStateT | FunctionPanelStateT) => void
    contextPopupValues: ContextPopupStateT
    setContextPopupValues: any
    showToolbar?:boolean
    forTestAction?:boolean
}) {
    const { state, setPanelState, contextPopupValues, setContextPopupValues, forTestAction } = props

    const { treeData, hasLinkedDialogs, active_automation, automationMeta } = useContext(SidePanelContext)

    const actionNode = state.currentNode.nodeType == Nodes.Action || state.currentNode.nodeType == Nodes.Trigger || state.currentNode.nodeType == Nodes.Notification

    const { input } = props

    const isAssistant = getProductId() === 'ASSISTANT'

    return forTestAction ? (
        <DraftInputWrapper style={{ margin: 0 }}>
            <InputContainer>
                <InputField
                style={{marginBottom: 10}}
                type="text"
                value={input.value ? (input.value as any) : ''}
                // onKeyDown={(e: any) => {
                //     setKeycode(e.keyCode)
                //     if (e.metaKey || e.ctrlKey) setMetacode(true)
                //     else setMetacode(false)
                // }}
                onChange={(e: any) => {
                    // if (keycode != 32 && metacode == false) {
                    //     setVariable(e.target.value.replace(/[^\w\s]/gi, ''))
                    // }
                    const nextValue = produce(state.currentNode as ActionsResponseT, (draftState) => {
                    const filteredDependencyLookup = draftState.workflowInputsListForTest.output.input.filter(
                        (inp) =>
                        inp.config.kind == 'lookup-enum' &&
                        inp.config.params[input.key]
                    );
                    const inputPreviousValue = input.value as string;

                    draftState.workflowInputsListForTest.output.input = draftState.workflowInputsListForTest.output.input.map(
                        (inputs: InputFieldT) => {
                        if (input.path) {
                            const value = updateValueUsingLens(
                            input.path as string,
                            inputs,
                            {
                                value: e.target.value as any,
                                inputHasValue: e.target.value.length
                                ? true
                                : false,
                            }
                            );
                            return value;
                        } else if (inputs.key === input.key) {
                            return {
                            ...inputs,
                            value: e.target.value as any,
                            inputHasValue: e.target.value.length
                                ? true
                                : false,
                            };
                        } else if (
                            filteredDependencyLookup.length > 0 &&
                            filteredDependencyLookup[0].key == inputs.key &&
                            e.target.value != inputPreviousValue
                        ) {
                            return {
                            ...inputs,
                            value: '',
                            inputHasValue: false,
                            };
                        }
                        return inputs;
                        }
                    );
                    });

                    setPanelState({
                        ...state,
                        currentNode: nextValue
                    } as ActionPanelStateT | FunctionPanelStateT)

                }}
                />
                <InputContent>{input.displayTitle}</InputContent>
                <p>{input.description}</p>

            </InputContainer>
        </DraftInputWrapper>
    ):(
        <>
            <DraftInputWrapper style={{margin: 0}}>
                <div className="select_work_form variable_content_button">
                    {input.displayTitle === 'Create custom variable' ? <h4>{input.displayTitle}</h4> : null}
                    <Editor
                        focus={
                            convertAsaSingleString(
                                input.value
                                    ? (convertToRaw((input.value as any).getCurrentContent())
                                          .blocks as RawDraftContentBlock[])
                                    : (convertToRaw(EditorState.createEmpty().getCurrentContent())
                                          .blocks as RawDraftContentBlock[])
                            ).replace(/\s/g, '').length
                                ? 'focus_input'
                                : ''
                        }
                        focusContent={input.displayTitle}
                        // description={input.description}
                        infocus={contextPopupValues.currentInput === input.key}
                        disabled={(isAssistant ? automationMeta.type ==='no_trigger' ?false : active_automation: active_automation)}
                        value={input.value ? (input.value as any) : EditorState.createEmpty(decorator)}
                        showToolbar={props.showToolbar}
                        onFocus={() => {
                            if (
                                treeData.tree.value.kind === 'AppTrigger' ||
                                treeData.tree.value.kind === 'EventTrigger' ||
                                treeData.tree.value.kind === 'WebhookTrigger' ||
                                (treeData.tree.value.output && Object.keys(treeData.tree.value.output).length > 0) ||
                                (state.parentNode.value.path && state.parentNode.value.path.length > 0)
                            ) {
                                setContextPopupValues({
                                    ...contextPopupValues,
                                    show: true,
                                    currentInput: input.key,
                                    isMultiple: {
                                        path: input.path ? input.path : '',
                                        parentKey: input.path ? input.path.split('/')[0] : ''
                                    },
                                    collectionPicker: {
                                        ...contextPopupValues.collectionPicker,
                                        isCollectionPicker: false
                                    }
                                })
                            } else {
                                setContextPopupValues({
                                    ...contextPopupValues,
                                    currentInput: input.key,
                                    isMultiple: {
                                        path: input.path ? input.path : '',
                                        parentKey: input.path ? input.path.split('/')[0] : ''
                                    },
                                    collectionPicker: {
                                        ...contextPopupValues.collectionPicker,
                                        isCollectionPicker: false
                                    }
                                })
                            }
                        }}
                        setValue={(editorState: EditorState) => {
                            //to update state without changing other value. produce is to use instead of doing {...something}
                            const nextValue = actionNode
                                ? produce(state.currentNode as ActionsResponseT, draftState => {
                                      const filteredDependencyLookup = draftState.actionInputsList.output.input.filter(
                                          inp => inp.config.kind == 'lookup-enum' && inp.config.params[input.key]
                                      )
                                      const val = input.value as EditorState
                                      const inputPreviousValue = convertAsaSingleString(
                                          convertToRaw(val.getCurrentContent()).blocks
                                      )

                                      draftState.actionInputsList.output.input = draftState.actionInputsList.output.input.map(
                                          (inputs: InputFieldT) => {
                                              if (input.path) {
                                                  const value = updateValueUsingLens(input.path as string, inputs, {
                                                      value: editorState as any,
                                                      inputHasValue: convertAsaSingleString(
                                                          convertToRaw(editorState.getCurrentContent()).blocks
                                                      ).replace(/\s/g, '').length
                                                          ? true
                                                          : false
                                                  })
                                                  return value
                                              } else if (inputs.key === input.key) {
                                                  return {
                                                      ...inputs,
                                                      value: editorState as any,
                                                      inputHasValue: convertAsaSingleString(
                                                          convertToRaw(editorState.getCurrentContent()).blocks
                                                      ).replace(/\s/g, '').length
                                                          ? true
                                                          : false
                                                  }
                                              } else if (
                                                  filteredDependencyLookup.length > 0 &&
                                                  filteredDependencyLookup[0].key == inputs.key &&
                                                  convertAsaSingleString(
                                                      convertToRaw(editorState.getCurrentContent()).blocks
                                                  ) != inputPreviousValue
                                              ) {
                                                  return {
                                                      ...inputs,
                                                      value: '',
                                                      inputHasValue: false
                                                  }
                                              }
                                              return inputs
                                          }
                                      )
                                  })
                                : produce(state.currentNode as FunctionResponseT, draftState => {
                                      const filteredDependencyLookup = draftState.functionInputsList.output.input.filter(
                                          inp => inp.config.kind == 'lookup-enum' && inp.config.params[input.key]
                                      )
                                      const val = input.value as EditorState
                                      const inputPreviousValue = convertAsaSingleString(
                                          convertToRaw(val.getCurrentContent()).blocks
                                      )

                                      draftState.functionInputsList.output.input = draftState.functionInputsList.output.input.map(
                                          (inputs: InputFieldT) => {
                                              if (input.path) {
                                                  const value = updateValueUsingLens(input.path as string, inputs, {
                                                      value: editorState as any,
                                                      inputHasValue: convertAsaSingleString(
                                                          convertToRaw(editorState.getCurrentContent()).blocks
                                                      ).replace(/\s/g, '').length
                                                          ? true
                                                          : false
                                                  })
                                                  return value
                                              } else if (inputs.key === input.key) {
                                                  return {
                                                      ...inputs,
                                                      inputHasValue: convertAsaSingleString(
                                                          convertToRaw(editorState.getCurrentContent()).blocks
                                                      ).replace(/\s/g, '').length
                                                          ? true
                                                          : false,
                                                      value: editorState as any
                                                  }
                                              } else if (
                                                  filteredDependencyLookup.length > 0 &&
                                                  filteredDependencyLookup[0].key == inputs.key &&
                                                  convertAsaSingleString(
                                                      convertToRaw(editorState.getCurrentContent()).blocks
                                                  ) != inputPreviousValue
                                              ) {
                                                  return {
                                                      ...inputs,
                                                      value: '',
                                                      inputHasValue: false
                                                  }
                                              }
                                              return inputs
                                          }
                                      )
                                  })

                            setPanelState({
                                ...state,
                                currentNode: nextValue
                            } as ActionPanelStateT | FunctionPanelStateT)
                        }}
                    />

                    <p>{input.description}</p>
                </div>
            </DraftInputWrapper>
            {/* <div className="automation_global_form">
                <div className="select_work_form">
                    <h4>{input.displayTitle}</h4>
                    <Editor
                        infocus={contextPopupValues.currentInput === input.key}
                        disabled={hasLinkedDialogs}
                        value={input.value ? (input.value as any) : EditorState.createEmpty()}
                        onFocus={() => {
                            if (
                                treeData.tree.value.kind === 'AppTrigger' ||
                                treeData.tree.value.kind === 'EventTrigger' ||
                                (treeData.tree.value.output && Object.keys(treeData.tree.value.output).length > 0) ||
                                (state.parentNode.value.path && state.parentNode.value.path.length > 0)
                            ) {
                                setContextPopupValues({
                                    ...contextPopupValues,
                                    show: true,
                                    currentInput: input.key,
                                    isMultiple: {
                                        path: input.path ? input.path : '',
                                        parentKey: input.path ? input.path.split('/')[0] : ''
                                    },
                                    collectionPicker: {
                                        ...contextPopupValues.collectionPicker,
                                        isCollectionPicker: false
                                    }
                                })
                            } else {
                                setContextPopupValues({
                                    ...contextPopupValues,
                                    currentInput: input.key,
                                    isMultiple: {
                                        path: input.path ? input.path : '',
                                        parentKey: input.path ? input.path.split('/')[0] : ''
                                    },
                                    collectionPicker: {
                                        ...contextPopupValues.collectionPicker,
                                        isCollectionPicker: false
                                    }
                                })
                            }
                        }}
                        setValue={(editorState: EditorState) => {
                            //to update state without changing other value. produce is to use instead of doing {...something}
                            const nextValue = actionNode
                                ? produce(state.currentNode as ActionsResponseT, draftState => {
                                      const filteredDependencyLookup = draftState.actionInputsList.output.input.filter(
                                          inp => inp.config.kind == 'lookup-enum' && inp.config.params[input.key]
                                      )
                                      const val = input.value as EditorState
                                      const inputPreviousValue = convertAsaSingleString(
                                          convertToRaw(val.getCurrentContent()).blocks
                                      )

                                      draftState.actionInputsList.output.input = draftState.actionInputsList.output.input.map(
                                          (inputs: InputFieldT) => {
                                              if (input.path) {
                                                  const value = updateValueUsingLens(input.path as string, inputs, {
                                                      value: editorState as any,
                                                      inputHasValue: convertAsaSingleString(
                                                          convertToRaw(editorState.getCurrentContent()).blocks
                                                      ).replace(/\s/g, '').length
                                                          ? true
                                                          : false
                                                  })
                                                  return value
                                              } else if (inputs.key === input.key) {
                                                  return {
                                                      ...inputs,
                                                      value: editorState as any,
                                                      inputHasValue: convertAsaSingleString(
                                                          convertToRaw(editorState.getCurrentContent()).blocks
                                                      ).replace(/\s/g, '').length
                                                          ? true
                                                          : false
                                                  }
                                              } else if (
                                                  filteredDependencyLookup.length > 0 &&
                                                  filteredDependencyLookup[0].key == inputs.key &&
                                                  convertAsaSingleString(
                                                      convertToRaw(editorState.getCurrentContent()).blocks
                                                  ) != inputPreviousValue
                                              ) {
                                                  return {
                                                      ...inputs,
                                                      value: '',
                                                      inputHasValue: false
                                                  }
                                              }
                                              return inputs
                                          }
                                      )
                                  })
                                : produce(state.currentNode as FunctionResponseT, draftState => {
                                      const filteredDependencyLookup = draftState.functionInputsList.output.input.filter(
                                          inp => inp.config.kind == 'lookup-enum' && inp.config.params[input.key]
                                      )
                                      const val = input.value as EditorState
                                      const inputPreviousValue = convertAsaSingleString(
                                          convertToRaw(val.getCurrentContent()).blocks
                                      )

                                      draftState.functionInputsList.output.input = draftState.functionInputsList.output.input.map(
                                          (inputs: InputFieldT) => {
                                              if (input.path) {
                                                  const value = updateValueUsingLens(input.path as string, inputs, {
                                                      value: editorState as any,
                                                      inputHasValue: convertAsaSingleString(
                                                          convertToRaw(editorState.getCurrentContent()).blocks
                                                      ).replace(/\s/g, '').length
                                                          ? true
                                                          : false
                                                  })
                                                  return value
                                              } else if (inputs.key === input.key) {
                                                  return {
                                                      ...inputs,
                                                      inputHasValue: convertAsaSingleString(
                                                          convertToRaw(editorState.getCurrentContent()).blocks
                                                      ).replace(/\s/g, '').length
                                                          ? true
                                                          : false,
                                                      value: editorState as any
                                                  }
                                              } else if (
                                                  filteredDependencyLookup.length > 0 &&
                                                  filteredDependencyLookup[0].key == inputs.key &&
                                                  convertAsaSingleString(
                                                      convertToRaw(editorState.getCurrentContent()).blocks
                                                  ) != inputPreviousValue
                                              ) {
                                                  return {
                                                      ...inputs,
                                                      value: '',
                                                      inputHasValue: false
                                                  }
                                              }
                                              return inputs
                                          }
                                      )
                                  })

                            setPanelState({
                                ...state,
                                currentNode: nextValue
                            } as ActionPanelStateT | FunctionPanelStateT)
                        }}
                    />
                    <p>{input.description}</p>
                </div>
            </div> */}
        </>
    )
}

const FileInput = (props: {
    index: number
    input: InputFieldT
    state: ActionPanelStateT | FunctionPanelStateT
    setPanelState: (state: ActionPanelStateT | FunctionPanelStateT) => void
    contextPopupValues: ContextPopupStateT
    setContextPopupValues: any
    updateInputValuesWithContext: any
    output: { name: string; value: CollectionOutputListTypeT }
}) => {
    const { state, contextPopupValues, setContextPopupValues, input, updateInputValuesWithContext } = props

    const { hasLinkedDialogs, treeData, active_automation } = useContext(SidePanelContext)

    const currentNode = state.currentNode as FunctionResponseT

    const fileInpVal = input.value as EditorState

    const val = convertToRaw(fileInpVal.getCurrentContent()).blocks[0].text

    const convertedValue: any = val.length > 0 ? convertToRaw(fileInpVal.getCurrentContent()).entityMap[0].data : {}

    return (
        <>
            <div className="automation_global_form">
                <div className="select_work_form">
                    {/* <h4>{input.displayTitle}</h4> */}
                    <div
                        className={active_automation?'form_req_step_two_global_form editor_btn_disabled':'form_req_step_two_global_form'}
                        onClick={() => {
                            if (
                                treeData.tree.value.kind === 'AppTrigger' ||
                                treeData.tree.value.kind === 'EventTrigger' ||
                                treeData.tree.value.kind === 'WebhookTrigger' ||
                                (treeData.tree.value.output && Object.keys(treeData.tree.value.output).length > 0) ||
                                (state.parentNode.value.path && state.parentNode.value.path.length > 0)
                            ) {
                                setContextPopupValues({
                                    ...contextPopupValues,
                                    show: true,
                                    currentInput: input.key,
                                    isFilePicker: true
                                })
                            } else {
                                setContextPopupValues({
                                    ...contextPopupValues,
                                    currentInput: input.key,
                                    isFilePicker: true
                                })
                            }
                        }}
                    >
                        {val.length > 0 ? (
                            // <>
                            //     <div
                            //         className={
                            //             contextPopupValues.currentInput.length == 0 ||
                            //             contextPopupValues.currentInput == input.key ||
                            //             (input.path &&
                            //                 input.path.split('/')[0] != contextPopupValues.isMultiple.parentKey)
                            //                 ? 'form_req_step_two_global_form'
                            //                 : 'form_req_step_two_global_form focused'
                            //         }
                            //     >
                            //         <div className="collection_picker_in_input_page">
                            <div className="collection_picker_in_input_page">
                                <ContextOutputTree
                                    output={{
                                        name: val,
                                        value: FileDefaultObject(val)
                                    }}
                                    onClick={() => {}}
                                    usingForInputs={true}
                                />
                            </div>
                        ) : (
                            // </>
                            <div className="position-relative w-100">
                                <div
                                    className="button_auto_input"
                                    style={{ cursor: 'pointer' }}
                                    // data-text={input.displayTitle}
                                />
                                <span>
                                    <AttachmentIcon />
                                </span>
                            </div>
                        )}{' '}
                    </div>
                    <p>{input.description}</p>
                </div>
            </div>
        </>
    )
}
const FunctionPopup = styled.div`
    width: 100%;
    float: left;
    background: #f4f4f4;

    .button_auto_input_click {
        color: #999;
        top: 4px;
        font-size: 10px;
        -webkit-transition: 0.26s ease-out;
        transition: 0.26s ease-out;
        line-height: 10px;
        font-family: 'Gordita-Regular';
        position: absolute;
        pointer-events: none;
        left: 12px;
        .target.customTip._description {
            position: relative;
            top: 1px;
            left: 6px;
            width: 9px;
            height: 9px;
            cursor: pointer;
        }
    }
    .automation_global_form_edit._select_a_variable:focus .button_auto_input_click {
        top: 4px;
        font-size: 10px;
        transition: 0.26s ease-out;
        line-height: 10px;
        .target.customTip._description {
            position: relative;
            top: 1px;
            left: 6px;
            width: 9px;
            height: 9px;
        }
    }
    .automation_global_form_edit._select_a_variable:focus .button_auto_input {
        top: 8px;
        font-size: 10px;
        transition: 0.26s ease-out;
        line-height: 10px;
        .target.customTip._description {
            position: relative;
            top: 1px;
            left: 6px;
            width: 9px;
            height: 9px;
        }
    }

    .automation_global_form_edit._select_a_variable {
        width: 100%;
        padding: 20px 12px 1px 12px;
        border: 1px solid #b9b9b9;
        border-radius: 6px;
        background: #fff;
        min-height: 48px;
        font-size: 12px;
        font-family: 'Gordita-Regular';
        color: #000000;
        position: relative;
        margin-right: 0px;
        margin-bottom: 20px;

        .button_auto_input {
            color: #999;
            font-size: 12px;
            font-family: 'Gordita-Regular';
            position: absolute;
            pointer-events: none;
            left: 12px;
            top: 16px;
            -webkit-transition: 0.26s ease-in;
            transition: 0.26s ease-in;
            pointer-events: inherit;
            .target.customTip._description {
                position: relative;
                top: 1px;
                left: 6px;
                cursor: pointer;
            }
        }
    }
`
const FunctionPopupTree = styled.div`
    width: 100%;
    float: left;
    background: #f4f4f4;
    position: relative;

    .button_auto_input_click {
        color: #999;
        top: 30px;
        font-size: 10px;
        -webkit-transition: 0.26s ease-out;
        transition: 0.26s ease-out;
        line-height: 10px;
        font-family: 'Gordita-Regular';
        position: absolute;
        pointer-events: none;
        left: 12px;
    }
    .form_req_step_two_global_form._border_width:focus .button_auto_input_click {
        top: 30px;
        font-size: 10px;
        transition: 0.26s ease-out;
        line-height: 10px;
    }

    .form_req_step_two_global_form._border_width {
        width: 100%;
        padding: 16px 12px 0px 12px;
        border: 1px solid #b9b9b9;
        border-radius: 6px;
        background: #fff;
        min-height: 48px;
        font-size: 12px;
        font-family: 'Gordita-Regular';
        color: #000000;
        position: relative;
        margin-right: 0px;
        margin-top: 20px;
        margin-bottom: 7px;

        .collection_picker.collection_picker_wrapeer {
            padding-top: 0px;
        }
        .tree1.treeview_automatiom_ul {
            padding-top: 0px;
        }

        .button_auto_input {
            color: #999;
            font-size: 12px;
            font-family: 'Gordita-Regular';
            position: absolute;
            pointer-events: none;
            left: 12px;
            top: 16px;
            -webkit-transition: 0.26s ease-in;
            transition: 0.26s ease-in;
        }
    }
`

const CollectionInput = (props: {
    index: number
    input: InputFieldT
    state: ActionPanelStateT | FunctionPanelStateT
    setPanelState: (state: ActionPanelStateT | FunctionPanelStateT) => void
    contextPopupValues: ContextPopupStateT
    setContextPopupValues: any
    updateInputValuesWithContext: any
    output: { name: string; value: CollectionOutputListTypeT }
}) => {
    const { state, contextPopupValues, setContextPopupValues, input, updateInputValuesWithContext } = props

    const { hasLinkedDialogs, treeData, active_automation,automationMeta } = useContext(SidePanelContext)

    const currentNode = state.currentNode as FunctionResponseT

    const path = input.path as string

    const objectFromMultiplesValue = currentNode.functionInputsList.output.input.filter(
        x => x.key == path.split('/')[0]
    )

    const pathL = ramda.compose(ramda.init, ramda.tail, ramda.split('/'))(path)

    const pathLens = ramda.lensPath(pathL)

    const data = ramda.view(pathLens, objectFromMultiplesValue[0]) as {
        [key: string]: DefaultInputKeysT & {
            config: ConfigKinds
        }
    }

    const collectionType = Object.values(data).filter((val: InputFieldT) => val.config.kind == 'hidden')[0]

    const selectedCollection = contextPopupValues.collectionPicker.selectedCollection

    const paths = contextPopupValues.collectionPicker.selectedCollection.value.$id
        .split('/')
        .filter(x => x.length !== 0 && x !== 'properties')
        const isAssistant = getProductId() === 'ASSISTANT'

    return (
        <>
            <div className="automation_global_form">
                <div className="select_work_form">
                    {/* <h4>{input.displayTitle}</h4> */}
                    <div
                        className={(isAssistant? automationMeta.type ==='no_trigger' ?true : !active_automation:!active_automation)?'form_req_step_two_global_form':'form_req_step_two_global_form editor_btn_disabled'}
                        onClick={() => {
                            if (
                                treeData.tree.value.kind === 'AppTrigger' ||
                                treeData.tree.value.kind === 'EventTrigger' ||
                                treeData.tree.value.kind === 'WebhookTrigger' ||
                                (treeData.tree.value.output && Object.keys(treeData.tree.value.output).length > 0) ||
                                (state.parentNode.value.path && state.parentNode.value.path.length > 0)
                            ) {
                                setContextPopupValues({
                                    ...contextPopupValues,
                                    show: true,
                                    currentInput: input.key,
                                    isMultiple: { path: path, parentKey: path.split('/')[0] },
                                    collectionPicker: {
                                        ...contextPopupValues.collectionPicker,
                                        collectionType,
                                        isCollectionPicker: true
                                    }
                                })
                            } else {
                                setContextPopupValues({
                                    ...contextPopupValues,
                                    currentInput: input.key,
                                    isMultiple: { path: path, parentKey: path.split('/')[0] },
                                    collectionPicker: {
                                        ...contextPopupValues.collectionPicker,
                                        collectionType,
                                        isCollectionPicker: true
                                    }
                                })
                            }
                        }}
                    >
                        <FunctionPopup>
                            <div className="automation_global_form_edit _select_a_variable">
                                {contextPopupValues.collectionPicker.selectedCollection &&
                                contextPopupValues.collectionPicker.selectedCollection.name.length > 0 ? (
                                    <React.Fragment>
                                        <span className="conetxt_variable_input">
                                            <div className="context_popup_buton_content_span">
                                                <div className="button_auto">
                                                    <img src={contextPopupValues.collectionPicker.app.icon} />
                                                    {contextPopupValues.collectionPicker.selectedCollection.value.title}
                                                </div>

                                                <div className="button_auto_hover">
                                                    {[
                                                        contextPopupValues.collectionPicker.app.appName,
                                                        contextPopupValues.collectionPicker.app.action
                                                    ]
                                                        .concat(paths.filter((v, i) => i + 1 != paths.length))
                                                        .concat(
                                                            contextPopupValues.collectionPicker.selectedCollection.value
                                                                .title
                                                        )
                                                        .map((value, j) => (
                                                            <React.Fragment key={j}>
                                                                {j !== 0 && (
                                                                    <div>
                                                                        <FlowCanvasContextIcons.NextIcon />
                                                                    </div>
                                                                )}
                                                                <div>{value}</div>
                                                            </React.Fragment>
                                                        ))}
                                                </div>
                                            </div>
                                        </span>
                                        <div
                                            className={
                                                contextPopupValues.collectionPicker.selectedCollection
                                                    ? 'button_auto_input_click'
                                                    : 'button_auto_input'
                                            }
                                        >
                                            {input.displayTitle}
                                            <Tooltip
                                                className="target customTip _description"
                                                zIndex={1035}
                                                tagName="span"
                                                content={input.description}
                                                distance={15}
                                                forceDirection={true}
                                                direction="up-start"
                                            >
                                                <InfoIcon />
                                            </Tooltip>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <div
                                        className={
                                            !contextPopupValues.collectionPicker.selectedCollection
                                                ? 'button_auto_input_click'
                                                : 'button_auto_input'
                                        }
                                    >
                                        {input.displayTitle}
                                        <Tooltip
                                            className="target customTip _description"
                                            zIndex={10000}
                                            tagName="span"
                                            content={input.description}
                                            distance={15}
                                            forceDirection={true}
                                            direction="up-start"
                                        >
                                            <InfoIcon />
                                        </Tooltip>
                                    </div>
                                )}
                            </div>
                        </FunctionPopup>
                    </div>
                    {selectedCollection && selectedCollection.value.$id.length > 0 && (
                        <>
                            <FunctionPopupTree>
                                <div
                                    className={
                                        contextPopupValues.currentInput.length == 0 ||
                                        contextPopupValues.currentInput == input.key ||
                                        (input.path &&
                                            input.path.split('/')[0] != contextPopupValues.isMultiple.parentKey)
                                            ? 'form_req_step_two_global_form _border_width'
                                            : 'form_req_step_two_global_form _border_width focused'
                                    }
                                >
                                    <div className="collection_picker_in_input_page">
                                        <ContextOutputTree
                                            output={contextPopupValues.collectionPicker.selectedCollection}
                                            showOnlyCollection={{
                                                showOnlyCollection:
                                                    contextPopupValues.collectionPicker.isCollectionPicker,
                                                contextPopupValues: contextPopupValues
                                            }}
                                            app={contextPopupValues.collectionPicker.app}
                                            onClick={updateInputValuesWithContext}
                                            usingForInputs={true}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={
                                        selectedCollection && selectedCollection
                                            ? 'button_auto_input_click'
                                            : 'button_auto_input'
                                    }
                                >
                                    Collection data
                                </div>
                            </FunctionPopupTree>
                            <p>Use this collection data to use as input for the input fields.</p>
                        </>
                    )}{' '}
                </div>
            </div>
        </>
    )
}

const EnumDropDown = (props: {
    input: InputFieldT
    state: ActionPanelStateT | FunctionPanelStateT | AppTriggerPanelStateT
    setPanelState: (state: ActionPanelStateT | FunctionPanelStateT) => void
    contextPopupValues: ContextPopupStateT
    setContextPopupValues: (state: ContextPopupStateT) => void
    forTestAction?:boolean
}) => {
    const { state, setPanelState, contextPopupValues, setContextPopupValues, forTestAction } = props
    const { hasLinkedDialogs, active_automation,automationMeta } = useContext(SidePanelContext)
    const [optionState,setOptionState] = useState<any>({ optionSelected:null })

    const { input } = props
    // console.log(input, 'inputinputinput')
    const config = input.config as (InputEnum | MultiSelectEnum)
    const selectedValue = getFirstMatched(
        config.values,
        config => input.value != undefined && config.value.toString() == input.value.toString()
    )

    useEffect(()=>{
        if(config.kind =='multiselect-enum'&&state.mode.mode==NodeMode.Edit){
            const optionSelected = config.values.filter((op)=>(input.value as any).indexOf(op.value)!=-1).map((op)=>{
                return { value:op.value, label: op.text }
            })
            setOptionState({ optionSelected})
        }
    },[])

    const handleChange = (selected:any) => {

        const nextValue = actionNode
                                ? produce(state.currentNode as ActionsResponseT, draftState => {
                                    (forTestAction?draftState.workflowInputsListForTest:draftState.actionInputsList).output.input = (forTestAction?draftState.workflowInputsListForTest:draftState.actionInputsList).output.input.map(
                                          (inputs: InputFieldT) => {
                                              if (input.path) {
                                                  const value = updateValueUsingLens(input.path as string, inputs, {
                                                      inputHasValue: true,
                                                      value: selected.map((s:{value:string,label:string})=> s.value )
                                                  })
                                                  return value
                                              } else if (inputs.key === input.key) {
                                                  return {
                                                      ...inputs,
                                                      inputHasValue: true,
                                                      value: selected.map((s:{value:string,label:string})=> s.value )
                                                  }
                                              }
                                              return inputs
                                          }
                                      )
                                  })
                                : produce(state.currentNode as FunctionResponseT, draftState => {
                                      draftState.functionInputsList.output.input = draftState.functionInputsList.output.input.map(
                                          (inputs: InputFieldT) => {
                                              if (input.path) {
                                                  const value = updateValueUsingLens(input.path as string, inputs, {
                                                      inputHasValue: true,
                                                      value: selected.map((s:{value:string,label:string})=> s.value )
                                                  })
                                                  //   console.log(inputs, 'values', value)
                                                  return value
                                              } else if (inputs.key === input.key) {
                                                  return {
                                                      ...inputs,
                                                      inputHasValue: true,
                                                      value: selected.map((s:{value:string,label:string})=> s.value )
                                                  }
                                              }
                                              return inputs
                                          }
                                      ) as any
                                  })

                            setPanelState({
                                ...state,
                                currentNode: nextValue
                            } as ActionPanelStateT | FunctionPanelStateT)

        setOptionState({
          optionSelected: selected
        });
    };
    //console.log('input Enum', input, config)

    const actionNode = state.currentNode.nodeType == Nodes.Action || state.currentNode.nodeType == Nodes.Trigger

    const isAssistant = getProductId() === 'ASSISTANT'

    return (
        <div className="automation_form_req automation_form_req_step_two">
            <div className="automation_global_form multiselect_form">
                <div className="select_work_form">
                {config.kind =='multiselect-enum'?                                 
                    <InputContainer>
                        <ReactSelect
                                options={
                                    config.values.map((op)=>{
                                        return { value:op.value, label: op.text }
                                    })
                                }
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{
                                    Option : OptionWithCheckboxs,
                                    NoOptionsMessage
                                }}
                                onChange={handleChange}
                                value={optionState.optionSelected}
                                placeholder={input.displayTitle}
                                className="action_select_deck"
                                />
                            <Paragraph>{input.description}</Paragraph>
                        </InputContainer>
                        :
                    <CanvasRightPanel.AppsDropDown
                        inputs={{
                            inputs: config.values,
                            key: 'text',
                            idKey: 'value'
                        }}
                        addDropDown
                        updateSelection={(_: string, id: string) => {
                            const nextValue = actionNode
                                ? produce(state.currentNode as ActionsResponseT, draftState => {
                                      const filteredDependencyLookup = (forTestAction?draftState.workflowInputsListForTest:draftState.actionInputsList).output.input.filter(
                                          inp => inp.config.kind == 'lookup-enum' && inp.config.params[input.key]
                                      )
                                      const inputPreviousValue = input.value as string

                                      (forTestAction?draftState.workflowInputsListForTest:draftState.actionInputsList).output.input = (forTestAction?draftState.workflowInputsListForTest:draftState.actionInputsList).output.input.map(
                                          (inputs: InputFieldT) => {
                                              if (input.path) {
                                                  const value = updateValueUsingLens(input.path as string, inputs, {
                                                      inputHasValue: true,
                                                      value: id.toString()
                                                  })
                                                  return value
                                              } else if (inputs.key === input.key) {
                                                  return {
                                                      ...inputs,
                                                      inputHasValue: true,
                                                      value: id.toString()
                                                  }
                                              } else if (
                                                  filteredDependencyLookup.length > 0 &&
                                                  filteredDependencyLookup[0].key == inputs.key &&
                                                  inputPreviousValue != id.toString()
                                              ) {
                                                  return {
                                                      ...inputs,
                                                      value: '',
                                                      inputHasValue: false
                                                  }
                                              }
                                              return inputs
                                          }
                                      )
                                  })
                                : produce(state.currentNode as FunctionResponseT, draftState => {
                                      const filteredDependencyLookup = draftState.functionInputsList.output.input.filter(
                                          inp => inp.config.kind == 'lookup-enum' && inp.config.params[input.key]
                                      )
                                      const inputPreviousValue = input.value as string

                                      draftState.functionInputsList.output.input = draftState.functionInputsList.output.input.map(
                                          (inputs: InputFieldT) => {
                                              if (input.path) {
                                                  const value = updateValueUsingLens(input.path as string, inputs, {
                                                      inputHasValue: true,
                                                      value: id.toString()
                                                  })
                                                  //   console.log(inputs, 'values', value)
                                                  return value
                                              } else if (inputs.key === input.key) {
                                                  return {
                                                      ...inputs,
                                                      inputHasValue: true,
                                                      value: id.toString()
                                                  }
                                              } else if (
                                                  filteredDependencyLookup.length > 0 &&
                                                  filteredDependencyLookup[0].key == inputs.key &&
                                                  inputPreviousValue != id.toString()
                                              ) {
                                                  return {
                                                      ...inputs,
                                                      value: '',
                                                      inputHasValue: false
                                                  }
                                              }
                                              return inputs
                                          }
                                      )
                                  })

                            setPanelState({
                                ...state,
                                currentNode: nextValue
                            } as ActionPanelStateT | FunctionPanelStateT)
                        }}
                        render={(makeDropDownVisible: () => void) => (
                            <React.Fragment>
                                <InputContainer className={`${
                                            (isAssistant ? automationMeta.type ==='no_trigger' ?false : active_automation: active_automation) ? 'disabled_with_blue' : ''
                                    }`}>
                                    <InputField
                                        type="text"
                                        className={`without_caret_input`}
                                        value={input.value && selectedValue ? selectedValue.text.toString() : 'Select'}
                                        onClick={() => {
                                            setContextPopupValues({ ...contextPopupValues, currentInput: input.key })
                                            makeDropDownVisible()
                                        }}
                                        onChange={() => {}}
                                    />
                                    <InputContent>{input.displayTitle}</InputContent>
                                    <CancelButtonInput
                                        rotate
                                        onClick={() => {
                                            setContextPopupValues({ ...contextPopupValues, currentInput: input.key })
                                            makeDropDownVisible()
                                        }}
                                    >
                                        <DownarrowWithCircle />
                                    </CancelButtonInput>
                                    <Paragraph>{input.description}</Paragraph>
                                </InputContainer>
                            </React.Fragment>
                        )}
                    />}
                </div>
            </div>
        </div>
    )
}

const DateAndTimePicker = (props: {
    input: InputFieldT
    index: number
    state: ActionPanelStateT | FunctionPanelStateT | AppTriggerPanelStateT
    setPanelState: (state: ActionPanelStateT | FunctionPanelStateT) => void
    contextPopupValues: ContextPopupStateT
    setContextPopupValues: (state: ContextPopupStateT) => void
    forTestAction?:boolean
}) => {
    const { state, setPanelState, contextPopupValues, setContextPopupValues, forTestAction } = props

    const { hasLinkedDialogs ,active_automation,automationMeta} = useContext(SidePanelContext)

    const currentNode = state.currentNode as ActionsResponseT

    const { input, index } = props

    const [localState, setState] = useState<any>(false)

    const value = input.value as string

    const isAssistant = getProductId() === 'ASSISTANT'

    return (
        <div className="automation_form_req automation_form_req_step_two">
            <div className="automation_global_form">
                <div className="select_work_form">
                    <React.Fragment>
                        {/* <Header>{input.displayTitle}</Header> */}
                        <InputContainer>
                            <InputField
                                type="text"
                                className={
                                    contextPopupValues.currentInput === input.key
                                        ? `without_caret_input form_req_step_two_global_form input_selected ${
                                            (isAssistant ? automationMeta.type ==='no_trigger' ?false : active_automation: active_automation) ? 'disabled_with_blue' : ''
                                          }`
                                        : `without_caret_input ${(isAssistant ? automationMeta.type ==='no_trigger' ?false : active_automation: active_automation) ? 'disabled_with_blue' : ''}`
                                }
                                value={value && value.length > 0 ? value : 'Set date and time'}
                                onClick={() => {
                                    setState(true)
                                    setContextPopupValues({ ...contextPopupValues, currentInput: input.key })
                                }}
                                onBlur={() => {}}
                                onChange={() => {}}
                            />
                            <InputContent>{input.displayTitle}</InputContent>
                            <CancelButtonInput
                                rotate
                                onClick={() => {
                                    setState(true)
                                    setContextPopupValues({ ...contextPopupValues, currentInput: input.key })
                                }}
                            >
                                <DownarrowWithCircle />
                            </CancelButtonInput>
                        </InputContainer>
                    </React.Fragment>
                    <Downshift
                        isOpen={localState}
                        onOuterClick={() => {
                            setContextPopupValues({ ...contextPopupValues, currentInput: '' })
                            setState(false)
                        }}
                    >
                        {({ isOpen }) =>
                            isOpen && (
                                <div className="date_and_time_picker">
                                    <Calendar
                                        value={
                                            input.value
                                                ? typeof input.value == 'string'
                                                    ? input.value
                                                    : convertAsaSingleString(
                                                          convertToRaw(input.value.getCurrentContent()).blocks
                                                      )
                                                : undefined
                                        }
                                        choosenDate={date => {
                                            const nextValue = produce(currentNode, draftState => {
                                                (forTestAction?draftState.workflowInputsListForTest:draftState.actionInputsList).output.input = (forTestAction?draftState.workflowInputsListForTest:draftState.actionInputsList).output.input.map(
                                                    (inputs: InputFieldT) => {
                                                        if (inputs.key === input.key) {
                                                            return {
                                                                ...inputs,
                                                                inputHasValue: true,
                                                                value: date.toString()
                                                            }
                                                        }
                                                        return inputs
                                                    }
                                                )
                                            })

                                            setPanelState({
                                                ...state,
                                                currentNode: nextValue
                                            })
                                        }}
                                        closePopup={() => {
                                            setContextPopupValues({ ...contextPopupValues, currentInput: '' })
                                            setState(false)
                                        }}
                                    />
                                </div>
                            )
                        }
                    </Downshift>

                    <p>{input.description}</p>
                </div>
            </div>
        </div>
    )
}

const LookUpEnumDropDown = (props: {
    input: InputFieldT
    workspacename: string
    state: ActionPanelStateT | FunctionPanelStateT | AppTriggerPanelStateT
    setPanelState: (state: ActionPanelStateT | FunctionPanelStateT) => void
    contextPopupValues: ContextPopupStateT
    setContextPopupValues: (state: ContextPopupStateT) => void
    forTestAction?:boolean
}) => {
    const { state, setPanelState, contextPopupValues, setContextPopupValues, forTestAction } = props

    const currentNode = state.currentNode as ActionsResponseT | FunctionResponseT | AppTriggerResponseT

    const [search, setSearch] = useState({
        searching: false,
        key: '',
        inFocus: false
    })

    const [optionState,setOptionState] = useState<any>({ optionSelected:null })

    const { hasLinkedDialogs, active_automation,automationMeta } = useContext(SidePanelContext)

    const [preVDependencyValue, setPreVDependencyValue] = useState<
        {
            key: string
            value: string | EditorState
        }[]
    >([
        {
            key: '',
            value: ''
        }
    ])

    const [currentLookUpValues, setCurrentLookUpValues] = useState<{
        selectedValue: { text: string; value: string }
        options: {
            text: string
            value: any
        }[]
    }>({ selectedValue: { text: '', value: '' }, options: [] })

    function getInputValue(key: string) {
        if (!key) {
            return []
        }
         if(forTestAction && currentNode.nodeType == Nodes.Action){
           return currentNode.workflowInputsListForTest.output.input.filter(inp => inp.key == key)
         }

        return (currentNode.nodeType == Nodes.Action || currentNode.nodeType == Nodes.Trigger
            ? currentNode.actionInputsList
            : currentNode.functionInputsList
        ).output.input
            .map(y => {
                const filteredMacthedInput = contextPopupValues.inputWithValues.filter(x => {
                    return key == x.nodeInput
                })

                if (filteredMacthedInput.length > 0) {
                    return {
                        ...y,
                        value: convertToServerFormat(y.value as any)
                    } as InputFieldT
                } else if (y.key == key) {
                    return { ...y } as InputFieldT
                }

                return { ...y, value: '' } as InputFieldT
            })
            .filter(inp => inp.key == key)
    }

    const { input } = props
    const config = input.config as (LookUpEnum | MultipleLookUpEnum)

    let isDependsOnOtherInput: any = []

    const dependency = Object.values(config.params)

    if (dependency.length > 0) {
        isDependsOnOtherInput = [
            (currentNode.nodeType == Nodes.Action || currentNode.nodeType == Nodes.Trigger ? (forTestAction && currentNode.nodeType == Nodes.Action?currentNode.workflowInputsListForTest:currentNode.actionInputsList) : currentNode.functionInputsList)
                .output.input,
            contextPopupValues
        ]
    }

    let editInput: InputFieldT[] = []

    if (state.mode.mode == NodeMode.Edit) {
        const editInputs = state.mode.nodeEditInput.input as InputFieldT[]
        editInput = editInputs.filter(inp => dependency.includes(inp.key))
    }

    useEffect(() => {
        setPreVDependencyValue(editInput.length > 0 ? editInput : [{ key: '', value: '' }])
    }, [])

    useEffect(() => {
        const workspaceName = props.workspacename

        const dependencyInputs =
            dependency.length > 0
                ? dependency.reduce((acc, curr) => {
                      const x = getInputValue(curr)
                      return [...acc, ...x]
                  }, [] as InputFieldT[])
                : ([] as InputFieldT[])

        const valueList = dependencyInputs.map(x => ({ key: x.key, value: x.value ? x.value: '' }))

        let convertedValues = valueList.map(({ key, value }) => ({
            key,
            value:
                typeof value === 'string'
                    ? value
                    : convertAsaSingleString(convertToRaw(value.getCurrentContent()).blocks)
        }))

        const validConvertedValues = convertedValues.every(x => x.value.length > 0) ? convertedValues : []

        const compareInputs = (prevVal: string | EditorState, currVal: string | EditorState) => {
            const currentValue =
                typeof currVal == 'string'
                    ? currVal
                    : convertAsaSingleString(convertToRaw(currVal.getCurrentContent()).blocks)
            let previousValue = ''
            if (typeof prevVal == 'string') {
                previousValue = prevVal
            } else if (prevVal) {
                previousValue = convertAsaSingleString(convertToRaw(prevVal.getCurrentContent()).blocks)
            }
            return currentValue != previousValue
        }

        const comparedInputs =
            dependencyInputs.length > 0
                ? dependencyInputs.filter(input => {
                      const prevDependentValue = preVDependencyValue.filter(prevValue => prevValue.key === input.key)[0]
                      return prevDependentValue
                          ? compareInputs(prevDependentValue.value? prevDependentValue.value : "", input.value? input.value: "")
                          : compareInputs('', input.value? input.value : "") // true -> prev != curr
                  })
                : []

        if (
            (dependencyInputs.length === 0 || validConvertedValues.length > 0) &&
            (editInput.length === 0 ||
                // comparedInputs.length > 0 &&
                (contextPopupValues.currentInput && contextPopupValues.currentInput.length && dependency.includes(contextPopupValues.currentInput)) ||
                (currentLookUpValues.options.length == 0 && comparedInputs.length > 0))
        ) {
            // to compare with new values
            setPreVDependencyValue(
                dependencyInputs.length > 0
                    ? dependencyInputs.map(x => ({ key: x.key, value: x.value }))
                    : [{ key: '', value: '' }]
            )

            const params =
                dependency.length > 0
                    ? validConvertedValues.reduce((acc, curr) => ({ ...acc, [curr.key]: curr.value }), {})
                    : {}
            let req: any

            if (currentNode.nodeType == Nodes.Function) {
                req = {
                    appId: currentNode.selectedFunction.id,
                    version: currentNode.selectedFunction.version,
                    lookupId: config.lookup,
                    params
                }
            } else {
                req = {
                    connectionId: currentNode.selectedConnection.id,
                    appId: currentNode.selectedApp.id,
                    version: currentNode.selectedApp.version,
                    lookupId: config.lookup,
                    params
                }
            }

            postJson(automationAPIURLWithWorkspace(workspaceName, `/actions/lookup`))(req)
                .then(resp => {
                    const response = resp.data as {
                        type: 'success'
                        output: {
                            text: string
                            value: any
                        }[]
                    }

                    if(config.kind =='multiselect-lookup-enum' && state.mode.mode==NodeMode.Edit){
                        const optionSelected = response.output.filter((op)=> input.value && (input.value as any).indexOf(op.value)!=-1).map((op)=>{
                            return { value:op.value, label: op.text }
                        })
                        console.log(optionSelected,input,currentLookUpValues)
                        setOptionState({ optionSelected})
                        setCurrentLookUpValues({
                            selectedValue: {
                                text: '',
                                value: ''
                            },
                            options: response.output
                        })
                    }else{
                    const filteredValue = response.output.filter(option => option.value == input.value)

                    setCurrentLookUpValues({
                        selectedValue: {
                            text: filteredValue.length > 0 ? filteredValue[0].text : '',
                            value: filteredValue.length > 0 ? filteredValue[0].value : ''
                        },
                        options: response.output
                    })
                }
                })
                .catch(() => {
                    setCurrentLookUpValues({ selectedValue: { text: '', value: '' }, options: [] })
                })
        }
    }, isDependsOnOtherInput)

    const handleChange = (selected:any) => {

        const nextValue = produce(currentNode, draftState => {

            const inputlist = (draftState.nodeType == Nodes.Action || draftState.nodeType == Nodes.Trigger
                ? (forTestAction && draftState.nodeType == Nodes.Action ?draftState.workflowInputsListForTest:draftState.actionInputsList)
                : draftState.functionInputsList
            )

            inputlist.output.input = inputlist.output.input.map((inputs: InputFieldT) => {
                if (inputs.key === input.key) {
                    return {
                        ...inputs,
                        inputHasValue: true,
                        value: selected.map((s:{value:string,label:string})=> s.value )
                    }
                }
                return inputs
            }) as any
        })

        setPanelState({
            ...state,
            currentNode: nextValue
        } as any)

        setOptionState({
          optionSelected: selected
        });
      };

    const isAssistant = getProductId() === 'ASSISTANT'

    return (
        <div className="automation_form_req automation_form_req_step_two">
            <div className="automation_global_form">
                <div className="select_work_form">
                    {config.kind =='multiselect-lookup-enum'? 
                    <InputContainer>
                        <ReactSelect
                            options={
                                currentLookUpValues.options.map((op)=>{
                                    return { value:op.value, label: op.text }
                                })
                            }
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{
                                Option : OptionWithCheckboxs,
                                NoOptionsMessage
                            }}
                            onChange={handleChange}
                            value={optionState.optionSelected}
                            placeholder={input.displayTitle}
                            className="action_select_deck"
                        />
                        <Paragraph>{input.description}</Paragraph>
                        </InputContainer>
                        :
                    <CanvasRightPanel.AppsDropDown
                        inputs={{
                            inputs: search.searching? currentLookUpValues.options.filter(
                                (opt: any) =>
                                opt.text.toLowerCase().indexOf(search.key.toLowerCase()) > -1
                            ):currentLookUpValues.options,
                            key: 'text',
                            idKey: 'value'
                        }}
                        addDropDown
                        closeCallBack={() => {
                            !search.inFocus && setSearch({ ...search, searching: false })
                        }}
                        updateSelection={(selectedOption: string, id: string) => {
                            const nextValue = produce(currentNode, draftState => {
                                const inputlist = (draftState.nodeType == Nodes.Action || draftState.nodeType == Nodes.Trigger
                                    ? (forTestAction && draftState.nodeType == Nodes.Action ?draftState.workflowInputsListForTest:draftState.actionInputsList)
                                    : draftState.functionInputsList
                                )
                                const filteredDependencyLookup = inputlist.output.input.filter(
                                    inp => inp.config.kind == 'lookup-enum' && inp.config.params[input.key]
                                )
                                const inputPreviousValue = input.value as string
                                ;inputlist.output.input = inputlist.output.input.map((inputs: InputFieldT) => {
                                    if (inputs.key === input.key) {
                                        return {
                                            ...inputs,
                                            inputHasValue: true,
                                            value: id.toString()
                                        }
                                    } else if (
                                        filteredDependencyLookup.length > 0 &&
                                        filteredDependencyLookup[0].key == inputs.key &&
                                        inputPreviousValue != id.toString()
                                    ) {
                                        return {
                                            ...inputs,
                                            value: '',
                                            inputHasValue: false
                                        }
                                    }
                                    return inputs
                                })
                            })

                            setPanelState({
                                ...state,
                                currentNode: nextValue
                            } as any)

                            setCurrentLookUpValues({
                                ...currentLookUpValues,
                                selectedValue: { value: id.toString(), text: selectedOption.toString() }
                            })
                        }}
                        render={(makeDropDownVisible: () => void) => (
                            <React.Fragment>
                                {/* <Header>{input.displayTitle}</Header> */}
                                <InputContainer>
                                    <InputField
                                        className={`without_caret_input ${
                                            (isAssistant ? automationMeta.type ==='no_trigger' ?false : active_automation: active_automation) ? 'disabled_with_blue' : ''
                                        }`}
                                        type="text"
                                        value={
                                            search.searching
                                                ? search.key : currentLookUpValues.selectedValue.text
                                                ? currentLookUpValues.selectedValue.text
                                                : 'Select'
                                        }
                                        onClick={() => {
                                            setContextPopupValues({ ...contextPopupValues, currentInput: input.key })
                                            makeDropDownVisible()
                                        }}
                                        onFocus={() => {
                                            setSearch({
                                                inFocus: true,
                                                searching: true,
                                                key: currentLookUpValues.selectedValue.text.toLowerCase() !== 'select'
                                                        ? currentLookUpValues.selectedValue.text
                                                        : ''
                                            })
                                        }}
                                        onBlur={() => {
                                            setSearch({
                                                ...search,
                                                inFocus: false
                                            })
                                        }}
                                        onChange={(e: any) => {
                                            setSearch({ ...search, key: e.target.value })
                                        }}
                                    />
                                    <InputContent>{input.displayTitle}</InputContent>
                                    <CancelButtonInput
                                        rotate
                                        onClick={() => {
                                            setContextPopupValues({ ...contextPopupValues, currentInput: input.key })
                                            makeDropDownVisible()
                                        }}
                                    >
                                        <DownarrowWithCircle />
                                    </CancelButtonInput>
                                    <p>{input.description}</p>
                                </InputContainer>
                            </React.Fragment>
                        )}
                    />}
                </div>
            </div>
        </div>
    )
}

const Switch = (props: {
    input: InputFieldT
    workspacename: string
    state: ActionPanelStateT | FunctionPanelStateT | AppTriggerPanelStateT
    setPanelState: (state: ActionPanelStateT | FunctionPanelStateT) => void
    contextPopupValues: ContextPopupStateT
    setContextPopupValues: (state: ContextPopupStateT) => void
    forTestAction?:boolean
}) => {
    const { input, state, setPanelState, forTestAction } = props

    const actionNode = state.currentNode.nodeType == Nodes.Action || state.currentNode.nodeType == Nodes.Trigger
    const { treeData, hasLinkedDialogs, active_automation, automationMeta } = useContext(SidePanelContext)
    const isAssistant = getProductId() === 'ASSISTANT'

    return (
        <div className="automation_form_req automation_form_req_step_two">
            <div className="automation_global_form">
                <div className="select_work_form">
                    <div className="error_handle_autoamtion_bg">
                        <div className="error_handle_autoamtion">
                            <Header style={{ fontSize: '14px' }} className="d-flex align-items-center">
                                {input.displayTitle}
                                <span className="show_hide _auto_switch">
                                    <div className={(isAssistant ? automationMeta.type ==='no_trigger' ?false : active_automation: active_automation)?`switch_auto disabled_with_blue`:`switch_auto`}>
                                        <input
                                            type="checkbox"
                                            className={`switch_1`}
                                            checked={input.value && input.value == 'yes' ? true : false}
                                            onChange={() => {}}
                                            onClick={() => {
                                                const nextValue = actionNode
                                                    ? produce(state.currentNode as ActionsResponseT, draftState => {
                                                          (forTestAction?draftState.workflowInputsListForTest:draftState.actionInputsList).output.input = (forTestAction?draftState.workflowInputsListForTest:draftState.actionInputsList).output.input.map(
                                                              (inputs: InputFieldT) => {
                                                                  if (
                                                                      input.path &&
                                                                      input.path.split('/')[0] == inputs.key
                                                                  ) {
                                                                      const value = updateValueUsingLens(
                                                                          input.path as string,
                                                                          inputs,
                                                                          {
                                                                              value:
                                                                                  !input.value || input.value == 'no'
                                                                                      ? 'yes'
                                                                                      : 'no',
                                                                              inputHasValue: true
                                                                          }
                                                                      )
                                                                      return value
                                                                  } else if (inputs.key === input.key) {
                                                                      return {
                                                                          ...inputs,
                                                                          inputHasValue: true,
                                                                          value:
                                                                              !input.value || input.value == 'no'
                                                                                  ? 'yes'
                                                                                  : 'no'
                                                                      }
                                                                  }
                                                                  return inputs
                                                              }
                                                          )
                                                      })
                                                    : produce(state.currentNode as FunctionResponseT, draftState => {
                                                          draftState.functionInputsList.output.input = draftState.functionInputsList.output.input.map(
                                                              (inputs: InputFieldT) => {
                                                                  if (
                                                                      input.path &&
                                                                      input.path.split('/')[0] == inputs.key
                                                                  ) {
                                                                      const value = updateValueUsingLens(
                                                                          input.path as string,
                                                                          inputs,
                                                                          {
                                                                              inputHasValue: true,
                                                                              value: (!input.value ||
                                                                              typeof input.value == 'string'
                                                                                ? input.value == 'no'
                                                                                : convertToRaw(
                                                                                      input.value.getCurrentContent()
                                                                                  ).blocks[0].text == 'no')
                                                                                  ? 'yes'
                                                                                  : 'no'
                                                                          }
                                                                      )

                                                                      return value
                                                                  } else if (inputs.key === input.key) {
                                                                      return {
                                                                          ...inputs,
                                                                          inputHasValue: true,
                                                                          value: (!input.value ||
                                                                          typeof input.value == 'string'
                                                                            ? input.value == 'no'
                                                                            : convertToRaw(
                                                                                  input.value.getCurrentContent()
                                                                              ).blocks[0].text == 'no')
                                                                              ? 'yes'
                                                                              : 'no'
                                                                      }
                                                                  }
                                                                  return inputs
                                                              }
                                                          )
                                                      })

                                                setPanelState({
                                                    ...state,
                                                    currentNode: nextValue
                                                } as ActionPanelStateT | FunctionPanelStateT)
                                            }}
                                        />
                                    </div>
                                </span>
                            </Header>
                            <p>{input.description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
