/*eslint-disable*/
import * as React from 'react';

import { SlackIconCC, TeamsIcon, WidgetIcon } from '@/common/Icons/Icons';
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary';
import { Header, HeaderChild } from '@/common/components/Header';
import { Loader } from '@/common/components/Loader';
import { formatDateInCard,formatAMPM } from '@/common/utils/utils';

import {
  getJson,
  slackIntegrationUrl,
  teamsIntegrationGetListUrl,
  widgetIntegrationURL,
} from '@/common/utils/api-utils';
import { workspace } from './Widget';
import {
  Action,
  ErrorPage,
  Loading,
  Modes,
  TeamsWorkspaceT,
  UnauthorizedPage,
  View,
  WorkspaceT,
} from './types';
import {
  MOCK_SLACK,
  MOCK_TEAMS,
  MOCK_WIDGETV,
  slackResponse,
  TeamResponse,
  WidegetResponse,
} from './MockFile';
import { usePermissions } from '@/common/utils/auth-token-api-utils';
// import Skype from '../../images/skype.png'

type IntegrateS = {
  slackworkspaces: WorkspaceT[];
  teamsWorkspaces: TeamsWorkspaceT[];
  widgetWorkspaces: workspace[];
  PageState: Modes;
};
export class Integration extends React.Component<any, IntegrateS> {
  state: IntegrateS = {
    slackworkspaces: [],
    teamsWorkspaces: [],
    widgetWorkspaces: [],
    PageState: View,
  };

  componentDidMount() {
    this.getWorkSpaces();
  }

  getWorkSpaces = () => {
    const workspacename = this.props.match.params.workspacename;
    this.setState({ PageState: Loading });

    let state = this.state;

    // MOCK_SLACK
    //   ? Promise.resolve(slackResponse).then((res) => {
    //       console.log('res', res);
    //       this.setState({
    //         ...state,
    //         slackworkspaces: res,
    //         PageState: View,
    //       });
    //     })
    //   : '';
    // MOCK_TEAMS
    //   ? Promise.resolve(TeamResponse).then((res) => {
    //       console.log('res', res);
    //       this.setState({
    //         ...state,
    //         teamsWorkspaces: res,
    //         PageState: View,
    //       });
    //     })
    //   : '';

    // MOCK_WIDGETV
    //   ? Promise.resolve(WidegetResponse).then((res) => {
    //       console.log('res', res);
    //       this.setState({
    //         ...state,
    //         widgetWorkspaces: res,
    //         PageState: View,
    //       });
    //     })
    //   : '';
        getJson(slackIntegrationUrl(`/clients/${workspacename}`))
          .then((res) => {
            state = { ...state, slackworkspaces: res };
        getJson(
              teamsIntegrationGetListUrl(workspacename, '/credentialList/')
            ).then((res) => {
              console.log(res,'teamslatest')
              state = { ...state, teamsWorkspaces: res };
        getJson(widgetIntegrationURL(workspacename, '/widget/list/')).then(
                (res) => {
                  this.setState({
                    ...state,
                    widgetWorkspaces: res,
                    PageState: View,
                  });
                }
              );
            });
          })
          .catch((err) => {
            if (err.response && err.response.status == 403) {
              this.setState({
                PageState: UnauthorizedPage,
              });
            } else {
              this.setState({ PageState: ErrorPage });
            }
          });
  };

  render() {
    const { PageState } = this.state;

    if (PageState.action === Action.Loading)
      return <Loader.PageLoader show={true} />;

    return (
      <React.Fragment>
        <Header>
          {[
            <HeaderChild type="first">
              <div className="indent_left">
                <div className="indent_title">Chatbot Channels</div>
                <p className="content_header">
                  Deploy your chatbot on your chat channels.{' '}
                </p>
                {/* <div className="">Select chat channel to integrate assistant</div> */}
              </div>
              {/* <section className="intr_homepage">
                                <div className="mt-3">
                                    <h6>Chat Channel</h6>
                                    <p>Select chat channel to integrate chatbot</p>
                                </div>
                            </section> */}
            </HeaderChild>,
          ]}
        </Header>
        <ErrorBoundary
          unauthorized={PageState.action === Action.Unauthorized}
          error={
            PageState.action !== Action.ErrorPage
              ? { error: null, info: null }
              : {
                  error: true,
                  info: 'Server error!<br />Please try again after sometime.',
                }
          }
          render={(err: any, info: any) => {
            return (
              err && (
                <Error.PageLoadingError
                  onClick={() => {
                    this.getWorkSpaces();
                  }}
                  info={info}
                  btnName={'Retry'}
                />
              )
            );
          }}
        >
          {/* <section className="intr_homepage">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-12 pl-15">
                            <h6>Chat Channel Integration</h6>
                            <p>Select chat channel to integrate chatbot</p>
                        </div>
                    </section> */}
          <ReturnList state={this.state} match={this.props.match} history={this.props.history}/>
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

function ReturnList(props:{state:IntegrateS,match:any,history:any}){
  const {state} = props
  const slackRead = usePermissions(props.match.params.workspacename,`CHAT_CHANNEL_MODULE/CHANNEL/SLACK`,"READ")
  const teamsRead = usePermissions(props.match.params.workspacename,`CHAT_CHANNEL_MODULE/CHANNEL/TEAMS`,"READ")
  const widgetRead = usePermissions(props.match.params.workspacename,`CHAT_CHANNEL_MODULE/CHANNEL/WIDGET`,"READ")

  return(
    <section className="entity_cards intr_chat_cards">
            <div className="col-md-12">
            <div className="chat_channel_row">
              {slackRead.value && <div
                className="col-3 intr_chat_cards_bg"
                onClick={() => {
                  props.history.push(
                    `/workspace/${props.match.params.workspacename}/integration/chat/slack`
                  );
                }}
              >
               
                <div className="chat_channel_container">
                    <div className="chat_channel_img">
                      <SlackIconCC />
                    </div>
                    <div className="intr_chat_cards_cont">
                      <h2>Slack</h2>
                      <h6>
                        Integrations{' '}
                        <span>(
                          {state.slackworkspaces.length == 0
                            ? '0'
                            : state.slackworkspaces.length < 10
                            ? '0' + state.slackworkspaces.length
                            : state.slackworkspaces.length}
                        )
                        </span>
                      </h6>
                    </div>
                  </div>
                  <div className="error_icon_wrapper">
                      <div className="error_icon_wrapper_left">
                          <span className="entity_span">
                            {state.slackworkspaces.length > 0
                            ? formatDateInCard(state.slackworkspaces[0].updated_at)
                            : "--"
                            }
                          </span>
                          <span className="entity_span">|</span>
                          <span className="entity_span">
                             {state.slackworkspaces.length > 0
                            ? formatAMPM(state.slackworkspaces[0].updated_at)
                            : "--"
                            }
                          </span>
                      </div>
                  </div>
              </div>}
              {teamsRead.value&&<div
                className="col-3 intr_chat_cards_bg_team"
                onClick={() =>
                  props.history.push(
                    `/workspace/${props.match.params.workspacename}/integration/chat/teams`
                  )
                }
              >
                <div className="chat_channel_container">
                  <div className="chat_channel_img team_integation">
                    <TeamsIcon />
                  </div>
                  <div className="intr_chat_cards_cont">
                    <h2>MS teams</h2>
                    <h6>
                      Integrations{' '}
                      <span>(
                        {state.teamsWorkspaces.length == 0
                          ? '0'
                          : state.teamsWorkspaces.length < 10
                          ? '0' + state.teamsWorkspaces.length
                          : state.teamsWorkspaces.length}
                      )
                      </span>
                    </h6>
                  </div>
                </div>
                <div className="error_icon_wrapper">
                      <div className="error_icon_wrapper_left">
                          <span className="entity_span">
                            {state.teamsWorkspaces.length>0 
                            ? formatDateInCard(state.teamsWorkspaces[0].updated_at)
                            :"--"
                              }
                          </span>
                          <span className="entity_span">|</span>
                          <span className="entity_span">
                           {state.teamsWorkspaces.length >0
                           ?formatAMPM(state.teamsWorkspaces[0].updated_at):
                           "--"
                              }
                          </span>
                      </div>
                  </div>
              </div>}
              {widgetRead.value&&<div
                className="col-3 intr_chat_cards_bg_team"
                onClick={() =>
                  props.history.push(
                    `/workspace/${props.match.params.workspacename}/integration/chat/widget`
                  )
                }
              >
                <div className="chat_channel_container">
                  <div className="chat_channel_img team_integation">
                    <WidgetIcon />
                  </div>
                  <div className="intr_chat_cards_cont">
                    <h2>Chat widget</h2>
                    <h6>
                      Integrations{' '}
                      <span>(
                        {state.widgetWorkspaces.length == 0
                          ? '0'
                          : state.widgetWorkspaces.length < 10
                          ? '0' + state.widgetWorkspaces.length
                          : state.widgetWorkspaces.length}
                      )
                      </span>
                    </h6>
                  </div>
                </div>
                <div className="error_icon_wrapper">
                      <div className="error_icon_wrapper_left">
                          <span className="entity_span">
                            {state.widgetWorkspaces.length>0 
                            ? formatDateInCard(state.widgetWorkspaces[0].updated_at)
                            :"--"
                              }
                          </span>
                          <span className="entity_span">|</span>
                          <span className="entity_span">
                          {state.widgetWorkspaces.length >0
                           ?formatAMPM(state.widgetWorkspaces[0].updated_at):
                           "--"
                              }
                          </span>
                      </div>
                  </div>
              </div>}
              <div
                className="intr_chat_cards_bg_skype"
                style={{ visibility: 'hidden' }}
              >
                <div className="row" />
              </div>
            </div>
            </div>
          </section>
  )
}
