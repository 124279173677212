/*eslint-disable*/
import { produce } from 'immer'
import React, { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'

import { useGenericCached } from '@/common/hooks/useCachedQuery'
import { FetchReducerT, initialLoadingData } from '@/common/hooks/usefetch-from-api'

import { AutomationApi, automationAPIURLWithWorkspace, wrapperGetJson } from '@/common/utils/api-utils'
import { getDialogData } from '../FlowWrapper'
import { fetchRoots } from '../utils'
import { FlowCanvas } from './Canvas'

export function FlowCanvasWrapper(props: any) {
    const flowId = props.match.params.id

    const workspaceName = props.match.params.workspacename

    const queryClient = useQueryClient()

    // const [state, setState, promiseRef] = useFetchFromApi(pendingPromise)

    const allWorkflow = useGenericCached(
        'workflows',
        () => wrapperGetJson(automationAPIURLWithWorkspace(workspaceName, AutomationApi.GetAll)),
        {
            refetchOnMount: false,
            staleTime: 30000
        }
    )

    const dialogData = useGenericCached('workflow-bots', () => getDialogData(workspaceName))

    const currentWorkflow = useGenericCached(
        `workflow::${flowId}`,
        () => wrapperGetJson(automationAPIURLWithWorkspace(workspaceName, `${AutomationApi.GetDetail}/${flowId}`)),
        {
            refetchOnMount: true,
            staleTime: 0
        },
        {
            everyTime: true
        }
    )

    // console.log('currentWorkflow', currentWorkflow, dialogData, allWorkflow)

    const [cachedData, setCachedData] = useState<FetchReducerT>(initialLoadingData)

    useEffect(() => {
        // console.log('COG1-7574 ::: use Effect')
        if (dialogData.status == 'fetched' && currentWorkflow.status == 'fetched' && allWorkflow.status == 'fetched') {
            const allWorkflowData = allWorkflow.data.data
            const dialogs = dialogData.data
            const currentWorkflowData = currentWorkflow.data.data

            // console.log('COG1-7574 ::: allWorkflowData', allWorkflowData)

            if (allWorkflowData.type === 'success') {
                const automations: any[] = allWorkflowData.output.automations
                const sortedList: any[] = allWorkflowData.output.sortedList

                // console.log('sortedList', sortedList)

                automations.map((automation: any) => {
                    automation.linkedDialogs = fetchRoots(dialogs, automation.id)
                    //console.log('autimations linked', automation.linkedDialogs)
                    return automation
                })
                sortedList.map((sAutomation: any) => {
                    //console.log('Automations', response.output)
                    sAutomation.linkedDialogs = fetchRoots(dialogs, sAutomation.id)
                    //console.log('autimations linked', automation.linkedDialogs)
                    return sAutomation
                })

                if (
                    currentWorkflowData.output &&
                    currentWorkflowData.output.custom_json &&
                    Object.keys(currentWorkflowData.output.custom_json).length > 0
                ) {
                    // console.log('flowList -2', flowList)
                    if (currentWorkflowData.output.deployed_status == 'deployed') {
                        // console.log('load automation')

                        const nextState = produce(currentWorkflowData, (draftState: any) => {
                            draftState.output.allAutomations = allWorkflowData.output.automations
                        })

                        setCachedData({
                            type: 'success',
                            data: nextState
                        })
                    } else {
                        // console.log('flowList -3', flowList)
                        const nextState = produce(currentWorkflowData, (draftState: any) => {
                            draftState.output.allAutomations = []
                        })
                        setCachedData({
                            type: 'success',
                            data: nextState
                        })
                    }
                } else {
                    // console.log('flowList -3', flowList)
                    const nextState = produce(currentWorkflowData, (draftState: any) => {
                        draftState.output.allAutomations = []
                    })
                    setCachedData({
                        type: 'success',
                        data: nextState
                    })
                }
            } else {
                const nextState = produce(allWorkflow, (draftState: any) => {
                    draftState.output.allAutomations = []
                })
                setCachedData({
                    type: 'success',
                    data: nextState
                })
            }
        }

        if (currentWorkflow.status === 'error' && currentWorkflow.error.tag == 'AUTOMATION_NOT_FOUND') {
            const location = {
                pathname: `/workspace/${workspaceName}/automation/view/home`,
                search: `?redirect_from_not_found=${flowId}`
            }
            // console.log('err', 'Not found error:', props)
            props.history.replace(location)
            // return Promise.reject(err)
        }
        if (
            dialogData.status == 'fetching' ||
            currentWorkflow.status == 'fetching' ||
            allWorkflow.status == 'fetching'
        ) {
            setCachedData(initialLoadingData)
        }
    }, [dialogData.status, currentWorkflow.status, allWorkflow.status])

    // useEffect(() => {
    //     const flowId = props.match.params.id
    //     promiseRef.current = wrapperGetJson(
    //         automationAPIURLWithWorkspace(workspaceName, `${AutomationApi.GetDetail}/${flowId}`)
    //     )
    //         .then((response: any) => response.data)
    //         .then(flowList => {
    //             // console.log('flowList', flowList)
    //             if (
    //                 flowList.output &&
    //                 flowList.output.custom_json &&
    //                 Object.keys(flowList.output.custom_json).length > 0
    //             ) {
    //                 // console.log('flowList -2', flowList)
    //                 if (flowList.output.deployed_status == 'deployed') {
    //                     // console.log('load automation')
    //                     return loadAutomations(workspaceName).then(
    //                         (response: { output: FlowsResponseT; type: string }) => {
    //                             const nextState = produce(flowList, (draftState: any) => {
    //                                 draftState.output.allAutomations = response.output.automations
    //                             })

    //                             return nextState
    //                         }
    //                     )
    //                 } else {
    //                     // console.log('flowList -3', flowList)
    //                     const nextState = produce(flowList, (draftState: any) => {
    //                         draftState.output.allAutomations = []
    //                     })
    //                     return nextState
    //                 }
    //             }

    //             const nextState = produce(flowList, (draftState: any) => {
    //                 draftState.output.allAutomations = []
    //             })
    //             return nextState
    //         })
    //         .catch((err: any) => {
    //             if (
    //                 err &&
    //                 err.response &&
    //                 err.response.data &&
    //                 err.response.data.type == 'error' &&
    //                 err.response.data.tag == 'AUTOMATION_NOT_FOUND'
    //             ) {
    //                 const location = {
    //                     pathname: `/workspace/${workspaceName}/automation/view/home`,
    //                     search: `?redirect_from_not_found=${flowId}`
    //                 }
    //                 // console.log('err', 'Not found error:', props)
    //                 props.history.replace(location)
    //                 return Promise.reject(err)
    //             }
    //             return Promise.reject(err)
    //         })
    // }, [props.match.params.id])

    // useEffect(() => {
    //     // console.log('current', flowId)
    //     if (promiseRef.current != null) {
    //         const setStateDispatch = setState as React.Dispatch<any>
    //         setStateDispatch(promiseRef.current ? promiseRef.current : null)
    //     }
    // }, [promiseRef.current])

    const retry = () => {
        // console.log('COG1-7574 ::: refecth called')
        queryClient.invalidateQueries(`workflow-${flowId}`)
    }

    // const customJsonApifn = () => {
    //     console.log('COG1-7574 ::: custom APi fn')
    //     wrapperGetJson(automationAPIURLWithWorkspace(workspaceName, `${AutomationApi.GetDetail}/${flowId}`))
    //         .then((response: any) => response.data)
    //         .catch(err => {
    //             // console.log('custom Json function', err)
    //             return err
    //         })
    // }

    // console.log('COG1-7199 :: ', cachedData)

    return <FlowCanvas state={cachedData} retry={retry} refetchWorkflowData={retry} {...props} scope={process.env["REACT_APP_PRODUCT_ID"]} />
}
