import Downshift from 'downshift'
/*eslint-disable*/
import React, { useContext, useState } from 'react'

import { Modal } from '@/common/components/Modal'

import { deleteDailouge, findCaption, findSubtitle, updateDailouge } from '../Actions/AsyncOperations'
import { CollectionT, Environment, InformationT } from '../Actions/EnvironmentBlock'
import { PageContext } from '../Actions/ReactContext'
import { OPERATIONS, PageView } from '../Types/PageT'
import { DialogT } from '../Types/WatsonT'
import { Automation } from './Nodes/Automation'
import { ContextChooser } from './Nodes/ContextChooser'
import { Exit } from './Nodes/Exit'
import { Jump } from './Nodes/Jump'
import { Question } from './Nodes/Question'
import { RecogniseDialog } from './Nodes/RecogniseDialog'
import { RespondWithOptions } from './Nodes/RespondWithOptions'
import { SlotContainer } from './Nodes/SlotContainer'
import { TextResponse } from './Nodes/TextResponse'

const Ramda = require('ramda')

export const DialogNodes = ({ isPublish, isdeployed, deploybot, publishBot }: any) => (
    <PageContext.Consumer>
        {({ PageState, workspace, setPageState, intents, entities, fetchCallback }) => {
            switch (PageState.operation) {
                case OPERATIONS.RecognizeDialogPT:
                    return (
                        <NodesWithContext>
                            <RecogniseDialog />
                        </NodesWithContext>
                    )
                case OPERATIONS.TextResponseDialogPT:
                    return (
                        <NodesWithContext>
                            <TextResponse />
                        </NodesWithContext>
                    )
                case OPERATIONS.OptionsResponseDialogPT:
                    return (
                        <NodesWithContext>
                            <RespondWithOptions />
                        </NodesWithContext>
                    )
                case OPERATIONS.AskQueryDialogPT:
                    return (
                        <NodesWithContext>
                            <Question />
                        </NodesWithContext>
                    )
                case OPERATIONS.JumpDialogPT:
                    return <Jump />
                case OPERATIONS.SlotContainerDialogPT:
                    return (
                        <NodesWithContext>
                            <SlotContainer
                                workspacename={workspace}
                                element={PageState.input}
                                previousSibling={PageState.previousSibling}
                                nextSibling={PageState.nextSibling}
                                parent={PageState.parent}
                                ResetView={() => setPageState(PageView)}
                                AfterSave={fetchCallback}
                                intents={intents}
                                entities={entities}
                            />
                        </NodesWithContext>
                    )
                case OPERATIONS.AutomationDialogPT:
                    return (
                        <NodesWithContext>
                            <Automation />
                        </NodesWithContext>
                    )
                case OPERATIONS.ExitDialogPT:
                    return (
                        <NodesWithContext>
                            <Exit />
                        </NodesWithContext>
                    )
                case OPERATIONS.AssistantView:
                    return null
                // <TryItLegacy
                //     workspacename={workspace}
                //     close={() => setPageState(PageView)}
                //     intents={intents}
                //     assistantid={''}
                //     deployed={isdeployed.success}
                //     deploymentError={isdeployed.error}
                //     deploybot={deploybot}
                // />
                case OPERATIONS.DeleteView:
                    return <DeleteDialog dialog={PageState.input} />
                default:
                    return null
            }
        }}
    </PageContext.Consumer>
)

const NodesWithContext = (props: any) => {
    const [exposed, assignExposed] = useState<boolean>(false)
    const [collection, assignCollection] = useState<CollectionT | null>(null)
    const [information, assignInformation] = useState<InformationT | null>(null)
    const [selected, setSelected] = useState<number | null>(null)

    return (
        <Environment.Provider
            value={{
                selected,
                setSelected,
                exposed,
                collection,
                information,
                assignExposed,
                assignCollection,
                assignInformation
            }}
        >
            <div className="flow_popup2">
                <div data-ml-modal="">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            {props.children}

                            {exposed ? (
                                <Downshift isOpen={exposed} onOuterClick={() => assignExposed(false)}>
                                    {() => (
                                        <section className="dialog_context_popup_height">
                                            <ContextChooser />
                                        </section>
                                    )}
                                </Downshift>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </Environment.Provider>
    )
}

interface DeleteDialogP {
    dialog: DialogT
}

const DeleteDialog = ({ dialog }: DeleteDialogP) => {
    const [loading, setLoading] = useState<boolean>(false)
    const context = useContext(PageContext)

    const onDelete = async () => {
        const { setHistory, DataMixture, workspace, fetchCallback } = context
        setLoading(true)

        let equivalent = DataMixture.reduce((accumulator, value) => {
            accumulator[value.dialog_node] = value
            return accumulator
        }, {})

        const previousSibling = dialog.previous_sibling ? equivalent[dialog.previous_sibling] : null
        const parent = dialog.parent ? equivalent[dialog.parent] : null
        const withoutJump = DataMixture.filter(e => e.previous_sibling == dialog.dialog_node).pop()

        if (dialog.type != 'if' && dialog.type != 'action') {
            const nextSibling = dialog.jump_to ? equivalent[dialog.jump_to.dialog_node] : null
            if (previousSibling && nextSibling) {
                await updateDailouge(workspace, {
                    ...previousSibling,
                    jump_to: {
                        dialog_node: nextSibling.dialog_node,
                        selector: nextSibling.type == 'if' ? 'user_input' : 'body'
                    },
                    metadata: { ...previousSibling.metadata, hide_jump_to: true }
                })
            } else if (!previousSibling && parent && nextSibling && nextSibling.type != 'if') {
                await updateDailouge(workspace, {
                    ...parent,
                    jump_to: {
                        dialog_node: nextSibling.dialog_node,
                        selector: 'body'
                    },
                    metadata: { ...parent.metadata, hide_jump_to: true }
                })
            }
        } else if (
            dialog.type == 'if' &&
            previousSibling &&
            previousSibling.type != 'if' &&
            previousSibling.jump_to &&
            previousSibling.jump_to.dialog_node == dialog.dialog_node &&
            withoutJump &&
            withoutJump.type == 'if'
        ) {
            await updateDailouge(workspace, {
                ...previousSibling,
                jump_to: {
                    dialog_node: withoutJump.dialog_node,
                    selector: 'user_input'
                },
                metadata: { ...previousSibling.metadata, hide_jump_to: true }
            })
        }

        await deleteDailouge(workspace, dialog)
        if (dialog.parent == null) {
            setHistory(`/workspace/${workspace}/dialog/home`)
        } else {
            fetchCallback(() => setLoading(false))
        }
    }

    return (
        <PageContext.Consumer>
            {({ setPageState }) => (
                <ArriveModal
                    caption={findCaption(dialog)}
                    subtitle={findSubtitle(dialog)}
                    onCancel={() => setPageState(PageView)}
                    loading={loading}
                    onClick={() => onDelete()}
                />
            )}
        </PageContext.Consumer>
    )
}

function ArriveModal(props: {
    onCancel: Function
    loading: boolean
    caption: string
    subtitle: string
    onClick: Function
}) {
    const { onCancel, loading, caption, subtitle, onClick } = props
    return (
        <Modal close={onCancel} showPopupLoader={loading}>
            <React.Fragment>
                <h2>{caption}</h2>
                <p>{subtitle}</p>
            </React.Fragment>

            <form>
                <button className="btn btn-primary" type="button" onClick={() => onCancel()}>
                    No
                </button>
                <button className="btn btn-success" type="button" onClick={() => onClick()}>
                    Yes
                </button>
            </form>
        </Modal>
    )
}
