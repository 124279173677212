/*eslint-disable*/
import React, { useState } from 'react'
import styled from 'styled-components'

import { DeleteIcon, EditIcon, WidgetIcon } from '@/common/Icons/Icons'
import { List } from '@/common/components/List';
import { formatAMPM, formatDateInCard } from '@/common/utils/utils'
import { workspace } from './Widget'
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@/common/styled/Workflow.Analytics.Dumb';
import { variableConditionT } from '@/Assistant/DialogueCrew/Nodes/RecogniseDialog/kind';
import { usePermissions } from '@/common/utils/auth-token-api-utils';


const TabelWrapper = styled.div`
  width: 100%;
  float: left;
  .list_flow_hr label {
    float: left;
    display: flex;
    position: absolute;
    top: 18px;
    width: 16px;
    height: 16px;
    margin-bottom: 0px;
    left: -22px;
  }
  .option-input.radio {
    top: -2px;
    position: absolute;
  }

  ${Table} {
    margin-top: 0px;
  }
  ${Thead} {
    ${Tr} {
      padding-left: 32px;
      padding-right: 20px;

      ${Th} {
        :last-child {
          justify-content: flex-end;
        }
      }
    }
  }
  ${Tbody} {
    border-radius: 0px 0px 10px 10px;
    max-height: calc(100vh - 84px);
    height: auto;
    ${Tr} {
      height: 48px;
      padding-left: 32px;
      padding-right: 20px;
      .list_flow_span .entity_span {
        :first-child {
          margin-right: 3px;
          color: #000;
          font-size: 11px;
        }
        :last-child {
          margin-left: 3px;
          color: #000;
          font-size: 11px;
        }
      }
      :hover {
        ${Td}:last-child {
          display: flex;
        }
      }

      ${Td} {
        padding: 0px;
        height: 48px;
        font-size: 12px;
        font-family: 'Gordita-Regular';
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        color: #000;
        span {
          margin-right: 0px;
        }

        .list_flow_span_example span {
          font-size: 12px;
          font-family: 'Gordita-Regular';
          color: #000;

          span {
            color: #f07f31;
          }
        }
        :nth-child(1) {
          display: flex;
          padding-right: 20px;
          cursor: pointer;
          position: relative;
        }

        :nth-child(2) {
          display: flex;
          padding-right: 20px;
        }
        :last-child {
          display: none;
        }
      }
    }
  }
`;

export const WidgetTable = (props: {
    list: workspace[]
    setToEditMode: (details: workspace) => void
    setToAddMode: () => void
    setToDeleteMode: (details: workspace) => void
    setvaluefunction:(data:string)=>void
    value:string
    workspacename:string
}) => {
    const setEditMode =(data:workspace)=>{
      props.setvaluefunction(data.id);
      props.setToEditMode(data)
    }
    // const sortList = props.list.sort((a, b)=> {
    //         return  new Date(a.updated_at) - new Date(b.updated_at)
    // });
    console.log(props.value,'listSorted')
    return (  
        <section className="slack_buton">
            <div className="col-md-12 col-lg-12 col-sm-12 col-12 slack_right_btn">
              <div className="slack_buton_box slack_inter_box">
                  <TabelWrapper>
                            <TableContainer>
                              <Table>
                                <Thead>
                                  <Tr>
                                    <Th size="3">Name</Th>
                                    <Th size="3">Description</Th>
                                    <Th size="2.5">Security Enabled</Th>
                                    <Th size="2.5">Date</Th>
                                    <Th size="1">Options</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {props.list.map(
                                    (data: workspace, i: number) => {
                                      console.log(data,'widgetinfo')
                                      return (
                                        <ReturnList data={data} i={i} setEditMode={setEditMode} setToDeleteMode={props.setToDeleteMode} value={props.value} workspacename={props.workspacename} key={i}/>
                                      );
                                    }
                                  )}
                                </Tbody>
                              </Table>
                            </TableContainer>
                  </TabelWrapper>
              </div>
            </div>
        </section>
    )
}

function ReturnList(props:{data:workspace,setEditMode:(params:any)=>void,i:number,workspacename:string,setToDeleteMode:(params:any)=>void,value:any}){
  const {data,setEditMode,i,workspacename,setToDeleteMode} = props
  const writePermission = usePermissions(workspacename,`CHAT_CHANNEL_MODULE/CHANNEL/WIDGET/INTEGRATION/${data.id}`,"WRITE")
  const deletePermission = usePermissions(workspacename,`CHAT_CHANNEL_MODULE/CHANNEL/WIDGET/INTEGRATION/${data.id}`,"DELETE")
  return(
    <Tr
    className = {props.value === data.id ? "is_active" :null}
    onClick={() =>
      writePermission.value && setEditMode(data)
    }
  >
    <List
      checkBoxShow={false}
      showCheckBox={false}
      key={i}
      render={(show: boolean, ToolBox: any) => {
        return (
          <>
            <Td
              size="3"
              className={'list_flow_hr'}
            >
                <p className="listite_centre">
                {data.name}
              </p>
              {/* <div
                className="search_card_list"
                onClick={() =>
                  setEditMode(data)
                }
              >
              </div> */}
            </Td>
            <Td
              size="3"
              className="list_flow_p"
            >
              <p className="listite_centre">
                {data.description}
              </p>
            </Td>
            <Td
              size="2.5"
              className="list_flow_p"
            >
              <p className="listite_centre">
                {data.is_secured
                  ? 'Yes'
                  : 'No'}
              </p>
            </Td>
            <Td
              size="2.5"
              className="list_flow_span"
            >
              <span className="entity_span">
                {formatDateInCard(
                  data.updated_at.includes(
                    'Z'
                  )
                    ? data.updated_at
                    : data.updated_at + 'Z'
                )}
              </span>
              <span>|</span>
              <span className="entity_span">
                {formatAMPM(
                  data.updated_at.includes(
                    'Z'
                  )
                    ? data.updated_at
                    : data.updated_at + 'Z'
                )}
              </span>
            </Td>
            <Td size="1">
                  <ToolBox>
                    {writePermission.value && <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditMode(
                          data
                        );
                      }}
                    >
                      <EditIcon />
                    </span>}
                    {deletePermission.value && <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setToDeleteMode(
                          data
                        );
                      }}
                    >
                      <DeleteIcon />
                    </span>}
                  </ToolBox>
            </Td>
          </>
        );
      }}
    />
  </Tr>
  )
}