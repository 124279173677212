/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react'

import {
    BulpIcon,
    DialogIcon,
    ExitIcon,
    FlowIcon,
    JumpIcon,
    OptionIcon,
    QaIcon,
    ResponseIcon,
    SlotsIcon
} from '@/common/Icons/Icons'

import dialogpng from '@/common/images/dialog.png'
import { PageContext } from '../Actions/ReactContext'
import { OPERATIONS } from '../Types/PageT'

enum VIEWS {
    DIALOG,
    SENDMESSAGE,
    QUESTION,
    OPTIONS,
    JUMP,
    SLOT,
    FLOW,
    EXIT
}
export const CanvasSidePanel = ({ dialog }: any) => {
    const wrapperContext = useContext(PageContext)
    const [view, setView] = useState<VIEWS>(VIEWS.DIALOG)
    useEffect(() => {
        if (wrapperContext.PageState.operation == OPERATIONS.TextResponseDialogPT) {
            setView(VIEWS.SENDMESSAGE)
        } else if (wrapperContext.PageState.operation == OPERATIONS.OptionsResponseDialogPT) {
            setView(VIEWS.OPTIONS)
        } else if (wrapperContext.PageState.operation == OPERATIONS.AskQueryDialogPT) {
            setView(VIEWS.QUESTION)
        } else if (wrapperContext.PageState.operation == OPERATIONS.JumpDialogPT) {
            setView(VIEWS.JUMP)
        } else if (wrapperContext.PageState.operation == OPERATIONS.SlotContainerDialogPT) {
            setView(VIEWS.SLOT)
        } else if (wrapperContext.PageState.operation == OPERATIONS.AutomationDialogPT) {
            setView(VIEWS.FLOW)
        } else if (wrapperContext.PageState.operation == OPERATIONS.ExitDialogPT) {
            setView(VIEWS.EXIT)
        } else {
            if (view != VIEWS.DIALOG) {
                setView(VIEWS.DIALOG)
            }
        }
    }, [wrapperContext.PageState])

    return (
        <div className="flex_side_menu">
            <div className="sidepanel_menu_right">
                <div className="sidepanel_menu_right_menu">
                    <div className="sidepanel_menu_right_menu_step">
                        <div className="sidepanel_step_automations_content dialog_canvas_editor_content">
                            <div className="sidepanel_menu_right_head">
                                <h4>Chatbot Builder</h4>
                            </div>

                            {dialog.conditions != '' ? (
                                <></>
                            ) : (
                                <>
                                    <p className="dialog_p_head">
                                        Use this space to compose a dialog for the chatbot to chat with a user.
                                    </p>
                                </>
                            )}
                            <img src={dialogpng} alt="dialog" title="dialog" />

                            <div className="sidepanel_step_canvas">
                                <h6 className="sidepanel_step_canvas_h6">
                                    <span>
                                        <BulpIcon />
                                    </span>
                                    Quick Tips
                                </h6>
                                <div className="sidepanel_step_canvas_svg">
                                    <span
                                        onClick={() => setView(VIEWS.DIALOG)}
                                        className={
                                            view == VIEWS.DIALOG
                                                ? 'sidepanel_step_canvas_svg_span sidepanel_step_canvas_svg_span_selected'
                                                : 'sidepanel_step_canvas_svg_span'
                                        }
                                    >
                                        <DialogIcon />
                                        <span className="side_menu_hvr">Recognize Dialog</span>
                                    </span>

                                    <span
                                        onClick={() => setView(VIEWS.SENDMESSAGE)}
                                        className={
                                            view == VIEWS.SENDMESSAGE
                                                ? 'sidepanel_step_canvas_svg_span sidepanel_step_canvas_svg_span_selected'
                                                : 'sidepanel_step_canvas_svg_span'
                                        }
                                    >
                                        <ResponseIcon />
                                        <span className="side_menu_hvr">Send a Message</span>
                                    </span>

                                    <span
                                        onClick={() => setView(VIEWS.QUESTION)}
                                        className={
                                            view == VIEWS.QUESTION
                                                ? 'sidepanel_step_canvas_svg_span sidepanel_step_canvas_svg_span_selected'
                                                : 'sidepanel_step_canvas_svg_span'
                                        }
                                    >
                                        <QaIcon />
                                        <span className="side_menu_hvr">Ask a question</span>
                                    </span>
                                    <span
                                        onClick={() => setView(VIEWS.OPTIONS)}
                                        className={
                                            view == VIEWS.OPTIONS
                                                ? 'sidepanel_step_canvas_svg_span sidepanel_step_canvas_svg_span_selected'
                                                : 'sidepanel_step_canvas_svg_span'
                                        }
                                    >
                                        <OptionIcon />
                                        <span className="side_menu_hvr">Options</span>
                                    </span>
                                    <span
                                        onClick={() => setView(VIEWS.JUMP)}
                                        className={
                                            view == VIEWS.JUMP
                                                ? 'sidepanel_step_canvas_svg_span sidepanel_step_canvas_svg_span_selected'
                                                : 'sidepanel_step_canvas_svg_span'
                                        }
                                    >
                                        <JumpIcon />
                                        <span className="side_menu_hvr">Jump</span>
                                    </span>
                                    <span
                                        onClick={() => setView(VIEWS.SLOT)}
                                        className={
                                            view == VIEWS.SLOT
                                                ? 'sidepanel_step_canvas_svg_span sidepanel_step_canvas_svg_span_selected'
                                                : 'sidepanel_step_canvas_svg_span'
                                        }
                                    >
                                        <SlotsIcon />
                                        <span className="side_menu_hvr">Verify User Details</span>
                                    </span>
                                    <span
                                        onClick={() => setView(VIEWS.FLOW)}
                                        className={
                                            view == VIEWS.FLOW
                                                ? 'sidepanel_step_canvas_svg_span sidepanel_step_canvas_svg_span_selected'
                                                : 'sidepanel_step_canvas_svg_span'
                                        }
                                    >
                                        <FlowIcon />
                                        <span className="side_menu_hvr">Automation</span>
                                    </span>
                                    <span
                                        onClick={() => setView(VIEWS.EXIT)}
                                        className={
                                            view == VIEWS.EXIT
                                                ? 'sidepanel_step_canvas_svg_span sidepanel_step_canvas_svg_span_selected'
                                                : 'sidepanel_step_canvas_svg_span'
                                        }
                                    >
                                        <ExitIcon />
                                        <span className="side_menu_hvr">Exit</span>
                                    </span>
                                </div>
                                {view == VIEWS.SENDMESSAGE && <SendMessageTips />}
                                {view == VIEWS.OPTIONS && <OptionsMessageTips />}
                                {view == VIEWS.QUESTION && <QuestionTips />}
                                {view == VIEWS.JUMP && <JumpTips />}
                                {view == VIEWS.SLOT && <SlotTips />}
                                {view == VIEWS.FLOW && <FlowTips />}
                                {view == VIEWS.EXIT && <ExitTips />}
                                {view == VIEWS.DIALOG && <DilogTips />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const SendMessageTips = () => (
    <div className="sidepanel_canvas_svg_content">
        <h4>Send a Message</h4>
        <p>
            <a
                className="sidepanel_href_color"
                target="_blank"
                href="https://help.workativ.com/index.php/knowledgebase/send-message/"
            >
                Send a Message
            </a>{' '}
            makes the chatbot respond to a user's input or query with a text message.
        </p>
        <p>
            You can specify multiple messages on the Send a Message pop up. The chatbot will then display each message
            one at a time for similar user input. This is to break the monotony.
        </p>
        <p>
            You can make the chatbot to either display the messages <b>sequentially</b> or <b>randomly</b>.
        </p>
    </div>
)
const OptionsMessageTips = () => (
    <div className="sidepanel_canvas_svg_content">
        <h4>Options</h4>
        <p>
            Use{' '}
            <a
                className="sidepanel_href_color"
                target="_blank"
                href="https://help.workativ.com/index.php/knowledgebase/options/"
            >
                Options
            </a>{' '}
            when you want the chatbot to display clickable options to the user.
        </p>
        <p>When a user selects an option, the value for that option gets passed to the Assistant. </p>
        <p>
            Based on the condition that this <b>value</b> satisfies, the corresponding dialog gets triggered.
        </p>
        <p>
            For example,
            <br />
            <b>Assistant:</b> Do you want to continue with the assistance?
            <br /> Option: <b>“Yes”</b> Value: <b>Welcome</b>
            <br />
            Option: <b>“No”</b> Value: <b>Exit</b>
        </p>
        <p>
            The <b>Yes</b> and <b>No</b> dialogs are created once you click on the <b>Save</b> button.
        </p>
        <p>
            You can then add the <b>intent</b> that matches the option's value as a condition to the respective dialog.
        </p>
    </div>
)
const QuestionTips = () => (
    <div className="sidepanel_canvas_svg_content">
        <h4>Ask a Question</h4>
        <p>
            <a
                target="_blank"
                className="sidepanel_href_color"
                href="https://help.workativ.com/index.php/knowledgebase/ask-a-question/"
            >
                Ask a Question
            </a>{' '}
            makes the chatbot prompt the user with a question and store the user's response in a <b>context variable</b>
            .{' '}
        </p>
        <p>
            You can select between a <b>simple</b> question and an <b>options-based</b> question.
        </p>
        <p>
            For example,
            <br />
            <b>Assistant:</b> Please provide your mobile number.
            <br />
            <b>User:</b> My mobile number is 1234567890.
        </p>
        <p>
            The chatbot will then store, <b>"My mobile number is 1234567890"</b> in a context variable.
        </p>{' '}
        <p>
            <b>Tip:</b> Use <b> Verify user details </b>
            function if you want to validate user answer before saving.
        </p>
    </div>
)
const JumpTips = () => (
    <div className="sidepanel_canvas_svg_content">
        <h4>Jump</h4>
        <p>
            Use{' '}
            <a
                className="sidepanel_href_color"
                target="_blank"
                href="https://help.workativ.com/index.php/knowledgebase/jump/"
            >
                Jump
            </a>{' '}
            when you want the chatbot to jump to a specific dialog. It has the following options:
        </p>
        <p>
            <b>Wait for user input—</b>Jumps to a dialog and waits for the user to input again. Based on that input, the
            specified dialog or any one of its successors get executed by the Assistant.{' '}
        </p>
        <p>
            <b>If bot recognizes—</b>Jumps to a dialog and executes it only if it recognizes that dialog from the user's
            last input. If it doesn't, then it checks successive dialogs' conditions against the user's input.{' '}
        </p>
        <p>
            <b>Execute Dialog—</b> Jumps to a dialog and executes its conversation flow. This doesn't depend on the
            user's input.
        </p>
    </div>
)
const SlotTips = () => (
    <div className="sidepanel_canvas_svg_content">
        <h4>Verify User Details</h4>
        <p>
            <a
                className="sidepanel_href_color"
                target="_blank"
                href="https://help.workativ.com/index.php/knowledgebase/get-details/"
            >
                Verify user details
            </a>{' '}
            makes the chatbot extract and store only the required data from a user's input.{' '}
        </p>
        <p>
            Use an appropriate <b>entity</b> to extract the required data after validation.
        </p>
        <p>
            For example, <br />
            <b>Assistant:</b> Please provide your mobile number. <br />
            <b>User:</b> My mobile number is 1234567890.
        </p>
        <p>
            The chatbot will store only <b>1234567890</b> after validating it. <br />
            If there's no match, the chatbot will continue to prompt the user for valid input.
        </p>
    </div>
)
const FlowTips = () => (
    <div className="sidepanel_canvas_svg_content">
        <h4>Call an Automation</h4>
        <p>
            Use{' '}
            <a
                target="_blank"
                className="sidepanel_href_color"
                href="https://help.workativ.com/index.php/knowledgebase/call-an-automation/"
            >
                <b>Call an Automation</b>
            </a>{' '}
            function when you want the chatbot to call and execute a workflow automation within a dialog.
        </p>
        <p>
            You'll have to <b>map the context variables</b> as <b>input values</b> on the Automation pop up.
        </p>
        <p> This enables the Workflow Builder to get the user's input to execute the workflow. </p>
        <p>
            The chatbot will create <b>Success</b> and <b>Failure</b> dialogs automatically automatically after adding
            an automation.
        </p>
        <p>You can then create a conversation flow for those dialogs.</p>
    </div>
)
const ExitTips = () => (
    <div className="sidepanel_canvas_svg_content">
        <h4>Exit</h4>
        <p>
            <a
                className="sidepanel_href_color"
                target="_blank"
                href="https://help.workativ.com/index.php/knowledgebase/exit/"
            >
                Exit
            </a>{' '}
            makes the chatbot to display a message and exit from a conversation with a user.
        </p>
        <p>
            You can also make the chatbot get the user's feedback with <b>Get feedback from the user option </b> on the{' '}
            <b>Exit</b> pop-up.
        </p>
        <p>You can configure the feedback options on the Assistant's settings page.</p>
    </div>
)
const DilogTips = () => (
    <div className="sidepanel_canvas_svg_content">
        <h4>Recognize Dialog</h4>
        <p>
            <a
                className="sidepanel_href_color"
                target="_blank"
                href="https://help.workativ.com/index.php/knowledgebase/recognize-dialog/"
            >
                Recognize Dialog
            </a>{' '}
            helps the chatbot recognize user utterance or intent to start a conversation with a user.
        </p>
        <p>
            Let's say you have 10 dialogs. You can specify conditions on how each dialog is executed using the Recognize
            Dialog pop up. Based on the user's intent, the chatbot can then initiate an appropriate dialog that can
            solve the user’s query.
        </p>
        <p> The chatbot can then check and choose the dialog that the user's input satisfies.</p>
    </div>
)
