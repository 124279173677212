export interface NewT {
    entity: string
    description: string
    fuzzy_match: boolean
    values: ExistvalueT[]
    metadata?: { pattern: string[]; displayTitle: string }
}
export interface NewvalueT {
    value: string
    type: valueTypeT
    synonyms?: string[]
    patterns?: string[]
}
export enum valueTypeT {
    patterns = 'patterns',
    synonyms = 'synonyms'
}
export interface ExistvalueT extends NewvalueT {
    created: string
    updated: string
}
export interface ExistT extends NewT {
    created: string
    updated: string
}
export interface Pagination {
    refresh_url: string
}
export interface ResponseT {
    entities: ExistT[]
    pagination: Pagination
}

export enum ViewTypeT {
    GridView,
    ListView
}

export type Views = GridT | ListT

export type GridT = {
    View: ViewTypeT.GridView
}

export type ListT = {
    View: ViewTypeT.ListView
}

export const GridView: GridT = { View: ViewTypeT.GridView }

export const ListView: ListT = { View: ViewTypeT.ListView }

export type PageState = ViewT | AddT | EditT | SelectT | DeleteT | LoadingT | NoDataFoundT

export type ViewT = {
    action: Action.View
}
export type SelectT = {
    action: Action.Select
    selectedEntity: string[]
}
export type DeleteT = {
    action: Action.Delete
    selectedEntity: string[]
    name: string
}
export type AddT = {
    action: Action.Add
    entity: string
    description: string
}
export type EditT = {
    action: Action.Edit
    init: string
    entity: string
    description: string
}

export type LoadingT = {
    action: Action.Loading
}

export enum Action {
    View,
    Add,
    Edit,
    Select,
    Delete,
    DetailView,
    DetailAdd,
    DetailEdit,
    DetailSelect,
    DetailDelete,
    Loading,
    NoDataFound
}

export const Select: SelectT = { action: Action.Select, selectedEntity: [] }
export const Delete: DeleteT = {
    action: Action.Delete,
    selectedEntity: [],
    name: ''
}
export const View: ViewT = { action: Action.View }
export const Add: AddT = {
    action: Action.Add,
    entity: '',
    description: ''
}
export const Edit: EditT = {
    action: Action.Edit,
    init: '',
    entity: '',
    description: ''
}

export type DetailPageState = DetailViewT | DetailAddT | DetailEditT | DetailSelectT | DetailDeleteT | IsDetailT

export type IsDetailT = {
    action: boolean
}

export const IsDetail: IsDetailT = { action: false }

export type DetailViewT = {
    action: Action.DetailView
}

export const DetailView: DetailViewT = { action: Action.DetailView }

export type DetailSelectT = {
    action: Action.DetailSelect
    entity: string
    selectedValues: string[]
}

export const DetailSelect: DetailSelectT = {
    action: Action.DetailSelect,
    entity: '',
    selectedValues: []
}

export type DetailDeleteT = {
    action: Action.DetailDelete
    name: string
    selectedValues: string[]
}

export const DetailDelete: DetailDeleteT = {
    action: Action.DetailDelete,
    name: '',
    selectedValues: []
}

export type DetailAddT = {
    action: Action.DetailAdd
    entity: string
    value: NewvalueT
}

export const DetailAdd: DetailAddT = {
    action: Action.DetailAdd,
    entity: '',
    value: {
        value: '',
        type: valueTypeT.synonyms,
        synonyms: []
    }
}

export type DetailEditT = ExistvalueT & {
    action: Action.DetailEdit
    entity: string
    key: string
}

export const DetailEdit: DetailEditT = {
    action: Action.DetailEdit,
    value: '',
    type: valueTypeT.synonyms,
    synonyms: [],
    created: '',
    updated: '',
    entity: '',
    key: ''
}

export const Loading: LoadingT = {
    action: Action.Loading
}

export const NoDataFound: NoDataFoundT = {
    action: Action.NoDataFound,
    showAddPopup: false,
    entity: '',
    description: ''
}

export type NoDataFoundT = {
    action: Action.NoDataFound
    showAddPopup: boolean
    entity: string
    description: string
}

export const MOCK_ENTITY = {
    created: '',
    updated: '',
    values: [],
    entity: '',
    description: '',
    fuzzy_match: false
}
