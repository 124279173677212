/*eslint-disable*/
import React, { useEffect, useRef, useState } from 'react'

import { useJourney } from '@/Journey/JourneyContext'
import lazyGA from '@/common/google-analytics/tracking'

import { logEvent, stripeGetApi } from '@/common/utils/api-utils'
import { AssistantT, DumpAssistant } from '../Construction/kind'
import { sortBySibling } from '../DialogueCrew/action'
import { EntityT } from '../Entity/kind'
import { IntentT } from '../Intent/kind'
//ACTION IMPORTS
import {
    createIfDialogue,
    createIfDialogueWithInitial,
    deleteIfDialogue,
    deployAssistant,
    getAssistants,
    getDialogueRootDescendants,
    getDialogueRoots,
    getEntities,
    getIntents,
    moveIfDialogue,
    multipleDeleteIfDialogue,
    publishAssistant,
    updateIfDialogue
} from '../Mechanisms/action'
//TYPE IMPORTS
import { ActionT, ROUTER_PROPS_T } from '../kind'
//COMPONENT IMPORTS
import Dialogue from './Dialogue'
import { DefaultDialogue, DialogueT, LOADING, PageStateT, VIEW } from './kind'

var qs = require('qs')

// import { delay } from ''

type ShowWorkflow = {
    show: 'workflow' | 'dialog' | undefined
}

const AssistantDialogue = (props: ROUTER_PROPS_T) => {
    // console.log('AssistantDialogue', props)
    const params: ShowWorkflow = qs.parse(props.location.search.substring(1))

    const { workspacename, assistantname } = props.match.params
    const journeyData = useJourney()

    const [PageState, setPageState] = useState<PageStateT>(LOADING)

    const [assistantID, setAssistantID] = useState<string>('')

    const [assistant, setAssistant] = useState<AssistantT>(DumpAssistant)

    const [rootNodes, setRootNodes] = useState<DialogueT[]>([])

    const [welcomeNode, setWelcomeNode] = useState<DialogueT>(DefaultDialogue)

    const [fallbackNode, setFallbackNode] = useState<DialogueT>(DefaultDialogue)

    const [defaultNodes, setDefaultNodes] = useState<DialogueT[]>([])

    const [intents, setIntents] = useState<IntentT[]>([])

    const [entities, setEntities] = useState<EntityT[]>([])
    const [canpublish, setCanpublish] = useState({ resourceLimit: 0, limit: false })

    const shouldBeDeployed = useRef<'yes' | 'no'>('yes')

    const [dialogues, setDialogues] = useState<DialogueT[][]>([])
    const [isdeployed, setIsDeployed] = useState({ error: false, success: false, deploying: false })
    function returnResourceLimit() {
        return stripeGetApi(`${props.match.params.workspacename}/resource/BOT/limit`)
            .then(res => {
                const { resourceLimit } = res.output
                return resourceLimit
            })
            .catch(err => 0)
    }
    useEffect(() => {
        fetch(() => {})
    }, [])
    const DeployBot = (statusCallback?: Function) => {
        // console.log('calling DeployBot', dialogues)
        let deployed = assistant.deployed !== null ? assistant.deployed.split('Z')[0] : ''
        if ((assistant.updated.split('Z')[0] === deployed) && shouldBeDeployed.current == 'no') {
            // console.log('already deployed', assistant)
            setIsDeployed({ error: false, success: true, deploying: false })
        } else {
            // console.log('deploying')
            setIsDeployed({ error: false, success: false, deploying: true })
            deployAssistant(workspacename, assistant.id)
                .then(res => {
                    // console.log('deployed')
                    shouldBeDeployed.current = 'no'
                    setIsDeployed({ error: false, success: true, deploying: false })
                    if (statusCallback) {
                        statusCallback()
                    }
                })
                .catch(err => {
                    setIsDeployed({ error: true, success: false, deploying: false })
                })
        }
    }
    const moveDialog = (id: string, data: any, cb: Function) => {
        setPageState(LOADING)
        moveIfDialogue(workspacename, assistantID, id, data).then(() => {
            fetch(cb)
        })
    }
    const fetch = (cb: Function) => {
        getAssistants(workspacename)
            .then(async res => {
                const assistant:AssistantT = res.reduce((accum: any, val: any) => {
                    if (val.name.toLowerCase() == assistantname.toLowerCase()) return val
                    else return accum
                }, '')

                if (assistant.id === '') {
                    setPageState({
                        action: ActionT.RESOURCE_NOT_FOUND,
                        resource: 'ASSISTANT'
                    })
                } else {
                    setAssistantID(assistant.id)
                    const assistantData = res.reduce((accum: AssistantT, val: AssistantT): AssistantT => {
                        if (val.name.toLowerCase() == assistantname.toLowerCase()) return val
                        else return accum
                    }, DumpAssistant)
                    setAssistant(assistantData)
                    const publishedbots =
                        res && res.length !== 0 ? res.filter((bot: any) => bot.published_at !== null).length : 0
                    const resourceLimit = await returnResourceLimit()
                    // console.log('Fetching is deployed to true', assistantData)
                    if (assistantData.deployed !== null && assistantData.deployed !== undefined) {
                        console.log('Seeting is deployed to true', assistant)
                        setIsDeployed({ error: false, success: true, deploying: false })
                    }

                    setCanpublish({ resourceLimit: resourceLimit, limit: resourceLimit > publishedbots })

                    getIntents(workspacename, assistant.id).then(data => {
                        setIntents(data)
                    })

                    getEntities(workspacename, assistant.id).then(data => {
                        setEntities(data)
                    })
                    getDialogueRoots(workspacename, assistant.id).then(async data => {
                        const crateNew = new URLSearchParams(window.location.search).get('create-dialog')
                        const crateFAQ = new URLSearchParams(window.location.search).get('create-faq')
                        if (data.length == 0 && !(crateNew || crateFAQ)) {
                            setPageState({
                                action: ActionT.NO_DATA_FOUND,
                                loading: false,
                                showPopup: false,
                                title: '',
                                description: '',
                                show: params.show === 'workflow' ? 'workflow' : 'dialog-only'
                            })
                            cb && cb()
                        } else {
                            setRootNodes(data)
                            const rootDes: DialogueT[] = data.map((d: DialogueT) =>
                                getDialogueRootDescendants(workspacename, assistant.id, d.uuid)
                            )

                            await Promise.all(rootDes)
                                .then(res => {
                                    setDialogues(res as any)
                                })
                                .catch(err => {})

                            setDefaultNodes(
                                sortBySibling(data).filter((node: DialogueT) => {
                                    if (
                                        node.uuid === assistant.welcome_node || (node.conditions &&
                                        (node.conditions.type === 'FallBackExpression'))
                                    )
                                        return false
                                    return true
                                })
                            )

                            setFallbackNode(
                                data.filter((node: DialogueT) => {
                                    if (node.conditions && node.conditions.type === 'FallBackExpression') return true
                                    return false
                                }).length
                                    ? data.filter((node: DialogueT) => {
                                          if (node.conditions && node.conditions.type === 'FallBackExpression')
                                              return true
                                          return false
                                      })[0]
                                    : DefaultDialogue
                            )

                            setWelcomeNode(
                                data.filter((node: DialogueT) => {
                                    if (node.uuid===assistant.welcome_node) return true
                                    return false
                                }).length
                                    ? data.filter((node: DialogueT) => {
                                          if (node.uuid===assistant.welcome_node)
                                              return true
                                          return false
                                      })[0]
                                    : DefaultDialogue
                            )

                            cb && cb()

                            if (crateNew) {
                                setPageState({ action: ActionT.ADD, loading: false, title: '', description: '' })
                            } else if (crateFAQ) {
                                setPageState({ action: ActionT.CREATE_FAQ, loading: false })
                            } else {
                                const queryString = window.location.search;
                                const urlParams = new URLSearchParams(queryString);
                                const open_faq = urlParams.get('open_faq')
                                const faqExists = (sortBySibling(data) as DialogueT[]).filter((d)=>d.uuid==open_faq)
                                if(open_faq && faqExists.length>0){
                                    setPageState({ action: ActionT.UPDATE_FAQ, value:faqExists[0], loading: false })
                                }else{
                                    setPageState(VIEW)
                                }
            
                            }
                        }
                    })
                }
            })
            .catch((error: any) => {
                if (error.response && error.response.status == 403) {
                    setPageState({
                        action: ActionT.UN_AUTHORIZED
                    })
                } else {
                    setPageState({
                        action: ActionT.ERROR,
                        reason: 'There was a problem in loading Page! <br/> Please click on "Retry"'
                    })
                }
                cb && cb()
            })
    }
    // console.log(
    //     'journeyData :: dialogs',
    //     journeyData.type,
    //     journeyData.type == 'success' ? journeyData.value.state() : 'not succeess'
    // )

    const createNewDialog = async (cb: Function) => {
        try {
            setPageState(state => ({ ...state, loading: true }))

            if (PageState.action == ActionT.ADD && rootNodes.length > 0) {
                const arr = sortBySibling(rootNodes)
                const res = await createIfDialogue(
                    workspacename,
                    assistantID,
                    {
                        type: 'if_node',
                        title: PageState.title,
                        description: PageState.description,
                        conditions: null,
                        responses: [],
                        parent: null,
                        previous_sibling: arr[arr.length - 2].uuid,
                        next_step: 'wait_for_user_input',
                        jump_to: null
                    },
                    PageState.title
                )

                logEvent(workspacename, {
                    event:"EVENT::DIALOG::CREATE_FROM_HOMEPAGE", event_meta:{
                    name: PageState.title,
                    description: PageState.description
                }})

                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Dialogue Node Created'
                })

                if (journeyData.type === 'success') {
                    const interopretor = journeyData.value
                    interopretor.send({
                        type: 'EVENT::DIALOG::CREATED',
                        tag: 'GENERIC',
                        journeyType: 'Dialog',
                        refId: assistantID
                    })
                }

                window.history.replaceState(null, '', window.location.pathname)

                props.history.push(`/workspace/${workspacename}/dialog/${assistantname}/detail/${res.id}`)
            } else if (PageState.action == ActionT.ADD && rootNodes.length == 0) {
                const arr = sortBySibling(rootNodes)

                const res = await createIfDialogueWithInitial(workspacename, assistantID, {
                    type: 'if_node',
                    title: PageState.title,
                    description: PageState.description,
                    conditions: null,
                    responses: [],
                    parent: null,
                    previous_sibling: null,
                    next_step: 'wait_for_user_input',
                    jump_to: null
                })
                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Dialogue Node Created'
                })
                if (journeyData.type === 'success') {
                    const interopretor = journeyData.value
                    interopretor.send({
                        type: 'EVENT::DIALOG::CREATED',
                        tag: 'GENERIC',
                        journeyType: 'Dialog',
                        refId: assistantID
                    })
                }
                window.history.replaceState(null, '', window.location.pathname)

                props.history.push(`/workspace/${workspacename}/dialog/${assistantname}/detail/${res.id}`)
            } else if (PageState.action === ActionT.NO_DATA_FOUND) {
                const arr = sortBySibling(rootNodes)

                const res = await createIfDialogueWithInitial(workspacename, assistantID, {
                    type: 'if_node',
                    description: '',
                    title: PageState.title,
                    conditions: null,
                    responses: [],
                    parent: null,
                    previous_sibling: null,
                    next_step: 'wait_for_user_input',
                    jump_to: null
                })

                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Dialogue Node Created'
                })

                if (journeyData.type === 'success') {
                    const interopretor = journeyData.value
                    interopretor.send({
                        type: 'EVENT::DIALOG::CREATED',
                        tag: 'GENERIC',
                        journeyType: 'Dialog',
                        refId: assistantID
                    })
                }
                window.history.replaceState(null, '', window.location.pathname)

                props.history.push(`/workspace/${workspacename}/dialog/${assistantname}/detail/${res.id}`)
            }
        } catch (err) {
            console.log('err', err)
            cb()
            // setPageState({
            //     action: ActionT.ERROR,
            //     reason: 'There was a problem in loading Page! <br/> Please click on "Retry"'
            // })
        }
    }

    const updateDialog = (cb: Function, assistant: Function) => {
        if (PageState.action == ActionT.EDIT) {
            const user: any = defaultNodes.find(node => node.id == PageState.id)

            updateIfDialogue(
                workspacename,
                assistantID,
                PageState.uuid,
                { ...user, title: PageState.title, description: PageState.description },
                PageState.title
            )
                .then(() => {
                    assistant()
                    fetch(() => {})
                })
                .catch(() => {
                    cb()
                })
        }
    }

    const deploy = (cb: Function) => {
        setPageState(state => ({ ...state, loading: true }))
        deployAssistant(workspacename, assistantID)
            .then(data => {
                fetch(() => {})
            })
            .catch(() => {
                cb()
            })
    }

    const publishAssistantHandle = (id: string, success: Function, failure: Function, name:string) => {
        //  console.log('publishAssistantHandle', success)
        setPageState(state => ({ ...state, loading: true }))
        publishAssistant(workspacename, assistantID)
            .then(res => {
                logEvent(workspacename, {
                    event:"EVENT::CHATBOT::PUBLISH_BOT", event_meta:{
                    name: name,
                    description: ""
                 }}).finally(()=>{
                    fetch(success)
                 })
                
                // window.location.reload()
            })
            .catch(err => {
                failure()
            })
    }

    const deleteDialog = (cb: Function, assistant: Function) => {
        switch (PageState.action) {
            case ActionT.MULTIPLE_DELETE:
                multipleDeleteIfDialogue(
                    workspacename,
                    assistantID,
                    PageState.items
                        .reduce((accum: any[], val: string) => {
                            const index = rootNodes.findIndex(e => (e.uuid ? e.uuid : e.id) === val)
                            if (index === -1) return accum
                            return accum.concat([
                                {
                                    id: val,
                                    title: rootNodes[index].title
                                }
                            ])
                        }, [])
                        .map(e => e.id)
                )
                    .then((_: {}) => {
                        assistant()
                        fetch(() => {})
                    })
                    .catch(() => {
                        cb()
                    })
                break
            case ActionT.DELETE:
                deleteIfDialogue(
                    workspacename,
                    assistantID,
                    PageState.data.uuid,
                    rootNodes.filter(e => e.uuid === PageState.data.uuid)[0].title,
                    'removed'
                )
                    .then((_: {}) => {
                        assistant()
                        fetch(() => {})
                    })
                    .catch(() => {
                        cb()
                    })
                break
        }
    }

    const openWorkflow = () => {
        // /workspace/nirup/automation/view/home?showIntialCreationModel=true
        props.history.push(`/workspace/${workspacename}/automation/view/home?showIntialCreationModel=true`)
    }

    const openChatChannel = () => {
        props.history.push(`/workspace/${workspacename}/integration/chat`)
    }

    // console.log('PROPSS for Dialogue :::isdeployed,deployAssistant', isdeployed)

    return (
        <Dialogue
            canpublish={canpublish}
            deploybot={DeployBot}
            isdeployed={isdeployed}
            dialogs={defaultNodes}
            dialogues={dialogues}
            rootNodes={rootNodes}
            assistant={assistant}
            moveDialog={moveDialog}
            intents={intents}
            entities={entities}
            workspacename={workspacename}
            welcomeNode={welcomeNode}
            fallbackNode={fallbackNode}
            retry={fetch}
            createNewDialog={createNewDialog}
            updateDialog={updateDialog}
            deleteDialog={deleteDialog}
            PageState={PageState}
            setPageState={setPageState}
            deployAssistant={deploy}
            publishAssistantHandle={publishAssistantHandle}
            openCanvas={(node: string) =>
                props.history.push(`/workspace/${workspacename}/dialog/${assistantname}/detail/${node}`)
            }
            openChatChannel={openChatChannel}
            openWorkflow={openWorkflow}
            shouldBeDeployed={shouldBeDeployed}
            {...props}
        />
    )
}

export default AssistantDialogue
