import '@draft-js-plugins/emoji/lib/plugin.css'
import '@draft-js-plugins/static-toolbar/lib/plugin.css'

import Downshift from 'downshift'
import { CompositeDecorator, EditorState, Entity, Modifier } from 'draft-js'
/*eslint-disable*/
import React, { useEffect, useRef, useState } from 'react'
import Tooltip from 'react-tooltip-lite'
import { debounce } from 'throttle-debounce'

import { CheckColorIcon, CloseColorIcon, EmojiIcon, FlowCanvasContextIcons, LinkIcon } from '@/common/Icons/Icons'
import { FocusContent } from '@/common/styled/DraftJs.Dumb'
import {
    BoldButton,
    createInlineStyleButton,
    ItalicButton,
    OrderedListButton,
    UnderlineButton,
    UnorderedListButton
} from '@draft-js-plugins/buttons'
import DraftEditor from '@draft-js-plugins/editor'
import createEmojiPlugin from '@draft-js-plugins/emoji'
import createToolbarPlugin, { Separator } from '@draft-js-plugins/static-toolbar'
import { EmojiPopup } from '@/Assistant/Editor/EmojiPopup'
import { Input, InputContainer, InputContent } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { InputWrapper } from '@/DialogEditor/Canvas/Components/WelcomePageWithJourney'

const Conductor = require('draftjs-conductor')
const draftJsUtils = require("draftjs-utils");


export interface VariableT {
    variable: string
    path: string[]
    icon: string
    dataType: string
    id: string
    examples: string
}

export interface EntityRangesT {
    offset: number
    length: number
    key: number
}

export interface DraftJST {
    blocks: BlocksT[]
    entityMap: EntityMapT
}

interface EntityMapT {
    [id: string]: EntityValueT
}

interface EntityValueT {
    type: 'VARIABLE' | "LINK" | "emoji"
    mutability: 'IMMUTABLE'
    data: EntityValueDataT
}

interface EntityValueDataT {
    value: string
    visible: string
    path: string[]
    icon: string
    dataType: 'string'
}

export interface BlocksT {
    key: string
    text: string
    type: 'unstyled'
    depth: 0
    inlineStyleRanges: []
    entityRanges: Array<entityRangesT>
}

interface entityRangesT {
    offset: number
    length: number
    key: number
}

var IsValidURL = (url: string) =>
    url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)

const TokenSpan = (props: any) => {
    const info: any = props.contentState.getEntityMap().get(props.entityKey).data

    return info.emojiUnicode ? (
        <span data-offset-key={props.offsetkey}>{props.children}</span>
    ) : (
        <span className="buton_dialog_auto_hvr" data-offset-key={props.offsetkey}>
            <Tooltip
                className="target customTip _hover_tooltip"
                zIndex={10000}
                arrowSize={0}
                tagName="span"
                content={info.path.map((path: any, index: number, arr: any[]) => (
                    <React.Fragment key={`${index}`}>
                        <div
                            style={
                                index + 1 == info.path.length
                                    ? {
                                          whiteSpace: 'initial',
                                          width: 'max-content'
                                      }
                                    : {}
                            }
                        >
                            {path}
                        </div>
                        {index + 1 != arr.length ? (
                            <div>
                                <FlowCanvasContextIcons.NextIcon />
                            </div>
                        ) : null}
                    </React.Fragment>
                ))}
                distance={5}
            >
                <span className="context_variable_button">
                    {/* <img src={info.icon} /> */}
                    {info.icon && <span className='button_variable_icon'><img src={info.icon} /></span>}
                    {props.children}
                </span>
            </Tooltip>
        </span>
    )
}

const getEntityStrategy = (contentBlock: any, callback: any, contentState: any) => {
    contentBlock.findEntityRanges((character: any) => {
        const entityKey = character.getEntity()
        if (entityKey === null) {
            return false
        }
        return contentState.getEntity(entityKey).getMutability() === 'IMMUTABLE'
    }, callback)
}

export const decorator = new CompositeDecorator([
    {
        strategy: getEntityStrategy,
        component: TokenSpan
    }
])

interface EditorP {
    value: EditorState
    setValue: Function
    onFocus: (event: React.MouseEvent) => void
    disabled: boolean
    infocus: boolean
    focus?: string
    focusContent?: string
    optional?: boolean
    showToolbar?:boolean
}

export const Link = (props: any) => {
    const { url, linkText, target } = props.contentState.getEntity(props.entityKey).getData()
    console.log(props)

    return (
        <a style={{ color: '#006cb7', textDecoration: 'underline' }} href={url} target={target} className="link-button" onClick={()=>{
            props.callback(url, linkText, props.entityKey)
        }}>
            {props.children || linkText}
            {/* {linkText || props.children} */}
        </a>
    )
}

export function findLinkEntities(contentBlock: any, callback: any, contentState: any) {
    contentBlock.findEntityRanges((character: any) => {
        const entitykey = character.getEntity()
        return entitykey !== null && contentState.getEntity(entitykey).getType() === 'LINK'
    }, callback)
}

export const Editor = (props: EditorP) => {
    const { disabled, infocus, value, optional } = props

    const editorRef = React.useRef(null);

    const [state, setState] = useState({ focus: false })

    const [showUrlBox, setShowUrlBox] = useState(false)
    const [showEmojiPopup, setShowEmojiPopup] = useState(false)

    const [hyperLink, setHyperLink] = useState('')
    const [linkText, setLinkText] = useState('')

    const [currentModifyKey,setCurrentModifyKey] = useState<{
        blockKey: null;
    }|{
        blockKey: string;
    }>({
        blockKey:null
    })

    const [{ plugins, Toolbar,EmojiSelect }] = React.useState(() => {
        const toolbarPlugin = createToolbarPlugin();
        const emojiPlugin = createEmojiPlugin({
            selectGroups: [{ title: 'Society', icon: '☺', categories: ['people'] }]
        })
        const { EmojiSelect } = emojiPlugin
        
        const { Toolbar } = toolbarPlugin;
        const plugins = [toolbarPlugin,emojiPlugin];
        return {
          plugins,
          Toolbar,
          EmojiSelect
        };
    });

    let defaultdecorator = new CompositeDecorator([
        {
            strategy: getEntityStrategy,
            component: TokenSpan
        },
        {
            strategy: findLinkEntities,
            component: (props:any)=> <Link callback={(url:string, linkText:string, blockKey:string)=>{
                console.log("coming",linkText)
                setHyperLink(url)
                setLinkText(linkText)
                setShowUrlBox(true)
                setCurrentModifyKey({ blockKey })
               !state.focus && setState({ focus: true })
               !state.focus && editorRef.current && (editorRef.current as any).focus()
            }} {...props}/>,
        }
    ])

    const insertEmoji = (emojiValue: string, information: { emojiUnicode:string }) => {
        const editorState = EditorState.set(value, { decorator: defaultdecorator })

        const currentContent = editorState.getCurrentContent()
        const selection = editorState.getSelection()
        const entityKey = Entity.create('emoji', 'IMMUTABLE', { ...information })
        const textWithEntity = Modifier.insertText(currentContent, selection, emojiValue, undefined, entityKey)
        props.setValue(EditorState.push(editorState, textWithEntity, 'insert-characters'))
    }

    const { getEntityRange, getSelectionEntity } = draftJsUtils

    const generateLink = (hyperLink: any, linkDisplayText: any) => {
        const editorState = EditorState.set(value, { decorator: defaultdecorator })

        let link = hyperLink
        if (hyperLink.includes('http://')) {
            if (!hyperLink.includes('https://')) {
                link = `http://${hyperLink}`
            }
        }

            if(currentModifyKey.blockKey){
                const contentState = editorState.getCurrentContent()

                const contentStateUpdated = contentState.mergeEntityData(
                    currentModifyKey.blockKey,
                  {
                    url: link,
                    target: '_blank',
                    linkText: linkDisplayText
                })
            

                let selection:any = editorState.getSelection();

                const entityRange = getEntityRange(
                  editorState,
                  getSelectionEntity(editorState)
                );
              
                const isBackward = selection.getIsBackward();
              
                if (isBackward) {
                    selection = selection.merge({
                      anchorOffset: entityRange.end,
                      focusOffset: entityRange.start
                    });
                  } else {
                    selection = selection.merge({
                      anchorOffset: entityRange.start,
                      focusOffset: entityRange.end
                    });
                  }
              
                const updatedEditorWithText = Modifier.replaceText(
                  contentStateUpdated,
                  selection,
                  linkDisplayText,
                  editorState.getCurrentInlineStyle(),
                  currentModifyKey.blockKey
                );
              
                const newEditorState = EditorState.push(
                  editorState,
                  updatedEditorWithText,
                  "insert-characters"
                );
                props.setValue(newEditorState)
                setHyperLink('')
                setLinkText('')
                setCurrentModifyKey({
                    blockKey:null
                })
            } else{
                const currentContent = editorState.getCurrentContent()
                const selection = editorState.getSelection()

                currentContent.createEntity('LINK', 'MUTABLE', {
                    url: link,
                    target: '_blank',
                    linkText: linkDisplayText
                })

                const entityKey = currentContent.getLastCreatedEntityKey()

                const textWithEntity = Modifier.replaceText(currentContent, selection, linkDisplayText, undefined, entityKey)
                const newState = EditorState.createWithContent(textWithEntity, decorator)
                // console.log(convertToRaw(newState.getCurrentContent()))
                props.setValue(newState)
                setHyperLink('')
                setLinkText('')
            }
    }

    useEffect(() => {
        const editorState = EditorState.set(value, { decorator: defaultdecorator })

        const selectionState = editorState.getSelection()

        if (showUrlBox && !selectionState.isCollapsed()) {
            const selectionState = editorState.getSelection()
            const anchorKey = selectionState.getAnchorKey()
            const currentContent = editorState.getCurrentContent()
            const currentContentBlock = currentContent.getBlockForKey(anchorKey)
            const start = selectionState.getStartOffset()
            const end = selectionState.getEndOffset()
            const selectedText = currentContentBlock.getText().slice(start, end)

            if (!selectionState.isCollapsed()) {
                const startKey = editorState.getSelection().getStartKey()
                const startOffset = editorState.getSelection().getStartOffset()
                const blockWithLinkAtBeginning = currentContent.getBlockForKey(startKey)
                const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset)

                let url = ''
                if (linkKey) {
                    const linkInstance = currentContent.getEntity(linkKey)
                    url = linkInstance.getData().url
                }
                setHyperLink(url)
            }
            setLinkText(selectedText)
        }
    }, [showUrlBox])

    //This fixes react and draftjs state mismatch when user types fast
    const debouncedChange = debounce(0, false, props.setValue as any)

    function handlePastedText(text: string, html: string, editorState: EditorState) {
        let newState = Conductor.handleDraftEditorPastedText(html, editorState)

        if (newState) {
            props.setValue(newState)
            return true
        }

        return false
    }

    const LinkButton = createInlineStyleButton({
        style: 'link-button',
        children: (
            <button onClick={() => {
                // context.setUrlisOpen(!showUrlBox)
                // variableContextFAQ.setUrlisOpen(!showUrlBox)
                setShowUrlBox(!showUrlBox)}}>
                <LinkIcon />
            </button>
        )
    })

    const EmojiButton = createInlineStyleButton({
        style: 'emoji-button',
        children: (
            <button onClick={() => {
                setShowEmojiPopup(!showEmojiPopup)}}>
                <EmojiIcon />
            </button>
        )
    })

    const Draft = DraftEditor as any

    return (
        <>
            <div
                className={
                    infocus
                        ? `dialog_input_edit_true dialog_context_input_edit_true input_selected_dialog ${
                              disabled ? 'disabled_with_blue' : ''
                          } ${props.focus ? props.focus : ''}`
                        : `dialog_input_edit_true dialog_context_input_edit_true ${
                              disabled ? 'disabled_with_blue' : ''
                          } ${props.focus ? props.focus : ''}`
                }
                onClick={e => {
                    const ref = editorRef.current
                    if (ref) {
                        const r = ref as HTMLInputElement
                        r.focus()
                    }
                    props.onFocus(e)
                    setState({ focus: true })
                }}
            >
                <FocusContent>
                    {props.focusContent} {optional && <span style={{ color: '#FF8216' }}> (Optional)</span>}
                    {/* {props.description && <Tooltip
                          className="target customTip _description"
                          zIndex={10000}
                          tagName="span"
                          content={props.description }
                          distance={15}
                          forceDirection={true}
                          direction="up-start"
                      >
                          <InfoIcon />
                      </Tooltip>} */}
                </FocusContent>
                {/* <div className="draft_input_text"> */}
                <Draft
                    onCopy={Conductor.onDraftEditorCopy}
                    onCut={Conductor.onDraftEditorCut}
                    // editorState={props.value}
                    editorState={EditorState.set(value, { decorator: defaultdecorator })}
                    onChange={(state:EditorState)=>props.setValue(state) as any}
                    // handlePastedText={(text: string, html: string | undefined, editorState: any) => 'handled'}
                    // handleReturn={() => 'handled'}
                    handlePastedText={handlePastedText}
                    editorKey="CustomInlineToolbarEditor"
                    plugins={plugins}
                    ref={(editor: any) => (editorRef.current = editor)}
                />
                {/* </div> */}
            </div>
            <div className="message_toolbar_input">
                {state.focus && props.showToolbar&& (
                    <Downshift
                        isOpen={true}
                        onOuterClick={() => {
                            setShowUrlBox(false)
                            setState({ focus: false })
                            setHyperLink('')
                            setLinkText('')
                        }}
                    >
                        {() => (
                            <div id="editor-input" className="message_toolbar_container">
                                <div className="workflow message_toolbar">
                                    <Toolbar>
                                        {externalProps => (
                                            <React.Fragment>
                                                <div className="toolbar_parent">
                                                <div className="toolbar_child_one">
                                                    <BoldButton {...externalProps} />
                                                    <ItalicButton {...externalProps} />
                                                    <UnderlineButton {...externalProps} />
                                                    <Separator />
                                                    <UnorderedListButton {...externalProps} />
                                                    <OrderedListButton {...externalProps} />
                                                    {/* <OrderedListButton {...externalProps} /> */}
                                                    <LinkButton {...externalProps} />
                                                    <EmojiButton {...externalProps}/>
                                                </div>
                                                {showEmojiPopup&&
                                                <Downshift isOpen={true}
                                                    onOuterClick={() => setShowEmojiPopup(false)}>
                                                        {() => (
                                                            <span>
                                                                <EmojiPopup 
                                                                onSelected={(emoji:any)=>{
                                                                    insertEmoji(emoji,{emojiUnicode:emoji})
                                                                    console.log(emoji,"emoji")
                                                                }}
                                                                />
                                                            </span>
                                                        )}
                                                    </Downshift>
                                                    }
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </Toolbar>
                                </div>
                                {showUrlBox && (
                                    <Downshift
                                        isOpen={true}
                                        onOuterClick={() => {
                                            setShowUrlBox(false)
                                            // context.setUrlisOpen(false)
                                            // variableContextFAQ.setUrlisOpen(false)
                                            setState({ focus: false })
                                            setHyperLink('')
                                            setLinkText('')
                                            setCurrentModifyKey({
                                                blockKey:null
                                            })
                                        }}
                                    >
                                        {() => (
                                            <span className="markdown_url_box">
                                                <InputWrapper
                                                    style={{
                                                        position: 'relative',
                                                        // top: 95,
                                                        left: 20,
                                                        bottom: 45
                                                    }}
                                                    className="markdown_url_box_input"
                                                >
                                                    <span
                                                       className={
                                                           linkText.length == 0 || IsValidURL(hyperLink) === null
                                                               ? 'editor_btn_disabled'
                                                               : ''
                                                       }
                                                       style={{
                                                           position: 'absolute',
                                                           bottom: 86,
                                                           zIndex: 1000,
                                                           cursor:'pointer',
                                                           left: -20
                                                       }}
                                                       onClick={()=>{
                                                            setShowUrlBox(false)
                                                            // context.setUrlisOpen(false)
                                                            // variableContextFAQ.setUrlisOpen(false)
                                                            setHyperLink('')
                                                            setLinkText('')
                                                            setCurrentModifyKey({
                                                                blockKey:null
                                                            })
                                                       }}
                                                   >
                                                       <CloseColorIcon />
                                                   </span>
                                                    <span
                                                        onClick={() => {
                                                            // context.setUrlisOpen(false)
                                                            // variableContextFAQ.setUrlisOpen(false)
                                                            setShowUrlBox(false)
                                                            generateLink(hyperLink, linkText)
                                                        }}
                                                        className={
                                                            linkText.length == 0 || IsValidURL(hyperLink) === null
                                                                ? 'editor_btn_disabled'
                                                                : ''
                                                        }
                                                        style={{
                                                            position: 'absolute',
                                                            bottom: 86,
                                                            zIndex: 1000,
                                                            cursor:'pointer',
                                                            left: 5
                                                        }}
                                                    >
                                                        <CheckColorIcon />
                                                    </span>

                                                        <div className="message_tool_link">
                                                            <InputContainer className="url_input_box">
                                                                <Input
                                                                    type="text"
                                                                    value={linkText}
                                                                    onChange={(e: any) => setLinkText(e.target.value)}
                                                                />
                                                                <InputContent>Text</InputContent>
                                                            </InputContainer>

                                                            <InputContainer className="url_input_box">
                                                                <Input
                                                                    type="text"
                                                                    value={hyperLink}
                                                                    onChange={(e: any) => setHyperLink(e.target.value)}
                                                                />
                                                                <InputContent>URL </InputContent>
                                                            </InputContainer>
                                                        </div>
                                                </InputWrapper>
                                            </span>
                                        )}
                                    </Downshift>
                                )}
                            </div>
                        )}
                    </Downshift>
                )}
            </div>

        </>
    )
}
