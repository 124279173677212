/*eslint-disable*/
import Downshift from 'downshift'
import produce from 'immer'
import React, { ReactChild, useContext, useEffect, useState } from 'react'
import Tooltip from 'react-tooltip-lite'

import { AddIcon, FlowCanvasActionMenu, FlowCanvasIcons, ForEachIconNew, NodeHoverMenuIcons } from '@/common/Icons/Icons'
import { ForEachIcon, WarningAlertIcon } from '@/common/Icons/Workflow.Icons'

import { generateUniqueId, getProductId } from '@/common/utils/utils'
import { NonCanvasPanelContext, SidePanelContext } from './Canvas'
import {
    ActionInitialState,
    TimerInitialState,
    ActionsResponseT,
    ApprovalInitialState,
    ConditionInitialState,
    ConditionResponseT,
    ForEachInitialState,
    FunctionInitialState,
    InputFieldT,
    NodeAddT,
    NodeDelete,
    NodeEdit,
    NodeMode,
    NodeT,
    NodeView,
    Nodes,
    RightPanelStateT,
    TreeT,
    addNewNodeAsParentToParallelNode,
    addNode,
    addParallelPath,
    checkInputHasValue,
    deleteNode,
    updateNode,
    ConnectionTypeT,
    ApiTokenValue,
    NotificationInitialState
} from './types'
import { reduceNodes } from './utils'

type TreeBranchT = {
    Node?: any
    ForEachNode?: any
    Arrow?: any
    SecondStepChildWithWrap?: any
    SingleChildWrap?: any
    MultiChildWrap?: any
    EndNodeWarp?: any
}

export type NodePosition =
    | {
          kind: 'single'
      }
    | {
          kind: 'multi'
          positionType: 'odd' | 'even'
      }

export const Tree: TreeBranchT = {}

export function useDropDown(show?: boolean): [boolean, () => void, () => void] {
    const [showDropDown, setshowDropDown] = useState(show ? true : false)

    function makeDropDownVisible() {
        setshowDropDown(true)
    }

    function makeDropDownHidden() {
        setshowDropDown(false)
    }

    return [showDropDown, makeDropDownVisible, makeDropDownHidden]
}

function getNodeInitialState(node: NodeT) {
    switch (node.kind) {
        case 'Action':
            return {
                ...ActionInitialState,
                addingInsideForEach: node.meta_data && node.meta_data.subflow && node.meta_data.subflow.hasSubFlow
            }
        case 'Timer':
            return {
                ...TimerInitialState,
                addingInsideForEach: node.meta_data && node.meta_data.subflow && node.meta_data.subflow.hasSubFlow
            }
        case 'Function':
            return {
                ...FunctionInitialState,
                addingInsideForEach: node.meta_data && node.meta_data.subflow && node.meta_data.subflow.hasSubFlow
            }

        case 'ForEach':
            return ForEachInitialState
        case 'Approval':
            return {
                ...ApprovalInitialState,
                addingInsideForEach: node.meta_data && node.meta_data.subflow && node.meta_data.subflow.hasSubFlow
            }
        case 'Notification':
                return {
                    ...NotificationInitialState,
                    addingInsideForEach: node.meta_data && node.meta_data.subflow && node.meta_data.subflow.hasSubFlow
                }
        default:
            return {
                ...ConditionInitialState,
                addingInsideForEach: node.meta_data && node.meta_data.subflow && node.meta_data.subflow.hasSubFlow
            }
    }
}

Tree.Node = function Node(props: {
    node: TreeT<NodeT>
    index: number
    position: NodePosition
    parentNode: TreeT<NodeT>
    comingUnderParallelPath: boolean
}) {
    const { node, index, position, comingUnderParallelPath } = props
    let positionClass = ''
    if (position && position.kind == 'single') {
        positionClass = ''
    } else if (position && position.kind == 'multi') {
        positionClass = position.positionType == 'odd' ? 'child_after' : 'child_before'
    }

    const { rightPanelState, setRightPanelState, setDisableDeployButton } = useContext(SidePanelContext)

    const { parentAutomation } = useContext(NonCanvasPanelContext)

    const nodesWithoutConnection: string[] = parentAutomation.nodesWithoutConnection

    const currentParent =
        rightPanelState.mode.mode === NodeMode.Edit
            ? rightPanelState.mode.nodeEditInput.id === node.value.id
                ? 'selected_parent_node'
                : ''
            : ''

    const dontHaveInputValue = !checkInputHasValue(props.node.value)

    useEffect(() => {
        setDisableDeployButton((value: boolean[]) => {
            return value.concat(dontHaveInputValue)
        })
    }, [dontHaveInputValue])

    return (
        <div className={`flow_tree ${positionClass} ${comingUnderParallelPath ? 'parallel_path_child' : ''}`}>
            <div
                className={`first_step  ${currentParent}`}
                onClick={() => {
                    rightPanelState.mode.mode === NodeMode.View &&
                        setRightPanelState({
                            ...rightPanelState,
                            currentNode: getNodeInitialState(props.node.value),
                            show: true,
                            parentNode: props.node,
                            mode: {
                                ...NodeEdit,
                                nodeEditInput: props.node.value
                            }
                        })
                }}
            >
                <span className={node.value.icon ? 'tree_node bg_tree_flow' : 'bg_tree_flow'}>
                    {node.value.icon && node.value.icon.length > 0 ? (
                        <img
                            src={node.value.icon}
                            alt={node.value.kind === 'Condition' ? 'if' : 'A'}
                            style={{ color: !node.value.icon ? '#fff' : '#494949' }}
                        />
                    ) : (
                        <span className="bg_tree_flow_without_image">
                            {node.value.kind === 'Condition' ? 'if' : 'A'}
                        </span>
                    )}
                </span>
                <span className="bg_tree_content">{node.value.name}</span>

                {/* showing warning */}
                {node.value.kind == 'Action' &&
                (!props.node.value.meta_data ||
                    !props.node.value.meta_data.authentication_id ||
                    props.node.value.meta_data.authentication_id.length == 0 ||
                    nodesWithoutConnection.indexOf(props.node.value.id) != -1) ? (
                    <span className="no_input_value">
                        <div className="error_input_show">
                            <WarningAlertIcon />
                            <span className="error_menu_hvr">Connection details are missing.</span>
                        </div>
                    </span>
                ) : (
                    dontHaveInputValue &&
                    node.value.kind !== 'EmptyAction' &&
                    node.value.kind !== 'EmptyFunction' &&
                    node.value.kind !== 'EmptyForEach' &&
                    node.value.kind !== 'EmptyCondition' &&
                    node.value.kind !== 'EmptyApproval' && node.value.kind !== 'EmptyTimer'&& (
                        <span className="no_input_value">
                            <div className="error_input_show">
                                <WarningAlertIcon />
                                <span className="error_menu_hvr">Input fields are missing.</span>
                            </div>
                        </span>
                    )
                )}
            </div>
            {rightPanelState.mode.mode === NodeMode.View && (
                <Tree.Node.Hover node={node} parentNode={props.parentNode} />
            )}
        </div>
    )
}

Tree.ForEachNode = function Node(props: {
    node: TreeT<NodeT>
    index: number
    position: NodePosition
    parentNode: TreeT<NodeT>
    comingUnderParallelPath: boolean
    children: ReactChild | null
}) {
    const { node, index, position, comingUnderParallelPath } = props

    const [showDropDown, makeDropDownVisible, makeDropDownHidden] = useDropDown()

    const { rightPanelState, hasLinkedDialogs, active_automation, automationMeta,setRightPanelState } = useContext(SidePanelContext)

    const { parentAutomation } = useContext(NonCanvasPanelContext)

    const currentParent =
        rightPanelState.mode.mode === NodeMode.Edit
            ? rightPanelState.mode.nodeEditInput.id === node.value.id
                ? 'selected_parent_node'
                : ''
            : ''

    const dontHaveInputValue = !checkInputHasValue(props.node.value)

    return (
        <>
            <div className="loop_foreach_condition">
                <div className="loop_autoamtion_header" onClick={()=>{
                 (getProductId() == 'ASSISTANT' ? automationMeta.type =='no_trigger' ?true:!active_automation:!active_automation) && setRightPanelState({
                        ...rightPanelState,
                        currentNode: getNodeInitialState(node.value),
                        show: true,
                        parentNode: node,
                        mode: {
                            ...NodeEdit,
                            nodeEditInput: node.value
                        }
                    })
                }}>
                    <h4>
                        {' '}
                        <span>
                            <ForEachIconNew />
                        </span>
                        <span className='loop_autoamtion_header_content'>  
                            For Each - {node.value.name}
                        </span>
                        
                    </h4>
                    {rightPanelState.mode.mode === NodeMode.View && (
                        <Tree.Node.Hover
                            node={node}
                            parentNode={props.parentNode}
                            className={props.parentNode.children.length < 2 ? '' : 'parallel_child'}
                        />
                    )}
                    {/* <span className="autoamtion_expend_icon">
                        <ExpendIcon />
                    </span> */}
                    {dontHaveInputValue && (
                        <span className="for_each_warning_alert">
                            <span className="no_input_value">
                                <div className="error_input_show">
                                    <WarningAlertIcon />
                                    <span className="error_menu_hvr">Input fields are missing.</span>
                                </div>
                            </span>
                        </span>
                    )}
                </div>

                {props.children == null && (getProductId() == 'ASSISTANT' ? automationMeta.type =='no_trigger' ?!hasLinkedDialogs : !active_automation : !active_automation) ? (
                    <div className="add_loop">
                        <div
                            className="add_foreach_loop"
                            onClick={() => 
                                rightPanelState.mode.mode === NodeMode.View && makeDropDownVisible()
                            }
                        >
                            <AddIcon />
                        </div>
                        {showDropDown && (
                            <Tree.Node.Menu
                                makeDropDownHidden={makeDropDownHidden}
                                parentNode={props.parentNode}
                                node={props.node}
                                addingInsideForEach={true}
                            />
                        )}
                    </div>
                ) : (
                    props.children
                )}
            </div>
        </>
    )
}

Tree.Arrow = function ArrowForChild(props: { node: TreeT<NodeT>; parentNode: TreeT<NodeT> }) {
    const [showDropDown, makeDropDownVisible, makeDropDownHidden] = useDropDown()

    const [showPlusIcon, setShowIcon] = useState(false)

    const { rightPanelState, hasLinkedDialogs, active_automation, automationMeta } = useContext(SidePanelContext)

    const nodeNotHasParallelPath =
        props.parentNode.children.filter(node => node.value.kind === 'ParallelPath').length === 0

    const nodeNotHasErrorHandling =
        props.parentNode.children.filter(node => node.value.kind === 'ErrorHandlingCondition').length === 0

    const isAssistant = getProductId() == 'ASSISTANT'

    return (
        <div className="arrow_menu_dropdown">
            <span className="arrow_menu">
                <div className="line_hover_plus">
                    {rightPanelState.mode.mode === NodeMode.View &&
                        nodeNotHasParallelPath &&
                        nodeNotHasErrorHandling &&
                        (isAssistant ? automationMeta.type =='no_trigger' ?!hasLinkedDialogs : !active_automation: !active_automation) && (
                            <div
                                className="line_hover_plus_icon loop_automation_icon"
                                onMouseEnter={() => setShowIcon(true)}
                                onMouseLeave={() => setShowIcon(false)}
                                style={{ opacity: showPlusIcon || showDropDown ? 1 : 0 }}
                            >
                                {(showPlusIcon || showDropDown) && (
                                    <div className="line_hover_plus_icon_svg" onClick={makeDropDownVisible}>
                                        <FlowCanvasIcons.ArrowPlusIcon />
                                    </div>
                                )}
                            </div>
                        )}
                </div>
            </span>
            {showDropDown && (
                <Tree.Node.Menu
                    makeDropDownHidden={makeDropDownHidden}
                    node={props.parentNode}
                    parentNode={props.parentNode}
                    addingInsideForEach={
                        props.parentNode.value.kind == 'ForEach' ||
                        (props.parentNode.value.meta_data &&
                            props.parentNode.value.meta_data &&
                            props.parentNode.value.meta_data.hasSubFlow)
                            ? true
                            : undefined
                    }
                />
            )}
        </div>
    )
}

Tree.Node.Wrap = function NodeWrapper(props: any) {
    const [showDropDown, makeDropDownVisible, makeDropDownHidden] = useDropDown()
    const [addingAsParallelPathParent, setAddingAsParallelPathParent] = useState(false)
    const { rightPanelState, hasLinkedDialogs, active_automation, automationMeta } = useContext(SidePanelContext)
    const [showPlusIcon, setShowIcon] = useState(false)

    const isAssistant = getProductId() == "ASSISTANT"

    return (
        <li className="dropdown_icons_li">
            {props.children}

            {props.isParallelPath &&
                rightPanelState.mode.mode === NodeMode.View &&
                (isAssistant ? automationMeta.type =='no_trigger' ?!hasLinkedDialogs : !active_automation : !active_automation) &&
                props.node.value.kind != 'ErrorHandlingCondition' && (
                    <div
                        className="parallel_path_plus_btn"
                        onClick={() => {
                            setAddingAsParallelPathParent(true)
                            makeDropDownVisible()
                        }}
                        onMouseEnter={() => setShowIcon(true)}
                        onMouseLeave={() => setShowIcon(false)}
                        style={{ opacity: showPlusIcon || (addingAsParallelPathParent && showDropDown) ? 1 : 0 }}
                    >
                        {(showPlusIcon || (addingAsParallelPathParent && showDropDown)) && (
                            <span className="parallel_path_plus_circle">
                                <FlowCanvasIcons.ArrowPlusIcon />
                            </span>
                        )}
                        {showDropDown && (
                            <Tree.Node.Menu
                                addingAsParallelPathParent={addingAsParallelPathParent}
                                makeDropDownHidden={makeDropDownHidden}
                                parentNode={props.parentNode}
                                addingInsideForEach={
                                    props.node.value.meta_data.subflow && props.node.value.meta_data.subflow.hasSubFlow
                                }
                                node={props.node}
                            />
                        )}
                    </div>
                )}
        </li>
    )
}

Tree.Node.EndNodeWarp = function EndNodeWrapper(props: {
    node: TreeT<NodeT>
    hasChildren: boolean
    children: React.ReactChild
    parentNode: TreeT<NodeT>
    isParallelPath: boolean
}) {
    const [showDropDown, makeDropDownVisible, makeDropDownHidden] = useDropDown()
    const [addingAsParallelPathParent, setAddingAsParallelPathParent] = useState(false)
    const { rightPanelState, hasLinkedDialogs,active_automation, automationMeta } = useContext(SidePanelContext)
    const [showPlusIcon, setShowIcon] = useState(false)

    return (
        <li className={!props.hasChildren ? 'end_node' : ''}>
            {props.children}
            {rightPanelState.mode.mode === NodeMode.View && (getProductId() == 'ASSISTANT' ? automationMeta.type =='no_trigger' ?!hasLinkedDialogs : !active_automation : !active_automation) && (
                <React.Fragment>
                    <div
                        className={
                            props.node.value.kind == 'ForEach'
                                ? 'line_hover_plus_icon foreach_line_hover_plus_icon'
                                : 'line_hover_plus_icon'
                        }
                        style={showDropDown ? { opacity: 1 } : {}}
                    >
                        <div
                            className={
                                props.node.value.kind == 'ForEach'
                                    ? 'line_hover_plus_icon_svg foreach_children_plus_bottom_menu'
                                    : 'line_hover_plus_icon_svg'
                            }
                            onClick={() => {
                                setAddingAsParallelPathParent(false)
                                makeDropDownVisible()
                            }}
                        >
                            <FlowCanvasIcons.ArrowPlusIconWithBackground />
                        </div>
                    </div>

                    {showDropDown && (
                        <Tree.Node.Menu
                            addingAsParallelPathParent={addingAsParallelPathParent}
                            makeDropDownHidden={makeDropDownHidden}
                            parentNode={addingAsParallelPathParent ? props.parentNode : props.node}
                            node={props.node}
                            addingInsideForEach={
                                addingAsParallelPathParent &&
                                props.node.value.meta_data.subflow &&
                                props.node.value.meta_data.subflow.hasSubFlow
                            }
                            className={props.node.value.kind == 'ForEach' ? 'foreach_bottom_menu' : ''}
                        />
                    )}
                </React.Fragment>
            )}

            {props.isParallelPath &&
                rightPanelState.mode.mode === NodeMode.View && (getProductId() == 'ASSISTANT' ? automationMeta.type =='no_trigger' ?!hasLinkedDialogs : !active_automation : !active_automation) &&
                props.node.value.kind != 'ErrorHandlingCondition' && (
                    <div
                        className={
                            props.node.value.kind == 'ForEach'
                                ? 'parallel_path_plus_btn _foreach'
                                : 'parallel_path_plus_btn'
                        }
                        onClick={() => {
                            setAddingAsParallelPathParent(true)
                            makeDropDownVisible()
                        }}
                        onMouseEnter={() => setShowIcon(true)}
                        onMouseLeave={() => setShowIcon(false)}
                        style={{ opacity: showPlusIcon || (addingAsParallelPathParent && showDropDown) ? 1 : 0 }}
                    >
                        {(showPlusIcon || (addingAsParallelPathParent && showDropDown)) && (
                            <span className="parallel_path_plus_circle">
                                <FlowCanvasIcons.ArrowPlusIcon />
                            </span>
                        )}
                    </div>
                )}
        </li>
    )
}

Tree.Node.Hover = function NodeHoverMenu(props: { node: TreeT<NodeT>; parentNode: TreeT<NodeT>; className: string }) {
    const { rightPanelState, setRightPanelState, hasLinkedDialogs, active_automation, automationMeta } = useContext(SidePanelContext)

    const isAssitant = getProductId() === 'ASSISTANT'

    const { className } = props
    // console.log(hasLinkedDialogs, 'hasLinkedDialogs')
    return (
        <div className={`flow_buttons ${className ? className : ''}`}>
            <div className={`flow_buttons_icons ${hasLinkedDialogs ? 'flow_buttons_icons_content_information' : ''}`}>
                {props.node.value.description.length > 0 && (
                    <>
                        <div className="flow_buttons_icons_content">
                            <Tooltip
                                className="target customTip"
                                content={props.node.value.description}
                                distance={8}
                                forceDirection={true}
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                            >
                                <NodeHoverMenuIcons.InfoIcon />
                            </Tooltip>
                        </div>
                    </>
                )}

                {props.node.value.kind != 'ErrorHandlingCondition' && (isAssitant ? automationMeta.type =='no_trigger' ?!hasLinkedDialogs : !active_automation : !active_automation)  && (
                    <div
                        className="flow_buttons_icons_content"
                        onClick={() => {
                            setRightPanelState({
                                ...rightPanelState,
                                currentNode: getNodeInitialState(props.node.value),
                                show: true,
                                parentNode: props.node,
                                mode: {
                                    ...NodeEdit,
                                    nodeEditInput: props.node.value
                                }
                            })
                        }}
                    >
                        <Tooltip
                            className="target customTip "
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content={'Edit'}
                            distance={5}
                        >
                            <NodeHoverMenuIcons.EditIcon />
                        </Tooltip>
                    </div>
                )}
                {/* <div className="flow_buttons_icons_content">
                    <NodeHoverMenuIcons.CopyIcon />
                </div> */}
                {(isAssitant ? automationMeta.type =='no_trigger' ?!hasLinkedDialogs : !active_automation: !active_automation) && (
                    <div
                        className="flow_buttons_icons_content"
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setRightPanelState({
                                ...rightPanelState,
                                show: false,
                                mode: {
                                    ...NodeDelete,
                                    nodeEditInput: props.node.value,
                                    parentNode: props.parentNode.value,
                                    currentNode: props.node
                                },
                                parentNode: props.parentNode
                            })
                        }}
                    >
                        <Tooltip
                            className="target customTip "
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content={'Delete'}
                            distance={5}
                        >
                            <NodeHoverMenuIcons.DeleteIcon />
                        </Tooltip>
                    </div>
                )}
            </div>
        </div>
    )
}

Tree.SecondStepChildWithWrap = function SecondStepChildWithWrap(props: {
    hasSingleChildern: boolean
    children: React.ReactChild
    node: TreeT<NodeT>
    parentNode: TreeT<NodeT>
    isOpen: boolean
    render: (makeDropDownVisible: any, MenuElement: any) => void
}) {
    const [showDropDown, makeDropDownVisible, makeDropDownHidden] = useDropDown(true)

    useEffect(() => {
        props.isOpen && makeDropDownVisible()
    }, [props.isOpen])

    const MenuElement = showDropDown ? (
        <Tree.Node.Menu makeDropDownHidden={makeDropDownHidden} parentNode={props.parentNode} node={props.node} />
    ) : null

    return <>{props.render(makeDropDownVisible, MenuElement)}</>
}

Tree.SingleChildWrap = function SingleChild(props: {
    hasSingleChildern: boolean
    children: React.ReactChild
    node: TreeT<NodeT>
    parentNode: TreeT<NodeT>
    withArrow: boolean
    insideForEach?: boolean
    disableMouseEvent?: boolean
}) {
    const { withArrow, node, insideForEach, parentNode } = props

    return (
        <>
            {withArrow && <Tree.Arrow node={props.node} parentNode={props.parentNode} />}
            {node.value.kind != 'ForEach' ? (
                <ul
                    className={
                        props.hasSingleChildern
                            ? `tree_left_side single_child ${insideForEach ? 'inside_forEach' : ''} ${
                                  !insideForEach && parentNode.value.kind == 'ForEach' ? 'forEach_children' : ''
                              }`
                            : `tree_left_side ${props.disableMouseEvent ? 'z__index_tree_left_side' : ''}  ${
                                  insideForEach ? 'inside_forEach' : ''
                              } ${!insideForEach && parentNode.value.kind == 'ForEach' ? 'forEach_children' : ''}`
                    }
                >
                    {props.children}
                </ul>
            ) : (
                <div className="loop_automation">
                    <ul className={props.hasSingleChildern ? 'tree_left_side single_child' : 'tree_left_side'}>
                        <div className="loop_automation_li">{props.children}</div>
                    </ul>
                </div>
            )}
        </>
    )
}

Tree.MultiChildWrap = function MultiChild(props: {
    children: React.ReactChild
    node: TreeT<NodeT>
    parentNode: TreeT<NodeT>
}) {
    return (
        <>
            <Tree.Arrow node={props.node} parentNode={props.parentNode} />
            <ul className="multi_child_li">{props.children}</ul>
        </>
    )
}

Tree.Node.EmptyParallelNode = function EmptyParallelNode(props: any) {
    const [showDropDown, makeDropDownVisible, makeDropDownHidden] = useDropDown()

    const { editTreeData, setEditTreeData, treeData, setRightPanelState, rightPanelState } = useContext(
        SidePanelContext
    )
    const currentNode = rightPanelState.currentNode as ActionsResponseT | ConditionResponseT

    const validNode = currentNode.parallelPathNode && currentNode.parallelPathNode.currentParallelPathNode

    const parallelPaths = props.parentNode.children.filter((child: TreeT<NodeT>) => child.value.kind === 'ParallelPath')

    const notEmptyParallelPath = parallelPaths.filter((child: TreeT<NodeT>) => child.value.name.length > 0)

    const disableNode =
        (parallelPaths.length > 1 &&
            notEmptyParallelPath.length > 0 &&
            notEmptyParallelPath[0].value.id !== props.node.value.id) ||
        (validNode && validNode.id !== props.node.value.id) ||
        (rightPanelState.mode.mode === NodeMode.Edit && rightPanelState.mode.nodeEditInput !== props.node.value.id) ||
        (rightPanelState.mode.mode === NodeMode.Add && rightPanelState.currentNode.nodeType === Nodes.Trigger)
            ? 'editor_btn_disabled'
            : ''

    return (
        <li
            className={
                !props.hasChildern ? `dotted_line_add end_node ${disableNode}` : `dotted_line_add ${disableNode}`
            }
            style={{ pointerEvents: disableNode.length > 0 ? 'none' : 'visible' }}
        >
            <div className="flow_tree" onClick={() => notEmptyParallelPath.length === 0 && makeDropDownVisible()}>
                <div className="center_canvas_svg center_scanvas_select_svg">
                    {props.node.value.name.length > 0 ? (
                        props.node.value.name
                    ) : (
                        <span className="action_plus_icon">
                            <FlowCanvasIcons.ArrowPlusIcon />
                        </span>
                    )}
                </div>
            </div>
            {props.node.value.name.length == 0 && (
                <span
                    className="right_child_svg"
                    onClick={() => {
                        if (disableNode.length === 0) {
                            const subflow =
                                props.node.value.meta_data && props.node.value.meta_data.subflow
                                    ? props.node.value.meta_data.subflow
                                    : { hasSubFlow: false }

                            if (NodeMode.View !== rightPanelState.mode.mode) {
                                const newTree = deleteNode(
                                    props.node.value,
                                    props.parentNode.value,
                                    editTreeData.tree,
                                    false,
                                    {
                                        addingInsideForEach: subflow.hasSubFlow,
                                        forEachNodeId: subflow.hasSubFlow ? subflow.forEachNodeId : '',
                                        parentNode: props.parentNode.value
                                    }
                                )
                                setEditTreeData({ ...editTreeData, tree: newTree })
                                if (parallelPaths.length === 1) {
                                    setRightPanelState({ ...rightPanelState, mode: NodeView })
                                } else if (
                                    props.parentNode.value.kind == 'ForEach' ||
                                    (subflow && subflow.hasSubFlow)
                                ) {
                                    const parallelPaths =
                                        props.parentNode.value.kind == 'ForEach'
                                            ? props.parentNode.value.subflow.children.filter(
                                                  (child: TreeT<NodeT>) => child.value.kind === 'ParallelPath'
                                              )
                                            : props.parentNode.children.filter(
                                                  (child: TreeT<NodeT>) => child.value.kind === 'ParallelPath'
                                              )
                                    parallelPaths.length === 1 &&
                                        setRightPanelState({ ...rightPanelState, mode: NodeView })
                                }
                            } else {
                                setRightPanelState({
                                    ...rightPanelState,
                                    show: false,
                                    mode: {
                                        ...NodeDelete,
                                        nodeEditInput: props.node.value,
                                        parentNode: props.parentNode.value
                                    }
                                })
                            }
                        }
                    }}
                >
                    <FlowCanvasIcons.ArrowPlusIcon />
                </span>
            )}

            {showDropDown && (
                <Tree.Node.Menu
                    node={props.node}
                    parentNode={props.parentNode}
                    makeDropDownHidden={makeDropDownHidden}
                    nodePosition={props.index}
                    addingInsideForEach={
                        props.node.value.meta_data &&
                        props.node.value.meta_data.subflow &&
                        props.node.value.meta_data.subflow.hasSubFlow
                    }
                />
            )}
        </li>
    )
}

Tree.Node.EmptyConditionNode = function EmptyConditionNode(props: any) {
    const [showDropDown, makeDropDownVisible] = useDropDown()

    return (
        <li className={!props.hasChildern ? 'dotted_line_add end_node' : 'dotted_line_add'}>
            <div className="flow_tree" onClick={makeDropDownVisible}>
                <div className="center_canvas_svg center_scanvas_select_svg">
                    <span className="action_plus_icon">
                        <FlowCanvasIcons.ArrowPlusIcon />
                    </span>
                    <span className="right_child_svg">
                        <FlowCanvasIcons.ArrowPlusIcon />
                    </span>
                </div>
            </div>
            {/* <Tree.Arrow /> */}
            {/* {props.children} */}
            {showDropDown && <Tree.Node.ActionMenu />}
        </li>
    )
}

Tree.Node.EmptyNode = function EmptyNode(props: any) {
    return (
        <div className="flow_tree">
            <div className="center_canvas_svg center_scanvas_select_svg">
                <p style={{ marginBottom: 0 }}>{props.node.value.name}</p>
            </div>
        </div>
    )
}

Tree.Node.Repeat = function RepeatNode(props: any) {
    return (
        <li className="child_after_left">
            <div className="flow_tree_actions">
                <div className="flow_tree_actions_header">
                    <h6>Actions</h6>
                </div>
                <div className="flow_tree_actions_swap">
                    <div className="flow_tree_img">
                        <div className="flow_tree_img_divide">
                            <span>
                                <FlowCanvasIcons.StartIcon />
                            </span>
                            <p>verify user accounts</p>
                        </div>
                        <div className="flow_tree_img_divide repeat_svg">
                            <span>
                                <FlowCanvasIcons.RepeatIcon />
                            </span>
                        </div>
                        <div className="flow_tree_img_divide">
                            <span>
                                <FlowCanvasIcons.StartIcon />
                            </span>
                            <p>verify user accounts</p>
                        </div>
                    </div>
                </div>
                <Tree.Node.Hover node={props.node} parentNode={props.parentNode} />
            </div>
            <Tree.Arrow />
        </li>
    )
}

Tree.Node.Menu = function NodeMenu(props: {
    makeDropDownHidden: () => void
    node: TreeT<NodeT>
    parentNode: TreeT<NodeT>
    nodePosition: number
    addingAsParallelPathParent: boolean
    addingInsideForEach: boolean
    className?: string
}) {
    const { rightPanelState, setRightPanelState, editTreeData, setEditTreeData, treeData, automationMeta } = useContext(
        SidePanelContext
    )

    let { addingInsideForEach } = props

    const state = rightPanelState as RightPanelStateT

    return (
        <div
            className={
                !props.addingAsParallelPathParent
                    ? `line_hover_dropdown ${props.className ? props.className : ''}`
                    : `line_hover_dropdown adding_as_parallel_path_parent ${props.className ? props.className : ''}`
            }
        >
            <Downshift isOpen={true} onOuterClick={props.makeDropDownHidden}>
                {() => (
                    <div className="line_hover_dropdown_ul">
                       {!addingInsideForEach &&
                            (!props.parentNode.value.meta_data ||
                                (props.parentNode.value.meta_data &&
                                    (!props.parentNode.value.meta_data.subflow ||
                                        !props.parentNode.value.meta_data.subflow.hasSubFlow))) && ( 
                        <div
                            className="line_hover_dropdown_li"
                            onClick={() => {
                                props.makeDropDownHidden()

                                let newTree: TreeT<NodeT>

                                if (props.node.value.kind !== 'ParallelPath') {
                                    const currentParentNode = props.addingAsParallelPathParent
                                        ? props.parentNode
                                        : props.node
                                    const id = generateUniqueId().toString()

                                    const newNode = {
                                        kind: 'EmptyTimer',
                                        id: id,
                                        name: 'Add Timer',
                                        description: '',
                                        meta_data: {},
                                        icon: '',
                                        input: [],
                                        output: {},
                                        path: []
                                    } as NodeT

                                    const addingFlowInsideForEach = {
                                        addingInsideForEach:false,
                                        forEachNodeId:'',
                                        parentNode: props.node.value
                                    }

                                    if (!props.addingAsParallelPathParent) {
                                        newTree = addNode(
                                            newNode,
                                            currentParentNode.value,
                                            rightPanelState.parentNode.value.id !== currentParentNode.value.id
                                                ? treeData.tree
                                                : editTreeData.tree,
                                            { isErrorHandling: false, errorHandlingChilds: [] },
                                            addingFlowInsideForEach
                                        )
                                    } else {
                                        newTree = addNewNodeAsParentToParallelNode(
                                            newNode,
                                            currentParentNode.value,
                                            props.node.value,
                                            rightPanelState.parentNode.value.id !== currentParentNode.value.id
                                                ? treeData.tree
                                                : editTreeData.tree,

                                            {
                                                isErrorHandling: false,
                                                errorHandlingChilds: []
                                            },
                                            addingFlowInsideForEach
                                        )
                                    }

                                    const nextState: RightPanelStateT = produce(state, draftState => {
                                        draftState.parentNode = currentParentNode
                                        draftState.currentNode = {
                                            ...TimerInitialState,
                                            parallelPathNode: {
                                                currentParallelPathNode: null,
                                                anotherEmptyChildren: null,
                                                replacingParallelSibling: props.addingAsParallelPathParent
                                                    ? props.node.value
                                                    : null,
                                                emptyChildrenPosition: 0
                                            },
                                            addingInsideForEach:false,
                                            subflowMetaData: {
                                                          subflow: { hasSubFlow: false }
                                                      }
                                        }
                                        draftState.show = true
                                        draftState.mode = NodeAddT
                                    })

                                    setRightPanelState(nextState)
                                } else {
                                    const currentParentNode = props.parentNode

                                    const addingFlowInsideForEach = {
                                        addingInsideForEach:false,
                                        forEachNodeId: '',
                                        parentNode: props.node.value
                                    }

                                    newTree = updateNode(
                                        { ...props.node.value, name: 'Add Timer' },
                                        editTreeData.tree,
                                        addingFlowInsideForEach
                                    )

                                    const hasOnlyParallelPaths: TreeT<NodeT>[] = props.parentNode.children
                                        .filter(z => z.value.kind === 'ParallelPath')
                                        .filter(x => x.value.id !== props.node.value.id)
                                        .filter(x => x.value.kind === 'ParallelPath')

                                    const nextState: RightPanelStateT = produce(state, draftState => {
                                        draftState.parentNode = props.parentNode

                                        draftState.currentNode = {
                                            ...TimerInitialState,
                                            parallelPathNode: {
                                                currentParallelPathNode: props.node.value,
                                                anotherEmptyChildren:
                                                    hasOnlyParallelPaths.length > 0
                                                        ? hasOnlyParallelPaths[0].value
                                                        : null,
                                                emptyChildrenPosition: props.nodePosition,
                                                replacingParallelSibling: null
                                            },
                                            addingInsideForEach:false,
                                            subflowMetaData: {
                                                      subflow: { hasSubFlow: false }
                                                  }
                                        }

                                        draftState.show = true
                                        draftState.mode = NodeAddT
                                    })

                                    setRightPanelState(nextState)
                                }

                                const tree = editTreeData as {
                                    firstStepDone: boolean
                                    secondStepDone: boolean
                                    tree: TreeT<NodeT>
                                }

                                const updatedTree = produce(tree, draftState => {
                                    draftState.tree = newTree
                                    draftState.firstStepDone = true
                                })

                                setEditTreeData(updatedTree)
                            }}
                        >
                            <div className="line_hover_dropdown_li_bg timer_iocn">
                                <FlowCanvasActionMenu.TimerIcon />
                            </div>
                            <span>Add Timer</span>
                        </div>
                        )}
                        {(automationMeta.type==null || getProductId() === 'AUTOMATE' || automationMeta.type == 'no_trigger')&& <div
                            className="line_hover_dropdown_li"
                            onClick={() => {
                                if (
                                    state.multiActionAccess === 'yes' ||
                                    reduceNodes(treeData.tree.children[0].children[0]).filter(
                                        (node: NodeT) => node.kind == 'Action'
                                    ).length == 0
                                ) {
                                    props.makeDropDownHidden()

                                    let newTree: TreeT<NodeT>

                                    if (props.node.value.kind !== 'ParallelPath') {
                                        const currentParentNode = props.addingAsParallelPathParent
                                            ? props.parentNode
                                            : props.node

                                        const id = generateUniqueId().toString()
                                        const newNode = {
                                            kind: 'EmptyAction',
                                            id: id,
                                            name: 'New Action',
                                            description: '',
                                            meta_data:
                                                addingInsideForEach ||
                                                (currentParentNode.value.meta_data &&
                                                    currentParentNode.value.meta_data.subflow &&
                                                    currentParentNode.value.meta_data.subflow.hasSubFlow)
                                                    ? {
                                                          authentication_id: '',
                                                          subflow: {
                                                              hasSubFlow: true,
                                                              forEachNodeId: addingInsideForEach
                                                                  ? currentParentNode.value.id
                                                                  : currentParentNode.value.meta_data.subflow
                                                                        .forEachNodeId,
                                                              parentId: currentParentNode.value.id
                                                          }
                                                      }
                                                    : {
                                                          authentication_id: '',
                                                          subflow: { hasSubFlow: false }
                                                      },
                                            icon: '',
                                            input: [],
                                            output: {},
                                            path: []
                                        } as NodeT

                                        // for adding subflow
                                        const addingFlowInsideForEach = {
                                            addingInsideForEach:
                                                addingInsideForEach ||
                                                (currentParentNode.value.meta_data &&
                                                    currentParentNode.value.meta_data.subflow &&
                                                    currentParentNode.value.meta_data.subflow.hasSubFlow),
                                            forEachNodeId:
                                                addingInsideForEach && currentParentNode.value.kind == 'ForEach'
                                                    ? currentParentNode.value.id
                                                    : currentParentNode.value.meta_data &&
                                                      currentParentNode.value.meta_data.subflow
                                                    ? currentParentNode.value.meta_data.subflow.forEachNodeId
                                                    : '',
                                            parentNode: addingInsideForEach ? currentParentNode.value : props.node.value
                                        }

                                        if (!props.addingAsParallelPathParent) {
                                            newTree = addNode(
                                                newNode,
                                                currentParentNode.value,
                                                rightPanelState.parentNode.value.id !== currentParentNode.value.id
                                                    ? treeData.tree
                                                    : editTreeData.tree,
                                                { isErrorHandling: false, errorHandlingChilds: [] },
                                                addingFlowInsideForEach
                                            )
                                        } else {
                                            newTree = addNewNodeAsParentToParallelNode(
                                                newNode,
                                                currentParentNode.value,
                                                props.node.value,
                                                rightPanelState.parentNode.value.id !== currentParentNode.value.id
                                                    ? treeData.tree
                                                    : editTreeData.tree,
                                                {
                                                    isErrorHandling: false,
                                                    errorHandlingChilds: []
                                                },
                                                addingFlowInsideForEach
                                            )
                                        }

                                        const nextState: RightPanelStateT = produce(state, draftState => {
                                            draftState.parentNode = currentParentNode
                                            draftState.currentNode = {
                                                ...ActionInitialState,
                                                parallelPathNode: {
                                                    currentParallelPathNode: null,
                                                    anotherEmptyChildren: null,
                                                    replacingParallelSibling: props.addingAsParallelPathParent
                                                        ? props.node.value
                                                        : null,
                                                    emptyChildrenPosition: 0
                                                },
                                                errorHandling: {
                                                    ...ActionInitialState.errorHandling,
                                                    errorHandlingParent: newNode
                                                },
                                                selectedConnection:{
                                                    type: ConnectionTypeT.new,
                                                    id: '',
                                                    hasError: null,
                                                    valid: null,
                                                    loading: false,
                                                    selectedConnection: 'Add a new Account',
                                                    connectionsDetails: ApiTokenValue,
                                                    error: { show: false, info: '' },
                                                    connectionEditing: false
                                                },
                                                addingInsideForEach:
                                                    addingInsideForEach ||
                                                    (currentParentNode.value.meta_data &&
                                                        currentParentNode.value.meta_data.subflow &&
                                                        currentParentNode.value.meta_data.subflow.hasSubFlow),
                                                subflowMetaData:
                                                    addingInsideForEach ||
                                                    (currentParentNode.value.meta_data &&
                                                        currentParentNode.value.meta_data.subflow &&
                                                        currentParentNode.value.meta_data.subflow.hasSubFlow)
                                                        ? {
                                                              subflow: {
                                                                  hasSubFlow: true,
                                                                  forEachNodeId: addingInsideForEach
                                                                      ? currentParentNode.value.id
                                                                      : currentParentNode.value.meta_data.subflow
                                                                            .forEachNodeId,
                                                                  parentId: currentParentNode.value.id
                                                              }
                                                          }
                                                        : {
                                                              subflow: { hasSubFlow: false }
                                                          }
                                            }
                                            draftState.show = true
                                            draftState.mode = NodeAddT
                                        })

                                        setRightPanelState(nextState)
                                    } else {
                                        const currentParentNode = props.parentNode

                                        const addingFlowInsideForEach = {
                                            addingInsideForEach:
                                                addingInsideForEach ||
                                                (currentParentNode.value.meta_data &&
                                                    currentParentNode.value.meta_data.subflow &&
                                                    currentParentNode.value.meta_data.subflow.hasSubFlow),
                                            forEachNodeId: addingInsideForEach
                                                ? props.node.value.meta_data.subflow.forEachNodeId
                                                : currentParentNode.value.meta_data &&
                                                  currentParentNode.value.meta_data.subflow
                                                ? currentParentNode.value.meta_data.subflow.forEachNodeId
                                                : '',
                                            parentNode: addingInsideForEach ? currentParentNode.value : props.node.value
                                        }

                                        newTree = updateNode(
                                            { ...props.node.value, name: 'New action' },
                                            editTreeData.tree,
                                            addingFlowInsideForEach
                                        )

                                        const hasOnlyParallelPaths: TreeT<NodeT>[] = props.parentNode.children
                                            .filter(z => z.value.kind === 'ParallelPath')
                                            .filter(x => x.value.id !== props.node.value.id)
                                            .filter(x => x.value.kind === 'ParallelPath')

                                        const nextState: RightPanelStateT = produce(state, draftState => {
                                            draftState.parentNode = props.parentNode

                                            draftState.currentNode = {
                                                ...ActionInitialState,
                                                parallelPathNode: {
                                                    currentParallelPathNode: props.node.value,
                                                    anotherEmptyChildren:
                                                        hasOnlyParallelPaths.length > 0
                                                            ? hasOnlyParallelPaths[0].value
                                                            : null,
                                                    emptyChildrenPosition: props.nodePosition,
                                                    replacingParallelSibling: null
                                                },
                                                selectedConnection:{
                                                    type: ConnectionTypeT.new,
                                                    id: '',
                                                    hasError: null,
                                                    valid: null,
                                                    loading: false,
                                                    selectedConnection: 'Add a new Account',
                                                    connectionsDetails: ApiTokenValue,
                                                    error: { show: false, info: '' },
                                                    connectionEditing: false
                                                },
                                                errorHandling: {
                                                    ...ActionInitialState.errorHandling,
                                                    errorHandlingParent: { ...props.node.value, name: 'New action' }
                                                },
                                                addingInsideForEach:
                                                    addingInsideForEach ||
                                                    (currentParentNode.value.meta_data &&
                                                        currentParentNode.value.meta_data.subflow &&
                                                        currentParentNode.value.meta_data.subflow.hasSubFlow),
                                                subflowMetaData: addingInsideForEach
                                                    ? {
                                                          subflow: {
                                                              hasSubFlow: true,
                                                              forEachNodeId:
                                                                  props.node.value.meta_data.subflow.forEachNodeId,
                                                              parentId: currentParentNode.value.id
                                                          }
                                                      }
                                                    : {
                                                          subflow: { hasSubFlow: false }
                                                      }
                                            }

                                            draftState.show = true
                                            draftState.mode = NodeAddT
                                        })

                                        setRightPanelState(nextState)
                                    }

                                    const tree = editTreeData as {
                                        firstStepDone: boolean
                                        secondStepDone: boolean
                                        tree: TreeT<NodeT>
                                    }

                                    const updatedTree = produce(tree, draftState => {
                                        draftState.tree = newTree
                                        draftState.firstStepDone = true
                                    })

                                    setEditTreeData(updatedTree)
                                }
                            }}
                        >
                            {state.multiActionAccess === 'yes' ||
                            reduceNodes(treeData.tree.children[0].children[0]).filter(
                                (node: NodeT) => node.kind == 'Action'
                            ).length == 0 ? (
                                <>
                                    <div className="line_hover_dropdown_li_bg ">
                                        <FlowCanvasActionMenu.AddAction />
                                    </div>
                                    <span>Add Action</span>
                                </>
                            ) : (
                                <>
                                    <div
                                        className={`line_hover_dropdown_li_bg ${
                                            state.multiActionAccess == 'no' ? 'opacity_li_bg' : 'opacity_li_bg_lower'
                                        }`}
                                    >
                                        <FlowCanvasActionMenu.AddAction />
                                    </div>
                                    <span
                                        className={`${
                                            state.multiActionAccess == 'no' ? 'opacity_li_bg' : 'opacity_li_bg_lower'
                                        }`}
                                    >
                                        Add Action
                                    </span>
                                    <span className="no_input_value free-plan-restriction">
                                        <div className="error_input_show">
                                            <WarningAlertIcon />
                                            <span className="error_menu_hvr">
                                                You can add only one action in Free plan. <a>Upgrade</a> to paid plan to
                                                add multiple Actions.
                                            </span>
                                        </div>
                                    </span>
                                </>
                            )}
                        </div>}

                        {getProductId() === "ASSISTANT" && !addingInsideForEach &&
                            (!props.parentNode.value.meta_data ||
                                (props.parentNode.value.meta_data &&
                                    (!props.parentNode.value.meta_data.subflow ||
                                        !props.parentNode.value.meta_data.subflow.hasSubFlow))) && (
                                        <div
                            className="line_hover_dropdown_li"
                            onClick={() => {
                                if (state.sendNotificationAccess === 'yes') {
                                props.makeDropDownHidden()

                                let newTree: TreeT<NodeT>

                                if (props.node.value.kind !== 'ParallelPath') {
                                    const currentParentNode = props.addingAsParallelPathParent
                                        ? props.parentNode
                                        : props.node
                                    const id = generateUniqueId().toString()

                                    const newNode = {
                                        kind: 'EmptyNotification',
                                        id: id,
                                        name: 'Send Notification',
                                        description: '',
                                        meta_data: {},
                                        icon: '',
                                        input: [],
                                        output: {},
                                        path: []
                                    } as NodeT

                                    const addingFlowInsideForEach = {
                                        addingInsideForEach:false,
                                        forEachNodeId:'',
                                        parentNode: props.node.value
                                    }

                                    if (!props.addingAsParallelPathParent) {
                                        newTree = addNode(
                                            newNode,
                                            currentParentNode.value,
                                            rightPanelState.parentNode.value.id !== currentParentNode.value.id
                                                ? treeData.tree
                                                : editTreeData.tree,
                                            { isErrorHandling: false, errorHandlingChilds: [] },
                                            addingFlowInsideForEach
                                        )
                                    } else {
                                        newTree = addNewNodeAsParentToParallelNode(
                                            newNode,
                                            currentParentNode.value,
                                            props.node.value,
                                            rightPanelState.parentNode.value.id !== currentParentNode.value.id
                                                ? treeData.tree
                                                : editTreeData.tree,

                                            {
                                                isErrorHandling: false,
                                                errorHandlingChilds: []
                                            },
                                            addingFlowInsideForEach
                                        )
                                    }

                                    const nextState: RightPanelStateT = produce(state, draftState => {
                                        draftState.parentNode = currentParentNode
                                        draftState.currentNode = {
                                            ...NotificationInitialState,
                                            parallelPathNode: {
                                                currentParallelPathNode: null,
                                                anotherEmptyChildren: null,
                                                replacingParallelSibling: props.addingAsParallelPathParent
                                                    ? props.node.value
                                                    : null,
                                                emptyChildrenPosition: 0
                                            },
                                            addingInsideForEach:false,
                                            subflowMetaData: {
                                                          subflow: { hasSubFlow: false }
                                                      }
                                        }
                                        draftState.show = true
                                        draftState.mode = NodeAddT
                                    })

                                    setRightPanelState(nextState)
                                } else {
                                    const currentParentNode = props.parentNode

                                    const addingFlowInsideForEach = {
                                        addingInsideForEach:false,
                                        forEachNodeId: '',
                                        parentNode: props.node.value
                                    }

                                    newTree = updateNode(
                                        { ...props.node.value, name: 'Send Notification' },
                                        editTreeData.tree,
                                        addingFlowInsideForEach
                                    )

                                    const hasOnlyParallelPaths: TreeT<NodeT>[] = props.parentNode.children
                                        .filter(z => z.value.kind === 'ParallelPath')
                                        .filter(x => x.value.id !== props.node.value.id)
                                        .filter(x => x.value.kind === 'ParallelPath')

                                    const nextState: RightPanelStateT = produce(state, draftState => {
                                        draftState.parentNode = props.parentNode

                                        draftState.currentNode = {
                                            ...NotificationInitialState,
                                            parallelPathNode: {
                                                currentParallelPathNode: props.node.value,
                                                anotherEmptyChildren:
                                                    hasOnlyParallelPaths.length > 0
                                                        ? hasOnlyParallelPaths[0].value
                                                        : null,
                                                emptyChildrenPosition: props.nodePosition,
                                                replacingParallelSibling: null
                                            },
                                            addingInsideForEach:false,
                                            subflowMetaData: {
                                                      subflow: { hasSubFlow: false }
                                                  }
                                        }

                                        draftState.show = true
                                        draftState.mode = NodeAddT
                                    })

                                    setRightPanelState(nextState)
                                }

                                const tree = editTreeData as {
                                    firstStepDone: boolean
                                    secondStepDone: boolean
                                    tree: TreeT<NodeT>
                                }

                                const updatedTree = produce(tree, draftState => {
                                    draftState.tree = newTree
                                    draftState.firstStepDone = true
                                })

                                setEditTreeData(updatedTree)
                            }
                            }}
                        >

                            {state.sendNotificationAccess == 'yes' ? (
                                        <>
                                            <div className="line_hover_dropdown_li_bg notification_icon">
                                                <FlowCanvasActionMenu.AutomationNotificationIcon />
                                            </div>
                                            <span>Send Notification</span>
                                        </>
                                    ) : (
                                        <>
                                            <div
                                                className={`line_hover_dropdown_li_bg notification_icon ${
                                                    state.sendNotificationAccess == 'no'
                                                        ? 'opacity_li_bg'
                                                        : 'opacity_li_bg_lower'
                                                }`}
                                            >
                                                <FlowCanvasActionMenu.AutomationNotificationIcon />
                                            </div>
                                            <span
                                                className={`${
                                                    state.sendNotificationAccess == 'no'
                                                        ? 'opacity_li_bg'
                                                        : 'opacity_li_bg_lower'
                                                }`}
                                            >
                                                Send Notification
                                            </span>
                                            {state.sendNotificationAccess == 'no' ? (
                                                <span className="no_input_value free-plan-restriction">
                                                    <div className="error_input_show">
                                                        <WarningAlertIcon />
                                                        <span className="error_menu_hvr">
                                                            You can add send notification only in Professional plan. Please upgrade.
                                                        </span>
                                                    </div>
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                        </div>
                        )}

                        {props.node.value.kind !== 'ParallelPath' &&
                            !props.addingAsParallelPathParent &&
                            // to restrict only children as a parallel path
                            props.parentNode.children.length < 5 && <ParallelPathButton />}

                        <div
                            className="line_hover_dropdown_li"
                            onClick={() => {
                                props.makeDropDownHidden()

                                let newTree: TreeT<NodeT>

                                if (props.node.value.kind !== 'ParallelPath') {
                                    const currentParentNode = props.addingAsParallelPathParent
                                        ? props.parentNode
                                        : props.node

                                    const newNode = {
                                        kind: 'EmptyCondition',
                                        id: '',
                                        name: 'New Condition',
                                        description: '',
                                        meta_data: {},
                                        icon: '',
                                        input: [],
                                        output: {},
                                        path: []
                                    } as NodeT

                                    const addingFlowInsideForEach = {
                                        addingInsideForEach:
                                            addingInsideForEach ||
                                            (currentParentNode.value.meta_data &&
                                                currentParentNode.value.meta_data.subflow &&
                                                currentParentNode.value.meta_data.subflow.hasSubFlow),
                                        forEachNodeId:
                                            addingInsideForEach && currentParentNode.value.kind == 'ForEach'
                                                ? currentParentNode.value.id
                                                : currentParentNode.value.meta_data &&
                                                  currentParentNode.value.meta_data.subflow
                                                ? currentParentNode.value.meta_data.subflow.forEachNodeId
                                                : '',
                                        parentNode: addingInsideForEach ? currentParentNode.value : props.node.value
                                    }

                                    if (!props.addingAsParallelPathParent) {
                                        newTree = addNode(
                                            newNode,
                                            currentParentNode.value,
                                            rightPanelState.parentNode.value.id !== currentParentNode.value.id
                                                ? treeData.tree
                                                : editTreeData.tree,
                                            { isErrorHandling: false, errorHandlingChilds: [] },
                                            addingFlowInsideForEach
                                        )
                                    } else {
                                        newTree = addNewNodeAsParentToParallelNode(
                                            newNode,
                                            currentParentNode.value,
                                            props.node.value,
                                            rightPanelState.parentNode.value.id !== currentParentNode.value.id
                                                ? treeData.tree
                                                : editTreeData.tree,

                                            {
                                                isErrorHandling: false,
                                                errorHandlingChilds: []
                                            },
                                            addingFlowInsideForEach
                                        )
                                    }

                                    const nextState: RightPanelStateT = produce(state, draftState => {
                                        draftState.parentNode = currentParentNode
                                        draftState.currentNode = {
                                            ...ConditionInitialState,
                                            parallelPathNode: {
                                                currentParallelPathNode: null,
                                                anotherEmptyChildren: null,
                                                replacingParallelSibling: props.addingAsParallelPathParent
                                                    ? props.node.value
                                                    : null,
                                                emptyChildrenPosition: 0
                                            },
                                            addingInsideForEach:
                                                addingInsideForEach ||
                                                (currentParentNode.value.meta_data &&
                                                    currentParentNode.value.meta_data.subflow &&
                                                    currentParentNode.value.meta_data.subflow.hasSubFlow),
                                            subflowMetaData:
                                                addingInsideForEach ||
                                                (currentParentNode.value.meta_data &&
                                                    currentParentNode.value.meta_data.subflow &&
                                                    currentParentNode.value.meta_data.subflow.hasSubFlow)
                                                    ? {
                                                          subflow: {
                                                              hasSubFlow: true,
                                                              forEachNodeId: addingInsideForEach
                                                                  ? currentParentNode.value.id
                                                                  : currentParentNode.value.meta_data.subflow
                                                                        .forEachNodeId,
                                                              parentId: currentParentNode.value.id
                                                          }
                                                      }
                                                    : {
                                                          subflow: { hasSubFlow: false }
                                                      }
                                        }
                                        draftState.show = true
                                        draftState.mode = NodeAddT
                                    })

                                    setRightPanelState(nextState)
                                } else {
                                    const currentParentNode = props.parentNode

                                    const addingFlowInsideForEach = {
                                        addingInsideForEach:
                                            addingInsideForEach ||
                                            (currentParentNode.value.meta_data &&
                                                currentParentNode.value.meta_data.subflow &&
                                                currentParentNode.value.meta_data.subflow.hasSubFlow),
                                        forEachNodeId: addingInsideForEach
                                            ? props.node.value.meta_data.subflow.forEachNodeId
                                            : currentParentNode.value.meta_data &&
                                              currentParentNode.value.meta_data.subflow
                                            ? currentParentNode.value.meta_data.subflow.forEachNodeId
                                            : '',
                                        parentNode: addingInsideForEach ? currentParentNode.value : props.node.value
                                    }

                                    newTree = updateNode(
                                        { ...props.node.value, name: 'New Condition' },
                                        editTreeData.tree,
                                        addingFlowInsideForEach
                                    )

                                    const hasOnlyParallelPaths: TreeT<NodeT>[] = props.parentNode.children
                                        .filter(z => z.value.kind === 'ParallelPath')
                                        .filter(x => x.value.id !== props.node.value.id)
                                        .filter(x => x.value.kind === 'ParallelPath')

                                    const nextState: RightPanelStateT = produce(state, draftState => {
                                        draftState.parentNode = props.parentNode

                                        draftState.currentNode = {
                                            ...ConditionInitialState,
                                            parallelPathNode: {
                                                currentParallelPathNode: props.node.value,
                                                anotherEmptyChildren:
                                                    hasOnlyParallelPaths.length > 0
                                                        ? hasOnlyParallelPaths[0].value
                                                        : null,
                                                emptyChildrenPosition: props.nodePosition,
                                                replacingParallelSibling: null
                                            },
                                            addingInsideForEach:
                                                addingInsideForEach ||
                                                (currentParentNode.value.meta_data &&
                                                    currentParentNode.value.meta_data.subflow &&
                                                    currentParentNode.value.meta_data.subflow.hasSubFlow),
                                            subflowMetaData: addingInsideForEach
                                                ? {
                                                      subflow: {
                                                          hasSubFlow: true,
                                                          forEachNodeId:
                                                              props.node.value.meta_data.subflow.forEachNodeId,
                                                          parentId: currentParentNode.value.id
                                                      }
                                                  }
                                                : {
                                                      subflow: { hasSubFlow: false }
                                                  }
                                        }

                                        draftState.show = true
                                        draftState.mode = NodeAddT
                                    })

                                    setRightPanelState(nextState)
                                }

                                const tree = editTreeData as {
                                    firstStepDone: boolean
                                    secondStepDone: boolean
                                    tree: TreeT<NodeT>
                                }

                                const updatedTree = produce(tree, draftState => {
                                    draftState.tree = newTree
                                    draftState.firstStepDone = true
                                })

                                setEditTreeData(updatedTree)
                            }}
                        >
                            <div className="line_hover_dropdown_li_bg ">
                                <FlowCanvasActionMenu.AddCondition />
                            </div>
                            <span>Add Condition</span>
                        </div>
                        <div
                            className="line_hover_dropdown_li"
                            onClick={() => {
                                props.makeDropDownHidden()

                                let newTree: TreeT<NodeT>

                                if (props.node.value.kind !== 'ParallelPath') {
                                    const currentParentNode = props.addingAsParallelPathParent
                                        ? props.parentNode
                                        : props.node

                                    const addingFlowInsideForEach = {
                                        addingInsideForEach:
                                            addingInsideForEach ||
                                            (currentParentNode.value.meta_data &&
                                                currentParentNode.value.meta_data.subflow &&
                                                currentParentNode.value.meta_data.subflow.hasSubFlow),
                                        forEachNodeId:
                                            addingInsideForEach && currentParentNode.value.kind == 'ForEach'
                                                ? currentParentNode.value.id
                                                : currentParentNode.value.meta_data &&
                                                  currentParentNode.value.meta_data.subflow
                                                ? currentParentNode.value.meta_data.subflow.forEachNodeId
                                                : '',
                                        parentNode: addingInsideForEach ? currentParentNode.value : props.node.value
                                    }

                                    const id = generateUniqueId().toString()
                                    const newNode = {
                                        kind: 'EmptyFunction',
                                        id: id,
                                        name: 'New function',
                                        description: '',
                                        meta_data: {
                                            authentication_id: ''
                                        },
                                        icon: '',
                                        input: [],
                                        output: {},
                                        path: []
                                    } as NodeT

                                    if (!props.addingAsParallelPathParent) {
                                        newTree = addNode(
                                            newNode,
                                            currentParentNode.value,
                                            rightPanelState.parentNode.value.id !== currentParentNode.value.id
                                                ? treeData.tree
                                                : editTreeData.tree,
                                            { isErrorHandling: false, errorHandlingChilds: [] },
                                            addingFlowInsideForEach
                                        )
                                    } else {
                                        newTree = addNewNodeAsParentToParallelNode(
                                            newNode,
                                            currentParentNode.value,
                                            props.node.value,
                                            rightPanelState.parentNode.value.id !== currentParentNode.value.id
                                                ? treeData.tree
                                                : editTreeData.tree,
                                            {
                                                isErrorHandling: false,
                                                errorHandlingChilds: []
                                            },
                                            addingFlowInsideForEach
                                        )
                                    }

                                    const nextState: RightPanelStateT = produce(state, draftState => {
                                        draftState.parentNode = currentParentNode
                                        draftState.currentNode = {
                                            ...FunctionInitialState,
                                            parallelPathNode: {
                                                currentParallelPathNode: null,
                                                anotherEmptyChildren: null,
                                                replacingParallelSibling: props.addingAsParallelPathParent
                                                    ? props.node.value
                                                    : null,
                                                emptyChildrenPosition: 0
                                            },
                                            addingInsideForEach:
                                                addingInsideForEach ||
                                                (currentParentNode.value.meta_data &&
                                                    currentParentNode.value.meta_data.subflow &&
                                                    currentParentNode.value.meta_data.subflow.hasSubFlow),
                                            subflowMetaData:
                                                addingInsideForEach ||
                                                (currentParentNode.value.meta_data &&
                                                    currentParentNode.value.meta_data.subflow &&
                                                    currentParentNode.value.meta_data.subflow.hasSubFlow)
                                                    ? {
                                                          subflow: {
                                                              hasSubFlow: true,
                                                              forEachNodeId: addingInsideForEach
                                                                  ? currentParentNode.value.id
                                                                  : currentParentNode.value.meta_data.subflow
                                                                        .forEachNodeId,
                                                              parentId: currentParentNode.value.id
                                                          }
                                                      }
                                                    : {
                                                          subflow: { hasSubFlow: false }
                                                      }
                                        }
                                        draftState.show = true
                                        draftState.mode = NodeAddT
                                    })

                                    setRightPanelState(nextState)
                                } else {
                                    const currentParentNode = props.parentNode

                                    const addingFlowInsideForEach = {
                                        addingInsideForEach:
                                            addingInsideForEach ||
                                            (currentParentNode.value.meta_data &&
                                                currentParentNode.value.meta_data.subflow &&
                                                currentParentNode.value.meta_data.subflow.hasSubFlow),
                                        forEachNodeId: addingInsideForEach
                                            ? props.node.value.meta_data.subflow.forEachNodeId
                                            : currentParentNode.value.meta_data &&
                                              currentParentNode.value.meta_data.subflow
                                            ? currentParentNode.value.meta_data.subflow.forEachNodeId
                                            : '',
                                        parentNode: addingInsideForEach ? currentParentNode.value : props.node.value
                                    }

                                    newTree = updateNode(
                                        { ...props.node.value, name: 'New function' },
                                        editTreeData.tree,
                                        addingFlowInsideForEach
                                    )

                                    const hasOnlyParallelPaths: TreeT<NodeT>[] = props.parentNode.children
                                        .filter(z => z.value.kind === 'ParallelPath')
                                        .filter(x => x.value.id !== props.node.value.id)
                                        .filter(x => x.value.kind === 'ParallelPath')

                                    const nextState: RightPanelStateT = produce(state, draftState => {
                                        draftState.parentNode = props.parentNode

                                        draftState.currentNode = {
                                            ...FunctionInitialState,
                                            parallelPathNode: {
                                                currentParallelPathNode: props.node.value,
                                                anotherEmptyChildren:
                                                    hasOnlyParallelPaths.length > 0
                                                        ? hasOnlyParallelPaths[0].value
                                                        : null,
                                                emptyChildrenPosition: props.nodePosition,
                                                replacingParallelSibling: null
                                            },
                                            addingInsideForEach:
                                                addingInsideForEach ||
                                                (currentParentNode.value.meta_data &&
                                                    currentParentNode.value.meta_data.subflow &&
                                                    currentParentNode.value.meta_data.subflow.hasSubFlow),
                                            subflowMetaData: addingInsideForEach
                                                ? {
                                                      subflow: {
                                                          hasSubFlow: true,
                                                          forEachNodeId:
                                                              props.node.value.meta_data.subflow.forEachNodeId,
                                                          parentId: currentParentNode.value.id
                                                      }
                                                  }
                                                : {
                                                      subflow: { hasSubFlow: false }
                                                  }
                                        }

                                        draftState.show = true
                                        draftState.mode = NodeAddT
                                    })

                                    setRightPanelState(nextState)
                                }

                                const tree = editTreeData as {
                                    firstStepDone: boolean
                                    secondStepDone: boolean
                                    tree: TreeT<NodeT>
                                }

                                const updatedTree = produce(tree, draftState => {
                                    draftState.tree = newTree
                                    draftState.firstStepDone = true
                                })

                                setEditTreeData(updatedTree)
                            }}
                        >
                            <div className="line_hover_dropdown_li_bg ">
                                <FlowCanvasActionMenu.FunctionIcon />
                            </div>
                            <span>Add Function</span>
                        </div>

                        {!addingInsideForEach &&
                            (!props.parentNode.value.meta_data ||
                                (props.parentNode.value.meta_data &&
                                    (!props.parentNode.value.meta_data.subflow ||
                                        !props.parentNode.value.meta_data.subflow.hasSubFlow))) && (
                                <div
                                    className="line_hover_dropdown_li"
                                    onClick={() => {
                                        if (state.approvalAccess === 'yes') {
                                            props.makeDropDownHidden()

                                            let newTree: TreeT<NodeT>

                                            if (props.node.value.kind !== 'ParallelPath') {
                                                const currentParentNode = props.addingAsParallelPathParent
                                                    ? props.parentNode
                                                    : props.node

                                                const newNode = {
                                                    kind: 'EmptyApproval',
                                                    id: '',
                                                    name: 'New approval',
                                                    description: '',
                                                    meta_data: {},
                                                    icon: '',
                                                    input: [],
                                                    output: {},
                                                    path: []
                                                } as NodeT

                                                const addingFlowInsideForEach = {
                                                    addingInsideForEach:
                                                        addingInsideForEach ||
                                                        (currentParentNode.value.meta_data &&
                                                            currentParentNode.value.meta_data.subflow &&
                                                            currentParentNode.value.meta_data.subflow.hasSubFlow),
                                                    forEachNodeId:
                                                        addingInsideForEach && currentParentNode.value.kind == 'ForEach'
                                                            ? currentParentNode.value.id
                                                            : currentParentNode.value.meta_data &&
                                                              currentParentNode.value.meta_data.subflow
                                                            ? currentParentNode.value.meta_data.subflow.forEachNodeId
                                                            : '',
                                                    parentNode: addingInsideForEach
                                                        ? currentParentNode.value
                                                        : props.node.value
                                                }

                                                if (!props.addingAsParallelPathParent) {
                                                    newTree = addNode(
                                                        newNode,
                                                        currentParentNode.value,
                                                        rightPanelState.parentNode.value.id !==
                                                            currentParentNode.value.id
                                                            ? treeData.tree
                                                            : editTreeData.tree,
                                                        { isErrorHandling: false, errorHandlingChilds: [] },
                                                        addingFlowInsideForEach
                                                    )
                                                } else {
                                                    newTree = addNewNodeAsParentToParallelNode(
                                                        newNode,
                                                        currentParentNode.value,
                                                        props.node.value,
                                                        rightPanelState.parentNode.value.id !==
                                                            currentParentNode.value.id
                                                            ? treeData.tree
                                                            : editTreeData.tree,

                                                        {
                                                            isErrorHandling: false,
                                                            errorHandlingChilds: []
                                                        },
                                                        addingFlowInsideForEach
                                                    )
                                                }

                                                const nextState: RightPanelStateT = produce(state, draftState => {
                                                    draftState.parentNode = currentParentNode
                                                    draftState.currentNode = {
                                                        ...ApprovalInitialState,
                                                        parallelPathNode: {
                                                            currentParallelPathNode: null,
                                                            anotherEmptyChildren: null,
                                                            replacingParallelSibling: props.addingAsParallelPathParent
                                                                ? props.node.value
                                                                : null,
                                                            emptyChildrenPosition: 0
                                                        },
                                                        addingInsideForEach:
                                                            addingInsideForEach ||
                                                            (currentParentNode.value.meta_data &&
                                                                currentParentNode.value.meta_data.subflow &&
                                                                currentParentNode.value.meta_data.subflow.hasSubFlow),
                                                        subflowMetaData:
                                                            addingInsideForEach ||
                                                            (currentParentNode.value.meta_data &&
                                                                currentParentNode.value.meta_data.subflow &&
                                                                currentParentNode.value.meta_data.subflow.hasSubFlow)
                                                                ? {
                                                                      subflow: {
                                                                          hasSubFlow: true,
                                                                          forEachNodeId: addingInsideForEach
                                                                              ? currentParentNode.value.id
                                                                              : currentParentNode.value.meta_data
                                                                                    .subflow.forEachNodeId,
                                                                          parentId: currentParentNode.value.id
                                                                      }
                                                                  }
                                                                : {
                                                                      subflow: { hasSubFlow: false }
                                                                  }
                                                    }
                                                    draftState.show = true
                                                    draftState.mode = NodeAddT
                                                })

                                                setRightPanelState(nextState)
                                            } else {
                                                const currentParentNode = props.parentNode

                                                const addingFlowInsideForEach = {
                                                    addingInsideForEach:
                                                        addingInsideForEach ||
                                                        (currentParentNode.value.meta_data &&
                                                            currentParentNode.value.meta_data.subflow &&
                                                            currentParentNode.value.meta_data.subflow.hasSubFlow),
                                                    forEachNodeId: addingInsideForEach
                                                        ? props.node.value.meta_data.subflow.forEachNodeId
                                                        : currentParentNode.value.meta_data &&
                                                          currentParentNode.value.meta_data.subflow
                                                        ? currentParentNode.value.meta_data.subflow.forEachNodeId
                                                        : '',
                                                    parentNode: addingInsideForEach
                                                        ? currentParentNode.value
                                                        : props.node.value
                                                }

                                                newTree = updateNode(
                                                    { ...props.node.value, name: 'New Approval' },
                                                    editTreeData.tree,
                                                    addingFlowInsideForEach
                                                )

                                                const hasOnlyParallelPaths: TreeT<NodeT>[] = props.parentNode.children
                                                    .filter(z => z.value.kind === 'ParallelPath')
                                                    .filter(x => x.value.id !== props.node.value.id)
                                                    .filter(x => x.value.kind === 'ParallelPath')

                                                const nextState: RightPanelStateT = produce(state, draftState => {
                                                    draftState.parentNode = props.parentNode

                                                    draftState.currentNode = {
                                                        ...ApprovalInitialState,
                                                        parallelPathNode: {
                                                            currentParallelPathNode: props.node.value,
                                                            anotherEmptyChildren:
                                                                hasOnlyParallelPaths.length > 0
                                                                    ? hasOnlyParallelPaths[0].value
                                                                    : null,
                                                            emptyChildrenPosition: props.nodePosition,
                                                            replacingParallelSibling: null
                                                        },
                                                        addingInsideForEach:
                                                            addingInsideForEach ||
                                                            (currentParentNode.value.meta_data &&
                                                                currentParentNode.value.meta_data.subflow &&
                                                                currentParentNode.value.meta_data.subflow.hasSubFlow),
                                                        subflowMetaData: addingInsideForEach
                                                            ? {
                                                                  subflow: {
                                                                      hasSubFlow: true,
                                                                      forEachNodeId:
                                                                          props.node.value.meta_data.subflow
                                                                              .forEachNodeId,
                                                                      parentId: currentParentNode.value.id
                                                                  }
                                                              }
                                                            : {
                                                                  subflow: { hasSubFlow: false }
                                                              }
                                                    }

                                                    draftState.show = true
                                                    draftState.mode = NodeAddT
                                                })

                                                setRightPanelState(nextState)
                                            }

                                            const tree = editTreeData as {
                                                firstStepDone: boolean
                                                secondStepDone: boolean
                                                tree: TreeT<NodeT>
                                            }

                                            const updatedTree = produce(tree, draftState => {
                                                draftState.tree = newTree
                                                draftState.firstStepDone = true
                                            })

                                            setEditTreeData(updatedTree)
                                        }
                                    }}
                                >
                                    {state.approvalAccess == 'yes' ? (
                                        <>
                                            <div className="line_hover_dropdown_li_bg ">
                                                <FlowCanvasActionMenu.ApprovalIcon />
                                            </div>
                                            <span>Add Approval</span>
                                        </>
                                    ) : (
                                        <>
                                            <div
                                                className={`line_hover_dropdown_li_bg ${
                                                    state.approvalAccess == 'no'
                                                        ? 'opacity_li_bg'
                                                        : 'opacity_li_bg_lower'
                                                }`}
                                            >
                                                <FlowCanvasActionMenu.ApprovalIcon />
                                            </div>
                                            <span
                                                className={`${
                                                    state.approvalAccess == 'no'
                                                        ? 'opacity_li_bg'
                                                        : 'opacity_li_bg_lower'
                                                }`}
                                            >
                                                Add Approval
                                            </span>
                                            {state.approvalAccess == 'no' ? (
                                                <span className="no_input_value free-plan-restriction">
                                                    <div className="error_input_show">
                                                        <WarningAlertIcon />
                                                        <span className="error_menu_hvr">
                                                            You can add approval only in Professional plan. Please upgrade.
                                                        </span>
                                                    </div>
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </div>
                            )}

                        {!addingInsideForEach &&
                            (!props.parentNode.value.meta_data ||
                                (props.parentNode.value.meta_data &&
                                    (!props.parentNode.value.meta_data.subflow ||
                                        !props.parentNode.value.meta_data.subflow.hasSubFlow))) && (
                                <div
                                    className="line_hover_dropdown_li"
                                    onClick={() => {
                                        props.makeDropDownHidden()

                                        let newTree: TreeT<NodeT>

                                        if (props.node.value.kind !== 'ParallelPath') {
                                            const currentParentNode = props.addingAsParallelPathParent
                                                ? props.parentNode
                                                : props.node

                                            const id = generateUniqueId().toString()
                                            const newNode = {
                                                kind: 'EmptyForEach',
                                                id: id,
                                                name: 'New ForEach',
                                                description: '',
                                                meta_data: {},
                                                icon: '',
                                                input: [],
                                                output: {},
                                                path: []
                                            } as NodeT

                                            if (!props.addingAsParallelPathParent) {
                                                newTree = addNode(
                                                    newNode,
                                                    currentParentNode.value,
                                                    rightPanelState.parentNode.value.id !== currentParentNode.value.id
                                                        ? treeData.tree
                                                        : editTreeData.tree,
                                                    { isErrorHandling: false, errorHandlingChilds: [] }
                                                )
                                            } else {
                                                newTree = addNewNodeAsParentToParallelNode(
                                                    newNode,
                                                    currentParentNode.value,
                                                    props.node.value,
                                                    rightPanelState.parentNode.value.id !== currentParentNode.value.id
                                                        ? treeData.tree
                                                        : editTreeData.tree,
                                                    {
                                                        isErrorHandling: false,
                                                        errorHandlingChilds: []
                                                    }
                                                )
                                            }

                                            const nextState: RightPanelStateT = produce(state, draftState => {
                                                draftState.parentNode = currentParentNode
                                                draftState.currentNode = {
                                                    ...ForEachInitialState,
                                                    parallelPathNode: {
                                                        currentParallelPathNode: null,
                                                        anotherEmptyChildren: null,
                                                        replacingParallelSibling: props.addingAsParallelPathParent
                                                            ? props.node.value
                                                            : null,
                                                        emptyChildrenPosition: 0
                                                    }
                                                }
                                                draftState.show = true
                                                draftState.mode = NodeAddT
                                            })

                                            setRightPanelState(nextState)
                                        } else {
                                            newTree = updateNode(
                                                { ...props.node.value, name: 'New ForEach' },
                                                editTreeData.tree
                                            )

                                            const hasOnlyParallelPaths: TreeT<NodeT>[] = props.parentNode.children
                                                .filter(z => z.value.kind === 'ParallelPath')
                                                .filter(x => x.value.id !== props.node.value.id)
                                                .filter(x => x.value.kind === 'ParallelPath')

                                            const nextState: RightPanelStateT = produce(state, draftState => {
                                                draftState.parentNode = props.parentNode

                                                draftState.currentNode = {
                                                    ...ForEachInitialState,
                                                    parallelPathNode: {
                                                        currentParallelPathNode: props.node.value,
                                                        anotherEmptyChildren:
                                                            hasOnlyParallelPaths.length > 0
                                                                ? hasOnlyParallelPaths[0].value
                                                                : null,
                                                        emptyChildrenPosition: props.nodePosition,
                                                        replacingParallelSibling: null
                                                    }
                                                }

                                                draftState.show = true
                                                draftState.mode = NodeAddT
                                            })

                                            setRightPanelState(nextState)
                                        }

                                        const tree = editTreeData as {
                                            firstStepDone: boolean
                                            secondStepDone: boolean
                                            tree: TreeT<NodeT>
                                        }

                                        const updatedTree = produce(tree, draftState => {
                                            draftState.tree = newTree
                                            draftState.firstStepDone = true
                                        })

                                        setEditTreeData(updatedTree)
                                    }}
                                >
                                    <div className="line_hover_dropdown_li_bg ">
                                        <FlowCanvasActionMenu.ForEachIcon />
                                    </div>
                                    <span>Add For Each</span>
                                </div>
                            )}
                    </div>
                )}
            </Downshift>
        </div>
    )

    function ParallelPathButton() {
        return (
            <div
                className="line_hover_dropdown_li"
                onClick={() => {
                    const nextState: RightPanelStateT = produce(state, draftState => {
                        draftState.parentNode = props.parentNode
                        draftState.currentNode = ActionInitialState
                        draftState.mode = NodeAddT
                        draftState.show = false
                    })

                    props.makeDropDownHidden()

                    const currentParentNode = props.addingAsParallelPathParent ? props.parentNode : props.node

                    const addingFlowInsideForEach = {
                        addingInsideForEach:
                            addingInsideForEach ||
                            (currentParentNode.value.meta_data &&
                                currentParentNode.value.meta_data.subflow &&
                                currentParentNode.value.meta_data.subflow.hasSubFlow),
                        forEachNodeId:
                            addingInsideForEach && currentParentNode.value.kind == 'ForEach'
                                ? currentParentNode.value.id
                                : currentParentNode.value.meta_data && currentParentNode.value.meta_data.subflow
                                ? currentParentNode.value.meta_data.subflow.forEachNodeId
                                : '',
                        parentNode: addingInsideForEach ? currentParentNode.value : props.node.value
                    }

                    const newNode = {
                        kind: 'ParallelPath',
                        id: '',
                        name: '',
                        description: '',
                        meta_data:
                            addingInsideForEach ||
                            (currentParentNode.value.meta_data &&
                                currentParentNode.value.meta_data.subflow &&
                                currentParentNode.value.meta_data.subflow.hasSubFlow)
                                ? {
                                      authentication_id: '',
                                      subflow: {
                                          hasSubFlow: true,
                                          forEachNodeId: addingInsideForEach
                                              ? currentParentNode.value.id
                                              : currentParentNode.value.meta_data.subflow.forEachNodeId,
                                          parentId: currentParentNode.value.id
                                      }
                                  }
                                : {
                                      authentication_id: '',
                                      subflow: { hasSubFlow: false }
                                  },
                        icon: '',
                        input: [] as InputFieldT[],
                        output: {},
                        path: [] as {
                            actionId: string
                            nodeId: string
                        }[]
                    } as NodeT

                    const newTree = addParallelPath(
                        newNode,
                        currentParentNode.value,
                        rightPanelState.parentNode.value.id !== currentParentNode.value.id
                            ? treeData.tree
                            : editTreeData.tree,
                        addingFlowInsideForEach
                    )

                    const tree = editTreeData as {
                        firstStepDone: boolean
                        secondStepDone: boolean
                        tree: TreeT<NodeT>
                    }

                    const updatedTree = produce(tree, draftState => {
                        draftState.tree = newTree
                        draftState.firstStepDone = true
                    })

                    setRightPanelState(nextState)

                    setEditTreeData(updatedTree)
                }}
            >
                <div className="line_hover_dropdown_li_bg ">
                    <FlowCanvasActionMenu.AddParallel />
                </div>
                <span>Add Parallel Path</span>
            </div>
        )
    }
}

Tree.Node.ActionMenu = function ActionMenu(props: any) {
    return (
        <div className="line_hover_dropdown">
            <div className="line_hover_dropdown_ul">
                <div className="line_hover_dropdown_li">
                    <div className="line_hover_dropdown_li_bg ">
                        <FlowCanvasActionMenu.AddAction />
                    </div>
                    <span>Add an Action</span>
                </div>
                <div className="line_hover_dropdown_li">
                    <div className="line_hover_dropdown_li_bg ">
                        <FlowCanvasActionMenu.AddCondition />
                    </div>
                    <span>Add a Condition</span>
                </div>
                <div className="line_hover_dropdown_li">
                    <div className="line_hover_dropdown_li_bg ">
                        <FlowCanvasActionMenu.CallAutomation />
                    </div>
                    <span>Call an Automation</span>
                </div>
                <div className="line_hover_dropdown_li">
                    <div className="line_hover_dropdown_li_bg ">
                        <FlowCanvasActionMenu.AddRepeat />
                    </div>
                    <span>Add Repeat</span>
                </div>
            </div>
        </div>
    )
}
