import React, { useState } from 'react'

import { ConditionExecuteWrapper } from '@/DialogEditor/Canvas/Components/Nodes/FaqPopup'
import { CloseBtnWithCircle, CloseIcon, DropdownWithCircle, NodeHoverMenuIcons } from '@/common/Icons/Icons'
import {
    CancelButton,
    DropdownLi,
    DropdownUl,
    DropdownUlWrapper,
    Input,
    InputContainer,
    InputContent
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb'

import { DropDown } from '../../../Common/Effective'
import { ExpressionTypeE, converterToEditor } from '../../../Editor/Action'
import ContextCondition from './ContextCondition'
import EntityCompoenent from './EntityCompoenent'
import IntentCompoenent from './IntentCompoenent'
import { conditionT, conditionTYPES, operatorsT } from './kind'

const ConditionalStatements = ({
    condition,
    conditions,
    onChange,
    remove,
    hideTititle,
    conditionEdit,
    index,
    showUserQuery,
    showFaq,
    callbackWhenChooserisOpen
}: {
    condition: conditionT
    conditions: conditionT[]
    onChange: Function
    remove: Function
    index?: number
    conditionEdit?: any
    hideTititle?: boolean
    showUserQuery?: boolean
    showFaq?: boolean,
    callbackWhenChooserisOpen? : () => void
}) => {
    const [chooser, setChooser] = useState<boolean>(false)

    if(chooser === true) {
        callbackWhenChooserisOpen && callbackWhenChooserisOpen()
    }

    return (
        <div className="test_buton_result">
            <ConditionExecuteWrapper className="test_buton_view">
                {!hideTititle && (
                    <div className="automation_form_req automation_form_req_step_two">
                        <div className="automation_condition_input">
                            <h4>{index != undefined && index + 1}. Condition</h4>
                            {conditions.length > 1 ? (
                                <span className="automation_condition_span" onClick={e => remove()}>
                                    {/* <NodeHoverMenuIcons.DeleteIcon /> */}
                                    <span>
                                        <CloseIcon />
                                    </span>
                                </span>
                            ) : (
                                <span />
                            )}
                        </div>
                    </div>
                )}

                <div className="automation_condition_form recognise_content_input">
                    <div className="select_work_form">
                        <InputContainer className="mb-0">
                            <Input
                                type="text"
                                value={condition.type == conditionTYPES.SELECT ? '' : condition.type}
                                onChange={(e: any) => {}}
                                onClick={(e: any) => setChooser(true)}
                            />
                            <InputContent>If chatbot recognizes</InputContent>

                            <CancelButton onClick={(e: any) => setChooser(true)}>
                                <DropdownWithCircle />
                            </CancelButton>
                        </InputContainer>
                        {chooser ? (
                            <DropdownUlWrapper>
                                <DropDown isOpen={chooser} onOuterClick={() => setChooser(false)}>
                                    <div className="automation_scroly mb-0" style={{ top: 2, position: 'relative' }}>
                                        <DropdownUl relative>
                                            {showUserQuery ? (
                                                showFaq && (
                                                    <DropdownLi
                                                        onClick={(e: any) => {
                                                            setChooser(false)
                                                            onChange({
                                                                type: conditionTYPES.FAQ,
                                                                queries: ['']
                                                            })
                                                        }}
                                                    >
                                                        User Queries
                                                    </DropdownLi>
                                                )
                                            ) : (
                                                <DropdownLi
                                                    onClick={(e: any) => {
                                                        setChooser(false)
                                                        onChange({
                                                            type: conditionTYPES.INTENT,
                                                            name: '',
                                                            operator: operatorsT.SELECT
                                                        })
                                                    }}
                                                >
                                                    Intent
                                                </DropdownLi>
                                            )}
                                            <DropdownLi
                                                onClick={(e: any) => {
                                                    setChooser(false)
                                                    onChange({
                                                        type: conditionTYPES.ENTITY,
                                                        name: '',
                                                        operator: operatorsT.SELECT,
                                                        value: ''
                                                    })
                                                }}
                                            >
                                                Entity
                                            </DropdownLi>
                                            <DropdownLi
                                                onClick={(e: any) => {
                                                    setChooser(false)
                                                    onChange({
                                                        type: conditionTYPES.CONTEXT,
                                                        LHS: {},
                                                        operator: operatorsT.SELECT,
                                                        RHS: converterToEditor({
                                                            type: ExpressionTypeE.TEXT,
                                                            value: ''
                                                        })
                                                    })
                                                }}
                                            >
                                                Variable
                                            </DropdownLi>
                                        </DropdownUl>
                                    </div>
                                </DropDown>
                            </DropdownUlWrapper>
                        ) : null}

                        {/* {condition.type == conditionTYPES.INTENT && (
                            <p>Select a pre-existing Intent or create a new Intent</p>
                        )} */}
                        {/* {condition.type == conditionTYPES.SELECT && <p>Select the type to setup the condition</p>} */}
                        {/* {condition.type == conditionTYPES.ENTITY && <p>Select a pre-existing Entity</p>} */}
                        {/* {condition.type == conditionTYPES.CONTEXT && (
                            <p>Select the variable to setup the condition</p>
                        )}{' '} */}
                    </div>
                </div>
                {condition.type == conditionTYPES.CONTEXT ? (
                    <ContextCondition condition={condition} onChange={onChange} />
                ) : null}
                {condition.type == conditionTYPES.INTENT ? (
                    <IntentCompoenent condition={condition} onChange={onChange} conditionEdit={conditionEdit} />
                ) : null}
                {condition.type == conditionTYPES.ENTITY ? (
                    <EntityCompoenent condition={condition} onChange={onChange} conditionEdit={conditionEdit} />
                ) : null}
            </ConditionExecuteWrapper>
        </div>
    )
}

export default ConditionalStatements
