import { EditorState, RawDraftContentBlock, convertToRaw } from 'draft-js'
import produce from 'immer'
/*eslint-disable*/
import React, { useContext } from 'react'
import styled from 'styled-components/macro'

import { AddIcon, CloseIconWithCircle } from '@/common/Icons/Icons'
import { Input, InputContainer, InputContent, SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { DraftInputWrapper } from '@/common/styled/DraftJs.Dumb'

import { ActionPanelStateT } from './ActionPanel'
import { TriggerPanelStateT } from './AppTriggerPanel'
import { SidePanelContext } from './Canvas'
import { FunctionPanelStateT } from './FunctionPopup'
import { Editor } from './InputandVariable'
import {
    ActionsResponseT,
    ContextPopupStateT,
    FunctionResponseT,
    InputDictionary,
    InputFieldT,
    Nodes,
    dictionaryFieldT
} from './types'
import { convertAsaSingleString, emptyEditorState, updateValueUsingLens } from './utils'

const InputAndEditorWrapper = styled.div`
    width: 100%;
    float: left;
    position: relative;
    top: -9px;

    ${DraftInputWrapper} {
        width: 100%;
        .dialog_input_edit_true.dialog_context_input_edit_true {
            width: 100%;
        }
    }
`

export function DictionaryInput(props: {
    input: InputFieldT
    state: ActionPanelStateT | FunctionPanelStateT | TriggerPanelStateT
    setPanelState: (state: ActionPanelStateT | FunctionPanelStateT) => void
    contextPopupValues: ContextPopupStateT
    setContextPopupValues: any
    forTestAction?:boolean
}) {
    const { state, setPanelState, contextPopupValues, setContextPopupValues, forTestAction } = props

    const { treeData, active_automation } = useContext(SidePanelContext)

    const actionNode = state.currentNode.nodeType == Nodes.Action || state.currentNode.nodeType == Nodes.Trigger

    const { input } = props

    const kind = input.config as InputDictionary
    console.log(kind, 'kind')
    return (
        <>
            <div className="automation_form_req automation_form_req_step_two">
                <div className="automation_global_form">
                    <div className="select_work_form">
                        {/* <h4>{input.displayTitle}</h4> */}
                        <div className="step_output_wrapper" style={{ margin: 0 }}>
                            {kind.fields &&
                                kind.fields.map((field: dictionaryFieldT, i: number) => (
                                    <div className="step_input_wrapper" key={i}>
                                        <div className="form_req_step_two_global_form" style={{ padding: 0 }}>
                                            {/* <div className="automation_global_form_edit"> */}
                                            <InputContainer>
                                                <Input
                                                    style={{ margin: 0 }}
                                                    type="text"
                                                    className="button_auto_input"
                                                    onChange={(e: any) => {
                                                        const nextValue = produce(
                                                            state.currentNode as ActionsResponseT,
                                                            draft => {
                                                                (forTestAction?draft.workflowInputsListForTest:draft.actionInputsList).output.input = (forTestAction?draft.workflowInputsListForTest:draft.actionInputsList).output.input.map(
                                                                    inp => {
                                                                        if (inp.key == input.key) {
                                                                            return {
                                                                                ...inp,
                                                                                config: {
                                                                                    ...kind,
                                                                                    fields:
                                                                                        kind.fields &&
                                                                                        kind.fields.map((f, ind) =>
                                                                                            ind == i
                                                                                                ? {
                                                                                                      ...f,
                                                                                                      key:
                                                                                                          e.target.value
                                                                                                  }
                                                                                                : f
                                                                                        )
                                                                                }
                                                                            }
                                                                        }
                                                                        return inp
                                                                    }
                                                                )
                                                            }
                                                        )

                                                        setPanelState({
                                                            ...state,
                                                            currentNode: nextValue
                                                        } as ActionPanelStateT | FunctionPanelStateT)
                                                    }}
                                                    value={field.key}
                                                />
                                                <InputContent>Field Name</InputContent>
                                            </InputContainer>
                                            {/* </div> */}
                                        </div>
                                        <InputAndEditorWrapper>
                                            <DraftInputWrapper>
                                                {forTestAction?(
                                                    <InputContainer>
                                                    <Input
                                                      type="text"
                                                      value={field.value ? (field.value as any) : ""}
                                                      // onKeyDown={(e: any) => {
                                                      //     setKeycode(e.keyCode)
                                                      //     if (e.metaKey || e.ctrlKey) setMetacode(true)
                                                      //     else setMetacode(false)
                                                      // }}
                                                      onChange={(e: any) => {
                                                        // if (keycode != 32 && metacode == false) {
                                                        //     setVariable(e.target.value.replace(/[^\w\s]/gi, ''))
                                                        // }
                                                        const nextValue = produce(
                                                          state.currentNode as ActionsResponseT,
                                                          (draftState) => {
                                                            const filteredDependencyLookup = draftState.workflowInputsListForTest.output.input.filter(
                                                              (inp) =>
                                                                inp.config.kind == 'lookup-enum' &&
                                                                inp.config.params[input.key]
                                                            );
                                                            const inputPreviousValue = field.value;
                        
                                                            draftState.workflowInputsListForTest.output.input = draftState.workflowInputsListForTest.output.input.map(
                                                              (inputs: InputFieldT) => {
                                                                if (input.path) {
                                                                  const value = updateValueUsingLens(
                                                                    input.path as string,
                                                                    inputs,
                                                                    {
                                                                      config: {
                                                                        ...kind,
                                                                        fields:
                                                                          kind.fields &&
                                                                          kind.fields.map((f, ind) =>
                                                                            ind == i
                                                                              ? {
                                                                                  ...field,
                                                                                  value: e.target.value as any,
                                                                                }
                                                                              : f
                                                                          ),
                                                                      },
                                                                      inputHasValue: true,
                                                                    }
                                                                  );
                                                                  return value;
                                                                } else if (inputs.key === input.key) {
                                                                  return {
                                                                    ...inputs,
                                                                    config: {
                                                                      ...kind,
                                                                      fields:
                                                                        kind.fields &&
                                                                        kind.fields.map((f, ind) =>
                                                                          ind == i
                                                                            ? {
                                                                                ...field,
                                                                                value: e.target.value as any,
                                                                              }
                                                                            : f
                                                                        ),
                                                                    },
                                                                    inputHasValue: true,
                                                                  };
                                                                } else if (
                                                                  filteredDependencyLookup.length > 0 &&
                                                                  filteredDependencyLookup[0].key ==
                                                                    inputs.key &&
                                                                  e.target.value != inputPreviousValue
                                                                ) {
                                                                  return {
                                                                    ...inputs,
                                                                    config: {
                                                                      ...kind,
                                                                      fields:
                                                                        kind.fields &&
                                                                        kind.fields.map((f, ind) =>
                                                                          ind == i
                                                                            ? {
                                                                                ...field,
                                                                                value: '',
                                                                              }
                                                                            : f
                                                                        ),
                                                                    },
                                                                    inputHasValue: false,
                                                                  };
                                                                }
                                                                return inputs;
                                                              }
                                                            );
                                                          }
                                                        );
                        
                                                        setPanelState({
                                                          ...state,
                                                          currentNode: nextValue,
                                                        } as ActionPanelStateT);
                                                      }}
                                                    />
                                                    <InputContent>{input.displayTitle}</InputContent>
                                                  </InputContainer>
                                                ):(
                                                <div className="select_work_form">
                                                    <Editor
                                                        infocus={contextPopupValues.currentInput === input.key}
                                                        disabled={active_automation}
                                                        focusContent="Select or Input Variable"
                                                        focus={
                                                            field.value &&
                                                            convertAsaSingleString(
                                                                convertToRaw(
                                                                    (field.value as EditorState).getCurrentContent()
                                                                ).blocks as RawDraftContentBlock[]
                                                            ).replace(/\s/g, '').length
                                                                ? 'focus_input'
                                                                : ''
                                                        }
                                                        value={
                                                            field.value
                                                                ? (field.value as any)
                                                                : EditorState.createEmpty()
                                                        }
                                                        onFocus={() => {
                                                            if (
                                                                treeData.tree.value.kind === 'AppTrigger' ||
                                                                treeData.tree.value.kind === 'EventTrigger' ||
                                                                treeData.tree.value.kind === 'WebhookTrigger' ||
                                                                (treeData.tree.value.output &&
                                                                    Object.keys(treeData.tree.value.output).length >
                                                                        0) ||
                                                                (state.parentNode.value.path &&
                                                                    state.parentNode.value.path.length > 0)
                                                            ) {
                                                                setContextPopupValues({
                                                                    ...contextPopupValues,
                                                                    show: true,
                                                                    currentInput: input.key,
                                                                    isMultiple: {
                                                                        path: input.path ? input.path : '',
                                                                        parentKey: input.path
                                                                            ? input.path.split('/')[0]
                                                                            : ''
                                                                    },
                                                                    collectionPicker: {
                                                                        ...contextPopupValues.collectionPicker,
                                                                        isCollectionPicker: false
                                                                    },
                                                                    currentInputIndex: i
                                                                })
                                                            } else {
                                                                setContextPopupValues({
                                                                    ...contextPopupValues,
                                                                    currentInput: input.key,
                                                                    isMultiple: {
                                                                        path: input.path ? input.path : '',
                                                                        parentKey: input.path
                                                                            ? input.path.split('/')[0]
                                                                            : ''
                                                                    },
                                                                    collectionPicker: {
                                                                        ...contextPopupValues.collectionPicker,
                                                                        isCollectionPicker: false
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                        setValue={(editorState: EditorState) => {
                                                            //to update state without changing other value. produce is to use instead of doing {...something}
                                                            const nextValue = actionNode
                                                                ? produce(
                                                                      state.currentNode as ActionsResponseT,
                                                                      draftState => {
                                                                          const filteredDependencyLookup = draftState.actionInputsList.output.input.filter(
                                                                              inp =>
                                                                                  inp.config.kind == 'lookup-enum' &&
                                                                                  inp.config.params[input.key]
                                                                          )
                                                                          const val = field.value as EditorState
                                                                          const inputPreviousValue = convertAsaSingleString(
                                                                              convertToRaw(val.getCurrentContent())
                                                                                  .blocks
                                                                          )

                                                                          draftState.actionInputsList.output.input = draftState.actionInputsList.output.input.map(
                                                                              (inputs: InputFieldT) => {
                                                                                  if (input.path) {
                                                                                      const value = updateValueUsingLens(
                                                                                          input.path as string,
                                                                                          inputs,
                                                                                          {
                                                                                              config: {
                                                                                                  ...kind,
                                                                                                  fields:
                                                                                                      kind.fields &&
                                                                                                      kind.fields.map(
                                                                                                          (f, ind) =>
                                                                                                              ind == i
                                                                                                                  ? {
                                                                                                                        ...field,
                                                                                                                        value: editorState as any
                                                                                                                    }
                                                                                                                  : f
                                                                                                      )
                                                                                              },
                                                                                              inputHasValue: true
                                                                                          }
                                                                                      )
                                                                                      return value
                                                                                  } else if (inputs.key === input.key) {
                                                                                      return {
                                                                                          ...inputs,
                                                                                          config: {
                                                                                              ...kind,
                                                                                              fields:
                                                                                                  kind.fields &&
                                                                                                  kind.fields.map(
                                                                                                      (f, ind) =>
                                                                                                          ind == i
                                                                                                              ? {
                                                                                                                    ...field,
                                                                                                                    value: editorState as any
                                                                                                                }
                                                                                                              : f
                                                                                                  )
                                                                                          },
                                                                                          inputHasValue: true
                                                                                      }
                                                                                  } else if (
                                                                                      filteredDependencyLookup.length >
                                                                                          0 &&
                                                                                      filteredDependencyLookup[0].key ==
                                                                                          inputs.key &&
                                                                                      convertAsaSingleString(
                                                                                          convertToRaw(
                                                                                              editorState.getCurrentContent()
                                                                                          ).blocks
                                                                                      ) != inputPreviousValue
                                                                                  ) {
                                                                                      return {
                                                                                          ...inputs,
                                                                                          config: {
                                                                                              ...kind,
                                                                                              fields:
                                                                                                  kind.fields &&
                                                                                                  kind.fields.map(
                                                                                                      (f, ind) =>
                                                                                                          ind == i
                                                                                                              ? {
                                                                                                                    ...field,
                                                                                                                    value:
                                                                                                                        ''
                                                                                                                }
                                                                                                              : f
                                                                                                  )
                                                                                          },
                                                                                          inputHasValue: false
                                                                                      }
                                                                                  }
                                                                                  return inputs
                                                                              }
                                                                          )
                                                                      }
                                                                  )
                                                                : produce(
                                                                      state.currentNode as FunctionResponseT,
                                                                      draftState => {
                                                                          const filteredDependencyLookup = draftState.functionInputsList.output.input.filter(
                                                                              inp =>
                                                                                  inp.config.kind == 'lookup-enum' &&
                                                                                  inp.config.params[input.key]
                                                                          )
                                                                          const val = input.value as EditorState
                                                                          const inputPreviousValue = convertAsaSingleString(
                                                                              convertToRaw(val.getCurrentContent())
                                                                                  .blocks
                                                                          )

                                                                          draftState.functionInputsList.output.input = draftState.functionInputsList.output.input.map(
                                                                              (inputs: InputFieldT) => {
                                                                                  if (input.path) {
                                                                                      const value = updateValueUsingLens(
                                                                                          input.path as string,
                                                                                          inputs,
                                                                                          {
                                                                                              value: editorState as any,
                                                                                              inputHasValue: true
                                                                                          }
                                                                                      )
                                                                                      return value
                                                                                  } else if (inputs.key === input.key) {
                                                                                      return {
                                                                                          ...inputs,
                                                                                          inputHasValue: true,
                                                                                          value: editorState as any
                                                                                      }
                                                                                  } else if (
                                                                                      filteredDependencyLookup.length >
                                                                                          0 &&
                                                                                      filteredDependencyLookup[0].key ==
                                                                                          inputs.key &&
                                                                                      convertAsaSingleString(
                                                                                          convertToRaw(
                                                                                              editorState.getCurrentContent()
                                                                                          ).blocks
                                                                                      ) != inputPreviousValue
                                                                                  ) {
                                                                                      return {
                                                                                          ...inputs,
                                                                                          value: '',
                                                                                          inputHasValue: false
                                                                                      }
                                                                                  }
                                                                                  return inputs
                                                                              }
                                                                          )
                                                                      }
                                                                  )

                                                            setPanelState({
                                                                ...state,
                                                                currentNode: nextValue
                                                            } as ActionPanelStateT | FunctionPanelStateT)
                                                        }}
                                                    />
                                                </div>)}
                                            </DraftInputWrapper>
                                        </InputAndEditorWrapper>

                                        {kind.fields && kind.fields.length > 1 && (
                                            <span
                                                className="step_d_count_icon"
                                                onClick={() => {
                                                    const nextValue = produce(
                                                        state.currentNode as ActionsResponseT,
                                                        draft => {
                                                            (forTestAction?draft.workflowInputsListForTest:draft.actionInputsList).output.input = (forTestAction?draft.workflowInputsListForTest:draft.actionInputsList).output.input.map(
                                                                inp => {
                                                                    if (inp.key == input.key) {
                                                                        return {
                                                                            ...inp,
                                                                            config: {
                                                                                ...kind,
                                                                                fields:
                                                                                    kind.fields &&
                                                                                    kind.fields.filter(
                                                                                        (f, ind) => ind != i
                                                                                    )
                                                                            }
                                                                        }
                                                                    }
                                                                    return inp
                                                                }
                                                            )
                                                        }
                                                    )

                                                    setPanelState({
                                                        ...state,
                                                        currentNode: nextValue
                                                    } as ActionPanelStateT | FunctionPanelStateT)
                                                }}
                                            >
                                                <CloseIconWithCircle />
                                            </span>
                                        )}
                                    </div>
                                ))}
                            <p style={{ marginTop: '7px' }}>{input.description}</p>
                            <div className="plus_addmore">
                                <div className="plus_add_more">
                                    <SmallButton primary style={{ marginTop: 12 }} onClick={() => {
                                        const nextValue = produce(state.currentNode as ActionsResponseT, draft => {
                                            (forTestAction?draft.workflowInputsListForTest:draft.actionInputsList).output.input = (forTestAction?draft.workflowInputsListForTest:draft.actionInputsList).output.input.map(
                                                inp => {
                                                    if (inp.key == input.key) {
                                                        return {
                                                            ...inp,
                                                            config: {
                                                                ...kind,
                                                                fields: kind.fields && [
                                                                    ...kind.fields,
                                                                    {
                                                                        value:forTestAction? "": emptyEditorState,
                                                                        key: ''
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    }
                                                    return inp
                                                }
                                            )
                                        })

                                        setPanelState({
                                            ...state,
                                            currentNode: nextValue
                                        } as ActionPanelStateT | FunctionPanelStateT)
                                    }}>
                                        <span>
                                            <AddIcon />
                                        </span>
                                        <label>Add Dynamic Field</label>
                                    </SmallButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
