import * as R from 'ramda'
import React, { useEffect, useState } from 'react'
import Tooltip from 'react-tooltip-lite'

import { useJourney } from '@/Journey/JourneyContext'
import { InfoIcon } from '@/common/Icons/Icons'
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { Loader } from '@/common/components/Loader'
import { Modal } from '@/common/components/Modal'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import { SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { ButtonWrapper } from '@/common/styled/Dialogue.Dumb'

import ImgLoader from '@/common/images/loading.svg'
import { Paragraph } from './Constructor'
import { AssistantT, fetchWorkspaces } from './kind'

type PublishButtonT = {
    assistant: AssistantT
    canpublish: { limit: boolean; resourceLimit: number }
    isInCard: boolean
    className: string
    setPublishId: Function
    isNull?: boolean
    isLive?: boolean
}
export function PublishPopup({
    modelError,
    setModelError,
    NoErrorV,
    publishId,
    setPublishId,
    publishbot,
    unpublishbot,
    setLoading,
    ErrorState,
    loading,
    workSpaces,
    setWorkSpaces,
    workspacename,
    Assistants
}: any) {
    const assistant = Assistants.find((assistant: AssistantT) => assistant.id === publishId)
    let publishedDate = assistant.published_at !== null ? assistant.published_at.split('Z')[0] : ''
    let recentPublish = assistant.updated.split('Z')[0] === publishedDate
    let updatedbot = publishedDate !== ''
    let publishState = 'publish'
    const journeyData = useJourney()
    useEffect(() => {
        if (recentPublish) {
            setLoading(true)
            fetchWorkspaces(workspacename, publishId)
                // Promise.resolve([
                //     {
                //         data: [
                //             {
                //                 workspace: 'manjulaworkspace',
                //                 assistance_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
                //                 name: 'manjula edit',
                //                 description: 'manjula edit',
                //                 client_id: '1273945647696.1365951877910',
                //                 client_secrect: 'd94bbee8f323769d93a722bcdd8ff086',
                //                 verification_token: 'jUDeiTlsNmkv89QzIWD3rCWO',
                //                 slack_deploy: {
                //                     mail_id: 'manjula.m@workativ.com',
                //                     added_by: 'manjula.m',
                //                     integrated_at: '2021-10-08T09:59:42.000Z',
                //                     is_active: true,
                //                     slack_workspace: 'SlackWorkativ',
                //                     slack_id: 'T0181TTK1LG'
                //                 }
                //             },
                //             {
                //                 workspace: 'manjulaworkspace',
                //                 assistance_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
                //                 name: 'Workativ Assistant',
                //                 description: 'Workativ Assistant',
                //                 client_id: '1374622349570.2228186775381',
                //                 client_secrect: '19ab7f6daf8ef5d7005d3c42a48d074f',
                //                 verification_token: 'SZbq1fa70rSJuu9bjDR8NIML',
                //                 slack_deploy: {
                //                     mail_id: 'manjula.m@workativ.com',
                //                     added_by: 'manjula m',
                //                     integrated_at: '2021-07-01T13:40:57.000Z',
                //                     is_active: true,
                //                     slack_workspace: 'SlackSkitter',
                //                     slack_id: 'T01B0JAA9GS'
                //                 }
                //             },
                //             {
                //                 workspace: 'manjulaworkspace',
                //                 assistance_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
                //                 name: 'manjula edit',
                //                 description: 'manjula edit',
                //                 client_id: 'b',
                //                 client_secrect: 'a',
                //                 verification_token: 'hii',
                //                 slack_deploy: null
                //             }
                //         ]
                //     },
                //     {
                //         data: [
                //             {
                //                 workspace: 'manjulaworkspace',
                //                 bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
                //                 app_id: '3d99765c-d8e2-4080-bd15-cb7d36428fdf',
                //                 app_password: 'e2Ge7JAsqHY._80Rl12542l~yq~_-vbw17',
                //                 is_authorized: true,
                //                 tenant: 'fe8f0076-8dbf-4684-9f73-b389c33b2158',
                //                 name: 'Workativ Assistant',
                //                 description: 'Your workplace assistant',
                //                 teams_deploy: {
                //                     team_name: 'Manjula Test Local',
                //                     team_id: '298c5339-237c-45f7-94ca-d3b978a4befa',
                //                     is_active: true,
                //                     date_added: '2021-10-18T08:30:54.000Z',
                //                     added_by: 'Manjula m',
                //                     email_id: 'manjula.m@workativ.com'
                //                 },
                //                 bot_data: {
                //                     bot_name: 'TestOne_Manjula',
                //                     bot_full_name: 'dx',
                //                     bot_short_desc: 'a',
                //                     bot_full_desc: 'dc',
                //                     is_generated: true,
                //                     is_active: true
                //                 }
                //             },
                //             {
                //                 workspace: 'manjulaworkspace',
                //                 bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
                //                 app_id: '9bedb5cd-5e5e-47cd-b9af-9d08fd9cf2a0',
                //                 app_password: 'n-Bdk2S.p892tH-IPynm~-Bxca~VCJ654v',
                //                 is_authorized: true,
                //                 tenant: 'fe8f0076-8dbf-4684-9f73-b389c33b2158',
                //                 name: 'Workativ Assistant',
                //                 description: 'Your workplace assistant',
                //                 teams_deploy: {
                //                     team_name: 'Dev App Manjula',
                //                     team_id: '913a8aa0-ee61-4013-a48b-7681534f41e8',
                //                     is_active: true,
                //                     date_added: '2021-10-06T09:32:20.000Z',
                //                     added_by: 'Manjula m',
                //                     email_id: 'manjula.m@workativ.com'
                //                 },
                //                 bot_data: {
                //                     bot_name: 'DevOne-Manjula',
                //                     bot_full_name: 'slack test app',
                //                     bot_short_desc: 'a',
                //                     bot_full_desc: 'b',
                //                     is_generated: true,
                //                     is_active: true
                //                 }
                //             },
                //             {
                //                 workspace: 'manjulaworkspace',
                //                 bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
                //                 app_id: '3842e03b-bc35-48f6-a800-eb379a136561',
                //                 app_password: 'TkfOgON-HP.34KV3A9--pXizDL2tMYVIGK',
                //                 is_authorized: false,
                //                 tenant: 'fe8f0076-8dbf-4684-9f73-b389c33b2158',
                //                 name: 'Workativ Assistant',
                //                 description: 'Your workplace assistant',
                //                 teams_deploy: null,
                //                 bot_data: null
                //             },
                //             {
                //                 workspace: 'manjulaworkspace',
                //                 bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
                //                 app_id: '',
                //                 app_password: '',
                //                 is_authorized: false,
                //                 tenant: 'fe8f0076-8dbf-4684-9f73-b389c33b2158',
                //                 name: 'manjula name',
                //                 description: 'manjula description',
                //                 teams_deploy: null,
                //                 bot_data: null
                //             }
                //         ]
                //     },
                //     {
                //         data: {
                //             id: '515204b1-29a2-49df-bd18-58d9eee49a48',
                //             workspace: 'manjulaworkspace',
                //             bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
                //             client_id: 'd59de9ab-a90c-49ce-ba2c-84e00cc90b85',
                //             client_secrect: 't8l5.1wlm7d2WhNcH75y~lF-0ouQC~xd-f',
                //             is_active: false,
                //             is_authorized: true,
                //             tenant: 'fe8f0076-8dbf-4684-9f73-b389c33b2158',
                //             teams_deploy: {
                //                 team_name: 'SupportPPBot',
                //                 team_id: 'd7812de3-4644-4f20-99f3-3baf0c39bdac',
                //                 is_active: true,
                //                 date_added: '2021-06-29T12:42:01.000Z',
                //                 added_by: 'Manjula m',
                //                 email_id: 'manjula.m@workativ.com'
                //             },
                //             bot_data: {
                //                 bot_name: 'AgentManjulaDev',
                //                 bot_full_name: 'devagent',
                //                 bot_short_desc: 'hi',
                //                 bot_full_desc: 'hello',
                //                 is_generated: true,
                //                 is_active: true
                //             }
                //         }
                //     }
                // ])
                .then((op: any) => {
                    let state = op.slackWorkspaces
                        ? op
                        : {
                              slackWorkspaces: op[0].data.filter(
                                  (w: any) => w.slack_deploy && w.assistance_id == publishId
                              ),
                              teamsWorkspaces: op[1].data.filter((w: any) => w.teams_deploy && w.bot_id == publishId),
                              agentHandOff: op[2].data
                          }

                    setLoading(false)
                    setWorkSpaces(state)
                })
                .catch(err => {
                    setLoading(false)
                    setModelError({
                        type: ErrorState.Valid,
                        error: true,
                        info: 'Something went wrong. Please try again!'
                    })
                })
        }
    }, [])
    function journeyUpdate() {
        journeyData.type === 'success' &&
            journeyData.value.send({
                type: 'EVENT::DIALOG::PUBLISH_CLICKED',
                tag: 'DIALOG_GENERIC_EVENT',
                journeyType: 'Dialog',
                botId: assistant.id
            })
    }
    const Publishing = () => {
        setLoading(true)
        // journeyUpdate()
        publishbot(
            publishId,
            () => {
                setPublishId('')
                setLoading(false)
                journeyUpdate()
            },
            () => {
                setLoading(false)
                setModelError({
                    type: ErrorState.Valid,
                    error: true,
                    info: 'Something went wrong while publishing the bot. Publish agian!'
                })
            },
            assistant.name
        )
    }
    const Unpublishing = () => {
        setLoading(true)
        journeyUpdate()
        unpublishbot(
            publishId,
            () => {
                setPublishId('')
                setLoading(false)
            },
            () => {
                setLoading(false)
                setModelError({
                    type: ErrorState.Valid,
                    error: true,
                    info: 'Something went wrong while unpublishing the bot. Unpublish agian!'
                })
            },
            assistant.name
        )
    }
    return (
        <Modal error={modelError} showPopupLoader={false}>
            {loading ? (
                <Loader.PopupLoader show={true} className="remove_connection_popuploader" />
            ) : (
                <ErrorBoundary
                    error={modelError}
                    render={(err: any, info: any) => {
                        return (
                            err && (
                                <Error.AddorEdit
                                    onClick={() => {
                                        setModelError(NoErrorV)
                                        setPublishId('')
                                    }}
                                    info={info}
                                />
                            )
                        )
                    }}
                >
                    <div className="alert_popup_message">
                        {!recentPublish ? (
                            <>
                                <h2>{`Are you sure to ${publishState} the ${assistant.name}?`}</h2>
                                <Paragraph style={{ margin: 0, whiteSpace: 'normal' }}>
                                    All the changes made till this point will be {publishState}ed to live bot if it is
                                    deployed in any chat channel.
                                </Paragraph>
                            </>
                        ) : (
                            <>
                                <h2>{`Are you sure to unpublish the ${assistant.name}?`}</h2>
                                <p className="unpublish_content">
                                    This chatbot is deployed on following chat channels. Please remove the integration
                                    to unpublish the assistant.
                                </p>
                                <ul style={{ listStyleType: 'none' }}>
                                    {workSpaces.slackWorkspaces.map((ws: any, i: number) => (
                                        <li key={i} className="automations_with_connections bots">
                                            <a href={`/workspace/${workspacename}/integration/chat/slack`}>
                                                Slack Channel - {ws.slack_deploy.slack_workspace}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                                <ul style={{ listStyleType: 'none' }}>
                                    {workSpaces.teamsWorkspaces.map((ws: any, i: number) => (
                                        <li key={i} className="automations_with_connections bots">
                                            <a href={`/workspace/${workspacename}/integration/chat/teams`}>
                                                Teams Channel - {ws.teams_deploy.team_name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                                {workSpaces.agentHandOff &&
                                    !R.isEmpty(workSpaces.agentHandOff) &&
                                    workSpaces.agentHandOff.teams_deploy != null && (
                                        <ul style={{ listStyleType: 'none' }}>
                                            {workSpaces.agentHandOff.teams_deploy && (
                                                <li key="" className="automations_with_connections bots">
                                                    <a
                                                        href={`/workspace/${workspacename}/dialog/${assistant.name}/agent-handoff`}
                                                    >
                                                        Agent HandOff - {workSpaces.agentHandOff.teams_deploy.team_name}
                                                    </a>
                                                </li>
                                            )}
                                        </ul>
                                    )}
                            </>
                        )}

                        {!recentPublish ||
                        (workSpaces.slackWorkspaces.length == 0 &&
                            workSpaces.teamsWorkspaces.length == 0 &&
                            (R.isEmpty(workSpaces.agentHandOff) || workSpaces.agentHandOff.teams_deploy == null)) ? (
                            <ButtonWrapper style={{ marginTop: 10 }}>
                                <Button
                                    primary
                                    type="button"
                                    onClick={(_: any) => {
                                        setPublishId('')
                                    }}
                                >
                                    <label>No</label>
                                </Button>
                                <Button
                                    type="button"
                                    onClick={(_: any) => (recentPublish ? Unpublishing() : Publishing())}
                                >
                                    <label>Yes</label>
                                </Button>
                            </ButtonWrapper>
                        ) : null}
                        {recentPublish &&
                        (workSpaces.slackWorkspaces.length > 0 ||
                            workSpaces.teamsWorkspaces.length > 0 ||
                            (!R.isEmpty(workSpaces.agentHandOff) && workSpaces.agentHandOff.teams_deploy != null)) ? (
                            <ButtonWrapper>
                                <Button
                                    primary
                                    type="button"
                                    onClick={() => {
                                        setPublishId('')
                                    }}
                                >
                                    <label>Cancel</label>
                                </Button>
                            </ButtonWrapper>
                        ) : null}
                    </div>
                </ErrorBoundary>
            )}
        </Modal>
    )
}

export const PublishButton = ({
    assistant,
    publishId,
    canpublish,
    className,
    isInCard,
    setPublishId,
    isNull,
    isLive,
    isIntentEntityEmpty,
    from
}: any) => {
    const journeyData = useJourney()
    const [ispublish, setIspublish] = useState({ error: false, loading: false, publish: false })
    let publishedDate = assistant.published_at !== null ? assistant.published_at.split('Z')[0] : ''
    let recentPublish = assistant.updated.split('Z')[0] === publishedDate
    let updatedbot = publishedDate !== ''
    useEffect(() => {
        if (recentPublish) {
            setIspublish({ ...ispublish, publish: true })
        }
    }, [])
    let disableKey = isIntentEntityEmpty ? true : isNull
    let disableState = disableKey || ispublish.loading || (!canpublish.limit && !updatedbot && !recentPublish)
    const toolTipClassName = disableKey ? 'target_bot editor_btn_disabled' : 'target_bot'

    const calculateMinWidth = () => {
        if (className === 'publish_button') {
            return recentPublish ? 80 : 65
        }
        return recentPublish ? 100 : 82
    }
    return (
        <div className={`${className} ${disableState|| assistant.welcome_node == null ? "disable_info_icon" : "" }`}>
            <SmallButton
                disabled={disableState|| assistant.welcome_node == null ? 'disable' : null}
                primary
                onClick={(e: any) => {
                    e.stopPropagation()
                    setPublishId(assistant.id)
                }}
                className={`${
                    journeyData.type === 'success' &&
                    journeyData.value.state().actions.filter(x => x.name === 'publish').length > 0 && assistant.welcome_node
                        ? `journey_publish _active`
                        : `journey_publish _inactive`
                }`}
                // style={{width: '95%'}}
            >
                {recentPublish ? <label>Unpublish</label> : <label>Publish</label>}
            </SmallButton>
            {ispublish.loading ? (
                <div className="btn_loader">
                    <img src={ImgLoader} />
                </div>
            ) : recentPublish && !isLive ? (
                <Tooltip
                    className={toolTipClassName}
                    tipContentHover
                    zIndex={5000}
                    arrowSize={5}
                    content={'Unpublish your bot'}
                >
                    <span>
                        <InfoIcon />
                    </span>
                </Tooltip>
            ) : recentPublish && isLive ? (
                <Tooltip
                    className={toolTipClassName}
                    tipContentHover
                    zIndex={5000}
                    arrowSize={5}
                    content={'Could not unpublish the bot. Bot already in chat channel.'}
                >
                    <span>
                        <InfoIcon />
                    </span>
                </Tooltip>
            ) : ispublish.error ? (
                <Tooltip
                    className={toolTipClassName}
                    tipContentHover
                    zIndex={5000}
                    arrowSize={5}
                    content={'Something went wrong while publishing the bot. Publish agian!'}
                >
                    <span style={{ fill: '#EB6453' }}>
                        <WarningAlertIcon />
                    </span>
                </Tooltip>
            ) : !ispublish.publish && !canpublish.limit && !recentPublish && !updatedbot ? (
                <Tooltip
                    className={toolTipClassName}
                    tipContentHover
                    zIndex={5000}
                    arrowSize={5}
                    content={`Your plan allows only ${canpublish.resourceLimit} published bot. Upgrade to publish more bots`}
                >
                    <span>
                        <InfoIcon />
                    </span>
                </Tooltip>
            ) : disableKey ? (
                <Tooltip
                    className={'target_bot'}
                    tipContentHover
                    zIndex={5000}
                    arrowSize={5}
                    content={
                        from && from == 'botsHome'
                            ? 'Clear the Dialog warnings to publish the bot in chat channels.'
                            : 'Clear the warnings to publish the bot in chat channels.'
                    }
                >
                    <span>
                        <InfoIcon />
                    </span>
                </Tooltip>
            ) : (
                <Tooltip
                    className={toolTipClassName}
                    tipContentHover
                    zIndex={5000}
                    arrowSize={5}
                    content={assistant.welcome_node ? 'Publish your bot' : "Chatbot is empty. Please create a dialog or FAQ to publish the bot"}
                >
                    <span>
                        <InfoIcon />
                    </span>
                </Tooltip>
            )}
            {!isInCard &&
                journeyData.type === 'success' &&
                journeyData.value.state().actions.filter(x => x.name === 'publish').length > 0 &&
                !disableState && (
                    <section>
                        <div
                            className={`publish_popup right-515 ${
                                journeyData.type === 'success' &&
                                journeyData.value.state().actions.filter(x => x.name === 'publish').length > 0
                                    ? `_active`
                                    : `_inactive`
                            }`}
                        >
                            <div className="tooltip_wrapper">
                                <div className="tooltip_wrapper_bg">
                                    <p>Publish the bot to use in chat-channels</p>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
        </div>
    )
}
