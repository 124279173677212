/*eslint-disable*/
import { differenceInDays, format } from 'date-fns';
import Downshift from 'downshift';
import React, { useEffect, useState } from 'react';
import Tooltip from 'react-tooltip-lite';
import styled from 'styled-components/macro';
import { getAssistants } from '@/Assistant/Mechanisms/action';
import { useVideoUrl } from '@/Videos/Videos';
import {
  BotDropdown,
  BotHomeIcon,
  DialogCardIcon,
  DialogIcon,
  FaqICon,
  FlowIcon,
  InfoIcon,
  IntegrationIcon,
  MarketPlaceIcon,
  SupportIcon,
  TrainingModuleIcon,
  UserIconAnalytics,
  WebhooksIcon,
  ApptriggerIcon,
  VideoIcon,
  UserTriggerIcon,
} from '@/common/Icons/Icons';
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary';
import { Loader } from '@/common/components/Loader';
import faqImage from '@/common/images/02_faq.png';
import workflowImage from '@/common/images/03_workflow.png';
import testImage from '@/common/images/04_test_bot.png';
import deployImage from '@/common/images/05_deploy_bot.png';
import dialogImage from '@/common/images/dialog1.png';
import productVideos from '@/common/images/product_help_videos.png';
import journeyVideos from '@/common/images/your-journey.png';
import quickStartImage from '@/common/images/quick_start_new.svg';
import productHelpDoc from '@/common/images/help_doc_new.svg';
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb';
import {
  automationAPIURL,
  getJson,
  loginApiURL,
  stripeGetApi,
  userJourneyWithWorkspace,
  watsonLogsApiURL,
} from '@/common/utils/api-utils';
import { sortAnArrayByDate } from '@/common/utils/common.utils';
import { ProductId, getProductId } from '@/common/utils/utils';
import { MOCK_USER_API, formatDateInCard } from '@/common/utils/utils';
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons';

export type JourneyApi = {
  type: 'success';
  output: UserJourney[];
};

export interface UserJourney {
  id: string;
  eventId: string;
  workspace: string;
  botId: string;
  createdDate: string;
  updatedDate: string;
  event: UserEvent;
}

export interface UserEvent {
  id: string;
  type: string;
  event:
    | 'EVENT::DIALOG::TESTED'
    | 'EVENT::DIALOG::CREATED'
    | 'EVENT::BOT::CREATED'
    | 'EVENT::FAQ::CREATED'
    | 'EVENT::WORKFLOW::CREATED'
    | 'EVENT::CHATCHANNEL::DEPLOYED';
}

type NormlisedUserEvent = {
  eventName:
    | 'EVENT::DIALOG::TESTED'
    | 'EVENT::DIALOG::CREATED'
    | 'EVENT::BOT::CREATED'
    | 'EVENT::FAQ::CREATED'
    | 'EVENT::WORKFLOW::CREATED'
    | 'EVENT::CHATCHANNEL::DEPLOYED';
  doneOn: string;
  type: string;
  id: string;
  redId: string;
};

export interface AssistantT {
  id: string;
  name: string;
  created: string;
  updated: string;
  deployed: string | null;
  avg_time_spend_by_agent: number;
  avg_cost_to_resolve_an_issue: number;
}
const Wrapper = styled.section`
  width: 100%;
  float: left;
  padding: 20px;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 60px;
  scroll-behavior: smooth;
`;
const Step = styled.p`
  position: absolute;
  top: -22px;
  left: 2px;
  color: #355d8b;
  font: normal normal medium 12px/19px Gordita;
`;
type ProductPlanT = {
  id: string;
  stripePlanId: string;
  metaForUI: null;
  created_date: string;
  modified_date: string;
  product: {
    id: string;
    name: string;
    displayName: string;
    description: null;
    stripeProductId: string;
    created_date: string;
    modified_date: string;
  };
  plan: {
    id: string;
    name: string;
    displayName: string;
    billed: string;
    created_date: string;
    modified_date: string;
  };
};
type SubscriptionT = {
  id: string;
  stripeSubscriptionId: null;
  stripeProductSubscriptionId: null;
  status: string;
  trialEndDate: string;
  created_date: string;
  modified_date: string;
  productPlan: ProductPlanT;
  hasTrialEnded: boolean;
  trialStartDate: string;
};

export type PlanT = {
  id: string;
  workspace: string;
  customerId: string;
  created_date: string;
  modified_date: string;
  subscription: SubscriptionT;
  trialPlan: {
    defaultPlanId: string;
    defaultPlanName: string;
    defaultPlanDisplayName: string;
    mappedPlanName: string;
    mappedPlanDisplayName: string;
    mappedPlanId: string;
  };
};

export const WelcomePage = (props: any) => {
  const [useInfo, setUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
  });
  const [bots, setBots] = useState<AssistantT[]>([]);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [pageError, setPageError] = useState({
    error: false,
    info: 'There was a problem in loading Page! <br/> Please click on "Retry"',
  });

  const [workFlowSummary, setWorkFlowSummary] = useState({
    totalAutoResolved: 0,
    totalExecution: 0,
    activeWorkflows: 0,
  });

  const [botEvents, setBotEvents] = useState<NormlisedUserEvent[]>([]);

  const [botDetails, setBotDetails] = useState<BotDetailsT>({
    user_count: 0,
    conversation_count: 0,
    satisfied_count: 0,
    not_satisfied_count: 0,
    day_wise_status_count: [],
    top_5_resolved_count: [],
    top_5_escalated_count: [],
    top_5_abandoned_count: [],
  });

  const [plan, setPlan] = useState<PlanT | {}>({});

  const [selectedBot, setSelectedBot] = useState({ bot: '', id: '' });

  function navigate(to: string) {
    const { workspacename } = props.match.params;
    props.history.push(`/workspace/${workspacename}/${to}`);
  }

  useEffect(() => {
    callApi();
  }, []);

  function callApi() {
    setPageLoading(true);
    setPageError({
      ...pageError,
      error: false,
    });
    // getAssistants(props.match.params.workspacename)
    //     .then(data => {
    const apis = [
      MOCK_USER_API
        ? Promise.resolve({
            phone_number: {
              id: 3,
              mobileNo: '',
              verified: true,
              valid: true,
              createdAt: '2020-03-27T13:43:49.000Z',
              updatedAt: '2020-03-27T13:43:49.000Z',
              code: '91',
              userId: 36,
            },
            id: 36,
            first_name: 'Vinod',
            last_name: 'Manda',
            email: 'vinod.m@workativ.com',
            org: 'workativ',
            role: 'product manager',
            country: 'IN',
          } as any)
        : getJson(loginApiURL('/user_info')),
      stripeGetApi(
        `${props.match.params.workspacename}/subscription/current/lite`
      ),
      getJson(
        automationAPIURL(
          `/${props.match.params.workspacename}/automation/summary`
        )
      ),
    ];
    // const apis = [
    // {
    //     phone_number: {
    //         id: 3,
    //         mobileNo: '09167646471',
    //         verified: true,
    //         valid: true,
    //         createdAt: '2020-03-27T13:43:49.000Z',
    //         updatedAt: '2020-03-27T13:43:49.000Z',
    //         code: '91',
    //         userId: 36
    //     },
    //     id: 36,
    //     first_name: 'Vinod',
    //     last_name: 'Manda',
    //     email: 'vinod.m@workativ.com',
    //     org: 'workativ',
    //     role: 'product manager',
    //     country: 'IN'
    // } as any,
    //     { type: 'success', output: { totalAutoResolved: 1, totalExecution: 43 } } as any,
    //     {
    //         type: 'success',
    //         output: {
    //             id: '7f650c7f-3893-47a7-acdf-8ff2ec7108ba',
    //             workspace: 'vinoddev',
    //             customerId: 'cus_Hk6XpM2DBo3ddh',
    //             created_date: '2020-07-30T14:01:52.516Z',
    //             modified_date: '2020-07-30T14:01:52.000Z',
    //             subscription: {
    //                 id: '507d0ebf-2a6e-4018-bd0d-80c8029b55e1',
    //                 stripeSubscriptionId: 'sub_JjdczgYPYgcKF7',
    //                 stripeProductSubscriptionId: 'sub_JjdczgYPYgcKF7',
    //                 status: 'active',
    //                 trialEndDate: '2021-08-29T14:01:53.000Z',
    //                 created_date: '2020-07-30T14:01:52.541Z',
    //                 modified_date: '2021-06-25T08:24:34.000Z',
    //                 productPlan: {
    //                     id: '75f6aec8-68bf-460d-835c-884f8c984e3e',
    //                     stripePlanId: 'price_1GwNaGJEQk5NJ3V80FPjDAQf',
    //                     metaForUI: {
    //                         type: 'Monthly',
    //                         plans: 'Standard',
    //                         price: '499',
    //                         steps: [
    //                             {
    //                                 category: 'Chatbot Builder',
    //                                 hoverContent: 'To built purposeful conversations'
    //                             },
    //                             {
    //                                 category: 'Workflow Builder',
    //                                 hoverContent: 'To built automations to resolve an issue'
    //                             },
    //                             {
    //                                 category: 'VA on Multi chat channel',
    //                                 hoverContent:
    //                                     'Deploy the virtual assistant on a chat channel of your choice like Slack and Microsoft Teams'
    //                             },
    //                             {
    //                                 bolder: '2',
    //                                 category: ' Admins',
    //                                 hoverContent: 'No of admins who can work on the application'
    //                             },
    //                             {
    //                                 bolder: '2000',
    //                                 category: ' Automation executions',
    //                                 hoverContent:
    //                                     'The total number of automations that can be executed over one month / 30 day period by the users.'
    //                             },
    //                             {
    //                                 bolder: '7000',
    //                                 category: ' Conversation messages',
    //                                 hoverContent:
    //                                     'The total number of conversational messages sent to the virtual assistant over a one month / 30 day period by the users.'
    //                             },
    //                             {
    //                                 bolder: '6',
    //                                 category: ' Active automations',
    //                                 hoverContent:
    //                                     ' At a time, the number of active automations available for use in the Dialog Designer. Admin will be able to disable existing and enable new automations as per choice. '
    //                             },
    //                             {
    //                                 bolder: '24 Hours',
    //                                 category: '  Response time ',
    //                                 hoverContent: 'Response time to answer/resolve the client queries.'
    //                             },
    //                             {
    //                                 bolder: 'Add-Ons',
    //                                 category: ' for 0.1 $',
    //                                 hoverContent:
    //                                     'Additional cost for each automation execution and conversation message.'
    //                             }
    //                         ],
    //                         dollar: '$',
    //                         priority: 2,
    //                         referenceName: 'STANDARD'
    //                     },
    //                     created_date: '2020-06-22T22:35:58.407Z',
    //                     modified_date: '2020-06-22T22:35:58.535Z',
    //                     product: {
    //                         id: '54fe8ee9-fe18-40b4-9306-19b0c8f4b584',
    //                         name: 'ASSISTANT',
    //                         displayName: 'Workativ Assistant',
    //                         description: null,
    //                         stripeProductId: 'prod_HVONDqFbA8p6cJ',
    //                         created_date: '2020-06-22T22:35:58.278Z',
    //                         modified_date: '2020-06-22T22:35:58.349Z'
    //                     },
    //                     plan: {
    //                         id: 'bfd79f1c-bf25-46f7-8b8d-4cf732ba2866',
    //                         name: 'STANDARD',
    //                         displayName: 'Standard',
    //                         billed: 'Monthly',
    //                         created_date: '2020-06-22T22:41:13.975Z',
    //                         modified_date: '2020-06-22T22:41:14.043Z'
    //                     }
    //                 },
    //                 hasTrialEnded: false,
    //                 trialStartDate: '2020-07-30T14:01:52.541Z'
    //             },
    //             trialPlan: {
    //                 defaultPlanId: 'bfd79f1c-bf25-46f7-8b8d-4cf732ba2865',
    //                 defaultPlanName: 'TRIAL',
    //                 defaultPlanDisplayName: 'Trial',
    //                 mappedPlanName: 'STANDARD',
    //                 mappedPlanDisplayName: 'Standard',
    //                 mappedPlanId: '75f6aec8-68bf-460d-835c-884f8c984e3e'
    //             }
    //         }
    //     } as any
    // ]
    Promise.all(apis)
      .then(([user, plan, workFlowSummary]) => {
        setUser({
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
        });
        // console.log("plan promise", user, plan, workFlowSummary)
        setPlan(plan.output);
        setWorkFlowSummary(workFlowSummary.output);
      })
      .catch((err) => {
        setPageError({
          ...pageError,
          error: true,
        });
        setPageLoading(false);
      });

    // setBots(data as any)
    // data.length > 0 &&
    //     setSelectedBot({
    //         id: sortAnArrayByDate(data, 'updated')[0].id,
    //         bot: sortAnArrayByDate(data, 'updated')[0].name
    //     })
    setPageLoading(false);
    // })
    // .catch(err => {
    //     setPageError({
    //         ...pageError,
    //         error: true
    //     })
    //     setPageLoading(false)
    // })
  }

  // useEffect(() => {
  //     selectedBot.id.length > 0 &&
  //         getJson(
  //             watsonLogsApiURL(
  //                 `/${props.match.params.workspacename}/analytics/bot_summary_report/${selectedBot.id}/30`
  //             )
  //         ).then(res => {
  //             setBotDetails(res)
  //         })

  //     selectedBot.id.length > 0 &&
  //         getJson(
  //             userJourneyWithWorkspace(props.match.params.workspacename, `/journey/event/${selectedBot.id}`)
  //         ).then((res: JourneyApi) => {
  //             const normalizedEvents = res.output.map(event => ({
  //                 eventName: event.event.event,
  //                 doneOn: event.createdDate,
  //                 type: event.event.type,
  //                 id: event.eventId,
  //                 redId: event.botId
  //             }))
  //             setBotEvents(normalizedEvents)
  //             // setBotDetails(res)
  //         })
  // }, [selectedBot])
  const eventsWithPriority = EventsLinkMap.map((eventMap) => {
    const eventsPri = botEvents.find(
      (event) => eventMap.event === event.eventName
    );
    if (!eventsPri) {
      return { ...eventMap, meta: { type: 'not-found' } };
    }

    return { ...eventMap, meta: { ...eventsPri, type: 'found' } };
  }).filter((x) => x.meta.type === 'not-found');

  // console.log('test', plan)

  return (
    <React.Fragment>
      <MainHeader header={'Workativ Automate'} />
      <ErrorBoundary
        error={pageError}
        render={(err: any, info: any) => {
          return (
            err && (
              <Error.PageLoadingError
                onClick={callApi}
                info={info}
                btnName="Retry"
              />
            )
          );
        }}
      >
        <Wrapper>
          {pageLoading ? (
            <Loader.PageLoader show={true} />
          ) : (
            <React.Fragment>
              <BoxWrapper>
                <PlanCards
                  bots={bots}
                  setSelectedBot={setSelectedBot}
                  selectedBot={selectedBot}
                  plan={plan}
                  useInfo={useInfo}
                  {...props}
                />
                {bots.length > 0 ? (
                  <ProgressWrapper>
                    <ProgressContainer>
                      <DropdownContainer
                        bots={bots}
                        setSelectedBot={setSelectedBot}
                        selectedBot={selectedBot}
                        plan={plan}
                        useInfo={useInfo}
                        {...props}
                      />
                      <Header>
                        Your journey{' '}
                        <span>
                          {botEvents.length > 0
                            ? `(Recent Activity: ${format(
                                botEvents.filter((_, i) => i == 0)[0].doneOn,
                                'dddd, D MMM YYYY, h:mm A'
                              )})`
                            : null}
                        </span>
                      </Header>
                      <ul className="progressbar">
                        <li
                          className={
                            botEvents.filter(
                              (event) =>
                                event.eventName === 'EVENT::BOT::CREATED'
                            ).length > 0
                              ? 'active'
                              : ''
                          }
                        >
                          <span />
                          <h6 onClick={() => navigate('dialog/homepage')}>
                            Create Bot
                          </h6>{' '}
                          <p>Name your bot</p>
                          <Step>Step 1</Step>
                        </li>
                        <li
                          className={
                            botEvents.filter(
                              (event) =>
                                event.eventName === 'EVENT::FAQ::CREATED'
                            ).length > 0
                              ? 'active'
                              : ''
                          }
                        >
                          <span />
                          <h6
                            onClick={() =>
                              selectedBot.id.length > 0 &&
                              navigate(
                                `dialog/${selectedBot.bot}/dialogs?create-faq=true`
                              )
                            }
                          >
                            FAQs
                          </h6>{' '}
                          <p>Create simple FAQs</p>
                          <Step>Step 2</Step>
                        </li>
                        <li
                          className={
                            botEvents.filter(
                              (event) =>
                                event.eventName === 'EVENT::DIALOG::TESTED'
                            ).length > 0
                              ? 'active'
                              : ''
                          }
                        >
                          <span />
                          <h6
                            onClick={() =>
                              selectedBot.id.length > 0 &&
                              navigate(`dialog/${selectedBot.bot}/dialogs`)
                            }
                          >
                            Test
                          </h6>{' '}
                          <p>Test your bot</p>
                          <Step>Step 3</Step>
                        </li>
                        <li
                          className={
                            botEvents.filter(
                              (event) =>
                                event.eventName === 'EVENT::DIALOG::CREATED'
                            ).length > 0
                              ? 'active'
                              : ''
                          }
                        >
                          <span />
                          <h6
                            onClick={() =>
                              selectedBot.id.length > 0 &&
                              navigate(
                                `dialog/${selectedBot.bot}/dialogs?create-dialog=true`
                              )
                            }
                          >
                            Dialogs{' '}
                          </h6>{' '}
                          <p>Create conversations for bot</p>
                          <Step>Step 4</Step>
                        </li>
                        <li
                          className={
                            botEvents.filter(
                              (event) =>
                                event.eventName === 'EVENT::WORKFLOW::CREATED'
                            ).length > 0
                              ? 'active'
                              : ''
                          }
                        >
                          <span />
                          <h6
                            onClick={() =>
                              navigate(
                                `automation/view/home?create-workflow=true`
                              )
                            }
                          >
                            App Workflows{' '}
                          </h6>{' '}
                          <p>Create and activate app workflows for bot</p>
                          <Step>Step 5</Step>
                        </li>
                        <li
                          className={
                            botEvents.filter(
                              (event) =>
                                event.eventName ===
                                'EVENT::CHATCHANNEL::DEPLOYED'
                            ).length > 0
                              ? 'active'
                              : ''
                          }
                        >
                          <span />
                          <h6 onClick={() => navigate(`integration/chat`)}>
                            Deploy Bot{' '}
                          </h6>{' '}
                          <p>Deploy bot in chat channel</p>
                          <Step>Step 6</Step>
                        </li>
                      </ul>
                    </ProgressContainer>
                    <VideosWRapper>
                      <CardsWrapper>
                        {' '}
                        {eventsWithPriority.length > 0 ? (
                          <BoxWrapper>
                            <VideoImage
                              id={eventsWithPriority[0].videoId}
                              text={eventsWithPriority[0].text}
                            />
                          </BoxWrapper>
                        ) : (
                          <BoxWrapper>
                            <VideoImage
                              id={'DEPLOY_BOT_AS_CHAT_WIDGET_IN_WEBPAGE'}
                              text={'How to deploy chatbot in Chat Channels'}
                            />
                          </BoxWrapper>
                        )}
                      </CardsWrapper>
                    </VideosWRapper>
                  </ProgressWrapper>
                ) : null}
              </BoxWrapper>
              {/*  <QuickStart selectedBot={selectedBot} {...props} />
                            <SummaryWrapper
                                bots={bots}
                                selectedBot={selectedBot}
                                workFlowSummary={workFlowSummary}
                                botDetails={botDetails}
                                workspace={props.match.params.workspacename}
                            /> */}
              <QuickStartAutomate selectedBot={selectedBot} {...props} />
              <SummaryWrapperAutomate
                workFlowSummary={workFlowSummary}
                workspace={props.match.params.workspacename}
              />
            </React.Fragment>
          )}
        </Wrapper>
      </ErrorBoundary>
    </React.Fragment>
  );
};

const VideoDescription = styled.p`
  position: static !important;
  font-size: 14px !important;
`;
const VideoWatchNow = styled.span`
  position: absolute;
  margin-top: 8px;
  margin-left: 10px;
  font-family: 'Gordita-Regular';
  font-size: 12px;
  color: #1857dc;
`;
const VideoWatchHolder = styled.div`
  margin-left: -115px;
  margin-top: 5px;
  svg {
    width: 32px;
    height: 32px;
  }
`;
const Centered = styled.div`
  position: absolute;
  left: 35%;
  top: 40%;
`;
const Centered2 = styled.div`
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 50px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const VideoImage = ({ id, text }: { id: string; text: string }) => {
  const [videoToPlace, showVideo] = useVideoUrl(id);

  return (
    <div className="img_center_tag cursor_pointer" onClick={showVideo}>
      <img src={journeyVideos} width="100%" height="100%" alt={text} />
      <Centered2>
        <VideoDescription>{text}</VideoDescription>
        <VideoWatchHolder>
          <VideoIcon />
          <VideoWatchNow>Watch Now!</VideoWatchNow>
        </VideoWatchHolder>
      </Centered2>
    </div>
  );
};

export const HeaderTop = styled.h3`
  width: 100%;
  float: left;
  font-size: 18px;
  line-height: 18px;
  font-family: 'Gordita-Medium';
  color: #355d8b;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
`;
export const HeaderContainer = styled.div`
  width: 100%;
  float: left;
  display: flex;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  float: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #0000001a;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  border-bottom: 1px solid #d9e2eb;

  @media (max-width: 1400px) {
    padding: 20px;
  }
`;

export const MainHeader = (props: any) => {
  return (
    <HeaderWrapper>
      <HeaderContainer>
        <HeaderTop>{props.header}</HeaderTop>
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export const PlanContetnWrapper = styled.div`
    float: left;
    padding-left: 15px;
    width: 100%;
    flex-direction: column;
    h3 {
        width: 100%;
        float: left;
        font-family: 'Gordita-Medium';
        font-size: 18px;
        color: #000;
        margin-bottom: 0px;
        line-height:18px;
    }
    p {
        width: 100%;
        float: left;
        font-family: 'Gordita-Regular';
        font-size: 14px;
        color: #2468f6;
        margin: 2px 0px 5px 0px;
    }

 
    }
`;
export const CurrentPlanWrapper = styled.div`
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  ${PlanContetnWrapper} {
    display: flex;
    width: max-content;
    flex-direction: row;
  }
  progress {
    text-align: right;
    color: #f4caad;
    background: #f4f4f4;
    width: 100%;
    border-radius: 6px;
    float: left;
    height: 10px;
    border: none;

    ::-moz-progress-bar {
      background: #f4caad;
      border-radius: 6px;
    }
    ::-webkit-progress-value {
      background: #f4caad;
      border-radius: 6px;
    }
    ::-webkit-progress-bar {
      background: #f4f4f4;
      border-radius: 6px;
    }
    ::-moz-progress-value {
      background: #f4caad !important;
      border-radius: 6px;
    }
  }
`;

const Span = styled.span`
  width: auto;
`;
export const CurrentPlanDetail = styled.h5`
  width: 100%;
  float: left;
  font-family: 'Gordita-Medium';
  font-size: 12px;
  color: #000000;
  margin-bottom: 0px !important;

  ${Span} {
    font-size: 12px;
    color: #355d8b;
    font-family: 'Gordita-Regular';
    display: flex;
    align-items: center;
  }
`;
export const HeaderWidthAuto = styled.div`
  width: 420px;
  float: left;
  display: flex;
  flex-direction: column;

  h4,
  h5,
  h6 {
    width: auto;
  }

  .welcome_progrss_button {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 5px;

    div:first-child {
      position: relative;
      top: 2px;
    }

    .progress_bar {
      width: 100%;
      ${Span} {
        position: absolute;
        right: 18px;
      }
    }
  }

  @media (max-width: 1400px) {
    width: 280px;
  }
`;
const UpgradeButton = styled.a`
  width: max-content;
  align-items: flex-end;
  display: flex;
  margin-left: 20px;
  text-decoration: none;
  :hover {
    text-decoration: none;
  }

  ${Button} {
    cursor: pointer;
    text-align: center;
    margin-bottom: 0px;
    margin: 0;
  }
`;

export const ImgWrapper = styled.span`
  width: 84px;
  height: 84px;
  float: left;
  border: 1px solid #e0f6ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
  background: #e0f6ff;

  svg {
    width: 64px;
    height: 64px;
    fill: #355d8b;
    color: #355d8b;
  }
`;
export const DateAndTime = styled.div`
  width: 100%;
  float: left;

  h5 {
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    font-size: 12px;
    color: #355d8b;
    text-align: right;
    margin-bottom: 6px;
    line-height: normal;
  }
  h6 {
    width: 100%;
    float: left;
    font-family: 'Gordita-Medium';
    font-size: 12px;
    color: #000000;
    text-align: right;
    margin-bottom: 0px;
    line-height: normal;
  }
`;
export const Header = styled.div`
  width: 100%;
  float: left;
  font-family: 'Gordita-Medium';
  font-size: 18px;
  color: #000000;
  margin-bottom: 14px;
`;
export const BoxWrapperTop = styled.div`
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
`;

const ProgressContainer = styled.div`
  width: 100%;
  float: left;
  padding-top: 30px;
  padding-bottom: 0px;

  .progressbar {
    position: relative;
    left: 0px;
    width: 100%;
    float: left;
    margin-bottom: 0px;
    padding-top: 20px;
  }
  .progressbar li {
    list-style-type: none;
    float: left;
    width: max-content;
    position: relative;
    text-align: center;
    font-weight: 600;
    padding: 0px;
    width: 142px;
    padding-right: 0px;
    :last-child {
      margin-left: 8px;
    }

    span {
      background-image: url(./images/tick.svg);
      background-repeat: no-repeat;
      position: absolute;
      width: 18px;
      height: 12px;
      top: 12px;
      left: 10px;
      z-index: 2;
    }
  }
  .progressbar li:after {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #4cbf96;
    top: 17px;
    left: 20%;
    z-index: 1;
  }
  .progressbar li:before {
    /* CSS for creating steper block before the li item*/
    content: '';
    height: 36px;
    width: 36px;
    line-height: 30px;
    border: 2px solid #adadad;
    display: block;
    text-align: center;
    border-radius: 50%;
    background-color: #fff;
    z-index: 2;
    position: relative;
  }

  .progressbar li:last-child:after {
    content: none;
  }
  .progressbar li.active {
    color: #4cbf96;
  }
  .progressbar li.active:before {
    border-color: #4cbf96;
    background-color: #4cbf96;
    color: #fff;
  }
  .progressbar li.active:after {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #4cbf96;
    top: 17px;
    left: 20%;
    z-index: 1;
  }
  .progressbar li.active:nth-child(3):after {
    left: calc(20% + 6px);
    @media (max-width: 1700px) {
      left: calc(19% + 6px);
    }
    @media (max-width: 1536px) {
      left: calc(17% + 6px);
    }
    @media (max-width: 1400px) {
      left: calc(15% + 6px);
    }
  }
  .progressbar li.active:last-child:after {
    content: none;
  }

  .progressbar li.active + li:after {
    background-color: #4cbf96;
  }
  .progressbar h6 {
    text-align: left;
    font-family: 'Gordita-Medium';
    font-size: 16px;
    letter-spacing: 0px;
    color: #1857dc;
    line-height: 24px;
    margin-top: 12px;
    margin-bottom: 5px;
    cursor: pointer;
    :hover {
      text-decoration: underline;
      cursor: default;
    }
  }
  .progressbar p {
    text-align: left;
    font-family: 'Gordita-Regular';
    font-size: 12px;
    letter-spacing: 0px;
    color: #355d8b;
    line-height: 18px;
    margin-bottom: 0px;
  }

  ${Header} {
    color: #000;
    font-size: 18px;
    font-family: 'Gordita-Medium';
    line-height: normal;
    span {
      color: #355d8b;
      font-family: 'Gordita-Regular';
      font-size: 12px;
    }
  }
`;

export const BoxWrapper = styled.section`
  width: 100%;
  float: left;
  padding: 30px 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000001a;
  border-radius: 10px;
  padding-bottom: 26px;

  h2 {
    width: 100%;
    float: left;
    font-family: 'Gordita-Medium';
    font-size: 24px;
    color: #000;
    margin-bottom: 16px;
    line-height: normal;

    span {
      color: #3da27e;
    }
  }
`;
const VideosWRapper = styled.div`
  width: 100%;
  float: left;
  display: flex;

  p {
    width: max-content;
    float: left;
    font-family: 'Gordita-Medium';
    font-size: 12px;
    color: #000;
    margin-bottom: 0px;
    position: absolute;
    top: 50%;
    left: 20px;

    :hover {
      //text-decoration: underline;
    }
  }
  ${BoxWrapper} {
    height: 100%;

    .img_center_tag {
      width: 100%;
      float: left;
      position: relative;
      display: flex;
      justify-content: flex-end;

      @media (min-width: 2160px) {
        height: 280px;
      }
      @media (max-width: 1920px) {
        height: 240px;
      }
      @media (max-width: 1700px) {
        height: 240px;
      }
      @media (max-width: 1536px) {
        height: 220px;
      }

      .home_product_container {
        position: absolute;
        display: flex;
        top: 0;
        left: 11%;
        bottom: 25px;
        right: 0;
        margin: 0 auto;
        align-items: center;

        svg {
          margin-top: 12px;
          height: 50px;
          width: 50px;
        }

        .home_product {
          position: relative;
          margin-left: 10px;
          // width: 48%;

          h6 {
            color: #000;
            line-height: 1.3;
            font-family: 'Gordita-Regular';
            font-size: 14px;
          }
          p {
            margin-top: 20px;
            left: 0;
            color: #1857dc;
            font-family: 'Gordita-Regular';
            font-size: 12px;
            color: #1857dc;

            :hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    img {
      width: 100%;
      float: left;
      //height: 220px;

      @media (min-width: 1500px) and (max-width: 2560px) {
        //width: 396px;
      }

      :hover ~ p {
        //text-decoration: underline;
        cursor: pointer;
      }
    }
  }
`;
const ProgressWrapper = styled.div`
  width: 100%;
  float: left;
  display: grid;
  grid-template-columns: 1fr 30%;
  border-top: 1px solid #d9e2eb;
  margin-top: 20px;

  ${VideosWRapper} {
    display: flex;
    align-items: center;
    position: relative;
    top: 15px;
    ${BoxWrapper} {
      padding: 0px;
      box-shadow: none;
      display: flex;
      align-items: flex-end;
      position: relative;
      top: 0px;
      justify-content: flex-end;
      p {
        // position: absolute;
        // top: 30%;
        // left: 20px;
        // margin: 0 auto;
        // bottom: 0;
        // right: 0;
        // font-size: 16px;
      }

      .home_watch_container {
        position: absolute;
        top: 32%;
        left: 31.5%;
        margin: 0 auto;
        bottom: 0;
        right: 0;

        .home_watch_now {
          display: flex;
          position: relative;
          align-items: center;
          margin-left: 16px;
          margin-top: 12px;

          svg {
            position: relative;
            left: 12px;
            max-width: 11%;
          }
        }
        h5 {
          position: relative;
          font-size: 16px;
          color: #000;
          font-weight: 400;
        }
        p {
          position: relative;
          font-size: 12px;
          color: #1857dc;
          font-weight: 200;
        }
      }
    }
  }
`;
export const PlanCards = (props: {
  bots: AssistantT[];
  setSelectedBot: Function;
  selectedBot: {
    bot: string;
    id: string;
  };
  plan: PlanT;
  useInfo: {
    first_name: string;
    last_name: string;
    email: string;
  };
  match?: any;
}) => {
  const { plan, useInfo } = props;

  // const Difference_In_Time =
  //   new Date(plan.subscription && plan.subscription.trialEndDate).getTime() -
  //   new Date(plan.subscription && plan.subscription.trialStartDate).getTime();
  // const remaining =
  //   new Date(plan.subscription && plan.subscription.trialEndDate).getTime() -
  //   new Date().getTime();

  const todayInUTC = new Date(new Date().toUTCString());
  // const trialDaysRemaining =

  // To calculate the no. of days between two dates
  const trailRemainingDays = differenceInDays(
    plan.subscription ? plan.subscription.trialEndDate : todayInUTC,
    todayInUTC
  );

  const totalDays = differenceInDays(
    plan.subscription && plan.subscription.trialEndDate,
    plan.subscription && plan.subscription.trialStartDate
  );

  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  const greet = [
    'Morning',
    'Good morning',
    'Good afternoon',
    'Good evening',
    'Go to bed',
  ];

  const secondCounter = new Date().getHours();
  const minuteCounter = new Date().getMinutes();
  const secondsCounter = new Date().getSeconds();

  const computedHour =
    String(secondCounter).length === 1 ? `0${secondCounter}` : secondCounter;
  const computedMinute =
    String(minuteCounter).length === 1 ? `0${minuteCounter}` : minuteCounter;
  const computedSeconds =
    String(secondsCounter).length === 1 ? `0${secondsCounter}` : secondsCounter;

  const greetMessage = (hrs: number) => {
    console.log('hrs', hrs);
    let msg = 'Morning';
    // Before 6am
    if (hrs < 7) return 'Morning';
    // After 6am
    else if (hrs < 13) return 'Good Morning';
    // After 12pm
    else if (hrs < 18) return 'Good afternoon';
    // After 5pm
    else if (hrs < 23) return 'Good evening';
    else return 'Go to bed';
    return msg;
  };

  const [day, setDay] = useState<any>({
    hour: computedHour,
    minute: computedMinute,
    seconds: computedSeconds,
    date: new Date(),
  });

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    intervalId = setInterval(() => {
      const hourCounter = new Date().getHours();
      const minuteCounter = new Date().getMinutes();
      const secondsCounter = new Date().getSeconds();

      const computedHour =
        String(hourCounter).length === 1 ? `0${hourCounter}` : hourCounter;
      const computedMinute =
        String(minuteCounter).length === 1
          ? `0${minuteCounter}`
          : minuteCounter;
      const computedSeconds =
        String(secondsCounter).length === 1
          ? `0${secondsCounter}`
          : secondsCounter;
      // console.log('hour', {
      //     hour: computedHour,
      //     minute: computedMinute,
      //     seconds: computedSeconds,
      //     date: new Date()
      // })

      setDay({
        hour: computedHour,
        minute: computedMinute,
        seconds: computedSeconds,
        date: new Date(),
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const currentDate = day.date;
  console.log('plan', plan);
  // console.log(
  //     'Welcome :::                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            day',
  //     currentDate.getDay()
  // )
  // day.hour = 22
  return (
    <React.Fragment>
      <BoxWrapperTop>
        <h2>
          {greetMessage(day.hour)}, <span>{useInfo.first_name}!</span>
        </h2>
        <DateAndTime>
          <h5>
            {days[currentDate.getDay()]},{' '}
            {formatDateInCard(currentDate.toString())}
          </h5>
          <h6>
            {day.hour > 12 ? day.hour % 12 : day.hour}:{day.minute}:
            {day.seconds} {day.hour >= 12 ? 'PM' : 'AM'}
          </h6>
        </DateAndTime>
      </BoxWrapperTop>
      <CurrentPlanWrapper>
        <ImgWrapper>
          <UserIconAnalytics />
        </ImgWrapper>
        <PlanContetnWrapper>
          <HeaderWidthAuto>
            <h3>
              {useInfo.first_name} {useInfo.last_name}
            </h3>
            <p> {useInfo.email}</p>
            {plan.subscription && plan.subscription.status == 'trialing' ? (
              <div className="welcome_progrss_button">
                <div className="progress_bar">
                  <CurrentPlanDetail className="d-flex justify-content-between mb-1">
                    {' '}
                    {plan.subscription &&
                      (plan.subscription.status == 'trialing'
                        ? plan.trialPlan.mappedPlanDisplayName + ' Trial'
                        : plan.subscription.productPlan.plan.displayName)}{' '}
                    {plan.subscription &&
                    plan.subscription.status == 'trialing' ? (
                      <div className="home_plan_expired">
                        {trailRemainingDays > 0 ? (
                          <Span style={{ top: '-2px', right: 0 }}>
                            {trailRemainingDays} days remaining
                          </Span>
                        ) : (
                          <>
                            <WarningAlertIcon />
                            <Span
                              style={{ color: 'red', top: '-2px', right: '' }}
                            >
                              Plan Expired
                            </Span>
                          </>
                        )}
                      </div>
                    ) : null}
                  </CurrentPlanDetail>
                  {plan.subscription &&
                  plan.subscription.status == 'trialing' ? (
                    <progress
                      value={Math.round((trailRemainingDays / totalDays) * 100)}
                      max="100"
                    >
                      {Math.round((trailRemainingDays / totalDays) * 100)}
                    </progress>
                  ) : null}
                </div>
                <div>
                  {plan.subscription &&
                  plan.subscription.productPlan.plan.name !== 'PRO_YEAR' ? (
                    <UpgradeButton
                      href={`/workspace/${props.match.params.workspacename}/billing/plans`}
                    >
                      <Button>
                        <label>Upgrade Plan</label>
                      </Button>
                    </UpgradeButton>
                  ) : null}
                </div>
              </div>
            ) : (
              <div className="welcome_progrss_button">
                <div>
                  <CurrentPlanDetail className="d-flex justify-content-between mb-1">
                    {' '}
                    {plan.subscription &&
                      (plan.subscription.status == 'trialing'
                        ? plan.trialPlan.mappedPlanDisplayName + ' Trial'
                        : plan.subscription.productPlan.plan.displayName)}{' '}
                    {plan.subscription &&
                    plan.subscription.status == 'trialing' ? (
                      <Span>
                        {trailRemainingDays > 0
                          ? `${trailRemainingDays} days remaining`
                          : 'Plan Expired'}
                      </Span>
                    ) : null}
                  </CurrentPlanDetail>
                  {plan.subscription &&
                  plan.subscription.status == 'trialing' ? (
                    <progress
                      value={Math.round((trailRemainingDays / totalDays) * 100)}
                      max="100"
                    >
                      {Math.round((trailRemainingDays / totalDays) * 100)}
                    </progress>
                  ) : null}
                </div>
                <div>
                  {plan.subscription &&
                  plan.subscription.productPlan.plan.name !== 'PRO_YEAR' ? (
                    <UpgradeButton
                      href={`/workspace/${props.match.params.workspacename}/billing/plans`}
                    >
                      <Button>
                        <label>Upgrade Plan</label>
                      </Button>
                    </UpgradeButton>
                  ) : null}
                </div>
              </div>
            )}
          </HeaderWidthAuto>
        </PlanContetnWrapper>
      </CurrentPlanWrapper>
    </React.Fragment>
  );
};;

const Content = styled.div`
  width: 100%;
  float: left;
  font-family: 'Gordita-Medium';
  font-size: 12px;
  color: #1857dc;
  margin-bottom: 0px;
  text-align: center;
  // @media (max-width: 1700px) {
  //     font-size: 18px;
  // }
  // @media (max-width: 1536px) {
  //     font-size: 16px;
  // }
`;
const Circle = styled.div`
  width: 64px;
  height: 64px;
  background: #e0f6ff 0% 0% no-repeat padding-box;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  cursor: pointer;

  svg {
    width: 28px;
    height: 28px;
    color: #355d8b;
    fill: #355d8b;
  }
`;
const CircleWrapper = styled.div`
  width: 20%;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;

  :hover {
    ${Content} {
      color: #1857dc;
      text-decoration: underline;
      cursor: pointer;
    }

    ${Circle} {
      background: #ffe5d3;
      cursor: pointer;
      svg {
        fill: #333;
        color: #333;
      }
    }
  }

  :first-child {
    width: auto;
    margin-right: 30px;
  }

  .quickStartImg {
    width: 120px;
  }
`;
const GridBox = styled.div`
  width: 100%;
  float: left;
  display: flex;
  justify-content: flex-start;
`;
const QuickStartWrapper = styled.div`
  width: 100%;
  float: left;
  margin-top: 20px;
`;
const QuickStart = (props: any) => {
  function navigate(to: string) {
    const { workspacename } = props.match.params;
    props.history.push(`/workspace/${workspacename}/${to}`);
  }
  const currentBot = props.selectedBot as {
    bot: string;
    id: string;
  };
  return (
    <QuickStartWrapper>
      <BoxWrapper>
        <Header>Quick Start With</Header>
        <GridBox>
          <CircleWrapper onClick={() => navigate('dialog/homepage')}>
            <Circle>
              <BotHomeIcon />
            </Circle>
            <Content>Bots Home</Content>
          </CircleWrapper>
          <CircleWrapper
            onClick={() =>
              currentBot.id.length > 0
                ? navigate(`dialog/${currentBot.bot}/dialogs?create-faq=true`)
                : navigate('dialog/homepage')
            }
          >
            <Circle>
              <FaqICon />
            </Circle>
            <Content>Create FAQ</Content>
          </CircleWrapper>
          <CircleWrapper
            onClick={() =>
              currentBot.id.length > 0
                ? navigate(
                    `dialog/${currentBot.bot}/dialogs?create-dialog=true`
                  )
                : navigate('dialog/homepage')
            }
          >
            <Circle>
              <DialogCardIcon />
            </Circle>
            <Content>Create Dialog</Content>
          </CircleWrapper>
          <CircleWrapper
            onClick={() =>
              navigate(`automation/view/home?create-workflow=true`)
            }
          >
            <Circle>
              <FlowIcon />
            </Circle>
            <Content>Create App Workflow</Content>
          </CircleWrapper>
          <CircleWrapper onClick={() => navigate(`integration/chat`)}>
            <Circle>
              <IntegrationIcon />
            </Circle>
            <Content>Bot Chat Channel</Content>
          </CircleWrapper>
          <CircleWrapper onClick={() => navigate(`train-assistant/home`)}>
            <Circle>
              <TrainingModuleIcon />
            </Circle>
            <Content>Bot Training</Content>
          </CircleWrapper>
          <CircleWrapper
            onClick={() =>
              navigate(`marketplace/automation/select-application`)
            }
          >
            <Circle>
              <MarketPlaceIcon />
            </Circle>
            <Content>Marketplace</Content>
          </CircleWrapper>
          <CircleWrapper onClick={() => navigate(`support`)}>
            <Circle>
              <SupportIcon />
            </Circle>
            <Content>Product Support</Content>
          </CircleWrapper>
          <CircleWrapper onClick={() => navigate(`profile`)}>
            <Circle>
              <UserIconAnalytics />
            </Circle>
            <Content>Account</Content>
          </CircleWrapper>
        </GridBox>
      </BoxWrapper>
    </QuickStartWrapper>
  );
};

const QuickStartAutomate = (props: any) => {
  function navigate(to: string) {
    const { workspacename } = props.match.params;
    props.history.push(`/workspace/${workspacename}/${to}`);
  }
  const currentBot = props.selectedBot as {
    bot: string;
    id: string;
  };
  return (
    <QuickStartWrapper>
      <BoxWrapper className="automate_quick_section">
        <Header>Quick Start With</Header>
        <GridBox>
          <CircleWrapper>
            <div className="quickStartImg">
              <img
                src={quickStartImage}
                width="100%"
                height="100%"
                alt="Qucik start"
              />
            </div>
          </CircleWrapper>
          <CircleWrapper
            onClick={() =>  navigate(`automation/view/home?create-workflow=true`)}
          >
            <Circle>
              <ApptriggerIcon />
            </Circle>
            <Content>Create App Trigger</Content>
          </CircleWrapper>
          <CircleWrapper
            onClick={() =>
              currentBot.id.length > 0
                ? navigate(`dialog/${currentBot.bot}/dialogs?create-faq=true`)
                :  navigate(`automation/view/home?create-workflow=true`)
            }
          >
            <Circle>
              <WebhooksIcon />
            </Circle>
            <Content>Create Webhook</Content>
          </CircleWrapper>
          {/* <CircleWrapper
                        onClick={() =>
                            currentBot.id.length > 0
                                ? navigate(`dialog/${currentBot.bot}/dialogs?create-dialog=true`)
                                : navigate('dialog/homepage')
                        }
                    >
                        <Circle>
                            <UserTriggerIcon />
                        </Circle>
                        <Content>Create User Trigger</Content>
                    </CircleWrapper> */}
          {/* <CircleWrapper onClick={() => navigate(`integration/chat`)}>
                        <Circle>
                            <IntegrationIcon />
                        </Circle>
                        <Content>Connections</Content>
                    </CircleWrapper> */}
          <CircleWrapper
            onClick={() =>
              navigate(`marketplace/automation/select-application`)
            }
          >
            <Circle>
              <MarketPlaceIcon />
            </Circle>
            <Content>App Marketplace</Content>
          </CircleWrapper>
          <CircleWrapper onClick={() => navigate(`support`)}>
            <Circle>
              <SupportIcon />
            </Circle>
            <Content>Product Support</Content>
          </CircleWrapper>
          <CircleWrapper onClick={() => navigate(`profile`)}>
            <Circle>
              <UserIconAnalytics />
            </Circle>
            <Content>Profile</Content>
          </CircleWrapper>
          <CircleWrapper onClick={() => navigate(`billing`)}>
            <Circle>
              <SupportIcon />
            </Circle>
            <Content>Billing</Content>
          </CircleWrapper>
        </GridBox>
      </BoxWrapper>
    </QuickStartWrapper>
  );
};

const Paragraph = styled.p`
width: 100%;
float: left;
color: #494949;
text-align: left;
margin-bottom: 20px;
font-family: 'Gordita-Regular';
font-size: 14px;
}
`;
const Count = styled.h3`
  width: 100%;
  float: left;
  color: #000000;
  text-align: left;
  margin-bottom: 0px;
  font-family: 'Gordita-Medium';
  font-size: 24px;

  span {
    font-family: 'Gordita-Regular';
    font-size: 12px;
    color: #355d8b;
    padding-left: 5px;
  }
`;

const ContentWrapper = styled.div`
  width: auto;
  float: left;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${Paragraph} {
    font-size: 16px;
    margin-bottom: 0px;
  }
`;

const Cards = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #0000001a;
  border: 1px solid #cecece;
  border-radius: 10px;
  padding: 12px 12px 10px 12px;
  height: 100px;
  justify-content: space-between;
  display: flex;
  align-items: inherit;
  flex-direction: column;
  justify-content: space-between;
  margin: 5px 0;
  ${ContentWrapper} {
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
  svg {
    width: 64px;
    height: 64px;
    float: left;
    margin-right: 10px;
  }
  ${Paragraph} {
    color: #315176;
    font-size: 12px;
    color: #355d8b;
    line-height: 19px;
    margin-bottom: 0px;
    font-family: 'Gordita-Medium';
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 18px;

    span {
      width: 12px;
      height: 12px;

      svg {
        width: 12px;
        height: 12px;
        fill: #999999;
        color: #999999;
        cursor: pointer;
      }
    }
  }
  ${Count} {
    color: #000000;
    font-size: 24px;
    line-height: 24px;
    font-family: 'Gordita-Medium';

    span {
      font-siz: 12px;
      color: #355d8b;
      line-height: 19px;
      font-family: 'Gordita-Regular';
    }
  }
`;
const CardsContainer = styled.div`
  width: 100%;
  float: left;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
  grid-gap: 20px;

  @media (max-width: 1500px) {
    grid-gap: 10px;
  }
`;
const CardsWrapper = styled.div`
  width: 100%;
  float: left;

  ${BoxWrapper} {
    padding: 30px 20px 20px;
    height: 100%;
    justify-content: center;
    .img_tag_center {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .img_tag_center.help_doc {
      background: #edf1ff;
      height: 280px;
      border: 1px solid #c4c4c4;
      border-radius: 10px;

      .img_center_tag {
        padding: 30px;
      }

      .home_product_container {
        bottom: 65px;
        left: 40%;
        top: auto;

        @media (min-width: 1920px) {
          left: 45%;
        }
        @media (min-width: 1280px) {
          bottom: 55px;
        }
      }
      @media (min-width: 2160px) {
        height: 280px;
      }
      @media (max-width: 1920px) {
        height: 240px;
      }
      @media (max-width: 1700px) {
        height: 240px;
      }
      @media (max-width: 1536px) {
        height: 220px;
      }
    }
    ${Header} {
      margin-bottom: 14px;
      line-height: normal;
      font-size: 18px;
    }
  }
`;

export type BotDetailsT = {
  user_count: number;
  conversation_count: number;
  satisfied_count: number;
  not_satisfied_count: number;
  day_wise_status_count: {
    date: string;
    resolved: number;
    abandoned: number;
    escalated?: undefined;
  }[];
  top_5_resolved_count: {
    dialog: string;
    dialog_id: string;
    status_count: number;
  }[];
  top_5_escalated_count: {
    dialog: string;
    dialog_id: string;
    status_count: number;
  }[];
  top_5_abandoned_count: {
    dialog: string;
    dialog_id: string;
    status_count: number;
  }[];
  botId?: string;
};

export const BotSummary = (props: {
  botDetails: BotDetailsT;
  bots: AssistantT[];
  selectedBot: {
    bot: string;
    id: string;
  };
}) => {
  const { botDetails, bots, selectedBot } = props;

  const bot = bots.filter((b) => b.id == selectedBot.id)[0];
  // console.log(botDetails, 'botDetails')
  return (
    <CardsWrapper>
      <BoxWrapper>
        <Header>Bot Summary</Header>
        <CardsContainer>
          <Cards>
            <ContentWrapper>
              <Paragraph>
                Total bot sessions{' '}
                <Tooltip
                  className="target customTip"
                  zIndex={5000}
                  arrowSize={8}
                  tagName="span"
                  content="Total number of bot sessions between bot and user in a specific time period."
                  distance={5}
                >
                  <InfoIcon />
                </Tooltip>
              </Paragraph>
              <Count>{botDetails.conversation_count}</Count>
            </ContentWrapper>
          </Cards>
          <Cards>
            <ContentWrapper>
              <Paragraph>
                Bot auto resolved{' '}
                <Tooltip
                  className="target customTip"
                  zIndex={5000}
                  arrowSize={8}
                  tagName="span"
                  content="Total number of bot sessions that are auto resolved by the bot in a specific time period."
                  distance={5}
                >
                  <InfoIcon />
                </Tooltip>
              </Paragraph>
              <Count>
                {botDetails.day_wise_status_count.reduce(
                  (acc, val) =>
                    (acc += val.resolved ? (val.resolved as any) : 0),
                  0
                )}
              </Count>
            </ContentWrapper>
          </Cards>
          <Cards>
            <ContentWrapper>
              <Paragraph>
                Agent hours saved{' '}
                <Tooltip
                  className="target customTip"
                  zIndex={5000}
                  arrowSize={8}
                  tagName="span"
                  content="Total number of agent hours saved by the bot by auto resolving user issues and requests in a specific time period."
                  distance={5}
                >
                  <InfoIcon />
                </Tooltip>
              </Paragraph>
              <Count>
                {botDetails.top_5_resolved_count.reduce(
                  (acc, val) => (acc += val.status_count),
                  0
                ) * (bot ? bot.avg_time_spend_by_agent : 0)}{' '}
                <span>hrs</span>
              </Count>
            </ContentWrapper>
          </Cards>
          <Cards>
            <ContentWrapper>
              <Paragraph>
                Cost savings{' '}
                <Tooltip
                  className="target customTip"
                  zIndex={5000}
                  arrowSize={8}
                  tagName="span"
                  content="Total cost saved to the company by the bot by auto resolving user issues and requests in a specific time period."
                  distance={5}
                >
                  <InfoIcon />
                </Tooltip>
              </Paragraph>
              <Count>
                $
                {botDetails.top_5_resolved_count.reduce(
                  (acc, val) => (acc += val.status_count),
                  0
                ) * (bot ? bot.avg_cost_to_resolve_an_issue : 0)}{' '}
              </Count>
            </ContentWrapper>
          </Cards>
        </CardsContainer>
      </BoxWrapper>
    </CardsWrapper>
  );
};

const BottomWrapper = styled.section`
  width: 100%;
  float: left;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  grid-gap: 20px;
  display: grid;
  margin-top: 20px;
  padding-bottom: 60px;
`;

const AutomateBottomWrapper = styled.section`
  width: 100%;
  float: left;
  grid-gap: 20px;
  display: grid;
  grid-template-columns: 42% 28% 1fr;
  margin-top: 20px;
  padding-bottom: 90px;

  ${CardsContainer} {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(35%, 1fr));
    grid-column-gap: 20px;

    @media (max-width: 1500px) {
      grid-column-gap: 10px;
    }
  }
`;

export const SummaryWrapper = (props: {
  workFlowSummary: {
    totalAutoResolved: number;
    totalExecution: number;
    activeWorkflows: number;
  };
  botDetails: BotDetailsT;
  bots: AssistantT[];
  selectedBot: {
    bot: string;
    id: string;
  };
  workspace: string;
}) => {
  return (
    <BottomWrapper>
      <BotSummary
        botDetails={props.botDetails}
        bots={props.bots}
        selectedBot={props.selectedBot}
      />
      <WorkflowSummary {...props} />
      <HowToVideos workspace={props.workspace} />
    </BottomWrapper>
  );
};

export const SummaryWrapperAutomate = (props: {
  workFlowSummary: {
    totalAutoResolved: number;
    totalExecution: number;
    activeWorkflows: number;
  };
  workspace: string;
}) => {
  return (
    <AutomateBottomWrapper>
      <WorkflowSummary workFlowSummary={props.workFlowSummary} />
      <HelpDocuments workspace={props.workspace} />
      <HowToVideos workspace={props.workspace} />
    </AutomateBottomWrapper>
  );
};

const CardsRow = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #0000001a;
  border: 1px solid #cecece;
  border-radius: 10px;
  padding: 20px 30px 18px 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  grid-gap: 20px;

  ${Cards} {
    background: inherit;
    padding: 0px;
    border: none;
    box-shadow: none;
    border-radius: 0px;
  }

  @media (max-width: 1700px) {
    padding: 20px 20px 16px 20px;
  }
  @media (max-width: 1536px) {
    padding: 15px 15px 10px 15px;
    grid-gap: 15px;
  }
`;
const WorkflowWrapper = styled.div`
  width: 100%;
  float: left;
  ${CardsContainer} {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    grid-gap: 20px;
  }
`;

export const WorkflowSummary = (props: {
  workFlowSummary: {
    totalAutoResolved: number;
    totalExecution: number;
    activeWorkflows: number;
  };
}) => {
  const { workFlowSummary } = props;
  return (
    // <WorkflowWrapper>
    <CardsWrapper>
      <BoxWrapper>
        <Header>App Workflow Summary</Header>
        <CardsContainer>
          {/* <CardsRow> */}
          <Cards>
            <ContentWrapper>
              <Paragraph>
                Total active workflows{' '}
                <Tooltip
                  className="target customTip"
                  zIndex={5000}
                  arrowSize={8}
                  tagName="span"
                  content="Total number of app workflows that are active."
                  distance={5}
                >
                  <InfoIcon />
                </Tooltip>
              </Paragraph>
              <Count>{workFlowSummary.activeWorkflows}</Count>
            </ContentWrapper>
          </Cards>

          {/* <Cards>
            <ContentWrapper>
              <Paragraph>
                Total workflow executions{' '}
                <Tooltip
                  className="target customTip"
                  zIndex={5000}
                  arrowSize={8}
                  tagName="span"
                  content="Total number of app workflow executions in a specific time period."
                  distance={5}
                >
                  <InfoIcon />
                </Tooltip>
              </Paragraph>
              <Count>{workFlowSummary.totalExecution}</Count>
            </ContentWrapper>
          </Cards> */}
          

          <Cards>
            <ContentWrapper>
              <Paragraph>
                Total successful executions{' '}
                <Tooltip
                  className="target customTip"
                  zIndex={5000}
                  arrowSize={8}
                  tagName="span"
                  content="Total number of successful workflow executions in selected time period."
                  distance={5}
                >
                  <InfoIcon />
                </Tooltip>
              </Paragraph>
              <Count>{workFlowSummary.totalAutoResolved}</Count>
            </ContentWrapper>
          </Cards>
          <Cards>
            <ContentWrapper>
              <Paragraph>
                Agent hours saved{' '}
                <Tooltip
                  className="target customTip"
                  zIndex={5000}
                  arrowSize={8}
                  tagName="span"
                  content="Total number of agent hours saved."
                  distance={5}
                >
                  <InfoIcon />
                </Tooltip>
              </Paragraph>
              <Count>
                {workFlowSummary.totalAutoResolved}
                <span>hrs</span>
              </Count>
            </ContentWrapper>
          </Cards>
          {/* <Cards>
            <ContentWrapper>
              <Paragraph>
                Total failed executions{' '}
                <Tooltip
                  className="target customTip"
                  zIndex={5000}
                  arrowSize={8}
                  tagName="span"
                  content="Total number of app workflow executions that failed in a specific time period."
                  distance={5}
                >
                  <InfoIcon />
                </Tooltip>
              </Paragraph>
              <Count>
                {workFlowSummary.totalExecution -
                  workFlowSummary.totalAutoResolved}
              </Count>
            </ContentWrapper>
          </Cards> */}
          <Cards>
            <ContentWrapper>
              <Paragraph>
                Cost savings{' '}
                <Tooltip
                  className="target customTip"
                  zIndex={5000}
                  arrowSize={8}
                  tagName="span"
                  content="Total number of cost saving."
                  distance={5}
                >


                  <InfoIcon />
                </Tooltip>
              </Paragraph>
              <Count>{workFlowSummary.totalAutoResolved}</Count>
            </ContentWrapper>
          </Cards>
          {/* </CardsRow> */}
        </CardsContainer>
      </BoxWrapper>
    </CardsWrapper>
    // </WorkflowWrapper>
  );
};

const HowToVideos = (props: any) => {
  return (
    <VideosWRapper>
      <CardsWrapper>
        {/* <a href={`/workspace/${props.workspace}/help/videos`}> */}
        <a href="" target="_blank">
          <BoxWrapper>
            <Header>Product Explainer Videos</Header>
              <div className="img_tag_center">
              <div className="img_center_tag">
                <img
                  src={productVideos}
                  width="100%"
                  height="100%"
                  alt="Automate videos page"
                />
                <div className="home_product_container">
                  <VideoIcon />
                  <div className="home_product">
                    <h6>Learn from product explainer videos</h6>
                    <p>
                      <a href={`/workspace/${props.workspace}/help/videos`}>
                        Watch now!
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              </div>
          </BoxWrapper>
        </a>
      </CardsWrapper>
    </VideosWRapper>
  );
};

const HelpDocuments = (props: any) => {
  return (
    <VideosWRapper>
      <CardsWrapper>
        {/* <a href={`/workspace/${props.workspace}/help/videos`}> */}
        <a
          href="https://help.workativ.com/kb/workativ-automate/"
          target="_blank"
        >
          <BoxWrapper>
            <Header>Product Help Documents</Header>
            <div className="img_tag_center help_doc">
              <div className="img_center_tag">
                <img
                  src={productHelpDoc}
                  width="100%"
                  height="100%"
                  alt="Automate help page"
                />
                <div className="home_product_container">
                  {/* <VideoIcon /> */}
                  <div className="home_product">
                    {/* <h6>
                                            Product help document
                                        </h6> */}
                    <p>
                      <a
                        href="https://help.workativ.com/kb/workativ-automate/"
                        target="_blank"
                      >
                        Learn more!
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </BoxWrapper>
        </a>
      </CardsWrapper>
    </VideosWRapper>
  );
};

export const DropdownLi = styled.div`
  width: 100%;
  float: left;
  display: inline-flex;
  align-items: center;
  border-bottom: 0.5px solid #e5e5e5;
  font-size: 12px;
  font-family: 'Gordita-Regular';
  height: 36px;
  padding: 0 12px;
  background: #fff;
  cursor: pointer !important;
  color: #000;
  label {
    width: auto;
    float: left;
    margin-bottom: 0px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 24px;
      height: 24px;
    }
  }

  :first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background: #fff;
  }

  :last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background: #fff;
  }

  :hover {
    background: #ffe5d3;
    color: #333;
    border-bottom: 0.5px solid #ffe5d3;

    svg {
      fill: #333;
    }
  }
`;

export const DropdownUl = styled.div`
  width: 100%;
  position: absolute;
  margin-top: 48px;
  right: 0px;
  left: inherit;
  cursor: pointer;
  padding: 0px;
  max-height: max-content;
  margin-bottom: 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 10px #00000029;
  border-radius: 19px;
  z-index: 3;
`;
export const DialogCount = styled.div`
  width: auto;
  height: 48px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 12px;

  svg {
    width: 24px;
    height: 24px;
    fill: #355d8b;
  }
  sup {
    width: 16px;
    height: 16px;
    background: #d5ffee 0% 0% no-repeat padding-box;
    border: 2px solid #d5ffee;
    border-radius: 50%;
    top: 6px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
    right: -4px;
    font-size: 8px;
    font-family: 'Gordita-Regular';

    label {
      position: relative;
      top: 1px;
      margin-bottom: 0px;
      right: 0;
      width: auto;
      height: auto;
    }
  }
`;
export const InputWrapper = styled.div`
  width: 100%;
  float: left;
  label {
    width: 16px;
    height: 16px;
    margin: 0px;
    position: absolute;
    right: 12px;
    top: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
  .url_input_box {
    position: absolute;
    bottom: 88px;
    z-index: 999;
    width: 90%;
    // border: 10px solid #cdebfe;
    border-radius: 10px;
    margin-top: 10px;
  }

  svg {
    position: absolute;
    left: 340px;
    width: 16px;
    height: 16px;
  }
`;
export const Input = styled.input.attrs((boolean: any) => ({
  required: true,
}))`
  width: 416px;
  float: left;
  padding: ${(props) =>
    props.padding ? '26px 12px 8px 12px;' : '26px 34px 8px 12px'};
  border: 1px solid #b9b9b9;
  border-radius: 6px;
  background: #fff;
  height: 48px;
  font-size: 12px;
  font-family: 'Gordita-Regular';
  color: #000000;

  :hover {
    outline: 0;
    border: 1px solid #eb974e;
    box-shadow: 0px 3px 6px #00000029;
  }

  ${({ activeDropdown }) =>
    activeDropdown &&
    `
border: 1px solid #EB974E;

`}
`;
export const InputContent = styled.div`
  color: #999;
  font-size: 12px;
  font-family: 'Gordita-Regular';
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 16px;
  transition: 0.26s ease-in;
  ${Input}:focus ~ &,
    ${Input}:not(:placeholder-shown).input:not(:focus) ~ .label,
    ${Input}:valid ~ & {
    top: 10px;
    font-size: 10px;
    transition: 0.26s ease-out;
    line-height: 10px;
  }
`;

export const DropdownWrapper = styled.div<any>`
  width: 416px;
  float: left;
  position: relative;
  margin-bottom: ${(props) => (props.last === true ? '0px' : '20px')};
  display: flex;

  span {
    position: absolute;
    top: 15px;
    width: 16px;
    height: 16px;
    right: 12px;
    transform: rotate(0deg);

    svg {
      width: 16px;
      height: 16px;
      color: #555555;
    }
  }
  ${InputContent} {
    left: 44px;
  }
  ${Input} {
    padding-left: 44px;
  }
`;

const DropdownContainer = (props: {
  bots: AssistantT[];
  setSelectedBot: Function;
  selectedBot: {
    bot: string;
    id: string;
  };
}) => {
  const { bots, selectedBot, setSelectedBot } = props;

  const [open, setOpen] = useState(false);
  const [botId, setBotId] = useState(1);

  return (
    <DropdownWrapper>
      <InputWrapper>
        <Input
          value={selectedBot.id.length > 0 ? selectedBot.bot : 'Select'}
          onClick={() => setOpen(true)}
          style={bots.length > 0 ? {} : { padding: '0px 32px 0px 12px' }}
        />
        <InputContent>Select Bot</InputContent>
        {bots.length > 0 && (
          <>
            <DialogCount>
              <DialogIcon />
              <sup>
                <label>{botId}</label>
              </sup>
            </DialogCount>

            <label>
              <BotDropdown />
            </label>
          </>
        )}
      </InputWrapper>

      {bots.length > 0 && open && (
        <Downshift
          isOpen={true}
          onOuterClick={() => {
            setOpen(false);
          }}
        >
          {() => (
            <div>
              <DropdownUl>
                {sortAnArrayByDate(bots, 'updated').map((b, i) => (
                  <DropdownLi
                    key={i}
                    onClick={() => {
                      setOpen(false);
                      setSelectedBot({ id: b.id, bot: b.name });
                      setBotId(i + 1);
                    }}
                  >
                    <label>
                      <DialogIcon />
                    </label>
                    {b.name}
                  </DropdownLi>
                ))}
              </DropdownUl>
            </div>
          )}
        </Downshift>
      )}
    </DropdownWrapper>
  );
};

const EventsLinkMap = [
  {
    event: 'EVENT::BOT::CREATED',
    link: 'https://help.workativ.com/knowledgebase/create-an-assistant/',
    text: 'How to create Chatbot',
    priority: 1,
    image: dialogImage,
    videoId: 'DOWNLOAD_AND_SET_UP_PRE_BUILT_CHATBOT',
  },
  {
    event: 'EVENT::FAQ::CREATED',
    link: 'https://help.workativ.com/knowledgebase/create-faq/ ',
    text: 'How to create FAQ',
    priority: 2,
    image: faqImage,
    videoId: 'START_WITH_FAQ',
  },
  {
    event: 'EVENT::DIALOG::TESTED',
    link: 'https://help.workativ.com/knowledgebase/testing-your-dialog-flow/',
    text: 'How to test the Chatbot',
    priority: 3,
    image: testImage,
    videoId: 'USE_TRY_ME',
  },
  {
    event: 'EVENT::DIALOG::CREATED',
    link: 'https://help.workativ.com/knowledgebase/building-a-dialog/',
    text: 'How to create a Dialog',
    priority: 4,
    image: dialogImage,
    videoId: 'START_WITH_CONVERSATION',
  },
  {
    event: 'EVENT::WORKFLOW::CREATED',
    link: 'https://help.workativ.com/knowledgebase/creating-an-automation/',
    text: 'How to create an App workflow',
    priority: 5,
    image: workflowImage,
    videoId: 'CREATE_A_APP_WORKFLOW',
  },
  {
    event: 'EVENT::CHATCHANNEL::DEPLOYED',
    link: 'https://help.workativ.com/kb/workativ-assistant/integrations/',
    text: 'How to deploy chatbot in Chat Channels',
    image: deployImage,
    priority: 6,
    videoId: 'DEPLOY_BOT_AS_CHAT_WIDGET_IN_WEBPAGE',
  },
];
