import { ActionT } from '../kind'
import { ExpressionT, DraftJST, TextT } from '../Editor/Action'

export function isJumpTo(
    jumpToparam: FAQNextStep | next_step
): jumpToparam is {
    jump_to: JumpToT
} {
    return (
        (jumpToparam as {
            jump_to: JumpToT
        }).jump_to !== null &&
        (jumpToparam as {
            jump_to: JumpToT
        }).jump_to !== undefined
    )
}

export type next_step =
    | 'wait_for_user_input'
    | { exit: MsgT }
    | { exit_with_feedback: MsgT }
    | {
          jump_to: JumpToT
      }

export interface DialogueExitT extends IfNodeT {
    next_step: { 
        exit: MsgT     
        update_query_status_as: "resolved" | "unresolved" | "ignore"
        mark_ticket_created:boolean
    } | { 
        exit_with_feedback: MsgT
        update_query_status_as: "resolved" | "unresolved" | "ignore"
        mark_ticket_created:boolean

    }
}

export interface IfNodeT {
    type: 'if_node'
    id: string
    uuid: string
    title: string
    description: string
    conditions: any
    responses: ResponseNodeE[]
    parent: string | null
    previous_sibling: string | null
    next_step: next_step
    updated: string
    created: string
    allow_digression: boolean
    allow_digression_into: boolean
    allow_return_from_here: boolean
}
export type DialogueT = FAQT | IfNodeT
export interface FAQT {
    type: 'faq_node'
    id: string
    uuid: string
    title: string
    description: string
    conditions: any
    responses: ExpressionT
    parent: string | null
    previous_sibling: string | null
    next_step: FAQNextStep
    updated: string
    created: string
}
type FAQNextStep =
    | {
          jumpto: {
              selector: 'body'
              to: string
          }
      }
    | { exit: { message: ExpressionT } }
export interface JumpToT {
    to: string
    selector: JumpToSelectorT
    clear_context_variables:boolean
    update_query_status_as: "resolved" | "unresolved" | "ignore"
    mark_ticket_created:boolean
}

export interface MsgT {
    message: ExpressionT
}

export enum JumpToSelectorT {
    user_input = 'user_input',
    condition = 'condition',
    body = 'body'
}

export enum PasteToSelectorT {
    above = 'above',
    below = 'below',
}

export type ResponseNodeE =
    | TextNodeT
    | OptionT
    | QuestionT
    | SlotContainerT
    | AutomationT
    | AdaptiveServerValueT
    | AttachmentT
    | AgentHandoffT
    | VariableValueT

export interface TextValueT {
    type: 'text'
    value: string
}

export interface TextNodeT {
    type: 'text'
    id: string
    selection_policy: 'sequential' | 'random'
    values: ExpressionT[]
    previous_sibling: string | null
    parent: string
}

export interface OptionsValueT {
    label: TextValueT
    value: DraftJST
}

export interface OptionsNewValueT {
    label: TextValueT
    value: ExpressionT
}

export interface VariableValueT {
    type: 'context_variable'
    id: string
    previous_sibling: string | null
    parent: string
    variables: {
        name: string
        expression: ExpressionT
    }[]
}

export interface AdaptiveValueT {
    label: TextValueT
    value: DraftJST
}

export interface AdaptiveServerFieldValueT {
    title: TextValueT
    value: ExpressionT
}

export interface OptionT {
    id: string
    type: 'option'
    prompt_message: ExpressionT
    parent: string
    previous_sibling: string | null
    options: OptionsNewValueT[]
}

export interface AttchValuesT {
    prompt: TextT
    category: string
    variable: string
    error_message: TextT
}
export interface AttachmentT {
    id: string
    type: 'file_upload'
    parent: string
    previous_sibling: string | null
    prompt: TextT
    category: string
    variable: string
    file_exit_conditions: SlotExitConditions[]
    allow_digression: boolean
    error_message: TextT
}

export interface AgentHandoffT {
    id: string
    type: 'agent_handoff'
    platform: string
    agent_id: string
    parent: string
    previous_sibling: string | null
    agent_message:ExpressionT
}

export interface AdaptiveT {
    id: string
    type: 'adaptive_card'
    title: TextT
    description: DraftJST
    image: TextT | null
    parent: string
    previous_sibling: string | null
    fields: AdaptiveValueT[]
    action_buttons: AdaptiveValueT[]
    collection: { path: null | string[] }
    collectionData?: { values: any; app: any }
}

export interface AdaptiveServerValueT {
    id: string
    type: 'adaptive_card'
    title: TextT
    image: TextT | null
    description: TextT
    parent: string
    previous_sibling: string | null
    fields: AdaptiveServerFieldValueT[]
    action_buttons: AdaptiveServerFieldValueT[]
    collection: { path: string[] } | null
}

export interface QuestionT {
    id: string
    type: 'ask_a_question'
    question: ExpressionT
    parent: string
    previous_sibling: string | null
    options: OptionsValueT[]
    variable: string
    mask_input: boolean
}

export type SlotConditionT =
    | {
          type: 'IntentExpression'
          value: string
      }
    | {
          type: 'EntityExpression'
          entity: string
      }

type TextExpression = NormalTextExpression | PathExpression | JoinExpression

interface NormalTextExpression {
    type: 'text'
    value: string
}

interface PathExpression {
    type: 'path'
    path: string[]
}

interface JoinExpression {
    type: 'join'
    lhs: TextExpression
    rhs: TextExpression
}

export interface SlotExitConditions {
    condition: Condition
    response: TextExpression
    next_step: SlotNextStep
}

type Condition =
    | WelcomeExpression
    | IntentExpression
    | EntityExpression
    | EntityValueExpression
    | LogicalExpression
    | FallBackExpression

interface WelcomeExpression {
    type: 'WelcomeExpression'
}

interface FallBackExpression {
    type: 'FallBackExpression'
}

/** @nullable */
type OptionalNegation = boolean

interface IntentExpression {
    type: 'IntentExpression'
    value: string
}

interface EntityExpression {
    type: 'EntityExpression'
    entity: string
}

interface EntityValueExpression {
    type: 'EntityValueExpression'
    entity: string
    value: string
    negation?: OptionalNegation
}

interface AndLogicalExpression {
    type: 'LogicalExpression'
    and: {
        lhs: Condition
        rhs: Condition
    }
}

interface OrLogicalExpression {
    type: 'LogicalExpression'
    or: {
        lhs: Condition
        rhs: Condition
    }
}

type LogicalExpression = AndLogicalExpression | OrLogicalExpression

type SlotNextStep =
    | { exit: { message: TextExpression } }
    | { exit_with_feedback: { message: TextExpression } }
    | {
          jump_to: JumpTo
      }

type NextStep = 'wait_for_user_input' | SlotNextStep

export interface JumpTo {
    to: string
    selector: 'condition' | 'body' | 'user_input'
}

export type SaveSlotAsT = 'value' | 'literal'

export type SlotPromptT = string | null

export interface slotsT {
    condition: SlotConditionT
    variable: string
    save_as: SaveSlotAsT
    prompt: SlotPromptT
    slot_exit_conditions: SlotExitConditions[]
    allow_digression: boolean
    supress_prompt_if_variable_not_empty: boolean
}

export interface SlotContainerT {
    id: string
    type: 'slot_container'
    slots: slotsT[]
    parent: string
    previous_sibling: string | null
}

export interface AutomationT {
    id: string
    type: 'automation'
    name: string
    automation_name: string
    automation: string
    input: any
    output_variable: string
    parent: string
    previous_sibling: string | null
    automation_type: 'approval' | 'automation'
    on_sucess?: {
        response: ExpressionT
        next_step:
            | {
                  jump_to: {
                      to: string
                      selector: string
                  }
              }
            | { exit: { message: string } }
    }
    on_failure?: {
        response: ExpressionT
        next_step:
            | {
                  jump_to: {
                      to: string
                      selector: string
                  }
              }
            | { exit: { message: string } }
    }
}

export const DefaultDialogue: IfNodeT = {
    type: 'if_node',
    id: 'somethingWentWrong',
    uuid: 'somethingWentWrong',
    title: '',
    description: '',
    conditions: {},
    responses: [],
    parent: '',
    previous_sibling: '',
    next_step: 'wait_for_user_input',
    updated: '',
    created: '',
    allow_digression: false,
    allow_digression_into: false,
    allow_return_from_here: false
}

export interface VIEW_T {
    action: ActionT.VIEW
}

export const VIEW: VIEW_T = {
    action: ActionT.VIEW
}

export interface LOADING_T {
    action: ActionT.LOADING
}

export const LOADING: LOADING_T = {
    action: ActionT.LOADING
}

export interface UN_AUTHORIZED_T {
    action: ActionT.UN_AUTHORIZED
}

export interface RESOURCE_NOT_FOUND_T {
    action: ActionT.RESOURCE_NOT_FOUND
    resource: 'WORKSPACE' | 'ASSISTANT'
}

export interface NO_DATA_FOUND_T {
    action: ActionT.NO_DATA_FOUND
    loading: boolean
    showPopup: boolean
    title: string
    description: string
    show: 'workflow' | 'dialog-only'
}

export interface ERROR_T {
    action: ActionT.ERROR
    reason: string
}

export interface ADD_T {
    action: ActionT.ADD
    loading: boolean
    title: string
    description: string
}

export interface EDIT_T {
    action: ActionT.EDIT
    loading: boolean
    uuid: string
    id: string
    title: string
    description: string
}

export interface DELETE_T {
    action: ActionT.DELETE
    data: { uuid: string; title: string; type: 'if_node' | 'faq_node' }
    loading: boolean
}

export interface MULTIPLE_DELETE_T {
    action: ActionT.MULTIPLE_DELETE
    items: string[]
    loading: boolean
}

export interface SELECT_T {
    action: ActionT.SELECT
    items: string[]
}

export interface DEPLOY_T {
    action: ActionT.DEPLOY
    loading: boolean
}
export interface FAQ_T {
    action: ActionT.CREATE_FAQ
    loading: boolean
}
export interface UPDATE_FAQT {
    action: ActionT.UPDATE_FAQ
    value: any
    loading: boolean
}

export type PageStateT =
    | VIEW_T
    | LOADING_T
    | UN_AUTHORIZED_T
    | RESOURCE_NOT_FOUND_T
    | NO_DATA_FOUND_T
    | ERROR_T
    | ADD_T
    | EDIT_T
    | DELETE_T
    | DEPLOY_T
    | MULTIPLE_DELETE_T
    | SELECT_T
    | FAQ_T
    | UPDATE_FAQT
