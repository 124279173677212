import Downshift from 'downshift'
import * as R from 'ramda'
/*eslint-disable*/
import React, { useEffect, useState } from 'react'
import Tooltip from 'react-tooltip-lite'
import { Bar, BarChart, Tooltip as BarchartTip, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { useGenericCached } from '@/common/hooks/useCachedQuery'
import { AutomationApi,wrapperGetJson,wrapperPostJson } from '@/common/utils/api-utils'
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons'

import { HeaderContainer } from '@/DialogEditor/Canvas/Components/WelcomePageWithJourney'
import { BotDropdown, FlowIcon, InfoIcon, SubPanelIcons } from '@/common/Icons/Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { Header, HeaderChild } from '@/common/components/Header'
import { Loader } from '@/common/components/Loader'
import { VideoButtonB } from '@/Videos/Videos'
import {
    BotCard,
    BotCardWrapper,
    // Card,
    // CardContainer,
    // CardHeader,
    ChartFeedbackWrapper,
    ChartWrapper,
    ChatWrapperLeft,
    ChatWrapperRight,
    Content,
    CountWrapper,
    DropdownLi,
    DropdownLiSpan,
    DropdownUl,
    DropdownWrapper,
    HeaderTop,
    HeaderWrapper,
    Input,
    InputContent,
    NodataContent,
    ProgressBar,
    ProgressContent,
    ProgressContentWithoutHover,
    ProgressLi,
    ProgressWrapper,
    Span,
    // Header as StatusHeader,
    StatusWrapper,
    SettingsWrapper,
    Wrapper,
    HoverContent,
    InputWrapper,
} from '@/common/styled/Dialog.BotDetails.Dumb'
import { ResponsivePie } from '@nivo/pie'
import { SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { automationAPIURLWithWorkspace, logEvent, postJson, stripeGetApi,putJson } from '@/common/utils/api-utils'
import { DateSelectInput } from './dateDropDown'
import {
    AnalyticsSuccessResponse,
    AnalyticsSuccessResponseT,
    ApprovalChartDataT,
    AutomationListT,
    ChartDataT
} from './types'
import { getProductId } from '@/common/utils/utils'
import { useMutation,useQueryClient } from 'react-query'
import styled from 'styled-components'

export const CountNumber = styled.div`
    width: 100%;
    float: left;
    font-size: 24px;
    font-family: 'Gordita-Medium';
    color: #000;
    display: flex;
    justify-content: space-between;
    line-height: 24px;
    align-items: center;
    padding: 10px 20px;
`

export const StatusHeader = styled.div`
    position: relative;
    margin-bottom: 0px !important;
    width: 100%;

    h4{
        font-size: 16px;
        font-family: 'Gordita-Medium';
        color: #000;
        margin-bottom: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        
        .target_bot{
            color: #999999;
            width: 16px;
            height: 16px;
            display: block;
            bottom: 5px;
            cursor: pointer;
        }
    }

    p{
        color: #355D8B;
        margin-bottom: 0;
        font-size: 13px;
        font-family: 'Gordita-Regular';
        margin-top: 0px;
    }

`

export const PieContent = styled.div`
    width: 100%;
    float: left;
    display: flex;
    flex-direction: row;
    white-space: pre;
    display: flex;
    justify-content: center;
    gap: 20px;

    div {
        width: auto;
        font-size: 12px;
        font-family: 'Gordita-Regular';
        color: #000;
        position: relative;
        // margin-left: 20px;

        :nth-child(1){
            margin-bottom: 20px;
            display: flex;

            .target.customTip{
                position: absolute !important;
                right: 14px;
                cursor: pointer;
                top: 1px;
                @media (max-width: 1500px) {
                    right: -22px;
                }
                
                svg{
                    color: #999999;
                    width: 12px;
                    height: 12px;
                }
            }
        }

        span {
            width: 16px;
            height: 16px;
            float: left;
            background: #fff;
            position: relative;
            border-radius: 50%;
            margin-right: 6px;
        }
        ._dot_1 {
            background: #7AE3DC;
        }
        ._dot_2 {
            background: #FF7462;
        }
        ._dot_3 {
            background: #FFAB57;
        }
        ._dot_4 {
            background: #FBE699;
        }
        ._dot_5 {
            background: #EB6453;
        }
        ._dot_6 {
            background: #A1E89B;
        }
    }
`

export const CardHeader = styled.div`
    width: 100%;
    float: left;
    font-size: 12px;
    font-family: 'Gordita-Medium';
    color: #355D8B;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 20px;

    p {
        text-align: left;
        width: 100%;
        margin-bottom: 0px;
        height: 20px;
        font-size: 13px;
       
        span{
            color:#007BFF;
            font-family: 'Gordita-Medium';
        }

    }

    .target_bot{
        margin-left: 5px;
        bottom: 1px;
    }
`

export const Card = styled.div`
    width: 100%;
    float: left;
    // background: #EBFAFD 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 4px #0000001a;
    border: 1px solid #cecece;
    border-radius: 10px;
    // padding: 12px 10px;
    position: relative;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    :nth-child(1){
        svg{
            width: 32px;
            height: 32px;
            fill: #73A4DB;
        }
    }

    .target_bot {
        width: 12px;
        height: 12px;
        cursor: pointer;
        span {
            width: 12px;
            height: 12px;
            svg {
                position: absolute;
                width: 12px;
                height: 12px;
                color: #999999;
                fill: #999999;
            }
        }
    }
    
`
export const CardContainer = styled.div`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16%, 1fr));
    grid-gap: 20px;
    margin-top: 10px;
    @media (max-width: 1500px) {
        ${Card}:nth-child(2) .target_bot{
            bottom: 10px;
        }
    }
    @media (max-width: 1400px) {
        ${Card}:nth-child(3) .target_bot{
            bottom: 10px;
        }
    }
    @media (max-width: 1300px) {
        ${Card}:nth-child(1) .target_bot, 
        ${Card}:nth-child(4) .target_bot, 
        ${Card}:nth-child(5) .target_bot{
            bottom: 10px;
        }
    }
    
`

var approx = require('approximate-number')
const items: DateSelectInput[] = [
    {
        date: 'yesterday',
        label: 'Yesterday'
    },
    {
        date: 'today',
        label: 'Today'
    }
]

const m = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export function FlowAnalyticsHome(props: any) {
    // console.log('FlowAnalyticsHome ::: props', props)
    const [automations, setAutomations] = useState<AnalyticsSuccessResponseT>(AnalyticsSuccessResponse)

    const [loading, setLoading] = useState({
        show: false,
        error: { error: false, info: '' }
    })

    const [dropDownState, setDropDownState] = useState({
        showListPopup: false,
        selectedAutomation: { name: 'All', id: '', totalExecutions: '0' }
    })

    const [dateOptions, setDateOptions] = useState<DateSelectInput[]>(items)
    const [dateDropdown, setDateDropDownValue] = useState({
        showDatePopup: false,
        selectedDate: { date: 'today', label: 'Today' }
    })

    const [stripeLoaded, setStripeLoaded] = useState(false)
    const [initialApiCall, setInitialApiCall] = useState(true)
    
    useEffect(() => {
        setLoading({ ...loading, show: true })

        logEvent(workspaceName, {
            event:"EVENT::WORKFLOW_SUMMARY::VIEW", event_meta:{}
        })

        stripeGetApi(`${workspaceName}/info/logs_limit`)
            .then((resp: { output: string; type: 'success' }) => {
                const maxDays = Number(resp.output)
                const daysList = [30, 14, 7]
                    .filter(x => x <= maxDays)
                    .map(value => ({ date: `last ${value} days`, label: `Last ${value} Days` }))
                const reqest = {
                    timeInterval: `last ${maxDays} days`,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                }
                postJson(automationAPIURLWithWorkspace(workspaceName, '/automation/approval/summary'))(reqest).then(
                    res => {
                        setAutomations(res.data.output)
                        setDateOptions(daysList.concat(dateOptions))
                        setStripeLoaded(true)
                        setDateDropDownValue({
                            ...dateDropdown,
                            selectedDate: { date: `last ${maxDays} days`, label: `Last ${maxDays} Days` }
                        })
                        setLoading({ ...loading, show: false })
                    }
                )
            })
            .catch(err =>
                setLoading({
                    show: true,
                    error: {
                        error: true,
                        info: 'Server error!<br />Please try again after sometime.'
                    }
                })
            )
    }, [])
    // useEffect(() => {
    //     stripeLoaded && callApi(dropDownState.selectedAutomation)
    // }, [dropDownState.selectedAutomation])

    useEffect(() => {
        if (stripeLoaded && !initialApiCall) {
            callApi({
                name: 'All',
                id: '',
                totalExecutions: '0'
            })
        } else if (stripeLoaded && initialApiCall) {
            setInitialApiCall(false)
        }
    }, [dateDropdown.selectedDate])

    const workspaceName = props.match.params.workspacename

    function callApi(automation: AutomationListT) {
        const reqest =
            automation.name === 'All'
                ? {
                      timeInterval: dateDropdown.selectedDate.date,
                      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                      //   workspace: workspaceName
                  }
                : {
                      timeInterval: dateDropdown.selectedDate.date,
                      //   workspace: workspaceName,
                      automationId: automation.id,
                      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                  }

        setLoading({ ...loading, show: true })

        postJson(automationAPIURLWithWorkspace(workspaceName, '/automation/approval/summary'))(reqest)
            .then(res => {
                setAutomations(res.data.output)
                setLoading({ ...loading, show: false })
            })
            .catch(err =>
                setLoading({
                    show: true,
                    error: {
                        error: true,
                        info: 'Server error!<br />Please try again after sometime.'
                    }
                })
            )
    }

    const redirectToFlow = (id: string) => {
        const location = {
            pathname: `/workspace/${workspaceName}/automation/detail/${id}`,
            state: { group: false, flow: true }
        }
        props.history.push(location)
    }

    function dates() {
        return dateOptions.filter(it => it.date == dateDropdown.selectedDate.date)[0].label
    }
    return (
        <React.Fragment>
            <Header>
                <HeaderChild type="first">
                    <div className="indent_left">
                        <div className="indent_title">Workflow Summary</div>
                    </div>
                </HeaderChild>
                <HeaderChild type="second">
                    {/* <div className="second_intent_right">
                        <Dropdowns
                            displayItems={dateOptions}
                            state={dateDropdown}
                            setDateDropDownValue={setDateDropDownValue}
                            setSelectedDate={setSelectedDate}
                        />
                    </div> */}
                    {/* <div className="second_intent_right">
                        <DropdownWrapper>
                            <Input
                                activeDropdown={dateDropdown.showDatePopup}
                                onClick={() =>
                                    setDateDropDownValue({
                                        ...dateDropdown,
                                        showDatePopup: !dateDropdown.showDatePopup
                                    })
                                }
                                type="text"
                                value={dates()}
                            />
                            <InputContent>Show by</InputContent>
                            <span>
                                <BotDropdown />
                            </span>
                            {dateDropdown.showDatePopup && (
                                <Downshift
                                    isOpen={true}
                                    onOuterClick={() =>
                                        setDateDropDownValue({
                                            ...dateDropdown,
                                            showDatePopup: false
                                        })
                                    }
                                >
                                    {() => (
                                        <div>
                                            <DropdownUl>
                                                {dateOptions.map((op, i) => (
                                                    <DropdownLi
                                                        onClick={() => {
                                                            setDateDropDownValue({
                                                                showDatePopup: false,
                                                                selectedDate: op
                                                            })
                                                        }}
                                                    >
                                                        <DropdownLiSpan>{op.label}</DropdownLiSpan>{' '}
                                                    </DropdownLi>
                                                ))}
                                            </DropdownUl>
                                        </div>
                                    )}
                                </Downshift>
                            )}
                        </DropdownWrapper>
                    </div> */}
                    <VideoButtonB id={'CREATE_INTENT_WITH_EXAMPLES_HOW_IT_WORKS'} />
                </HeaderChild>
            </Header>
            <Wrapper>
                {loading.show ? (
                    <ErrorBoundary
                        error={loading.error}
                        render={(err: any, info: any) => {
                            return (
                                err && (
                                    <Error.PageLoadingError
                                        onClick={() => {
                                            setLoading({ show: true, error: { error: false, info: '' } })

                                            callApi(dropDownState.selectedAutomation)
                                        }}
                                        info={info}
                                        className={'analytics_page_error'}
                                        btnName="Retry"
                                    />
                                )
                            )
                        }}
                    >
                        <Loader.PageLoader show={true} className="analytics_loader" />
                    </ErrorBoundary>
                ) : (
                    <React.Fragment>
                        <DefaultInfoHeader
                            automations={automations}
                            dateDropdown={dateDropdown}
                            dateOptions={dateOptions}
                            dates={dates}
                            setDateDropDownValue={setDateDropDownValue}
                            workspaceName={workspaceName}
                        />

                        <ChartFeedbackWrapper>
                            <ChartWrapper chartsBg style={{gridTemplateColumns: '65% 1fr'}}>
                                <ChatWrapperLeft chartsPadding>
                                    <StatusHeader style={{ marginBottom: 0 }}>
                                        <h4>Workflow Executions: {automations.totalWorkflowExecutionCount}</h4>
                                    </StatusHeader>
                                    <div
                                        className={
                                            automations.workflowChartDetails.length > 0
                                                ? 'bar_charts_wrapper'
                                                : 'bar_no_charts_wrapper'
                                        }
                                    >
                                        <WorkflowBarChart
                                            workflowChartDetails={automations.workflowChartDetails}
                                            selectedDate={dateDropdown.selectedDate}
                                        />
                                    </div>
                                </ChatWrapperLeft>
                                {  getProductId() === "ASSISTANT" ? 
                                (
                                    <ChatWrapperRight chartsPadding style={{marginTop: 20, padding: '10px 20px'}}>
                                    <StatusHeader>
                                        <h4>Workflow Executions: {automations.totalWorkflowExecutionCount}</h4>
                                    </StatusHeader>
                                    <BotSessionsPieChat workflowChartDetails={automations.workflowChartDetails} />
                                    <PieContent style={{flexDirection: 'row ', justifyContent: 'center', marginTop: 20}}>
                                        <div>
                                            <span className="_dot_1"></span>Successful executions
                                        </div>
                                        <div>
                                            <span className="_dot_5"></span>Failed executions
                                        </div>
                                    </PieContent>
                                </ChatWrapperRight>
                                )
                                : 
                                (<ChatWrapperRight chartsPadding style={{marginTop: 20, padding: '10px 20px'}}>
                                    <StatusHeader>
                                        <h4> Workflow executions: {automations.totalWorkflowExecutionCount}</h4>
                                    </StatusHeader>
                                    <BotSessionsPieChat workflowChartDetails={automations.workflowChartDetails} />
                                    <PieContent style={{flexDirection: 'row ', justifyContent: 'center', marginTop: 20}}>
                                        <div>
                                            <span className="_dot_1"></span>Successful executions
                                        </div>
                                        <div>
                                            <span className="_dot_3"></span>Failed executions
                                        </div>
                                    </PieContent>
                                </ChatWrapperRight>
                                )
                                }
                            </ChartWrapper>
                        </ChartFeedbackWrapper>
                        <ChartFeedbackWrapper>
                            <ChartWrapper chartsBg style={{gridTemplateColumns: '65% 1fr'}}>
                                <ChatWrapperLeft chartsPadding>
                                    <StatusHeader style={{ marginBottom: 0 }}>
                                       <h4>Approval Executions: {automations.totalApproval}</h4> 
                                    </StatusHeader>
                                    <div
                                        className={
                                            automations.workflowChartDetails.length > 0
                                                ? 'bar_charts_wrapper'
                                                : 'bar_no_charts_wrapper'
                                        }
                                    >
                                        <ApprovalBarChart
                                            approvalChatDetails={automations.approvalChatDetails}
                                            selectedDate={dateDropdown.selectedDate}
                                        />
                                    </div>
                                </ChatWrapperLeft>
                                <ChatWrapperRight chartsPadding style={{marginTop: 20, padding : '10px 20px'}}>
                                    <StatusHeader>
                                        <h4>Approval Executions: {automations.totalApproval}</h4>
                                        </StatusHeader>
                                    <ApprovalPieChat approvalChatDetails={automations.approvalChatDetails} />
                                    <PieContent style={{marginTop: 20}}>
                                        <div>
                                            <span className="_dot_6"></span>Approved
                                        </div>
                                        <div>
                                            <span className="_dot_5"></span>Rejected
                                        </div>
                                        <div>
                                            <span className="_dot_3"></span>Waiting for approval
                                        </div>
                                    </PieContent>
                                </ChatWrapperRight>
                            </ChartWrapper>
                        </ChartFeedbackWrapper>
                        <BotCardWrapper WorkflowCardWidth>
                            <BotCard>
                                <CardHeader className="automation_box_header">
                                    <StatusHeader HeaderCardWorkflows>
                                        <h4>Most Used Workflows{' '}
                                                <Tooltip
                                                    className="target_bot"
                                                    tipContentHover
                                                    zIndex={5000}
                                                    arrowSize={5}
                                                    tagName="span"
                                                    content="Most used app workflows by the bot in selected time period."
                                                >
                                                    <span>
                                                        <InfoIcon />
                                                    </span>
                                                </Tooltip>
                                        </h4>
                                    </StatusHeader>
                                </CardHeader>
                                <ProgressWrapper className="ul">
                                    {automations.mostUsedWorkflows.length > 0 ? (
                                        automations.mostUsedWorkflows.map((a, i) => (
                                            <ProgressLi key={i}>
                                                <ProgressContent width={'100%'} onClick={() => redirectToFlow(a.id)}>
                                                    {a.name}
                                                </ProgressContent>
                                                <ProgressBar width={'20%'}>
                                                    <Span>{a.count}</Span>
                                                </ProgressBar>
                                            </ProgressLi>
                                        ))
                                    ) : (
                                        <NodataContent>No data available</NodataContent>
                                    )}
                                </ProgressWrapper>
                            </BotCard>
                            <BotCard>
                                <CardHeader className="automation_box_header">
                                    <StatusHeader HeaderCardWorkflows>
                                        <h4>Least Used Workflows{' '}
                                                <Tooltip
                                                    className="target_bot"
                                                    tipContentHover
                                                    zIndex={5000}
                                                    arrowSize={5}
                                                    tagName="span"
                                                    content="Least used app workflows by bot in selected time period."
                                                >
                                                    <span>
                                                        <InfoIcon />
                                                    </span>
                                                </Tooltip>
                                        </h4>
                                    </StatusHeader>
                                </CardHeader>
                                <ProgressWrapper className="ul">
                                    {automations.leastUsedWorkflows.length > 0 ? (
                                        automations.leastUsedWorkflows.map((a, i) => (
                                            <ProgressLi key={i}>
                                                <ProgressContent width={'100%'} onClick={() => redirectToFlow(a.id)}>
                                                    {a.name}
                                                </ProgressContent>
                                                <ProgressBar width={'20%'}>
                                                    <Span>{a.count}</Span>
                                                </ProgressBar>
                                            </ProgressLi>
                                        ))
                                    ) : (
                                        <NodataContent>No data available</NodataContent>
                                    )}
                                </ProgressWrapper>
                            </BotCard>
                            <BotCard>
                                <CardHeader className="automation_box_header">
                                    <StatusHeader HeaderCardWorkflows>
                                        <h4>Most Used Approvals{' '}
                                                <Tooltip
                                                    className="target_bot"
                                                    tipContentHover
                                                    zIndex={5000}
                                                    arrowSize={5}
                                                    tagName="span"
                                                    content="Most used app workflows with approvals by bot in selected time period."
                                                >
                                                    <span>
                                                        <InfoIcon />
                                                    </span>
                                                </Tooltip>
                                        </h4>
                                    </StatusHeader>
                                </CardHeader>
                                <ProgressWrapper className="ul">
                                    {automations.mostUsedApproval.length > 0 ? (
                                        automations.mostUsedApproval.map((a, i) => (
                                            <ProgressLi key={i}>
                                                <ProgressContent width={'100%'}>
                                                    {a.name}
                                                </ProgressContent>
                                                <ProgressBar width={'20%'}>
                                                    <Span>{a.count}</Span>
                                                </ProgressBar>
                                            </ProgressLi>
                                        ))
                                    ) : (
                                        <NodataContent>No data available</NodataContent>
                                    )}
                                </ProgressWrapper>
                            </BotCard>
                            <BotCard>
                                <CardHeader className="automation_box_header">
                                    <StatusHeader HeaderCardWorkflows>
                                        <h4>
                                            Least Used Approvals{' '}
                                                <Tooltip
                                                    className="target_bot"
                                                    tipContentHover
                                                    zIndex={5000}
                                                    arrowSize={5}
                                                    tagName="span"
                                                    content="Least used app workflows with approvals by bot in selected time period."
                                                >
                                                    <span>
                                                        <InfoIcon />
                                                    </span>
                                                </Tooltip>
                                        </h4>
                                    </StatusHeader>
                                </CardHeader>
                                <ProgressWrapper className="ul">
                                    {automations.leastUsedApproval.length > 0 ? (
                                        automations.leastUsedApproval.map((a, i) => (
                                            <ProgressLi key={i}>
                                                <ProgressContent width={'100%'}>
                                                    {a.name}
                                                </ProgressContent>
                                                <ProgressBar width={'20%'}>
                                                    <Span>{a.count}</Span>
                                                </ProgressBar>
                                            </ProgressLi>
                                        ))
                                    ) : (
                                        <NodataContent>No data available</NodataContent>
                                    )}
                                </ProgressWrapper>
                            </BotCard>
                        </BotCardWrapper>
                    </React.Fragment>
                )}
            </Wrapper>
        </React.Fragment>
    )
}

function BotSessionsPieChat(props: { workflowChartDetails: ChartDataT[] }) {
    const { workflowChartDetails } = props

    const total = workflowChartDetails.reduce((acc, val) => (acc += val.failed + val.success), 0)

    const success = (workflowChartDetails.reduce((acc, val) => (acc += val.success), 0) / total) * 100
    const failed = (workflowChartDetails.reduce((acc, val) => (acc += val.failed), 0) / total) * 100
    
    const data = [
        { value: !success ? 0 : Math.round(success), id: 'success', label: 'Success' },
        { value: !failed ? 0 : Math.round(failed), id: 'failed', label: 'Failed' }
    ]
    return (
        // <div className="pie_chat">
        total == 0 ? (
            <div className="pie_chart_img">
                <NodataContent>No data available</NodataContent>
            </div>
        ) : (
            <>
            {/* <defs>
                <linearGradient id="successclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                    <stop offset='0%' stopColor='#7AE3DC' />
                    <stop offset='100%' stopColor='#5CAAA4'/>
                </linearGradient>
                <linearGradient id="failclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                    <stop offset='0%' stopColor='#EB6453' />
                    <stop offset='100%' stopColor='#C1665B'/>
                </linearGradient>
                </defs> */}
            <PieCharts data={data} colors={['url(#successpieclr)', 'url(#failpieclr)']} />
            </>

        )
        // </div>
    )
}

function ApprovalPieChat(props: { approvalChatDetails: ApprovalChartDataT[] }) {
    const { approvalChatDetails } = props

    const total = approvalChatDetails.reduce(
        (acc, val) => (acc += val.approved + val.rejected + val.waiting_for_approval),
        0
    )

    const approved = (approvalChatDetails.reduce((acc, val) => (acc += val.approved), 0) / total) * 100
    const rejected = (approvalChatDetails.reduce((acc, val) => (acc += val.rejected), 0) / total) * 100
    const waiting_for_approval =
        (approvalChatDetails.reduce((acc, val) => (acc += val.waiting_for_approval), 0) / total) * 100
    const mockDATA=[
        { value: 10, id: 'approved', label: 'Approved' },
        { value: 3, id: 'rejected', label: 'Rejected' },
        {
            value: 4,
            id: 'waiting_for_approval',
            label: 'Waiting for approval'
        }        
    ]
    const data = [
        { value: !approved ? 0 : Math.round(approved), id: 'approved', label: 'Approved' },
        { value: !rejected ? 0 : Math.round(rejected), id: 'rejected', label: 'Rejected' },
        {
            value: !waiting_for_approval ? 0 : Math.round(waiting_for_approval),
            id: 'waiting_for_approval',
            label: 'Waiting for approval'
        }
    ]
    return (
        // <div className="pie_chat">
        total == 0 ? (
            <div className="pie_chart_img">
                <NodataContent>No data available</NodataContent>
            </div>
        ) : (
            <>
              {/* <defs>
              <linearGradient id="approvalclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                    <stop offset='0%' stopColor='#A4F2A4' />
                    <stop offset='100%' stopColor='#80C993'/>
                </linearGradient>
                <linearGradient id="waitingpieclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                    <stop offset='0%' stopColor='#FFBF84'/>
                    <stop offset='100%' stopColor='#E9A05D'/>
                </linearGradient>
                <linearGradient id="rejectedclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                    <stop offset='0%' stopColor='#EB6453'/>
                    <stop offset='100%' stopColor='#C1665B'/>
                </linearGradient>
                </defs>                                 */}
            <PieCharts data={data} 
            // colors={['#A1E89B', '#EB6453', '#FFAB57']}
            colors={["url(#approvalpieclr)","url(#waitingpieclr)","url(#rejectedpieclr)"]}
            />
            </>
        )
        // </div>
    )
}
const StatusDetails = [
    {
        header: 'Active App Workflows',
        count: '36',
        image: <InfoIcon />,
        hoverContent: 'Total number of app workflows that are active.'
    },
    {
        header: 'Total Workflow Executions',
        count: '2',
        image: <InfoIcon />,
        hoverContent: 'Total number of workflow executions in selected time period.'
    },
    {
        header: 'Successful Executions',
        count: '2',
        image: <InfoIcon />,
        hoverContent: 'Total number of successful workflow executions in selected time period.',
        toDisplayChatbotIcons: true
    },
    {
        header: 'Approval Workflows',
        count: '1',
        image: <InfoIcon />,
        hoverContent: 'Total number of workflow executions with approvals in selected time period.',
        toDisplayChatbotIcons: true
    },
    {
        header: 'Approvals Pending',
        count: '1',
        image: <InfoIcon />,
        hoverContent: 'Total number of workflows that are waiting for approval in selected time period.',
        toDisplayChatbotIcons: true
    }
]

const StatusDetailsAutomate = [
    {
        header: 'Total active workflows',
        count: '36',
        image: <InfoIcon />,
        hoverContent: 'Total number of app workflows that are active.'
    },
    {
        header: 'Total workflow executions',
        count: '2',
        image: <InfoIcon />,
        hoverContent: 'Total number of workflow executions in selected time period.'
    },
    {
        header: 'Total successful executions',
        count: '2',
        image: <InfoIcon />,
        hoverContent: 'Total number of successful workflow executions in selected time period.',
        toDisplayChatbotIcons: true
    },
    {
        header: 'Agent hours saved',
        count: '1',
        image: <InfoIcon />,
        hoverContent: 'Total number of agent hours saved.',
        toDisplayChatbotIcons: true,
        settingsIcon: <SubPanelIcons.settings />
    },
    {
        header: 'Cost savings',
        count: '1',
        image: <InfoIcon />,
        hoverContent: 'Total number of cost saving.',
        toDisplayChatbotIcons: true,
        settingsIcon: <SubPanelIcons.settings />
    }
]
interface CostUpdate{
        agentHoursSaved: number,
        costSavings: number,
    }
interface CostPut{
        costSaved:number
    }
    interface HoursPut{
        hoursSaved:number
    }
function DefaultInfoHeader(props: {
    automations: AnalyticsSuccessResponseT
    dateDropdown: any
    setDateDropDownValue: any
    dates: any
    dateOptions: any
    workspaceName:string
}) {
    const { automations, dateDropdown, setDateDropDownValue, dates, dateOptions,workspaceName } = props
    console.log(automations,'automations')
    function retnFn(i: number) {
        switch (i) {
            case 0:
                return automations.totalActiveWorkflow
            case 1:
                return automations.totalWorkflowExecutionCount
            case 2:
                return automations.totalWorkflowSuccessCount
            // case 3:
            //     return automations.totalWorkflowFailureCount
            // case 3:
            //     return automations.totalApproval
            default:
                return automations.totalWorkflowSuccessCount
        }
    }

    function assistantRetnFn(i: number) {
        switch (i) {
            case 0:
                return automations.totalActiveWorkflow
            case 1:
                return automations.totalWorkflowExecutionCount
            case 2:
                return automations.totalWorkflowSuccessCount
            case 3:
                return automations.totalApproval
            case 4:
                return automations.waitingForApproval
            default:
                return automations.totalWorkflowSuccessCount
        }
    }

    const [costDropdown, setCostDropdown] = useState(false)
    const [hrsDropdown, setHrsDropdown] = useState(false)
    const [errorIcon, setErrorIcon] = useState(false)

    const [updateDetail, setUpdateDetail] = useState<CostUpdate>({
        agentHoursSaved: 0,
        costSavings: 0
    })
    const getCostDetails =useGenericCached(
        'agent-cost-savings',
        () => wrapperGetJson(automationAPIURLWithWorkspace(workspaceName, '/agent/cost')),
        {
            refetchOnMount: false,
            staleTime: 30000
        }
    )
    useEffect(() => {
        if (getCostDetails.status === 'fetched') {
            setUpdateDetail(getCostDetails.data.data.output)
        } else if (getCostDetails.status === 'error') {
            setErrorIcon(true)
        }
    }, [getCostDetails.status])

    // const x:CostUpdate = getCostDetails.status == 'fetched' ? getCostDetails.data.data.output : {
    //     agentHoursSaved: 0,
    //     costSavings: 0
    // }
    // const E =  getCostDetails.status == 'error' ? setErrorIcon(true)
    //                 // console.log('Error in fetching',getCostDetails.error)
    //                 : console.log("No Error")
    const queryClient = useQueryClient()
    const {mutate:costMutate} =useMutation({
            mutationFn: (data:CostPut | HoursPut ) =>
            putJson(automationAPIURLWithWorkspace(workspaceName,'/agent/cost/update'))()
                (data),
                onSuccess: () => {
                queryClient.invalidateQueries('agent-cost-savings')
                setHrsDropdown(false);
                setCostDropdown(false);
            },
    })

    const updateCostDetails = (type: 'cost' | 'hr', value: number)=>{
        type == 'cost' ?  costMutate({"costSaved":value}) :costMutate({"hoursSaved":value})       
    }
    const multiply = (value: number, data: number) => {
        return value * data;
      };
    return (
        <StatusWrapper>
            <HeaderWrapper>
                {/* <StatusHeader>Workflow Status</StatusHeader> */}
                <HeaderContainer>
                    <HeaderTop className="">
                        <span>
                            <FlowIcon />
                        </span>
                        <div className="header_top_right">
                            Workflow Summary
                            <Content>Summary dashboard of your app workflows.</Content>
                        </div>
                    </HeaderTop>
                    {/* <HeaderTop>
                        <div className="header_top_right">
                            Workflow Status
                        </div>
                    </HeaderTop> */}
                    <DropdownWrapper>
                        <Input
                            activeDropdown={dateDropdown.showDatePopup}
                            onClick={() =>
                                setDateDropDownValue({
                                    ...dateDropdown,
                                    showDatePopup: !dateDropdown.showDatePopup
                                })
                            }
                            type="text"
                            defaultValue={dates()}
                        />
                        <InputContent>Show by</InputContent>
                        <span
                            onClick={() =>
                                setDateDropDownValue({
                                    ...dateDropdown,
                                    showDatePopup: !dateDropdown.showDatePopup
                                })
                            }
                        >
                            <BotDropdown />
                        </span>
                        {dateDropdown.showDatePopup && (
                            <Downshift
                                isOpen={true}
                                onOuterClick={() =>
                                    setDateDropDownValue({
                                        ...dateDropdown,
                                        showDatePopup: false
                                    })
                                }
                            >
                                {() => (
                                    <div>
                                        <DropdownUl>
                                            {dateOptions.map((op: any, i: number) => (
                                                <DropdownLi
                                                    onClick={() => {
                                                        setDateDropDownValue({
                                                            showDatePopup: false,
                                                            selectedDate: op
                                                        })
                                                    }}
                                                >
                                                    <DropdownLiSpan>{op.label}</DropdownLiSpan>{' '}
                                                </DropdownLi>
                                            ))}
                                        </DropdownUl>
                                    </div>
                                )}
                            </Downshift>
                        )}
                    </DropdownWrapper>
                </HeaderContainer>
                <CardContainer>
                {getProductId() === "ASSISTANT" ?
                    (StatusDetails.map((data, i) => (
                        <Card>
                            {console.log(i,"here")}
                            <CardHeader>
                                {data.header}{' '}
                                <Tooltip
                                    className="target_bot"
                                    tipContentHover
                                    zIndex={5000}
                                    arrowSize={5}
                                    tagName="span"
                                    content={data.hoverContent}
                                >
                                    <span>{data.image}</span>
                                </Tooltip>
                            </CardHeader>
                            <CountNumber>
                                <CountWrapper>{assistantRetnFn(i)}</CountWrapper>
                            </CountNumber>
                        </Card>
                    ))) :
                    (StatusDetailsAutomate.map((data, i) => (
                        <Card>
                            {/* {console.log(i,"nothere")} */}
                            <CardHeader>
                                {data.header}{' '}
                                <Tooltip
                                    className="target_bot"
                                    tipContentHover
                                    zIndex={5000}
                                    arrowSize={5}
                                    tagName="span"
                                    content={data.hoverContent}
                                >
                                    <span>{data.image}</span>
                                </Tooltip>
                            </CardHeader>
                            <CountNumber>
                                {data.header === 'Agent hours saved' ? (
                                    <CountWrapper>
                                    {multiply(retnFn(i), updateDetail.agentHoursSaved)}
                                    </CountWrapper>
                                    ) : data.header === 'Cost savings' ? (
                                    <CountWrapper>
                                    {multiply(retnFn(i), updateDetail.costSavings)}
                                    </CountWrapper>
                                    ) : (
                                    <CountWrapper>{retnFn(i)}</CountWrapper>
                                    )}
                                {data.header.toLowerCase() == 'Agent Hours Saved'.toLowerCase() && (
                                    <SettingsWrapper>
                                        <div className='summary_settings_box'>
                                            {errorIcon && (
                                                <span className="no_input_value">
                                                <div className="error_input_show" style={{right:"10px"}}>
                                            <Tooltip
                                                className="target customTip"
                                                zIndex={10000}
                                                arrowSize={8}
                                                tagName="label"
                                                content="Connection issue"
                                                distance={3}
                                            >
                                            <WarningAlertIcon />
                                                        </Tooltip>
                                                    </div>
                                                </span>
                                            )}  
                                            {data.settingsIcon && (
                                                <div className="settings" style={{right:"0px"}} onClick={() => setHrsDropdown(true)}>
                                                    {data.settingsIcon}
                                                {hrsDropdown && (
                                                <HoverContent>
                                                    <Content>
                                                        Time spent by your support agent to resolve or address an issue,
                                                        ticket, or service request.
                                                    </Content>
                                                    <Downshift isOpen={true} onOuterClick={() => setHrsDropdown(false)}>
                                                        {() => (
                                                            <span>
                                                                <InputWrapper>
                                                                    <Input
                                                                        type="number"
                                                                        value={updateDetail.agentHoursSaved}
                                                                        onChange={(event: any) => {
                                                                            setUpdateDetail({
                                                                                ...updateDetail,
                                                                                agentHoursSaved: parseFloat(event.target.value)
                                                                            })
                                                                        }}
                                                                        style={{ width: 42, height: 26 }}
                                                                    />
                                                                    <span style={{ marginLeft: 4, marginRight: 4 }}>
                                                                        hrs
                                                                    </span>
                                                                    <SmallButton style={{ minWidth: 54}}
                                                                        onClick={() => {
                                                                            updateCostDetails(
                                                                                'hr',
                                                                                updateDetail.agentHoursSaved
                                                                            )

                                                                        }}
                                                                    >
                                                                        <label>Apply</label>
                                                                    </SmallButton>
                                                                </InputWrapper>
                                                            </span>
                                                        )}
                                                    </Downshift>
                                                </HoverContent>
                                            )}  
                                            </div>
                                            )}
                                        </div>
                                    </SettingsWrapper>
                                )}
                                {data.header.toLowerCase() == 'Cost Savings'.toLowerCase() && (
                                    <SettingsWrapper>
                                        <div className='summary_settings_box' >
                                                {errorIcon && (
                                                    <span className="no_input_value">
                                                        <div className="error_input_show" style={{right:"10px"}}>
                                                            <Tooltip
                                                                className="target customTip"
                                                                zIndex={10000}
                                                                arrowSize={8}
                                                                tagName="label"
                                                                content="Connection issue"
                                                                distance={3}
                                                            >
                                                                <WarningAlertIcon />
                                                            </Tooltip>
                                                        </div>
                                                    </span>
                                                )}  
                                        {data.settingsIcon && (
                                            <div className="settings" style={{right:"0px"}} onClick={() => setCostDropdown(true)}>
                                                {data.settingsIcon}
                                                {costDropdown && (
                                                    <HoverContent>
                                                        <Content>
                                                            Average cost to resolve an issue, ticket or service request.
                                                        </Content>
                                                        <Downshift isOpen={true} onOuterClick={() => setCostDropdown(false)}>
                                                            {() => (
                                                                <span>
                                                                    <InputWrapper>
                                                                        <span
                                                                            style={{
                                                                                marginLeft: '0px',
                                                                                marginRight: '5px',
                                                                            }}
                                                                        >
                                                                            $
                                                                        </span>{' '}
                                                                        <Input
                                                                            type="number"
                                                                            value={updateDetail.costSavings}
                                                                            onChange={(event: any) => {
                                                                                setUpdateDetail({
                                                                                    ...updateDetail,
                                                                                    costSavings:
                                                                                        parseFloat(event.target.value)
                                                                                })
                                                                            }}
                                                                        />
                                                                        <SmallButton
                                                                            style={{ minWidth: 54, marginLeft: 10 }}
                                                                            onClick={() => {
                                                                                updateCostDetails(
                                                                                    'cost',
                                                                                    updateDetail.costSavings
                                                                                )
                                                                            }}
                                                                        >
                                                                            <label>Apply</label>
                                                                        </SmallButton>
                                                                    </InputWrapper>
                                                                </span>
                                                            )}
                                                        </Downshift>
                                                    </HoverContent>
                                                )}
                                            </div>
                                        )}
                                        </div>
                                    </SettingsWrapper>
                                )}
                            </CountNumber>
                        </Card>
                    )))
                    }
                </CardContainer>
                
            </HeaderWrapper>
        </StatusWrapper>
    )
}

function WorkflowBarChart(props: {
    workflowChartDetails: ChartDataT[]
    selectedDate: {
        date: string
        label: string
    }
}) {
    const { workflowChartDetails, selectedDate } = props

    function filterDate() {
        return workflowChartDetails.map(chart => {
            const date = chart.date.split('/')[0] + ' ' + m[parseInt(chart.date.split('/')[1])]
            return {
                name: selectedDate.date == 'today' || selectedDate.date == 'yesterday' ? chart.date : date,
                success: chart.success,
                failed: chart.failed
            }
        })
    }

    const datas: any = filterDate()

    const data = workflowChartDetails.map(chart => ({
        name:
            selectedDate.date == 'today' || selectedDate.date == 'yesterday'
                ? chart.date
                : (new Date(chart.date).getDate() < 10
                      ? '0' + new Date(chart.date).getDate()
                      : new Date(chart.date).getDate()) +
                  '/' +
                  (new Date(chart.date).getMonth() < 9
                      ? `0${new Date(chart.date).getMonth() + 1}`
                      : new Date(chart.date).getMonth() + 1),
        failed: chart.failed,
        success: chart.success
    }))

    const modifiedData = data

    const maxValue = modifiedData
        .map(x => x.failed + x.success)
        .reduce((prev, curr) => {
            return Math.max(prev, curr)
        }, 0)

    const length = `${maxValue}`.length - 1

    const unit = maxValue <= 25 ? 5 : parseInt(1 + '0'.repeat(length))

    const yAxisTicks =
        unit === 5
            ? [5, 10, 15, 20, 25, 30]
            : R.unfold<number, number>(it => (it > maxValue + 2 * unit ? false : [it, it + unit]))(unit)

    // console.log('data::BarChart', modifiedData, maxValue, unit, array)

    return (
        <div className={datas.length > 0 ? 'bot_session_chart' : 'no_data_bot_session_chart'}>
            {maxValue > 0 ? (
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={830}
                        height={320}
                        data={modifiedData}
                        margin={{
                            top: 15,
                            right: 20,
                            left: -13,
                            bottom: 5
                        }}
                        barCategoryGap={'35%'}
                        barGap={'10%'}
                    >
                        <defs>
                            <linearGradient id="successclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                                <stop offset='0%' stopColor='#7AE3DC' />
                                <stop offset='100%' stopColor='#5CAAA4'/>
                            </linearGradient>
                            <linearGradient id="failclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                                <stop offset='0%' stopColor='#EB6453' />
                                <stop offset='100%' stopColor='#C1665B'/>
                            </linearGradient>
                            <linearGradient id="successpieclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                                <stop offset='0%' stopColor='#86F4EC' />
                                <stop offset='100%' stopColor='#7CE3DB'/>
                            </linearGradient>
                            <linearGradient id="failpieclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                                <stop offset='0%' stopColor='#FFA096' />
                                <stop offset='100%' stopColor='#EA7367'/>
                            </linearGradient>
                            </defs>
                        <CartesianGrid />
                        <XAxis
                            angle={45}
                            dy={12}
                            dx={10}
                            dataKey="name"
                            interval={0}
                            label={{
                                // value:
                                //     selectedDate.date == 'today' || selectedDate.date == 'yesterday' ? 'Hours' : 'Date',
                                position: 'insideBottom',
                                className: 'xAxisLabel',
                                offset: -20
                            }}
                            scale="point"
                            // padding={{ left: 5, right: 5 }}
                            axisLine={false}
                            tickLine={false}
                        />
                        <YAxis
                            allowDecimals={false}
                            label={{
                                value: 'Workflows',
                                angle: -90,
                                position: 'insideLeft',
                                offset: 19,
                                style: { textAnchor: 'middle' }
                            }}
                            axisLine={false}
                            tickLine={{ stroke: '#F2F2F2' }}
                            tickFormatter={(x: number) => {
                                return approx(x)
                            }}
                            ticks={[0, ...yAxisTicks]}
                        />
                        <BarchartTip
                            isAnimationActive={false}
                            coordinate={{ x: 100, y: 140 }}
                            cursor={false}
                            content={<BarchartTooltip />}
                        />
                        {/* <Legend /> */}
                        <Bar dataKey="failed" stackId="a" fill="url(#failclr)" barSize={10} />
                        <Bar dataKey="success" stackId="a" fill="url(#successclr)"radius={[10, 10, 0, 0]} />
                    </BarChart>
                </ResponsiveContainer>
            ) : (
                <NodataContent>No data available</NodataContent>
            )}
        </div>
    )
}

function ApprovalBarChart(props: {
    approvalChatDetails: ApprovalChartDataT[]
    selectedDate: {
        date: string
        label: string
    }
}) {
    const { approvalChatDetails, selectedDate } = props

    function filterDate() {
        return approvalChatDetails.map(chart => {
            const date = chart.date.split('/')[0] + ' ' + m[parseInt(chart.date.split('/')[1])]
            return {
                name: selectedDate.date == 'today' || selectedDate.date == 'yesterday' ? chart.date : date,
                approved: chart.approved,
                waiting_for_approval: chart.waiting_for_approval,
                rejected: chart.rejected
            }
        })
    }

    const datas: any = filterDate()

    const data = approvalChatDetails.map(chart => ({
        name:
            selectedDate.date == 'today' || selectedDate.date == 'yesterday'
                ? chart.date
                : (new Date(chart.date).getDate() < 10
                      ? '0' + new Date(chart.date).getDate()
                      : new Date(chart.date).getDate()) +
                  '/' +
                  (new Date(chart.date).getMonth() < 9
                      ? `0${new Date(chart.date).getMonth() + 1}`
                      : new Date(chart.date).getMonth() + 1),
        rejected: chart.rejected,
        waiting_for_approval: chart.waiting_for_approval,
        approved: chart.approved
    }))
    const MOCK_approval=[
        {
            approved:7,
            date: '18/04',
            rejected:3,
            waiting_for_approval:3,
        }
    ]
    const modifiedData = data

    const maxValue = modifiedData
        .map(x => x.rejected + x.waiting_for_approval + x.approved)
        .reduce((prev, curr) => {
            return Math.max(prev, curr)
        }, 0)

    const length = `${maxValue}`.length - 1

    const unit = maxValue <= 25 ? 5 : parseInt(1 + '0'.repeat(length))

    const yAxisTicks =
        unit === 5
            ? [5, 10, 15, 20, 25, 30]
            : R.unfold<number, number>(it => (it > maxValue + 2 * unit ? false : [it, it + unit]))(unit)

    return (
        <div className={data.length > 0 ? 'bot_session_chart' : 'no_data_bot_session_chart'}>
            {maxValue > 0 ? (
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={830}
                        height={320}
                        data={modifiedData}
                        margin={{
                            top: 15,
                            right: 20,
                            left: -13,
                            bottom: 5
                        }}
                        barCategoryGap={'35%'}
                        barGap={'10%'}
                    >
                        <defs>
                            <linearGradient id="approvalclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                                <stop offset='0%' stopColor='#A1E89B' />
                                <stop offset='100%' stopColor='#3D726E' />
                            </linearGradient>
                            <linearGradient id="waitingclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                                <stop offset='0%' stopColor='#FFAB57' />
                                <stop offset='35%' stopColor='#FBA957'/>
                                <stop offset='100%' stopColor='#BE8954'/>
                            </linearGradient>
                            <linearGradient id="rejectedclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                                <stop offset='0%' stopColor='#EB6453' />
                                <stop offset='100%' stopColor='#C1665B'/>
                            </linearGradient>
                                    <linearGradient id="approvalpieclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                            <stop offset='0%' stopColor='#A4F2A4' />
                            <stop offset='100%' stopColor='#80C993'/>
                            </linearGradient>
                            <linearGradient id="waitingpieclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                                <stop offset='0%' stopColor='#FFBF84'/>
                                <stop offset='100%' stopColor='#E9A05D'/>
                            </linearGradient>
                            <linearGradient id="rejectedpieclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                                <stop offset='0%' stopColor='#FFA096'/>
                                <stop offset='100%' stopColor='#EA7367'/>
                            </linearGradient>
                            </defs>
                        <CartesianGrid />
                        <XAxis
                            angle={45}
                            dy={12}
                            dx={10}
                            dataKey="name"
                            interval={0}
                            label={{
                                value:
                                    selectedDate.date == 'today' || selectedDate.date == 'yesterday' ? 'Hours' : 'Date',
                                position: 'insideBottom',
                                className: 'xAxisLabel',
                                offset: -20
                            }}
                            scale="point"
                            // padding={{ left: 5, right: 5 }}
                            axisLine={false}                         
                        />
                        <YAxis
                            allowDecimals={false}
                            label={{
                                value: 'Approvals',
                                angle: -90,
                                position: 'insideLeft',
                                offset: 19,
                                style: { textAnchor: 'middle' }
                            }}
                            axisLine={false}
                            tickLine={{ stroke: '#F2F2F2' }}
                            tickFormatter={(x: number) => {
                                return approx(x)
                            }}
                            ticks={[0, ...yAxisTicks]}
                        />
                        <BarchartTip
                            isAnimationActive={false}
                            coordinate={{ x: 100, y: 140 }}
                            cursor={false}
                            content={<BarchartApprovalTooltip />}
                        />
                        {/* <Legend /> */}
                        <Bar dataKey="rejected" stackId="a" fill="url(#rejectedclr)" barSize={10} />
                        <Bar dataKey="waiting_for_approval" stackId="a" fill="url(#waitingclr)" />
                        <Bar dataKey="approved" stackId="a" fill="url(#approvalclr)" radius={[10, 10, 0, 0]} />
                    </BarChart>
                </ResponsiveContainer>
            ) : (
                <NodataContent>No data available</NodataContent>
            )}
        </div>
    )
}

export function PieCharts(props: {
    data: {
        value: number
        id: string
        label: string
    }[]
    colors: string[]
}) {
    const resolved = props.data.filter(a => a.id == 'success')[0]
    const satisfied = props.data.filter(a => a.id == 'approved')[0]
    // console.log(satisfied, 'satisfied')
    return (
        <div className="analytics_charts_left_img pie-chart">
            <div className="pie_chart_img">
                <ResponsivePie
                    sortByValue={true}
                    data={props.data}
                    startAngle={-180}
                    innerRadius={0.85}
                    borderWidth={0}
                    enableSlicesLabels={false}
                    animate={true}
                    // tooltip={CustomPieTooltip}
                    colors={props.colors}
                    enableRadialLabels={false}
                    theme={{
                        tooltip: {
                            container: {
                                display: 'none'
                            }
                        }
                    }}
                />
                {resolved && (
                    <span className="pie_chart_center">
                        <p style={{ color: '#37BCB3' }}>{resolved.value ? resolved.value : 0}%</p>
                        <p style={{ color: '#37BCB3' }}>Successful</p>
                    </span>
                )}
                {satisfied && (
                    <span className="pie_chart_center">
                        <p style={{ color: '#6A9B66' }}>{satisfied.value ? satisfied.value : 0}%</p>
                        <p style={{ color: '#6A9B66' }}>Approved</p>
                    </span>
                )}
            </div>
        </div>
    )
}

const BarchartTooltip = (props: any) => {
    const { active, payload, label } = props
    // console.log(payload, label, 'payload')
    if (active && payload && payload.length && payload[0].payload.success + payload[0].payload.failed) {
        return (
            <div className="custom-tooltip _chart_tip">
                <div className="hide">
                    <p className="tooltip_date">
                        Total Workflows:
                        <span>{payload[0].payload.success + payload[0].payload.failed}</span>
                    </p>
                    <p>
                    Successful executions: <span>{payload[0].payload.success}</span>
                    </p>
                    <p>
                    Failed executions: <span>{payload[0].payload.failed}</span>
                    </p>
                </div>
                {/* <p className="tooltip_date">
                    Total Workflows:
                    <span>{payload[0].payload.success + payload[0].payload.failed}</span>
                </p> */}
                {/* <p>
                     Successful executions: <span>{payload[0].payload.success}</span>
                </p>
                <p>
                    Failed executions: <span>{payload[0].payload.failed}</span>
                </p> */}
            </div>
            
        )
    }
    return null
}

const BarchartApprovalTooltip = (props: any) => {
    const { active, payload, label } = props
    if (
        active &&
        payload &&
        payload.length &&
        payload[0].payload.approved + payload[0].payload.rejected + payload[0].payload.waiting_for_approval
    ) {
        return (
            <div className="custom-tooltip _chart_tip">
                <p className="tooltip_date">
                    Total approvals:
                    <span>
                        {payload[0].payload.approved +
                            payload[0].payload.rejected +
                            payload[0].payload.waiting_for_approval}
                    </span>
                </p>
                <p>
                    Approved: <span className="approved">{payload[0].payload.approved}</span>
                </p>
                <p>
                    Rejected: <span className="rejected">{payload[0].payload.rejected}</span>
                </p>
                <p>
                    Waiting for approval:{' '}
                    <span className="waiting_for_approval">{payload[0].payload.waiting_for_approval}</span>
                </p>
            </div>
        )
    }
    return null
}
