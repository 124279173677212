/*eslint-disable*/
import React from 'react'

//ICONS IMPORT
import { AddIcon, ArrowIcon, DeleteIcon, EditIconWithCircle, MoveIcon } from '@/common/Icons/Icons'
//COMMON COMPONENTS IMPORT
import { HeaderChild } from '@/common/components/Header'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import { SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { BackButton, IconButton } from '@/common/styled/Entity.Dumb'

import { IntentT } from '../Intent/kind'
//TYPES IMPORT
import { ActionT } from '../kind'
import { PageStateT } from './kind'

interface ExampleHeaderT {
    intent: IntentT
    PageState: PageStateT
    setPageState: Function
    deleteExample: Function
    setExampleToMoveMode: Function
    permissions: {
        intentWritePermission: boolean | null;
        exampleWritePermission: boolean | null;
        exampleDeletePermission: boolean | null;
    }
}

export const Header = ({ intent, PageState, setPageState, deleteExample, setExampleToMoveMode, permissions }: ExampleHeaderT) => {
    return (
        <React.Fragment>
            <section className="cogni_cards">
                <div className="flow_canvas1 intent_inner_page">
                    <HeaderChild type="first">
                        <div className="flex_canvas1">
                            <div className="flex_menu1 flow_group_home">
                                <div className="indent_dialog_left">
                                    <div className="indent_title">
                                        <BackButton onClick={() => window.history.back()}>
                                            <span>
                                                <ArrowIcon />
                                            </span>{' '}
                                            <label>Back</label>
                                        </BackButton>
                                        <div className="buton_back_content_column">
                                            <div className="buton_back_content">
                                                <h2>{intent.intent}</h2>
                                                {permissions.intentWritePermission && <IconButton
                                                    onClick={() =>
                                                        setPageState({ ...intent, action: ActionT.INTENT_EDIT })
                                                    }
                                                >
                                                    <span>
                                                        <EditIconWithCircle />
                                                    </span>
                                                </IconButton>}
                                            </div>

                                            <p>{intent.description}</p>
                                        </div>
                                    </div>
                                    {/* <div className="indent_title">
                                        <h2>
                                            <span className="buton_back" onClick={() => window.history.back()}>
                                                <svg className="rotate_dropdown" viewBox="0 0 240.811 240.811">
                                                    <g>
                                                        <path
                                                            id="Expand_More"
                                                            d="M220.088,57.667l-99.671,99.695L20.746,57.655c-4.752-4.752-12.439-4.752-17.191,0 c-4.74,4.752-4.74,12.451,0,17.203l108.261,108.297l0,0l0,0c4.74,4.752,12.439,4.752,17.179,0L237.256,74.859 c4.74-4.752,4.74-12.463,0-17.215C232.528,52.915,224.828,52.915,220.088,57.667z"
                                                        />
                                                    </g>
                                                </svg>
                                            </span>
                                            {intent.intent}
                                            <span
                                                className="buton_edit_intent"
                                                onClick={() => setPageState({ ...intent, action: ActionT.INTENT_EDIT })}
                                            >
                                                <EditIcon />
                                            </span>
                                        </h2>
                                        <p>{intent.description}</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </HeaderChild>

                    <HeaderChild type="second">
                        <div>
                            {/* <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12 intent_dialog_right"> */}
                            {(PageState.action === ActionT.SELECT || PageState.action === ActionT.MOVE) &&
                                // <button className="btn btn-primary" onClick={setExampleToMapMode}>
                                //     <MoveIcon />
                                //     <span>Map to entity</span>
                                // </button>
                                null}
                            {PageState.action === ActionT.SELECT && (
                                <>
                                    {permissions.exampleWritePermission && <Button
                                        className="intent_header_right_button btn btn-primary"
                                        data-testid="MOVE-BUTTON"
                                        onClick={() => setExampleToMoveMode()}
                                        primary
                                    >
                                        <span>
                                            <MoveIcon />
                                        </span>
                                        <label>Move to</label>
                                    </Button>}

                                    {permissions.exampleDeletePermission && <Button
                                        primary
                                        className="intent_header_right_button btn btn-primary"
                                        data-testid="DELETE-BUTTON"
                                        onClick={() => deleteExample(null)}
                                        // onClick={() => setExampleModeToDelete('')}
                                    >
                                        <span>
                                            <DeleteIcon />
                                        </span>
                                        <label>Delete</label>
                                    </Button>}
                                </>
                            )}
                        </div>
                    </HeaderChild>
                </div>

                <div className="flow_canvas1">
                    <div className="flex_canvas1">
                        <div className="flex_menu1 flow_group_home intent_header_background">
                            {[
                                <HeaderChild type="first">
                                    <section className="entity__header">
                                        <div className="entity__header_width">
                                            <div className="entity__header_top">
                                                <h4 className="indent_title">Examples</h4>
                                                {PageState.action !== ActionT.SELECT &&
                                                    PageState.action !== ActionT.MOVE &&
                                                    PageState.action !== ActionT.MULTIPLE_DELETE && permissions.exampleWritePermission && (
                                                        <SmallButton
                                                            primary
                                                            style={{ margin: 0 }}
                                                            onClick={() =>
                                                                setPageState({
                                                                    examples: [''],
                                                                    action: ActionT.ADD
                                                                })
                                                            }
                                                        >
                                                            <span>
                                                                <AddIcon />
                                                            </span>
                                                            <label>Add</label>
                                                        </SmallButton>
                                                    )}
                                            </div>

                                            <div className="entity__header_bottom">
                                                <p>
                                                    Give examples of text excerpts that user may use to express their
                                                    intent for initiating session with the Assistant. <br></br>Each
                                                    example can be as long as 255 characters.
                                                </p>
                                            </div>
                                        </div>
                                    </section>
                                </HeaderChild>
                            ]}
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
