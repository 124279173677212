/*eslint-disable*/
import React from 'react'

import { FetchReducerT, useFetchFromApi } from '@/common/hooks/usefetch-from-api'

import { watsonApiURL, wrapperGetJson } from '@/common/utils/api-utils'
import { Dialog } from './Dialog'
import { RecordApi, sourchConverter } from './triggers'

export function DialogWrapper(props: any) {
    const workspacename = props.match.params.workspacename

    const [state, setState, promiseRef] = useFetchFromApi(() =>
        wrapperGetJson(watsonApiURL(workspacename, RecordApi.TotalRecord)).then((response: any) => response.data)
    )

    const retry = () => {
        const setStateDispatch = setState as React.Dispatch<any>
        setStateDispatch(promiseRef.current ? promiseRef.current.then((response: any) => response.data) : null)
    }

    let stateN = state as FetchReducerT

    let newState = stateN

    if (stateN.type == 'success') {
        const sorted = sourchConverter(stateN.data.filter((e: any) => e.type == 'if'))

        newState = { ...stateN, data: sorted }
    }

    return <Dialog state={newState} retry={retry} {...props} />
}
