import Downshift from 'downshift'
/*eslint-disable*/
import React, { useEffect, useRef, useState } from 'react'
import { StyledComponent } from 'styled-components/macro'

import { getAssistants } from '@/Assistant/Mechanisms/action'
import { DateSelectInput } from '@/Flows/analytics/dateDropDown'
import { TabContainer, TabHeader, TabRow, capitalizeFirstLetter, filterData } from '@/Flows/analytics/logs'
import { VideoButtonB } from '@/Videos/Videos'
import {
    AbandonedIcon,
    AddIcon,
    AnalyticsAscendingSortIcon,
    AnalyticsDescendingSortIcon,
    AutoResolvedIcon,
    BotIconAnalytics,
    CloseIconWithCircle,
    ConversationsMessage,
    ConversationsTotal,
    DeleteIcon,
    DialogCardIcon,
    DialogIcon,
    DownArrowCircle,
    DropdownWithCircle,
    EditIcon,
    FaqAnsweredIcon,
    FaqICon,
    IgnoredIcon,
    InfoIcon,
    LeftArrowWithCircle,
    NewThumbsDownIcon,
    NewThumbsUpIcon,
    NextIconCanvas,
    OnGoingIcon,
    RightArrowWithCircle,
    SearchIcon,
    SlackIconCC,
    TeamsIcon,
    ThumbsDownIcon,
    ThumbsUpIcon,
    TryMeanalyticsIcon,
    UnresolvedIcon,
    UserIconAnalytics,
    UserIconAnalyticsMenu,
    WidgetIcon
} from '@/common/Icons/Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { Header, HeaderChild, PageWrapper } from '@/common/components/Header'
import { Loader } from '@/common/components/Loader'
import { SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import {
    ContentEllipse,
    CountNumber,
    DropdownContainer,
    EntityWrapper,
    LabelImage,
    OpenSubTable,
    SerachInput,
    SerachInputWrapper,
    TableWrapper,
    Tbody,
    TrainHeader,
    UserWrapper
} from '@/common/styled/DialogLogs.Dumb'
import { Table, TableContainer, Td, TdContent, Th, Thead, Tr } from '@/common/styled/Workflow.Analytics.Dumb'
import { BarExtendedDatum, ResponsiveBar } from '@nivo/bar'

import { formatAMPM, formatDateInCard } from '@/common/utils/utils'
import {
    analyticsGetJson,
    getJson,
    logEvent,
    postJson,
    stripeGetApi,
    watsonApiURL,
    watsonLogsApiURL
} from '@/common/utils/api-utils'
import { TryIt } from '../TrainingModule/DemoTryIt'
import { Dropdwon } from '../TrainingModule/TrainingModule'
import { Dropdowns } from '../analytics/home'
import {
    BaseConversationT,
    Conversation,
    ConversationT,
    DefaultResponse,
    DefaultResponseT,
    EmailConversationT,
    MostUsedIntents,
    RequestType
} from '../analytics/types'
import Tooltip from 'react-tooltip-lite'

const ramda = require('ramda')

const items: DateSelectInput[] = [
    {
        date: 'yesterday',
        label: 'Yesterday'
    },
    {
        date: 'today',
        label: 'Today'
    }
]

const statusItems = [
    {
        value: 'session_status',
        label: 'Sessions'
    },
    {
        value: 'user_email',
        label: 'User email'
    }
]

type AgentSessionsTypeT = {
    agent_name: string
    agent_email: string
    user_name: string
    user_email: string
    created_at: string
    bot_id: string
    agent_id: string
    agent_session_id: string
    feedback: 'Satisfied' | 'Not satisfied'
    conversation_id: string
}
export function getDateNumber(date: string) {
    switch (date) {
        case 'today':
            return 'today'
        case 'yesterday':
            return 'yesterday'
        case 'last 30 days':
            return 30
        case 'last 14 days':
            return 14
        case 'last 7 days':
            return 7
        default:
            return 'today'
    }
}
export function DialogLogsHome(props: any) {
    const changingGroupByRef = useRef<null | true>(null)
    const { workspacename, assistantname } = props.match.params
    const [loading, setLoading] = useState({
        show: false,
        error: { error: false, info: '' }
    })
    const [bots, setBots] = useState<any[]>([])
    const [unauthorized, setUnauthorized] = useState<boolean>(false)
    const [dateOptions, setDateOptions] = useState<DateSelectInput[]>(items)

    const [state, setState] = useState({
        showFlowPopup: false,
        selectedAutomation: 'Select',
        showDatePopup: false,
        selectedDate: { date: 'today', label: 'Today' }
    })

    const [agentState, setAgentState] = useState({
        showFlowPopup: false,
        selectedAutomation: 'Select',
        showDatePopup: false,
        selectedDate: { date: 'today', label: 'Today' }
    })

    const [platform, setPlatform] = useState<string>('all')

    const [group_by, setGroupBy] = useState<'session_status' | 'user_email'>('session_status')
    const [sessions, setSessions] = useState<string>('All Sessions')
    const [filterByEmail, setFilterByEmail] = useState<string>('all')

    const [chatChannel, setChatChannel] = useState<string>('all')

    const [BOT_ID, setBOT_ID] = useState<string>('')

    const [defaultDialogs, setDefaultdialogs] = useState(DefaultResponse)

    const [notDeployed, setNotDeployed] = useState<boolean>(false)

    const [dialogs, setDialogs] = useState(DefaultResponse)
    const [agentSessions, setAgentSessions] = useState<{ data: AgentSessionsTypeT[]; totalCount: number }>({
        data: [],
        totalCount: 0
    })

    const [allStatus, setAllStatus] = useState<("ongoing"|"resolved"|"escalated"|"abandoned"|"unresolved"|"faq_answered"|"ignored")[]>([])

    const [feedbacks, setFeedbacks] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [agentCurrentPage, setAgentCurrentPage] = useState(1)
    const [currentTab, setCurrentTab] = useState<'chatbot' | 'agent'>('chatbot')

    function setSelectedDate(date: any) {
        setState({ ...state, selectedDate: date, showDatePopup: false })
    }

    function setAgentSelectedDate(date: any) {
        setAgentState({ ...agentState, selectedDate: date, showDatePopup: false })
    }

    useEffect(() => {
        stripeGetApi(`${workspacename}/info/logs_limit`).then((resp: { output: string; type: 'success' }) => {
            const maxDays = Number(resp.output)
            const daysList = [30, 14, 7]
                .filter(x => x <= maxDays)
                .map(value => ({ date: `last ${value} days`, label: `Last ${value} Days` }))

            setDateOptions(daysList.concat(dateOptions))
        })
        logEvent(workspacename, {
            event:"EVENT::CHATBOT_LOGS::VIEW", event_meta:{}
        })
    }, [])
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    function callApi(filter?: any) {
        setLoading({ ...loading, show: true })

        getJson(
            watsonLogsApiURL(
                (sessions == 'All Sessions' || filter == "user_email" )
                    ? `/${workspacename}/conversations${filter == 'user_email' || (!filter && group_by == 'user_email')?"":"/v2"}/${BOT_ID}/${getDateNumber(
                          state.selectedDate.date
                      )}/${platform}?sort_by_start_time=-1&page_no=${
                          filter ? 1 : currentPage
                      }&no_of_records=50&group_by=${filter ? filter : group_by}&timezone=${timezone}`
                    : `/${workspacename}/conversations${filter == 'user_email' || (!filter && group_by == 'user_email')?"":"/v2"}/${BOT_ID}/${getDateNumber(
                          state.selectedDate.date
                      )}/${platform}?sort_by_start_time=-1&page_no=${
                          filter ? 1 : currentPage
                      }&no_of_records=50&group_by=${
                          filter ? filter : group_by
                      }&timezone=${timezone}&status=${sessions.toLocaleLowerCase()}`
            )
        )
            .then(res => {
                setDialogs( filter=='user_email'|| (!filter && group_by == 'user_email')?
                {...dialogs, 
                 conversations:res.data,
                 totalEmailCount: res.totalCount
                }
                : {
                    ...dialogs,
                    baseConversation: res.data,
                    totalSessionCount: res.totalCount
                });
                (filter == 'session_status' || (!filter && group_by == 'session_status')) &&  setAllStatus(res.status)

                if (filter) {
                    setFilterByEmail('all')
                    setSessions('All Sessions')
                    setGroupBy(filter)
                    changingGroupByRef.current = null
                }
                setLoading({ ...loading, show: false })
            })
            .catch(err => {
                if (err && err.response && err.response.status == 403) {
                    setUnauthorized(true)
                } else {
                    setLoading({
                        show: true,
                        error: {
                            error: true,
                            info: 'Server error!<br />Please try again after sometime.'
                        }
                    })
                }
            })
    }

    function callAgentApi() {
        setLoading({ ...loading, show: true })

        getJson(
            watsonLogsApiURL(
                `/${workspacename}/agents/${BOT_ID}/${getDateNumber(
                    agentState.selectedDate.date
                )}?sort_by_start_time=-1&page_no=${currentPage}&no_of_records=50&timezone=${timezone}`
            )
        )
            .then(agent => {
                setAgentSessions(agent as any)
                // setAgentSessions({ data: agent as any, totalCount: 10 })

                setLoading({ ...loading, show: false })
            })
            .catch(err => {
                if (err && err.response && err.response.status == 403) {
                    setUnauthorized(true)
                } else {
                    setLoading({
                        show: true,
                        error: {
                            error: true,
                            info: 'Server error!<br />Please try again after sometime.'
                        }
                    })
                }
            })
    }

    useEffect(() => {
        setLoading({ ...loading, show: true })
        getAssistants(workspacename).then((data: any[]) => {
            const assistant = data.reduce((accum: any, val: any) => {
                if (val.name.toLowerCase() == assistantname.toLowerCase()) return val
                else return accum
            }, null)
            if (assistant !== null && assistant.deployed !== null) {
                setBOT_ID(assistant.id)

                Promise.all([
                    getJson(
                        watsonLogsApiURL(
                            `/${workspacename}/conversations/${group_by=='user_email' ? "" : "/v2/" }${assistant.id}/${getDateNumber(
                                state.selectedDate.date
                            )}/${platform}?sort_by_start_time=-1&page_no=${currentPage}&no_of_records=50&group_by=${group_by}&timezone=${timezone}`
                        )
                    ),
                    getJson(
                        watsonLogsApiURL(
                            `/${workspacename}/agents/${assistant.id}/${getDateNumber(
                                state.selectedDate.date
                            )}?sort_by_start_time=-1&page_no=${currentPage}&no_of_records=50&timezone=${timezone}`
                        )
                    )
                ])
                    .then(([res, agent]) => {
                        // getJson(
                        //     watsonLogsApiURL(
                        //         `/${workspacename}/conversations/${assistant.id}/${getDateNumber(
                        //             state.selectedDate.date
                        //         )}/${platform}?sort_by_start_time=-1&page_no=${currentPage}&no_of_records=50&group_by=${group_by}&timezone=${timezone}`
                        //     )
                        // )
                        // .then((res: { data: ConversationT[]; totalCount: number }) => {
                        setDialogs(group_by=='user_email'?{
                            ...dialogs,
                            conversations: res.data,
                            totalEmailCount: res.totalCount
                        }:{
                            ...dialogs,
                            baseConversation: res.data,
                            totalSessionCount: res.totalCount
                        })
        
                        group_by=='session_status' && setAllStatus(res.status?res.status:[])

                        setAgentSessions(agent as any)
                        setLoading({ ...loading, show: false })
                        setBots(data)
                    })
                    .catch(err => {
                        if (err && err.response && err.response.status == 403) {
                            setUnauthorized(true)
                        } else {
                            setLoading({
                                show: true,
                                error: {
                                    error: true,
                                    info: 'Server error!<br />Please try again after sometime.'
                                }
                            })
                        }
                    })
            } else if (assistant !== null && assistant.deployed === null) {
                setBots(data)
                setNotDeployed(true)
                setLoading({ ...loading, show: false })
            }
        })
    }, [])

    useEffect(() => {
        // if (defaultDialogs.convos.length > 0) {
        if (BOT_ID.length > 0 && !changingGroupByRef.current) {
            callApi()
        } else {
            changingGroupByRef.current = null
        }
        // }
    }, [state.selectedDate.date, platform, currentPage, sessions])

    useEffect(() => {
        // if (defaultDialogs.convos.length > 0) {
        if (BOT_ID.length > 0) {
            callAgentApi()
        }
        // }
    }, [agentState.selectedDate.date, agentCurrentPage])

    const changeGroupFilter = (filter: any) => {
            if (BOT_ID.length > 0) {
                callApi(filter)
            }
    }

    return (
        <ErrorBoundary
            notDeployed={notDeployed}
            notDeployedFn={() => {
                props.history.push(`/workspace/${workspacename}/dialog/${assistantname}/dialogs`)
            }}
            unauthorized={notDeployed}
            error={loading.error}
            render={(err: any, info: any) => {
                return (
                    err && (
                        <Error.PageLoadingError
                            onClick={() => callApi()}
                            info={info}
                            className={'analytics_page_error'}
                            btnName="Retry"
                        />
                    )
                )
            }}
        >
            <Header>
                <HeaderChild type="first">
                    <div className="indent_left">
                        <div className="indent_title">Chatbot Logs</div>
                    </div>
                </HeaderChild>
                <HeaderChild type="second">
                    <VideoButtonB id={'READ_CHATBOT_LOGS'} />
                </HeaderChild>
            </Header>
            <div className="header_loader_height_change">
                {loading.show ? (
                    <Loader.PageLoader show={true} className="analytics_loader" />
                ) : (
                    <PageWrapper>
                        <TabContainer>
                            <TabRow>
                                <TabHeader active={currentTab == 'chatbot'} onClick={() => setCurrentTab('chatbot')}>
                                    Chatbot Logs
                                </TabHeader>
                                <TabHeader active={currentTab == 'agent'} onClick={() => setCurrentTab('agent')}>
                                    Agent Handover Logs
                                </TabHeader>
                            </TabRow>
                        </TabContainer>
                        {currentTab == 'chatbot' ? (
                            <DetailedConversationListView
                                workspacename={workspacename}
                                BOT_ID={BOT_ID}
                                dialogs={dialogs}
                                state={state}
                                platform={platform}
                                setPlatform={setPlatform}
                                feedbacks={feedbacks}
                                groupBy={group_by}
                                setGroupBy={changeGroupFilter}
                                sessions={sessions}
                                setSessions={setSessions}
                                filterByEmail={filterByEmail}
                                setFilterByEmail={setFilterByEmail}
                                chatChannel={chatChannel}
                                setChatChannel={setChatChannel}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                displayItems={dateOptions}
                                setSelectedDate={setSelectedDate}
                                bots={bots}
                                changingGroupByRef={changingGroupByRef}
                                allStatus={allStatus}
                            />
                        ) : (
                            <DetailedAgentConversationListView
                                workspacename={workspacename}
                                BOT_ID={BOT_ID}
                                state={agentState}
                                sessions={agentSessions}
                                currentPage={agentCurrentPage}
                                setCurrentPage={setAgentCurrentPage}
                                displayItems={dateOptions}
                                setSelectedDate={setAgentSelectedDate}
                                bots={bots}
                                changingGroupByRef={changingGroupByRef}
                            />
                        )}
                    </PageWrapper>
                )}
            </div>
        </ErrorBoundary>
    )
}

function DetailedConversationListView(props: {
    workspacename: string
    BOT_ID: string
    state: {
        showFlowPopup: boolean
        selectedAutomation: string
        showDatePopup: boolean
        selectedDate: { date: string; label: string }
    }
    platform: string
    setPlatform: Function
    feedbacks: any
    dialogs: DefaultResponseT
    groupBy: 'session_status' | 'user_email'
    setGroupBy: any
    sessions: string
    chatChannel: string
    setSessions: any
    setChatChannel: any
    currentPage: number
    setCurrentPage: any
    displayItems: DateSelectInput[]
    filterByEmail: string
    setFilterByEmail: Function
    setSelectedDate: (date: { date: string; label: string }) => void
    bots: any[]
    changingGroupByRef: React.MutableRefObject<null | true>
    allStatus:("ongoing" | "resolved" | "escalated" | "abandoned" | "unresolved" | "faq_answered" | "ignored")[]
}) {
    const {
        dialogs,
        BOT_ID,
        workspacename,
        platform,
        groupBy,
        setGroupBy,
        bots,
        state,
        sessions,
        filterByEmail,
        allStatus
    } = props

    const [popupState, setPopupState] = useState({
        showPopup: false,
        data: [],
        conversationId: '',
        loading: false,
        idToScroll: ""
    })

    const [detailsPopup, setDetailsPopup] = useState<{
        show: boolean
        loading: boolean
        data: any[]
        currentLog: any
        error: string
        index: null | number
        currentPage: number
    }>({
        show: false,
        loading: true,
        currentLog: {},
        data: [],
        error: '',
        index: null,
        currentPage: 1
    })

    function callAPILogs(currentLog: any, index: number, page: number,session:boolean) {
        setDetailsPopup({ ...detailsPopup, show: true, loading: true, currentLog, index: index })
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        getJson(
            watsonLogsApiURL(
                session ? `/${workspacename}/queries/${currentLog.conversation_id}/all?sort_by_start_time=-1&page_no=${page}&no_of_records=50&timezone=${timezone}`:
                `/${workspacename}/conversations/${BOT_ID}/${getDateNumber(
                    state.selectedDate.date
                )}/all?sort_by_start_time=-1&page_no=${page}&no_of_records=50&user_email=${
                    currentLog._id[0]
                }&timezone=${timezone}`
            )
        )
            .then(res => {
                setDetailsPopup({
                    ...detailsPopup,
                    data: session? res: res.data,
                    loading: false,
                    show: true,
                    currentLog,
                    index: index,
                    currentPage: page
                })
            })
            .catch(err => {
                setDetailsPopup({
                    ...detailsPopup,
                    loading: false,
                    show: true,
                    index: index,
                    currentLog,
                    currentPage: page,
                    error: 'Server error!<br />Please try again after sometime.'
                })
            })
    }

    useEffect(() => {
        if (popupState.showPopup) {
            getJson(
                watsonLogsApiURL(
                    `/${workspacename}/logs/${BOT_ID}/${getDateNumber(props.state.selectedDate.date)}?conversation_id=${
                        popupState.conversationId
                    }`
                )
            ).then(e => {
                console.log('e', e)
                setPopupState({
                    ...popupState,
                    loading: false,
                    data: e.map((res: any) => {
                        // const resResponse = res.response[0]
                        const concatedResponse = res.response.reduce(
                            (acc: any, curr: any) => {
                                return {
                                    ...acc,
                                    intents: curr.intents,
                                    entities: curr.entities,
                                    context: res.request.context_variables,
                                    faq:curr.faq,
                                    output: acc.output.concat(curr.output)
                                }
                            },
                            { intents: [], entities: [], faq:[], context: null, output: [] }
                        )

                        return {
                            text: res.request.input,
                            masked: res.masked,
                            intents: concatedResponse.intents.map((intent: any) => intent.intent),
                            entities: concatedResponse.entities.map((entity: any) => entity.entity),
                            faq:concatedResponse.faq,
                            context: res.request.context_variables,
                            req_id:res.req_id,
                            output: concatedResponse.output
                                .map((e: any) => {
                                    return e.output == 'text'
                                        ? {
                                              response_type: 'text',
                                              text: e.text
                                          }
                                        : e.output == 'option'
                                        ? {
                                              response_type: 'text',
                                              title: e.option.title,
                                              options: e.option.options.map((e: any) => ({
                                                  label: e.label
                                              }))
                                          }
                                        : e.output=='adaptive_card'?
                                        {
                                            response_type: 'text',
                                            title: e.adaptive_card.blocks.filter((b:any)=>b.text&&b.text.style=="bold")[0].text.text,
                                            description: e.adaptive_card.blocks.filter((b:any)=>b.text&&b.text.style=="normal")[0].text.text,
                                            fields:  e.adaptive_card.blocks.filter((b:any)=>b.fields)[0].fields,
                                            buttons: e.adaptive_card.blocks.filter((b:any)=>b.elements)[0].elements,
                                            image: e.adaptive_card.blocks.filter((b:any)=>b.fields)[0].accessory,
                                            adaptive_card:"adaptive_card"
                                        } : e.type == 'Carousal' ? {
                                            response_type: 'text',
                                            values:e.accumulate_carousal.map((carousal:any)=>({
                                                title: carousal.blocks.filter((b:any)=>b.text&&b.text.style=="bold")[0].text.text,
                                                description: carousal.blocks.filter((b:any)=>b.text&&b.text.style=="normal")[0].text.text,
                                                fields:  carousal.blocks.filter((b:any)=>b.fields)[0].fields,
                                                buttons: carousal.blocks.filter((b:any)=>b.elements)[0].elements,
                                                image: carousal.blocks.filter((b:any)=>b.fields)[0].accessory,
                                            })),
                                            adaptive_card:"carousal"
                                        }
                                        :null
                                })
                                .filter(Boolean)
                        }
                    }) as any
                })
            })
        }
    }, [popupState.conversationId])

    // function findDuration(conversation: ConversationT) {
    //     return (new Date(conversation.end_time).getTime() - new Date(conversation.start_time).getTime()) / 1000
    // }

    return (
        <>
            <DropDownHeader {...props} conversations={groupBy == 'user_email'?dialogs.conversations:dialogs.baseConversation} totalCount={groupBy == 'user_email'?dialogs.totalEmailCount:dialogs.totalSessionCount} allStatus={allStatus} />
            {groupBy == 'user_email' ? (
                <TableEmailContainer
                    platform={platform}
                    conversations={dialogs.conversations}
                    setDetailsPopup={setDetailsPopup}
                    workspacename={workspacename}
                    callAPILogs={callAPILogs}
                    detailsPopup={detailsPopup}
                    bots={bots}
                    onView={(conversation_id: string) =>
                        setPopupState({
                            ...popupState,
                            showPopup: true,
                            loading: true,
                            conversationId: conversation_id
                        })
                    }
                    filterByEmail={filterByEmail}
                />
            ) : (
                <TableHomeUiContainer
                    workspacename={workspacename}
                    platform={platform}
                    conversations={dialogs.baseConversation}
                    bots={bots}
                    disable={detailsPopup.show}
                    onView={(conversation_id: string , root_req_id? :string) =>
                        setPopupState({
                            ...popupState,
                            showPopup: true,
                            loading: true,
                            conversationId: conversation_id,
                            idToScroll: root_req_id ? root_req_id : ""
                        })
                    }
                    sessions={sessions}
                    callAPILogs={callAPILogs}
                    detailsPopup={detailsPopup}
                    setDetailsPopup={setDetailsPopup}
                />
            )}
            {renderPopup()}
        </>
    )

    function renderPopup() {
        if (popupState.showPopup) {
            return (
                <TryIt
                    message={popupState.data}
                    close={() => {
                        setPopupState({
                            showPopup: false,
                            data: [],
                            conversationId: '',
                            loading: false,
                            idToScroll : ""
                        })
                    }}
                    loading={popupState.loading}
                    scollId={popupState.idToScroll}
                />
            )
        }
        return null
    }
}

type OutputT = {
    text: string
    output: { text: { response_type: 'text'; text: string } }[]
}

interface AgentLog {
    agent_session_logs: AgentSessionLog[]
}
interface AgentSessionLog {
    _id: string
    agent_session_id: string
    __v: number
    created_at: string
    message: string
    type: 'user-message' | 'agent-message'
}
function DetailedAgentConversationListView(props: {
    workspacename: string
    BOT_ID: string
    state: {
        showFlowPopup: boolean
        selectedAutomation: string
        showDatePopup: boolean
        selectedDate: { date: string; label: string }
    }
    sessions: { data: AgentSessionsTypeT[]; totalCount: number }
    currentPage: number
    setCurrentPage: any
    displayItems: DateSelectInput[]
    setSelectedDate: (date: { date: string; label: string }) => void
    bots: any[]
    changingGroupByRef:React.MutableRefObject<null | true>
}) {
    const { BOT_ID, workspacename, bots, state, sessions } = props

    const [popupState, setPopupState] = useState({
        showPopup: false,
        data: [],
        conversationId: '',
        loading: false,
        agentOrBot: 'agent'
    })

    const [detailsPopup, setDetailsPopup] = useState<{
        show: boolean
        loading: boolean
        data: any[]
        currentLog: any
        error: string
        index: null | number
        currentPage: number
    }>({
        show: false,
        loading: true,
        currentLog: {},
        data: [],
        error: '',
        index: null,
        currentPage: 1
    })

    const [agentSearch, setAgentSearch] = useState('')
    const [userSearch, setUserSearch] = useState('')

    useEffect(() => {
        if (popupState.showPopup) {
            if (popupState.agentOrBot == 'bot') {
                getJson(
                    watsonLogsApiURL(
                        `/${workspacename}/logs/${BOT_ID}/${getDateNumber(
                            props.state.selectedDate.date
                        )}?conversation_id=${popupState.conversationId}`
                    )
                ).then(e => {
                    console.log('e', e)
                    setPopupState({
                        ...popupState,
                        loading: false,
                        data: e.map((res: any) => {
                            // const resResponse = res.response[0]
                            const concatedResponse = res.response.reduce(
                                (acc: any, curr: any) => {
                                    return {
                                        ...acc,
                                        intents: curr.intents,
                                        entities: curr.entities,
                                        context: res.request.context_variables,
                                        faq:curr.faq,
                                        output: acc.output.concat(curr.output)
                                    }
                                },
                                { intents: [], entities: [],  faq:[], context: null, output: [] }
                            )

                            return {
                                text: res.request.input,
                                masked: res.masked,
                                intents: concatedResponse.intents.map((intent: any) => intent.intent),
                                entities: concatedResponse.entities.map((entity: any) => entity.entity),
                                context: res.request.context_variables,
                                faq:concatedResponse.faq,
                                output: concatedResponse.output
                                    .map((e: any) => {
                                        return e.output == 'text'
                                            ? {
                                                  response_type: 'text',
                                                  text: e.text
                                              }
                                            : e.output == 'option'
                                            ? {
                                                  response_type: 'text',
                                                  title: e.option.title,
                                                  options: e.option.options.map((e: any) => ({
                                                      label: e.label
                                                  }))
                                              }
                                            : e.output=='adaptive_card'?
                                            {
                                                response_type: 'text',
                                                title: e.adaptive_card.blocks.filter((b:any)=>b.text&&b.text.style=="bold")[0].text.text,
                                                description: e.adaptive_card.blocks.filter((b:any)=>b.text&&b.text.style=="normal")[0].text.text,
                                                fields:  e.adaptive_card.blocks.filter((b:any)=>b.fields)[0].fields,
                                                buttons: e.adaptive_card.blocks.filter((b:any)=>b.elements)[0].elements,
                                                image: e.adaptive_card.blocks.filter((b:any)=>b.fields)[0].accessory,
                                                adaptive_card:"adaptive_card"
                                            } : e.type == 'Carousal' ? {
                                                response_type: 'text',
                                                values:e.accumulate_carousal.map((carousal:any)=>({
                                                    title: carousal.blocks.filter((b:any)=>b.text&&b.text.style=="bold")[0].text.text,
                                                    description: carousal.blocks.filter((b:any)=>b.text&&b.text.style=="normal")[0].text.text,
                                                    fields:  carousal.blocks.filter((b:any)=>b.fields)[0].fields,
                                                    buttons: carousal.blocks.filter((b:any)=>b.elements)[0].elements,
                                                    image: carousal.blocks.filter((b:any)=>b.fields)[0].accessory,
                                                })),
                                                adaptive_card:"carousal"
                                            }
                                            : null
                                    })
                                    .filter(Boolean)
                            }
                        }) as any
                    })
                })
            } else {
                getJson(
                    watsonLogsApiURL(
                        `/${workspacename}/agents/getAgentSessionById/${BOT_ID}/${popupState.conversationId}?sort_by_start_time=-1`
                    )
                ).then(e => {
                    setPopupState({
                        ...popupState,
                        loading: false,
                        data: e.agent_session_logs.reduce((acc: OutputT[], current: any) => {
                            if (current.type === 'user-message') {
                                return [
                                    ...acc,
                                    { text: current.message, intents: [], entities: [], context: {}, output: [] }
                                ]
                            } else {
                                if (acc.length != 0) {
                                    const last = acc[acc.length - 1]
                                    last.output.push({ text: { response_type: 'text', text: current.message } })
                                    return acc
                                } else {
                                    return [
                                        ...acc,
                                        {
                                            text: '',
                                            intents: [],
                                            entities: [],
                                            context: {},
                                            output: [
                                                { text: { response_type: 'text' as 'text', text: current.message } }
                                            ]
                                        }
                                    ]
                                }
                            }
                        }, []) as any
                    })
                })
            }
        }
    }, [popupState.conversationId])

    return (
        <>
            <DropDownHeaderForAgent
                {...props}
                sessions={sessions.data}
                totalCount={sessions.totalCount}
                agentSearch={agentSearch}
                setAgentSearch={setAgentSearch}
                userSearch={userSearch}
                setUserSearch={setUserSearch}
            />

            <TableUiContainerForAgent
                workspacename={workspacename}
                bots={bots}
                disable={detailsPopup.show}
                onView={(execution: AgentSessionsTypeT, agentOrBot: 'agent' | 'bot') =>
                    setPopupState({
                        ...popupState,
                        showPopup: true,
                        loading: true,
                        conversationId: agentOrBot == 'agent' ? execution.agent_session_id : execution.conversation_id,
                        agentOrBot: agentOrBot
                    })
                }
                sessions={sessions.data
                    .filter(
                        a =>
                            agentSearch.length == 0 ||
                            (a.agent_email &&
                                a.agent_email.toLocaleLowerCase().indexOf(agentSearch.toLocaleLowerCase()) != -1)
                    )
                    .filter(
                        a =>
                            userSearch.length == 0 ||
                            (a.user_email &&
                                a.user_email.toLocaleLowerCase().indexOf(userSearch.toLocaleLowerCase()) != -1)
                    )}
            />
            {renderPopup()}
        </>
    )

    function renderPopup() {
        if (popupState.showPopup) {
            return (
                <TryIt
                    message={popupState.data}
                    close={() => {
                        setPopupState({
                            showPopup: false,
                            data: [],
                            conversationId: '',
                            loading: false,
                            agentOrBot: 'agent'
                        })
                    }}
                    loading={popupState.loading}
                    agentHandoverLog={popupState.agentOrBot == 'agent'}
                />
            )
        }
        return null
    }
}

const DropDownHeader = (props: any) => {
    const {
        conversations,
        sessions,
        setSessions,
        chatChannel,
        setChatChannel,
        currentPage,
        setCurrentPage,
        displayItems,
        state,
        setSelectedDate,
        filterByEmail,
        setFilterByEmail,
        totalCount,
        changingGroupByRef,
        allStatus
    } = props

    const toDisplay = displayItems.find((x: DateSelectInput) => x.date === state.selectedDate.date)
    return (
        <DropdownContainer>
            <div className="tab_container_left">
                <Dropdwon
                    header={'Group by'}
                    content={'IT_Bot'}
                    value={statusItems.filter(v => v.value == props.groupBy)[0].label}
                    list={statusItems}
                    onClick={(data: any) => {
                        changingGroupByRef.current = true
                        if(data != props.groupBy){
                            setCurrentPage(1)
                            props.setGroupBy(data)
                            setSessions("all")
                            props.setPlatform("all")
                        }
                    }}
                />
                {props.groupBy == 'session_status' ? (
                    <Dropdwon
                        header={'Filter by'}
                        content={'All Sessions'}
                        list={[
                            { label: 'All Sessions', value: 'All Sessions' },
                            ...allStatus.filter((f:any)=>f!='faq_answered')
                                .map((s:("ongoing" | "resolved" | "escalated" | "abandoned" | "unresolved" | "faq_answered" | "ignored")) => s== 'faq_answered'? ({ label: "FAQ Answered", value: s }): ({ label: capitalizeFirstLetter(s), value: s }))
                        ]}
                        value={sessions == 'all' ? 'All Sessions' : sessions == 'faq_answered'? "FAQ Answered" : capitalizeFirstLetter(sessions)}
                        onClick={(data: any) => {
                            setSessions(data)
                            setCurrentPage(1)
                        }}
                    />
                ) : (
                    <Dropdwon
                        header={'Filter by'}
                        content={'All Email'}
                        list={[
                            { label: 'All Email', value: 'all' },
                            ...filterData(
                                conversations.map((emails: any) => ({ ...emails, email: emails._id[0] })),
                                'email'
                            )
                                .filter(Boolean)
                                .map(s => ({ label: s, value: s }))
                        ]}
                        value={filterByEmail == 'all' ? 'All Email' : filterByEmail}
                        onClick={(data: any) => setFilterByEmail(data)}
                    />
                )}
                <Dropdwon
                    header={'Chat channel'}
                    content={'All'}
                    value={props.platform == 'try_it' ? 'Try Me' : capitalizeFirstLetter(props.platform)}
                    list={[
                        { label: 'All', value: 'all' },
                        { label: 'Try Me', value: 'try_it' },
                        { label: 'Teams', value: 'teams' },
                        { label: 'Slack', value: 'slack' },
                        { label: 'Widget', value: 'widget' }
                    ]}
                    onClick={(data: any) => {
                        props.setPlatform(data)
                    }}
                />
            </div>
            <div className="tab_container_right">
                <Dropdwon
                    header={'Show by'}
                    value={toDisplay ? toDisplay.label : 'invalid date'}
                    content={'IT_Bot'}
                    list={displayItems.map((d: DateSelectInput) => ({ value: d, label: d.label }))}
                    onClick={(item: DateSelectInput) => setSelectedDate(item)}
                />{' '}
                {conversations.length === 0 ? null : (
                    <>
                        <p>
                            {currentPage === 1 ? 1 : (currentPage - 1) * 50 + 1} -{' '}
                            {totalCount > currentPage * 50 ? currentPage * 50 : totalCount} of {totalCount}
                            <span
                                className={currentPage !== 1 ? 'prev_icon' : 'prev_icon editor_btn_disabled'}
                                onClick={() => setCurrentPage(currentPage !== 0 ? currentPage - 1 : 0)}
                            >
                                <DropdownWithCircle />
                            </span>
                            <span
                                className={
                                    totalCount > currentPage * 50 ? 'next_icon' : 'next_icon editor_btn_disabled'
                                }
                                onClick={() => {
                                    changingGroupByRef.current = null
                                    setCurrentPage(currentPage + 1)
                                }}
                            >
                                <DropdownWithCircle />
                            </span>
                        </p>
                    </>
                )}
            </div>
        </DropdownContainer>
    )
}

const DropDownHeaderForAgent = (props: any) => {
    const {
        sessions,
        currentPage,
        setCurrentPage,
        displayItems,
        state,
        setSelectedDate,
        totalCount,
        changingGroupByRef,
        agentSearch,
        setAgentSearch,
        userSearch,
        setUserSearch
    } = props

    const toDisplay = displayItems.find((x: DateSelectInput) => x.date === state.selectedDate.date)

    return (
        <DropdownContainer>
            <div className="tab_container_left">
                <SerachInputWrapper>
                    <SerachInput
                        type="search"
                        value={agentSearch}
                        placeholder={`Search agent name`}
                        onChange={(e: any) => setAgentSearch(e.target.value)}
                    />
                    <span>
                        <SearchIcon />
                    </span>
                </SerachInputWrapper>
                <SerachInputWrapper>
                    <SerachInput
                        type="search"
                        value={userSearch}
                        placeholder={`Search user name`}
                        onChange={(e: any) => setUserSearch(e.target.value)}
                    />
                    <span>
                        <SearchIcon />
                    </span>
                </SerachInputWrapper>
            </div>
            <div className="tab_container_right">
                <Dropdwon
                    header={'Show by'}
                    value={toDisplay ? toDisplay.label : 'invalid date'}
                    content={'IT_Bot'}
                    list={displayItems.map((d: DateSelectInput) => ({ value: d, label: d.label }))}
                    onClick={(item: DateSelectInput) => setSelectedDate(item)}
                />{' '}
                {sessions.length === 0 ? null : (
                    <>
                        <p>
                            {currentPage === 1 ? 1 : (currentPage - 1) * 50 + 1} -{' '}
                            {totalCount > currentPage * 50 ? currentPage * 50 : totalCount} of {totalCount}
                            <span
                                className={currentPage !== 1 ? 'prev_icon' : 'prev_icon editor_btn_disabled'}
                                onClick={() => setCurrentPage(currentPage !== 0 ? currentPage - 1 : 0)}
                            >
                                <DropdownWithCircle />
                            </span>
                            <span
                                className={
                                    totalCount > currentPage * 50 ? 'next_icon' : 'next_icon editor_btn_disabled'
                                }
                                onClick={() => {
                                    changingGroupByRef.current = null
                                    setCurrentPage(currentPage + 1)
                                }}
                            >
                                <DropdownWithCircle />
                            </span>
                        </p>
                    </>
                )}
            </div>
        </DropdownContainer>
    )
}

const statusCounts = [    
    {countName:"resolvedCount" , tooltip:"Auto Resolved" , icon:<AutoResolvedIcon/>},
    {countName:"abandonedCount" , tooltip:"Abandoned" , icon:<AbandonedIcon/>},
    {countName:"escalatedCount" , tooltip:"Escalated" , icon:<UserIconAnalytics/>},
    {countName:"unresolvedCount" , tooltip:"Unresolved" , icon:<UnresolvedIcon/>},
    {countName:"ongoingCount" , tooltip:"On Going" , icon:<OnGoingIcon/>},
    {countName:"faqAnsweredCount" , tooltip:"FAQ Answered" , icon:<FaqAnsweredIcon/>},
    {countName:"ignoredCount" , tooltip:"Ignored" , icon:<IgnoredIcon/>},
]

export const TableHomeUiContainer = (props: {
    conversations: BaseConversationT[]
    platform: string
    onView: (conversation_id: string,root_req_id?:string) => void
    workspacename: string
    bots: any[]
    callAPILogs: (conversation: BaseConversationT, index: number, page: number,session:boolean) => void
    detailsPopup: {
        show: boolean
        loading: boolean
        data: any[]
        currentLog: any
        error: string
        index: null | number
        currentPage: number
    }
    setDetailsPopup: any;
    currentPage?: number
    setCurrentPage?: Function
    sessions?: string
    from?: string
    currentLog?: any
    error?: boolean
    loading?: boolean
    disable?: boolean
}) => {
    const {
        conversations,
        onView,
        bots,
        from,
        currentLog,
        error,
        loading,
        disable,
        sessions,
        currentPage,
        setCurrentPage,
        callAPILogs,
        detailsPopup,
        platform,
        workspacename,
        setDetailsPopup
    } = props

    const [userDetails, shoUserDetails] = useState<null | BaseConversationT>(null)
    const [index,setIndex] = useState<null | number>(null)
    const [feedbackDetails, setFeedbackDetails] = useState<null | number>(null)

    function returnSessionFilterValue(session:string,data:BaseConversationT){
        switch(session){
            case "auto_resolved":
                return data.resolvedCount > 0
            case "escalated":
                return data.escalatedCount > 0
            case "abandoned":
                return data.abandonedCount > 0
            case "faqAnsweredCount":
                return data.faqAnsweredCount > 0
            case "ongoingCount":
                return data.ongoingCount > 0
            case "ignoredCount":
                return data.ignoredCount > 0        
            default:
                return data.unresolvedCount > 0
        }

    }

    return (
        <TableWrapper>
            <TableContainer className={conversations.length < 4 ? 'table_row_single' : 'table_row_multiple'}>
                <Table>
                    <Thead>
                        <Tr className={disable ? 'is_opacity' : ''}>
                            <Th size={2}>
                                    <div className="th_label">Sessions</div>
                            </Th>
                            <Th size={2}>
                                <div className="th_label">Dialogs/FAQs</div>
                            </Th>
                            <Th size={2}>
                                <div className="th_label">Session status</div>
                            </Th>
                            <Th contentCenter size={1.5}>
                                <div className="th_label">Feedback</div>
                            </Th>
                            <Th size={1.5}>
                                <div className="th_label">Chat channel</div>
                            </Th>
                            <Th size={3}>
                                <div className="th_label">User details</div>
                            </Th>
                        </Tr>
                    </Thead>
                    {loading ||
                    error ||
                    conversations.length == 0 ? (
                        <Tbody
                            style={{
                                height: 140,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            {loading ? (
                                <Loader.PopupLoader show={true} className={'medium_loader'} />
                            ) : error ? (
                                <div className="no_data_available">
                                    <p>Something went wrong! Please try again after some time.</p>
                                </div>
                            ) : (
                                <div className="no_data_available">
                                    <p>No data available</p>
                                </div>
                            )}
                        </Tbody>
                    ) : (
                        <Tbody>
                            {conversations.map((execution: BaseConversationT, i: number) => (
                                    <div className={conversations.length < 3 ? 'is_opacity_wrapper table_single_row' : 'is_opacity_wrapper'}>
                                        <Tr 
                                        // className={disable ? 'is_opacity' : ''} 
                                        className={disable ? conversations.length < 3 ? "is_opacity logs_first_row" : "is_opacity" : conversations.length < 3 ? "logs_first_row" : ''}
                                        onClick={() => {
                                                callAPILogs(execution, i, detailsPopup.currentPage,true)
                                        }}
                                        >
                                            <Td size={2}>
                                                <ContentEllipse>
                                                    <TdContent className="td_header">
                                                        <SmallButton primary onClick={(event:any) =>{
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            onView(execution.conversation_id)
                                                        }}>
                                                            <label>View Session</label>{' '}
                                                        </SmallButton>
                                                        <div className="session_count">
                                                                <>
                                                                    <CountNumber>
                                                                    <label>{execution.totalCount}</label>
                                                                    </CountNumber>
                                                                    <LabelImage pointer onClick={() => {
                                                                             callAPILogs(execution, i, detailsPopup.currentPage,true)
                                                                           }}>
                                                                            <NextIconCanvas />
                                                                        </LabelImage>
                                                                </>
                                                        </div>
                                                    </TdContent>
                                                </ContentEllipse>
                                                <div className="td_date">
                                                    {formatDateInCard(execution.endTime)} |{' '}
                                                    {formatAMPM(execution.endTime)}
                                                </div>
                                            </Td>
                                            <Td size={2}>
                                                 <div className="dialog_logs_session_status">
                                                    {execution.dialogCount?<ContentEllipse>
                                                        <div className="icon_left">
                                                            <DialogCardIcon />
                                                        </div>
                                                        <label>{execution.dialogCount>9?execution.dialogCount:"0"+execution.dialogCount}</label>
                                                    </ContentEllipse>:null}
                                                    {execution.faqDialogCount?<ContentEllipse>
                                                        <div className="icon_left">
                                                            <FaqICon />
                                                        </div>
                                                        <label>{execution.faqDialogCount>9?execution.faqDialogCount:"0"+execution.faqDialogCount}</label>
                                                    </ContentEllipse>:null}
                                                </div>
                                            </Td>
                                            <Td size={2}>
                                                <div className="dialog_logs_session_status">
                                                    {statusCounts.map((status,i)=>
                                                        execution[`${status.countName}`]?
                                                        <ContentEllipse key={i}>
                                                            <div className="icon_left">
                                                                <Tooltip
                                                                    className="target customTip "
                                                                    zIndex={10000}
                                                                    arrowSize={8}
                                                                    tagName="span"
                                                                    content={status.tooltip}
                                                                    distance={5}
                                                                >
                                                                    {status.icon}
                                                                </Tooltip>
                                                            </div>
                                                            <label>{execution[`${status.countName}`] > 9 ? execution[`${status.countName}`] : "0" + execution[`${status.countName}`]}</label>
                                                        </ContentEllipse> : null
                                                    )}
                                                </div>
                                            </Td>
                                            <Td contentCenter size={1.5}>
                                                {execution.feedback.map((feedback:null|string)=>(
                                                    (feedback &&
                                                    (feedback.toLowerCase() == 'satisfied' ||
                                                    feedback.toLowerCase() == 'not satisfied')) ? (
                                                    feedback.toLowerCase() == 'satisfied' ? (
                                                        <ContentEllipse contentCenter>
                                                            <div className="icon_left_25">
                                                                <NewThumbsUpIcon />
                                                            </div>
                                                        </ContentEllipse>
                                                    ) : (
                                                        <ContentEllipse contentCenter>
                                                            <div className="icon_left_25">
                                                                <NewThumbsDownIcon />
                                                            </div>
                                                        </ContentEllipse>
                                                    )
                                                    ) : feedback ? (
                                                        
                                                        <>
                                                        <ContentEllipse
                                                          onMouseEnter={() => {
                                                            setFeedbackDetails(i)
                                                        }}
                                                        onMouseLeave={(e: any) => {
                                                            e.preventDefault()
                                                            setFeedbackDetails(null)
                                                        }}
                                                        className="logs_feedback_content" contentCenter>{feedback}</ContentEllipse>
                                                            {feedbackDetails == i && (
                                                                <Downshift isOpen onOuterClick={() => setFeedbackDetails(null)}>
                                                                    {() => (
                                                                        <span>
                                                                            <EntityWrapper>
                                                                                <TrainHeader>
                                                                                    <label>Feedback</label>
                                                                                </TrainHeader>
                                                                                <UserWrapper>
                                                                                    <p>
                                                                                        {feedback}
                                                                                    </p>
                                                                                </UserWrapper>
                                                                            </EntityWrapper>
                                                                        </span>
                                                                    )}
                                                                </Downshift>
                                                            )}
                                                        </>
                                                    ): (
                                                    <ContentEllipse contentCenter>-</ContentEllipse>
                                                    )
                                                ))}
                                            </Td>
                                            <Td size={1.5}>
                                                {' '}
                                                <ContentEllipse>
                                                    <div className="icon_left_24">
                                                        {execution.chat_channel == 'widget' ? (
                                                            <WidgetIcon />
                                                        ) : execution.chat_channel == 'teams' ? (
                                                            <TeamsIcon />
                                                        ) : execution.chat_channel == 'slack' ? (
                                                            <SlackIconCC />
                                                        ) : (
                                                            <TryMeanalyticsIcon />
                                                        )}
                                                    </div>
                                                </ContentEllipse>
                                            </Td>
                                            <Td
                                                size={3}
                                                onMouseEnter={(e: any) => {
                                                    e.preventDefault()
                                                    shoUserDetails(execution)
                                                    setIndex(i)
                                                }}
                                                onMouseLeave={(e: any) => {
                                                    e.preventDefault()
                                                    shoUserDetails(null)
                                                    setIndex(null)
                                                }}
                                                className={index == i ? "hovered_row" : ""}
                                            >
                                                <TdContent className="td_email">
                                                    Email:{' '}
                                                    <span className="truncate">
                                                        {' '}
                                                        {execution.user && execution.user.user_email ? execution.user.user_email : '-'}
                                                    </span>
                                                </TdContent>
                                                <div className="td_id">
                                                    Unique ID: <span>{execution.user && execution.user.user_id ? execution.user.user_id : '-'}</span>
                                                </div>
                                                {index == i && userDetails && !detailsPopup.show && (
                                                    <Downshift isOpen onOuterClick={() => shoUserDetails(null)}>
                                                        {() => (
                                                            <span className="user_details_popup_mutiple">
                                                                <TrainEntityPopup user={userDetails.user} />
                                                            </span>
                                                        )}
                                                    </Downshift>
                                                )}
                                            </Td>
                                            
                                        </Tr>
                                        {detailsPopup.show && detailsPopup.index == i && (
                                            <Downshift
                                                isOpen
                                                // onOuterClick={() =>
                                                //     setDetailsPopup({
                                                //         ...detailsPopup,
                                                //         show: false,
                                                //         error: '',
                                                //         currentLog: null,
                                                //         index: null,
                                                //         currentPage: 1
                                                //     })
                                                // }
                                            >
                                                {() => (
                                                    <div>
                                                        <OpenSubTable>
                                                            <TableUiContainer
                                                                workspacename={workspacename}
                                                                platform={platform}
                                                                conversations={detailsPopup.data}
                                                                bots={bots}
                                                                onView={(conversation_id: string,root_req_id?:string) => onView(conversation_id,root_req_id)}
                                                                currentLog={detailsPopup.currentLog}
                                                                error={detailsPopup.error.length > 0}
                                                                loading={detailsPopup.loading}
                                                                currentPage={detailsPopup.currentPage}
                                                                setCurrentPage={(page: number) => {
                                                                    callAPILogs(
                                                                        detailsPopup.currentLog,
                                                                        detailsPopup.index ? detailsPopup.index : 0,
                                                                        page,
                                                                        false
                                                                    )
                                                                }}
                                                                closePopup={()=>{
                                                                    setDetailsPopup({
                                                                        ...detailsPopup,
                                                                        show: false,
                                                                        error: '',
                                                                        currentLog: null,
                                                                        index: null,
                                                                        currentPage: 1
                                                                    })
                                                                }}
                                                                user={execution.user? execution.user : null}
                                                            />
                                                        </OpenSubTable>
                                                    </div>
                                                )}
                                            </Downshift>
                                        )}
                                    </div>
                                ))}
                        </Tbody>
                    )}
                </Table>
                {userDetails !=null&& !detailsPopup.show && (
                    <Downshift isOpen onOuterClick={() => shoUserDetails(null)}>
                        {() => (
                            <span className="user_details_popup_single">
                                <TrainEntityPopup user={userDetails.user} />
                            </span>
                        )}
                    </Downshift>
                )}
            </TableContainer>
        </TableWrapper>
    )
}

export const TableUiContainerForEmail = (props: {
    conversations: EmailConversationT[]
    platform: string
    onView: (conversation_id: string) => void
    workspacename: string
    bots: any[]
    closePopup:Function
    currentPage?: number
    setCurrentPage?: Function
    sessions?: string
    from?: string
    currentLog?: any
    error?: boolean
    loading?: boolean
    disable?: boolean
}) => {
    const {
        conversations,
        onView,
        bots,
        from,
        currentLog,
        error,
        loading,
        disable,
        sessions,
        currentPage,
        setCurrentPage,
        closePopup
    } = props

    const [userDetails, shoUserDetails] = useState<null | number>(null)
    const [feedbackDetails, setFeedbackDetails] = useState<null | number>(null)

    return (
        <TableWrapper>
            <TableContainer>
                <Table>
                    {from && from == 'detailsPopup' && currentPage && (
                        <Thead>
                            <Tr>
                                <Th size={10}>
                                    <div className="th_label" style={{ color: '#355d8b' }}>
                                        Results
                                    </div>
                                </Th>

                                <Th size={2}>
                                    {/* <div className="th_label">Chat channel</div> */}
                                    <div className="tab_container_right detailed_session usermail">
                                        <p className={'logs_pagination'}>
                                            {currentPage === 1 ? 1 : (currentPage - 1) * 50 + 1} -{' '}
                                            {currentLog.totalCount > currentPage * 50
                                                ? currentPage * 50
                                                : currentLog.totalCount}{' '}
                                            of {currentLog.totalCount}
                                            <span
                                                className={
                                                    currentPage !== 1 ? 'prev_icon' : 'prev_icon editor_btn_disabled'
                                                }
                                                onClick={() =>
                                                    setCurrentPage &&
                                                    setCurrentPage(currentPage !== 0 ? currentPage - 1 : 0)
                                                }
                                            >
                                                <DropdownWithCircle />
                                            </span>
                                            <span
                                                className={
                                                    currentLog.totalCount > currentPage * 50
                                                        ? 'next_icon'
                                                        : 'next_icon editor_btn_disabled'
                                                }
                                                onClick={() => {
                                                    setCurrentPage && setCurrentPage(currentPage + 1)
                                                }}
                                            >
                                                <DropdownWithCircle />
                                            </span>
                                            <div className='icon_left usermail_close' onClick={()=>{
                                                    closePopup()
                                                }}>
                                                   <CloseIconWithCircle/>
                                                </div>
                                        </p>
                                        {/* <ContentEllipse pointer>
                                                <div className='icon_left usermail_close' onClick={()=>{
                                                    closePopup()
                                                }}>
                                                   <CloseIconWithCircle/>
                                                </div>
                                            </ContentEllipse> */}
                                    </div>
                                </Th>
                            </Tr>
                        </Thead>
                    )}
                    <Thead>
                        <Tr>
                            <Th size={from && from == 'detailsPopup' ? 2.6 : 2}>
                                {from && from == 'detailsPopup' ? (
                                    <>
                                        <div className="user_email_left chatbot_logs_mail">
                                            <ContentEllipse>
                                                <TdContent className="td_header">
                                                    {currentLog._id[0] ? currentLog._id[0] : 'anonymous user'}
                                                </TdContent>
                                            </ContentEllipse>
                                        </div>
                                        {currentLog._id[0] && currentLog.totalCount > 1 && (
                                            <div className="user_email_right usermail_count">
                                                <LabelImage pointer onClick={()=>{ closePopup() }}>
                                                    <NextIconCanvas />
                                                </LabelImage>
                                                <CountNumber className="usermail_count_box">
                                                    <label>{currentLog.totalCount}</label>
                                                </CountNumber>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div className="th_label">Sessions</div>
                                )}
                            </Th>
                            <Th size={2}>
                                <div className="th_label">Session status</div>
                            </Th>
                            <Th size={2}>
                                <div className="th_label">Dialog</div>
                            </Th>
                            <Th contentCenter size={2}>
                                <div className="th_label">Feedback</div>
                            </Th>
                            <Th size={1.5}>
                                <div className="th_label">Chat channel</div>
                            </Th>
                            <Th size={2.5}>
                                <div className="th_label">User details</div>
                            </Th>
                        </Tr>
                    </Thead>
                    {loading ||
                    error ||
                    conversations.filter(
                        (c: EmailConversationT) =>
                            (from && from == 'detailsPopup') ||
                            props.platform.toLocaleLowerCase() == 'all' ||
                            c.chat_channel.toLocaleLowerCase() == props.platform.toLocaleLowerCase()
                    ).length == 0 ? (
                        <Tbody
                            style={{
                                height: 140,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            {loading ? (
                                <Loader.PopupLoader show={true} className={'medium_loader'} />
                            ) : error ? (
                                <div className="no_data_available">
                                    <p>Something went wrong! Please try again after some time.</p>
                                </div>
                            ) : (
                                <div className="no_data_available">
                                    <p>No data available</p>
                                </div>
                            )}
                        </Tbody>
                    ) : (
                        <Tbody>
                            {conversations
                                .filter(
                                    (c: EmailConversationT) =>
                                        (from && from == 'detailsPopup') ||
                                        props.platform.toLocaleLowerCase() == 'all' ||
                                        c.chat_channel.toLocaleLowerCase() == props.platform.toLocaleLowerCase()
                                )
                                .filter(
                                    (c: EmailConversationT) =>
                                        (from && from == 'detailsPopup') ||
                                        (sessions &&
                                            (sessions == 'All Sessions' ||
                                                sessions.toLocaleLowerCase() ==
                                                    (c.status && c.status.toLocaleLowerCase())))
                                )
                                // .slice(currentPage === 1 ? 0 : (currentPage - 1) * 50, currentPage * 50)
                                .map((execution: EmailConversationT, i: number) => (
                                    <div className={'is_opacity_wrapper'}>
                                        <Tr className={disable ? 'is_opacity' : ''}>
                                            <Td size={from && from == 'detailsPopup' ? 3 : 2}>
                                                <ContentEllipse>
                                                    <TdContent className="td_header">
                                                        <SmallButton primary onClick={() => onView(execution.conversation_id)}>
                                                            <label>View Session</label>{' '}
                                                        </SmallButton>
                                                    </TdContent>
                                                </ContentEllipse>
                                                <div className="td_date">
                                                    {formatDateInCard(execution.start_time)} |{' '}
                                                    {formatAMPM(execution.start_time)}
                                                </div>
                                            </Td>
                                            <Td size={2}>
                                                {execution.status == 'Resolved' ? (
                                                    <ContentEllipse>
                                                        <div className="icon_left">
                                                            <AutoResolvedIcon />
                                                        </div>
                                                        <label>{execution.status}</label>
                                                    </ContentEllipse>
                                                ) : execution.status == 'Abandoned' ? (
                                                    <ContentEllipse>
                                                        <div className="icon_left">
                                                            <AbandonedIcon />
                                                        </div>
                                                        <label>{execution.status}</label>
                                                    </ContentEllipse>
                                                ) : execution.status == 'Escalated' ? (
                                                    <ContentEllipse>
                                                        <div className="icon_left">
                                                            <UserIconAnalytics />
                                                        </div>
                                                        <label>{execution.status}</label>
                                                        <span
                                                            style={{
                                                                width: 18,
                                                                height: 18,
                                                                marginLeft: 5
                                                            }}
                                                        >
                                                            <TeamsIcon />
                                                        </span>{' '}
                                                    </ContentEllipse>
                                                ) : (
                                                    <ContentEllipse>
                                                        {execution.chat_channel != 'widget' &&
                                                        execution.chat_channel != 'teams' &&
                                                        execution.chat_channel != 'slack' ? (
                                                            <>
                                                                <div className="icon_left">
                                                                    <AbandonedIcon />
                                                                </div>
                                                                <label>Abandoned</label>
                                                            </>
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </ContentEllipse>
                                                )}
                                            </Td>
                                            <Td size={2}>
                                                {bots.find((b: any) => b.id == execution.user.bot_id) ? (
                                                    <a
                                                        className="diloag_td_content diloag_td_content_link"
                                                        href={`/workspace/${props.workspacename}/dialog/${
                                                            bots.find((b: any) => b.id == execution.user.bot_id).name
                                                        }/detail/${execution.dialog_id}`}
                                                        target="_blank"
                                                    >
                                                        {execution.dialog_name}
                                                    </a>
                                                ) : (
                                                    <div className="diloag_td_content">{execution.dialog_name}</div>
                                                )}
                                            </Td>
                                            <Td contentCenter size={2}>
                                                {(execution.feedback &&
                                                    (execution.feedback.toLowerCase() == 'satisfied' ||
                                                    execution.feedback.toLowerCase() == 'not satisfied')) ? (
                                                    execution.feedback.toLowerCase() == 'satisfied' ? (
                                                        <ContentEllipse contentCenter>
                                                            <div className="icon_left_24">
                                                                <NewThumbsUpIcon />
                                                            </div>
                                                        </ContentEllipse>
                                                    ) : (
                                                        <ContentEllipse contentCenter>
                                                            <div className="icon_left_24">
                                                                <NewThumbsDownIcon />
                                                            </div>
                                                        </ContentEllipse>
                                                    )) : execution.feedback ? (
                                                    <>
                                                    <ContentEllipse
                                                      onMouseEnter={() => {
                                                        setFeedbackDetails(i)
                                                    }}
                                                    onMouseLeave={(e: any) => {
                                                        e.preventDefault()
                                                        setFeedbackDetails(null)
                                                    }}
                                                    className="logs_feedback_content" contentCenter>{execution.feedback}</ContentEllipse>
                                                        {feedbackDetails == i && (
                                                            <Downshift isOpen onOuterClick={() => setFeedbackDetails(null)}>
                                                                {() => (
                                                                    <span>
                                                                        <EntityWrapper>
                                                                            <TrainHeader>
                                                                                <label>Feedback</label>
                                                                            </TrainHeader>
                                                                            <UserWrapper>
                                                                                <p>
                                                                                    {execution.feedback}
                                                                                </p>
                                                                            </UserWrapper>
                                                                        </EntityWrapper>
                                                                    </span>
                                                                )}
                                                            </Downshift>
                                                        )}
                                                    </>
                                                ) : (
                                                    <ContentEllipse contentCenter>-</ContentEllipse>
                                                )}
                                            </Td>
                                            <Td size={1.5}>
                                                {' '}
                                                <ContentEllipse>
                                                    {/* <div className="icon_left_24">
                                                        {execution.chat_channel == 'widget' ? (
                                                            <WidgetIcon />
                                                        ) : execution.chat_channel == 'teams' ? (
                                                            <TeamsIcon />
                                                        ) : execution.chat_channel == 'slack' ? (
                                                            <SlackIconCC />
                                                        ) : (
                                                            <TryMeanalyticsIcon />
                                                        )}
                                                    </div> */}
                                                    <label>
                                                        {execution.chat_channel == 'try_it'
                                                            ? 'Try Me'
                                                            : capitalizeFirstLetter(execution.chat_channel)}
                                                    </label>
                                                </ContentEllipse>
                                            </Td>
                                            <Td
                                                size={2.5}
                                                // onClick={() => {
                                                //     shoUserDetails(i)
                                                // }}
                                                onMouseEnter={() => {
                                                    shoUserDetails(i)
                                                }}
                                                onMouseLeave={(e: any) => {
                                                    e.preventDefault()
                                                    shoUserDetails(null)
                                                }}
                                            >
                                                <TdContent className="td_email">
                                                    Email:{' '}
                                                    <span className="truncate">
                                                        {' '}
                                                        {execution.user.user_email ? execution.user.user_email : '-'}
                                                    </span>
                                                </TdContent>
                                                <div className="td_id">
                                                    Unique ID: <span>{execution.user.user_id}</span>
                                                </div>
                                                {userDetails == i && (
                                                    <Downshift isOpen onOuterClick={() => shoUserDetails(null)}>
                                                        {() => (
                                                            <span>
                                                                <TrainEntityPopup user={execution.user} />
                                                            </span>
                                                        )}
                                                    </Downshift>
                                                )}
                                            </Td>
                                        </Tr>
                                    </div>
                                ))}
                        </Tbody>
                    )}
                </Table>
            </TableContainer>
        </TableWrapper>
    )
}

export const TableUiContainer = (props: {
    conversations: ConversationT[]
    platform: string
    onView: (conversation_id: string,root_req_id?:string) => void
    workspacename: string
    bots: any[]
    user:{
        _id: string;
        bot_id: null | string;
        user_id: string;
        __v: number;
        user_email: string;
        user_firstname: string;
        user_lastname: string;
    } | null
    currentPage: number
    setCurrentPage: Function
    closePopup:Function
    sessions?: string
    currentLog?: any
    error?: boolean
    loading?: boolean
    disable?: boolean
}) => {
    const {
        conversations,
        onView,
        bots,
        currentLog,
        error,
        loading,
        disable,
        sessions,
        currentPage,
        setCurrentPage,
        user,
        closePopup
    } = props

    const [userDetails, shoUserDetails] = useState<null | number>(null)

    function returnIcon(status: ("ongoing" | "resolved" | "escalated" | "abandoned" | "unresolved" | "faq_answered" | "ignored")){
        switch(status){
            case 'resolved':
                return  <AutoResolvedIcon />
            case 'abandoned':
                return <AbandonedIcon />
            case 'unresolved':
                    return <UnresolvedIcon />
            case 'faq_answered':
                    return <FaqAnsweredIcon />
            case 'ongoing' :
                    return <OnGoingIcon />
            case 'ignored' :
                    return <IgnoredIcon />
            default:
                    return <UserIconAnalytics/>
        }
    }

    return (
        <TableWrapper>
            <TableContainer className={conversations.length < 4 ? 'table_row_inner_single' : 'table_row_inner_multiple'}>
                <Table>
                    <Thead>
                        <Tr>
                            <Th size={2.5}>
                                    <>
                                        <div className="user_email_left chatbot_logs_mail detailed_session">
                                            <ContentEllipse>
                                                <TdContent className="td_header">
                                                   View Session
                                                </TdContent>
                                            </ContentEllipse>
                                        </div>
                                        <div className="user_email_right">
                                                <LabelImage pointer onClick={()=>{
                                                    closePopup()
                                                }}>
                                                    <NextIconCanvas />
                                                </LabelImage>
                                                <CountNumber>
                                                    <label>{currentLog.totalCount}</label>
                                                </CountNumber>
                                        </div>
                                    </>
                            </Th>
                            <Th size={2.5}>
                                <div className="th_label" style={{ color: '#355d8b' }}>Dialog/FAQs</div>
                            </Th>
                            <Th size={2.5}>
                                <div className="th_label" style={{ color: '#355d8b' }}>Session status</div>
                            </Th>
                            <Th size={2.5}>
                                <div className="th_label" style={{ color: '#355d8b' }}>Ticket Raised</div>
                            </Th>
                            <Th size={2.5}>
                                <div className="th_label" style={{ color: '#355d8b' }}>User details</div>
                            </Th>
                            <Th size={2}>
                                    {/* <div className="th_label">Chat channel</div> */}
                                    <div className="tab_container_right detailed_session">
                                        <p className={'logs_pagination'}>
                                            {currentPage === 1 ? 1 : (currentPage - 1) * 50 + 1} -{' '}
                                            {currentLog.totalCount > currentPage * 50
                                                ? currentPage * 50
                                                : currentLog.totalCount}{' '}
                                            of {currentLog.totalCount}
                                            <span
                                                className={
                                                    currentPage !== 1 ? 'prev_icon' : 'prev_icon editor_btn_disabled'
                                                }
                                                onClick={() =>
                                                    setCurrentPage &&
                                                    setCurrentPage(currentPage !== 0 ? currentPage - 1 : 0)
                                                }
                                            >
                                                <DropdownWithCircle />
                                            </span>
                                            <span
                                                className={
                                                    currentLog.totalCount > currentPage * 50
                                                        ? 'next_icon'
                                                        : 'next_icon editor_btn_disabled'
                                                }
                                                onClick={() => {
                                                    setCurrentPage && setCurrentPage(currentPage + 1)
                                                }}
                                            >
                                                <DropdownWithCircle />
                                            </span>
                                        </p>
                                            <ContentEllipse pointer>
                                                <div className='icon_left' onClick={()=>{
                                                    closePopup()
                                                }}>
                                                   <CloseIconWithCircle/>
                                                </div>
                                            </ContentEllipse>
                                        
                                    </div>
                            </Th>
                        </Tr>
                    </Thead>
                    {loading ||
                    error ||
                    conversations.length == 0 ? (
                        <Tbody
                            style={{
                                height: 140,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            {loading ? (
                                <Loader.PopupLoader show={true} className={'medium_loader'} />
                            ) : error ? (
                                <div className="no_data_available">
                                    <p>Something went wrong! Please try again after some time.</p>
                                </div>
                            ) : (
                                <div className="no_data_available">
                                    <p>No data available</p>
                                </div>
                            )}
                        </Tbody>
                    ) : (
                        <Tbody>
                            {conversations.map((execution: ConversationT, i: number) => (
                                    <div className={'is_opacity_wrapper'}>
                                        <Tr className={disable ? 'is_opacity' : ''}>
                                            <Td size={2.5}>
                                                <ContentEllipse>
                                                    <TdContent className="td_header">
                                                        <SmallButton primary onClick={() => onView(execution.conversation_id,execution.root_req_id)}>
                                                            <label>View Session</label>{' '}
                                                        </SmallButton>
                                                    </TdContent>
                                                </ContentEllipse>
                                                <div className="td_date">
                                                    {formatDateInCard(execution.query_created_at)} |{' '}
                                                    {formatAMPM(execution.query_created_at)}
                                                </div>
                                            </Td>
                                            <Td size={2.5}>
                                                { user && bots.find((b: any) => b.id == user.bot_id) ? (
                                                    <a
                                                        className="diloag_td_content diloag_td_content_link"
                                                        href={`/workspace/${props.workspacename}/dialog/${
                                                            user && bots.find((b: any) => b.id == user.bot_id).name
                                                        }/detail/${execution.dialog_id}`}
                                                        target="_blank"
                                                    >
                                                        {execution.dialog_name}
                                                    </a>
                                                ) : (
                                                    <div className="diloag_td_content">{execution.dialog_name}</div>
                                                )}
                                            </Td>
                                            <Td size={2.5}>
                                                {execution.status == 'escalated' ? (
                                                    <ContentEllipse>
                                                        <div className="icon_left">
                                                            <UserIconAnalytics />
                                                        </div>
                                                        <label>{execution.status}</label>
                                                        <span
                                                            style={{
                                                                width: 18,
                                                                height: 18,
                                                                marginLeft: 5
                                                            }}
                                                        >
                                                            <TeamsIcon />
                                                        </span>{' '}
                                                    </ContentEllipse>
                                                ) : (
                                                    <ContentEllipse>
                                                        <div className='icon_left'>
                                                            {returnIcon(execution.status)}
                                                        </div>
                                                        <label>{execution.status}</label>
                                                    </ContentEllipse>
                                                )}
                                            </Td>
                                            <Td size={2.5}>
                                                <div className="diloag_td_content">
                                                    {execution.ticket_created ? "Yes": "No"}</div>
                                            </Td>
                                            <Td
                                                size={4.5}
                                                // onClick={() => {
                                                //     shoUserDetails(i)
                                                // }}
                                                onMouseEnter={() => {
                                                    shoUserDetails(i)
                                                }}
                                                onMouseLeave={(e: any) => {
                                                    e.preventDefault()
                                                    shoUserDetails(null)
                                                }}
                                                className={userDetails == i ? "hovered_row" : ""}

                                            >
                                                <TdContent className="td_email">
                                                    Email:{' '}
                                                    <span className="truncate">
                                                        {' '}
                                                        {user && user.user_email ? user.user_email : '-'}
                                                    </span>
                                                </TdContent>
                                                <div className="td_id">
                                                    Unique ID: <span>{user ? user.user_id:'-'}</span>
                                                </div>
                                                {userDetails == i && user && userDetails > 2 && (
                                                    <Downshift isOpen onOuterClick={() => shoUserDetails(null)}>
                                                        {() => (
                                                            <span className="user_details_inner_popup_multiple">
                                                                <TrainEntityPopup calssName="user_details_hover" user={user} />
                                                            </span>
                                                        )}
                                                    </Downshift>
                                                )}
                                            </Td>
                                        </Tr>
                                    </div>
                                ))}
                        </Tbody>
                    )}
                </Table>
                {userDetails !=null&& userDetails < 3 && (
                    <Downshift isOpen onOuterClick={() => shoUserDetails(null)}>
                        {() => (
                            <span className="user_details_inner_popup_single">
                                <TrainEntityPopup calssName="user_details_hover" user={user as any} />
                            </span>
                        )}
                    </Downshift>
                )}
            </TableContainer>
        </TableWrapper>
    )
}

export const TableEmailContainer = (props: {
    conversations: EmailConversationT[]
    setDetailsPopup: any
    platform: string
    workspacename: string
    callAPILogs: (email: string, index: number, page: number,session:boolean) => void
    detailsPopup: {
        show: boolean
        loading: boolean
        data: any[]
        currentLog: any
        error: string
        index: null | number
        currentPage: number
    }
    bots: any[]
    onView: (conversation_id: string) => void
    filterByEmail: string
}) => {
    const {
        conversations,
        callAPILogs,
        detailsPopup,
        platform,
        bots,
        onView,
        workspacename,
        setDetailsPopup,
        filterByEmail
    } = props

    console.log(detailsPopup, 'detailsPopup')
    return (
        <TableWrapper>
            <TableContainer>
                <Table>
                    <Thead>
                        <Tr>
                            <Th size={4}>
                                <div className="th_label">User email</div>
                            </Th>
                            <Th size={3.5}>
                                <div className="th_label">Session status</div>
                            </Th>
                            <Th size={2.5}>
                                <div className="th_label">Feedback</div>
                            </Th>
                            <Th size={2}>
                                <div className="th_label">Chat channel</div>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {conversations.filter((c: any) => {
                            return (
                                (filterByEmail == 'all' || c._id.indexOf(filterByEmail) != -1) &&
                                (props.platform.toLocaleLowerCase() == 'all' ||
                                    c.chatChannel.find((chat: string) => chat == props.platform.toLocaleLowerCase()))
                            )
                        }).length > 0 ? (
                            conversations
                                .filter(
                                    (c: any) =>
                                        (filterByEmail == 'all' || c._id.indexOf(filterByEmail) != -1) &&
                                        (props.platform.toLocaleLowerCase() == 'all' ||
                                            c.chatChannel.find(
                                                (chat: string) => chat == props.platform.toLocaleLowerCase()
                                            ))
                                )
                                // .slice(currentPage === 1 ? 0 : (currentPage - 1) * 50, currentPage * 50)
                                .map((execution: any, i: number) => (
                                    <div className="is_opacity_wrapper">
                                        <Tr
                                            className={detailsPopup.show ? 'is_opacity' : ''}
                                            onClick={() => {
                                                execution.totalCount > 1 &&
                                                    callAPILogs(execution, i, detailsPopup.currentPage,false)
                                            }}
                                        >
                                            <Td size={4} style={{ paddingTop: 15, justifyContent: 'left' }} className="flex-row">
                                                <div className="user_email_left">
                                                    <ContentEllipse>
                                                        <TdContent className="td_header">
                                                            {execution._id[0] ? execution._id[0] : 'anonymous user'}
                                                        </TdContent>
                                                    </ContentEllipse>
                                                    <div className="td_date">
                                                        {formatDateInCard(execution.endTime)} |{' '}
                                                        {formatAMPM(execution.endTime)}
                                                    </div>
                                                </div>
                                                
                                                    <div className="user_email_right">
                                                    {execution.totalCount > 1 && (
                                                        <LabelImage
                                                            pointer
                                                            onClick={() => {
                                                                callAPILogs(execution, i, detailsPopup.currentPage,false)
                                                            }}
                                                        >
                                                            <NextIconCanvas />
                                                        </LabelImage>
                                                        )}
                                                        <CountNumber>
                                                            <label>{execution.totalCount}</label>
                                                        </CountNumber>
                                                    </div>
                                            </Td>
                                            <Td size={3.5}>
                                                {' '}
                                                <ContentEllipse className="align-items-center">
                                                    <div className="session_status">
                                                        <div className="icon_left">
                                                            <AutoResolvedIcon />
                                                        </div>
                                                        <CountNumber sessionBg>
                                                            <label>{execution.resolvedCount}</label>
                                                        </CountNumber>
                                                    </div>
                                                    <div className="session_status">
                                                        <div className="icon_left">
                                                            <UserIconAnalytics />
                                                        </div>
                                                        <CountNumber sessionBg>
                                                            <label>{execution.escalatedCount}</label>
                                                        </CountNumber>
                                                    </div>
                                                    <div className="session_status">
                                                        <div className="icon_left">
                                                            <AbandonedIcon />
                                                        </div>
                                                        <CountNumber sessionBg>
                                                            <label>{execution.abandonedCount}</label>
                                                        </CountNumber>
                                                    </div>
                                                </ContentEllipse>
                                            </Td>

                                            <Td contentCenter size={2.5}>
                                                <ContentEllipse>
                                                    <div className="session_status">
                                                        <div className="icon_left_24">
                                                            <NewThumbsUpIcon />
                                                        </div>
                                                        <CountNumber successBg>
                                                            <label>{execution.satisfiedCount}</label>
                                                        </CountNumber>
                                                    </div>
                                                    <div className="session_status">
                                                        <div className="icon_left_24">
                                                            <NewThumbsDownIcon />
                                                        </div>
                                                        <CountNumber failedBg>
                                                            <label>{execution.notSatisfiedCount}</label>
                                                        </CountNumber>
                                                    </div>
                                                </ContentEllipse>
                                            </Td>
                                            <Td size={2}>
                                                {' '}
                                                <ContentEllipse className="cc_td_icons">
                                                    {console.log(execution.chatChannel)}
                                                    {execution.chatChannel.map((chat: string) =>
                                                        chat == 'slack' ? (
                                                            <div className="icon_left_24">
                                                                <SlackIconCC />
                                                            </div>
                                                        ) : chat == 'teams' ? (
                                                            <div className="icon_left_24">
                                                                <TeamsIcon />
                                                            </div>
                                                        ) : chat == 'widget' ? (
                                                            <div className="icon_left_24">
                                                                <WidgetIcon />
                                                            </div>
                                                        ) : (
                                                            <div className="icon_left_24">
                                                                <TryMeanalyticsIcon />
                                                            </div>
                                                        )
                                                    )}
                                                </ContentEllipse>
                                            </Td>
                                        </Tr>
                                        {detailsPopup.show && detailsPopup.index == i && (
                                            <Downshift
                                                isOpen
                                                // onOuterClick={() =>
                                                //     setDetailsPopup({
                                                //         ...detailsPopup,
                                                //         show: false,
                                                //         error: '',
                                                //         currentLog: null,
                                                //         index: null,
                                                //         currentPage: 1
                                                //     })
                                                // }
                                            >
                                                {() => (
                                                    <div>
                                                        <OpenSubTable>
                                                            <TableUiContainerForEmail
                                                                workspacename={workspacename}
                                                                platform={platform}
                                                                conversations={detailsPopup.data}
                                                                bots={bots}
                                                                onView={(conversation_id: string) => onView(conversation_id)}
                                                                from="detailsPopup"
                                                                currentLog={detailsPopup.currentLog}
                                                                error={detailsPopup.error.length > 0}
                                                                loading={detailsPopup.loading}
                                                                currentPage={detailsPopup.currentPage}
                                                                setCurrentPage={(page: number) => {
                                                                    callAPILogs(
                                                                        detailsPopup.currentLog,
                                                                        detailsPopup.index ? detailsPopup.index : 0,
                                                                        page,
                                                                        false
                                                                    )
                                                                }}
                                                                closePopup={()=>{
                                                                    setDetailsPopup({
                                                                        ...detailsPopup,
                                                                        show: false,
                                                                        error: '',
                                                                        currentLog: null,
                                                                        index: null,
                                                                        currentPage: 1
                                                                    })
                                                                }}
                                                            />
                                                        </OpenSubTable>
                                                    </div>
                                                )}
                                            </Downshift>
                                        )}
                                    </div>
                                ))
                        ) : (
                            <div className="no_data_available">
                                <p>No data available</p>
                            </div>
                        )}
                    </Tbody>
                </Table>
            </TableContainer>
        </TableWrapper>
    )
}

export const TableUiContainerForAgent = (props: {
    sessions: AgentSessionsTypeT[]
    onView: (execution: AgentSessionsTypeT, agentOrBot: 'agent' | 'bot') => void
    workspacename: string
    bots: any[]
    currentPage?: number
    setCurrentPage?: Function
    currentLog?: any
    error?: boolean
    loading?: boolean
    disable?: boolean
}) => {
    const { onView, bots, currentLog, error, loading, disable, sessions, currentPage, setCurrentPage } = props

    return (
        <TableWrapper>
            <TableContainer>
                <Table>
                    <Thead>
                        <Tr>
                            <Th size={2.7}>
                                <div className="th_label">Agent Email</div>
                            </Th>
                            <Th size={2.7}>
                                <div className="th_label">User Email</div>
                            </Th>
                            <Th size={1.7}>
                                <div className="th_label">Agent Session</div>
                            </Th>
                            <Th size={1.7}>
                                <div className="th_label">Bot Session</div>
                            </Th>
                            <Th size={2}>
                                <div className="th_label">Date and Time</div>
                            </Th>
                            <Th size={1.2}>
                                <div className="th_label">Feedback</div>
                            </Th>
                        </Tr>
                    </Thead>
                    {loading || error || sessions.length == 0 ? (
                        <Tbody
                            style={{
                                height: 140,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            {loading ? (
                                <Loader.PopupLoader show={true} className={'medium_loader'} />
                            ) : error ? (
                                <div className="no_data_available">
                                    <p>Something went wrong! Please try again after some time.</p>
                                </div>
                            ) : (
                                <div className="no_data_available">
                                    <p>No data available</p>
                                </div>
                            )}
                        </Tbody>
                    ) : (
                        <Tbody>
                            {sessions.map((execution: AgentSessionsTypeT, i: number) => (
                                <div className={'is_opacity_wrapper'}>
                                    <Tr className={disable ? 'is_opacity' : ''}>
                                        <Td size={2.7} className={'cursor_default'}>
                                            <div className="td_email">{execution.agent_email}</div>
                                        </Td>
                                        <Td size={2.7} className={'cursor_default'}>
                                            {execution.user_email ? (
                                                <div className="td_email">{execution.user_email}</div>
                                            ) : (
                                                <ContentEllipse>-</ContentEllipse>
                                            )}
                                        </Td>
                                        <Td size={1.7}>
                                            <ContentEllipse>
                                                <TdContent className="td_header">
                                                    <SmallButton primary onClick={() => onView(execution, 'agent')}>
                                                        <label style={{top: 0}}>View Session</label>{' '}
                                                    </SmallButton>
                                                </TdContent>
                                            </ContentEllipse>
                                        </Td>
                                        <Td contentCenter size={1.7}>
                                            <ContentEllipse>
                                                <TdContent className="td_header">
                                                    <SmallButton primary onClick={() => onView(execution, 'bot')}>
                                                        <label style={{top: 0}}>View Session</label>{' '}
                                                    </SmallButton>
                                                </TdContent>
                                            </ContentEllipse>
                                        </Td>
                                        <Td size={2} className={'cursor_default'}>
                                            {execution.created_at != null ? (
                                                <div className="td_date_wrapper">
                                                    {formatDateInCard(execution.created_at)} |{' '}
                                                    {formatAMPM(execution.created_at)}
                                                </div>
                                            ) : (
                                                <div className="td_date_wrapper" style={{fontSize: 9}}>-</div>
                                            )}
                                        </Td>
                                        <Td size={1.2} className={'cursor_default'}>
                                                {(execution.feedback &&
                                                    (execution.feedback.toLowerCase() == 'satisfied' ||
                                                    execution.feedback.toLowerCase() == 'not satisfied'|| execution.feedback.toLowerCase() == 'not_satisfied')) ? (
                                                    execution.feedback.toLowerCase() == 'satisfied' ? (
                                                        <ContentEllipse contentCenter>
                                                            <div className="icon_left_24">
                                                                <NewThumbsUpIcon />
                                                            </div>
                                                        </ContentEllipse>
                                                    ) : (
                                                        <ContentEllipse contentCenter>
                                                            <div className="icon_left_24">
                                                                <NewThumbsDownIcon />
                                                            </div>
                                                        </ContentEllipse>
                                                    )
                                                    ) : execution.feedback ?(
                                                        <ContentEllipse contentCenter>{execution.feedback}</ContentEllipse>
                                                    ): (
                                                        <ContentEllipse contentCenter>-</ContentEllipse>
                                            )}
                                        </Td>
                                    </Tr>
                                </div>
                            ))}
                        </Tbody>
                    )}
                </Table>
            </TableContainer>
        </TableWrapper>
    )
}

const TrainEntityPopup = (props: { user: {
    _id: string;
    bot_id: null | string;
    user_id: string;
    __v: number;
    user_email: string;
    user_firstname: string;
    user_lastname: string;
} , calssName?: string}) => {
    const { user } = props
    return user ? (
        <EntityWrapper className={props.calssName}>
            <TrainHeader>
                <span>
                    <UserIconAnalytics />
                </span>
                <label>User details</label>
            </TrainHeader>
            <UserWrapper>
                <p>
                    Unique ID: <span>{user.user_id}</span>{' '}
                </p>
                <p>
                    User name:{' '}
                    <span>
                        {user.user_firstname != ''
                            ? `${user.user_firstname} ${user.user_lastname}`
                            : '-'}
                    </span>
                </p>
                <p>
                    Email: <span>{user.user_email ? user.user_email : '-'}</span>
                </p>
            </UserWrapper>
        </EntityWrapper>
    ) : null
}
