/*eslint-disable*/
import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { sortAnArrayAlphabetically } from '@/Flows/canvas/utils'
import { VideoButtonB } from '@/Videos/Videos'
import {
    AddIcon,
    CloseIcon,
    DeleteIcon,
    EditIcon,
    ErrorIcon,
    GroupIcon,
    ListviewIcon,
    SearchIcon
} from '@/common/Icons/Icons'
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons'
import { Card, NewCard } from '@/common/components/Card'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import Validate, { ErrorContext } from '@/common/components/FormValidate'
import { Header, HeaderChild } from '@/common/components/Header'
import InputBox from '@/common/components/InputBox'
import { List } from '@/common/components/List'
import { Loader } from '@/common/components/Loader'
import { Modal } from '@/common/components/Modal'
import { ListSelectBox } from '@/common/components/SelectBox'
import lazyGA from '@/common/google-analytics/tracking'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import { ButtonWrapper, Paragraph } from '@/common/styled/Dialogue.Dumb'
import {
    ErrorMessage,
    Input,
    InputContainer,
    InputContainerForTextArea,
    InputContent,
    InputErrorIcon,
    InputWrapper,
    Table,
    TableContainer,
    Tbody,
    Td,
    TextareaV2,
    Th,
    Thead,
    Tr
} from '@/common/styled/Workflow.Analytics.Dumb'
import { entityValidation, inputTextAreaValidations, newLine } from '@/common/utils/_validation-utils'
import { sortAnArrayByDate, sortAnArrayByName } from '@/common/utils/common.utils'

import { formatAMPM, formatDateInCard } from '@/common/utils/utils'
import { AssistantT } from '../Construction/kind'
import { createEntity, deleteEntity, getAssistants, getEntities, updateEntity } from '../Mechanisms/action'
import { ActionT, GridView, ListView, PageViewsT, ROUTER_PROPS_T, ViewTypeT } from '../kind'
import { EDIT_T, EntityT, LOADING, PageStateT, VIEW, defaultEntity } from './kind'
import Tooltip from 'react-tooltip-lite'
import { logEvent } from '@/common/utils/api-utils'
import { usePermissionSignal } from '@/common/utils/auth-token-api-utils'
import { useSignal } from '@preact/signals-react'

const Wrapper = styled.div`
    width: 100%;
    float: left;
    .list_flow_span span {
        font-size: 9px;
    }
`

const TabelWrapper = styled.div`
    width: 100%;
    float: left;
    .list_flow_hr label {
        float: left;
        display: flex;
        position: absolute;
        top: 18px;
        width: 16px;
        height: 16px;
        margin-bottom: 0px;
        left: -22px;
    }
    .option-input.radio {
        top: 6px;
        position: absolute;
        margin-right: 6px;
    }

    ${Table} {
        margin-top: 0px;
    }
    ${Thead} {
        ${Tr} {
            padding-left: 32px;
            padding-right: 20px;

            ${Th} {
                :last-child {
                    justify-content: flex-end;
                }
            }
        }
    }
    ${Tbody} {
        border-radius: 0px 0px 10px 10px;
        max-height: calc(100vh - 84px);
        height: auto;
        ${Tr} {
            height: 48px;
            padding-left: 32px;
            padding-right: 20px;
            .list_flow_span .entity_span {
                :first-child {
                    margin-right: 3px;
                    color: #000;
                    font-size: 11px;
                }
                :last-child {
                    margin-left: 3px;
                    color: #000;
                    font-size: 11px;
                }
            }
            :hover {
                ${Td}:last-child {
                    display: flex;
                }
            }

            ${Td} {
                padding: 0px;
                height: 48px;
                font-size: 12px;
                font-family: 'Gordita-Regular';
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                color: #000;
                span {
                    margin-right: 0px;
                }

                .list_flow_span_example span {
                    font-size: 12px;
                    font-family: 'Gordita-Regular';
                    color: #000;

                    span {
                        color: #f07f31;
                    }
                }
                :nth-child(1) {
                    display: flex;
                    padding-right: 20px;
                    cursor: pointer;
                    position: relative;
                }

                :nth-child(2) {
                    display: flex;
                    padding-right: 20px;
                }
                :nth-child(3) {
                    display: flex;
                    padding-right: 20px;
                }
                :last-child {
                    display: none;
                }
            }
        }
    }
`

let Parser = require('html-react-parser')

const AssistantEntity = (props: ROUTER_PROPS_T) => {
    const { workspacename, assistantname } = props.match.params

    const [PageState, setPageState] = useState<PageStateT>(LOADING)

    const [assistant, setAssistant] = useState<string>('')

    const [viewType, setviewType] = useState<PageViewsT>(ListView)

    const [inputFocus, setInputFocus] = useState<boolean>(false)

    const [searching, setSearching] = useState<boolean>(false)

    const [searchWord, setSearchWord] = useState<string>('')

    const [entities, setEntities] = useState<EntityT[]>([])

    const [modelError, setModelError] = useState({ error: null, info: null })

    const [modelLoader, setModelLoader] = useState<boolean>(false)
    const [currentEntity, setCurrentEntity] = useState<EntityT>(defaultEntity)

    const moduleName$ = useSignal<string>("")

    const entityWritePermission = usePermissionSignal(workspacename,moduleName$,"WRITE")

    const entityDeletePermission = usePermissionSignal(workspacename,moduleName$,"DELETE")
    console.log(entityWritePermission.value,entityDeletePermission.value,moduleName$,"entity")

    const retry = () => {
        setPageState(LOADING)
        getAssistants(workspacename)
            .then((res: AssistantT[]) => {
                const assistant = res.reduce((accum: string, val: AssistantT) => {
                    if (val.name == assistantname) return val.id
                    else return accum
                }, '')
                if (assistant === '') {
                    setPageState({
                        action: ActionT.RESOURCE_NOT_FOUND,
                        resource: 'WORKSPACE'
                    })
                } else {
                    moduleName$.value = `CHAT_BOT_MODULE/BOT/${assistant}/ENTITY`

                    getEntities(workspacename, assistant).then(data => {
                        setPageState(VIEW)

                        setAssistant(assistant)
                        setEntities(sortAnArrayAlphabetically(data, 'entity'))
                    })

                }
            })
            .catch(error => {
                if (error.response && error.response.status == 403) {
                    setPageState({
                        action: ActionT.UN_AUTHORIZED
                    })
                } else {
                    setPageState({
                        action: ActionT.ERROR,
                        reason: 'There was a problem in loading Page! <br/> Please click on "Retry"'
                    })
                }
            })
    }

    useEffect(() => {
        retry()
    }, [])

    const search = (name: string) => {
        setSearching(name.length > 0)
        setSearchWord(name)
    }

    const closeSearch = () => {
        setSearching(false)
        setSearchWord('')
    }

    const deleteConformation = () => {
        switch (PageState.action) {
            case ActionT.SELECT:
                setPageState(state => ({
                    action: ActionT.MULTIPLE_DELETE,
                    items: state.action === ActionT.SELECT ? state.items : []
                }))
        }
    }

    const changeView = () => {
        if (viewType.View === ViewTypeT.GridView) {
            setviewType(ListView)
        } else {
            setviewType(GridView)
        }
    }

    const nameHandleChage = (name: string) => {
        if (PageState.action === ActionT.ADD || PageState.action === ActionT.EDIT) {
            setPageState(state => ({ ...state, entity: name }))
        }
    }

    const infoHandleChage = (info: string) => {
        if (PageState.action === ActionT.ADD || PageState.action === ActionT.EDIT) {
            setPageState(state => ({ ...state, description: info }))
        }
    }

    const Save = () => {
        if (PageState.action == ActionT.ADD) {
            createEntity(workspacename, assistant, {
                entity: PageState.entity,
                description: PageState.description,
                fuzzy_match: true
            })
                .then(data => {
                    // setPageState(VIEW)
                    // setEntities(x => x.concat([data]))
                    logEvent(workspacename, {
                        event:"EVENT::ENTITY::CREATE_WITH_VALUES", event_meta:{
                            name: PageState.entity,
                            description: PageState.description,            
                        }
                    })
                    props.history.push(`/workspace/${workspacename}/dialog/${assistantname}/entity/${PageState.entity}`)
                    lazyGA().event({
                        category: 'Entity',
                        action: 'Create Entity'
                    })
                })
                .catch(() => {
                    console.log('::::')
                })
            // this.props.history.push(`/workspace/${workspacename}/dialog/entity/${data.data.entity}`)
            // } catch (err) {
            // this.setState({
            //     showPopupLoader: false,
            //     error: {
            //         error: true,
            //         info: `Server error!<br />Please try again after sometime.`
            //     }
            // })
            // }
        }
    }

    const editEntity = () => {
        if (PageState.action == ActionT.EDIT) {
            updateEntity(workspacename, assistant, PageState.id, {
                entity: PageState.entity,
                description: PageState.description,
                fuzzy_match: true
            })
                .then(resp => {
                    setEntities((data: EntityT[]) =>
                        sortAnArrayAlphabetically(
                            data.map((entity: EntityT) =>
                                entity.id === PageState.id ? { ...resp, values: entity.values } : entity
                            ),
                            'entity'
                        )
                    )
                    setPageState(VIEW)
                })
                .catch(() => {
                    console.log('::::::: editEntity ERR')
                })
        }
    }

    const isSelected = (id: string) => {
        if (PageState.action === ActionT.SELECT || PageState.action === ActionT.MULTIPLE_DELETE) {
            return PageState.items.filter((entity: string) => entity === id).length > 0
        }
        return false
    }

    const selectEntity = (entity: string) => {
        switch (PageState.action) {
            case ActionT.SELECT:
                const items: string[] =
                    PageState.items.filter((id: string) => id === entity).length > 0
                        ? PageState.items.filter((x: string) => x !== entity)
                        : [...PageState.items, entity]

                if (items.length) {
                    setPageState({
                        action: ActionT.SELECT,
                        items
                    })
                } else {
                    setPageState(VIEW)
                }
                break
            default:
                setPageState({ action: ActionT.SELECT, items: [entity] })
                break
        }
    }

    const removeEntity = () => {
        switch (PageState.action) {
            case ActionT.MULTIPLE_DELETE:
                Promise.all(
                    PageState.items
                        .reduce((accum: any[], val: string) => {
                            const index = entities.findIndex(e => e.id === val)
                            if (index === -1) return accum
                            return accum.concat([
                                {
                                    id: val,
                                    entity: entities[index].entity
                                }
                            ])
                        }, [])
                        .map(entity => deleteEntity(workspacename, assistant, entity.id, entity.entity))
                )
                    .then((_: {}) => {
                        const list = entities.reduce((accum: EntityT[], value: EntityT): EntityT[] => {
                            if (
                                PageState.action === ActionT.MULTIPLE_DELETE &&
                                PageState.items.findIndex((data: string) => data === value.id) === -1
                            ) {
                                return accum.concat(value)
                            } else return accum
                        }, [])
                        setPageState(VIEW)
                        setEntities(list)
                    })
                    .catch(() => {
                        console.log('Server error!<br />Please try again after sometime.')
                    })
                break
            case ActionT.DELETE:
                deleteEntity(workspacename, assistant, PageState.data.id, PageState.data.entity)
                    .then((_: {}) => {
                        const list = entities.filter((data: EntityT) => data.id !== PageState.data.id)
                        setPageState(VIEW)
                        setEntities(list)
                    })
                    .catch(() => {
                        console.log('Server error!<br />Please try again after sometime.')
                    })
                break
        }
    }

    const editModeDisable = () => {
        if (PageState.action != ActionT.EDIT) {
            return true
        } else {
            const state = PageState as EDIT_T
            return entities.filter((e, i) => e.entity == state.entity && e.description == state.description).length > 0
        }
    }

    const renderConfirmModal = () => {
        if (PageState.action === ActionT.ADD || PageState.action == ActionT.EDIT) {
            const duplicate =
                entities.filter(e => e.entity != currentEntity.entity && e.entity == PageState.entity).length > 0
            return (
                <Modal error={modelError} showPopupLoader={modelLoader}>
                    <ErrorBoundary
                        error={modelError}
                        render={(err: any, info: any) => {
                            return (
                                err && (
                                    <Error.AddorEdit
                                        onClick={() => setModelError({ error: null, info: null })}
                                        info={info}
                                    />
                                )
                            )
                        }}
                    >
                        <Validate
                            defaultValue={
                                PageState.action === ActionT.ADD ? { entityName: false } : { entityName: true }
                            }
                        >
                            {PageState.action === ActionT.ADD ? <h2>Create Entity </h2> : <h2>Update Entity</h2>}
                            <Paragraph margin>Enter name and description </Paragraph>{' '}
                            <div>
                                <InputBox
                                    validationList={entityValidation}
                                    value={PageState.entity}
                                    name="entityName"
                                    render={(
                                        value: string,
                                        valid: boolean,
                                        errorMsg: string,
                                        startValidation: () => void,
                                        handleInput: Function
                                    ) => {
                                        return (
                                            <React.Fragment>
                                                <InputWrapper>
                                                    <ErrorMessage>{!valid ? errorMsg : newLine}</ErrorMessage>
                                                    <InputContainer>
                                                        <Input
                                                            primary={!valid ? `primary` : ''}
                                                            type="text"
                                                            // ref={node => (this.inputRef = node)}
                                                            onChange={(event: any) => {
                                                                startValidation(),
                                                                    handleInput('entityName', event.target.value)
                                                                nameHandleChage(event.target.value)
                                                            }}
                                                            value={value}
                                                        />
                                                        <InputContent>Entity name</InputContent>
                                                        {!valid && (
                                                            <InputErrorIcon>
                                                                <ErrorIcon />
                                                            </InputErrorIcon>
                                                        )}
                                                    </InputContainer>
                                                </InputWrapper>
                                                {duplicate ? (
                                                    <div className="error_input_show intent">
                                                        <WarningAlertIcon />
                                                        <span className="error_menu_hvr">
                                                            Duplicate entity cannot be saved. Modify the entity name.
                                                        </span>
                                                    </div>
                                                ) : null}
                                            </React.Fragment>
                                        )
                                    }}
                                />

                                <InputBox
                                    validationList={inputTextAreaValidations}
                                    value={PageState.description}
                                    name={'entityDescription'}
                                    render={(
                                        value: string,
                                        valid: boolean,
                                        errorMsg: string,
                                        startValidation: () => void,
                                        handleInput: Function
                                    ) => {
                                        return (
                                            <React.Fragment>
                                                <InputWrapper>
                                                    <ErrorMessage>{!valid ? errorMsg : newLine}</ErrorMessage>
                                                    <InputContainerForTextArea style={{marginTop: 10}}>
                                                        <TextareaV2
                                                            onChange={(event: any) => {
                                                                startValidation()
                                                                handleInput('entityDescription', event.target.value)
                                                                infoHandleChage(event.target.value)
                                                            }}
                                                            value={value}
                                                            // onKeyDown={e => {
                                                            //     let keyCode = e.keyCode || e.which
                                                            //     this.setState({
                                                            //         keycode: keyCode
                                                            //     })
                                                            // }}
                                                        />
                                                        <InputContent>Entity description</InputContent>
                                                        {!valid && (
                                                            <InputErrorIcon>
                                                                <ErrorIcon />
                                                            </InputErrorIcon>
                                                        )}
                                                        {/* <p className="error_msg">{!valid ? errorMsg : newLine}</p> */}
                                                    </InputContainerForTextArea>
                                                </InputWrapper>
                                            </React.Fragment>
                                        )
                                    }}
                                />
                                <ErrorContext.Consumer>
                                    {valid => {
                                        return (
                                            <React.Fragment>
                                                <ButtonWrapper>
                                                    <Button primary type="button" onClick={() => setPageState(VIEW)}>
                                                        <label>Cancel</label>
                                                    </Button>
                                                    {PageState.action == ActionT.ADD ? (
                                                        <Button
                                                            type="button"
                                                            onClick={Save}
                                                            disabled={!valid.formValid.formValid || duplicate}
                                                            className={
                                                                !valid.formValid.formValid || duplicate
                                                                    ? 'editor_btn_disabled'
                                                                    : ''
                                                            }
                                                        >
                                                            <label>Add</label>
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            type="button"
                                                            onClick={() => editEntity()}
                                                            disabled={!valid.formValid.formValid || editModeDisable()}
                                                            className={
                                                                !valid.formValid.formValid ||
                                                                editModeDisable() ||
                                                                duplicate
                                                                    ? 'editor_btn_disabled'
                                                                    : ''
                                                            }
                                                        >
                                                            <label>Update</label>
                                                        </Button>
                                                    )}
                                                </ButtonWrapper>
                                            </React.Fragment>
                                        )
                                    }}
                                </ErrorContext.Consumer>
                            </div>
                        </Validate>
                    </ErrorBoundary>
                </Modal>
            )
        } else if (PageState.action == ActionT.DELETE || PageState.action == ActionT.MULTIPLE_DELETE) {
            return (
                <Modal error={modelError} showPopupLoader={modelLoader}>
                    <ErrorBoundary
                        error={modelError}
                        render={(err: any, info: any) => {
                            return (
                                err && (
                                    <Error.AddorEdit
                                        onClick={() => setModelError({ error: null, info: null })}
                                        info={info}
                                    />
                                )
                            )
                        }}
                    >
                        <React.Fragment>
                            <h2>Delete {PageState.action == ActionT.DELETE ? PageState.data.entity : 'Entities'}?</h2>
                            <Paragraph>
                                Are you sure you want to delete selected{' '}
                                {PageState.action == ActionT.DELETE ? 'Entity' : 'Entities'}? <br />
                                Dialog may not work properly if the Entity is mapped in a Dialog conversation.
                            </Paragraph>
                        </React.Fragment>

                        <div>
                            <ButtonWrapper>
                                <Button primary type="button" onClick={() => setPageState(VIEW)}>
                                    <label>No</label>
                                </Button>
                                <Button type="button" onClick={() => removeEntity()}>
                                    <label>Yes</label>
                                </Button>
                            </ButtonWrapper>
                        </div>
                    </ErrorBoundary>
                </Modal>
            )
        }
        return null
    }

    return PageState.action === ActionT.LOADING ? (
        <Loader.PageLoader show={true} />
    ) : (
        <>
            {renderConfirmModal()}
            <ErrorBoundary
                unauthorized={PageState.action === ActionT.UN_AUTHORIZED}
                noDataFound={{
                    show: PageState.action === ActionT.NO_DATA_FOUND,
                    noDatainfo: '<p>No entities to show! Create your first entity</p>',
                    onClick: () => {
                        setPageState(state => ({ ...state, showAddPopup: true }))
                    },
                    btnName: 'Create one'
                }}
                error={
                    PageState.action === ActionT.ERROR
                        ? { error: true, info: PageState.reason }
                        : { error: null, info: null }
                }
                render={(err: any, info: any) => {
                    return err && <Error.PageLoadingError onClick={retry} info={info} btnName={'Retry'} />
                }}
            >
                <Header>
                    <HeaderChild type="first">
                        <div className="indent_left">
                            <div className="indent_title">Entity</div>
                            <div className="indent_sub_title">Catalog ({entities ? entities.length : 0})</div>
                        </div>

                        <div className="indent_right">
                            {(PageState.action === ActionT.SELECT ||
                                (PageState.action === ActionT.MULTIPLE_DELETE && PageState.items.length > 0)) && (
                                <Button
                                    style={{ background: '#FFE5D3' }}
                                    className="btn btn-primary btn_large"
                                    onClick={() => setPageState(VIEW)}
                                >
                                    <label style={{ color: '#333' }}><b>
                                            {PageState.items.length > 9
                                                ? PageState.items.length
                                                : `0${PageState.items.length}`}
                                        </b>{' '}
                                        Entities selected{' '}
                                    </label>
                                    <span>
                                        <CloseIcon />
                                    </span>
                                </Button>
                            )}

                            {PageState.action !== ActionT.SELECT &&
                                PageState.action !== ActionT.MULTIPLE_DELETE &&
                                PageState.action !== ActionT.NO_DATA_FOUND &&
                                // !this.state.inputFocus && (
                                !inputFocus && entityWritePermission.value && (
                                    <Button
                                        primary
                                        className="btn btn-primary"
                                        onClick={() => {
                                            setPageState({
                                                action: ActionT.ADD,
                                                entity: '',
                                                description: ''
                                            })
                                        }}
                                    >
                                        <span className="header_button">
                                            <AddIcon />
                                        </span>
                                        <label>Create New</label>
                                    </Button>
                                )}
                        </div>
                    </HeaderChild>
                    <HeaderChild type="second">
                        {PageState.action === ActionT.SELECT && entityDeletePermission.value && (
                            <button className="btn btn-primary" onClick={deleteConformation}>
                                <DeleteIcon />
                                <span>Delete</span>
                            </button>
                        )}

                        {PageState.action === ActionT.VIEW ? (
                            viewType.View === ViewTypeT.GridView ? (
                                <button className="btn btn-primary" onClick={() => changeView()}>
                                    <ListviewIcon />
                                    <span>List View</span>
                                </button>
                            ) : (
                                <button className="btn btn-primary" onClick={() => changeView()}>
                                    <GroupIcon />
                                    <span>Card View</span>
                                </button>
                            )
                        ) : null}

                        {!inputFocus && PageState.action !== ActionT.NO_DATA_FOUND ? (
                            <span className="search_icon_svg">
                                <input
                                    type="search"
                                    placeholder="Search"
                                    value={searchWord}
                                    onFocus={() => setInputFocus(true)}
                                />
                                <SearchIcon />
                            </span>
                        ) : (
                            PageState.action !== ActionT.NO_DATA_FOUND && (
                                <span className="search_click">
                                    <input
                                        className="search_click"
                                        type="search"
                                        placeholder="Search"
                                        value={searchWord}
                                        autoFocus={true}
                                        onChange={e => search(e.target.value)}
                                    />
                                    <span
                                        onClick={() => {
                                            closeSearch()
                                            setInputFocus(false)
                                        }}
                                    >
                                        <CloseIcon />
                                    </span>
                                </span>
                            )
                        )}
                        <VideoButtonB id={'CREATE_ENTITY_WITH_VALUES_HOW_IT_WORKS'} />
                    </HeaderChild>
                </Header>
                <Wrapper>
                    <section className="entity_cards" style={{overflow: 'hidden'}}>
                        <div className="col-md-12">
                            <div className={viewType.View === ViewTypeT.GridView ? 'row card_grid_view' : 'row'}>
                                {viewType.View == ViewTypeT.GridView ? (
                                    searchWord === '' && (
                                        <NewCard
                                            onClick={() =>
                                                setPageState({
                                                    action: ActionT.ADD,
                                                    entity: '',
                                                    description: ''
                                                })
                                            }
                                            disabled={PageState.action == ActionT.SELECT}
                                        />
                                    )
                                ) : (
                                    <React.Fragment>
                                        <TabelWrapper>
                                            <TableContainer>
                                                <Table>
                                                    <Thead>
                                                        <Tr>
                                                            <Th size="3">Entity Name</Th>
                                                            <Th size="4">Description</Th>
                                                            <Th size="2">No. of values</Th>
                                                            <Th size="2">Date</Th>
                                                            <Th size="1">Options</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody style={{maxHeight: 'calc(100vh - 190px)'}}>
                                                        {sortAnArrayByName(entities, 'entity')
                                                            .filter((e: EntityT) =>
                                                                e.entity
                                                                    .toLowerCase()
                                                                    .includes(searchWord.toLowerCase())
                                                            )
                                                            .map((entity: EntityT, index) => {
                                                                let enName = entity.entity

                                                                let reg = new RegExp(searchWord, 'gi')
                                                                const replaced = enName.replace(reg, function(str) {
                                                                    return (
                                                                        '<div class="highlighted-text d-inline">' +
                                                                        str +
                                                                        '</div>'
                                                                    )
                                                                })

                                                                let label =
                                                                    searchWord != ''
                                                                        ? replaced
                                                                        : `<h5 style='padding-top:${
                                                                              PageState.action == ActionT.SELECT
                                                                                  ? 18
                                                                                  : 0
                                                                          }px'>` +
                                                                          enName +
                                                                          '</h5>'
                                                                return (
                                                                    <Tr>
                                                                        <List
                                                                            key={index}
                                                                            showCheckBox={
                                                                                !enName.includes('workativ-') && true
                                                                            }
                                                                            checkBoxShow={
                                                                                PageState.action === ActionT.SELECT ||
                                                                                (PageState.action ===
                                                                                    ActionT.MULTIPLE_DELETE &&
                                                                                    PageState.items.length > 0)
                                                                            }
                                                                            render={(show: boolean, ToolBox: any) => {
                                                                                return (
                                                                                    <>
                                                                                        <Td
                                                                                            size={3}
                                                                                            className={'list_flow_hr'}
                                                                                        >
                                                                                            <ListSelectBox
                                                                                                show={show}
                                                                                                isSelected={isSelected(
                                                                                                    entity.id
                                                                                                )}
                                                                                                onClick={e =>
                                                                                                    selectEntity(
                                                                                                        entity.id
                                                                                                    )
                                                                                                }
                                                                                                value={entity.entity}
                                                                                            />
                                                                                            <div
                                                                                                className="search_card_list"
                                                                                                onClick={() =>
                                                                                                    props.history.push(
                                                                                                        `/workspace/${workspacename}/dialog/${assistantname}/entity/${entity.entity}`
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                {Parser(label)}
                                                                                            </div>
                                                                                        </Td>
                                                                                        <Td
                                                                                            size={4}
                                                                                            className="list_flow_p"
                                                                                            onClick={() =>
                                                                                                props.history.push(
                                                                                                    `/workspace/${workspacename}/dialog/${assistantname}/entity/${entity.entity}`
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <p className="listite_centre">
                                                                                                {entity.is_system_entity ===
                                                                                                false
                                                                                                    ? entity.description
                                                                                                    : JSON.parse(
                                                                                                          entity.description
                                                                                                      )
                                                                                                          .original_description}
                                                                                            </p>
                                                                                        </Td>
                                                                                        <Td size="2">
                                                                                            Values
                                                                                            {entity.values.length >=
                                                                                            9 ? (
                                                                                                <span
                                                                                                    style={{
                                                                                                        color:
                                                                                                            '#F07F31',
                                                                                                        marginLeft:
                                                                                                            '3px'
                                                                                                    }}
                                                                                                >
                                                                                                    (
                                                                                                    {
                                                                                                        entity.values
                                                                                                            .length
                                                                                                    }
                                                                                                    )
                                                                                                </span>
                                                                                            ) : (
                                                                                                <span
                                                                                                    style={{
                                                                                                        color:
                                                                                                            '#F07F31',
                                                                                                        marginLeft:
                                                                                                            '3px'
                                                                                                    }}
                                                                                                >
                                                                                                    (0
                                                                                                    {
                                                                                                        entity.values
                                                                                                            .length
                                                                                                    }
                                                                                                    )
                                                                                                </span>
                                                                                            )}
                                                                                        </Td>

                                                                                        <Td
                                                                                            size={2}
                                                                                            className="list_flow_span"
                                                                                            onClick={() =>
                                                                                                props.history.push(
                                                                                                    `/workspace/${workspacename}/dialog/${assistantname}/entity/${entity.entity}`
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <span className="entity_span">
                                                                                                {formatDateInCard(
                                                                                                    entity.updated.includes(
                                                                                                        'Z'
                                                                                                    )
                                                                                                        ? entity.updated
                                                                                                        : entity.updated +
                                                                                                              'Z'
                                                                                                )}
                                                                                            </span>
                                                                                            <span>|</span>
                                                                                            <span className="entity_span">
                                                                                                {formatAMPM(
                                                                                                    entity.updated.includes(
                                                                                                        'Z'
                                                                                                    )
                                                                                                        ? entity.updated
                                                                                                        : entity.updated +
                                                                                                              'Z'
                                                                                                )}
                                                                                            </span>
                                                                                        </Td>
                                                                                        <Td size={1}>
                                                                                            {PageState.action !==
                                                                                                ActionT.SELECT &&
                                                                                                !entity.is_system_entity &&
                                                                                                PageState.action !==
                                                                                                    ActionT.MULTIPLE_DELETE &&
                                                                                                !enName.includes(
                                                                                                    'workativ-'
                                                                                                ) && (
                                                                                                    <ToolBox>
                                                                                                        {entityWritePermission.value && <span
                                                                                                            onClick={() => {
                                                                                                                setPageState(
                                                                                                                    {
                                                                                                                        action:
                                                                                                                            ActionT.EDIT,
                                                                                                                        id:
                                                                                                                            entity.id,
                                                                                                                        entity:
                                                                                                                            entity.entity,
                                                                                                                        description:
                                                                                                                            entity.description
                                                                                                                    }
                                                                                                                )
                                                                                                                setCurrentEntity(
                                                                                                                    entity
                                                                                                                )
                                                                                                            }}
                                                                                                        >
                                                                                                            {/* <Tooltip
                                                                                                                className="target customTip "
                                                                                                                zIndex={10000}
                                                                                                                arrowSize={8}
                                                                                                                tagName="span"
                                                                                                                content={'Edit'}
                                                                                                                distance={5}
                                                                                                            > */}
                                                                                                                <EditIcon />
                                                                                                            {/* </Tooltip> */}
                                                                                                        </span>}
                                                                                                        {entityDeletePermission.value && <span
                                                                                                            onClick={() =>
                                                                                                                setPageState(
                                                                                                                    {
                                                                                                                        action:
                                                                                                                            ActionT.DELETE,
                                                                                                                        data: entity
                                                                                                                    }
                                                                                                                )
                                                                                                            }
                                                                                                        >
                                                                                                            {/* <Tooltip
                                                                                                                className="target customTip "
                                                                                                                zIndex={10000}
                                                                                                                arrowSize={8}
                                                                                                                tagName="span"
                                                                                                                content={'Delete'}
                                                                                                                distance={5}
                                                                                                            > */}
                                                                                                                <DeleteIcon />
                                                                                                            {/* </Tooltip> */}
                                                                                                            
                                                                                                        </span>}
                                                                                                    </ToolBox>
                                                                                                )}
                                                                                        </Td>
                                                                                    </>
                                                                                )
                                                                            }}
                                                                        />
                                                                    </Tr>
                                                                )
                                                            })}
                                                    </Tbody>
                                                </Table>
                                            </TableContainer>
                                        </TabelWrapper>
                                        {/* <div className="col-md-4 intent_list_description caret_before">
                                        <span>Name</span>
                                    </div>
                                    <div className="col-md-4 intent_list_description">
                                        <span>Description</span>
                                    </div>
                                    <div className="col-md-2 intent_list_description">
                                        <span>Date</span>
                                    </div> */}
                                    </React.Fragment>
                                )}
                                {entities
                                    .filter(e => e.entity.toLowerCase().includes(searchWord.toLowerCase()))
                                    .map((entity, index) => {
                                        //entity name
                                        let enName = entity.entity
                                        // let de: ExistT[] = []

                                        //de default entity

                                        // if (entity.metadata) {
                                        //     const defaultEntity = entity.metadata.displayTitle as any
                                        //     de = entities.filter(
                                        //         (x: any) => x.metadata && x.metadata.displayTitle === defaultEntity
                                        //     )
                                        // }
                                        // if (de.length > 0 && de[0].metadata) {
                                        //     enName = de[0].metadata.displayTitle
                                        // }

                                        let reg = new RegExp(searchWord, 'gi')
                                        const replaced = enName.replace(reg, function(str) {
                                            return '<div class="highlighted-text d-inline">' + str + '</div>'
                                        })

                                        let label =
                                            searchWord != ''
                                                ? `<h5 style='padding-top:${
                                                      PageState.action == ActionT.SELECT ? 18 : 0
                                                  }px'>` +
                                                  enName +
                                                  '</h5>'
                                                : `<h5 style='padding-top:${
                                                      PageState.action == ActionT.SELECT ? 18 : 0
                                                  }px'>` +
                                                  enName +
                                                  '</h5>'
                                        if (viewType.View == ViewTypeT.GridView) {
                                            return (
                                                <Card
                                                    key={index}
                                                    id={entity.id}
                                                    uuid={entity.id}
                                                    isSelected={isSelected}
                                                    select={selectEntity}
                                                    showSelection={() =>
                                                        PageState.action === ActionT.SELECT ||
                                                        (PageState.action === ActionT.MULTIPLE_DELETE &&
                                                            PageState.items.length > 0)
                                                    }
                                                    delete={() => {
                                                        setPageState({
                                                            action: ActionT.DELETE,
                                                            data: entity
                                                        })
                                                    }}
                                                    edit={() => {
                                                        setPageState({
                                                            action: ActionT.EDIT,
                                                            id: entity.id,
                                                            entity: entity.entity,
                                                            description: entity.description
                                                        })
                                                    }}
                                                    label={label}
                                                    info={
                                                        entity.is_system_entity === false
                                                            ? entity.description
                                                            : JSON.parse(entity.description).original_description
                                                    }
                                                    onClick={() =>
                                                        props.history.push(
                                                            `/workspace/${workspacename}/dialog/${assistantname}/entity/${entity.entity}`
                                                        )
                                                    }
                                                    showToolbox={
                                                        PageState.action !== ActionT.SELECT &&
                                                        entity.is_system_entity == false
                                                    }
                                                    modified={entity.updated}
                                                    disabled={PageState.action == ActionT.SELECT}
                                                    entityLength={entity.values.length}
                                                    permissions={{
                                                        write: entityWritePermission.value,
                                                        delete: entityDeletePermission.value
                                                    } as {
                                                        write: boolean 
                                                        delete: boolean
                                                    }}
                                                />
                                            )
                                        } else {
                                            return (
                                                // <List
                                                //     key={index}
                                                //     showCheckBox={!enName.includes('workativ-') && true}
                                                //     checkBoxShow={
                                                //         PageState.action === ActionT.SELECT ||
                                                //         (PageState.action === ActionT.MULTIPLE_DELETE &&
                                                //             PageState.items.length > 0)
                                                //     }
                                                //     render={(show: boolean, ToolBox: any) => {
                                                //         return (
                                                //             <div className="col-md-12 list-view-sub">
                                                //                 <div className="main_sub">
                                                //                     <div className={'col-md-4 list_flow_hr'}>
                                                //                         <ListSelectBox
                                                //                             show={show}
                                                //                             isSelected={isSelected(entity.id)}
                                                //                             onClick={e => selectEntity(entity.id)}
                                                //                             value={entity.entity}
                                                //                         />
                                                //                         <div
                                                //                             className="search_card_list"
                                                //                             onClick={() =>
                                                //                                 props.history.push(
                                                //                                     `/workspace/${workspacename}/dialog/${assistantname}/entity/${entity.entity}`
                                                //                                 )
                                                //                             }
                                                //                         >
                                                //                             {Parser(label)}
                                                //                         </div>
                                                //                     </div>
                                                //                     <div
                                                //                         className="col-md-4 list_flow_p"
                                                //                         onClick={() =>
                                                //                             props.history.push(
                                                //                                 `/workspace/${workspacename}/dialog/${assistantname}/entity/${entity.entity}`
                                                //                             )
                                                //                         }
                                                //                     >
                                                //                         <p className="listite_centre">
                                                //                             {entity.is_system_entity === false
                                                //                                 ? entity.description
                                                //                                 : JSON.parse(entity.description)
                                                //                                       .original_description}
                                                //                         </p>
                                                //                     </div>
                                                //                     <div
                                                //                         className="col-md-2  list_flow_span"
                                                //                         onClick={() =>
                                                //                             props.history.push(
                                                //                                 `/workspace/${workspacename}/dialog/${assistantname}/entity/${entity.entity}`
                                                //                             )
                                                //                         }
                                                //                     >
                                                //                         <span className="entity_span">
                                                //                             {formatDateInCard(
                                                //                                 entity.updated.includes('Z')
                                                //                                     ? entity.updated
                                                //                                     : entity.updated + 'Z'
                                                //                             )}
                                                //                         </span>
                                                //                         <span>|</span>
                                                //                         <span className="entity_span">
                                                //                             {formatAMPM(
                                                //                                 entity.updated.includes('Z')
                                                //                                     ? entity.updated
                                                //                                     : entity.updated + 'Z'
                                                //                             )}
                                                //                         </span>
                                                //                     </div>

                                                //                     {PageState.action !== ActionT.SELECT &&
                                                //                         !entity.is_system_entity &&
                                                //                         PageState.action !== ActionT.MULTIPLE_DELETE &&
                                                //                         !enName.includes('workativ-') && (
                                                //                             <ToolBox>
                                                //                                 <span
                                                //                                     onClick={() =>
                                                //                                         setPageState({
                                                //                                             action: ActionT.EDIT,
                                                //                                             id: entity.id,
                                                //                                             entity: entity.entity,
                                                //                                             description: entity.description
                                                //                                         })
                                                //                                     }
                                                //                                 >
                                                //                                     <EditIcon />
                                                //                                 </span>
                                                //                                 <span
                                                //                                     onClick={() =>
                                                //                                         setPageState({
                                                //                                             action: ActionT.DELETE,
                                                //                                             data: entity
                                                //                                         })
                                                //                                     }
                                                //                                 >
                                                //                                     <DeleteIcon />
                                                //                                 </span>
                                                //                             </ToolBox>
                                                //                         )}
                                                //                 </div>
                                                //             </div>
                                                //         )
                                                //     }}
                                                // />
                                                <></>
                                            )
                                        }
                                    })}
                            </div>
                        </div>
                    </section>
                </Wrapper>
            </ErrorBoundary>
        </>
    )
}

export default AssistantEntity
