import * as React from 'react';
import * as R from 'ramda';
import { getProductId } from '../../../shared/common/src/utils/api-utils';
import {
  BasicIcon,
  BotsIcon,
  ConversationMessageIcon,
  PriceEnterpriseIcon,
  PriceFreeIcon,
  PriceProIcon,
  PriceStandardIcon,
  PricingNoIcon,
  PricingYesIcon,
  WorkflowExecutionIcon,
  WorkflowIcon,
} from '@/common/Icons/Icons';

import {
  PlanResponse,
  ResourceConsumption,
  StripeData,
} from './SubsriptionResponse';

type Required<T> = {
  [P in keyof T]-?: T[P];
};

export interface InvoiceHistoryT {
  startDate: number;
  endDate: number;
  amount: number;
  currency: string;
  invoicePdf: string;
  invoiceId: string;
  status: string;
}

export enum ActionT {
  View = 'View',
  ChangesCard = 'View',
  Loading = 'loading',
  CustomerNotFound = 'CustomerNotFound',
  CustomerInTrial = 'CustomerInTrial',
  Unauthorized = 'Unauthorized',
  Error = 'Error',
}

export type DefaultPlan = {
  defaultPlanId: string;
  defaultPlanName: string;
  defaultPlanDisplayName: string;
  mappedPlanName: string;
  mappedPlanDisplayName: string;
  mappedPlanId: string;
};
export type ViewT = {
  action: ActionT.View;
  plans: PlanResponse[];
  subscription: SubscribedPlan;
  planLimits: {
    [index: string]: ResourceLimitMeta[];
  };
  defaultPlan: DefaultPlan;
  planInfo: PlanInfo;
  workspace: string;
};

export type CustomerInTrialT = {
  action: ActionT.CustomerInTrial;
  subscription: SubscribedPlan;
};

export type LoadingT = {
  action: ActionT.Loading;
};

export type UnauthorizedT = {
  action: ActionT.Unauthorized;
};

export type CustomerNotFoundT = {
  action: ActionT.CustomerNotFound;
};

export type ErrorT = {
  action: ActionT.Error;
  error?: { error: boolean; info: string };
};

export const ErrorPage: ErrorT = {
  action: ActionT.Error,
  error: { error: false, info: '' },
};

export type PageStateT =
  | ViewT
  | LoadingT
  | UnauthorizedT
  | CustomerNotFoundT
  | CustomerInTrialT
  | ErrorT;

export type TrialStatus = 'trialing';

export enum SubscribedStatus {
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
}

type SubscribedCustomerDetails =
  | {
      status: TrialStatus;
      trialEndDate: Date;
      trialStartDate: Date;
      hasTrialEnded: boolean;
    }
  | {
      status: SubscribedStatus;
      stripeSubscription: StripeData;
      current_period_end: number;
      current_period_start: number;
      productSubscriptionId: string;
      addOnSubscriptionId: string;
      hasHadTrial: boolean;
    };

type PlanDetails = {
  referenceName: string;
  displayName: string;
  id: string;
  billed: 'Monthly' | 'Yearly' | 'Not_billed';
  price: string;
};

export const Plans = [
  {
    plans: 'Basic',
    referenceName: 'BASIC',
    type: 'Monthly',
    priority: 1,
    dollar: '$',
    price: '199',
    steps: [
      {
        category: 'Chatbot Builder',
        hoverContent: 'To built purposeful conversations',
      },
      {
        category: 'Workflow Builder',
        hoverContent: 'To built automations to resolve an issue',
      },
      {
        category: 'VA on Multi chat channel',
        hoverContent:
          'Deploy the chatbot on a chat channel of your choice like Slack and Microsoft Teams',
      },

      {
        bolder: '2',
        category: 'Admins',
        hoverContent: 'No of admins who can work on the application',
      },

      {
        bolder: '2000',
        category: ' Automation executions',
        hoverContent:
          'The total number of automations that can be executed over one month / 30 day period by the users.',
      },

      {
        bolder: '4000',
        category: ' Messages',
        hoverContent:
          'The total number of messages sent to the chatbot over a one month / 30 day period by the users.',
      },

      {
        bolder: '5',
        category: ' Active automations',
        hoverContent:
          ' At a time, the number of active automations available for use in the Chatbot Builder. Admin will be able to disable existing and enable new automations as per choice. ',
      },
      {
        bolder: '48 Hours',
        category: '  Response time ',
        hoverContent: 'Response time to answer/resolve the client queires.',
      },
      {
        bolder: 'Add-Ons',
        category: ' for 0.1 $',
        hoverContent:
          'Additional cost for each automation execution and message.',
      },
    ],
  },
  {
    plans: 'Standard',
    priority: 2,
    referenceName: 'STANDARD',
    type: 'Monthly',
    dollar: '$',
    price: '399',

    steps: [
      {
        category: 'Chatbot Builder',
        hoverContent: 'To built purposeful conversations',
      },
      {
        category: 'Workflow Builder',
        hoverContent: 'To built automations to resolve an issue',
      },
      {
        category: 'VA on Multi chat channel',
        hoverContent:
          'Deploy the chatbot on a chat channel of your choice like Slack and Microsoft Teams',
      },

      {
        bolder: '3',
        category: ' Admins',
        hoverContent: 'No of admins who can work on the application',
      },

      {
        bolder: '5000',
        category: ' Automation executions',
        hoverContent:
          'The total number of automations that can be executed over one month / 30 day period by the users.',
      },

      {
        bolder: '10000',

        category: ' Messages',
        hoverContent:
          'The total number of messages sent to the chatbot over a one month / 30 day period by the users.',
      },

      {
        bolder: '10',
        category: ' Active automations',
        hoverContent:
          ' At a time, the number of active automations available for use in the Chatbot Builder. Admin will be able to disable existing and enable new automations as per choice. ',
      },
      {
        bolder: '24 Hours',
        category: '  Response time ',
        hoverContent: 'Response time to answer/resolve the client queires.',
      },
      {
        bolder: 'Add-Ons',
        category: ' for 0.1 $',
        hoverContent:
          'Additional cost for each automation execution and message.',
      },
    ],
  },
  {
    plans: 'Professional',
    priority: 3,
    referenceName: 'PRO',
    type: 'Monthly',
    dollar: '$',
    price: '699',

    steps: [
      {
        category: 'Chatbot Builder',
        hoverContent: 'To built purposeful conversations',
      },
      {
        category: 'Workflow Builder',
        hoverContent: 'To built automations to resolve an issue',
      },
      {
        category: 'VA on Multi chat channel',
        hoverContent:
          'Deploy the chatbot on a chat channel of your choice like Slack and Microsoft Teams',
      },

      {
        bolder: '3',
        category: ' Admins',
        hoverContent: 'No of admins who can work on the application',
      },

      {
        bolder: '5000',
        category: ' Automation executions',
        hoverContent:
          'The total number of automations that can be executed over one month / 30 day period by the users.',
      },

      {
        bolder: '10000',

        category: ' Messages',
        hoverContent:
          'The total number of messages sent to the chatbot over a one month / 30 day period by the users.',
      },

      {
        bolder: '10',
        category: ' Active automations',
        hoverContent:
          ' At a time, the number of active automations available for use in the Chatbot Builder. Admin will be able to disable existing and enable new automations as per choice. ',
      },
      {
        bolder: '24 Hours',
        category: '  Response time ',
        hoverContent: 'Response time to answer/resolve the client queires.',
      },
      {
        bolder: 'Add-Ons',
        category: ' for 0.1 $',
        hoverContent:
          'Additional cost for each automation execution and message.',
      },
    ],
  },
];

export const AdditionalPlans =
  getProductId() === 'ASSISTANT'
    ? [
        {
          plans: 'Free',
          referenceName: 'FREE',
          type: 'Monthly',
          priority: -1,
          image: <PriceFreeIcon />,
          dollar: '$',
          price: '0',
          uesrsMessage: '',
          discount: '',
          resources: [
            {
              bolder: '1',
              category: 'Admin',
              hoverContent: 'Number of admins access.',
            },
            {
              bolder: '1',
              category: ' Chatbots',
              hoverContent: 'Create multiple chatbots (IT, HR etc)',
            },
            {
              bolder: '200',
              category: ' Chatbot Sessions',
              hoverContent: 'Total number of bot sessions per month.',
            },
            {
              bolder: '1',
              category: ' App Workflows',
              hoverContent:
                'Total no. of active app workflow automations to use within chatbot. Admin can disable old and enable new workflows.',
            },
            {
              bolder: '100',
              category: ' App Workflow Executions',
              hoverContent:
                'Total number of app workflow execution(from bot) per month.',
            },
          ],
          steps: [
            {
              category: 'Chatbot Builder',
              hoverContent:
                'No-code chatbot builder tools to create and manage chatbot.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Chatbot Automation',
              hoverContent:
                'No-code workflow builder to create app based workflow automations for chatbot.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'FAQ Builder',
              hoverContent:
                'No-code FAQ builder to create and automate FAQs for user queries.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'App Integrations',
              hoverContent:
                'Pre-built app integrations or connectors for chatbot.',
              icon: <PricingYesIcon />,
            },

            {
              category: 'Marketplace',
              hoverContent:
                'Pre-built app workflows, Bots, dialogs*, and FAQs* for chatbot.',
              icon: <PricingYesIcon />,
            },

            {
              category: 'Integrate with Chat Channels',
              hoverContent:
                'Deploy chatbot on your Slack, MS Teams, and as a Chat Widget.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Multi-Step App Workflows',
              hoverContent: 'Ability to create multi-step app workflows.',
              icon: <PricingNoIcon />,
            },
            {
              category: 'Agent Handover',
              hoverContent:
                'Ability to transfer chatbot session to Live Agent in MS Teams. ',
              icon: <PricingNoIcon />,
            },

            {
              category: 'On-prem App Connect',
              hoverContent: 'Connect chatbot with on-prem applications.',
              icon: <PricingNoIcon />,
            },
            {
              category: 'Approval Management',
              hoverContent:
                'Manage approvals for app workflows or service request automated via chatbot. eg., new laptop, account activation, reset password etc',
              icon: <PricingNoIcon />,
            },
            {
              category: 'Add-On Consumptions',
              hoverContent:
                'Cost of each additional bot session, and each app workflow execution over the monthly limit.',
              icon: <PricingNoIcon />,
            },
            {
              category: 'Email, Help Doc Support',
              hoverContent: 'Phone support is outbound calls only. ',
              icon: <PricingYesIcon />,
            },
          ],
        },
      ]
    : [
        {
          plans: 'Free',
          referenceName: 'FREE',
          type: 'Monthly',
          priority: -1,
          image: <PriceFreeIcon />,
          dollar: '$',
          price: '0',
          uesrsMessage: '',
          discount: '',
          resources: [
            {
              bolder: '1',
              category: 'Admin',
              hoverContent: 'Number of admins access.',
            },
            {
              bolder: '2',
              category: ' App Workflows',
              hoverContent:
                'Total no. of active app workflow automations. Admin can disable old and enable new workflows.',
            },
            {
              bolder: '100',
              category: ' App Workflow Executions',
              hoverContent:
                'Total number of app workflow execution per month.',
            },
            {
              bolder: '15 Min',
              category: ' Polling Time',
              hoverContent:
                'Time interval between executions.',
            },
          ],
          steps: [
            {
              category: 'Marketplace',
              hoverContent:
                'Pre-built app workflows.',
              icon: <PricingYesIcon />,
            },

            {
              category: 'Premium Apps',
              hoverContent: 'Access to Premium Apps',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Multi-Step App Workflows',
              hoverContent: 'Ability to create multi-step app workflows.',
              icon: <PricingNoIcon />,
            },
            {
              category: 'Webhooks',
              hoverContent:
                'Ability to call workflow via webhooks from 3rd party apps or chatbot platforms.',
              icon: <PricingNoIcon />,
            },

            {
              category: 'On-prem App Connect',
              hoverContent: 'Connect with on-prem applications.',
              icon: <PricingNoIcon />,
            },
            {
              category: 'Approval Management',
              hoverContent:
                'Manage approvals for app workflows or service request. eg., new laptop, account activation, reset password etc',
              icon: <PricingNoIcon />,
            },
            {
              category: 'Add-On Consumptions',
              hoverContent:
                'Cost of each additional workflow execution above monthly limit.',
              icon: <PricingNoIcon />,
            },
            {
              category: 'Execution logs',
              hoverContent:
                'Workflow execution logs',
              isConsumption: '7 days',
            },
            {
              category: 'Email, Help Doc Support',
              hoverContent:
                'Phone support is outbound calls only. All paid plans include a dedicated customer success specialist.',
              icon: <PricingNoIcon />,
            },
          ],
        },
      ];

export const PlansV2 =
  getProductId() === 'ASSISTANT'
    ? [
        {
          plans: 'Basic',
          referenceName: 'BASIC',
          type: 'Monthly',
          priority: 1,
          image: <BasicIcon />,
          dollar: '$',
          price: '595',
          uesrsMessage: '',
          discount: '33% discount on annual plan',
          resources: [
            {
              bolder: '1',
              category: 'Admin',
              hoverContent: 'Number of admins access.',
            },
            {
              bolder: '1',
              category: ' Chatbots',
              hoverContent: 'Create multiple chatbots (IT, HR etc)',
            },
            {
              bolder: '600',
              category: ' Chatbot Sessions',
              hoverContent: 'Total number of bot sessions per month.',
            },
            {
              bolder: '2',
              category: ' App Workflows',
              hoverContent:
                'Total no. of active app workflow automations to use within chatbot. Admin can disable old and enable new workflows.',
            },
            // {
            //     bolder: '1000',
            //     category: ' Faq Answers',
            //     isComingSoon: true,
            //     hoverContent: 'Total number of FAQ answer limit per month.'
            // },
            {
              bolder: '600',
              category: ' App Workflow Executions',
              hoverContent:
                'Total number of app workflow execution(from bot) per month.',
            },
          ],
          steps: [
            {
              category: 'Chatbot Builder',
              hoverContent:
                'No-code chatbot builder tools to create and manage chatbot.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Chatbot Automation',
              hoverContent:
                'No-code workflow builder to create app based workflow automations for chatbot.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'FAQ Builder',
              hoverContent:
                'No-code FAQ builder to create and automate FAQs for user queries.',
              icon: <PricingYesIcon />,
            },

            {
              category: 'App Integrations',
              hoverContent:
                'Pre-built app integrations or connectors for chatbot.',
              icon: <PricingYesIcon />,
            },

            {
              category: 'Marketplace',
              hoverContent:
                'Pre-built app workflows, Bots, dialogs*, and FAQs* for chatbot.',
              icon: <PricingYesIcon />,
            },

            {
              category: 'Integrate with Chat Channels',
              hoverContent:
                'Deploy chatbot on your Slack, MS Teams, and as a Chat Widget.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Multi-Step App Workflows',
              hoverContent: 'Ability to create multi-step app workflows.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Agent Handover',
              hoverContent:
                'Ability to transfer chatbot session to Live Agent in MS Teams. ',
              icon: <PricingNoIcon />,
            },

            {
              category: 'On-prem App Connect',
              hoverContent: 'Connect chatbot with on-prem applications.',
              icon: <PricingNoIcon />,
            },
            {
              category: 'Approval Management',
              hoverContent:
                'Manage approvals for app workflows or service request automated via chatbot. eg., new laptop, account activation, reset password etc',
              icon: <PricingNoIcon />,
            },
            {
              category: 'Add-On Consumptions',
              hoverContent:
                'Cost of each additional bot session, and each app workflow execution over the monthly limit.',
              icon: <PricingNoIcon />,
            },
            {
              category: 'Email, Help Doc Support',
              hoverContent: 'Phone support is outbound calls only. ',
              icon: <PricingYesIcon />,
            },
          ],
        },
        {
          plans: 'Standard',
          priority: 2,
          referenceName: 'STANDARD',
          type: 'Monthly',
          image: <PriceStandardIcon />,
          dollar: '$',
          price: '1345',
          uesrsMessage: '',
          discount: '33% discount on annual plan',
          resources: [
            {
              bolder: '2',
              category: 'Admins',
              hoverContent: 'Number of admins access.',
            },
            {
              bolder: '1',
              category: ' Chatbots',
              hoverContent: 'Create multiple chatbots (IT, HR etc)',
            },
            {
              bolder: '2000',
              category: ' Chatbot Sessions',
              hoverContent: 'Total number of bot sessions per month.',
            },
            {
              bolder: '5',
              category: ' App Workflows',
              hoverContent:
                'Total no. of active app workflow automations to use within chatbot. Admin can disable old and enable new workflows.',
            },
            // {
            //     bolder: '2000',
            //     category: ' Faq Answers',
            //     isComingSoon: true,
            //     hoverContent: 'Total number of FAQ answer limit per month.'
            // },
            {
              bolder: '2000',
              category: ' App Workflow Executions',
              hoverContent:
                'Total number of app workflow execution(from bot) per month.',
            },
          ],
          steps: [
            {
              category: 'Chatbot Builder',
              hoverContent:
                'No-code chatbot builder tools to create and manage chatbot.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Chatbot Automation',
              hoverContent:
                'No-code workflow builder to create app based workflow automations for chatbot.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'FAQ Builder',
              hoverContent:
                'No-code FAQ builder to create and automate FAQs for user queries.',
              icon: <PricingYesIcon />,
            },

            {
              category: 'App Integrations',
              hoverContent:
                'Pre-built app integrations or connectors for chatbot.',
              icon: <PricingYesIcon />,
            },

            {
              category: 'Marketplace',
              hoverContent:
                'Pre-built app workflows, Bots, dialogs*, and FAQs* for chatbot.',
              icon: <PricingYesIcon />,
            },

            {
              category: 'Integrate with Chat Channels',
              hoverContent:
                'Deploy chatbot on your Slack, MS Teams, and as a Chat Widget.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Multi-Step App Workflows',
              hoverContent: 'Ability to create multi-step app workflows.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Agent Handover',
              hoverContent:
                'Ability to transfer chatbot session to Live Agent in MS Teams. ',
              icon: <PricingNoIcon />,
            },

            {
              category: 'On-prem App Connect',
              hoverContent: 'Connect chatbot with on-prem applications.',
              icon: <PricingNoIcon />,
            },
            {
              category: 'Approval Management',
              hoverContent:
                'Manage approvals for app workflows or service request automated via chatbot. eg., new laptop, account activation, reset password etc',
              icon: <PricingNoIcon />,
            },
            {
              category: 'Add-On Consumptions',
              hoverContent:
                'Cost of each additional bot session, and each app workflow execution over the monthly limit.',
              isConsumption: '$0.1 each',
            },
            {
              category: 'Email, Help Doc, and Phone*',
              hoverContent: 'Phone support is outbound calls only. ',
              icon: <PricingYesIcon />,
            },
          ],
        },
        {
          plans: 'Pro',
          priority: 3,
          referenceName: 'PRO',
          type: 'Monthly',
          image: <PriceProIcon />,
          dollar: '$',
          price: '2088',
          uesrsMessage: '',
          discount: '33% discount on annual plan',
          resources: [
            {
              bolder: '3',
              category: 'Admins',
              hoverContent: 'Number of admins access.',
            },
            {
              bolder: '2',
              category: ' Chatbots',
              hoverContent: 'Create multiple chatbots (IT, HR etc)',
            },
            {
              bolder: '4000',
              category: ' Chatbot Sessions',
              hoverContent: 'Total number of bot sessions per month.',
            },
            {
              bolder: '10',
              category: ' App Workflows',
              hoverContent:
                'Total no. of active app workflow automations to use within chatbot. Admin can disable old and enable new workflows.',
            },
            // {
            //     bolder: '5000',
            //     category: ' Faq Answers',
            //     isComingSoon: true,
            //     hoverContent: 'Total number of FAQ answer limit per month.'
            // },
            {
              bolder: '4000',
              category: ' App Workflow Executions',
              hoverContent:
                'Total number of app workflow execution(from bot) per month.',
            },
          ],
          steps: [
            {
              category: 'Chatbot Builder',
              hoverContent:
                'No-code chatbot builder tools to create and manage chatbot.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Chatbot Automation',
              hoverContent:
                'No-code workflow builder to create app based workflow automations for chatbot.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'FAQ Builder',
              hoverContent:
                'No-code FAQ builder to create and automate FAQs for user queries.',
              icon: <PricingYesIcon />,
            },

            {
              category: 'App Integrations',
              hoverContent:
                'Pre-built app integrations or connectors for chatbot.',
              icon: <PricingYesIcon />,
            },

            {
              category: 'Marketplace',
              hoverContent:
                'Pre-built app workflows, Bots, dialogs*, and FAQs* for chatbot.',
              icon: <PricingYesIcon />,
            },

            {
              category: 'Integrate with Chat Channels',
              hoverContent:
                'Deploy chatbot on your Slack, MS Teams, and as a Chat Widget.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Multi-Step App Workflows',
              hoverContent: 'Ability to create multi-step app workflows.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Agent Handover',
              hoverContent:
                'Ability to transfer chatbot session to Live Agent in MS Teams. ',
              icon: <PricingYesIcon />,
            },

            {
              category: 'On-prem App Connect',
              hoverContent: 'Connect chatbot with on-prem applications.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Approval Management',
              hoverContent:
                'Manage approvals for app workflows or service request automated via chatbot. eg., new laptop, account activation, reset password etc',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Add-On Consumptions',
              hoverContent:
                'Cost of each additional bot session, and each app workflow execution over the monthly limit.',
              isConsumption: '$0.1 each',
            },
            {
              category: 'Email, Help Doc, and Phone*',
              hoverContent: 'Phone support is outbound calls only. ',
              icon: <PricingYesIcon />,
            },
          ],
        },
        {
          plans: 'Basic',
          referenceName: 'BASIC_YEAR',
          type: 'Yearly',
          priority: 1,
          image: <BasicIcon />,
          dollar: '$',
          price: '399',
          uesrsMessage: '',
          discount: '33% discount applied',
          resources: [
            {
              bolder: '1',
              category: 'Admin',
              hoverContent: 'Number of admins access.',
            },
            {
              bolder: '1',
              category: ' Chatbots',
              hoverContent: 'Create multiple chatbots (IT, HR etc)',
            },
            {
              bolder: '600',
              category: ' Chatbot Sessions',
              hoverContent: 'Total number of bot sessions per month.',
            },
            {
              bolder: '2',
              category: ' App Workflows',
              hoverContent:
                'Total no. of active app workflow automations to use within chatbot. Admin can disable old and enable new workflows.',
            },
            // {
            //     bolder: '1000',
            //     category: ' Faq Answers',
            //     isComingSoon: true,
            //     hoverContent: 'Total number of FAQ answer limit per month.'
            // },
            {
              bolder: '600',
              category: ' App Workflow Executions',
              hoverContent:
                'Total number of app workflow execution(from bot) per month.',
            },
          ],
          steps: [
            {
              category: 'Chatbot Builder',
              hoverContent:
                'No-code chatbot builder tools to create and manage chatbot.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Chatbot Automation',
              hoverContent:
                'No-code workflow builder to create app based workflow automations for chatbot.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'FAQ Builder',
              hoverContent:
                'No-code FAQ builder to create and automate FAQs for user queries.',
              icon: <PricingYesIcon />,
            },

            {
              category: 'App Integrations',
              hoverContent:
                'Pre-built app integrations or connectors for chatbot.',
              icon: <PricingYesIcon />,
            },

            {
              category: 'Marketplace',
              hoverContent:
                'Pre-built app workflows, Bots, dialogs*, and FAQs* for chatbot.',
              icon: <PricingYesIcon />,
            },

            {
              category: 'Integrate with Chat Channels',
              hoverContent:
                'Deploy chatbot on your Slack, MS Teams, and as a Chat Widget.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Multi-Step App Workflows',
              hoverContent: 'Ability to create multi-step app workflows.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Agent Handover',
              hoverContent:
                'Ability to transfer chatbot session to Live Agent in MS Teams. ',
              icon: <PricingNoIcon />,
            },

            {
              category: 'On-prem App Connect',
              hoverContent: 'Connect chatbot with on-prem applications.',
              icon: <PricingNoIcon />,
            },
            {
              category: 'Approval Management',
              hoverContent:
                'Manage approvals for app workflows or service request automated via chatbot. eg., new laptop, account activation, reset password etc',
              icon: <PricingNoIcon />,
            },
            {
              category: 'Add-On Consumptions',
              hoverContent:
                'Cost of each additional bot session, and each app workflow execution over the monthly limit.',
              icon: <PricingNoIcon />,
            },
            {
              category: 'Email, Help Doc Support',
              hoverContent: 'Phone support is outbound calls only. ',
              icon: <PricingYesIcon />,
            },
          ],
        },
        {
          plans: 'Standard',
          priority: 2,
          referenceName: 'STANDARD_YEAR',
          type: 'Yearly',
          image: <PriceStandardIcon />,
          dollar: '$',
          price: '899',
          uesrsMessage: '',
          discount: '33% discount applied',
          resources: [
            {
              bolder: '2',
              category: 'Admins',
              hoverContent: 'Number of admins access.',
            },
            {
              bolder: '1',
              category: ' Chatbots',
              hoverContent: 'Create multiple chatbots (IT, HR etc)',
            },
            {
              bolder: '2000',
              category: ' Chatbot Sessions',
              hoverContent: 'Total number of bot sessions per month.',
            },
            {
              bolder: '5',
              category: ' App Workflows',
              hoverContent:
                'Total no. of active app workflow automations to use within chatbot. Admin can disable old and enable new workflows.',
            },
            // {
            //     bolder: '2000',
            //     category: ' Faq Answers',
            //     isComingSoon: true,
            //     hoverContent: 'Total number of FAQ answer limit per month.'
            // },
            {
              bolder: '2000',
              category: ' App Workflow Executions',
              hoverContent:
                'Total number of app workflow execution(from bot) per month.',
            },
          ],
          steps: [
            {
              category: 'Chatbot Builder',
              hoverContent:
                'No-code chatbot builder tools to create and manage chatbot.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Chatbot Automation',
              hoverContent:
                'No-code workflow builder to create app based workflow automations for chatbot.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'FAQ Builder',
              hoverContent:
                'No-code FAQ builder to create and automate FAQs for user queries.',
              icon: <PricingYesIcon />,
            },

            {
              category: 'App Integrations',
              hoverContent:
                'Pre-built app integrations or connectors for chatbot.',
              icon: <PricingYesIcon />,
            },

            {
              category: 'Marketplace',
              hoverContent:
                'Pre-built app workflows, Bots, dialogs*, and FAQs* for chatbot.',
              icon: <PricingYesIcon />,
            },

            {
              category: 'Integrate with Chat Channels',
              hoverContent:
                'Deploy chatbot on your Slack, MS Teams, and as a Chat Widget.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Multi-Step App Workflows',
              hoverContent: 'Ability to create multi-step app workflows.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Agent Handover',
              hoverContent:
                'Ability to transfer chatbot session to Live Agent in MS Teams. ',
              icon: <PricingNoIcon />,
            },

            {
              category: 'On-prem App Connect',
              hoverContent: 'Connect chatbot with on-prem applications.',
              icon: <PricingNoIcon />,
            },
            {
              category: 'Approval Management',
              hoverContent:
                'Manage approvals for app workflows or service request automated via chatbot. eg., new laptop, account activation, reset password etc',
              icon: <PricingNoIcon />,
            },
            {
              category: 'Add-On Consumptions',
              hoverContent:
                'Cost of each additional bot session, and each app workflow execution over the monthly limit.',
              isConsumption: '$0.1 each',
            },
            {
              category: 'Email, Help Doc, and Phone*',
              hoverContent: 'Phone support is outbound calls only. ',
              icon: <PricingYesIcon />,
            },
          ],
        },
        {
          plans: 'Pro',
          priority: 3,
          referenceName: 'PRO_YEAR',
          type: 'Yearly',
          image: <PriceProIcon />,
          dollar: '$',
          price: '1399',
          uesrsMessage: '',
          discount: '33% discount applied',
          resources: [
            {
              bolder: '3',
              category: 'Admins',
              hoverContent: 'Number of admins access.',
            },
            {
              bolder: '2',
              category: ' Chatbots',
              hoverContent: 'Create multiple chatbots (IT, HR etc)',
            },
            {
              bolder: '4000',
              category: ' Chatbot Sessions',
              hoverContent: 'Total number of bot sessions per month.',
            },
            {
              bolder: '10',
              category: ' App Workflows',
              hoverContent:
                'Total no. of active app workflow automations to use within chatbot. Admin can disable old and enable new workflows.',
            },
            // {
            //     bolder: '5000',
            //     category: ' Faq Answers',
            //     isComingSoon: true,
            //     hoverContent: 'Total number of FAQ answer limit per month.'
            // },
            {
              bolder: '4000',
              category: ' App Workflow Executions',
              hoverContent:
                'Total number of app workflow execution(from bot) per month.',
            },
          ],
          steps: [
            {
              category: 'Chatbot Builder',
              hoverContent:
                'No-code chatbot builder tools to create and manage chatbot.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Chatbot Automation',
              hoverContent:
                'No-code workflow builder to create app based workflow automations for chatbot.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'FAQ Builder',
              hoverContent:
                'No-code FAQ builder to create and automate FAQs for user queries.',
              icon: <PricingYesIcon />,
            },

            {
              category: 'App Integrations',
              hoverContent:
                'Pre-built app integrations or connectors for chatbot.',
              icon: <PricingYesIcon />,
            },

            {
              category: 'Marketplace',
              hoverContent:
                'Pre-built app workflows, Bots, dialogs*, and FAQs* for chatbot.',
              icon: <PricingYesIcon />,
            },

            {
              category: 'Integrate with Chat Channels',
              hoverContent:
                'Deploy chatbot on your Slack, MS Teams, and as a Chat Widget.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Multi-Step App Workflows',
              hoverContent: 'Ability to create multi-step app workflows.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Agent Handover',
              hoverContent:
                'Ability to transfer chatbot session to Live Agent in MS Teams. ',
              icon: <PricingYesIcon />,
            },

            {
              category: 'On-prem App Connect',
              hoverContent: 'Connect chatbot with on-prem applications.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Approval Management',
              hoverContent:
                'Manage approvals for app workflows or service request automated via chatbot. eg., new laptop, account activation, reset password etc',

              icon: <PricingYesIcon />,
            },
            {
              category: 'Add-On Consumptions',
              hoverContent:
                'Cost of each additional bot session, and each app workflow execution over the monthly limit.',
              isConsumption: '$0.1 each',
            },
            {
              category: 'Email, Help Doc, and Phone*',
              hoverContent: 'Phone support is outbound calls only. ',
              icon: <PricingYesIcon />,
            },
          ],
        },
      ]
    : [
        {
          plans: 'Pro',
          priority: 3,
          referenceName: 'PRO',
          type: 'Monthly',
          image: <PriceProIcon />,
          dollar: '$',
          price: '430',
          uesrsMessage: '',
          discount: '15% discount on annual plan',
          resources: [
            {
              bolder: '10',
              category: 'Admin',
              hoverContent: 'Number of admins access.',
            },
            {
              bolder: '50',
              category: ' App Workflows',
              hoverContent:
                'Total no. of active app workflow automations. Admin can disable old and enable new workflows.',
            },
            {
              bolder: '10000',
              category: ' App Workflow Executions',
              hoverContent:
                'Total number of app workflow execution per month.',
            },
            {
              bolder: '2 Min',
              category: ' Polling Time',
              hoverContent:
                'Time interval between executions.',
            },
          ],
          steps: [
            {
              category: 'Marketplace',
              hoverContent:
                'Pre-built app workflows.',
              icon: <PricingYesIcon />,
            },

            {
              category: 'Premium Apps',
              hoverContent: 'Access to Premium Apps',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Multi-Step App Workflows',
              hoverContent: 'Ability to create multi-step app workflows.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Webhooks',
              hoverContent:
                'Ability to call workflow via webhooks from 3rd party apps or chatbot platforms.',
              icon: <PricingYesIcon />,
            },

            {
              category: 'On-prem App Connect',
              hoverContent: 'Connect with on-prem applications.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Approval Management',
              hoverContent:
                'Manage approvals for app workflows or service request. eg., new laptop, account activation, reset password etc',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Add-On Consumptions',
              hoverContent:
                'Cost of each additional workflow execution above monthly limit.',
                isConsumption: '$0.1 each',
            },
            {
              category: 'Execution logs',
              hoverContent:
                'Workflow execution logs',
              isConsumption: '60 days',
            },
            {
              category: 'Email, Help Doc Support',
              hoverContent:
                'Phone support is outbound calls only. All paid plans include a dedicated customer success specialist.',
              icon: <PricingYesIcon />,
            },
          ],
        },
        {
          plans: 'Pro',
          priority: 3,
          referenceName: 'PRO_YEAR',
          type: 'Yearly',
          image: <PriceProIcon />,
          dollar: '$',
          price: '365',
          uesrsMessage: '',
          discount: '15% discount applied',
          resources: [
            {
              bolder: '10',
              category: 'Admins',
              hoverContent: 'Number of admins access.',
            },
            {
              bolder: '50',
              category: ' App Workflows',
              hoverContent:
                'Total no. of active app workflow automations. Admin can disable old and enable new workflows.',
            },
            {
              bolder: '10000',
              category: ' App Workflow Executions',
              hoverContent:
                'Total number of app workflow execution per month.',
            },
            {
              bolder: '2 Min',
              category: 'Polling Time',
              hoverContent:
                'Time interval between executions.',
            },

          ],
          steps: [
            {
              category: 'Marketplace',
              hoverContent:
                'Pre-built app workflows.',
              icon: <PricingYesIcon />,
            },

            {
              category: 'Premium Apps',
              hoverContent: 'Access to Premium Apps.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Multi-Step App Workflows',
              hoverContent: 'Ability to create multi-step app workflows.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Webhooks',
              hoverContent:
                'Ability to call workflow via webhooks from 3rd party apps or chatbot platforms.',
              icon: <PricingYesIcon />,
            },

            {
              category: 'On-prem App Connect',
              hoverContent: 'Connect with on-prem applications.',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Approval Management',
              hoverContent:
                'Manage approvals for app workflows or service request. eg., new laptop, account activation, reset password etc',
              icon: <PricingYesIcon />,
            },
            {
              category: 'Add-On Consumptions',
              hoverContent:
                'Cost of each additional workflow execution above monthly limit.',
                isConsumption: '$0.1 each',
            },
            {
              category: 'Execution logs',
              hoverContent:
                'Workflow execution logs',
              isConsumption: '60 days',
            },
            {
              category: 'Email, Help Doc Support',
              hoverContent:
                'Phone support is outbound calls only. All paid plans include a dedicated customer success specialist.',
              icon: <PricingYesIcon />,
            },
          ],
        },
      ];

type UnwrapArray<T> = T extends Array<infer U> ? U : never;

export type PlanMetaForUI = UnwrapArray<typeof Plans>;

export type PlanMetaForUIV2 = UnwrapArray<typeof PlansV2>;

export type AllResourceTypes =
  | 'ADMIN'
  | 'BOT'
  | 'ACTIVE_AUTOMATION'
  | 'AUTOMATION'
  | 'SESSION';

type ResourceCommonDisplay = {
  title: string;
  icon: JSX.Element;
  name: AllResourceTypes;
  priority: number;
};

type FiniteResourceDisplay = ResourceCommonDisplay & {
  type: 'FINITE';
  totalText: string;
  userText: string;
  remainingText: string;
};

type InfiniteResourceDisplay = ResourceCommonDisplay & {
  type: 'INFINITE';
  totalText: string;
  userText: string;
  remainingText: string;
  addOntext: {
    text: string;
    hoverContent: string;
  };
};

export type ResourceDisplay = FiniteResourceDisplay | InfiniteResourceDisplay;

export const ResourceDetails: ResourceDisplay[] = [
  {
    title: 'Active App Workflows',
    icon: <WorkflowIcon />,
    name: 'ACTIVE_AUTOMATION',
    type: 'FINITE',
    totalText: 'Active Workflows Quota',
    userText: 'Used Workflows ',
    remainingText: ' Remaining Workflows ',
    priority: 3,
  },
  {
    title: 'App Workflow Executions',
    icon: <WorkflowExecutionIcon />,
    name: 'AUTOMATION',
    type: 'INFINITE',
    totalText: 'Montly Quota ',
    userText: 'Used Executions ',
    remainingText: 'Remaining Executions',
    addOntext: {
      text: 'Add-On Executions',
      hoverContent: 'Exceeded Executions',
    },
    priority: 4,
  },
  {
    title: 'Chatbots',
    icon: <BotsIcon />,
    name: 'BOT',
    type: 'FINITE',
    totalText: 'Active Bots Quota',
    userText: 'Used Bots ',
    remainingText: ' Remaining Bots ',
    priority: 1,
  },
  {
    title: 'Chatbot Sessions',
    icon: <ConversationMessageIcon />,
    name: 'SESSION',
    type: 'INFINITE',
    totalText: 'Montly Quota ',
    userText: 'Used Sessions ',
    remainingText: 'Remaining Sessions',
    addOntext: {
      text: 'Add-On Sessions',
      hoverContent: 'Exceeded Sessions',
    },
    priority: 2,
  },
  {
    title: 'App Workflow Executions',
    icon: <WorkflowExecutionIcon />,
    name: 'AUTOMATION',
    type: 'FINITE',
    totalText: 'Montly Quota ',
    userText: 'Used Executions ',
    remainingText: 'Remaining Executions',
    priority: 4,
  },
  {
    title: 'Chatbot Sessions',
    icon: <ConversationMessageIcon />,
    name: 'SESSION',
    type: 'FINITE',
    totalText: 'Montly Quota ',
    userText: 'Used Sessions ',
    remainingText: 'Remaining Sessions',
    priority: 2,
  },
];

export type ResourceConsumptionForDisplay = {
  resourceId: string;
  name: string;
  id: AllResourceTypes;
  consumptionType: 'FINITE' | 'INFINITE';
  consumed: string;
  resourceLimit: number;
  priority: number;
};

export type SubscribedPlan = {
  workspace: string;
  displayName: string;
  referenceName: string;
  id: string;
  subscription: SubscribedCustomerDetails;
  planDetails: PlanDetails;
  metaForUI: PlanMetaForUI;
  resourceConsumption: ResourceConsumptionForDisplay[];
  finiteResourceConsumption: ResourceConsumption[];
  infiniteResourceConsumption: ResourceConsumption[];
};
export const planIconMap = {
  BASIC_YEAR: <PriceFreeIcon />,
  STANDARD_YEAR: <PriceStandardIcon />,
  PRO_YEAR: <PriceProIcon />,
  BASIC: <PriceFreeIcon />,
  STANDARD: <PriceStandardIcon />,
  PRO: <PriceProIcon />,
  FREE: <PriceFreeIcon />,
  ENTERPRISE: <PriceEnterpriseIcon />,
};

export const subscriptionStatusMap = {
  incomplete: 'Incomplete',
  incomplete_expired: 'Incomplete expired',
  trialing: 'Trialing',
  active: 'Active',
  past_due: 'Payment Due',
  unpaid: 'Unpaid',
  canceled: 'Cancelled',
  scheduled_for_cancellation: 'Cancelation Scheduled',
};

export const EnterprisePlan = [
  {
    plans: 'Enterprise',
    referenceName: 'ENTERPRISE',
    type: 'Monthly',
    priority: Infinity,
    image: <PriceEnterpriseIcon />,
    dollar: '$',
    price: '0',
    uesrsMessage: '',
    discount: '',
    resources: [
      {
        bolder: '1',
        category: 'Admin',
        hoverContent: 'Number of admins access.',
      },
      {
        bolder: '1',
        category: ' Chatbots',
        hoverContent: 'Create multiple chatbots (IT, HR etc)',
      },
      {
        bolder: '200',
        category: ' Chatbot Sessions',
        hoverContent: 'Total number of bot sessions per month.',
      },
      {
        bolder: '1',
        category: ' App Workflows',
        hoverContent:
          'Total no. of active app workflow automations to use within chatbot. Admin can disable old and enable new workflows.',
      },
      // {
      //     bolder: '300',
      //     category: ' Faq Answers',
      //     isComingSoon: true,
      //     hoverContent: 'Total number of FAQ answer limit per month.'
      // },
      {
        bolder: '100',
        category: ' App Workflow Executions',
        hoverContent:
          'Total number of app workflow execution(from bot) per month.',
      },
    ],
    steps: [
      {
        category: 'Chatbot Builder',
        hoverContent:
          'No-code chatbot builder tools to create and manage chatbot.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'Chatbot Automation',
        hoverContent:
          'No-code workflow builder to create app based workflow automations for chatbot.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'FAQ Builder',
        hoverContent:
          'No-code FAQ builder to create and automate FAQs for user queries.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'App Integrations',
        hoverContent: 'Pre-built app integrations or connectors for chatbot.',
        icon: <PricingYesIcon />,
      },

      {
        category: 'Marketplace',
        hoverContent:
          'Pre-built app workflows, Bots, dialogs*, and FAQs* for chatbot.',
        icon: <PricingYesIcon />,
      },

      {
        category: 'Integrate with Chat Channels',
        hoverContent:
          'Deploy chatbot on your Slack, MS Teams, and as a Chat Widget.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'Multi-Step App Workflows',
        hoverContent: 'Ability to create multi-step app workflows.',
        icon: <PricingNoIcon />,
      },
      {
        category: 'Agent Handover',
        hoverContent:
          'Ability to transfer chatbot session to Live Agent in MS Teams. ',
        icon: <PricingNoIcon />,
      },

      {
        category: 'On-prem App Connect',
        hoverContent: 'Connect chatbot with on-prem applications.',
        icon: <PricingNoIcon />,
      },
      {
        category: 'Approval Management',
        hoverContent:
          'Manage approvals for app workflows or service request automated via chatbot. eg., new laptop, account activation, reset password etc',
        icon: <PricingNoIcon />,
      },
      {
        category: 'Add-On Consumptions',
        hoverContent:
          'Cost of each additional bot session, and each app workflow execution over the monthly limit.',
        icon: <PricingNoIcon />,
      },
      {
        category: 'Email, Help Doc Support',
        hoverContent: 'Phone support is outbound calls only. ',
        icon: <PricingYesIcon />,
      },
    ],
  },
];

export type ResourceLimitMeta = {
  planDisplayName: string;
  planName: string;
  resourceDisplayName: string;
  resourceLimit: number;
  resourceName: AllResourceTypes;
  resourceType: 'finite' | 'infinite';
};

export type PlanResourcesLimit = Record<string, ResourceLimitMeta[]>;

export type PlanInfo = Record<string, InfoMeta[]>;

export interface InfoMeta {
  infoType: string;
  name: string;
  planDisplayName: string;
  planName: string;
  value: string;
}

export const makeEnterprisePlanUIStructure = (
  resourceLimitMeta: ResourceLimitMeta[],
  planInfoMeta: InfoMeta[],
  planDisplayName: string,
  planReferencename: string,
  price: string,
  planType: string
) => {
  // console.log(
  //   'DOING :: makeEnterprisePlanUIStructure',
  //   resourceLimitMeta,
  //   planInfoMeta,
  //   planDisplayName,
  //   planReferencename
  // );

  const limitUIMeta = resourceLimitMeta.map((x) => {
    switch (x.resourceName) {
      case 'ADMIN': {
        return {
          bolder: `${x.resourceLimit}`,
          category: 'Admin',
          hoverContent: 'Number of admins access.',
          order: 1,
        };
      }
      case 'BOT': {
        return {
          bolder: `${x.resourceLimit}`,
          category: ' Chatbots',
          hoverContent: 'Create multiple chatbots (IT, HR etc)',
          order: 2,
        };
      }
      case 'SESSION': {
        return {
          bolder: `${x.resourceLimit}`,
          category: ' Chatbot Sessions',
          hoverContent: 'Total number of bot sessions per month.',
          order: 3,
        };
      }
      case 'ACTIVE_AUTOMATION': {
        return {
          bolder: `${x.resourceLimit}`,
          category: ' App Workflows',
          hoverContent:
            'Total no. of active app workflow automations to use within chatbot. Admin can disable old and enable new workflows.',
          order: 4,
        };
      }
      case 'AUTOMATION': {
        return {
          bolder: `${x.resourceLimit}`,
          category: ' App Workflow Executions',
          hoverContent:
            'Total number of app workflow execution(from bot) per month.',
          order: 5,
        };
      }
    }
  });

  const hasAddon = resourceLimitMeta.find((x) => x.resourceType === 'infinite')
    ? {
        category: 'Add-On Consumptions',
        hoverContent:
          'Cost of each additional bot session, and each app workflow execution over the monthly limit.',
        isConsumption: '$0.1 each',
      }
    : {
        category: 'Add-On Consumptions',
        hoverContent:
          'Cost of each additional bot session, and each app workflow execution over the monthly limit.',
        icon: <PricingNoIcon />,
      };

  //   0: {name: "LOGS_LIMIT", value: "30", planName: "ENTERPRISE_GOTO", planDisplayName: "Enterprise", infoType: "standard"}
  // 1: {name: "ON_PREM_ACCESS", value: "yes", planName: "ENTERPRISE_GOTO", planDisplayName: "Enterprise", infoType: "permission"}
  // 2: {name: "AGENT_HANDOFF_ACCESS", value: "yes", planName: "ENTERPRISE_GOTO", planDisplayName: "Enterprise", infoType: "permission"}
  // 3: {name: "MULTI_STEP_ACCESS", value: "yes", planName: "ENTERPRISE_GOTO", planDisplayName: "Enterprise", infoType: "permission"}
  // 4: {name: "APPROVAL_ACCESS", value: "yes", planName: "ENTERPRISE_GOTO", planDisplayName: "Enterprise", infoType: "permission"}

  const infoUIMeta = planInfoMeta.map((x) => {
    switch (x.name) {
      case 'MULTI_STEP_ACCESS': {
        return {
          category: 'Multi-Step App Workflows',
          hoverContent: 'Ability to create multi-step app workflows.',
          icon: x.value === 'no' ? <PricingNoIcon /> : <PricingYesIcon />,
          order: 1,
        };
      }
      case 'AGENT_HANDOFF_ACCESS': {
        return {
          category: 'Agent Handover',
          hoverContent:
            'Ability to transfer chatbot session to Live Agent in MS Teams. ',
          icon: x.value === 'no' ? <PricingNoIcon /> : <PricingYesIcon />,
          order: 2,
        };
      }
      case 'ON_PREM_ACCESS': {
        return {
          category: 'On-prem App Connect',
          hoverContent: 'Connect chatbot with on-prem applications.',
          icon: x.value === 'no' ? <PricingNoIcon /> : <PricingYesIcon />,
          order: 3,
        };
      }

      case 'APPROVAL_ACCESS': {
        return {
          category: 'Approval Management',
          hoverContent:
            'Manage approvals for app workflows or service request automated via chatbot. eg., new laptop, account activation, reset password etc',
          icon: x.value === 'no' ? <PricingNoIcon /> : <PricingYesIcon />,
          order: 4,
        };
      }
    }

    return { order: -1 };
  });

  //  type ReqInfo = Required<UnwrapArray<typeof infoUIMeta>>

  const sorted = R.sortBy(R.prop('order'), limitUIMeta);

  const sortedInfo = R.sortBy(R.prop('order'), infoUIMeta).filter(
    (x) => x.order !== -1
  ) as {
    category: string;
    hoverContent: string;
    icon: JSX.Element;
    order: number;
  }[];

  const x = {
    plans: planDisplayName,
    referenceName: planReferencename,
    type: planType,
    priority: Infinity,
    image: <PriceEnterpriseIcon />,
    dollar: '$',
    price: price,
    uesrsMessage: '',
    discount: '',
    resources: [...sorted],
    steps: [
      {
        category: 'Chatbot Builder',
        hoverContent:
          'No-code chatbot builder tools to create and manage chatbot.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'Chatbot Automation',
        hoverContent:
          'No-code workflow builder to create app based workflow automations for chatbot.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'FAQ Builder',
        hoverContent:
          'No-code FAQ builder to create and automate FAQs for user queries.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'App Integrations',
        hoverContent: 'Pre-built app integrations or connectors for chatbot.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'Marketplace',
        hoverContent:
          'Pre-built app workflows, Bots, dialogs*, and FAQs* for chatbot.',
        icon: <PricingYesIcon />,
      },

      {
        category: 'Integrate with Chat Channels',
        hoverContent:
          'Deploy chatbot on your Slack, MS Teams, and as a Chat Widget.',
        icon: <PricingYesIcon />,
      },
      ...sortedInfo,
      ...[hasAddon],
      {
        category: 'Email, Help Doc Support',
        hoverContent: 'Phone support is outbound calls only. ',
        icon: <PricingYesIcon />,
      },
    ],
  };

  // console.log('DOING :: makeEnterprisePlanUIStructure :: output', x);
  return x;
};
