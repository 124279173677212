/*eslint-disable*/
import React from 'react';

import { useFetchFromApi } from '@/common/hooks/usefetch-from-api';

import {
  getJson,
  slackIntegrationUrl,
  teamsIntegrationGetListUrl,
} from '@/common/utils/api-utils';
import { SlackIntegration } from './SlackIntegration';
import { TeamsIntegration } from './TeamsIntegration';
import {
  MOCK_SLACK,
  slackResponse,
  MOCK_TEAMS,
  TeamResponse,
} from './MockFile';
export function AppWrapper(props: any) {
  const workspacename = props.match.params.workspacename;

  const app = props;
  // const slackValue = Promise.resolve(slackResponse);
  // const TeamsValue = Promise.resolve(TeamResponse);
  // const apiToCall = 
  // app.app === 'slack'
  // ?slackValue
  // :TeamsValue

  const apiToCall =
    app.app === 'slack'
      ? () => getJson(slackIntegrationUrl(`/clients/${workspacename}`))
      : () =>
        getJson(
          teamsIntegrationGetListUrl(workspacename, '/credentialList/')
        );

  const [state, setState, promiseRef] = useFetchFromApi(apiToCall);

  const retry = () => {
    const setStateDispatch = setState as React.Dispatch<any>;
    setStateDispatch(
      promiseRef.current
        ? promiseRef.current.then((response: any) => response.data)
        : null
    );
  };

  if (app.app === 'slack') {
    return <SlackIntegration state={state} retry={retry} {...props} />;
  } else {
    return <TeamsIntegration state={state} retry={retry} {...props} />;
  }
}
