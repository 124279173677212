/*eslint-disable*/
import React, { Component, Fragment } from 'react'
import Tooltip from 'react-tooltip-lite'

import { AppTriggerCardIcon, AppTriggerCardIconNew, ApptriggerIcon, DeleteIcon, DialogIcon, EditIcon, FlowCanvasIcons, FlowIcon, SaveAutomation, TimerIcon, WebhookCardIcon, WebhooksIcon } from '@/common/Icons/Icons'
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons'

import { formatAMPM, formatDateInCard, getProductId } from '@/common/utils/utils'
import { LinkedDialogIcon } from './Icons'
import { SelectBox } from './SelectBox'
import { CardDisplayT, CardTypes, FlowFilterE, Mode, Modes } from './types'
import { isDeployedNoTrigger } from './utils'

let Parser = require('html-react-parser')
type CardP = {
    card: CardDisplayT
    edit: (card: CardDisplayT) => void
    saveAs: (card: CardDisplayT) => void
    select: (card: CardDisplayT) => void
    click: () => void
    delete: (card: CardDisplayT) => void
    mode: Modes
    view: FlowFilterE
    setActivateOrDeactivateMode?: (card: any) => void
    hasConnectionError: boolean
    isApproval: boolean
}

type CardS = {
    show: boolean
}

export default class Card extends Component<CardP, CardS> {
    state: CardS = {
        show: false
    }

    isSelected = () => {
        const { mode, card } = this.props
        return (
            (mode.mode == Mode.Select &&
                mode.cards.filter(e => e.id == card.id && e.cardType == CardTypes.Flow).length != 0) ||
            (mode.mode == Mode.MoveToGroup &&
                mode.selectedFlows.filter((e: any) => e.id == card.id && e.cardType == CardTypes.Flow).length != 0)
        )
    }

    setShow = () => {
        this.setState(() => ({
            show: true
        }))
    }

    clearShow = () => {
        this.setState(() => ({
            show: false
        }))
    }

    handleSelected = () => {
        if (this.showSelection()) {
            this.props.select(this.props.card)
        }
    }

    showSelection = () =>
        this.props.card.active_status == false &&
        (this.props.mode.mode == Mode.Select || this.props.mode.mode == Mode.MoveToGroup || this.state.show)

    renderToolBox = () => {
        const stop = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.stopPropagation()
            event.preventDefault()
            this.props.edit(this.props.card)
        }
        const update = (event: React.ChangeEvent<HTMLInputElement>) => {
            event.stopPropagation()
            event.preventDefault()
            this.props.setActivateOrDeactivateMode && this.props.setActivateOrDeactivateMode(this.props.card)
        }

        const startOrStop = (event: React.MouseEvent<HTMLDivElement> ) => {
            event.stopPropagation()
            event.preventDefault()
            this.props.setActivateOrDeactivateMode && this.props.setActivateOrDeactivateMode(this.props.card)
        }

        const deleteCard = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.stopPropagation()
            event.preventDefault()
            this.props.delete(this.props.card)
        }

        const saveAs = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.stopPropagation()
            event.preventDefault()
            this.props.saveAs(this.props.card)
        }
        return !(this.props.mode.mode == Mode.Select) ? (
            <ToolBox>
                {/* no trigger activate/deactivate method */}
                {(this.props.card.type === 'webhook_trigger'||this.props.card.type === 'no_trigger') &&
                    this.props.card.deployed_status &&
                    this.props.card.deployed_status !== 'saved' && (
                        <SwitchWrapper>
                            <input
                                type="checkbox"
                                className="switch_1 automation_home_switch_without_hover"
                                checked={this.props.card.active_status === true}
                                onChange={update}
                                onClick={(e: any) => {
                                    update(e)
                                }}
                            />
                        </SwitchWrapper>
                    )}
                {/* no-trigger activate/deactivate method */}

                {/* trigger start/stop method */}
                {(this.props.card.type === 'trigger' || this.props.card.type === 'event_trigger') &&
                    this.props.card.deployed_status &&
                    this.props.card.deployed_status !== 'saved' && (
                        <span className="start_stop_triggers">
                            <label className="switch_triggers">
                                <input
                                    type="checkbox"
                                    id="togBtn"
                                    checked={true}
                                    onChange={()=>{}}
                                />
                                <div onClick={startOrStop} className="slider_trigger round_trigger">{this.props.card.active_status === true? "Stop" : "Start"}</div>
                            </label>
                        </span>
                    )}
                {/* trigger start/stop method */}

                {/* trigger Draft method */}
                {(this.props.view === FlowFilterE.Home || this.props.view === FlowFilterE.Draft) &&
                    this.props.card.deployed_status &&
                    this.props.card.deployed_status == 'saved' && (
                        <span className="start_stop_triggers">
                            <label className="switch_triggers">
                                <div className="slider_trigger_draft">
                                    <span>Draft</span>
                                </div>
                            </label>
                        </span>
                    )}
                {/* trigger Draft method */}

                <div className={(this.props.view === FlowFilterE.Home || this.props.view === FlowFilterE.Draft &&
                    this.props.card.deployed_status &&
                    this.props.card.deployed_status == 'saved') || 
                    (this.props.card.type === 'no_trigger' && this.props.card.deployed_status && 
                    this.props.card.deployed_status !== 'saved' ) || 
                    ((this.props.card.type === 'trigger' || this.props.card.type === 'event_trigger') && 
                    this.props.card.deployed_status &&
                    this.props.card.deployed_status !== 'saved')? "workflow_hover_menu": "workflow_hover_menu draft_page" }>      
                    <span className="home_edit" onClick={deleteCard}>
                        <Tooltip
                            className="target customTip "
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content={'Delete'}
                            distance={5}
                        >
                            <DeleteIcon />
                        </Tooltip>
                    </span>
                    <span className="home_edit" onClick={stop}>
                        <Tooltip
                            className="target customTip "
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content={'Edit'}
                            distance={5}
                        >
                            <EditIcon />
                        </Tooltip>
                    </span>
                    <span className="home_edit" onClick={saveAs}>
                        <Tooltip
                            className="target customTip "
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content={'Save as'}
                            distance={5}
                        >
                            <SaveAutomation />
                        </Tooltip>
                    </span>
                </div>  
            </ToolBox>
        ) : null
    }

    render() {
        let { modified_date, displayLabel, description } = this.props.card
        let card = this.props.card

        return (
            <div className="col-3 dialog_grids">
               {getProductId() === 'ASSISTANT' ? (
                   <div
                       className={'entity_cards_layer workflow_cards'}
                       onMouseOver={this.setShow}
                       onMouseLeave={this.clearShow}
                       onClick={() => this.props.click()}
                   >
   
                       <div className="dialog_top_section workflows">
                           <div className="dialog_icon">
                               {/* <span>
                                   <FlowIcon />
                               </span> */}
                               <span>
                                    {/* {(card.type==='no_trigger'||card.type===null)? <FlowCanvasIcons.StartIcon/> : card.type==='event_trigger'?<AppTriggerCardIconNew/> : <TimerIcon />} */}
                                    {(card.type==='no_trigger'||card.type===null)? <DialogIcon/> : card.type==='event_trigger'? <span className="triggerIcon"><AppTriggerCardIconNew/></span> : <span className="timerIocn"><TimerIcon /></span>}
                                </span>
                           </div>
                           <SelectBox
                               show={this.showSelection()}
                               isSelected={this.isSelected()}
                               handleSelected={this.handleSelected}
                           />
                           {/* <span className="autoamtion_connect_dialog_span">
                               {card.deployed_status == 'saved' || this.props.view === FlowFilterE.Home ? (
                                   <span className="trigger_changes"> */}
                           {/* <span>
                                           {card.type == 'trigger' || card.type == 'event_trigger' ? (
                                               <SubPanelIcons.Trigger />
                                           ) : (
                                               <SubPanelIcons.NoTrigger />
                                           )}
                                       </span> */}
                           {/* <span>
                                           {card.type == 'trigger' || card.type == 'event_trigger' ? 'Trigger' : 'NoTrigger'}
                                       </span> */}
                           {/* </span>
                               ) : null}
                           </span> */}
                           {this.renderToolBox()}
                       </div>
                       <div className="automation_card">
                           <div className="search-card-title flow_create_new">
                               {/* <div className="flow_card_left">
                                   <FlowIcon />
                               </div> */}
                               <div className="flow_card_right">
                                   <div>
                                       {Parser(displayLabel)}
                                       <p>{description}</p>
                                   </div>
                                   <div className="autoamtion_connect_dialog">
                                       {isDeployedNoTrigger(card) ? (
                                           <Fragment>
                                               <h6>
                                                   <span>
                                                       <LinkedDialogIcon />
                                                   </span>
                                                   Linked with Dialogs{' '}
                                                   <span className="automation_count_dilaog">
                                                       ({card.linkedDialogs ? card.linkedDialogs.length : 0})
                                                   </span>{' '}
                                               </h6>
                                           </Fragment>
                                       ) : (
                                           <h6></h6>
                                       )}{' '}
                                       {this.props.isApproval && (
                                           <div className="approval_btn">
                                               <label>Approval</label>
                                           </div>
                                       )}
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div className="flow_entity_span">
                           {/* <span className="entity_span">Last Updated:</span> */}
                           <span className="entity_span">{formatDateInCard(modified_date)}</span>
                           <span className="entity_span">|</span>
                           <span className="entity_span">{formatAMPM(modified_date)}</span>
                           {this.props.hasConnectionError && (
                               <span className="no_input_value">
                                   <div className="error_input_show">
                                       <Tooltip
                                           className="target customTip"
                                           zIndex={10000}
                                           arrowSize={8}
                                           tagName="label"
                                           content="Connection details are missing."
                                           distance={3}
                                       >
                                           <WarningAlertIcon />
                                       </Tooltip>
                                   </div>
                               </span>
                           )}
                       </div>
                   </div>
                ) : ( 
                    <div
                    className={ this.showSelection()? 'entity_cards_layer workflow_cards':'entity_cards_layer workflow_cards without_select'}
                    onMouseOver={this.setShow}
                    onMouseLeave={this.clearShow}
                    onClick={() => this.props.click()}
                    >
                    <SelectBox
                            show={this.showSelection()}
                            isSelected={this.isSelected()}
                            handleSelected={this.handleSelected}
                        />
                    <div 
                         className={ this.showSelection()? 'dialog_top_section workflows':'dialog_top_section workflows without_select'}
                    >
                        
                        <div className="dialog_icon">
                            {getProductId() === 'ASSISTANT' ? (
                                <span>
                                    {(card.type==='no_trigger'||card.type===null)? <FlowCanvasIcons.StartIcon/> : card.type==='event_trigger'?<AppTriggerCardIconNew/> : <TimerIcon />}
                                </span>
                            ) : (
                                <span className='bg_tree_flow'>
                                    {card.type==='webhook_trigger'? <WebhookCardIcon/> : card.type==='event_trigger'?<AppTriggerCardIconNew/> : <TimerIcon />}
                                </span>
                            )}   
                        </div>
                        <div className="dialog_icon_content">
                            {getProductId() === 'ASSISTANT' ? (
                                <p>
                                    {}
                                </p>
                            ) : (
                                <p>
                                    {card.type==='webhook_trigger'?"Webhook":"App Trigger"}
                                </p>
                            )}   
                        </div>
                        {/* <span className="autoamtion_connect_dialog_span">
                            {card.deployed_status == 'saved' || this.props.view === FlowFilterE.Home ? (
                                <span className="trigger_changes"> */}
                        {/* <span>
                                        {card.type == 'trigger' || card.type == 'event_trigger' ? (
                                            <SubPanelIcons.Trigger />
                                        ) : (
                                            <SubPanelIcons.NoTrigger />
                                        )}
                                    </span> */}
                        {/* <span>
                                        {card.type == 'trigger' || card.type == 'event_trigger' ? 'Trigger' : 'NoTrigger'}
                                    </span> */}
                        {/* </span>
                            ) : null}
                        </span> */}
                        {this.renderToolBox()}
                    </div>
                    <div className="automation_card">
                        <div className="search-card-title flow_create_new">
                            {/* <div className="flow_card_left">
                                <FlowIcon />
                            </div> */}
                            <div className="flow_card_right">
                                <div>
                                    {Parser(displayLabel)}
                                    <p>{description}</p>
                                </div>
                                <div className="autoamtion_connect_dialog">
                                    {isDeployedNoTrigger(card) ? (
                                        <Fragment>
                                            <h6>
                                                <span>
                                                    <LinkedDialogIcon />
                                                </span>
                                                Linked with Dialogs{' '}
                                                <span className="automation_count_dilaog">
                                                    ({card.linkedDialogs ? card.linkedDialogs.length : 0})
                                                </span>{' '}
                                            </h6>
                                        </Fragment>
                                    ) : (
                                        <h6></h6>
                                    )}{' '}
                                    {this.props.isApproval && (
                                        <div className="approval_btn">
                                            <label>Approval</label>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flow_entity_span">
                        {/* <span className="entity_span">Last Updated:</span> */}
                        <span className="entity_span">{formatDateInCard(modified_date)}</span>
                        <span className="entity_span">|</span>
                        <span className="entity_span">{formatAMPM(modified_date)}</span>
                        {this.props.hasConnectionError && (
                            <span className="no_input_value">
                                <div className="error_input_show">
                                    <Tooltip
                                        className="target customTip"
                                        zIndex={10000}
                                        arrowSize={8}
                                        tagName="label"
                                        content="Connection details are missing."
                                        distance={3}
                                    >
                                        <WarningAlertIcon />
                                    </Tooltip>
                                </div>
                            </span>
                        )}
                    </div>
                </div>
                ) }
                
            </div>

                
        )
    }
}
const ToolBox: React.FunctionComponent = ({ children }) => {
    return <div className="entity_hvr">{children}</div>
}
export const SwitchWrapper: React.FunctionComponent = ({ children }) => {
    return (
        <span className="home_edit show_hide _auto_switch">
            <div className="switch_box box_1">{children}</div>
        </span>
    )
}
{
    /* <div className={'entity_cards_layer'} onMouseOver={this.setShow} onMouseLeave={this.clearShow}>
                    <SelectBox
                        show={this.showSelection()}
                        isSelected={this.isSelected()}
                        handleSelected={this.handleSelected}
                    />
                    {this.renderToolBox()}
                    <div className="automation_card" onClick={e => this.props.click()}>
                        <div className="search-card-title flow_create_new">
                            {Parser(displayLabel)}
                            <p>{description}</p>
                        </div>
                        <div className="autoamtion_connect_dialog">
                            {card.deployed_status == 'saved' ? (
                                <h6 className="trigger_changes">
                                    <span>
                                        {card.type == 'trigger' ? (
                                            <SubPanelIcons.Trigger />
                                        ) : (
                                            <SubPanelIcons.NoTrigger />
                                        )}
                                    </span>
                                    <span>{card.type == 'trigger' ? 'Trigger' : 'NoTrigger'}</span>
                                </h6>
                            ) : null}
                            {isDeployedNoTrigger(card) ? (
                                <React.Fragment>
                                    <h6>
                                        <span>
                                            <LinkedDialogIcon />
                                        </span>
                                        Linked with Dialogs{' '}
                                        <span className="automation_count_dilaog">
                                            ({card.linkedDialogs ? card.linkedDialogs.length : 0})
                                        </span>{' '}
                                    </h6>
                                </React.Fragment>
                            ) : null}
                        </div>
                        <div className="flow_entity_span">
                            <span className="entity_span">Last Updated:</span>
                            <span className="entity_span">{formatDateInCard(modified_date)}</span>
                            <span className="entity_span">{formatAMPM(modified_date)}</span>
                        </div>
                    </div>
                </div> */
}
