/*eslint-disable*/
import * as React from 'react'

import { AddIcon, CloseIcon, DeleteIcon, MoveIcon, RemoveIcon, SearchIcon } from '@/common/Icons/Icons'
import { Header, HeaderChild } from '@/common/components/Header'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'

import { Mode, Modes } from './types'

export default class FlowEditorHeader extends React.Component<any, any> {
    state = { inputFocus: false }
    renderDelete = (mode: Modes) => {
        return mode.mode === Mode.Select ? (
            <button className="btn btn-primary" onClick={this.props.delete}>
                <DeleteIcon />
                <span>Delete</span>
            </button>
        ) : null
    }
    renderMove = (mode: Modes) => {
        return mode.mode === Mode.Select ? (
            <button className="btn btn-primary" onClick={this.props.move}>
                <MoveIcon />
                <span>Move to</span>
            </button>
        ) : null
    }

    renderRemove = (mode: Modes) => {
        return mode.mode === Mode.Select ? (
            <button className="btn btn-primary" onClick={this.props.ungroup}>
                <RemoveIcon />
                <span>Remove</span>
            </button>
        ) : null
    }

    renderHeader = (count: number, name: string) => {
        return (
            <div className="indent_left">
                <div className="indent_title">{name}</div>
                <div className="indent_sub_title">Catalog ({count})</div>
            </div>
        )
    }
    renderNewButton = (mode: Modes) => {
        return mode.mode === Mode.View || mode.mode === Mode.Add ? (
            <button className="btn btn-primary" onClick={this.props.addMode}>
                <AddIcon />
                <span>Create New</span>
            </button>
        ) : null
    }

    render() {
        let { mode, search, deselect } = this.props
        return (
            <Header>
                <HeaderChild type="first">
                    {this.renderHeader(this.props.flowCount, this.props.name)}
                    <div className="indent_right">
                        {mode.mode === Mode.Select ? (
                            <Button
                                primary
                                style={{ background: '#FFE5D3' }}
                                className="btn btn-primary btn_large"
                                onClick={deselect}
                            >
                                <label>
                                    Selected{' '}
                                    <b>{mode.cards.length > 9 ? mode.cards.length : `0${mode.cards.length}`}</b> Flows
                                </label>
                                <span>
                                    <CloseIcon />
                                </span>
                            </Button>
                        ) : (
                            this.renderNewButton(mode)
                        )}
                    </div>
                    {/* <div className="flow_indent_sub_title">
                        {this.props.name}
                    </div> */}
                </HeaderChild>
                <HeaderChild type="second">
                    {this.renderMove(mode)}
                    {this.renderDelete(mode)}
                    {this.renderRemove(mode)}
                    {!this.state.inputFocus ? (
                        <span className="search_icon_svg">
                            <input
                                type="search"
                                placeholder="Search"
                                value=""
                                onFocus={() => this.setState({ inputFocus: true })}
                            />
                            <SearchIcon />
                        </span>
                    ) : (
                        <span className="search_click">
                            <input
                                className="search_click"
                                type="search"
                                placeholder="Search"
                                autoFocus={true}
                                onChange={e => search(e.target.value)}
                            />
                            <span
                                onClick={() => {
                                    this.setState({ inputFocus: false })
                                }}
                            >
                                <CloseIcon />
                            </span>
                        </span>
                    )}
                </HeaderChild>
            </Header>
        )
    }
}
