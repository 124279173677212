import { CompositeDecorator, Editor as DraftEditor, EditorState, convertFromRaw } from 'draft-js'
/*eslint-disable*/
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import Tooltip from 'react-tooltip-lite'

import { fetchFlowNodes } from '@/DialogEditor/Canvas/Actions/ContextManager'
import { entityConditionT } from '@/DialogEditor/Canvas/Components/Nodes/RecogniseDialog'
//ICON IMPORT
import {
    AddIcon,
    CanvasDialogIcon,
    DailogAdaptiveIcon,
    DeleteIcon,
    DialogAttachmentIcon,
    DialogExitIcon,
    DialogFlowIcon,
    DialogJumpICon,
    DialogOptionIcon,
    DialogQaIcon,
    DialogResponseIcon,
    DialogSlotsIcon,
    DialogVariableIcon,
    DownArrowCircle,
    EditIcon,
    FlowCanvasContextIcons,
    MoreIcon,
    NextDetailICon,
    SubPanelIcons,
    CopyIocn,
    SuccessIcon
} from '@/common/Icons/Icons'
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons'
import WorkativIcon from '@/common/images/workativ_icon.svg'

import { traverseTree } from '@/Assistant/utils'
//COMMON COMPONENTS IMPORTS
import { DropDown } from '../../Common/Effective'
//TYPE IMPORTS
import {
    DialogueExitT,
    DialogueT,
    IfNodeT,
    JumpToSelectorT,
    JumpToT,
    OptionsValueT,
    ResponseNodeE,
    SlotExitConditions,
    slotsT
} from '../../Dialogue/kind'
import { DraftJST, ExpressionT, ExpressionTypeE, converterToEditor } from '../../Editor/Action'
import { EntityT } from '../../Entity/kind'
import { IntentT } from '../../Intent/kind'
//CONTENT IMPORT
import { DescendantContext, DialogueContext, DialogueContextT } from '../Context'
import { reverseCond } from '../Nodes/RecogniseDialog/action'
import { conditionT, conditionTYPES, operatorsT } from '../Nodes/RecogniseDialog/kind'
import { ActionT } from '../kind'
//TREE HELPERS IMPORTS
import {
    CreateAdaptiveNode,
    CreateAgentHandoffNode,
    CreateAttachmentNode,
    CreateAutomationNode,
    CreateIfNode,
    CreateOptionNode,
    CreateQuestionNode,
    CreateSlotContainerNode,
    CreateTextNode,
    CreateVariableNode,
    EditDialog,
    PasteCopiedFunction
} from './Helpers'
import PasteDialogsPopup from '@/Assistant/Dialogue/PasteDialogsPopup'
import { AssistantT } from '@/Assistant/Construction/kind'
import { CopyPasteContext } from '@/Assistant/Dialogue/CopyPasteContext'
import { findLinkEntities, Link } from '@/Assistant/Editor/Editor'
import { usePermissions } from '@/common/utils/auth-token-api-utils'

let Parser = require('html-react-parser')

const Descendants = ({
    dialogNode,
    isDetailView,
    isNull,
    assistants
}: {
    dialogNode: DialogueT
    isDetailView: boolean
    assistants:AssistantT[]
    isNull?: boolean
}) => {
    const [appendChild, setAppendChild] = useState<string | null>(null)

    const [addChildInLast, setAddChildInLast] = useState<string | null>(null)

    const [addChildInBetween, setAddChildInBetween] = useState<string | null>(null)

    const context = useContext(DialogueContext)
    const [hoverCopy,setHoverCopy] = useState(false)

    const [showPastePopup, setShowPastePopup] = useState(null)


    return (
        <>
       {showPastePopup!=null&&<PasteDialogsPopup dialog={showPastePopup} closePopup={()=>setShowPastePopup(null)} assistantID={context.assistantID} workspaceName={context.workspaceName} assistants={assistants}/>}

        <ul className={isDetailView ? 'tree zontal_lines dialog_detail_ul' : 'tree zontal_lines'} id="scroll_menu">
            <DescendantContext.Provider
                value={{
                    appendChild,
                    setAppendChild,
                    addChildInLast,
                    setAddChildInLast,
                    addChildInBetween,
                    setAddChildInBetween,

                    CreateTextNode: (previousSibling: ResponseNodeE, parent: IfNodeT) =>
                        CreateTextNode(context, previousSibling, parent),

                    CreateIfNode: (previousSibling: IfNodeT, parent: IfNodeT) =>
                        CreateIfNode(context, previousSibling, parent),

                    CreateOptionNode: (previousSibling: IfNodeT, parent: IfNodeT) =>
                        CreateOptionNode(context, previousSibling, parent),

                    CreateVariableNode: (previousSibling: IfNodeT, parent: IfNodeT) =>
                        CreateVariableNode(context, previousSibling, parent),

                    CreateAdaptiveNode: (previousSibling: IfNodeT, parent: IfNodeT) =>
                        CreateAdaptiveNode(context, previousSibling, parent),
                    CreateAttachmentNode: (previousSibling: IfNodeT, parent: IfNodeT) =>
                        CreateAttachmentNode(context, previousSibling, parent),

                    CreateQuestionNode: (previousSibling: IfNodeT, parent: IfNodeT) =>
                        CreateQuestionNode(context, previousSibling, parent),

                    CreateSlotContainerNode: (previousSibling: IfNodeT, parent: IfNodeT) =>
                        CreateSlotContainerNode(context, previousSibling, parent),
                    CreateAutomationNode: (previousSibling: IfNodeT, parent: IfNodeT) =>
                        CreateAutomationNode(context, previousSibling, parent),
                    CreateAgentHandoffNode: (previousSibling: IfNodeT, parent: IfNodeT) =>
                        CreateAgentHandoffNode(context, previousSibling, parent),
                    JumpNode: (dialogue: IfNodeT) =>
                        context.setPageState({
                            action: ActionT.JUMP_DIALOGUE,
                            data: dialogue
                        }),

                    ExitNode: (dialogue: IfNodeT) =>
                        context.setPageState({
                            action: ActionT.EXIT_DIALOGUE,
                            data: {
                                ...dialogue,
                                next_step: {
                                    exit_with_feedback: {
                                        message: {
                                            type: ExpressionTypeE.TEXT,
                                            value: ''
                                        }
                                    }
                                }
                            }
                        }),

                    EditResponse: (dialogue: ResponseNodeE) => {
                        EditDialog(context, dialogue)
                    },
                    PasteCopiedFunction:(dialogue: ResponseNodeE,previousSibling: ResponseNodeE, parent: IfNodeT,setCopiedData:Function)=>{
                        PasteCopiedFunction(context, dialogue,previousSibling, parent,setCopiedData)
                    }
                }}
            >
                <div className={isDetailView ? 'isdetailWrapper' : 'islistwrapper'}>
                    <li className={`first_add_node ${hoverCopy&& dialogNode.parent==null? "copy_border" : ""}`}>
                        <TreeRow
                            context={context}
                            dialogNode={dialogNode}
                            className="col-md-12 parent_node parent_node_left"
                            isDetailView={isDetailView}
                            index={Math.random()}
                            isNull={isNull}
                            hoverCopy={hoverCopy} 
                            setHoverCopy={setHoverCopy}
                            setShowPastePopup={setShowPastePopup}
                        />
                        <ul className={isDetailView ? 'dialog_detail_ul_parent' : 'dialog_list_ul_parent'}>
                            {context.fetchChild(dialogNode).map((node: IfNodeT, index: number) => (
                                <Presentation
                                    key={index}
                                    dialog={node}
                                    context={context}
                                    isDetailView={isDetailView}
                                    isNull={isNull}
                                    assistants={assistants}
                                    setShowPastePopup={setShowPastePopup}
                                    hoveringParent={hoverCopy && dialogNode.uuid==node.parent}
                                />
                            ))}
                        </ul>
                    </li>
                </div>
            </DescendantContext.Provider>
        </ul>
        </>
    )
}

const Presentation = ({
    dialog,
    context,
    isDetailView,
    isNull,
    assistants,
    setShowPastePopup,
    hoveringParent
}: {
    dialog: IfNodeT
    context: DialogueContextT
    isDetailView: boolean
    assistants:AssistantT[]
    setShowPastePopup: Function;
    hoveringParent:boolean
    isNull?: boolean
}) => {
    const [hoverCopy,setHoverCopy] = useState(false)

    return (
        <>
        <DialogueContext.Consumer>
            {({ fetchChild }) => (
                <li className={`${(hoverCopy&& dialog.parent) || hoveringParent? "copy_border" : ""}`}>
                    <input type="checkbox" checked onChange={() => {}} />
                    <TreeRow
                        dialogNode={dialog}
                        context={context}
                        className={!isDetailView ? 'col-md-12 parent_node' : 'col-md-12 parent_node parent_node_left'}
                        isDetailView={isDetailView}
                        index={Math.random()}
                        isNull={isNull}
                        hoverCopy={hoverCopy} 
                        setHoverCopy={setHoverCopy}
                        setShowPastePopup={setShowPastePopup}
                    />
                    <ul>
                        {fetchChild(dialog).map((node: IfNodeT, index: number) => (
                            <Presentation
                                key={index.toString()}
                                dialog={node}
                                context={context}
                                isDetailView={isDetailView}
                                isNull={isNull}
                                assistants={assistants}
                                setShowPastePopup={setShowPastePopup}
                                hoveringParent={hoveringParent||(hoverCopy && dialog.uuid==node.parent)}        
                            />
                        ))}
                    </ul>
                </li>
            )}
        </DialogueContext.Consumer>
        </>
    )
}

const needHook = (dialogNode: IfNodeT): boolean => {
    if (dialogNode.type == 'if_node')
        if (dialogNode.conditions === null) return false
        else if (dialogNode.next_step !== 'wait_for_user_input') return false
        else if (dialogNode.responses.length == 0) return true
    const hook =
        dialogNode.type === 'if_node'
            ? dialogNode.responses.map((node: ResponseNodeE) => {
                  if (node.type === 'option') return false
                  else if (node.type === 'automation') return false
                  else if (node.type === 'agent_handoff') return false
                  return true
              })
            : []
    return hook[hook.length - 1]
}

const needCustomNode = (dialogNode: IfNodeT): boolean => {
    const next_step: any = dialogNode.next_step
    if (typeof next_step === 'string') {
        return false
    } else if (typeof next_step !== 'string') {
        if (next_step && next_step.exit_with_feedback !== undefined) {
            return true
        } else if (next_step && next_step.exit !== undefined) {
            return true
        } else if (next_step && next_step.jump_to !== undefined) {
            return true
        }
    }
    return false
}

const customHookOpen = (value: string | null, dialogNode: IfNodeT): boolean => {
    if (value === null) return false
    const next_step: any = dialogNode.next_step
    if (typeof next_step !== 'string') {
        if (next_step.exit_with_feedback !== undefined) {
            return value === dialogNode.id + 'exit'
        } else if (next_step.exit !== undefined) {
            return value === dialogNode.id + 'exit'
        } else if (next_step.jump_to !== undefined) {
            return value === dialogNode.id + 'jump_to'
        }
    }
    return false
}

const isJumpNode = (dialogNode: IfNodeT): boolean => {
    const next_step: any = dialogNode.next_step
    if (typeof next_step === 'string') {
        return false
    } else if (typeof next_step !== 'string') {
        if (next_step.jump_to !== undefined) {
            return true
        }
    }
    return false
}
const isExitNode = (dialogNode: IfNodeT): boolean => {
    const next_step: any = dialogNode.next_step
    if (typeof next_step === 'string') {
        return false
    } else if (typeof next_step !== 'string') {
        if (next_step.exit_with_feedback !== undefined) {
            return true
        } else if (next_step.exit !== undefined) {
            return true
        }
    }
    return false
}

export function validateNode(
    context: { intents: IntentT[]; entities: EntityT[]; ismarketplacebot?: string },
    conditions: any
) {
    if (conditions && conditions.type && !context.ismarketplacebot) {
        switch (conditions.type) {
            case 'LogicalExpression':
                const array = traverseTree(conditions, []).map((cond: any) => validateNode(context, cond)) as any
                return array.some((cond: boolean) => cond == true)
            case 'IntentExpression':
            case 'Intent': {
                const isIntentExist = context.intents.filter(
                    intent => conditions.operator == 'SELECT' || intent.intent == (conditions.value || conditions.name)
                )
                return isIntentExist.length == 0
            }
            case 'EntityExpression':
            case 'Entity': {
                const isEntityExist = context.entities.filter(
                    intent => conditions.operator == 'SELECT' || intent.entity == (conditions.entity || conditions.name)
                )
                return isEntityExist.length == 0
            }
        }
    }
    return false
}

export function validateResponseNode(
    context: { intents: IntentT[]; entities: EntityT[]; ismarketplacebot?: string },
    dialogNode: ResponseNodeE,
    rootDialogs?: DialogueT[]
) {
    if (!context.ismarketplacebot) {
        switch (dialogNode.type) {
            case 'slot_container': {
                // const slots = dialogNode.slots
                //     .slice(0, 1)
                //     .filter((slot: any) =>
                //         slot.condition.type == 'IntentExpression'
                //             ? context.intents.filter(intent => intent.intent == slot.condition.value).length > 0
                //             : context.entities.filter(entity => entity.entity == slot.condition.entity).length > 0
                //     )

                // const slotsExitConditions = dialogNode.slots
                //     .slice(0, 1)

                //     .map((slot: any) => {
                //         const slotsExitConditions = slot.slot_exit_conditions.filter((cond: any) =>
                //             cond.condition.type == 'IntentExpression'
                //                 ? context.intents.filter(intent => intent.intent == cond.condition.value).length > 0
                //                 : context.entities.filter(entity => entity.entity == cond.condition.entity).length > 0
                //         )

                //         return slotsExitConditions.length != slot.slot_exit_conditions.length
                //     })

                // return (
                //     dialogNode.slots.slice(0, 1).length != slots.length || slotsExitConditions.some(arr => arr == true)
                // )
                const slots = (dialogNode.slots
                    .slice(0, 1)
                    .map((cond: any) => validateNode(context, cond.condition)) as Boolean[]).some(s => s == true)

                const slotsExitConditions = dialogNode.slots.slice(0, 1).map((slot: any) => {
                    const slotsExitConditions1 = slot.slot_exit_conditions.map((cond: any) =>
                        validateNode(context, cond.condition)
                    )
                    return (slotsExitConditions1 as Boolean[]).some(s => s == true)
                })
                return slots || slotsExitConditions.some(arr => arr == true)
            }
            case 'file_upload':
                // const file_exit_conditions = dialogNode.file_exit_conditions.filter((cond: any) =>
                //     cond.condition.type == 'IntentExpression'
                //         ? context.intents.filter(intent => intent.intent == cond.condition.value).length > 0
                //         : context.entities.filter(entity => entity.entity == cond.condition.entity).length > 0
                // )

                // return file_exit_conditions.length != dialogNode.file_exit_conditions.length

                const file_exit_conditions = (dialogNode.file_exit_conditions.map((cond: any) =>
                    validateNode(context, cond.condition)
                ) as Boolean[]).some(s => s == true)

                const checkJumptoDialogExist =  dialogNode.file_exit_conditions.map((cond:SlotExitConditions)=>{
                    if((cond.next_step as any).jump_to && rootDialogs){
                        const matchDialog: DialogueT = rootDialogs.filter(dia => dia.uuid == (cond.next_step as any).jump_to.to || dia.id == (cond.next_step as any).jump_to.to)[0]
                        return matchDialog != undefined ? false : true
                    }

                    return false
                })
                return file_exit_conditions || checkJumptoDialogExist.some((n)=>n == true)
            case 'automation':
                return dialogNode.automation == null
            case 'agent_handoff':
                return !dialogNode.parent || !dialogNode.agent_id
            default: {
                return false
            }
        }
    }
    return false
}

function returnOperator(operator: entityConditionT) {
    switch (operator.operator) {
        case operatorsT.IS_PRESENT:
            return (
                <>
                    <div className="get_detils_data">
                        <p>
                            <span className="value"> {operator.name} </span>
                            <span>
                                {' '}
                                <label> </label> is found
                            </span>
                        </p>
                    </div>
                </>
            )
        case operatorsT.IS_NOT_PRESENT:
            return (
                <>
                    <div className="get_detils_data">
                        <p>
                            <span className="value"> {operator.name} </span>
                            <span>
                                {' '}
                                <label> </label> is not found
                            </span>
                        </p>
                    </div>
                </>
            )
        case operatorsT.IS:
            return (
                <>
                    <p>
                        if <span className="value"> {operator.name} </span>
                    </p>
                    <p>
                        is <span className="value">{operator.value}</span>
                    </p>
                </>
            )
        case operatorsT.IS_NOT:
            return (
                <>
                    <p>
                        if <span className="value"> {operator.name} </span>
                    </p>
                    <p>
                        is not <span className="value"> {operator.value}</span>
                    </p>
                </>
            )

        default:
            return ''
    }
}

const TreeRow = ({
    dialogNode,
    className,
    context,
    isDetailView,
    index,
    isNull,
    hoverCopy, 
    setHoverCopy,
    setShowPastePopup
}: {
    dialogNode: IfNodeT
    className: string
    context: DialogueContextT
    isDetailView: boolean
    index: number
    hoverCopy:boolean
    setHoverCopy:Function
    setShowPastePopup:Function
    isNull?: boolean
}) => {

    const [showCopied,setShowCopied] = useState(false)
    const {workspaceName,assistant} = context
    const writePermission = usePermissions(workspaceName,`CHAT_BOT_MODULE/BOT/${assistant.id}/SUB_DIALOG_NODE`,"WRITE")
    const deletePermission = usePermissions(workspaceName,`CHAT_BOT_MODULE/BOT/${assistant.id}/SUB_DIALOG_NODE`,"DELETE")
    const rootWritePermission = usePermissions(workspaceName,`CHAT_BOT_MODULE/BOT/${assistant.id}/ROOT_DIALOG_NODE/${dialogNode.uuid}/CONDITION`,"WRITE")
    const rootDeletePermission = usePermissions(workspaceName,`CHAT_BOT_MODULE/BOT/${assistant.id}/ROOT_DIALOG_NODE/${dialogNode.uuid}/CONDITION`,"DELETE")

    const [hoverCopyForExitOrJump,setHoverCopyForExitOrJump] = useState(false)
    return (
        <label className={`tree_label `}>
            <div className="first">
                {isDetailView ? (
                    <div
                        className="first_parent_node"
                        onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                        }}
                    >
                        <div
                            className={`${className} ${
                                isDetailView ? 'dialog_detail_account dialog_detail_box_shadow' : ''
                            }`}
                        >
                            <DialogCard isNull={isNull} dialog={dialogNode} isDetailView={isDetailView} index={index} hoverCopy={hoverCopy} setHoverCopy={setHoverCopy} setShowPastePopup={setShowPastePopup}/>
                        </div>
                    </div>
                ) : (
                    <div className={`${className}`}>
                        <DialogCard isNull={isNull} dialog={dialogNode} isDetailView={isDetailView} index={index} hoverCopy={hoverCopy} setHoverCopy={setHoverCopy}setShowPastePopup={setShowPastePopup}/>
                    </div>
                )}
                {validateNode(context, dialogNode.conditions) && (
                    <div
                        className={
                            isDetailView ? 'error_input_show parent one' : 'error_input_show parent _list_view one'
                        }
                    >
                        <WarningAlertIcon />
                        <span className="error_menu_hvr">Input data is missing.</span>
                    </div>
                )}
                {dialogNode.responses &&
                    dialogNode.responses.length > 0 &&
                    dialogNode.responses.map((node: ResponseNodeE, index: number) => (
                        <div
                            key={index}
                            className="dialog_tree_render "
                            onClick={e => {
                                e.stopPropagation()
                                e.preventDefault()
                            }}
                        >
                            <MedianHook parent={dialogNode} dialog={node} />
                            <DialogNode
                                dialog={node}
                                context={context}
                                isDetailView={isDetailView}
                                index={Math.random()}
                                node={dialogNode}
                            />
                        </div>
                    ))}
                {needCustomNode(dialogNode) ?
                 <>
                        <DescendantContext.Consumer>
                            {({
                                addChildInBetween,
                                setAddChildInBetween,
                                CreateTextNode,
                                CreateQuestionNode,
                                CreateSlotContainerNode,
                                JumpNode,
                                CreateAdaptiveNode,
                                CreateAttachmentNode,
                                CreateVariableNode,
                                PasteCopiedFunction
                            }) => (
                                <div
                                    className="dialog_tree_render "
                                    onClick={e => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                    }}
                                >
                                    {((dialogNode.parent==null && rootWritePermission.value)||(writePermission.value)) ? (
                                        <>
                                        {!customHookOpen(addChildInBetween, dialogNode) ? (
                                            <div className={'dialog_tree_next'}>
                                                <div className="dialog_tree_next_toggle">
                                                    <div className="downarrow_hide_right">
                                                        <NextDetailICon />
                                                    </div>
                                                    <div
                                                        className="dialog_tree_next_hover"
                                                        onClick={e => {
                                                            const next_step: any = dialogNode.next_step
                                                            if (typeof next_step !== 'string') {
                                                                if (next_step.exit_with_feedback !== undefined) {
                                                                    setAddChildInBetween(dialogNode.id + 'exit')
                                                                } else if (next_step.exit !== undefined) {
                                                                    setAddChildInBetween(dialogNode.id + 'exit')
                                                                } else if (next_step.jump_to !== undefined) {
                                                                    setAddChildInBetween(dialogNode.id + 'jump_to')
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        <div className="child_node_button">
                                                            <div className="parent_node_no">
                                                                <span className="parent_svg">
                                                                    <AddIcon />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <React.Fragment>
                                                <div className={'dialog_tree_next'}>
                                                    <div className="dialog_tree_next_toggle">
                                                        <div className="dialog_tree_next_hover" style={{ display: 'block' }}>
                                                            <div className="child_node_button">
                                                                <div className="parent_node_no">
                                                                    <span
                                                                        className="parent_svg"
                                                                        onClick={e => setAddChildInBetween(null)}
                                                                    >
                                                                        <AddIcon />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <CopyPasteContext.Consumer>
                                                {({copiedData,setCopiedData})=>
                                                <DropDown
                                                    isOpen={customHookOpen(addChildInBetween, dialogNode)}
                                                    onOuterClick={() => setAddChildInBetween(null)}
                                                >
                                                    <div className="dialog_tree_render dropdown_content plus_dropdown_list dialog_options_dynamic">
                                                        <div className="drpdown_dialog">
                                                        {copiedData && copiedData.type != 'agent_handoff' && copiedData.type != 'option' && copiedData.type != 'automation'&& !(copiedData as any).next_step&&
                                                        <>
                                                        <div
                                                                className="drodown_li_span"
                                                                onClick={e => {
                                                                    // console.log('drpdown_dialog click 1034')
                                                                    const previousSibling = dialogNode.responses
                                                                    .map((e: ResponseNodeE) => e)
                                                                    .pop()
                                                                    PasteCopiedFunction(copiedData,previousSibling ? previousSibling : null, dialogNode,setCopiedData)
                                                                    e.preventDefault()
                                                                    e.stopPropagation()
                                                                }}
                                                            >
                                                                <span className="copy_paste_icon">
                                                                    <CopyIocn />
                                                                </span>
                                                                <label>Paste the copied function</label>
                                                            </div>
                                                            </>
                                                            }
                                                            <div
                                                                className="drodown_li_span"
                                                                onClick={() => {
                                                                    // console.log('drpdown_dialog click 494')
                                                                    const previousSibling = dialogNode.responses
                                                                        .map((e: ResponseNodeE) => e)
                                                                        .pop()
                                                                    setAddChildInBetween(null)
                                                                    setCopiedData(null)
                                                                    CreateTextNode(
                                                                        previousSibling ? previousSibling : null,
                                                                        dialogNode
                                                                    )
                                                                }}
                                                            >
                                                                <span>
                                                                    {isExitNode(dialogNode) ? (
                                                                        <DialogExitIcon />
                                                                    ) : (
                                                                        <DialogJumpICon />
                                                                    )}
                                                                </span>
                                                                <label>Send message</label>
                                                            </div>

                                                            <div
                                                                className="drodown_li_span"
                                                                onClick={() => {
                                                                    // console.log('drpdown_dialog click 494')
                                                                    const previousSibling = dialogNode.responses
                                                                        .map((e: ResponseNodeE) => e)
                                                                        .pop()
                                                                    setAddChildInBetween(null)
                                                                    setCopiedData(null)
                                                                    CreateQuestionNode(
                                                                        previousSibling ? previousSibling : null,
                                                                        dialogNode
                                                                    )
                                                                }}
                                                            >
                                                                <span>
                                                                    <DialogQaIcon />
                                                                </span>
                                                                <label>Ask question</label>
                                                            </div>
                                                            <div
                                                                className="drodown_li_span"
                                                                onClick={() => {
                                                                    // console.log('drpdown_dialog click 494')
                                                                    const previousSibling = dialogNode.responses
                                                                        .map((e: ResponseNodeE) => e)
                                                                        .pop()
                                                                    setAddChildInBetween(null)
                                                                    setCopiedData(null)
                                                                    CreateSlotContainerNode(
                                                                        previousSibling ? previousSibling : null,
                                                                        dialogNode
                                                                    )
                                                                }}
                                                            >
                                                                <span>
                                                                    <DialogSlotsIcon />
                                                                </span>
                                                                <label>Verify user details</label>
                                                            </div>
                                                            <div
                                                                className="drodown_li_span"
                                                                onClick={() => {
                                                                    const previousSibling = dialogNode.responses
                                                                        .map((e: ResponseNodeE) => e)
                                                                        .pop()
                                                                    setAddChildInBetween(null)
                                                                    setCopiedData(null)
                                                                    CreateAdaptiveNode(
                                                                        previousSibling ? previousSibling : null,
                                                                        dialogNode
                                                                    )
                                                                }}
                                                            >
                                                                <span>
                                                                    <DailogAdaptiveIcon />
                                                                </span>
                                                                <label>Adaptive card</label>
                                                            </div>
                                                            <div
                                                                className="drodown_li_span"
                                                                onClick={() => {
                                                                    const previousSibling = dialogNode.responses
                                                                        .map((e: ResponseNodeE) => e)
                                                                        .pop()
                                                                    setAddChildInBetween(null)
                                                                    setCopiedData(null)
                                                                    CreateVariableNode(
                                                                        previousSibling ? previousSibling : null,
                                                                        dialogNode
                                                                    )
                                                                }}
                                                            >
                                                                <span>
                                                                    <DialogVariableIcon />
                                                                </span>
                                                                <label>Custom variable</label>
                                                            </div>

                                                            <div
                                                                className="drodown_li_span"
                                                                onClick={() => {
                                                                    const previousSibling = dialogNode.responses
                                                                        .map((e: ResponseNodeE) => e)
                                                                        .pop()
                                                                    setAddChildInBetween(null)
                                                                    setCopiedData(null)
                                                                    CreateAttachmentNode(
                                                                        previousSibling ? previousSibling : null,
                                                                        dialogNode
                                                                    )
                                                                }}
                                                            >
                                                                <span>
                                                                    <DialogAttachmentIcon />
                                                                </span>
                                                                <label>Upload attachment</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </DropDown>
                                                }
                                                </CopyPasteContext.Consumer>
                                            </React.Fragment>
                                        )}
                                        </>
                                    ): (
                                    <div className={'dialog_tree_next'}>
                                    <div className="dialog_tree_next_toggle">
                                        <div className="downarrow_hide_right">
                                            <NextDetailICon />
                                        </div>
                                    </div>
                                    </div>
                                )}
                                <CopyPasteContext.Consumer>
                                {({copiedData,setCopiedData})=>
                                    <DialogueContext.Consumer>
                                        {({ setPageState }) => (
                                            <>
                                                {!isDetailView ? (
                                                    <div className={'child_node'}>
                                                        <>
                                                            <div
                                                                className={'child_node_no'}
                                                                onClick={() => {
                                                                    if (isJumpNode(dialogNode)) {
                                                                        JumpNode(dialogNode)
                                                                    } else if (isExitNode(dialogNode)) {
                                                                        setPageState({
                                                                            action: ActionT.EXIT_DIALOGUE,
                                                                            data: dialogNode as DialogueExitT
                                                                        })
                                                                    }
                                                                }}
                                                            >
                                                                <span>
                                                                    <DialogJumpICon />
                                                                </span>
                                                            </div>
                                                            <h4
                                                                onClick={() => {
                                                                    if (isJumpNode(dialogNode)) {
                                                                        JumpNode(dialogNode)
                                                                    } else if (isExitNode(dialogNode)) {
                                                                        setPageState({
                                                                            action: ActionT.EXIT_DIALOGUE,
                                                                            data: dialogNode as DialogueExitT
                                                                        })
                                                                    }
                                                                }}
                                                            >
                                                                {isJumpNode(dialogNode) ? 'Jump to' : null}
                                                                {isExitNode(dialogNode) ? 'Exit conversation' : null}
                                                            </h4>
                                                        </>
                                                    </div>
                                                ) : (
                                                    (isJumpNode(dialogNode) || isExitNode(dialogNode)) && (
                                                        <div
                                                            className="respond_dialog_page"
                                                            onClick={
                                                                isJumpNode(dialogNode)
                                                                    ? () => JumpNode(dialogNode)
                                                                    : isExitNode(dialogNode)
                                                                    ? () =>
                                                                        setPageState({
                                                                            action: ActionT.EXIT_DIALOGUE,
                                                                            data: dialogNode as DialogueExitT
                                                                        })
                                                                    : () => {}
                                                            }
                                                        >
                                                            <div className="col-md-12 parent_node parent_node_left dialog_detail_account">
                                                                <div className={`dialog_detail_parent_node ${hoverCopyForExitOrJump? "copy_border_for_individual" : "" }`}>
                                                                    {returnUI(
                                                                        {
                                                                            ...dialogNode,
                                                                            type: isJumpNode(dialogNode) ? 'jump' : 'exit'
                                                                        },
                                                                        isJumpNode(dialogNode)
                                                                            ? () => JumpNode(dialogNode)
                                                                            : isExitNode(dialogNode)
                                                                            ? () =>
                                                                                setPageState({
                                                                                    action: ActionT.EXIT_DIALOGUE,
                                                                                    data: dialogNode as DialogueExitT
                                                                                })
                                                                            : () => {},
                                                                        Math.random(),
                                                                        context
                                                                    )}
                                                                </div>
                                                                <EditDelete
                                                                    isDetailView={isDetailView}
                                                                    onEdit={() => {
                                                                        if (isJumpNode(dialogNode)) {
                                                                            JumpNode(dialogNode)
                                                                        } else if (isExitNode(dialogNode)) {
                                                                            setPageState({
                                                                                action: ActionT.EXIT_DIALOGUE,
                                                                                data: dialogNode as DialogueExitT
                                                                            })
                                                                        }
                                                                    }}
                                                                    onDelete={() => {
                                                                        if (isJumpNode(dialogNode)) {
                                                                            setPageState({
                                                                                action: ActionT.DELETE_JUMP_NODE,
                                                                                data: dialogNode
                                                                            })
                                                                        } else if (isExitNode(dialogNode)) {
                                                                            setPageState({
                                                                                action: ActionT.DELETE_EXIT_NODE,
                                                                                data: dialogNode
                                                                            })
                                                                        }
                                                                    }}
                                                                    dontShowCopyIcon
                                                                    setCopiedData={()=>{}}
                                                                    setHoverCopy={setHoverCopyForExitOrJump}
                                                                    permission={{rootWrite:rootWritePermission.value,rootDelete:rootDeletePermission.value,subWrite:writePermission.value,subDelete:deletePermission.value} as {
                                                                        rootWrite: boolean ;
                                                                        rootDelete: boolean ;
                                                                        subWrite: boolean ;
                                                                        subDelete: boolean ;
                                                                    }}
                                                                    node={dialogNode}
                                            
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                                {!isJumpNode(dialogNode) && !isExitNode(dialogNode) && (
                                                    <EditDelete
                                                        isDetailView={isDetailView}
                                                        onEdit={() => {
                                                            if (isJumpNode(dialogNode)) {
                                                                JumpNode(dialogNode)
                                                            } else if (isExitNode(dialogNode)) {
                                                                setPageState({
                                                                    action: ActionT.EXIT_DIALOGUE,
                                                                    data: dialogNode as DialogueExitT
                                                                })
                                                            }
                                                        }}
                                                        onDelete={() => {
                                                            if (isJumpNode(dialogNode)) {
                                                                setPageState({
                                                                    action: ActionT.DELETE_JUMP_NODE,
                                                                    data: dialogNode
                                                                })
                                                            } else if (isExitNode(dialogNode)) {
                                                                setPageState({
                                                                    action: ActionT.DELETE_EXIT_NODE,
                                                                    data: dialogNode
                                                                })
                                                            }
                                                        }}
                                                        setCopiedData={()=>setCopiedData(dialogNode)}
                                                        setHoverCopy={setHoverCopy}
                                                        permission={{rootWrite:rootWritePermission.value,rootDelete:rootDeletePermission.value,subWrite:writePermission.value,subDelete:deletePermission.value} as {
                                                            rootWrite: boolean ;
                                                            rootDelete: boolean ;
                                                            subWrite: boolean ;
                                                            subDelete: boolean ;
                                                        }}
                                                        node={dialogNode}
                    
                                                    />
                                                )}
                                            </>
                                        )}
                                    </DialogueContext.Consumer>
                                    }
                                </CopyPasteContext.Consumer>
                                </div>
                            )}
                        </DescendantContext.Consumer>
                </>
                :null
                }

                {needHook(dialogNode) ? (
                    <FinalHook dialog={dialogNode} showDropDownInitially={dialogNode.responses.length == 0} />
                ) : null}
            </div>
        </label>
    )
}

interface DialogCardP {
    dialog: IfNodeT
    isDetailView: boolean
    index: number
    hoverCopy:boolean
    setHoverCopy:Function
    setShowPastePopup:Function
    isNull?: boolean
}

const DialogCard = ({ dialog, isDetailView, index, isNull, hoverCopy, setHoverCopy,setShowPastePopup }: DialogCardP) => {
    const { fetchChild, assistant, workspaceName } = useContext(DialogueContext)

    const [showCopied,setShowCopied] = useState(false)

    const writePermission = usePermissions(workspaceName,`CHAT_BOT_MODULE/BOT/${assistant.id}/SUB_DIALOG_NODE`,"WRITE")
    const deletePermission = usePermissions(workspaceName,`CHAT_BOT_MODULE/BOT/${assistant.id}/SUB_DIALOG_NODE`,"DELETE")
    const rootWritePermission = usePermissions(workspaceName,`CHAT_BOT_MODULE/BOT/${assistant.id}/ROOT_DIALOG_NODE/${dialog.uuid}/CONDITION`,"WRITE")
    const rootDeletePermission = usePermissions(workspaceName,`CHAT_BOT_MODULE/BOT/${assistant.id}/ROOT_DIALOG_NODE/${dialog.uuid}/CONDITION`,"DELETE")

    return (
        <DialogueContext.Consumer>
            {({ setPageState }) => (
                <DescendantContext.Consumer>
                    {({ appendChild, setAppendChild, CreateIfNode }) => (
                        <React.Fragment>
                            {!isDetailView ? (
                                <>
                                    {(writePermission.value) && 
                                        <>
                                        {appendChild == null || appendChild != dialog.id ? (
                                            <span onClick={() => setAppendChild(dialog.id)}>
                                                <MoreIcon />
                                            </span>
                                        ) :(
                                            <DropDown
                                                isOpen={appendChild == dialog.id}
                                                onOuterClick={() => setAppendChild(null)}
                                            >
                                                <span>
                                                    <div className="dropdown_right dilaog_addchild_hover">
                                                        <ul>
                                                            <li
                                                                onClick={() => {
                                                                    const childrens = fetchChild(dialog)
                                                                    const previousSibling = childrens.length
                                                                        ? childrens.pop()
                                                                        : null
                                                                    CreateIfNode(previousSibling, dialog)
                                                                }}
                                                            >
                                                                Add child dialog
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </span>
                                            </DropDown>
                                        )}
                                    </>
                                    }
                                    {(() => {
                                        if (dialog.conditions == null) return true
                                        // else if (dialog.conditions.type === 'WelcomeExpression') return false
                                        else if (dialog.conditions.type === 'FallBackExpression') return false
                                        return true
                                    })() ? (
                                        <>
                                            {(dialog.uuid !== assistant.welcome_node) &&
                                                <>
                                                {((dialog.parent==null && rootWritePermission.value)||(writePermission.value)) && 
                                                    <div className="flow_buttons_icons_content" 
                                                        onMouseEnter={()=>{
                                                            setHoverCopy(true)
                                                        }}
                                                        onMouseLeave={()=>{
                                                            setHoverCopy(false)
                                                        }}
                                                        onClick={(e)=>{
                                                            setShowPastePopup(dialog)
                                                            setShowCopied(true)
                                                            setTimeout(()=>setShowCopied(false),1000)
                                                            e.preventDefault()
                                                            e.stopPropagation()

                                                        }}
                                                        >
                                                            <Tooltip
                                                                className="target customTip "
                                                                zIndex={10000}
                                                                arrowSize={8}
                                                                tagName="span"
                                                                content={'Copy'}
                                                                distance={5}
                                                            >
                                                                <CopyIocn />
                                                            </Tooltip>
                                                    </div>
                                                }
                                                {((dialog.parent==null && rootDeletePermission.value)||(deletePermission.value))&& <span
                                                    onClick={() =>
                                                        setPageState({ action: ActionT.DELETE_DIALOGUE_NODE, data: dialog })
                                                    }
                                                >
                                                    <DeleteIcon />
                                                </span>}
                                                </>
                                            }

                                            {((dialog.parent==null && rootWritePermission.value)||(writePermission.value)) &&
                                                <span
                                                    onClick={() =>
                                                        setPageState({ action: ActionT.IF_DIALOGUE, data: dialog })
                                                    }
                                                >
                                                    <EditIcon />
                                                </span>
                                            }
                                        </>
                                    ) : null}
                                </>
                            ) : (
                                <>
                                    <div className="flow_buttons ">
                                        <div className="flow_buttons_icons ">
                                            {(writePermission.value) &&
                                            <>
                                            <div
                                                className="flow_buttons_icons_content"
                                                onClick={e => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    setAppendChild(dialog.id)
                                                }}
                                            >
                                                <MoreIcon />
                                            </div>
                                            <DropDown
                                                isOpen={appendChild == dialog.id}
                                                onOuterClick={() => setAppendChild(null)}
                                            >
                                                <span>
                                                    <div className="dropdown_right dilaog_addchild_hover">
                                                        <ul>
                                                            <li
                                                                onClick={e => {
                                                                    e.preventDefault()
                                                                    e.stopPropagation()

                                                                    const childrens = fetchChild(dialog)
                                                                    const previousSibling = childrens.length
                                                                        ? childrens.pop()
                                                                        : null
                                                                    CreateIfNode(previousSibling, dialog)
                                                                }}
                                                            >
                                                                Add child dialog
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </span>
                                            </DropDown>
                                            </>
                                            }
                                            {(() => {
                                                if (dialog.conditions == null) return true
                                                // else if (dialog.conditions.type === 'WelcomeExpression') return false
                                                else if (dialog.conditions.type === 'FallBackExpression') return false
                                                return true
                                            })() ? (
                                                <>
                                                {(dialog.uuid !== assistant.welcome_node)&&
                                                <>
                                                {((dialog.parent==null && rootWritePermission.value)||(writePermission.value)) &&<div className="flow_buttons_icons_content" 
                                                    onMouseEnter={()=>{
                                                        setHoverCopy(true)
                                                    }}
                                                    onMouseLeave={()=>{
                                                        setHoverCopy(false)
                                                    }}
                                                    onClick={(e)=>{
                                                        setShowPastePopup(dialog)
                                                        setShowCopied(true)
                                                        setTimeout(()=>setShowCopied(false),1000)
                                                        e.preventDefault()
                                                        e.stopPropagation()

                                                    }}
                                                    >
                                                        <Tooltip
                                                            className="target customTip "
                                                            zIndex={10000}
                                                            arrowSize={8}
                                                            tagName="span"
                                                            content={'Copy'}
                                                            distance={5}
                                                        >
                                                            <CopyIocn />
                                                        </Tooltip>
                                                        
                                                    </div>
                                                }
                                                    {((dialog.parent==null ? rootDeletePermission.value : deletePermission.value))&&<div
                                                        className="flow_buttons_icons_content"
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            setPageState({
                                                                action: ActionT.DELETE_DIALOGUE_NODE,
                                                                data: dialog
                                                            })
                                                        }}
                                                    >
                                                        <Tooltip
                                                            className="target customTip "
                                                            zIndex={10000}
                                                            arrowSize={8}
                                                            tagName="span"
                                                            content={'Delete'}
                                                            distance={5}
                                                        >
                                                            <DeleteIcon />
                                                        </Tooltip>
                                                        
                                                    </div>}
                                                    </>
                                                    }
                                                    {((dialog.parent==null && rootWritePermission.value)||(writePermission.value)) && <div
                                                        className="flow_buttons_icons_content"
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            setPageState({ action: ActionT.IF_DIALOGUE, data: dialog })
                                                        }}
                                                    >
                                                        <Tooltip
                                                            className="target customTip "
                                                            zIndex={10000}
                                                            arrowSize={8}
                                                            tagName="span"
                                                            content={'Edit'}
                                                            distance={5}
                                                        >
                                                            <EditIcon />
                                                        </Tooltip>
                                                        
                                                    </div>}
                                                </>
                                            ) : null}                                            
                                        </div>                                       
                                    </div>
                                     {showCopied &&
                                        <span className="copy_function_copied for_canvas_dialog">
                                                <SuccessIcon />
                                                <p>Copied</p>
                                        </span>
                                     }
                                </>
                            )}

                            {isDetailView ? (
                                <DialogContent
                                    isNull={isNull}
                                    dialog={dialog}
                                    setPageState={setPageState}
                                    index={index}
                                />
                            ) : (
                                <>
                                    <div
                                        className="parent_node_no"
                                        onClick={() => {
                                            // if (dialog.conditions && dialog.conditions.type === 'WelcomeExpression')
                                            //     return false
                                            // else
                                             if (
                                                dialog.conditions &&
                                                dialog.conditions.type === 'FallBackExpression'
                                            )
                                            return false
                                            return setPageState({ action: ActionT.IF_DIALOGUE, data: dialog })
                                        }}
                                    >
                                        <span className="parent_svg">
                                            <SubPanelIcons.BotIcon />
                                        </span>
                                    </div>

                                    <h4
                                        onClick={() => {
                                            // if (dialog.conditions && dialog.conditions.type === 'WelcomeExpression')
                                            //     return false
                                            // else
                                             if (
                                                dialog.conditions &&
                                                dialog.conditions.type === 'FallBackExpression'
                                            )
                                                return false
                                            return setPageState({ action: ActionT.IF_DIALOGUE, data: dialog })
                                        }}
                                    >
                                        {dialog.title}
                                    </h4>
                                </>
                            )}
                        </React.Fragment>
                    )}
                </DescendantContext.Consumer>
            )}
        </DialogueContext.Consumer>
    )
}

interface MedianHookP {
    dialog: ResponseNodeE
    parent: IfNodeT
}

function scroller() {
    const ul = document.getElementById('scroll_menu')

    if (ul) {
        let scroll = ul as HTMLElement
        var maxScroll = scroll.scrollWidth - ul.clientWidth // required to stop
        if (scroll.scrollLeft > 50) {
            scroll.scrollLeft += 270

            // window.setTimeout(scroller, 0)
            // // scroller()
        }
    }
}

const MedianHook = ({ dialog, parent }: MedianHookP) =>{
    const { assistant, workspaceName } = useContext(DialogueContext)

    const rootWritePermission = usePermissions(workspaceName,`CHAT_BOT_MODULE/BOT/${assistant.id}/ROOT_DIALOG_NODE/${parent.uuid}/RESPONSE_NODE`,"WRITE")
    const writePermission = usePermissions(workspaceName,`CHAT_BOT_MODULE/BOT/${assistant.id}/SUB_DIALOG_NODE/${dialog.id}/RESPONSE_NODE`,"WRITE")

    return ((parent.parent==null && rootWritePermission.value)||(writePermission.value))? (
    <DescendantContext.Consumer>
        {({
            addChildInBetween,
            setAddChildInBetween,
            CreateTextNode,
            CreateQuestionNode,
            CreateSlotContainerNode,
            CreateAdaptiveNode,
            CreateAttachmentNode,
            CreateVariableNode,
            PasteCopiedFunction
        }) =>
            addChildInBetween !== dialog.id ? (
                <div className={'dialog_tree_next'}>
                    <div className="dialog_tree_next_toggle">
                        <div className="downarrow_hide_right">
                            <NextDetailICon />
                        </div>
                        <div
                            className="dialog_tree_next_hover"
                            onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                setAddChildInBetween(dialog.id)
                            }}
                        >
                            <div className="child_node_button">
                                <div className="parent_node_no">
                                    <span className="parent_svg">
                                        <AddIcon />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <CopyPasteContext.Consumer>
                {({copiedData,setCopiedData})=>
                <React.Fragment>
                    <div className={'dialog_tree_next'}>
                        <div className="dialog_tree_next_toggle">
                            <div className="dialog_tree_next_hover" style={{ display: 'block' }}>
                                <div className="child_node_button">
                                    <div className="parent_node_no">
                                        <span
                                            className="parent_svg"
                                            onClick={e => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                setAddChildInBetween(null)
                                            }}
                                        >
                                            <AddIcon />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DropDown isOpen={addChildInBetween == dialog.id} onOuterClick={() => setAddChildInBetween(null)}>
                        <div className="dialog_tree_render dropdown_content plus_dropdown_list dialog_options_dynamic">
                            <div className="drpdown_dialog">
                            {copiedData && copiedData.type != 'agent_handoff' && copiedData.type != 'option' && copiedData.type != 'automation'&& !(copiedData as any).next_step&&
                            <div
                                    className="drodown_li_span"
                                    onClick={e => {
                                        // console.log('drpdown_dialog click 1034')
                                        const previousSibling = parent.responses
                                        .filter((e: ResponseNodeE) => e.id == dialog.previous_sibling)
                                        .pop()
                                        PasteCopiedFunction(copiedData,previousSibling ? previousSibling : null, parent,setCopiedData)

                                        e.preventDefault()
                                        e.stopPropagation()
                                    }}
                                >
                                    <span className="copy_paste_icon">
                                        <CopyIocn />
                                    </span>
                                    <label>Paste the copied function</label>
                                </div>
                                }
                                <div
                                    className="drodown_li_span"
                                    onClick={e => {
                                        const previousSibling = parent.responses
                                            .filter((e: ResponseNodeE) => e.id == dialog.previous_sibling)
                                            .pop()
                                        setAddChildInBetween(null)
                                        setCopiedData(null)
                                        CreateTextNode(previousSibling ? previousSibling : null, parent)
                                        e.preventDefault()
                                        e.stopPropagation()
                                    }}
                                >
                                    <span>
                                        <DialogResponseIcon />
                                    </span>
                                    <label>Send message</label>
                                </div>

                                <div
                                    className="drodown_li_span"
                                    onClick={e => {
                                        // console.log('drpdown_dialog click 1034')
                                        const previousSibling = parent.responses
                                            .filter((e: ResponseNodeE) => e.id == dialog.previous_sibling)
                                            .pop()
                                        setAddChildInBetween(null)
                                        setCopiedData(null)
                                        CreateQuestionNode(previousSibling ? previousSibling : null, parent)
                                        e.preventDefault()
                                        e.stopPropagation()
                                    }}
                                >
                                    <span>
                                        <DialogQaIcon />
                                    </span>
                                    <label>Ask question</label>
                                </div>
                                <div
                                    className="drodown_li_span"
                                    onClick={e => {
                                        // console.log('drpdown_dialog click 1050')
                                        const previousSibling = parent.responses
                                            .filter((e: ResponseNodeE) => e.id == dialog.previous_sibling)
                                            .pop()
                                        setAddChildInBetween(null)
                                        setCopiedData(null)
                                        CreateSlotContainerNode(previousSibling ? previousSibling : null, parent)
                                        e.preventDefault()
                                        e.stopPropagation()
                                    }}
                                >
                                    <span>
                                        <DialogSlotsIcon />
                                    </span>
                                    <label>Verify user details</label>
                                </div>
                                <div
                                    className="drodown_li_span"
                                    onClick={e => {
                                        const previousSibling = parent.responses
                                            .filter((e: ResponseNodeE) => e.id == dialog.previous_sibling)
                                            .pop()
                                        setAddChildInBetween(null)
                                        setCopiedData(null)
                                        CreateAdaptiveNode(previousSibling ? previousSibling : null, parent)
                                        e.preventDefault()
                                        e.stopPropagation()
                                    }}
                                >
                                    <span>
                                        <DailogAdaptiveIcon />
                                    </span>
                                    <label>Adaptive card</label>
                                </div>
                                <div
                                    className="drodown_li_span"
                                    onClick={e => {
                                        const previousSibling = parent.responses
                                            .filter((e: ResponseNodeE) => e.id == dialog.previous_sibling)
                                            .pop()

                                        setAddChildInBetween(null)
                                        setCopiedData(null)
                                        CreateVariableNode(previousSibling ? previousSibling : null, parent)
                                        e.preventDefault()
                                        e.stopPropagation()
                                    }}
                                >
                                    <span>
                                        <DialogVariableIcon />
                                    </span>
                                    <label>Custom variable</label>
                                </div>

                                <div
                                    className="drodown_li_span"
                                    onClick={e => {
                                        const previousSibling = parent.responses
                                            .filter((e: ResponseNodeE) => e.id == dialog.previous_sibling)
                                            .pop()
                                        setAddChildInBetween(null)
                                        setCopiedData(null)
                                        CreateAttachmentNode(previousSibling ? previousSibling : null, parent)
                                        e.preventDefault()
                                        e.stopPropagation()
                                    }}
                                >
                                    <span>
                                        <DialogAttachmentIcon />
                                    </span>
                                    <label>Upload attachment</label>
                                </div>
                            </div>
                        </div>
                    </DropDown>
                </React.Fragment>
            }
            </CopyPasteContext.Consumer>
            )
        }
    </DescendantContext.Consumer>
    ) : (
        <div className={'dialog_tree_next'}>
            <div className="dialog_tree_next_toggle">
                <div className="downarrow_hide_right">
                    <NextDetailICon />
                </div>
            </div>
        </div>                   
    )
}


const DialogNode = ({
    dialog,
    context,
    isDetailView,
    index,
    node
}: {
    dialog: ResponseNodeE
    context: DialogueContextT
    isDetailView: boolean
    index: number
    node:IfNodeT
}) =>{
    const [hoverCopy,setHoverCopy] = useState(false)
    const {workspaceName,assistant} = context
    const writePermission = usePermissions(workspaceName,`CHAT_BOT_MODULE/BOT/${assistant.id}/SUB_DIALOG_NODE`,"WRITE")
    const deletePermission = usePermissions(workspaceName,`CHAT_BOT_MODULE/BOT/${assistant.id}/SUB_DIALOG_NODE`,"DELETE")
    const rootWritePermission = usePermissions(workspaceName,`CHAT_BOT_MODULE/BOT/${assistant.id}/ROOT_DIALOG_NODE/${node.uuid}/CONDITION`,"WRITE")
    const rootDeletePermission = usePermissions(workspaceName,`CHAT_BOT_MODULE/BOT/${assistant.id}/ROOT_DIALOG_NODE/${node.uuid}/CONDITION`,"DELETE")


    return(
     <CopyPasteContext.Consumer>
    {({copiedData,setCopiedData})=>
    <DescendantContext.Consumer>
        {({ EditResponse }) =>
            !isDetailView ? (
                <div className="child_node">
                    <Designer
                        dialog={dialog}
                        onClick={(e: any) => {
                            e.stopPropagation()
                            e.preventDefault()
                            EditResponse(dialog)
                        }}
                    />

                    <h4
                        onClick={(e: any) => {
                            e.stopPropagation()
                            e.preventDefault()
                            EditResponse(dialog)
                        }}
                    >
                        {dialog.type === 'text' && 'Send message'}
                        {dialog.type === 'option' && 'Menu option'}
                        {dialog.type === 'ask_a_question' && 'Ask question'}
                        {dialog.type === 'slot_container' && 'Verify user details'}
                        {dialog.type === 'automation' && 'Automation'}
                        {dialog.type === 'adaptive_card' && 'Adaptive card'}
                        {dialog.type === 'file_upload' && 'Upload attachment'}
                        {dialog.type === 'agent_handoff' && 'Agent handover'}
                        {dialog.type === 'context_variable' && 'Custom variable'}
                    </h4>
                    <DialogueContext.Consumer>
                        {({ setPageState, rootDialogs }) => (
                            <>
                            <EditDelete
                                onEdit={() => {
                                    EditResponse(dialog)
                                }}
                                onDelete={() => {
                                    setPageState({ action: ActionT.DELETE_RESPONSE_NODE, data: dialog })
                                }}
                                isDetailView={isDetailView} 
                                setHoverCopy={()=>{}}
                                setCopiedData={()=>{}}
                                permission={{rootWrite:rootWritePermission.value,rootDelete:rootDeletePermission.value,subWrite:writePermission.value,subDelete:deletePermission.value} as {
                                    rootWrite: boolean ;
                                    rootDelete: boolean ;
                                    subWrite: boolean ;
                                    subDelete: boolean ;
                                }}
                                node={node}
                            />

                            {validateResponseNode(context, dialog, rootDialogs) && (
                                <div className="error_input_show dialog_node child_svg">
                                    <WarningAlertIcon />
                                    <span className="error_menu_hvr">Input data is missing.</span>
                                </div>
                            )}
                            </>
                        )}
                    </DialogueContext.Consumer>
                    
                </div>
            ) : (
                <div className="respond_dialog_page">
                    <div className="col-md-12 parent_node parent_node_left dialog_detail_account">
                        <div
                            className={`dialog_detail_parent_node ${hoverCopy?"copy_border_for_individual":""}`}
                            onClick={e => {
                                // console.log('parent click')
                                EditResponse(dialog)
                                e.stopPropagation()
                                e.preventDefault()
                            }}
                        >
                            {returnUI(
                                dialog,
                                () => {
                                    EditResponse(dialog)
                                    // e.stopPropagation()
                                    // e.preventDefault()
                                },
                                index,
                                context
                            )}
                        </div>
                        <DialogueContext.Consumer>
                            {({ setPageState, rootDialogs }) => (
                                <>
                                <EditDelete
                                    onEdit={() => {
                                        EditResponse(dialog)
                                    }}
                                    onDelete={() => {
                                        setPageState({ action: ActionT.DELETE_RESPONSE_NODE, data: dialog })
                                    }}
                                    isDetailView={isDetailView}
                                    setHoverCopy={setHoverCopy}
                                    setCopiedData={()=>setCopiedData(dialog)}
                                    permission={{rootWrite:rootWritePermission.value,rootDelete:rootDeletePermission.value,subWrite:writePermission.value,subDelete:deletePermission.value} as {
                                        rootWrite: boolean ;
                                        rootDelete: boolean ;
                                        subWrite: boolean ;
                                        subDelete: boolean ;
                                    }}
                                    node={node}
                                />
                                {validateResponseNode(context, dialog,rootDialogs) && (
                                    <div className="error_input_show dialog_node svg-15px">
                                        <WarningAlertIcon />
                                        <span className="error_menu_hvr">Input data is missing.</span>
                                    </div>
                                )}
                                </>
                            )}
                        </DialogueContext.Consumer>
                        
                    </div>
                </div>
            )
        }
    </DescendantContext.Consumer>
    }
    </CopyPasteContext.Consumer>
    )
    }
const HeaderContent = (props: { icon: any; header: string }) => {
    return (
        <>
            <div
                className="dialog_detail_header"
                onClick={e => {
                    // console.log('evnet', e)
                    e.stopPropagation()
                    e.preventDefault()
                }}
            >
                <div className="parent_node_no">
                    <span className="parent_svg">{props.icon}</span>
                </div>
                <h4>{props.header}</h4>
            </div>
            <div className="border-bottom_flow" />
        </>
    )
}

function OpenPopupWrapper(props: any) {
    return (
        <div className="send_message_editor">
            <div className="send_message_details send_message_hover">
                <div className="dialog_detail_borderbottom" id={props.id}>
                    <h6>{props.title}</h6>
                    <div className="max_scroll_limit">{props.children}</div>
                    <div
                        className="add_respond"
                        onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            props.setOpen(!open)
                        }}
                    >
                        <span className="icon_add_respond">
                            <DownArrowCircle />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ShowAndHideArrow(props: { showExpand: boolean; setOpen: any; open: boolean }) {
    // console.log('COG1-7169 ::ShowAndHideArrow', props)
    return props.showExpand ? (
        <div
            className="add_respond"
            onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                props.setOpen(!props.open)
            }}
        >
            <span className="icon_add_respond open_arrow">
                <DownArrowCircle />
            </span>{' '}
        </div>
    ) : null
}

function ExpandView({ render }: { render: any }) {
    const [open, setOpen] = useState(false)

    return render(open, setOpen)
}

function Content({
    render,
    id,
    title,
    icon,
    dialog
}: {
    render: any
    id: number
    title: string
    icon: any
    dialog: any
}) {
    const [showExpand, setShowExpand] = useState(false)

    useEffect(() => {
        const node = document.getElementById(id.toString())
        // console.log('COG1-7169 :: Content :: context', id, node, id.toString(), node && node.clientHeight)
        node && node.clientHeight > 63 && !showExpand && setShowExpand(true)
    }, [dialog])

    return (
        <>
            <HeaderContent icon={icon} header={title} />
            {render(showExpand)}
        </>
    )
}

function DialogContent({
    dialog,
    setPageState,
    index,
    isNull
}: {
    dialog: IfNodeT
    setPageState: any
    index: number
    isNull?: boolean
}) {
    const context = useContext(DialogueContext)

    const [showExpand, setShowExpand] = useState(false)
    useEffect(() => {
        const node = document.getElementById(index.toString())
        node && node.clientHeight > 63 && !showExpand && setShowExpand(true)
    }, [dialog])

    const operatorDisplayText = (operator: string) => {
        switch (operator) {
            case 'equals':
                return 'equals'
            case 'DOES_NOT_EQUALS_TEXT':
                return 'does not equal to'
            default:
                return operator
        }
    }

    const isWelcomeNode = dialog.uuid == context.assistant.welcome_node

    const reversedCondition = reverseCond(dialog.conditions, context, context.dialogueNode)

    const details = (isWelcomeNode && dialog.conditions.type !== 'WelcomeExpression'? reversedCondition.slice(2,reversedCondition.length):reversedCondition).map((c: conditionT) => {
        const variable = c.type == conditionTYPES.CONTEXT ? (c.LHS as any) : undefined
        return (
            <>
                <h6>
                    {' '}
                    {c.type == conditionTYPES.INTENT
                        ? 'If user says'
                        : c.type == conditionTYPES.ENTITY
                        ? 'Verify'
                        : c.type == conditionTYPES.COMBINATOR
                        ? c.value
                        : c.type == conditionTYPES.CONTEXT
                        ? typeof c.RHS == 'boolean'
                            ? c.RHS
                                ? 'If'
                                : 'If not'
                            : 'if'
                        : ''}
                </h6>
                {c.type == conditionTYPES.INTENT ? (
                    <p>
                        <span className="value">{c.name}</span>
                    </p>
                ) : c.type == conditionTYPES.ENTITY ? (
                    returnOperator(c as entityConditionT)
                ) : c.type == conditionTYPES.CONTEXT ? (
                    <>
                        {' '}
                        <div className="dialog_detail_borderbottom_span">
                            <div className={`dialog_input_edit_true dialog_context_input_edit_true `}>
                                <span className="buton_dialog_auto_hvr workflow_button_text">
                                    <Tooltip
                                        className="dialog_bot"
                                        tipContentHover
                                        zIndex={5000}
                                        arrowSize={0}
                                        distance={6}
                                        tagName="span"
                                        content={c.LHS.dummypath.map((path: any, index: number, arr: any[]) => (
                                            <React.Fragment key={`${index}`}>
                                                <span>{path}</span>
                                                {index + 1 != arr.length ? (
                                                    <span>
                                                        <FlowCanvasContextIcons.NextIcon />
                                                    </span>
                                                ) : null}
                                            </React.Fragment>
                                        ))}
                                    >
                                        <span className="context_variable_button">
                                            <img src={WorkativIcon} />
                                            {variable.variable ? variable.variable : variable.dummypath[0]}
                                        </span>
                                    </Tooltip>
                                </span>
                                {typeof c.RHS != 'boolean' && (
                                    <span
                                        className="span_content"
                                        style={{
                                            minHeight: '17px',
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            paddingLeft: '3px'
                                        }}
                                    >
                                        {operatorDisplayText(c.operator)}{' '}
                                    </span>
                                )}
                            </div>
                        </div>
                        {typeof c.RHS != 'boolean' && (
                            <>
                                <div className="recognize_variables">
                                    <Varibales editorState={c.RHS} />
                                </div>
                            </>
                        )}
                    </>
                ) : null}
            </>
        )
    })
    // const flowNodes = fetchFlowNodes(dialog)

    return (
        <>
            <div
                className="dialog_detail_parent_node"
                onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    // if (dialog.conditions && dialog.conditions.type === 'WelcomeExpression') return false
                    // else 
                    if (dialog.conditions && dialog.conditions.type === 'FallBackExpression') return false
                    return setPageState({ action: ActionT.IF_DIALOGUE, data: dialog })
                }}
            >
                <HeaderContent icon={<CanvasDialogIcon />} header={dialog.title} />
                {isNull && dialog.conditions && dialog.conditions.type === 'ComparisionExpression' && (
                    <div className="error_input_show parent two svg-15px">
                        <WarningAlertIcon />
                        <span className="error_menu_hvr">Input data is missing.</span>
                    </div>
                )}
                <ExpandView
                    render={(open: boolean, setOpen: any) => {
                        return (
                            <>
                                <div className="get_details_bottom">
                                    {open ? (
                                        <>
                                            <div className="send_message_details send_message_hover">
                                                <div className="recognise_dialog_details">
                                                    <div className="dialog_detail_borderbottom" id={index.toString()}>
                                                        <div className="max_scroll_limit recognise_dialog_card">
                                                            {details}
                                                        </div>
                                                        <div
                                                            className="add_respond"
                                                            onClick={e => {
                                                                e.preventDefault()
                                                                e.stopPropagation()
                                                                setOpen(!open)
                                                            }}
                                                        >
                                                            <span className="icon_add_respond">
                                                                <DownArrowCircle />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="recognise_dialog_details">
                                            <div className="send_message_wrapper">
                                                <div
                                                    className="dialog_detail_borderbottom recognise_hidden_content"
                                                    id={index.toString()}
                                                >
                                                    {dialog.conditions == null ? <h6>No condition set</h6> : details}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <ShowAndHideArrow open={open} setOpen={setOpen} showExpand={showExpand} />
                                </div>
                            </>
                        )
                    }}
                />
            </div>
        </>
    )
}

type ExitType = 'WITH_FEEDBACK' | 'WITHOUT_FEEDBACK' | 'INVALID_EXIT'

const findExitType = (exitNode: any): ExitType => {
    if (exitNode.next_step && exitNode.next_step.exit_with_feedback) {
        return 'WITH_FEEDBACK'
    }

    if (exitNode.next_step && exitNode.next_step.exit) {
        return 'WITHOUT_FEEDBACK'
    }

    return 'INVALID_EXIT'
}

function returnUI(params: any, EditResponse: Function, index: number, context: DialogueContextT) {
    const { dialogueNode, agents } = context

    // console.log('COG1-7169 params.type', params, index)
    switch (params.type) {
        case 'text': {
            const mappedValues = params.values
                .map((e: ExpressionT) => converterToEditor(e, context, dialogueNode))
                .map((t: any, i: number) => (
                    <div className="dialog_detail_borderbottom_span" key={i}>
                        <div className="number_count">
                            <label>{i + 1}</label>
                        </div>
                        <Varibales editorState={t} />
                    </div>
                ))
            return (
                <Content
                    dialog={params}
                    id={index}
                    icon={<DialogResponseIcon />}
                    title={'Send message'}
                    render={(showExpand: boolean) => (
                        <ExpandView
                            render={(open: boolean, setOpen: any) => {
                                return (
                                    <>
                                        <div className="get_details_bottom">
                                            <div className="send_message_wrapper">
                                                <div
                                                    className="dialog_detail_borderbottom"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        e.stopPropagation()
                                                        EditResponse()
                                                    }}
                                                    id={index.toString()}
                                                >
                                                    <h6>Respond with</h6>
                                                    {mappedValues}
                                                </div>
                                            </div>
                                            {open ? (
                                                <OpenPopupWrapper
                                                    open={open}
                                                    setOpen={setOpen}
                                                    title={'Respond with'}
                                                    id={index.toString()}
                                                >
                                                    {mappedValues}
                                                </OpenPopupWrapper>
                                            ) : null}
                                            <ShowAndHideArrow open={open} setOpen={setOpen} showExpand={showExpand} />
                                        </div>
                                    </>
                                )
                            }}
                        />
                    )}
                />
            )
        }
        case 'slot_container': {
            const mappedValues = params.slots.slice(0, 1).map((s: slotsT, i: number) => (
                <>
                    <div className="get_detils_data get_user_count">
                        {/* <div className="number_count">
                            <label>{i + 1}</label>
                        </div>{' '} */}
                        {s.condition.type == 'EntityExpression' ? (
                            <p style={{ padding: 0, whiteSpace: 'normal' }}>
                                If <label> "{s.condition.entity}"</label> is available,
                                {s.prompt ? (
                                    <>
                                        <label> </label> if not ask user
                                        <label>
                                            {' '}
                                            "{s.prompt}" <label> </label>
                                        </label>{' '}
                                        and save.
                                    </>
                                ) : (
                                    <>
                                        <label> </label> if not ignore.
                                    </>
                                )}
                            </p>
                        ) : (
                            <p style={{ padding: 0, whiteSpace: 'normal' }}>
                                If <label> "{s.condition.value}"</label> is available,
                                {s.prompt ? (
                                    <>
                                        <label> </label> if not ask user<label> "{s.prompt}"</label> and save.
                                    </>
                                ) : (
                                    <>
                                        <label> </label> if not ignore.
                                    </>
                                )}
                            </p>
                        )}
                    </div>
                </>
            ))
            return (
                <Content
                    dialog={params}
                    id={index}
                    icon={<DialogSlotsIcon />}
                    title={'Verify user details'}
                    render={(showExpand: boolean) => (
                        <ExpandView
                            render={(open: boolean, setOpen: any) => {
                                return (
                                    <>
                                        <div className="get_details_bottom verify_user_details">
                                            <div className="send_message_wrapper">
                                                <div
                                                    className="dialog_detail_borderbottom"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        e.stopPropagation()
                                                        EditResponse()
                                                    }}
                                                    id={index.toString()}
                                                >
                                                    <h6>Verify</h6>
                                                    {mappedValues}
                                                </div>
                                            </div>
                                            {open ? (
                                                <OpenPopupWrapper
                                                    open={open}
                                                    setOpen={setOpen}
                                                    title={'Verify'}
                                                    id={index.toString()}
                                                >
                                                    {mappedValues}
                                                </OpenPopupWrapper>
                                            ) : null}
                                            <ShowAndHideArrow open={open} setOpen={setOpen} showExpand={showExpand} />
                                        </div>
                                    </>
                                )
                            }}
                        />
                    )}
                />
            )
        }
        case 'ask_a_question': {
            const mappedValues = params.options.map((v: any, i: number) => (
                <button key={i}>
                    <label>{v.value.value}</label>
                </button>
            ))
            return (
                <Content
                    dialog={params}
                    id={index}
                    icon={<DialogQaIcon />}
                    title={'Ask question'}
                    render={(showExpand: boolean) => (
                        <ExpandView
                            render={(open: boolean, setOpen: any) => {
                                return (
                                    <>
                                        <div className="get_details_bottom ask_user">
                                            <div className="send_message_wrapper">
                                                <div
                                                    className="dialog_detail_borderbottom"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        e.stopPropagation()
                                                        EditResponse()
                                                    }}
                                                    id={index.toString()}
                                                >
                                                    <h6>Ask user</h6>
                                                    <div className="ask_question_card">
                                                        <div className="ask_question">
                                                            <Varibales
                                                                editorState={converterToEditor(
                                                                    params.question,
                                                                    context,
                                                                    dialogueNode
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    {params.options && params.options.length > 0 && (
                                                        <p>
                                                            <b>Options</b>
                                                        </p>
                                                    )}
                                                    <div className="options_details_button ask_question_button">
                                                        {mappedValues}
                                                    </div>
                                                </div>
                                            </div>
                                            {open ? (
                                                <OpenPopupWrapper
                                                    open={open}
                                                    setOpen={setOpen}
                                                    title={'Ask user'}
                                                    id={index.toString()}
                                                >
                                                    <div className="ask_question_card">
                                                        <div className="ask_question">
                                                            <Varibales
                                                                editorState={converterToEditor(
                                                                    params.question,
                                                                    context,
                                                                    dialogueNode
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    {params.options && params.options.length > 0 && (
                                                        <p>
                                                            <b>Options</b>
                                                        </p>
                                                    )}

                                                    <div className="options_details_button ask_question_button">
                                                        {mappedValues}
                                                    </div>
                                                </OpenPopupWrapper>
                                            ) : null}
                                            <ShowAndHideArrow open={open} setOpen={setOpen} showExpand={showExpand} />
                                        </div>
                                    </>
                                )
                            }}
                        />
                    )}
                />
            )
        }
        case 'context_variable': {
            const mappedValues = params.variables
                .map((obj: any) => ({
                    name: obj.name,
                    value: converterToEditor(obj.expression, context, dialogueNode)
                }))
                .map((v: any, i: number) => (
                    <div className="ask_question">
                        <p>
                            <div className="number_count">
                                <label>{i + 1}</label>
                            </div>{' '}
                            For <label className="value">"{v.name}"</label> value is
                        </p>
                        <Varibales editorState={v.value} />
                    </div>
                ))
            return (
                <Content
                    dialog={params}
                    id={index}
                    icon={<DialogVariableIcon />}
                    title={'Custom variable'}
                    render={(showExpand: boolean) => (
                        <ExpandView
                            render={(open: boolean, setOpen: any) => {
                                return (
                                    <>
                                        <div className="get_details_bottom custom_variable_wrapper">
                                            <div
                                                className="dialog_detail_borderbottom"
                                                onClick={e => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    EditResponse()
                                                }}
                                                id={index.toString()}
                                            >
                                                <h6>Custom details</h6>
                                                {mappedValues}
                                            </div>
                                            {open ? (
                                                <div className="send_message_details send_message_hover">
                                                    <div className="custom_variable_detail">
                                                        <h6>Custom details</h6>
                                                        <div className="max_scroll_limit">
                                                            <div className="dialog_detail_borderbottom">
                                                                {mappedValues}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="add_respond"
                                                            onClick={e => {
                                                                e.preventDefault()
                                                                e.stopPropagation()
                                                                setOpen(!open)
                                                            }}
                                                        >
                                                            <span className="icon_add_respond">
                                                                <DownArrowCircle />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                            <ShowAndHideArrow open={open} setOpen={setOpen} showExpand={showExpand} />
                                        </div>
                                    </>
                                )
                            }}
                        />
                    )}
                />
            )
        }
        case 'option': {
            const mappedValues = params.options.map((o: OptionsValueT, i: number) => (
                <button key={i}>
                    <label>{o.label.value}</label>
                </button>
            ))

            const promtMessage = params.prompt_message as ExpressionT

            const editorFormat = converterToEditor(promtMessage, context, dialogueNode)
            // console.log('promtMessage', converterToEditor(promtMessage, context, dialogueNode))

            return (
                <Content
                    dialog={params}
                    id={index}
                    icon={<DialogOptionIcon />}
                    title={'Menu option'}
                    render={(showExpand: boolean) => (
                        <ExpandView
                            render={(open: boolean, setOpen: any) => {
                                return (
                                    <>
                                        {open ? (
                                            <div
                                                className="send_message_details send_message_hover"
                                                style={{ maxHeight: 'fit-content' }}
                                            >
                                                <div className="">
                                                    <h6 style={{ paddingTop: '4px' }}>Prompt user</h6>
                                                    <div
                                                        className="get_details_bottom options_details"
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            EditResponse()
                                                        }}
                                                        style={{ paddingRight: '0px', paddingLeft: '0px' }}
                                                        id={index.toString()}
                                                    >
                                                        <div
                                                            className="dialog_detail_borderbottom_span"
                                                            key={0}
                                                            style={{
                                                                paddingRight: '0px',
                                                                paddingTop: '0px',
                                                                marginTop: '0px',
                                                                paddingLeft: '0px'
                                                            }}
                                                        >
                                                            <Varibales editorState={editorFormat} />
                                                        </div>
                                                        <p style={{ paddingLeft: '11px', marginTop: '6px' }}>Options</p>
                                                        <div className="options_details_button">
                                                            <div className="max_scroll_limit">{mappedValues}</div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="add_respond"
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            setOpen(!open)
                                                        }}
                                                    >
                                                        <span className="icon_add_respond">
                                                            <DownArrowCircle />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                {' '}
                                                <h6>Prompt user</h6>
                                                <div
                                                    className="get_details_bottom options_details"
                                                    style={{ paddingRight: '10px' }}
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        e.stopPropagation()
                                                        EditResponse()
                                                    }}
                                                    id={index.toString()}
                                                >
                                                    <div className="dialog_detail_borderbottom_span" key={0}>
                                                        <Varibales editorState={editorFormat} />
                                                    </div>
                                                    <p style={{ paddingLeft: '0px' }}>Options</p>
                                                    <div className="options_details_button">{mappedValues}</div>
                                                </div>
                                            </>
                                        )}
                                        <ShowAndHideArrow open={open} setOpen={setOpen} showExpand={showExpand} />
                                    </>
                                )
                            }}
                        />
                    )}
                />
            )
        }
        case 'jump':
            return (
                <DialogueContext.Consumer>
                    {({ workspaceDescendents }) => {
                        const descantant = workspaceDescendents.filter(
                            w => params.next_step != null && params.next_step.jump_to.to == w.uuid
                        )[0]

                        const next_step = params.next_step
                            ? params.next_step.jump_to.selector == JumpToSelectorT.user_input
                                ? 'Wait for user input'
                                : params.next_step.jump_to.selector == JumpToSelectorT.condition
                                ? 'If bot recognizes'
                                : `Execute ${descantant && descantant.type == 'if_node' ? 'dialog' : 'faq'}`
                            : ''

                        return (
                            <>
                                <HeaderContent icon={<DialogJumpICon />} header={'Jump to'} />
                                <div className="dialog_detail_borderbottom">
                                    <h6
                                        onClick={e => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            EditResponse()
                                        }}
                                    >
                                        Then jump to
                                    </h6>
                                    <div
                                        className="get_details_bottom"
                                        style={{ paddingTop: '0' }}
                                        onClick={e => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            EditResponse()
                                        }}
                                    >
                                        <div className="jump_details">
                                            <p className="exit_conversation_detail_label">
                                                <label>{descantant && descantant.title}</label>{' '}
                                                {descantant && descantant.type == 'faq_node' ? 'faq' : 'dialog'} and{' '}
                                                {next_step}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }}
                </DialogueContext.Consumer>
            )
        case 'automation':
            return (
                <>
                    <HeaderContent icon={<DialogFlowIcon />} header={'Call automation'} />
                    <div className="get_details_bottom">
                        <h6
                            onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                EditResponse()
                            }}
                        >
                            {params.automation_type == 'approval' ? 'Execute approval workflow' : 'Execute workflow'}
                        </h6>
                        <div
                            className="automation_details"
                            onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                EditResponse()
                            }}
                        >
                            <div className="get_detils_data">
                                <p style={{ padding: 0 }}>
                                    <label>{params.automation !== null && params.automation_name}</label>
                                </p>
                            </div>
                            {/* {params.automation === null && (
                                <div className="error_input_show parent three svg-15px">
                                    <span style={{ width: 15 }}>
                                        <WarningAlertIcon />
                                    </span>
                                    <span className="error_menu_hvr">Add workflow automation.</span>
                                </div>
                            )} */}
                        </div>
                    </div>
                </>
            )
        case 'adaptive_card':
            return (
                <>
                <Content
                    dialog={params}
                    id={index}
                    icon={<DailogAdaptiveIcon />}
                    title={'Adaptive card'}
                    render={(showExpand: boolean) => (
                        <ExpandView
                            render={(open: boolean, setOpen: any) => {
                                return (
                                    <>
                                        <div className="get_details_bottom adaptive_card_wrapper" id={index.toString()}>
                                            <h6
                                                onClick={e => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    EditResponse()
                                                }}
                                            >
                                                Show
                                            </h6>

                                            <div
                                                className="automation_details"
                                                onClick={e => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    EditResponse()
                                                }}
                                            >
                                                <div className="get_detils_data">
                                                    <p className="text_ellipsis">
                                                        <b>Title is </b> {params.title.value}
                                                    </p>
                                                </div>
                                            </div>
                                            <h6
                                                style={{ fontSize: '12px' }}
                                                onClick={e => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    EditResponse()
                                                }}
                                            ></h6>
                                            <div
                                                className="automation_details"
                                                onClick={e => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    EditResponse()
                                                }}
                                            >
                                                <div className="get_detils_data">
                                                    <p className="text_ellipsis">
                                                        <b>Description is </b> <Varibales editorState={converterToEditor(params.description)} />
                                                    </p>
                                                </div>
                                            </div>
                                            {open ? (
                                                <OpenPopupWrapper
                                                    open={open}
                                                    setOpen={setOpen}
                                                    title={""}
                                                    id={index.toString()}
                                                >
                                                    <h6
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            EditResponse()
                                                        }}
                                                    >
                                                        Show
                                                    </h6>

                                                    <div
                                                        className="automation_details"
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            EditResponse()
                                                        }}
                                                    >
                                                        <div className="get_detils_data">
                                                            <p className="text_ellipsis">
                                                                <b>Title is </b> {params.title.value}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {/* <h6
                                                        style={{ fontSize: '12px' }}
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            EditResponse()
                                                        }}
                                                    ></h6> */}
                                                    <div
                                                        className="automation_details"
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            EditResponse()
                                                        }}
                                                    >
                                                        <div className="get_detils_data">
                                                            <p className="text_ellipsis">
                                                                <b>Description is </b> <Varibales editorState={converterToEditor(params.description)} />
                                                            </p>
                                                        </div>
                                                    </div>
                                            </OpenPopupWrapper>
                                            ) : null}
                                            {console.log(showExpand,"showExpand")}
                                            <ShowAndHideArrow open={open} setOpen={setOpen} showExpand={showExpand} />
                                        </div>
                                       
                                    </>
                                )
                            }}
                        />
                    )}
                />

                    {/* <HeaderContent icon={<DailogAdaptiveIcon />} header={'Adaptive card'} /> */}
                    
                </>
            )
        case 'agent_handoff':
        const getAgentName = (params:any) =>{
            const selectedAgent = agents.filter((a)=> a.id == params.agent_id )[0]
            return selectedAgent ? selectedAgent.name : ""
        }
        
            return (
                <>
                    <HeaderContent icon={<SubPanelIcons.DialogAgentHandoff />} header={'Agent handover'} />
                    <h6
                        onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            EditResponse()
                        }}
                    >
                        Handover chat to
                    </h6>
                    <div
                        className="get_details_bottom"
                        onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            EditResponse()
                        }}
                    >
                        <div className="jump_details">
                            <p>
                                {getAgentName(params)} in <label>{params.platform}</label>
                            </p>
                        </div>
                    </div>
                </>
            )
        case 'file_upload':
            return (
                <>
                    <HeaderContent icon={<DialogAttachmentIcon />} header={'Upload attachment'} />
                    <h6
                        onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            EditResponse()
                        }}
                    >
                        Ask user to upload
                    </h6>
                    <div
                        className="get_details_bottom file_upload_wrapper"
                        onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            EditResponse()
                        }}
                    >
                        <div className="jump_details">
                            <p>
                                <label>{params.category}</label> if not provided, ask user{' '}
                                <label>{params.prompt.value}</label>
                            </p>
                        </div>
                    </div>
                </>
            )
        case 'exit': {
            const exitType = findExitType(params)

            const findHeaderText = (exitType: ExitType) => {
                if (exitType == 'WITH_FEEDBACK') {
                    return 'Get feedback and exit'
                } else if (exitType === 'WITHOUT_FEEDBACK') {
                    return 'Exit without collecting feedback'
                }

                return ''
            }

            const headerText = findHeaderText(exitType)

            const messageToDisplay = (params: any) => {
                const exitType = findExitType(params)

                const valueToCheck =
                    exitType === 'WITH_FEEDBACK'
                        ? params.next_step.exit_with_feedback.message
                        : params.next_step.exit.message

                const message = converterToEditor(valueToCheck, context, dialogueNode)

                // console.log('COG1-7169 ::: exit message', index)

                return message
            }

            return (
                <>
                    <Content
                        dialog={params}
                        id={index}
                        icon={<DialogExitIcon />}
                        title={'Exit conversation'}
                        render={(showExpand: boolean) => (
                            <ExpandView
                                render={(open: boolean, setOpen: any) => {
                                    return (
                                        <div className="get_details_bottom">
                                            <div className="send_message_wrapper">
                                                <div
                                                    className="dialog_detail_borderbottom"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        e.stopPropagation()
                                                        EditResponse()
                                                    }}
                                                    id={index.toString()}
                                                >
                                                    <h6>{headerText}</h6>
                                                    <div className="dialog_detail_borderbottom_span" key={0}>
                                                        <Varibales editorState={messageToDisplay(params)} />
                                                    </div>
                                                </div>
                                            </div>
                                            {open ? (
                                                <OpenPopupWrapper
                                                    open={open}
                                                    setOpen={setOpen}
                                                    title={headerText}
                                                    id={index.toString()}
                                                >
                                                    <div className="dialog_detail_borderbottom_span" key={0}>
                                                        <Varibales editorState={messageToDisplay(params)} />
                                                    </div>
                                                </OpenPopupWrapper>
                                            ) : null}
                                            <ShowAndHideArrow open={open} setOpen={setOpen} showExpand={showExpand} />
                                        </div>
                                    )
                                }}
                            />
                        )}
                    />
                </>
            )
        }

        default:
            return null
    }
}

export const Designer = ({ dialog, onClick }: { dialog: ResponseNodeE; onClick: Function }) => {
    switch (dialog.type) {
        case 'text':
            return (
                <div className={'child_node_no'} onClick={() => onClick()}>
                    <span>
                        <DialogResponseIcon />
                    </span>
                </div>
            )
        case 'option':
            return (
                <div className={'child_node_no'} onClick={() => onClick()}>
                    <span>
                        <DialogOptionIcon />
                    </span>
                </div>
            )
        case 'automation':
            return (
                <div className={'child_node_no'} onClick={() => onClick()}>
                    <span>
                        <DialogFlowIcon />
                    </span>
                </div>
            )
        case 'agent_handoff':
            return (
                <div className={'child_node_no'} onClick={() => onClick()}>
                    <span>
                        <SubPanelIcons.DialogAgentHandoff />
                    </span>
                </div>
            )
        case 'ask_a_question':
            return (
                <div className={'child_node_no'} onClick={() => onClick()}>
                    <span>
                        <DialogQaIcon />
                    </span>
                </div>
            )
        case 'slot_container':
            return (
                <div className={'child_node_no'} onClick={() => onClick()}>
                    <span>
                        <DialogSlotsIcon />
                    </span>
                </div>
            )
        case 'adaptive_card':
            return (
                <div className={'child_node_no'} onClick={() => onClick()}>
                    <span>
                        <DailogAdaptiveIcon />
                    </span>
                </div>
            )
        case 'file_upload':
            return (
                <div className={'child_node_no'} onClick={() => onClick()}>
                    <span>
                        <DialogAttachmentIcon />
                    </span>
                </div>
            )
        case 'context_variable':
            return (
                <div className={'child_node_no  '} onClick={() => onClick()}>
                    <span>
                        <DialogVariableIcon />
                    </span>
                </div>
            )

        default:
            return (
                <div className={'child_node_no'} onClick={() => onClick()}>
                    <span>
                        <CanvasDialogIcon />
                    </span>
                </div>
            )
    }
}

export const EditDelete = ({
    onEdit,
    onDelete,
    isDetailView,
    setHoverCopy,
    setCopiedData,
    dontShowCopyIcon,
    permission,
    node
}: {
    onEdit: Function
    onDelete: Function
    isDetailView: boolean
    setHoverCopy:Function
    setCopiedData:Function
    permission:{
        rootWrite: boolean ;
        rootDelete: boolean ;
        subWrite: boolean ;
        subDelete: boolean ;
    }
    node:IfNodeT
    dontShowCopyIcon?:boolean
}) =>{
    const [showCopied,setShowCopied] = useState(false)

    return !isDetailView ? (
        <>
            {((node.parent==null&&permission.rootWrite)||(permission.subWrite)) && <span
                className="child_svg"
                onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    onEdit()
                }}
            >
                <Tooltip
                    className="target customTip "
                    zIndex={10000}
                    arrowSize={8}
                    tagName="span"
                    content={'Edit'}
                    distance={5}
                >
                    <EditIcon />
                </Tooltip>
               
            </span>}
            {((node.parent==null&&permission.rootDelete)||(permission.subDelete)) &&<span
                className="child_svg"
                onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    onDelete()
                }}
            >
                <Tooltip
                    className="target customTip "
                    zIndex={10000}
                    arrowSize={8}
                    tagName="span"
                    content={'Delete'}
                    distance={5}
                >
                    <DeleteIcon />
                </Tooltip>
                
            </span>}
        </>
    ) : (
        <>
            <div className="flow_buttons ">
                <div className="flow_buttons_icons ">
                    <div className="flow_buttons_icons_content">
                        <MoreIcon />
                    </div>
                    {!dontShowCopyIcon&& ((node.parent==null&&permission.rootWrite)||(permission.subWrite)) &&
                        <>
                    <div className="flow_buttons_icons_content" 
                      onMouseEnter={()=>{
                                            setHoverCopy(true)
                                        }}
                                        onMouseLeave={()=>{
                                            setHoverCopy(false)
                                        }}
                                        onClick={(e)=>{
                                            setCopiedData()
                                            setShowCopied(true)
                                            setTimeout(()=>setShowCopied(false),1000)
                                            e.preventDefault()
                                            e.stopPropagation()
                                        }}
                                        >
                                            <Tooltip
                                                className="target customTip "
                                                zIndex={10000}
                                                arrowSize={8}
                                                tagName="span"
                                                content={'Copy'}
                                                distance={5}
                                            >
                                                <CopyIocn />
                                            </Tooltip>
                                            
                                        </div>
                                        {showCopied &&
                                        <span className="copy_function_copied">
                                        <SuccessIcon />
                                        <p>Copied</p>
                                        </span>
                                        }
                                        </>}
                    {((node.parent==null&&permission.rootDelete)||(permission.subDelete))&&<div
                        className="flow_buttons_icons_content"
                        onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            onDelete()
                        }}
                    >
                            <Tooltip
                                className="target customTip "
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content={'Delete'}
                                distance={5}
                            >
                                <DeleteIcon />
                            </Tooltip>
                        
                    </div>}
                    {((node.parent==null&&permission.rootWrite)||(permission.subWrite))&&<div
                        className="flow_buttons_icons_content"
                        onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            onEdit()
                        }}
                    >
                            <Tooltip
                                className="target customTip "
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content={'Edit'}
                                distance={5}
                            >
                                <EditIcon />
                            </Tooltip>
                        
                    </div>}
                </div>
            </div>
        </>
    )
}

const FinalHook = ({ dialog, showDropDownInitially }: { dialog: IfNodeT; showDropDownInitially: boolean }) => {
    const setAddChildInLast2 = useContext(DescendantContext).setAddChildInLast
    useEffect(() => {
        showDropDownInitially && setAddChildInLast2(dialog.id)
    }, [])

    const { assistant, workspaceName } = useContext(DialogueContext)

    const rootWritePermission = usePermissions(workspaceName,`CHAT_BOT_MODULE/BOT/${assistant.id}/ROOT_DIALOG_NODE/${dialog.uuid}/RESPONSE_NODE`,"WRITE")
    const writePermission = usePermissions(workspaceName,`CHAT_BOT_MODULE/BOT/${assistant.id}/SUB_DIALOG_NODE/${dialog.uuid}/RESPONSE_NODE`,"WRITE")

    return ((dialog.parent==null&&rootWritePermission.value)||(writePermission.value))? (
        <>
            <DescendantContext.Consumer>
                {({
                    addChildInLast,
                    setAddChildInLast,
                    CreateTextNode,
                    CreateOptionNode,
                    CreateQuestionNode,
                    JumpNode,
                    ExitNode,
                    CreateSlotContainerNode,
                    CreateAutomationNode,
                    CreateAdaptiveNode,
                    CreateAttachmentNode,
                    CreateAgentHandoffNode,
                    CreateVariableNode,
                    PasteCopiedFunction
                }) =>
                    addChildInLast == null || addChildInLast != dialog.id ? (
                        <div
                            className="dialog_tree_render"
                            onClick={e => {
                                e.preventDefault()

                                scroller()
                                setAddChildInLast(dialog.id)
                            }}
                        >
                            <div className="dialog_tree_next">
                                <div className="dialog_tree_next_toggle">
                                    <div className="downarrow_hide_right">
                                        <NextDetailICon />
                                    </div>
                                    <div className="dialog_tree_next_hover">
                                        <div className="child_node_button">
                                            <div className="parent_node_no parent_node_no_select">
                                                <span className="parent_svg">
                                                    <AddIcon />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                    <CopyPasteContext.Consumer>
                        {({copiedData,setCopiedData})=>
                        <DropDown isOpen={addChildInLast == dialog.id} onOuterClick={() => setAddChildInLast(null)}>
                            <div className="dialog_tree_render dropdown_content plus_dropdown_list"
                                onClick={e=>{
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                                }>
                                <div className="dropdown_content_add_dropdown">
                                    <div className="child_node_button">
                                        <div className="parent_node_no">
                                            <span className="parent_svg">
                                                <AddIcon />
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="drpdown_dialog">
                                {copiedData &&
                                <div
                                    className="drodown_li_span"
                                    onClick={e => {
                                        // console.log('drpdown_dialog click 1034')
                                        const previousSibling = dialog.responses.length
                                                ? dialog.responses.concat([]).pop()
                                                : null
                                        PasteCopiedFunction(copiedData,previousSibling ? previousSibling : null, dialog,setCopiedData)

                                        e.preventDefault()
                                        e.stopPropagation()
                                    }}
                                >
                                    <span className="copy_paste_icon">
                                        <CopyIocn />
                                    </span>
                                    <label>Paste the copied function</label>
                                </div>
                                }
                                    <div
                                        className="drodown_li_span"
                                        onClick={e => {
                                            e.preventDefault()
                                            const previousSibling = dialog.responses.length
                                                ? dialog.responses.concat([]).pop()
                                                : null

                                            setAddChildInLast(null)
                                            setCopiedData(null)

                                            CreateTextNode(previousSibling, dialog)
                                        }}
                                    >
                                        <span>
                                            <DialogResponseIcon />
                                        </span>
                                        <label>Send message</label>
                                    </div>

                                    <div
                                        className="drodown_li_span"
                                        onClick={e => {
                                            e.preventDefault()
                                            const previousSibling = dialog.responses.length
                                                ? dialog.responses.concat([]).pop()
                                                : null

                                            setAddChildInLast(null)
                                            setCopiedData(null)

                                            CreateQuestionNode(previousSibling, dialog)
                                        }}
                                    >
                                        <span>
                                            <DialogQaIcon />
                                        </span>
                                        <label>Ask question</label>
                                    </div>

                                    <div
                                        className="drodown_li_span"
                                        onClick={e => {
                                            e.preventDefault()
                                            const previousSibling = dialog.responses.length
                                                ? dialog.responses.concat([]).pop()
                                                : null

                                            setAddChildInLast(null)
                                            setCopiedData(null)

                                            CreateOptionNode(previousSibling, dialog)
                                        }}
                                    >
                                        <span>
                                            <DialogOptionIcon />
                                        </span>
                                        <label>Menu option</label>
                                    </div>

                                    <div
                                        className="drodown_li_span"
                                        onClick={e => {
                                            e.preventDefault()
                                            setCopiedData(null)

                                            setAddChildInLast(null)
                                            JumpNode(dialog)
                                        }}
                                    >
                                        <span>
                                            <DialogJumpICon />
                                        </span>
                                        <label>Jump to</label>
                                    </div>
                                    <div
                                        className="drodown_li_span"
                                        onClick={e => {
                                            e.preventDefault()
                                            const previousSibling = dialog.responses.length
                                                ? dialog.responses.concat([]).pop()
                                                : null

                                            setAddChildInLast(null)
                                            setCopiedData(null)

                                            CreateSlotContainerNode(previousSibling, dialog)
                                        }}
                                    >
                                        <span>
                                            <DialogSlotsIcon />
                                        </span>
                                        <label>Verify user details</label>
                                    </div>

                                    <div
                                        className="drodown_li_span"
                                        onClick={e => {
                                            e.preventDefault()
                                            const previousSibling = dialog.responses.length
                                                ? dialog.responses.concat([]).pop()
                                                : null

                                            setAddChildInLast(null)
                                            setCopiedData(null)

                                            CreateAutomationNode(previousSibling, dialog)
                                        }}
                                    >
                                        <span>
                                            <DialogFlowIcon />
                                        </span>
                                        <label>Call automation</label>
                                    </div>

                                    <div
                                        className="drodown_li_span"
                                        onClick={e => {
                                            e.preventDefault()
                                            const previousSibling = dialog.responses.length
                                                ? dialog.responses.concat([]).pop()
                                                : null

                                            setAddChildInLast(null)
                                            setCopiedData(null)

                                            CreateAgentHandoffNode(previousSibling, dialog)
                                        }}
                                    >
                                        <span>
                                            <SubPanelIcons.DialogAgentHandoff />
                                        </span>
                                        <label>Agent handover</label>
                                    </div>
                                    <div
                                        className="drodown_li_span"
                                        onClick={e => {
                                            e.preventDefault()
                                            const previousSibling = dialog.responses.length
                                                ? dialog.responses.concat([]).pop()
                                                : null

                                            setAddChildInLast(null)
                                            setCopiedData(null)

                                            CreateAdaptiveNode(previousSibling, dialog)
                                        }}
                                    >
                                        <span>
                                            <DailogAdaptiveIcon />
                                        </span>
                                        <label>Adaptive card</label>
                                    </div>
                                    <div
                                        className="drodown_li_span"
                                        onClick={e => {
                                            e.preventDefault()
                                            const previousSibling = dialog.responses.length
                                                ? dialog.responses.concat([]).pop()
                                                : null

                                            setAddChildInLast(null)
                                            setCopiedData(null)
                                            CreateVariableNode(previousSibling, dialog)
                                        }}
                                    >
                                        <span>
                                            <DialogVariableIcon />
                                        </span>

                                        <label>Custom variable</label>
                                    </div>

                                    <div
                                        className="drodown_li_span"
                                        onClick={e => {
                                            e.preventDefault()
                                            const previousSibling = dialog.responses.length
                                                ? dialog.responses.concat([]).pop()
                                                : null

                                            setAddChildInLast(null)
                                            setCopiedData(null)
                                            CreateAttachmentNode(previousSibling, dialog)
                                        }}
                                    >
                                        <span>
                                            <DialogAttachmentIcon />
                                        </span>
                                        <label>Upload attachment</label>
                                    </div>

                                    <div
                                        className="drodown_li_span"
                                        onClick={e => {
                                            e.preventDefault()
                                            setAddChildInLast(null)
                                            setCopiedData(null)
                                            ExitNode(dialog)
                                        }}
                                    >
                                        <span>
                                            <DialogExitIcon />
                                        </span>
                                        <label>Exit conversation</label>
                                    </div>
                                </div>
                            </div>
                        </DropDown>
                        }
                        </CopyPasteContext.Consumer>
                    )
                }
            </DescendantContext.Consumer>

            <div className="final_hook" />
        </>
    ) : null
}

export function Varibales({ editorState }: { editorState: DraftJST }) {
    const state = EditorState.createWithContent(convertFromRaw(editorState as any), decorator)

    return (
        <div className={`dialog_input_edit_true dialog_context_input_edit_true `}>
            <DraftEditor editorState={state} onChange={() => {}} />
        </div>
    )
}

const TokenSpan = (props: any) => {
    const info: any = props.contentState.getEntityMap().get(props.entityKey).data
    return info.emojiUnicode ? (
        props.children
    ) : (
        <span className="buton_dialog_auto_hvr workflow_button_text">
            <Tooltip
                className="dialog_bot"
                tipContentHover
                zIndex={5000}
                arrowSize={0}
                distance={5}
                tagName="span"
                content={(info.dummypath ? info.dummypath : info.path).map((path: any, index: number, arr: any[]) => (
                    <React.Fragment key={`${index}`}>
                        <span>{path}</span>
                        {index + 1 != arr.length ? (
                            <span>
                                <FlowCanvasContextIcons.NextIcon />
                            </span>
                        ) : null}
                    </React.Fragment>
                ))}
            >
                <span className="context_variable_button">
                    <img src={WorkativIcon} />
                    {props.children}
                </span>
            </Tooltip>
        </span>
    )
}

const getEntityStrategy = (contentBlock: any, callback: any, contentState: any) => {
    contentBlock.findEntityRanges((character: any) => {
        const entityKey = character.getEntity()
        if (entityKey === null) {
            return false
        }
        return contentState.getEntity(entityKey).getMutability() === 'IMMUTABLE'
    }, callback)
}

export const decorator = new CompositeDecorator([
    {
        strategy: getEntityStrategy,
        component: TokenSpan
    },
    {
        strategy: findLinkEntities,
        component: Link
    }
])

export default Descendants
