/*eslint-disable*/
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import Tooltip from 'react-tooltip-lite';
import styled from 'styled-components/macro';

import {
  AddIcon,
  ArrowIcon,
  DeleteIcon,
  DropdownWithCircle,
  EditIcon,
  EditIconWithCircle,
} from '@/common/Icons/Icons';
import { LoadingSvgImage } from '@/common/Icons/LoadingSvgImage';
import { GreenTickIcon, WarningAlertIcon } from '@/common/Icons/Workflow.Icons';
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary';
import Validate, { ErrorContext } from '@/common/components/FormValidate';
import { HeaderChild } from '@/common/components/Header';
import InputBox from '@/common/components/InputBox';
import { List } from '@/common/components/List';
import { Loader } from '@/common/components/Loader';
import { Modal } from '@/common/components/Modal';
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb';
import { SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb';
import { ButtonWrapper, Paragraph } from '@/common/styled/Dialogue.Dumb';
import { BackButton, IconButton } from '@/common/styled/Entity.Dumb';
import {
  CancelButton,
  ErrorMessage,
  Input,
  InputContainer,
  InputContent,
  InputWrapper,
  Table,
  TableContainer,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
} from '@/common/styled/Workflow.Analytics.Dumb';
import {
  flowValidations,
  inputTextAreaValidations,
  newLine,
  onPremTextAreaValidations,
} from '@/common/utils/_validation-utils';
import { postJson } from '@/common/utils/axios-utils';

import { formatAMPM, formatDateInCard } from '@/common/utils/utils';
import {
  AutomationOnPremApi,
  automationAPIURLWithWorkspace,
  deleteJson,
  getJson,
  putJson,
  wrapperGetJson,
  logEvent,
} from '@/common/utils/api-utils';
import { CanvasRightPanel } from './canvas/CanvasRightPanel';
import { AppsListT, ResponseOutputT } from './canvas/types';
import { grouping, sortAnArrayAlphabetically } from './canvas/utils';
import {
  Add,
  AddT,
  AgentEdit,
  ConnectionModes,
  ConnectionView,
  ConnectionViewT,
  DeleteLinkedAutomation,
  Edit,
  ErrorPage,
  Loading,
  Mode,
  NoDataFound,
  NoDataFoundT,
  UnAuthorized,
  onPremConnectionListResponseT,
  onPremListResponseT,
} from './onPremTypes';

const TabelWrapper = styled.div`
  width: 100%;
  float: left;
  .list_flow_hr label {
    float: left;
    display: flex;
    position: absolute;
    top: 18px;
    width: 16px;
    height: 16px;
    margin-bottom: 0px;
    left: -22px;
  }
  .entity_cards {
    overflow: hidden;
    height: calc(100vh - 70px);
  }
  .entity_cards._grid_view {
    overflow-y: scroll;
  }

  ${Table} {
    margin-top: 0px;
    background: none;
    box-shadow: none;
  }

  ${Thead} {
    ${Tr} {
      padding-left: 20px;
      padding-right: 20px;

      ${Th} {
        :nth-child(2) {
          padding-right: 20px;
        }

        :nth-child(4) {
          padding-left: 20px;
        }
        :last-child {
          justify-content: flex-end;
        }
      }
    }
  }
  .onprem_icons_wrapper {
    ${SmallButton} {
      padding: 0;
      cursor: pointer;
      min-width: 45px;
      text-align: center;
      justify-content: center;
      height: 18px;
      padding-top: 2px;
      font-size: 9px;
      color: #666666;
      label {
        top: 0px;
      }
    }

    span {
      color: rgb(11, 178, 81);
    }
  }
  .connect_account_loading img {
    width: 18px;
    height: 18px;
    margin-top: 0px;
    margin-left: 8px;
  }

  ${Tbody} {
    border-radius: 0px 0px 10px 10px;
    max-height: calc(100vh - 152px);
    height: auto;
    padding-bottom: 60px;

    .list-full-width {
      width: 100%;
      float: left;
      :last-child {
        ${Tr} {
          border-radius: 0px 0px 10px 10px;
        }
      }
      :hover {
        :last-child {
          ${Tr} {
            border-radius: 0px 0px 10px 10px;
          }
        }
      }
    }

    .box_1 {
      padding: 0;
    }

    ${Tr} {
      .list_flow_span {
        flex-direction: row;
        span {
          font-size: 9px;
          margin: 3px;
          color: #000;
          :first-child {
            margin: 0px;
          }
          :last-child {
            margin: 0px;
          }
        }
      }
      ${Td} {
        padding: 0px;
        height: 48px !important;
        font-size: 12px;
        font-family: 'Gordita-Regular';
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        color: #000;

        :nth-child(1) {
          display: flex;
          padding-right: 20px;
          cursor: pointer;
          position: relative;
          font-family: 'Gordita-Medium';
          position: relative;
          padding-right: 20px;
          div {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          label {
            float: left;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            position: absolute;
            top: 16px;
            width: 16px;
            height: 16px;
            margin-bottom: 0px;
            left: -22px;
            visibility: visible;
            margin: 0px;
            .option-input.radio {
              margin: 0px;
              top: 0px;
            }
          }
        }
        :nth-child(2) {
          padding-right: 20px;
          div {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        :nth-child(4) {
          padding-left: 20px;
          justify-content: flex-end;
        }
      }
    }
  }
`;

const sortByNameCaseInsensitive = R.sortBy(
  R.compose(R.toLower, R.prop('name'))
);

const popupText = (mode: ConnectionModes): any => {
  if (mode.mode == Mode.AgentEdit) {
    return {
      header: 'Edit Agent',
      subHeader: 'Make the required changes and click on Save',
      buttonText: 'Save',
    };
  }
  if (mode.mode == Mode.Add || mode.mode === Mode.NoDataFound) {
    return {
      header: 'Create Connection for this Connector ',
      subHeader: 'Select an app, provide name and description ',
      buttonText: 'Add',
    };
  } else if (mode.mode == Mode.Edit) {
    return {
      header: 'Edit Connection',
      subHeader: 'Make changes and click update ',
      buttonText: 'Save',
    };
  } else {
    return {
      header: 'Delete Agent',
      subHeader: 'Make the required changes and click on Save',
      buttonText: 'Save',
    };
  }
};

export const OnPremConnections = (props: any) => {
  const agentId = props.match.params.id;

  const [crudModes, setCrudModes] = useState<ConnectionModes>(ConnectionView);

  const [state, setState] = useState<ConnectionModes>(Loading);

  const [search, setSearch] = useState({
    searching: crudModes.mode == Mode.Edit,
    key: crudModes.mode == Mode.Edit ? crudModes.name : '',
    inFocus: false,
  });

  const [selectedApp, setSelectedApp] = useState({
    name: 'Select',
    id: '',
    icon: '',
  });

  const getAgents = async () => {
    const agents = await wrapperGetJson(
      automationAPIURLWithWorkspace(
        props.match.params.workspacename,
        `${AutomationOnPremApi.GetAll}`
      )
    )
      .then((response: any) => response.data)
      .catch((err) => err);
    return agents;
  };

  async function callApplistAPI() {
    const workspaceName = props.match.params.workspacename;

    const apps = await getJson(
      automationAPIURLWithWorkspace(workspaceName, '/actions')
    )
      .then((unfiltertedAppList: AppsListT) => unfiltertedAppList)
      .catch((err) => err);

    return apps;
  }

  useEffect(() => {
    const nextProps = props;

    if (nextProps.state.type == 'loading') {
      setState(Loading);
    } else if (nextProps.state.type == 'error') {
      if (
        nextProps.state.error.response &&
        (nextProps.state.error.response.status == 401 ||
          nextProps.state.error.response.status == 403)
      ) {
        setState(UnAuthorized);
        return;
      } else {
        setState(ErrorPage);
        return;
      }
    } else if (nextProps.state.type == 'success') {
      const list = nextProps.state.data;
      getAgents()
        .then((data: { type: string; output: onPremListResponseT[] }) => {
          callApplistAPI()
            .then((apps: AppsListT) => {
              const onPremAppActionsOnly = apps.output.filter(
                (action) =>
                  action.type == 'action' &&
                  action.latest &&
                  action.authentication_type == 'on_prem'
              );
              !data.output
                ? setState(ErrorPage)
                : list.output.length > 0
                ? setState({
                    ...ConnectionView,
                    data: sortByNameCaseInsensitive(list.output),
                    agent: data.output.filter(
                      (agent) => agent.id == agentId
                    )[0],
                    apps: onPremAppActionsOnly,
                  })
                : setState({
                    ...NoDataFound,
                    agent: data.output.filter(
                      (agent) => agent.id == agentId
                    )[0],
                    apps: onPremAppActionsOnly,
                  });
            })
            .catch((err) => setState(ErrorPage));
        })
        .catch((err) => setState(ErrorPage));
    }
  }, [props.state]);

  const NoDataFoundRetryAPI = (
    agent: onPremListResponseT,
    apps: ResponseOutputT[]
  ) => {
    wrapperGetJson(
      automationAPIURLWithWorkspace(
        props.match.params.workspacename,
        `${AutomationOnPremApi.Update}${props.match.params.id}/connection`
      )
    )
      .then((response: any) => response.data)
      .then((data) => {
        setState({
          ...ConnectionView,
          data: sortByNameCaseInsensitive(data.output),
          agent: agent,
          apps: apps,
        });
        setCrudModes(ConnectionView);
      })
      .catch((err) => setState(ErrorPage));
  };

  const saveAgent = () => {
    switch (crudModes.mode) {
      case Mode.Add:
        {
          setCrudModes({ ...crudModes, loading: true });

          const { name, description } = crudModes;
          postJson(
            automationAPIURLWithWorkspace(
              props.match.params.workspacename,
              `${AutomationOnPremApi.Update}${agentId}/connection/create`
            ),
            {
              name,
              description,
              appId: selectedApp.id,
            }
          )
            .then((resp) => {

              logEvent(props.match.params.workspacename, {
                event:"EVENT::ON_PREM::CREATE_APP_CONNECTION", event_meta:{}
              })

              if (state.mode == Mode.NoDataFound) {
                const noDataState = state as NoDataFoundT;
                setState(Loading);
                NoDataFoundRetryAPI(noDataState.agent, noDataState.apps);
              } else {
                const list = state as ConnectionViewT;
                const listUpdated = sortByNameCaseInsensitive(
                  list.data.concat({
                    ...resp.data.output,
                  })
                );

                setState({ ...list, data: listUpdated });
                setCrudModes(ConnectionView);
              }
            })
            .catch(() => {
              setCrudModes({
                ...crudModes,
                error: {
                  error: true,
                  info: 'Unable to create Connection! Please try again.',
                },
              });
            });
        }
        break;
      case Mode.Edit:
        {
          setCrudModes({ ...crudModes, loading: true });

          const { name, description, id } = crudModes;

          putJson(
            automationAPIURLWithWorkspace(
              props.match.params.workspacename,
              `${AutomationOnPremApi.Update}${agentId}/connection/${id}/update`
            )
          )()({
            name,
            description,
            appId: selectedApp.id,
          })
            .then((resp) => {
              const list = state as ConnectionViewT;
              const listUpdated = sortByNameCaseInsensitive(
                list.data.map((l) => {
                  if (l.id == resp.data.output.id) {
                    return resp.data.output;
                  }
                  return l;
                })
              );
              setState({ ...list, data: listUpdated });
              setCrudModes(ConnectionView);
            })
            .catch(() => {
              setCrudModes({
                ...crudModes,
                error: {
                  error: true,
                  info: 'Unable to edit Connection! Please try again.',
                },
              });
            });
        }
        break;

      case Mode.DeleteLinkedAutomation:
        {
          setCrudModes({ ...crudModes, loading: true });

          const { id } = crudModes;
          deleteJson(
            automationAPIURLWithWorkspace(
              props.match.params.workspacename,
              `${AutomationOnPremApi.Update}${agentId}/connection/${id}/delete`
            )
          )()
            .then((resp) => {
              const list = state as ConnectionViewT;
              const listUpdated = sortByNameCaseInsensitive(
                list.data.filter((l) => l.id !== crudModes.id)
              );
              setState(
                listUpdated.length > 0
                  ? { ...list, data: listUpdated }
                  : { ...NoDataFound, agent: list.agent, apps: list.apps }
              );
              setCrudModes(ConnectionView);
            })
            .catch(() => {
              setCrudModes({
                ...crudModes,
                error: {
                  error: true,
                  info: 'Unable to delete Connection! Please try again.',
                },
              });
            });
        }
        break;

      case Mode.AgentEdit:
        {
          setCrudModes({ ...crudModes, loading: true });

          const { name, description, id } = crudModes as AgentEdit;

          putJson(
            automationAPIURLWithWorkspace(
              props.match.params.workspacename,
              `${AutomationOnPremApi.Update}${id}/update`
            )
          )()({
            name,
            description,
          })
            .then((resp) => {
              const list = state as ConnectionViewT;
              setState({
                ...list,
                agent: { ...list.agent, name, description },
              });
              setCrudModes(ConnectionView);
            })
            .catch(() => {
              setCrudModes({
                ...crudModes,
                error: {
                  error: true,
                  info: 'Unable to edit agent! Please try again.',
                },
              });
            });
        }
        break;
    }
  };

  const onNameChange = (name: string) => {
    const mode = crudModes as AddT;
    setCrudModes({ ...mode, name: name });
  };

  const onDescriptionChange = (description: string) => {
    const mode = crudModes as AddT;
    setCrudModes({ ...mode, description: description });
  };

  const resetToViewMode = () => {
    setCrudModes(ConnectionView);
  };

  const enableSave = () => {
    if (crudModes.mode == Mode.AgentEdit) {
      const list = state as ConnectionViewT;
      const filtAgent = list.agent;

      return (
        filtAgent.name == crudModes.name &&
        filtAgent.description == crudModes.description
      );
    } else if (crudModes.mode == Mode.Edit) {
      const list = state as ConnectionViewT;
      const filtAgent = list.data.filter((l) => l.id == crudModes.id);

      return (
        filtAgent[0].name == crudModes.name &&
        filtAgent[0].description == crudModes.description &&
        selectedApp.id == crudModes.appId
      );
    } else if (crudModes.mode == Mode.Add) {
      return selectedApp.id.length == 0;
    }

    return false;
  };

  const renderModal = () => {
    const { mode } = crudModes;
    const head = popupText(crudModes);

    if (
      crudModes.mode == Mode.Add ||
      crudModes.mode == Mode.Edit ||
      crudModes.mode == Mode.AgentEdit
    ) {
      return (
        <Modal showPopupLoader={crudModes.loading} className="edit_connection_popup">
          <ErrorBoundary
            error={crudModes.error}
            render={(err: any, info: any) => {
              return (
                err && (
                  <Error.AddorEdit
                    onClick={() =>
                      setCrudModes({
                        ...crudModes,
                        error: { error: false, info: '' },
                      })
                    }
                    info={info}
                  />
                )
              );
            }}
          >
            <form>
              <Validate
                defaultValue={
                  mode == Mode.Add || mode === Mode.NoDataFound
                    ? { flowName: false }
                    : { flowName: true }
                }
              >
                <h2
                  dangerouslySetInnerHTML={{
                    __html: head.header,
                  }}
                />
                <Paragraph>{head.subHeader}</Paragraph>
                {(crudModes.mode == Mode.Add ||
                  crudModes.mode == Mode.Edit) && (
                  <div className="onprem_select_wrapper">
                    <div className="select_application">
                      <div className="select_work_form">
                        <CanvasRightPanel.AppsDropDown
                          inputs={{
                            inputs: [
                              ...((state.mode == Mode.View ||
                                state.mode == Mode.NoDataFound) &&
                              state.apps
                                ? search.searching
                                  ? grouping(
                                      sortAnArrayAlphabetically(
                                        state.apps.filter(
                                          (action) =>
                                            action.type == 'action' &&
                                            action.latest &&
                                            action.authentication_type ==
                                              'on_prem'
                                        ),
                                        'name'
                                      ),
                                      'appName'
                                    ).filter(
                                      (app: any) =>
                                        app.name
                                          .toLowerCase()
                                          .indexOf(search.key.toLowerCase()) >
                                        -1
                                    )
                                  : grouping(
                                      sortAnArrayAlphabetically(
                                        state.apps.filter(
                                          (action) =>
                                            action.type == 'action' &&
                                            action.latest
                                        ),
                                        'name'
                                      ),
                                      'appName'
                                    )
                                : []),
                            ],
                            key: 'name',
                            idKey: 'appId',
                            showIcon: true,
                          }}
                          addDropDown
                          showOnPrem
                          closeCallBack={() => {
                            !search.inFocus &&
                              setSearch({ ...search, searching: false });
                          }}
                          updateSelection={(
                            selectedNewApp: string,
                            id: string
                          ) => {
                            const s = state as any;
                            setSelectedApp({
                              name: selectedNewApp,
                              id: id,
                              icon: s.apps
                                ? s.apps.filter(
                                    (app: any) => app.appId == id
                                  )[0].icon
                                : '',
                            });
                          }}
                          render={(makeDropDownVisible: () => void) => (
                            <React.Fragment>
                              <InputWrapper>
                                <InputContainer style={{marginBottom: 20}}>
                                  <Input
                                    type="text"
                                    value={
                                      search.searching
                                        ? search.key
                                        : selectedApp.name +
                                          (selectedApp.name != 'Select'
                                            ? ' (On-Prem)'
                                            : '')
                                    }
                                    onFocus={() => {
                                      setSearch({
                                        inFocus: true,
                                        searching: true,
                                        key:
                                          state.mode == Mode.Edit ||
                                          (selectedApp.name.length > 0 &&
                                            selectedApp.name.toLowerCase() !==
                                              'select')
                                            ? selectedApp.name
                                            : '',
                                      });
                                    }}
                                    onBlur={() => {
                                      setSearch({
                                        ...search,
                                        inFocus: false,
                                      });
                                    }}
                                    onClick={() => makeDropDownVisible()}
                                    onChange={(e: any) => {
                                      setSearch({
                                        ...search,
                                        key: e.target.value,
                                      });
                                    }}
                                    style={{
                                      paddingLeft:
                                        selectedApp.name === 'Select' ? 10 : 35,
                                    }}
                                  />
                                  <InputContent>Application name</InputContent>
                                  <CancelButton
                                    onClick={() => makeDropDownVisible()}
                                    style={{
                                      display: 'flex',
                                      top: '16px',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <DropdownWithCircle />
                                  </CancelButton>
                                </InputContainer>
                              </InputWrapper>

                              {selectedApp.name !== 'Select' && (
                                <div className="input_img_left">
                                  <img src={selectedApp.icon} />
                                </div>
                              )}
                            </React.Fragment>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <InputBox
                  validationList={flowValidations}
                  value={crudModes.name}
                  name="flowName"
                  render={(
                    value: string,
                    valid: boolean,
                    errorMsg: string,
                    startValidation: () => void,
                    handleInput: Function
                  ) => {
                    return (
                      <React.Fragment>
                        <ErrorMessage>
                          {!valid ? errorMsg : newLine}
                        </ErrorMessage>
                        <InputWrapper>
                          <InputContainer style={{marginBottom: 20}}>
                            <Input
                              type="text"
                              onChange={(event: any) => {
                                startValidation();
                                handleInput('flowName', event.target.value);
                                onNameChange(event.target.value);
                              }}
                              value={value}
                            />
                            <InputContent>Connection name</InputContent>
                          </InputContainer>
                        </InputWrapper>
                      </React.Fragment>
                    );
                  }}
                />
                <InputBox
                  validationList={
                    onPremTextAreaValidations
                  }
                  value={crudModes.description}
                  name="flowDescription"
                  render={(
                    value: string,
                    valid: boolean,
                    errorMsg: string,
                    startValidation: () => void,
                    handleInput: Function
                  ) => {
                    return (
                      <React.Fragment>
                        <ErrorMessage>
                          {!valid ? errorMsg : newLine}
                        </ErrorMessage>
                        <InputWrapper>
                          <InputContainer>
                            <Textarea
                              onChange={(event: any) => {
                                startValidation();
                                handleInput(
                                  'flowDescription',
                                  event.target.value
                                );
                                onDescriptionChange(event.target.value);
                              }}
                              value={value}
                            />
                            <InputContent>Connection description</InputContent>
                          </InputContainer>
                        </InputWrapper>
                      </React.Fragment>
                    );
                  }}
                />
                {mode == Mode.Add && crudModes.error ? (
                  <p className="error_msg">{crudModes.error.info}</p>
                ) : null}
                <ButtonWrapper>
                  <Button primary type="button" onClick={resetToViewMode}>
                    <label>Cancel</label>
                  </Button>
                  <ErrorContext.Consumer>
                    {(valid) => {
                      let disableButton =
                        !valid.formValid.formValid || enableSave();
                      return (
                        <Button
                          type="button"
                          onClick={() => {
                            saveAgent();
                          }}
                          disabled={disableButton}
                          className={disableButton ? 'editor_btn_disabled' : ''}
                        >
                          <label>{head.buttonText}</label>
                        </Button>
                      );
                    }}
                  </ErrorContext.Consumer>
                </ButtonWrapper>
              </Validate>
            </form>
          </ErrorBoundary>
        </Modal>
      );
    } else if (crudModes.mode == Mode.DeleteLinkedAutomation) {
      return (
        <Modal showPopupLoader={crudModes.loading}>
          <ErrorBoundary
            error={crudModes.error}
            render={(err: any, info: any) => {
              return (
                err && (
                  <Error.Delete
                    onClick={() =>
                      setCrudModes({
                        ...crudModes,
                        error: { error: false, info: '' },
                      })
                    }
                    info={info}
                  />
                )
              );
            }}
          >
            <div className="alert_popup_message">
              <h2>
                Delete{' '}
                <span
                  style={{
                    color: '#ff7900',
                    paddingLeft: '3px',
                    fontFamily: 'Gordita-Medium',
                  }}
                >
                  "{crudModes.name}"
                </span>
                ?
              </h2>
              <Paragraph>
                Note: Connection will be removed and unmapped from all
                workflows. Are you sure to delete?
              </Paragraph>
              <ul style={{ listStyleType: 'none' }}>
                {crudModes.automations.map(
                  (auto: { id: string; name: string }) => (
                    <li key={auto.id} className="automations_with_connections">
                      {auto.name}
                    </li>
                  )
                )}
              </ul>
              <ButtonWrapper>
                <Button primary type="button" onClick={resetToViewMode}>
                  <label>No</label>
                </Button>
                <Button type="button" onClick={saveAgent}>
                  <label>Yes</label>
                </Button>
              </ButtonWrapper>
            </div>
          </ErrorBoundary>
        </Modal>
      );
    }

    return null;
  };

  const { mode } = state;

  if (mode == Mode.Loading) return <Loader.PageLoader show={true} />;

  return (
    <>
      <ErrorBoundary
        unauthorized={state.mode === Mode.UnAuthorized}
        noDataFound={{
          show: state.mode === Mode.NoDataFound,
          noDatainfo:
            '<p>No connections to show! Create your first connection</p>',
          onClick: () => {
            setCrudModes(Add);
            setSelectedApp({ icon: '', id: '', name: 'Select' });
          },
          btnName: 'Create one',
        }}
        error={
          state.mode === Mode.Error
            ? { error: true, info: 'Server Error!<br/>Please try again later' }
            : { error: null, info: null }
        }
        render={(err: any, info: any) => {
          return (
            err && (
              <Error.PageLoadingError
                onClick={props.retry}
                info={info}
                btnName={'Retry'}
              />
            )
          );
        }}
      >
        <section className="cogni_cards">
          <div className="flow_canvas1 intent_inner_page">
            <HeaderChild type="first">
              <div className="flex_canvas1">
                <div className="flex_menu1 flow_group_home">
                  <div className="indent_dialog_left">
                    <div className="indent_title">
                      <BackButton onClick={() => window.history.back()}>
                        <span>
                          <ArrowIcon />
                        </span>{' '}
                        <label>Back</label>
                      </BackButton>

                      <div className="buton_back_content">
                        <div>
                          <h2>
                            {' '}
                            {state.mode == Mode.View && state.agent.name}
                            <IconButton
                              onClick={() => {
                                const agent = state as ConnectionViewT;
                                setCrudModes({
                                  ...AgentEdit,
                                  name: agent.agent.name,
                                  description: agent.agent.description,
                                  id: agent.agent.id,
                                });
                              }}
                            >
                              <span>
                                <EditIconWithCircle />
                              </span>
                            </IconButton>
                          </h2>
                          <p>
                            {state.mode == Mode.View && state.agent.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </HeaderChild>
          </div>
          <div className="flow_canvas1">
            <div className="flex_canvas1">
              <div className="flex_menu1  flow_group_home intent_header_background">
                {[
                  <HeaderChild type="first" key={0}>
                    <section className="entity__header">
                      <div className="entity__header_width">
                        <div className="entity__header_top">
                          <h4 className="indent_title">Connections</h4>
                          <SmallButton
                            style={{ marginLeft: 10 }}
                            primary
                            onClick={() => {
                              setCrudModes(Add);
                              setSelectedApp({
                                id: '',
                                name: 'Select',
                                icon: '',
                              });
                            }}
                          >
                            <span>
                              <AddIcon />
                            </span>
                            <label>Add</label>
                          </SmallButton>
                        </div>

                        <div className="entity__header_bottom">
                          <p>
                            Connect your on-prem application securely. Add new
                            or edit exiting app connections below
                          </p>
                        </div>
                      </div>
                    </section>
                  </HeaderChild>,
                ]}
              </div>
            </div>
          </div>
        </section>
        <section className="entity_cards" style={{ marginTop: 0 }}>
          <TabelWrapper>
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th size={3}>
                      <span>Connection</span>
                    </Th>
                    <Th size={4}>
                      <span>Description</span>
                    </Th>
                    <Th size={3}>
                      <span>Date</span>
                    </Th>
                    <Th size={2}></Th>
                  </Tr>
                </Thead>

                <div className="intent-inner-height">
                  <Tbody>
                    {state.mode == Mode.View &&
                      state.data.map(
                        (val: onPremConnectionListResponseT, index: number) => (
                          <ValueList
                            key={index}
                            value={val}
                            apps={state.apps}
                            workspacename={props.match.params.workspacename}
                            setToEdit={(
                              connection: onPremConnectionListResponseT
                            ) => {
                              setCrudModes({
                                ...Edit,
                                id: connection.id,
                                name: connection.name,
                                description: connection.description,
                                appId: connection.appId,
                              });
                              const apps = state as ConnectionViewT;
                              const filteredApp = apps.apps
                                ? apps.apps.filter(
                                    (app) => app.appId == connection.appId
                                  )
                                : [];
                              setSelectedApp(
                                filteredApp.length > 0
                                  ? {
                                      id: connection.appId,
                                      icon: filteredApp[0].icon,
                                      name: filteredApp[0].appName,
                                    }
                                  : {
                                      name: 'Select',
                                      id: '',
                                      icon: '',
                                    }
                              );
                            }}
                            setToDelete={(
                              connection: onPremConnectionListResponseT
                            ) => {
                              const list = state as ConnectionViewT;
                              setCrudModes({
                                ...DeleteLinkedAutomation,
                                id: connection.id,
                                name: connection.name,
                                automations:
                                  list.agent.linkedWithAutomationList,
                              });
                            }}
                          />
                        )
                      )}
                  </Tbody>
                </div>
              </Table>
            </TableContainer>
          </TabelWrapper>
        </section>
        {/* </section> */}
      </ErrorBoundary>
      {renderModal()}
    </>
  );
};

// const ValueList = (props: { selectEntityExample: Function }) => {
const ValueList = (props: {
  value: onPremConnectionListResponseT;
  workspacename: string;
  setToEdit: any;
  setToDelete: any;
  apps: ResponseOutputT[];
}) => {
  const { value, setToEdit, setToDelete, workspacename, apps } = props;

  const [state, setState] = useState<ConnectionModes>({
    ...ConnectionView,
    connection: { connected: false, connection: '' },
  });

  function testingConnectionWithAPI(authStoreMap: string, appId: string) {
    const app = apps.filter((app) => app.appId == value.appId && app.latest)[0];
    if (app) {
      setState(Loading);
      postJson(
        automationAPIURLWithWorkspace(workspacename, '/connection/connect'),
        {
          connectionId: authStoreMap,
          appId,
          version: app.version,
        }
      )
        .then((res) => {
          setState({
            ...ConnectionView,
            connection: { connected: true, connection: authStoreMap },
          });
        })
        .catch((err) => {
          setState({
            ...ErrorPage,
            error: {
              error: true,
              info: err.response
                ? err.response.data.error
                : 'Something went wrong!',
            },
          });
        });
    } else {
      setState({
        ...ErrorPage,
        error: {
          error: true,
          info: 'App not found! Please update connection.',
        },
      });
    }
  }

  return (
    <List
      showCheckBox
      checkBoxShow={false}
      render={(show: boolean, ToolBox: any) => {
        return (
          <Tr>
            <Td size={3} className=" entity_table_row_1 intent_table_row_1">
              <h4>{value.name}</h4>
            </Td>

            <Td size={4} className="entity_table_row_2 intent_table_row_2">
              <h4 className="word_break">{value.description}</h4>
            </Td>
            <Td size={3} className=" entity_table_row_2 intent_table_row_2">
              <span className="entity_span">
                {formatDateInCard(new Date(value.modifiedDate).toString())}
              </span>
              <span className="entity_span">|</span>
              <span className="entity_span">
                {formatAMPM(new Date(value.modifiedDate).toString())}
              </span>
            </Td>
            {/* <div className="col-md-2 entity_table_row_2 intent_table_row_2">
                                <span className="entity_span"></span>{' '}
                            </div> */}
            <Td size={2}>
              <div className="onprem_icons_wrapper">
                {state.mode == Mode.View &&
                state.connection &&
                state.connection.connected ? (
                  <span>Success</span>
                ) : (
                  <SmallButton
                    primary
                    className={
                      state.mode == Mode.Error || state.mode == Mode.View
                        ? ''
                        : 'editor_btn_disabled'
                    }
                    onClick={() => {
                      testingConnectionWithAPI(value.id, value.appId);
                    }}
                    disabled={
                      state.mode != Mode.Error && state.mode != Mode.View
                    }
                  >
                    <label>Test</label>
                  </SmallButton>
                )}
                {state.mode == Mode.Loading && (
                  <span className="connect_account_loading">
                    <img src={LoadingSvgImage} />
                  </span>
                )}

                {state.mode == Mode.View &&
                state.connection &&
                state.connection.connected ? (
                  <span className="connect_account_success">
                    <Tooltip
                      className="target customTip"
                      zIndex={10000}
                      arrowSize={8}
                      tagName="span"
                      content="Connection success"
                      distance={5}
                    >
                      <GreenTickIcon />
                    </Tooltip>
                  </span>
                ) : (
                  state.mode == Mode.Error && (
                    <span className="connect_account_failed">
                      <div className="error_input_show">
                        <Tooltip
                          className="target customTip"
                          zIndex={10000}
                          arrowSize={8}
                          tagName="span"
                          content={state.error && state.error.info}
                          distance={5}
                          direction="top center"
                        >
                          <WarningAlertIcon />
                        </Tooltip>
                      </div>
                    </span>
                  )
                )}
              </div>
              <div className="onprem-list-view-icon-status w-auto">
                <span
                  className=""
                  onClick={() => {
                    setToEdit(value);
                  }}
                >
                  <EditIcon />
                </span>
                <span
                  className="m-0 p-0"
                  onClick={() => {
                    setToDelete(value);
                  }}
                >
                  <DeleteIcon />
                </span>
              </div>
            </Td>
          </Tr>
        );
      }}
    />
  );
};
