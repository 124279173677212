export enum Action {
  View,
  ErrorPage,
  Loading,
  NoDataFound,
  Unauthorized,
  Select,
  LineDelete,
  Delete,
  Edit,
  Add,
  Create,
}

export type ViewT = {
  action: Action.View;
};
export type ErrorT = {
  action: Action.ErrorPage;
};

export type UnauthorizedT = {
  action: Action.Unauthorized;
};

export type LoadingT = {
  action: Action.Loading;
};

export type SelectT = {
  action: Action.Select;
  selectedItems: string[];
};
export type LineDeleteT = {
  action: Action.LineDelete;
  selectedItems: string[];
  name: string;
};
export type DeleteT = {
  action: Action.Delete;
  name: string;
};
export type CreateT={
  action:Action.Create;
}
export type SlackD = {
  added_by: string,
  integrated_at: string,
  is_active: boolean,
  mail_id: string,
  slack_id: string,
  slack_workspace: string,
}
export type WorkspaceT = {
  slack_team_name: string;
  date_added: string;
  active: string;
  slack_team_id: string;
  description: string;
  status: string;
  name: string;
  client_id: string;
  assistance_id: string;
  slack_deploy: null | SlackD;
  created_at:string;
  updated_at:string;
  client_secrect:string;
  verification_token:string;
  client_secret_active:boolean;
  verification_token_active:boolean;
  workspace:string
  id:number
};
export type EditT = {
  action: Action.Edit;
  name: string;
  description: string;
};
export type AddT = {
  action: Action.Add;
  name: string;
  description: string;
};
export type Modes =
  | ViewT
  | ErrorT
  | LoadingT
  | NoDataFoundT
  | UnauthorizedT
  | SelectT
  | LineDeleteT
  | DeleteT
  | EditT
  | AddT
  | CreateT

export interface ResponseT {
  intents: WorkspaceT[];
}
export interface RequestT {
  name: string;
  description: string;
}
export const View: ViewT = {
  action: Action.View,
};
export const ErrorPage: ErrorT = {
  action: Action.ErrorPage,
};

export const UnauthorizedPage: UnauthorizedT = {
  action: Action.Unauthorized,
};

export const Loading: LoadingT = {
  action: Action.Loading,
};
export const Create:CreateT={
  action:Action.Create
}

export const Add: AddT = { action: Action.Add, name: '', description: '' };
export const Edit: EditT = {
  action: Action.Edit,
  description: '',
  name: '',
};
export type DeployDataT = {
  team_name: string;
  team_id: string;
  is_active: boolean;
  date_added: string;
  added_by: null | string;
  email_id: null | string;
};

export type BotDataT = {
  bot_name: string;
  bot_full_name: string;
  bot_short_desc: string;
  bot_full_desc: string;
  is_generated: boolean;
  is_active: boolean;
};
export type TeamsWorkspaceT = {
  workspace: string;
  bot_id: string;
  app_id: string;
  app_password: string;
  tenant: string;
  teams_deploy: null | DeployDataT;
  bot_data: null | BotDataT;
  is_authorized: boolean;
  tenant_type: 'multi_tenant' | 'single_tenant';
  is_org_wide: boolean;
  name: string;
  description:string;
  updated_at: string;
  created_at: string;
  client_secret_active:boolean
  id:number
};

export const NoDataFound: NoDataFoundT = {
  action: Action.NoDataFound,
};

export type NoDataFoundT = {
  action: Action.NoDataFound;
};

export const mockData = [
  {
    id: '07f2e032-0b71-4c0d-8908-c49791084004',
    name: 'Workativ Assistants',
    description: 'Your workplace assistant',
    bot_id: '05c02c4d-50db-4199-bd0f-d04e7a7857cb',
    workspace: 'nirup',
    is_active: false,
    logo_url:
      'https://ac-image.s3.amazonaws.com/8/8/7/3/4/1/home/admin/images_for_product/logo.png?r=1663289093',
    primary_color: '#000000',
    secondary_color: '#E3F6FF',
    accent_color: '#FFE5D3',
    button_color: '#E3F6FF',
    is_secured: true,
    public_key: 'asdasd',
    created_at: '2021-06-04T13:31:32.000Z',
    updated_at: '2021-06-04T13:31:32.000Z',
    snippet:
      '<div id="assistant-widget-07f2e032-0b71-4c0d-8908-c49791084004"></div>\n  <script src="https://unpkg.com/assistant-widget@latest/dist/lib.js"></script>\n  <script>\n      WAWidget.init({\n          el: document.querySelector("#assistant-widget-07f2e032-0b71-4c0d-8908-c49791084004"),\n          endpoint: "http://dev-assistant.workativ.ai/widget",\n          widget_id: "07f2e032-0b71-4c0d-8908-c49791084004",\n          openByDefault: false\n      });\n  </script>\n  ',
  },
  {
    id: '0ce62c15-b7e6-47d6-a4cf-c34e8735aa07',
    name: 'Workativ Assistant',
    description: 'Your workplace assistant',
    bot_id: '25b56031-6719-486b-b103-908331b20467',
    workspace: 'nirup',
    is_active: false,
    logo_url:
      'https://ac-image.s3.amazonaws.com/8/8/7/3/4/1/home/admin/images_for_product/logo.png?r=1663289093',
    primary_color: '#000000',
    secondary_color: '#E3F6FF',
    accent_color: '#FFE5D3',
    button_color: '#E3F6FF',
    is_secured: true,
    public_key: 'jkkkkkkk',
    created_at: '2021-06-04T14:15:31.000Z',
    updated_at: '2021-06-04T14:15:31.000Z',
    snippet:
      '<div id="assistant-widget-0ce62c15-b7e6-47d6-a4cf-c34e8735aa07"></div>\n  <script src="https://unpkg.com/assistant-widget@latest/dist/lib.js"></script>\n  <script>\n      WAWidget.init({\n          el: document.querySelector("#assistant-widget-0ce62c15-b7e6-47d6-a4cf-c34e8735aa07"),\n          endpoint: "http://dev-assistant.workativ.ai/widget",\n          widget_id: "0ce62c15-b7e6-47d6-a4cf-c34e8735aa07",\n          openByDefault: false\n      });\n  </script>\n  ',
  },
  {
    id: '0cf1273a-c8ce-4960-a99f-3d54c32712cd',
    name: 'Workativ Assistant',
    description: 'Your workplace assistant',
    bot_id: '0c27fd9e-a8bc-4f86-a0a4-9b96183b8d97',
    workspace: 'nirup',
    is_active: false,
    logo_url:
      'https://ac-image.s3.amazonaws.com/8/8/7/3/4/1/home/admin/images_for_product/logo.png?r=1663289093',
    primary_color: '#000000',
    secondary_color: '#E3F6FF',
    accent_color: '#FFE5D3',
    button_color: '#E3F6FF',
    is_secured: true,
    public_key: 'sssssddd',
    created_at: '2021-06-04T13:29:13.000Z',
    updated_at: '2021-06-04T13:29:13.000Z',
    snippet:
      '<div id="assistant-widget-0cf1273a-c8ce-4960-a99f-3d54c32712cd"></div>\n  <script src="https://unpkg.com/assistant-widget@latest/dist/lib.js"></script>\n  <script>\n      WAWidget.init({\n          el: document.querySelector("#assistant-widget-0cf1273a-c8ce-4960-a99f-3d54c32712cd"),\n          endpoint: "http://dev-assistant.workativ.ai/widget",\n          widget_id: "0cf1273a-c8ce-4960-a99f-3d54c32712cd",\n          openByDefault: false\n      });\n  </script>\n  ',
  },
  {
    id: '1b4902d1-2547-4fef-84b6-9c818ed673a6',
    name: 'Workativ Assistants',
    description: 'Your workplace assistant',
    bot_id: '571466d3-9bd3-4c55-b802-eb295bcf1a8c',
    workspace: 'nirup',
    is_active: false,
    logo_url:
      'https://ac-image.s3.amazonaws.com/8/8/7/3/4/1/home/admin/images_for_product/logo.png?r=1663289093',
    primary_color: '#000000',
    secondary_color: '#E3F6FF',
    accent_color: '#FFE5D3',
    button_color: '#E3F6FF',
    is_secured: false,
    public_key: null,
    created_at: '2021-06-18T05:50:23.000Z',
    updated_at: '2021-06-18T05:50:23.000Z',
    snippet:
      '<div id="assistant-widget-1b4902d1-2547-4fef-84b6-9c818ed673a6"></div>\n  <script src="https://unpkg.com/assistant-widget@latest/dist/lib.js"></script>\n  <script>\n      WAWidget.init({\n          el: document.querySelector("#assistant-widget-1b4902d1-2547-4fef-84b6-9c818ed673a6"),\n          endpoint: "http://dev-assistant.workativ.ai/widget",\n          widget_id: "1b4902d1-2547-4fef-84b6-9c818ed673a6",\n          openByDefault: false\n      });\n  </script>\n  ',
  },
  {
    id: '1bf2fefc-3b28-4705-a192-20b71decf8c8',
    name: 'Workativ Assistant',
    description: 'Your workplace assistant',
    bot_id: '2f12165f-43b0-44ca-af17-8ff4c591bed7',
    workspace: 'nirup',
    is_active: false,
    logo_url:
      'https://ac-image.s3.amazonaws.com/8/8/7/3/4/1/home/admin/images_for_product/logo.png?r=1663289093',
    primary_color: '#000000',
    secondary_color: '#E3F6FF',
    accent_color: '#FFE5D3',
    button_color: '#E3F6FF',
    is_secured: false,
    public_key: null,
    created_at: '2021-10-07T11:27:02.000Z',
    updated_at: '2021-10-07T11:27:02.000Z',
    snippet:
      '<div id="assistant-widget-1bf2fefc-3b28-4705-a192-20b71decf8c8"></div>\n  <script src="https://unpkg.com/assistant-widget@latest/dist/lib.js"></script>\n  <script>\n      WAWidget.init({\n          el: document.querySelector("#assistant-widget-1bf2fefc-3b28-4705-a192-20b71decf8c8"),\n          endpoint: "http://dev-assistant.workativ.ai/widget",\n          widget_id: "1bf2fefc-3b28-4705-a192-20b71decf8c8",\n          openByDefault: false\n      });\n  </script>\n  ',
  },
  {
    id: '2c99885c-b814-452f-b9d9-a8c516967860',
    name: 'Workativ Assistant',
    description: 'Your workplace assistant',
    bot_id: '0c27fd9e-a8bc-4f86-a0a4-9b96183b8d97',
    workspace: 'nirup',
    is_active: true,
    logo_url:
      'https://ac-image.s3.amazonaws.com/8/8/7/3/4/1/home/admin/images_for_product/logo.png?r=1663289093',
    primary_color: '#000000',
    secondary_color: '#E3F6FF',
    accent_color: '#FFE5D3',
    button_color: '#E3F6FF',
    is_secured: false,
    public_key: null,
    created_at: '2021-10-08T04:39:33.000Z',
    updated_at: '2021-10-08T04:39:33.000Z',
    snippet:
      '<div id="assistant-widget-2c99885c-b814-452f-b9d9-a8c516967860"></div>\n  <script src="https://unpkg.com/assistant-widget@latest/dist/lib.js"></script>\n  <script>\n      WAWidget.init({\n          el: document.querySelector("#assistant-widget-2c99885c-b814-452f-b9d9-a8c516967860"),\n          endpoint: "http://dev-assistant.workativ.ai/widget",\n          widget_id: "2c99885c-b814-452f-b9d9-a8c516967860",\n          openByDefault: false\n      });\n  </script>\n  ',
  },
  {
    id: '330cf94f-1c7a-48c7-8e41-b406f6c785dd',
    name: 'Workativ Assistant',
    description: 'Your workplace assistant',
    bot_id: '05c02c4d-50db-4199-bd0f-d04e7a7857cb',
    workspace: 'nirup',
    is_active: false,
    logo_url:
      'https://ac-image.s3.amazonaws.com/8/8/7/3/4/1/home/admin/images_for_product/logo.png?r=1663289093',
    primary_color: '#000000',
    secondary_color: '#E3F6FF',
    accent_color: '#FFE5D3',
    button_color: '#E3F6FF',
    is_secured: true,
    public_key: 'KK',
    created_at: '2021-06-07T06:23:41.000Z',
    updated_at: '2021-06-07T06:23:41.000Z',
    snippet:
      '<div id="assistant-widget-330cf94f-1c7a-48c7-8e41-b406f6c785dd"></div>\n  <script src="https://unpkg.com/assistant-widget@latest/dist/lib.js"></script>\n  <script>\n      WAWidget.init({\n          el: document.querySelector("#assistant-widget-330cf94f-1c7a-48c7-8e41-b406f6c785dd"),\n          endpoint: "http://dev-assistant.workativ.ai/widget",\n          widget_id: "330cf94f-1c7a-48c7-8e41-b406f6c785dd",\n          openByDefault: false\n      });\n  </script>\n  ',
  },
  {
    id: '3f5230b2-c7bb-48c1-a1bd-edb20a967eb4',
    name: 'Workativ Assistants',
    description: 'Your workplace assistant',
    bot_id: '05c02c4d-50db-4199-bd0f-d04e7a7857cb',
    workspace: 'nirup',
    is_active: false,
    logo_url:
      'https://ac-image.s3.amazonaws.com/8/8/7/3/4/1/home/admin/images_for_product/logo.png?r=1663289093',
    primary_color: '#000000',
    secondary_color: '#E3F6FF',
    accent_color: '#FFE5D3',
    button_color: '#E3F6FF',
    is_secured: true,
    public_key: 'sample',
    created_at: '2021-06-07T04:45:48.000Z',
    updated_at: '2021-06-07T04:45:48.000Z',
    snippet:
      '<div id="assistant-widget-3f5230b2-c7bb-48c1-a1bd-edb20a967eb4"></div>\n  <script src="https://unpkg.com/assistant-widget@latest/dist/lib.js"></script>\n  <script>\n      WAWidget.init({\n          el: document.querySelector("#assistant-widget-3f5230b2-c7bb-48c1-a1bd-edb20a967eb4"),\n          endpoint: "http://dev-assistant.workativ.ai/widget",\n          widget_id: "3f5230b2-c7bb-48c1-a1bd-edb20a967eb4",\n          openByDefault: false\n      });\n  </script>\n  ',
  },
  {
    id: '509083fe-90cf-4de2-8bf5-3d6db7e42da7',
    name: 'Workativ Assistant',
    description: 'Your workplace assistant',
    bot_id: '05c02c4d-50db-4199-bd0f-d04e7a7857cb',
    workspace: 'nirup',
    is_active: false,
    logo_url:
      'https://assistant-workativ-fileupload.s3.amazonaws.com/1623411026068',
    primary_color: '#000000',
    secondary_color: '#E3F6FF',
    accent_color: '#FFE5D3',
    button_color: '#E3F6FF',
    is_secured: false,
    public_key: null,
    created_at: '2021-06-11T07:36:16.000Z',
    updated_at: '2021-06-11T07:36:16.000Z',
    snippet:
      '<div id="assistant-widget-509083fe-90cf-4de2-8bf5-3d6db7e42da7"></div>\n  <script src="https://unpkg.com/assistant-widget@latest/dist/lib.js"></script>\n  <script>\n      WAWidget.init({\n          el: document.querySelector("#assistant-widget-509083fe-90cf-4de2-8bf5-3d6db7e42da7"),\n          endpoint: "http://dev-assistant.workativ.ai/widget",\n          widget_id: "509083fe-90cf-4de2-8bf5-3d6db7e42da7",\n          openByDefault: false\n      });\n  </script>\n  ',
  },
  {
    id: '78b8df4e-e77d-4d70-a1bb-24ce5ddf36ed',
    name: 'Workativ Assistants',
    description: 'Your workplace assistant',
    bot_id: '571466d3-9bd3-4c55-b802-eb295bcf1a8c',
    workspace: 'nirup',
    is_active: false,
    logo_url:
      'https://assistant-workativ-fileupload.s3.ap-south-1.amazonaws.com/1616665995884',
    primary_color: '#FFFFFF',
    secondary_color: '#D4E8FE',
    accent_color: '#3789E1',
    button_color: '#F07F31',
    is_secured: false,
    public_key: null,
    created_at: '2021-03-25T09:53:26.000Z',
    updated_at: '2021-03-25T09:53:26.000Z',
    snippet:
      '<div id="assistant-widget-78b8df4e-e77d-4d70-a1bb-24ce5ddf36ed"></div>\n  <script src="https://unpkg.com/assistant-widget@latest/dist/lib.js"></script>\n  <script>\n      WAWidget.init({\n          el: document.querySelector("#assistant-widget-78b8df4e-e77d-4d70-a1bb-24ce5ddf36ed"),\n          endpoint: "http://dev-assistant.workativ.ai/widget",\n          widget_id: "78b8df4e-e77d-4d70-a1bb-24ce5ddf36ed",\n          openByDefault: false\n      });\n  </script>\n  ',
  },
  {
    id: '95925156-5cf9-4db5-87b8-2b5f04e4c8d5',
    name: 'Workativ Assistant',
    description: 'Your workplace assistant',
    bot_id: 'dc432576-df3a-4e67-a237-12fd0c5fceed',
    workspace: 'nirup',
    is_active: true,
    logo_url:
      'https://ac-image.s3.amazonaws.com/8/8/7/3/4/1/home/admin/images_for_product/logo.png?r=1663289093',
    primary_color: '#000000',
    secondary_color: '#E3F6FF',
    accent_color: '#FFE5D3',
    button_color: '#E3F6FF',
    is_secured: false,
    public_key: null,
    created_at: '2021-10-12T09:30:22.000Z',
    updated_at: '2021-10-12T09:30:22.000Z',
    snippet:
      '<div id="assistant-widget-95925156-5cf9-4db5-87b8-2b5f04e4c8d5"></div>\n  <script src="https://unpkg.com/assistant-widget@latest/dist/lib.js"></script>\n  <script>\n      WAWidget.init({\n          el: document.querySelector("#assistant-widget-95925156-5cf9-4db5-87b8-2b5f04e4c8d5"),\n          endpoint: "http://dev-assistant.workativ.ai/widget",\n          widget_id: "95925156-5cf9-4db5-87b8-2b5f04e4c8d5",\n          openByDefault: false\n      });\n  </script>\n  ',
  },
  {
    id: 'e0cf516e-f0f3-4402-9739-2b4e57836aae',
    name: 'Workativ Assistantaa',
    description: 'Your workplace assistant',
    bot_id: '05c02c4d-50db-4199-bd0f-d04e7a7857cb',
    workspace: 'nirup',
    is_active: false,
    logo_url:
      'https://ac-image.s3.amazonaws.com/8/8/7/3/4/1/home/admin/images_for_product/logo.png?r=1663289093',
    primary_color: '#000000',
    secondary_color: '#E3F6FF',
    accent_color: '#FFE5D3',
    button_color: '#E3F6FF',
    is_secured: false,
    public_key: null,
    created_at: '2021-06-18T05:49:01.000Z',
    updated_at: '2021-06-18T05:49:01.000Z',
    snippet:
      '<div id="assistant-widget-e0cf516e-f0f3-4402-9739-2b4e57836aae"></div>\n  <script src="https://unpkg.com/assistant-widget@latest/dist/lib.js"></script>\n  <script>\n      WAWidget.init({\n          el: document.querySelector("#assistant-widget-e0cf516e-f0f3-4402-9739-2b4e57836aae"),\n          endpoint: "http://dev-assistant.workativ.ai/widget",\n          widget_id: "e0cf516e-f0f3-4402-9739-2b4e57836aae",\n          openByDefault: false\n      });\n  </script>\n  ',
  },
  {
    id: 'eebcf327-ab1e-4a54-991b-8b3d75429392',
    name: 'Widget CSS test',
    description: 'Widget CSS test',
    bot_id: 'a78bffdd-2eda-4482-ab50-0a094d7eee5e',
    workspace: 'nirup',
    is_active: true,
    logo_url:
      'https://ac-image.s3.amazonaws.com/8/8/7/3/4/1/home/admin/images_for_product/logo.png?r=1663289093',
    primary_color: '#000000',
    secondary_color: '#E3F6FF',
    accent_color: '#FFE5D3',
    button_color: '#E3F6FF',
    is_secured: false,
    public_key: null,
    created_at: '2021-10-26T06:18:26.000Z',
    updated_at: '2021-10-26T06:18:26.000Z',
    snippet:
      '<div id="assistant-widget-eebcf327-ab1e-4a54-991b-8b3d75429392"></div>\n  <script src="https://unpkg.com/assistant-widget@latest/dist/lib.js"></script>\n  <script>\n      WAWidget.init({\n          el: document.querySelector("#assistant-widget-eebcf327-ab1e-4a54-991b-8b3d75429392"),\n          endpoint: "http://dev-assistant.workativ.ai/widget",\n          widget_id: "eebcf327-ab1e-4a54-991b-8b3d75429392",\n          openByDefault: false\n      });\n  </script>\n  ',
  },
  {
    id: 'fdfec1ce-fc01-44f9-afe0-113b8b045004',
    name: 'Workativ Assistant',
    description: 'Your workplace assistant',
    bot_id: '2f12165f-43b0-44ca-af17-8ff4c591bed7',
    workspace: 'nirup',
    is_active: false,
    logo_url:
      'https://ac-image.s3.amazonaws.com/8/8/7/3/4/1/home/admin/images_for_product/logo.png?r=1663289093',
    primary_color: '#000000',
    secondary_color: '#E3F6FF',
    accent_color: '#FFE5D3',
    button_color: '#E3F6FF',
    is_secured: false,
    public_key: null,
    created_at: '2021-10-07T05:06:46.000Z',
    updated_at: '2021-10-07T05:06:46.000Z',
    snippet:
      '<div id="assistant-widget-fdfec1ce-fc01-44f9-afe0-113b8b045004"></div>\n  <script src="https://unpkg.com/assistant-widget@latest/dist/lib.js"></script>\n  <script>\n      WAWidget.init({\n          el: document.querySelector("#assistant-widget-fdfec1ce-fc01-44f9-afe0-113b8b045004"),\n          endpoint: "http://dev-assistant.workativ.ai/widget",\n          widget_id: "fdfec1ce-fc01-44f9-afe0-113b8b045004",\n          openByDefault: false\n      });\n  </script>\n  ',
  },
];
