/*eslint-disable*/
import React, { useEffect } from 'react'

import { useFetchFromApi } from '@/common/hooks/usefetch-from-api'

import { AutomationOnPremApi, automationAPIURLWithWorkspace, wrapperGetJson } from '@/common/utils/api-utils'
import { OnPremConnections } from './onPremConnections'

export function onPremConnectionsWrapper(props: any) {
    const { workspacename, id } = props.match.params

    const [state, setState, promiseRef] = useFetchFromApi(() =>
        wrapperGetJson(
            automationAPIURLWithWorkspace(workspacename, `${AutomationOnPremApi.Update}${id}/connection`)
        ).then((response: any) => response.data)
    )

    useEffect(() => {
        promiseRef.current = wrapperGetJson(
            automationAPIURLWithWorkspace(workspacename, `${AutomationOnPremApi.Update}${id}/connection`)
        ).then((response: any) => response.data)
    }, [props.match.params.id])

    useEffect(() => {
        if (promiseRef.current != null) {
            const setStateDispatch = setState as React.Dispatch<any>
            setStateDispatch(promiseRef.current ? promiseRef.current.then((response: any) => response.data) : null)
        }
    }, [promiseRef.current])

    const retry = () => {
        const setStateDispatch = setState as React.Dispatch<any>
        // setStateDispatch(promiseRef.current ? promiseRef.current.then((response: any) => response.data) : null)
        promiseRef.current = null
        setStateDispatch(
            wrapperGetJson(
                automationAPIURLWithWorkspace(workspacename, `${AutomationOnPremApi.Update}${id}/connection`)
            ).then((response: any) => response.data)
        )
    }

    return <OnPremConnections state={state} retry={retry} {...props} />
}
