/*eslint-disable*/

import * as R from 'ramda'
import { useRef, useState } from 'react'

import { useInterval } from '@/common/hooks/useInterval'

import { getJson, loginApiURL, postJson } from '@/common/utils/api-utils'
import { Action, NotificationP } from './types'

export const notificationUrl = () => {
    return process.env.REACT_APP_NOTIFICATION_API
}
export const shouldPollForNotification = () => {
    const shouldPoll = R.isNil(process.env.REACT_APP_SHOULD_POLL_FOR_NOTIFICATION)
        ? false
        : (JSON.parse(process.env.REACT_APP_SHOULD_POLL_FOR_NOTIFICATION) as string)
    return shouldPoll
}
export const notificationPollingTime = () => {
    return Number(process.env.REACT_APP_NOTIFICATION_POLLING_TIME)
}

const mapActionForNotificationType = (notification: NotificationP, workspace: string): Action => {
    switch (notification.type) {
        case 'Create Automation':
            return {
                url: 'hello',
                action: 'redirect'
            }
        default:
            return { action: 'None' }
    }
}

const executeAction = (action: Action) => {
    switch (action.action) {
        case 'redirect': {
            window.location.href = action.url
            break
        }
        case 'None': {
            console.log('Action')
            break
        }
        default:
            console.log('Action')
    }
}

const executeActionForNotification = (noti: NotificationP, workspace: string) => {
    executeAction(mapActionForNotificationType(noti, workspace))
}

type NotificationOperations = {
    notificationClick: (noti: NotificationP) => Promise<void>
    markAllRead: () => Promise<void>
    markSeen: () => Promise<void>
    fetchNotification: (when: string) => Promise<void>
}

type ParsedString = {
    stringValue: string
    rawString: string
    adjustedLength: number
}

function addAdditionalMetaData(users: any, metaData: any) {
    if (metaData.type === 'admin') {
        const user = users.filter((x: any) => x.WorkspaceAccess.accessLevel == 'admin')[0]
        metaData.value = user ? `${user.first_name} ${user.last_name}` : ''
    }

    if (metaData.keyName === 'userFullName') {
        const user = users.filter((x: any) => x.id == metaData.id)[0]
        metaData.value = user ? `${user.first_name} ${user.last_name}` : ''
    }

    return Promise.resolve(metaData)
}

const modifierFunction = (users: any) => (notification: any) => {
    const metaData = notification.metadata
    const message = notification.message
    const status = notification.status

    const metaVariablesWithKeyNames = R.map(([name, value]: [string, any]) => {
        const newValue = value.positions.map((positions: any) => {
            return {
                ...value,
                end: positions.end,
                start: positions.start,
                keyName: name,
                type: value.type,
                highLight: value.highLight
            }
        })

        return newValue
    }, Object.entries(metaData.variables))
    const flattenedVariables = R.flatten(metaVariablesWithKeyNames)

    const flattenedMetaVariables = {
        message: message,
        status: status,
        createdAt: new Date(notification.createdAt),
        variables: R.sortBy((x: any) => R.prop('start')(x), flattenedVariables)
    }

    const reduceFunction = (accum: Promise<ParsedString>, currentMetaVariable: any) => {
        const metaVariableWithValue = Promise.resolve(currentMetaVariable).then(currentMetaVariable => {
            if (currentMetaVariable.type == 'admin') {
                return addAdditionalMetaData(users, currentMetaVariable)
            } else if (currentMetaVariable.value) {
                return currentMetaVariable
            } else {
                return addAdditionalMetaData(users, currentMetaVariable)
            }
        })
        const value = Promise.all([metaVariableWithValue, accum]).then(([metaVariable, accum]) => {
            const replacedString = `${accum.stringValue.substring(0, metaVariable.start + accum.adjustedLength)}<b>${
                metaVariable.value
            }</b>${accum.stringValue.substring(metaVariable.end + 1 + accum.adjustedLength)}`

            const replacedRawString = `${accum.stringValue.substring(0, metaVariable.start + accum.adjustedLength)}${
                metaVariable.value
            }${accum.stringValue.substring(metaVariable.end + 1 + accum.adjustedLength)}`

            return {
                stringValue: replacedString,
                rawString: replacedRawString,
                adjustedLength: accum.adjustedLength + replacedString.length - accum.stringValue.length
            }
        })
        return value
    }

    const z = flattenedMetaVariables.variables.reduce(
        reduceFunction,
        Promise.resolve({ stringValue: flattenedMetaVariables.message, rawString: '', adjustedLength: 0 })
    )
    return z.then((x: ParsedString) => ({
        ...notification,
        message: x.stringValue,
        rawString: x.rawString
    }))
}

export function useNotifications(
    pauseCondition: boolean,
    workspaceName: string
): [NotificationP[], NotificationOperations, number, boolean] {
    const [notifications, setNotifications] = useState<NotificationP[]>([])
    const pageNumber = useRef(0)
    const paginationLimit = 20
    const hasMore = useRef(true)
    const getInitial = useRef(true)
    // const resetPage = useRef(true)

    const fetchNotification = async (when: string) => {
        console.log('fetchNotification ::: ', when)
        function callUserListApi() {
            return getJson(loginApiURL(`/access/${workspaceName}/users`))
        }
        if (when == 'initial') {
            let tempPageNumber = getInitial.current === true ? 0 : pageNumber.current
            getInitial.current = getInitial.current === true ? false : true

            getJson(
                notificationUrl() +
                    '/notifications' +
                    `?q={}&sort=-createdAt&pagination={"page_limit" : ${paginationLimit},"page_number" : ${tempPageNumber}}`
            )
                .then((initialNotification: NotificationP[]) => {
                    pageNumber.current = pageNumber.current + 1
                    return initialNotification
                        .filter(noti => notifications.map(x => x.id).indexOf(noti.id) == -1)
                        .map(x => ({ ...x, createdAt: new Date(x.createdAt) }))
                })
                .then((notiArray: NotificationP[]) => {
                    return callUserListApi().then(users => Promise.all(notiArray.map(modifierFunction(users))))
                })
                .then((notification: NotificationP[]) => {
                    const allNotifications: NotificationP[] = [...notifications, ...notification]
                    if (allNotifications.length === 0) {
                        pageNumber.current = 0
                    } else if (notifications.length === allNotifications.length) {
                        pageNumber.current = 0
                        hasMore.current = false
                    }
                    const sortedNotifications: NotificationP[] = R.sort(
                        R.descend((noti: NotificationP) => noti.createdAt.getTime()),
                        allNotifications
                    )
                    setNotifications(sortedNotifications)
                })
        } else {
            pageNumber.current = pageNumber.current + 1
            getJson(
                notificationUrl() +
                    '/notifications' +
                    `?q={}&sort=-createdAt&pagination={"page_limit" : ${paginationLimit},"page_number" : ${pageNumber.current}}`
            )
                .then((newNotification: NotificationP[]) => {
                    return newNotification
                        .filter(noti => notifications.map(x => x.id).indexOf(noti.id) == -1)
                        .map(x => ({ ...x, createdAt: new Date(x.createdAt) }))
                })
                .then((notiArray: NotificationP[]) => {
                    return callUserListApi().then(users => Promise.all(notiArray.map(modifierFunction(users))))
                })
                .then((notification: NotificationP[]) => {
                    const allNotifications: NotificationP[] = [...notifications, ...notification]
                    const sortedNotifications: NotificationP[] = R.sort(
                        R.descend((noti: NotificationP) => noti.createdAt.getTime()),
                        allNotifications
                    )
                    setNotifications(sortedNotifications)
                })
        }
    }

    const notificationClick = async (noti: NotificationP) => {
        postJson(notificationUrl() + '/notifications' + '/update/status/read' + `?q={"id":"${noti.id}"}`)({}).then(
            _ => {
                const readNotification = notifications.map(n => {
                    if (noti.id === n.id) {
                        n.status = 'read'
                    }
                    return n
                })
                setNotifications(readNotification)
            }
        )
    }

    const markAllRead = async () => {
        console.log('Mark read called')
        Promise.all(
            notifications
                .filter(notification => notification.status != 'read')
                .map(notification =>
                    postJson(
                        notificationUrl() + '/notifications' + '/update/status/read' + `?q={"id":"${notification.id}"}`
                    )({})
                )
        ).then(_ => {
            const readNotification = notifications.map((noti: NotificationP) => {
                noti.status = 'read'
                return noti
            })
            setNotifications(readNotification)
        })
    }

    const markSeen = async () => {
        console.log('Mark seen called')
        Promise.all(
            notifications
                .filter(notification => notification.status != 'read')
                .map(notification =>
                    postJson(
                        notificationUrl() + '/notifications' + '/update/status/read' + `?q={"id":"${notification.id}"}`
                    )({})
                )
        ).then(_ => {
            const seenNotifications = notifications.map((noti: NotificationP) => {
                if (noti.status === 'unread') {
                    noti.status = 'read'
                }
                return noti
            })
            pageNumber.current = 0
            hasMore.current = true
            setNotifications(seenNotifications)
        })
    }

    const operations = {
        markAllRead,
        markSeen,
        notificationClick,
        fetchNotification
    }

    if (shouldPollForNotification()) {
        const pollingTime = notificationPollingTime() ? notificationPollingTime() : 15000

        useInterval(() => {
            fetchNotification('initial')
        }, pollingTime)
    }

    return [notifications, operations, notifications.filter(x => x.status === 'unread').length, hasMore.current]
}
