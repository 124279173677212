/*eslint-disable*/
import React, { useEffect, useState } from 'react'

import { ErrorIcon } from '@/common/Icons/Icons'
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons'
//COMMON COMPONENTS IMPORT
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import Validate, { ErrorContext } from '@/common/components/FormValidate'
import InputBox from '@/common/components/InputBox'
import { Modal } from '@/common/components/Modal'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import { ButtonWrapper, Paragraph } from '@/common/styled/Dialogue.Dumb'
import {
    ErrorMessage,
    Input,
    InputContainer,
    InputContainerForTextArea,
    InputContent,
    InputErrorIcon,
    InputWrapper,
    TextareaV2
} from '@/common/styled/Workflow.Analytics.Dumb'
import { inputTextAreaValidations, intentValidation, newLine } from '@/common/utils/_validation-utils'
import { sortAnArrayByDate, sortAnArrayByName } from '@/common/utils/common.utils'

//TYPES IMPORT
import { ActionT } from '../kind'
import { IntentT, PageStateT } from './kind'

enum ModelActionE {
    ADD,
    EDIT
}

type ADDT = {
    View: ModelActionE.ADD
}

type EDITT = {
    View: ModelActionE.EDIT
}

export const ADD: ADDT = { View: ModelActionE.ADD }

export const EDIT: EDITT = { View: ModelActionE.EDIT }

type ModelActionT = ADDT | EDITT

const IntentModel = ({
    Action,
    disable,
    duplicate,
    headerText,
    headerDescription,
    nameValue,
    NamePlaceholder,
    onNameEdit,
    DescriptionValue,
    DescriptionPlaceholder,
    onDescriptionEdit,
    onCancel,
    onSave,
    saveText
}: {
    Action: ModelActionT
    disable: boolean
    duplicate: boolean
    headerText: string
    headerDescription: string
    nameValue: string
    NamePlaceholder: string
    onNameEdit: Function
    DescriptionValue: string
    DescriptionPlaceholder: string
    onDescriptionEdit: Function
    onCancel: Function
    onSave: Function
    saveText: string
}) => {
    let inputRef: any = null

    const [updatedEle, setUpdatedEle] = useState(false)

    useEffect(() => {
        inputRef && inputRef.focus()
    }, [])

    return (
        <Validate defaultValue={Action.View == ModelActionE.ADD ? { name: false, description: true } : { name: true }}>
            <h2>{headerText}</h2>
            <Paragraph margin>{headerDescription}</Paragraph>
            <div>
                <InputBox
                    validationList={intentValidation}
                    value={nameValue}
                    name="name"
                    render={(
                        value: string,
                        valid: boolean,
                        errorMsg: string,
                        startValidation: () => void,
                        handleInput: Function
                    ) => {
                        return (
                            <React.Fragment>
                                <InputWrapper>
                                    <ErrorMessage>{!valid ? errorMsg : newLine}</ErrorMessage>
                                    <InputContainer>
                                        <Input
                                            primary={!valid ? `primary` : ''}
                                            type="text"
                                            // placeholder={NamePlaceholder}
                                            value={value}
                                            ref={(node: any) => (inputRef = node)}
                                            onChange={(e: any) => {
                                                startValidation(), handleInput('name', e.target.value)
                                                onNameEdit(e.target.value)
                                                setUpdatedEle(Action.View == ModelActionE.EDIT && true)
                                            }}
                                        />
                                        <InputContent>{NamePlaceholder}</InputContent>
                                        {!valid && (
                                            <InputErrorIcon>
                                                <ErrorIcon />
                                            </InputErrorIcon>
                                        )}
                                    </InputContainer>
                                </InputWrapper>
                                {duplicate ? (
                                    <div className="error_input_show intent">
                                        <WarningAlertIcon />
                                        <span className="error_menu_hvr">
                                            Duplicate intent cannot be saved. Modify the intent name.
                                        </span>
                                    </div>
                                ) : null}
                            </React.Fragment>
                        )
                    }}
                />

                <InputBox
                    validationList={inputTextAreaValidations}
                    value={DescriptionValue}
                    name="description"
                    render={(
                        value: string,
                        valid: boolean,
                        errorMsg: string,
                        startValidation: () => void,
                        handleInput: Function
                    ) => {
                        return (
                            <React.Fragment>
                                <InputWrapper>
                                    <ErrorMessage>{!valid ? errorMsg : newLine}</ErrorMessage>
                                    <InputContainerForTextArea style={{marginTop: 10}}>
                                        <TextareaV2
                                            // placeholder={DescriptionPlaceholder}
                                            value={value}
                                            onChange={(e: any) => {
                                                startValidation(), handleInput('description', e.target.value)
                                                onDescriptionEdit(e.target.value)
                                            }}
                                        />
                                        <InputContent>{DescriptionPlaceholder}</InputContent>
                                        {/* <p className="error_msg">{!valid ? errorMsg : newLine}</p> */}
                                        {!valid && (
                                            <InputErrorIcon>
                                                <ErrorIcon />
                                            </InputErrorIcon>
                                        )}
                                    </InputContainerForTextArea>
                                </InputWrapper>
                            </React.Fragment>
                        )
                    }}
                />

                <ErrorContext.Consumer>
                    {valid => {
                        return (
                            <React.Fragment>
                                <ButtonWrapper>
                                    <Button primary type="button" onClick={() => onCancel()}>
                                        <label>Cancel</label>
                                    </Button>
                                    <Button
                                        type="button"
                                        disabled={!valid.formValid.formValid || disable || duplicate}
                                        className={
                                            !valid.formValid.formValid || disable || duplicate
                                                ? 'editor_btn_disabled'
                                                : ''
                                        }
                                        onClick={() => onSave()}
                                    >
                                        <label>{saveText}</label>
                                    </Button>
                                </ButtonWrapper>
                            </React.Fragment>
                        )
                    }}
                </ErrorContext.Consumer>
            </div>
        </Validate>
    )
}

export const IntentForm = ({
    intents,
    PageState,
    setPageState,
    save,
    reset
}: {
    intents: IntentT[]
    PageState: PageStateT
    setPageState: React.Dispatch<React.SetStateAction<PageStateT>>
    save: Function
    reset: Function
}) => {
    const [loading, setLoading] = useState<boolean>(false)

    const [ModelError, setModelError] = useState<{
        error: any
        info: any
    }>({ error: null, info: null })

    const saveIntent = () => {
        setLoading(true)
        save(
            () => setLoading(false),
            (reson: string) => {
                setLoading(false)
                setModelError({ error: true, info: reson })
            }
        )
    }

    if (PageState.action === ActionT.NO_DATA_FOUND && PageState.loading) {
        return (
            <Modal error={ModelError} showPopupLoader={loading}>
                <ErrorBoundary
                    error={ModelError}
                    render={(err: any, info: any) => {
                        return (
                            err && (
                                <Error.AddorEdit
                                    onClick={() => setModelError({ error: null, info: null })}
                                    info={info}
                                />
                            )
                        )
                    }}
                >
                    <IntentModel
                        disable={false}
                        duplicate={
                            sortAnArrayByName(intents, 'intent').filter(
                                e => e.intent.toLowerCase() == PageState.intent.toLowerCase()
                            ).length > 0
                        }
                        Action={ADD}
                        headerText={'Create new Intent'}
                        headerDescription={'Enter name and description '}
                        nameValue={PageState.intent}
                        NamePlaceholder={'Intent name'}
                        onNameEdit={(val: string) =>
                            setPageState(page => ({
                                ...page,
                                intent: val
                            }))
                        }
                        DescriptionValue={PageState.description}
                        DescriptionPlaceholder={'Intent description'}
                        onDescriptionEdit={(val: string) =>
                            setPageState(page => ({
                                ...page,
                                description: val
                            }))
                        }
                        onCancel={reset}
                        onSave={saveIntent}
                        saveText={'Create'}
                    />
                </ErrorBoundary>
            </Modal>
        )
    } else if (PageState.action == ActionT.ADD || PageState.action == ActionT.EDIT) {
        return (
            <Modal error={ModelError} showPopupLoader={loading}>
                <ErrorBoundary
                    error={ModelError}
                    render={(err: any, info: any) => {
                        return (
                            err && (
                                <Error.AddorEdit
                                    onClick={() => setModelError({ error: null, info: null })}
                                    info={info}
                                />
                            )
                        )
                    }}
                >
                    <IntentModel
                        disable={(() => {
                            if (PageState.action === ActionT.EDIT) {
                                const editI = intents.filter(
                                    e =>
                                        e.id !== PageState.id &&
                                        e.intent.toLowerCase() == PageState.intent.toLowerCase()
                                )

                                return editI.length > 0
                            } else if (PageState.action === ActionT.ADD) {
                                return (
                                    intents.filter(
                                        e => e.intent.trim().toLowerCase() === PageState.intent.trim().toLowerCase()
                                    ).length > 0
                                )
                            }
                            return false
                        })()}
                        duplicate={(() => {
                            if (PageState.action === ActionT.EDIT) {
                                const editI = intents.filter(
                                    e =>
                                        e.id !== PageState.id &&
                                        e.intent.toLowerCase() == PageState.intent.toLowerCase()
                                )

                                return editI.length > 0
                            } else if (PageState.action === ActionT.ADD) {
                                return (
                                    intents.filter(
                                        e => e.intent.trim().toLowerCase() === PageState.intent.trim().toLowerCase()
                                    ).length > 0
                                )
                            }
                            return false
                        })()}
                        Action={PageState.action == ActionT.ADD ? ADD : EDIT}
                        headerText={PageState.action == ActionT.ADD ? 'Create new Intent' : 'Edit Intent'}
                        headerDescription={'Enter name and description for this Intent'}
                        nameValue={PageState.intent}
                        NamePlaceholder={'Intent name'}
                        onNameEdit={(val: string) =>
                            setPageState(page => ({
                                ...page,
                                intent: val
                            }))
                        }
                        DescriptionValue={PageState.description}
                        DescriptionPlaceholder={'Intent description'}
                        onDescriptionEdit={(val: string) =>
                            setPageState(page => ({
                                ...page,
                                description: val
                            }))
                        }
                        onCancel={reset}
                        onSave={saveIntent}
                        saveText={PageState.action == ActionT.ADD ? 'Create' : 'Update'}
                    />
                </ErrorBoundary>
            </Modal>
        )
    } else return null
}

export const IntentDelete = ({
    PageState,
    remove,
    reset
}: {
    PageState: PageStateT
    remove: Function
    reset: Function
}) => {
    const [loading, setLoading] = useState<boolean>(false)

    const [ModelError, setModelError] = useState<{
        error: any
        info: any
    }>({ error: null, info: null })

    const deleteIntent = () => {
        setLoading(true)
        remove(
            () => setLoading(false),
            (reson: string) => {
                setLoading(false)
                setModelError({ error: true, info: reson })
            }
        )
    }

    return PageState.action == ActionT.DELETE || PageState.action == ActionT.MULTIPLE_DELETE ? (
        <Modal error={ModelError} showPopupLoader={loading}>
            <ErrorBoundary
                error={ModelError}
                render={(err: any, info: any) => {
                    return (
                        err && <Error.Delete onClick={() => setModelError({ error: null, info: null })} info={info} />
                    )
                }}
            >
                <h2>Delete {PageState.action == ActionT.DELETE ? PageState.data.intent : 'Intents'}?</h2>
                <Paragraph>
                    Are you sure you want to delete selected {PageState.action == ActionT.DELETE ? 'Intent' : 'Intents'}
                    ?<br />
                    Dialog may not work properly if the intent is mapped in a Dialog conversation.
                </Paragraph>
                <p></p>
                <div>
                    <ButtonWrapper>
                        <Button primary type="button" data-dismiss="modal" onClick={() => reset()}>
                            <label> No</label>
                        </Button>
                        <Button type="button" data-dismiss="modal" onClick={() => deleteIntent()}>
                            <label>Yes</label>
                        </Button>
                    </ButtonWrapper>
                </div>
            </ErrorBoundary>
        </Modal>
    ) : null
}
