/*eslint-disable*/
import * as React from 'react'

import { AddIcon, CloseIcon, DeleteIcon } from '@/common/Icons/Icons'
import { DialogCard, EndCard, NewCard, StartCard } from '@/common/components/Card'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import Validate, { ErrorContext } from '@/common/components/FormValidate'
import { Header, HeaderChild } from '@/common/components/Header'
import InputBox from '@/common/components/InputBox'
import { Loader } from '@/common/components/Loader'
import { Modal } from '@/common/components/Modal'
import lazyGA from '@/common/google-analytics/tracking'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import { newLine, validations } from '@/common/utils/_validation-utils'

import { postJson, watsonApiURL } from '@/common/utils/api-utils'
import { Action, Add, IfNodeT, Loading, NoDataFound, PageState, View } from './Types'
import { CorrectIfRecord, EarnIntents, EarnTotalRecord, RecordApi, RemoveIfRecord, sourchConverter } from './triggers'

const welcomeNode = 'welcome'
const otherNode = 'anything_else'

enum ErrorState {
    Valid,
    InValid
}

type HasError = {
    type: ErrorState.Valid
    error: boolean
    info: string
}

type NoError = {
    type: ErrorState.InValid
}
const NoErrorV: NoError = { type: ErrorState.InValid }

type Error = NoError | HasError

type dialogS = {
    dialogs: IfNodeT[]
    defaultNodes: IfNodeT[]
    PageState: PageState
    searchWord: string
    insertKey: number
    error: Error
    unauthorized: boolean
    PageError: Error
    inputFocus: boolean
    showPopupLoader: boolean
    tryit: boolean
    intents: any[]
}
export class Dialog extends React.Component<any, dialogS> {
    pageError: HasError = {
        type: ErrorState.Valid,
        error: true,
        info: 'There was a problem in loading Page! <br/> Please click on "Retry"'
    }

    error: HasError = {
        type: ErrorState.Valid,
        error: true,
        info: 'Server error!<br />Please try again after sometime.'
    }

    inputRef: any

    state: dialogS = {
        PageState: Loading,
        dialogs: [],
        defaultNodes: [],
        searchWord: '',
        insertKey: 13,
        error: NoErrorV,
        unauthorized: false,
        PageError: NoErrorV,
        inputFocus: false,
        showPopupLoader: false,
        tryit: false,
        intents: []
    }

    async componentDidMount() {
        const workspacename = this.props.match.params.workspacename
        EarnIntents(workspacename).then((e: any) => this.setState({ intents: e.intents }))
    }

    UNSAFE_componentWillReceiveProps(nextProps: any) {
        const { props } = this

        if (props.state.type != nextProps.state.type) {
            if (nextProps.state.type == 'loading') {
                this.setState({ PageState: Loading })
            } else if (nextProps.state.type == 'error') {
                if (
                    nextProps.state.error &&
                    nextProps.state.error.response &&
                    nextProps.state.error.response.status == 403
                ) {
                    this.setState({
                        PageState: View,
                        unauthorized: true
                    })
                } else {
                    this.setState({
                        PageState: View,
                        PageError: this.pageError
                    })
                }
            } else if (nextProps.state.type == 'success') {
                const data: IfNodeT[] = nextProps.state.data

                const withoutDefaultNodes =
                    data.length > 0 ? data.filter(e => e.conditions != welcomeNode && e.conditions != otherNode) : []

                const PS = data
                    .filter(e => e.dialog_node == welcomeNode)
                    .filter(node => (node.metadata ? node.metadata.FIRST_DIALOG == false : false))

                this.setState({
                    dialogs: withoutDefaultNodes,
                    defaultNodes: data.filter(e => e.dialog_node == welcomeNode || e.conditions == otherNode),
                    PageError: NoErrorV,
                    PageState: PS.length == 0 ? NoDataFound : View
                })
            }
        }
    }

    componentDidUpdate() {
        this.inputRef && this.inputRef.focus()
    }

    getDialogs = async () => {
        try {
            const data = (await EarnTotalRecord(this.props.match.params.workspacename)) as IfNodeT[]
            if (data.length > 0) {
                this.setState({
                    dialogs: data.filter(e => e.conditions != welcomeNode && e.conditions != otherNode),
                    defaultNodes: data.filter(e => e.conditions == welcomeNode || e.conditions == otherNode),
                    PageError: NoErrorV,
                    PageState: View
                })
            } else {
                this.setState({ PageState: NoDataFound })
            }
        } catch (err) {
            this.setState({
                PageError: this.pageError,
                PageState: View
            })
        }
    }

    resetToView = () => {
        this.setState({
            PageState: View
        })
    }
    addConformation = () => {
        this.setState({
            PageState: Add
        })
    }
    deleteConformation = () => {
        const { PageState } = this.state
        if (PageState.action === Action.Select) {
            const selected = PageState.selectedItems
            this.setState({
                PageState: {
                    action: Action.Delete,
                    selectedItems: selected,
                    name: ''
                }
            })
        }
    }
    searchHandle = (word: string) => this.setState({ searchWord: word })

    isSelected = (id: string) => {
        const { PageState } = this.state
        if (PageState.action === Action.Select || PageState.action === Action.Delete) {
            return PageState.selectedItems.includes(id)
        }
        return false
    }
    selectDialog = (id: string) => {
        const { PageState } = this.state
        switch (PageState.action) {
            case Action.Select:
                const selected: string[] = PageState.selectedItems.includes(id)
                    ? PageState.selectedItems.filter((x: string) => x !== id)
                    : [...PageState.selectedItems, id]

                if (selected.length) {
                    this.setState({
                        PageState: {
                            ...PageState,
                            action: Action.Select,
                            selectedItems: selected
                        }
                    })
                } else {
                    this.setState({ PageState: View })
                }
                break
            default:
                const newSelected = [id]
                this.setState({
                    PageState: {
                        ...PageState,
                        action: Action.Select,
                        selectedItems: newSelected
                    }
                })
                break
        }
    }
    showSelection = () =>
        this.state.PageState.action === Action.Select ||
        (this.state.PageState.action === Action.Delete && this.state.PageState.selectedItems.length > 0)

    showDetail = (dialog: string) => {
        this.props.history.push(`/workspace/${this.props.match.params.workspacename}/dialog/detail/${dialog}`)
    }

    deleteIndividual = (id: string) => {
        this.setState({
            PageState: {
                action: Action.Delete,
                selectedItems: [],
                name: id
            }
        })
    }
    editIndividual = (id: string) => {
        const { dialogs } = this.state
        const cur = dialogs.filter(dialog => dialog.dialog_node === id)[0]
        this.setState({
            PageState: {
                action: Action.Edit,
                title: cur.title,
                dialog_node: cur.dialog_node
            }
        })
    }
    deleteDialog = async () => {
        const { PageState } = this.state
        try {
            if (PageState.action == Action.Delete) {
                //show Loading
                this.setState({ showPopupLoader: true })

                if (PageState.selectedItems.length > 0) {
                    await Promise.all(
                        PageState.selectedItems.map(node => RemoveIfRecord(this.props.match.params.workspacename, node))
                    )
                } else {
                    await RemoveIfRecord(this.props.match.params.workspacename, PageState.name)
                }
                const res = (await EarnTotalRecord(this.props.match.params.workspacename)) as IfNodeT[]
                const data = sourchConverter(res.filter((e: any) => e.type == 'if')) as IfNodeT[]
                this.setState({
                    dialogs: data.filter(e => e.conditions != welcomeNode && e.conditions != otherNode),
                    defaultNodes: data.filter(e => e.conditions == welcomeNode || e.conditions == otherNode),
                    PageState: View,
                    showPopupLoader: false
                })

                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Dialogue Node Deleted'
                })
            }
        } catch (err) {
            this.setState({
                error: this.error,
                showPopupLoader: false
            })
        }
    }
    updateDialog = async () => {
        const { PageState } = this.state
        try {
            if (PageState.action == Action.Edit) {
                //show Loading
                this.setState({ showPopupLoader: true })

                const res = await CorrectIfRecord(this.props.match.params.workspacename, PageState.dialog_node, {
                    title: PageState.title
                })

                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Dialogue Node Updated'
                })

                this.setState({
                    dialogs: this.state.dialogs.map(e => (e.dialog_node === res.dialog_node ? res : e)),
                    PageState: View,
                    showPopupLoader: false
                })
            }
        } catch (err) {
            this.setState({
                error: this.error,
                showPopupLoader: false
            })
        }
    }

    getLastElement = (dialogs: IfNodeT[]) => {
        return dialogs.filter(e => e.type == 'if').pop()
    }

    addNewDialog = async () => {
        const { PageState, dialogs, defaultNodes } = this.state

        try {
            if (PageState.action == Action.Add || PageState.action === Action.NoDataFound) {
                //show Loading
                this.setState({ showPopupLoader: true })

                if (PageState.action === Action.NoDataFound) {
                    await CorrectIfRecord(this.props.match.params.workspacename, 'welcome', {
                        metadata: {
                            FIRST_DIALOG: false
                        }
                    })
                }

                let lastSibling

                if (dialogs.length) {
                    lastSibling = this.getLastElement(dialogs.filter(e => e.type == 'if'))
                } else {
                    lastSibling = defaultNodes.filter(e => e.conditions == welcomeNode).pop()
                }

                let obj: any = {}
                if (lastSibling) {
                    obj = {
                        previous_sibling: lastSibling.dialog_node
                    }
                }

                obj.title = PageState.title
                const nextSibling = await postJson(
                    watsonApiURL(this.props.match.params.workspacename, RecordApi.makeIfRecord)
                )(obj)

                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Dialogue Node Created'
                })

                // this.setState({
                //     dialogs: [...dialogs, nextSibling.data],
                //     PageState: View,
                // })

                //hiding loader
                nextSibling.data.dialog_node && this.setState({ showPopupLoader: false })

                this.props.history.push(
                    `/workspace/${this.props.match.params.workspacename}/dialog/detail/${nextSibling.data.dialog_node}`
                )
            }
        } catch (err) {
            this.setState({
                error: this.error,
                showPopupLoader: false
            })
        }
    }

    handleChage = (name: string) => {
        const { PageState } = this.state
        if (PageState.action === Action.Add || PageState.action === Action.NoDataFound) {
            this.setState({ PageState: { ...PageState, title: name } })
        } else if (PageState.action === Action.Edit) {
            this.setState({ PageState: { ...PageState, title: name } })
        }
    }

    closeError = () => {
        this.setState({ error: NoErrorV })
    }

    renderModel = () => {
        const { PageState, error, showPopupLoader } = this.state
        if (
            PageState.action === Action.Add ||
            (PageState.action === Action.NoDataFound && PageState.showAddPopup) ||
            PageState.action === Action.Edit
        ) {
            return (
                <Modal error={error} showPopupLoader={showPopupLoader}>
                    <ErrorBoundary
                        error={error}
                        render={(err: any, info: any) => {
                            return err && <Error.AddorEdit onClick={this.closeError} info={info} />
                        }}
                    >
                        <Validate
                            defaultValue={
                                PageState.action === Action.Add || PageState.action === Action.NoDataFound
                                    ? { dialog: false }
                                    : { dialog: true }
                            }
                        >
                            {PageState.action === Action.Add || PageState.action === Action.NoDataFound ? (
                                <h2>Create Dialog</h2>
                            ) : (
                                <h2>Edit Dialog</h2>
                            )}
                            {PageState.action === Action.Add || PageState.action === Action.NoDataFound ? (
                                <p>Enter a name for the dialog</p>
                            ) : (
                                <p>Edit dialog name</p>
                            )}
                            <div>
                                <InputBox
                                    validationList={validations}
                                    value={PageState.title}
                                    name="dialog"
                                    render={(
                                        value: string,
                                        valid: boolean,
                                        errorMsg: string,
                                        startValidation: () => void,
                                        handleInput: Function
                                    ) => {
                                        return (
                                            <>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Dialog name"
                                                    ref={node => (this.inputRef = node)}
                                                    onKeyDown={e =>
                                                        this.setState({
                                                            insertKey: e.keyCode
                                                        })
                                                    }
                                                    onChange={event => {
                                                        if (
                                                            (this.state.insertKey > 64 && this.state.insertKey < 91) ||
                                                            this.state.insertKey == 32 ||
                                                            this.state.insertKey == 8 ||
                                                            (this.state.insertKey > 47 && this.state.insertKey < 58)
                                                        ) {
                                                            startValidation(), handleInput('dialog', event.target.value)
                                                            this.handleChage(event.target.value)
                                                        }
                                                    }}
                                                    value={value}
                                                />
                                                <p className="error_msg">{!valid ? errorMsg : newLine}</p>
                                            </>
                                        )
                                    }}
                                />
                                <ErrorContext.Consumer>
                                    {valid => {
                                        return (
                                            <React.Fragment>
                                                <button
                                                    className="btn btn-primary"
                                                    type="button"
                                                    onClick={
                                                        PageState.action !== Action.NoDataFound
                                                            ? this.resetToView
                                                            : () =>
                                                                  this.setState({
                                                                      PageState: {
                                                                          ...PageState,
                                                                          showAddPopup: false
                                                                      }
                                                                  })
                                                    }
                                                >
                                                    Cancel
                                                </button>
                                                {PageState.action === Action.Add ||
                                                PageState.action === Action.NoDataFound ? (
                                                    <button
                                                        className={'btn btn-success'}
                                                        disabled={!valid.formValid.formValid}
                                                        type="button"
                                                        onClick={this.addNewDialog}
                                                    >
                                                        Create
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className={'btn btn-success'}
                                                        disabled={!valid.formValid.formValid}
                                                        onClick={this.updateDialog}
                                                    >
                                                        Save
                                                    </button>
                                                )}
                                            </React.Fragment>
                                        )
                                    }}
                                </ErrorContext.Consumer>
                            </div>
                        </Validate>
                    </ErrorBoundary>
                </Modal>
            )
        } else if (PageState.action === Action.Delete) {
            return (
                <Modal showPopupLoader={showPopupLoader}>
                    <ErrorBoundary
                        error={error}
                        render={(err: any, info: any) => {
                            return err && <Error.Delete onClick={this.closeError} info={info} />
                        }}
                    >
                        {PageState.selectedItems.length > 1 ? (
                            <React.Fragment>
                                <h2>Delete Dialog</h2>
                                <p>Are you sure to delete selected Dialog?</p>{' '}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <h2>Delete Dialog</h2>
                                <p>Are you sure to delete this dialog? </p>{' '}
                            </React.Fragment>
                        )}

                        <div>
                            <button className="btn btn-primary" type="button" onClick={this.deleteDialog}>
                                Yes
                            </button>
                            <button className="btn btn-success" type="button" onClick={this.resetToView}>
                                No
                            </button>
                        </div>
                    </ErrorBoundary>
                </Modal>
            )
        }
        return null
    }

    closeSearch = () => {
        this.setState({ searchWord: '' })
    }

    render() {
        const { dialogs, PageState, searchWord, defaultNodes, PageError, unauthorized } = this.state
        let renderItems = dialogs
        if (searchWord != '') {
            renderItems = dialogs.filter(dialog => dialog.title.toLowerCase().includes(searchWord.toLowerCase()))
        }

        return PageState.action === Action.Loading ? (
            <Loader.PageLoader show={true} />
        ) : (
            <React.Fragment>
                {this.renderModel()}
                <ErrorBoundary
                    unauthorized={unauthorized}
                    noDataFound={{
                        show: PageState.action === Action.NoDataFound,
                        noDatainfo:
                            '<p>Empty as a bird’s nest in December. Create your first dialog now!</p><p>We will create <b>Welcome</b> and <b>Fallback</b> dialogs!</p>',
                        onClick: () => {
                            this.setState({
                                PageState: {
                                    ...NoDataFound,
                                    showAddPopup: true
                                }
                            })
                        },
                        btnName: 'Create one'
                    }}
                    error={PageError}
                    render={(err: any, info: any) => {
                        return err && <Error.PageLoadingError onClick={this.getDialogs} info={info} btnName="Retry" />
                    }}
                >
                    {this.state.tryit ? null : null}
                    <Header>
                        <HeaderChild type="first">
                            <div className="indent_left">
                                <div className="indent_title">Dialog</div>
                                <div className="indent_sub_title">Catalog ({dialogs.length})</div>
                            </div>

                            <div className="indent_right">
                                {(PageState.action === Action.Select ||
                                    (PageState.action === Action.Delete && PageState.selectedItems.length > 0)) && (
                                    <Button
                                        primary
                                        style={{ background: '#FFE5D3' }}
                                        className="btn btn-primary btn_large"
                                        onClick={this.resetToView}
                                    >
                                        <label>
                                            Selected{' '}
                                            <b>
                                                {PageState.selectedItems.length > 9
                                                    ? PageState.selectedItems.length
                                                    : `0${PageState.selectedItems.length}`}
                                            </b>{' '}
                                            Dialogs
                                        </label>
                                        <span>
                                            <CloseIcon />
                                        </span>
                                    </Button>
                                )}

                                {PageState.action !== Action.Select &&
                                    !this.state.inputFocus &&
                                    PageState.action !== Action.NoDataFound && (
                                        <button className="btn btn-primary" onClick={this.addConformation}>
                                            <AddIcon />
                                            <span>Create New</span>
                                        </button>
                                    )}
                            </div>
                        </HeaderChild>
                        <HeaderChild type="second">
                            {PageState.action === Action.Select && (
                                <button className="btn btn-primary" onClick={this.deleteConformation}>
                                    <DeleteIcon />
                                    <span>Delete</span>
                                </button>
                            )}
                            {/* <button className="btn btn-primary">
                                <ListviewIcon />
                                <span>List view</span>
                            </button>

                            <button className="btn btn-primary">
                                <GroupIcon />

                                <span>Group View</span>
                            </button> */}

                            {!this.state.inputFocus ? (
                                <input
                                    type="search"
                                    placeholder="Search"
                                    value={searchWord}
                                    onFocus={() => this.setState({ inputFocus: true })}
                                />
                            ) : (
                                <span className="search_click">
                                    <input
                                        className="search_click"
                                        type="search"
                                        placeholder="Search"
                                        value={searchWord}
                                        autoFocus={true}
                                        onChange={e => this.searchHandle(e.target.value)}
                                    />
                                    <span
                                        onClick={() => {
                                            this.closeSearch()
                                            this.setState({ inputFocus: false })
                                        }}
                                    >
                                        <CloseIcon />
                                    </span>
                                </span>
                            )}
                        </HeaderChild>
                    </Header>
                    <section className="entity_cards">
                        <div className="col-md-12">
                            <div className="row">
                                {searchWord === '' ? (
                                    <React.Fragment>
                                        <NewCard
                                            onClick={this.addConformation}
                                            disabled={PageState.action === Action.Select}
                                        />
                                        {defaultNodes.length ? (
                                            <StartCard
                                                disabled={PageState.action === Action.Select}
                                                onClick={this.showDetail}
                                                dialog={this.getLastElement(
                                                    defaultNodes.map(e => e).filter(e => e.conditions == welcomeNode)
                                                )}
                                            />
                                        ) : null}
                                    </React.Fragment>
                                ) : null}
                                {renderItems.map((e: IfNodeT, i: number) => {
                                    // console.log('Dialog', e.type)
                                    let reg = new RegExp(searchWord, 'gi')

                                    let label =
                                        searchWord != ''
                                            ? `<h3 style='padding-top:${0}px'>` + e.title + '</h3>'
                                            : `<h3 style='padding-top:${0}px'>` + e.title + '</h3>'

                                    const index = i < 9 ? '0' + (i + 1).toString() : i == 9 ? '10' : i + 1
                                    return (
                                        <DialogCard
                                            key={i}
                                            type={e.type}
                                            isSelected={this.isSelected}
                                            select={this.selectDialog}
                                            showSelection={this.showSelection}
                                            onClick={this.showDetail}
                                            delete={this.deleteIndividual}
                                            edit={this.editIndividual}
                                            id={e.dialog_node}
                                            label={label}
                                            dialogIndex={index}
                                            modified={e.updated}
                                            showToolbox={PageState.action === Action.View}
                                        />
                                    )
                                })}
                                {searchWord === '' ? (
                                    defaultNodes.length ? (
                                        <EndCard
                                            disabled={PageState.action === Action.Select}
                                            onClick={this.showDetail}
                                            dialog={this.getLastElement(
                                                defaultNodes.map(e => e).filter(e => e.conditions == otherNode)
                                            )}
                                        />
                                    ) : null
                                ) : null}
                            </div>
                        </div>
                    </section>
                    <section>
                        {this.state.tryit == false ? (
                            <div className="tryme_popup">
                                <button
                                    className="btn btn-primary"
                                    onClick={() =>
                                        this.setState({
                                            tryit: true
                                        })
                                    }
                                >
                                    <span>Try Me</span>
                                </button>
                            </div>
                        ) : null}
                    </section>
                    <section className="intent_bottom">
                        <div>&nbsp;</div>
                    </section>
                </ErrorBoundary>
            </React.Fragment>
        )
    }
}
