/*eslint-disable*/
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { usePrevious } from '@/common/hooks/usePrevious'

import { MOCK_TRYIT } from '@/common/utils/utils'
import { agenHandoffUrl, getJson, postJson } from '@/common/utils/api-utils'
import { AssistantT, DumpAssistant } from '../Construction/kind'
import { MixChild as MixChild2 } from '../Dialogue/Dialogue'
import { AutomationT, DefaultDialogue, DialogueT, IfNodeT, ResponseNodeE, slotsT } from '../Dialogue/kind'
import { ExpressionT } from '../Editor/Action'
import { EntityT } from '../Entity/kind'
import { IntentT } from '../Intent/kind'
//ACTIONS IMPORTS
import {
    DialogApiURLForPolling,
    deployAssistant,
    fetchActionConfigs,
    fetchUIJsonForFlows,
    getAssistants,
    getDialogueFromMarketplace,
    getDialogueRootDescendants,
    getDialogueRoots,
    getDialogues,
    getEntities,
    getIntents,
    publishAssistant,
    statusT
} from '../Mechanisms/action'
//TYPE IMPORTS
import { ROUTER_PROPS_T } from '../kind'
//CONTENT IMPORT
import { DialogueContext } from './Context'
import Canvas from './DialogueMap/Canvas'
//COMPONENT IMPORT
import { sortBySibling } from './action'
import { ActionT, LOADING, PageStateT, VIEW } from './kind'
import { AgentHandoffWorkspace } from '../AgentHandoff/types'

const Ramda = require('ramda')

export const fetchFlowNodes = (source: IfNodeT[]): AutomationT[] => {
    return source.reduce(
        (accumulator: AutomationT[], currentValue: IfNodeT): AutomationT[] =>
            accumulator.concat(
                Array.isArray(currentValue.responses)
                    ? (currentValue.responses.filter(node => node.type === 'automation') as AutomationT[])
                    : []
            ),
        []
    )
}
export const fetchFlowNodes1 = (source: IfNodeT[][]): AutomationT[] => {
    return source.reduce(
        (accumulator: AutomationT[], currentValue: IfNodeT[]): AutomationT[] => [
            ...accumulator,
            Ramda.flatten(
                currentValue.map(c =>
                    Array.isArray(c.responses) ? c.responses.filter(node => node.type === 'automation') : []
                ) as any
            )
        ],
        []
    )
}

const fetchFlowPromise = (workspace: string, source: AutomationT[]): Promise<any> => {
    let automationId = source
        .reduce(
            (accumulator: string[], currentValue: AutomationT): string[] => accumulator.concat(currentValue.automation),
            []
        )
        .filter(data => data !== null)
    return fetchUIJsonForFlows(workspace, automationId)
}

const actionMap = (arr: any[], result: string[]): string[] =>
    arr.reduce((accumulator, currentvalue): string[] => {
        if (currentvalue.children.length > 0) {
            return actionMap(
                currentvalue.children,
                currentvalue.value.meta_data.action_id
                    ? accumulator.concat(currentvalue.value.meta_data.action_id)
                    : accumulator
            )
        }
        if (currentvalue.value.meta_data.action_id) {
            return accumulator.concat(currentvalue.value.meta_data.action_id)
        }
        return accumulator
    }, result)

const flowUIMap = (uiJSONS: any[], output: string[]): string[] =>
    uiJSONS.reduce((accumulator, currentvalue): string[] => {
        return actionMap(currentvalue.custom_json.children[0].children, accumulator)
    }, output)

const fetchDes = (dialogues: DialogueT[], data: DialogueT, result: DialogueT[]): DialogueT[] => {
    return dialogues
        .filter(e => e.parent === data.uuid)
        .reduce((accum: DialogueT[], val: DialogueT): DialogueT[] => {
            return [...fetchDes(dialogues, val, accum), val]
        }, result)
}
const watsoncontext = (dialogues: DialogueT[]) =>
    dialogues
        .filter(e => e.type === 'if_node' && e.parent === null)
        .reduce((accum: any, val: DialogueT) => {
            const resnodes = Object.values(
                fetchDes(dialogues, val, [val])
                    // .concat([val])
                    .map(e => e.responses)
                    .reduce((accu: any, val) => accu.concat(val), [])
                    .reduce((accum: any, val: ResponseNodeE) => {
                        if (val.type === 'slot_container') {
                            const slots = val.slots.reduce((accum: any, slot_val: slotsT) => {
                                return {
                                    ...accum,
                                    [slot_val.variable]: {
                                        variable: slot_val.variable,
                                        node: { ...val, slots: slot_val }
                                    }
                                }
                            }, {})
                            return {
                                ...accum,
                                ...slots
                            }
                        } else if (val.type === 'ask_a_question') {
                            return {
                                ...accum,
                                [val.variable]: { variable: val.variable, node: val }
                            }
                        } else if (val.type === 'file_upload') {
                            return {
                                ...accum,
                                [val.variable]: { variable: val.variable, node: val }
                            }
                        } else if (val.type === 'context_variable') {
                            const variables = val.variables.reduce(
                                (acc: any, values: { name: string; expression: ExpressionT }) => {
                                    return [...acc, { variable: values.name, node: val }]
                                },
                                []
                            )
                            return {
                                ...accum,
                                // variables: variables
                                variables: [ ...(accum.variables? accum.variables : []), ...variables ]
                            }
                        }
                        return accum
                    }, {})
            )
            return {
                ...accum,
                [val.id]: resnodes
            }
        }, {})

const flowcontext = (dialogues: DialogueT[], flowJSON: any[]) =>
    dialogues
        .filter(e => e.parent === null)
        .reduce((accum: any, val: DialogueT) => {
            const des = fetchDes(dialogues, val, [val])
            const flownodes = fetchFlowNodes(des as any)
            const res = flownodes.reduce((accum: any, val: AutomationT) => {
                const currentFlow = flowJSON.filter((flow: any) => flow.id == val.automation).pop()
                if (currentFlow) {
                    return {
                        ...accum,
                        [val.id]: currentFlow
                    }
                }
                return accum
            }, {})
            return {
                ...accum,
                [val.uuid]: res
            }
        }, {})

const AssistantDialogueCanvas = (props: ROUTER_PROPS_T) => {
    const pollRef = useRef<number | null>(null)

    const { workspacename, assistantname, dialogue_node } = props.match.params
    const [PageState, setPageState] = useState<PageStateT>(LOADING)

    const [intents, setIntents] = useState<IntentT[]>([])

    const [entities, setEntities] = useState<EntityT[]>([])

    const [assistantID, setAssistantID] = useState<string>('')
    const [isNull, setIsNull] = useState(false)

    const [assistant, setAssistant] = useState<AssistantT>(DumpAssistant)

    const [assistants, setAssistants] = useState<AssistantT[]>([])

    const [currentDialog, setCurrentDialog] = useState<DialogueT>(DefaultDialogue)

    const [dialogueIndex, setDialogueIndex] = useState<string>('')

    const [dialogues, setDialogues] = useState<DialogueT[]>([])

    const [workspaceDescendents, setWorkspaceDescendents] = useState<DialogueT[]>([])

    const [dialogueContext, setDialogueContext] = useState({})

    const [automationContext, setAutomationContext] = useState({})

    const [actionMixture, setActionMixture] = useState<any[]>([])

    const [defaultNodes, setDefaultNodes] = useState<DialogueT[]>([])

    const [getAgents, setAgents] = useState<AgentHandoffWorkspace[]>([])

    const [rootDialogs, setRootDialogs] = useState<DialogueT[]>([])
    const [isPublish, setIspublish] = useState({ error: false, success: false })
    const [isdeployed, setIsDeployed] = useState({ error: false, success: false, deploying: false })

    const shouldBeDeployed = useRef<'yes' | 'no'>('yes')

    const previousAction = usePrevious(PageState.action)

    const [status, setStatus] = useState<statusT>('checking_for_update')

    const [initialCall, setInitialCall] = useState(true)
    const [rootDesForMixChild, setrootDesForMixChild] = useState<DialogueT[][]>([])

    const PublishBot = () => {
        publishAssistant(workspacename, assistant.id)
            .then(res => {
                setIspublish({ error: false, success: true })
            })
            .catch(err => {
                setIspublish({ error: true, success: false })
            })
    }
    const DeployBot = () => {
        let deployed = assistant.deployed !== null ? assistant.deployed.split('Z')[0] : ''
        if (assistant.updated.split('Z')[0] === deployed && shouldBeDeployed.current == 'no') {
            // console.log('already deployed', assistant)
            setIsDeployed({ error: false, success: true, deploying: false })
        } else {
            // console.log('deploying')
            setIsDeployed({ error: false, success: false, deploying: true })
            deployAssistant(workspacename, assistant.id)
                .then(res => {
                    // console.log('deployed')
                    shouldBeDeployed.current = 'no'
                    sessionStorage.setItem(assistantID, 'checking_for_update')
                    setStatus('checking_for_update')
                    pollRef.current = pollAPI(assistantID)
                    setIsDeployed({ error: false, success: true, deploying: false })
                })
                .catch(err => {
                    setIsDeployed({ error: true, success: false, deploying: false })
                })
        }
    }

    function pollAPI(assistantID: string) {
        const storage = typeof Storage !== 'undefined'

        const intervalHolder = setInterval(() => {
            const nluStatus = MOCK_TRYIT
                ? getJson(DialogApiURLForPolling(`/status`, `testing_${assistantID}`))
                : getJson(DialogApiURLForPolling(`/${workspacename}/nlu/status`, `testing_${assistantID}`))

            nluStatus
                .then(res => {
                    // console.log('clear poll api :: before')
                    setStatus(res)
                    if (res == 'trained' || res == 'failed' || res == 'bot_not_deployed') {
                        storage && sessionStorage.setItem(assistantID, res)
                        clearInterval(pollRef.current as number)
                        pollRef.current = null
                    } else {
                        storage && sessionStorage.setItem(assistantID, res)
                    }
                    // console.log('clear poll api :: after', pollRef.current)
                })
                .catch(_err => {})
        }, 5000)

        return intervalHolder
    }

    const initialization = async () => {
        try {
            const assis = await getAssistants(workspacename)
            setAssistants(assis)
            const assistantID = props.assistantId
                ? props.assistantId
                : assis.reduce((accum: any, val: any) => {
                      if (val.name == assistantname) return val.id
                      else return accum
                  }, '')

            if (assistantID == '') {
                setPageState({
                    action: ActionT.RESOURCE_NOT_FOUND,
                    resource: 'ASSISTANT'
                })
            } else {
                setAssistantID(assistantID)

                setAssistant(
                    assis.reduce((accum: AssistantT, val: AssistantT): AssistantT => {
                        if (val.name.toLowerCase() == assistantname.toLowerCase()) return val
                        else return accum
                    }, DumpAssistant)
                )

                const intenData = await getIntents(workspacename, assistantID)

                setIntents(intenData)

                const entityData = await getEntities(workspacename, assistantID)

                setEntities(entityData)

                const dialogRootData = await getDialogueRoots(workspacename, assistantID)
                let parentNode = dialogRootData.find((data: any) => data.id === dialogue_node)
                const DialogIndex =
                    sortBySibling(dialogRootData)
                        .filter((e: DialogueT) => (e.conditions ? e.uuid !== assistant.welcome_node : true))
                        .filter((e: DialogueT) => (e.conditions ? e.conditions.type !== 'FallBackExpression' : true))
                        .findIndex((e: DialogueT) => e.id == dialogue_node) + 1
                setDialogueIndex(
                    DialogIndex < 9 ? '0' + DialogIndex.toString() : DialogIndex == 9 ? '09' : DialogIndex.toString()
                )
                setRootDialogs(sortBySibling(dialogRootData))

                setDefaultNodes(
                    dialogRootData.length == 0
                        ? []
                        : sortBySibling(dialogRootData).filter((node: DialogueT) => {
                              if (
                                  node.conditions &&
                                  (node.uuid === assistant.welcome_node ||
                                      node.conditions.type === 'FallBackExpression')
                              )
                                  return false
                              return true
                          })
                )

                const rootDes: DialogueT[] = !props.assistantId
                    ? await getDialogueRootDescendants(workspacename, assistantID, parentNode.uuid)
                    : await getDialogueFromMarketplace(workspacename, assistantID, dialogue_node)

                setDialogues(rootDes)

                const rootDesForMixChild: DialogueT[] = dialogRootData.map((d: DialogueT) =>
                    getDialogueRootDescendants(workspacename, assistantID, d.uuid)
                )

                await Promise.all(rootDesForMixChild).then(res => {
                    setrootDesForMixChild(res as any)
                })

                const root = rootDes.filter(e => e.parent === null)[0]
                setCurrentDialog(root as any)

                const workspaceDes: DialogueT[] = await getDialogues(workspacename, assistantID)

                setWorkspaceDescendents(workspaceDes)

                const flowNodes = fetchFlowNodes(workspaceDes as any)

                const data = flowNodes.length > 0 && flowNodes.every(dialog => dialog.automation === null)
                setIsNull(data)

                const agents: { data: AgentHandoffWorkspace[] } = !MOCK_TRYIT ? await postJson(agenHandoffUrl(workspacename, `/${assistantID}/agent/getAgent/`))({ bot_id: assistantID }) as any : {data: [{"id":"17aa24af-c61f-44a8-87a3-1b165beb7e77","workspace":"vinoddev","bot_id":"00318c03-cfa3-4f30-80d2-069de4654488","client_id":"65b1c05e-4e56-4912-a2ee-34b8f370939d","client_secrect":"jfO8Q~4kCg2U4E9mx_PAM_3AeuuC8Jts6INrZa4F","name":"IT Agents","description":"","is_active":false,"is_authorized":true,"tenant":"fe8f0076-8dbf-4684-9f73-b389c33b2158","teams_deploy":{"team_name":"Multi-Agent-IT bot","team_id":"b891d405-ea44-4649-a112-d16e075bcfaa","is_active":true,"date_added":"2022-10-14T08:14:34.000Z","added_by":"Vinod Kumar Manda","email_id":"vinod.m@workativ.com"},"bot_data":{"bot_name":"IT agent handover","bot_full_name":"IT agent handover bot","bot_short_desc":"IT agent handover bot","bot_full_desc":"IT agent handover bot","is_generated":true,"is_active":true}}] as any}
                
                setAgents(agents.data)

                if (!data) {
                    const flowUIJson = await fetchFlowPromise(workspacename, flowNodes)

                    const actions = await fetchActionConfigs(workspacename, flowUIMap(flowUIJson.output, []))
                    setActionMixture(
                        actions.output.reduce(
                            (accumulator: any, currentvalue: any) =>
                                Ramda.assoc(currentvalue.id, currentvalue, accumulator),
                            {}
                        )
                    )
                    setAutomationContext(flowcontext(workspaceDes, flowUIJson.output))
                }
                setDialogueContext(watsoncontext(workspaceDes))
                if ((root && root.conditions && Object.keys(root.conditions).length > 1) || (root && (root.responses as ResponseNodeE[]).length>0)) {
                    setPageState(VIEW)
                } else {
                    setPageState({ action: ActionT.IF_DIALOGUE, data: root })
                }
                if (
                    (initialCall &&
                        (sessionStorage.getItem(assistantID) == null ||
                            (sessionStorage.getItem(assistantID) as statusT) == 'training'|| (sessionStorage.getItem(assistantID) as statusT) == "checking_for_update")) ||
                    ((sessionStorage.getItem(assistantID) as statusT) == 'trained' &&
                        previousAction != undefined &&
                        previousAction != ActionT.LOADING)
                ) {
                    // sessionStorage.setItem(assistantID, 'training')
                    // setStatus('training')
                    // pollRef.current = pollAPI(assistantID)
                } else {
                    setInitialCall(false)
                    setStatus(sessionStorage.getItem(assistantID) as statusT)
                }
            }
        } catch (err) {
            let error = err as any
            if ((error.response as any) && error.response.status == 403) {
                setPageState({
                    action: ActionT.UN_AUTHORIZED
                })
            } else {
                setPageState({
                    action: ActionT.ERROR,
                    reason: 'There was a problem in loading Page! <br/> Please click on "Retry"'
                })
            }
        }
    }

    useEffect(() => {
        initialization()
        return () => {
            if (pollRef.current) {
                clearInterval(pollRef.current)
                pollRef.current = null
            }
        }
    }, [])

    const fetchCallback = (callback: Function) => initialization().then(() => callback())

    const MixChild = (Mixture: any) => (element: DialogueT) =>
        Ramda.compose(
            sortBySibling,
            Ramda.filter((e: DialogueT) => e.parent == element.uuid)
        )(Mixture)

    const fetchChild = useCallback(() => MixChild(dialogues), [dialogues])()
    const fetchChild2 = useCallback(() => MixChild2(rootDesForMixChild), [rootDesForMixChild])()
    return (
        <DialogueContext.Provider
            value={{
                navigateHomePage: () =>
                    props.history.replace(`/workspace/${workspacename}/dialog/${assistantname}/dialogs`),
                dialogueName: currentDialog.title,
                workspaceName: workspacename,
                assistant: assistant,
                assistantID: assistantID,
                dialogueNode: dialogue_node,
                intents: intents,
                setIntents: setIntents,
                entities: entities,
                PageState: PageState,
                setPageState: setPageState,
                fetchChild: fetchChild,
                fetchCallback: fetchCallback,
                ismarketplacebot: props.assistantId,
                dialogueContext,
                automationContext,
                workspaceDescendents,
                actionMixture,
                rootDialogs,
                fetchChild2,
                parentIfnode: currentDialog,
                agents: getAgents
            }}
        >
            <Canvas
                props={props}
                deploybot={DeployBot}
                isdeployed={isdeployed}
                publishBot={PublishBot}
                isPublish={isPublish}
                index={dialogueIndex}
                currentDialog={currentDialog}
                defaultNodes={defaultNodes}
                isNull={isNull}
                pollRef={pollRef}
                pollAPI={pollAPI}
                status={status}
                setStatus={setStatus}
                assistants={assistants}
            />
        </DialogueContext.Provider>
    )
}

export default AssistantDialogueCanvas
