//TYPE IMPORTS
import Downshift from 'downshift'
// COMMON COMPONENTS
import * as R from 'ramda'
/*eslint-disable*/
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Tooltip from 'react-tooltip-lite'

import { AfterBotStarted, DialogOrFAQ } from '@/DialogEditor/Canvas/Components/BotSelection'
import AssistantDialogueFAQ from '@/DialogEditor/Canvas/Components/Nodes/ApiWrapper'
import { TryItWithButton } from '@/DialogEditor/Canvas/Components/Nodes/TryIt'
import { useJourney } from '@/Journey/JourneyContext'
import { VideoButton } from '@/Videos/Videos'
import {
    AddIcon,
    CloseIcon,
    DeleteIcon,
    DialogCardIcon,
    EditIcon,
    ErrorIcon,
    FolderIcon,
    GroupIcon,
    ListviewIcon,
    SearchIcon,
    CopyIocn
} from '@/common/Icons/Icons'
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons'
// ICONS
import { Card, FAQCard, NewCard } from '@/common/components/Card'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import Validate, { ErrorContext } from '@/common/components/FormValidate'
import { Header, HeaderChild } from '@/common/components/Header'
import InputBox from '@/common/components/InputBox'
import { List } from '@/common/components/List'
import { Loader } from '@/common/components/Loader'
import { Modal } from '@/common/components/Modal'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
// import { PublishButton } from '../Construction/Constructor'
import {
    ButtonContainer,
    EmptyDiv,
    Header as MoveHeader,
    PopupContainer,
    PopupScrollHeight,
    PopupWrapper,
    Wrapper as WrapperMove
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { SerachInput, SerachInputWrapper } from '@/common/styled/DialogLogs.Dumb'
import { ButtonWrapper, Paragraph, TabelWrapper } from '@/common/styled/Dialogue.Dumb'
import {
    ErrorMessage,
    Input,
    InputContent,
    InputErrorIcon,
    InputWrapper,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from '@/common/styled/Workflow.Analytics.Dumb'
import { dialogDescriptionValidation, dialogNameValidation, newLine } from '@/common/utils/_validation-utils'

import { MOCK_TRYIT, formatAMPM, formatDateInCard } from '@/common/utils/utils'
// UTILS
import { getJson, logEvent } from '@/common/utils/api-utils'
import { DropDown } from '../Common/Effective'
import { PublishButton, PublishPopup } from '../Construction/PublishBot'
import { AssistantT } from '../Construction/kind'
import { fetchFlowNodes } from '../DialogueCrew'
import { validateNode, validateResponseNode } from '../DialogueCrew/DialogueMap/Descendant'
import { sortBySibling } from '../DialogueCrew/action'
import { DraftJST, converterToEditor } from '../Editor/Action'
import { EntityT } from '../Entity/kind'
import { IntentT } from '../Intent/kind'
import {
    DialogApiURLForPolling,
    StatusContext,
    getAssistants,
    getDialogues,
    statusT,
    unpublishAssistant
} from '../Mechanisms/action'
import { ActionT, GridView, ListView, PageViewsT, ViewTypeT } from '../kind'
import { DialogueT, FAQT, PageStateT, VIEW } from './kind'
import PasteDialogsPopup from './PasteDialogsPopup'
import styled from 'styled-components'
import { usePermissions, usePermissionSignal } from '@/common/utils/auth-token-api-utils'
import { useSignal } from '@preact/signals-react'

export const InputContainer = styled.div`
    width: 100%;
    float: left;
    position: relative;
    margin-bottom: 20px;
`

let Parser = require('html-react-parser')

function usePrevious(value: any) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = React.useRef<any>()
    // Store current value in ref
    useEffect(() => {
        ref.current = value
    }, [value]) // Only re-run if value changes

    return ref.current
}

enum ErrorState {
    Valid,
    InValid
}

type HasError = {
    type: ErrorState.Valid
    error: boolean
    info: string
}

type NoError = {
    type: ErrorState.InValid
}

const NoErrorV: NoError = { type: ErrorState.InValid }

type Error = NoError | HasError

interface DialogueP {
    canpublish: any
    isdeployed: { error: boolean; success: boolean; deploying: boolean }
    deploybot: Function
    dialogs: DialogueT[]
    intents: IntentT[]
    moveDialog: Function
    assistant: AssistantT
    workspacename: string
    welcomeNode: DialogueT
    fallbackNode: DialogueT
    retry: Function
    createNewDialog: Function
    updateDialog: Function
    deleteDialog: Function
    openCanvas: Function
    deployAssistant: Function
    publishAssistantHandle: Function
    PageState: PageStateT
    setPageState: React.Dispatch<React.SetStateAction<PageStateT>>
    entities: EntityT[]
    dialogues: DialogueT[][]
    openChatChannel: Function
    openWorkflow: Function
    rootNodes: any[]
    shouldBeDeployed: React.MutableRefObject<'no' | 'yes'>
}

export const MixChild = (Mixture: DialogueT[][]) => (element: DialogueT) => {
    const fn = (Mixture: DialogueT[][]) => {
        const valu = R.flatten(
            Mixture.map((m: DialogueT[]) => {
                return m.filter((e: DialogueT) => {
                    return e.parent === element.uuid
                })
            })
        ) as any
        return valu as DialogueT[]
    }
    return sortBySibling(fn(Mixture))
}

const Dialogue = (props: DialogueP) => {
    const {
        canpublish,
        dialogs,
        dialogues,
        assistant,
        moveDialog,
        intents,
        workspacename,
        welcomeNode,
        fallbackNode,
        deployAssistant,
        retry,
        openCanvas,
        PageState,
        setPageState,
        createNewDialog,
        updateDialog,
        deleteDialog,
        entities,
        isdeployed,
        deploybot,
        rootNodes,
        shouldBeDeployed
    }: DialogueP = props
    let inputRef: any
    // console.log('Dialogue ::: PageState', PageState)

    const journeyData = useJourney()

    const [tryme, setTryme] = useState<boolean>(false)

    const [viewType, setviewType] = useState<PageViewsT>(GridView)

    const [modelError, setModelError] = useState<Error>(NoErrorV)

    const [insertKey, setInsertKey] = useState<number>(13)

    const [inputFocus, setInputFocus] = useState<boolean>(false)

    const [searchWord, setSearchWord] = useState<string>('')

    const [searchWordForMove, setSearchWordForMove] = useState<string>('')

    const [showChatChannelsPopup, setShowChatChannelsPopup] = useState<boolean>(true)

    const [movePupup, setMovePupup] = useState<boolean>(false)

    const [selectedMoveDialogId, setSelectedMoveDialogId] = useState<string>('')

    const [selectedDialog, setSelectedDialog] = useState<any>(null)

    const [moveLoading, setMoveLoading] = useState<boolean>(false)

    const [selector, setSelector] = useState<string>('above')

    const [publishId, setPublishId] = useState('')

    const [isNull, setIsNull] = useState(false)
    const [isIntentEntityEmpty, setIsIntentEntityEmpty] = useState(false)

    const resetToView = () =>
        dialogs.length === 0
            ? setPageState({
                  action: ActionT.NO_DATA_FOUND,
                  loading: false,
                  showPopup: false,
                  title: '',
                  description: '',
                  show: 'dialog-only'
              })
            : setPageState(VIEW)

    const [status, setStatus] = useState<statusT>('checking_for_update')
    const poll = useRef<number | null>(null)
    const EditFAQ = (id: string) => {
        let value = dialogs.find(data => data.id === id)
        setPageState({ action: ActionT.UPDATE_FAQ, value, loading: false })
    }
    const OnListViewClick = (type: string, id: string) => {
        type === 'if_node' ? openCanvas(id) : EditFAQ(id)
    }
    const OnEditViewClick = (type: string, id: string) => {
        type === 'if_node' ? editIndividual(id) : EditFAQ(id)
    }
    const [isLive, setIsLive] = useState(undefined)

    const [workSpaces, setWorkSpaces] = useState({ slackWorkspaces: [], teamsWorkspaces: [], agentHandOff: {} })
    const [loading, setLoading] = useState(false)
    const [assistants, setAssistants] = useState([])

    // const tryMeDisabled = disableDeployBtn ? true : isNull

    const storage = typeof Storage !== 'undefined'

    const previousAction = usePrevious(PageState.action)

    const previousAssistant = usePrevious(isdeployed)

    const unpublishbot = (id: string, success: Function, failure: Function,name:string) => {
        unpublishAssistant(workspacename, id)
            .then(res => {
                // retry()
                logEvent(workspacename, {
                    event: "EVENT::CHATBOT::UNPUBLISH_BOT", event_meta:{
                    name: name,
                    description: ""
                 }}).finally(()=>{
                    success()
                    window.location.reload()
                })         
            })
            .catch(err => {
                failure()
            })
    }
    const publishbot = (id: string, success: Function, failure: Function,name:string) => {
        props.publishAssistantHandle(id, success, failure, name)
        // .then(res => {
        //     //retry()
        //     success()
        //     window.location.reload()
        // })
        // .catch(err => {
        //     failure()
        // })
    }
    const GetAssistants = () => {
        getAssistants(workspacename)
            .then((res: any) => {
                setAssistants(res)
            })
            .catch(err => console.log('Err::', err))
    }

    const fetchChild = useCallback(() => MixChild(dialogues), [dialogues])()

    const showInputError = (dialog: DialogueT) => {
        const parentNode =
            dialog.conditions &&
            (dialog.conditions.type == 'FallBackExpression')
                ? false
                : validateNode({ intents, entities }, dialog.conditions)
        const responses: any = !parentNode
            ? dialog.type === 'if_node'
                ? dialog.responses
                      .filter(
                          dg =>
                              dg.type == 'slot_container' ||
                              dg.type == 'automation' ||
                              dg.type == 'agent_handoff' ||
                              dg.type == 'file_upload'
                      )
                      .map(dg => {
                          return validateResponseNode({ intents, entities }, dg, rootNodes)
                      })
                      .concat(
                          fetchChild(dialog).map(c => {
                              return showInputError(c)
                          }) as any
                      )
                : validateFaq(dialog,rootNodes)
            : []

        return parentNode || responses.some((r: any) => r == true)
    }

    function validateFaq(dialog:FAQT,rootNodes:DialogueT[]){
        if((dialog.next_step as any).jump_to){
            const matchDialog: DialogueT = rootNodes.filter(dia => dia.uuid == (dialog.next_step as any).jump_to.to || dia.id == (dialog.next_step as any).jump_to.to)[0]
            return [matchDialog != undefined ? false : true]
        }else if((dialog.next_step as any).exit){
            return [false]
        }
            return [true]
    }

    useEffect(() => {
        // console.log('isIntent empty', dialogs)
        // if (dialogs.length > 0) {
        //     let disable = dialogs
        //         .map((dialogue: DialogueT) => {
        //             const child = fetchChild(dialogue)

        //             const showError = showInputError(dialogue) || child.filter(c => showInputError(c)).length > 0
        //             return showError
        //         })
        //         .some((data: any) => data === true)
        //     // console.log('isIntent empty ::: 2', disable)

        //     setIsIntentEntityEmpty(disable)
        // }
        if (assistant.id !== '') {
            getDialogues(workspacename, assistant.id)
                .then(res => {
                    // console.log('isNULL', res)
                    let flowNodes = fetchFlowNodes(res)
                    const data =
                        flowNodes && flowNodes.length > 0 && flowNodes.every(dialog => dialog.automation === null)
                    setIsNull(data)
                })
                .catch(err => {
                    console.log('IfNode err', err)
                })
        }
    }, [dialogs])
    useEffect(() => {
        if (assistants.length === 0) {
            GetAssistants()
        }
        if (assistant.id !== '') {
            getDialogues(workspacename, assistant.id)
                .then(res => {
                    // console.log('isNULL', res)
                    let flowNodes = fetchFlowNodes(res)
                    const data =
                        flowNodes && flowNodes.length > 0 && flowNodes.every(dialog => dialog.automation === null)
                    setIsNull(data)
                })
                .catch(err => {
                    console.log('IfNode err', err)
                })
        }
    }, [assistant.id])

    function pollAPI() {
        // console.log('send nlu status pollAPI')
        const intervalHolder = setInterval(() => {
            // console.log('send nlu status start')
            const nluStatus = MOCK_TRYIT
                ? getJson(DialogApiURLForPolling(`/status`, `testing_${assistant.id}`))
                : getJson(DialogApiURLForPolling(`/${workspacename}/nlu/status`, `testing_${assistant.id}`))

            nluStatus
                .then(res => {
                    // console.log('clear poll api :: before')
                    setStatus(res)
                    if (res == 'trained' || res == 'failed' || res == 'bot_not_deployed') {
                        // console.log("clear poll api")
                        storage && sessionStorage.setItem(assistant.id, res)
                        clearInterval(poll.current as number)
                        poll.current = null
                    } else {
                        storage && sessionStorage.setItem(assistant.id, res)
                    }
                })
                .catch(_err => {})
        }, 5000)
        // console.log('get nlu poll appi called start', intervalHolder)
        return intervalHolder
    }

    useEffect(() => {
        const trained =
            storage &&
            sessionStorage.getItem(assistant.id) &&
            sessionStorage.getItem(assistant.id) == ('trained' || 'failed' || 'bot_not_deployed')
        if (assistant.id.length > 0 && poll.current == null && PageState.action != ActionT.DEPLOY) {
            if (
                previousAction == ActionT.ADD ||
                previousAction == ActionT.EDIT ||
                previousAction == ActionT.DELETE ||
                previousAction == ActionT.MULTIPLE_DELETE ||
                previousAction == ActionT.CREATE_FAQ ||
                previousAction == ActionT.UPDATE_FAQ
            ) {
                shouldBeDeployed.current = 'yes'
            }

            if (
                !trained ||
                previousAction == ActionT.ADD ||
                previousAction == ActionT.EDIT ||
                previousAction == ActionT.DELETE ||
                previousAction == ActionT.MULTIPLE_DELETE ||
                previousAction == ActionT.CREATE_FAQ ||
                previousAction == ActionT.UPDATE_FAQ
            ) {
                sessionStorage.setItem(assistant.id, 'checking_for_update')
                // console.log('send ::: calling nlu after action')

                setStatus('checking_for_update')
                poll.current = pollAPI()
            } else {
                setStatus(sessionStorage.getItem(assistant.id) as statusT)
            }
        }

        return () => {
            if (poll.current) {
                clearInterval(poll.current)
                poll.current = null
            }
        }
    }, [PageState.action, assistant])

    useEffect(() => {
        if (assistant.id.length > 0) {
            if (isdeployed.success === true && previousAssistant.deploying === true) {
                sessionStorage.setItem(assistant.id, 'checking_for_update')
                //  console.log('send calling nlu after deploy', isdeployed.success)

                setStatus('checking_for_update')
                poll.current = pollAPI()
            }
        }

        return () => {
            if (poll.current) {
                clearInterval(poll.current)
                poll.current = null
            }
        }
    }, [isdeployed.success])

    const deployAndFetchStatus = () => {
        // console.log('send calling deployAndFetchStatus')
        const fetchStatus = () => {
            // console.log('calling fetchStatus callback')

            if (poll.current === null) {
            }
        }
        deploybot()
    }

    // useEffect(() => {
    //     if (assistant.id.length > 0 && poll.current == null && isdeployed.success) {
    //         // console.log('calling nlu after deploy')
    //         sessionStorage.setItem(assistant.id, 'training')
    //         setStatus('training')
    //         poll.current = pollAPI()
    //         // if (poll.current) {
    //         //     clearInterval(poll.current)

    //         // }
    //     }

    //     return () => {
    //         if (poll.current) {
    //             clearInterval(poll.current)
    //             poll.current = null
    //         }
    //     }
    // }, [isdeployed])

    useEffect(() => {
        inputRef && inputRef.focus()
    })

    const movedialog = (id: string) => {
        setSelectedMoveDialogId(id)
        setMovePupup(true)
    }

    const handleMoveSave = () => {
        setMoveLoading(true)
        const target = dialogs.filter(e => e.id === selectedMoveDialogId)[0]

        if (selector === 'above') {
            moveDialog(target.uuid, { previous_sibling: selectedDialog.previous_sibling, parent: null }, () => {
                setMoveLoading(false)
                setSelector('above')
                setSelectedDialog(null)
                setSelectedMoveDialogId('')
                setMovePupup(false)
                GetAssistants()
            })
        } else if (selector === 'below') {
            moveDialog(target.uuid, { previous_sibling: selectedDialog.uuid, parent: null }, () => {
                setMoveLoading(false)
                setSelector('above')
                setSelectedDialog(null)
                setSelectedMoveDialogId('')
                setMovePupup(false)
                GetAssistants()
            })
        } else {
            setMoveLoading(false)
        }
        setSearchWordForMove('')
    }

    const deleteConformation = () => {
        if (PageState.action === ActionT.SELECT) {
            setPageState({
                action: ActionT.MULTIPLE_DELETE,
                items: PageState.items,
                loading: false
            })
        } else {
        }
    }

    const renderNodes = () =>
        searchWord !== ''
            ? dialogs.filter(dialog => dialog.title.toLowerCase().includes(searchWord.toLowerCase()))
            : dialogs

    const isSelected = (id: string) => {
        if (PageState.action === ActionT.SELECT || PageState.action === ActionT.MULTIPLE_DELETE) {
            return PageState.items.findIndex((dialog: string) => dialog == id) !== -1
        }
        return false
    }

    const selectDialog = (dialogue: string) => {
        switch (PageState.action) {
            case ActionT.SELECT:
                const selected: string[] =
                    PageState.items.filter((id: string) => id === dialogue).length > 0
                        ? PageState.items.filter((id: string) => id !== dialogue)
                        : [...PageState.items, dialogue]
                if (selected.length) {
                    setPageState({ action: ActionT.SELECT, items: selected })
                } else {
                    setPageState(VIEW)
                }
                break
            default:
                const newSelected = [dialogue]
                setPageState({ action: ActionT.SELECT, items: newSelected })
                break
        }
    }

    const deleteIndividual = (dialogue: { uuid: string; title: string; type: 'if_node' | 'faq_node' }) => {
        setPageState({
            action: ActionT.DELETE,
            data: dialogue,
            loading: false
        })
    }

    const editIndividual = (id: string) => {
        setPageState({
            action: ActionT.EDIT,
            loading: false,
            id: id,
            uuid: dialogs.filter(data => data.id === id).length ? dialogs.filter(data => data.id === id)[0].uuid : '',
            title: dialogs.filter(data => data.id === id).length ? dialogs.filter(data => data.id === id)[0].title : '',
            description: dialogs.filter(data => data.id === id).length
                ? dialogs.filter(data => data.id === id)[0].description
                : ''
        })
    }

    const handleChage = (name: string) =>
        PageState.action === ActionT.ADD ||
        PageState.action === ActionT.NO_DATA_FOUND ||
        PageState.action === ActionT.EDIT
            ? setPageState(state => ({ ...state, title: name }))
            : null

    const handleDescriptionChange = (description: string) =>
        PageState.action === ActionT.ADD ||
        PageState.action === ActionT.NO_DATA_FOUND ||
        PageState.action === ActionT.EDIT
            ? setPageState(state => ({ ...state, description }))
            : null

    const showSelection = () =>
        PageState.action === ActionT.SELECT ||
        (PageState.action === ActionT.MULTIPLE_DELETE && PageState.items.length > 0)

    const renderModel = () => {
        if (
            journeyData.type === 'success' &&
            journeyData.value.state().actions.filter(x => x.type === 'ui' && x.name === 'chat-channel').length > 0 &&
            showChatChannelsPopup == true
        ) {
            return (
                <Modal showPopupLoader={false}>
                    <ErrorBoundary
                        error={modelError}
                        render={(err: any, info: any) => {
                            return (
                                err && (
                                    <Error.Delete
                                        onClick={() => {
                                            setModelError(NoErrorV)
                                        }}
                                        info={info}
                                    />
                                )
                            )
                        }}
                    >
                        <Paragraph> Do you want to deploy the {assistant.name} in chat channels?</Paragraph>
                        <Paragraph> (e.g, MS Teams, Slack & Widget)</Paragraph>
                        <ButtonWrapper>
                            <Button
                                onClick={() => {
                                    journeyData.value.send({
                                        type: 'EVENT::DIALOG::END_JOURNEY',
                                        tag: 'DIALOG_GENERIC_EVENT',
                                        journeyType: 'Dialog',
                                        botId: assistant.id
                                    })
                                    props.openChatChannel()
                                }}
                            >
                                Chat Channels
                            </Button>
                            <Button
                                className="btn btn-success"
                                type="button"
                                onClick={() => {
                                    setShowChatChannelsPopup(false)
                                    journeyData.value.send({
                                        type: 'EVENT::DIALOG::END_JOURNEY',
                                        tag: 'DIALOG_GENERIC_EVENT',
                                        journeyType: 'Dialog',
                                        botId: assistant.id
                                    })
                                }}
                            >
                                Cancel
                            </Button>
                        </ButtonWrapper>
                    </ErrorBoundary>
                </Modal>
            )
        }
        if (
            PageState.action === ActionT.ADD ||
            (PageState.action === ActionT.NO_DATA_FOUND && PageState.showPopup) ||
            PageState.action === ActionT.EDIT
        ) {
            return (
                <div className="fff">
                    <Modal error={modelError} showPopupLoader={PageState.loading}>
                        <ErrorBoundary
                            error={modelError}
                            render={(err: any, info: any) => {
                                return (
                                    err && (
                                        <Error.AddorEdit
                                            onClick={() => {
                                                setModelError(NoErrorV)
                                                PageState.action === ActionT.NO_DATA_FOUND
                                                    ? setPageState({
                                                          action: ActionT.NO_DATA_FOUND,
                                                          loading: false,
                                                          showPopup: false,
                                                          title: '',
                                                          description: '',
                                                          show: 'dialog-only'
                                                      })
                                                    : setPageState(VIEW)
                                            }}
                                            info={info}
                                        />
                                    )
                                )
                            }}
                        >
                            <Validate
                                defaultValue={
                                    PageState.action === ActionT.ADD || PageState.action === ActionT.NO_DATA_FOUND
                                        ? { dialog: false, description: true }
                                        : { dialog: true, description: true }
                                }
                            >
                                {PageState.action === ActionT.ADD || PageState.action === ActionT.NO_DATA_FOUND ? (
                                    <h2>Create Dialog</h2>
                                ) : (
                                    <h2>Edit Dialog</h2>
                                )}
                                {PageState.action === ActionT.ADD || PageState.action === ActionT.NO_DATA_FOUND ? (
                                    <Paragraph margin>Enter a name for the dialog</Paragraph>
                                ) : (
                                    <Paragraph margin>Edit dialog name</Paragraph>
                                )}
                                <>
                                    <InputBox
                                        validationList={dialogNameValidation}
                                        value={PageState.title}
                                        name="dialog"
                                        render={(
                                            value: string,
                                            valid: boolean,
                                            errorMsg: string,
                                            startValidation: () => void,
                                            handleInput: Function
                                        ) => {
                                            return (
                                                <>
                                                    <InputWrapper>
                                                        <ErrorMessage>{!valid ? errorMsg : newLine}</ErrorMessage>
                                                        <InputContainer>
                                                            <Input
                                                                primary={!valid ? `primary` : ''}
                                                                type="text"
                                                                onKeyDown={(e: any) => setInsertKey(e.keyCode)}
                                                                onChange={(event: any) => {
                                                                    if (
                                                                        (insertKey > 64 && insertKey < 91) ||
                                                                        insertKey == 32 ||
                                                                        insertKey == 8 ||
                                                                        (insertKey > 47 && insertKey < 58)
                                                                    ) {
                                                                        startValidation(),
                                                                            handleInput('dialog', event.target.value)
                                                                        handleChage(event.target.value)
                                                                    }
                                                                }}
                                                                value={value}
                                                            />
                                                            <InputContent>Dialog name</InputContent>
                                                            {!valid && (
                                                                <InputErrorIcon>
                                                                    <ErrorIcon />
                                                                </InputErrorIcon>
                                                            )}
                                                        </InputContainer>
                                                    </InputWrapper>
                                                </>
                                            )
                                        }}
                                    />
                                    <InputBox
                                        validationList={dialogDescriptionValidation}
                                        value={PageState.description}
                                        name="description"
                                        render={(
                                            value: string,
                                            valid: boolean,
                                            errorMsg: string,
                                            startValidation: () => void,
                                            handleInput: Function
                                        ) => {
                                            return (
                                                <>
                                                    <InputWrapper>
                                                        <ErrorMessage>{!valid ? errorMsg : newLine}</ErrorMessage>
                                                        <InputContainer>
                                                            <Input
                                                                primary={!valid ? `primary` : ''}
                                                                type="text"
                                                                onKeyDown={(e: any) => setInsertKey(e.keyCode)}
                                                                onChange={(event: any) => {
                                                                    startValidation(),
                                                                        handleInput('description', event.target.value)
                                                                    handleDescriptionChange(event.target.value)
                                                                }}
                                                                value={value}
                                                            />
                                                            <InputContent>Dialog description</InputContent>
                                                            {!valid && (
                                                                <InputErrorIcon>
                                                                    <ErrorIcon />
                                                                </InputErrorIcon>
                                                            )}
                                                        </InputContainer>
                                                    </InputWrapper>
                                                </>
                                            )
                                        }}
                                    />
                                    <ErrorContext.Consumer>
                                        {valid => {
                                            return (
                                                <ButtonWrapper>
                                                    <React.Fragment>
                                                        <Button
                                                            primary
                                                            type="button"
                                                            onClick={
                                                                PageState.action !== ActionT.NO_DATA_FOUND
                                                                    ? resetToView
                                                                    : () =>
                                                                          setPageState(state => ({
                                                                              ...state,
                                                                              showPopup: false
                                                                          }))
                                                            }
                                                        >
                                                            <label>Cancel</label>
                                                        </Button>
                                                        {PageState.action === ActionT.ADD ||
                                                        PageState.action === ActionT.NO_DATA_FOUND ? (
                                                            <Button
                                                                disabled={!valid.formValid.formValid}
                                                                className={
                                                                    !valid.formValid.formValid
                                                                        ? 'editor_btn_disabled'
                                                                        : ''
                                                                }
                                                                type="button"
                                                                onClick={() => {
                                                                    createNewDialog(() => {
                                                                        setPageState(e => ({
                                                                            ...e,
                                                                            loading: false
                                                                        }))
                                                                        setModelError({
                                                                            type: ErrorState.Valid,
                                                                            error: true,
                                                                            info: ''
                                                                        })
                                                                    })
                                                                }}
                                                            >
                                                                <label>Create</label>
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                type="button"
                                                                className={
                                                                    !valid.formValid.formValid
                                                                        ? 'editor_btn_disabled'
                                                                        : ''
                                                                }
                                                                disabled={!valid.formValid.formValid}
                                                                onClick={() => {
                                                                    setPageState(e => ({
                                                                        ...e,
                                                                        loading: true
                                                                    }))
                                                                    updateDialog(
                                                                        () => {
                                                                            setPageState(e => ({
                                                                                ...e,
                                                                                loading: false
                                                                            }))
                                                                            setModelError({
                                                                                type: ErrorState.Valid,
                                                                                error: true,
                                                                                info: ''
                                                                            })
                                                                        },
                                                                        () => GetAssistants()
                                                                    )
                                                                }}
                                                            >
                                                                <label>Save</label>
                                                            </Button>
                                                        )}
                                                    </React.Fragment>
                                                </ButtonWrapper>
                                            )
                                        }}
                                    </ErrorContext.Consumer>
                                </>
                            </Validate>
                        </ErrorBoundary>
                    </Modal>
                </div>
            )
        } else if (PageState.action === ActionT.DELETE) {
            return (
                <Modal showPopupLoader={PageState.loading}>
                    <ErrorBoundary
                        error={modelError}
                        render={(err: any, info: any) => {
                            return (
                                err && (
                                    <Error.Delete
                                        onClick={() => {
                                            setModelError(NoErrorV)
                                        }}
                                        info={info}
                                    />
                                )
                            )
                        }}
                    >
                        <h2>Delete {PageState.data.type == 'if_node' ? 'Dialog' : 'FAQ'}</h2>
                        <Paragraph className="delete_dialog_content">Are you sure to delete the "{PageState.data.title}"? </Paragraph>
                        <ButtonWrapper>
                            <Button primary type="button" onClick={resetToView}>
                                <label>No</label>
                            </Button>
                            <Button
                                type="button"
                                onClick={() => {
                                    // console.log('PageState:::', PageState)
                                    setPageState(e => ({
                                        ...e,
                                        loading: true
                                    }))
                                    deleteDialog(
                                        () => {
                                            setPageState(e => ({
                                                ...e,
                                                loading: false
                                            }))
                                            setModelError({
                                                type: ErrorState.Valid,
                                                error: true,
                                                info: ''
                                            })
                                        },
                                        () => GetAssistants()
                                    )
                                }}
                            >
                                <label>Yes</label>
                            </Button>
                        </ButtonWrapper>
                    </ErrorBoundary>
                </Modal>
            )
        } else if (PageState.action === ActionT.MULTIPLE_DELETE) {
            return (
                <Modal showPopupLoader={PageState.loading}>
                    <ErrorBoundary
                        error={modelError}
                        render={(err: any, info: any) => {
                            return (
                                err && (
                                    <Error.Delete
                                        onClick={() => {
                                            setModelError(NoErrorV)
                                        }}
                                        info={info}
                                    />
                                )
                            )
                        }}
                    >
                        <h2>Delete Dialog / FAQ</h2>
                        <Paragraph>Are you sure to delete the selected Dialog / FAQ?</Paragraph>{' '}
                        <ButtonWrapper>
                            <Button primary type="button" onClick={resetToView}>
                                <label> No</label>
                            </Button>
                            <Button
                                type="button"
                                onClick={() => {
                                    setPageState(e => ({
                                        ...e,
                                        loading: true
                                    }))
                                    deleteDialog(
                                        () => {
                                            setPageState(e => ({
                                                ...e,
                                                loading: false
                                            }))
                                            setModelError({
                                                type: ErrorState.Valid,
                                                error: true,
                                                info: ''
                                            })
                                        },
                                        () => GetAssistants()
                                    )
                                }}
                            >
                                <label>Yes</label>
                            </Button>
                        </ButtonWrapper>
                    </ErrorBoundary>
                </Modal>
            )
        } else if (PageState.action === ActionT.DEPLOY) {
            return (
                <Modal showPopupLoader={PageState.loading}>
                    <ErrorBoundary
                        error={modelError}
                        render={(err: any, info: any) => {
                            return (
                                err && (
                                    <Error.Delete
                                        onClick={() => {
                                            setModelError(NoErrorV)
                                        }}
                                        info={info}
                                    />
                                )
                            )
                        }}
                    >
                        <h2>Deployment</h2>
                        <p>
                            Would you like to deploy all the Dialogs, Intents, Entities created/updated to this point?
                        </p>
                        <div>
                            <button className="btn btn-primary" type="button" onClick={resetToView}>
                                Cancel
                            </button>
                            <button
                                className="btn btn-success"
                                type="button"
                                onClick={() => {
                                    deployAssistant(() => {
                                        setPageState(e => ({
                                            ...e,
                                            loading: false
                                        }))
                                        setModelError({
                                            type: ErrorState.Valid,
                                            error: true,
                                            info: ''
                                        })
                                    })
                                }}
                            >
                                Ok
                            </button>
                        </div>
                    </ErrorBoundary>
                </Modal>
            )
        } else if (PageState.action === ActionT.CREATE_FAQ) {
            let faqnode = {
                title: '',
                description: '',
                conditions: [''],
                nextstep: {
                    then: 'jump_to',
                    jumpto: { id: '', title: '' },
                    exit: { blocks: [], entityMap: {} }
                },
                responses: { type: 'text_message' }
            }
            return (
                <AssistantDialogueFAQ
                    onCancel={resetToView}
                    dialougeId={assistant.id}
                    {...props}
                    state={'create'}
                    node={faqnode}
                    refresh={retry}
                    assistants={GetAssistants}
                />
            )
        } else if (PageState.action === ActionT.UPDATE_FAQ) {
            let value = PageState.value
            let faqnode = {
                id: value.id,
                uuid: value.uuid,
                created: value.created,
                updated: value.updated,
                title: value.title,
                description: value.description,
                conditions: value.conditions,
                nextstep: {
                    then: '',
                    jumpto: { id: '', title: '' },
                    exit: { blocks: [], entityMap: {} } as DraftJST
                },
                responses: value.responses
            }
            if (value.next_step.jump_to) {
                let title = rootNodes.find(data => data.uuid === value.next_step.jump_to.to)
                faqnode.nextstep.jumpto = title ? { id: title.uuid, title: title.title } : { id: '', title: '' }
                faqnode.nextstep.then = 'jump_to'
            }
            if (value.next_step.exit) {
                faqnode.nextstep.exit = converterToEditor(value.next_step.exit.message)
                faqnode.nextstep.then = 'exit'
            }
            return (
                <AssistantDialogueFAQ
                    onCancel={resetToView}
                    dialougeId={assistant.id}
                    {...props}
                    state={'update'}
                    node={faqnode}
                    refresh={retry}
                    assistants={GetAssistants}
                />
            )
        } else if (publishId !== '' && assistants.length > 0) {
            return (
                <PublishPopup
                    modelError={modelError}
                    setModelError={setModelError}
                    NoErrorV={NoErrorV}
                    publishbot={publishbot}
                    unpublishbot={unpublishbot}
                    publishId={publishId}
                    setPublishId={setPublishId}
                    setLoading={setLoading}
                    ErrorState={ErrorState}
                    loading={loading}
                    workSpaces={workSpaces}
                    setWorkSpaces={setWorkSpaces}
                    workspacename={workspacename}
                    Assistants={assistants}
                />
            )
        }
        return null
    }
    const renderMoveModel = () => {
        if (movePupup) {
            return (
                <WrapperMove>
                    <PopupWrapper>
                        <PopupContainer>
                            <MoveHeader className="moveto_popup_header">Move</MoveHeader>
                            <Paragraph className="text-left">
                                Click any dialog and select an option to move your dialog position.
                            </Paragraph>
                            <PopupScrollHeight>
                                <EmptyDiv>
                                    <div className="jump_to_panel">
                                        {moveLoading ? (
                                            <div className="popup_loader">
                                                <Loader.PopupLoader show={moveLoading} />
                                            </div>
                                        ) : (
                                            <>
                                                <SerachInputWrapper>
                                                    <SerachInput
                                                        className="w-100"
                                                        type="text"
                                                        placeholder="Search"
                                                        value={searchWordForMove}
                                                        onChange={(e: any) => setSearchWordForMove(e.target.value)}
                                                    />
                                                    <span style={{ top: 10 }}>
                                                        <SearchIcon />
                                                    </span>
                                                </SerachInputWrapper>

                                                <div className="right_panel_ht">
                                                    <ul className="tree1 zontal_lines1 move_popup_hide">
                                                        {dialogs
                                                            .filter(e =>
                                                                e.title
                                                                    ? e.title
                                                                          .toLowerCase()
                                                                          .includes(searchWordForMove.toLowerCase())
                                                                    : false
                                                            )
                                                            .map((dialog: DialogueT, index: number) => (
                                                                <li className="first_add_node" key={index.toString()}>
                                                                    <label
                                                                        className={
                                                                            selectedMoveDialogId !== dialog.id
                                                                                ? 'tree_label1'
                                                                                : 'tree_label1 hide_move_dialog'
                                                                        }
                                                                    >
                                                                        <div
                                                                            className="jump_to"
                                                                            onClick={() => {
                                                                                if (
                                                                                    selectedMoveDialogId !== dialog.id
                                                                                ) {
                                                                                    setSelectedDialog(dialog)
                                                                                }
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    selectedDialog &&
                                                                                    selectedDialog.id == dialog.id
                                                                                        ? 'jump_to_list_selected'
                                                                                        : 'jump_to_list'
                                                                                }
                                                                            >
                                                                                {(() => {
                                                                                    if (
                                                                                        dialog.conditions === null ||
                                                                                        (dialog.uuid !==
                                                                                            assistant.welcome_node &&
                                                                                            dialog.conditions.type !==
                                                                                                'FallBackExpression')
                                                                                    ) {
                                                                                        return (
                                                                                            <div className="parent_node_no">
                                                                                                <span className="parent_svg dialog_value">
                                                                                                    {selectedMoveDialogId !==
                                                                                                    dialog.id ? (
                                                                                                        index < 9 ? (
                                                                                                            '0' +
                                                                                                            (
                                                                                                                index +
                                                                                                                1
                                                                                                            ).toString()
                                                                                                        ) : index ==
                                                                                                          9 ? (
                                                                                                            '10'
                                                                                                        ) : (
                                                                                                            index + 1
                                                                                                        )
                                                                                                    ) : (
                                                                                                        <input
                                                                                                            type="radio"
                                                                                                            className="option-input radio"
                                                                                                            name="date"
                                                                                                            checked
                                                                                                        />
                                                                                                    )}
                                                                                                </span>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    return null
                                                                                })()}
                                                                                <h4>{dialog.title}</h4>
                                                                            </div>
                                                                            {selectedDialog &&
                                                                            selectedDialog.id == dialog.id ? (
                                                                                <DropDown
                                                                                    isOpen={
                                                                                        selectedDialog
                                                                                            ? selectedDialog.id ==
                                                                                              dialog.id
                                                                                            : false
                                                                                    }
                                                                                    onOuterClick={() => {}}
                                                                                >
                                                                                    <div className="dropdown_jumpto">
                                                                                        <div className="dropdown_move">
                                                                                            {selectedMoveDialogId !==
                                                                                            dialog.previous_sibling ? (
                                                                                                <span
                                                                                                    onClick={() =>
                                                                                                        setSelector(
                                                                                                            'above'
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    Move above this
                                                                                                    dialog
                                                                                                    <label>
                                                                                                        <input
                                                                                                            type="radio"
                                                                                                            className="option-input radio"
                                                                                                            name="example"
                                                                                                            checked={
                                                                                                                selector ==
                                                                                                                'above'
                                                                                                            }
                                                                                                            onChange={() =>
                                                                                                                setSelector(
                                                                                                                    'above'
                                                                                                                )
                                                                                                            }
                                                                                                        />
                                                                                                    </label>
                                                                                                </span>
                                                                                            ) : null}
                                                                                            {dialogs.findIndex(data =>
                                                                                                data.id ===
                                                                                                selectedMoveDialogId
                                                                                                    ? data.previous_sibling ===
                                                                                                      dialog.uuid
                                                                                                    : false
                                                                                            ) === -1 ? (
                                                                                                <span
                                                                                                    onClick={() =>
                                                                                                        setSelector(
                                                                                                            'below'
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    Move below this
                                                                                                    dialog
                                                                                                    <label>
                                                                                                        <input
                                                                                                            type="radio"
                                                                                                            className="option-input radio"
                                                                                                            name="example"
                                                                                                            checked={
                                                                                                                selector ==
                                                                                                                'below'
                                                                                                            }
                                                                                                            onChange={() =>
                                                                                                                setSelector(
                                                                                                                    'below'
                                                                                                                )
                                                                                                            }
                                                                                                        />
                                                                                                    </label>
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    </div>
                                                                                </DropDown>
                                                                            ) : null}
                                                                        </div>
                                                                    </label>
                                                                </li>
                                                            ))}
                                                    </ul>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </EmptyDiv>
                                <ButtonContainer className="footer_button_fixed">
                                        <div className="popup_footer_button_container">
                                    <Button
                                        primary
                                        type="button"
                                        data-dismiss="modal"
                                        onClick={() => setMovePupup(false)}
                                    >
                                        <label>Cancel</label>
                                    </Button>
                                    <Button
                                        className={selectedDialog == null ? 'editor_btn_disabled' : ''}
                                        type="button"
                                        data-dismiss="modal"
                                        onClick={() => handleMoveSave()}
                                    >
                                        <label>Move</label>
                                    </Button>
                                    </div>
                                </ButtonContainer>
                            </PopupScrollHeight>
                        </PopupContainer>
                    </PopupWrapper>
                </WrapperMove>
            )
        }
        return null
    }
    const crateNewParam = new URLSearchParams(window.location.search).get('create-dialog')
    // setPageState(crateNew ? { action: ActionT.ADD, loading: false, title: '' } : VIEW)

    const crateFAQParam = new URLSearchParams(window.location.search).get('create-faq')
    // setPageState(crateFAQ ? { action: ActionT.CREATE_FAQ, loading: false } : VIEW)

    const showErrorOnWelcomeNode = showInputError(welcomeNode)
    const showErrorOnFallbackNode = showInputError(fallbackNode)
    const [isTrue, setIsTrue] = useState(false)
    const [showPastePopup, setShowPastePopup] = useState<null|DialogueT>(null)

    const disableDeployBtn =
        showErrorOnWelcomeNode ||
        showErrorOnFallbackNode ||
        renderNodes()
            .map((dialogue: DialogueT, i: number) => showInputError(dialogue))
            .some(err => err == true) ||
        PageState.action === ActionT.NO_DATA_FOUND

    // let publishedDate = assistant.published_at !== null ? assistant.published_at.split('Z')[0] : ''
    // let recentPublish = assistant.updated.split('Z')[0] === publishedDate
    // let updatedbot = publishedDate !== ''

    // let disableKey = disableDeployBtn ? true : isNull
    // let disableState = disableKey || (!canpublish.limit && !updatedbot && !recentPublish)

    const tryMeDisabled = disableDeployBtn ? true : isNull

    const bottomNode = React.useRef<HTMLDivElement | null>(null)
    const containerNode = React.useRef<HTMLDivElement | null>(null)

    const scrollToBottom = () => {
        if (containerNode.current !== null) {
            containerNode.current.scrollTop = containerNode.current.scrollHeight
        }
    }

    const setOpenPastePopup = (dialog:DialogueT)=>{
        setShowPastePopup(dialog)
    }

    const writePermission = usePermissions(workspacename,`CHAT_BOT_MODULE/BOT/${assistant.id}/ROOT_DIALOG_NODE`,"WRITE")
    const deletePermission = usePermissions(workspacename,`CHAT_BOT_MODULE/BOT/${assistant.id}/ROOT_DIALOG_NODE`,"DELETE")
    const publishButtonPermission = usePermissions(workspacename,`ENGINE/PUBLIC_BOT`,"WRITE")

    if (PageState.action === ActionT.LOADING) {
        return <Loader.PageLoader show={true} />
    } else if (PageState.action === ActionT.NO_DATA_FOUND && PageState.show === 'workflow') {
        return (
            <AfterBotStarted
                botName={assistant.name}
                openCreateDialog={() =>
                    setPageState({
                        action: ActionT.ADD,
                        loading: false,
                        title: '',
                        description: ''
                    })
                }
                openCreateFaqDialog={() =>
                    setPageState({
                        action: ActionT.CREATE_FAQ,
                        loading: false
                    })
                }
                redirectToWorkflow={() => {
                    props.openWorkflow()
                    // /workspace/nirup/automation/view/home?showIntialCreationModel=true
                    // console.log('workflow')
                }}
            />
        )
    } else {
        return (
            <React.Fragment>
                {renderModel()}
                {renderMoveModel()}
                {showPastePopup!=null&&<PasteDialogsPopup dialog={showPastePopup} closePopup={()=>setShowPastePopup(null)} assistantID={assistant.id} workspaceName={workspacename} assistants={assistants} refresh={retry}/>}
                <ErrorBoundary
                    unauthorized={PageState.action === ActionT.UN_AUTHORIZED}
                    noDataFound={{
                        show: false,
                        noDatainfo:
                            '<p>Empty as a bird’s nest in December. Create your first dialog now!</p><p>We will create <b>Welcome</b> and <b>Fallback</b> dialogs!</p>',
                        onClick: () =>
                            setPageState(page => ({
                                ...page,
                                showPopup: true
                            })),
                        btnName: 'Create one'
                    }}
                    error={
                        PageState.action === ActionT.ERROR
                            ? { error: true, info: PageState.reason }
                            : { error: null, info: null }
                    }
                    render={(err: any, info: any) => {
                        return err && <Error.PageLoadingError onClick={() => retry()} info={info} btnName="Retry" />
                    }}
                >
                    {/* <AssistantDialogueFAQ /> */}
                    <Header>
                        <HeaderChild type="first">
                            <div className="indent_left">
                                <div className="indent_title">Dialog</div>
                                <div className="indent_sub_title">
                                    Catalog <span className="count_number">({dialogs.length})</span>
                                </div>
                            </div>

                            <div className="indent_right">
                                {(PageState.action === ActionT.SELECT ||
                                    (PageState.action === ActionT.MULTIPLE_DELETE && PageState.items.length > 0)) && (
                                    <Button
                                        primary
                                        style={{ background: '#FFE5D3' }}
                                        className="btn btn-primary btn_large select_button"
                                        onClick={() => setPageState(VIEW)}
                                    >
                                        <label>
                                            <b>
                                                {PageState.items.length > 9
                                                    ? PageState.items.length
                                                    : `0${PageState.items.length}`}
                                            </b>{' '}
                                            Dialogs selected
                                        </label>
                                        <span>
                                            <CloseIcon />
                                        </span>
                                    </Button>
                                )}

                                {PageState.action !== ActionT.SELECT &&
                                    !inputFocus &&
                                    PageState.action !== ActionT.NO_DATA_FOUND && writePermission.value && (
                                        <div className="btn_width_wrapper">
                                            <Button
                                                primary
                                                className="btn btn-primary"
                                                onMouseOver={() => setIsTrue(true)}
                                            >
                                                <span className="header_button">
                                                    <AddIcon />
                                                </span>
                                                <label>Create New</label>
                                            </Button>
                                            {isTrue && (
                                                <Downshift isOpen={true} onOuterClick={() => setIsTrue(false)}>
                                                    {() => (
                                                        <div className="flow_sort_by dialog_dp">
                                                            <div className="flow_sort_dropdown">
                                                                <p
                                                                    onClick={() => {
                                                                        setPageState({
                                                                            action: ActionT.ADD,
                                                                            loading: false,
                                                                            title: '',
                                                                            description: ''
                                                                        })
                                                                        setIsTrue(false)
                                                                    }}
                                                                >
                                                                    <AddIcon />
                                                                    <span>Create Dialog</span>
                                                                </p>
                                                                <p
                                                                    onClick={() => {
                                                                        setPageState({
                                                                            action: ActionT.CREATE_FAQ,
                                                                            loading: false
                                                                        })
                                                                        setIsTrue(false)
                                                                    }}
                                                                >
                                                                    <AddIcon />
                                                                    <span>Create FAQ</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Downshift>
                                            )}
                                        </div>
                                    )}
                            </div>
                        </HeaderChild>
                        <HeaderChild type="second">
                            {journeyData.type === 'success' &&
                                journeyData.value.state().actions.filter(x => x.name === 'publish').length > 0 &&
                                !disableDeployBtn && <div className="z__index"></div>}
                            {/* {PageState.action === ActionT.SELECT && (
                                <button className="btn btn-primary">
                                    <FolderIcon />
                                    <span>Group</span>
                                </button>
                            )} */}
                            {PageState.action === ActionT.SELECT && deletePermission.value && (
                                <button className="btn btn-primary" onClick={() => deleteConformation()}>
                                    <DeleteIcon />
                                    <span>Delete</span>
                                </button>
                            )}
                            
                            {/* <button className="btn btn-primary">
                                 <FolderIcon />
                                <span>Group</span>
                            </button> */}

                            <button
                                className="btn btn-primary"
                                onClick={() => setviewType(viewType.View === ViewTypeT.ListView ? GridView : ListView)}
                            >
                                {viewType.View != ViewTypeT.ListView ? <ListviewIcon /> : <GroupIcon />}
                                <span>{viewType.View === ViewTypeT.ListView ? 'Card View' : 'List View'}</span>
                            </button>

                            {!inputFocus ? (
                                <span className="search_icon_svg">
                                    <input
                                        type="search"
                                        placeholder="Search"
                                        value=""
                                        onFocus={() => setInputFocus(true)}
                                    />
                                    <SearchIcon />
                                </span>
                            ) : (
                                <span className="search_click">
                                    <input
                                        className="search_click"
                                        type="search"
                                        placeholder="Search"
                                        value={searchWord}
                                        autoFocus={true}
                                        onChange={e => setSearchWord(e.target.value)}
                                    />
                                    <span
                                        onClick={() => {
                                            setSearchWord('')
                                            setInputFocus(false)
                                        }}
                                    >
                                        <CloseIcon />
                                    </span>
                                </span>
                            )}
                            {PageState.action === ActionT.NO_DATA_FOUND || !publishButtonPermission.value ? null : (
                                <PublishButton
                                    assistant={assistant}
                                    canpublish={canpublish}
                                    className={
                                        journeyData.type === 'success' &&
                                        journeyData.value.state().actions.filter(x => x.name === 'publish').length >
                                            0 &&
                                        !disableDeployBtn
                                            ? 'publish_button_dialog z__index_1101'
                                            : 'publish_button_dialog'
                                    }
                                    isInCard={false}
                                    setPublishId={setPublishId}
                                    isNull={isNull}
                                    isLive={isLive ? true : false}
                                    isIntentEntityEmpty={disableDeployBtn}
                                />
                            )}

                            <VideoButton id={'DIALOG_HOMEPAGE_FUNCTIONS'} />
                        </HeaderChild>
                    </Header>
                    {PageState.action === ActionT.NO_DATA_FOUND && !(crateNewParam || crateFAQParam) ? (
                        <DialogOrFAQ
                            openCreateDialog={() =>
                                setPageState({
                                    action: ActionT.ADD,
                                    loading: false,
                                    title: '',
                                    description: ''
                                })
                            }
                            openCreateFaqDialog={() =>
                                setPageState({
                                    action: ActionT.CREATE_FAQ,
                                    loading: false
                                })
                            }
                        />
                    ) : (
                        <section ref={containerNode} className="entity_cards" id="dialog-cards-container">
                            {journeyData.type === 'success' &&
                                journeyData.value
                                    .state()
                                    .actions.filter(
                                        x =>
                                            (x.name === 'tryme-popup' && tryme === false && !tryMeDisabled) ||
                                            x.name === 'welcome-dialog' ||
                                            x.name === 'fallback-dialog'
                                    ).length > 0 && <div className="z__index"></div>}

                            <div className="col-md-12">
                                <div className={viewType.View === ViewTypeT.GridView ? 'row card_grid_view' : 'row'}>
                                    {viewType.View === ViewTypeT.ListView ? (
                                        <>
                                            <TabelWrapper>
                                                <TableContainer>
                                                    <Table>
                                                        <Thead>
                                                            <Tr>
                                                                <Th size={3}>Name</Th>
                                                                <Th size={4}>Description</Th>
                                                                <Th size={2}>Type</Th>
                                                                <Th size={2}>Date</Th>
                                                                <Th size={1}>Options</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                           {welcomeNode.title.toLowerCase().indexOf(searchWord.toLowerCase())!=-1 && <List
                                                                showCheckBox
                                                                checkBoxShow={false}
                                                                render={(show: boolean, ToolBox: any) => {
                                                                    return (
                                                                        <>
                                                                            <Tr
                                                                                onClick={() =>
                                                                                    openCanvas(welcomeNode.id)
                                                                                }
                                                                            >
                                                                                <Td size={3}>
                                                                                    <div className={'list_flow_hr'}>
                                                                                        <div className="search_card_list">
                                                                                            <h5>{welcomeNode.title}</h5>
                                                                                        </div>
                                                                                    </div>
                                                                                </Td>
                                                                                <Td size={4}>
                                                                                    {welcomeNode &&
                                                                                    'Add welcome message for your users'}
                                                                                </Td>
                                                                                <Td size={2}></Td>
                                                                                <Td size={2}>
                                                                                    <div className="list_flow_span">
                                                                                        <span className="entity_span">
                                                                                            {formatDateInCard(
                                                                                                welcomeNode.updated
                                                                                            )}
                                                                                        </span>
                                                                                        <span style={{ fontSize: 9 }}>
                                                                                            |
                                                                                        </span>
                                                                                        <span className="entity_span">
                                                                                            {formatAMPM(
                                                                                                welcomeNode.updated
                                                                                            )}
                                                                                        </span>
                                                                                    </div>
                                                                                </Td>
                                                                                <Td size={1}></Td>
                                                                            </Tr>

                                                                            {showErrorOnWelcomeNode && (
                                                                                <div className="error_input_show parent">
                                                                                    <Tooltip
                                                                                        className="target customTip"
                                                                                        zIndex={10000}
                                                                                        arrowSize={8}
                                                                                        tagName="label"
                                                                                        content="Input data is missing."
                                                                                        distance={3}
                                                                                    >
                                                                                        <WarningAlertIcon />
                                                                                    </Tooltip>
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    )
                                                                }}
                                                            />}
                                                            {renderNodes().map((dialogue: DialogueT, i: number) => {
                                                                let reg = new RegExp(searchWord, 'gi')
                                                                const child = fetchChild(dialogue)
                                                                let label =
                                                                    searchWord != ''
                                                                        ? `<h5 style='padding-top:${0}px'>` +
                                                                          dialogue.title +
                                                                          '</h5>'
                                                                        : `<h5 style='padding-top:${0}px'>` +
                                                                          dialogue.title +
                                                                          '</h5>'

                                                                const index =
                                                                    i < 9
                                                                        ? '0' + (i + 1).toString()
                                                                        : i == 9
                                                                        ? '10'
                                                                        : i + 1
                                                                const showError =
                                                                    showInputError(dialogue) ||
                                                                    child.filter(c => showInputError(c)).length > 0

                                                                return (
                                                                    <List
                                                                        key={i}
                                                                        showCheckBox
                                                                        checkBoxShow={showSelection()}
                                                                        render={(show: boolean, ToolBox: any) => {
                                                                            return (
                                                                                <Tr
                                                                                    onClick={() =>
                                                                                        dialogue.type=='if_node'? openCanvas(dialogue.id) : EditFAQ(dialogue.id)
                                                                                    }
                                                                                >
                                                                                    <Td
                                                                                        size={3}
                                                                                        className={'list_flow_hr'}
                                                                                    >
                                                                                        <label>
                                                                                            {show && (
                                                                                                <input
                                                                                                    type="radio"
                                                                                                    className="option-input radio"
                                                                                                    checked={isSelected(
                                                                                                        dialogue.uuid
                                                                                                            ? dialogue.uuid
                                                                                                            : dialogue.id
                                                                                                    )}
                                                                                                    onClick={e => {
                                                                                                        e.stopPropagation()
                                                                                                        selectDialog(
                                                                                                            dialogue.uuid
                                                                                                                ? dialogue.uuid
                                                                                                                : dialogue.id
                                                                                                        )
                                                                                                    }}
                                                                                                    onChange={() => {}}
                                                                                                    style={{
                                                                                                        visibility: show
                                                                                                            ? 'visible'
                                                                                                            : 'hidden',
                                                                                                        border:
                                                                                                            show &&
                                                                                                            !isSelected
                                                                                                                ? '1px solid #2468F6'
                                                                                                                : '1px solid #b9b9b9'
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        </label>

                                                                                        <div className="search_card_list">
                                                                                            <span className="count_list_no">
                                                                                                <span>{index}</span>
                                                                                            </span>
                                                                                            {Parser(label)}
                                                                                        </div>
                                                                                    </Td>
                                                                                    <Td size={4}>
                                                                                        {' '}
                                                                                        <div>
                                                                                            {dialogue.description}
                                                                                        </div>
                                                                                    </Td>
                                                                                    <Td size={2}>
                                                                                        {dialogue.type == 'if_node'
                                                                                            ? 'Dialog'
                                                                                            : 'Faq'}
                                                                                    </Td>

                                                                                    <Td
                                                                                        size={2}
                                                                                        className="list_flow_span"
                                                                                    >
                                                                                        <span className="entity_span">
                                                                                            {formatDateInCard(
                                                                                                dialogue.updated
                                                                                            )}
                                                                                        </span>
                                                                                        <span style={{ fontSize: 9 }}>
                                                                                            |
                                                                                        </span>
                                                                                        <span className="entity_span">
                                                                                            {formatAMPM(
                                                                                                dialogue.updated
                                                                                            )}
                                                                                        </span>
                                                                                    </Td>

                                                                                    <Td size={1}>
                                                                                        {PageState.action !==
                                                                                            ActionT.SELECT &&
                                                                                            PageState.action !==
                                                                                                ActionT.MULTIPLE_DELETE && (
                                                                                                <ToolBox>
                                                                                                    {writePermission.value &&<span
                                                                                                        onClick={e => {
                                                                                                            e.stopPropagation()
                                                                                                            movedialog(
                                                                                                                dialogue.id
                                                                                                            )
                                                                                                        }}
                                                                                                    >
                                                                                                        <Tooltip
                                                                                                            className="target customTip "
                                                                                                            zIndex={10000}
                                                                                                            arrowSize={8}
                                                                                                            tagName="span"
                                                                                                            content={'Move'}
                                                                                                            distance={5}
                                                                                                        >
                                                                                                        <svg
                                                                                                            version="1.1"
                                                                                                            id="Essentials__x2F__Interface"
                                                                                                            x="0px"
                                                                                                            y="0px"
                                                                                                            viewBox="0 0 48 48"
                                                                                                        >
                                                                                                            <g>
                                                                                                                <g>
                                                                                                                    <g>
                                                                                                                        <path d="M38,48c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1v46C39,47.6,38.6,48,38,48z" />
                                                                                                                    </g>
                                                                                                                    <g>
                                                                                                                        <path
                                                                                                                            d="M38,48c-0.3,0-0.5-0.1-0.7-0.3l-9-9c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l8.3,8.3l8.3-8.3c0.4-0.4,1-0.4,1.4,0
                    c0.4,0.4,0.4,1,0,1.4l-9,9C38.5,47.9,38.3,48,38,48z"
                                                                                                                        />
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                                <g>
                                                                                                                    <g>
                                                                                                                        <path d="M10,48c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1v46C11,47.6,10.6,48,10,48z" />
                                                                                                                    </g>
                                                                                                                    <g>
                                                                                                                        <path
                                                                                                                            d="M19,11c-0.3,0-0.5-0.1-0.7-0.3L10,2.4l-8.3,8.3c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4l9-9c0.4-0.4,1-0.4,1.4,0l9,9
                    c0.4,0.4,0.4,1,0,1.4C19.5,10.9,19.3,11,19,11z"
                                                                                                                        />
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </svg>
                                                                                                        </Tooltip>
                                                                                                    </span>}
                                                                                                    {writePermission.value &&<span onClick={(e)=>{
                                                                                                        e.preventDefault()
                                                                                                        e.stopPropagation()
                                                                                                        setOpenPastePopup(dialogue)
                                                                                                    }}>
                                                                                                        <Tooltip
                                                                                                            className="target customTip "
                                                                                                            zIndex={10000}
                                                                                                            arrowSize={8}
                                                                                                            tagName="span"
                                                                                                            content={'Copy'}
                                                                                                            distance={5}
                                                                                                        >
                                                                                                        <CopyIocn />
                                                                                                        </Tooltip>
                                                                                                    </span>}
                                                                                                    {writePermission.value &&<span
                                                                                                        onClick={e => {
                                                                                                            e.stopPropagation()
                                                                                                            editIndividual(
                                                                                                                dialogue.id
                                                                                                            )
                                                                                                        }}
                                                                                                    >
                                                                                                        <Tooltip
                                                                                                            className="target customTip "
                                                                                                            zIndex={10000}
                                                                                                            arrowSize={8}
                                                                                                            tagName="span"
                                                                                                            content={'Edit'}
                                                                                                            distance={5}
                                                                                                        >
                                                                                                            <EditIcon />
                                                                                                        </Tooltip>
                                                                                                        
                                                                                                    </span>}
                                                                                                    {deletePermission.value &&<span
                                                                                                        onClick={e => {
                                                                                                            e.stopPropagation()
                                                                                                            deleteIndividual(
                                                                                                                dialogue
                                                                                                            )
                                                                                                        }}
                                                                                                    >
                                                                                                        <Tooltip
                                                                                                            className="target customTip "
                                                                                                            zIndex={10000}
                                                                                                            arrowSize={8}
                                                                                                            tagName="span"
                                                                                                            content={'Delete'}
                                                                                                            distance={5}
                                                                                                        >
                                                                                                            <DeleteIcon />
                                                                                                        </Tooltip>
                                                                                                        
                                                                                                    </span>}
                                                                                                    {showError && (
                                                                                                        <span className="error_icon_lv">
                                                                                                            <Tooltip
                                                                                                                className="target customTip"
                                                                                                                zIndex={
                                                                                                                    10000
                                                                                                                }
                                                                                                                arrowSize={
                                                                                                                    8
                                                                                                                }
                                                                                                                tagName="label"
                                                                                                                content=" Input data is missing."
                                                                                                                distance={
                                                                                                                    3
                                                                                                                }
                                                                                                            >
                                                                                                                <WarningAlertIcon />
                                                                                                            </Tooltip>
                                                                                                        </span>
                                                                                                    )}
                                                                                                </ToolBox>
                                                                                            )}
                                                                                    </Td>
                                                                                </Tr>
                                                                            )
                                                                        }}
                                                                    />
                                                                )
                                                            })}
                                                            {fallbackNode.title.toLowerCase().indexOf(searchWord.toLowerCase())!=-1 &&<List
                                                                showCheckBox
                                                                checkBoxShow={false}
                                                                render={(show: boolean, ToolBox: any) => {
                                                                    return (
                                                                        <Tr>
                                                                            <Td size={3} className={'list_flow_hr'}>
                                                                                <div
                                                                                    className="search_card_list"
                                                                                    onClick={() =>
                                                                                        openCanvas(fallbackNode.id)
                                                                                    }
                                                                                >
                                                                                    <h5>{fallbackNode.title}</h5>
                                                                                </div>
                                                                            </Td>

                                                                            <Td size={4}>
                                                                                {' '}
                                                                                {fallbackNode &&
                                                                                    'Add fallback message for your users'}
                                                                            </Td>
                                                                            <Td size={2}></Td>

                                                                            <Td size={2} className="list_flow_span">
                                                                                <span className="entity_span">
                                                                                    {formatDateInCard(
                                                                                        fallbackNode.updated
                                                                                    )}
                                                                                </span>
                                                                                <span style={{ fontSize: 9 }}>|</span>
                                                                                <span className="entity_span">
                                                                                    {formatAMPM(fallbackNode.updated)}
                                                                                </span>
                                                                            </Td>

                                                                            <Td size={1}></Td>

                                                                            {showErrorOnFallbackNode && (
                                                                                <div className="error_input_show parent">
                                                                                    <Tooltip
                                                                                        className="target customTip"
                                                                                        zIndex={10000}
                                                                                        arrowSize={8}
                                                                                        tagName="label"
                                                                                        content=" Input data is missing."
                                                                                        distance={3}
                                                                                    >
                                                                                        <WarningAlertIcon />
                                                                                    </Tooltip>
                                                                                </div>
                                                                            )}
                                                                        </Tr>
                                                                    )
                                                                }}
                                                            />}
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </TabelWrapper>
                                            {/* <div className="col-md-5 intent_list_description caret_before">
                                            <span>Name</span>
                                        </div>
                                        <div className="col-md-3 intent_list_description">
                                            <span>Date</span>
                                        </div>
                                        <div className="col-md-3 intent_list_description"></div>
                                        <div className="col-md-1 intent_list_description text-left">Options</div>

                                        <List
                                        <div className="col-md-1 intent_list_description text-left">Options</div> */}
                                            {/* <List
                                            showCheckBox
                                            checkBoxShow={false}
                                            render={(show: boolean, ToolBox: any) => {
                                                return (
                                                    <div className="col-md-12 list-view-sub">
                                                        <div className="main_sub dialog_list_view">
                                                            <div className={'col-md-5 list_flow_hr'}>
                                                                <div
                                                                    className="search_card_list"
                                                                    onClick={() => openCanvas(welcomeNode.id)}
                                                                >
                                                                    <h5 style={{ paddingLeft: '35px' }}>
                                                                        {welcomeNode.title}
                                                                    </h5>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3  list_flow_span">
                                                                <span className="entity_span">
                                                                    {formatDateInCard(welcomeNode.updated)}
                                                                </span>
                                                                <span>|</span>
                                                                <span className="entity_span">
                                                                    {formatAMPM(welcomeNode.updated)}
                                                                </span>
                                                            </div>
                                                            <div className="col-md-2 list_flow_p">
                                                                <p className="listite_centre"></p>
                                                            </div>
                                                            {showErrorOnWelcomeNode && (
                                                                <div className="error_input_show parent">
                                                                    <WarningAlertIcon />
                                                                    <span className="error_menu_hvr">
                                                                        Input data is missing.
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            }}
                                        />

                                        {renderNodes().map((dialogue: DialogueT, i: number) => {
                                            let reg = new RegExp(searchWord, 'gi')
                                            const child = fetchChild(dialogue)
                                            let label =
                                                searchWord != ''
                                                    ? dialogue.title.replace(reg, function(str) {
                                                        return (
                                                            '<div class="highlighted-dialog-text d-inline">' +
                                                            str +
                                                            '</div>'
                                                        )
                                                    })
                                                    : `<h5 style='padding-top:${0}px'>` + dialogue.title + '</h5>'

                                            const index = i < 9 ? '0' + (i + 1).toString() : i == 9 ? '10' : i + 1
                                            const showError =
                                                showInputError(dialogue) || child.filter(c => showInputError(c)).length > 0
                                            return (
                                                <List
                                                    key={i}
                                                    showCheckBox
                                                    checkBoxShow={showSelection()}
                                                    render={(show: boolean, ToolBox: any) => {
                                                        return (
                                                            <div className="col-md-12 list-view-sub">
                                                                <div className="main_sub dialog_list_view">
                                                                    <div className={'col-md-5 list_flow_hr'}>
                                                                        <label>
                                                                            {show && (
                                                                                <input
                                                                                    type="radio"
                                                                                    className="option-input radio"
                                                                                    checked={isSelected(dialogue.id)}
                                                                                    onClick={() =>
                                                                                        selectDialog(dialogue.id)
                                                                                    }
                                                                                    onChange={() => {}}
                                                                                    style={{
                                                                                        visibility: show
                                                                                            ? 'visible'
                                                                                            : 'hidden',
                                                                                        border:
                                                                                            show && !isSelected
                                                                                                ? '1px solid #fff'
                                                                                                : '1px solid #999'
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </label>

                                                                        <div
                                                                            className="search_card_list"
                                                                            onClick={() =>
                                                                                OnListViewClick(dialogue.type, dialogue.id)
                                                                            }
                                                                        >
                                                                            <span className="count_list_no">{index}</span>
                                                                            {Parser(label)}
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-3  list_flow_span">
                                                                        <span className="entity_span">
                                                                            {formatDateInCard(dialogue.updated)}
                                                                        </span>
                                                                        <span>|</span>
                                                                        <span className="entity_span">
                                                                            {formatAMPM(dialogue.updated)}
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-md-2 list_flow_p">
                                                                        <p className="listite_centre"></p>
                                                                    </div>

                                                                    {PageState.action !== ActionT.SELECT &&
                                                                        PageState.action !== ActionT.MULTIPLE_DELETE && (
                                                                            <ToolBox>
                                                                                <span
                                                                                    onClick={() => movedialog(dialogue.id)}
                                                                                >
                                                                                    <svg
                                                                                        version="1.1"
                                                                                        id="Essentials__x2F__Interface"
                                                                                        x="0px"
                                                                                        y="0px"
                                                                                        viewBox="0 0 48 48"
                                                                                    >
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path d="M38,48c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1v46C39,47.6,38.6,48,38,48z" />
                                                                                                </g>
                                                                                                <g>
                                                                                                    <path
                                                                                                        d="M38,48c-0.3,0-0.5-0.1-0.7-0.3l-9-9c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l8.3,8.3l8.3-8.3c0.4-0.4,1-0.4,1.4,0
                    c0.4,0.4,0.4,1,0,1.4l-9,9C38.5,47.9,38.3,48,38,48z"
                                                                                                    />
                                                                                                </g>
                                                                                            </g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path d="M10,48c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1v46C11,47.6,10.6,48,10,48z" />
                                                                                                </g>
                                                                                                <g>
                                                                                                    <path
                                                                                                        d="M19,11c-0.3,0-0.5-0.1-0.7-0.3L10,2.4l-8.3,8.3c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4l9-9c0.4-0.4,1-0.4,1.4,0l9,9
                    c0.4,0.4,0.4,1,0,1.4C19.5,10.9,19.3,11,19,11z"
                                                                                                    />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </svg>
                                                                                </span>
                                                                                <span
                                                                                    onClick={() =>
                                                                                        OnEditViewClick(
                                                                                            dialogue.type,
                                                                                            dialogue.id
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <EditIcon />
                                                                                </span>
                                                                                <span
                                                                                    onClick={() =>
                                                                                        deleteIndividual({
                                                                                            title: dialogue.title,
                                                                                            id: dialogue.id
                                                                                        })
                                                                                    }
                                                                                >
                                                                                    <DeleteIcon />
                                                                                </span>
                                                                                {showError && (
                                                                                    <span className="error_icon_lv">
                                                                                        <WarningAlertIcon />
                                                                                        <span className="error_menu_hvr">
                                                                                            Input data is missing.
                                                                                        </span>
                                                                                    </span>
                                                                                )}
                                                                            </ToolBox>
                                                                        )}
                                                                </div>
                                                            </div>
                                                        )
                                                    }}
                                                />
                                            )
                                        })}
                                        <List
                                            showCheckBox
                                            checkBoxShow={false}
                                            render={(show: boolean, ToolBox: any) => {
                                                return (
                                                    <div className="col-md-12 list-view-sub">
                                                        <div className="main_sub dialog_list_view">
                                                            <div className={'col-md-5 list_flow_hr'}>
                                                                <div
                                                                    className="search_card_list"
                                                                    onClick={() => openCanvas(fallbackNode.id)}
                                                                >
                                                                    <h5 style={{ paddingLeft: '35px' }}>
                                                                        {fallbackNode.title}
                                                                    </h5>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3  list_flow_span">
                                                                <span className="entity_span">
                                                                    {formatDateInCard(fallbackNode.updated)}
                                                                </span>
                                                                <span>|</span>
                                                                <span className="entity_span">
                                                                    {formatAMPM(fallbackNode.updated)}
                                                                </span>
                                                            </div>
                                                            <div className="col-md-2 list_flow_p">
                                                                <p className="listite_centre"></p>
                                                            </div>
                                                            {showErrorOnFallbackNode && (
                                                                <div className="error_input_show parent">
                                                                    <WarningAlertIcon />
                                                                    <span className="error_menu_hvr">
                                                                        Input data is missing.
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            }}
                                        /> */}
                                        </>
                                    ) : (
                                        <>
                                            {searchWord === '' ? (
                                                <React.Fragment>
                                                    <NewCard
                                                        onClick={() =>
                                                            setPageState({
                                                                action: ActionT.ADD,
                                                                loading: false,
                                                                title: '',
                                                                description: ''
                                                            })
                                                        }
                                                        disabled={PageState.action === ActionT.SELECT}
                                                    />
                                                    <StartCard
                                                        disabled={PageState.action === ActionT.SELECT}
                                                        onClick={() => openCanvas(welcomeNode.id)}
                                                        dialog={welcomeNode}
                                                        showErrorOnDefaultNode={showErrorOnWelcomeNode}
                                                        botId={assistant.id}
                                                        scrollToBottom={scrollToBottom}
                                                        permissions={{write:writePermission.value,delete:deletePermission.value}}
                                                    />
                                                </React.Fragment>
                                            ) : null}
                                            {renderNodes().map((dialogue: DialogueT, i: number) => {
                                                let reg = new RegExp(searchWord, 'gi')
                                                const child = fetchChild(dialogue)
                                                const { responses, type } = dialogue as any

                                                const flowNodes =
                                                    type === 'if_node' &&
                                                    responses.filter((response: any) => response.type === 'automation')
                                                const checkAutomation =
                                                    flowNodes &&
                                                    flowNodes.length > 0 &&
                                                    flowNodes.every((dialog: any) => dialog.automation === null)
                                                let label =
                                                    searchWord != ''
                                                        ? `<h3 style='padding-top:${0}px'>` + dialogue.title + '</h3>'
                                                        : `<h3 style='padding-top:${0}px'>` + dialogue.title + '</h3>'

                                                const index = i < 9 ? '0' + (i + 1).toString() : i == 9 ? '10' : i + 1

                                                const showError =
                                                    showInputError(dialogue) ||
                                                    child.filter(c => showInputError(c)).length > 0
                                                return (
                                                    <>
                                                        {dialogue.type == 'if_node' ? (
                                                            <DialogCard
                                                                key={i}
                                                                type={dialogue.type}
                                                                isSelected={isSelected}
                                                                select={selectDialog}
                                                                showSelection={showSelection}
                                                                disabled={PageState.action === ActionT.SELECT}
                                                                onClick={openCanvas}
                                                                delete={deleteIndividual}
                                                                edit={editIndividual}
                                                                id={dialogue.id}
                                                                uuid={dialogue.uuid}
                                                                title={dialogue.title}
                                                                move={movedialog}
                                                                label={label}
                                                                dialogIndex={index}
                                                                modified={dialogue.updated}
                                                                showToolbox={PageState.action === ActionT.VIEW}
                                                                searchWord={searchWord}
                                                                showInputError={showError}
                                                                description={dialogue.description}
                                                                setOpenPastePopup={()=>setOpenPastePopup(dialogue)}
                                                                workspacename={workspacename}
                                                                assistantID={assistant.id}
                                                            />
                                                        ) : dialogue.type === 'faq_node' ? (
                                                            <FAQCard
                                                                key={i}
                                                                title={dialogue.title}
                                                                type={dialogue.type}
                                                                isSelected={isSelected}
                                                                select={selectDialog}
                                                                showSelection={showSelection}
                                                                disabled={PageState.action === ActionT.SELECT}
                                                                onClick={EditFAQ}
                                                                delete={deleteIndividual}
                                                                edit={EditFAQ}
                                                                id={dialogue.id}
                                                                uuid={dialogue.uuid}
                                                                move={movedialog}
                                                                label={label}
                                                                dialogIndex={index}
                                                                modified={dialogue.updated}
                                                                showToolbox={PageState.action === ActionT.VIEW}
                                                                searchWord={searchWord}
                                                                showInputError={showError}
                                                                description={dialogue.description}
                                                                permissions={{write:writePermission.value,delete:deletePermission.value}}
                                                            />
                                                        ) : null}
                                                    </>
                                                )
                                            })}
                                            {searchWord === '' ? (
                                                <EndCard
                                                    disabled={PageState.action === ActionT.SELECT}
                                                    onClick={() => openCanvas(fallbackNode.id)}
                                                    dialog={fallbackNode}
                                                    showErrorOnDefaultNode={showErrorOnFallbackNode}
                                                    botId={assistant.id}
                                                    scrollToBottom={scrollToBottom}
                                                    permissions={{write:writePermission.value,delete:deletePermission.value}}
                                                />
                                            ) : null}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div
                                ref={bottomNode}
                                style={{ float: 'left', clear: 'both', width: '1px', height: '200px' }}
                            >
                                {' '}
                            </div>
                        </section>
                    )}

                    <StatusContext.Provider value={status}>
                        <TryItWithButton
                            deployStatus={props.isdeployed}
                            workspacename={workspacename}
                            close={() => {
                                journeyData.type === 'success' &&
                                    journeyData.value.send({
                                        type: 'EVENT::DIALOG::TRYME_CLOSED',
                                        tag: 'DIALOG_GENERIC_EVENT',
                                        journeyType: 'Dialog',
                                        botId: assistant.id
                                    })
                                setTryme(false)
                            }}
                            intents={intents}
                            assistantid={assistant.id}
                            deployed={isdeployed.success}
                            deploymentError={isdeployed.error}
                            deploybot={deployAndFetchStatus}
                            setTryme={setTryme}
                            tryMeStatus={tryme}
                            hideAll={PageState.action === ActionT.NO_DATA_FOUND}
                            isNull={isNull}
                            isIntentEnityEmpty={disableDeployBtn}
                        />
                    </StatusContext.Provider>

                    <section className="intent_bottom">
                        <div>&nbsp;</div>
                    </section>
                </ErrorBoundary>
            </React.Fragment>
        )
    }
}

// const TryMeWithWrapper: React.SFC<any> = props => {}

const DefaultNode: React.SFC<any> = props => {
    // let toolTipclass = '_inactive'
    const toolTip = useRef<'_active' | '_inactive'>('_inactive')
    const journeyData = useJourney()
    // console.log('DefaultNode ::: ', (journeyData as any).value.state().actions)

    useEffect(() => {
        if (
            journeyData.type === 'success' &&
            journeyData.value.state().actions.filter(x => x.name === 'fallback-dialog').length > 0
        ) {
            props.scrollToBottom && props.scrollToBottom()
        }
    }, [props.scrollToBottom, journeyData])
    const showPopup = () => {
        if (
            journeyData.type === 'success' &&
            journeyData.value.state().actions.filter(x => x.name === 'welcome-dialog').length > 0
        ) {
            toolTip.current = '_active'
            // console.log('welcome ::: journey Data', journeyData.value.state())
            const welcomeText = journeyData.value.state().actions.filter(x => x.name === 'welcome-dialog')[0].name
            if (props.isStart) {
                return (
                    <div className="tooltip_wrapper tour_welcome_adjust_position">
                        <div className="tooltip_wrapper_bg">
                            <p>
                                You can update your bot's welcome or greeting message (to the user) from this welcome
                                dialog
                            </p>
                            <Button
                                onClick={(event: any) => {
                                    event.stopPropagation()
                                    journeyData.value.send({
                                        type: 'EVENT::DIALOG::WELCOME_CLICKED',
                                        tag: 'DIALOG_GENERIC_EVENT',
                                        journeyType: 'Dialog',
                                        botId: props.botId
                                    })
                                    props.scrollToBottom && props.scrollToBottom()
                                }}
                            >
                                Ok
                            </Button>
                        </div>
                    </div>
                )
            }
            return null
        } else if (
            journeyData.type === 'success' &&
            journeyData.value.state().actions.filter(x => x.name === 'fallback-dialog').length > 0
        ) {
            toolTip.current = '_active'
            // console.log('fallback ::: journey Data', journeyData.value.state())
            const welcomeText = journeyData.value.state().actions.filter(x => x.name === 'fallback-dialog')[0].name

            if (!props.isStart) {
                return (
                    <div className="tooltip_wrapper tour_fallback_adjust_position">
                        <div className="tooltip_wrapper_bg">
                            <p>
                                You can update your bot's fallback message (when the bot does not recognize user input)
                                from this fallback dialog
                            </p>
                            <Button
                                onClick={(event: any) => {
                                    event.stopPropagation()
                                    journeyData.value.send({
                                        type: 'EVENT::DIALOG::FALLBACK_CLICKED',
                                        tag: 'DIALOG_GENERIC_EVENT',
                                        journeyType: 'Dialog',
                                        botId: props.botId
                                    })
                                }}
                            >
                                Ok
                            </Button>
                        </div>
                    </div>
                )
            }
        }
        return null
    }
    return (
        <div
            className={
                !props.disabled
                    ? `col-lg-2 col-md-2 col-sm-6 col-12  dialog_cards_layer start_layer ${
                          journeyData.type === 'success' &&
                          journeyData.value
                              .state()
                              .actions.filter(
                                  x =>
                                      (x.name === 'fallback-dialog' && !props.isStart) ||
                                      (x.name === 'welcome-dialog' && props.isStart)
                              ).length > 0
                              ? '_active'
                              : '_inactive'
                      }`
                    : `col-lg-2 col-md-2 col-sm-6 col-12  dialog_cards_layer start_layer hide_create_card ${
                          journeyData.type === 'success' &&
                          journeyData.value
                              .state()
                              .actions.filter(
                                  x =>
                                      (x.name === 'fallback-dialog' && !props.isStart) ||
                                      (x.name === 'welcome-dialog' && props.isStart)
                              ).length > 0
                              ? '_active'
                              : '_inactive'
                      }`
            }
            onClick={(props.disabled) ? () => {} : () => props.onClick(props.dialog.id)}
        >
            <div className="dialog_icon">
                <span>
                    <DialogCardIcon />
                </span>
            </div>
            <div className="search_word_highligter">
                <div className="d_card_wrapper ">
                    {/* <div className="d_card_left">
                        <span>
                            <DialogCardIcon />
                        </span>
                    </div> */}
                    <div className="d_card_right">
                        <div className="search-card-title d-inline searching_dialog_title">
                            <h3 style={{ paddingTop: 0 }}>{props.isStart ? 'Welcome' : 'Fallback'} </h3>
                        </div>
                        <p>{props.dialog.description}</p>
                    </div>
                </div>
                <div className="error_icon_wrapper">
                    <div className="error_icon_wrapper_left">
                        {props.dialog ? (
                            <span className="entity_span">
                                {props.dialog.updated ? formatDateInCard(props.dialog.updated) : ''}
                            </span>
                        ) : null}
                        <span className="entity_span">|</span>
                        {props.dialog ? (
                            <span className="entity_span">
                                {props.dialog.updated ? formatAMPM(props.dialog.updated) : ''}
                            </span>
                        ) : null}
                    </div>
                    {props.showErrorOnDefaultNode && (
                        <div className="error_input_show parent welcome_fallback_error">
                            <Tooltip
                                className="target customTip"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="label"
                                content="Input data is missing."
                                distance={3}
                            >
                                <WarningAlertIcon />
                            </Tooltip>
                        </div>
                    )}
                    <div className="error_icon_wrapper_right">
                        <label>
                            <span>{props.isStart ? 'Start' : 'End'}</span>
                        </label>
                    </div>
                </div>
            </div>
            {showPopup()}
        </div>
    )
}
export const StartCard: React.SFC<any> = props => {
    return <DefaultNode isStart={true} botId={props.id} {...props} />
}

export const EndCard: React.SFC<any> = props => {
    return <DefaultNode isStart={false} botId={props.id} {...props} />
}

export const DialogCard: React.SFC<any> = (props: any) => {
    const {uuid,workspacename,assistantID} = props
    const selectedLink$ = useSignal<string>('')
    useEffect(() => {
        selectedLink$.value = `CHAT_BOT_MODULE/BOT/${assistantID}/ROOT_DIALOG_NODE/${uuid}/CONDITION`
    }, [uuid])
    
    const writePermission = usePermissions(workspacename,`CHAT_BOT_MODULE/BOT/${assistantID}/ROOT_DIALOG_NODE/${uuid}/CONDITION`,"WRITE")
    const deletePermission = usePermissionSignal(workspacename,selectedLink$,"DELETE")
    return <Card {...props} 
    permissions={{write:writePermission.value,delete:deletePermission.value}}
    />
}

export default Dialogue
/* {tryme ? (
                        <StatusContext.Provider value={status}>
                            <TryIt
                                workspacename={workspacename}
                                close={() => {
                                    journeyData.type === 'success' &&
                                        journeyData.value.send({
                                            type: 'EVENT::DIALOG::TRYME_CLOSED',
                                            tag: 'DIALOG_GENERIC_EVENT',
                                            journeyType: 'Dialog',
                                            botId: assistant.id
                                        })
                                    setTryme(false)
                                }}
                                intents={intents}
                                assistantid={assistant.id}
                                deployed={isdeployed.success}
                                deploymentError={isdeployed.error}
                                deploybot={deploybot}
                            />
                        </StatusContext.Provider>
                    ) : null}

                    {tryme === false && PageState.action !== ActionT.NO_DATA_FOUND ? (
                        // (status == 'training' || status == 'trained') &&
                        // assistant.deployed !== null &&
                        // new Date(assistant.updated) <= new Date(assistant.deployed) ? (
                        <section className="tryme_tooltip">
                            <div
                                className={`tryme_popup ${
                                    journeyData.type === 'success' &&
                                    journeyData.value.state().actions.filter(x => x.name === 'tryme-popup').length > 0
                                        ? `_active`
                                        : `_inactive`
                                }`}
                            >
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        setTryme(true)
                                        journeyData.type === 'success' &&
                                            journeyData.value.send({
                                                type: 'EVENT::DIALOG::TRYME_CLICKED',
                                                tag: 'DIALOG_GENERIC_EVENT',
                                                journeyType: 'Dialog',
                                                botId: assistant.id
                                            })
                                    }}
                                >
                                    <span>Try Me</span>
                                </button>
                            </div>
                            {journeyData.type === 'success' &&
                                journeyData.value.state().actions.filter(x => x.name === 'tryme-popup').length > 0 && (
                                    <div className="tooltip_wrapper">
                                        <div className="tooltip_wrapper_bg">
                                            <p>Test your bot with Try Me</p>
                                        </div>
                                    </div>
                                )}
                        </section>
                    ) : // ) : (
                    null} */
