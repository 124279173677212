import Downshift from 'downshift'
import * as ramda from 'ramda'
/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react'
import Tooltip from 'react-tooltip-lite'
import styled from 'styled-components/macro'

import { VideoLink } from '@/Videos/Videos'
import {
    AddIcon,
    CrossIcon,
    DownArrowCircle,
    DropdownWithCircle,
    InfoIcon,
    MinusIcon,
    QaIconWithCircle,
    SearchIcon
} from '@/common/Icons/Icons'
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { Loader } from '@/common/components/Loader'
import { Modal } from '@/common/components/Modal'
//COMMON MODULE IMPORTS
import lazyGA from '@/common/google-analytics/tracking'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import {
    ButtonContainer,
    CancelButton,
    DropdownLi,
    DropdownUl,
    DropdownUlWrapper,
    EmptyDiv,
    Header,
    HeaderLeft,
    Input,
    InputContainer,
    InputContent,
    Paragraph,
    PopupContainer,
    PopupScrollHeight,
    PopupWrapper,
    SmallButton,
    Textarea,
    Wrapper as WrapperExit
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { HeaderRight } from '@/common/styled/Subscription.Xtra.Dumb'

// TYPE IMPORTS
import {
    DialogueT,
    IfNodeT,
    SaveSlotAsT,
    SlotConditionT,
    SlotContainerT,
    SlotExitConditions,
    SlotPromptT,
    slotsT
} from '../../Dialogue/kind'
//ACTION IMPORTS
import { createResponseDialogue, getDialogues, updateResponseDialogue } from '../../Mechanisms/action'
//CONTEXT IMPORTS
import { DialogueContext } from '../Context'
import { validateNode } from '../DialogueMap/Descendant'
import { ActionT, ErrorState, ErrorT, SLOT_RESPONSE, VIEW } from '../kind'
import { HeaderWithSwitch } from './Exit'
import ConditionalStatements from './RecogniseDialog/ConditionalStatements'
import { generateCond, reverseCond } from './RecogniseDialog/action'
import { conditionT, conditionTYPES, operatorsT } from './RecogniseDialog/kind'
import { logEvent } from '@/common/utils/api-utils'
import { useScrollHook } from '@/common/hooks/useScrollHook'
import { sortAnArrayByName } from '@/common/utils/common.utils'

// import { AddIcon, DeleteIcon, EditIcon, CheckIcon, CrossIcon, DownArrowCircle } from '@/common/Icons'

const IconCircle = styled.div`
    width: auto;
    float: left;

    span {
        width: 18px;
        height: 18px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 0.5px solid #999999;
        border-radius: 50%;
        float: left;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 6px;

        svg {
            width: 8px;
            height: 8px;
            fill: #999;
        }
    }
`
const IconsWrapper = styled.div`
    width: auto;
    float: left;
`
const WrapperSlot = styled.div`
    width: 100%;
    float: left;
    .d-flex {
        display: flex;
        top: 16px;
        width: 16px;
        height: 16px;
        left: inherit;
        svg {
            width: 16px;
            height: 16px;
        }
    }
    .verify-user.d-flex{
        top: 1px;
    }
`

const SlotTips = () => (
    <>
        <p>
            <a
                className="sidepanel_href_color"
                target="_blank"
                href="https://help.workativ.com/index.php/knowledgebase/get-user-details/"
            >
                Verify user details
            </a>{' '}
            makes the bot extract and save only the required data from a user's input.{' '}
        </p>
        <p>
            The bot will verify the user input with either an existing entity or intent, and then save the input as
            either entity value or user text.
        </p>
        <p>
            For example, <br />
            <b>Bot:</b> Please provide your mobile number. <br />
            <b>User:</b> My mobile number is 1234567890.
        </p>
        <p>
            The bot will store only <b>1234567890</b> after validating it. <br />
            But if there's no match or incorrect input, the bot will continue to prompt the user for valid input.
        </p>
    </>
)
const Slot = ({
    slot,
    slots,
    alreadyOpen,
    setAlreadyOpen,
    removeSlot,
    saveSlot,
    showSaveButton,
    defaultSlots,
    index,
    parentScrollCallback
}: {
    slot: slotsT
    slots: slotsT[]
    alreadyOpen: boolean
    setAlreadyOpen: Function
    removeSlot: Function
    saveSlot: (slot: slotsT) => any
    showSaveButton: Function
    defaultSlots: slotsT[]
    index: number,
    parentScrollCallback? : (shouldScroll:boolean) => void
}) => {
    const { intents, entities, PageState, workspaceName, assistantID, rootDialogs } = useContext(DialogueContext)

    const context = useContext(DialogueContext)

    const [mode, setMode] = useState<'view' | 'edit'>('edit')

    const [condition, setCondition] = useState<SlotConditionT>(slot.condition)

    const [saveAs, setSaveAs] = useState<SaveSlotAsT>(slot.save_as)

    const [prompt, setPrompt] = useState<SlotPromptT>(slot.prompt)

    const [variable, setVariable] = useState<string>(slot.variable)

    const [keycode, setKeycode] = useState<number>(13)

    const [metacode, setMetacode] = useState<boolean>(false)

    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)

    const [conditionChooser, setConditionChooser] = useState<boolean>(false)
    const [isSaveAsOpen, setIsSaveAsOpen] = useState<boolean>(false)

    const [deleteModal, setDeleteModal] = useState({ show: false, index: 0 })

    const [dialogChooser, setDialogChooser] = useState<
        {
            show: boolean
            title: string
            dialogId: string
            showDropDown: boolean
            jumpOrExit: string
            respondWith: string
        }[]
    >([])

    const [conditions, setConditions] = useState<conditionT[]>([])

    // const [rootDialogues, setRootDialogues] = useState<DialogueT[]>([])
    const [rootDialogues, setRootDialogues] = useState<DialogueT[]>(rootDialogs ? rootDialogs : [])

    const [optionalPrompt, setOptionalPrompt] = useState(slot.prompt == null)
    const [jumpOrExitDropdown, setJumpOrExitDropdown] = useState<null|number>(null)

    const onChange = (index: number, condition: conditionT) =>
        setConditions(element => element.map((e: conditionT, i: number) => (i == index ? condition : e)))

    const changeSaveAs = (saveAs: SaveSlotAsT) => {
        setSaveAs(saveAs)
    }

    useEffect(() => {
        const { data } = PageState as SLOT_RESPONSE
        console.log(data)
        if (data.id === '') {
            setConditions([])
        } else {
            setConditions(ramda.flatten(slot.slot_exit_conditions.map(cond => reverseCond(cond.condition))) as any)
        }

        getDialogueNodes(data)
    }, [])

    function getDialogueNodes(data: SlotContainerT) {
        getDialogues(workspaceName, assistantID)
            .then((res: DialogueT[]) => {
                // const WelcomeNode = res
                //     .filter((e: DialogueT) => (e.conditions ? e.conditions.type === 'WelcomeExpression' : false))
                //     .pop()

                // const FallbackNode = res
                //     .filter((e: DialogueT) => (e.conditions ? e.conditions.type === 'FallBackExpression' : false))
                //     .pop()

                // const rootDialogs = sortBySibling(res.filter(e => e.parent === null))

                if (data.id !== '') {
                    setDialogChooser(
                        slot.slot_exit_conditions.map(d => {
                            const next_step = d.next_step as any
                            if (next_step.jump_to) {
                                const matchDialog = rootDialogs.filter(dia => dia.uuid == next_step.jump_to.to || dia.id == next_step.jump_to.to)[0]
                                return {
                                    show: true,
                                    title: matchDialog.title,
                                    dialogId: matchDialog.id,
                                    showDropDown: false,
                                    jumpOrExit: 'jump',
                                    respondWith: d.response.type == 'text' ? d.response.value : ''
                                }
                            }

                            return {
                                show: true,
                                title: '',
                                dialogId: '',
                                showDropDown: false,
                                jumpOrExit: 'exit',
                                respondWith: d.response.type == 'text' ? d.response.value : ''
                            }
                        })
                    )
                }
            })
            .catch(err => {
                // setError({
                //     error: true,
                //     info: `Server error!<br />Please try again after sometime.`
                // })
                // setLoader(false)
            })
    }

    useEffect(() => {
        showSaveButton(showAddMoreIf())
    }, [showAddMoreIf()])

    useEffect(() => {
        saveSlot({
            ...slot,
            condition,
            variable,
            prompt: optionalPrompt ? null : prompt,
            save_as: saveAs,
            slot_exit_conditions: conditions
                .map((cond, i) => {
                    if (
                        cond.type != conditionTYPES.SELECT &&
                        cond.type != conditionTYPES.COMBINATOR &&
                        dialogChooser.length > 0
                    ) {
                        return {
                            condition: generateCond([cond]),
                            response: {
                                type: 'text',
                                value: dialogChooser[i].respondWith
                            },
                            next_step:
                                dialogChooser[i].jumpOrExit == 'jump'
                                    ? {
                                          jump_to: {
                                              to: dialogChooser[i].dialogId,
                                              selector: 'body'
                                          }
                                      }
                                    : {
                                          exit: null
                                      }
                        } as SlotExitConditions
                    }

                    return null
                })
                .filter(Boolean) as SlotExitConditions[]
        })
    }, [condition, variable, optionalPrompt, saveAs, conditions, prompt, dialogChooser])

    function conditionValidation() {
        if (condition.type == 'IntentExpression') {
            return condition.value.length > 0
        } else {
            return condition.entity.length > 0
        }
    }

    function showAddMoreIf() {
        return (
            variable.length != 0 &&
            (optionalPrompt || (prompt && prompt.length > 0)) &&
            conditionValidation() &&
            (dialogChooser.length == 0 ||
                (dialogChooser.length > 0 &&
                    dialogChooser.filter(
                        d =>
                            (d.jumpOrExit != 'jump' || (d.jumpOrExit == 'jump' && d.dialogId.length > 0)) &&
                            d.respondWith.length > 0
                    ).length > 0 &&
                    conditions.filter(data => {
                        if (data.type == conditionTYPES.INTENT) {
                            return data.name == ''
                        } else if (data.type == conditionTYPES.ENTITY) {
                            if (data.operator == operatorsT.SELECT) {
                                return true
                            }
                            if (data.name != '') {
                                if (data.operator == operatorsT.IS || data.operator == operatorsT.IS_NOT) {
                                    return data.value == ''
                                }
                            } else {
                                return true
                            }
                        } else if (data.type == conditionTYPES.SELECT) {
                            return true
                        } else if (data.type == conditionTYPES.CONTEXT) {
                            if (data.operator == operatorsT.SELECT) {
                                return true
                            }
                            if (data.LHS.type === 'context' && data.LHS.variable != '') {
                                if (data.LHS.variable != '') {
                                    if (
                                        data.operator == operatorsT.IS_TRUE ||
                                        data.operator == operatorsT.IS_FALSE ||
                                        data.operator == operatorsT.IS_PRESENT ||
                                        data.operator == operatorsT.IS_NOT_PRESENT
                                    ) {
                                        return false
                                    } else if (typeof data.RHS !== 'boolean') {
                                        return data.RHS.blocks[0].text === ''
                                    }
                                } else {
                                    return true
                                }
                            } else {
                                return true
                            }
                        }
                        return false
                    }).length == 0))
        )
    }

    const resetSlot = () => {
        setCondition(slot.condition)
        setPrompt(slot.prompt)
        setVariable(slot.variable)
        // if (slot.variable === '') {
        //     setMode('edit')
        //     setAlreadyOpen(true)
        // } else {
        //     setMode('view')
        // }
    }

    useEffect(() => {
        resetSlot()
    }, [slot])

    function showDeleteModal() {
        return deleteModal.show ? (
            <Modal showPopupLoader={false}>
                <Paragraph style={{ textAlign: 'center', marginTop: 0 }}>
                    Are you sure to delete selected Value?
                </Paragraph>

                <ButtonContainer style={{ paddingBottom: '0' }}>
                    <Button primary type="button" onClick={() => setDeleteModal({ show: false, index: 0 })}>
                        <label>No</label>
                    </Button>
                    <Button
                        type="button"
                        onClick={() => {
                            setDialogChooser(dialogChooser.filter((d, i) => i != deleteModal.index))
                            setConditions(conditions.filter((c, i) => i != deleteModal.index))
                            setDeleteModal({ show: false, index: 0 })
                        }}
                    >
                        <label>Yes</label>
                    </Button>
                </ButtonContainer>
            </Modal>
        ) : null
    }

    const conditionType = slot.condition.type as any

    return (
        <WrapperSlot>
            <div className={mode === 'view' ? 'slots_left_bot' : 'slots_right_bot plus_addmore_edit'}>
                <div className="row">
                    <div className="get_users_wrapper">
                        <div className="get_users_number">
                            {/* <div className="parent_node_no">
                            <span className="parent_svg dialog_value">01</span>
                        </div> */}
                            <Header>Verify and save</Header>
                        </div>
                        {/* <IconsWrapper>
                            {mode === 'view' ? (
                                <IconCircle>
                                    <span
                                        className={
                                            slots.length === 1 || alreadyOpen === true ? 'editor_btn_disabled' : ''
                                        }
                                        onClick={() =>
                                            slots.length === 1 || alreadyOpen === true ? null : removeSlot()
                                        }
                                    >
                                        <DeleteIcon />
                                    </span>
                                    <span
                                        className={alreadyOpen === true ? 'editor_btn_disabled' : ''}
                                        onClick={() => {
                                            if (alreadyOpen === false) {
                                                setMode('edit')
                                                setAlreadyOpen(true)
                                            }
                                        }}
                                    >
                                        <EditIcon />
                                    </span>
                                </IconCircle>
                            ) : (
                                <IconCircle>
                                    <span
                                        className={
                                            variable === '' || (!optionalPrompt && !prompt) || !showAddMoreIf()
                                                ? 'editor_btn_disabled'
                                                : ''
                                        }
                                        onClick={() => {
                                            if (
                                                condition.type === 'EntityExpression'
                                                    ? condition.entity === ''
                                                    : condition.value === '' ||
                                                      variable === '' ||
                                                      (!optionalPrompt && !prompt)
                                            ) {
                                            } else {
                                                saveSlot({
                                                    condition,
                                                    variable,
                                                    prompt: optionalPrompt ? null : prompt,
                                                    save_as: saveAs,
                                                    slot_exit_conditions: conditions
                                                        .map((cond, i) => {
                                                            if (
                                                                cond.type != conditionTYPES.SELECT &&
                                                                cond.type != conditionTYPES.COMBINATOR
                                                            ) {
                                                                return {
                                                                    condition: generateCond([cond]),
                                                                    response: {
                                                                        type: 'text',
                                                                        value: dialogChooser[i].respondWith
                                                                    },
                                                                    next_step:
                                                                        dialogChooser[i].jumpOrExit == 'jump'
                                                                            ? {
                                                                                  jump_to: {
                                                                                      to: dialogChooser[i].dialogId,
                                                                                      selector: 'body'
                                                                                  }
                                                                              }
                                                                            : {
                                                                                  exit: {
                                                                                      message: {
                                                                                          type: 'text',
                                                                                          value: ''
                                                                                      }
                                                                                  }
                                                                              }
                                                                } as SlotExitConditions
                                                            }

                                                            return null
                                                        })
                                                        .filter(Boolean) as SlotExitConditions[]
                                                })
                                                setAlreadyOpen(false)
                                            }
                                        }}
                                    >
                                        <EditIcon />
                                    </span>
                                    <span
                                        className={slots.length === 1 ? 'editor_btn_disabled' : ''}
                                        onClick={() => {
                                            if (slots.length > 1) {
                                                if (slot.variable === '') {
                                                    removeSlot()
                                                } else {
                                                    resetSlot()
                                                }
                                                setAlreadyOpen(false)
                                            }
                                        }}
                                    >
                                        <CloseIcon />
                                    </span>
                                </IconCircle>
                            )}
                        </IconsWrapper> */}
                    </div>
                </div>
                {/* slot dropdown */}

                <div className="automation_condition_form get_user_details_form">
                    <div className={`select_work_form select_work_form_top_0`}>
                        <InputContainer>
                            <Input
                                className={`${mode == 'view' ? 'disable_edit' : ''}`}
                                type="text"
                                value={condition.type === 'IntentExpression' ? 'Intent' : 'Entity'}
                                onClick={() => setConditionChooser(true)}
                            />
                            <InputContent>Verify with</InputContent>
                            <CancelButton className="d-flex" onClick={() => setConditionChooser(true)}>
                                <DropdownWithCircle />
                            </CancelButton>
                            {conditionChooser && (
                                <DropdownUlWrapper>
                                    <Downshift
                                        isOpen={conditionChooser === true}
                                        onOuterClick={() => setConditionChooser(false)}
                                    >
                                        {() => (
                                            <div className="automation_scroly">
                                                <DropdownUl>
                                                    <DropdownLi
                                                        onClick={() => {
                                                            setConditionChooser(false)
                                                            setCondition({
                                                                type: 'IntentExpression',
                                                                value: ''
                                                            })
                                                        }}
                                                    >
                                                        Intent
                                                    </DropdownLi>
                                                    <DropdownLi
                                                        onClick={() => {
                                                            setConditionChooser(false)
                                                            setCondition({
                                                                type: 'EntityExpression',
                                                                entity: ''
                                                            })
                                                        }}
                                                    >
                                                        Entity
                                                    </DropdownLi>
                                                </DropdownUl>{' '}
                                            </div>
                                        )}
                                    </Downshift>
                                </DropdownUlWrapper>
                            )}
                        </InputContainer>
                    </div>

                    {condition.type === 'IntentExpression' ? (
                        <ValueChooser
                            data={intents.map(e => ({ id: e.id, text: e.intent }))}
                            handleChange={(val: string) => setCondition({ type: 'IntentExpression', value: val })}
                            rawVal={condition.value}
                            type={'IntentExpression'}
                            mode={mode}
                        />
                    ) : (
                        <ValueChooser
                            data={entities.map(e => ({ id: e.id, text: e.entity }))}
                            handleChange={(val: string) => setCondition({ type: 'EntityExpression', entity: val })}
                            rawVal={condition.entity}
                            type={'EntityExpression'}
                            mode={mode}
                        />
                    )
                    // <Chooser
                    //     data={this.props.intents.map(e => e.intent)}
                    //     value={this.state.condition}
                    //     onChange={this.conditionOnChange}
                    //     type={'intent'}
                    // />
                    }
                    {context.PageState.action == ActionT.SLOT_RESPONSE &&
                        context.PageState.data.id.length > 0 &&
                        validateNode(context, slot.condition) &&
                        (slot.condition.type == 'IntentExpression'
                            ? slot.condition.value.length > 0
                            : slot.condition.type == 'EntityExpression'
                            ? slot.condition.entity.length > 0
                            : false) && (
                            <div className="error_input_show slot_error">
                                <WarningAlertIcon />
                                <span className="error_menu_hvr">
                                    {conditionType == 'IntentExpression' ? 'Intent not found' : 'Entity not found'}
                                </span>
                            </div>
                        )}
                    {/* radio button */}
                    {condition.type == 'EntityExpression' && (
                        <InputContainer>
                            <Input
                                className={`${mode == 'view' ? 'disable_edit' : ''}`}
                                type="text"
                                value={saveAs === 'literal' ? 'Save user text' : 'Save entity value'}
                                onClick={() => setIsSaveAsOpen(true)}
                            />
                            <InputContent>Select type</InputContent>
                            <CancelButton className="d-flex" onClick={() => setConditionChooser(true)}>
                                <DropdownWithCircle />
                            </CancelButton>
                            {isSaveAsOpen && (
                                <>
                                    <DropdownUlWrapper>
                                        <Downshift isOpen onOuterClick={() => setIsSaveAsOpen(false)}>
                                            {() => (
                                                <div className="automation_scroly">
                                                    <DropdownUl>
                                                        <DropdownLi
                                                            onClick={() => {
                                                                changeSaveAs('literal')
                                                                setIsSaveAsOpen(false)
                                                            }}
                                                        >
                                                            Save user text
                                                        </DropdownLi>
                                                        <DropdownLi
                                                            onClick={() => {
                                                                changeSaveAs('value')
                                                                setIsSaveAsOpen(false)
                                                            }}
                                                        >
                                                            Save entity value
                                                        </DropdownLi>
                                                    </DropdownUl>{' '}
                                                </div>
                                            )}
                                        </Downshift>
                                    </DropdownUlWrapper>
                                </>
                            )}
                        </InputContainer>
                    )}

                    {/* radio button */}

                    {/* {condition.type === 'IntentExpression' && <h6 className="get_user_details_h6">Save as:</h6>} */}
                    <InputContainer className="mb-0">
                        <Input
                            type="text"
                            className={`form-control ${mode == 'view' ? 'disable_edit' : ''}`}
                            value={variable}
                            onKeyDown={(e: any) => {
                                setMetacode(e.metaKey || e.ctrlKey ? true : false)
                                setKeycode(e.keyCode)
                            }}
                            onChange={(e: any) => {
                                if (keycode !== 32 && metacode === false) {
                                    setVariable(e.target.value.replace(/[^\w\s]/gi, ''))
                                }
                            }}
                        />
                        <InputContent>Save as</InputContent>
                    </InputContainer>

                    <div className="textarea_user_details">
                        <HeaderWithSwitch style={{ borderTop: '1px solid #B1B1B1', paddingTop: '10px' }}>
                            <Header>
                                <div style={{ visibility: 'hidden' }}>
                                    {' '}
                                    Optional{' '}
                                    <Tooltip
                                        className="target customTip"
                                        zIndex={10000}
                                        arrowSize={8}
                                        tagName="span"
                                        content={
                                            'Make the verify user details as optional. When you enable it, bot will not prompt the user to provide the details.'
                                        }
                                        distance={5}
                                    >
                                        <InfoIcon />
                                    </Tooltip>{' '}
                                </div>
                                <div className="popup_show_hide">
                                    <div style={{ marginTop: '-5px' }}>
                                        Don't ask user
                                        <Tooltip
                                            className="target customTip"
                                            zIndex={10000}
                                            arrowSize={8}
                                            tagName="span"
                                            content={
                                                "Enable this button if you don't want the bot to ask user for data or input."
                                            }
                                            distance={5}
                                        >
                                            <InfoIcon />
                                        </Tooltip>
                                    </div>
                                    <div className="show_hide">
                                        <div className="switch_box box_1">
                                            <input
                                                type="checkbox"
                                                className={`switch_1 ${mode == 'view' ? 'editor_btn_disabled' : ''}`}
                                                checked={optionalPrompt}
                                                onChange={event => setOptionalPrompt(event.currentTarget.checked)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Header>
                        </HeaderWithSwitch>

                        {!optionalPrompt && (
                            <InputContainer style={{ marginBottom: 12 }}>
                                <Textarea
                                    className={`form-control ${mode == 'view' ? 'disable_edit' : ''}`}
                                    value={prompt || ''}
                                    onChange={(e: any) => setPrompt(e.target.value)}
                                />
                                <InputContent>If user data not available, ask user</InputContent>
                            </InputContainer>
                        )}
                    </div>
                    {/* Advanced Options Start */}
                    <div className="advanced_options_wrapper get_user__details_wrapper">
                        {!optionalPrompt && (
                            <div className="add_connection_automation" style={{ display: 'inline-flex' }}>
                                <SmallButton
                                    primary
                                    onClick={() => {
                                        parentScrollCallback && parentScrollCallback(true)
                                        if (dialogChooser.length === 0 && mode != 'view') {
                                            setDialogChooser([
                                                ...dialogChooser,
                                                {
                                                    show: true,
                                                    title: '',
                                                    dialogId: '',
                                                    showDropDown: false,
                                                    jumpOrExit: 'jump',
                                                    respondWith: ''
                                                }
                                            ])

                                            setConditions([
                                                ...conditions,
                                                {
                                                    type: conditionTYPES.SELECT
                                                }
                                            ])
                                        }
                                    }}
                                >
                                    <span>{dialogChooser.length !== 0 ? <MinusIcon /> : <AddIcon />}</span>
                                    <label>Set advanced conditions ({dialogChooser.length})</label>
                                </SmallButton>
                                <span style={{ marginLeft: '5px', marginTop: 4 }}>
                                    <Tooltip
                                        className="target customTip"
                                        zIndex={10000}
                                        arrowSize={8}
                                        tagName="span"
                                        content={
                                            'Setting advanced condition helps the bot handle specific text, value or input in user response, to the bot question.'
                                        }
                                        distance={5}
                                    >
                                        <span className="info_icon">
                                            <InfoIcon />
                                        </span>
                                    </Tooltip>
                                </span>
                            </div>
                        )}
                        {/* {showAdvancedOptions && ( */}
                        <>
                            <div className="assistant_recognizes">
                                {/* close recognise dialog */}

                                {!optionalPrompt &&
                                    dialogChooser.map((dialog, index) => {
                                        const updateState = (value: {
                                            show: boolean
                                            title: string
                                            dialogId: string
                                            showDropDown: boolean
                                            jumpOrExit: string
                                            respondWith: string
                                        }) => {
                                            setDialogChooser(
                                                dialogChooser.map((d, j) => {
                                                    if (j == index) {
                                                        return value
                                                    }
                                                    return d
                                                })
                                            )
                                        }
                                        if (!dialog.show) {
                                            return (
                                                <div className="assistant_recognizes_header assistant_recognizes_hide">
                                                    <Header className="justify-content-start">
                                                        Set condition{' '}
                                                        <span>({index + 1 < 10 ? '0' + (index + 1) : index + 1})</span>
                                                    </Header>
                                                    {
                                                        <div className="icons_right_close">
                                                            <span
                                                                onClick={() =>
                                                                    setDeleteModal({ show: true, index: index })
                                                                }
                                                                className={mode == 'view' ? 'disable_edit' : ''}
                                                            >
                                                                <CrossIcon />
                                                            </span>
                                                            <span
                                                                onClick={() => updateState({ ...dialog, show: true })}
                                                            >
                                                                <DownArrowCircle />
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        }

                                        return (
                                            <>
                                                {/* close recognise dialog */}
                                                <div className="assistant_recognizes_header">
                                                    <Header className="justify-content-start">
                                                        Set condition{' '}
                                                        <span>({index + 1 < 10 ? '0' + (index + 1) : index + 1})</span>
                                                    </Header>
                                                    {
                                                        <div className="icons_right_close">
                                                            <span
                                                                onClick={() =>
                                                                    setDeleteModal({ show: true, index: index })
                                                                }
                                                                className={mode == 'view' ? 'disable_edit' : ''}
                                                            >
                                                                {/* <MinusIcon /> */}
                                                                <CrossIcon />
                                                            </span>
                                                            <span
                                                                onClick={() => updateState({ ...dialog, show: false })}
                                                            >
                                                                <DownArrowCircle />
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                                <div className={mode == 'view' ? 'disable_edit' : ''}>
                                                    {conditions[index].type != conditionTYPES.COMBINATOR ? (
                                                        <ConditionalStatements
                                                            condition={conditions[index]}
                                                            conditions={conditions.filter((c, i) => i == index)}
                                                            key={index}
                                                            onChange={(condition: any) => onChange(index, condition)}
                                                            remove={() => {}}
                                                            hideTititle={true}
                                                            callbackWhenChooserisOpen={(() =>   parentScrollCallback && parentScrollCallback(true))}
                                                        />
                                                    ) : null}
                                                </div>
                                                <div className="textarea_user_details">
                                                    <InputContainer>
                                                        <Textarea
                                                            className={`${mode == 'view' ? 'disable_edit' : ''}`}
                                                            value={dialog.respondWith}
                                                            onChange={(e: any) =>
                                                                updateState({ ...dialog, respondWith: e.target.value })
                                                            }
                                                        />
                                                        <InputContent>Respond with</InputContent>
                                                    </InputContainer>
                                                </div>
                                                {/* radio button */}
                                                <div className="radio_slot_btn">
                                                    <div className="dropdown_select_dialog">
                                                        <div className="select_work_form">
                                                            <InputContainer>
                                                                <Input
                                                                    type="text"
                                                                    value={
                                                                        dialog.jumpOrExit == 'jump'
                                                                            ? 'Jump to specific dialog or faq'
                                                                            : 'Exit the conversation'
                                                                    }
                                                                    onClick={() => {setJumpOrExitDropdown(index);  parentScrollCallback && parentScrollCallback(true)}}
                                                                />
                                                                <InputContent>Then</InputContent>
                                                                {jumpOrExitDropdown == index && (
                                                                    <DropdownUlWrapper>
                                                                        <Downshift
                                                                            isOpen={true}
                                                                            onOuterClick={() =>
                                                                                setJumpOrExitDropdown(null)
                                                                            }
                                                                        >
                                                                            {() => (
                                                                                <div className="automation_scroly">
                                                                                    <DropdownUl>
                                                                                        <DropdownLi
                                                                                            onClick={() => {
                                                                                                updateState({
                                                                                                    ...dialog,
                                                                                                    jumpOrExit: 'jump'
                                                                                                })
                                                                                                setJumpOrExitDropdown(
                                                                                                    null
                                                                                                )
                                                                                            }}
                                                                                        >
                                                                                            Jump to specific dialog or
                                                                                            faq
                                                                                        </DropdownLi>
                                                                                        <DropdownLi
                                                                                            onClick={() => {
                                                                                                updateState({
                                                                                                    ...dialog,
                                                                                                    jumpOrExit: 'exit',
                                                                                                    title:
                                                                                                        'Select dialog',
                                                                                                    dialogId: ''
                                                                                                })
                                                                                                setJumpOrExitDropdown(
                                                                                                    null
                                                                                                )
                                                                                            }}
                                                                                        >
                                                                                            Exit the conversation
                                                                                        </DropdownLi>
                                                                                    </DropdownUl>{' '}
                                                                                </div>
                                                                            )}
                                                                        </Downshift>
                                                                    </DropdownUlWrapper>
                                                                )}
                                                                <CancelButton className="d-flex">
                                                                    <DropdownWithCircle />
                                                                </CancelButton>
                                                            </InputContainer>
                                                        </div>
                                                    </div>

                                                    {/* <div className="right_panel_radio">
                                                    <div className="col-md-12 entity_radio">
                                                        <h6>Then:</h6>
                                                        <div className="radio">
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    className="radio-warning"
                                                                    onClick={() =>
                                                                        updateState({ ...dialog, jumpOrExit: 'jump' })
                                                                    }
                                                                    checked={dialog.jumpOrExit == 'jump'}
                                                                />
                                                            </label>
                                                            <span>Jump</span>
                                                        </div>
                                                        <div className="radio">
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    onClick={() =>
                                                                        updateState({
                                                                            ...dialog,
                                                                            jumpOrExit: 'exit',
                                                                            title: 'Select Dialog',
                                                                            dialogId: ''
                                                                        })
                                                                    }
                                                                    disabled={mode == 'view'}
                                                                    checked={dialog.jumpOrExit == 'exit'}
                                                                />
                                                            </label>
                                                            <span>Exit</span>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                </div>
                                                {dialog.jumpOrExit == 'jump' && (
                                                    <>
                                                        <div className="dropdown_select_dialog">
                                                            <div className="select_work_form">
                                                                <InputContainer className="mb-0">
                                                                    <Input
                                                                        type="text"
                                                                        value={dialog.title}
                                                                        onClick={() =>{
                                                                            parentScrollCallback && parentScrollCallback(true)
                                                                            updateState({
                                                                                ...dialog,
                                                                                showDropDown: true
                                                                            })
                                                                        }
                                                                        }
                                                                        className={mode == 'view' ? 'disable_edit' : ''}
                                                                    />
                                                                    <InputContent>Select dialog or faq</InputContent>
                                                                    {dialog.showDropDown && (
                                                                        <DropdownUlWrapper>
                                                                            <Downshift
                                                                                isOpen={true}
                                                                                onOuterClick={() =>
                                                                                    updateState({
                                                                                        ...dialog,
                                                                                        showDropDown: false
                                                                                    })
                                                                                }
                                                                            >
                                                                                {() => (
                                                                                    <div className="automation_scroly">
                                                                                        <DropdownUl>
                                                                                            {sortAnArrayByName(rootDialogues,"title").map(
                                                                                                (dg, i) => (
                                                                                                    <DropdownLi
                                                                                                        key={i}
                                                                                                        onClick={() => {
                                                                                                            updateState(
                                                                                                                {
                                                                                                                    ...dialog,
                                                                                                                    showDropDown: false,
                                                                                                                    title:
                                                                                                                        dg.title,
                                                                                                                    dialogId:
                                                                                                                        dg.id
                                                                                                                }
                                                                                                            )
                                                                                                        }}
                                                                                                    >
                                                                                                        {dg.title}
                                                                                                    </DropdownLi>
                                                                                                )
                                                                                            )}
                                                                                        </DropdownUl>{' '}
                                                                                    </div>
                                                                                )}
                                                                            </Downshift>
                                                                        </DropdownUlWrapper>
                                                                    )}
                                                                    <CancelButton className="d-flex">
                                                                        <DropdownWithCircle />
                                                                    </CancelButton>
                                                                </InputContainer>
                                                                {/* <p>Select a dialog from the dropdown list.</p> */}
                                                            </div>
                                                        </div>
                                                        {/* <div className="automation_condition_form">
                                                            <div className="select_work_form">
                                                                <InputContainer className="mb-0">
                                                                    <Input
                                                                        type="text"
                                                                        defaultValue="Execute Dialog"
                                                                        className={'disable_edit'}
                                                                    />
                                                                    <InputContent>Select Dialog</InputContent>
                                                                </InputContainer>
                                                            </div>
                                                        </div> */}
                                                    </>
                                                )}
                                            </>
                                        )
                                    })}
                            </div>
                        </>
                        {/* // )} */}
                        {/* addmore field */}
                        {!optionalPrompt && (
                            <div className="add_connection_automation add_recognise">
                                <div className="show_options">
                                    {dialogChooser.length !== 0 ? (
                                        <SmallButton
                                            primary
                                            onClick={() => {
                                                if (mode != 'view') {
                                                    parentScrollCallback && parentScrollCallback(true)
                                                    setDialogChooser([
                                                        ...dialogChooser,
                                                        {
                                                            show: true,
                                                            title: '',
                                                            dialogId: '',
                                                            showDropDown: false,
                                                            jumpOrExit: 'jump',
                                                            respondWith: ''
                                                        }
                                                    ])

                                                    setConditions([
                                                        ...conditions,
                                                        {
                                                            type: conditionTYPES.SELECT
                                                        }
                                                    ])
                                                }
                                            }}
                                            className={mode == 'view' ? 'disable_edit' : ''}
                                        >
                                            <span>
                                                <AddIcon />
                                            </span>
                                            <label>Add more</label>
                                        </SmallButton>
                                    ) : null}
                                </div>
                            </div>
                        )}
                    </div>
                    {/* Advanced Options End */}
                </div>

                {showDeleteModal()}
            </div>
        </WrapperSlot>
    )
}

const ValueChooser = ({
    type,
    rawVal,
    data,
    handleChange,
    mode
}: {
    type: 'IntentExpression' | 'EntityExpression'
    rawVal: string
    data: any[]
    handleChange: Function
    mode: 'view' | 'edit'
}) => {
    var searchEl: any

    const [isPopUpOpen, setIsPopUpOpen] = useState<boolean>(false)

    const [value, setValue] = useState<string>(rawVal)

    const [search, setSearch] = useState<string>('')

    const [keycode, setKeycode] = useState<number>(13)

    const [metacode, setMetacode] = useState<boolean>(false)

    useEffect(() => {
        setValue(rawVal)
    }, [rawVal])

    return (
        <>
            <div className="automation_condition_form search_slots">
                <div className="">
                    <InputContainer>
                        <Input
                            type="text"
                            onClick={() => {
                                setIsPopUpOpen(true)
                                setSearch(value)
                            }}
                            onChange={() => {}}
                            value={value}
                            className={mode == 'view' ? 'disable_edit' : ''}
                        />
                        <InputContent>{type === 'IntentExpression' ? 'Intent name' : 'Entity name'}</InputContent>
                        <DropdownUlWrapper>
                            {isPopUpOpen ? (
                                <Downshift
                                    isOpen={isPopUpOpen === true}
                                    onOuterClick={() => {
                                        setIsPopUpOpen(false)
                                        setSearch('')
                                    }}
                                >
                                    {() => (
                                        <div className="dropdown_right_entity">
                                            <DropdownUl>
                                                <DropdownLi style={{ height: 48 }}>
                                                    <label className="form-input">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder={
                                                                type === 'IntentExpression'
                                                                    ? 'Search intent'
                                                                    : 'Search entity'
                                                            }
                                                            ref={el => {
                                                                searchEl = el
                                                            }}
                                                            value={search}
                                                            onKeyDown={e => {
                                                                setMetacode(e.metaKey || e.ctrlKey ? true : false)
                                                                setKeycode(e.keyCode)
                                                            }}
                                                            onChange={e => {
                                                                if (keycode !== 32 && metacode === false) {
                                                                    setSearch(e.target.value)
                                                                }
                                                            }}
                                                        />
                                                        <span>
                                                            <SearchIcon />
                                                        </span>
                                                    </label>
                                                </DropdownLi>
                                                {data.filter(e => e.text.toLowerCase().includes(search.toLowerCase()))
                                                    .length === 0 ? (
                                                    <DropdownLi className="color-li">
                                                        {type === 'IntentExpression'
                                                            ? 'No Intent available'
                                                            : 'No Entity available'}
                                                    </DropdownLi>
                                                ) : null}
                                                {sortAnArrayByName(data,"text")
                                                    .filter(e => e.text.toLowerCase().includes(search.toLowerCase()))
                                                    .map((e: any, index: number) => (
                                                        <DropdownLi
                                                            key={index}
                                                            onClick={() => {
                                                                handleChange(e.text)
                                                                setIsPopUpOpen(false)
                                                                setSearch('')
                                                            }}
                                                        >
                                                            {e.text}
                                                        </DropdownLi>
                                                    ))}
                                            </DropdownUl>
                                        </div>
                                    )}
                                </Downshift>
                            ) : null}
                        </DropdownUlWrapper>
                    </InputContainer>
                </div>
            </div>
        </>
    )
}

const SlotsContainer = () => {
    const {
        workspaceName,
        assistantID,
        setPageState,
        PageState,
        fetchCallback,
        dialogueName,
        parentIfnode
    } = useContext(DialogueContext)

    const { data } = PageState as SLOT_RESPONSE

    const [slots, setSlots] = useState<slotsT[]>([data.slots[0]])

    const [loader, setLoader] = useState<boolean>(false)

    const [error, setError] = useState<ErrorT>(ErrorState)

    const [alreadyOpen, setAlreadyOpen] = useState<boolean>(true)

    const [showSaveButton, setShowSaveButton] = useState<boolean>(false)
    const [digression, setDigression] = useState<boolean>(data.slots[0].allow_digression)
    const [supress_prompt_if_variable_not_empty, setSupress_prompt_if_variable_not_empty] = useState<boolean>(
        data.slots[0].supress_prompt_if_variable_not_empty
    )
    const [scrollableDivRef, scrollTo] = useScrollHook()
    // const addMore = () => {
    //     setSlots(data =>
    //         data.concat([
    //             {
    //                 condition: {
    //                     type: 'IntentExpression',
    //                     value: ''
    //                 },
    //                 save_as: 'literal',
    //                 variable: '',
    //                 prompt: '',
    //                 slot_exit_conditions: []
    //             }
    //         ])
    //     )
    // }

    const save = async () => {
        setLoader(true)
        try {
            if (data.id === '') {
                await createResponseDialogue(
                    workspaceName,
                    assistantID,
                    data.parent,
                    {
                        ...data,
                        slots: [
                            {
                                ...slots[0],
                                allow_digression: digression,
                                supress_prompt_if_variable_not_empty: supress_prompt_if_variable_not_empty
                            }
                        ]
                    } as SlotContainerT,
                    dialogueName
                )

                logEvent(workspaceName, {
                    event:"EVENT::DIALOG::ADD_VERIFY_USER_DETAILS", event_meta:{
                    name: dialogueName,
                    description: ""
                }})

                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Get Details Node Created'
                })
            } else {
                await updateResponseDialogue(
                    workspaceName,
                    assistantID,
                    data.parent,
                    data.id,
                    {
                        ...data,
                        slots: [
                            {
                                ...slots[0],
                                allow_digression: digression,
                                supress_prompt_if_variable_not_empty: supress_prompt_if_variable_not_empty
                            }
                        ]
                    } as SlotContainerT,
                    dialogueName
                )
                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Get Details Node Updated'
                })
            }
            fetchCallback(() => setLoader(false))
        } catch (error) {
            setLoader(false)
            setError({ error: true, info: 'Something went wrong. Please try again!' })
        }
    }

    const parentRootNode = parentIfnode as IfNodeT

    return (
        <WrapperExit>
            <PopupWrapper>
                <PopupContainer>
                    <Header>
                        <HeaderLeft minWidth={300}>
                            Verify user details
                            <Tooltip
                                className="target customTip"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content={<SlotTips />}
                                distance={5}
                            >
                                <InfoIcon />
                            </Tooltip>
                            <a href="https://help.workativ.com/knowledgebase/get-user-details" target="_blank">
                                <Tooltip
                                    className="target customTip"
                                    zIndex={10000}
                                    arrowSize={8}
                                    tagName="span"
                                    content="View help document"
                                    distance={5}
                                >
                                    <QaIconWithCircle />
                                </Tooltip>
                            </a>
                        </HeaderLeft>
                        <HeaderRight>
                            <VideoLink id={'GET_AND_VERIFY_USER_DETAILS'} text={'How to'} />
                        </HeaderRight>
                    </Header>
                    <Paragraph>Make your chatbot verify and save user input.</Paragraph>
                    <PopupScrollHeight ref={scrollableDivRef}>
                        {/* <EmptyDiv>
                            <div className="row">
                                <div className="get_users_wrapper">
                                    <div className="slots_right_bot">
                                        <Paragraph className="recognize_dialog_fontsize row">
                                            Skip user input
                                            <Tooltip
                                                className="target customTip verify_user_tooltips tooltips_color"
                                                zIndex={10000}
                                                arrowSize={8}
                                                tagName="span"
                                                content={''}
                                                distance={5}
                                            >
                                                <InfoIcon />
                                            </Tooltip>
                                            <div className="show_hide">
                                                <div className="switch_box box_1">
                                                    <input type="checkbox" className={'switch_1 editor_btn_disabled'} />
                                                </div>
                                            </div>
                                        </Paragraph>
                                    </div>
                                </div>
                            </div>
                        </EmptyDiv> */}
                        <ErrorBoundary
                            error={error}
                            render={(err: any, info: any) => {
                                return err && <Error.Delete onClick={() => setPageState(VIEW)} info={info} />
                            }}
                        >
                            {/* {console.log(slots)} */}
                            {loader ? (
                                <div className="popup_loader">
                                    <Loader.PopupLoader show={loader} />
                                </div>
                            ) : (
                                <React.Fragment>
                                    <EmptyDiv>
                                        {parentRootNode.allow_digression && (
                                            <EmptyDiv>
                                                <div className="row">
                                                    <div className="get_users_wrapper">
                                                        <div className="slots_right_bot">
                                                            <Paragraph className="recognize_dialog_fontsize row">
                                                                Allow dialog switching at this point
                                                                <Tooltip
                                                                    className="target customTip verify_user_tooltips tooltips_color"
                                                                    zIndex={10000}
                                                                    arrowSize={4}
                                                                    tagName="span"
                                                                    content="By enabling this option, chatbot can switch conversation at this point, based on user input."
                                                                    distance={5}
                                                                >
                                                                    <InfoIcon />
                                                                </Tooltip>
                                                                <a
                                                                    href="https://help.workativ.com/knowledgebase/building-a-dialog/"
                                                                    target="_blank"
                                                                    rel="noopener"
                                                                    title='Building a dialog'
                                                                >
                                                                    <Tooltip
                                                                        className="target customTip tooltips_color"
                                                                        zIndex={10000}
                                                                        arrowSize={8}
                                                                        tagName="span"
                                                                        content="View help document"
                                                                        distance={5}
                                                                    >
                                                                        <QaIconWithCircle />
                                                                    </Tooltip>
                                                                </a>
                                                                <div className="show_hide">
                                                                    <div className="switch_box box_1">
                                                                        <input
                                                                            type="checkbox"
                                                                            className={`switch_1`}
                                                                            checked={digression}
                                                                            onChange={() => {
                                                                                setDigression(!digression)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Paragraph>
                                                            <Paragraph className="recognize_dialog_fontsize row">
                                                                Always prompt user
                                                                <div className="show_hide">
                                                                    <div className="switch_box box_1">
                                                                        <input
                                                                            type="checkbox"
                                                                            className={`switch_1`}
                                                                            checked={
                                                                                supress_prompt_if_variable_not_empty
                                                                            }
                                                                            onChange={() => {
                                                                                setSupress_prompt_if_variable_not_empty(
                                                                                    !supress_prompt_if_variable_not_empty
                                                                                )
                                                                            }}
                                                                            style={{ marginLeft: 5 }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Paragraph>
                                                        </div>
                                                    </div>
                                                </div>
                                            </EmptyDiv>
                                        )}
                                        {slots
                                            ? slots.map((slot: slotsT, i: number) => {
                                                  return (
                                                      <Slot
                                                          key={i}
                                                          index={i}
                                                          slot={slot}
                                                          slots={slots}
                                                          defaultSlots={data.slots}
                                                          alreadyOpen={alreadyOpen}
                                                          setAlreadyOpen={setAlreadyOpen}
                                                          removeSlot={() => {
                                                              setSlots(slotdata =>
                                                                  slotdata.filter((e, index) => i !== index)
                                                              )
                                                          }}
                                                          saveSlot={(data: slotsT) => {
                                                              setSlots(slotdata =>
                                                                  slotdata.map((e, index) => (i === index ? data : e))
                                                              )
                                                          }}
                                                          showSaveButton={(showSaveButton: boolean) => {
                                                              setShowSaveButton(showSaveButton)
                                                          }}
                                                          parentScrollCallback={((shouldScroll) => scrollTo('bottom', shouldScroll) )}
                                                      />
                                                  )
                                              })
                                            : null}

                                        {/* <div
                                className={alreadyOpen === false ? 'plus_addmore' : 'plus_addmore editor_btn_disabled'}
                            >
                                <div
                                    className="plus_add_more full_width"
                                    onClick={() => (alreadyOpen === false ? addMore() : null)}
                                >
                                    <h4>
                                        <div className="parent_node_no">
                                            <span className="parent_svg">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.24 16.24">
                                                    <title>Add More</title>
                                                    <g id="Layer_2" data-name="Layer 2">
                                                        <g id="Layer_1-2" data-name="Layer 1">
                                                            <path
                                                                className="cls-1"
                                                                d="M0,8.12a.93.93,0,0,1,.93-.93H7.19V.93a.93.93,0,0,1,1.86,0V7.19h6.26a.93.93,0,0,1,0,1.86H9.05v6.26a.93.93,0,0,1-1.86,0V9.05H.93A.93.93,0,0,1,0,8.12Z"
                                                            />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </span>
                                        </div>
                                        Add More
                                    </h4>
                                </div>
                            </div> */}
                                    </EmptyDiv>
                                    <ButtonContainer className="footer_button_fixed">
                                        <div className="popup_footer_button_container">
                                        <Button
                                            primary
                                            type="button"
                                            data-dismiss="modal"
                                            onClick={() => setPageState(VIEW)}
                                        >
                                            <label> Cancel</label>
                                        </Button>

                                        <Button
                                            className={!showSaveButton ? ' editor_btn_disabled' : ''}
                                            disabled={!showSaveButton}
                                            type="button"
                                            data-dismiss="modal"
                                            onClick={() => save()}
                                        >
                                            <label> Save</label>
                                        </Button>
                                        </div>
                                    </ButtonContainer>
                                </React.Fragment>
                            )}
                        </ErrorBoundary>
                    </PopupScrollHeight>
                </PopupContainer>
            </PopupWrapper>
        </WrapperExit>
    )
}

export default SlotsContainer
