/*eslint-disable*/
import Downshift from 'downshift'
import * as React from 'react'

import { VideoButtonB } from '@/Videos/Videos'
import {
    AddIcon,
    AppTriggerFilterIcon,
    CloseIcon,
    DateIcon,
    DeleteIcon,
    DownloadFromMarketplace,
    GroupIcon,
    ListviewIcon,
    SearchIcon,
    SortIcon,
    TypeIcon
} from '@/common/Icons/Icons'
import { Header, HeaderChild } from '@/common/components/Header'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'

import {
    CardCountT,
    FlowFilterE,
    FlowFilterT,
    GridView,
    ListView,
    Mode,
    Modes,
    SortT,
    SortType,
    ViewTypeT,
    Views
} from './types'
import { selectedFlowCards, selectedGroupCards } from './utils'
import { getProductId } from '../../../shared/common/src/utils/utils';

const product = getProductId();

const filterList =
  product === 'AUTOMATE'
    ? [
        { filterType: FlowFilterE.Home, displayText: 'All' },
        { filterType: FlowFilterE.Draft, displayText: 'Draft' },
        { filterType: FlowFilterE.Approval, displayText: 'Approvals' },
        // { filterType: FlowFilterE.Trigger, displayText: 'Trigger' },
        // { filterType: FlowFilterE.NoTrigger, displayText: 'No Trigger' },
        { filterType: FlowFilterE.Active, displayText: 'Active' },
        { filterType: FlowFilterE.InActive, displayText: 'Inactive' },
        { filterType: FlowFilterE.AppTrigger, displayText: 'App Triggers' },
        { filterType: FlowFilterE.WebhookTrigger, displayText: 'Webhooks' },
        // { filterType: FlowFilterE.Trigger, displayText: 'User Trigger' },
      ]
    : product === 'ASSISTANT'
    ? [
        { filterType: FlowFilterE.Home, displayText: 'All' },
        { filterType: FlowFilterE.Draft, displayText: 'Draft' },
        { filterType: FlowFilterE.Approval, displayText: 'Approvals' },
        // { filterType: FlowFilterE.Trigger, displayText: 'Trigger' },
        // { filterType: FlowFilterE.NoTrigger, displayText: 'No Trigger' },
        { filterType: FlowFilterE.Active, displayText: 'Active' },
        { filterType: FlowFilterE.InActive, displayText: 'Inactive' },
      ]
    : [];

const filterAppList = [
    { filterAppType: FlowFilterE.Home, displayText: 'All Triggers' },
    { filterAppType: FlowFilterE.EventTrigger, displayText: 'Application Triggers' },
    { filterAppType: FlowFilterE.Trigger, displayText: 'Chatbot Triggers' }
]

type FlowEditorHeaderP = CardCountT & {
    mode: Modes
    deselect: () => void
    flowView: () => void
    addGroup: () => void
    setToAddMode: () => void
    homeFilterApply: (filter: FlowFilterT,forAllFilter:boolean) => void
    appliedHomeFilter: FlowFilterT
    sortBy: (sortType: SortT) => void
    sortOption: SortT
    delete: () => void
    closeSearch: () => void
    search: (key: string) => void
    searchKey: string
    viewType: Views
    changeView: (View: Views) => void
    openMoveModal: () => void
    changeRouteView: (view: FlowFilterT) => void
    routeFilter: FlowFilterT
    openMarketPlace: Function
    isMarketPlaceOpen: boolean
}

export default class FlowEditorHeader extends React.Component<FlowEditorHeaderP> {
    state = {
        inputFocus: false,
        showSortButton: true,
        showFilterButton: true,
        showAppFilterButton: true,
        dropdown: { show: false, value: this.props.routeFilter as FlowFilterT }
    }

    showDropDown = () => {
        this.setState({ showSortButton: false })
    }

    showFilterDropDown = () => {
        this.setState({ showFilterButton: false })
    }

    showAppFilterDropDown = () => {
        this.setState({ showAppFilterButton: false })
    }

    dropDownChange = (value: FlowFilterT) => {
        this.props.changeRouteView(value)
    }

    renderSelected = (pageMode: Modes, deselect: () => void) => {
        switch (pageMode.mode) {
            case Mode.Select: {
                const groupList = selectedGroupCards(pageMode.cards)
                const flowList = selectedFlowCards(pageMode.cards)
                return (
                    <Button
                        priamry
                        style={{ background: '#FFE5D3' }}
                        className="btn btn-primary btn_large automation_select_btn"
                        onClick={deselect}
                    >
                        <label style={{ color: '#333' }}>
                            {flowList.length ? <b>0{flowList.length}</b> : ''}
                            {flowList.length ? 'Automations selected' : ''}
                            {groupList.length && flowList.length ? ' &' : ''}
                            {groupList.length ? <b>{groupList.length}</b> : ''}
                            {groupList.length ? 'Groups selected' : ''}
                        </label>
                        <span>
                            {' '}
                            <CloseIcon />
                        </span>
                    </Button>
                )
            }
        }
        return null
    }

    renderCreateGroup = (pageMode: Modes, addGroup: () => void) => {
        const { openMoveModal } = this.props
        const view = this.props.routeFilter
        return null
    }

    renderDelete = (mode: Modes) => {
        return mode.mode === Mode.Select ? (
            <Button primary onClick={this.props.delete}>
                <span className="header_button">
                    {' '}
                    <DeleteIcon />
                </span>
                <label>Delete</label>
            </Button>
        ) : null
    }

    renderNewButton = (mode: Modes) => {
        return (this.props.routeFilter === FlowFilterE.Draft || this.props.routeFilter === FlowFilterE.Home) &&
            (mode.mode === Mode.View || mode.mode === Mode.Add) ? (
            <>
                <div className="automation_download_btn">
                    <Button primary onClick={this.props.setToAddMode}>
                        <span className="header_button">
                            <AddIcon />
                        </span>
                        <label>Create New</label>
                    </Button>
                    {/* <span className="or_center">Or</span> */}
                    <Button primary onClick={() => this.props.openMarketPlace()}>
                        <span className="header_button">
                            {' '}
                            <DownloadFromMarketplace />
                        </span>
                        <label>Download</label>
                    </Button>
                </div>
            </>
        ) : null
    }

    renderHeader = (mode: Modes) => {
        return (
            <div className="indent_left">
                <div className="indent_title" style={{ textTransform: 'capitalize' }}>
                    {this.props.routeFilter == "app-trigger"?"App Trigger":this.props.routeFilter == "webhook-trigger"? "Webhook Trigger": this.props.routeFilter == "chatbot-trigger"? "Chatbot Trigger": this.props.routeFilter} {this.props.routeFilter === 'home' ? '- ' : ' '}Workflows
                </div>
                <div className="indent_sub_title">Catalog ({this.props.count})</div>
            </div>
        )
    }

    renderFilter = (_mode: Modes) => {
        if (_mode.mode === Mode.Error) {
            return null
        }
        return (
            <>
                <Button primary onClick={this.showFilterDropDown}>
                    <span className="header_button">
                        <SortIcon />
                    </span>
                    <label>Filter</label>
                </Button>
                {!this.state.showFilterButton && (
                    <Downshift
                        isOpen={true}
                        onOuterClick={() => {
                            this.setState({
                                showFilterButton: true
                            })
                        }}
                    >
                        {() => (
                            <div className="flow_sort_by automation_sort">
                                <div className="flow_sort_dropdown">
                                    {filterList.map((item, index) => (
                                        <p
                                            key={index}
                                            onClick={() => {
                                                this.props.homeFilterApply(item.filterType,item.filterType == FlowFilterE.Home )
                                                this.setState({ showFilterButton: true })
                                            }}
                                        >
                                            <span>{item.displayText}</span>
                                            <label>
                                                <input
                                                    type="radio"
                                                    className="option-input radio"
                                                    name="date"
                                                    checked={this.props.appliedHomeFilter === item.filterType}
                                                />
                                            </label>
                                        </p>
                                    ))}
                                </div>
                            </div>
                        )}
                    </Downshift>
                )}
            </>
        )
    }

    renderAppFilter = (_mode: Modes) => {
        if (_mode.mode === Mode.Error) {
            return null
        }
        return (
            <>
                <Button primary onClick={this.showAppFilterDropDown}>
                    <span className="header_button">
                        <AppTriggerFilterIcon />
                    </span>
                    <label>All Trigger</label>
                </Button>
                {!this.state.showAppFilterButton && (
                    <Downshift
                        isOpen={true}
                        onOuterClick={() => {
                            this.setState({
                                showAppFilterButton: true
                            })
                        }}
                    >
                        {() => (
                            <div className="flow_sort_by automation_sort">
                                <div className="flow_sort_dropdown trigger_filter">
                                    {filterAppList.map((item, index) => (
                                        <p
                                            key={index}
                                            onClick={() => {
                                                this.props.homeFilterApply(item.filterAppType,false)
                                                this.setState({ showAppFilterButton: true })
                                            }}
                                        >
                                            <span>{item.displayText}</span>
                                            <label>
                                                <input
                                                    type="radio"
                                                    className="option-input radio"
                                                    name="date"
                                                    checked={this.props.appliedHomeFilter === item.filterAppType}
                                                />
                                            </label>
                                        </p>
                                    ))}
                                </div>
                            </div>
                        )}
                    </Downshift>
                )}
            </>
        )
    }

    renderSort = (mode: Modes) => {
        if (mode.mode === Mode.Error) {
            return null
        }
        if (mode.mode === Mode.Select) return null
        return (
            <>
                <Button primary onClick={this.showDropDown}>
                    <span className="header_button">
                        <SortIcon />
                    </span>
                    <label>Sort</label>
                </Button>
                {!this.state.showSortButton && (
                    <Downshift
                        isOpen={true}
                        onOuterClick={() => {
                            this.setState({
                                showSortButton: true
                            })
                        }}
                    >
                        {() => (
                            <div className="flow_sort_by automation_sort">
                                <div className="flow_sort_dropdown">
                                    <p
                                        onClick={() => {
                                            this.props.sortBy(SortType.Date)
                                            this.setState({ showSortButton: true })
                                        }}
                                    >
                                        <span className="header_button">
                                            {' '}
                                            <DateIcon />
                                            Sort by date
                                        </span>
                                        <label>
                                            <input
                                                type="radio"
                                                className="option-input radio"
                                                name="date"
                                                checked={this.props.sortOption === SortType.Date}
                                            />
                                        </label>
                                    </p>
                                    <p
                                        onClick={() => {
                                            this.props.sortBy(SortType.Name)
                                            this.setState({ showSortButton: true })
                                        }}
                                    >
                                        <span>
                                            <TypeIcon />
                                            Sort by name
                                        </span>
                                        <label>
                                            <input
                                                type="radio"
                                                className="option-input radio"
                                                name="name"
                                                checked={this.props.sortOption === SortType.Name}
                                            />
                                        </label>
                                    </p>
                                </div>
                            </div>
                        )}
                    </Downshift>
                )}
            </>
        )
        return (
            <>
                <Button primary onClick={this.showDropDown}>
                    <span>
                        <SortIcon />
                    </span>
                    <label>Sort</label>
                </Button>
                {!this.state.showSortButton && (
                    <Downshift
                        isOpen={true}
                        onOuterClick={() => {
                            this.setState({
                                showSortButton: true
                            })
                        }}
                    >
                        {() => (
                            <div className="flow_sort_by automation_sort">
                                <div className="flow_sort_dropdown">
                                    <p
                                        onClick={() => {
                                            this.props.sortBy(SortType.Date)
                                            this.setState({ showSortButton: true })
                                        }}
                                    >
                                        <span>
                                            {' '}
                                            <DateIcon />
                                            Sort by date
                                        </span>
                                        <label>
                                            <input
                                                type="radio"
                                                className="option-input radio"
                                                name="date"
                                                checked={this.props.sortOption === SortType.Date}
                                            />
                                        </label>
                                    </p>
                                    <p
                                        onClick={() => {
                                            this.props.sortBy(SortType.Name)
                                            this.setState({ showSortButton: true })
                                        }}
                                    >
                                        <span>
                                            <TypeIcon />
                                            Sort by name
                                        </span>
                                        <label>
                                            <input
                                                type="radio"
                                                className="option-input radio"
                                                name="name"
                                                checked={this.props.sortOption === SortType.Name}
                                            />
                                        </label>
                                    </p>
                                </div>
                            </div>
                        )}
                    </Downshift>
                )}
            </>
        )
    }

    renderViewButton = () => {
        let { mode, viewType, changeView } = this.props

        if (mode.mode === Mode.Error) {
            return null
        }
        return mode.mode === Mode.View ? (
            viewType.View === ViewTypeT.GridView ? (
                <React.Fragment>
                    <Button primary onClick={() => changeView(ListView)}>
                        <span className="header_button">
                            <ListviewIcon />
                        </span>
                        <label>List view</label>
                    </Button>
                </React.Fragment>
            ) : (
                <Button primary onClick={() => changeView(GridView)}>
                    <span className="header_button">
                        <GroupIcon />
                    </span>
                    <label>Card view</label>
                </Button>
            )
        ) : null
    }

    renderSearch = (mode: Modes) => {
        if (mode.mode === Mode.Error) {
            return null
        }
        return (
            <span className="search_icon_svg">
                <input
                    type="search"
                    placeholder="Search"
                    value={this.props.searchKey}
                    onFocus={() => this.setState({ inputFocus: true })}
                />
                <SearchIcon />
            </span>
        )
    }

    render() {
        let { mode, deselect, addGroup, search } = this.props

        const view = this.props.routeFilter
        // console.log('props search', this.props.searchKey)

        return (
            <Header>
                <HeaderChild type="first">
                    {this.renderHeader(mode)}
                    <div className={this.props.isMarketPlaceOpen ? 'indent_right editor_btn_disabled' : 'indent_right'}>
                        {this.renderNewButton(mode)}
                        {this.renderSelected(mode, deselect)}
                    </div>
                </HeaderChild>
                <HeaderChild
                    type="second"
                    class={
                        this.props.isMarketPlaceOpen
                            ? 'automation_download_btn editor_btn_disabled'
                            : 'automation_download_btn'
                    }
                >
                    {!this.state.inputFocus && (
                        <React.Fragment>
                            {view === FlowFilterE.Home && (
                                <div className="flow_sortby_dropdown">{this.renderAppFilter(mode)}</div>
                            )}
                            {this.renderViewButton()}
                            {(view === FlowFilterE.Draft || view === FlowFilterE.Home) && (
                                <div className="flow_sortby_dropdown sort_button">{this.renderSort(mode)}</div>
                            )}
                            {view === FlowFilterE.Home && (
                                <div className="flow_sortby_dropdown">{this.renderFilter(mode)}</div>
                            )}
                            
                            {this.renderCreateGroup(mode, addGroup)}
                            {this.renderDelete(mode)}
                        </React.Fragment>
                    )}
                    {!this.state.inputFocus ? (
                        this.renderSearch(mode)
                    ) : (
                        <span className="search_click">
                            <input
                                className="search_click"
                                type="search"
                                placeholder="Search"
                                value={this.props.searchKey}
                                autoFocus={true}
                                onChange={e => search(e.target.value)}
                            />
                            <span
                                onClick={() => {
                                    this.props.closeSearch()
                                    this.setState({ inputFocus: false })
                                }}
                            >
                                <CloseIcon />
                            </span>
                        </span>
                    )}
                    <VideoButtonB id={'WORKFLOW_HOMEPAGE_FUNCTIONS'} />
                </HeaderChild>
            </Header>
        )
    }
}
