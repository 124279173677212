/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react'
import { VideosLayout, VideoContext } from './Videos'

export function VideosWrapper(props: any) {
    const videoContext = useContext(VideoContext)
    const [state, setState] = useState<any>('loading')

    useEffect(() => {
        setState(videoContext.videosList)
    }, [videoContext.videosList])

    return (
        <VideosLayout
            state={
                state == 'loading'
                    ? { type: 'loading' }
                    : Array.isArray(state)
                    ? { type: 'success', data: state }
                    : {
                          type: 'error',
                          error: state.response && state.response.status == 403 ? state.response : {}
                      }
            }
            retry={videoContext.refetchVideos}
            {...props}
        />
    )
}
