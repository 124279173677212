/*eslint-disable*/
import React from 'react'

import { CloseIcon } from '@/common/Icons/Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { Loader } from '@/common/components/Loader'
import lazyGA from '@/common/google-analytics/tracking'

import { ExistT as EntityT } from '../../../Entity/types'
import { IntentT } from '../../../Intent/types'
import {
    createSlotContainerDialog,
    createSlotDialog,
    removeSlotDialog,
    updateDailouge,
    updateSlotDialog
} from '../../Actions/AsyncOperations'
import { sortBySibling } from '../../Actions/ContextManager'
import { JumpToSelectorT, SlotContainerDialogT, SlotDialogT } from '../../Types/WatsonT'
import { DropDown } from '../Common'

const MOCK_SLOT_DIALOG: SlotDialogT = {
    type: 'slot',
    parent: '',
    previous_sibling: null,
    variable: '',
    condition: '',
    prompt: '',
    dialog_node: ''
}

type SlotsP = {
    workspacename: string
    element: any
    previousSibling: any
    nextSibling: any
    parent: any
    ResetView: Function
    AfterSave: Function
    intents: IntentT[]
    entities: EntityT[]
}

type SlotsS = {
    dialog: any
    showPopupLoader: boolean
    error: object
    slotOnEdit: boolean
}
export class SlotContainer extends React.Component<SlotsP, SlotsS> {
    state: SlotsS = {
        dialog: this.props.element,
        showPopupLoader: false,
        error: { error: null, info: null },
        slotOnEdit: false
    }

    componentDidMount() {
        const { dialog } = this.state
        if (dialog.slots && dialog.slots.length == 0) {
            const newSlot = dialog.slots.concat({
                type: 'slot',
                parent: dialog.dialog_node,
                previous_sibling: null,
                variable: '',
                condition: '',
                prompt: '',
                dialog_node: ''
            })
            this.setState({
                dialog: {
                    ...dialog,
                    slots: newSlot
                }
            })
        } else if (dialog.slots) {
            this.setState({
                dialog: {
                    ...dialog,
                    slots: sortBySibling(dialog.slots)
                }
            })
        }
    }
    deleteSlot = async (node: SlotDialogT, index: number) => {
        const { dialog } = this.state
        const { workspacename } = this.props
        if (node.dialog_node == '') {
            this.setState({
                dialog: {
                    ...dialog,
                    slots: dialog.slots ? dialog.slots.filter((e: any, i: number) => i != index) : []
                }
            })
        } else if (dialog.slots && dialog.slots.length > 1) {
            await removeSlotDialog(workspacename, node.dialog_node, node)
            this.setState({
                dialog: {
                    ...dialog,
                    slots: dialog.slots.filter((e: any) => e.dialog_node != node.dialog_node)
                }
            })
        }
    }
    addMore = () => {
        const { dialog } = this.state
        if (dialog.slots) {
            const newSlot = dialog.slots.concat({
                type: 'slot',
                parent: dialog.dialog_node,
                previous_sibling: dialog.slots[dialog.slots.length - 1]
                    ? dialog.slots[dialog.slots.length - 1].dialog_node
                    : null,
                variable: '',
                condition: '',
                prompt: '',
                dialog_node: ''
            })
            this.setState({
                dialog: {
                    ...dialog,
                    slots: newSlot
                }
            })
        }
    }
    saveSlot = async (data: any) => {
        const { dialog } = this.state
        const { workspacename } = this.props
        try {
            const { previousSibling, nextSibling, parent } = this.props
            let SlotContainer = null
            const obj: SlotDialogT = {
                type: 'slot',
                dialog_node: '',
                prompt: data.prompt,
                condition: data.condition,
                variable:
                    data.variable.slice(0, 1) == '$' ? data.variable.slice(1, data.variable.length) : data.variable,
                previous_sibling: data.previous_sibling,
                parent: data.parent
            }

            //show Loading
            this.setState({ showPopupLoader: true })

            if (dialog.dialog_node == '') {
                const container: SlotContainerDialogT = {
                    type: 'slot-container',
                    dialog_node: '',
                    parent: dialog.parent,
                    previous_sibling: previousSibling ? previousSibling.dialog_node : null,
                    slots: [],
                    children: [],
                    jump_to: {
                        dialog_node: 'dmmy',
                        selector: JumpToSelectorT.body
                    },
                    metadata: { hide_jump_to: false },
                    context: {},
                    created: '',
                    updated: ''
                }
                const result = await createSlotContainerDialog(workspacename, container)

                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Get Details Node Created'
                })

                SlotContainer = result
                if (previousSibling) {
                    await updateDailouge(workspacename, {
                        ...previousSibling,
                        jump_to: {
                            dialog_node: result.dialog_node,
                            selector: JumpToSelectorT.body
                        },
                        metadata: { ...previousSibling.metadata, hide_jump_to: true }
                    })
                } else {
                    if (parent && parent.type == 'if') {
                        await updateDailouge(workspacename, {
                            ...parent,
                            jump_to: {
                                dialog_node: result.dialog_node,
                                selector: JumpToSelectorT.body
                            },
                            metadata: { ...parent.metadata, hide_jump_to: true }
                        })
                    }
                }
                if (nextSibling) {
                    if (nextSibling.type == 'if') {
                        await updateDailouge(workspacename, {
                            ...result,
                            jump_to: {
                                dialog_node: nextSibling.dialog_node,
                                selector: JumpToSelectorT.user_input
                            },
                            metadata: { hide_jump_to: true }
                        })
                    } else {
                        await updateDailouge(workspacename, {
                            ...result,
                            jump_to: {
                                dialog_node: nextSibling.dialog_node,
                                selector: JumpToSelectorT.body
                            },
                            metadata: { hide_jump_to: true }
                        })
                    }
                }
                obj.parent = result.dialog_node
            }

            if (data.dialog_node == '') {
                const result: SlotDialogT = await createSlotDialog(workspacename, obj)
                if (dialog.slots) {
                    const newData = dialog.dialog_node == '' && SlotContainer ? SlotContainer : dialog
                    this.setState({
                        dialog: {
                            ...newData,
                            slots:
                                dialog.slots.length == 1
                                    ? [result]
                                    : dialog.slots.map((e: any) => (e.dialog_node == '' ? result : e))
                        }
                    })
                }
            } else {
                const result: SlotDialogT = await updateSlotDialog(workspacename, data.dialog_node, {
                    ...data,
                    prompt: data.prompt,
                    condition: data.condition,
                    variable:
                        data.variable.slice(0, 1) == '$' ? data.variable.slice(1, data.variable.length) : data.variable
                })

                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Get Details Node Updated'
                })

                if (dialog.slots) {
                    const newData = dialog.dialog_node == '' && SlotContainer ? SlotContainer : dialog
                    this.setState({
                        dialog: {
                            ...newData,
                            slots: dialog.slots.map((e: any) => {
                                if (e.dialog_node == data.dialog_node || e.dialog_node == '') {
                                    return result
                                } else {
                                    return e
                                }
                            })
                        }
                    })
                }
            }
            //hide loading
            this.setState({
                showPopupLoader: false
            })
        } catch (err) {
            this.setState({
                error: {
                    error: true,
                    info: `Server error!<br />Please try again after sometime.`
                },
                showPopupLoader: false
            })
        }
    }

    render() {
        const { dialog, showPopupLoader, error } = this.state
        let disable = dialog.slots
            ? dialog.slots.filter((e: any) => e.variable == '' || e.condition == '' || e.prompt == '').length > 0
            : true
        disable = this.state.slotOnEdit == true ? true : disable
        return (
            <div className="flow_popup2">
                <div data-ml-modal="">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="right-panel slots_panel">
                                <ErrorBoundary
                                    error={error}
                                    render={(err: any, info: any) => {
                                        return err && <Error.Delete onClick={this.props.ResetView} info={info} />
                                    }}
                                >
                                    <h5>Verify User Details</h5>
                                    <p>
                                        Makes your chatbot ‘get’ and ‘store’ the required data from a user's input
                                        during a session
                                    </p>
                                    {showPopupLoader ? (
                                        <div className="popup_loader">
                                            <Loader.PopupLoader show={showPopupLoader} />
                                        </div>
                                    ) : (
                                        <React.Fragment>
                                            <div className="right_panel_ht">
                                                {dialog.slots
                                                    ? dialog.slots.map((e: any, i: number) => {
                                                          return (
                                                              <ChecKFor
                                                                  key={i}
                                                                  index={i}
                                                                  slots={dialog.slots ? dialog.slots : []}
                                                                  isAnythingEdit={this.state.slotOnEdit}
                                                                  changeEditMode={(val: boolean) =>
                                                                      this.setState({
                                                                          slotOnEdit: val
                                                                      })
                                                                  }
                                                                  element={e}
                                                                  deleteSlot={this.deleteSlot}
                                                                  save={this.saveSlot}
                                                                  intents={this.props.intents}
                                                                  entities={this.props.entities}
                                                              />
                                                          )
                                                      })
                                                    : null}

                                                <div
                                                    className={
                                                        this.state.slotOnEdit == false
                                                            ? 'plus_addmore'
                                                            : 'plus_addmore editor_btn_disabled'
                                                    }
                                                >
                                                    <div
                                                        className="plus_add_more full_width"
                                                        onClick={() =>
                                                            this.state.slotOnEdit == false ? this.addMore() : null
                                                        }
                                                    >
                                                        <h4>
                                                            <div className="parent_node_no">
                                                                <span className="parent_svg">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 16.24 16.24"
                                                                    >
                                                                        <title>Add More</title>
                                                                        <g id="Layer_2" data-name="Layer 2">
                                                                            <g id="Layer_1-2" data-name="Layer 1">
                                                                                <path
                                                                                    className="cls-1"
                                                                                    d="M0,8.12a.93.93,0,0,1,.93-.93H7.19V.93a.93.93,0,0,1,1.86,0V7.19h6.26a.93.93,0,0,1,0,1.86H9.05v6.26a.93.93,0,0,1-1.86,0V9.05H.93A.93.93,0,0,1,0,8.12Z"
                                                                                />
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                            </div>
                                                            Add More
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="right-panel_btn">
                                                <button
                                                    className="btn btn-primary"
                                                    type="button"
                                                    data-dismiss="modal"
                                                    onClick={() => {
                                                        this.setState({
                                                            showPopupLoader: true
                                                        })
                                                        this.props.AfterSave(() => {
                                                            this.setState({
                                                                showPopupLoader: false
                                                            })
                                                        })
                                                    }}
                                                >
                                                    Cancel
                                                </button>

                                                <button
                                                    className={
                                                        disable
                                                            ? 'btn btn-success editor_btn_disabled'
                                                            : 'btn btn-success'
                                                    }
                                                    type="button"
                                                    data-dismiss="modal"
                                                    onClick={() => {
                                                        this.setState({
                                                            showPopupLoader: true
                                                        })
                                                        this.props.AfterSave(() => {
                                                            this.setState({
                                                                showPopupLoader: false
                                                            })
                                                        })
                                                    }}
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </ErrorBoundary>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

type ChecKForP = {
    element: SlotDialogT
    index: number
    deleteSlot: Function
    save: Function
    intents: IntentT[]
    entities: EntityT[]
    slots: SlotDialogT[]
    isAnythingEdit: boolean
    changeEditMode: Function
}

type ChecKForS = {
    isPopupOpen: Boolean
    conditionType: string
    condition: string
    variable: string
    prompt: string
    mode: string
    keycode: number
    metacode: boolean
}

export class ChecKFor extends React.Component<ChecKForP, ChecKForS> {
    state: ChecKForS = {
        isPopupOpen: false,
        conditionType: 'entity',
        condition: '',
        variable: '',
        prompt: '',
        mode: 'view',
        keycode: 13,
        metacode: false
    }

    componentDidUpdate(prevProps: ChecKForP) {
        if (this.props.element != prevProps.element) {
            const { prompt, condition, variable } = this.props.element
            let conditionType = 'entity'
            let conditionValue = condition
            if (condition != '') {
                const type = condition.slice(0, 1)
                if (type == '@') {
                    conditionType = 'entity'
                    conditionValue = condition.slice(1, condition.length - 8)
                } else if (type == '#') {
                    conditionType = 'intent'
                    conditionValue = condition.slice(1, condition.length)
                }
            }
            this.setState({
                condition: conditionValue,
                variable: variable.slice(0, 1) == '$' ? variable.slice(1, variable.length) : variable,
                prompt: prompt,
                conditionType: conditionType,
                mode: 'view'
            })
        }
    }

    componentDidMount() {
        const { prompt, condition, variable, dialog_node } = this.props.element
        let conditionType = 'entity'
        let conditionValue = condition
        if (condition != '') {
            const type = condition.slice(0, 1)
            if (type == '@') {
                conditionType = 'entity'
                conditionValue = condition.slice(1, condition.length - 8)
            } else if (type == '#') {
                conditionType = 'intent'
                conditionValue =
                    condition.slice(condition.length - 8, condition.length) == '.literal'
                        ? condition.slice(1, condition.length - 8)
                        : condition.slice(1, condition.length)
            }
        }
        this.setState({
            condition: conditionValue,
            variable: variable.slice(0, 1) == '$' ? variable.slice(1, variable.length) : variable,
            prompt: prompt,
            conditionType: conditionType,
            mode: dialog_node == '' ? 'edit' : 'view'
        })
        if (dialog_node == '') {
            this.props.changeEditMode(true)
        }
    }

    conditionOnChange = (value: string) => {
        this.setState({ condition: value })
    }
    variableOnChange = (value: string) => {
        this.setState({ variable: value })
    }
    promptOnChange = (value: string) => {
        this.setState({ prompt: value })
    }

    save = async () => {
        const { dialog_node, previous_sibling, parent, type } = this.props.element
        const { prompt, condition, variable, conditionType } = this.state
        const obj = {
            prompt: prompt,
            condition: conditionType == 'entity' ? '@' + condition + '.literal' : '#' + condition,
            variable: variable,
            dialog_node: dialog_node,
            previous_sibling: previous_sibling,
            parent: parent,
            type: type
        }
        await this.props.save(obj)
        this.props.changeEditMode(false)
        this.setState({
            mode: 'view'
        })
    }

    cancel = () => {
        const { prompt, condition, variable } = this.props.element
        if (prompt == '' && condition == '' && variable == '') {
            this.deleteSlot()
        } else {
            let conditionType = 'entity'
            let conditionValue = condition
            if (condition != '') {
                const type = condition.slice(0, 1)
                if (type == '@') {
                    conditionType = 'entity'
                    conditionValue = condition.slice(1, condition.length - 8)
                } else if (type == '#') {
                    conditionType = 'intent'
                    conditionValue = condition.slice(1, condition.length)
                }
            }
            this.setState({
                condition: conditionValue,
                conditionType: conditionType,
                variable: variable.slice(0, 1) == '$' ? variable.slice(1, variable.length) : variable,
                prompt: prompt,
                mode: 'view'
            })
        }
        this.props.changeEditMode(false)
    }

    deleteSlot = () => {
        this.props.deleteSlot(this.props.element, this.props.index)
    }

    render() {
        const { mode, condition, variable, prompt } = this.state
        return (
            <div className={mode == 'view' ? 'slots_left_bot' : 'slots_right_bot plus_addmore_edit'}>
                <div className="row">
                    <div className="col-md-7">
                        <p>Get required details</p>
                    </div>
                    <div className="col-md-5">
                        {mode == 'view' ? (
                            <div className="right_pop_icons">
                                <span
                                    className={
                                        this.props.slots.length == 1 || this.props.isAnythingEdit == true
                                            ? 'editor_btn_disabled'
                                            : ''
                                    }
                                    onClick={() =>
                                        this.props.slots.length == 1 || this.props.isAnythingEdit == true
                                            ? null
                                            : this.deleteSlot()
                                    }
                                >
                                    <svg viewBox="0 0 14.46 15.53" xmlns="http://www.w3.org/2000/svg">
                                        <title>delete</title>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <path d="M13.91,2.34H11V2.15A2.14,2.14,0,0,0,8.85,0H5.61A2.13,2.13,0,0,0,3.48,2a.41.41,0,0,0,0,.11v.19H.55a.56.56,0,0,0,0,1.11h1.2v9.94a2.14,2.14,0,0,0,2.14,2.14h6.68a2.14,2.14,0,0,0,2.14-2v-10h1.2a.56.56,0,0,0,0-1.11ZM4.58,2.17a.13.13,0,0,1,0-.06,1,1,0,0,1,1-1H8.85a1,1,0,0,1,1,1v.19H4.58Zm7,11.19v.07a1,1,0,0,1-1,1H3.89a1,1,0,0,1-1-1V3.45H11.6Z" />
                                                <path d="M4.73,12.6A.56.56,0,0,0,5.29,12v-6a.56.56,0,0,0-1.11,0v6A.56.56,0,0,0,4.73,12.6Z" />
                                                <path d="M7.23,12.6A.56.56,0,0,0,7.79,12v-6a.56.56,0,0,0-1.11,0v6A.56.56,0,0,0,7.23,12.6Z" />
                                                <path d="M9.73,12.6a.55.55,0,0,0,.55-.56v-6a.55.55,0,0,0-1.1,0v6A.55.55,0,0,0,9.73,12.6Z" />
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                                <span
                                    className={this.props.isAnythingEdit == true ? 'editor_btn_disabled' : ''}
                                    onClick={() => {
                                        if (this.props.isAnythingEdit == false) {
                                            this.setState({ mode: 'edit' })
                                            this.props.changeEditMode(true)
                                        }
                                    }}
                                >
                                    <svg viewBox="0 0 13.91 13.91" xmlns="http://www.w3.org/2000/svg">
                                        <title>Edit</title>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <path d="M11.58,1.23l1.1,1.1L5.36,9.64,4.27,8.55l7.31-7.32m0-1.23A.81.81,0,0,0,11,.24L2.89,8.36a.26.26,0,0,0,0,.38L5.17,11a.27.27,0,0,0,.38,0L13.67,2.9a.81.81,0,0,0,0-1.15L12.16.24A.82.82,0,0,0,11.58,0Z" />
                                                <path d="M2.1,11.14l.67.67c-.42.2-.82.37-1.19.52.15-.37.32-.77.52-1.19M1.9,9.51a.4.4,0,0,0-.36.23A33.84,33.84,0,0,0,.08,13.12c-.19.49,0,.79.34.79a1,1,0,0,0,.37-.08,33.84,33.84,0,0,0,3.38-1.46.37.37,0,0,0,.14-.59L2.13,9.6a.32.32,0,0,0-.23-.09Z" />
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        ) : (
                            <div className="right_pop_icons">
                                <span
                                    className={
                                        condition == '' || variable == '' || prompt == '' ? 'editor_btn_disabled' : ''
                                    }
                                    onClick={() =>
                                        condition == '' || variable == '' || prompt == '' ? null : this.save()
                                    }
                                >
                                    <svg
                                        viewBox="0 0 448 512"
                                        className="svg-inline--fa fa-check fa-w-14 fa-2x"
                                        version="1.1"
                                    >
                                        <path d="M413.505 91.951L133.49 371.966l-98.995-98.995c-4.686-4.686-12.284-4.686-16.971 0L6.211 284.284c-4.686 4.686-4.686 12.284 0 16.971l118.794 118.794c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-11.314-11.314c-4.686-4.686-12.284-4.686-16.97 0z" />
                                    </svg>
                                </span>
                                <span
                                    className={this.props.slots.length == 1 ? 'editor_btn_disabled' : ''}
                                    onClick={() => (this.props.slots.length == 1 ? null : this.cancel())}
                                >
                                    <CloseIcon />
                                </span>
                            </div>
                        )}
                    </div>
                </div>

                <div className="if_bot_wdt">
                    <div className="if_bot">
                        <div className="if_dropdown">
                            <h6>{this.state.conditionType == 'entity' ? 'Entity' : 'Intent'}</h6>

                            <DropDown
                                isOpen={this.state.isPopupOpen == true}
                                onOuterClick={() =>
                                    this.setState({
                                        isPopupOpen: false
                                    })
                                }
                            >
                                <div className="dropdown_right intent_entity_details">
                                    <ul>
                                        <li
                                            onClick={() =>
                                                this.setState({
                                                    isPopupOpen: false,
                                                    conditionType: 'intent',
                                                    condition:
                                                        this.state.conditionType == 'entity' ? '' : this.state.condition
                                                })
                                            }
                                        >
                                            Intent
                                        </li>
                                        <li
                                            onClick={() =>
                                                this.setState({
                                                    isPopupOpen: false,
                                                    conditionType: 'entity',
                                                    condition:
                                                        this.state.conditionType == 'entity' ? this.state.condition : ''
                                                })
                                            }
                                        >
                                            Entity
                                        </li>
                                    </ul>
                                </div>
                            </DropDown>
                        </div>
                    </div>

                    {this.state.conditionType == 'entity' ? (
                        <Chooser
                            data={this.props.entities.map(e => e.entity)}
                            onChange={this.conditionOnChange}
                            value={this.state.condition}
                            type={'entity'}
                        />
                    ) : (
                        <Chooser
                            data={this.props.intents.map(e => e.intent)}
                            value={this.state.condition}
                            onChange={this.conditionOnChange}
                            type={'intent'}
                        />
                    )}
                </div>

                <div className="if_right_dropdown dropdown_entity flows_edit_input">
                    <span className="slots_spaname">Save as:</span>
                    <div className="if_dropdown">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Variable Name"
                            value={this.state.variable}
                            onKeyDown={e =>
                                this.setState({
                                    keycode: e.keyCode,
                                    metacode: e.metaKey || e.ctrlKey ? true : false
                                })
                            }
                            onChange={e => {
                                if (this.state.keycode != 32 && this.state.metacode == false) {
                                    this.variableOnChange(e.target.value)
                                }
                            }}
                        />
                    </div>
                </div>

                <div className="if_bot_wdt right_side_bot">
                    <span className="slots_spaname">
                        Message to display to the user if the chatbot can't detect any relevant data
                    </span>
                    <div className="if_bot">
                        <div className="if_right_dropdown dropdown_entity">
                            <div className="if_dropdown slots_bottom">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Type here"
                                    value={this.state.prompt}
                                    onChange={e => this.promptOnChange(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

type DropDownP = {
    data: any[]
    onChange: Function
    value: string
    type: 'intent' | 'entity'
}

type DropDownS = {
    isPopUpOpen: Boolean
    searchKey: string
    keycode: number
    metacode: boolean
}

class Chooser extends React.Component<DropDownP, DropDownS> {
    search: any
    state: DropDownS = {
        isPopUpOpen: false,
        searchKey: '',
        keycode: 13,
        metacode: false
    }

    render() {
        const { data, onChange, value, type } = this.props
        const { searchKey, isPopUpOpen } = this.state
        return (
            <div className="if_right_dropdown dropdown_entity">
                <div className="if_dropdown">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={type == 'entity' ? 'Select Entity' : 'Select Intent'}
                        onClick={() =>
                            this.setState(
                                {
                                    isPopUpOpen: true,
                                    searchKey: value
                                },
                                () => this.search.focus()
                            )
                        }
                        value={value}
                    />

                    <DropDown
                        isOpen={isPopUpOpen == true}
                        onOuterClick={() =>
                            this.setState({
                                isPopUpOpen: false,
                                searchKey: ''
                            })
                        }
                    >
                        <div className="dropdown_right_entity">
                            <label className="form-input">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={type == 'entity' ? 'Entity name' : 'Intent name'}
                                    ref={el => {
                                        this.search = el
                                    }}
                                    value={searchKey}
                                    onKeyDown={e =>
                                        this.setState({
                                            keycode: e.keyCode,
                                            metacode: e.metaKey || e.ctrlKey ? true : false
                                        })
                                    }
                                    onChange={e => {
                                        if (this.state.keycode != 32 && this.state.metacode == false) {
                                            this.setState({
                                                searchKey: e.target.value
                                            })
                                        }
                                    }}
                                />
                            </label>

                            <ul>
                                {data.filter(e => e.toLowerCase().includes(searchKey.toLowerCase())).length == 0 ? (
                                    <li className="color-li">
                                        {type == 'entity' ? 'No Entity available' : 'No Intent available'}
                                    </li>
                                ) : null}
                                {data
                                    .filter(e => e.toLowerCase().includes(searchKey.toLowerCase()))
                                    .map((e: any, index: number) => (
                                        <li
                                            key={index}
                                            onClick={() => {
                                                onChange(e)
                                                this.setState({
                                                    isPopUpOpen: false,
                                                    searchKey: ''
                                                })
                                            }}
                                        >
                                            {e}
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </DropDown>
                </div>
            </div>
        )
    }
}
