/*eslint-disable*/
import Downshift from 'downshift';
import * as React from 'react';
import { List } from '@/common/components/List';
import { ListSelectBox } from '@/common/components/SelectBox';
import { getAssistants } from '@/Assistant/Mechanisms/action';
import { makeEventEntry } from '@/Journey/JourneyMachine';
import { VideoButtonB } from '@/Videos/Videos';
import {
  ArrowIcon,
  DeleteIcon,
  DownArrowCircle,
  EditIconCircle,
  HelpIcon,
  TeamsIcon,
  EditIcon,
  InfoIcon,
  QaIconWithCircle,
  MinusIcon,
  AddIcon,
  CloseIcon,
} from '@/common/Icons/Icons';
import { LoadingSvgImage } from '@/common/Icons/LoadingSvgImage';
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons';
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary';
import { Header, HeaderChild } from '@/common/components/Header';
import { Loader } from '@/common/components/Loader';
import { Modal } from '@/common/components/Modal';
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb';
import styled from 'styled-components/macro';
import Tooltip from 'react-tooltip-lite';
import { HeaderRight } from '@/common/styled/FaqPopup.Dumb';
import { VideoLink } from '@/Videos/Videos';
import * as R from 'ramda';

import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@/common/styled/Workflow.Analytics.Dumb';
import { formatDateInCard, formatAMPM } from '@/common/utils/utils';
import {
  postJson,
  teamsBackendIntegrationGenerateUrl,
  teamsIntegrationUrl,
  teamsIntegrationGetListUrl,
  getJson,
} from '@/common/utils/api-utils';
import {
  Action,
  BotDataT,
  DeleteT,
  EditT,
  ErrorPage,
  LineDeleteT,
  Loading,
  Modes,
  NoDataFound,
  TeamsWorkspaceT,
  UnauthorizedPage,
  View,
} from './types';
import {
  EmptyDiv,
  HeaderLeft,
  Paragraph,
  PopupContainer,
  PopupScrollHeight,
  PopupWrapper,
  SmallButton,
  Wrapper,
  Header as PopupHeader,
  Input,
  InputContainer,
  InputWrapper,
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb';
import { valueTypeT } from '@/DialogEditor/Entity/types';
import { usePermissions } from '@/common/utils/auth-token-api-utils';

export const Textarea = styled.textarea.attrs((boolean: any) => ({
  required: true,
}))`
  width: 100%;
  float: left;
  padding: 24px 12px 8px 12px;
  border: 1px solid #b9b9b9;
  border-radius: 6px;
  background: transparent;
  min-height: 84px;
  font-size: 12px;
  font-family: 'Gordita-Regular';
  color: #000000;
  outline: 0;
  word-break: break-word;
  resize: none;
  cursor: auto;


  :hover {
    outline: 0;
    border: 1px solid #eb974e;
    box-shadow: 0px 3px 6px #00000029;
  }

  :active {
    outline: 0;
    border: 1px solid #eb974e;
    box-shadow: none;
  }
`;
export const InputText = styled.textarea.attrs((boolean: any) => ({
  required: true,
}))`
  width: 100%;
  float: left;
  padding: 24px 12px 0px 12px;
  border: 1px solid #b9b9b9;
  border-radius: 6px;
  background: #fff;
  height: 48px;
  font-size: 12px;
  font-family: 'Gordita-Regular';
  color: #000000;
  outline: 0;
  word-break: break-word;
  resize: none;
  cursor: auto;

  :hover {
    outline: 0;
    border: 1px solid #eb974e;
    box-shadow: 0px 3px 6px #00000029;
  }

  :active {
    outline: 0;
    border: 1px solid #eb974e;
    box-shadow: none;
  }
`;
export const InputContent = styled.div`
  color: #999;
  font-size: 12px;
  font-family: 'Gordita-Regular';
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 10px;
  transition: 0.26s ease-in;
  ${Input}:focus ~ &,
    ${Input}:not(:placeholder-shown).input:not(:focus) ~ .label,
    ${Input}:valid ~ & {
    top: 10px;
    font-size: 10px;
    transition: 0.26s ease-out;
    line-height: 10px;
  }
  ${Textarea}:focus ~ &,
    ${Textarea}:not(:placeholder-shown).input:not(:focus) ~ .label,
    ${Textarea}:valid ~ & {
    top: 1px;
    font-size: 10px;
    transition: 0.26s ease-out;
    line-height: 10px;
    background: transparent;
    width: 95%;
    text-align: left;
    height: 24px;
    padding-top: 8px;
  }
  ${InputText}:focus ~ &,
    ${InputText}:not(:placeholder-shown).input:not(:focus) ~ .label,
    ${InputText}:valid ~ & {
    top: 1px;
    font-size: 10px;
    transition: 0.26s ease-out;
    line-height: 10px;
    background: transparent;
    width: 95%;
    text-align: left;
    height: 24px;
    padding-top: 8px;
  }
`;

const TabelWrapper = styled.div`
  width: 100%;
  float: left;
  .list_flow_hr label {
    float: left;
    display: flex;
    position: absolute;
    top: 18px;
    width: 16px;
    height: 16px;
    margin-bottom: 0px;
    left: -22px;
  }
  .option-input.radio {
    top: -2px;
    position: absolute;
  }

  ${Table} {
    margin-top: 0px;
  }
  ${Thead} {
    ${Tr} {
      padding-left: 32px;
      padding-right: 20px;

      ${Th} {
        :last-child {
          justify-content: flex-end;
        }
      }
    }
  }
  ${Tbody} {
    border-radius: 0px 0px 10px 10px;
    max-height: calc(100vh - 84px);
    height: auto;
    ${Tr} {
      height: 48px;
      padding-left: 32px;
      padding-right: 20px;
      .list_flow_span .entity_span {
        :first-child {
          margin-right: 3px;
          color: #000;
          font-size: 11px;
        }
        :last-child {
          margin-left: 3px;
          color: #000;
          font-size: 11px;
        }
      }
      :hover {
        ${Td}:last-child {
          display: flex;
        }
      }

      ${Td} {
        padding: 0px;
        height: 48px;
        font-size: 12px;
        font-family: 'Gordita-Regular';
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        color: #000;
        span {
          margin-right: 0px;
        }

        .list_flow_span_example span {
          font-size: 12px;
          font-family: 'Gordita-Regular';
          color: #000;

          span {
            color: #f07f31;
          }
        }
        :nth-child(1) {
          display: flex;
          padding-right: 20px;
          cursor: pointer;
          position: relative;
        }

        :nth-child(2) {
          display: flex;
          padding-right: 20px;
        }
        :last-child {
          display: none;
        }
      }
    }
  }
`;
const initialWorkspaceData = {
  app_id: '',
  tenant: '',
  workspace: '',
  app_password: '',
  bot_data: {
    bot_full_desc: '',
    bot_full_name: '',
    bot_name: '',
    bot_short_desc: '',
    is_active: false,
    is_generated: false,
  },
  bot_id: '',
  is_authorized: false,
  teams_deploy: null,
  tenant_type: 'multi_tenant',
  is_org_wide: false,
} as TeamsWorkspaceT;
let Parser = require('html-react-parser');

type IntegrateS = {
  PageState: Modes;
  workspaces: TeamsWorkspaceT[];
  disableButton: boolean;
  PageError: any;
  bots: any[];
  addMode: boolean;
  openBotDropDown: boolean;
  openWideDropDown: boolean;
  openTenantDropDown: boolean;
  editIndex: number | null;
  tokens: {
    workspace: string;
    assistance_id: string;
    app_id: string;
    tenant: string;
  };
  currentTeamCredentials: {
    app_id: string;
    app_password: string;
    tenant: string;
    tenant_type: 'multi_tenant' | 'single_tenant';
    is_org_wide: boolean;
    name: string;
    description: string;
  };
  botDetails: {
    name: string;
    fullName: string;
    desc: string;
    fullDesc: string;
  };
  redirectionClipBoard: string | null;
  endpointClipBoard: string | null;
  popUp: {
    error: null | string;
    loader: boolean;
    loaderIndex: number | null;
    errorIndex: number | null;
  };
  editAppId: string;
  editBotIndex: number | null;
  showDeleteModal: boolean;
  popup: {
    showPopupLoader: boolean;
    modalError: { error: boolean; info: null | string };
  };
  currentEditingTeamCredentials: TeamsWorkspaceT;
  selectedWorkspace: TeamsWorkspaceT[] | [];
  clonedWorkspace: TeamsWorkspaceT[];
  name: string;
  description: string;
  showDetails: boolean;
  showAppDetails: boolean;
  showWorkspaceDetails: boolean;
  showChatDetails: boolean;
  showAddButton:boolean;
  // selectedBotData: any;
};
export class TeamsIntegration extends React.Component<any, IntegrateS> {
  newWindow: any = null;
  timer: any;
  workspaceName: string = this.props['match'].params.workspacename;
  inputRef: any;
  state: IntegrateS = {
    PageState: Loading,
    workspaces: [],
    disableButton: false,
    addMode: false,
    bots: [],
    PageError: {},
    openBotDropDown: false,
    openTenantDropDown: false,
    openWideDropDown: false,
    currentTeamCredentials: {
      app_id: '',
      app_password: '',
      tenant: '',
      tenant_type: 'multi_tenant',
      is_org_wide: false,
      name: '',
      description: '',
    },
    editIndex: null,
    botDetails: { name: '', fullName: '', desc: '', fullDesc: '' },
    tokens: {
      workspace: this.props['match'].params.workspacename,
      assistance_id: '',
      app_id: '',
      tenant: '',
    },
    redirectionClipBoard: null,
    endpointClipBoard: null,
    popUp: { error: null, loader: false, loaderIndex: null, errorIndex: null },
    editAppId: '',
    editBotIndex: null,
    showDeleteModal: false,
    popup: { showPopupLoader: false, modalError: { error: false, info: null } },
    currentEditingTeamCredentials: initialWorkspaceData,
    selectedWorkspace: [],
    clonedWorkspace: [],
    name: '',
    description: '',
    showDetails: true,
    showAppDetails: true,
    showWorkspaceDetails: true,
    showChatDetails: true,
    showAddButton: this.props['permission'] as boolean,
    // selectedBotData: null,
    // {bot_full_desc: "",bot_full_name: "",bot_name: "",bot_short_desc: "",is_active: false,is_generated: false}
  };

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { props } = this;

    if (props['state'].type != nextProps.state.type) {
      if (nextProps.state.type == 'loading') {
        this.setState({ PageState: Loading });
      } else if (nextProps.state.type == 'error') {
        if (
          nextProps.state.error.response &&
          nextProps.state.error.response.status == 403
        ) {
          this.setState({
            PageState: UnauthorizedPage,
          });
        } else {
          this.setState({
            PageState: ErrorPage,
            PageError: {
              error: true,
              info: 'There was a problem in loading Page! <br/> Please click on "Retry"',
            },
          });
        }
      } else if (nextProps.state.type == 'success') {
        const res: any = nextProps.state.data;
        getAssistants(this.workspaceName)
          .then((data) => {
            if (res.length > 0) {
              this.setState({
                workspaces: res.map((w: TeamsWorkspaceT) => {
                  if (w.is_authorized && !w.bot_data) {
                    return {
                      ...w,
                      bot_data: {
                        bot_name: '',
                        bot_full_name: '',
                        bot_full_desc: '',
                        bot_short_desc: '',
                        is_active: false,
                        is_generated: false,
                      },
                    };
                  }
                  return w;
                }),
                PageState: View,
                bots: data,
              });
            } else {
              this.setState({
                workspaces: res,
                PageState: NoDataFound,
                bots: data,
              });
            }
          })
          .catch(() =>
            this.setState({
              PageState: ErrorPage,
              PageError: {
                error: true,
                info: 'There was a problem in loading Page! <br/> Please click on "Retry"',
              },
            })
          );
      }
    }
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    const { workspaces } = this.state;
    if (this.state.addMode && !this.state.popUp.error) {
      const { app_id, tenant } = this.state.currentTeamCredentials;
      const { assistance_id } = this.state.tokens;

      const isAppAlreadyExists = workspaces.filter(
        (w) => assistance_id == w.bot_id && w.app_id == app_id
      );
      isAppAlreadyExists.length > 0 &&
        this.setState({
          popUp: {
            error: 'Client ID already mapped to this Bot',
            loader: false,
            loaderIndex: null,
            errorIndex: null,
          },
        });
    } else if (this.state.editIndex && !this.state.popUp.error) {
      const { app_id, tenant, bot_id } = this.state.selectedWorkspace[0];

      const isAppAlreadyExists = workspaces.filter((w, i) => {
        // console.log(i != this.state.editIndex);
        return (
          i != this.state.editIndex && bot_id == w.bot_id && w.app_id == app_id
        );
      });

      isAppAlreadyExists.length > 0 &&
        this.setState({
          popUp: {
            error: 'Client ID already mapped to this Bot',
            loader: false,
            loaderIndex: null,
            errorIndex: this.state.editIndex,
          },
        });
    }
  }

  toggleActivation = async (data: TeamsWorkspaceT, index: number) => {
    // this.setState({
    //   popUp: {
    //     loader: true,
    //     error: null,
    //     loaderIndex: index,
    //     errorIndex: null,
    //   },
    // });

    const { app_id, app_password, tenant } = data;

    const teams_deploy = data.teams_deploy as {
      is_active: Boolean | undefined;
      team_name: string;
      team_id: string;
      added_by: string | null;
      email_id: string | null;
    };

    postJson(
      teamsIntegrationUrl(
        this.workspaceName,
        '/activate/',
        data.bot_id + '/' + !teams_deploy.is_active
      )
    )({
      app_id,
      team_id: teams_deploy.team_id,
      tenant,
    })
      .then((data) => 
        getJson(
          teamsIntegrationGetListUrl(this.workspaceName, '/credentialList/')
        )
      )
      .then((res) => {
        if (!teams_deploy.is_active) {
          makeEventEntry(this.workspaceName, {
            event: 'EVENT::CHATCHANNEL::DEPLOYED',
            refId: data.bot_id,
          });
        }
        this.setState({
          addMode: false,
          currentTeamCredentials: {
            app_id: '',
            app_password: '',
            tenant: '',
            is_org_wide: false,
            tenant_type: 'multi_tenant',
            name: '',
            description: '',
          },
          workspaces: res,
          PageState: View,
          popUp: {
            error: null,
            loader: false,
            loaderIndex: null,
            errorIndex: null,
          },
        });
      })
      .catch((err) => {
        this.setState({
          popUp: {
            error:
              err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : 'Something went wrong! Please try again after some time.',
            loader: false,
            loaderIndex: null,
            errorIndex: index,
          },
        });
      });
  };

  onSave = () => {
    const botId = this.state.bots.find(
      (e) => e.id === this.state.tokens.assistance_id
    );

    const {
      app_id,
      app_password,
      tenant,
      tenant_type,
      is_org_wide,
      name,
      description,
    } = this.state.currentTeamCredentials;

    this.setState({
      popUp: { loader: true, error: null, loaderIndex: null, errorIndex: null },
    });

    postJson(
      teamsIntegrationUrl(this.workspaceName, '/credentials/save/', botId.id)
    )({
      app_id,
      app_password,
      tenant,
      tenant_type,
      is_org_wide,
      name,
      description,
    })
      .then((data) =>
        getJson(
          teamsIntegrationGetListUrl(this.workspaceName, '/credentialList/')
        )
      )
      .then((res) => {
        this.setState({
          addMode: false,
          currentTeamCredentials: {
            app_id: '',
            app_password: '',
            tenant: '',
            tenant_type: 'multi_tenant',
            is_org_wide: false,
            name: '',
            description: '',
          },
          workspaces: res,
          PageState: View,
          popUp: {
            error: null,
            loader: false,
            loaderIndex: null,
            errorIndex: null,
          },
        });
      })
      .catch((err) => {
        this.setState({
          popUp: {
            error:
              err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : 'Something went wrong! Please try again after some time.',
            loader: false,
            loaderIndex: null,
            errorIndex: null,
          },
        });
      });
  };

  getWorkSpaces = async () => {
    // await postJson(teamsIntegrationUrl(this.workspaceName, '/tenant.list.workspace'))({
    //     cognidesk_workspace_name: this.props.match.params.workspacename
    // })
    //     .then(res => {
    //         this.setState({
    //             workspaces: res.data,
    //             PageState: res.data.length > 0 ? View : NoDataFound
    //         })
    //     })
    //     .catch(() => {
    //         this.setState({ PageState: ErrorPage })
    //     })
  };

  addBotDate = async (data: TeamsWorkspaceT, index: number) => {
    this.setState({
      popUp: {
        loader: true,
        error: null,
        loaderIndex: index,
        errorIndex: null,
      },
    });
    const bot_data = data.bot_data as BotDataT;
    const req = {
      app_id: data.app_id,
      app_password: data.app_password,
      tenant: data.tenant,
      botShortName: bot_data.bot_name,
      botFullName: bot_data.bot_full_name,
      shortDes: bot_data.bot_short_desc,
      fullDes: bot_data.bot_full_desc,
    };
    await postJson(
      teamsIntegrationUrl(
        this.workspaceName,
        '/credentials/edit/',
        data.bot_id + '/' + this.state.editAppId
      )
    )(req)
      .then((data) =>
        getJson(
          teamsIntegrationGetListUrl(this.workspaceName, '/credentialList/')
        )
      )
      .then((res) => {
        this.setState({
          addMode: false,
          currentTeamCredentials: {
            app_id: '',
            app_password: '',
            tenant: '',
            tenant_type: 'multi_tenant',
            is_org_wide: false,
            name: '',
            description: '',
          },
          editIndex: null,
          workspaces: res,
          PageState: View,
          popUp: {
            error: null,
            loader: false,
            loaderIndex: null,
            errorIndex: null,
          },
        });
      })
      .catch((err) => {
        this.setState({
          popUp: {
            error:
              err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : 'Something went wrong! Please try again after some time.',
            loader: false,
            loaderIndex: null,
            errorIndex: index,
          },
        });
      });

    // await postJson(teamsIntegrationUrl(data.workspace, '/teams/addToTeams/', data.bot_id))(req)
    //     .then(res => {

    //         const workspaces = this.state.workspaces.map((w: TeamsWorkspaceT) =>
    //             w.app_id == this.state.editAppId
    //                 ? {
    //                       ...w,
    //                       bot_data: { ...bot_data, is_active: false, is_generated: true }
    //                   }
    //                 : w
    //         ) as TeamsWorkspaceT[]

    //         this.setState({
    //             workspaces: workspaces,
    //             popUp: { error: null, loader: false, loaderIndex: null, errorIndex: null },
    //             editIndex: null,
    //             PageState: View,
    //             editBotIndex: null,
    //             editAppId:""

    //         })
    //     })
    //     .catch(err => {
    //         this.setState({
    //             popUp: {
    //                 error:
    //                     err.response && err.response.data && err.response.data.message
    //                         ? err.response.data.message
    //                         : 'Something went wrong! Please try again after some time.',
    //                 loader: false,
    //                 loaderIndex: null,
    //                 errorIndex: index
    //             }
    //         })
    //     })
  };
  disablesave = () => {
    const omittedWorkspace = R.omit(
      ['bot_data','is_authorized'],
      this.state.selectedWorkspace[0]
    );
    const omittedClonedSpace = R.omit(
      ['bot_data','is_authorized'],
      this.state.clonedWorkspace[0]
    );

    return (
      JSON.stringify(omittedWorkspace) !== JSON.stringify(omittedClonedSpace)
    );
  };
  editCRED = async (data: TeamsWorkspaceT, index: number) => {
    // console.log(data,'insideCred')
    const omittednameWorkspace =JSON.stringify(R.omit(
      ['name','description'],
      this.state.selectedWorkspace[0]
    ))
    const omittednameClonedSpace = JSON.stringify(R.omit(
      ['name','description'],
      this.state.clonedWorkspace[0]
    ))
    // console.log(omittednameWorkspace,omittednameClonedSpace,omittednameWorkspace === omittednameClonedSpace,"insideCred")

    if(omittednameWorkspace === omittednameClonedSpace ) {
      // this.setState({
      // popUp: {
      //   loader: true,
      //   error: null,
      //   loaderIndex: index,
      //   errorIndex: null,
      // },
      // });

      // data.bot_data != null ? this.addBotDate(data, index) : ""

    await postJson(
      teamsIntegrationUrl(
        this.workspaceName,
        '/credentials/edit/',
        data.bot_id + '/' + this.state.editAppId
      )
    )({
      app_id: data.app_id,
      app_password: data.app_password,
      tenant: data.tenant,
      tenant_type: data.tenant_type,
      is_org_wide: data.is_org_wide,
      name: data.name,
      description: data.description,
    })
      .then((data) =>
        getJson(
          teamsIntegrationGetListUrl(this.workspaceName, '/credentialList/')
        )
      )
      .then((res) => {
        const updatedWorkspace = res.filter((workspace:TeamsWorkspaceT)=> workspace.app_id === this.state.editAppId)
        // console.log(updatedWorkspace,'updatedWorkspace')
        this.setState({
          addMode: false,
          currentTeamCredentials: {
            app_id: '',
            app_password: '',
            tenant: '',
            tenant_type: 'multi_tenant',
            is_org_wide: false,
            name: '',
            description: '',
          },
          selectedWorkspace:JSON.parse(JSON.stringify(updatedWorkspace)),
          clonedWorkspace:JSON.parse(JSON.stringify(updatedWorkspace)),
          editIndex: null,
          workspaces: res,
          // PageState: View,
          popUp: {
            error: null,
            loader: false,
            loaderIndex: null,
            errorIndex: null,
          },
        });
      })
      .catch((err) => {
        this.setState({
          popUp: {
            error:
              err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : 'Something went wrong! Please try again after some time.',
            loader: false,
            loaderIndex: null,
            errorIndex: index,
          },
        });
      });
    } else {
      await postJson(
      teamsIntegrationUrl(
        this.workspaceName,
        '/credentials/edit/',
        data.bot_id + '/' + this.state.editAppId
      )
    )({
      app_id: data.app_id,
      app_password: data.app_password,
      tenant: data.tenant,
      tenant_type: data.tenant_type,
      is_org_wide: data.is_org_wide,
      name: data.name,
      description: data.description,
      is_authorized: false,
    })
      .then((data) =>
        getJson(
          teamsIntegrationGetListUrl(this.workspaceName, '/credentialList/')
        )
      )
      .then((res) => {
        const updatedWorkspace = res.filter((workspace:TeamsWorkspaceT)=> workspace.app_id === this.state.editAppId)
        this.setState({
          addMode: false,
          currentTeamCredentials: {
            app_id: '',
            app_password: '',
            tenant: '',
            tenant_type: 'multi_tenant',
            is_org_wide: false,
            name: '',
            description: '',
          },
          selectedWorkspace:JSON.parse(JSON.stringify(updatedWorkspace)),
          clonedWorkspace:JSON.parse(JSON.stringify(updatedWorkspace)),
          editIndex: null,
          workspaces: res,
          // PageState: View,
          popUp: {
            error: null,
            loader: false,
            loaderIndex: null,
            errorIndex: null,
          },
        });
      })
      .catch((err) => {
        this.setState({
          popUp: {
            error:
              err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : 'Something went wrong! Please try again after some time.',
            loader: false,
            loaderIndex: null,
            errorIndex: index,
          },
        });
      });
    }
  };

  addToTeams = async (data: TeamsWorkspaceT, index: number) => {
    this.setState({
      popUp: {
        loader: true,
        error: null,
        loaderIndex: index,
        errorIndex: null,
      },
    });
    // console.log(data.bot_data, 'botdata');
    const bot_data = data.bot_data as BotDataT;

    const req = {
      app_id: data.app_id,
      app_password: data.app_password,
      tenant: data.tenant,
      botShortName: bot_data.bot_name,
      botFullName: bot_data.bot_full_name,
      shortDes: bot_data.bot_short_desc,
      fullDes: bot_data.bot_full_desc,
    };
    // console.log(req, 'botdata');

    await postJson(
      teamsIntegrationUrl(data.workspace, '/teams/addToTeams/', data.bot_id)
    )(req)
      .then((res) => {
        window.open(
          window.location.origin +
            process.env.REACT_APP_TEAMS_INTEGRATION_URL +
            '/' +
            res.data.file_path,
          '_blank'
        );

        const workspaces = this.state.workspaces.map((w: TeamsWorkspaceT) =>
          w.app_id == this.state.editAppId
            ? {
                ...w,
                bot_data: { ...bot_data, is_active: false, is_generated: true },
              }
            : w
        ) as TeamsWorkspaceT[];

        this.setState({
          workspaces: workspaces,
          popUp: {
            error: null,
            loader: false,
            loaderIndex: null,
            errorIndex: null,
          },
          editIndex: null,
          PageState: View,
          editBotIndex: null,
          editAppId: '',
        });
      })
      .catch((err) => {
        this.setState({
          popUp: {
            error:
              err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : 'Something went wrong! Please try again after some time.',
            loader: false,
            loaderIndex: null,
            errorIndex: index,
          },
        });
      });
  };

 authorizeTeams = async (
    data: TeamsWorkspaceT,
    redirectUrl: string,
    index: number
  ) => {
    this.setState({
      popUp: {
        loader: true,
        error: null,
        loaderIndex: index,
        errorIndex: null,
      },
    });
    // const botValue = data.bot_data
    const req = {
      app_id: data.app_id,
      app_password: data.app_password,
      tenant: data.tenant,
      redirectUrl: redirectUrl,
      // redirectUrl: 'https://dev-assistant.workativ.ai/teams-v2/oauth/redirect/success​​​​​​​'
    };
    // const addBotData = this.state.selectedWorkspace.map(
    //   (workspace: TeamsWorkspaceT) => {
    //     return {
    //       ...workspace,
    //       bot_data: {
    //         bot_name: botValue?.bot_name ? botValue.bot_name: '',
    //         bot_full_name:botValue?.bot_full_name ? botValue.bot_full_name : '',
    //         bot_full_desc:botValue?.bot_full_desc ?botValue.bot_full_desc :  '',
    //         bot_short_desc:botValue?.bot_short_desc ? botValue.bot_short_desc : '',
    //         is_active: botValue?.is_active ? botValue.is_active: false,
    //         is_generated: botValue?.is_generated ? botValue.is_generated: false,
    //       },
    //       is_authorized: true,
    //     };
    //   }
    // );
    // console.log(addBotData, 'selected');
    await postJson(
      teamsIntegrationUrl(data.workspace, '/grantConsent/', data.bot_id)
    )(req)
      .then((res) => {
        this.setState({
          popUp: {
            loader: false,
            error: null,
            loaderIndex: null,
            errorIndex: null,
          },
        });

        var childWindow: any;

        const receiveMessage = (event: any) => {
          if (event.origin === 'http://localhost:3000') return;
          window.removeEventListener('message', receiveMessage, false);
          if (event.data == 'succeeded') {
            getJson(
              teamsIntegrationGetListUrl(this.workspaceName, '/credentialList/')
            ).then((data)=>{
            const workspaces = data.map((w: TeamsWorkspaceT) =>{
              const botValue = w.bot_data;
              if(w.app_id == this.state.editAppId){
                  return {
                      ...w,
                      bot_data: {
                        bot_name: botValue?.bot_name ? botValue.bot_name: '',
                        bot_full_name:botValue?.bot_full_name ? botValue.bot_full_name : '',
                        bot_full_desc:botValue?.bot_full_desc ?botValue.bot_full_desc :  '',
                        bot_short_desc:botValue?.bot_short_desc ? botValue.bot_short_desc : '',
                        is_active: botValue?.is_active ? botValue.is_active: false,
                        is_generated: botValue?.is_generated ? botValue.is_generated: false,
                      },
                      is_authorized: true,
                    }
                } else {
                    return w
                  }
            })

            // const addBotData = data.filter((w: TeamsWorkspaceT) =>{
            //   console.log(this.state.editAppId,'editAppId')
            //  if(w.app_id == this.state.editAppId){
            //     const botValue = w.bot_data
            //     console.log(botValue,'working')
            //     return {
            //       ...w,
            //       bot_data: {
            //         bot_name: botValue?.bot_name ? botValue.bot_name: '',
            //         bot_full_name:botValue?.bot_full_name ? botValue.bot_full_name : '',
            //         bot_full_desc:botValue?.bot_full_desc ?botValue.bot_full_desc :  '',
            //         bot_short_desc:botValue?.bot_short_desc ? botValue.bot_short_desc : '',
            //         is_active: botValue?.is_active ? botValue.is_active: false,
            //         is_generated: botValue?.is_generated ? botValue.is_generated: false,
            //       },
            //       is_authorized: true,
            //     };
            //   }}
            // );

            const addBotData = workspaces.filter(
              (workspace: TeamsWorkspaceT) => workspace.app_id == this.state.editAppId
            );
            console.log(addBotData,'addbotdata')
            this.setState({
              workspaces: workspaces,
              selectedWorkspace: JSON.parse(JSON.stringify(addBotData)),
              clonedWorkspace: JSON.parse(JSON.stringify(addBotData)),
            });
          })
          } else {
            this.setState({
              popUp: {
                error:
                  'Authorization failed due to invalid credentials/invalid redirection url',
                loader: false,
                loaderIndex: null,
                errorIndex: index,
              },
            });
          }
          childWindow.close();
        };

        childWindow = window.open(
          res.data.url,
          'authwind',
          'width=550,height=500,0,status=0,'
        );

        window.addEventListener('message', receiveMessage, false);
      })
      .catch((err) => {
        this.setState({
          popUp: {
            error:
              err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : 'Something went wrong! Please try again after some time.',
            loader: false,
            loaderIndex: null,
            errorIndex: index,
          },
        });
      });
  };
  
  copyToClipBoard = (target: string) => {
    var copyText = document.getElementById(target);

    if (copyText) {
      const el = copyText as HTMLInputElement;
      el.select();
      el.setSelectionRange(0, 99999);
      document.execCommand('copy');
      target == 'redirectionInput'
        ? this.setState({
            redirectionClipBoard: el.value,
            endpointClipBoard: null,
          })
        : this.setState({
            endpointClipBoard: el.value,
            redirectionClipBoard: null,
          });
    }
  };

  deleteWorkspace(selected: TeamsWorkspaceT[]) {
    this.setState({ popup: { ...this.state.popup, showPopupLoader: true } });
    // console.log('deletemode',this.state,selected,selected[0].assistance_id)
    postJson(
      teamsIntegrationUrl(
        this.workspaceName,
        `/teams/delete/`,
        selected[0].bot_id
      )
    )({
      app_id: selected[0].app_id,
      tenant: selected[0].tenant,
    })
      .then((data) =>
        getJson(
          teamsIntegrationGetListUrl(this.workspaceName, '/credentialList/')
        )
      )
      .then((res) => {
        this.setState({
          popup: { ...this.state.popup, showPopupLoader: false },
          showDeleteModal: false,
          editIndex: null,
          workspaces: res,
          tokens: {
            ...this.state.tokens,
            assistance_id: '',
            app_id: '',
            tenant: '',
          },
          PageState: View,
        });

        // this.state.workspaces.filter((workspace: any, i) => i != this.state.editIndex).length > 0
        //     ? this.setState({
        //           popup: { ...this.state.popup, showPopupLoader: false },
        //           showDeleteModal: false,
        //           editIndex: null,
        //           workspaces: this.state.workspaces.filter((workspace: any, i) => i != this.state.editIndex),
        //           tokens: { ...this.state.tokens, assistance_id: '', app_id: '', tenant: '' }
        //       })
        //     : this.setState({
        //           popup: { ...this.state.popup, showPopupLoader: false },
        //           showDeleteModal: false,
        //           editIndex: null,
        //           workspaces: [],
        //           tokens: { ...this.state.tokens, assistance_id: '', app_id: '', tenant: '' },
        //           PageState: NoDataFound
        //       })
      })
      .catch(() => {
        this.setState({
          popup: {
            showPopupLoader: false,
            modalError: {
              info: 'Something went wrong! Please try again.',
              error: true,
            },
          },
        });
      });
  }

  resetToViewMode = () => {
    this.setState({ PageState: View });
  };
  renderDeleteModal() {
    const { PageState, selectedWorkspace, workspaces } = this.state;

    // if (this.state.showDeleteModal) {
    if (
      PageState.action == Action.Delete ||
      PageState.action == Action.LineDelete
    ) {
      const { editAppId } = this.state;
      const selected = workspaces.filter(
        (x: TeamsWorkspaceT) => x.app_id === editAppId
      );
      // console.log(selected,'selected');

      return (
        <Modal showPopupLoader={this.state.popup.showPopupLoader}>
          <ErrorBoundary
            error={this.state.popup.modalError}
            render={(err: any, info: any) => {
              return (
                err && (
                  <Error.Delete
                    onClick={() =>
                      this.setState({
                        showDeleteModal: false,
                        editIndex: null,
                        popup: {
                          showPopupLoader: false,
                          modalError: {
                            info: null,
                            error: false,
                          },
                        },
                      })
                    }
                    info={info}
                  />
                )
              );
            }}
          >
            <React.Fragment>
              <h2>Delete {PageState.name ? PageState.name : 'Chatbot'} </h2>
              <br></br>
              <p>
                Are you sure you want to delete{' '}
                {PageState.name ? PageState.name : 'Chatbot'} integration?
              </p>{' '}
            </React.Fragment>

            <div className="d-flex">
              <span className="mr-4">
                <Button
                  className="text-center mx-auto teams-intergration-button"
                  type="button"
                  onClick={() => this.deleteWorkspace(selected)}
                >
                  Yes
                </Button>
              </span>
              <Button
                className="mx-auto intergration-delete-button-text teams_delete_no_button"
                type="button"
                onClick={() => {
                  this.resetToViewMode();
                  this.setState({
                    editAppId: '',
                    showDeleteModal: false,
                    editIndex: null,
                    tokens: {
                      ...this.state.tokens,
                      assistance_id: '',
                      app_id: '',
                      tenant: '',
                    },
                  });
                }}
              >
                No
              </Button>
            </div>
          </ErrorBoundary>
        </Modal>
      );
    }

    return null;
  }
  setInputFocus() {
    this.inputRef && this.inputRef.focus();
  }

  setDeleteMode = (value: string) => {
    let { PageState, workspaces } = this.state;
    // console.log(PageState,value,'pagestate')
    const selected = workspaces.filter(
      (x: TeamsWorkspaceT) => x.app_id === value
    );
    this.setState({ editAppId: value });
    const deleteMultiple: LineDeleteT = {
      action: Action.LineDelete,
      selectedItems:
        PageState.action == Action.Select ? PageState.selectedItems : [],
      name: '',
    };

    const deleteSingle: DeleteT = {
      action: Action.Delete,
      name: selected[0].name,
    };

    const setMode =
      PageState.action == Action.Select ? deleteMultiple : deleteSingle;

    this.setState({ PageState: setMode });
  };
  isSelected = (id: string) => {
    const { PageState } = this.state;
    if (
      PageState.action === Action.Select ||
      PageState.action === Action.LineDelete
    ) {
      return PageState.selectedItems.includes(id);
    }
    return false;
  };
  selectTeam = (team: string) => {
    const modes = this.state.PageState;

    switch (modes.action) {
      case Action.Select:
        const selected: string[] = modes.selectedItems.includes(team)
          ? modes.selectedItems.filter((x: string) => x !== team)
          : [...modes.selectedItems, team];

        if (selected.length) {
          this.setState({
            PageState: {
              ...modes,
              action: Action.Select,
              selectedItems: selected,
            },
          });
        } else {
          this.setState({ PageState: View });
        }
        break;

      default:
        const newSelected = [team];
        this.setState({
          PageState: {
            ...modes,
            action: Action.Select,
            selectedItems: newSelected,
          },
        });
        break;
    }
  };

  setToEditMode = (value: string) => {
    let { workspaces } = this.state;
    const selected: TeamsWorkspaceT[] = workspaces.filter(
      (x: TeamsWorkspaceT) => x.app_id === value
    );
    const action: EditT = {
      action: Action.Edit,
      name: selected[0].name,
      description: selected[0].description,
    };
    const findIndex: number = workspaces.findIndex(
      (x) => x.app_id === selected[0].app_id
    );
    // console.log(selected,'selected')
    this.setState(
      {
        PageState: action,
        selectedWorkspace: JSON.parse(JSON.stringify(selected)),
        clonedWorkspace: JSON.parse(JSON.stringify(selected)),
        editAppId: selected[0].app_id,
        editBotIndex: findIndex,
        // selectedBotData: [R.clone(selected[0].bot_data)],
      },
      () => this.setInputFocus()
    );
  };

  render() {
    const { workspaces, PageState, searching } = this.state;
    // console.log(this.state.selectedWorkspace,this.state.clonedWorkspace,'workspacevalues')
    // const botId = this.state.bots.find((e) => e.id === this.state.bot_Id);
    // const botData = this.state.currentEditingTeamCredentials.bot_data as any
    // const botData = this.state.selectedBotData as any;

    if (PageState.action === Action.Loading)
      return <Loader.PageLoader show={true} />;

    return (
      <React.Fragment>
        <Header>
          {[
            <HeaderChild type="first" key={0}>
              <div className="indent_left">
                <div className="indent_title">
                  {/* <span
                        className="buton_back inner_pages"
                        onClick={() => window.history.back()}
                      >
                        <ArrowIcon />
                        <span>Back</span>
                      </span> */}
                  <div className="indent_title">MS Teams Chatbot</div>
                  <div className="indent_sub_title">
                  Catalog (
                    {this.state.workspaces.length == 0
                      ? '0'
                      : this.state.workspaces.length < 10
                      ? '0' + this.state.workspaces.length
                      : this.state.workspaces.length}
                    )
                  </div>
                  {/* <span style={{ position: 'relative', top: '4px' }}>
                        MS Teams Chatbot
                        <span className="count_no_cc">
                          (
                          {this.state.workspaces.length == 0
                            ? '0'
                            : this.state.workspaces.length < 10
                            ? '0' + this.state.workspaces.length
                            : this.state.workspaces.length}
                          )
                        </span>
                      </span> */}
                </div>
                <div className="indent_sub_title" />
              </div>
              <>
                {/* {PageState.action === Action.View &&
                    this.state.workspaces.length > 0 &&
                    this.state.editIndex == null &&
                    this.state.editBotIndex == null ? ( */}
                {/* {this.state.workspaces.length > 0 ? ( */}
                {this.state.showAddButton && <Button
                  primary
                  onClick={() =>
                    this.setState({ addMode: true, PageState: View })
                  }
                >
                  <span className="add_teams_header">
                    <TeamsIcon />
                  </span>
                  <label style={{ top: '1.5px' }}>Add to Teams</label>
                </Button>}
                {/* // ) : null} */}
              </>
            </HeaderChild>,
            <HeaderChild type="second">
              <VideoButtonB id={'DEPLOY_BOT_IN_MS_TEAMS'} />
            </HeaderChild>,
          ]}
        </Header>

        <section className="slack_buton">
          <div className="col-md-12 col-lg-12 col-sm-12 col-12 slack_right_btn">
            <div className="slack_buton_box slack_inter_box">
              <ErrorBoundary
                unauthorized={PageState.action === Action.Unauthorized}
                noDataFound={{
                  show: PageState.action === Action.NoDataFound,
                  noDatainfo:
                    '<p>Extend your chatbot experience on MS teams.<br/>Deploy your chatbot on your MS Teams easily with simple steps. Click "add to teams" to get started. </p>',
                  onClick: () => {},
                  ButtonElement: (
                    this.state.showAddButton?<Button
                      onClick={() =>
                        this.setState({ addMode: true, PageState: View })
                      }
                    >
                      <span
                        className="add_teams"
                        style={{ width: 18, height: 18 }}
                      >
                        <TeamsIcon />
                      </span>
                      <label style={{ color: '#333' }}>Add to teams</label>
                    </Button> : null
                  ),
                }}
                error={
                  PageState.action !== Action.ErrorPage
                    ? { error: null, info: null }
                    : {
                        error: true,
                        info: 'Server error!<br />Please try again after sometime.',
                      }
                }
                render={(err: any, info: any) => {
                  return (
                    err && (
                      <Error.PageLoadingError
                        onClick={() => {
                          this.props['retry']();
                        }}
                        info={info}
                        btnName="Retry"
                      />
                    )
                  );
                }}
              >
                <React.Fragment>
                  <TabelWrapper>
                    <TableContainer>
                      <Table>
                        <Thead>
                          <Tr>
                            <Th size="3">Name</Th>
                            <Th size="3">Description</Th>
                            <Th size="2.5">Status</Th>
                            <Th size="2.5">Date</Th>
                            <Th size="1">Options</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {this.state.workspaces.map(
                            (data: TeamsWorkspaceT, i: number) => {
                              let label = searching
                                ? `<h5 style='padding-top:${
                                    PageState.action == Action.Select ? 18 : 0
                                  }px'>` +
                                  data.name +
                                  '</h5>'
                                : `<h5 style='padding-top:${
                                    PageState.action == Action.Select ? 18 : 0
                                  }px'>` +
                                  data.name +
                                  '</h5>';
                              return (
                                <ReturnList data={data} selectTeam={this.selectTeam} editAppId={this.state.editAppId} i={i} isSelected={this.isSelected} label={label} setDeleteMode={this.setDeleteMode} setToEditMode={this.setToEditMode} state={this.state} workspacename={this.workspaceName} key={i} showAddButton={(show:boolean)=>this.setState({showAddButton:show})}/>
                                );
                            }
                          )}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </TabelWrapper>
                </React.Fragment>

                <div className="slack_buton_box slack_inter_box">
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-12 slack_buton_12">
                      {this.state.addMode && (
                        <Wrapper className="create_dialog_popup">
                          <PopupWrapper>
                            <PopupHeader>
                              <HeaderLeft minWidth={400}>
                                Chatbot deployment
                                <Tooltip
                                  className="target customTip"
                                  zIndex={10000}
                                  arrowSize={8}
                                  tagName="span"
                                  content={<DilogTips />}
                                  distance={5}
                                >
                                  <InfoIcon />
                                </Tooltip>
                                <a
                                  href="https://help.workativ.com/knowledgebase/microsoft-teams/"
                                  target="_blank"
                                >
                                  <Tooltip
                                    className="target customTip"
                                    zIndex={10000}
                                    arrowSize={8}
                                    tagName="span"
                                    content="View help document"
                                    distance={5}
                                  >
                                    <QaIconWithCircle />
                                  </Tooltip>
                                </a>
                              </HeaderLeft>
                              <HeaderRight>
                                <VideoLink
                                  // id={'SET_CONDITIONS_FOR_A_DIALOG'}
                                  text={'How to create'}
                                />
                              </HeaderRight>
                            </PopupHeader>
                            <Paragraph>Deploy chatbot in MS Teams.</Paragraph>
                            {/* <div className="box_slack"> */}
                            <div className="row">
                              <div className="slack_buton_box_12 cc_header_icons">
                                {/* <h4>Configure chatbot in MS Teams</h4>
                                <div className="item_icons_right">
                                  <div className="help_link_icon">
                                    <a
                                      href="https://help.workativ.com/knowledgebase/microsoft-teams/"
                                      target="_blank"
                                    >
                                      <div className="item help_tab">
                                        <div className="img">
                                          <HelpIcon />
                                        </div>
                                        <span className="side_menu_hvr">
                                          Help
                                        </span>
                                      </div>
                                    </a>
                                  </div>
                                </div> */}
                              </div>
                              <div className="m_h_cc_container">
                                <div className="bg_slack_cc bg_slack_cc_one ">
                                  <div className="slack_buton_box_12">
                                    <InputContainer className="dialog_name_alert_content">
                                      <div>
                                        <Input
                                          type="text"
                                          name="name"
                                          value={
                                            this.state.bots.find(
                                              (e) =>
                                                e.id ===
                                                this.state.tokens.assistance_id
                                            )
                                              ? this.state.bots.find(
                                                  (e) =>
                                                    e.id ===
                                                    this.state.tokens
                                                      .assistance_id
                                                ).name
                                              : 'Select Chatbot'
                                          }
                                          onClick={() =>
                                            this.setState({
                                              openBotDropDown: true,
                                            })
                                          }
                                        />
                                        <div className="arrow_up_form">
                                          <DownArrowCircle />
                                        </div>
                                      </div>
                                      <InputContent
                                        style={{ top: 10, fontSize: 10 }}
                                      >
                                        Select your chatbot to deploy
                                      </InputContent>

                                      {/* <div className="slack_buton_8">
                                      <p>Select Chatbot</p>
                                    </div> */}
                                      {/* {this.state.openBotDropDown === false ? ( */}
                                      {/* <div
                                      className="slack_buton_4 "
                                      onClick={() =>
                                        this.setState({
                                          openBotDropDown: true,
                                        })
                                      }
                                    >
                                      <h6 className="input_integration">
                                        {botId ? botId.name : 'Select Chatbot'}
                                      </h6>
                                      <div className="arrow_up_form">
                                        <DownArrowCircle />
                                      </div>
                                    </div> */}
                                      {/* ) : */}

                                      {this.state.openBotDropDown && (
                                        <Downshift
                                          isOpen={true}
                                          onOuterClick={() => {
                                            this.setState({
                                              openBotDropDown: false,
                                            });
                                          }}
                                        >
                                          {() => (
                                            <div className="slack_buton_4 integration_dropdown">
                                              <ul>
                                                {this.state.bots.filter(
                                                  (b) => b.published_at != null
                                                ).length > 0 ? (
                                                  this.state.bots
                                                    .filter(
                                                      (b) =>
                                                        b.published_at != null
                                                    )
                                                    .map((data, index) => (
                                                      <li
                                                        key={index}
                                                        onClick={() => {
                                                          this.setState({
                                                            openBotDropDown:
                                                              false,
                                                            tokens: {
                                                              ...this.state
                                                                .tokens,
                                                              assistance_id:
                                                                data.id,
                                                            },
                                                          });
                                                        }}
                                                      >
                                                        {data.name}
                                                      </li>
                                                    ))
                                                ) : (
                                                  <li>No bots available</li>
                                                )}
                                              </ul>
                                            </div>
                                          )}
                                        </Downshift>
                                      )}
                                    </InputContainer>
                                    <InputContainer className="dialog_name_alert_content">
                                      <Input
                                        type="text"
                                        name="name"
                                        value={
                                          this.state.currentTeamCredentials.name
                                        }
                                        onChange={(event) =>
                                          this.setState({
                                            currentTeamCredentials: {
                                              ...this.state
                                                .currentTeamCredentials,
                                              name: event.target.value,
                                            },
                                          })
                                        }
                                      />
                                      <InputContent>Name</InputContent>
                                    </InputContainer>
                                    <InputContainer className="dialog_name_alert_content">
                                      <Input
                                        type="text"
                                        name="Description"
                                        value={
                                          this.state.currentTeamCredentials
                                            .description
                                        }
                                        onChange={(event) =>
                                          this.setState({
                                            currentTeamCredentials: {
                                              ...this.state
                                                .currentTeamCredentials,
                                              description: event.target.value,
                                            },
                                          })
                                        }
                                      />
                                      <InputContent>Description</InputContent>
                                    </InputContainer>
                                  </div>
                                </div>
                                <div className="bg_slack_cc bg_slack_cc_two">
                                  <div className="chat_channel_subTitle">
                                    <h4>
                                      Add following details in your Teams app
                                    </h4>
                                    {this.state.showDetails ? (
                                      <div
                                        className="img"
                                        onClick={() => {
                                          this.setState({
                                            showDetails: false,
                                          });
                                        }}
                                      >
                                        <MinusIcon />
                                      </div>
                                    ) : (
                                      <div
                                        className="img"
                                        onClick={() => {
                                          this.setState({
                                            showDetails: true,
                                          });
                                        }}
                                      >
                                        <AddIcon />
                                      </div>
                                    )}
                                  </div>
                                  {this.state.showDetails && (
                                    <>
                                      <InputContainer className="dialog_name_alert_content bg-grey">
                                        <Textarea
                                          disabled
                                          type="text"
                                          name="redirectionURL"
                                          value={
                                            window.location.origin +
                                            process.env
                                              .REACT_APP_TEAMS_EXTERNAL_ROUTE +
                                            '/' +
                                            'oauth/redirect/success' +
                                            '/' +
                                            this.workspaceName +
                                            '/' +
                                            this.state.tokens.assistance_id +
                                            '/' +
                                            this.state.currentTeamCredentials
                                              .app_id
                                          }
                                        />
                                        <InputContent>
                                          Redirection URL :
                                        </InputContent>
                                      </InputContainer>

                                      <InputContainer className="dialog_name_alert_content bg-grey" style={{marginBottom: 0}}>
                                        <Textarea
                                          type="text"
                                          disabled
                                          name="messageEndpoint"
                                          value={
                                            window.location.origin +
                                            teamsBackendIntegrationGenerateUrl(
                                              this.workspaceName,
                                              this.state.tokens.assistance_id
                                            )
                                          }
                                        />
                                        <InputContent>
                                          Message Endpoint :
                                        </InputContent>
                                      </InputContainer>
                                      {/* <div className="slack_buton_box_12">
                                    <div className="slack_buton_8">
                                      <p>Message Endpoint :</p>
                                    </div>
                                    <div className="slack_buton_4">
                                      <input
                                        className={
                                          botId && botId.id.length
                                            ? 'content_slack_input'
                                            : 'content_slack_input editor_btn_disabled'
                                        }
                                        value={
                                          botId
                                            ? window.location.origin +
                                              teamsBackendIntegrationGenerateUrl(
                                                this.workspaceName,
                                                botId.id
                                              )
                                            : ''
                                        }
                                        onChange={() => {}}
                                        id="endpointInput"
                                        disabled
                                      /> */}
                                      {/* <span
                                                                            onClick={() =>
                                                                                this.copyToClipBoard('endpointInput')
                                                                            }
                                                                        >
                                                                            {this.state.endpointClipBoard
                                                                                ? 'copied'
                                                                                : 'copy'}
                                                                        </span> */}
                                      {/* </div> */}
                                      {/* </div> */}
                                    </>
                                  )}
                                </div>
                                <div className="bg_slack_cc bg_slack_cc_two">
                                  <div className="chat_channel_subTitle">
                                    {/* <div
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'space-between',
                                            }}
                                          > */}
                                    <h4>
                                      Provide app details from your Teams app
                                    </h4>
                                    {this.state.showAppDetails ? (
                                      <div
                                        className="img"
                                        onClick={() => {
                                          this.setState({
                                            showAppDetails: false,
                                          });
                                        }}
                                      >
                                        <MinusIcon />
                                      </div>
                                    ) : (
                                      <div
                                        className="img"
                                        onClick={() => {
                                          this.setState({
                                            showAppDetails: true,
                                          });
                                        }}
                                      >
                                        <AddIcon />
                                      </div>
                                    )}
                                  </div>
                                  {this.state.showAppDetails && (
                                    <>
                                      <InputContainer className="dialog_name_alert_content">
                                        <Input
                                          type="text"
                                          name="appId"
                                          value={
                                            this.state.currentTeamCredentials
                                              .app_id
                                          }
                                          onChange={(event) =>
                                            this.setState({
                                              currentTeamCredentials: {
                                                ...this.state
                                                  .currentTeamCredentials,
                                                app_id: event.target.value,
                                              },
                                              popUp: {
                                                error: null,
                                                loader: false,
                                                loaderIndex: null,
                                                errorIndex: null,
                                              },
                                            })
                                          }
                                        />
                                        <InputContent>Client ID :</InputContent>
                                      </InputContainer>
                                      {/* <div className="slack_buton_box_12">
                                    <div className="slack_buton_8">
                                      <p>Client ID :</p>
                                    </div>
                                    <div className="slack_buton_4">
                                      <input
                                        type="text"
                                        value={
                                          this.state.currentTeamCredentials
                                            .app_id
                                        }
                                        onChange={(event) =>
                                          this.setState({
                                            currentTeamCredentials: {
                                              ...this.state
                                                .currentTeamCredentials,
                                              app_id: event.target.value,
                                            },
                                            popUp: {
                                              error: null,
                                              loader: false,
                                              loaderIndex: null,
                                              errorIndex: null,
                                            },
                                          })
                                        }
                                      />
                                    </div>
                                  </div> */}
                                      <InputContainer className="dialog_name_alert_content">
                                        <Input
                                          type="text"
                                          name="appId"
                                          value={
                                            this.state.currentTeamCredentials
                                              .app_password
                                          }
                                          onChange={(event) =>
                                            this.setState({
                                              currentTeamCredentials: {
                                                ...this.state
                                                  .currentTeamCredentials,
                                                app_password:
                                                  event.target.value,
                                              },
                                            })
                                          }
                                        />
                                        <InputContent>
                                          Client Secret :
                                        </InputContent>
                                      </InputContainer>
                                      {/* <div className="slack_buton_box_12">
                                    <div className="slack_buton_8">
                                      <p>Client Secret :</p>
                                    </div>
                                    <div className="slack_buton_4">
                                      <input
                                        type="text"
                                        value={
                                          this.state.currentTeamCredentials
                                            .app_password
                                        }
                                        onChange={(event) =>
                                          this.setState({
                                            currentTeamCredentials: {
                                              ...this.state
                                                .currentTeamCredentials,
                                              app_password: event.target.value,
                                            },
                                          })
                                        }
                                      />
                                    </div>
                                  </div> */}
                                      <InputContainer className="dialog_name_alert_content">
                                        <Input
                                          type="text"
                                          name="appId"
                                          value={
                                            this.state.currentTeamCredentials
                                              .tenant
                                          }
                                          onChange={(event) =>
                                            this.setState({
                                              currentTeamCredentials: {
                                                ...this.state
                                                  .currentTeamCredentials,
                                                tenant: event.target.value,
                                              },
                                            })
                                          }
                                        />
                                        <InputContent>Tenant ID :</InputContent>
                                      </InputContainer>
                                      {/* <div className="slack_buton_box_12">
                                    <div className="slack_buton_8">
                                      <p>Tenant ID :</p>
                                    </div>
                                    <div className="slack_buton_4">
                                      <input
                                        type="text"
                                        value={
                                          this.state.currentTeamCredentials
                                            .tenant
                                        }
                                        onChange={(event) =>
                                          this.setState({
                                            currentTeamCredentials: {
                                              ...this.state
                                                .currentTeamCredentials,
                                              tenant: event.target.value,
                                            },
                                          })
                                        }
                                      />
                                    </div>
                                  </div> */}
                                      <div className="slack_buton_box_12">
                                        <InputContainer className="dialog_name_alert_content">
                                          <div>
                                            <Input
                                              type="text"
                                              name="appId"
                                              value={
                                                this.state
                                                  .currentTeamCredentials
                                                  .tenant_type == 'multi_tenant'
                                                  ? 'Multi tenant'
                                                  : 'Single tenant'
                                              }
                                              onClick={() =>
                                                this.setState({
                                                  openTenantDropDown: true,
                                                })
                                              }
                                            />
                                            <div className="arrow_up_form">
                                              <DownArrowCircle />
                                            </div>
                                          </div>
                                          <InputContent
                                            style={{ top: 10, fontSize: 10 }}
                                          >
                                            Select Tenant Type
                                          </InputContent>

                                          {this.state.openTenantDropDown && (
                                            <Downshift
                                              isOpen={true}
                                              onOuterClick={() => {
                                                setTimeout(() => {
                                                  this.setState({
                                                    openTenantDropDown: false,
                                                  });
                                                });
                                              }}
                                            >
                                              {() => (
                                                <div className="slack_buton_4 integration_dropdown teamsIntegration">
                                                  <ul>
                                                    <li
                                                      onClick={() => {
                                                        this.setState({
                                                          currentTeamCredentials:
                                                            {
                                                              ...this.state
                                                                .currentTeamCredentials,
                                                              tenant_type:
                                                                'multi_tenant',
                                                            },
                                                          openTenantDropDown:
                                                            false,
                                                        });
                                                      }}
                                                    >
                                                      Multi tenant
                                                    </li>
                                                    <li
                                                      onClick={() => {
                                                        this.setState({
                                                          currentTeamCredentials:
                                                            {
                                                              ...this.state
                                                                .currentTeamCredentials,
                                                              tenant_type:
                                                                'single_tenant',
                                                            },
                                                          openTenantDropDown:
                                                            false,
                                                        });
                                                      }}
                                                    >
                                                      Single tenant
                                                    </li>
                                                  </ul>
                                                </div>
                                              )}
                                            </Downshift>
                                          )}
                                        </InputContainer>
                                      </div>
                                      <div className="slack_buton_box_12">
                                        <InputContainer className="dialog_name_alert_content">
                                          <div>
                                            <Input
                                              type="text"
                                              name="appId"
                                              value={
                                                this.state
                                                  .currentTeamCredentials
                                                  .is_org_wide
                                                  ? 'Yes'
                                                  : 'No'
                                              }
                                              onClick={() =>
                                                this.setState({
                                                  openWideDropDown: true,
                                                })
                                              }
                                            />
                                            <div className="arrow_up_form">
                                              <DownArrowCircle />
                                            </div>
                                          </div>
                                          <InputContent
                                            style={{ top: 10, fontSize: 10 }}
                                          >
                                            Publish the bot for your
                                            Organisation
                                          </InputContent>

                                          {this.state.openWideDropDown && (
                                            <Downshift
                                              isOpen={true}
                                              onOuterClick={() => {
                                                setTimeout(() => {
                                                  this.setState({
                                                    openWideDropDown: false,
                                                  });
                                                });
                                              }}
                                            >
                                              {() => (
                                                <div className="slack_buton_4 integration_dropdown teamsIntegration">
                                                  <ul>
                                                    <li
                                                      onClick={() => {
                                                        this.setState({
                                                          currentTeamCredentials:
                                                            {
                                                              ...this.state
                                                                .currentTeamCredentials,
                                                              is_org_wide: true,
                                                            },
                                                          openWideDropDown:
                                                            false,
                                                        });
                                                      }}
                                                    >
                                                      Yes
                                                    </li>
                                                    <li
                                                      onClick={() => {
                                                        this.setState({
                                                          currentTeamCredentials:
                                                            {
                                                              ...this.state
                                                                .currentTeamCredentials,
                                                              is_org_wide:
                                                                false,
                                                            },
                                                          openWideDropDown:
                                                            false,
                                                        });
                                                      }}
                                                    >
                                                      No
                                                    </li>
                                                  </ul>
                                                </div>
                                              )}
                                            </Downshift>
                                          )}
                                        </InputContainer>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* </div> */}

                            <div className="slack_buton_center slack_buton_error">
                              <Button
                                primary
                                onClick={() => {
                                  this.state.workspaces.length
                                    ? this.setState({
                                        addMode: false,
                                        currentTeamCredentials: {
                                          app_id: '',
                                          app_password: '',
                                          tenant: '',
                                          tenant_type: 'multi_tenant',
                                          is_org_wide: false,
                                          name: '',
                                          description: '',
                                        },
                                        tokens: {
                                          ...this.state.tokens,
                                          assistance_id: '',
                                        },
                                        popUp: {
                                          error: null,
                                          loader: false,
                                          loaderIndex: null,
                                          errorIndex: null,
                                        },
                                      })
                                    : this.setState({
                                        addMode: false,
                                        PageState: NoDataFound,
                                        currentTeamCredentials: {
                                          app_id: '',
                                          app_password: '',
                                          tenant: '',
                                          tenant_type: 'multi_tenant',
                                          is_org_wide: false,
                                          name: '',
                                          description: '',
                                        },
                                        tokens: {
                                          ...this.state.tokens,
                                          assistance_id: '',
                                        },
                                        popUp: {
                                          error: null,
                                          loader: false,
                                          loaderIndex: null,
                                          errorIndex: null,
                                        },
                                      });
                                }}
                              >
                                <span className="slack-cancel-btn">Cancel</span>
                              </Button>
                              <Button
                                disabled={this.state.disableButton}
                                className={
                                  this.state.currentTeamCredentials.app_id !==
                                    '' &&
                                  this.state.currentTeamCredentials
                                    .app_password !== '' &&
                                  this.state.currentTeamCredentials.tenant !==
                                    '' &&
                                  this.state.tokens.assistance_id !== '' &&
                                  this.state.currentTeamCredentials.name !==
                                    '' &&
                                  this.state.currentTeamCredentials
                                    .description !== '' &&
                                  !this.state.popUp.error
                                    ? ''
                                    : 'editor_btn_disabled'
                                }
                                onClick={() => this.onSave()}
                              >
                                <span className="slack-save-btn">Save</span>
                              </Button>

                              {this.state.popUp.loader && this.state.addMode && (
                                <span className="connect_account_loading">
                                  <img src={LoadingSvgImage} />
                                </span>
                              )}
                              {this.state.popUp.error && this.state.addMode && (
                                <span className="connect_account_failed">
                                  <div className="error_input_show error_input_show_add teams_page_alert">
                                    <WarningAlertIcon />
                                    <span className="error_menu_hvr">
                                      {this.state.popUp.error}
                                    </span>
                                  </div>
                                </span>
                              )}
                            </div>
                            {/* </div> */}
                          </PopupWrapper>
                        </Wrapper>
                      )}

                      {this.state.PageState.action === Action.Edit &&
                        this.state.selectedWorkspace.length > 0 &&
                        this.state.selectedWorkspace?.map(
                          (data: TeamsWorkspaceT, index: number) => (
                            <Wrapper className="create_dialog_popup">
                              <PopupWrapper>
                                <PopupHeader>
                                  <HeaderLeft minWidth={300}>
                                    Chatbot deployment
                                    <Tooltip
                                      className="target customTip"
                                      zIndex={10000}
                                      arrowSize={8}
                                      tagName="span"
                                      content={<DilogTips />}
                                      distance={5}
                                    >
                                      <InfoIcon />
                                    </Tooltip>
                                    <a
                                      href="https://help.workativ.com/knowledgebase/microsoft-teams/"
                                      target="_blank"
                                    >
                                      <Tooltip
                                        className="target customTip"
                                        zIndex={10000}
                                        arrowSize={8}
                                        tagName="span"
                                        content="View help document"
                                        distance={5}
                                      >
                                        <QaIconWithCircle />
                                      </Tooltip>
                                    </a>
                                  </HeaderLeft>
                                  <HeaderRight>
                                    <VideoLink
                                      // id={'SET_CONDITIONS_FOR_A_DIALOG'}
                                      text={'How to create'}
                                    />
                                  </HeaderRight>
                                  <span
                                         onClick={() =>{
                                          this.setState({
                                            selectedWorkspace: [],
                                            showDetails: true,
                                            showAppDetails: true,
                                            showWorkspaceDetails: true,
                                            editIndex: null,
                                            clonedWorkspace: [],
                                            editAppId: '',
                                            // selectedBotData: '',
                                            popUp: {
                                              error: null,
                                              loader: false,
                                              loaderIndex: null,
                                              errorIndex: null,
                                              }
                                            })
                                         }}
                                         className="slack_close_btn"
                                     >
                                         <CloseIcon />
                                     </span>
                                </PopupHeader>
                                <Paragraph>
                                  Deploy Chatbot in MS Teams.
                                </Paragraph>
                                <div className="row">
                                  <div className="m_h_cc_container">
                                    <div className="bg_slack_cc bg_slack_cc_one">
                                      <div className="slack_buton_box_12" style={{marginBotttom: 0}}>
                                        <InputContainer className="dialog_name_alert_content">
                                          <div>
                                            <InputText
                                              type="text"
                                              name="name"
                                              disabled
                                              value={
                                                this.state.bots.find(
                                                  (e) => e.id == data.bot_id
                                                ).name
                                              }
                                              // value={this.state.bots.find(
                                              //     (e) =>
                                              //       e.id ==
                                              //       data.bot_id
                                              //     )
                                              //     ? this.state.bots.find(
                                              //         (e) =>
                                              //           e.id ==
                                              //         data.bot_id
                                              //       ).name
                                              //     : 'Select Chatbot'}
                                              //           onClick={() =>
                                              //           this.setState({
                                              //             openBotDropDown: true,
                                              //             })
                                              //           }
                                            />
                                            {/* <div className="arrow_up_form">
                                        <DownArrowCircle />
                                      </div> */}
                                          </div>
                                          <InputContent
                                            style={{ top: 10, fontSize: 10 }}
                                          >
                                            Chatbot Name
                                          </InputContent>
                                        </InputContainer>
                                        {this.state.openBotDropDown && (
                                          <Downshift
                                            isOpen={true}
                                            onOuterClick={() => {
                                              this.setState({
                                                openBotDropDown: false,
                                              });
                                            }}
                                          >
                                            {() => (
                                              <div className="slack_buton_4 integration_dropdown">
                                                <ul>
                                                  {this.state.bots.filter(
                                                    (b) =>
                                                      b.published_at != null
                                                  ).length > 0 ? (
                                                    this.state.bots
                                                      .filter(
                                                        (b) =>
                                                          b.published_at != null
                                                      )
                                                      .map((data, index) => (
                                                        <li
                                                          key={index}
                                                          onClick={() => {
                                                            this.setState({
                                                              openBotDropDown:
                                                                false,
                                                              selectedWorkspace:
                                                                this.state.selectedWorkspace.map(
                                                                  (yes) =>
                                                                    Object.assign(
                                                                      yes,
                                                                      {
                                                                        bot_id:
                                                                          data.id,
                                                                      }
                                                                    )
                                                                ),
                                                            });
                                                          }}
                                                        >
                                                          {data.name}
                                                        </li>
                                                      ))
                                                  ) : (
                                                    <li>No bots available</li>
                                                  )}
                                                </ul>
                                              </div>
                                            )}
                                          </Downshift>
                                        )}
                                      </div>
                                      <InputContainer className="dialog_name_alert_content">
                                        <Input
                                          type="text"
                                          name="name"
                                          value={data.name}
                                          onChange={(e) =>
                                            this.setState({
                                              selectedWorkspace:
                                                this.state.selectedWorkspace.map(
                                                  (yes) =>
                                                    Object.assign(yes, {
                                                      name: e.target.value,
                                                    })
                                                ),
                                            })
                                          }
                                        />
                                        <InputContent>Name</InputContent>
                                      </InputContainer>
                                      <InputContainer className="dialog_name_alert_content" style={{marginBottom: 0}}>
                                        <Input
                                          type="text"
                                          name="description"
                                          value={data.description}
                                          onChange={(e) =>
                                            this.setState({
                                              selectedWorkspace:
                                                this.state.selectedWorkspace.map(
                                                  (yes: any) =>
                                                    Object.assign(yes, {
                                                      description:
                                                        e.target.value,
                                                    })
                                                ),
                                            })
                                          }
                                          maxLength="80"
                                        />
                                        <InputContent>Description</InputContent>
                                      </InputContainer>
                                      {/* <div className="slack_buton_box_12">
                                            <div className="slack_buton_8">
                                              <p>
                                                Select your chatbot to integrate
                                              </p>
                                            </div>

                                            <div className="slack_buton_4">
                                              <h6>
                                                {this.state.bots.find(
                                                  (e) => e.id === data.bot_id
                                                )
                                                  ? this.state.bots.find(
                                                      (e) =>
                                                        e.id === data.bot_id
                                                    ).name
                                                  : ''}
                                              </h6>
                                            </div>
                                          </div> */}
                                    </div>
                                    {data.teams_deploy && (
                                      <div className="bg_slack_cc bg_slack_cc_three">
                                        <div className="chat_channel_subTitle">
                                          <h4>Workspace Details</h4>
                                          {this.state.showWorkspaceDetails ? (
                                            <div
                                              className="img"
                                              onClick={() => {
                                                this.setState({
                                                  showWorkspaceDetails: false,
                                                });
                                              }}
                                            >
                                              <MinusIcon />
                                            </div>
                                          ) : (
                                            <div
                                              className="img"
                                              onClick={() => {
                                                this.setState({
                                                  showWorkspaceDetails: true,
                                                });
                                              }}
                                            >
                                              <AddIcon />
                                            </div>
                                          )}
                                        </div>
                                        {this.state.showWorkspaceDetails && (
                                          <>
                                            <InputContainer className="dialog_name_alert_content">
                                              <InputText
                                                type="text"
                                                name="teamName"
                                                disabled
                                                value={
                                                  data.teams_deploy.team_name
                                                }
                                              />
                                              <InputContent className="client_input_content">
                                                Team Name
                                              </InputContent>
                                            </InputContainer>
                                            <InputContainer className="dialog_name_alert_content">
                                              <InputText
                                                type="text"
                                                name="addedBy"
                                                disabled
                                                value={
                                                  data.teams_deploy.added_by
                                                }
                                              />
                                              <InputContent className="client_input_content">
                                                Added By
                                              </InputContent>
                                            </InputContainer>
                                            <InputContainer className="dialog_name_alert_content">
                                              <InputText
                                                type="text"
                                                name="emailId"
                                                disabled
                                                value={
                                                  data.teams_deploy.email_id
                                                }
                                              />
                                              <InputContent className="client_input_content">
                                                Email Id
                                              </InputContent>
                                            </InputContainer>
                                            {data.teams_deploy.date_added && (
                                              <InputContainer className="dialog_name_alert_content" style={{marginBottom: 0}}>
                                                <InputText
                                                  type="text"
                                                  name="deployTime"
                                                  disabled
                                                  value={formatDateInCard(
                                                    data.teams_deploy.date_added
                                                  )}
                                                />
                                                <InputContent className="client_input_content">
                                                  Deployed Time
                                                </InputContent>
                                              </InputContainer>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    )}
                                    <div className="bg_slack_cc bg_slack_cc_two">
                                      <div className="chat_channel_subTitle">
                                        <h4>
                                          Add following details in your Teams
                                          app
                                        </h4>
                                        {this.state.showDetails ? (
                                          <div
                                            className="img"
                                            onClick={() => {
                                              this.setState({
                                                showDetails: false,
                                              });
                                            }}
                                          >
                                            <MinusIcon />
                                          </div>
                                        ) : (
                                          <div
                                            className="img"
                                            onClick={() => {
                                              this.setState({
                                                showDetails: true,
                                              });
                                            }}
                                          >
                                            <AddIcon />
                                          </div>
                                        )}
                                      </div>
                                      {this.state.showDetails && (
                                        <>
                                          <InputContainer className="dialog_name_alert_content bg-grey">
                                            <Textarea
                                              type="text"
                                              disabled
                                              name="redirectionURL"
                                              value={
                                                window.location.origin +
                                                process.env
                                                  .REACT_APP_TEAMS_EXTERNAL_ROUTE +
                                                '/' +
                                                'oauth/redirect/success' +
                                                '/' +
                                                this.workspaceName +
                                                '/' +
                                                data.bot_id +
                                                '/' +
                                                data.app_id
                                              }
                                            />
                                            <InputContent>
                                              Redirection URL :
                                            </InputContent>
                                          </InputContainer>
                                          {/* <div className="slack_buton_box_12">
                                            <div className="slack_buton_8">
                                              <p>Redirection URL :</p>
                                            </div>
                                            <div className="slack_buton_4">
                                              <div
                                                className={
                                                  this.state.editIndex !==
                                                    index ||
                                                  this.state
                                                    .currentEditingTeamCredentials
                                                    .app_id.length
                                                    ? 'content_slack_input'
                                                    : 'content_slack_input editor_btn_disabled'
                                                }
                                                // className="content_slack_input"
                                              >
                                                {window.location.origin +
                                                  process.env
                                                    .REACT_APP_TEAMS_EXTERNAL_ROUTE +
                                                  '/' +
                                                  'oauth/redirect/success' +
                                                  '/' +
                                                  this.workspaceName +
                                                  '/' +
                                                  data.bot_id +
                                                  '/' +
                                                  data.app_id}
                                              </div>
                                            </div>
                                          </div> */}
                                          {/* {this.state.bots.find(
                                                  (e) => e.id === data.bot_id
                                                ) &&  */}
                                          <InputContainer className="dialog_name_alert_content bg-grey">
                                            <Textarea
                                              type="text"
                                              disabled
                                              name="messageEndpoint"
                                              value={
                                                window.location.origin +
                                                teamsBackendIntegrationGenerateUrl(
                                                  this.workspaceName,
                                                  data.bot_id
                                                )
                                              }
                                            />
                                            <InputContent>
                                              Message Endpoint :
                                            </InputContent>
                                          </InputContainer>

                                          {/* <div className="slack_buton_box_12">
                                            <div className="slack_buton_8">
                                              <p>Message Endpoint :</p>
                                            </div>
                                            <div className="slack_buton_4">
                                              <div className="content_slack_input">
                                                {this.state.bots.find(
                                                  (e) => e.id === data.bot_id
                                                ) &&
                                                  window.location.origin +
                                                    teamsBackendIntegrationGenerateUrl(
                                                      this.workspaceName,
                                                      this.state.bots.find(
                                                        (e) =>
                                                          e.id === data.bot_id
                                                      ).id
                                                    )}
                                              </div>
                                            </div>
                                          </div> */}
                                        </>
                                      )}
                                    </div>

                                    <div className="bg_slack_cc bg_slack_cc_three">
                                      <div className="chat_channel_subTitle">
                                        {/* <div
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'space-between',
                                            }}
                                          > */}
                                        <h4>
                                          Provide app details from your Teams
                                          app
                                        </h4>
                                        {this.state.showAppDetails ? (
                                          <div
                                            className="img"
                                            onClick={() => {
                                              this.setState({
                                                showAppDetails: false,
                                              });
                                            }}
                                          >
                                            <MinusIcon />
                                          </div>
                                        ) : (
                                          <div
                                            className="img"
                                            onClick={() => {
                                              this.setState({
                                                showAppDetails: true,
                                              });
                                            }}
                                          >
                                            <AddIcon />
                                          </div>
                                        )}
                                      </div>
                                      {this.state.showAppDetails && (
                                        <>
                                          {this.state.selectedWorkspace[0]
                                            .teams_deploy ? (
                                            <InputContainer className="dialog_name_alert_content">
                                              <InputText
                                                type="text"
                                                name="clientId"
                                                value={data.app_id}
                                                disabled
                                              />
                                              <InputContent className="client_input_content">
                                                Client ID
                                              </InputContent>
                                            </InputContainer>
                                          ) : (
                                            <InputContainer className="dialog_name_alert_content">
                                              <Input
                                                type="text"
                                                name="clientId"
                                                value={data.app_id}
                                                onChange={(e) =>
                                                  this.setState({
                                                    selectedWorkspace:
                                                      this.state.selectedWorkspace.map(
                                                        (yes) =>
                                                          Object.assign(yes, {
                                                            app_id:
                                                              e.target.value,
                                                          })
                                                      ),
                                                  })
                                                }
                                              />
                                              <InputContent>
                                                Client ID
                                              </InputContent>
                                            </InputContainer>
                                          )}

                                          {/* <div className="slack_buton_box_12">
                                            <div className="slack_buton_8">
                                              <p>Client ID</p>
                                            </div>
                                            <div className="slack_buton_4">
                                              {this.state.editIndex !== index ||
                                              data.teams_deploy ? (
                                                <h6>{data.app_id}</h6>
                                              ) : (
                                                <input
                                                  type="text"
                                                  value={
                                                    this.state
                                                      .currentEditingTeamCredentials
                                                      .app_id
                                                  }
                                                  onChange={(e) =>
                                                    this.setState({
                                                      currentEditingTeamCredentials:
                                                        {
                                                          ...this.state
                                                            .currentEditingTeamCredentials,
                                                          app_id:
                                                            e.target.value,
                                                        },
                                                      popUp: {
                                                        error: null,
                                                        loader: false,
                                                        loaderIndex: null,
                                                        errorIndex: null,
                                                      },
                                                    })
                                                  }
                                                />
                                              )}
                                            </div>
                                          </div> */}
                                          <InputContainer className="dialog_name_alert_content">
                                            <Input
                                              type="text"
                                              name="clientSecret"
                                              value={data.app_password}
                                              onChange={(e) =>
                                                this.setState({
                                                  selectedWorkspace:
                                                    this.state.selectedWorkspace.map(
                                                      (yes) =>
                                                        Object.assign(yes, {
                                                          app_password:
                                                            e.target.value,
                                                        })
                                                    ),
                                                })
                                              }
                                            />
                                            <InputContent>
                                              Client Secret
                                            </InputContent>
                                            {data.client_secret_active === false ? (
                                              <span className="info_icon info_icon_Adaptive alerticon">
                                                    <Tooltip
                                                        className="target customTip"
                                                        zIndex={10000}
                                                        arrowSize={8}
                                                        tagName="span"
                                                        content={'The client secret has expired/changed. Please update it for the bot to work.'}
                                                        distance={5}
                                                    >
                                                        <WarningAlertIcon />
                                                    </Tooltip>
                                                </span>
                                             ):""}
                                          </InputContainer>

                                          {/* <div className="slack_buton_box_12">
                                            <div className="slack_buton_8">
                                              <p>Client Secret</p>
                                            </div>
                                            <div className="slack_buton_4">
                                              {this.state.editIndex !==
                                              index ? (
                                                <h6>{data.app_password}</h6>
                                              ) : (
                                                <input
                                                  type="text"
                                                  value={
                                                    this.state
                                                      .currentEditingTeamCredentials
                                                      .app_password
                                                  }
                                                  onChange={(e) =>
                                                    this.setState({
                                                      currentEditingTeamCredentials:
                                                        {
                                                          ...this.state
                                                            .currentEditingTeamCredentials,
                                                          app_password:
                                                            e.target.value,
                                                        },
                                                    })
                                                  }
                                                />
                                              )}
                                            </div>
                                          </div> */}
                                          {this.state.selectedWorkspace[0]
                                            .teams_deploy ? (
                                            <InputContainer className="dialog_name_alert_content">
                                              <InputText
                                                type="text"
                                                name="tenentID"
                                                value={data.tenant}
                                                disabled
                                                //         onChange={(e) =>
                                                //          this.setState({
                                                //            selectedWorkspace:
                                                //            this.state.selectedWorkspace.map(
                                                //           (yes: any) =>
                                                //           Object.assign(yes, {
                                                //           tenant: e.target.value,
                                                //           })
                                                //       ),
                                                //   })
                                                // }
                                              />
                                              <InputContent className="client_input_content">
                                                Tenant ID
                                              </InputContent>
                                            </InputContainer>
                                          ) : (
                                            <InputContainer className="dialog_name_alert_content">
                                              <Input
                                                type="text"
                                                name="tenentID"
                                                value={data.tenant}
                                                onChange={(e) =>
                                                  this.setState({
                                                    selectedWorkspace:
                                                      this.state.selectedWorkspace.map(
                                                        (yes) =>
                                                          Object.assign(yes, {
                                                            tenant:
                                                              e.target.value,
                                                          })
                                                      ),
                                                  })
                                                }
                                              />
                                              <InputContent>
                                                Tenant ID
                                              </InputContent>
                                            </InputContainer>
                                          )}
                                          {/* <div className="slack_buton_box_12">
                                            <div className="slack_buton_8">
                                              <p>Tenant ID</p>
                                            </div>
                                            <div className="slack_buton_4">
                                              {this.state.editIndex !== index ||
                                              data.teams_deploy ? (
                                                <h6>{data.tenant}</h6>
                                              ) : (
                                                <input
                                                  type="text"
                                                  value={
                                                    this.state
                                                      .currentEditingTeamCredentials
                                                      .tenant
                                                  }
                                                  onChange={(e) =>
                                                    this.setState({
                                                      currentEditingTeamCredentials:
                                                        {
                                                          ...this.state
                                                            .currentEditingTeamCredentials,
                                                          tenant:
                                                            e.target.value,
                                                        },
                                                    })
                                                  }
                                                />
                                              )}
                                            </div>
                                          </div> */}

                                          <div className="slack_buton_box_12">
                                            <InputContainer className="dialog_name_alert_content">
                                              <div>
                                                <Input
                                                  type="text"
                                                  name="appId"
                                                  value={
                                                    data.tenant_type ==
                                                    'multi_tenant'
                                                      ? 'Multi tenant'
                                                      : 'Single tenant'
                                                  }
                                                  onClick={() =>
                                                    this.setState({
                                                      openTenantDropDown: true,
                                                    })
                                                  }
                                                />
                                                <div className="arrow_up_form">
                                                  <DownArrowCircle />
                                                </div>
                                              </div>
                                              <InputContent
                                                style={{
                                                  top: 10,
                                                  fontSize: 10,
                                                }}
                                              >
                                                Select Tenant Type
                                              </InputContent>
                                            </InputContainer>
                                            {/* <div className="slack_buton_8">
                                              <p>Select Tenant Type</p>
                                            </div>
                                            <div
                                              className="slack_buton_4 "
                                              onClick={() =>
                                                this.state.editIndex == index &&
                                                this.setState({
                                                  openTenantDropDown: true,
                                                })
                                              }
                                            >
                                              <h6 className="input_integration">
                                                {this.state.editIndex != index
                                                  ? data.tenant_type ==
                                                    'multi_tenant'
                                                    ? 'Multi tenant'
                                                    : 'Single tenant'
                                                  : this.state
                                                      .currentEditingTeamCredentials
                                                      .tenant_type ==
                                                    'multi_tenant'
                                                  ? 'Multi tenant'
                                                  : 'Single tenant'}
                                              </h6>
                                              <div className="arrow_up_form">
                                                <DownArrowCircle />
                                              </div>
                                            </div> */}

                                            {this.state.openTenantDropDown && (
                                              <Downshift
                                                isOpen={true}
                                                onOuterClick={() => {
                                                  setTimeout(() => {
                                                    this.setState({
                                                      openTenantDropDown: false,
                                                    });
                                                  });
                                                }}
                                              >
                                                {() => (
                                                  <div className="slack_buton_4 integration_dropdown">
                                                    <ul>
                                                      <li
                                                        onClick={(e) => {
                                                          // this.setState({
                                                          //   currentEditingTeamCredentials:
                                                          //     {
                                                          //       ...this.state
                                                          //         .currentEditingTeamCredentials,
                                                          //       tenant_type:
                                                          //         'multi_tenant',
                                                          //     },
                                                          //   openTenantDropDown:
                                                          //     false,
                                                          // });
                                                          this.setState({
                                                            selectedWorkspace:
                                                              this.state.selectedWorkspace.map(
                                                                (yes) =>
                                                                  Object.assign(
                                                                    yes,
                                                                    {
                                                                      tenant_type:
                                                                        'multi_tenant',
                                                                    }
                                                                  )
                                                              ),
                                                            openTenantDropDown:
                                                              false,
                                                          });
                                                        }}
                                                      >
                                                        Multi tenant
                                                      </li>
                                                      <li
                                                        onClick={(e) => {
                                                          this.setState({
                                                            selectedWorkspace:
                                                              this.state.selectedWorkspace.map(
                                                                (yes) =>
                                                                  Object.assign(
                                                                    yes,
                                                                    {
                                                                      tenant_type:
                                                                        'single_tenant',
                                                                    }
                                                                  )
                                                              ),
                                                            openTenantDropDown:
                                                              false,
                                                          });
                                                        }}
                                                      >
                                                        Single tenant
                                                      </li>
                                                    </ul>
                                                  </div>
                                                )}
                                              </Downshift>
                                            )}
                                          </div>

                                          <div className="slack_buton_box_12">
                                            <InputContainer className="dialog_name_alert_content" style={{marginBottom: 0}}>
                                              <div>
                                                <Input
                                                  type="text"
                                                  name="appId"
                                                  value={
                                                    data.is_org_wide
                                                      ? 'Yes'
                                                      : 'No'
                                                  }
                                                  onClick={() =>
                                                    this.setState({
                                                      openWideDropDown: true,
                                                    })
                                                  }
                                                />
                                                <div className="arrow_up_form">
                                                  <DownArrowCircle />
                                                </div>
                                              </div>
                                              <InputContent
                                                style={{
                                                  top: 10,
                                                  fontSize: 10,
                                                }}
                                              >
                                                Publish the bot for your
                                                Organisation
                                              </InputContent>
                                            </InputContainer>

                                            {/* <div className="slack_buton_8">
                                              <p>
                                                Publish the bot for your
                                                Organisation
                                              </p>
                                            </div> */}
                                            {/* {this.state.openBotDropDown === false ? ( */}
                                            {/* <div
                                              className="slack_buton_4 "
                                              onClick={() =>
                                                this.state.editIndex ===
                                                  index &&
                                                this.setState({
                                                  openWideDropDown: true,
                                                })
                                              }
                                            >
                                              <h6 className="input_integration">
                                                {this.state.editIndex != index
                                                  ? data.is_org_wide
                                                    ? 'Yes'
                                                    : 'No'
                                                  : this.state
                                                      .currentEditingTeamCredentials
                                                      .is_org_wide
                                                  ? 'Yes'
                                                  : 'No'}
                                              </h6>
                                              <div className="arrow_up_form">
                                                <DownArrowCircle />
                                              </div>
                                            </div> */}
                                            {/* ) : */}

                                            {this.state.openWideDropDown && (
                                              <Downshift
                                                isOpen={true}
                                                onOuterClick={() => {
                                                  setTimeout(() => {
                                                    this.setState({
                                                      openWideDropDown: false,
                                                    });
                                                  });
                                                }}
                                              >
                                                {() => (
                                                  <div className="slack_buton_4 integration_dropdown teamsIntegration">
                                                    <ul>
                                                      <li
                                                        onClick={() => {
                                                          // this.setState({
                                                          //   currentEditingTeamCredentials:
                                                          //     {
                                                          //       ...this.state
                                                          //         .currentEditingTeamCredentials,
                                                          //       is_org_wide:
                                                          //         true,
                                                          //     },
                                                          //   openWideDropDown:
                                                          //     false,
                                                          // });
                                                          this.setState({
                                                            selectedWorkspace:
                                                              this.state.selectedWorkspace.map(
                                                                (yes) =>
                                                                  Object.assign(
                                                                    yes,
                                                                    {
                                                                      is_org_wide:
                                                                        true,
                                                                    }
                                                                  )
                                                              ),
                                                            openWideDropDown:
                                                              false,
                                                          });
                                                        }}
                                                      >
                                                        Yes
                                                      </li>
                                                      <li
                                                        onClick={() => {
                                                          this.setState({
                                                            selectedWorkspace:
                                                              this.state.selectedWorkspace.map(
                                                                (yes) =>
                                                                  Object.assign(
                                                                    yes,
                                                                    {
                                                                      is_org_wide:
                                                                        false,
                                                                    }
                                                                  )
                                                              ),
                                                            openWideDropDown:
                                                              false,
                                                          });
                                                        }}
                                                      >
                                                        No
                                                      </li>
                                                    </ul>
                                                  </div>
                                                )}
                                              </Downshift>
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </div>

                                    {data.bot_data && (
                                      <div className="bg_slack_cc bg_slack_cc_three">
                                        <div className="chat_channel_subTitle">
                                          {/* <div
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                              }}
                                            > */}
                                          <h4>Chatbot Details</h4>
                                          {this.state.showChatDetails ? (
                                            <div
                                              className="img"
                                              onClick={() => {
                                                this.setState({
                                                  showChatDetails: false,
                                                });
                                              }}
                                            >
                                              <MinusIcon />
                                            </div>
                                          ) : (
                                            <div
                                              className="img"
                                              onClick={() => {
                                                this.setState({
                                                  showChatDetails: true,
                                                });
                                              }}
                                            >
                                              <AddIcon />
                                            </div>
                                          )}
                                        </div>

                                        {data.bot_data &&
                                          this.state.showChatDetails && (
                                            <>
                                              {data.teams_deploy !== null ? (
                                                //   &&
                                                // (this.state.editBotIndex ==
                                                //   null ||
                                                //   this.state.editBotIndex !==
                                                //     index)
                                                <InputContainer className="dialog_name_alert_content">
                                                  <InputText
                                                    type="text"
                                                    name="shortName"
                                                    value={
                                                      data.bot_data.bot_name
                                                    }
                                                    disabled
                                                  />
                                                  <InputContent className="client_input_content">
                                                    Short name
                                                  </InputContent>
                                                </InputContainer>
                                              ) : (
                                                <InputContainer className="dialog_name_alert_content">
                                                  <InputText
                                                    type="text"
                                                    name="shortName"
                                                    value={
                                                      data.bot_data.bot_name
                                                    }
                                                    onChange={(e) => {
                                                      this.setState({
                                                        selectedWorkspace:
                                                          this.state.selectedWorkspace.map(
                                                            (yes) =>
                                                              Object.assign(
                                                                yes,
                                                                {
                                                                  bot_data: {
                                                                    ...data.bot_data,
                                                                    bot_name:
                                                                      e.target
                                                                        .value,
                                                                  },
                                                                }
                                                              )
                                                          ),
                                                        // selectedBotData:
                                                        //   this.state.selectedBotData.map(
                                                        //     (yes: any) =>
                                                        //       Object.assign(
                                                        //         yes,
                                                        //         {
                                                        //           ...this
                                                        //             .state
                                                        //             .selectedBotData,
                                                        //           bot_name:
                                                        //             e.target
                                                        //               .value,
                                                        //         }
                                                        //       )
                                                        //   ),
                                                      });
                                                    }}
                                                  />
                                                  <InputContent>
                                                    Short name
                                                  </InputContent>
                                                </InputContainer>
                                              )}
                                              {data.teams_deploy !== null ? (
                                                <InputContainer className="dialog_name_alert_content">
                                                  <InputText
                                                    type="text"
                                                    name="fullName"
                                                    value={
                                                      data.bot_data
                                                        .bot_full_name
                                                    }
                                                    disabled
                                                  />
                                                  <InputContent className="client_input_content">
                                                    Full name
                                                  </InputContent>
                                                </InputContainer>
                                              ) : (
                                                <InputContainer className="dialog_name_alert_content">
                                                  <Input
                                                    type="text"
                                                    name="fullName"
                                                    value={
                                                      data.bot_data
                                                        .bot_full_name
                                                    }
                                                    onChange={(e) => {
                                                      // console.log(
                                                      //   e,
                                                      //   e.target.value,
                                                      //   'inputvalue'
                                                      // ),
                                                      this.setState({
                                                        selectedWorkspace:
                                                          this.state.selectedWorkspace.map(
                                                            (yes) =>
                                                              Object.assign(
                                                                yes,
                                                                {
                                                                  bot_data: {
                                                                    ...data.bot_data,
                                                                    bot_full_name:
                                                                      e.target
                                                                        .value,
                                                                  },
                                                                }
                                                              )
                                                          ),
                                                        // selectedBotData:
                                                        //   this.state.selectedBotData.map(
                                                        //     (yes: any) =>
                                                        //       Object.assign(
                                                        //         yes,
                                                        //         {
                                                        //           ...this
                                                        //             .state
                                                        //             .selectedBotData,
                                                        //           bot_full_name:
                                                        //             e.target
                                                        //               .value,
                                                        //         }
                                                        //       )
                                                        //   ),
                                                      });
                                                    }}
                                                  />
                                                  <InputContent>
                                                    Full name
                                                  </InputContent>
                                                </InputContainer>
                                              )}
                                              {data.teams_deploy !== null ? (
                                                <InputContainer className="dialog_name_alert_content">
                                                  <InputText
                                                    type="text"
                                                    name="shortDescription"
                                                    value={
                                                      data.bot_data
                                                        .bot_short_desc
                                                    }
                                                    disabled
                                                  />
                                                  <InputContent className="client_input_content">
                                                    Short description (80
                                                    characters or less)
                                                  </InputContent>
                                                </InputContainer>
                                              ) : (
                                                <InputContainer className="dialog_name_alert_content">
                                                  <Input
                                                    type="text"
                                                    name="shortDescription"
                                                    value={
                                                      data.bot_data
                                                        .bot_short_desc
                                                    }
                                                    onChange={(e) => {
                                                      // console.log(
                                                      //   e,
                                                      //   e.target.value,
                                                      //   'inputvalue'
                                                      // ),
                                                      this.setState({
                                                        selectedWorkspace:
                                                          this.state.selectedWorkspace.map(
                                                            (yes) =>
                                                              Object.assign(
                                                                yes,
                                                                {
                                                                  bot_data: {
                                                                    ...data.bot_data,
                                                                    bot_short_desc:
                                                                      e.target
                                                                        .value,
                                                                  },
                                                                }
                                                              )
                                                          ),
                                                        // selectedBotData:
                                                        //   this.state.selectedBotData.map(
                                                        //     (yes: any) =>
                                                        //       Object.assign(
                                                        //         yes,
                                                        //         {
                                                        //           ...this
                                                        //             .state
                                                        //             .selectedBotData,
                                                        //           bot_short_desc:
                                                        //             e.target
                                                        //               .value,
                                                        //         }
                                                        //       )
                                                        //   ),
                                                      });
                                                    }}
                                                  />
                                                  <InputContent>
                                                    Short description (80
                                                    characters or less)
                                                  </InputContent>
                                                </InputContainer>
                                              )}
                                              {data.teams_deploy !== null ? (
                                                <InputContainer className="dialog_name_alert_content" style={{marginBottom: 0}}>
                                                  <InputText
                                                    type="text"
                                                    name="fullDescription"
                                                    disabled
                                                    value={
                                                      data.bot_data
                                                        .bot_full_desc
                                                    }
                                                    className={
                                                      data.bot_data.bot_full_desc.length > 80 ? "teams_above_80char" :""
                                                    }
                                                  />
                                                  <InputContent className={
                                                      data.bot_data.bot_full_desc.length > 80 ? "client_input_content teams_above_80chars inputContent" : "client_input_content"
                                                    }>
                                                    Full description (4000 characters or less)
                                                  </InputContent>
                                                </InputContainer>
                                              ) : (
                                                <InputContainer className="dialog_name_alert_content">
                                                  <Input
                                                    type="text"
                                                    name="shortDescription"
                                                    value={
                                                      data.bot_data
                                                        .bot_full_desc
                                                    }
                                                    className={
                                                      data.bot_data.bot_full_desc.length > 80 ? "teams_above_80char" :""
                                                    }
                                                    onChange={(e) => {
                                                      // console.log(
                                                      //   e,
                                                      //   e.target.value,
                                                      //   'inputvalue'
                                                      // ),
                                                      this.setState({
                                                        selectedWorkspace:
                                                          this.state.selectedWorkspace.map(
                                                            (yes) =>
                                                              Object.assign(
                                                                yes,
                                                                {
                                                                  bot_data: {
                                                                    ...data.bot_data,
                                                                    bot_full_desc:
                                                                      e.target
                                                                        .value,
                                                                  },
                                                                }
                                                              )
                                                          ),
                                                        // selectedBotData:
                                                        //   this.state.selectedBotData.map(
                                                        //     (yes: any) =>
                                                        //       Object.assign(
                                                        //         yes,
                                                        //         {
                                                        //           ...this
                                                        //             .state
                                                        //             .selectedBotData,
                                                        //           bot_full_desc:
                                                        //             e.target
                                                        //               .value,
                                                        //         }
                                                        //       )
                                                        //   ),
                                                      });
                                                    }}
                                                  />
                                                  <InputContent>
                                                    Full description (4000
                                                    characters or less)
                                                  </InputContent>
                                                </InputContainer>
                                              )}
                                            </>
                                          )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="slack_buton_center slack_btn_icon slack_buton_error">
                                  {/* {
                                      this.state.editIndex === index &&
                                      !this.state.editBotIndex &&
                                      !this.state.showDeleteModal ? ( */}
                                  <div className="slack_buton_center">
                                    <Button
                                      onClick={() =>
                                        this.editCRED(
                                          // this.state
                                          //   .currentEditingTeamCredentials,
                                          data,
                                          index
                                        )
                                      }
                                      // disabled={
                                      //   this.state.addMode &&
                                      //   this.state.editBotIndex != null &&
                                      //   this.state.selectedWorkspace.app_id == '' &&
                                      //   this.state.selectedWorkspace
                                      //     .app_password == '' &&
                                      //   this.state.selectedWorkspace
                                      //     .bot_id == '' &&
                                      //   this.state.popUp.error != null
                                      // }
                                      className={
                                        this.state.selectedWorkspace[0]
                                          .app_id !== '' &&
                                        this.state.selectedWorkspace[0]
                                          .app_password !== '' &&
                                        this.state.selectedWorkspace[0]
                                          .bot_id !== '' &&
                                        this.state.selectedWorkspace[0].name !==
                                          '' &&
                                        this.state.selectedWorkspace[0]
                                          .description !== '' &&
                                        this.state.selectedWorkspace[0]
                                          .tenant !== '' &&
                                        this.disablesave()
                                          ? // this.state.popUp.errors
                                            ''
                                          : 'hidden'
                                      }
                                    >
                                      <span className="slack-save-btn">
                                        Save
                                      </span>
                                    </Button>
                                  </div>
                                  {/* ) :  */}
                                  {!data.is_authorized ? (
                                    <Button
                                      //className={'btn btn-success add_to_slack'}
                                      onClick={() =>
                                        this.authorizeTeams(
                                          data,
                                          window.location.origin +
                                            process.env
                                              .REACT_APP_TEAMS_EXTERNAL_ROUTE +
                                            '/' +
                                            'oauth/redirect/success' +
                                            '/' +
                                            this.workspaceName +
                                            '/' +
                                            data.bot_id +
                                            '/' +
                                            data.app_id,
                                          index
                                        )
                                      }
                                      // disabled={
                                      //   this.state.addMode ||
                                      //   this.state.editBotIndex != null
                                      // }
                                    >
                                      Authorize
                                    </Button>
                                  ) : data.bot_data &&
                                    data.teams_deploy === null ? (
                                    this.state.editBotIndex == index ? (
                                      <div className="slack_buton_center slack_buton_error">
                                        <Button
                                          className={
                                            data.bot_data.bot_name.length ==
                                              0 ||
                                            data.bot_data.bot_full_name
                                              .length == 0 ||
                                            data.bot_data.bot_short_desc
                                              .length == 0 ||
                                            data.bot_data.bot_full_desc
                                              .length == 0 ||
                                            this.state.addMode
                                              ? 'editor_btn_disabled'
                                              : ''
                                          }
                                          // primary
                                          // className={
                                          //     'btn btn-primary slack_button teams_button'
                                          // }
                                          onClick={() =>
                                            this.addToTeams(
                                              data,
                                              // {
                                              //   // ...this.state
                                              //   //   .currentEditingTeamCredentials,
                                              //   ...data,
                                              //   bot_data: botData,
                                              // },
                                              index
                                            )
                                          }
                                        >
                                          {/* <TeamsIcon /> */}
                                          <span>Download</span>
                                        </Button>
                                      </div>
                                    ) : (
                                      <Button
                                        primary
                                        onClick={() =>
                                          this.addToTeams(
                                            data,
                                            // { ...data, bot_data: botData },
                                            index
                                          )
                                        }
                                        className={
                                          data.bot_data.bot_name.length == 0 ||
                                          data.bot_data.bot_full_name.length ==
                                            0 ||
                                          data.bot_data.bot_short_desc.length ==
                                            0 ||
                                          data.bot_data.bot_full_desc.length ==
                                            0 ||
                                          this.state.addMode
                                            ? 'editor_btn_disabled'
                                            : ''
                                        }
                                      >
                                        {/* <TeamsIcon /> */}
                                        <span>Download</span>
                                      </Button>
                                    )
                                  ) : data.teams_deploy &&
                                    data.teams_deploy.is_active ? (
                                    <Button
                                      onClick={() =>
                                        this.toggleActivation(data, index)
                                      }
                                      className={
                                        this.state.addMode ||
                                        this.state.editBotIndex == null
                                          ? 'btn-deactivaed'
                                          : ''
                                      }
                                      disabled={
                                        this.state.addMode ||
                                        this.state.editBotIndex == null
                                      }
                                    >
                                      <span>Deactivate</span>
                                    </Button>
                                  ) : (
                                    <Button
                                      className={
                                        this.state.addMode ||
                                        this.state.editBotIndex == null
                                          ? 'btn-deactivaed'
                                          : ''
                                      }
                                      onClick={() =>
                                        this.toggleActivation(data, index)
                                      }
                                      disabled={
                                        this.state.addMode ||
                                        this.state.editBotIndex == null
                                      }
                                    >
                                      <span className="slack_integration">
                                        Activate
                                      </span>
                                    </Button>
                                  )}
                                  {this.state.popUp.error &&
                                    !this.state.addMode &&
                                    this.state.popUp.errorIndex == index && (
                                      <span className="connect_account_failed">
                                        <div className="error_input_show">
                                          <WarningAlertIcon />
                                          <span className="error_menu_hvr">
                                            {this.state.popUp.error}
                                          </span>
                                        </div>
                                      </span>
                                    )}

                                  {this.state.popUp.loader &&
                                    !this.state.addMode &&
                                    typeof this.state.popUp.loaderIndex ==
                                      'number' &&
                                    this.state.popUp.loaderIndex == index && (
                                      <span className="connect_account_loading">
                                        <img src={LoadingSvgImage} />
                                      </span>
                                    )}
                                </div>
                              </PopupWrapper>
                            </Wrapper>
                          )
                        )}
                    </div>
                  </div>
                </div>
              </ErrorBoundary>
            </div>
          </div>
        </section>
        {this.renderDeleteModal()}
      </React.Fragment>
    );
  }
}
const DilogTips = () => (
  <div className="">
    <p>
      <a
        className="sidepanel_href_color"
        target="_blank"
        href="https://help.workativ.com/knowledgebase/building-a-dialog/"
      >
        Chatbot deployment
      </a>{' '}
      helps the bot recognize user utterance or intent (to start a conversation
      or dialog).
    </p>
    <p>
      {' '}
      You can specify conditions on how the dialog is executed using the 'create
      dialog’ pop up. Based on the user's intent and condition, the bot can then
      initiate an appropriate conversation or take action on user’s intent or
      query.
    </p>
    {/* <p> The chatbot can then check and choose the dialog that the user's input satisfies.</p> */}
  </div>
);

function ReturnList(props:{data:TeamsWorkspaceT,editAppId:string,setToEditMode:(params:any)=>void,i:number,isSelected:any,label:any,state:any,workspacename:string,selectTeam:any ,setDeleteMode:(params:any)=>void,showAddButton:(params:boolean)=>void}){
  const {data,editAppId,setToEditMode,i,isSelected,label,state,workspacename,setDeleteMode,showAddButton,selectTeam} = props
  const writePermission = usePermissions(workspacename,`CHAT_CHANNEL_MODULE/CHANNEL/TEAMS/INTEGRATION/${data.id}`,"WRITE")
  const deletePermission = usePermissions(workspacename,`CHAT_CHANNEL_MODULE/CHANNEL/TEAMS/INTEGRATION/${data.id}`,"DELETE")
  const slackWritePermission = usePermissions(workspacename,`CHAT_CHANNEL_MODULE/CHANNEL/TEAMS`,"WRITE")

  React.useMemo(()=>{
    showAddButton(slackWritePermission.value as boolean)
  },[slackWritePermission])

  return(
    <Tr
                                  className={
                                    data.app_id == state.editAppId
                                      ? 'is_active'
                                      : null
                                  }
                                  onClick={() =>
                                    writePermission.value && setToEditMode(data.app_id)
                                  }
                                >
                                  <List
                                    key={i}
                                    showCheckBox={false}
                                    checkBoxShow={false}
                                    render={(show: boolean, ToolBox: any) => {
                                      return (
                                        <>
                                          <Td
                                            size="3"
                                            className={'list_flow_hr'}
                                          >
                                            <ListSelectBox
                                              show={show}
                                              isSelected={isSelected(
                                                data.name
                                              )}
                                              onClick={selectTeam}
                                              value={data.name}
                                            >
                                              {data.name}
                                            </ListSelectBox>
                                            <div
                                              className="search_card_list"
                                              onClick={() =>
                                                setToEditMode(data.app_id)
                                              }
                                            >
                                              {Parser(label)}
                                            </div>
                                          </Td>
                                          <Td size="3" className="list_flow_p">
                                            <p className="listite_centre">
                                              {data.description}
                                            </p>
                                          </Td>
                                          <Td
                                            size="2.5"
                                            className="list_flow_p"
                                          >
                                            <p className="listite_centre">
                                              {data.is_authorized === false ? 'Draft' :
                                                (!data.teams_deploy
                                                  ? 'Draft'
                                                  : data.teams_deploy.is_active
                                                  ? 'Active'
                                                  : 'Inactive')
                                              }
                                            </p>
                                          </Td>
                                          <Td
                                            size="2.5"
                                            className="list_flow_span"
                                          >
                                            <span className="entity_span">
                                              {/* {data.teams_deploy ?
                                                  formatDateInCard(
                                                   data.teams_deploy.date_added)
                                                    :
                                                    "--"
                                                } */}
                                              {formatDateInCard(
                                                data.updated_at
                                              )}
                                            </span>
                                            <span>|</span>
                                            <span className="entity_span">
                                              {/* {data.teams_deploy ? 
                                                formatAMPM(
                                                    data.teams_deploy.date_added)
                                                    :
                                                    "--"
                                                } */}
                                              {formatAMPM(data.updated_at)}
                                            </span>
                                            {data.client_secret_active === false ? (
                                                <span className="alerticon">
                                                    <Tooltip
                                                        className="target customTip"
                                                        zIndex={10000}
                                                        arrowSize={8}
                                                        tagName="span"
                                                        content={'The client secret has expired/changed. Please update it for the bot to work.'}
                                                        distance={5}
                                                    >
                                                        <WarningAlertIcon />
                                                    </Tooltip>
                                                </span>
                                             ):""}
                                          </Td>
                                          <Td size="1">
                                            {state.PageState.action !==
                                              Action.Select &&
                                              state.PageState.action !==
                                                Action.LineDelete && (
                                                <ToolBox>
                                                  {writePermission.value&&<span
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      setToEditMode(
                                                        data.app_id
                                                      );
                                                    }}
                                                  >
                                                    <EditIcon />
                                                  </span>}
                                                  {data.teams_deploy === null && deletePermission.value && (
                                                    <span
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        setDeleteMode(
                                                          data.app_id
                                                        );
                                                      }}
                                                    >
                                                      <DeleteIcon />
                                                    </span>
                                                  )}
                                                </ToolBox>
                                              )}
                                          </Td>
                                        </>
                                      );
                                    }}
                                  />
                                </Tr>
  )
}