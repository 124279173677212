import Downshift from 'downshift'
/*eslint-disable*/
import React, { useEffect, useState } from 'react'
import Tooltip from 'react-tooltip-lite'

import { SettingsApi } from '@/DialogEditor/Home/triggers'
import { SwitchWrapper } from '@/Flows/Card'
import { VideoButtonB } from '@/Videos/Videos'
import {
    DropdownWithCircle,
    FaqICon,
    InfoIcon,
    NewTrymeIcon,
    SubPanelIcons,
    ThumbsDownIcon,
    ThumbsUpIcon
} from '@/common/Icons/Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { Header, HeaderChild } from '@/common/components/Header'
import { Loader } from '@/common/components/Loader'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import { ButtonContainer, Header as AgentSettingsHeader, SmallButton, Paragraph } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { TabContainer, TabHeader, TabRow } from '@/common/styled/Settings.Dumb'
import {
    CancelButton,
    DropdownLi,
    DropdownUl,
    DropdownUlWrapper,
    ErrorMessage,
    Input,
    InputContainer,
    InputContent,
    InputWrapper
} from '@/common/styled/Workflow.Analytics.Dumb'

import { getJson, logEvent, putJson, settingsApiURL } from '@/common/utils/api-utils'
import { AssistantT, DumpAssistant } from '../Construction/kind'
import { DialogueT } from '../Dialogue/kind'
import { sortBySibling } from '../DialogueCrew/action'
//ACTION IMPORTS
import {
    getAgentSettings,
    getAssistants,
    getDialogueRoots,
    getSettings,
    updateAgentSettings,
    updateSettings
} from '../Mechanisms/action'
import { HeaderWithSwitch } from '../DialogueCrew/Nodes/Exit'

type settingsT = {
    on_boarding_message: string
    off_boarding_message: string
    service_not_available: string

    end_conversation_message: string
    end_conversation_time: number

    feedback_message: string
    satisfied_feedback: string
    not_satisfied_feedback: string
    else_feedback_message: string
    disambiguation_message: string
    enable_disambiguation: boolean
    disambigutation_nota_enabled: true
    disambigutation_nota_option_text: string
    disambigutation_nota_handle_node: null | string
    id: number
    session_termination_reminder_time: null | number,
    session_termination_reminder_message: string
}

type AgentSettings = {
    bot_id: string
    user_in_queue: string
    user_connected: string
    agent_connected: string
    communication_error: string
    time_out: number
    queue_time_exceed: string
    created_at: string
    updated_at: string
    no_agents_available_message:string
    agent_details_show:boolean
}

const initSettings: settingsT = {
    on_boarding_message: '',
    off_boarding_message: '',
    service_not_available: '',

    end_conversation_message: '',
    end_conversation_time: 5,

    feedback_message: '',
    satisfied_feedback: '',
    not_satisfied_feedback: '',
    else_feedback_message: '',
    disambiguation_message: '',
    enable_disambiguation: false,
    disambigutation_nota_handle_node: null,
    disambigutation_nota_enabled: true,
    disambigutation_nota_option_text: 'None of the above',
    id: 0,
    session_termination_reminder_time : null,
    session_termination_reminder_message : ""
}

const initialAgentSettings: AgentSettings = {
    bot_id: '',
    user_in_queue: '',
    user_connected: '',
    agent_connected: '',
    communication_error: '',
    time_out: 5,
    queue_time_exceed: '',
    created_at: '',
    updated_at: '',
    no_agents_available_message:'',
    agent_details_show:false
}

type TextBoxP = {
    message: string
    value: string
    setValue: Function
    info?: string
    showTooltip?: boolean
}

const TextBox = (props: TextBoxP) => {
    const { message, value, setValue, info, showTooltip } = props
    return (
        <InputContainer style={{marginBottom: 20}}>
            <Input onChange={(e: any) => setValue(e.target.value)} value={value} />
            {/* {info ? <p>{info}</p> : null} */}
            <InputContent className="d-flex">
                {message}{' '}
                {showTooltip ? (
                    <Tooltip
                        className="target customTip"
                        zIndex={10000}
                        arrowSize={8}
                        tagName="span"
                        content="Message to display to the user before ending a session if the user types something without choosing any of the feedback options."
                        distance={5}
                    >
                        <InfoIcon />
                    </Tooltip>
                ) : null}
            </InputContent>
        </InputContainer>
    )
}

type DropDownP = {
    value: number
    setValue: Function
    currentTab: 'assistant' | 'agent'
}

const DropDown = (props: DropDownP) => {
    const { value, setValue } = props

    const [isOpen, setOpen] = useState<boolean>(false)
    const selection = (x: number) => {
        setOpen(false)
        setValue(x)
    }
    const getValue = (x: number): string => `${x} Mins`
    return (
        <div className="dialog_settings_content">
            <div className="dialog_settings_dropdown">
                <InputContainer style={{marginBottom: 20}}>
                    <Input type="text" onClick={() => setOpen(true)} onChange={() => {}} value={getValue(value)} />
                    <InputContent>
                        {' '}
                        {props.currentTab == 'agent'
                            ? 'Time out duration'
                            : 'End the session if there is no response from the user in'}
                    </InputContent>
                    <CancelButton className="d-flex" onClick={() => setOpen(true)} style={{ top: 15 }}>
                        <DropdownWithCircle />
                    </CancelButton>
                    <DropdownUlWrapper className="mb-0">
                        {isOpen == true ? (
                            <Downshift isOpen={isOpen == true} onOuterClick={() => setOpen(false)}>
                                {() => (
                                    <div
                                        className="dropdown_right_entity"
                                        role="combobox"
                                        aria-expanded="true"
                                        aria-haspopup="listbox"
                                        aria-owns="downshift-1-menu"
                                        aria-labelledby="downshift-1-label"
                                    >
                                        <div className="dialog_scroll_list">
                                            <DropdownUl>
                                                <DropdownLi onClick={() => selection(5)}>5 Mins</DropdownLi>
                                                <DropdownLi onClick={() => selection(10)}>10 Mins</DropdownLi>
                                                <DropdownLi onClick={() => selection(15)}>15 Mins</DropdownLi>
                                                <DropdownLi onClick={() => selection(30)}>30 Mins</DropdownLi>
                                            </DropdownUl>
                                        </div>
                                    </div>
                                )}
                            </Downshift>
                        ) : null}
                    </DropdownUlWrapper>
                </InputContainer>
            </div>
        </div>
    )
}

type DropDownForRemainderP = {
    value: number|null
    setValue: Function
    currentTab: 'assistant' | 'agent'
    endTime: number
}

const remainders = [3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28]

const DropDownForRemainder = (props: DropDownForRemainderP) => {
    const { value, setValue } = props

    const [isOpen, setOpen] = useState<boolean>(false)
    const selection = (x: number) => {
        setOpen(false)
        setValue(x)
    }
    const getValue = (x: number): string => `${x} Mins`
    return (
        <div className="dialog_settings_content">
            <div className="dialog_settings_dropdown">
                <InputContainer style={{marginBottom: 20}}>
                    <Input type="text" onClick={() => setOpen(true)} onChange={() => {}} value={value ? getValue(value) : "" } />
                    <InputContent>
                        {' '}
                        {'Send user prompt if there is no response from the end user in'}
                    </InputContent>
                    <CancelButton className="d-flex" onClick={() => setOpen(true)} style={{ top: 15 }}>
                        <DropdownWithCircle />
                    </CancelButton>
                    <DropdownUlWrapper className="mb-0">
                        {isOpen == true ? (
                            <Downshift isOpen={isOpen == true} onOuterClick={() => setOpen(false)}>
                                {() => (
                                    <div
                                        className="dropdown_right_entity"
                                        role="combobox"
                                        aria-expanded="true"
                                        aria-haspopup="listbox"
                                        aria-owns="downshift-1-menu"
                                        aria-labelledby="downshift-1-label"
                                    >
                                        <div className="dialog_scroll_list">
                                            <DropdownUl>
                                                {remainders.filter((v)=>v<=(props.endTime-2)).map((val)=>(<DropdownLi onClick={() => selection(val)}>{val} Mins</DropdownLi>))}
                                            </DropdownUl>
                                        </div>
                                    </div>
                                )}
                            </Downshift>
                        ) : null}
                    </DropdownUlWrapper>
                </InputContainer>
            </div>
        </div>
    )
}

const CommonDropDown = (props: { value: string | null; setValue: Function; values: DialogueT[], bot:AssistantT, disabled?:boolean }) => {
    const { value, setValue, values, disabled, bot } = props

    const [isOpen, setOpen] = useState<boolean>(false)
    const selection = (x: any) => {
        setOpen(false)
        setValue(x)
    }
    const selectedDialog = value
        ? values.filter(v => v.uuid == value)[0]
        : values.filter(v => v.type == 'if_node' && v.uuid == bot.welcome_node)[0]

    return (
        <div className="dialog_settings_content">
            <div className="dialog_settings_dropdown">
                <InputContainer  style={{marginBottom: 20}}>
                    <Input type="text" onClick={() => setOpen(true)} onChange={() => {}} value={selectedDialog.title} disabled={disabled} />
                    <InputContent>
                        {'Jump to "Welcome" dialog if user selects "None of the above" option.'}
                    </InputContent>
                    {!disabled &&
                        <CancelButton className="d-flex" onClick={() => setOpen(true)} style={{ top: 15 }}>
                            <DropdownWithCircle />
                        </CancelButton>
                    }
                    <DropdownUlWrapper className="mb-0">
                        {isOpen == true ? (
                            <Downshift isOpen={isOpen == true} onOuterClick={() => setOpen(false)}>
                                {() => (
                                    <div
                                        className="dropdown_right_entity"
                                        role="combobox"
                                        aria-expanded="true"
                                        aria-haspopup="listbox"
                                        aria-owns="downshift-1-menu"
                                        aria-labelledby="downshift-1-label"
                                    >
                                        <div className="dialog_scroll_list">
                                            <DropdownUl>
                                                {values.map(value => (
                                                    <DropdownLi onClick={() => selection(value)}>
                                                        {value.title}
                                                    </DropdownLi>
                                                ))}
                                            </DropdownUl>
                                        </div>
                                    </div>
                                )}
                            </Downshift>
                        ) : null}
                    </DropdownUlWrapper>
                </InputContainer>
            </div>
        </div>
    )
}
export function DialogSettings(props: any) {
    const [currentTab, setTab] = useState<'assistant' | 'agent'>('assistant')

    const [initialSetting, setInitialSetting] = useState<settingsT>(initSettings)
    const [initialAgentSetting, setInitialAgentSetting] = useState<AgentSettings>(initialAgentSettings)

    const [unauthorized, setUnauthorized] = useState<boolean>(false)

    const [pageLoading, setPageLoading] = useState<boolean>(true)

    const [pageError, setPageError] = useState({
        error: false,
        info: 'There was a problem in loading Page! <br/> Please click on "Retry"'
    })

    const [setting, assignSettings] = useState<settingsT>(initSettings)
    const [agentSetting, assignAgentSettings] = useState<AgentSettings>(initialAgentSetting)

    const [init, setInit] = useState<boolean>(true)

    const [botId, setBotId] = useState<string>('')

    const [bot,setBot] = useState(DumpAssistant)

    const [modified, setModified] = useState<boolean>(false)

    const [rootDialogs, setRootDialogs] = useState<DialogueT[]>([])

    const setSetting = (data: settingsT | AgentSettings) => {
        currentTab == 'assistant' ? assignSettings(data as settingsT) : assignAgentSettings(data as AgentSettings)
    }

    useEffect(() => {
        if (init === false) {
            if (currentTab == 'assistant') {
                if (
                    setting.on_boarding_message !== initialSetting.on_boarding_message ||
                    setting.off_boarding_message !== initialSetting.off_boarding_message ||
                    setting.service_not_available !== initialSetting.service_not_available ||
                    setting.end_conversation_message !== initialSetting.end_conversation_message ||
                    setting.end_conversation_time !== initialSetting.end_conversation_time ||
                    setting.feedback_message !== initialSetting.feedback_message ||
                    setting.satisfied_feedback !== initialSetting.satisfied_feedback ||
                    setting.not_satisfied_feedback !== initialSetting.not_satisfied_feedback ||
                    setting.else_feedback_message !== initialSetting.else_feedback_message ||
                    setting.disambiguation_message !== initialSetting.disambiguation_message ||
                    setting.enable_disambiguation !== initialSetting.enable_disambiguation ||
                    setting.disambigutation_nota_handle_node !== initialSetting.disambigutation_nota_handle_node||
                    setting.session_termination_reminder_message !== initSettings.session_termination_reminder_message ||
                    setting.session_termination_reminder_time !== initSettings.session_termination_reminder_time
                ) {
                    setModified(true)
                } else {
                    setModified(false)
                }
            } else {
                if (
                    agentSetting.agent_connected !== initialAgentSetting.agent_connected ||
                    agentSetting.communication_error !== initialAgentSetting.communication_error ||
                    agentSetting.queue_time_exceed !== initialAgentSetting.queue_time_exceed ||
                    agentSetting.time_out !== initialAgentSetting.time_out ||
                    agentSetting.user_connected !== initialAgentSetting.user_connected ||
                    agentSetting.user_in_queue !== initialAgentSetting.user_in_queue ||
                    agentSetting.no_agents_available_message !== initialAgentSetting.no_agents_available_message ||
                    agentSetting.agent_details_show !== initialAgentSetting.agent_details_show
                ) {
                    setModified(true)
                } else {
                    setModified(false)
                }
            }
        }
    }, [setting, agentSetting])

    const { workspacename, assistantname } = props.match.params

    let disableState =
        setting.on_boarding_message.length > 255 ||
        setting.off_boarding_message.length > 255 ||
        setting.service_not_available.length > 255 ||
        setting.end_conversation_message.length > 255 ||
        setting.feedback_message.length > 255 ||
        setting.satisfied_feedback.length > 255 ||
        setting.not_satisfied_feedback.length > 255 ||
        setting.else_feedback_message.length > 255 ||
        setting.disambiguation_message.length > 255 ||
        setting.session_termination_reminder_message.length > 255 ||
        agentSetting.agent_connected.length > 255 ||
        agentSetting.communication_error.length > 255 ||
        agentSetting.user_connected.length > 255 ||
        agentSetting.user_in_queue.length > 255||
        agentSetting.no_agents_available_message.length>255 ||
        setting.session_termination_reminder_time == null

    const fetchSettings = () => {
        setPageLoading(true)
        getAssistants(workspacename)
            .then((res: AssistantT[]) => {
                const assistant:AssistantT = res.reduce((accum: AssistantT, val: AssistantT) => {
                    if (val.name == assistantname) return val
                    else return accum
                }, DumpAssistant)
                setBot(assistant)
                if (assistant.id === '') {
                    setPageError({
                        ...pageError,
                        error: true
                    })
                } else {
                    setBotId(assistant.id)
                    Promise.all([
                        getSettings(workspacename, assistant.id),
                        getAgentSettings(workspacename, assistant.id),
                        getDialogueRoots(workspacename, assistant.id)
                    ])
                        .then(([assistant, agent, dialogRootData]: [settingsT, AgentSettings, DialogueT[]]) => {
                            const {
                                on_boarding_message,
                                off_boarding_message,
                                service_not_available,
                                end_conversation_message,
                                end_conversation_time,
                                feedback_message,
                                satisfied_feedback,
                                not_satisfied_feedback,
                                else_feedback_message,
                                disambiguation_message,
                                enable_disambiguation,
                                disambigutation_nota_enabled,
                                disambigutation_nota_option_text,
                                disambigutation_nota_handle_node,
                                id,
                                session_termination_reminder_message,
                                session_termination_reminder_time
                            } = assistant

                            assignSettings({
                                on_boarding_message,
                                off_boarding_message,
                                service_not_available,
                                end_conversation_message,
                                end_conversation_time,
                                feedback_message,
                                satisfied_feedback,
                                not_satisfied_feedback,
                                else_feedback_message,
                                disambiguation_message,
                                enable_disambiguation,
                                disambigutation_nota_enabled,
                                disambigutation_nota_option_text,
                                disambigutation_nota_handle_node,
                                id,
                                session_termination_reminder_message,
                                session_termination_reminder_time
                            })

                            setPageError({
                                ...pageError,
                                error: false
                            })

                            setInitialSetting({
                                on_boarding_message,
                                off_boarding_message,
                                service_not_available,
                                end_conversation_message,
                                end_conversation_time,
                                feedback_message,
                                satisfied_feedback,
                                not_satisfied_feedback,
                                else_feedback_message,
                                disambiguation_message,
                                enable_disambiguation,
                                disambigutation_nota_enabled,
                                disambigutation_nota_option_text,
                                disambigutation_nota_handle_node,
                                id,
                                session_termination_reminder_message,
                                session_termination_reminder_time
                            })

                            assignAgentSettings(agent)

                            setInitialAgentSetting(agent)

                            setRootDialogs(sortBySibling(dialogRootData))

                            setPageLoading(false)

                            setModified(false)
                            setInit(false)
                        })
                        .catch((err: any) => {
                            if (err && err.response && err.response.status == 403) {
                                setUnauthorized(true)
                            } else {
                                setPageError({
                                    ...pageError,
                                    error: true
                                })
                            }
                        })
                }
            })
            .catch((err: any) => {
                setPageError({
                    ...pageError,
                    error: true
                })
            })
    }

    useEffect(() => {
        fetchSettings()
    }, [])

    const save = (data: settingsT | AgentSettings) => {
        const workspacename = props.match.params.workspacename
        setPageLoading(true)
        currentTab == 'assistant'
            ? updateSettings(workspacename, botId, data)
                  .then((e: any) => {
                      logEvent(workspacename, {
                        event:"EVENT::SETTINGS::UPDATE", event_meta:{}
                      })
                      fetchSettings()
                  })
                  .catch((err: any) => {
                      setPageError({
                          ...pageError,
                          error: true
                      })
                  })
            : updateAgentSettings(workspacename, botId, data)
                  .then((e: any) => {
                      fetchSettings()
                  })
                  .catch((err: any) => {
                      setPageError({
                          ...pageError,
                          error: true
                      })
                  })
    }
    const FeedbackTip = () => (
        <p>
            If you have turned on ‘Feedback’ in your bot dialogs, you can customize your feedback messages{' '}
            <a
                className="sidepanel_href_color"
                target="_blank"
                href="https://help.workativ.com/knowledgebase/assistant-settings/"
            >
                here
            </a>
            .
        </p>
    )

    return (
        <ErrorBoundary
            error={pageError}
            unauthorized={unauthorized}
            render={(err: any, info: any) => {
                return err && <Error.PageLoadingError onClick={fetchSettings} info={info} btnName="Retry" />
            }}
        >
            {pageLoading ? (
                <Loader.PageLoader show={true} />
            ) : (
                <React.Fragment>
                    <Header>
                        {[
                            <HeaderChild type={'first'}>
                                <div className="indent_left">
                                    <div className="indent_title mb-0">Chatbot Settings</div>
                                </div>
                            </HeaderChild>,
                            <HeaderChild type={'second'}>
                                <VideoButtonB id={'CONFIGURE_CHATBOT_SETTINGS'} />
                            </HeaderChild>
                        ]}
                    </Header>
                    <section className="entity_cards pb-4">
                        <div className="container-fluid page__center">
                            <div className="row">
                                <div className="dialog_settings">
                                    <TabContainer>
                                        <TabRow>
                                            <TabHeader
                                                active={currentTab == 'assistant'}
                                                onClick={() => setTab('assistant')}
                                            >
                                                Configure your Chatbot
                                            </TabHeader>
                                            <TabHeader active={currentTab == 'agent'} onClick={() => setTab('agent')}>
                                                Configure your Agent Handover
                                            </TabHeader>
                                        </TabRow>
                                    </TabContainer>

                                    {currentTab == 'assistant' ? (
                                        <>
                                            <div className="dialog_settings_body">
                                                <div className="dialog_settings_body_bg">
                                                    <div className="dialog_settings_body_pad">
                                                        <h5>
                                                            Messages to display on chat channels (e.g., Slack or Teams){' '}
                                                        </h5>
                                                        <InputWrapper>
                                                            {setting.on_boarding_message.length > 255 && (
                                                                <ErrorMessage>Text limit is Exceeded!</ErrorMessage>
                                                            )}
                                                            <TextBox 
                                                                message={
                                                                    'Chatbot onboarding message (after bot deployment)'
                                                                }
                                                                value={setting.on_boarding_message}
                                                                setValue={(value: string) =>
                                                                    setSetting({
                                                                        ...setting,
                                                                        on_boarding_message: value
                                                                    })
                                                                }
                                                                
                                                            />
                                                        </InputWrapper>
                                                        {setting.off_boarding_message.length > 255 && (
                                                            <ErrorMessage>Text limit is Exceeded!</ErrorMessage>
                                                        )}
                                                        <TextBox
                                                            message={
                                                                'Chatbot off-boarding message (when bot is removed)'
                                                            }
                                                            value={setting.off_boarding_message}
                                                            setValue={(value: string) =>
                                                                setSetting({
                                                                    ...setting,
                                                                    off_boarding_message: value
                                                                })
                                                            }
                                                        />
                                                        {setting.service_not_available.length > 255 && (
                                                            <ErrorMessage>Text limit is Exceeded!</ErrorMessage>
                                                        )}
                                                        <TextBox
                                                            message={'Message for end-user (if chatbot is unavailable)'}
                                                            value={setting.service_not_available}
                                                            setValue={(value: string) =>
                                                                setSetting({
                                                                    ...setting,
                                                                    service_not_available: value
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                    <div className="chat_message_wrapper">
                                                        <div className="tryit_understand_parent ">
                                                            <span>
                                                                <NewTrymeIcon />
                                                            </span>
                                                            <div className="tryit_bot_msg">
                                                                <p className="tryit_understand">
                                                                    Hello, I’m your workplace bot to help you with your
                                                                    workplace support issues
                                                                    <span className="time_stamp">10:12 am</span>
                                                                </p>
                                                                <svg
                                                                    x="0px"
                                                                    y="0px"
                                                                    viewBox="0 0 7.9 7.9"
                                                                    xmlSpace="preserve"
                                                                >
                                                                    <path
                                                                        d="M7.9 8c0-4.4-3.6-8-8-8h8v8z"
                                                                        fill="currentColor"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dialog_settings_body">
                                                <div className="dialog_settings_body_bg">
                                                    <div className="dialog_settings_body_pad">
                                                        <h5>
                                                            Disambiguation{' '}
                                                            <Tooltip
                                                                className="target customTip settings_info"
                                                                zIndex={10000}
                                                                arrowSize={8}
                                                                tagName="span"
                                                                content="When you enable disambiguation, you instruct the chatbot to
                                                                ask end-users to clarify when the bot finds that more than one
                                                                dialog or FAQ can respond to user input or query."
                                                                distance={5}
                                                            >
                                                                <InfoIcon />
                                                            </Tooltip>
                                                            <SwitchWrapper>
                                                                <input
                                                                    type="checkbox"
                                                                    className="switch_1 automation_home_switch_without_hover settings_page"
                                                                    checked={setting.enable_disambiguation}
                                                                    onChange={() =>
                                                                        setSetting({
                                                                            ...setting,
                                                                            enable_disambiguation: !setting.enable_disambiguation
                                                                        })
                                                                    }
                                                                />
                                                            </SwitchWrapper>
                                                        </h5>

                                                        <TextBox
                                                            message={
                                                                'Provide a text to prompt the user when multiple dialogs/FAQs can respond to the user input or query.'
                                                            }
                                                            value={setting.disambiguation_message}
                                                            setValue={(value: string) =>
                                                                setSetting({
                                                                    ...setting,
                                                                    disambiguation_message: value
                                                                })
                                                            }
                                                        />
                                                        <CommonDropDown
                                                            value={setting.disambigutation_nota_handle_node}
                                                            // setValue={(value: any) =>
                                                            //     setSetting({
                                                            //         ...setting,
                                                            //         disambigutation_nota_handle_node: value.uuid
                                                            //     })
                                                            // }
                                                            setValue={()=>{}}
                                                            disabled
                                                            values={rootDialogs.filter((d)=>d.conditions&&d.uuid==bot.welcome_node)}
                                                            bot={bot}
                                                        />
                                                    </div>
                                                    <div className="chat_message_wrapper settings_button_section">
                                                        <div className="tryit_understand_parent ">
                                                            <span>
                                                                <NewTrymeIcon />
                                                            </span>
                                                            <div className="tryit_bot_msg">
                                                                <p className="tryit_understand">
                                                                    There are multiple options for your request. What
                                                                    would you like to do?
                                                                    <span className="time_stamp">10:12 am</span>
                                                                </p>
                                                                <svg
                                                                    x="0px"
                                                                    y="0px"
                                                                    viewBox="0 0 7.9 7.9"
                                                                    xmlSpace="preserve"
                                                                >
                                                                    <path
                                                                        d="M7.9 8c0-4.4-3.6-8-8-8h8v8z"
                                                                        fill="currentColor"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <ButtonContainer
                                                            padding
                                                            className="pb-0 bg-transparent settings_buttons"
                                                        >
                                                            <SmallButton primary>
                                                                <span>
                                                                    <SubPanelIcons.BotIcon />
                                                                </span>
                                                                <label>Unlock Account</label>
                                                            </SmallButton>
                                                            <SmallButton primary>
                                                                <span>
                                                                    <FaqICon />
                                                                </span>
                                                                <label>Account Access</label>
                                                            </SmallButton>
                                                        </ButtonContainer>
                                                        <ButtonContainer
                                                            padding
                                                            className="pb-0 bg-transparent settings_buttons left_align"
                                                            align="left"
                                                        >
                                                            <SmallButton primary>
                                                                <label>
                                                                    {setting.disambigutation_nota_option_text}
                                                                </label>
                                                            </SmallButton>
                                                        </ButtonContainer>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dialog_settings_body">
                                                <div className="dialog_settings_body_bg">
                                                    <div className="dialog_settings_body_pad">
                                                        <h5>
                                                            Session expiry{' '}
                                                            <Tooltip
                                                                className="target customTip"
                                                                zIndex={10000}
                                                                arrowSize={8}
                                                                tagName="span"
                                                                content=" Current session will be cleared and the user will have to initiate a
                                                        new session"
                                                                distance={5}
                                                            >
                                                                <InfoIcon />
                                                            </Tooltip>
                                                        </h5>
                                                        <DropDown
                                                            value={setting.end_conversation_time}
                                                            setValue={(value: number) =>
                                                                setSetting({
                                                                    ...setting,
                                                                    end_conversation_time: value,
                                                                    session_termination_reminder_time: null
                                                                })
                                                            }
                                                            currentTab={currentTab}
                                                        />
                                                        {setting.end_conversation_message.length > 255 && (
                                                            <ErrorMessage>Text limit is Exceeded!</ErrorMessage>
                                                        )}
                                                        <TextBox
                                                            message={
                                                                'Message to display to the user after ending a session'
                                                            }
                                                            value={setting.end_conversation_message}
                                                            setValue={(value: string) =>
                                                                setSetting({
                                                                    ...setting,
                                                                    end_conversation_message: value
                                                                })
                                                            }
                                                        />
                                                        <DropDownForRemainder
                                                            value={setting.session_termination_reminder_time}
                                                            endTime={setting.end_conversation_time}
                                                            setValue={(value: number) =>
                                                                setSetting({
                                                                    ...setting,
                                                                    session_termination_reminder_time: value
                                                                })
                                                            }
                                                            currentTab={currentTab}
                                                        />
                                                        {setting.session_termination_reminder_message.length > 255 && (
                                                            <ErrorMessage>Text limit is Exceeded!</ErrorMessage>
                                                        )}
                                                        <TextBox
                                                            message={
                                                                'Prompt user before session expiry'
                                                            }
                                                            value={setting.session_termination_reminder_message}
                                                            setValue={(value: string) =>
                                                                setSetting({
                                                                    ...setting,
                                                                    session_termination_reminder_message: value
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                    <div className="chat_message_wrapper">
                                                        <div className="tryit_understand_parent ">
                                                            <span>
                                                                <NewTrymeIcon />
                                                            </span>
                                                            <div className="tryit_bot_msg">
                                                                <p className="tryit_understand">
                                                                    Your previous session has ended. Please start again
                                                                    <span className="time_stamp">10:12 am</span>
                                                                </p>
                                                                <svg
                                                                    x="0px"
                                                                    y="0px"
                                                                    viewBox="0 0 7.9 7.9"
                                                                    xmlSpace="preserve"
                                                                >
                                                                    <path
                                                                        d="M7.9 8c0-4.4-3.6-8-8-8h8v8z"
                                                                        fill="currentColor"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="dialog_settings_body _section_2">
                                                <div className="dialog_settings_body_bg">
                                                    <div className="dialog_settings_body_pad">
                                                        <h5>
                                                            Feedback
                                                            <Tooltip
                                                                className="target customTip"
                                                                zIndex={10000}
                                                                arrowSize={8}
                                                                tagName="span"
                                                                content={<FeedbackTip />}
                                                                distance={5}
                                                            >
                                                                <InfoIcon />
                                                            </Tooltip>
                                                        </h5>
                                                        {setting.feedback_message.length > 255 && (
                                                            <ErrorMessage>Text limit is Exceeded!</ErrorMessage>
                                                        )}
                                                        <TextBox
                                                            // info={'Feedback question for user'}
                                                            message={'Feedback prompt message'}
                                                            value={setting.feedback_message}
                                                            setValue={(value: string) =>
                                                                setSetting({
                                                                    ...setting,
                                                                    feedback_message: value
                                                                })
                                                            }
                                                        />

                                                        <InputWrapper>
                                                            {/* <ThumbsUpIcon /> */}
                                                            {setting.satisfied_feedback.length > 255 && (
                                                                <ErrorMessage>Text limit is Exceeded!</ErrorMessage>
                                                            )}
                                                            <InputContainer style={{marginBottom: 20}}>
                                                                <Input
                                                                    onChange={(e: any) =>
                                                                        setSetting({
                                                                            ...setting,
                                                                            satisfied_feedback: e.target.value
                                                                        })
                                                                    }
                                                                    value={setting.satisfied_feedback}
                                                                />
                                                                <InputContent>
                                                                    Message to display if user selects satisfied option
                                                                </InputContent>
                                                            </InputContainer>
                                                        </InputWrapper>
                                                        <InputWrapper>
                                                            {/* <ThumbsDownIcon /> */}
                                                            {setting.not_satisfied_feedback.length > 255 && (
                                                                <ErrorMessage>Text limit is Exceeded!</ErrorMessage>
                                                            )}
                                                            <InputContainer style={{marginBottom: 20}}>
                                                                <Input
                                                                    onChange={(e: any) =>
                                                                        setSetting({
                                                                            ...setting,
                                                                            not_satisfied_feedback: e.target.value
                                                                        })
                                                                    }
                                                                    value={setting.not_satisfied_feedback}
                                                                />
                                                                <InputContent>
                                                                    Message to display if user selects not satisfied
                                                                    option
                                                                </InputContent>
                                                            </InputContainer>
                                                        </InputWrapper>
                                                        <div className="info_with_content">
                                                            {setting.else_feedback_message.length > 255 && (
                                                                <ErrorMessage>Text limit is Exceeded!</ErrorMessage>
                                                            )}
                                                            <TextBox
                                                                info={
                                                                    'Message to display to the user before ending a session if the user opts out of feedback.'
                                                                }
                                                                message={'Anything else:'}
                                                                showTooltip
                                                                value={setting.else_feedback_message}
                                                                setValue={(value: string) =>
                                                                    setSetting({
                                                                        ...setting,
                                                                        else_feedback_message: value
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="chat_message_wrapper">
                                                        <div className="tryit_understand_parent ">
                                                            <span>
                                                                <NewTrymeIcon />
                                                            </span>
                                                            <div className="tryit_bot_msg">
                                                                <p className="tryit_understand">
                                                                    Please provide your feedback
                                                                    <span className="time_stamp">10:12 am</span>
                                                                </p>
                                                                <svg
                                                                    x="0px"
                                                                    y="0px"
                                                                    viewBox="0 0 7.9 7.9"
                                                                    xmlSpace="preserve"
                                                                >
                                                                    <path
                                                                        d="M7.9 8c0-4.4-3.6-8-8-8h8v8z"
                                                                        fill="currentColor"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <ButtonContainer padding className="pb-0 bg-transparent">
                                                            <SmallButton primary>
                                                                <span>
                                                                    <ThumbsUpIcon />
                                                                </span>
                                                                <label>Satisfied</label>
                                                            </SmallButton>
                                                            <SmallButton primary>
                                                                <span>
                                                                    <ThumbsDownIcon />
                                                                </span>
                                                                <label>Not satisfied</label>
                                                            </SmallButton>
                                                        </ButtonContainer>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="dialog_settings_body">
                                                <div className="dialog_settings_body_bg">
                                                    <div className="dialog_settings_body_pad">
                                                        <h5>
                                                            Messages to display to end users on chat channels (e.g.,
                                                            Slack, Teams or Widget)
                                                        </h5>
                                                        {agentSetting.user_in_queue.length > 255 && (
                                                            <ErrorMessage>Text limit is Exceeded!</ErrorMessage>
                                                        )}
                                                        <TextBox
                                                            message={'When user is moved to the queue'}
                                                            value={agentSetting.user_in_queue}
                                                            setValue={(value: string) =>
                                                                setSetting({
                                                                    ...agentSetting,
                                                                    user_in_queue: value
                                                                })
                                                            }
                                                        />
                                                        {agentSetting.agent_connected.length > 255 && (
                                                            <ErrorMessage>Text limit is Exceeded!</ErrorMessage>
                                                        )}
                                                        <TextBox
                                                            message={'When user is connected to a live agent'}
                                                            value={agentSetting.agent_connected}
                                                            setValue={(value: string) =>
                                                                setSetting({
                                                                    ...agentSetting,
                                                                    agent_connected: value
                                                                })
                                                            }
                                                        />
                                                        {agentSetting.user_connected.length > 255 && (
                                                            <ErrorMessage>Text limit is Exceeded!</ErrorMessage>
                                                        )}
                                                        <TextBox
                                                            message={'When live agent is connected to a user'}
                                                            value={agentSetting.user_connected}
                                                            setValue={(value: string) =>
                                                                setSetting({
                                                                    ...agentSetting,
                                                                    user_connected: value
                                                                })
                                                            }
                                                        />
                                                        {agentSetting.no_agents_available_message.length > 255 && (
                                                            <ErrorMessage>Text limit is Exceeded!</ErrorMessage>
                                                        )}
                                                        <TextBox
                                                            message={
                                                                'Message to display when agents are not available'
                                                            }
                                                            value={agentSetting.no_agents_available_message}
                                                            setValue={(value: string) =>
                                                                setSetting({
                                                                    ...agentSetting,
                                                                    no_agents_available_message: value
                                                                })
                                                            }
                                                        />
                                                        {agentSetting.communication_error.length > 255 && (
                                                            <ErrorMessage>Text limit is Exceeded!</ErrorMessage>
                                                        )}
                                                        <TextBox
                                                            message={
                                                                'When error occurs whilst user communicating with live agent'
                                                            }
                                                            value={agentSetting.communication_error}
                                                            setValue={(value: string) =>
                                                                setSetting({
                                                                    ...agentSetting,
                                                                    communication_error: value
                                                                })
                                                            }
                                                        />
                                                        <HeaderWithSwitch>
                                                            <AgentSettingsHeader style={{ width:"28%" }}>
                                                            Display agent details{' '}
                                                                <div className="popup_show_hide agent_handover_setting">
                                                                    <div className="show_hide">
                                                                        <div className="switch_box box_1">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="switch_1"
                                                                                checked={agentSetting.agent_details_show}
                                                                                onChange={()=>{}}
                                                                                onClick={()=>{
                                                                                    setSetting({
                                                                                        ...agentSetting,
                                                                                        agent_details_show: !agentSetting.agent_details_show
                                                                                    })
                                                                                }}
                                                                                // onChange={() => setNeedFeedBack(!needFeedBack)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </AgentSettingsHeader>
                                                            <Paragraph>
                                                                Show connected agent details to the enduser in chat.
                                                            </Paragraph>
                                                    </HeaderWithSwitch>
                                                    </div>
                                                    <div className="chat_message_wrapper">
                                                        <div className="tryit_understand_parent ">
                                                            <span>
                                                                <NewTrymeIcon />
                                                            </span>
                                                            <div className="tryit_bot_msg">
                                                                <p className="tryit_understand">
                                                                    Sure, I’m connecting you to a live agent. Please
                                                                    wait.
                                                                    <span className="time_stamp">10:12 am</span>
                                                                </p>
                                                                <svg
                                                                    x="0px"
                                                                    y="0px"
                                                                    viewBox="0 0 7.9 7.9"
                                                                    xmlSpace="preserve"
                                                                >
                                                                    <path
                                                                        d="M7.9 8c0-4.4-3.6-8-8-8h8v8z"
                                                                        fill="currentColor"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className="dialog_settings_body">
                                                <h5>When to timeout</h5>
                                                <div className="dialog_settings_body_bg">
                                                    <div className="dialog_settings_body_pad">
                                                        <DropDown
                                                            value={agentSetting.time_out}
                                                            setValue={(value: number) =>
                                                                setSetting({
                                                                    ...agentSetting,
                                                                    time_out: value
                                                                })
                                                            }
                                                            currentTab={currentTab}
                                                        />

                                                        <TextBox
                                                            message={'Time out message to user when in Queue'}
                                                            value={agentSetting.queue_time_exceed}
                                                            setValue={(value: string) =>
                                                                setSetting({
                                                                    ...agentSetting,
                                                                    queue_time_exceed: value
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div> */}
                                        </>
                                    )}
                                    {modified === true ? (
                                        <ButtonContainer className="p-0 bg-transparent justify-content-start">
                                            <Button
                                                primary
                                                onClick={() =>
                                                    setSetting(
                                                        currentTab == 'assistant' ? initialSetting : initialAgentSetting
                                                    )
                                                }
                                            >
                                                <label>Cancel</label>
                                            </Button>
                                            <Button
                                                className={disableState ? 'editor_btn_disabled' : ''}
                                                onClick={() => save(currentTab == 'assistant' ? setting : agentSetting)}
                                            >
                                                <label>Save</label>
                                            </Button>
                                        </ButtonContainer>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </section>
                </React.Fragment>
            )}
        </ErrorBoundary>
    )
}
