import React, { useState } from 'react'

import { DropdownWithSearch } from '@/DialogEditor/TrainingModule/TrainingModule'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { Modal } from '@/common/components/Modal'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import { ButtonWrapper } from '@/common/styled/Dialogue.Dumb'
import { Input, InputContainer, InputContent, InputWrapper } from '@/common/styled/Workflow.Analytics.Dumb'

import { DropdownSearchWrapper, Paragraph } from './Constructor'

type BotPopupT = {
    modelError: any
    setModelError: Function
    NoErrorV: any
    loading: boolean
    setLoading: Function
    keycode: number
    setKeycode: Function
    setMetacode: Function
    metacode: boolean
    name: string
    setName: Function
    selectedLanguage: { name: string; type: string }
    setSelectedLanguage: Function
    ErrorState: any
    assistantAction: Function
    onCancel?: Function
    editId?: string
    setEditId?: Function
}
export default function BotPopup({
    modelError,
    setModelError,
    NoErrorV,
    loading,
    setLoading,
    keycode,
    setKeycode,
    setMetacode,
    metacode,
    name,
    setName,
    selectedLanguage,
    setSelectedLanguage,
    ErrorState,
    onCancel,
    editId,
    setEditId,
    assistantAction
}: BotPopupT) {
    const languages = [
        { name: 'English (United States)', type: 'english' },
        { name: 'Spanish', type: 'spanish' },
        { name: 'Greek', type: 'greek' },
        { name: 'Portuguese', type: 'portuguese' },
        // { name: 'German', type: 'german' },
        // { name: 'French', type: 'french' }
    ]
    let status = editId ? 'Edit' : 'Create'
    const [validName, setValidName] = useState(false)
    let isEmptyField = !validName && name !== '' && name.length < 30 && selectedLanguage.name !== ''
    const CancelPopup = () => {
        editId ? EditCancel() : onCancel && onCancel()
    }
    const EditCancel = () => {
        setEditId && setEditId('')
        setName('')
        setSelectedLanguage({ name: '', type: '' })
        setMetacode(false)
        setKeycode(13)
    }
    const onSave = () => {
        setLoading(true)
        editId ? UpdateAssistant() : CreateAssistant()
    }
    const format = /[`!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/
    const checkSpecialChar = (str: string) => format.test(str)
    const onChangeName = (event: any) => {
        if (keycode != 32 && metacode == false) {
            setName(event.target.value)
            setValidName(checkSpecialChar(event.target.value))
        }
    }
    const CreateAssistant = () => {
        assistantAction(name, selectedLanguage.type, (error: any) => {
            const errorMessage =
                error.response &&
                error.response.data &&
                error.response.data.code === 'RESOURCE_CONSUMPTION_LIMIT_EXCEEDED'
                    ? 'Maximum allowed bots limit reached, upgrade now to add more'
                    : error.response.data.message
                    ? error.response.data.message
                    : 'something went wrong'
            setLoading(false)
            setModelError({
                type: ErrorState.Valid,
                error: true,
                info: errorMessage
            })
        })
    }
    const UpdateAssistant = () => {
        assistantAction(
            editId,
            name,
            () => {
                setEditId && setEditId('')
                setName('')
                setMetacode(false)
                setKeycode(13)
                setLoading(false)
            },
            () => {
                setLoading(false)
                setModelError({
                    type: ErrorState.Valid,
                    error: true,
                    info: 'something went wrong'
                })
            }
        )
    }
    return (
        <Modal error={modelError} showPopupLoader={loading}>
            <ErrorBoundary
                error={modelError}
                render={(err: any, info: any) => {
                    return (
                        err && (
                            <Error.AddorEdit
                                onClick={() => {
                                    setModelError(NoErrorV)
                                }}
                                info={info}
                            />
                        )
                    )
                }}
            >
                <h2>{status} Chatbot</h2>
                <Paragraph style={{ margin: 0 }}>{status} chatbot name</Paragraph>
                <div style={{ marginTop: 12 }}>
                    {validName ? (
                        <small style={{ float: 'right', color: 'red' }}>Special character will not be accepted</small>
                    ) : null}
                    <InputWrapper>
                        <InputContainer>
                            <Input
                                type="text"
                                value={name}
                                onKeyDown={(e: any) => {
                                    setKeycode(e.keyCode)
                                    if (e.metaKey || e.ctrlKey) setMetacode(true)
                                    else setMetacode(false)
                                }}
                                onChange={(event: any) => onChangeName(event)}
                            />
                            <InputContent>Chatbot name</InputContent>
                        </InputContainer>
                    </InputWrapper>
                    {status === 'Create' ? (
                        <DropdownSearchWrapper>
                            <DropdownWithSearch
                                header={'Bot Language'}
                                keys={'name'}
                                returnAllData
                                placholder={'Search'}
                                value={selectedLanguage.name}
                                content={languages}
                                onClick={(language: any) => {
                                    setSelectedLanguage(language)
                                }}
                                size={"responsive"}
                            />
                        </DropdownSearchWrapper>
                    ) : null}
                    <ButtonWrapper>
                        <Button primary type="button" onClick={() => CancelPopup()}>
                            <label>Cancel</label>
                        </Button>
                        <Button
                            className={!isEmptyField ? 'editor_btn_disabled' : null}
                            type="button"
                            disabled={!isEmptyField}
                            onClick={() => onSave()}
                        >
                            <label>Save</label>
                        </Button>
                    </ButtonWrapper>
                </div>
            </ErrorBoundary>
        </Modal>
    )
}
