/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react'

import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { Loader } from '@/common/components/Loader'
import lazyGA from '@/common/google-analytics/tracking'

import { createActionDialog, updateActionDialog, updateDailouge } from '../../Actions/AsyncOperations'
import { PageContext } from '../../Actions/ReactContext'
import { JumpDialogPT, OPERATIONS, PageView } from '../../Types/PageT'
import { ErrorState, ErrorT } from '../../Types/Types'
import {
    AskQueryDialogT,
    DialogT,
    JumpDialogT,
    JumpToSelectorT,
    RecognizeDialogT,
    SlotContainerDialogT,
    TextResponseDialogT
} from '../../Types/WatsonT'
import { DropDown } from '../Common'

export const Jump = () => {
    let SEARCHELEMENT: any

    const [searchKey, setSearchKey] = useState<string>('')

    const [selector, setSelector] = useState<JumpToSelectorT>(JumpToSelectorT.body)

    const [selected, setSelected] = useState<RecognizeDialogT | null>(null)

    const [loader, setLoader] = useState<boolean>(false)

    const [error, setError] = useState<ErrorT>(ErrorState)

    const context = useContext(PageContext)

    const {
        workspace,
        PageState,
        setPageState,
        workspaceDataMixture,
        workspaceDataWF,
        fetchWorkspaceChild,
        fetchCallback
    } = context

    const WelcomeNode = workspaceDataWF.filter(e => e.conditions == 'welcome').pop()

    const FallbackNode = workspaceDataWF.filter(e => e.conditions == 'anything_else').pop()

    const RecognizeDialogs = workspaceDataWF
        .filter(e => e.conditions != 'welcome')
        .filter(e => e.conditions != 'anything_else')

    useEffect(() => {
        if (PageState.operation == OPERATIONS.JumpDialogPT && PageState.input.jump_to) {
            const proof = workspaceDataMixture
                .filter(e => e.dialog_node == PageState.input.jump_to.dialog_node)
                .pop() as RecognizeDialogT
            setSelected(proof ? proof : null)
            setSelector(PageState.input.jump_to.selector)
            SEARCHELEMENT.focus()
        }
    }, [])

    const SaveDialog = async () => {
        try {
            if (PageState.operation == OPERATIONS.JumpDialogPT && selected) {
                const { input, previousSibling, nextSibling, parent } = PageState as JumpDialogPT
                setLoader(true)
                const result = {
                    ...input,
                    jump_to: {
                        dialog_node: selected.dialog_node,
                        selector: selector
                    },
                    metadata: { hide_jump_to: false }
                } as JumpDialogT
                if (input.dialog_node == '') {
                    const response: JumpDialogT = await createActionDialog(workspace, result)
                    if (previousSibling) {
                        await updateDailouge(workspace, {
                            ...previousSibling,
                            type: previousSibling.type,
                            jump_to: {
                                dialog_node: response.dialog_node,
                                selector: JumpToSelectorT.body
                            },
                            metadata: { ...previousSibling.metadata, hide_jump_to: true }
                        } as TextResponseDialogT | AskQueryDialogT | SlotContainerDialogT)
                    } else if (parent) {
                        await updateDailouge(workspace, {
                            ...parent,
                            type: parent.type,
                            jump_to: {
                                dialog_node: response.dialog_node,
                                selector: JumpToSelectorT.body
                            },
                            metadata: { ...parent.metadata, hide_jump_to: true }
                        } as RecognizeDialogT)
                    }
                    if (nextSibling) {
                        // don't want jump to for next dialog becos next dialog won't execute
                        console.log("dialog don't execute", nextSibling)
                        // await updateDailouge(workspace, {
                        //     ...response,
                        //     jump_to: {
                        //         dialog_node: nextSibling.dialog_node,
                        //         selector: JumpToSelectorT.body
                        //     },
                        //     metadata: { hide_jump_to: true }
                        // } as JumpDialogT)
                    }
                    lazyGA().event({
                        category: 'Dialog Editor',
                        action: 'Jump to Node Created'
                    })
                } else if (PageState.input.dialog_node != '') {
                    await updateActionDialog(workspace, PageState.input.dialog_node, result)
                    lazyGA().event({
                        category: 'Dialog Editor',
                        action: 'Jump to Node Updated'
                    })
                }

                fetchCallback(() => {
                    setLoader(false)
                })
            }
        } catch (err) {
            setError({
                error: true,
                info: `Server error!<br />Please try again after sometime.`
            })
            setLoader(false)
        }
    }

    const DownshiftModel = () => (
        <div className="dropdown_jumpto">
            <div className="dropdown_move">
                <span onClick={() => setSelector(JumpToSelectorT.user_input)}>
                    Wait for user input
                    <label>
                        <input
                            type="radio"
                            className="option-input radio"
                            name="example"
                            checked={selector == JumpToSelectorT.user_input}
                            onChange={() => setSelector(JumpToSelectorT.user_input)}
                        />
                    </label>
                </span>
                <span onClick={() => setSelector(JumpToSelectorT.condition)}>
                    If bot recognizes
                    <label>
                        <input
                            type="radio"
                            className="option-input radio"
                            name="example"
                            checked={selector == JumpToSelectorT.condition}
                            onChange={() => setSelector(JumpToSelectorT.condition)}
                        />
                    </label>
                </span>
                <span className="last-child_jump" onClick={() => setSelector(JumpToSelectorT.body)}>
                    Execute dialog
                    <label>
                        <input
                            type="radio"
                            className="option-input radio"
                            name="example"
                            checked={selector == JumpToSelectorT.body}
                            onChange={() => setSelector(JumpToSelectorT.body)}
                        />
                    </label>
                </span>
            </div>
        </div>
    )

    const Representation = (dialog: RecognizeDialogT) => (
        <React.Fragment>
            {fetchWorkspaceChild(dialog).filter((e: DialogT) => e.type == 'if').length > 0 ? (
                <ul>
                    {fetchWorkspaceChild(dialog)
                        .filter((e: DialogT) => e.type == 'if')
                        .map((node: RecognizeDialogT, index: number) => (
                            <li key={index.toString()}>
                                <input type="checkbx" checked onChange={() => {}} />
                                <label className="tree_label1">
                                    <div className="jump_to" onClick={() => setSelected(node)}>
                                        <div
                                            className={
                                                selected && selected.dialog_node == node.dialog_node
                                                    ? 'jump_to_list_selected'
                                                    : 'jump_to_list'
                                            }
                                        >
                                            <h5>{node.title}</h5>
                                        </div>
                                        {selected && selected.dialog_node == node.dialog_node ? (
                                            <DropDown
                                                isOpen={selected ? selected.dialog_node == node.dialog_node : false}
                                                onOuterClick={() => {}}
                                            >
                                                {DownshiftModel()}
                                            </DropDown>
                                        ) : null}
                                    </div>
                                </label>

                                {Representation(node)}
                            </li>
                        ))}
                </ul>
            ) : null}
        </React.Fragment>
    )

    return (
        <ErrorBoundary
            error={error}
            render={(err: any, info: any) => {
                return err && <Error.Delete onClick={() => setPageState(PageView)} info={info} />
            }}
        >
            <h2>Jump</h2>
            <p>Make your chatbot jump to a specific dialog </p>
            {loader ? (
                <div className="popup_loader">
                    <Loader.PopupLoader show={loader} />
                </div>
            ) : (
                <React.Fragment>
                    <span className="form-input">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search dialog "
                            value={searchKey}
                            ref={element => {
                                SEARCHELEMENT = element
                            }}
                            onChange={e => setSearchKey(e.target.value)}
                        />
                    </span>
                    <div className="right_panel_ht">
                        {WelcomeNode && (
                            <div className="jump_to_start welcome_jumpto" onClick={() => setSelected(WelcomeNode)}>
                                <div className="jump_to_list first_add_node">
                                    <h4>Welcome</h4>

                                    {selected && selected.dialog_node == WelcomeNode.dialog_node ? (
                                        <DropDown
                                            isOpen={selected ? selected.dialog_node == WelcomeNode.dialog_node : false}
                                            onOuterClick={() => {}}
                                        >
                                            {DownshiftModel()}
                                        </DropDown>
                                    ) : null}
                                </div>
                            </div>
                        )}
                        {RecognizeDialogs.length > 0 ? (
                            <ul className="tree1 zontal_lines1">
                                {RecognizeDialogs.filter(e =>
                                    e.title ? e.title.toLowerCase().includes(searchKey.toLowerCase()) : false
                                ).map((dialog: RecognizeDialogT, index: number) => (
                                    <li className="first_add_node" key={index.toString()}>
                                        <label className="tree_label1">
                                            <div className="jump_to" onClick={() => setSelected(dialog)}>
                                                <div
                                                    className={
                                                        selected && selected.dialog_node == dialog.dialog_node
                                                            ? 'jump_to_list_selected'
                                                            : 'jump_to_list'
                                                    }
                                                >
                                                    <div className="parent_node_no">
                                                        <span className="parent_svg dialog_value">
                                                            {index < 9
                                                                ? '0' + (index + 1).toString()
                                                                : index == 9
                                                                ? '09'
                                                                : index}
                                                        </span>
                                                    </div>
                                                    <h4>{dialog.title}</h4>
                                                </div>
                                                {selected && selected.dialog_node == dialog.dialog_node ? (
                                                    <DropDown
                                                        isOpen={
                                                            selected
                                                                ? selected.dialog_node == dialog.dialog_node
                                                                : false
                                                        }
                                                        onOuterClick={() => {}}
                                                    >
                                                        {DownshiftModel()}
                                                    </DropDown>
                                                ) : null}
                                            </div>
                                        </label>
                                        {Representation(dialog)}
                                    </li>
                                ))}
                            </ul>
                        ) : null}
                        {FallbackNode && (
                            <div className="jump_to_start" onClick={() => setSelected(FallbackNode)}>
                                <div className="jump_to_list fallback first_add_node">
                                    <h4>Fallback</h4>

                                    {selected && selected.dialog_node == FallbackNode.dialog_node ? (
                                        <DropDown
                                            isOpen={selected ? selected.dialog_node == FallbackNode.dialog_node : false}
                                            onOuterClick={() => {}}
                                        >
                                            {DownshiftModel()}
                                        </DropDown>
                                    ) : null}
                                </div>
                            </div>
                        )}
                        <div className="margin-bottom-75" />
                    </div>

                    <div className="right-panel_btn">
                        <button
                            className="btn btn-primary"
                            type="button"
                            data-dismiss="modal"
                            onClick={() => setPageState(PageView)}
                        >
                            Cancel
                        </button>
                        <button
                            className={selected == null ? 'btn btn-success editor_btn_disabled' : 'btn btn-success'}
                            type="button"
                            data-dismiss="modal"
                            onClick={() => SaveDialog()}
                        >
                            Jump
                        </button>
                    </div>
                </React.Fragment>
            )}
        </ErrorBoundary>
    )
}
