import {
    AdaptiveT,
    AgentHandoffT,
    AttachmentT,
    AutomationT,
    DialogueExitT,
    DialogueT,
    OptionT,
    QuestionT,
    ResponseNodeE,
    SlotContainerT,
    TextNodeT,
    VariableValueT
} from '../Dialogue/kind'

export enum ActionT {
    VIEW,
    LOADING,
    UN_AUTHORIZED,
    RESOURCE_NOT_FOUND,
    NO_DATA_FOUND,
    ERROR,

    ASSISTANT_VIEW,
    DEPLOY,

    DELETE_RESPONSE_NODE,
    DELETE_JUMP_NODE,
    DELETE_EXIT_NODE,
    DELETE_DIALOGUE_NODE,

    IF_DIALOGUE,
    TEXT_RESPONSE,
    OPTIONS_RESPONSE,
    QUESTION_RESPONSE,
    SLOT_RESPONSE,
    ADAPTIVE_RESPONSE,
    AGENT_HANDOFF_RESPONSE,
    ATTACH_RESPONSE,
    VARIABLE_RESPONSE,
    JUMP_DIALOGUE,
    FLOW_DIALOGUE,
    EXIT_DIALOGUE
}

export interface VIEW_T {
    action: ActionT.VIEW
}

export const VIEW: VIEW_T = {
    action: ActionT.VIEW
}

export interface LOADING_T {
    action: ActionT.LOADING
}

export const LOADING: LOADING_T = {
    action: ActionT.LOADING
}

export interface UN_AUTHORIZED_T {
    action: ActionT.UN_AUTHORIZED
}

export interface RESOURCE_NOT_FOUND_T {
    action: ActionT.RESOURCE_NOT_FOUND
    resource: 'WORKSPACE' | 'ASSISTANT'
}

export interface NO_DATA_FOUND_T {
    action: ActionT.NO_DATA_FOUND
    loading: boolean
    showPopup: boolean
    title: string
}

export interface ERROR_T {
    action: ActionT.ERROR
    reason: string
}

export interface ASSISTANT_VIEW {
    action: ActionT.ASSISTANT_VIEW
}
export interface ASSISTANT_DEPLOY {
    action: ActionT.DEPLOY
    loading: boolean
}

export interface DELETE_RESPONSE_NODE {
    action: ActionT.DELETE_RESPONSE_NODE
    data: ResponseNodeE
}
export interface DELETE_JUMP_NODE {
    action: ActionT.DELETE_JUMP_NODE
    data: DialogueT
}

export interface DELETE_EXIT_NODE {
    action: ActionT.DELETE_EXIT_NODE
    data: DialogueT
}
export interface DELETE_DIALOGUE_NODE {
    action: ActionT.DELETE_DIALOGUE_NODE
    data: DialogueT
}

export interface VARIABLE_RESPONSE {
    action: ActionT.VARIABLE_RESPONSE
    data: VariableValueT
}

export interface IF_DIALOGUE {
    action: ActionT.IF_DIALOGUE
    data: DialogueT
}
export interface TEXT_RESPONSE {
    action: ActionT.TEXT_RESPONSE
    data: TextNodeT
}
export interface OPTIONS_RESPONSE {
    action: ActionT.OPTIONS_RESPONSE
    data: OptionT
}
export interface ADAPTIVE_RESPONSE {
    action: ActionT.ADAPTIVE_RESPONSE
    data: AdaptiveT
}

export interface AGENT_HANDOFF_RESPONSE {
    action: ActionT.AGENT_HANDOFF_RESPONSE
    data: AgentHandoffT
}

export interface ATTACH_RESPONSE {
    action: ActionT.ATTACH_RESPONSE
    data: AttachmentT
}
export interface QUESTION_RESPONSE {
    action: ActionT.QUESTION_RESPONSE
    data: QuestionT
}
export interface SLOT_RESPONSE {
    action: ActionT.SLOT_RESPONSE
    data: SlotContainerT
}
export interface JUMP_DIALOGUE {
    action: ActionT.JUMP_DIALOGUE
    data: DialogueT
}

export interface FLOW_DIALOGUE {
    action: ActionT.FLOW_DIALOGUE
    data: AutomationT
}
export interface EXIT_DIALOGUE {
    action: ActionT.EXIT_DIALOGUE
    data: DialogueExitT
}

export type PageStateT =
    | VIEW_T
    | LOADING_T
    | UN_AUTHORIZED_T
    | RESOURCE_NOT_FOUND_T
    | NO_DATA_FOUND_T
    | ERROR_T
    | ASSISTANT_VIEW
    | ASSISTANT_DEPLOY
    | DELETE_RESPONSE_NODE
    | DELETE_JUMP_NODE
    | DELETE_EXIT_NODE
    | DELETE_DIALOGUE_NODE
    | IF_DIALOGUE
    | TEXT_RESPONSE
    | OPTIONS_RESPONSE
    | QUESTION_RESPONSE
    | SLOT_RESPONSE
    | JUMP_DIALOGUE
    | FLOW_DIALOGUE
    | EXIT_DIALOGUE
    | ADAPTIVE_RESPONSE
    | ATTACH_RESPONSE
    | AGENT_HANDOFF_RESPONSE
    | VARIABLE_RESPONSE

// ERROR STATE START
export type ErrorT = {
    error: boolean
    info: String
}

export const ErrorState: ErrorT = {
    error: false,
    info: ''
}
