/*eslint-disable*/
import * as React from 'react'
/*eslint-disable*/
import * as ReactDOM from 'react-dom'
import { AppWithCache } from './App'
import {
    applyOnce,
    addHandlerForLogout,
    initializeAccessTokenElection,
    loadSessionStorage
} from '@/common/user-sessions/across-tabs'

// initialize logout across tabs
addHandlerForLogout(_ => {
    // console.log('Logging out...')
    location.replace(window.location.origin + '/authentication/u')
})

initializeAccessTokenElection().then(_ => {
    // document.title = '♛ ' + 'Leader'
    console.log('♛')
})

loadSessionStorage()

applyOnce().then(_ => {
    const root = document.getElementById('root') as HTMLElement
    ReactDOM.render(<AppWithCache />, root)
})
