/*eslint-disable*/
import React, { useEffect } from 'react'

//import { notification } from './mockData'
import { ArrowIcon } from '@/common/Icons/Icons'
import { SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'

import { NotificationP } from './types'
import { logEvent } from '@/common/utils/api-utils'

var InfiniteScroll = require('react-infinite-scroll-component')

const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 0 }
]

function timeSince(date: Date) {
    const seconds = Math.floor((Date.now() - date.getTime()) / 1000)
    const interval = intervals.find(i => i.seconds < seconds)
    const inte = interval ? interval : intervals[4]
    const count = Math.floor(seconds / inte.seconds)
    return `${count == Infinity ? 0 : count} ${inte.label}${count !== 1 ? 's' : ''} ago`
}

const unseenMessages = (notification: NotificationP) => notification.status == 'unread'

const seenMessages = (notification: NotificationP) => notification.status != 'unread'

export const Notification = ({
    notification,
    actionClick
}: {
    notification: NotificationP
    actionClick: (noti: NotificationP) => void
}) => {
    const notificationClass = 'notifications_panel_user'
    const readClass = notification.status == 'read' ? 'notifications_panel_user_read' : ''
    const decodedMessage = notification.message

    return (
        <div
            className={`${notificationClass} ${readClass}`}
            onClick={event => {
                actionClick(notification)
                event.stopPropagation()
            }}
        >
            <div className="notifications_panel_body_right">
                <h5>{notification.type}</h5>
                <span> {timeSince(notification.createdAt)} </span>
                <p
                    title={notification.rawMessage ? notification.rawMessage : ''}
                    dangerouslySetInnerHTML={{ __html: decodedMessage }}
                />
            </div>
        </div>
    )
}

export function NotificationPanel({
    show,
    notifications,
    markAllRead,
    notificationClick,
    fetchNotification,
    closePopup,
    hasMore,
    workspace
}: {
    show: boolean
    notifications: NotificationP[]
    markAllRead: () => void
    notificationClick: (noti: NotificationP) => void
    fetchNotification: () => void
    closePopup: () => void
    hasMore: boolean
    workspace:string
}) {
    const display = show ? 'block' : 'none'
    const doNothing = (event: any) => {
        event.stopPropagation()
    }

    useEffect(()=> {
        if(show){
            logEvent(workspace, {
                event:"EVENT::NOTIFICATION::READ", event_meta:{}
            })
        }
    },[show])

    return (
        <section className="notifications_panel" style={{ display: display }}>
            <div className="_head_notifications_panel _head_notifications_panel_z_index" onClick={closePopup}>
                <div className="notifications_panel_body" onClick={doNothing}>
                    <h4 className="notification_border-after">
                        <SmallButton onClick={closePopup} style={{ cursor: 'pointer', marginRight: 6 }}>
                            <span className="rotate_back_button">
                                <ArrowIcon />
                            </span>
                            <label>Back</label>
                        </SmallButton>
                        <div className="notifications_header">Notifications</div>
                    </h4>
                    {notifications.length > 0 ? (
                        <div
                            className="notifications_panel_scrol"
                            onClick={doNothing}
                            id="scrollableDiv"
                            style={{ height: 'calc(100vh - 72px)', overflow: 'auto' }}
                        >
                            <InfiniteScroll
                                dataLength={notifications.length}
                                next={fetchNotification}
                                hasMore={hasMore}
                                loader={
                                    <h3>
                                        <div id="preloader"></div>Loading...
                                    </h3>
                                }
                                scrollableTarget="scrollableDiv"
                            >
                                <div style={{ width: '100%', height: '100%' }} onClick={doNothing}>
                                    <h6
                                        className="h6_notifis_right pointer-events-all"
                                        onClick={event => {
                                            // markAllRead()
                                            // event.stopPropagation()
                                        }}
                                    ></h6>
                                    <div className="row_new_notifications">
                                        {notifications.map(noti => (
                                            <Notification
                                                key={noti.id}
                                                notification={noti}
                                                actionClick={notificationClick}
                                            />
                                        ))}
                                    </div>
                                </div>
                                {/* <div className="older_notifications ">
                                    <h6 className="h6_notifis_left">Old</h6>
                                    <div className="order_notifications_height">
                                        {notifications.filter(seenMessages).map(noti => (
                                            <Notification notification={noti} actionClick={notificationClick} />
                                        ))}
                                    </div>
                                </div> */}
                            </InfiniteScroll>
                        </div>
                    ) : (
                        <>
                            <div className="no_notification"> No new notification</div>
                            {/* <h4 className="loader_notifications">
                                <div id="preloader"></div>Loading
                            </h4> */}
                        </>
                    )}
                </div>
            </div>
        </section>
    )
}
