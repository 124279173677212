import React, { useEffect, useState } from 'react';
import {
  BgLine,
  Card,
  CardHeader,
  ChartFeedbackWrapper,
  ChartWrapper,
  ChatHeaderWrapper,
  ChatWrapperLeft,
  ChatWrapperRight,
  CountNumber,
  CountWrapper,
  DropdownLi,
  DropdownLiSpan,
  DropdownUl,
  DropdownWrapper,
  FeedbackWrapper,
  Header,
  HeaderContainer,
  Input,
  InputContent,
  NodataContent,
  PieContent,
  ProgressBar,
  ProgressContent,
  ProgressLi,
  ProgressWrapper,
  Span,
  TabCardContainer,
} from '@/common/styled/Dialog.BotDetails.Dumb';
import {
  capitalizeFirstLetter,
  convertFromToDate,
  formatAMPM,
  formatAreaChartDate,
  formatDateInCard,
} from '@/common/utils/utils';
import Tooltip from 'react-tooltip-lite';
import { BasiceDropdown, CustomPieTooltip, OverAllSummaryT } from './BotDetails';
import { AssistantT } from '../Construction/kind';
import {
  useFetchAreaBotSummary,
  useFetchBarchartBotSummary,
  useFetchQuryTabSummary,
  useFetchTop10StatusValues,
  useUpdateAreaBotSummary,
  useUpdateBarChartSummary,
  useUpdateTop10StatusValues,
} from './BotSummaryAPI';
import { BotDropdown, InfoIcon, TriangleIcon } from '@/common/Icons/Icons';
import { areachartDates, barchartDates, percentageCalculate, returnDate } from './TabUIElements';
import Downshift from 'downshift';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip as ChartTip,
  AreaChart,
  Label,
  Area,
  Cell,
  PieChart,
  Pie,
} from 'recharts';
import * as R from 'ramda';
import BasicDateRangePicker from './CustomDatePicker';
import { ResponsivePie } from '@nivo/pie';
import thumbs_down from '@/common/images/bot_page/thumbs_down.svg';
import thumbs_up from '@/common/images/bot_page/thumbs_up.svg';
import {BotSessionsPieChat as SatisfiedPieChart} from './TabUIElements'
var approx = require('approximate-number');

type BarchartT = {
  date: string;
  resolved: number;
  faq_answered: number;
  escalated: number;
  abandoned: number;
  unresolved: number;
  auto_resolved: number;
  user_queries: number;
};

type QuerySummaryT = {
  avg_resolved_query_time: string;
  total_query_count: number;
  resolved_query_count: number;
  faq_query_count: number;
  abandoned_query_count: number;
  escalated_query_count: number;
  unresolved_query_count: number;
  day_wise_status_count: BarchartT[];
  week_wise_status_count: BarchartT[];
  top_5_resolved_status_count: [];
};

type Top10VaaluesType = {
  dialog:string;
  dialog_id:string;
  status_count:number
  dialog_type: "faq" | "dialog"
}

type Top10UnansweredType = {
  input_text: string
  count: number
}

type AgentSummaryT = {
  total_escalated_queries: number;
  agent_session_duration: string;
  agent_feedback_positive_count: number;
  agent_feedback_negative_count: number;
  day_wise_status_count: BarchartT[];
  top_5_escalated_status_count: Top10VaaluesType[];
  top_10_escalated_status_count:Top10VaaluesType[]
};

const QueryTabFirstRowCards = [
  {
    header: 'Avg. queries per session',
  },
  {
    header: 'Avg. queries per user',
  },
  {
    header: 'Avg. queries resolved per user',
  },
  {
    header: 'Avg. time to resolve a user query',
    hours: '(hh:mm:ss)',
  },
];

const AgenthandoverTabFirstRowCards = [
  {
    header: 'Total user queries',
  },
  {
    header:"of total queries",
    hours:'queries escalated to agent',
    subheaderPercentage: true,
  },
  {
    header: 'Avg. agent session duration',
    hours: '(hh:mm:ss)',
  },
  {
    hours: 'sessions were rated positive out of total agent sessions that received a feedback.',
  }
];

const QueryTabSecondRowCards = [
  {
    header: 'queries auto-resolved using Dialogs',
    subheaderPercentage: true,
  },
  {
    header: 'queries auto-resolved using FAQs',
    subheaderPercentage: true,
  },
  {
    header: 'queries escalated to agent',
    subheaderPercentage: true,
  },
  {
    header: 'queries abandoned by users',
    subheaderPercentage: true,
  },
  {
    header: 'queries unresolved by bot',
    subheaderPercentage: true,
  },
];

export function QueriesTab(props: {
  botId: string | undefined;
  workspace: string;
  overAllSummary: OverAllSummaryT;
  selectedBot: AssistantT;
  history: any;
  match: any;
  currenPagetTab:"user_queries"|"agent_handover";
  setTabLoading:(loading:boolean)=>void
}) {
  const { overAllSummary, history, match, currenPagetTab, setTabLoading } = props;

  const [dateOptions, setOptions] = useState('last30days')

  const [currentTab, setCurrentTab] = useState<
    'resolved' | 'faq_answered' | 'abandoned' | 'escalated' | 'unresolved'
  >('resolved');
  const [apiCall,setApiCall] = useState<boolean>(false)
  const { querySummary, querySummaryRetry, querySummaryStatus, dataUpdatedAt,isRefetching,isFetching } =
    useFetchQuryTabSummary(
      props.workspace,
      props.botId ? props.botId : '',
      returnDate(dateOptions),
      currenPagetTab,
      {
        enabled: !!props.botId,
        onSuccess:(data:any)=>{
          setTabLoading(false)
        }
      }
    );

  const { areaSummary } = useFetchAreaBotSummary(
    props.workspace,
    props.botId ? props.botId : '',
    returnDate('last2months'),
    {
      enabled: !!props.botId,
    },
    currenPagetTab==='user_queries'?'queryAreaChart':"agentAreaChart"
  );

  const { barchartBotSummary } = useFetchBarchartBotSummary(
    props.workspace,
    props.botId ? props.botId : '',
    '30',
    {
      enabled: !!props.botId,
    },
    currenPagetTab==='user_queries'?'queryBarChart':"agentBarChart"
  );

  const { top10StatusValues } = useFetchTop10StatusValues(
    props.workspace,
    props.botId ? props.botId : '',
    '30',
    {
      enabled: !!props.botId,
    },
    currenPagetTab==='user_queries'?'top10StatusValues':"top10AgentStatusValues",
    'resolved'
  );
  
  const top10ValuesForAgent = currenPagetTab === 'agent_handover' && querySummary ? (querySummary as AgentSummaryT).top_10_escalated_status_count:top10StatusValues

  const { barChartMutate } = useUpdateBarChartSummary(currenPagetTab==='user_queries'?'queryBarChart':"agentBarChart");

  const { areaChartMutate } = useUpdateAreaBotSummary(currenPagetTab==='user_queries'?'queryAreaChart':"agentAreaChart");

  const { top10StatusValuesMutate } = useUpdateTop10StatusValues('top10StatusValues');

  const [state, setState] = useState({
    showBarChartDropDown: false,
    selectedBarchartDate: 'last30days',
    showAreaChartDropDown: false,
    selectedAreachartDate: 'last2months',
    customDatePickerShow: false,
    customDates: { from_date: null, to_date: null },
  });

  useEffect(() => {
    barchartBotSummary &&
      (state.selectedBarchartDate !== 'customrange' ||
        (state.customDates.from_date != null &&
          state.customDates.to_date != null)) &&
      barChartMutate({
        botId: props.botId,
        workspace: props.workspace,
        date:
          state.selectedBarchartDate === 'customrange'
            ? state.customDates
            : returnDate(state.selectedBarchartDate),
      });
    state.selectedBarchartDate === 'customrange' &&
      setState({ ...state, customDatePickerShow: true });
  }, [state.selectedBarchartDate, state.customDates]);

  useEffect(() => {
    areaSummary &&
      areaChartMutate({
        botId: props.botId,
        workspace: props.workspace,
        date: returnDate(state.selectedAreachartDate),
      });
  }, [state.selectedAreachartDate]);

  useEffect(() => {
    // console.log(currentTab,apiCall,"working")
    // return()=>{
    // console.log(currentTab,"workinginside")
    if(apiCall===true){
      top10StatusValues && 
      // console.log(currentTab,"workinginside")
      top10StatusValuesMutate({
        botId: props.botId,
        workspace: props.workspace,
        date: '30',
        status: currentTab,
      });
      setApiCall(false)
    } 
    // }
  }, [currentTab]);

  function retnQueryFn(i: number) {
    const q = querySummary as QuerySummaryT;
    // console.log(querySummary,overAllSummary,'querySummary')
    switch (i) {
      case 0:
        return overAllSummary.avg_query_per_session;
      case 1:
        return overAllSummary.avg_query_per_user;
      case 2:
        return overAllSummary.avg_query_resolved_per_user;
      default:
        return q ? q.avg_resolved_query_time : 0;
    }
  }

  function retnAgentFn(i: number) {
    const q = querySummary as AgentSummaryT;

    if(!q){
      return 0
    }

    switch (i) {
      case 0:
        return overAllSummary.total_user_queries;
      case 1:
        return q.total_escalated_queries;
      case 2:
        return q.agent_session_duration;
      default:
        return q.agent_feedback_positive_count;
    }
  }
  // const MOCK_querySummary = 
  //   {
  //     resolved_query_count:10,
  //     faq_query_count:15,
  //     escalated_query_count:9,
  //     abandoned_query_count:50,
  //     unresolved_query_count:2
  //   }
  
  function retnSecondRowFn(i: number) {
    const q = querySummary as QuerySummaryT;
    // const q = MOCK_querySummary as QuerySummaryT;

    switch (i) {
      case 0:
        return q.resolved_query_count;
      case 1:
        return q.faq_query_count;
      case 2:
        return q.escalated_query_count;
      case 3:
        return q.abandoned_query_count;
      default:
        return q.unresolved_query_count;
    }
  }

  function retnSecondRowPercentage(i: number) {
    const q = querySummary as QuerySummaryT;
    switch (i) {
      case 0:
        return (q.resolved_query_count / q.total_query_count) * 100;
      case 1:
        return (q.faq_query_count / q.total_query_count) * 100;
      case 2:
        return (q.escalated_query_count / q.total_query_count) * 100;
      case 3:
        return (q.abandoned_query_count / q.total_query_count) * 100;
      default:
        return (q.unresolved_query_count / q.total_query_count) * 100;
    }
  }

  function barchartDate() {
    return barchartDates.filter(
      (it) => it.date === state.selectedBarchartDate
    )[0].label;
  }

  function areachartDate() {
    return areachartDates.filter(
      (it) => it.date === state.selectedAreachartDate
    )[0].label;
  }


  return (
    <div className="bot_sessions_container">
      {isRefetching && 
       setTabLoading(true)
      }
      {isFetching && 
       setTabLoading(true)
      }
      <div className="bot_sessions_heaader">
        <div>
          <h5>{currenPagetTab === 'agent_handover'?"Closer look at queries that were escalated to agents (agent handover)":"Closer look at user queries during bot sessions"}</h5>
        </div>
        <div className="d-flex align-items-center bot_sessions_heaader_color">
          <div className="report_content">
            This report was generated on{' '}<br/>
            {formatDateInCard((querySummary?new Date(dataUpdatedAt):new Date()).toString(), true)} at{' '}
            {formatAMPM((querySummary?new Date(dataUpdatedAt):new Date()).toString(), true)} -{' '}
            <span onClick={() =>{
              setTabLoading(true)
              querySummaryRetry()
            }}>Refresh</span>
          </div>
          <BasiceDropdown dateOptions={dateOptions} changeDateFilter={()=>{
              setTabLoading(true)
            }} setOptions={setOptions}/>
        </div>
      </div>
      <HeaderContainer>
        <TabCardContainer style={{marginBottom: 0}} className="tab_user_queris_one">
          {(currenPagetTab === 'agent_handover'? AgenthandoverTabFirstRowCards : QueryTabFirstRowCards).map((data, i) => (
            <Card key={i}>
              <CardHeader>{currenPagetTab==='agent_handover'&& (data as any).subheaderPercentage && querySummary?<p className="user_queries_2box_last_text">(<span>{(querySummary as AgentSummaryT).total_escalated_queries?
              percentageCalculate(((querySummary as AgentSummaryT).total_escalated_queries/overAllSummary.total_user_queries)*100)
              :0}%</span> {data.header})</p>:data.header}</CardHeader>
              {data.hours && (
                <CardHeader>
                  <span>{data.hours}</span>
                </CardHeader>
              )}
              <CountNumber>
                <CountWrapper>{currenPagetTab==='agent_handover'?  retnAgentFn(i):retnQueryFn(i)}</CountWrapper>
              </CountNumber>
            </Card>
          ))}
        </TabCardContainer>
      </HeaderContainer>
      {currenPagetTab !== 'agent_handover' &&
        <HeaderContainer>
          <TabCardContainer className="tab_user_queris_two">
            {QueryTabSecondRowCards.map((data, i) => (
              <Card style={{ minHeight: 140 }}>
                <CardHeader className="user_queries_2box_last_text">
                  <p>
                    (
                    <span>
                      {querySummary && querySummary.total_query_count > 0
                        ? percentageCalculate(retnSecondRowPercentage(i))
                        : 0}
                      %
                    </span>{' '}
                    of total queries)
                  </p>
                </CardHeader>
                <CardHeader className="user_queries_2box_first_text">{data.header}</CardHeader>
                <CountNumber>
                  <CountWrapper>
                    {querySummary ? retnSecondRowFn(i) : 0}
                  </CountWrapper>
                </CountNumber>
              </Card>
            ))}
          </TabCardContainer>
        </HeaderContainer>
      }
      <ChartFeedbackWrapper style={{marginTop: 10}}>
        <ChartWrapper chartsBg style={{gridTemplateColumns: '65% 1fr'}}>
          <ChatWrapperLeft chartsPadding style={{padding: '0', border: '1px solid #CECECE', marginTop: 0}}>
            <ChatHeaderWrapper style={{ height: 80 }}>
              <Header>
                <h4>{currenPagetTab==='agent_handover'? "Agent Handover Sessions":"User Queries"}</h4>
                <p>{currenPagetTab==='agent_handover'? "Bot resolved user queries vs. queries escalated to agents":"How user queries were handled"}</p>
              </Header>
              <Header>
                <div className="tab_dropdown_date">
                  {state.customDatePickerShow && (
                    <BasicDateRangePicker
                      setDate={(date: Date[]) => {
                        setState({
                          ...state,
                          customDates: {
                            from_date: convertFromToDate(
                              date[0].toString()
                            ) as any,
                            to_date: convertFromToDate(
                              date[1].toString()
                            ) as any,
                          },
                        });
                      }}
                    />
                  )}
                  <DropdownWrapper>
                    <Input
                      onClick={() =>
                        setState({ ...state, showBarChartDropDown: true })
                      }
                      onChange={() => {}}
                      value={barchartDate()}
                    />
                    <InputContent>Show by</InputContent>
                    <span
                      onClick={() =>
                        setState({ ...state, showBarChartDropDown: true })
                      }
                      onChange={() => {}}
                      value={barchartDate()}
                    >
                      <BotDropdown />
                    </span>
                    {state.showBarChartDropDown && (
                      <Downshift
                        isOpen={true}
                        onOuterClick={() =>
                          setState({ ...state, showBarChartDropDown: false })
                        }
                      >
                        {() => (
                          <div>
                            <DropdownUl>
                              {barchartDates.map((date, i) => (
                                <DropdownLi
                                  key={i}
                                  onClick={() => {
                                    setState({
                                      ...state,
                                      selectedBarchartDate: date.date,
                                      showBarChartDropDown: false,
                                    });
                                  }}
                                >
                                  <DropdownLiSpan>{date.label}</DropdownLiSpan>{' '}
                                </DropdownLi>
                              ))}
                            </DropdownUl>
                          </div>
                        )}
                      </Downshift>
                    )}
                  </DropdownWrapper>
                </div>
              </Header>
            </ChatHeaderWrapper>
            <div className="bar_charts_wrapper" style={{ marginTop: 20, padding: '0 20px' }}>
              <BarCharts
                day_wise_session_query_count={
                  barchartBotSummary ? barchartBotSummary : []
                }
                dateOptions={state.selectedBarchartDate}
                currenPagetTab={currenPagetTab}
              />
            </div>
          </ChatWrapperLeft>
          <ChatWrapperRight chartsPadding style={{border: '1px solid #CECECE'}}>
            <ChatHeaderWrapper style={{ height: 60 }}>
              <Header style={{top: 3}}>
                <h4>
                  {currenPagetTab==='agent_handover'?"Feedback on agents": "Total User Queries"}
                  {currenPagetTab==='agent_handover'?(
                      <Tooltip
                        className="target_bot"
                        tipContentHover
                        zIndex={5000}
                        arrowSize={5}
                        tagName="span"
                        content="User feedback on live agent handover"
                      >
                        <span className="info_icon">
                            <InfoIcon />
                        </span>
                      </Tooltip>
                      ):("")}
                  </h4>
              
              
              </Header>
            </ChatHeaderWrapper>
            {currenPagetTab=== 'user_queries'?
            (<>
            <BotSessionsPieChat
              querySummary={barchartBotSummary ? barchartBotSummary : []}
              keys={['resolved', 'escalated','abandoned', 'unresolved']}
            />
            <div className="pie_content_container">
              <PieContent>
                <div>
                  <span className="_dot_1"></span>Auto Resolved
                      <Tooltip
                            className="target customTip"
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content="User queries auto-resolved by bot using Dialogs and FAQs "
                            distance={5}
                        >
                            <InfoIcon />
                        </Tooltip>
                </div>
                <div>
                  <span className="_dot_2"></span> Abandoned
                </div>
              </PieContent>
              <PieContent className="pie_content_section_two">
                <div>
                  <span className="_dot_3"></span>Agent Escalated
                </div>
                <div>
                  <span className="_dot_4"></span>Unresolved
                </div>
              </PieContent>
            </div>
            </>):(
              <>
                <SatisfiedPieChart
                    botDetails={querySummary?querySummary as any:{}}
                    positiveKey="agent_feedback_positive_count"
                    negativeKey="agent_feedback_negative_count"
                  />          
                  <FeedbackWrapper style={{ marginTop: 38 }}>
                    <label>
                      <img src={thumbs_up} alt="thumbs_up" />
                          <span>Satisfied</span>
                    </label>
                    <label>
                      <img src={thumbs_down} alt="thumbs_down" />
                      <span>Not Satisfied</span>
                    </label>
                  </FeedbackWrapper>
              </>
            
            )}
          </ChatWrapperRight>
        </ChartWrapper>
      </ChartFeedbackWrapper>

      <div className="tab_section_wrapper">
        <div className="tab_section_header">
          <div>
            <h5>{currenPagetTab==='agent_handover'?"Agent Handover Sessions":"User Queries"}</h5>
            <p>{currenPagetTab==='agent_handover'?"Compare agent handover sessions over a specific period":"Compare how user queries were handled over a specific period"}</p>
          </div>
          <div className="tab_dropdown_date">
            <DropdownWrapper>
              <Input
                onClick={() =>
                  setState({ ...state, showAreaChartDropDown: true })
                }
                onChange={() => {}}
                value={areachartDate()}
              />
              <InputContent>Show comparision</InputContent>
              <span 
                onClick={() =>
                  setState({ ...state, showAreaChartDropDown: true })
                }
              >
                <BotDropdown />
              </span>
              {state.showAreaChartDropDown && (
                <Downshift
                  isOpen={true}
                  onOuterClick={() =>
                    setState({ ...state, showAreaChartDropDown: false })
                  }
                >
                  {() => (
                    <div>
                      <DropdownUl>
                        {areachartDates.map((date, i) => (
                          <DropdownLi
                            key={i}
                            onClick={() => {
                              setState({
                                ...state,
                                selectedAreachartDate: date.date,
                                showAreaChartDropDown: false,
                              });
                            }}
                          >
                            <DropdownLiSpan>{date.label}</DropdownLiSpan>{' '}
                          </DropdownLi>
                        ))}
                      </DropdownUl>
                    </div>
                  )}
                </Downshift>
              )}
            </DropdownWrapper>
          </div>
        </div>
        <div className="tab_section_graph">
          <AreaChartWithWrapper
            areaDetails={areaSummary ? areaSummary : []}
            dateOptions={state.selectedAreachartDate}
            currenPagetTab={currenPagetTab}
          />
        </div>
      </div>
      <div className="tab_section_wrapper">
        <div className="tab_section_header">
          <div>
            {/* <h5>How most frequent user queries were handled</h5>
            <p></p> */}
            <h5>{currenPagetTab==='agent_handover'?"Agent Handover Triggers":"How most frequent user queries were handled"}</h5>
            <p>{currenPagetTab==='agent_handover'?"Queries that triggered agent handover":""}</p>
          </div>
        </div>
        {currenPagetTab === 'user_queries' && <div className="tab_menu tab_section">
          <a
            className={currentTab === 'resolved' ? 'active' : ''}
            onClick={() => {
              setApiCall(true)
              setCurrentTab('resolved')}
            }
          >
            Auto-resolved using Dialogs{' '}
          </a>
          <a
            className={currentTab === 'faq_answered' ? 'active' : ''}
            onClick={() => {
              setApiCall(true)
              setCurrentTab('faq_answered')}}
          >
            Auto-resolved using FAQs
          </a>
          <a
            className={currentTab === 'escalated' ? 'active' : ''}
            onClick={() => {
              setApiCall(true)
              setCurrentTab('escalated')}}
          >
           Escalated to agent
          </a>
          <a
            className={currentTab === 'abandoned' ? 'active' : ''}
            onClick={() => {
              setApiCall(true)
              setCurrentTab('abandoned')}}
          >
            Abandoned by users
          </a>
          <a
            className={currentTab === 'unresolved' ? 'active' : ''}
            onClick={() => {
              setApiCall(true)
              setCurrentTab('unresolved')}}
          >
            Unresolved
          </a>
        </div>}
        <Top10ValuesCharts top10ValuesForAgent={top10ValuesForAgent} history={history} match={match}/>
      </div>
    </div>
  );
}

export function Top10ValuesCharts(props:{top10ValuesForAgent:(Top10VaaluesType| Top10UnansweredType)[],history:any, match:any}){
  const { top10ValuesForAgent,history,match } = props;
  const totalQueryCountForDialog = top10ValuesForAgent
  ? top10ValuesForAgent.reduce(
      (
        acc: number,
        c: {
          dialog: string;
          dialog_id: string;
          status_count: number;
        } | Top10UnansweredType
      ) => (acc += ((c as Top10VaaluesType).status_count?(c as Top10VaaluesType).status_count:(c as Top10UnansweredType).count)),
      0
    )
  : 0;

  function gotoDialog(dialog_id: string) {
    history.push(
      `/workspace/${match.params.workspacename}/dialog/${match.params.assistantname}/detail/${dialog_id}`
    );
  }

  function gotoFAQ(dialog_id: string) {
    history.push(
      `/workspace/${match.params.workspacename}/dialog/${match.params.assistantname}/dialogs?open_faq=${dialog_id}`
    );
  }

  return(
    <div className="tab_user_queris_table_section">
          <div className="tab_user_queris_table_container">
            <div className="tab_user_queris_table">
              <ProgressWrapper className="ul">
                {top10ValuesForAgent && top10ValuesForAgent.length > 0 ? (
                 R.sortBy(R.prop('status_count'))(top10ValuesForAgent).reverse().map(
                    (
                      a: any,
                      i: number
                    ) => (
                      <ProgressLi key={i}>
                        <ProgressContent
                          nolink={!a.dialog_id}
                          onClick={() => {
                            if(a.dialog_id){
                              a.dialog_type === 'faq'? gotoFAQ(a.dialog_id): gotoDialog(a.dialog_id);
                            }
                          }}
                        >
                          {a.dialog?a.dialog:a.input_text}
                        </ProgressContent>
                        <ProgressBar>
                          <Span>{a.status_count?a.status_count:a.count}</Span>
                          <BgLine>
                            {/* <progress
                              id="file"
                              style={{
                                width: '100%',
                                background: frequentPieChartColor[i],
                              }}
                              max="100"
                              value={
                                (a.status_count / totalQueryCountForDialog) *
                                100
                              }
                              className=""
                            ></progress> */}
                            <div className='summary_progress_container'>
                              <div className='summary_progress_bar'
                                 style={{
                                  width: ((a.status_count?a.status_count:a.count) / totalQueryCountForDialog) * 100,
                                  background: frequentPieChartColor[i],
                                }}
                              >

                              </div>
                            </div>
                          </BgLine>
                        </ProgressBar>
                      </ProgressLi>
                      // )
                    )
                  )
                ) : (
                  <NodataContent>No data available</NodataContent>
                )}
              </ProgressWrapper>
            </div>
          </div>
          <div className="tab_user_queris_piechart">
            <ChartFeedbackWrapper>
              <ChartWrapper chartsBg>
                <ChatWrapperRight chartsPadding>
                  <MostFrequentPieCharts
                    data={
                      top10ValuesForAgent
                        ? R.sortBy(R.prop('status_count'))(top10ValuesForAgent).reverse().map(
                            (val: any) => ({
                              value: (val.status_count?val.status_count:val.count),
                              id: val.dialog_id?val.dialog_id:"",
                              label: val.dialog?val.dialog:val.input_text,
                            })
                          )
                        : []
                    }
                    colors={frequentPieChartColor}
                  />
                </ChatWrapperRight>
              </ChartWrapper>
            </ChartFeedbackWrapper>
          </div>
        </div>
  )
}

const frequentPieChartColor = [
  '#DAED89',
  '#EDEEFF',
  '#A5D2FF',
  '#FDEDFF',
  '#FFEFD8',
  '#FFCCCC',
  '#C3FFF8',
  '#CCCCFF',
  '#EBFFCC',
  '#C8FBC8',
  '#D3B4FB',
  '#F8B4FB',
];

function BarCharts(props: {
  day_wise_session_query_count: BarchartT[];
  dateOptions: string;
  currenPagetTab: "user_queries" | "agent_handover"
}) {
  const BarchartTooltip = (props: any) => {
    const { active, payload, label } = props;
    if (active && payload && payload.length) {
      const pLoad = payload[0].payload;
      return (
        <div className="custom-tooltip">
          <p className="tooltip_date">
            User Queries: <span>{pLoad.queries ? pLoad.queries : 0}</span>
          </p>
          <p>
            Auto resolved:
            <span>{pLoad.resolved ? pLoad.resolved : 0}</span>
          </p>
          <p>
            Agent escalated:
            <span style={{color:"#FF9933"}}>{pLoad.escalated ? pLoad.escalated : 0}</span>
          </p>
          <p>
            User abandoned:
            <span style={{color:"#FF7462"}}>{pLoad.abandoned ? pLoad.abandoned : 0}</span>
          </p>
          <p>
            Unresolved:
            <span style={{color:"#E3CE85"}}>{pLoad.unresolved ? pLoad.unresolved : 0}</span>
          </p>
        </div>
      );
    }
    return null;
  };
  const AgentTooltip = (props: any) => {
    const { active, payload, label } = props;
    if (active && payload && payload.length) {
      const pLoad = payload[0].payload;
      return (
        <div className="custom-tooltip">
          <p className="tooltip_date">
            Bot resolved user queries: <span style={{color:"#4AC3BA"}}>{pLoad.resolved ? pLoad.resolved : 0}</span>
          </p>
          <p>
            Queries escalated to agent:
            <span style={{color:"#FF9933"}}>{pLoad.escalated ? pLoad.escalated: 0}</span>
          </p>
        </div>
      );
    }
    return null;
  };
  type ChartData = {
    name: string;
    resolved: number;
    escalated: number;
    abandoned: number;
    unresolved: number;
    queries: number;
  };

  const { day_wise_session_query_count, dateOptions } = props;

  function filterDate() {
    return day_wise_session_query_count.map((chart) => {
      const date =
        dateOptions != 'today' && dateOptions != 'yesterday'
          ? chart.date.split('/')[0] + '/' + parseInt(chart.date.split('/')[1])
          : chart.date.split('-');
      return {
        name: date,
        resolved: chart.resolved,
        escalated: chart.escalated,
        abandoned: chart.abandoned,
        unresolved: chart.unresolved,
        queries: chart.user_queries,
      };
    });
  }
  // const mock_userQueries = [
  //   {
  //       name: '05/4/2023',
  //       resolved: 18,
  //       escalated: 5,
  //       abandoned: 5,
  //       unresolved: 2,
  //       queries: 30,
  //   },
  //   {
  //       name: '07/4/2023',
  //       resolved: 22,
  //       escalated: 5,
  //       abandoned: 10,
  //       unresolved: 2,
  //       queries: 39,
  //   }
  // ]
  const datas: ChartData[] = filterDate();

  const modifiedData = [
    {
      name: '',
      resolved: 0,
      escalated: 0,
      abandoned: 0,
      unresolved: 0,
      queries: 0,
    },
    ...datas,
  ];
  const maxValue = modifiedData
    .map((x: ChartData) => x.queries as any)
    .reduce((prev, curr) => {
      return Math.max(prev, curr);
    }, 0);

  const length = `${maxValue}`.length - 1;

  const unit = maxValue <= 25 ? 5 : parseInt(1 + '0'.repeat(length));
  const [position, setPosition] = useState({
    data: { x: 0, y: 0,height:0 },
    show: false,
    belowBar:false,
  });
  const yAxisTicks =
    unit === 5
      ? [5, 10, 15, 20, 25, 30]
      : R.unfold<number, number>((it) =>
          it > maxValue + 2 * unit ? false : [it, it + unit]
        )(unit);
  return (
    // <div className="analytics_charts_left_img">
    <div
      style={{ width: '100%' }}
      className={
        datas.length > 0 ? 'bot_session_chart' : 'no_data_bot_session_chart'
      }
    >
      {props.currenPagetTab ==='user_queries'? (
      datas.length > 0 ? (
        <div className="bot-summary-all-sessions">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={830}
              height={320}
              data={modifiedData}
              margin={{
                top: 15,
                right: 25,
                left: -10,
                bottom: 5,
              }}
              barCategoryGap={'35%'}
              barGap={'10%'}
            >
              <defs>
              <linearGradient id="resolvedclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                <stop offset='0' stopColor='#7AE3DC' />
                <stop offset='1' stopColor='#5CAAA4'/>
              </linearGradient>
              <linearGradient id="escalatedclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                <stop offset='0' stopColor='#FFBE7E' />
                <stop offset='1' stopColor='#D6A87A'/>
              </linearGradient>
              <linearGradient id="unresolvedclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                <stop offset='0' stopColor='#FBE699' />
                <stop offset='1' stopColor='#C3AF6A'/>
              </linearGradient>
              <linearGradient id="userabandonedclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                <stop offset='0' stopColor='#EB6453' />
                <stop offset='1' stopColor='#C1665B'/>
              </linearGradient>
            </defs>
              <CartesianGrid />
              <XAxis
                angle={(props.dateOptions === "today" || props.dateOptions ===  "yesterday") ? 0 : 45}
                dy={8}
                dx={(props.dateOptions === "today" || props.dateOptions ===  "yesterday") ? 0 :12}
                dataKey="name"
                interval={0}
                // label={{
                //   value:
                //     dateOptions == 'today' || dateOptions == 'yesterday'
                //       ? 'Hours'
                //       : 'Date',
                //   position: 'insideBottom',
                //   className: 'xAxisLabel',
                //   offset: -20,
                // }}
                scale="point"
                // padding={{ left: 5, right: 5 }}
                axisLine={false}
                tickLine={false}
              />
              <YAxis
                allowDecimals={false}
                label={{
                  value: 'User Queries',
                  angle: -90,
                  position: 'insideLeft',
                  offset: 16,
                  style: { textAnchor: 'middle', fontFamily: 'Gordita-Regular' },
                }}
                axisLine={false}
                tickLine={{ stroke: '#F2F2F2' }}
                tickFormatter={(x: number) => {
                  return approx(x);
                }}
                ticks={[0, ...yAxisTicks]}
                tickMargin={5}
              />
              <ChartTip
                isAnimationActive={false}
                cursor={false}
                position ={position.belowBar ? (position.show? {x:position.data.x - 60, y:position.data.y - 115}: undefined) : (position.show? {x:position.data.x - 60, y:position.data.y -95}: undefined)}
                content={position.show ? (
                    <BarchartTooltip />
                  ) : (
                    <div style={{ display: 'none' }}></div>
                  )
                }
              />
                  <Bar
                    dataKey="unresolved"
                    stackId="a"
                    fill="url(#unresolvedclr)"
                    // "#A1E89B"
                    barSize={10}
                     onMouseOver={(data) => {
                    setPosition({data : data,show: true, belowBar:true });
                    }}
                    onMouseLeave={(data) => {
                      setPosition({ data : { x : 0 , y : 0,height:0 },show: false,belowBar:true });
                    }}
                  />
                  <Bar
                    dataKey="abandoned"
                    stackId="a"
                    fill= "url(#userabandonedclr)"
                    barSize={10}
                     onMouseOver={(data) => {
                    setPosition({data : data,show: true,belowBar:true });
                    }}
                    onMouseLeave={(data) => {
                      setPosition({ data : { x : 0 , y : 0,height:0 },show: false,belowBar:true });
                    }}
                  />
                  <Bar dataKey="escalated" stackId="a" 
                    fill="url(#escalatedclr)"
                    // '#FFAB57'
                    // {props.currenPagetTab =='user_queries'?"#A1E89B":"#FFAB57"} 
                    barSize={10}
                    onMouseOver={(data) => {
                    setPosition({data : data,show: true,belowBar:true });
                    }}
                    onMouseLeave={(data) => {
                      setPosition({ data : { x : 0 , y : 0,height:0 },show: false,belowBar:true });
                    }} 
                  />
                  <Bar dataKey="resolved" stackId="a" radius={[10, 10, 0, 0]}
                    fill= "url(#resolvedclr)"
                    // '#BDB2FF'
                    // {props.currenPagetTab =='user_queries'?"#BDB2FF":"#A1E89B"} 
                    barSize={10}
                    isAnimationActive={false}
                    onMouseOver={(data) => {
                    setPosition({data : data, show: true,belowBar:false });
                    }}
                    onMouseLeave={(data) => {
                      setPosition({ data : { x : 0 , y : 0,height:0 },show: false,belowBar:false });
                    }} 
                  />
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <NodataContent>No data available</NodataContent>
      )) : (
        datas.length > 0 ? (
        <div className="bot-summary-all-sessions">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={830}
              height={320}
              data={modifiedData}
              margin={{
                top: 15,
                right: 25,
                left: -10,
                bottom: 5,
              }}
              barCategoryGap={'35%'}
              barGap={'10%'}
            >
              <defs>
              <linearGradient id="resolvedclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                <stop offset='0%' stopColor='#A1E89B' />
                <stop offset='100%' stopColor='#75AC70'/>
              </linearGradient>
              <linearGradient id="escalatedclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                <stop offset='0%' stopColor='#FFAB57' />
                <stop offset='26%' stopColor='#FBA957'/>
                <stop offset='100%' stopColor='#BE8954'/>
              </linearGradient>
            </defs>
              <CartesianGrid />
              <XAxis
                angle={(props.dateOptions === "today" || props.dateOptions ===  "yesterday") ? 0 :45}
                dy={8}
                dx={(props.dateOptions === "today" || props.dateOptions ===  "yesterday") ? 0 :12}
                dataKey="name"
                interval={0}
                // label={{
                //   value:
                //     dateOptions == 'today' || dateOptions == 'yesterday'
                //       ? 'Hours'
                //       : 'Date',
                //   position: 'insideBottom',
                //   className: 'xAxisLabel',
                //   offset: -20,
                // }}
                scale="point"
                // padding={{ left: 5, right: 5 }}
                axisLine={false}
                tickLine={false}
              />
              <YAxis
                allowDecimals={false}
                label={{
                  value: 'User Queries',
                  angle: -90,
                  position: 'insideLeft',
                  offset: 16,
                  style: { textAnchor: 'middle', fontFamily: 'Gordita-Regular' },
                }}
                axisLine={false}
                tickLine={{ stroke: '#F2F2F2' }}
                tickFormatter={(x: number) => {
                  return approx(x);
                }}
                ticks={[0, ...yAxisTicks]}
                tickMargin={5}
              />
              <ChartTip
                isAnimationActive={false}
                cursor={false}
                position ={position.belowBar ? (position.show? {x:position.data.x - 80, y:position.data.y - 75}: undefined) : (position.show? {x:position.data.x - 80, y:position.data.y -55}: undefined)}
                content={position.show ? (
                    <AgentTooltip />
                  ) : (
                    <div style={{ display: 'none' }}></div>
                  )
                }
              />
                  {/* <Bar
                    dataKey="unresolved"
                    stackId="a"
                    fill="url(#unresolvedclr)"
                    // "#A1E89B"
                    barSize={10}
                     onMouseOver={(data) => {
                    // console.log(data,'onMouseEnter')
                    setPosition({data : data,show: true, belowBar:true });
                    }}
                    onMouseLeave={(data) => {
                      setPosition({ data : { x : 0 , y : 0,height:0 },show: false,belowBar:true });
                    }}
                  />
                  <Bar
                    dataKey="abandoned"
                    stackId="a"
                    fill= '#EB6453'
                    // "url(#abandonedclr)"
                    // "#A1E89B"
                    barSize={10}
                     onMouseOver={(data) => {
                    // console.log(data,'onMouseEnter')
                    setPosition({data : data,show: true,belowBar:true });
                    }}
                    onMouseLeave={(data) => {
                      setPosition({ data : { x : 0 , y : 0,height:0 },show: false,belowBar:true });
                    }}
                  /> */}
                  <Bar dataKey="escalated" stackId="a" 
                    fill="url(#escalatedclr)"
                    // '#FFAB57'
                    // {props.currenPagetTab =='user_queries'?"#A1E89B":"#FFAB57"} 
                    barSize={10}
                    onMouseOver={(data) => {
                    setPosition({data : data,show: true, belowBar:true });
                    }}
                    onMouseLeave={(data) => {
                      setPosition({ data : { x : 0 , y : 0,height:0 },show: false,belowBar:true });
                    }} 
                  />
                  <Bar dataKey="resolved" stackId="a" radius={[10, 10, 0, 0]}
                    fill= "url(#resolvedclr)"
                    // '#BDB2FF'
                    // {props.currenPagetTab =='user_queries'?"#BDB2FF":"#A1E89B"} 
                    barSize={10}
                    isAnimationActive={false}
                    onMouseOver={(data) => {
                    setPosition({data : data, show: true,belowBar:false });
                    }}
                    onMouseLeave={(data) => {
                      setPosition({ data : { x : 0 , y : 0,height:0 },show: false,belowBar:false });
                    }} 
                  />
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <NodataContent>No data available</NodataContent>
      )
      )}
    </div>
    // </div>
  );
}

function BotAndAgentBarchart(props: {
  overAllSummary: OverAllSummaryT;
  botDetails: any;
}) {
  const BarchartTooltip = (props: any) => {
    // const { series, seriesIndex, dataPointIndex, w } = props
    // console.log("pLoad",props)

    const { active, payload, label } = props;

    if (active && payload && payload.length) {
      if (payload[0].dataKey === 'agentSessions') {
        const pLoad = payload[0].payload;
        return (
          <div className="custom-tooltip">
            <p className="tooltip_date">
              <span>{pLoad.agentSessions ? pLoad.agentSessions : 0}</span>
            </p>
          </div>
        );
      } else {
        const pLoad = payload[0].payload;
        return (
          <div className="custom-tooltip">
            <p className="tooltip_date">
              Bot Sessions:
              <span>{pLoad.sessions ? pLoad.sessions : 0}</span>
            </p>
            <p>
              User Queries: <span>{pLoad.queries ? pLoad.queries : 0}</span>
            </p>
          </div>
        );
      }
    }
    return null;
  };
  type ChartData = {
    name: string;
    sessions: number;
    queries: number;
  };

  const { overAllSummary, botDetails } = props;

  function filterDate() {
    return [
      {
        name: 'Bot Sessions with User Queries',
        sessions: overAllSummary.total_chatbot_session,
        queries: overAllSummary.total_user_queries,
      },
      {
        name: 'Agent Sessions',
        agentSessions: botDetails.agent_session_count,
      },
    ];
  }

  const datas: any[] = filterDate();

  const modifiedData = datas;

  return (
    // <div className="analytics_charts_left_img">
    <div
      style={{ width: '100%' }}
      className={
        datas.length > 0 ? 'bot_session_chart' : 'no_data_bot_session_chart'
      }
    >
      {datas.length > 0 ? (
        <div className="bot-summary-all-sessions">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={830}
              height={320}
              data={modifiedData}
              margin={{
                top: 15,
                right: 65,
                left: 65,
                bottom: 5,
              }}
              barCategoryGap={'20%'}
              barGap={'5%'}
            >
              {/* <CartesianGrid /> */}
              <XAxis
                // angle={45}
                dy={12}
                dx={12}
                dataKey="name"
                interval={0}
                scale="point"
                axisLine={true}
                tickLine={false}
              />
              <ChartTip
                isAnimationActive={false}
                cursor={false}
                content={<BarchartTooltip />}
              />

              <Bar dataKey="sessions" stackId="a" fill="#BDB2FF" barSize={10} />

              <Bar
                dataKey="queries"
                stackId="a"
                fill="#A1E89B"
                barSize={10}
                radius={[10, 10, 0, 0]}
              />
              <Bar
                dataKey="agentSessions"
                fill="#3A8FBC"
                barSize={10}
                radius={[10, 10, 0, 0]}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <NodataContent>No data available</NodataContent>
      )}
    </div>
    // </div>
  );
}

function BotSessionsPieChat(props: {
  querySummary: BarchartT[];
  keys: string[];
}) {
  const { querySummary, keys } = props;
  // For mock values
  // const { keys } = props;

  // const querySummary= [
  //   {
  //   abandoned: 15,
  //   auto_resolved: 0,
  //   date: "19/03/2023",
  //   escalated: 0,
  //   faq_answered: 10,
  //   resolved: 10,
  //   unresolved: 5,
  //   user_queries: 0,
  //   },
  //   {
  //   abandoned: 10,
  //   auto_resolved: 0,
  //   date: "19/03/2023",
  //   escalated: 5,
  //   faq_answered: 4,
  //   resolved: 10,
  //   unresolved: 6,
  //   user_queries: 0,
  //   },
  //   {
  //   abandoned: 6,
  //   auto_resolved: 0,
  //   date: "19/03/2023",
  //   escalated: 7,
  //   faq_answered: 5,
  //   resolved: 20,
  //   unresolved: 0,
  //   user_queries: 0,
  //   },
  // ]
  const sumOfValues = querySummary.reduce((accum, current) => {
    Object.entries(current).forEach(([key, value]) => {
      if (key !== 'date') {
        accum[key] = accum[key] + value || value;
      }
      
    });
    return {
      ...accum,
    };
  }, {});
  function sum(obj: any) {
    return Object.keys(obj).reduce(
      (sum, key) => sum + parseFloat(obj[key] || 0),
      0
    );
  }

  const total = sum(sumOfValues);

  const data = keys.reduce(
    (
      accum: {
        value: number;
        id: string;
        label: string;
      }[],
      key
    ) => {
      if (sumOfValues[`${key}`]) {
          if(key === 'resolved'){
          return [
          ...accum,
          {
            value: sumOfValues[`resolved`] + sumOfValues[`faq_answered`],
            id: key,
            label: `Auto Resolved`,
          },
        ]}
        else if(key === 'escalated'){
          return [
          ...accum,
          {
            value: sumOfValues[`escalated`],
            id: key,
            label: `Agent Escalated`,
          },
        ]
        } else {
        return [
          ...accum,
          {
            value: sumOfValues[`${key}`],
            id: key,
            label: capitalizeFirstLetter(key),
          },
        ];}
      }
      return accum;
    },
    []
  );
  return (
    // <div className="pie_chat">
    total === 0 ? (
      <div className="pie_chart_img">
        <NodataContent>No data available</NodataContent>
      </div>
    ) : (
      <PieCharts
        data={data}
        colors={{
          resolved: '#7AE3DC',
          abandoned:'#FF7462',
          escalated: '#FFAB57',
          unresolved: '#FBE699',
        }}
      />
    )
    // </div>
  );
}

const tooltipKeysForQuery = [
  { title: 'User Queries',color:"#000000", key: 'queries', anotherKey: 'query' },
  { title: 'Auto Resolved',color:"#5AD1C9", key: 'resolved', anotherKey: 'resolved' },
  { title: 'Agent Escalated', color:"#FF9933",key: 'escalated', anotherKey: 'escalated' },
  { title: 'User Abandoned',color:"#E27568", key: 'abandoned', anotherKey: 'abandoned' },
  { title: 'Unresolved',color:"#E3CE85", key: 'unresolved', anotherKey: 'unResolved' },
];

const tooltipKeysForAgent = [
  { title: 'Total user queries',color:"#4AC3BA", key: 'queries', anotherKey: 'query' },
  { title: 'Agent Escalated', color:"#FF9933",key: 'escalated', anotherKey: 'escalated' },
];

const AreaChartWithWrapper = (props: {
  areaDetails: BarchartT[];
  dateOptions: string;
  currenPagetTab: "user_queries" | "agent_handover"
}) => {
  const { areaDetails, dateOptions,currenPagetTab } = props;

  const AreaTooltip = (props: any) => {
    const { active, payload, label } = props;

    if (active && payload && payload.length && payload[0].payload.queries) {
      return (
        <div className="custom-tooltip _resolved">
          {(currenPagetTab==='user_queries'?tooltipKeysForQuery:tooltipKeysForAgent).map((k) => (
            <p className="tooltip_date">
              {k.title}:
              <span style={{color:`${k.color}`}}>
                {payload[0].payload[`${k.key}`]
                  ? payload[0].payload[`${k.key}`]
                  : 0}{' '}

                {payload[0].payload.index > 1 &&
                  payload[0].payload[`${k.key}`] !== 0 && (
                    <p
                      className="custom_tooltip_persentage_color"
                      style={{
                        color: payload[0].payload[`${k.anotherKey}Increased`]
                          ? '#70D397'
                          : '#D13737',
                      }}
                    >
                      <span
                        className="custom_tooltip_persentage_img"
                        style={{
                          transform: payload[0].payload[
                            `${k.anotherKey}Increased`
                          ]
                            ? 'rotate(0deg)'
                            : 'rotate(180deg)',
                          fill: payload[0].payload[`${k.anotherKey}Increased`]
                            ? '#70D397'
                            : '#D13737',
                        }}
                      >
                        <TriangleIcon />
                      </span>
                      {Math.round(
                        payload[0].payload[`${k.anotherKey}Percentage`]
                      ) + '%'}
                    </p>
                  )}
              </span>
            </p>
          ))}
        </div>
      );
    }
    return null;
  };
  const datas = areaDetails.reduce(
    (accum, chart, i) => {
      const date = formatAreaChartDate(chart.date);
      const calculate = (first: number, second: number) =>
        first < second
          ? Math.abs((second - first) / second)
          : Math.abs((first - second) / first);
      const queryPercentage =
        i === 0 ? 100 : calculate(accum[i].queries, chart.user_queries) * 100;

      const escalatedPercentage =
          i === 0 ? 100 : calculate(accum[i].escalated, chart.escalated) * 100;
      if(currenPagetTab==='user_queries'){
        const resolvedPercentage =
        i === 0
          ? 100
          : calculate(accum[i].resolved, chart.auto_resolved) * 100;

      const unResolvedPercentage =
        i === 0 ? 100 : calculate(accum[i].unresolved, chart.unresolved) * 100;

      const abandonedPercentage =
        i === 0 ? 100 : calculate(accum[i].abandoned, chart.abandoned) * 100;

      return [
        ...accum,
        {
          name: date,
          queries: chart.user_queries,
          resolved: chart.auto_resolved,
          abandoned: chart.abandoned,
          escalated: chart.escalated,
          unresolved: chart.unresolved,
          queryPercentage,
          resolvedPercentage,
          unResolvedPercentage,
          escalatedPercentage,
          abandonedPercentage,
          index: i + 1,
          queryIncreased: accum[i].queries < chart.user_queries,
          resolvedIncreased: accum[i].resolved < chart.auto_resolved,
          unResolvedIncreased: accum[i].unresolved < chart.unresolved,
          abandonedIncreased: accum[i].abandoned < chart.abandoned,
          escalatedIncreased: accum[i].escalated < chart.escalated,
        },
      ] as any;
    }else{
      return [
        ...accum,
        {
          name: date,
          queries: chart.user_queries,
          escalated: chart.escalated,
          queryPercentage,
          escalatedPercentage,
          index: i + 1,
          queryIncreased: accum[i].queries < chart.user_queries,
          escalatedIncreased: accum[i].escalated < chart.escalated,
        },
      ] as any;
    }
    },
    [
      {
        name: '',
        queries: 0,
        resolved: 0,
        abandoned: 0,
        escalated: 0,
        unresolved: 0,
        queryPercentage: 0,
        resolvedPercentage: 0,
        unResolvedPercentage: 0,
        escalatedPercentage: 0,
        abandonedPercentage: 0,
        index: 0,
        queryIncreased: false,
        resolvedIncreased: false,
        unResolvedIncreased: false,
        abandonedIncreased: false,
        escalatedIncreased: false,
      },
    ] as any
  ) as {
    name: string;
    queries: number;
    resolved: number;
    abandoned: number;
    escalated: number;
    unresolved: number;
    queryPercentage: number;
    resolvedPercentage: number;
    unResolvedPercentage: number;
    escalatedPercentage: number;
    abandonedPercentage: number;
    index: number;
    queryIncreased: boolean;
    resolvedIncreased: boolean;
    unResolvedIncreased: boolean;
    abandonedIncreased: boolean;
    escalatedIncreased: boolean;
  }[];
  const modifiedData = datas;

  const maxValue = modifiedData
    .map((x) => x.queries)
    .reduce((prev, curr) => {
      return Math.max(prev, curr);
    }, 0);

  const length = `${maxValue}`.length - 1;

  const unit = maxValue <= 25 ? 5 : parseInt(1 + '0'.repeat(length));

  const yAxisTicks =
    unit === 5
      ? [5, 10, 15, 20, 25, 30]
      : R.unfold<number, number>((it) =>
          it > maxValue + 2 * unit ? false : [it, it + unit]
        )(unit);

  return (
    <ChartFeedbackWrapper>
      <ChartWrapper chartsBg style={{ marginTop: 0 }}>
        <ChatWrapperLeft chartsPadding>
          {/* <Row>
            <Header>
              Bot Auto Resolved:{' '}
              {areaDetails.reduce(
                (acc, val) =>
                  (acc += val.num_of_queries ? val.num_of_queries : 0),
                0
              )}
            </Header>
          </Row> */}
          <div
            className={
              areaDetails.reduce((acc, val) => (acc += val.user_queries), 0) > 0
                ? 'bot-auto-resolved'
                : 'bot-auto-resolved display_inline'
            }
          >
            {areaDetails.reduce((acc, val) => (acc += val.user_queries), 0) >
            0 ? (
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={830}
                  height={320}
                  data={datas}
                  margin={{
                    top: 15,
                    right: 40,
                    left: -10,
                    bottom: 5,
                  }}
                  barCategoryGap={'35%'}
                  barGap={'10%'}
                >
                  <defs>
                    <linearGradient
                      id="colour-resolved"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="5%" stopColor={props.currenPagetTab==='user_queries' ?"#D8FFD5":"#FFF2E5"} stopOpacity={0.6} />
                      <stop offset="95%" stopColor={props.currenPagetTab==='user_queries' ?"#A1E29B":"#CC955E"} stopOpacity={1} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid />
                  <XAxis
                    // angle={45}
                    dy={2}
                    dx={3}
                    dataKey="name"
                    interval={0}
                    tickLine={false}
                    axisLine={false}
                    tickMargin={5}
                  ></XAxis>
                  <YAxis
                    allowDecimals={false}
                    label={{
                      value: 'User Queries',
                      angle: -90,
                      position: 'insideLeft',
                      offset: 20,
                      style: { textAnchor: 'middle', fontFamily: 'Gordita-Regular' },
                    }}
                    axisLine={false}
                    tickLine={{ stroke: '#F2F2F2' }}
                    tickFormatter={(x: number) => {
                      return approx(x);
                    }}
                    ticks={[0, ...yAxisTicks]}
                  />
                  <ChartTip
                    isAnimationActive={false}
                    cursor={false}
                    content={<AreaTooltip />}
                  />
                  {/* <Legend /> */}
                  <Area
                    type="linear"
                    dataKey="queries"
                    stroke={props.currenPagetTab==='user_queries' ?"#63B7B1":"#CA8F54"}
                    fillOpacity={1}
                    fill="url(#colour-resolved)"
                    strokeWidth={3}
                    activeDot={{stroke:props.currenPagetTab==='user_queries' ?"#63B7B1":"#CA8F54",fill:"white"}}
                  />
                </AreaChart>
              </ResponsiveContainer>
            ) : (
              <NodataContent>
                No data available
              </NodataContent>
            )}
          </div>
        </ChatWrapperLeft>
      </ChartWrapper>
    </ChartFeedbackWrapper>
  );
};

export function PieCharts(props: {
  data: {
    value: number;
    id: string;
    label: string;
  }[];
  colors: { [key: string]: string };
}) {
  const satisfied = props.data.filter((a) => a.id === 'resolved')[0];
  function returnColors(key: string) {
    switch (key) {
      case 'resolved':
        return props.colors[`${key}`];
      case 'abandoned':
        return props.colors[`${key}`];
      case 'escalated':
        return props.colors[`${key}`];
      default:
        return props.colors['unresolved'];
    }
  }
  const total = props.data.reduce((acc,val)=>acc+=val.value,0)
  return (
    <div className="analytics_charts_left_img pie-chart">
      <div className="pie_chart_img toolTip">
        <ResponsivePie
          // sortByValue={true}
          data={props.data}
          startAngle={0}
          innerRadius={0.85}
          borderWidth={0}
          enableSlicesLabels={false}
          animate={true}
          tooltip={CustomPieTooltip}
          colors={props.data.map((entry, index) => (returnColors(entry.id))) as any}
          enableRadialLabels={false}
          // theme={{
          //   tooltip: {
          //     container: {
          //       display: 'none',
          //     },
          //   },
          // }}
        >
          {/* {props.data.map((entry, index) => (
            <Cell stroke={returnColors(entry.id)} fill={returnColors(entry.id)} />
          ))} */}
        </ResponsivePie>
        <span className="pie_chart_center">
          <p style={{ color: '#37BCB3' }}>{satisfied ? Math.round((satisfied.value/total)*100) : 0}%</p>
          <p style={{ color: '#37BCB3' }}>Auto Resolved</p>
          {/* <p>
              <img src={SatisfiedEmoji} alt="satisfied" />
            </p> */}
        </span>
      </div>
    </div>
  );
}

export function MostFrequentPieCharts(props: {
  data: {
    value: number;
    id: string;
    label: string;
  }[];
  colors: string[];
}) {
  const filteredData = props.data.map((data,i)=>{
    const datachange = 
      {
        colors:props.colors[i],
        ...data
      }
    
    return datachange
  })

  const modifiedData= filteredData

  return (
    <div className="analytics_charts_left_img pie-chart" style={{marginTop: 0}}>
      <div style={{marginTop: 0}} 
      // className="pie_chart_img toolTip"
      >
        {modifiedData.length > 0 ? (
          // <ResponsivePie
          //   sortByValue={true}
          //   data={modifiedData}
          //   startAngle={0}
          //   // innerRadius={0.85}
          //   borderWidth={1}
          //   borderColor={'#FCFCFC'}
          //   enableSlicesLabels
          //   animate={true}
          //   tooltip={CustomPieTooltipForMostFrequentPieChart}
          //   colors={props.colors}
          //   enableRadialLabels={false}
          //   // theme={{
          //   //   tooltip: {
          //   //     container: {
          //   //       display: 'none',
          //   //     },
          //   //   },
          //   // }}
          // >
          // {/* {modifiedData.map((entry, index) => 
          // (
          //   // {console.log(entry.colors,'checking')}
          //   <Cell
          //      key={`cell-${index}`}
          //      fill={entry.colors}
          //   />
          //   )
          //   )} */}
          // </ResponsivePie>
          <PieChart width={199} height={200}>
          <Pie
            data={modifiedData}
            color="#000000"
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={100}
            fill="#8884d8"
          >
         {modifiedData.map((entry, index) => (
            <Cell
               key={`cell-${index}`}
               fill={`${entry.colors}`}
            />
         ))}
        </Pie>
        <ChartTip content={<CustomPieTooltipForMostFrequentPieChart />} />
        {/* <Legend /> */}
        </PieChart>
          
        ) : (
          <NodataContent>No data available</NodataContent>
        )}
      </div>
    </div>
  );
}

export const CustomPieTooltipForMostFrequentPieChart = (props: any) => {
  const { active, payload, label } = props;
    if (active && payload && payload.length) {
      const pLoad = payload[0].payload;
      return (
        <div className="custom-tooltip">
          <p className="tooltip_date">
           {pLoad.label}:
            <span>{pLoad.value ? pLoad.value : 0}</span>
          </p>
        </div>
      );
    }
    return null;
  // if (props) {
  //   return (
  //     <div className={'tooltiptext'}>
  //       {props.label}: {props.value}
  //     </div>
  //   );
  // }
  // return null;
};
