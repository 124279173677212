import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  ChartFeedbackWrapper,
  ChartWrapper,
  ChatWrapperLeft,
  ChatWrapperRight,
  CountNumber,
  CountWrapper,
  DropdownLi,
  DropdownLiSpan,
  DropdownUl,
  DropdownWrapper,
  FeedbackWrapper,
  Header,
  HeaderContainer,
  Input,
  InputContent,
  NodataContent,
  Row,
  TabCardContainer,
} from '@/common/styled/Dialog.BotDetails.Dumb';
import {
  BotDropdown,
  InfoIcon,
  SatisfiedEmoji,
  TriangleIcon,
  UnSatisfiedEmoji,
} from '@/common/Icons/Icons';
import Tooltip from 'react-tooltip-lite';
import thumbs_down from '@/common/images/bot_page/thumbs_down.svg';
import thumbs_up from '@/common/images/bot_page/thumbs_up.svg';
import {
  useFetchAreaBotSummary,
  useFetchBarchartBotSummary,
  useFetchBotSummary,
  useUpdateAreaBotSummary,
  useUpdateBarChartSummary,
  useUpdateBotSummary,
} from './BotSummaryAPI';
import { BasiceDropdown, OverAllSummaryT } from './BotDetails';
import Downshift from 'downshift';
import { DateSelectInput } from '@/Flows/analytics/dateDropDown';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip as ChartTip,
  AreaChart,
  Label,
  Area,
  Cell,
  Text,
} from 'recharts';
import * as R from 'ramda';
import { AssistantT } from '../Construction/kind';
import { ResponsivePie } from '@nivo/pie';
import {
  convertFromToDate,
  formatAMPM,
  formatAreaChartDate,
  formatDateInCard,
} from '@/common/utils/utils';
import BasicDateRangePicker from './CustomDatePicker';
import { differenceInCalendarDays, startOfWeek } from 'date-fns';
import { Loader } from '@/common/components/Loader';
var approx = require('approximate-number');

type QueryT = {
  date: string;
  num_of_queries: number;
  num_of_conversations: number;
};

type BotSummaryTypeT = {
  total_chatbot_session	:number 
  total_user_queries:number
  avg_session_per_user:number
  avg_session_duration: string;
  user_feedback_positive_count: number;
  user_feedback_negative_count: number;
  agent_feedback_positive_count: number;
  agent_feedback_negative_count: number;
  agent_session_count: number;
  day_wise_session_query_count: QueryT[];
  week_wise_session_query_count: QueryT[];
};

export function percentageCalculate(percentage:number){
  return Math.round(percentage)?Math.round(percentage):0
}

const StatusDetails = [
  {
    header: 'Avg. sessions per user',
    image: <InfoIcon />,
    hoverContent: 'Average number of bot sessions by a user',
  },
  {
    header: 'Avg. session duration',
    hours: '(hh:mm:ss)',
  },
  {
    header: '',
  },
];

export const barchartDates: DateSelectInput[] = [
  {
    date: 'today',
    label: 'Today',
  },
  {
    date: 'yesterday',
    label: 'Yesterday',
  },
  {
    date: 'last7days',
    label: 'Last 7 days',
  },
  {
    date: 'last30days',
    label: 'Last 30 days',
  },
  {
    date: 'currentMonth',
    label: 'Current Month',
  },
  {
    date: 'previousMonth',
    label: 'Previous Month',
  },
  // {
  //   date: 'customrange',
  //   label: 'Custom Range',
  // },
];

export const areachartDates: DateSelectInput[] = [
  {
    date: 'last2months',
    label: 'Last 2 months',
  },
  {
    date: 'last4weeks',
    label: 'Last 4 weeks',
  },
  {
    date: 'last2weeks',
    label: 'Last 2 weeks',
  },
  {
    date: 'last7days',
    label: 'Last 7 days',
  },
  {
    date: 'last2days',
    label: 'Last 2 days',
  },
];

export function returnDate(filter: any) {
  switch (filter) {
    case 'last14days':
      return 14;
    case 'last30days':
      return 30;
    case 'last7days':
      return 7;
    case 'last2days':
      return 2;
    case 'last2weeks': {
      const d = new Date().getDay();
      return 14 - (d + 1 == 8 ? 7 : 7 - (d + 1));
    }
    case 'last4weeks': {
      const d = new Date().getDay();
      return 28 - (d + 1 == 8 ? 7 : 7 - (d + 1));
    }
    case 'last2months': {
      const date = new Date();

      const firstDayPrevMonth = new Date(
        date.getFullYear(),
        date.getMonth() - 1,
        1
      );
      const result = startOfWeek(firstDayPrevMonth);

      return differenceInCalendarDays(date, result);
    }
    case 'currentMonth': {
      const date = new Date();
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      return {
        from_date: convertFromToDate(firstDay.toString()),
        to_date: convertFromToDate(date.toString()),
      };
    }
    case 'previousMonth': {
      const date = new Date();
      const firstDayPrevMonth = new Date(
        date.getFullYear(),
        date.getMonth() - 1,
        1
      );
      const lastDayPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0);
      return {
        from_date: convertFromToDate(firstDayPrevMonth.toString()),
        to_date: convertFromToDate(lastDayPrevMonth.toString()),
      };
    }
    default:
      return filter;
  }
}

export const BotSessionTab = (props: {
  botId: string | undefined;
  workspace: string;
  overAllSummary: OverAllSummaryT;
  selectedBot: AssistantT;
  setTabLoading:(loading:boolean)=>void
}) => {
  const { overAllSummary, setTabLoading } = props;
  const [dateOptions, setOptions] = useState('last30days')

  const { botSummary, botSummaryStatus, botSummaryRetry, dataUpdatedAt, isRefetching,isFetching } =
    useFetchBotSummary(props.workspace, props.botId ? props.botId : '', returnDate(dateOptions), {
      enabled: !!props.botId,
      onSuccess:(data:any)=>{
        setTabLoading(false)
      }
    });

  // const botSummaryRetry = () => {};
  const {
    areaBotSummaryRetry,
    areaBotSummaryStatus,
    areaSummary,
    // dataUpdatedAt,
  } = useFetchAreaBotSummary(
    props.workspace,
    props.botId ? props.botId : '',
    returnDate('last2months'),
    {
      enabled: !!props.botId,
    },
    'areabotsummary'
  );
//   const overAllSummary_Mock= [{
//     avg_query_per_session : 10,
//     avg_query_per_user :2,
//     avg_query_resolved_per_user :1,
//     avg_session_per_user :1,
//     total_auto_resolved_queries:5,
//     total_chatbot_session:10,
//     total_unique_users:8,
//     total_user_queries:4,
//   }
// ]
//   const botDetails_Mock =[{
//     agentSessions :5,
// name:"Agent Sessions"
//   }]
  const { barchartBotSummary, barchartBotSummaryStatus, barchartBotRetry } =
    useFetchBarchartBotSummary(
      props.workspace,
      props.botId ? props.botId : '',
      '30',
      {
        enabled: !!props.botId,
      },
      'barchartsummary'
    );

  // const { botSummaryMutate, botSummaryMutateStatus } = useUpdateBotSummary();

  const { barChartMutate, barChartStatus } =
    useUpdateBarChartSummary('barchartsummary');

  const { areaChartMutate, areaChartStatus } =
    useUpdateAreaBotSummary('areabotsummary');

  const summary = botSummary
    ? botSummary
    : {
        avg_session_duration: '0:0:0',
        user_feedback_positive_count: 0,
        user_feedback_negative_count: 0,
        agent_feedback_positive_count: 0,
        agent_feedback_negative_count: 0,
        agent_session_count: 0,
        day_wise_session_query_count: [],
        week_wise_session_query_count: [],
        total_chatbot_session	:0,
        total_user_queries:0,
        avg_session_per_user:0
      };

  const [state, setState] = useState({
    showBarChartDropDown: false,
    selectedBarchartDate: 'last30days',
    showAreaChartDropDown: false,
    selectedAreachartDate: 'last2months',
    customDatePickerShow: false,
    customDates: { from_date: null, to_date: null },
  });
  function barchartDate() {
    return barchartDates.filter(
      (it) => it.date === state.selectedBarchartDate
    )[0].label;
  }

  function areachartDate() {
    return areachartDates.filter(
      (it) => it.date === state.selectedAreachartDate
    )[0].label;
  }

  useEffect(() => {
    barchartBotSummary &&
      (state.selectedBarchartDate !== 'customrange' ||
        (state.customDates.from_date != null &&
          state.customDates.to_date != null)) &&
      barChartMutate({
        botId: props.botId,
        workspace: props.workspace,
        date:
          state.selectedBarchartDate === 'customrange'
            ? state.customDates
            : returnDate(state.selectedBarchartDate),
      });
    state.selectedBarchartDate === 'customrange' &&
      setState({ ...state, customDatePickerShow: true });
  }, [state.selectedBarchartDate, state.customDates]);

  useEffect(() => {
    areaSummary &&
      areaChartMutate({
        botId: props.botId,
        workspace: props.workspace,
        date: returnDate(state.selectedAreachartDate),
      });
  }, [state.selectedAreachartDate]);

  function retnFn(i: number) {
    switch (i) {
      case 0:
        return summary.avg_session_per_user;
      case 1:
        return summary.avg_session_duration;
      default:
        return (
          summary.agent_feedback_positive_count +
          summary.user_feedback_positive_count
        );
    }
  }

  const totalFeedbacks =
    summary.agent_feedback_positive_count +
    summary.user_feedback_positive_count +
    summary.agent_feedback_negative_count +
    summary.user_feedback_negative_count;

  return (
    <div className="bot_sessions_container">
      {isRefetching && 
        setTabLoading(true)
      }
      {isFetching && 
       setTabLoading(true)
      }
      <div className="bot_sessions_heaader">
        <div>
          <h5>Closer look at your bot sessions </h5>
        </div>
        <div className="d-flex align-items-center bot_sessions_heaader_color">
          <div className="report_content">
            This report was generated on{' '}<br/>
            {formatDateInCard((botSummary?new Date(dataUpdatedAt):new Date()).toString(), true)} at{' '}
            {formatAMPM((botSummary?new Date(dataUpdatedAt):new Date()).toString(), true)} -{' '}
            <span onClick={() => {
              setTabLoading(true)
              botSummaryRetry()
            }}>Refresh</span>
          </div>
          <BasiceDropdown dateOptions={dateOptions} changeDateFilter={()=>{
              setTabLoading(true)
            }} setOptions={setOptions}/>
          </div>
      </div>
      <HeaderContainer>
        <TabCardContainer>
          {StatusDetails.map((data, i) => (
            <Card key={i}>
              <CardHeader>
                {i !== 2
                  ? data.header
                  : `sessions were rated positive out of total sessions that received a feedback`}{' '}
                  {/* ${totalFeedbacks} */}
                <Tooltip
                  className="target_bot"
                  tipContentHover
                  zIndex={5000}
                  arrowSize={5}
                  tagName="span"
                  content={data.hoverContent}
                >
                  <span>{data.image}</span>
                </Tooltip>
              </CardHeader>
              {data.hours && (
                <CardHeader>
                  <span>{data.hours}</span>
                </CardHeader>
              )}
              <CountNumber>
                <CountWrapper>{retnFn(i)}</CountWrapper>
              </CountNumber>
            </Card>
          ))}
        </TabCardContainer>
      </HeaderContainer>
      <div className="tab_section_wrapper">
        <div className="tab_section_header">
          <div>
            <h5>Bot Sessions</h5>
            <p>User queries in bot sessions</p>
          </div>
          <div className="tab_dropdown_date">
            {state.customDatePickerShow && (
              <BasicDateRangePicker
                setDate={(date: Date[]) => {
                  setState({
                    ...state,
                    customDates: {
                      from_date: convertFromToDate(date[0].toString()) as any,
                      to_date: convertFromToDate(date[1].toString()) as any,
                    },
                  });
                }}
              />
            )}
            <DropdownWrapper>
              <Input
                onClick={() =>
                  setState({ ...state, showBarChartDropDown: true })
                }
                onChange={() => {}}
                value={barchartDate()}
              />
              <InputContent>Show by</InputContent>
              <span 
                onClick={() =>
                  setState({ ...state, showBarChartDropDown: true })
                }
              >
                <BotDropdown />
              </span>
              {state.showBarChartDropDown && (
                <Downshift
                  isOpen={true}
                  onOuterClick={() =>
                    setState({ ...state, showBarChartDropDown: false })
                  }
                >
                  {() => (
                    <div>
                      <DropdownUl>
                        {barchartDates.map((date, i) => (
                          <DropdownLi
                            key={i}
                            onClick={() => {
                              setState({
                                ...state,
                                selectedBarchartDate: date.date,
                                showBarChartDropDown: false,
                              });
                            }}
                          >
                            <DropdownLiSpan>{date.label}</DropdownLiSpan>{' '}
                          </DropdownLi>
                        ))}
                      </DropdownUl>
                    </div>
                  )}
                </Downshift>
              )}
            </DropdownWrapper>
          </div>
        </div>
        <div className="tab_section_graph">
          <ChartFeedbackWrapper>
            <ChartWrapper chartsBg>
              <ChatWrapperLeft chartsPadding>
                <div className="bar_charts_wrapper">
                  <BarCharts
                  // day_wise_session_query_count={
                  //   [
                  //     {date :"11/03/2023",
                  //     num_of_conversations:5,
                  //     num_of_queries:7},
                  //     {date :"12/03/2023",
                  //     num_of_conversations:10,
                  //     num_of_queries:18},
                  //     {date :"13/03/2023",
                  //     num_of_conversations:12,
                  //     num_of_queries:15},
                  //     {date :"14/03/2023",
                  //     num_of_conversations:15,
                  //     num_of_queries:20},
                  //     {date :"15/03/2023",
                  //     num_of_conversations:15,
                  //     num_of_queries:1}
                  //   ]
                  // }
                    day_wise_session_query_count={
                      barchartBotSummary ? barchartBotSummary : []
                    }
                    dateOptions={state.selectedBarchartDate}
                  />
                </div>
              </ChatWrapperLeft>
            </ChartWrapper>
          </ChartFeedbackWrapper>
        </div>
      </div>
      <div className="tab_section_wrapper">
        <div className="tab_section_header">
          <div>
            <h5>Bot Sessions</h5>
            <p>Compare user queries in bot sessions over a specific period</p>
          </div>
          <div className="tab_dropdown_date">
            <DropdownWrapper>
              <Input
                onClick={() =>
                  setState({ ...state, showAreaChartDropDown: true })
                }
                onChange={() => {}}
                value={areachartDate()}
              />
              <InputContent>Show comparision</InputContent>
              <span 
                onClick={() =>
                  setState({ ...state, showAreaChartDropDown: true })
                }
              >
                <BotDropdown />
              </span>
              {state.showAreaChartDropDown && (
                <Downshift
                  isOpen={true}
                  onOuterClick={() =>
                    setState({ ...state, showAreaChartDropDown: false })
                  }
                >
                  {() => (
                    <div>
                      <DropdownUl>
                        {areachartDates.map((date, i) => (
                          <DropdownLi
                            key={i}
                            onClick={() => {
                              setState({
                                ...state,
                                selectedAreachartDate: date.date,
                                showAreaChartDropDown: false,
                              });
                            }}
                          >
                            <DropdownLiSpan>{date.label}</DropdownLiSpan>{' '}
                          </DropdownLi>
                        ))}
                      </DropdownUl>
                    </div>
                  )}
                </Downshift>
              )}
            </DropdownWrapper>
          </div>
        </div>
        <div className="tab_section_graph">
          <AreaChartWithWrapper
            areaDetails={areaSummary ? areaSummary : []}
            dateOptions={state.selectedAreachartDate}
          />
        </div>
      </div>
      <div className="tab_section_3_col_container">
        <div className="tab_col">
          <div className="tab_section_header">
            <div>
              <h5>User feedback on bot sessions</h5>
            </div>
          </div>
          <ChartFeedbackWrapper>
            <ChartWrapper chartsBg>
              <ChatWrapperRight chartsPadding>
                <BotSessionsPieChat
                  botDetails={summary}
                  positiveKey="user_feedback_positive_count"
                  negativeKey="user_feedback_negative_count"
                />
              </ChatWrapperRight>
            </ChartWrapper>
          </ChartFeedbackWrapper>
          <FeedbackWrapper>
            <label>
              <img src={thumbs_up} alt="thumbs_up" />
              <span>Satisfied</span>
            </label>
            <label>
              <img src={thumbs_down} alt="thumbs_down" />
              <span>Not Satisfied</span>
            </label>
          </FeedbackWrapper>
        </div>
        <div className="tab_col">
          <div className="tab_section_header">
            <div>
              <h5>User feedback on agent handover</h5>
            </div>
          </div>
          {/* <div className="tab_col_graph">
            <p>User feedback on agent handover</p>
          </div> */}
          <ChartFeedbackWrapper>
            <ChartWrapper chartsBg>
              <ChatWrapperRight chartsPadding>
                <BotSessionsPieChat
                  botDetails={summary}
                  positiveKey={'agent_feedback_positive_count'}
                  negativeKey={'agent_feedback_negative_count'}
                />
              </ChatWrapperRight>
            </ChartWrapper>
          </ChartFeedbackWrapper>
          <FeedbackWrapper style={{ marginTop: 40 }}>
            <label>
              <img src={thumbs_up} alt="thumbs_up" />
              <span>Satisfied</span>
            </label>
            <label>
              <img src={thumbs_down} alt="thumbs_down" />
              <span>Not Satisfied</span>
            </label>
          </FeedbackWrapper>
        </div>
        <div className="tab_col">
          <div className="tab_section_header">
            <div>
              <h5>Bot sessions vs. Agent handover</h5>
            </div>
          </div>
          {/* <div className="tab_col_graph">
            <p>Bot sessions vs. Agent handover</p>
          </div> */}
          <ChartFeedbackWrapper>
            <ChartWrapper chartsBg>
              <ChatWrapperLeft chartsPadding>
                <div className="bar_charts_wrapper three_col">
                  <BotAndAgentBarchart
                    // overAllSummary={overAllSummary_Mock}
                    overAllSummary={props.overAllSummary}
                    botDetails={summary}
                    //  botDetails={botDetails_Mock}
                  />
                </div>
              </ChatWrapperLeft>
            </ChartWrapper>
          </ChartFeedbackWrapper>
        </div>
      </div>
    </div>
  );
};

function BarCharts(props: {
  day_wise_session_query_count: QueryT[];
  dateOptions: string;
}) {
  const BarchartTooltip = (props: any) => {
    // const { series, seriesIndex, dataPointIndex, w } = props
    // console.log("pLoad",props)

    const { active, payload, label } = props;
    if (active && payload && payload.length) {
      const pLoad = payload[0].payload;
      return (
        <div className="custom-tooltip">
          <p className="tooltip_date">
            Bot Sessions:
            <span style={{color:"#6B5AD0"}}>{pLoad.sessions ? pLoad.sessions : 0}</span>
          </p>
          <p>
            User Queries: <span>{pLoad.queries ? pLoad.queries : 0}</span>
          </p>
        </div>
      );
    }
    return null;
  };
  type ChartData = {
    name: string;
    sessions: number;
    queries: number;
  };

  const { day_wise_session_query_count, dateOptions } = props;

  function filterDate() {
    return day_wise_session_query_count.map((chart) => {
      const date =
        dateOptions != 'today' && dateOptions != 'yesterday'
          ? chart.date.split('/')[0] + '/' + (parseInt(chart.date.split('/')[1]) < 10
          ? '0' + parseInt(chart.date.split('/')[1])
          : parseInt(chart.date.split('/')[1]))
          : chart.date.split('-')[1];
      return {
        name: date,
        sessions: chart.num_of_conversations,
        queries: chart.num_of_queries,
      };
    });
  }

  const datas: ChartData[] = filterDate();

  const modifiedData = [
    {
      name: '',
      sessions: 0,
      queries: 0,
    },
    ...datas,
  ];

  const maxValue = modifiedData
    .map((x: ChartData) => x.sessions + x.queries)
    .reduce((prev, curr) => {
      return Math.max(prev, curr);
    }, 0);

  const length = `${maxValue}`.length - 1;

  const unit = maxValue <= 25 ? 5 : parseInt(1 + '0'.repeat(length));
  // const [position, setPosition] = useState({});
  const [position, setPosition] = useState({
    data: { x: 0, y: 0,height:0 },
    show: false,
    belowBar:false,
  });

  const yAxisTicks =
    unit === 5
      ? [5, 10, 15, 20, 25, 30]
      : R.unfold<number, number>((it) =>
          it > maxValue + 2 * unit ? false : [it, it + unit]
        )(unit);
  
  return (
    // <div className="analytics_charts_left_img">
    <div
      style={{ width: '100%' }}
      className={
        datas.length > 0 ? 'bot_session_chart' : 'no_data_bot_session_chart'
      }
    >
      {datas.length > 0 ? (
        <div className="bot-summary-all-sessions">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={830}
              height={320}
              data={modifiedData}
              margin={{
                top: 15,
                right: 40,
                left: -10,
                bottom: 5,
              }}
              barCategoryGap={'35%'}
              barGap={'10%'}
            >
              <defs>
              <linearGradient id="sessionsclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                <stop offset='0%' stopColor='#BDB2FF' />
                <stop offset='34%' stopColor='#BCB1FD' />
                <stop offset='100%' stopColor='#978FCC'/>
              </linearGradient>
              <linearGradient id="queriesclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                <stop offset='0%' stopColor='#A1E89B' />
                <stop offset='100%' stopColor='#51744E'/>
              </linearGradient>
              </defs>
              <CartesianGrid />
              <XAxis
                angle={(props.dateOptions === "today" || props.dateOptions ===  "yesterday") ? 0 : 45}
                dy={8}
                dx={(props.dateOptions === "today" || props.dateOptions ===  "yesterday") ? 0 :12}
                dataKey="name"
                interval={0}
                // label={{
                //   value:
                //     dateOptions == 'today' || dateOptions == 'yesterday'
                //       ? 'Hours'
                //       : 'Date',
                //   position: 'insideBottom',
                //   className: 'xAxisLabel',
                //   offset: -20,
                // }}
                scale="point"
                // padding={{ left: 5, right: 5 }}
                axisLine={false}
                tickLine={false}
              />
              <YAxis
                allowDecimals={false}
                label={{
                  value: 'Sessions',
                  angle: -90,
                  position: 'insideLeft',
                  offset: 16,
                  style: { textAnchor: 'middle' },
                }}
                axisLine={false}
                tickLine={{ stroke: '#F2F2F2' }}
                tickFormatter={(x: number) => {
                  return approx(x);
                }}
                ticks={[0, ...yAxisTicks]}
                tickMargin={5}
              />
              <ChartTip
                isAnimationActive={false}
                cursor={false}
                position ={position.belowBar ? (position.show? {x:position.data.x - 60, y:position.data.y-position.data.height - 45}: undefined) : (position.show? {x:position.data.x - 60, y:position.data.y - 45}: undefined)}
                // content={<BarchartTooltip />}
                content={
                  position.show ? (
                    <BarchartTooltip />
                  ) : (
                    <div style={{ display: 'none' }}></div>
                  )
                }
              />
              <Bar dataKey="sessions" stackId="a" 
                fill="url(#sessionsclr)"
                // "#BDB2FF" 
                barSize={10}
                onMouseOver={(data) => {
                  setPosition({ data: data, show: true, belowBar:true })
                }}
                onMouseLeave={(data) =>
                  setPosition({ data : { x : 0 , y : 0,height:0 },show: false,belowBar:false })
                }
                isAnimationActive={false}
                 />

              <Bar
                dataKey="queries"
                stackId="a"
                fill="url(#queriesclr)"
                // "#A1E89B"
                barSize={10}
                radius={[10, 10, 0, 0]}
                isAnimationActive={false}
                onMouseOver={(data,label) => {
                  setPosition({ data: data, show: true,belowBar:false });
                }}
                onMouseLeave={(data,label) => {
                  setPosition({ data : { x : 0 , y : 0,height:0 },show: false,belowBar:false });
                }}
              >
              </Bar>
              {/* <Bar dataKey="resolved" stackId="a" fill="#7AE3DC" barSize={10} /> */}
              {/* ['#EB6453', '#FFAB57', '#7AE3DC'] */}
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <NodataContent>No data available</NodataContent>
      )}
    </div>
    // </div>
  );
}

function BotAndAgentBarchart(props: {
  overAllSummary: OverAllSummaryT;
  botDetails: BotSummaryTypeT;
}) {
  const BarchartTooltip = (props: any) => {
    // const { series, seriesIndex, dataPointIndex, w } = props
    // console.log("pLoad",props)

    const { active, payload, label } = props;

    if (active && payload && payload.length) {
      if (payload[0].dataKey === 'agentSessions') {
        const pLoad = payload[0].payload;
        return pLoad.agentSessions?(
          <div className="custom-tooltip">
            <p className="tooltip_date">
              Agent Sessions:
              <span>{pLoad.agentSessions ? pLoad.agentSessions : 0}</span>
            </p>
          </div>
        ):null;
      } else {
        const pLoad = payload[0].payload;
        return pLoad.sessions || pLoad.queries?  (
          <div className="custom-tooltip">
            <p className="tooltip_date">
              Bot Sessions:
              <span>{pLoad.sessions ? pLoad.sessions : 0}</span>
            </p>
            <p>
              User Queries: <span>{pLoad.queries ? pLoad.queries : 0}</span>
            </p>
          </div>
        ):null;
      }
    }
    return null;
  };
  const AgentTooltip =(props: any) => {
    // const { series, seriesIndex, dataPointIndex, w } = props
    // console.log("pLoad",props)

    const { active, payload, label } = props;

    if (active && payload && payload.length) {
      // if (payload[0].dataKey === 'agentSessions') {
        const pLoad = payload[0].payload;
        return (
          <div className="custom-tooltip">
            <p className="tooltip_date">
              Agent Sessions:
              <span>{pLoad.queries ? pLoad.queries : 0}</span>
            </p>
          </div>
        ) 
      } else {return null }
  }
  type ChartData = {
    name: string;
    sessions: number;
    queries: number;
  };

  const { overAllSummary, botDetails } = props;

  function filterDate() {
    return [
      {
        name: '',
        sessions: 0,
        queries: 0,
      },
      {
        name: 'Bot Sessions with User Queries',
        sessions: botDetails.total_chatbot_session,
        queries: botDetails.total_user_queries,
      },
      {
        name: 'Agent Sessions',
        sessions: 0,
        queries: botDetails.agent_session_count,
      },
    ];
  }

  const datas: any[] = filterDate();

  const modifiedData = datas;

  const total = botDetails.total_chatbot_session+botDetails.total_user_queries+botDetails.agent_session_count
  const [position, setPosition] = useState({
    data: { x: 0, y: 0,height:0 },
    show: false,
    belowBar:false,
    label:0
  });

  const CustomizedAxisTick = (props:any) =>{
    const {x, y, payload} = props;
    return <Text className="bot_sessions_content" x={x} y={y} width={140} textAnchor="middle" verticalAnchor="start">{payload.value}</Text>
     }

  return (
    // <div className="analytics_charts_left_img">
    <div
      style={{ width: '100%' }}
      className={
        datas.length > 0 ? 'bot_session_chart' : 'no_data_bot_session_chart'
      }
    >
      {total > 0 ? (
        <div className="bot-summary-all-sessions">
          <span className='xAxisLine'></span>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={830}
              height={320}
              data={modifiedData}
              margin={{
                top: 15,
                right: 65,
                left: -20,
                bottom: 5,
              }}
              barCategoryGap={'20%'}
              barGap={'5%'}
            >
              <defs>
              <linearGradient id="sessionsclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                <stop offset='0' stopColor='#BDB2FF' />
                <stop offset='1' stopColor='#9990CE'/>
              </linearGradient>
              <linearGradient id="escalatedclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                <stop offset='0' stopColor='#A1E89B' />
                <stop offset='1' stopColor='#51744E'/>
              </linearGradient>
              <linearGradient id="agentSessionsclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                <stop offset='0' stopColor='#A7E1FF' />
                <stop offset='1' stopColor='#8CB0C3'/>
              </linearGradient>
              </defs>
              {/* <CartesianGrid /> */}
              <XAxis
                // angle={45}
                dy={12}
                dx={12}
                dataKey="name"
                interval={0}
                scale="point"
                axisLine={false}
                tickLine={false}
                tick={<CustomizedAxisTick />}
              >
                
              </XAxis>
              <ChartTip
                isAnimationActive={false}
                cursor={false}
                position ={position.belowBar ? (position.show? {x:position.data.x - 60, y:position.data.y-position.data.height - 45}: undefined) : (position.show? {x:position.data.x - 60, y:position.data.y - 45}: undefined)}
                content={position.show ? (position.label === 1 ? (
                    <BarchartTooltip />
                  ): (<AgentTooltip />)) : (
                    <div style={{ display: 'none' }}></div>
                  )
                }
              />

              <Bar dataKey="sessions" stackId="a" 
                fill="url(#sessionsclr)"
                // "#BDB2FF" 
                barSize={16} 
                isAnimationActive={false}
                onMouseOver={(data,label) => {
                  setPosition({data : data, show: true, belowBar:true,label:label });
                }}
                onMouseLeave={(data,label) => {
                  setPosition({ data : { x : 0 , y : 0,height:0 }, show: false, belowBar:true,label:label });
                }}>
                     
              </Bar>

              <Bar
                dataKey="queries"
                stackId="a"
                // fill="url(#escalatedclr)"
                // "#A1E89B"
                barSize={16}
                radius={[10, 10, 0, 0]}
                isAnimationActive={false}
                onMouseEnter={(data,label) => {
                  setPosition({data : data,show: true,belowBar:false,label:label });
                }}
                onMouseLeave={(data,label) => {
                  setPosition({ data : { x : 0 , y : 0,height:0 }, show: false, belowBar:false,label:label });
                }}
                >
                  {modifiedData.map((entry, index) => (
                  <Cell key={index} fill={entry.name === "Agent Sessions"? "url(#agentSessionsclr)" :"url(#escalatedclr)"}/>
                  ))} 
                </Bar>
              {/* /> */}
              {/* <Bar
                dataKey="agentSessions"
                fill="url(#agentSessionsclr)"
                // "#3A8FBC"
                barSize={10}
                radius={[10, 10, 0, 0]}
                isAnimationActive={false}
                onMouseOver={(data) => {
                  setPosition({ data: data, show: true,belowBar:false });
                }}
                onMouseLeave={(data) => {
                  setPosition({ data : { x : 0 , y : 0,height:0 }, show: false, belowBar:false });
                }}
              /> */}
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <NodataContent>No data available</NodataContent>
      )}
    </div>
    // </div>
  );
}

const AreaChartWithWrapper = (props: {
  areaDetails: QueryT[];
  dateOptions: string;
}) => {
  const { areaDetails, dateOptions } = props;

  const AreaTooltip = (props: any) => {
    const { active, payload, label } = props;

    if (active && payload && payload.length && payload[0].payload.sessions) {
      return (
        <div className="custom-tooltip _resolved">
          <p className="tooltip_date">
            Bot Sessions:
            <span>
              {payload[0].payload.sessions ? payload[0].payload.sessions : 0}{' '}
              {payload[0].payload.index > 1 && (
                <p
                  className="custom_tooltip_persentage_color"
                  style={{
                    color: payload[0].payload.sessionIncreased
                      ? '#70D397'
                      : '#D13737',
                  }}
                >
                  <span
                    className="custom_tooltip_persentage_img"
                    style={{
                      transform: payload[0].payload.sessionIncreased
                        ? 'rotate(0deg)'
                        : 'rotate(180deg)',
                      fill: payload[0].payload.queryIncresed
                        ? '#70D397'
                        : '#D13737',
                    }}
                  >
                    <TriangleIcon />
                  </span>
                  {Math.round(payload[0].payload.sessionPercentage) + '%'}
                </p>
              )}
            </span>
          </p>
          <p className="tooltip_date">
            User Queries:
            <span>
              {payload[0].payload.queries ? payload[0].payload.queries : 0}{' '}
              {payload[0].payload.index > 1 && (
                <p
                  className="custom_tooltip_persentage_color"
                  style={{
                    color: payload[0].payload.queryIncresed
                      ? '#70D397'
                      : '#D13737',
                  }}
                >
                  <span
                    className="custom_tooltip_persentage_img"
                    style={{
                      transform: payload[0].payload.sessionIncreased
                        ? 'rotate(0deg)'
                        : 'rotate(180deg)',
                      fill: payload[0].payload.queryIncresed
                        ? '#70D397'
                        : '#D13737',
                    }}
                  >
                    <TriangleIcon />
                  </span>
                  {Math.round(payload[0].payload.queryPercentage) + '%'}
                </p>
              )}
            </span>
          </p>
        </div>
      );
    }
    return null;
  };

  const datas = areaDetails.reduce(
    (accum, chart, i) => {
      const date = formatAreaChartDate(chart.date);
      // dateOptions != 'today' && dateOptions != 'yesterday'
      //   ? chart.date.split('/')[0] +
      //     '/' +
      //     (parseInt(chart.date.split('/')[1]) < 10
      //       ? '0' + parseInt(chart.date.split('/')[1])
      //       : parseInt(chart.date.split('/')[1]))
      //   : chart.date;
      const calculate = (first: number, second: number) =>
        first < second
          ? Math.abs((second - first) / second)
          : Math.abs((first - second) / first);
      const queryPercentage =
        i === 0 ? 100 : calculate(accum[i].queries, chart.num_of_queries) * 100;
      const sessionPercentage =
        i === 0
          ? 100
          : calculate(accum[i].sessions, chart.num_of_conversations) * 100;

      return [
        ...accum,
        {
          name: date,
          queries: chart.num_of_queries,
          sessions: chart.num_of_conversations,
          queryPercentage: queryPercentage,
          sessionPercentage: sessionPercentage,
          index: i + 1,
          queryIncresed: accum[i].queries < chart.num_of_queries,
          sessionIncreased: accum[i].sessions < chart.num_of_conversations,
        },
      ] as any;
    },
    [
      {
        name: '',
        queries: 0,
        sessions: 0,
        queryPercentage: 0,
        sessionPercentage: 0,
        index: 0,
        queryIncresed: false,
        sessionIncreased: false,
      },
    ] as any
  ) as { name: string; queries: number; sessions: number }[];
  const modifiedData = datas;

  const maxValue = modifiedData
    .map((x) => x.queries)
    .reduce((prev, curr) => {
      return Math.max(prev, curr);
    }, 0);

  const length = `${maxValue}`.length - 1;

  const unit = maxValue <= 25 ? 5 : parseInt(1 + '0'.repeat(length));

  const yAxisTicks =
    unit === 5
      ? [5, 10, 15, 20, 25, 30]
      : R.unfold<number, number>((it) =>
          it > maxValue + 2 * unit ? false : [it, it + unit]
        )(unit);

  return (
    <ChartFeedbackWrapper>
      <ChartWrapper chartsBg style={{ marginTop: 0 }}>
        <ChatWrapperLeft chartsPadding>
          {/* <Row>
            <Header>
              Bot Auto Resolved:{' '}
              {areaDetails.reduce(
                (acc, val) =>
                  (acc += val.num_of_queries ? val.num_of_queries : 0),
                0
              )}
            </Header>
          </Row> */}
          <div
            className={
              areaDetails.reduce((acc, val) => (acc += val.num_of_queries), 0) >
              0
                ? 'bot-auto-resolved'
                : 'bot-auto-resolved display_inline'
            }
          >
            {areaDetails.reduce((acc, val) => (acc += val.num_of_queries), 0) >
            0 ? (
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={830}
                  height={320}
                  data={datas}
                  margin={{
                    top: 15,
                    right: 40,
                    left: -10,
                    bottom: 5,
                  }}
                  barCategoryGap={'35%'}
                  barGap={'10%'}
                >
                  <defs>
                    <linearGradient
                      id="colour-resolved"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="5%" stopColor="#9990CE" stopOpacity={0.6} />
                      <stop offset="95%" stopColor="#9990CE" stopOpacity={1} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid />
                  <XAxis
                    // angle={45}
                    dy={2}
                    dx={3}
                    dataKey="name"
                    interval={0}
                    tickLine={false}
                    axisLine={false}
                    tickMargin={5}
                  >
                    <Label
                      value={
                        dateOptions == 'today' || dateOptions == 'yesterday'
                          ? 'Hours'
                          : 'Date'
                      }
                      position={'bottom'}
                      className={'xAxisLabel'}
                      offset={20}
                    />
                  </XAxis>
                  <YAxis
                    allowDecimals={false}
                    label={{
                      value: 'Sessions',
                      angle: -90,
                      position: 'insideLeft',
                      offset: 20,
                      style: { textAnchor: 'middle' },
                    }}
                    axisLine={false}
                    tickLine={{ stroke: '#F2F2F2' }}
                    tickFormatter={(x: number) => {
                      return approx(x);
                    }}
                    ticks={[0, ...yAxisTicks]}
                  />
                  <ChartTip
                    isAnimationActive={false}
                    cursor={false}
                    content={<AreaTooltip />}
                  />
                  {/* <Legend /> */}
                  <Area
                    type="linear"
                    dataKey="queries"
                    stroke="#9990CE"
                    fillOpacity={1}
                    fill="url(#colour-resolved)"
                    strokeWidth={3}
                    activeDot={{ stroke:"#9990CE",fill:"white" }}
                  />
                </AreaChart>
              </ResponsiveContainer>
            ) : (
              <NodataContent>
                No data available
              </NodataContent>
            )}
          </div>
        </ChatWrapperLeft>
      </ChartWrapper>
    </ChartFeedbackWrapper>
  );
};

function CustomAxis(props: any) {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={0}
        textAnchor="end"
        fill="#494949"
        className="customized-axis-tick-text"
      >
        {payload.value} hrs
      </text>
    </g>
  );
}

export const CustomizedTooltip = (props: any) => {
  if (props && props.payload[0]) {
    return (
      <div className="custom_tooltip">
        <p>
          {' '}
          Auto Resolved:{' '}
          <span style={{ color: '#000' }}>
            {props.botDetails.top_5_resolved_count.reduce(
              (acc: any, val: any) => (acc += val.status_count),
              0
            )}
          </span>
        </p>
        <p>
          {' '}
          Cost Savings:{' '}
          <span style={{ color: '#5A9BEA' }}>
            {props.payload[0].payload.dollar}
          </span>
        </p>
        <p>
          {' '}
          Hours Saved:{' '}
          <span style={{ color: '#BD60C7' }}>
            {props.payload[0].payload.name}
          </span>{' '}
        </p>
      </div>
    );
  }
  return null;
};

export function BotSessionsPieChat(props: {
  botDetails: BotSummaryTypeT;
  positiveKey: string;
  negativeKey: string;
}) {
  const { botDetails, negativeKey, positiveKey } = props;


  const userFeedbackTotal =
    botDetails[`${positiveKey}`] + botDetails[`${negativeKey}`];

  const avgPositiveFeedBack =
    (botDetails[`${positiveKey}`] / userFeedbackTotal) * 100;
  const avgNegativeFeedBack =
    (botDetails[`${negativeKey}`] / userFeedbackTotal) * 100;

  const data = [
    {
      value: !avgPositiveFeedBack ? 0 : Math.round(avgPositiveFeedBack),
      id: 'satisfied',
      label: 'Satisfied',
    },
    {
      value: !avgNegativeFeedBack ? 0 : Math.round(avgNegativeFeedBack),
      id: 'notsatisfied',
      label: 'Not Satisfied',
    },
  ];
  return (
    // <div className="pie_chat">
    userFeedbackTotal == 0 ? (
      <div className={ userFeedbackTotal == 0 ? "pie_chart_img with_out_data" : "pie_chart_img"}>
        <NodataContent>No data available</NodataContent>
      </div>
    ) : (
      <PieCharts data={data} colors={['#7AE3DC', '#EB6453', '#FFAB57']} />
    )
    // </div>
  );
}

export function PieCharts(props: {
  data: {
    value: number;
    id: string;
    label: string;
  }[];
  colors: string[];
}) {
  const satisfied = props.data.filter((a) => a.id === 'satisfied')[0];
  const notsatisfied = props.data.filter((a) => a.id === 'notsatisfied')[0];
  return (
    <div className="analytics_charts_left_img pie-chart">
      <div className="pie_chart_img">
        <ResponsivePie
          sortByValue={true}
          data={props.data}
          startAngle={0}
          innerRadius={0.85}
          borderWidth={0}
          enableSlicesLabels={false}
          animate={true}
          // tooltip={CustomPieTooltip}
          colors={props.colors}
          enableRadialLabels={false}
          theme={{
            tooltip: {
              container: {
                display: 'none',
              },
            },
          }}
        />
        {satisfied && notsatisfied && satisfied.value >= notsatisfied.value && (
          <span className="pie_chart_center">
            <p style={{ color: '#37BCB3' }}>
              {satisfied ? satisfied.value : 0}%
            </p>
            <p style={{ color: '#37BCB3' }}>Satisfied</p>
            <p>
              <img src={SatisfiedEmoji} alt="satisfied" />
            </p>
          </span>
        )}
        {satisfied && notsatisfied && satisfied.value < notsatisfied.value && (
          <span className="pie_chart_center">
            <p style={{ color: '#37BCB3' }}>
              {satisfied ? satisfied.value : 0}%
            </p>
            <p style={{ color: '#37BCB3' }}>Satisfied</p>
            <p>
              <img src={UnSatisfiedEmoji} alt="not satisfied" />
            </p>
          </span>
        )}
      </div>
    </div>
  );
}
