import * as R from 'ramda'

import { deleteJson, flowApiURL, getJson, postJson, watsonApiURL } from '@/common/utils/api-utils'
import { DialogT } from './Types'

export enum EntityApi {
    AllEntity = '/entities',
    Create = '/entity/create/',
    Update = '/entity/update/',
    ListDelete = '/entities/delete'
}
export enum IntentApi {
    AllIntents = '/intents',
    AddIntent = '/intent/create/',
    UpdateIntent = '/intent/update/',
    DeleteIntent = '/intent/delete/',
    DeleteMultipleIntent = '/intents/delete/'
}

export enum SettingsApi {
    settings = '/settings'
}

export enum RecordApi {
    TotalRecord = '/dialog_nodes',
    flowDeleteRecord = '/dialog_nodes/flow/',
    FlowRecord = '/flow-editor/flows/config',

    makeIfRecord = '/if_node/create',
    correctIfRecord = '/if_node/update/',
    removeIfRecord = '/if_node/delete/',

    makeTextRecord = '/text_node/create',
    correctTextRecord = '/text_node/update/',
    removeTextRecord = '/text_node/remove/',

    makeOptionRecord = '/option_node/create',
    correctOptionRecord = '/option_node/update/',
    removeOptionRecord = '/option_node/remove/',

    makeQueryRecord = '/qa_node/create',
    correctQueryRecord = '/qa_node/update/',
    removeQueryRecord = '/qa_node/remove/',

    makeSlotContainerRecord = '/slot_container/create',
    correctSlotContainerRecord = '/slot_container/update/',
    removeSlotContainerRecord = '/slot_container/remove/',

    makeSlotRecord = '/slot_node/create',
    correctSlotRecord = '/slot_node/update/',
    removeSlotRecord = '/slot_node/remove/',

    makeFlowRecord = '/flow_node/create',
    correctFlowRecord = '/flow_node/update/',
    removeFlowRecord = '/flow_node/remove/',

    Message = '/message.io',
    TrainingModule = '/cognidesk/logs/'
}

export const EarnTotalRecord = (workspacename: string) => getJson(watsonApiURL(workspacename, RecordApi.TotalRecord))

export const EarnFlowDeleteRecord = (workspacename: string, flowId: string) =>
    getJson(watsonApiURL(workspacename, RecordApi.flowDeleteRecord + flowId))

export const EarnFlowRecord = (workspaceName: string) => getJson(flowApiURL(workspaceName, RecordApi.FlowRecord))

export const EarnIntents = (workspacename: string) => getJson(watsonApiURL(workspacename, IntentApi.AllIntents))

export const EarnEntity = (workspacename: string) => getJson(watsonApiURL(workspacename, EntityApi.AllEntity))

export const MakeIfRecord = (workspacename: string, data: any) =>
    postJson(watsonApiURL(workspacename, RecordApi.makeIfRecord))(data).then(e => e.data)

export const CorrectIfRecord = (workspacename: string, dialog_node: string, data: any) =>
    postJson(watsonApiURL(workspacename, RecordApi.correctIfRecord + dialog_node))(data).then(e => e.data)

export const RemoveIfRecord = (workspacename: string, dialog_node: string) =>
    deleteJson(watsonApiURL(workspacename, RecordApi.removeIfRecord + dialog_node))()

export const MakeTextRecord = (workspacename: string, data: any) =>
    postJson(watsonApiURL(workspacename, RecordApi.makeTextRecord))(data).then(e => e.data)

export const CorrectTextRecord = (workspacename: string, dialog_node: string, data: any) =>
    postJson(watsonApiURL(workspacename, RecordApi.correctTextRecord + dialog_node))(data).then(e => e.data)

export const RemoveTextRecord = (workspacename: string, dialog_node: string) =>
    deleteJson(watsonApiURL(workspacename, RecordApi.removeTextRecord + dialog_node))({})

export const MakeOptionRecord = (workspacename: string, data: any) =>
    postJson(watsonApiURL(workspacename, RecordApi.makeOptionRecord))(data).then(e => e.data)

export const CorrectOptionRecord = (workspacename: string, dialog_node: string, data: any) =>
    postJson(watsonApiURL(workspacename, RecordApi.correctOptionRecord + dialog_node))(data).then(e => e.data)

export const RemoveOptionRecord = (workspacename: string, dialog_node: string) =>
    deleteJson(watsonApiURL(workspacename, RecordApi.removeOptionRecord + dialog_node))({})

export const MakeQueryRecord = (workspacename: string, data: any) =>
    postJson(watsonApiURL(workspacename, RecordApi.makeQueryRecord))(data).then(e => e.data)

export const CorrectQueryRecord = (workspacename: string, dialog_node: string, data: any) =>
    postJson(watsonApiURL(workspacename, RecordApi.correctQueryRecord + dialog_node))(data).then(e => e.data)

export const RemoveQueryRecord = async (workspacename: string, dialog_node: string) =>
    deleteJson(watsonApiURL(workspacename, RecordApi.removeQueryRecord + dialog_node))({})

export const MakeSlotContainerRecord = (workspacename: string, data: any) =>
    postJson(watsonApiURL(workspacename, RecordApi.makeSlotContainerRecord))(data).then(e => e.data)
export const CorrectSlotContainerRecord = (workspacename: string, dialog_node: string, data: any) =>
    postJson(watsonApiURL(workspacename, RecordApi.correctSlotContainerRecord + dialog_node))(data).then(e => e.data)

export const RemoveSlotContainerRecord = (workspacename: string, dialog_node: string) =>
    deleteJson(watsonApiURL(workspacename, RecordApi.removeSlotContainerRecord + dialog_node))()

export const MakeSlotRecord = (workspacename: string, data: any) =>
    postJson(watsonApiURL(workspacename, RecordApi.makeSlotRecord))(data).then(e => e.data)

export const CorrectSlotRecord = (workspacename: string, dialog_node: string, data: any) =>
    postJson(watsonApiURL(workspacename, RecordApi.correctSlotRecord + dialog_node))(data).then(e => e.data)

export const RemoveSlotRecord = (workspacename: string, dialog_node: string) =>
    deleteJson(watsonApiURL(workspacename, RecordApi.removeSlotRecord + dialog_node))()

export const MakeFlowRecord = (workspacename: string, data: any) =>
    postJson(watsonApiURL(workspacename, RecordApi.makeFlowRecord))(data).then(e => e.data)

export const CorrectFlowRecord = (workspacename: string, dialog_node: string, data: any) =>
    postJson(watsonApiURL(workspacename, RecordApi.correctFlowRecord + dialog_node))(data).then(e => e.data)

export const RemoveFlowRecord = (workspacename: string, dialog_node: string) =>
    deleteJson(watsonApiURL(workspacename, RecordApi.removeFlowRecord + dialog_node))()

export const DialogSort = (accumulator: DialogT[], currentValue: DialogT, index: number, arr: DialogT[]): DialogT[] => {
    if (accumulator.length) {
        const previous_sibling = accumulator.pop()
        if (previous_sibling) {
            const next_sibling = arr
                .filter((val: DialogT) => previous_sibling.dialog_node == val.previous_sibling)
                .pop()
            return next_sibling
                ? accumulator.concat(previous_sibling, next_sibling)
                : accumulator.concat(previous_sibling)
        }
        return accumulator
    } else {
        const root = arr.filter(value => value.previous_sibling == null).pop()
        return root ? accumulator.concat(root) : []
    }
}
export const sortBySibling = (sourch: DialogT[]) => sourch.reduce(DialogSort, [])

export const sourchConverter = (sourch: DialogT[]) =>
    sortBySibling(sourch).map((data: DialogT) => {
        if (data.children && data.children.length) {
            data.children = sourchConverter(data.children)
        }
        return data
    })

const handleIntent = (intent: string) => ({
    type: 'Intent',
    name: intent.substring(1)
})

const equalExpression = (expression: string) => {
    const vs = R.split(':')(expression)
    return {
        type: 'Entity',
        operator: '=',
        name: vs[0].substring(1),
        value: R.last(vs)
    }
}

const conditionalExpr = (expression: string) => {
    const vs = R.split(' ')(expression)
    return {
        type: 'Entity',
        operator: vs[1],
        name: vs[0].substring(1),
        value: R.last(vs)
    }
}

const noExpr = (expression: string) => {
    return { type: 'Entity', operator: 'None', name: expression.substring(1) }
}
const handleEntity = (entity: string) =>
    R.cond([
        [R.contains(':'), equalExpression],
        [R.complement(R.contains(' ')), noExpr],
        [R.T, conditionalExpr]
    ])(entity)

const handleContext = R.identity
const handleCombinator = (expr: string) => ({
    type: 'Combinator',
    name: expr
})
const isCombinatorString = R.either(R.equals('and'), R.equals('or'))
const handleExpression = R.cond([
    [R.startsWith('#'), handleIntent],
    [R.startsWith('@'), handleEntity],
    [R.startsWith('$'), handleContext],
    [isCombinatorString, handleCombinator],
    [R.T, R.identity]
])

export const parserOne = R.compose<any, any[], ReadonlyArray<string>, any[][], any[], string[], any[]>(
    R.map(handleExpression),
    R.map(R.trim),
    R.flatten,
    R.map(R.compose(R.intersperse('or'), R.split('||'))),
    R.intersperse('and'),
    R.split('&&')
)

export const parser = (x: string) => {
    const y = x as never
    return R.cond([
        [R.equals(''), R.always([])],
        [R.T, parserOne]
    ])(y)
}

const intent = ({ name }: { name: never }) => `#${name}`
const combinator = ({ name }: { name: string }) =>
    R.cond([
        [R.equals('and'), R.always('&&')],
        [R.T, R.always('||')]
    ])(name as never)

const entity = (entity: any) => {
    return R.cond([
        [R.equals('None'), R.always(`@${entity.name}`)],
        [R.equals('='), R.always(`@${entity.name}:${entity.value}`)],
        [R.equals('='), R.always(`@${entity.name}:${entity.value}`)],
        [R.T, R.always(`@${entity.name} ${entity.operator} ${entity.value}`)]
    ])(entity.operator as never)
}

const isIntent = R.compose<any, string, boolean>(R.equals('Intent'), R.prop('type'))
const isEntity = R.compose<any, string, boolean>(R.equals('Entity'), R.prop('type'))
const isCombinator = R.compose<any, string, boolean>(R.equals('Combinator'), R.prop('type'))

const parseOne = R.cond([
    [isIntent, intent],
    [isCombinator, combinator],
    [isEntity, entity],
    [R.T, R.identity]
])

export const parsAll = R.compose(R.join(' '), R.map(parseOne))

export const BounceExecution = async (workspacename: string, element: DialogT, jump_to: any, metadata: any) => {
    if (element.type == 'if') {
        return await CorrectIfRecord(workspacename, element.dialog_node, {
            ...element,
            jump_to,
            metadata
        })
    } else if (element.type == 'text-response') {
        return await CorrectTextRecord(workspacename, element.dialog_node, {
            ...element,
            jump_to,
            metadata
        })
    } else if (element.type == 'option-response') {
        return await CorrectOptionRecord(workspacename, element.dialog_node, {
            ...element,
            jump_to,
            metadata
        })
    } else if (element.type == 'slot-container') {
        return await CorrectSlotContainerRecord(workspacename, element.dialog_node, {
            ...element,
            jump_to,
            metadata
        })
    } else if (element.type == 'flow_call') {
        return await CorrectFlowRecord(workspacename, element.dialog_node, {
            ...element,
            jump_to,
            metadata
        })
    } else if (element.type == 'qa') {
        return await CorrectQueryRecord(workspacename, element.dialog_node, {
            ...element,
            jump_to,
            metadata
        })
    }
}
