/*eslint-disable*/
import React, { useEffect } from 'react'

import { useFetchFromApi } from '@/common/hooks/usefetch-from-api'

import {
    AutomationOnPremApi,
    automationAPIURLWithWorkspace,
    stripeGetApi,
    wrapperGetJson
} from '@/common/utils/api-utils'
import { OnPremAgent } from './onPrem'

const loadOnPremDate = (workspace: string) => {
    const onPremAccessApi = stripeGetApi(`${workspace}/info/ON_PREM_ACCESS`).then(
        (resp: { output: string; type: 'success' }) => {
            return resp.output == 'yes' ? true : false
        }
    )

    const onPremData = wrapperGetJson(automationAPIURLWithWorkspace(workspace, `${AutomationOnPremApi.GetAll}`)).then(
        (response: any) => response.data
    )

    const mergedData = Promise.all([onPremAccessApi, onPremData]).then(([onPremAccess, onPremData]: [boolean, any]) => {
        const hasOnPremAccess = onPremAccess
        return {
            ...onPremData,
            hasOnPremAccess: hasOnPremAccess
        }
    })

    return mergedData
}

export function OnPremWrapper(props: any) {
    const workspaceName = props.match.params.workspacename

    const [state, setState, promiseRef] = useFetchFromApi(() => loadOnPremDate(workspaceName))

    useEffect(() => {
        promiseRef.current = loadOnPremDate(workspaceName)
    }, [props.match.params.id])

    useEffect(() => {
        if (promiseRef.current != null) {
            const setStateDispatch = setState as React.Dispatch<any>
            setStateDispatch(promiseRef.current ? promiseRef.current.then((response: any) => response.data) : null)
        }
    }, [promiseRef.current])

    const retry = () => {
        const setStateDispatch = setState as React.Dispatch<any>
        // setStateDispatch(promiseRef.current ? promiseRef.current.then((response: any) => response.data) : null)

        setStateDispatch(
            promiseRef.current
                ? wrapperGetJson(automationAPIURLWithWorkspace(workspaceName, `${AutomationOnPremApi.GetAll}`)).then(
                      (response: any) => response.data
                  )
                : null
        )
    }

    return <OnPremAgent state={state} retry={retry} {...props} />
}
