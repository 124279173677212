/*eslint-disable*/
import React, { useState } from 'react'

import {
    DownarrowWithCircle,
    ExitIcon,
    ManageUserIcon,
    ProfileIcon,
    SubscriptionDetailsIcon,
    WorkspacesIcon
} from '@/common/Icons/Icons'
import { Modal } from '@/common/components/Modal'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import { ButtonWrapper, Paragraph } from '@/common/styled/Dialogue.Dumb'
import { WorkspacesT } from '@/common/types/WorkspacesT'
import { postMessage } from '@/common/user-sessions/across-tabs'
import { postJson } from '@/common/utils/axios-utils'

import { getJsonwithCredentials, loginApiURL } from '@/common/utils/api-utils'
import { usePermissions } from '@/common/utils/auth-token-api-utils'

export function Workspaces(props: any) {
    const { state, setState, profileData, emailLiRef, plan } = props

    const [showprompt, setShowprompt] = useState(false)

    const [expander, setExpander] = useState({
        showWorkspaces: { show: state.viewWorkspaces },
        showWorkspaceDeatiles: {
            show: state.viewWorkspaces,
            data: state.viewWorkspaces ? state.workspaces[0] : {}
        }
    })

    const [selectedLink] = useState({
        selectedLink: props.selectedLink,
        currentWorkspace: ''
    })

    const logOut = async () => {
        //Promise.resolve('logout')
        getJsonwithCredentials(loginApiURL('/logout'))
            .then((response: any) => {
                postMessage({ logout: true })
                location.reload()
            })
            .catch(err => {})
    }

    const splittedPath = props.match.path.split('/')

    const currentPath = splittedPath.length > 0 ? splittedPath : false

    const updateDefaultWS = (workspace: any) => {
        postJson(loginApiURL(`/update_def_ws/${workspace.id}`), {})
            .then(e => {
                setState({ ...state, defaultWs: workspace.id })
                console.log('success')
            })
            .catch(e => {
                console.log(e)
            })
    }
    const renderPopup = () =>
        showprompt ? (
            <Modal showPopupLoader={false}>
                <React.Fragment>
                    <h2>Confirm Logout </h2>
                    <Paragraph>Are you sure to logout? </Paragraph>
                    <ButtonWrapper className="log_out_container">
                        <Button className="log_out_button" primary type="button" onClick={() => setShowprompt(false)}>
                            <label>No</label>
                        </Button>
                        <Button className="log_out_button" type="button" onClick={() => logOut()}>
                            <label>Yes</label>
                        </Button>
                    </ButtonWrapper>
                </React.Fragment>
            </Modal>
        ) : null
    const profilePermission = usePermissions(props.match.params.workspacename,`BILLING_PAGE`,"WRITE")
    const manageUsersPermission = usePermissions(props.match.params.workspacename,`USERS_MODULE`,"WRITE")
    return (
        <>
            {renderPopup()}
            <section className="notifications_panel">
                <div onClick={props.closePopup} />
                <div>
                    <div className="profile_page_side">
                        <div className="profile_page_menu">
                            <ul>
                                <li className="pro_user_name" ref={emailLiRef}>
                                    <span>{`${profileData.first_name} ${profileData.last_name}`}</span>
                                    <span className="profile_email">{profileData.email}</span>
                                    <span className="profile_plan">
                                        <label>
                                            {plan.subscription &&
                                                (plan.subscription.status == 'trialing'
                                                    ? plan.trialPlan.mappedPlanDisplayName + ' Trial'
                                                    : plan.subscription.productPlan.plan.displayName)}
                                        </label>
                                    </span>
                                </li>
                                <li
                                    className={
                                        currentPath && currentPath[3] === 'profile'
                                            ? 'pro_user_svg pro_user_name_select'
                                            : 'pro_user_svg'
                                    }
                                    onClick={() => {
                                        if (currentPath && currentPath[3] !== 'profile') {
                                            props.history.push(`/workspace/${props.match.params.workspacename}/profile`)
                                            props.closePopup()
                                        }
                                    }}
                                >
                                    <ProfileIcon />
                                    <span>Profile</span>
                                </li>
                                {manageUsersPermission.value && (
                                <li
                                    className={
                                        currentPath && currentPath[3] === 'users'
                                            ? 'pro_user_svg pro_user_name_select'
                                            : 'pro_user_svg'
                                    }
                                    onClick={() => {
                                        if (currentPath && currentPath[3] !== 'users') {
                                            props.history.push(`/workspace/${props.match.params.workspacename}/users`)
                                            props.closePopup()
                                        }
                                    }}
                                >
                                    <ManageUserIcon />
                                    <span>Manage Users</span>
                                </li>
                                )}
                                {profilePermission.value && (
                                <li
                                    className={
                                        currentPath && currentPath[3] === 'billing'
                                            ? 'pro_user_svg pro_user_name_select'
                                            : 'pro_user_svg'
                                    }
                                    onClick={() => {
                                        if (currentPath && currentPath[3] !== 'billing') {
                                            props.history.push(`/workspace/${props.match.params.workspacename}/billing`)
                                            props.closePopup()
                                        }
                                    }}
                                >
                                    <SubscriptionDetailsIcon />
                                    <span>Billing</span>
                                </li>
                                )}
                                {/* <li className="pro_user_svg" onClick={() => {}}>
                                    <AuditLogsIcon />
                                    <span>Audit Logs</span>
                                </li> */}
                                {state.workspaces.length == 1 ? null : (
                                    <React.Fragment>
                                        <li
                                            className={
                                                selectedLink.selectedLink === 'Workspace'
                                                    ? 'pro_user_svg pro_user_name_select'
                                                    : 'pro_user_svg'
                                            }
                                            onClick={() =>
                                                setExpander({
                                                    ...expander,
                                                    showWorkspaces: {
                                                        ...expander.showWorkspaces,
                                                        show: !expander.showWorkspaces.show
                                                    }
                                                })
                                            }
                                        >
                                            <WorkspacesIcon />
                                            <span>Workspaces</span>
                                            <div className="svg_workspace_width">
                                                {expander.showWorkspaces.show ? (
                                                    <span style={{ transform: 'rotate(90deg)' }}>
                                                        <DownarrowWithCircle />
                                                    </span>
                                                ) : (
                                                    <DownarrowWithCircle />
                                                )}
                                            </div>
                                        </li>
                                        {/* list of workspaces */}
                                        {expander.showWorkspaces.show && (
                                            <div className="workspace_nav">
                                                <ul>
                                                    {state.workspaces.map((workspace: WorkspacesT, i: number) => (
                                                        <React.Fragment>
                                                            <WorkspaceItem
                                                                key={i}
                                                                singleWS={false}
                                                                defaultWSId={state.defaultWs}
                                                                workspace={workspace}
                                                                updateDefaultWS={updateDefaultWS}
                                                            />

                                                            {expander.showWorkspaceDeatiles.show &&
                                                                expander.showWorkspaceDeatiles.data.id ===
                                                                    workspace.id && <ul className="workspace_ul_bg" />}
                                                        </React.Fragment>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </React.Fragment>
                                )}
                                <li className="pro_user_svg" onClick={() => setShowprompt(true)}>
                                    <ExitIcon />
                                    <span>Logout</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const WorkspaceItem = (props: any) => {
    const { workspace, updateDefaultWS, singleWS, defaultWSId } = props
    console.log('workspace', workspace)
    return (
        <li
            className="workativ_users_active"
            style={
                singleWS
                    ? {
                          width: '100%',
                          padding: '0px'
                      }
                    : {}
            }
            onClick={() => {}}
        >
            <div className="accounts_users">
                <div className="accounts_users_name">
                    <div className="accounts_users_name_img accounts_users_change_border">
                        <div
                            className="accounts_users_name_img_circle"
                            onClick={e => window.open(workspace.workspaceURL)}
                        >
                            <span>{workspace.name.slice(0, 1).toUpperCase()}</span>
                        </div>
                        <h5 onClick={e => window.open(workspace.workspaceURL)}>{workspace.name}</h5>
                        <div className="work_workspace_name">
                            <label
                                className="label_checked"
                                onClick={e => {
                                    updateDefaultWS(workspace)
                                }}
                            >
                                <input
                                    type="radio"
                                    className="option-input radio"
                                    checked={defaultWSId == workspace.id}
                                />
                                <div className="hover_radio_default1">Set as Default</div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}
