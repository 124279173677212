import styled from 'styled-components/macro'

import { SmallButton } from './Dialog.Canvas.Nodes.Dumb'
import {
    DropdownUlWrapper,
    Input,
    InputContainer,
    Table,
    Td,
    TdContent,
    Th,
    Thead,
    Tr
} from './Workflow.Analytics.Dumb'

export const DropdownContainer = styled.div`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: 66% 34%;
    height: 80px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 5px #00000029;
    border-radius: 10px;
    padding: 16px 20px;
    margin-bottom: 20px;

    ${DropdownUlWrapper} {
        margin-bottom: 0px;
    }
    ${InputContainer} {
        margin-bottom: 0px;
        ${Input} {
            padding-right: 30px;
        }
    }

    .tab_container_left {
        display: grid;
        grid-template-columns: 180px 180px 180px;
        grid-gap: 20px;
    }
    .tab_container_right {
        display: grid;
        grid-template-columns: 180px auto;
        justify-content: flex-end;
        gap: 20px;
        .prev_icon {
            margin-left: 6px;
            width: 16px;
            height: 16px;
            position: relative;
            top: -1px;
            svg {
                width: 16px;
                height: 16px;
                cursor: pointer;
                fill: #555;
                transform: rotate(90deg);
            }
        }
        .next_icon {
            margin-left: 6px;
            width: 16px;
            height: 16px;
            position: relative;
            top: -1px;
            svg {
                width: 16px;
                height: 16px;
                fill: #555;
                cursor: pointer;
                transform: rotate(-90deg);
            }
        }
        p {
            margin-bottom: 0px;
            display: flex;
            align-items: center;
        }
    }
`
export const SerachInputWrapper = styled.div`
    width: 100%;
    float: left;
    position: relative;
    display: flex;
    align-items: center;

    span {
        width: 18px;
        height: 18px;
        position: absolute;
        top: 15px;
        left: 12px;
        svg {
            width: 18px;
            height: 18px;
            color: #999;
            fill: #999;
        }
    }
`
export const SerachInput = styled.input`
    width: 180px;
    height: 38px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #b9b9b9;
    border-radius: 6px;
    padding: 5px 12px 0px 36px;
    font-size: 12px;
    font-family: Gordita-Regular;
    color: #000;
    :placeholder {
        color: #999;
    }
    :hover {
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #eb974e;
    }
`
const ContentLeft = styled.div`
    width: auto;
    float: left;
`
const TrainButton = styled.button`
    background: #d5ffee 0% 0% no-repeat padding-box;
    border: 1px solid #cecece;
    border-radius: 12px;
    color: #000;
    width: auto;
    height: 20px;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    label {
        margin-bottom: 0px;
        position: relative;
        top: 1px;
    }
`
export const CountNumber = styled.span`
    font-family: 'Gordita-Medium';
    color: #333;
    font-size: 10px;
    margin-left: 4px;
    width: 24px;
    height: 24px;
    background: #d5ffee 0% 0% no-repeat padding-box;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ sessionBg }) =>
        sessionBg &&
        `
    background: #F4F4F4 0% 0% no-repeat padding-box;
display:flex;
`}
    ${({ successBg }) =>
        successBg &&
        `
        background: #D5FFEE 0% 0% no-repeat padding-box;
  display:flex;
`}
    ${({ failedBg }) =>
        failedBg &&
        `
    background: #FFDDDD 0% 0% no-repeat padding-box;
    display:flex;
`}

    label {
        position: relative;
        top: 1px;
        margin: 0px;
    }
`
export const ContentEllipse = styled.div`
    width: 100%;
    float: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    .icon_left{
        cursor:${({pointer})=>pointer?"pointer":"default"}
    }
    label {
        position: relative;
        margin: 0;
        margin-left: 6px;
    }
    .session_status {
        width: auto;
        float: left;
        margin-left: 10px;
        display: flex;
        align-items: center;

        ${CountNumber} {
            margin-left: 3px;
            label {
                margin: 0;
                top: 1px;
            }
        }

        :first-child {
            margin-left: 0px;
        }
    }

    ${({ contentCenter }) =>
        contentCenter &&
        `
  justify-content: center;
  display:flex;
`}

    ${ContentLeft} {
        width: auto;
        float: left;
    }
    .icon_left {
        width: 18px;
        height: 18px;
        float: left;
        position: relative;
        top: 0px;

        svg {
            width: 18px;
            height: 18px;
            fill: #355d8b;
            color: #355d8b;
        }
    }
    .icon_left_24 {
        width: 55%;
        height: 24px;
        // float: left;
        display: flex;
        justify-content: center;
        svg {
            width: 24px;
            height: 24px;
            float: left;
            fill: #355D8B;
        }
    }
        .icon_left_25{
        width: 55%;
        height: 24px;
        display: flex;
        justify-content: center;
            svg {
                width: 18px;
                height: 18px;
                float: left;
                fill: #355D8B;
            }
        }
    }
`
export const LabelImage = styled.div`
    width: 8px;
    float: left;
    height: 8px;
    margin-left: 6px;
    margin-bottom: 0px;
    position: relative;
    top: -1px;
    svg {
        width: 8px;
        height: 8px;
        float: left;
        margin-left: 0px;
    }
    cursor:${(props)=>props.pointer?"pointer":"default"}
`
const ActionSpan = styled.div`
    width: auto;
    float: left;
    svg {
        width: 12px;
        height: 12px;
        color: #666;
        fill: #666;
    }
`
const ActionWrapper = styled.div`
    width: auto;
    float: right;
    display: none;
    ${ActionSpan} {
        :first-child {
            margin-right: 6px;
            margin-left: 6px;
        }
    }
`
export const OpenSubTable = styled.div`
    background: transparent linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    box-shadow: 0px 5px 15px #00000033;
    border-radius: 10px;
    display: block;
    width: 100%;
    float: left;
    margin: -1px 0px;
    overflow-y: scroll;
    scroll-behavior: smooth;

    ${TdContent} {
        svg {
            display: block;
            transform: rotate(90deg);
        }
    }

    ${Thead} {
        ${Tr} {
            box-shadow: none !important;
            background: transparent linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px 10px 0px 0px;
            padding-top: 18px;
            height: 48px !important;
            .user_email_right {
                position: relative;
                top: -2px;
            }
            :first-child {
                ${LabelImage} {
                    width: 8px;
                    float: left;
                    height: 8px;
                    margin-left: 6px;
                    margin-bottom: 0px;
                    position: relative;
                    top: -1px;
                    display: flex;
                    svg {
                        width: 8px;
                        height: 8px;
                        float: left;
                        margin-left: 0px;
                    }
                }
            }
            :hover {
                box-shadow: none !important;
                background: transparent linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%) 0% 0% no-repeat padding-box !important;
                border-radius: 10px 10px 0px 0px;
            }
        }
        ${Th} {
            color: #333;

            :first-child {
                color: #333;
                position: relative;
                top: -3px;
                ${CountNumber} {
                    top: 2px;
                }
            }
            .user_email_right {
                ${LabelImage} {
                    float: right;
                    top: 11px;
                }
            }
            span {
                position: relative;
                top: 2px;
            }
            ${LabelImage} {
                top: 0px;
                transform: rotate(90deg);
            }
            // :last-child {
            //     justify-content: center;
            // }
        }
    }

    :hover {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 15px #00000033 !important;
        border-radius: 10px;
        ${Thead} {
            ${Tr} {
                box-shadow: none;
            }
        }
        ${Tr} {
            background: #fff 0% 0% no-repeat padding-box;
            box-shadow: 0px 2px 4px #00000029;

            ${TdContent} {
                svg {
                    display: block;
                }
            }
        }
    }

    .is_active_tr {
        background: transparent linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%) 0% 0% no-repeat padding-box !important;
        border-radius: 10px 10px 0px 0px;
        :hover {
            background: transparent linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%) 0% 0% no-repeat padding-box;
        }
    }
`
const Count = styled.span`
    font-family: 'Gordita-Regular';
    color: #fd7a00;
    font-size: 12px;
    margin-left: 3px;
`
export const Tbody = styled.tbody`
    width: 100%;
    float: left;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    overflow-y: scroll;
    border-radius: 10px;

    ${Tr} {
        background: #ffffff 0% 0% no-repeat padding-box;
        padding: 0px 20px;
        min-height: 64px;
        line-height: normal;
        display: flex;
        border-bottom: 1px solid #e0e0e0;
        ${SmallButton} {
            justify-content: center;
            :hover {
                border: 1px solid #333;
            }
        }
        ${Td} {
            padding-right: 15px;
            font-size: 12px;
            color: #000000;
            justify-content: center;
            align-items: flex-start;
            justify-content: flex-start;
            padding-top: 0px;
            position: relative;

            .diloag_td_content {
                padding-top: 6px;

                :hover {
                    color: #2468f6;
                    text-decoration: underline;
                }
            }

            .diloag_td_content.diloag_td_content_link {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 100%;
            }

            .irrelevant {
                color: #fd7a00;
            }
            :nth-child(1) {
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: flex-start;
            }
        }

        :hover {
            background: #ffefe3 0% 0% no-repeat padding-box;
            z-index: 1;
            position: relative;
            top: 0px;
            ${CountNumber} {
                background: #fff;
            }
            ${ActionWrapper} {
                display: flex;
            }
            ${TdContent} {
                svg {
                    display: block;
                }
            }
            .option-input.radio {
                border: 1px solid #2468f6 !important;
                visibility: visible !important;
            }
            .dialog_logs_session_status label{
                background: #fff;
            }
        }
        .is_active{
            backgorund: #ffefe3;
        }
    }

    .is_opacity_wrapper {
        width: 100%;
        float: left;
        transition: .3s;
    }
    .is_opacity {
        opacity: 0.2;
        box-shadow: none;
        pointer-events: none;
        width: 100%;
        float: left;
    }
    
`
export const TableWrapper = styled.div`
    width: 100%;
    float: left;
    ${Table} {
        margin-top: 0px;
    }
    ${Thead} {
        ${Tr} {
            height: 48px;
            ${Th} {
                display: flex;
                align-items: center;
                .th_label {
                    position: relative;
                    top: 0px;
                    margin: 0;
                    width: auto;
                    float: left;
                }
                :nth-child(2) {
                    span {
                        width: 14px;
                        height: 14px;
                        margin-right: 6px;
                        color: #000;
                        svg {
                            width: 14px;
                            height: 14px;
                            fill: #355d8b;
                            color: #355d8b;
                        }
                    }
                }
            }
        }
    }
    ${Tbody} {
        border-radius: 0px 0px 10px 10px;
        max-height: calc(100vh - 380px);
        @media (max-width: 1500px) {
            max-height: calc(100vh - 370px);
        }
        .no_data_available {
            display: flex;
            align-items: center;
            justify-content: center;
            max-height: calc(100vh - 253px);
            overflow: hidden;
            min-height: 100px;
            p {
                font-size: 14px;
                font-family: 'Gordita-Regular';
                color: #000;
            }
        }
        ${Tr} {
            height: 64px;
            ${Td} {
                padding: 0px;
                height: 64px;
                font-size: 12px;
                font-family: 'Gordita-Regular';
                display: flex;
                padding-top: 0px;
                justify-content: center;
                position: relative;

                :nth-child(1) {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding-top: 0;
                    justify-content: center;
                    .td_date {
                        margin-top: 5px;
                    }
                    ${SmallButton} {
                        label {
                            top: 1px;
                            margin: 0;
                        }
                    }
                    ${LabelImage} {
                        top: 0px;
                    }
                }
                :nth-child(2) {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding-right: 12px;
                    ${ContentEllipse} {
                        display: flex;
                        align-items: center;
                    }
                }
                :nth-child(3) {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding-right: 12px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    ${ContentEllipse} {
                        display: flex;
                        align-items: center;
                    }
                }

                :nth-child(4) {
                    padding-right: 0px;
                }
                .cc_td_icons {
                    width: auto;
                    float: left;
                    .icon_left_24 {
                        margin-left: 10px;
                        float: left;
                        :first-child {
                            margin: 0px;
                        }
                    }
                }
                .td_header {
                    font-size: 12px;
                    color: #000;
                    margin-bottom: 3px;
                    margin-top: 3px;
                }
                .td_date {
                    font-size: 9px;
                    color: #333;
                    line-height: 7px;
                    margin-top: 3px;
                    margin-left: 1.5px;
                }
                .td_email {
                    font-size: 12px;
                    color: #355d8b;
                    margin-bottom: 0px;
                    width: 100%;

                    .truncate {
                        display: block;
                        overflow: hidden;
                        white-space: pre;
                        text-overflow: ellipsis;
                        color: #000;
                    }
                }
                .td_id {
                    font-size: 12px;
                    color: #355d8b;
                    margin-top: 3px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    width: 95%;
                    span {
                        color: #000;
                        width: auto;
                    }
                }
                .user_email_right {
                    position: relative;
                    top: 3px;
                    ${LabelImage} {
                        float: right;
                        top: 8px;
                    }
                }
                .td_cc_img {
                    width: 24px;
                    height: 24px;
                    margin-right: 6px;

                    svg {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }
`
export const TrainHeader = styled.h4`
    width: 100%;
    float: left;
    justify-content: left;
    font-family: 'Gordita-Medium';
    color: #476b95;
    font-size: 12px;
    margin-bottom: 0px;
    background: transparent linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%) 0% 0% no-repeat padding-box;
    border-radius: 6px 6px 0px 0px;
    height: 42px;
    padding: 0px 10px;
    display: flex;
    align-items: center;

    label {
        position: relative;
        top: 2px;
        margin: 0;
    }

    span {
        width: 24px;
        height: 24px;
        background: #e0f6ff 0% 0% no-repeat padding-box;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 6px;

        svg {
            width: 14px;
            height: 14px;
            color: #526a86;
            filll: #526a86;
        }
    }
`
export const UserWrapper = styled.div`
    width: 100%;
    float: left;
    padding: 10px 10px 12px 10px;
    background: #fff;
    border-radius: 0px 0px 6px 6px;
    box-shadow: 0px 6px 5px 0px #ccc;
    height: 120px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        width: 6px;
        display: block;
    }
    ::-webkit-scrollbar-thumb {
        background: #ccc;
        height: 30px;
        border-radius: 5px;
    }
    ::-webkit-scrollbar-track {
        background: inherit;
    }

    p {
        font-family: 'Gordita-Regular';
        color: #355d8b;
        font-size: 12px;
        width: 100%;
        float: left;
        margin-bottom: 6px;

        :last-child {
            margin: 0;
        }

        span {
            color: #000;
        }
    }
`
export const EntityWrapper = styled.div`
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 15px #00000033;
    border-radius: 10px;
    width: 280px;
    position: absolute;
    top: inherit;
    z-index: 1;
    margin-left: -35px;
    margin-bottom: 40px;
`