import { ResponseOutputT } from './canvas/types'

export enum ViewTypeT {
    GridView,
    ListView
}

export type Views = GridT | ListT

export type GridT = {
    View: ViewTypeT.GridView
}

export type ListT = {
    View: ViewTypeT.ListView
}

export const GridView: GridT = { View: ViewTypeT.GridView }

export const ListView: ListT = { View: ViewTypeT.ListView }

export enum Mode {
    View = 'View',
    Add = 'Add',
    Edit = 'Edit',
    AgentEdit = 'AgentEdit',
    Delete = 'Delete',
    Search = 'Search',
    Select = 'Selection',
    SaveAs = 'SaveAs',
    GroupView = 'GroupView',
    Loading = 'Loading',
    Error = 'Error',
    MoveToGroup = 'MoveToGroup',
    Activate = 'Activate',
    DeactivateLinkedDialog = 'DeactivateLinkedDialog',
    EditAutomationMeta = 'EditAutomationMeta',
    DeleteLinkedAutomation = 'DeleteLinkedAutomation',
    NoDataFound = 'NoDataFound',
    NotPro = 'NotPro',
    UnAuthorized = 'UnAuthorized'
}

export type Modes =
    | ViewT
    | SelectT
    | AddT
    | EditT
    | DeleteSingleCardT
    | SearchT
    | LoadingT
    | ErrorT
    | NoDataFoundT
    | UnAuthorized
    | NotInProT

export type ConnectionModes =
    | ConnectionViewT
    | SelectT
    | AddT
    | EditT
    | AgentEdit
    | DeleteSingleCardT
    | SearchT
    | LoadingT
    | ErrorT
    | DeleteLinkedAutomationT
    | NoDataFoundT
    | UnAuthorized

export type LinkedWithAutomationList = {
    name: string
    id: string
}

export type onPremListResponseT = {
    id: string
    name: string
    description: string
    token: string
    status: string
    createdDate: string
    modifiedDate: string
    connections: onPremConnectionListResponseT[]
    linkedWithAutomationList: LinkedWithAutomationList[]
}

export type onPremConnectionListResponseT = {
    id: string
    name: string
    description: string
    appId: string
    createdDate: string
    modifiedDate: string
    agentId: onPremListResponseT
}

export type ViewT = {
    mode: Mode.View
    data: onPremListResponseT[]
}

export type ConnectionViewT = {
    mode: Mode.View
    data: onPremConnectionListResponseT[]
    agent: onPremListResponseT
    apps: ResponseOutputT[]
    connection?: { connection: string; connected: boolean }
}
export type SelectT = {
    mode: Mode.Select
}

export type AddT = {
    mode: Mode.Add
    name: string
    description: string
    loading: boolean
    error: {
        error: boolean
        info: string
    }
}

export type EditT = {
    mode: Mode.Edit
    name: string
    description: string
    id: string
    loading: boolean
    error: {
        error: boolean
        info: string
    }
    appId?: string
}

export type AgentEdit = {
    mode: Mode.AgentEdit
    name: string
    description: string
    id: string
    loading: boolean
    error: {
        error: boolean
        info: string
    }
}

export type DeleteLinkedAutomationT = {
    mode: Mode.DeleteLinkedAutomation
    name: string
    id: string
    loading: boolean
    error: {
        error: boolean
        info: string
    }
    automations: LinkedWithAutomationList[]
}

export type DeleteSingleCardT = {
    mode: Mode.Delete
    name: string
    id: string
    loading: boolean
    error: {
        error: boolean
        info: string
    }
}

export type ErrorT = {
    mode: Mode.Error
    error?: { error: boolean; info: string }
}

export type LoadingT = {
    mode: Mode.Loading
}

export type UnAuthorized = {
    mode: Mode.UnAuthorized
}

export type NoDataFoundT = {
    mode: Mode.NoDataFound
    agent: onPremListResponseT
    apps: ResponseOutputT[]
}

export type GroupViewT = {
    mode: Mode.GroupView
    groupId: string
}

export type SearchT = {
    mode: Mode.Search
    query: string
}

export type EditAutomationMetaT = {
    mode: Mode.EditAutomationMeta
    error: {
        error: boolean
        info: string
    }
}

export const View: ViewT = { mode: Mode.View, data: [] }

export type NotInProT = {
    mode: 'NotPro'
}

export const NotInPro: NotInProT = {
    mode: 'NotPro'
}

export const ConnectionView: ConnectionViewT = {
    mode: Mode.View,
    data: [],
    agent: {
        createdDate: '',
        description: '',
        id: '',
        modifiedDate: '',
        name: '',
        status: '',
        token: '',
        connections: [],
        linkedWithAutomationList: []
    },
    apps: []
}

export const Add: AddT = {
    mode: Mode.Add,
    name: '',
    description: '',
    error: { error: false, info: '' },
    loading: false
}

export const Edit: EditT = {
    mode: Mode.Edit,
    name: '',
    description: '',
    id: '',
    error: { error: false, info: '' },
    loading: false
}
export const AgentEdit: AgentEdit = {
    mode: Mode.AgentEdit,
    name: '',
    description: '',
    id: '',
    error: { error: false, info: '' },
    loading: false
}

export const Delete: DeleteSingleCardT = {
    mode: Mode.Delete,
    name: '',
    id: '',
    error: { error: false, info: '' },
    loading: false
}

export const DeleteLinkedAutomation: DeleteLinkedAutomationT = {
    mode: Mode.DeleteLinkedAutomation,
    name: '',
    id: '',
    error: { error: false, info: '' },
    automations: [],
    loading: false
}

export const Loading: LoadingT = { mode: Mode.Loading }

export const ErrorPage: ErrorT = { mode: Mode.Error, error: { error: false, info: '' } }

export const NoDataFound: NoDataFoundT = {
    mode: Mode.NoDataFound,
    agent: {
        createdDate: '',
        description: '',
        id: '',
        modifiedDate: '',
        name: '',
        status: '',
        token: '',
        connections: [],
        linkedWithAutomationList: []
    },
    apps: []
}

export const UnAuthorized: UnAuthorized = {
    mode: Mode.UnAuthorized
}
