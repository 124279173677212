/*eslint-disable*/
import * as React from 'react'

export enum ViewTypeT {
    GridView,
    ListView
}

export const Header: React.FunctionComponent = ({ children }) => {
    return (
        <section className="cogni_cards tour_automation_canvas">
            <div className="header_box_shadow" />
            <div className="flow_canvas1">
                <div className="flex_canvas1">{generateChildren(children)}</div>
            </div>
        </section>
    )
}

function generateChildren(children: React.ReactNode) {
    return (
        children &&
        Array.isArray(children) &&
        children.map((Child: any, i: number) => {
            if (Child.props.type === 'first') {
                return (
                    <div className="flex_menu1 flow_group_home" key={i}>
                        {Child}
                    </div>
                )
            } else if (Child.props.type === 'second') {
                return Child
            } else return Child
        })
    )
}

export const FlowCanvasHeader: React.FunctionComponent<{
    children: React.ReactNode
    class_?: string
}> = (props: { children: React.ReactNode; class_?: string }) => {
    return (
        <section className="cogni_cards">
            <div className={props.class_ ? `flow_canvas1 ${props.class_}` : 'flow_canvas1'}>
                <div className="flex_canvas1">{generateChildren(props.children)}</div>
            </div>
        </section>
    )
}

export const HeaderChild: React.FunctionComponent<{
    type: string
    children: React.ReactNode
    class?: string
}> = (props: { type: string; children: React.ReactNode; class?: string }) => {
    if (props.type === 'first') {
        return <div className="indent_dialog_left">{props.children}</div>
    } else {
        return (
            <div className="flex_side_menu1 intent_home flow_group_home">
                <div className={props.class ? `intent_dialog_right ${props.class}` : 'intent_dialog_right'}>
                    {props.children}
                </div>
            </div>
        )
    }
}
export const WorkflowHeaderChild: React.FunctionComponent<{
    type: string
    children: React.ReactNode
    class?: string
}> = (props: { type: string; children: React.ReactNode; class?: string }) => {
    if (props.type === 'first') {
        return (
            <div className="indent_dialog_left" style={{ width: '70%' }}>
                {props.children}
            </div>
        )
    } else {
        return (
            <div className="flex_side_menu1 intent_home flow_group_home">
                <div className={props.class ? `intent_dialog_right ${props.class}` : 'intent_dialog_right'}>
                    {props.children}
                </div>
            </div>
        )
    }
}
export const HeaderLeft: React.FunctionComponent = ({ children }) => {
    return <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 indent_dialog_left">{children}</div>
}

export const PageWrapper: React.FunctionComponent = (props: {
    children?: React.ReactNode
    extraClass?: string
    gridViewCard?: ViewTypeT
}) => {
    return (
        <div
            className={
                props.extraClass
                    ? `entity_cards_pagewrapper ${props.extraClass}`
                    : props.gridViewCard
                    ? 'entity_cards'
                    : 'entity_cards _grid_view'
            }
            style={{height: 'calc(100vh - 80px)'}}
        >
            <div className="col-md-12">
                <div className={props.gridViewCard == ViewTypeT.GridView ? 'row card_grid_view' : 'row'}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}
