import React from 'react'
import Tooltip from 'react-tooltip-lite'

import { FlowCanvasContextIcons } from '@/common/Icons/Icons'

import { ContextVariableT, VariableContext, VariableContextT, VariableT } from '../../Context'

const SelectContextVariable = ({ rawMeta, setRawMeta }: { rawMeta: VariableT; setRawMeta: Function }) => {
    const [editorTime, setEditorTime] = React.useState<null | number>(null)

    const context: VariableContextT = React.useContext(VariableContext)

    React.useEffect(() => {
        if (context.variable !== null && context.selectedEditor === editorTime && editorTime != null) {
            if (context.variable.type === 'context') {
                const { variable, path, dummypath, icon, dataType } = context.variable
                setRawMeta({
                    variable,
                    dataType,
                    path,
                    dummypath,
                    icon,
                    type: 'context'
                })
            }

            context.assignVariable(null)
        }
    }, [context])

    const onFocus = () => {
        const datetime = Date.now()
        setEditorTime(datetime)
        context.setSelectedEditor(datetime)
        context.assignPopupExposed(true)
        context.setDisplayStatusOfEntityListInVariable('HIDE')
    }

    return (
        <div className="onfocus_recognise_input" onClick={onFocus}>
            {rawMeta.type == 'context' && (rawMeta as ContextVariableT).variable ? (
                <span className="buton_dialog_auto_hvr">
                    <Tooltip
                        className="target customTip _hover_tooltip"
                        zIndex={10000}
                        arrowSize={0}
                        tagName="span"
                        content={rawMeta.dummypath.map((path: any, index: number, arr: any[]) => (
                            <React.Fragment key={`${index}`}>
                                {console.log('Path:::', path)}
                                <div>{path}</div>
                                {index + 1 != arr.length ? (
                                    <div>
                                        <FlowCanvasContextIcons.NextIcon />
                                    </div>
                                ) : null}
                            </React.Fragment>
                        ))}
                        distance={5}
                    >
                        <button className="button_dialog">
                            <img src={rawMeta.icon} style={{ width: 12, height: 12, marginRight: 5 }} />
                            <span>{rawMeta.dummypath[rawMeta.dummypath.length - 1]}</span>
                        </button>
                    </Tooltip>
                </span>
            ) : null}
            <div className={rawMeta.type == 'context' ? 'button_auto_input_click' : 'button_auto_input'}>
                Select variable
            </div>
        </div>
    )
}

export default SelectContextVariable
