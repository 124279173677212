import { ActionT } from '../kind'
import { EntityValueT, EntityValueRequestT } from '../Entity/kind'

export interface VIEW_T {
    action: ActionT.VIEW
}

export const VIEW: VIEW_T = {
    action: ActionT.VIEW
}

export interface LOADING_T {
    action: ActionT.LOADING
}

export const LOADING: LOADING_T = {
    action: ActionT.LOADING
}

export interface UN_AUTHORIZED_T {
    action: ActionT.UN_AUTHORIZED
}

export interface RESOURCE_NOT_FOUND_T {
    action: ActionT.RESOURCE_NOT_FOUND
    resource: 'WORKSPACE' | 'ASSISTANT' | 'ENTITY'
}

export interface ERROR_T {
    action: ActionT.ERROR
    reason: string
}

export interface ENTITY_EDIT {
    action: ActionT.ENTITY_EDIT
    id: string
    entity: string
    description: string
}

export interface ADD_T {
    action: ActionT.ADD
    value: EntityValueRequestT
}

export interface EDIT_T {
    action: ActionT.EDIT
    value: EntityValueT
}

export interface DELETE_T {
    action: ActionT.DELETE
    value: EntityValueT
}

export interface MULTIPLE_DELETE_T {
    action: ActionT.MULTIPLE_DELETE
    items: string[]
}

export interface SELECT_T {
    action: ActionT.SELECT
    items: string[]
}

export type PageStateT =
    | VIEW_T
    | LOADING_T
    | UN_AUTHORIZED_T
    | RESOURCE_NOT_FOUND_T
    | ERROR_T
    | ENTITY_EDIT
    | ADD_T
    | EDIT_T
    | DELETE_T
    | MULTIPLE_DELETE_T
    | SELECT_T
