import { IntentT } from '../Intent/kind'
import { ActionT } from '../kind'

export interface ExampleT {
    id: string
    text: string
    created: string
    updated: string
}

export interface ExampleRequestT {
    text: string
}

export type PageStateT =
    | ViewT
    | LoadingT
    | UnauthorizedT
    | ResourceNotFoundT
    | IntentEditT
    | AddT
    | SelectT
    | DeleteT
    | MultipleDeleteT
    | EditT
    | MoveT
    | PageErrorT

export type ViewT = {
    action: ActionT.VIEW
}
export type LoadingT = {
    action: ActionT.LOADING
}
export type UnauthorizedT = {
    action: ActionT.UN_AUTHORIZED
}

export type ResourceNotFoundT = {
    action: ActionT.RESOURCE_NOT_FOUND
}
export interface IntentEditT extends IntentT {
    action: ActionT.INTENT_EDIT
}

export type AddT = {
    action: ActionT.ADD
    examples: string[]
}

export type SelectT = {
    action: ActionT.SELECT
    selectedItems: string[]
}

export type DeleteT = {
    action: ActionT.DELETE
    example: ExampleT
}
export type MultipleDeleteT = {
    action: ActionT.MULTIPLE_DELETE
    selectedItems: string[]
}
export type EditT = {
    action: ActionT.EDIT
    exampleid: string
    intentId: string
    example: string
}

export type MoveT = {
    action: ActionT.MOVE
    selectedItems: string[]
}
export type PageErrorT = {
    action: ActionT.ERROR
    error: any
    info: any
}

export const View: ViewT = { action: ActionT.VIEW }

export const Loading: LoadingT = {
    action: ActionT.LOADING
}

export const Unauthorized: UnauthorizedT = {
    action: ActionT.UN_AUTHORIZED
}
