import React from 'react'
import styled, { StyledComponent } from 'styled-components/macro'

import {
    BotsIcon,
    ConversationMessageIcon,
    DownloadFromMarketplace,
    InfoIcon,
    InformationIcon,
    StarIcon,
    UserIconAnalytics,
    WorkflowExecutionIcon,
    WorkflowIcon
} from '@/common/Icons/Icons'
import { BodyWrapper, Button, Container, MessageHeader, QuestionPlan, Wrapper } from '@/common/styled/Subscription.Dumb'

import visa_img from '@/common/images/visa.png'
import { CurrentPlanCards, PlansHeader } from './PlansWrapper'

const PlanWithMoney = styled.div`
    font-size: 16px;
    color: #000;
    font-family: ${props => (props.fontFamily ? 'Gordita-Medium' : 'Gordita-Regular')};
    display: flex;
    justify-content: space-between;
    span{
        display:flex;
    }
    .info_icon {
        position: relative;
        width: auto;
        display: flex;
        align-items: center;
    }
    .info_icon {
        svg {
            width: 18px;
            height: 18px;
            color: #FF9922;
            cursor: pointer;
            margin-left: 7px;
            transition: all 0.8s ease 0s;
        }
        .side_menu_hvr {
            position: absolute;
            width: max-content;
            background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
            box-shadow: rgb(0 0 0 / 16%) 0px 10px 20px;
            padding: 6px 12px;
            font-size: 16px;
            border-radius: 4px;
            color: rgb(49, 81, 118);
            max-width: 315px;
            bottom: 32px;
            left: -15px;
            font-family: Gordita-Regular;
            display: none;
            :before {
                position: absolute;
                content: ' ';
                background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
                bottom: -11px;
                border-width: 6px;
                border-style: solid;
                border-color: transparent transparent white;
                top: inherit;
                left: 25px;
                transform: rotate(180deg);
            }
        }
    }
    .info_icon:hover .side_menu_hvr {
        display: flex;
    }
    @media (max-width:1600px){
        font-size: 14px;
        .info_icon {
            svg {
                width: 15px;
                height: 15px;
            }
            .side_menu_hvr {
                bottom: 27px;
                left: -17px;
                font-size: 14px;
                max-width: 290px;
            }
    }
`
const PlanWithMoneyWrapper = styled.div`
    width: 85%;
    float: left;
    margin-right: 20px; 
}
`
const CurrentPlanRightWrapper = styled.div`
    width: 480px;
    float: left;
    background: #f4f4f4;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: center;

    @media (max-width: 1600px) {
        width: 420px;
    }
`

const CurrentPlanWrapper = styled.section`
    width: 100%;
    float: left;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #c6c6c6;
    ${QuestionPlan} {
        width: 22%;
    }
    ${Button} {
        margin-right: 0px;
    }
`
const CurrentPlan = () => {
    return (
        <div className="current_plan_wrapper">
            <BodyWrapper>
                <Wrapper>
                    <Container>
                        <BillingWrapper>
                            <Plan />
                            <DisplayCards />
                            <Link link={'View Previous Consumptions'} />
                        </BillingWrapper>
                        <BillingAndAccountWrapper />
                    </Container>
                </Wrapper>
            </BodyWrapper>
        </div>
    )
}

export const Plan = () => {
    return (
        <CurrentPlanWrapper>
            <CurrentPlanCards
                upgradeButton
                plan={'Current Plan '}
                CurrentPlan={'Standard'}
                CurrentPlanCounts={''}
                btnName={'Upgrade'}
            />
            <CurrentPlanRightWrapper>
                <PlanWithMoneyWrapper>
                    <PlanWithMoney>
                        <span>Yearly Plan</span> <span>$6589.00</span>
                    </PlanWithMoney>
                    <PlanWithMoney>
                        <span>
                            {' '}
                            Add-On Consumptions{' '}
                            <span className="info_icon">
                                <InfoIcon />
                                <span className="side_menu_hvr">
                                    Current month add-on consumption charges are included in next billing cycle.
                                </span>
                            </span>
                        </span>
                        <span>$0.36</span>
                    </PlanWithMoney>
                    <PlanWithMoney fontFamily>
                        <span>Next Billing 14 Mar 2021</span> <span> $0.36</span>
                    </PlanWithMoney>
                </PlanWithMoneyWrapper>
                <Button bgColor>Pay Now</Button>
            </CurrentPlanRightWrapper>
        </CurrentPlanWrapper>
    )
}

export const CardsWrapper = styled.div`
    width: 100%;
    float: left;
    background: #fff;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(22%, 1fr));
    grid-gap: 30px;
    @media (max-width: 1700px) {
        grid-gap: 20px;
    }
`

const ListContent = styled.h6`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    font-size: 18px;
    color: #000;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    ${MessageHeader} {
        font-size: 18px;
        color: #000;

        @media (max-width: 1700px) {
            font-size: 16px;
        }
        @media (max-width: 1500px) {
            font-size: 14px;
        }
        @media (max-width: 1400px) {
            font-size: 13px;
        }
    }
    span {
        font-size: 14px;
        width: auto;
        float: right;
        display: flex;
        align-items: center;
        margin-left: 5px;
    }

    @media (max-width: 1700px) {
        font-size: 16px;
    }
    @media (max-width: 1500px) {
        font-size: 14px;

        span {
            font-size: 12px;
        }
    }
    @media (max-width: 1400px) {
        font-size: 13px;
        span {
            font-size: 11px;
        }
    }
`
const ListCount = styled.div`
    width: auto;
    float: right;
    font-family: 'Gordita-Regular';
    color: #000;
    font-size: 18px;
    @media (max-width: 1700px) {
        font-size: 16px;
    }
    @media (max-width: 1500px) {
        font-size: 14px;
    }
    @media (max-width: 1400px) {
        font-size: 13px;
    }
`
const ListPlan = styled.div`
    width: 100%;
    float: left;
    display: flex;

    ${ListContent} {
        justify-content: flex-start;
    }
`
export const Listwrapper = styled.div`
    width: 100%;
    float: left;
    padding: 20px;
    @media (max-width: 1700px) {
        padding: 15px;
    }
    @media (max-width: 1500px) {
        padding: 12px;
    }
`
export const CurrentPlanHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    padding: 20px;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
    float: left;
    font-family: 'Gordita-Medium';
    font-weight: normal;
    font-size: 18px;
    color: #000;
    span {
        width: 32px;
        height: 32px;
        float: left;
        margin-right: 7px;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    @media (max-width: 1700px) {
        font-size: 16px;
        padding: 15px;
        span {
            width: 28px;
            height: 28px;
            float: left;
            margin-right: 7px;
        }
    }
    @media (max-width: 1500px) {
        font-size: 15px;
        padding: 12px;
        span {
            width: 26px;
            height: 26px;
            float: left;
            margin-right: 7px;
        }
    }
    @media (max-width: 1400px) {
        font-size: 14px;
    }
`
export const Card = styled.div`
    width: 100%;
    float: left;
    border-radius: 10px;

    :nth-child(1) {
        background: #fff0f0;
        svg {
            fill: #d93d3d;
        }
    }
    :nth-child(2) {
        background: #e8f6ff;
        svg {
            fill: #2a78aa;
        }
    }
    :nth-child(3) {
        background: #fff4e8;
        svg {
            fill: #c97518;
        }
    }
    :nth-child(4) {
        background: #edfff1;
        svg {
            fill: #2a9542;
        }
    }
`

const PlanDetails = [
    {
        header: 'Workflows',
        image: <WorkflowIcon />,
        contentMessage: [
            {
                header: 'Active Workflows Quota',
                count: '5'
            },
            {
                header: 'Used Workflows ',
                count: '4'
            },
            {
                header: ' Remaining Workflows ',
                count: '1'
            }
        ]
    },
    {
        header: 'Workflow Executions',
        image: <WorkflowExecutionIcon />,
        contentMessage: [
            {
                header: 'Montly Quota ',
                count: '1500'
            },
            {
                header: 'Used Executions ',
                count: '250'
            },
            {
                header: 'Remaining Executions ',
                count: '1250'
            },
            {
                header: 'Add-On Executions',
                count: '0',
                image: <InfoIcon />,
                hoverContent: 'Exceeded Executions'
            }
        ]
    },
    {
        header: 'FAQ Answers',
        image: <BotsIcon />,
        contentMessage: [
            {
                header: 'Montly Quota ',
                count: '2000'
            },
            {
                header: 'Used FAQ Executions ',
                count: '200'
            },
            {
                header: 'Remaining FAQ Executions ',
                count: '1800'
            },
            {
                header: 'Add-On FAQ Answers',
                count: '0',
                image: <InfoIcon />,
                hoverContent: 'Exceeded FAQ Executions'
            }
        ]
    },
    {
        header: 'Messages',
        image: <ConversationMessageIcon />,
        contentMessage: [
            {
                header: 'Montly Quota ',
                count: '7000'
            },
            {
                header: 'Used Executions ',
                count: '1200'
            },
            {
                header: 'Remaining Executions ',
                count: '5800'
            },
            {
                header: 'Add-On Messages',
                count: '0',
                image: <InfoIcon />,
                hoverContent: 'Exceeded Messages'
            }
        ]
    }
]

const CalculationWrapper = styled.div`
    width: 100%;
    float: left;
    padding: 20px;
    border-top: 1px solid #e0e0e0;
    ${ListContent} {
        font-family: 'Gordita-Medium';
        font-weight: normal;
        margin-bottom: 0px;
    }
    ${ListCount} {
        font-family: 'Gordita-Medium';
        font-weight: normal;
    }

    @media (max-width: 1700px) {
        padding: 15px;
    }

    @media (max-width: 1500px) {
        padding: 12px;
    }
`
export const DisplayCards = (props: any) => {
    return (
        <>
            <CardHeader>Current Consumptions</CardHeader>
            <CardsWrapper>
                {PlanDetails.map(data => (
                    <Card>
                        <CurrentPlanHeader>
                            <span>{data.image}</span>
                            {data.header}
                        </CurrentPlanHeader>
                        <Listwrapper>
                            {data.contentMessage.map(data => (
                                <>
                                    {data.hoverContent && data.image ? (
                                        <ListContent>
                                            <MessageHeader>
                                                <label>
                                                    {data.header}
                                                    <span data-tooltip={data.hoverContent}>
                                                        <InfoIcon />
                                                    </span>
                                                </label>
                                            </MessageHeader>
                                            <ListCount>{data.count}</ListCount>
                                        </ListContent>
                                    ) : (
                                        <ListContent>
                                            {data.header} <ListCount>{data.count}</ListCount>
                                        </ListContent>
                                    )}
                                </>
                            ))}
                        </Listwrapper>
                    </Card>
                ))}
            </CardsWrapper>
        </>
    )
}

const LinkWrapper = styled.div`
    width: 100%;
    float: left;
    padding: 0px 20px 20px 20px;

    @media (max-width: 1700px) {
        padding: 0px 15px 15px 20px;
    }
    @media (max-width: 1500px) {
        padding: 0px 12px 15px 20px;
    }
`
const LinkContent = styled.div`
    width: auto;
    float: left;
    font-family: 'Gordita-Medium';
    font-weight: normal;
    font-size: 18px;
    color: #466af3;
    cursor: pointer;
    @media (max-width: 1700px) {
        font-size: 16px;
    }
    @media (max-width: 1500px) {
        font-size: 14px;
    }
    @media (max-width: 1400px) {
        font-size: 13px;
    }
`
export const BillingWrapper = styled.section`
    width: 100%;
    float: left;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 5px #16161629;
`
const Link = (props: any) => {
    return (
        <LinkWrapper>
            <LinkContent>{props.link}</LinkContent>
        </LinkWrapper>
    )
}

const AccountWrapper = styled.div`
    width: 100%;
    float: left;
    background: #fff;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 5px #00000029;
    border-radius: 10px;

    ${CurrentPlanHeader} {
        padding: 20px 20px 10px 20px;

        // @media (max-width: 1700px) {
        //     padding: 15px 15px 10px 15px;
        // }
        // @media (max-width: 1500px) {
        //     padding: 12px 15px;
        // }
    }
`
const AdminWrappers = styled.section`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: 24% 24% auto;
    margin-top: 30px;
    grid-gap: 30px;

    @media (max-width: 1700px) {
        grid-gap: 20px;
    }
`
// Main Page
const BillingAndAccountWrapper = () => {
    return (
        <AdminWrappers>
            <CardUserDetials />
            <AdminDetails />
            <AccountWrapper>
                <CurrentPlanHeader>Billing History</CurrentPlanHeader>
                <BillingHistory />
            </AccountWrapper>
        </AdminWrappers>
    )
}

const ImgContainer = styled.div`
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    padding: 12px 20px;
    border-bottom: 1px solid #e0e0e0;
`
const AdminIcon = styled.div`
    position: absolute;
    top: -3px;
    right: 0;
    width: 15px;
    height: 15px;
    @media (max-width: 1700px) {
        top: -6px;
        right: -3px;
    }
`
const ProfileImg = styled.span`
    width: 60px;
    height: 60px;
    float: left;
    border-radius: 50%;
    border: 1px solid #c6c6c6;
    padding: 15px;
    position: relative;
    img {
        width: 100%;
        height: 100%;
    }
    svg {
        width: 100%;
        height: 100%;
        fill: #859ebc;
    }
    ${AdminIcon} {
        svg {
            width: 15px;
            height: 15px;
            fill: #eb974e;
        }
    }

    @media (max-width: 1700px) {
        width: 42px;
        height: 42px;
        padding: 10px;
    }
`
const UserID = styled.div`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    color: #466af3;
    font-size: 18px;

    @media (max-width: 1700px) {
        font-size: 16px;
    }
    @media (max-width: 1500px) {
        font-size: 14px;
    }
    @media (max-width: 1400px) {
        font-size: 13px;
    }
`
const UserName = styled.div`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    color: #333;
    font-size: 18px;

    @media (max-width: 1700px) {
        font-size: 16px;
    }
    @media (max-width: 1500px) {
        font-size: 14px;
    }
    @media (max-width: 1400px) {
        font-size: 13px;
    }
`
const EllipsisContent = styled.span`
    width: 100%;
    float: left;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
`
const EmailWrapper = styled.div`
    width: 85%;
    float: left;
    padding-left: 20px;
    @media (max-width: 1700px) {
        padding-left: 15px;
    }
    @media (max-width: 1500px) {
        padding-left: 12px;
    }
`

export const UserDetails = (props: any) => {
    return (
        <ImgContainer>
            <ProfileImg>
                <UserIconAnalytics /> {/* svg Image*/}
                {props.adminIcon ? (
                    <>
                        <AdminIcon className="">
                            <span>
                                <StarIcon />
                            </span>
                        </AdminIcon>{' '}
                    </>
                ) : null}
            </ProfileImg>
            <EmailWrapper>
                <UserName>
                    <EllipsisContent>Arunachalamoorthy.s</EllipsisContent>
                </UserName>
                <UserID>
                    {' '}
                    <EllipsisContent>Arunachalamoorthy.s@skitter.in</EllipsisContent>
                </UserID>
            </EmailWrapper>
        </ImgContainer>
    )
}

const CardDetails = styled.div`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;
    @media (max-width: 1700px) {
        margin-bottom: 10px;
    }
`
const CardNumber = styled.div`
    width: 80%;
    float: left;
    font-family: 'Gordita-Regular';
    color: #000;
    font-size: 18px;
    @media (max-width: 1700px) {
        font-size: 16px;
    }
    @media (max-width: 1500px) {
        font-size: 14px;
    }
    @media (max-width: 1400px) {
        font-size: 13px;
    }
`
const VisaImg = styled.img`
    width: 48px;
    height: auto;
    float: left;

    @media (max-width: 1700px) {
        width: 42px;
    }
    @media (max-width: 1500px) {
        width: 36px;
    }
`
const Billing = styled.p`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    font-size: 18px;
    color: #000;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: left;

    @media (max-width: 1700px) {
        font-size: 16px;
    }
    @media (max-width: 1500px) {
        font-size: 14px;
    }
    @media (max-width: 1400px) {
        font-size: 13px;
    }
`
const CardExpiryWrapper = styled.div`
    width: 100%;
    float: left;
    padding: 15px 15px;

    @media (max-width: 1500px) {
        padding: 12px 15px;
    }

    ${CurrentPlanHeader} {
        padding: 0px;
        border: none;
        margin-bottom: 10px;
    }
`
const CardDetailsWrapper = () => {
    return (
        <>
            <CardExpiryWrapper>
                <CurrentPlanHeader>Credit Card</CurrentPlanHeader>
                <CardDetails>
                    <CardNumber>**** **** **** 4444</CardNumber>
                    <VisaImg src={visa_img}></VisaImg>
                </CardDetails>
                <UserName>
                    <EllipsisContent>Arunachalamoorthy.s</EllipsisContent>
                </UserName>
                <Billing>Expires: 12/2024</Billing>
            </CardExpiryWrapper>
        </>
    )
}

const CardMainWrapper = styled.div`
    width: 100%;
    float: left;

    ${Billing} {
        padding-top: 12px;
        @media (max-width: 1700px) {
            padding-top: 10px;
        }
    }

    ${LinkWrapper} {
        padding: 10px 15px 20px 15px;

        @media (max-width: 1700px) {
            padding: 0px 15px 15px 15px;
        }
    }
`

const CardUserDetials = () => {
    return (
        <AccountWrapper>
            <CurrentPlanHeader>Account & Billing</CurrentPlanHeader>
            <CardMainWrapper>
                <UserDetails />
                <CardDetailsWrapper />
                <Link link={'Manage Details'} />
            </CardMainWrapper>
        </AccountWrapper>
    )
}

const Paragraph = styled.p`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    color: #333;
    font-size: 18px;
    margin-bottom: 0px;
    @media (max-width: 1700px) {
        font-size: 16px;
    }
    @media (max-width: 1500px) {
        font-size: 14px;
    }
    @media (max-width: 1400px) {
        font-size: 13px;
    }
`
const AdminDetailWrapper = styled.div`
    width: 100%;
    float: left;
    ${Paragraph} {
        padding: 30px 17%;
        text-align: center;
        font-size: 16px;

        @media (max-width: 1700px) {
            padding: 30px 17%;
        }
        @media (max-width: 1500px) {
            padding: 30px 17%;
        }
        @media (max-width: 1400px) {
            padding: 30px 17%;
        }
    }
`
const AdminDetails = () => {
    return (
        <AccountWrapper>
            <AdminDetailWrapper>
                <CurrentPlanHeader>Admin Details</CurrentPlanHeader>
                <UserDetails adminIcon />
                <UserDetails />
                <Paragraph>
                    Current plan includes workspace access for two admins. Upgrade your plan to include more number of
                    admins.
                </Paragraph>
            </AdminDetailWrapper>
        </AccountWrapper>
    )
}

export const Table = styled.table`
    width: 100%;
    float: left;
    position: relative;
`
export const Thead: StyledComponent<'thead', any, any, never> = styled.thead`
    border-bottom: ${(props: any) => (props.primary ? '1px solid #ccc' : 'none')};
    width: 100%;
    float: left;
    display: flex;
    margin: ${(props: any) => (props.primary ? '0px 0px' : '0px')};
    padding: ${(props: any) => (props.primary ? '40px 15px 10px' : '0px')};
    @media (max-width: 1700px) {
        padding: ${(props: any) => (props.primary ? '30px 15px 0px' : '0px')};
    }
    @media (max-width: 1500px) {
        padding: ${(props: any) => (props.primary ? '15px 15px 0px' : '0px')};
    }
`

export const Tr = styled.tr`
    width: 100%;
    float: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    color: #000;
    span {
        width: 22px;
        height: 22px;
        float: left;
        cursor: pointer;
        svg {
            width: 100%;
            height: 100%;
            fill: #466af3;
        }
    }
`

export const Tbody: StyledComponent<'tbody', any, any, never> = styled.tbody`
    width: 100%;
    float: left;
    display: ${(props: any) => (props.primary ? 'block' : 'flex')};
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    position: relative;
`

const BillingHistoryWrapper = styled.div`
    width: 100%;
    float: left;
`
export const Th: StyledComponent<'th', any, any, never> = styled.th`
    width: ${(props: any) => `${props.size * 8.33}%` || 'auto'};
    float: left;
    font-size: 18px;
    display: flex;
    justify-content: ${(props: any) => (props.primary ? 'flex-start' : 'center')};
    font-family: 'Gordita-Medium';
    font-weight: normal;
    color: #000;

    @media (max-width: 1700px) {
        font-size: 16px;
    }
    @media (max-width: 1500px) {
        font-size: 14px;
    }
    @media (max-width: 1400px) {
        font-size: 13px;
    }
`
export const TdSpan = styled.label`
    width: max-content;
    padding: 0px 20px;
    height: 30px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    background: ${(props: any) => (props.bgPending ? '#FFF4E8' : '#E0FFF9')};
    border-radius: 16px;
    color: ${(props: any) => (props.bgPending ? '#DB6E01' : '#27B99D')};
    font-size: 16px;
    font-family: 'Gordita-Medium';
    font-weight: normal;
    @media (max-width: 1500px) {
        font-size: 14px;
    }
`
export const Td: StyledComponent<'td', any, any, never> = styled.td`
    width: auto;
    float: left;
    font-size: 18px;
    font-family: 'Gordita-Regular';
    width: ${(props: any) => `${props.size * 8.33}%` || 'auto'};
    display: flex;
    justify-content: ${(props: any) => (props.ContentRight ? 'flex-start' : 'center')};

    align-items: center;
    color: ${(props: any) => (props.primary ? '#ff7900' : '#000')};

    @media (max-width: 1700px) {
        font-size: 16px;
    }
    @media (max-width: 1500px) {
        font-size: 14px;
    }
    @media (max-width: 1400px) {
        font-size: 13px;
    }
`

export const TbodyWrapper = styled.div`
    width: 100%;
    float: left;
    dispaly: flex;
`
export const NoDataWrapper = styled.div`
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(50vh);
    overflow-y: hidden;
    position: relative;
`
export const NoDataHeader = styled.h3`
    width: 100%;
    float: left;
    dispaly: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 18px;
    font-family: 'Gordita-Medium';
    font-weight: normal;
    text-align: center;

    @media (max-width: 1700px) {
        font-size: 16px;
    }
    @media (max-width: 1500px) {
        font-size: 14px;
    }
    @media (max-width: 1400px) {
        font-size: 13px;
    }
`
const CardHeader = styled.h3`
    width: 100%;
    float: left;
    dispaly: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 18px;
    font-family: 'Gordita-Medium';
    font-weight: normal;
    margin-bottom: 0px;
    padding: 20px 20px 0px 22px;

    @media (max-width: 1700px) {
        font-size: 16px;
    }
    @media (max-width: 1500px) {
        font-size: 14px;
    }
    @media (max-width: 1400px) {
        font-size: 13px;
    }
`

const BillingHistory = () => {
    return (
        <Container>
            <BillingHistoryWrapper>
                <Table>
                    <Thead primary>
                        <Tr>
                            <Th primary size={2}>
                                Date
                            </Th>
                            <Th primary size={3}>
                                Invoice Number
                            </Th>
                            <Th primary size={1.5}>
                                Type
                            </Th>

                            <Th primary size={1.5}>
                                Amount
                            </Th>
                            <Th size={2}>Download</Th>
                            <Th primary size={2}>
                                Payment Status
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td ContentRight size={2}>
                                14 Feb 2021
                            </Td>
                            <Td ContentRight size={3}>
                                F6420A06-0010
                            </Td>
                            <Td ContentRight size={1.5}>
                                Monthly
                            </Td>
                            <Td ContentRight size={1.5}>
                                $606.20
                            </Td>
                            <Td size={2}>
                                <span>
                                    <DownloadFromMarketplace />
                                </span>
                            </Td>
                            <Td size={2}>
                                <TdSpan bgPending>Pending</TdSpan>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td ContentRight size={2}>
                                14 Feb 2021
                            </Td>
                            <Td ContentRight size={3}>
                                F6420A06-0010
                            </Td>
                            <Td ContentRight size={1.5}>
                                Monthly
                            </Td>
                            <Td ContentRight size={1.5}>
                                $606.20
                            </Td>
                            <Td size={2}>
                                <span>
                                    <DownloadFromMarketplace />
                                </span>
                            </Td>
                            <Td size={2}>
                                <TdSpan>Paid</TdSpan>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td ContentRight size={2}>
                                14 Feb 2021
                            </Td>
                            <Td ContentRight size={3}>
                                F6420A06-0010
                            </Td>
                            <Td ContentRight size={1.5}>
                                Monthly
                            </Td>
                            <Td ContentRight size={1.5}>
                                $606.20
                            </Td>
                            <Td size={2}>
                                <span>
                                    <DownloadFromMarketplace />
                                </span>
                            </Td>
                            <Td size={2}>
                                <TdSpan>Paid</TdSpan>
                            </Td>
                        </Tr>
                    </Tbody>
                    <Link link={'View All Invoices'} />
                </Table>
            </BillingHistoryWrapper>
        </Container>
    )
}
const InformationContent = styled.p`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    font-size: 14px;
    margin-bottom: 0px;
    display:flex;
    align-items:center;

    span {
        width: 18px;
        height: 18px;
        float:left;
        margin-right:5px;
        position:relative;
        top:-1px;
       
        svg{
            width:18px:
            height:18px;
            fill:#E88600;
        }
    }
    @media (max-width: 1500px) {
        font-size: 13px;

        span {
            width: 15px;
            height: 15px;
            float:left;
           
            svg{
                width:15px:
                height:15px;
                fill:#E88600;
            }
        }
    }
`
const Information = styled.div`
    background: #fff5ef 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 5px #16161629;
    border-radius: 10px;
    width: 100%;
    float: left;
    margin-top: 20px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    @media (max-width: 1500px) {
        padding: 0px 15px;
    }
`

const AlertWrapper = () => {
    return (
        <Information>
            <InformationContent>
                <span>
                    <InformationIcon />
                </span>
                “Your payment is past due date. Please pay before 28 March 2021 to avoid account suspension”
            </InformationContent>
        </Information>
    )
}
const PaidUserWrapper = styled.div`
width:100%:
float:left;
`
export const PaidUserPlans = () => {
    return (
        <>
            <PaidUserWrapper>
                <PlansHeader name={'Billing Overview'} />
                <AlertWrapper />
                <CurrentPlan />
            </PaidUserWrapper>
        </>
    )
}
