import React from 'react'
import styled, { StyledComponent } from 'styled-components'

import { DownArrowCircle } from '@/common/Icons/Icons'

export const Wrapper = styled.section`
    border-radius: 6px;
    width: 100%;
    display: flex;
`
export const Container: StyledComponent<'div', any, any, never> = styled.div`
    border-radius: 6px;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: ${(props: any) => (props.Border ? '1px solid #ccc' : 'none')};
`

export const Header = styled.div`
width:100%
float:left;
height:81px;
border-bottom: 0.5px solid #e2e2e2;
display: flex;
align-items: center;
justify-content: center;
z-index:1;
background:#f4f4f4;

`
export const HeaderName = styled.h4`
width:100%
float:left;
font-family: 'Gordita-Medium';
    font-size: 18px;
    color: #000;
    margin-bottom:0px;

`
const StatusSection = styled.div`
    width: 80%;
    float: left;
    ${Container} {
        border-radius: 6px;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    ${HeaderName} {
        width: auto;
        float: left;
    }
`

const StatusWrapper = styled.div`
    width: auto;
    float: left;
    background: #d2e5fb;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
`
const StatusContent = styled.span`
    width: auto;
    float: ${(props: any) => (props.primary ? 'right' : 'left')};
    font-family: 'Gordita-Medium';
    font-size: 14px;
    color: #494949;
    color: ${(props: any) => (props.primary ? '#2e5ce6' : '#494949')};
    padding: ${(props: any) => (props.primary ? '0px 0px 0px 5px' : '0px 30px')};
`

export const SubscriptionHeader = ({ subscriptionStatus }: { subscriptionStatus: string }) => {
    return (
        <Wrapper>
            <Header>
                <StatusSection>
                    <Container>
                        <HeaderName>
                            {' '}
                            Billing Overview <br></br>{' '}
                            <p className="content_header">Current plan and billing information.</p>
                        </HeaderName>
                        <StatusWrapper>
                            <StatusContent>
                                Account status : <StatusContent primary>{subscriptionStatus}</StatusContent>
                            </StatusContent>
                        </StatusWrapper>
                    </Container>
                </StatusSection>
            </Header>
        </Wrapper>
    )
}
export const PlansHeader = () => {
    return (
        <Wrapper>
            <Header>
                <Container>
                    <HeaderName>Plans</HeaderName>
                    <SubscriptionPlanChange></SubscriptionPlanChange>
                </Container>
            </Header>
        </Wrapper>
    )
}

export const EstimateHeader = ({}: {}) => {
    return (
        <Wrapper>
            <Header>
                <Container>
                    <span className="flow_canvas_header_back_button">
                        <span className="buton_back" onClick={() => window.history.back()}>
                            <DownArrowCircle />
                        </span>
                        <HeaderName>Billing Estimate</HeaderName>
                    </span>
                </Container>
            </Header>
        </Wrapper>
    )
}

const Toggler = styled.div`
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`
const Toggle = styled.span`
    color: #494949;
    transition: 0.2s;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
    margin: 10px;
    width: auto;
    float: left;
    font-family: 'Gordita-Medium';
    font-size: 16px;
`
const ToggleSwitch = styled.span`
    position: relative;
    width: 80px;
    float: left;
    height: 35px;
    border-radius: 100px;
    background-color: #fff;
    overflow: hidden;
    border: 1px solid #2468f6;
`

const Switch = styled.span`
    position: absolute;
    left: 2px;
    top: 2px;
    bottom: 2px;
    right: 57.5%;
    background-color: #2468f6;
    border-radius: 36px;
    z-index: 1;
    transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition-property: left, right;
    transition-delay: 0s, 0.08s;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
`
const CheckBox = styled.input`
    position: absolute;
    display: block;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 6;
    &:checked ~ ${Switch} {
        right: 2px;
        left: 57.5%;
        transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        transition-property: left, right;
        transition-delay: 0.08s, 0s;
    }
`

export const SubscriptionPlanChange = (props: any) => {
    const [checkedBox, setCheckbox] = React.useState<Boolean>(props.defaultValue === 'Monthly' ? false : true)

    const toggleCheckbox = (_: any) => {
        if (checkedBox) {
            setCheckbox(false)
            props.toggleSwitch('Monthly')
        } else {
            setCheckbox(true)
            props.toggleSwitch('Yearly')
        }
    }

    return (
        <Toggler>
            <Toggle id="filt-monthly">Bill Monthly</Toggle>
            <ToggleSwitch>
                <CheckBox id="checkbox" type="checkbox" onChange={toggleCheckbox} checked={checkedBox} />
                <Switch></Switch>
            </ToggleSwitch>
            <Toggle id="filt-hourly">Bill Yearly</Toggle>
        </Toggler>
    )
}
