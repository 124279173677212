import React, { useEffect, useState } from 'react';
import {
  BgLine,
  Card,
  CardHeader,
  ChartFeedbackWrapper,
  ChartWrapper,
  ChatWrapperLeft,
  Content,
  CountNumber,
  CountWrapper,
  DropdownLi,
  DropdownLiSpan,
  DropdownUl,
  DropdownWrapper,
  HeaderContainer,
  HoverContent,
  Input,
  InputContent,
  InputWrapper,
  NodataContent,
  SettingsWrapper,
  TabCardContainer,
} from '@/common/styled/Dialog.BotDetails.Dumb';
import { BotDropdown, InfoIcon, SettingsIcon } from '@/common/Icons/Icons';
import Tooltip from 'react-tooltip-lite';
import { BasiceDropdown, OverAllSummaryT } from './BotDetails';
import { AssistantT } from '../Construction/kind';
import {
  useFetchBarchartBotSummary,
  useFetchROISummary,
  useUpdateBarChartSummary,
  useUpdateCostDetails,
} from './BotSummaryAPI';
import { barchartDates, returnDate } from './TabUIElements';
import Downshift from 'downshift';
import BasicDateRangePicker from './CustomDatePicker';
import {
  convertFromToDate,
  formatAMPM,
  formatDateInCard,
} from '@/common/utils/utils';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip as ChartTip,
  AreaChart,
  Label,
  Area,
} from 'recharts';
import * as R from 'ramda';
import { SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb';
var approx = require('approximate-number');

const StatusDetailsROI = [
  {
    header: 'First contact resolution',
    subheader: 'of queries were auto-resolved',
    count: '80%',
  },
  {
    header: 'Mean time to resolution',
    subheader: '(hh:mm:ss)',
    count: '00:01:00',
  },
  {
    header: 'User hours saved',
    count: '4870',
    hoverContent: 'Time saved for users by bot auto-resolving user queries',
    image: <InfoIcon />,
    iconImage: <SettingsIcon />,
    hoursText: 'hrs',
  },
  {
    header: 'Agent hours saved',
    count: '15863',
    hoverContent: 'Time saved for agents by bot auto-resolving user queries',
    image: <InfoIcon />,
    iconImage: <SettingsIcon />,
    hoursText: 'hrs',
  },
  {
    header: 'Cost savings',
    count: '$30819',
    hoverContent: 'Cost savings from bot',
    image: <InfoIcon />,
    iconImage: <SettingsIcon />,
  },
];

type AutoReolvedChartT = {
  date: string;
  auto_resolved: number;
  user_queries: number;
};

type TotalAutoReolvedChartT = {
  date: string;
  total_auto_resolved: number;
  total_query: number;
};

type MeanTimeAutoReolvedChartT = {
  date: string;
  auto_resolved: number;
  avg: string;
};

type ROISummaryT = {
  total_queries: number;
  total_resolved_query: number;
  mean_time_resolution: string;
  day_wise_auto_resolved: AutoReolvedChartT[];
  day_wise_total_resolved_query: TotalAutoReolvedChartT[];
  day_wise_mean_time_resolved_query: MeanTimeAutoReolvedChartT[];
};

export function ROITab(props: {
  botId: string | undefined;
  workspace: string;
  overAllSummary: OverAllSummaryT;
  selectedBot: AssistantT;
  setTabLoading: (loading: boolean) => void;
}) {
  const { overAllSummary, setTabLoading } = props;
  const [dateOptions, setOptions] = useState('last30days')

  const [state, setState] = useState({
    showBarChartDropDown: false,
    selectedBarchartDate: 'last30days',
    showMeanTimeBarChartDropDown: false,
    selectedMeanTimeBarchartDate: 'last30days',
    showTotalResolvedChartDropDown: false,
    selectedTotalResolvedChartDate: 'last30days',
    customDatePickerShow: false,
    customDates1: { from_date: null, to_date: null },
    customDates2: { from_date: null, to_date: null },
    customDates3: { from_date: null, to_date: null },
  });

  const [costDropdown, setCostDropdown] = useState(false);
  const [agenthrsDropdown, setAgentHrsDropdown] = useState(false);
  const [userhrsDropdown, setUserHrsDropdown] = useState(false);

  const bot = props.selectedBot;

  const [updateCostDetail, setUpdateDetail] = useState({
    avg_time_spend_by_agent: 0,
    avg_cost_to_resolve_an_issue: 0,
    avg_time_spend_by_user: 0,
  });

  const [updateCostDetailInitial, setUpdateDetailInitial] = useState({
    avg_time_spend_by_agent: 0,
    avg_cost_to_resolve_an_issue: 0,
    avg_time_spend_by_user: 0,
  });

  useEffect(() => {
    roiFun(
       bot ? bot.avg_time_spend_by_agent : 0,
       bot ? bot.avg_cost_to_resolve_an_issue : 0,
       bot ? bot.avg_time_spend_by_user : 0,
    )
  }, [bot]);

  function roiFun(avg_time_spend_by_agent:number,avg_cost_to_resolve_an_issue:number,avg_time_spend_by_user:number){
    setUpdateDetail({
      avg_time_spend_by_agent,
      avg_cost_to_resolve_an_issue,
      avg_time_spend_by_user
    });
    setUpdateDetailInitial({
      avg_time_spend_by_agent,
      avg_cost_to_resolve_an_issue,
      avg_time_spend_by_user
    });
    costDropdown && setCostDropdown(false);
    agenthrsDropdown && setAgentHrsDropdown(false);
    userhrsDropdown && setUserHrsDropdown(false);
  }

  const {
    roiSummary,
    roiSummaryRetry,
    roiSummaryStatus,
    dataUpdatedAt,
    isRefetching,
    isFetching
  } = useFetchROISummary(
    props.workspace,
    props.botId ? props.botId : '',
    returnDate(dateOptions),
    {
      enabled: !!props.botId,
      onSuccess: (data: any) => {
        setTabLoading(false);
      },
    }
  );

  const { barchartBotSummary } = useFetchBarchartBotSummary(
    props.workspace,
    props.botId ? props.botId : '',
    '30',
    {
      enabled: !!props.botId,
    },
    'roibarchartsummary'
  );

  const meantimeBarchartBotSummary = useFetchBarchartBotSummary(
    props.workspace,
    props.botId ? props.botId : '',
    '30',
    {
      enabled: !!props.botId,
    },
    'roimeantimebarchartsummary'
  );

  const totalresolvedBarchartBotSummary = useFetchBarchartBotSummary(
    props.workspace,
    props.botId ? props.botId : '',
    '30',
    {
      enabled: !!props.botId,
    },
    'roitotalresolvedbarchartsummary'
  );

  const { barChartMutate } = useUpdateBarChartSummary('roibarchartsummary');
  const  barChartMutate1 = useUpdateBarChartSummary('roimeantimebarchartsummary');
  const  barChartMutate2  = useUpdateBarChartSummary('roitotalresolvedbarchartsummary');

  const { mutate, status } = useUpdateCostDetails((response:AssistantT)=>{
    if(response){
      roiFun(
        response.avg_time_spend_by_agent,
        response.avg_cost_to_resolve_an_issue,
        response.avg_time_spend_by_user,
     )
    }
  });

  function barchartDate(key:string) {
    return barchartDates.filter(
      (it) => it.date === state[`${key}`]
    )[0].label;
  }

  useEffect(() => {
    barchartBotSummary &&
      (state.selectedBarchartDate !== 'customrange' ||
        (state.customDates1.from_date != null &&
          state.customDates1.to_date != null)) &&
      barChartMutate({
        botId: props.botId,
        workspace: props.workspace,
        date:
          state.selectedBarchartDate === 'customrange'
            ? state.customDates1
            : returnDate(state.selectedBarchartDate),
      });
    state.selectedBarchartDate === 'customrange' &&
      setState({ ...state, customDatePickerShow: true });
  }, [state.selectedBarchartDate, state.customDates1]);

  useEffect(() => {
    meantimeBarchartBotSummary.barchartBotSummary &&
      (state.selectedMeanTimeBarchartDate !== 'customrange' ||
        (state.customDates2.from_date != null &&
          state.customDates2.to_date != null)) &&
      barChartMutate1.barChartMutate({
        botId: props.botId,
        workspace: props.workspace,
        date:
          state.selectedMeanTimeBarchartDate === 'customrange'
            ? state.customDates2
            : returnDate(state.selectedMeanTimeBarchartDate),
      });
    state.selectedMeanTimeBarchartDate === 'customrange' &&
      setState({ ...state, customDatePickerShow: true });
  }, [state.selectedMeanTimeBarchartDate, state.customDates2]);

  useEffect(() => {
    totalresolvedBarchartBotSummary.barchartBotSummary &&
      (state.selectedTotalResolvedChartDate !== 'customrange' ||
        (state.customDates3.from_date != null &&
          state.customDates3.to_date != null)) &&
      barChartMutate2.barChartMutate({
        botId: props.botId,
        workspace: props.workspace,
        date:
          state.selectedTotalResolvedChartDate === 'customrange'
            ? state.customDates3
            : returnDate(state.selectedTotalResolvedChartDate),
      });
    state.selectedTotalResolvedChartDate === 'customrange' &&
      setState({ ...state, customDatePickerShow: true });
  }, [state.selectedTotalResolvedChartDate, state.customDates3]);

  function retnFn(i: number) {
    const roi = roiSummary as ROISummaryT;
    if (!roi) return 0;
    switch (i) {
      case 0:
        return roi.total_queries === 0 ? ( `0.00 %`) :
        (
          ((roi.total_resolved_query / roi.total_queries) * 100).toFixed(2) +
          '%'
        )
      case 1:
        return roi.mean_time_resolution;
      case 2:
        return roi.total_resolved_query * updateCostDetailInitial.avg_time_spend_by_user;
      case 3:
          return roi.total_resolved_query * updateCostDetailInitial.avg_time_spend_by_agent;
      default:
        return '$' + roi.total_resolved_query * updateCostDetailInitial.avg_cost_to_resolve_an_issue;
    }
  }

  return (
    <div className="bot_sessions_container">
      {isRefetching &&
      setTabLoading(true)
      }
      {isFetching && 
       setTabLoading(true)
      }
      <div className="bot_sessions_heaader">
        <div>
          <h5>Explore cost savings and productivity gains from the bot</h5>
        </div>
        <div className="d-flex align-items-center bot_sessions_heaader_color">
          <div className="report_content">
            This report was generated on{' '}<br/>
            {formatDateInCard((roiSummary?new Date(dataUpdatedAt):new Date()).toString(), true)} at{' '}
            {formatAMPM((roiSummary?new Date(dataUpdatedAt):new Date()).toString(), true)} -{' '}
            <span
              onClick={() => {
                setTabLoading(true);
                roiSummaryRetry();
              }}
            >
              Refresh
            </span>
          </div>
          <BasiceDropdown dateOptions={dateOptions} changeDateFilter={()=>{
              setTabLoading(true)
            }} setOptions={setOptions}/>
        </div>
      </div>
      <HeaderContainer>
        <TabCardContainer className="tab_user_queris_two">
          {StatusDetailsROI.map((data, i) => (
            <Card style={{ height: '140px' }}>
              <CardHeader>
                {data.header}
                <Tooltip
                  className="target_bot"
                  tipContentHover
                  zIndex={5000}
                  arrowSize={5}
                  tagName="span"
                  content={data.hoverContent}
                >
                  <span>{data.image}</span>
                </Tooltip>
              </CardHeader>
              <CardHeader>
                <p>{data.subheader} </p>
              </CardHeader>

              <CountNumber>
                <CountWrapper>
                  {retnFn(i)}
                  <span>{data.hoursText}</span>
                </CountWrapper>
                {data.header.toLowerCase() ==
                  'Agent Hours Saved'.toLowerCase() && (
                  <SettingsWrapper>
                    {data.iconImage && (
                      <div
                        className="settings"
                        onClick={() => setAgentHrsDropdown(true)}
                      >
                        {data.iconImage}
                        {agenthrsDropdown && (
                          <HoverContent>
                            {/* <Content>
                              Time spent by your support agent to resolve or
                              address an issue, ticket, or service request.
                            </Content> */}
                            <Downshift
                              isOpen={true}
                              onOuterClick={() => {
                                setUpdateDetail({
                                  ...updateCostDetail,
                                  [`${'avg_time_spend_by_agent'}`]:
                                  updateCostDetailInitial.avg_time_spend_by_agent,
                                });
                                setAgentHrsDropdown(false)
                              }}
                            >
                              {() => (
                                <span>
                                  <InputWrapper>
                                    <Input
                                      type="number"
                                      value={
                                        updateCostDetail.avg_time_spend_by_agent
                                      }
                                      onChange={(event: any) => {
                                        setUpdateDetail({
                                          ...updateCostDetail,
                                          [`${'avg_time_spend_by_agent'}`]:
                                            event.target.value,
                                        });
                                      }}
                                      style={{ width: 42, height: 26 }}
                                    />
                                    <span
                                      style={{ marginLeft: 4, marginRight: 4 }}
                                    >
                                      hrs
                                    </span>
                                    <SmallButton
                                      style={{ minWidth: 54 }}
                                      onClick={() => {
                                        mutate({
                                          workspace: props.workspace,
                                          botId: props.botId,
                                          name: bot.name,
                                          data: {
                                            [`${'avg_time_spend'}`]:
                                              parseFloat(updateCostDetail.avg_time_spend_by_agent as unknown as string),
                                          },
                                        });
                                      }}
                                    >
                                      <label>Apply</label>
                                    </SmallButton>
                                  </InputWrapper>
                                </span>
                              )}
                            </Downshift>
                          </HoverContent>
                        )}
                      </div>
                    )}
                  </SettingsWrapper>
                )}
                {data.header.toLowerCase() ==
                  'User Hours Saved'.toLowerCase() && (
                  <SettingsWrapper>
                    {data.iconImage && (
                      <div
                        className="settings"
                        onClick={() => setUserHrsDropdown(true)}
                      >
                        {data.iconImage}
                        {userhrsDropdown && (
                          <HoverContent>
                            {/* <Content>
                              Time spent by your support agent to resolve or
                              address an issue, ticket, or service request.
                            </Content> */}
                            <Downshift
                              isOpen={true}
                              onOuterClick={() => {
                                setUpdateDetail({
                                  ...updateCostDetail,
                                  [`${'avg_time_spend_by_user'}`]:
                                  updateCostDetailInitial.avg_time_spend_by_user,
                                });
                                setUserHrsDropdown(false)
                              }}
                            >
                              {() => (
                                <span>
                                  <InputWrapper>
                                    <Input
                                      type="number"
                                      value={
                                        updateCostDetail.avg_time_spend_by_user
                                      }
                                      onChange={(event: any) => {
                                        setUpdateDetail({
                                          ...updateCostDetail,
                                          [`${'avg_time_spend_by_user'}`]:
                                            event.target.value,
                                        });
                                      }}
                                      style={{ width: 42, height: 26 }}
                                    />
                                    <span
                                      style={{ marginLeft: 4, marginRight: 4 }}
                                    >
                                      hrs
                                    </span>
                                    <SmallButton
                                      style={{ minWidth: 54 }}
                                      onClick={() => {
                                        mutate({
                                          workspace: props.workspace,
                                          botId: props.botId,
                                          name: bot.name,
                                          data: {
                                            [`${'avg_time_spend_by_user'}`]:
                                            parseFloat(updateCostDetail.avg_time_spend_by_user as unknown as string),
                                          },
                                        });
                                      }}
                                    >
                                      <label>Apply</label>
                                    </SmallButton>
                                  </InputWrapper>
                                </span>
                              )}
                            </Downshift>
                          </HoverContent>
                        )}
                      </div>
                    )}
                  </SettingsWrapper>
                )}
                {data.header.toLowerCase() == 'Cost Savings'.toLowerCase() && (
                  <SettingsWrapper>
                    {data.iconImage && (
                      <div
                        className="settings"
                        onClick={() => setCostDropdown(true)}
                      >
                        {data.iconImage}
                        {costDropdown && (
                          <HoverContent>
                            {/* <Content>
                              Average cost to resolve an issue, ticket or
                              service request.
                            </Content> */}
                            <Downshift
                              isOpen={true}
                              onOuterClick={() => {
                                setUpdateDetail({
                                  ...updateCostDetail,
                                  avg_cost_to_resolve_an_issue:
                                  updateCostDetailInitial.avg_cost_to_resolve_an_issue,
                                });
                                setCostDropdown(false)
                              }}
                            >
                              {() => (
                                <span>
                                  <InputWrapper>
                                    <span
                                      style={{
                                        marginLeft: '0px',
                                        marginRight: '5px',
                                      }}
                                    >
                                      $
                                    </span>{' '}
                                    <Input
                                      type="number"
                                      value={
                                        updateCostDetail.avg_cost_to_resolve_an_issue
                                      }
                                      onChange={(event: any) => {
                                        setUpdateDetail({
                                          ...updateCostDetail,
                                          avg_cost_to_resolve_an_issue:
                                            event.target.value,
                                        });
                                      }}
                                    />
                                    <SmallButton
                                      style={{ minWidth: 54, marginLeft: 10 }}
                                      onClick={() => {
                                        mutate({
                                          workspace: props.workspace,
                                          botId: props.botId,
                                          name: bot.name,
                                          data: {
                                            avg_cost_to_resolve_an_issue:
                                            parseFloat(updateCostDetail.avg_cost_to_resolve_an_issue as unknown as string),
                                          },
                                        });
                                      }}
                                    >
                                      <label>Apply</label>
                                    </SmallButton>
                                  </InputWrapper>
                                </span>
                              )}
                            </Downshift>
                          </HoverContent>
                        )}
                      </div>
                    )}
                  </SettingsWrapper>
                )}
              </CountNumber>
            </Card>
          ))}
        </TabCardContainer>
      </HeaderContainer>

      <div className="tab_section_wrapper">
        <div className="tab_section_header">
          <div>
            <h5>First Contact Resolution (FCR)</h5>
            <p>
              User queries that were auto-resolved by the bot in first contact
              over a specific period
            </p>
          </div>
          <div className="tab_dropdown_date">
            {state.customDatePickerShow && (
              <BasicDateRangePicker
                setDate={(date: Date[]) => {
                  setState({
                    ...state,
                    customDates1: {
                      from_date: convertFromToDate(date[0].toString()) as any,
                      to_date: convertFromToDate(date[1].toString()) as any,
                    },
                  });
                }}
              />
            )}
            <DropdownWrapper>
              <Input
                onClick={() =>
                  setState({ ...state, showBarChartDropDown: true })
                }
                onChange={() => {}}
                value={barchartDate("selectedBarchartDate")}
              />
              <InputContent>Show by</InputContent>
              <span
                 onClick={() =>
                  setState({ ...state, showBarChartDropDown: true })
                }
              >
                <BotDropdown />
              </span>
              {state.showBarChartDropDown && (
                <Downshift
                  isOpen={true}
                  onOuterClick={() =>
                    setState({ ...state, showBarChartDropDown: false })
                  }
                >
                  {() => (
                    <div>
                      <DropdownUl>
                        {barchartDates.map((date, i) => (
                          <DropdownLi
                            key={i}
                            onClick={() => {
                              setState({
                                ...state,
                                selectedBarchartDate: date.date,
                                showBarChartDropDown: false,
                              });
                            }}
                          >
                            <DropdownLiSpan>{date.label}</DropdownLiSpan>{' '}
                          </DropdownLi>
                        ))}
                      </DropdownUl>
                    </div>
                  )}
                </Downshift>
              )}
            </DropdownWrapper>
          </div>
        </div>
        <div className="tab_section_graph">
          <ChartFeedbackWrapper>
            <ChartWrapper chartsBg>
              <ChatWrapperLeft chartsPadding>
                <div className="bar_charts_wrapper">
                  <BarCharts
                    day_wise_session_query_count={
                      barchartBotSummary ? barchartBotSummary : []
                    }
                    dateOptions={state.selectedBarchartDate}
                  />
                </div>
              </ChatWrapperLeft>
            </ChartWrapper>
          </ChartFeedbackWrapper>
        </div>
      </div>

      <div className="tab_section_wrapper">
        <div className="tab_section_header">
          <div>
            <h5>Mean Time To Resolution (MTTR)</h5>
            <p>
              Average time taken by the bot to auto-resolve a user query over a
              specific period
            </p>
          </div>
          <div className="tab_dropdown_date">
            {state.customDatePickerShow && (
              <BasicDateRangePicker
                setDate={(date: Date[]) => {
                  setState({
                    ...state,
                    customDates2: {
                      from_date: convertFromToDate(date[0].toString()) as any,
                      to_date: convertFromToDate(date[1].toString()) as any,
                    },
                  });
                }}
              />
            )}
            <DropdownWrapper>
              <Input
                onClick={() =>
                  setState({ ...state, showMeanTimeBarChartDropDown: true })
                }
                onChange={() => {}}
                value={barchartDate("selectedMeanTimeBarchartDate")}
              />
              <InputContent>Show by</InputContent>
              <span 
                onClick={() =>
                  setState({ ...state, showMeanTimeBarChartDropDown: true })
                }
              >
                <BotDropdown />
              </span>
              {state.showMeanTimeBarChartDropDown && (
                <Downshift
                  isOpen={true}
                  onOuterClick={() =>
                    setState({ ...state, showMeanTimeBarChartDropDown: false })
                  }
                >
                  {() => (
                    <div>
                      <DropdownUl>
                        {barchartDates.map((date, i) => (
                          <DropdownLi
                            key={i}
                            onClick={() => {
                              setState({
                                ...state,
                                selectedMeanTimeBarchartDate: date.date,
                                showMeanTimeBarChartDropDown: false,
                              });
                            }}
                          >
                            <DropdownLiSpan>{date.label}</DropdownLiSpan>{' '}
                          </DropdownLi>
                        ))}
                      </DropdownUl>
                    </div>
                  )}
                </Downshift>
              )}
            </DropdownWrapper>
          </div>
        </div>
        <div className="tab_section_graph">
          <ChartFeedbackWrapper>
            <ChartWrapper chartsBg>
              <ChatWrapperLeft chartsPadding>
                <div className="bar_charts_wrapper">
                  <BarCharts1
                    day_wise_session_query_count={
                      meantimeBarchartBotSummary.barchartBotSummary
                        ? meantimeBarchartBotSummary.barchartBotSummary
                        : []
                    }
                    dateOptions={state.selectedMeanTimeBarchartDate}
                  />
                </div>
              </ChatWrapperLeft>
            </ChartWrapper>
          </ChartFeedbackWrapper>
        </div>
      </div>

      <div className="tab_section_wrapper">
        <div className="tab_section_header">
          <div>
            <h5>Cost Savings & Productivity Improvements</h5>
            <p>
              How did the bot help you save costs and improve productivity over
              a specific period
            </p>
          </div>
          <div className="tab_dropdown_date">
            {state.customDatePickerShow && (
              <BasicDateRangePicker
                setDate={(date: Date[]) => {
                  setState({
                    ...state,
                    customDates3: {
                      from_date: convertFromToDate(date[0].toString()) as any,
                      to_date: convertFromToDate(date[1].toString()) as any,
                    },
                  });
                }}
              />
            )}
            <DropdownWrapper>
              <Input
                onClick={() =>
                  setState({ ...state, showTotalResolvedChartDropDown: true })
                }
                onChange={() => {}}
                value={barchartDate("selectedTotalResolvedChartDate")}
              />
              <InputContent>Show by</InputContent>
              <span
                onClick={() =>
                  setState({ ...state, showTotalResolvedChartDropDown: true })
                }
              >
                <BotDropdown />
              </span>
              {state.showTotalResolvedChartDropDown && (
                <Downshift
                  isOpen={true}
                  onOuterClick={() =>
                    setState({ ...state, showTotalResolvedChartDropDown: false })
                  }
                >
                  {() => (
                    <div>
                      <DropdownUl>
                        {barchartDates.map((date, i) => (
                          <DropdownLi
                            key={i}
                            onClick={() => {
                              setState({
                                ...state,
                                selectedTotalResolvedChartDate: date.date,
                                showTotalResolvedChartDropDown: false,
                              });
                            }}
                          >
                            <DropdownLiSpan>{date.label}</DropdownLiSpan>{' '}
                          </DropdownLi>
                        ))}
                      </DropdownUl>
                    </div>
                  )}
                </Downshift>
              )}
            </DropdownWrapper>
          </div>
        </div>
        <div className="tab_section_graph">
          <ChartWithWrapper
            botDetails={
              totalresolvedBarchartBotSummary.barchartBotSummary
                ? totalresolvedBarchartBotSummary.barchartBotSummary
                : []
            }
            dateOptions={state.selectedTotalResolvedChartDate}
          />
        </div>
      </div>
    </div>
  );
}

function BarCharts(props: {
  day_wise_session_query_count: AutoReolvedChartT[];
  dateOptions: string;
}) {
  const BarchartTooltip = (props: any) => {
    // const { series, seriesIndex, dataPointIndex, w } = props
    // console.log("pLoad",props)

    const { active, payload, label } = props;
    if (active && payload && payload.length) {
      const pLoad = payload[0].payload;
      const resolPercentage =(pLoad:any)=>{
        return (pLoad.auto_resolved / pLoad.user_queries) * 100
      }
      return (
        <div className="custom-tooltip">
          <p>
            User Queries:{' '}
            <span style={{color:"#43C937"}}>{pLoad.user_queries ? pLoad.user_queries : 0}</span>
          </p>
          <p>
            Bot auto reolved:
            <span style={{color:"#49CBC2"}}>{pLoad.auto_resolved ? pLoad.auto_resolved : 0}</span>
          </p>
          <p>
            First contact resolution:
            <span style={{color:"#333333"}}>
              {pLoad.user_queries
                ? (Math.round(resolPercentage(pLoad)))
                : 0}
              %
            </span>
          </p>
        </div>
      );
    }
    return null;
  };
  type ChartData = {
    name: string;
    auto_resolved: number;
    user_queries: number;
  };

  const { day_wise_session_query_count, dateOptions } = props;

  function filterDate() {
    return day_wise_session_query_count.map((chart) => {
      const date =
        dateOptions != 'today' && dateOptions != 'yesterday'
          ? chart.date.split('/')[0] + '/' + parseInt(chart.date.split('/')[1])
          : chart.date.split('-');
      return {
        name: date,
        auto_resolved: chart.auto_resolved,
        user_queries: chart.user_queries,
      };
    });
  }
  // const MOCK_ROI = [
  //   {
  //     name: '18/03/2023',
  //     auto_resolved: 5,
  //     user_queries: 7,
  //   },
  //   {
  //     name: '25/03/2023',
  //     auto_resolved: 8,
  //     user_queries: 10,
  //   },
  //   {
  //     name: '02/04/2023',
  //     auto_resolved: 15,
  //     user_queries: 24,
  //   },
    
  // ]
  const datas: ChartData[] =filterDate();

  const modifiedData = [
    {
      name: '',
      auto_resolved: 0,
      user_queries: 0,
    },
    ...datas,
  ];

  const maxValue = modifiedData
    .map((x: ChartData) => x.auto_resolved + x.user_queries)
    .reduce((prev, curr) => {
      return Math.max(prev, curr);
    }, 0);

  const length = `${maxValue}`.length - 1;

  const unit = maxValue <= 25 ? 5 : parseInt(1 + '0'.repeat(length));

  const yAxisTicks =
    unit === 5
      ? [5, 10, 15, 20, 25, 30]
      : R.unfold<number, number>((it) =>
          it > maxValue + 2 * unit ? false : [it, it + unit]
        )(unit);

   const [position, setPosition] = useState({
    data: { x: 0, y: 0,height:0 },
    show: false,
    belowBar:false
  });
  return (
    // <div className="analytics_charts_left_img">
    <div
      style={{ width: '100%' }}
      className={
        datas.length > 0 ? 'bot_session_chart' : 'no_data_bot_session_chart'
      }
    >
      {datas.length > 0 ? (
        <div className="bot-summary-all-sessions">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={830}
              height={320}
              data={modifiedData}
              margin={{
                top: 15,
                right: 25,
                left: -10,
                bottom: 5,
              }}
              barCategoryGap={'35%'}
              barGap={'10%'}
            >
              <defs>
              <linearGradient id="resolvedclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                <stop offset='0%' stopColor='#A1E89B' />
                <stop offset='4%' stopColor='#9EE498' />
                <stop offset='45%' stopColor='#77AE73' />
                <stop offset='100%' stopColor='#6A9B66'/>
              </linearGradient>
              <linearGradient id="queriesclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                <stop offset='0%' stopColor='#7AE3DC' />
                <stop offset='35%' stopColor='#77DED7'/>
                <stop offset='100%' stopColor='#55A39E'/>
              </linearGradient>
              </defs>
              <CartesianGrid />
              <XAxis
                angle={(props.dateOptions === "today" || props.dateOptions ===  "yesterday") ? 0 : 45}
                dy={8}
                dx={(props.dateOptions === "today" || props.dateOptions ===  "yesterday") ? 0 : 12}
                dataKey="name"
                interval={0}
                // label={{
                //   value:
                //     dateOptions == 'today' || dateOptions == 'yesterday'
                //       ? 'Hours'
                //       : 'Date',
                //   position: 'insideBottom',
                //   className: 'xAxisLabel',
                //   offset: -20,
                // }}
                scale="point"
                // padding={{ left: 5, right: 5 }}
                axisLine={false}
                tickLine={false}
              />
              <YAxis
                allowDecimals={false}
                label={{
                  value: 'User Queries',
                  angle: -90,
                  position: 'insideLeft',
                  offset: 16,
                  style: { textAnchor: 'middle' },
                }}
                axisLine={false}
                tickLine={{ stroke: '#F2F2F2' }}
                tickFormatter={(x: number) => {
                  return approx(x);
                }}
                ticks={[0, ...yAxisTicks]}
                tickMargin={5}
              />
              <ChartTip
                isAnimationActive={false}
                cursor={false}
                position ={position.belowBar ? (position.show? {x:position.data.x - 70, y:position.data.y-position.data.height - 45}: undefined) : (position.show? {x:position.data.x - 70, y:position.data.y - 65}: undefined)}
                content={
                  position.show ? (
                    <BarchartTooltip />
                  ) : (
                    <div style={{ display: 'none' }}></div>
                  )
                }
              />

              <Bar
                dataKey="user_queries"
                stackId="a"
                fill="url(#queriesclr)"
                // "#7AE3DC"
                barSize={10}
                onMouseOver={(data) => {
                  setPosition({ data: data, show: true, belowBar:true })
                }}
                onMouseLeave={(data) =>
                  setPosition({ data : { x : 0 , y : 0,height:0 },show: false,belowBar:false })
                }
                isAnimationActive={false}
              />

              <Bar
                dataKey="auto_resolved"
                stackId="a"
                fill="url(#resolvedclr)"
                // "#A1E89B"
                barSize={10}
                radius={[10, 10, 0, 0]}
                onMouseOver={(data) => {
                  setPosition({ data: data, show: true,belowBar:false });
                }}
                onMouseLeave={(data) => {
                  setPosition({ data : { x : 0 , y : 0,height:0 },show: false,belowBar:false });
                }}
              />
              {/* <Bar dataKey="resolved" stackId="a" fill="#7AE3DC" barSize={10} /> */}
              {/* ['#EB6453', '#FFAB57', '#7AE3DC'] */}
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <NodataContent>No data available</NodataContent>
      )}
    </div>
    // </div>
  );
}

function BarCharts1(props: {
  day_wise_session_query_count: MeanTimeAutoReolvedChartT[];
  dateOptions: string;
}) {
  const BarchartTooltip = (props: any) => {
    // const { series, seriesIndex, dataPointIndex, w } = props
    // console.log("pLoad",props)

    const { active, payload, label } = props;
    if (active && payload && payload.length) {
      const pLoad = payload[0].payload;
      return (
        <div className="custom-tooltip">
          <p className="tooltip_date">
            Queries auto-resolved:
            <span>{pLoad.auto_resolved ? pLoad.auto_resolved : 0}</span>
          </p>
          <p>
            MTTR: <span>{pLoad.avg ? pLoad.avg : 0} (hh:mm:ss)</span>
          </p>
        </div>
      );
    }
    return null;
  };
  type ChartData1 = {
    name: string;
    auto_resolved: number;
    avg: string;
  };

  const { day_wise_session_query_count, dateOptions } = props;

  function filterDate() {
    return day_wise_session_query_count.map((chart) => {
      const date =
        dateOptions != 'today' && dateOptions != 'yesterday'
          ? chart.date.split('/')[0] + '/' + parseInt(chart.date.split('/')[1])
          : chart.date.split('-');
      return {
        name: date,
        auto_resolved: chart.auto_resolved,
        avg: chart.avg,
      };
    });
  }
  // const MOCK_MTTR=[
  //   {
  //     name: '17/03/2023',
  //     auto_resolved: 51,
  //     avg: '10:59:38',
  //   },
  //   {
  //     name: '23/03/2023',
  //     auto_resolved: 11,
  //     avg: '4:8:22',
  //   },
  //   {
  //     name: '04/04/2023',
  //     auto_resolved: 44,
  //     avg: '0:0:30',
  //   },

  // ]
  const datas: ChartData1[] = filterDate();

  const modifiedData = [
    {
      name: '',
      auto_resolved: 0,
      avg: '',
    },
    ...datas,
  ];

  const maxValue = modifiedData
    .map((x: ChartData1) => x.auto_resolved)
    .reduce((prev, curr) => {
      return Math.max(prev, curr);
    }, 0);

  const length = `${maxValue}`.length - 1;

  const unit = maxValue <= 25 ? 5 : parseInt(1 + '0'.repeat(length));

  const yAxisTicks =
    unit === 5
      ? [5, 10, 15, 20, 25, 30]
      : R.unfold<number, number>((it) =>
          it > maxValue + 2 * unit ? false : [it, it + unit]
        )(unit);
  const [position, setPosition] = useState({
    data: { x: 0, y: 0,height:0 },
    show: false,
  });
  return (
    <div
      style={{ width: '100%' }}
      className={
        datas.length > 0 ? 'bot_session_chart' : 'no_data_bot_session_chart'
      }
    >
      {datas.length > 0 ? (
        <div className="bot-summary-all-sessions">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={830}
              height={320}
              data={modifiedData}
              margin={{
                top: 15,
                right: 25,
                left: -10,
                bottom: 5,
              }}
              barCategoryGap={'35%'}
              barGap={'10%'}
            >
              <defs>
              <linearGradient id="autoresolvedclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                <stop offset='0%' stopColor='#7AE3DC' />
                <stop offset='15%' stopColor='#7AE0DA' />
                <stop offset='100%' stopColor='#70AFAA'/>
              </linearGradient>
              </defs>
              <CartesianGrid />
              <XAxis
                angle={(props.dateOptions === "today" || props.dateOptions ===  "yesterday") ? 0 : 45}
                dy={8}
                dx={(props.dateOptions === "today" || props.dateOptions ===  "yesterday") ? 0 : 12}
                dataKey="name"
                interval={0}
                scale="point"
                axisLine={false}
                tickLine={false}
              />
              <YAxis
                allowDecimals={false}
                label={{
                  value: 'User Queries',
                  angle: -90,
                  position: 'insideLeft',
                  offset: 16,
                  style: { textAnchor: 'middle' },
                }}
                axisLine={false}
                tickLine={{ stroke: '#F2F2F2' }}
                tickFormatter={(x: number) => {
                  return approx(x);
                }}
                ticks={[0, ...yAxisTicks]}
                tickMargin={5}
              />
              <ChartTip
                isAnimationActive={false}
                cursor={false}
                position ={(position.show? {x:position.data.x - 70, y:position.data.y - 45}: undefined)}
                content={
                  position.show ? (
                    <BarchartTooltip />
                  ) : (
                    <div style={{ display: 'none' }}></div>
                  )
                }
              />
              <Bar
                dataKey="auto_resolved"
                stackId="a"
                fill="url(#autoresolvedclr)"
                // "#7AE3DC"
                barSize={10}
                radius={[10, 10, 0, 0]}
                isAnimationActive={false}
                onMouseOver={(data) => {
                  setPosition({ data: data, show: true });
                }}
                onMouseLeave={(data) => {
                  setPosition({ data : { x : 0 , y : 0,height:0 },show: false });
                }}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <NodataContent>No data available</NodataContent>
      )}
    </div>
  );
}

const ChartWithWrapper = (props: {
  botDetails: TotalAutoReolvedChartT[];
  dateOptions: string;
}) => {
  const { botDetails, dateOptions } = props;

  const AreaTooltip = (props: any) => {
    const { active, payload, label } = props;
    if (active && payload && payload.length && payload[0].payload.total_query) {
      return (
        <div className="custom-tooltip _resolved">
          <p>
            Queries auto-reolved:{' '}
            <span>
              {payload[0].payload.total_auto_resolved
                ? payload[0].payload.total_auto_resolved
                : 0}
            </span>
          </p>
          <p>
            Cost savings:{' '}
            <span>
              $
              {payload[0].payload.total_auto_resolved
                ? payload[0].payload.total_auto_resolved * 15.5
                : 0}
            </span>
          </p>
          <p>
            Agent hours saved:{' '}
            <span>
              {payload[0].payload.total_auto_resolved
                ? payload[0].payload.total_auto_resolved * 8
                : 0}
              {'hrs'}
            </span>
          </p>
          <p>
            User hours saved:{' '}
            <span>
              {payload[0].payload.total_auto_resolved
                ? payload[0].payload.total_auto_resolved * 8
                : 0}
              {'hrs'}
            </span>
          </p>
        </div>
      );
    }
    return null;
  };

  const datas = botDetails.reduce(
    (acc: any, chart) => {
      const date =
        dateOptions != 'today' && dateOptions != 'yesterday'
          ? chart.date.split('/')[0] +
            '/' +
            (parseInt(chart.date.split('/')[1]) < 10
              ? '0' + parseInt(chart.date.split('/')[1])
              : parseInt(chart.date.split('/')[1]))
          : chart.date;
      return {
        previousQueryvalue: acc.previousQueryvalue + chart.total_query,
        previousAutoResolvedvalue:acc.previousAutoResolvedvalue + chart.total_auto_resolved,
        array: [
          ...acc.array,
          {
            name: date,
            total_query: chart.total_query,
            total_auto_resolved: chart.total_auto_resolved,
          },
        ],
      };
    },
    { previousQueryvalue: 0, previousAutoResolvedvalue: 0, array: [] }
  ).array as {
    name: string;
    total_query: number;
    total_auto_resolved: number;
  }[];

  const modifiedData = datas;

  const mxValue = modifiedData
    .map((x) => x.total_query)
    .reduce((prev, curr) => {
      return Math.max(prev, curr);
    }, 0);
  const maxValue = mxValue ? mxValue : 0;

  const length = `${maxValue}`.length - 1;

  const unit = maxValue <= 25 ? 5 : parseInt(1 + '0'.repeat(length));

  const yAxisTicks =
    unit === 5
      ? [5, 10, 15, 20, 25, 30]
      : R.unfold<number, number>((it) =>
          it > maxValue + 2 * unit ? false : [it, it + unit]
        )(unit);
  // console.log(datas, botDetails, 'datas');
  return (
    <ChartFeedbackWrapper>
      <ChartWrapper chartsBg>
        <ChatWrapperLeft chartsPadding>
          <div
            className={
              botDetails.reduce((acc, val) => (acc += val.total_query), 0) > 0
                ? 'bot-auto-resolved'
                : 'bot-auto-resolved display_inline'
            }
          >
            {botDetails.reduce((acc, val) => (acc += val.total_query), 0) >
            0 ? (
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={830}
                  height={320}
                  data={datas}
                  margin={{
                    top: 15,
                    right: 40,
                    left: -10,
                    bottom: 5,
                  }}
                  barCategoryGap={'35%'}
                  barGap={'10%'}
                >
                  <defs>
                    <linearGradient
                      id="colour-resolved"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="5%" stopColor="#74C1BC" stopOpacity={0.6} />
                      <stop offset="95%" stopColor="#70B2AD" stopOpacity={1} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid />
                  <XAxis
                    angle={45}
                    dy={5}
                    dx={12}
                    dataKey="name"
                    interval={0}
                    tickLine={true}
                    axisLine={false}
                    tickMargin={10}
                  >
                    <Label
                      value={
                        dateOptions == 'today' || dateOptions == 'yesterday'
                          ? 'Hours'
                          : 'Date'
                      }
                      position={'bottom'}
                      className={'xAxisLabel'}
                      offset={20}
                    />
                  </XAxis>
                  <YAxis
                    allowDecimals={false}
                    label={{
                      value: 'Unique Users',
                      angle: -90,
                      position: 'insideLeft',
                      offset: 20,
                      style: { textAnchor: 'middle' },
                    }}
                    axisLine={false}
                    tickLine={{ stroke: '#F2F2F2' }}
                    tickFormatter={(x: number) => {
                      return approx(x);
                    }}
                    ticks={[0, ...yAxisTicks]}
                  />
                  <ChartTip
                    isAnimationActive={false}
                    cursor={false}
                    content={<AreaTooltip />}
                  />
                  {/* <Legend /> */}
                  <Area
                    type="monotone"
                    dataKey="total_query"
                    stroke="#70B2AD"
                    fillOpacity={1}
                    fill="url(#colour-resolved)"
                    strokeWidth={3}
                    activeDot={{stroke:"#70B2AD",fill:"white"}}
                  />
                </AreaChart>
              </ResponsiveContainer>
            ) : (
              <NodataContent>
                No data available
              </NodataContent>
            )}
          </div>
        </ChatWrapperLeft>
      </ChartWrapper>
    </ChartFeedbackWrapper>
  );
};
