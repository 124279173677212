import styled from 'styled-components/macro'

import { Button } from './Dialog.BotDetails.Dumb'
import { Table, Tbody, Td, Th, Thead, Tr } from './Workflow.Analytics.Dumb'

export const ButtonWrapper = styled.div`
    width: 100%;
    float: left;
    display: flex;
    justify-content: center;
    flex-direction: ${props => (props.direction ? 'column' : 'row')};
    align-items: baseline;
    margin-top: ${props => (props.margin ? 10 : 0)}px;
    ${Button} {
        justify-content: center;
        :first-child {
            margin: 0px;
            margin-right: 20px;
        }
        :last-child {
            margin: 10px 0 0;
        }
    }
`
export const ButtonWrapperAlternate = styled.div`
    width: 100%;
    float: left;
    display: flex;
    justify-content: center;
    //flex-direction: ${props => (props.direction ? 'column' : 'row')};
    flex-direction: column-reverse;
    align-items: baseline;
    text-align: center;
    margin-top: ${props => (props.margin ? 20 : 0)}px;
    ${Button} {
        justify-content: center;
        :first-child {
            margin: 0px;
            margin-right: 0px;
        }
        :last-child {
            margin: 5px 0 0;
        }
    }
`
export const Paragraph = styled.h6`
    text-align: center;
    font-size: 12px;
    font-weight: normal;
    font-family: 'Gordita-Regular';
    margin-bottom: ${props => (props.margin ? '12px' : '10px')};
    color: #355d8b;
    line-height: 18px;
`
export const ModalPopupWrapper = styled.div`
    width: 100%;
    float: left;
`
export const TabelWrapper = styled.div`
    width: 100%;
    float: left;
    .list_flow_hr label {
        float: left;
        display: flex;
        position: absolute;
        top: 18px;
        width: 16px;
        height: 16px;
        margin-bottom: 0px;
        left: -22px;
    }
    .option-input.radio {
        top: 7px;
        position: absolute;
    }

    .entity_cards {
        overflow: hidden;
      }

    ${Table} {
        margin-top: 0px;
        background: none;
        box-shadow: none;
    }
    ${Thead} {
        ${Tr} {
            padding-left: 32px;
            padding-right: 20px;

            ${Th} {
                :last-child {
                    justify-content: flex-end;
                }
            }
        }
    }
    ${Tbody} {
        border-radius: 0px 0px 10px 10px;
        max-height: calc(100vh - 153px);
        height: auto;
        padding-bottom: 60px;
        ${Tr} {
            height: 48px;
            padding-left: 32px;
            padding-right: 20px;

            .list_flow_span .entity_span {
                :first-child {
                    margin-right: 3px;
                    color: #000;
                    font-size: 9px;
                }
                :last-child {
                    margin-left: 3px;
                    color: #000;
                    font-size: 10px;
                }
            }
            :hover {
                .count_list_no {
                    background: #fff;
                }
                ${Td}:last-child {
                    span {
                        display: flex;
                        padding: 0px;
                    }
                }
            }
            ${Td} {
                padding: 0px;
                height: 48px;
                font-size: 12px;
                font-family: 'Gordita-Regular';
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                color: #000;
                position: relative;
                span {
                    margin-right: 0px;
                }
                .count_list_no span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 0px;
                    position: relative;
                    top: 2px;
                }
                .count_list_no {
                    left: 0px;
                    margin-right: 6px;
                }

                :nth-child(1) {
                    display: flex;
                    padding-right: 20px;
                }

                :nth-child(2) {
                    display: flex;
                    padding-right: 20px;
                }
                :last-child {
                    span {
                        display: none;
                    }
                    span.error_icon_lv {
                        display: flex;
                    }
                }
            }
        }
        .list-full-width:last-child ${Tr} {
            border-radius: 0px 0px 10px 10px;
        }
    }
`
