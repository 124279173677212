import { Button, Input } from '@/common/styled/Dialog.BotDetails.Dumb';
import moment from 'moment';
import React, { useState } from 'react';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.css';
import DatePicker, { DateRange, DisabledDateFunction } from 'rsuite/esm/DateRangePicker';

const { afterToday } = DatePicker;

export default function BasicDateRangePicker(props: {
  setDate: (date: Date[]) => void;
}) {
  const [show, setShow] = useState(false);
  const [date, setDate] = useState<Date[]>([]);

  const _date = moment().toString(); 
  const _date2 = moment().date(1).toString();
  console.log(date, 'dateee1');
  return (
    <>
      <Input
        type="text"
        value={date[0]}
        // onClick={() => setShow(true)}
        style={{ width: '20%' }}
      />
      <Input
        type={'text'}
        value={date.length > 1 ? date[1] : ''}
        // onClick={() => setShow(true)}
        style={{ width: '20%' }}
      />

      <DateRangePicker
        ranges={[]}
        placement="bottomStart"
        format={"MM/dd/yyyy"} 
        defaultCalendarValue={[new Date(_date2), new Date(_date)]}
        onSelect={(d) => {
          console.log(date.length > 1, date, d, 'dateee');
          date.length > 1
            ? setDate([d])
            : setDate(d > date[0] ? [...date, d] : [d, ...date]);
        }}
        disabledDate={(afterToday as (() => DisabledDateFunction))()}
        showOneCalendar
        open={show}
        onChange={(e) => {
          console.log(e, 'dateee');
          setShow(false);
          props.setDate(e as DateRange);
        }}
      />
      <Button>Cancel</Button>
    </>
  );
}
