import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  ChartFeedbackWrapper,
  ChartWrapper,
  ChatWrapperLeft,
  CountNumber,
  CountWrapper,
  DropdownLi,
  DropdownLiSpan,
  DropdownUl,
  DropdownWrapper,
  HeaderContainer,
  Input,
  InputContent,
  NodataContent,
  TabCardContainer,
} from '@/common/styled/Dialog.BotDetails.Dumb';
import {
  AgentSummaryIcon,
  BotDropdown,
  InfoIcon,
  SlackIconCC,
  TeamsIcon,
  TriangleIcon,
  UserIconAnalyticsMenu,
  WidgetIcon,
} from '@/common/Icons/Icons';
import Tooltip from 'react-tooltip-lite';
import { BasiceDropdown, OverAllSummaryT } from './BotDetails';
import { AssistantT } from '../Construction/kind';
import {
  useFetchAreaBotSummary,
  useFetchAreaQuerychartBotSummary,
  useFetchBarchartBotSummary,
  useFetchLiveBotTabSummary,
  useFetchUserMetricsSummary,
  useUpdateAreaBotSummary,
  useUpdateAreaQueryBotSummary,
  useUpdateBarChartSummary,
} from './BotSummaryAPI';
import {
  convertFromToDate,
  formatAMPM,
  formatAreaChartDate,
  formatDateInCard,
} from '@/common/utils/utils';
import { areachartDates, barchartDates, returnDate } from './TabUIElements';
import Downshift from 'downshift';
import BasicDateRangePicker from './CustomDatePicker';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip as ChartTip,
  AreaChart,
  Label,
  Area,
} from 'recharts';
import * as R from 'ramda';
var approx = require('approximate-number');

const UserMetricsDetails = [
  {
    header: 'Total users',
    count: '850',
    hoverContent: 'Users who have initiated at least one bot session',
    image: <InfoIcon />,
  },
  {
    header: 'Frequent users',
    hoverContent: 'Users who have initiated more than one bot session',
    image: <InfoIcon />,
    count: '700',
  },
  {
    header: 'One-time users',
    count: '150',
    hoverContent: 'Users who have initiated only one bot session',
    image: <InfoIcon />,
  },
  {
    header: 'Avg. sessions per user',
    count: '10',
    hoverContent: 'Average number of bot sessions initiated by a user',
    image: <InfoIcon />,
  },
  {
    header: 'Avg. queries resolved per user',
    count: '20',
    hoverContent: 'Average number of user queries auto-resolved by bot',
    image: <InfoIcon />,
  },
];

type UniqueUserT = {
  date: string;
  unique_users: number;
};

type UserQuerT = {
  date: string;
  total_users: number;
  total_query: number;
};

type NewUserT = {
  date: string;
  return_user: number;
  new_user: number;
};

type UserMetricsTypeT = {
  total_user: number;
  frequent_user: number;
  one_time_user: number;
  total_unique_user: UniqueUserT[];
  user_vs_query: UserQuerT[];
  new_return_user: NewUserT[];
};

export function UserMetricsTab(props: {
  botId: string | undefined;
  workspace: string;
  overAllSummary: OverAllSummaryT;
  selectedBot: AssistantT;
  setTabLoading:(loading:boolean)=>void
}) {
  const { overAllSummary, setTabLoading } = props;

  const [dateOptions, setOptions] = useState('last30days')

  const {
    userMetricsSummary,
    userMetricsSummaryRetry,
    userMetricsSummaryStatus,
    dataUpdatedAt,
    isRefetching,
    isFetching
  } = useFetchUserMetricsSummary(
    props.workspace,
    props.botId ? props.botId : '',
    returnDate(dateOptions),
    {
      enabled: !!props.botId,
      onSuccess:(data:any)=>{
        setTabLoading(false)
      }
    }
  );

  const { areaSummary } = useFetchAreaBotSummary(
    props.workspace,
    props.botId ? props.botId : '',
    returnDate('last2months'),
    {
      enabled: !!props.botId,
    },
    'areausermetricssummary'
  );

  // const botsummaryday:UniqueUserT[] =[
  //   {
  //     date :"1",
  //     unique_users:2
  //   },
  //   {
  //     date :"2",
  //   unique_users:2
  //   },
  //   {
  //     date :"3",
  //   unique_users:2
  //   },
  //   {
  //     date :"4",
  //   unique_users:2
  //   },
  // ]
  const { barchartBotSummary } = useFetchBarchartBotSummary(
    props.workspace,
    props.botId ? props.botId : '',
    '30',
    {
      enabled: !!props.botId,
    },
    'barchartusermetricssummary'
  );

  const { areaQuerychartBotSummary } = useFetchAreaQuerychartBotSummary(
    props.workspace,
    props.botId ? props.botId : '',
    '30',
    {
      enabled: !!props.botId,
    },
    'areaqueryusermetricssummary'
  );

  // const { botSummaryMutate, botSummaryMutateStatus } = useUpdateBotSummary();

  const { barChartMutate } = useUpdateBarChartSummary(
    'barchartusermetricssummary'
  );

  const { areaChartMutate } = useUpdateAreaBotSummary('areausermetricssummary');

  const { areaQueryChartMutate } = useUpdateAreaQueryBotSummary(
    'areaqueryusermetricssummary'
  );


  const [state, setState] = useState({
    showBarChartDropDown: false,
    selectedBarchartDate: 'last30days',
    showAreaQueryChartDropDown: false,
    selectedAreaQueryDate: 'last30days',
    showAreaChartDropDown: false,
    selectedAreachartDate: 'last2months',
    customDatePickerShow: false,
    customDates: { from_date: null, to_date: null },
  });

  function barchartDate(key:string) {
    return barchartDates.filter(
      (it) => it.date === state[`${key}`]
    )[0].label;
  }

  function areachartDate() {
    return areachartDates.filter(
      (it) => it.date === state.selectedAreachartDate
    )[0].label;
  }

  useEffect(() => {
    barchartBotSummary &&
      (state.selectedBarchartDate !== 'customrange' ||
        (state.customDates.from_date != null &&
          state.customDates.to_date != null)) &&
      barChartMutate({
        botId: props.botId,
        workspace: props.workspace,
        date:
          state.selectedBarchartDate === 'customrange'
            ? state.customDates
            : returnDate(state.selectedBarchartDate),
      });
    state.selectedBarchartDate === 'customrange' &&
      setState({ ...state, customDatePickerShow: true });
  }, [state.selectedBarchartDate, state.customDates]);

  useEffect(() => {
    areaQuerychartBotSummary &&
      (state.selectedAreaQueryDate !== 'customrange' ||
        (state.customDates.from_date != null &&
          state.customDates.to_date != null)) &&
      areaQueryChartMutate({
        botId: props.botId,
        workspace: props.workspace,
        date:
          state.selectedAreaQueryDate === 'customrange'
            ? state.customDates
            : returnDate(state.selectedAreaQueryDate),
      });
    state.selectedAreaQueryDate === 'customrange' &&
      setState({ ...state, customDatePickerShow: true });
  }, [state.selectedAreaQueryDate, state.customDates]);

  useEffect(() => {
    areaSummary &&
      areaChartMutate({
        botId: props.botId,
        workspace: props.workspace,
        date: returnDate(state.selectedAreachartDate),
      });
  }, [state.selectedAreachartDate]);

  function retnFn(i: number) {
    if (!userMetricsSummary) {
      return 0;
    }

    const s = userMetricsSummary as UserMetricsTypeT;

    switch (i) {
      case 0:
        return s.total_user;
      case 1:
        return s.frequent_user;
      case 2:
        return s.one_time_user;
      case 3:
        return overAllSummary.avg_session_per_user;
      default:
        return overAllSummary.avg_query_resolved_per_user;
    }
  }


  return (
    <div className="bot_sessions_container">
       {isRefetching && 
       setTabLoading(true)
        // <div className='second_section_loader'>
        //     <Loader.PopupLoader show={true}/>
        // </div>
      }
      {isFetching && 
       setTabLoading(true)
      }
      <div className="bot_sessions_heaader">
        <div>
          <h5>Explore how users interact with the bot</h5>
        </div>
        <div className="d-flex align-items-center bot_sessions_heaader_color">
          <div className="report_content">
            This report was generated on{' '}<br/>
            {formatDateInCard((userMetricsSummary?new Date(dataUpdatedAt):new Date()).toString(), true)} at{' '}
            {formatAMPM((userMetricsSummary?new Date(dataUpdatedAt):new Date()).toString(), true)} -{' '}
            <span onClick={() =>{
              setTabLoading(true)
              userMetricsSummaryRetry()
            }}>Refresh</span>
          </div>
          <BasiceDropdown dateOptions={dateOptions} changeDateFilter={()=>{
              setTabLoading(true)
            }} setOptions={setOptions}/>
          </div>
      </div>
      <HeaderContainer>
        <TabCardContainer className="tab_user_queris_two">
          {UserMetricsDetails.map((data, i) => (
            <Card style={{ minHeight: 120 }}>
              <CardHeader>
                {data.header}{' '}
                <Tooltip
                  className="target_bot"
                  tipContentHover
                  zIndex={5000}
                  arrowSize={5}
                  tagName="span"
                  content={data.hoverContent}
                >
                  <span>{data.image}</span>
                </Tooltip>
              </CardHeader>
              <CountNumber>
                <CountWrapper>{retnFn(i)}</CountWrapper>
              </CountNumber>
            </Card>
          ))}
        </TabCardContainer>
      </HeaderContainer>

      <div className="tab_section_wrapper">
        <div className="tab_section_header">
          <div>
            <h5>Total Unique Users</h5>
            <p>Unique users interacting with the bot over a period of time</p>
          </div>
          <div className="tab_dropdown_date">
            {state.customDatePickerShow && (
              <BasicDateRangePicker
                setDate={(date: Date[]) => {
                  setState({
                    ...state,
                    customDates: {
                      from_date: convertFromToDate(date[0].toString()) as any,
                      to_date: convertFromToDate(date[1].toString()) as any,
                    },
                  });
                }}
              />
            )}
            <DropdownWrapper>
              <Input
                onClick={() =>
                  setState({ ...state, showBarChartDropDown: true })
                }
                onChange={() => {}}
                value={barchartDate("selectedBarchartDate")}
              />
              <InputContent>Show by</InputContent>
              <span
                onClick={() =>
                  setState({ ...state, showBarChartDropDown: true })
                }
              >
                <BotDropdown />
              </span>
              {state.showBarChartDropDown && (
                <Downshift
                  isOpen={true}
                  onOuterClick={() =>
                    setState({ ...state, showBarChartDropDown: false })
                  }
                >
                  {() => (
                    <div>
                      <DropdownUl>
                        {barchartDates.map((date, i) => (
                          <DropdownLi
                            key={i}
                            onClick={() => {
                              setState({
                                ...state,
                                selectedBarchartDate: date.date,
                                showBarChartDropDown: false,
                              });
                            }}
                          >
                            <DropdownLiSpan>{date.label}</DropdownLiSpan>{' '}
                          </DropdownLi>
                        ))}
                      </DropdownUl>
                    </div>
                  )}
                </Downshift>
              )}
            </DropdownWrapper>
          </div>
        </div>
        <div className="tab_section_graph">
          <ChartFeedbackWrapper>
            <ChartWrapper chartsBg>
              <ChatWrapperLeft chartsPadding>
                <div className="bar_charts_wrapper">
                  <BarCharts
                    day_wise_session_query_count={
                      barchartBotSummary ? barchartBotSummary : []
                    }
                    dateOptions={state.selectedBarchartDate}
                  />
                </div>
              </ChatWrapperLeft>
            </ChartWrapper>
          </ChartFeedbackWrapper>
        </div>
      </div>

      <div className="tab_section_wrapper">
        <div className="tab_section_header">
          <div>
            <h5>Users vs. Queries</h5>
            <p>Unique users who raised queries over a period of time</p>
          </div>
          <div className="tab_dropdown_date">
            {state.customDatePickerShow && (
              <BasicDateRangePicker
                setDate={(date: Date[]) => {
                  setState({
                    ...state,
                    customDates: {
                      from_date: convertFromToDate(date[0].toString()) as any,
                      to_date: convertFromToDate(date[1].toString()) as any,
                    },
                  });
                }}
              />
            )}
            <DropdownWrapper>
              <Input
                onClick={() =>
                  setState({ ...state, showAreaQueryChartDropDown: true })
                }
                onChange={() => {}}
                value={barchartDate("selectedAreaQueryDate")}
              />
              <InputContent>Show by</InputContent>
              <span
                onClick={() =>
                  setState({ ...state, showAreaQueryChartDropDown: true })
                }
              >
                <BotDropdown />
              </span>
              {state.showAreaQueryChartDropDown && (
                <Downshift
                  isOpen={true}
                  onOuterClick={() =>
                    setState({ ...state, showAreaQueryChartDropDown: false })
                  }
                >
                  {() => (
                    <div>
                      <DropdownUl>
                        {barchartDates.map((date, i) => (
                          <DropdownLi
                            key={i}
                            onClick={() => {
                              setState({
                                ...state,
                                selectedAreaQueryDate: date.date,
                                showAreaQueryChartDropDown: false,
                              });
                            }}
                          >
                            <DropdownLiSpan>{date.label}</DropdownLiSpan>{' '}
                          </DropdownLi>
                        ))}
                      </DropdownUl>
                    </div>
                  )}
                </Downshift>
              )}
            </DropdownWrapper>
          </div>
        </div>
        <div className="tab_section_graph">
          <ChartWithWrapper
            botDetails={
              areaQuerychartBotSummary ? areaQuerychartBotSummary : []
            }
            dateOptions={state.selectedAreaQueryDate}
          />
        </div>
      </div>

      <div className="tab_section_wrapper">
        <div className="tab_section_header">
          <div>
            <h5>New vs. Returning Users</h5>
            <p>Compare new users vs. returning users over a period of time</p>
          </div>
          <div className="tab_dropdown_date">
            <DropdownWrapper>
              <Input
                onClick={() =>
                  setState({ ...state, showAreaChartDropDown: true })
                }
                onChange={() => {}}
                value={areachartDate()}
              />
              <InputContent>Show comparision</InputContent>
              <span
                onClick={() =>
                  setState({ ...state, showAreaChartDropDown: true })
                }
              >
                <BotDropdown />
              </span>
              {state.showAreaChartDropDown && (
                <Downshift
                  isOpen={true}
                  onOuterClick={() =>
                    setState({ ...state, showAreaChartDropDown: false })
                  }
                >
                  {() => (
                    <div>
                      <DropdownUl>
                        {areachartDates.map((date, i) => (
                          <DropdownLi
                            key={i}
                            onClick={() => {
                              setState({
                                ...state,
                                selectedAreachartDate: date.date,
                                showAreaChartDropDown: false,
                              });
                            }}
                          >
                            <DropdownLiSpan>{date.label}</DropdownLiSpan>{' '}
                          </DropdownLi>
                        ))}
                      </DropdownUl>
                    </div>
                  )}
                </Downshift>
              )}
            </DropdownWrapper>
          </div>
        </div>
        <div className="tab_section_graph">
          <AreaChartWithWrapper
            areaDetails={areaSummary ? areaSummary : []}
            dateOptions={state.selectedAreachartDate}
          />
        </div>
      </div>
    </div>
  );
}

function BarCharts(props: {
  day_wise_session_query_count: UniqueUserT[];
  dateOptions: string;
}) {
  const BarchartTooltip = (props: any) => {
    // const { series, seriesIndex, dataPointIndex, w } = props
    // console.log("pLoad",props)

    const { active, payload, label } = props;
    if (active && payload && payload.length) {
      const pLoad = payload[0].payload;
      return (
        <div className="custom-tooltip">
          <p>
            Total unique users: <span style={{color:"#6AB5FF"}}>{pLoad.users ? pLoad.users : 0}</span>
          </p>
        </div>
      );
    }
    return null;
  };

  type ChartData = {
    name: string;
    users: number;
  };

  const { day_wise_session_query_count, dateOptions } = props;
  // console.log(day_wise_session_query_count,dateOptions,'unique')

  function filterDate() {
    return day_wise_session_query_count.map((chart) => {
      const date =
        dateOptions != 'today' && dateOptions != 'yesterday'
          ? chart.date.split('/')[0] + '/' + parseInt(chart.date.split('/')[1])
          : chart.date;
      return {
        name: date,
        users: chart.unique_users,
      };
    });
  }
  // const mock_uniqueUsers =[
  //   {
  //     name: '10/04/2023',
  //     users: 15,
  //   },
  //   {
  //     name: '12/04/2023',
  //     users: 25,
  //   },
  // ]
  const datas: ChartData[] = filterDate();

  const modifiedData = [
    {
      name: '',
      users: 0,
    },
    ...datas,
  ];

  const mxValue = modifiedData
    .map((x: ChartData) => x.users)
    .reduce((prev, curr) => {
      return Math.max(prev, curr);
    }, 0);

  const maxValue = mxValue ? mxValue : 0;

  const length = `${maxValue}`.length - 1;

  const unit = maxValue <= 25 ? 5 : parseInt(1 + '0'.repeat(length));

  const yAxisTicks =
    unit === 5
      ? [5, 10, 15, 20, 25, 30]
      : R.unfold<number, number>((it) =>
          it > maxValue + 2 * unit ? false : [it, it + unit]
        )(unit);
  const [position, setPosition] = useState({
    data: { x: 0, y: 0,height:0 },
    show: false,
  });
  return (
    // <div className="analytics_charts_left_img">
    <div
      style={{ width: '100%' }}
      className={
        datas.length > 0 ? 'bot_session_chart' : 'no_data_bot_session_chart'
      }
    >
      {datas.length > 0 ? (
        <div className="bot-summary-all-sessions">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={830}
              height={320}
              data={modifiedData}
              margin={{
                top: 15,
                right: 25,
                left: -10,
                bottom: 5,
              }}
              barCategoryGap={'35%'}
              barGap={'10%'}
            >
              <defs>
              <linearGradient id="sessionsclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                <stop offset='0%' stopColor='#6AB5FF' />
                <stop offset='14%' stopColor='#69B3FB' />
                <stop offset='100%' stopColor='#769DC3'/>
              </linearGradient>
              </defs>
              <CartesianGrid />
              <XAxis
                angle={(props.dateOptions === "today" || props.dateOptions ===  "yesterday") ? 0 : 45}
                dy={8}
                dx={(props.dateOptions === "today" || props.dateOptions ===  "yesterday") ? 0 :12}
                dataKey="name"
                interval={0}
                scale="point"
                axisLine={false}
                tickLine={false}
              />
              <YAxis
                allowDecimals={false}
                label={{
                  value: 'Unique Users',
                  angle: -90,
                  position: 'insideLeft',
                  offset: 16,
                  style: { textAnchor: 'middle' },
                }}
                axisLine={false}
                tickLine={{ stroke: '#F2F2F2' }}
                tickFormatter={(x: number) => {
                  return approx(x);
                }}
                ticks={[0, ...yAxisTicks]}
                tickMargin={5}
              />
              <ChartTip
                isAnimationActive={false}
                cursor={false}
                position ={(position.show? {x:position.data.x - 60, y:position.data.y - 35}: undefined)}
                content={
                  position.show ? (
                    <BarchartTooltip />
                  ) : (
                    <div style={{ display: 'none' }}></div>
                  )
                }
              />
              <Bar
                dataKey="users"
                stackId="a"
                fill="url(#sessionsclr)"
                // "#6AB5FF"
                barSize={10}
                radius={[10, 10, 0, 0]}
                isAnimationActive={false}
                onMouseOver={(data) => {
                  setPosition({ data: data, show: true });
                }}
                onMouseLeave={(data) => {
                  setPosition({ data : { x : 0 , y : 0,height:0 },show: false });
                }}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <NodataContent>No data available</NodataContent>
      )}
    </div>
    // </div>
  );
}

const AreaChartWithWrapper = (props: {
  areaDetails: NewUserT[];
  dateOptions: string;
}) => {
  const { areaDetails, dateOptions } = props;

  const AreaTooltip = (props: any) => {
    const { active, payload, label } = props;

    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip _resolved">
          <p className="tooltip_date">
            Total users:
            <span style={{color:"#6FA7DD"}}>
              {payload[0].payload.totalUsers ? payload[0].payload.totalUsers : 0}{' '}
              {payload[0].payload.index > 1 && payload[0].payload.totalUserPercentage>0 && (
                <p
                  className="custom_tooltip_persentage_color"
                  style={{
                    color: payload[0].payload.totalUserIncresed
                      ? '#70D397'
                      : '#D13737',
                  }}
                >
                  <span
                    className="custom_tooltip_persentage_img"
                    style={{
                      transform: payload[0].payload.totalUserIncresed
                        ? 'rotate(0deg)'
                        : 'rotate(180deg)',
                      fill: payload[0].payload.totalUserIncresed
                        ? '#70D397'
                        : '#D13737',
                    }}
                  >
                    <TriangleIcon />
                  </span>
                  {Math.round(payload[0].payload.totalUserPercentage) + '%'}
                </p>
              )}
            </span>
          </p>
          <p className="tooltip_date">
            New users:
            <span style={{color:"#333333"}}>
              {payload[0].payload.newUser ? payload[0].payload.newUser : 0}{' '}
              {payload[0].payload.index > 1 && payload[0].payload.newUserPercentage>0 && (
                <p
                  className="custom_tooltip_persentage_color"
                  style={{
                    color: payload[0].payload.newUserIncresed
                      ? '#70D397'
                      : '#D13737',
                  }}
                >
                  <span
                    className="custom_tooltip_persentage_img"
                    style={{
                      transform: payload[0].payload.newUserIncresed
                        ? 'rotate(0deg)'
                        : 'rotate(180deg)',
                      fill: payload[0].payload.newUserIncresed
                        ? '#70D397'
                        : '#D13737',
                    }}
                  >
                    <TriangleIcon />
                  </span>
                  {Math.round(payload[0].payload.newUserPercentage) + '%'}
                </p>
              )}
            </span>
          </p>
          <p className="tooltip_date">
            Return users:
            <span style={{color:"#333333"}}>
              {payload[0].payload.returnUser ? payload[0].payload.returnUser : 0}{' '}
              {payload[0].payload.index > 1 && payload[0].payload.returnUserPercentage>0 && (
                <p
                  className="custom_tooltip_persentage_color"
                  style={{
                    color: payload[0].payload.returnUserIncresed
                      ? '#70D397'
                      : '#D13737',
                  }}
                >
                  <span
                    className="custom_tooltip_persentage_img"
                    style={{
                      transform: payload[0].payload.returnUserIncresed
                        ? 'rotate(0deg)'
                        : 'rotate(180deg)',
                      fill: payload[0].payload.returnUserIncresed
                        ? '#70D397'
                        : '#D13737',
                    }}
                  >
                    <TriangleIcon />
                  </span>
                  {Math.round(payload[0].payload.returnUserPercentage) + '%'}
                </p>
              )}
            </span>
          </p>
        </div>
      );
    }
    return null;
  };

  const datas = areaDetails.reduce(
    (accum, chart, i) => {
      const date = formatAreaChartDate(chart.date);
      const calculate = (first: number, second: number) =>
        first < second
          ? Math.abs((second - first) / second)
          : Math.abs((first - second) / first);
      const newUserPercentage =
        i === 0 ? 100 : calculate(accum[i].newUser, chart.new_user) * 100;
      const returnUserPercentage =
        i === 0 ? 100 : calculate(accum[i].returnUser, chart.return_user) * 100;
      const totalUserPercentage =
        i === 0 ? 100 : calculate(accum[i].totalUsers, (chart.new_user+chart.return_user)) * 100;

      return [
        ...accum,
        {
          name: date,
          newUser: chart.new_user,
          returnUser: chart.return_user,
          totalUsers: chart.new_user + chart.return_user,
          newUserPercentage: isNaN(newUserPercentage) ? 0 : newUserPercentage,
          returnUserPercentage: isNaN(returnUserPercentage) ? 0 : returnUserPercentage,
          totalUserPercentage: isNaN(totalUserPercentage)?0:totalUserPercentage,
          index: i + 1,
          newUserIncresed: accum[i].newUser < chart.new_user,
          returnUserIncresed: accum[i].returnUser < chart.return_user,
          totalUserIncresed: accum[i].totalUsers < (chart.new_user+chart.return_user)
        },
      ] as any;
    },
    [
      {
        name: '',
        newUser: 0,
        returnUser: 0,
        totalUsers:0,
        newUserPercentage: 0,
        returnUserPercentage: 0,
        index: 0,
        newUserIncresed: false,
        returnUserIncresed: false,
      },
    ] as any
  ) as { name: string; newUser: number; returnUser: number; totalUsers:number }[];
  const modifiedData = datas;

  const mxValue = modifiedData
    .map((x) => x.totalUsers)
    .reduce((prev, curr) => {
      return Math.max(prev, curr);
    }, 0);

  const maxValue = mxValue ? mxValue : 0;

  const length = `${maxValue}`.length - 1;

  const unit = maxValue <= 25 ? 5 : parseInt(1 + '0'.repeat(length));

  const yAxisTicks =
    unit === 5
      ? [5, 10, 15, 20, 25, 30]
      : R.unfold<number, number>((it) =>
          it > maxValue + 2 * unit ? false : [it, it + unit]
        )(unit);

  return (
    <ChartFeedbackWrapper>
      <ChartWrapper chartsBg style={{ marginTop: 0 }}>
        <ChatWrapperLeft chartsPadding>
          {/* <Row>
              <Header>
                Bot Auto Resolved:{' '}
                {areaDetails.reduce(
                  (acc, val) =>
                    (acc += val.num_of_queries ? val.num_of_queries : 0),
                  0
                )}
              </Header>
            </Row> */}
          <div
            className={
              areaDetails.reduce((acc, val) => (acc += val.new_user), 0) > 0
                ? 'bot-auto-resolved returing_user'
                : 'bot-auto-resolved display_inline'
            }
          >
            {areaDetails.reduce((acc, val) => (acc += val.new_user), 0) > 0 ? (
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={830}
                  height={320}
                  data={datas}
                  margin={{
                    top: 15,
                    right: 40,
                    left: -10,
                    bottom: 5,
                  }}
                  barCategoryGap={'35%'}
                  barGap={'10%'}
                >
                  <defs>
                    <linearGradient
                      id="colour-resolved-1"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="5%" stopColor="#CCE6FF" stopOpacity={0.6} />
                      <stop offset="95%" stopColor="#81AEDD" stopOpacity={1} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid />
                  <XAxis
                    // angle={45}
                    dy={2}
                    dx={3}
                    dataKey="name"
                    interval={0}
                    tickLine={false}
                    axisLine={false}
                    tickMargin={5}
                  ></XAxis>
                  <YAxis
                    allowDecimals={false}
                    label={{
                      value: 'Users',
                      angle: -90,
                      position: 'insideLeft',
                      offset: 20,
                      style: { textAnchor: 'middle' },
                    }}
                    axisLine={false}
                    tickLine={{ stroke: '#F2F2F2' }}
                    tickFormatter={(x: number) => {
                      return approx(x);
                    }}
                    ticks={[0, ...yAxisTicks]}
                  />
                  <ChartTip
                    isAnimationActive={false}
                    cursor={false}
                    content={<AreaTooltip />}
                  />
                  <Area
                    type="linear"
                    dataKey="totalUsers"
                    stroke="#6FA7DD"
                    fillOpacity={1}
                    fill="url(#colour-resolved-1)"
                    strokeWidth={3}
                    activeDot={{stroke:"#6FA7DD",fill:"white"}}
                  />
                </AreaChart>
              </ResponsiveContainer>
            ) : (
              <NodataContent>
                No data available
              </NodataContent>
            )}
          </div>
        </ChatWrapperLeft>
      </ChartWrapper>
    </ChartFeedbackWrapper>
  );
};

const ChartWithWrapper = (props: {
  botDetails: UserQuerT[];
  dateOptions: string;
}) => {
  const { botDetails, dateOptions } = props;

  const AreaTooltip = (props: any) => {
    const { active, payload, label } = props;
    if (active && payload && payload.length && payload[0].payload.total_query) {
      return (
        <div className="custom-tooltip _resolved">
          <p>
            Total users:{' '}
            <span style={{color:"#6FA7DD"}}>
              {payload[0].payload.total_users
                ? payload[0].payload.total_users
                : 0}
            </span>
          </p>
          <p>
            User Queries:{' '}
            <span style={{color:"#63B7B1"}}>
              {payload[0].payload.total_query
                ? payload[0].payload.total_query
                : 0}{' '}
            </span>
          </p>
        </div>
      );
    }
    return null;
  };

  const datas = botDetails.reduce(
    (acc: any, chart) => {
      const date =
        dateOptions != 'today' && dateOptions != 'yesterday'
          ? chart.date.split('/')[0] +
            '/' +
            (parseInt(chart.date.split('/')[1]) < 10
              ? '0' + parseInt(chart.date.split('/')[1])
              : parseInt(chart.date.split('/')[1]))
          : chart.date;
      return {
        previousQueryvalue: acc.previousQueryvalue + chart.total_query,
        previousUservalue: acc.previousUservalue + chart.total_users,
        array: [
          ...acc.array,
          {
            name: date,
            total_query: chart.total_query,
            total_users: chart.total_users,
          },
        ],
      };
    },
    { previousQueryvalue: 0, previousUservalue: 0, array: [] }
  ).array as { name: string; total_query: number; total_users: number }[];

  const modifiedData = datas;

  const mxValue = modifiedData
    .map((x) => x.total_query)
    .reduce((prev, curr) => {
      return Math.max(prev, curr);
    }, 0);
  const maxValue = mxValue ? mxValue : 0;

  const length = `${maxValue}`.length - 1;

  const unit = maxValue <= 25 ? 5 : parseInt(1 + '0'.repeat(length));

  const yAxisTicks =
    unit === 5
      ? [5, 10, 15, 20, 25, 30]
      : R.unfold<number, number>((it) =>
          it > maxValue + 2 * unit ? false : [it, it + unit]
        )(unit);
  return (
    <ChartFeedbackWrapper>
      <ChartWrapper chartsBg>
        <ChatWrapperLeft chartsPadding>
          <div
            className={
              botDetails.reduce((acc, val) => (acc += val.total_query), 0) > 0
                ? 'bot-auto-resolved'
                : 'bot-auto-resolved display_inline'
            }
          >
            {botDetails.reduce((acc, val) => (acc += val.total_query), 0) >
            0 ? (
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={830}
                  height={320}
                  data={datas}
                  margin={{
                    top: 15,
                    right: 40,
                    left: -10,
                    bottom: 5,
                  }}
                  barCategoryGap={'35%'}
                  barGap={'10%'}
                >
                  <defs>
                    <linearGradient
                      id="colour-resolved-2"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="5%" stopColor="#D8FFD5" stopOpacity={0.6} />
                      <stop offset="95%" stopColor="#A1E29B" stopOpacity={1} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid />
                  <XAxis
                    angle={45}
                    dy={5}
                    dx={12}
                    dataKey="name"
                    interval={0}
                    tickLine={true}
                    axisLine={false}
                    tickMargin={10}
                  >
                    <Label
                      value={
                        dateOptions == 'today' || dateOptions == 'yesterday'
                          ? 'Hours'
                          : 'Date'
                      }
                      position={'bottom'}
                      className={'xAxisLabel'}
                      offset={20}
                    />
                  </XAxis>
                  <YAxis
                    allowDecimals={false}
                    label={{
                      value: 'Unique Users',
                      angle: -90,
                      position: 'insideLeft',
                      offset: 20,
                      style: { textAnchor: 'middle' },
                    }}
                    axisLine={false}
                    tickLine={{ stroke: '#F2F2F2' }}
                    tickFormatter={(x: number) => {
                      return approx(x);
                    }}
                    ticks={[0, ...yAxisTicks]}
                  />
                  <ChartTip
                    isAnimationActive={false}
                    cursor={false}
                    content={<AreaTooltip />}
                  />
                  {/* <Legend /> */}
                  <Area
                    type="monotone"
                    dataKey="total_query"
                    stroke="#63B7B1"
                    fillOpacity={1}
                    fill="url(#colour-resolved-2)"
                    strokeWidth={3}
                    activeDot={{stroke:"#63B7B1",fill:"white"}}
                  />
                </AreaChart>
              </ResponsiveContainer>
            ) : (
              <NodataContent>
                No data available
              </NodataContent>
            )}
          </div>
        </ChatWrapperLeft>
      </ChartWrapper>
    </ChartFeedbackWrapper>
  );
};
