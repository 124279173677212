import * as R from 'ramda'
import React, { useEffect, useState } from 'react'
import Tooltip from 'react-tooltip-lite'
import styled from 'styled-components/macro'

import { VideoButtonB } from '@/Videos/Videos'
import {
    AddIcon,
    CloseIcon,
    DeleteIcon,
    DownloadFromMarketplace,
    EditIcon,
    GroupIcon,
    InfoIcon,
    ListviewIcon,
    QaIconWithCircle,
    SearchIcon
} from '@/common/Icons/Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import Validate, { ErrorContext } from '@/common/components/FormValidate'
import { Header, HeaderChild } from '@/common/components/Header'
import InputBox from '@/common/components/InputBox'
import { Loader } from '@/common/components/Loader'
import { Modal } from '@/common/components/Modal'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import { ButtonWrapper, Paragraph } from '@/common/styled/Dialogue.Dumb'
import {
    ErrorMessage,
    Input,
    InputContainer,
    InputContent,
    InputWrapper,
    Table,
    TableContainer,
    Tbody,
    Td,
    Textarea,
    Th,
    Thead,
    Tr
} from '@/common/styled/Workflow.Analytics.Dumb'
import { flowValidations, inputTextAreaValidations, newLine, onPremTextAreaValidations } from '@/common/utils/_validation-utils'
import { postJson } from '@/common/utils/axios-utils'

import { formatAMPM, formatDateInCard } from '@/common/utils/utils'
import {
    AutomationOnPremApi,
    automationAPIURLWithWorkspace,
    deleteJson,
    getJson,
    putJson,
    wrapperGetJson,
    logEvent,
    getProductId
} from '@/common/utils/api-utils'
import { AppleIcon, LinkedDialogIcon, LinuxIcon, MicrosoftIcon, RefreshIcon, TokenIcon } from './Icons'
import {
    Add,
    AddT,
    Delete,
    Edit,
    ErrorPage,
    GridView,
    ListView,
    Loading,
    Mode,
    Modes,
    NoDataFound,
    NotInPro,
    UnAuthorized,
    View,
    ViewT,
    ViewTypeT,
    Views,
    onPremListResponseT
} from './onPremTypes'

const TabelWrapper = styled.div`
    width: 100%;
    float: left;
    .list_flow_hr label {
        float: left;
        display: flex;
        position: absolute;
        top: 18px;
        width: 16px;
        height: 16px;
        margin-bottom: 0px;
        left: -22px;
    }
    .entity_cards {
        overflow: hidden;
        height: calc(100vh - 70px);
    }
    .entity_cards._grid_view {
        overflow-y: scroll;
    }

    ${Table} {
        margin-top: 0px;
        background: none;
        box-shadow: none;
    }
    ${Thead} {
        ${Tr} {
            padding-left: 20px;
            padding-right: 20px;

            ${Th} {
                :nth-child(2) {
                    padding-right: 20px;
                }

                :nth-child(4) {
                    padding-left: 20px;
                }
                :last-child {
                    justify-content: flex-end;
                }
            }
        }
    }
    ${Tbody} {
        border-radius: 0px 0px 10px 10px;
        max-height: calc(100vh - 152px);
        height: auto;
        padding-bottom: 60px;

        .list-full-width {
            width: 100%;
            float: left;
            :last-child {
                ${Tr} {
                    border-radius: 0px 0px 10px 10px;
                }
            }
            :hover {
                :last-child {
                    ${Tr} {
                        border-radius: 0px 0px 10px 10px;
                    }
                }
            }
        }
        .list-view-icon span {
            width: auto;
            height: 18px;
            padding-right: 0px;
        }
        .automation_count_dilaog {
            font-size: 12px;
        }
        .error_input_show span {
            width: 180px;
        }

        .box_1 {
            padding: 0;
            p {
                color: #eb6453;
                margin-bottom: 0px;
            }
        }
        :hover ${Td} {
            :last-child {
                .list-view-icon span {
                    margin-left: 10px;
                }
                .list-view-icon span svg {
                    display: flex;
                }
                .list-view-icon span.no_input_value {
                    display: flex;
                    margin-right: 0px;
                }
            }
        }
        ${Tr} {
            .list_flow_span {
                flex-direction: row;
                span {
                    font-size: 9px;
                    margin: 3px;
                    color: #000;
                    :first-child {
                        margin: 0px;
                    }
                    :last-child {
                        margin: 0px;
                    }
                }
            }
            ${Td} {
                padding: 0px;
                height: 48px !important;
                font-size: 12px;
                font-family: 'Gordita-Regular';
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                color: #000;

                :nth-child(1) {
                    display: flex;
                    padding-right: 20px;
                    cursor: pointer;
                    position: relative;
                    font-family: 'Gordita-Medium';
                    position: relative;
                    padding-right: 20px;
                    div {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    label {
                        float: left;
                        display: -webkit-box;
                        display: -webkit-flex;
                        display: -ms-flexbox;
                        display: flex;
                        position: absolute;
                        top: 16px;
                        width: 16px;
                        height: 16px;
                        margin-bottom: 0px;
                        left: -22px;
                        visibility: visible;
                        margin: 0px;
                        .option-input.radio {
                            margin: 0px;
                            top: 0px;
                        }
                    }
                }
                :nth-child(2) {
                    padding-right: 20px;
                    div {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                }

                :nth-child(4) {
                    padding-left: 20px;
                }
                :last-child {
                    .list-view-icon span {
                        margin-left: 0px;
                    }
                    .list-view-icon span svg {
                        display: none;
                    }
                    .list-view-icon span.no_input_value {
                        display: flex;
                        margin-right: 10px;
                        svg {
                            display: flex;
                        }
                    }
                }
            }
        }
    }
`

const sortByNameCaseInsensitive = R.sortBy(R.compose(R.toLower, R.prop('name')))

export function OnPremAgent(props: any) {
    const [state, setState] = useState<Modes>(Loading)

    const [crudModes, setCrudModes] = useState<Modes>(View)

    const [view, setView] = useState<Views>(ListView)

    const [search, setSearch] = useState({ show: false, search: '' })

    let intervalId: null | NodeJS.Timeout | any = null

    useEffect(() => {
        const nextProps = props

        if (nextProps.state.type == 'loading') {
            setState(Loading)
        } else if (nextProps.state.type == 'error') {
            if (
                nextProps.state.error.response &&
                (nextProps.state.error.response.status == 401 || nextProps.state.error.response.status == 403)
            ) {
                setState(UnAuthorized)
                return
            } else {
                setState(ErrorPage)
                return
            }
        } else if (nextProps.state.type == 'success') {
            console.log('data', nextProps.state.data)
            const list = nextProps.state.data
            if (nextProps.state.data.hasOnPremAccess === false) {
                setState({ ...NotInPro })
            } else if (list.output.length > 0) {
                setState({ ...View, data: sortByNameCaseInsensitive(list.output) })
                intervalId = setInterval(() => {
                    NoDataFoundRetryAPI(true)
                }, 5000)
            } else {
                setState(NoDataFound)
            }
        }

        return () => {
            if (intervalId) clearInterval(intervalId)
        }
    }, [props.state])

    const NoDataFoundRetryAPI = (donotHandleError: boolean) => {
        wrapperGetJson(automationAPIURLWithWorkspace(props.match.params.workspacename, `${AutomationOnPremApi.GetAll}`))
            .then((response: any) => response.data)
            .then(data => {
                const prevState = state as ViewT
                if (!R.equals(prevState.data, sortByNameCaseInsensitive(data.output))) {
                    setState({
                        ...View,
                        data: sortByNameCaseInsensitive(data.output)
                    })
                    !donotHandleError && setCrudModes(View)
                }
            })
            .catch(err => {
                if (!donotHandleError) setState(ErrorPage)
            })
    }

    const { mode } = state

    const popupText = (mode: Modes): any => {
        if (mode.mode == Mode.Add || mode.mode === Mode.NoDataFound) {
            return {
                header: 'Create Connector',
                subHeader: 'Enter name and description',
                buttonText: 'Create'
            }
        } else if (mode.mode == Mode.Edit) {
            return {
                header: 'Edit Connector',
                subHeader: 'Enter name and description',
                buttonText: 'Save'
            }
        } else {
            return {
                header: 'Delete Connector',
                subHeader: 'Are you sure to delete this On-prem Connector?',
                buttonText: 'Save'
            }
        }
    }

    const saveAgent = () => {
        switch (crudModes.mode) {
            case Mode.Add:
                {
                    setCrudModes({ ...crudModes, loading: true })

                    const { name, description } = crudModes
                    postJson(
                        automationAPIURLWithWorkspace(
                            props.match.params.workspacename,
                            `${AutomationOnPremApi.Create}`
                        ),
                        {
                            name,
                            description
                        }
                    )
                        .then(resp => {
                            logEvent(props.match.params.workspacename, {
                                event:"EVENT::ON_PREM::CREATE_CONNECTOR", event_meta:{
                                    name,
                                    description
                                }
                            })
                            if (state.mode == Mode.NoDataFound) {
                                setState(Loading)
                                NoDataFoundRetryAPI(false)
                            } else {
                                const list = state as ViewT
                                const listUpdated = sortByNameCaseInsensitive(
                                    list.data.concat({
                                        ...resp.data.output,
                                        status: resp.data.output.status ? resp.data.output.status : 'offline',
                                        createdDate: new Date().toString(),
                                        modifiedDate: new Date().toString(),
                                        linkedWithAutomationList: [],
                                        connections: []
                                    })
                                )

                                setState({ ...list, data: listUpdated })
                                setCrudModes(View)
                            }
                        })
                        .catch(() => {
                            setCrudModes({
                                ...crudModes,
                                error: { error: true, info: 'Unable to create On-prem Connector! Please try again.' }
                            })
                        })
                }
                break
            case Mode.Edit:
                {
                    setCrudModes({ ...crudModes, loading: true })

                    const { name, description, id } = crudModes

                    putJson(
                        automationAPIURLWithWorkspace(
                            props.match.params.workspacename,
                            `${AutomationOnPremApi.Update}${id}/update`
                        )
                    )()({
                        name,
                        description
                    })
                        .then(resp => {
                            const list = state as ViewT
                            const listUpdated = sortByNameCaseInsensitive(
                                list.data.map(l => {
                                    if (l.id == id) {
                                        return { ...l, name, description }
                                    }
                                    return l
                                })
                            )
                            setState({ ...list, data: listUpdated })
                            setCrudModes(View)
                        })
                        .catch(() => {
                            setCrudModes({
                                ...crudModes,
                                error: { error: true, info: 'Unable to edit On-prem Connector! Please try again.' }
                            })
                        })
                }
                break
            case Mode.Delete: {
                setCrudModes({ ...crudModes, loading: true })

                const { id } = crudModes
                deleteJson(
                    automationAPIURLWithWorkspace(
                        props.match.params.workspacename,
                        `${AutomationOnPremApi.Update}${id}/delete`
                    )
                )()
                    .then(resp => {
                        const list = state as ViewT
                        const listUpdated = sortByNameCaseInsensitive(list.data.filter(l => l.id !== crudModes.id))
                        setState(listUpdated.length > 0 ? { ...list, data: listUpdated } : NoDataFound)
                        setCrudModes(View)
                    })
                    .catch(() => {
                        setCrudModes({
                            ...crudModes,
                            error: { error: true, info: 'Unable to delete On-prem Connector! Please try again.' }
                        })
                    })
            }
        }
    }

    const onNameChange = (name: string) => {
        const mode = crudModes as AddT
        setCrudModes({ ...mode, name: name })
    }

    const onDescriptionChange = (description: string) => {
        const mode = crudModes as AddT
        setCrudModes({ ...mode, description: description })
    }

    const resetToViewMode = () => {
        setCrudModes(View)
    }

    const enableSave = () => {
        if (crudModes.mode == Mode.Edit) {
            const list = state as ViewT
            const filtAgent = list.data.filter(l => l.id == crudModes.id)

            return filtAgent[0].name == crudModes.name && filtAgent[0].description == crudModes.description
        }

        return false
    }

    const refreshToken = (token: string, id: string) => {
        const list = state as ViewT
        const listUpdated = sortByNameCaseInsensitive(
            list.data.map(l => {
                if (l.id == id) {
                    return { ...l, token: token }
                }
                return l
            })
        )
        setState({ ...list, data: listUpdated })
    }

    const goToRoute = (id: string) => {
        props.history.push(
            `/workspace/${props.match.params.workspacename}/automation/onprem-agent/detail/${id.toLowerCase()}`
        )
    }

    const renderModal = () => {
        const { mode } = crudModes
        const head = popupText(crudModes)

        if (crudModes.mode == Mode.Add || crudModes.mode == Mode.Edit) {
            return (
                <Modal showPopupLoader={crudModes.loading}>
                    <form>
                        <ErrorBoundary
                            error={crudModes.error}
                            render={(err: any, info: any) => {
                                return (
                                    err && (
                                        <Error.AddorEdit
                                            onClick={() =>
                                                setCrudModes({ ...crudModes, error: { error: false, info: '' } })
                                            }
                                            info={info}
                                        />
                                    )
                                )
                            }}
                        >
                            <Validate
                                defaultValue={
                                    mode == Mode.Add || mode === Mode.NoDataFound
                                        ? { flowName: false }
                                        : { flowName: true }
                                }
                            >
                                <h2
                                    dangerouslySetInnerHTML={{
                                        __html: head.header
                                    }}
                                />
                                <Paragraph>{head.subHeader}</Paragraph>
                                <InputBox
                                    validationList={flowValidations}
                                    value={crudModes.name}
                                    name="flowName"
                                    render={(
                                        value: string,
                                        valid: boolean,
                                        errorMsg: string,
                                        startValidation: () => void,
                                        handleInput: Function
                                    ) => {
                                        return (
                                            <React.Fragment>
                                                <ErrorMessage>{!valid ? errorMsg : newLine}</ErrorMessage>
                                                <InputWrapper>
                                                    <InputContainer style={{marginBottom: 20}}>
                                                        <Input
                                                            type="text"
                                                            onChange={(event: any) => {
                                                                startValidation()
                                                                handleInput('flowName', event.target.value)
                                                                onNameChange(event.target.value)
                                                            }}
                                                            value={value}
                                                        />
                                                        <InputContent>Connector name</InputContent>
                                                    </InputContainer>
                                                </InputWrapper>
                                            </React.Fragment>
                                        )
                                    }}
                                />
                                <InputBox
                                    validationList={onPremTextAreaValidations}
                                    value={crudModes.description}
                                    name="flowDescription"
                                    render={(
                                        value: string,
                                        valid: boolean,
                                        errorMsg: string,
                                        startValidation: () => void,
                                        handleInput: Function
                                    ) => {
                                        return (
                                            <React.Fragment>
                                                <ErrorMessage>{!valid ? errorMsg : newLine}</ErrorMessage>
                                                <InputWrapper>
                                                    <InputContainer>
                                                        <Textarea
                                                            onChange={(event: any) => {
                                                                startValidation()
                                                                handleInput('flowDescription', event.target.value)
                                                                onDescriptionChange(event.target.value)
                                                            }}
                                                            value={value}
                                                        />
                                                        <InputContent>Connection description</InputContent>
                                                    </InputContainer>
                                                </InputWrapper>
                                            </React.Fragment>
                                        )
                                    }}
                                />
                                {mode == Mode.Add && crudModes.error ? (
                                    <p className="error_msg">{crudModes.error.info}</p>
                                ) : null}
                                <ButtonWrapper>
                                    <Button primary type="button" onClick={resetToViewMode}>
                                        <label>Cancel</label>
                                    </Button>
                                    <ErrorContext.Consumer>
                                        {valid => {
                                            let hasDisablebutton = !valid.formValid.formValid || enableSave()
                                            return (
                                                <Button
                                                    type="button"
                                                    onClick={() => {
                                                        saveAgent()
                                                    }}
                                                    disabled={hasDisablebutton}
                                                    className={hasDisablebutton ? 'editor_btn_disabled' : ''}
                                                >
                                                    <label>{head.buttonText}</label>
                                                </Button>
                                            )
                                        }}
                                    </ErrorContext.Consumer>
                                </ButtonWrapper>
                            </Validate>
                        </ErrorBoundary>
                    </form>
                </Modal>
            )
        } else if (crudModes.mode == Mode.Delete) {
            return (
                <Modal showPopupLoader={crudModes.loading}>
                    <ErrorBoundary
                        error={crudModes.error}
                        render={(err: any, info: any) => {
                            return (
                                err && (
                                    <Error.Delete
                                        onClick={() =>
                                            setCrudModes({ ...crudModes, error: { error: false, info: '' } })
                                        }
                                        info={info}
                                    />
                                )
                            )
                        }}
                    >
                        <h2>
                            Delete{' '}
                            <span style={{ color: '#ff7900', paddingLeft: '3px', fontFamily: 'Gordita-Medium' }}>
                                "{crudModes.name}"
                            </span>
                        </h2>
                        <Paragraph>Are you sure to delete selected On-prem Connector?</Paragraph>
                        <ButtonWrapper>
                            <Button primary type="button" onClick={resetToViewMode}>
                                <label>No</label>
                            </Button>
                            <Button type="button" onClick={saveAgent}>
                                <label>Yes</label>
                            </Button>
                        </ButtonWrapper>
                    </ErrorBoundary>
                </Modal>
            )
        }

        return null
    }

    if (mode == Mode.Loading) return <Loader.PageLoader show={true} />

    return (
        <div className="market_place">
            <ErrorBoundary
                unauthorized={state.mode == Mode.UnAuthorized}
                noDataFound={{
                    show: state.mode == Mode.NoDataFound || state.mode == Mode.NotPro,
                    noDatainfo:
                        state.mode == Mode.NotPro
                            ? '<p>Please upgrade to Professional plan to use this feature!'
                            : '<p>Zero, zilch, nada. Create an On-prem Connector now.',

                    onClick:
                        state.mode == Mode.NoDataFound
                            ? () => setCrudModes(Add)
                            : () => {
                                  props.history.push(
                                      `/workspace/${props.match.params.workspacename}/billing/plans?from=billing`
                                  )
                              },
                    btnName: state.mode == Mode.NotPro ? 'Upgrade Now' : 'Create one'
                }}
                error={
                    mode == Mode.Error
                        ? { error: true, info: 'There was a problem in loading Page! <br/> Please click on "Retry"' }
                        : { error: false, info: null }
                }
                render={(err: any, info: any) => {
                    return err && <Error.PageLoadingError onClick={() => props.retry()} info={info} btnName="Retry" />
                }}
            >
                <OnPremeHead
                    agentCount={state.mode == Mode.View ? state.data.length : 0}
                    showSearch={search.show}
                    setSearch={setSearch}
                    view={view}
                    setView={setView}
                    mode={state}
                    setToAddMode={() => setCrudModes(Add)}
                />
                <div className="entity_cards">
                    <div className="col-md-12">
                        {state.mode == Mode.View &&
                            (view.View == ViewTypeT.GridView ? (
                                <div className="row card_grid_view">
                                    {state.data
                                        .filter(e => e.name.toLowerCase().includes(search.search.toLowerCase()))
                                        .map((listData, i) => (
                                            <OnpremCard
                                                listData={listData}
                                                key={i}
                                                onClick={goToRoute}
                                                setToEdit={(card: onPremListResponseT) => {
                                                    setCrudModes({
                                                        ...Edit,
                                                        name: card.name,
                                                        description: card.description,
                                                        id: card.id
                                                    })
                                                }}
                                                setToDelete={(card: onPremListResponseT) => {
                                                    setCrudModes({
                                                        ...Delete,
                                                        name: card.name,
                                                        id: card.id
                                                    })
                                                }}
                                                refreshToken={refreshToken}
                                                workspacename={props.match.params.workspacename}
                                            />
                                        ))}
                                </div>
                            ) : (
                                <div className="onprem_agent">
                                    <div className="row">
                                        <TabelWrapper>
                                            <TableContainer>
                                                <Table>
                                                    <Thead>
                                                        <Tr style={{ height: 48 }}>
                                                            <Th size={2.5}>
                                                                <span>Name</span>
                                                            </Th>
                                                            {/* <Th className="col-md-2 intent_list_description">
                                                            <span>Description</span>
                                                        </Th> */}
                                                            <Th contentCenter size={2.5}>
                                                                <span>Linked With Workflows</span>
                                                            </Th>

                                                            <Th size={2}>
                                                                <span>Date & Time</span>
                                                            </Th>
                                                            <Th size={2}>
                                                                <div className="onprem_list_description">
                                                                    <span>Download Connector</span>
                                                                </div>
                                                            </Th>
                                                            <Th contentCenter size={1}>
                                                                <div className="onprem_list_description">
                                                                    <span>Token</span>
                                                                </div>
                                                            </Th>
                                                            <Th contentCenter size={1}>
                                                                <div className="onprem_list_description">
                                                                    <span>Status</span>
                                                                </div>
                                                            </Th>
                                                            <Th size={1}>
                                                                <div className="onprem_list_description">
                                                                    <span>Options</span>
                                                                </div>
                                                            </Th>
                                                        </Tr>
                                                    </Thead>

                                                    <Tbody>
                                                        {state.data
                                                            .filter(e =>
                                                                e.name
                                                                    .toLowerCase()
                                                                    .includes(search.search.toLowerCase())
                                                            )
                                                            .map((listData, i) => (
                                                                <OnpremListView
                                                                    listData={listData}
                                                                    key={i}
                                                                    onClick={goToRoute}
                                                                    setToEdit={(card: onPremListResponseT) => {
                                                                        setCrudModes({
                                                                            ...Edit,
                                                                            name: card.name,
                                                                            description: card.description,
                                                                            id: card.id
                                                                        })
                                                                    }}
                                                                    setToDelete={(card: onPremListResponseT) => {
                                                                        setCrudModes({
                                                                            ...Delete,
                                                                            name: card.name,
                                                                            id: card.id
                                                                        })
                                                                    }}
                                                                    refreshToken={refreshToken}
                                                                    workspacename={props.match.params.workspacename}
                                                                />
                                                            ))}
                                                    </Tbody>
                                                </Table>
                                            </TableContainer>
                                        </TabelWrapper>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </ErrorBoundary>
            {renderModal()}
        </div>
    )
}

function OnPremeHead(props: {
    agentCount: number
    showSearch: boolean
    setSearch: any
    view: Views
    setView: any
    mode: Modes
    setToAddMode: any
}) {
    const { agentCount, showSearch, setSearch, setToAddMode } = props

    const renderViewButton = () => {
        let { view, setView, mode } = props

        return mode.mode === Mode.View ? (
            view.View === ViewTypeT.GridView ? (
                <React.Fragment>
                    <div className="flow_sortby_dropdown" onClick={() => setView(ListView)}>
                        <button className="btn btn-primary">
                            <ListviewIcon />
                            <span>List view</span>
                        </button>
                    </div>
                </React.Fragment>
            ) : (
                <div className="flow_sortby_dropdown" onClick={() => setView(GridView)}>
                    <button className="btn btn-primary">
                        <GroupIcon />
                        <span>Card view</span>
                    </button>
                </div>
            )
        ) : null
    }
    const OnPremTips = () => (
        <>
        {getProductId() === "ASSISTANT" ? (
            <>
                <p>
                Use{' '}
                <a
                    className="sidepanel_href_color"
                    target="_blank"
                    href="https://help.workativ.com/knowledgebase/on-prem-connect/"
                >
                    On-prem Connector
                </a>{' '}
                to connect your bot with your on-prem app. On-prem Connector is a secure connector software that you can
                install on your server to connect workativ with your onprem apps behind your firewall to execute
                workflows, without whitelisting workativ’s IP address. Each on-prem connector has an access token that
                should be used when installing the on-prem connect on your machine. The connector connects back to
                workativ and establishes a reverse SSH tunnel allowing secure communication through your firewall
                without any need of whitelisting IPs in your firewall settings.
                </p>
            </>
         ) : ( 
             <>
                <p>
                Use{' '}
                <a
                    className="sidepanel_href_color"
                    target="_blank"
                    href="https://help.workativ.com/knowledgebase/on-prem-connect-2/"
                >
                    On-prem Connector
                </a>{' '}
                to connect with your on-prem app. On-prem Connector is a secure connector software that you can
                install on your server to connect workativ with your onprem apps behind your firewall to execute
                workflows, without whitelisting workativ’s IP address. Each on-prem connector has an access token that
                should be used when installing the on-prem connect on your machine. The connector connects back to
                workativ and establishes a reverse SSH tunnel allowing secure communication through your firewall
                without any need of whitelisting IPs in your firewall settings.
                </p>
             </>
         ) }
        </>
    )

    return (
        <div className="marketplace_head">
        {getProductId() === "ASSISTANT" ? ( 
            <Header>
            <HeaderChild type="first">
                <div className="indent_left">
                    <div className="indent_title onprem_header">
                        On-prem Connector
                        <Tooltip
                            className="target customTip"
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content={<OnPremTips />}
                            distance={5}
                        >
                            <InfoIcon />
                        </Tooltip>
                    </div>
                    <div className="indent_sub_title">On-prem Connector ({agentCount})</div>
                </div>
                <Button primary onClick={setToAddMode}>
                    <span className="header_button">
                        <AddIcon />
                    </span>
                    <label>Create New</label>
                </Button>
                {/* <Tooltip
                    className="target customTip __button"
                    zIndex={10000}
                    arrowSize={8}
                    tagName="span"
                    content="content"
                    distance={5}
                    direction="right"
                >
                    <InfoIcon />
                </Tooltip> */}
            </HeaderChild>
            <HeaderChild type="second">
                {/* {renderViewButton()} */}
                {!showSearch ? (
                    <span className="search_icon_svg">
                        <input
                            type="search"
                            placeholder="Search"
                            value=""
                            onFocus={() => setSearch({ search: '', show: true })}
                        />
                        <SearchIcon />
                    </span>
                ) : (
                    <span className="search_click">
                        <input
                            className="search_click"
                            type="search"
                            placeholder="Search"
                            autoFocus={true}
                            onChange={e => setSearch({ search: e.target.value, show: true })}
                        />
                        <span onClick={() => setSearch({ search: '', show: false })}>
                            <CloseIcon />
                        </span>
                    </span>
                )}
                <VideoButtonB id={'CREATE_ON_PREM_APP_CONNECTION'} />
            </HeaderChild>
            </Header>
        ) : (
            <Header>
                <HeaderChild type="first">
                    <div className="indent_left">
                        <div className="indent_title onprem_header">
                            On-prem Connector
                            <Tooltip
                                className="target customTip"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content={"Help document"}
                                distance={5}
                            >
                                <a
                                target="_blank"
                                href="https://help.workativ.com/knowledgebase/on-prem-connect-2/"
                                >
                                    <QaIconWithCircle />
                                </a>
                            </Tooltip>
                            <Tooltip
                                className="target customTip"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content={<OnPremTips />}
                                distance={5}
                            >
                                <InfoIcon />
                            </Tooltip>
                            
                        </div>
                        <div className="indent_sub_title">On-prem Connector ({agentCount})</div>
                    </div>
                    <Button primary onClick={setToAddMode}>
                        <span className="header_button">
                            <AddIcon />
                        </span>
                        <label>Create New</label>
                    </Button>
                    {/* <Tooltip
                        className="target customTip __button"
                        zIndex={10000}
                        arrowSize={8}
                        tagName="span"
                        content="content"
                        distance={5}
                        direction="right"
                    >
                        <InfoIcon />
                    </Tooltip> */}
                </HeaderChild>
                <HeaderChild type="second">
                    {/* {renderViewButton()} */}
                    {!showSearch ? (
                        <span className="search_icon_svg">
                            <input
                                type="search"
                                placeholder="Search"
                                value=""
                                onFocus={() => setSearch({ search: '', show: true })}
                            />
                            <SearchIcon />
                        </span>
                    ) : (
                        <span className="search_click">
                            <input
                                className="search_click"
                                type="search"
                                placeholder="Search"
                                autoFocus={true}
                                onChange={e => setSearch({ search: e.target.value, show: true })}
                            />
                            <span onClick={() => setSearch({ search: '', show: false })}>
                                <CloseIcon />
                            </span>
                        </span>
                    )}
                    <VideoButtonB id={'CREATE_ON_PREM_APP_CONNECTION'} />
                </HeaderChild>
            </Header>
        ) }

            {/* <Header>
                <HeaderChild type="first">
                    <div className="indent_left">
                        <div className="indent_title onprem_header">
                            On-prem Connector
                            <Tooltip
                                className="target customTip"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content={<OnPremTips />}
                                distance={5}
                            >
                                <InfoIcon />
                            </Tooltip>
                        </div>
                        <div className="indent_sub_title">On-prem Connector ({agentCount})</div>
                    </div>
                    <Button primary onClick={setToAddMode}>
                        <span className="header_button">
                            <AddIcon />
                        </span>
                        <label>Create New</label>
                    </Button>
                    {/* <Tooltip
                        className="target customTip __button"
                        zIndex={10000}
                        arrowSize={8}
                        tagName="span"
                        content="content"
                        distance={5}
                        direction="right"
                    >
                        <InfoIcon />
                    </Tooltip> 
                </HeaderChild>
                <HeaderChild type="second">
                    {/* {renderViewButton()} 
                    {!showSearch ? (
                        <span className="search_icon_svg">
                            <input
                                type="search"
                                placeholder="Search"
                                value=""
                                onFocus={() => setSearch({ search: '', show: true })}
                            />
                            <SearchIcon />
                        </span>
                    ) : (
                        <span className="search_click">
                            <input
                                className="search_click"
                                type="search"
                                placeholder="Search"
                                autoFocus={true}
                                onChange={e => setSearch({ search: e.target.value, show: true })}
                            />
                            <span onClick={() => setSearch({ search: '', show: false })}>
                                <CloseIcon />
                            </span>
                        </span>
                    )}
                    <VideoButtonB id={'CREATE_ON_PREM_APP_CONNECTION'} />
                </HeaderChild>
            </Header> */}
        </div>
    )
}

function showToken(
    token: string,
    closePopup: any,
    refreshCurrentToken: any,
    op: { loading: boolean } | { error: boolean },
    setOp: React.Dispatch<
        React.SetStateAction<
            | {
                  loading: boolean
              }
            | {
                  error: boolean
              }
        >
    >
) {
    const loadingOperation = op as { loading: boolean }
    const errorOperation = op as { error: boolean }

    return (
        <Modal className='onPrem_popup' showPopupLoader={loadingOperation.loading && true}>
            <ErrorBoundary
                error={
                    errorOperation.error
                        ? { error: true, info: 'Unable to refresh token! Please try again.' }
                        : { error: false, info: null }
                }
                render={(err: any, info: any) => {
                    return (
                        err && (
                            <Error.Delete
                                onClick={() => {
                                    setOp({ error: false })
                                }}
                                info={info}
                            />
                        )
                    )
                }}
            >
                <h2>On-prem Connector Token</h2>
                <div className="onprem_token_wrap_container">
                    <div className="token_wrap onprem_token_wrap">
                        <InputWrapper>
                            <InputContainer>
                                <Input
                                    style={{ padding: '0px 0px 0px 14px' }}
                                    type="text"
                                    onChange={(event: any) => {}}
                                    disabled
                                    value={token}
                                />
                                <span onClick={refreshCurrentToken} className="refresh_btn">
                                    <RefreshIcon />
                                    <span className="side_menu_hvr">Refresh</span>
                                </span>
                                {/* <InputContent>Automation Name</InputContent> */}
                            </InputContainer>
                        </InputWrapper>
                    </div>
                    <Button primary type="button" onClick={closePopup}>
                        <label className="otp_popup_label">Close</label>
                    </Button>
                </div>
            </ErrorBoundary>
        </Modal>
    )
}

function downloadFile(workspacename: string) {
    getJson(automationAPIURLWithWorkspace(workspacename, `${AutomationOnPremApi.DownloadApp}`) as string).then(res => {
        window.open(res.output.url)
    })
}
function downloadFileLinux(workspacename: string) {
    getJson(automationAPIURLWithWorkspace(workspacename, `${AutomationOnPremApi.DownloadApp}/linux`) as string).then(
        res => {
            logEvent(workspacename, {
                event:"EVENT::ON_PREM::DOWNLOAD_INSTALLER", event_meta:{
                    
                }
            })
            window.open(res.output.url)
        }
    )
}
function downloadFileMac(workspacename: string) {
    getJson(automationAPIURLWithWorkspace(workspacename, `${AutomationOnPremApi.DownloadApp}/mac`) as string).then(
        res => {
            logEvent(workspacename, {
                event:"EVENT::ON_PREM::DOWNLOAD_INSTALLER", event_meta:{
                   
                }
            })
            window.open(res.output.url)
        }
    )
}
function downloadFileWindows(workspacename: string) {
    getJson(automationAPIURLWithWorkspace(workspacename, `${AutomationOnPremApi.DownloadApp}/windows`) as string).then(
        res => {
            logEvent(workspacename, {
                event:"EVENT::ON_PREM::DOWNLOAD_INSTALLER", event_meta:{
                    
                }
            })
            window.open(res.output.url)
        }
    )
}

function OnpremCard(props: {
    listData: onPremListResponseT
    setToEdit: (list: onPremListResponseT) => void
    setToDelete: (list: onPremListResponseT) => void
    refreshToken: (token: string, id: string) => void
    onClick: (id: string) => void
    workspacename: string
}) {
    const [token, setShowToken] = useState<null | string>(null)
    const [op, setOp] = useState<{ loading: boolean } | { error: boolean }>({ loading: false })
    const { listData, setToEdit, setToDelete, refreshToken, workspacename, onClick } = props

    const refreshCurrentToken = () => {
        setOp({ loading: true })
        putJson(
            automationAPIURLWithWorkspace(workspacename, `${AutomationOnPremApi.Update}${listData.id}/refresh-token`)
        )()({})
            .then(resp => {
                setOp({ loading: false })
                setShowToken(resp.data.output.token)
                refreshToken(resp.data.output.token, listData.id)
            })
            .catch(() => {
                setOp({ error: true })
            })
    }

    return (
        <div className="col-3 dialog_grids onprem_grids">
            <div className="entity_cards_layer">
                <div className="entity_hvr">
                    <span className="start_stop_triggers">
                        <label className="agent_active">
                            <div
                                className={listData.status == 'offline' ? 'agent_active_off' : 'agent_active_on'}
                            ></div>
                        </label>
                    </span>
                    <span className="home_edit" onClick={() => setToDelete(listData)}>
                        <DeleteIcon />
                    </span>
                    <span className="home_edit" onClick={() => setToEdit(listData)}>
                        <EditIcon />
                    </span>
                    <span
                        className="home_edit"
                        onClick={() => {
                            setShowToken(listData.token)
                        }}
                    >
                        <TokenIcon />
                    </span>

                    <div className="icon_content">
                        <div className="icon_content_hover">Token</div>
                    </div>
                </div>
                <div className="automation_card">
                    <div className="search-card-title flow_create_new" onClick={() => onClick(listData.id)}>
                        <h5>{listData.name}</h5>
                        <p>{listData.description}</p>
                    </div>
                    <div className="autoamtion_connect_dialog">
                        <h6>
                            <span>
                                <LinkedDialogIcon />
                            </span>
                            Linked with Automations{' '}
                            <span className="automation_count_dilaog">
                                ({listData.linkedWithAutomationList.length})
                            </span>{' '}
                        </h6>{' '}
                    </div>
                    <div className="flow_entity_span">
                        <div className="flow_span_updated">
                            {/* <span className="entity_span">Last Updated:</span> */}
                            <span className="entity_span">{formatDateInCard(listData.modifiedDate)}</span>
                            <span className="entity_span">{formatAMPM(listData.modifiedDate)}</span>
                        </div>
                        <div className="flow_icon_download">
                            <span className="icon_entity_span">
                                <DownloadFromMarketplace />
                            </span>
                            <div className="flow_icon_download_hover">
                                <span className="os_icons" onClick={() => downloadFileWindows(workspacename)}>
                                    <MicrosoftIcon />
                                </span>
                                <span className="os_icons" onClick={() => downloadFileLinux(workspacename)}>
                                    <LinuxIcon />
                                </span>
                                <span className="os_icons" onClick={() => downloadFileMac(workspacename)}>
                                    <AppleIcon />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {token && showToken(token, () => setShowToken(null), refreshCurrentToken, op, setOp)}
        </div>
    )
}

function OnpremListView(props: {
    listData: onPremListResponseT
    setToEdit: (list: onPremListResponseT) => void
    setToDelete: (list: onPremListResponseT) => void
    refreshToken: (token: string, id: string) => void
    onClick: (id: string) => void
    workspacename: string
}) {
    const [token, setShowToken] = useState<null | string>(null)

    const [op, setOp] = useState<{ loading: boolean } | { error: boolean }>({ loading: false })
    const { listData, setToEdit, setToDelete, refreshToken, workspacename, onClick } = props

    const refreshCurrentToken = () => {
        setOp({ loading: true })
        putJson(
            automationAPIURLWithWorkspace(workspacename, `${AutomationOnPremApi.Update}${listData.id}/refresh-token`)
        )()({})
            .then(resp => {
                setOp({ loading: false })
                setShowToken(resp.data.output.token)
                refreshToken(resp.data.output.token, listData.id)
            })
            .catch(() => {
                setOp({ error: true })
            })
    }

    return (
        <>
            <div className="list-full-width">
                <div className="col-md-12 pl-0 pr-0">
                    <div className="main_sub">
                        <Tr style={{ height: 48 }}>
                            <Td size={2.5} className="list_flow_hr" onClick={() => onClick(listData.id)}>
                                <div className="search_card_list">
                                    <h5 style={{ paddingTop: 0 }}>{listData.name}</h5>
                                </div>
                            </Td>

                            <Td
                                contentCenter
                                size={2.5}
                                className="list_flow_p autoamtion_connect_dialog onprem_align_center"
                            >
                                <p className="listite_centre" />
                                <h6 className="listite_centre autoamtion_connect_dialog_listview">
                                    {/* <span>
                                        <LinkedDialogIcon />
                                    </span> */}
                                    <span className="automation_count_dilaog">
                                        ({listData.linkedWithAutomationList.length})
                                    </span>
                                </h6>
                                <p />
                            </Td>
                            <Td size={2} className="list_flow_span">
                                <span className="entity_span">{formatDateInCard(listData.modifiedDate)}</span>
                                <span>|</span>
                                <span className="entity_span">{formatAMPM(listData.modifiedDate)}</span>
                            </Td>
                            <Td size={2} className="onprem_list_header_icons onprem_align_center">
                                <div className="onprem-list-view-icon">
                                    <span onClick={() => downloadFileWindows(workspacename)}>
                                        <MicrosoftIcon />
                                    </span>
                                    <span onClick={() => downloadFileLinux(workspacename)}>
                                        <LinuxIcon />
                                    </span>
                                    <span onClick={() => downloadFileMac(workspacename)}>
                                        <AppleIcon />
                                    </span>
                                </div>
                            </Td>
                            <Td size={1} className="onprem_list_header_icons onprem_align_center">
                                <div className="onprem-list-view-icon-status ">
                                    <span
                                        className="m-0 p-0"
                                        onClick={() => {
                                            setShowToken(listData.token)
                                        }}
                                    >
                                        <Tooltip
                                            className="target customTip"
                                            zIndex={10000}
                                            arrowSize={8}
                                            tagName="span"
                                            content="Token"
                                            distance={14}
                                        >
                                            <TokenIcon />
                                        </Tooltip>
                                    </span>
                                </div>{' '}
                            </Td>
                            <Td size={1} className="onprem_list_header_icons onprem_align_center">
                                <div className="onprem-list-view-icon-status ">
                                    <span className="start_stop_triggers m-0 p-0">
                                        <label className="agent_active">
                                            <div
                                                className={
                                                    listData.status == 'offline'
                                                        ? 'agent_active_off'
                                                        : 'agent_active_on'
                                                }
                                            ></div>
                                        </label>
                                        <div
                                            className="agent_live"
                                            style={
                                                listData.status == 'offline'
                                                    ? { color: '#eb6453' }
                                                    : { color: '#1cb75e' }
                                            }
                                        >
                                            {listData.status == 'offline' ? 'Offline' : 'Online'}
                                        </div>
                                    </span>
                                    {/* <div className="popup_show_hide" style={{ cursor: 'default' }}>
                                        <div className="show_hide">
                                            <div className="switch_box box_1">
                                                <input
                                                    style={{ cursor: 'default' }}
                                                    type="checkbox"
                                                    className={
                                                        listData.status == 'offline'
                                                            ? 'switch_1 agent_active_off'
                                                            : 'switch_1 agent_active_on'
                                                    }
                                                    checked={!(listData.status == 'offline')}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                </div>{' '}
                            </Td>
                            <Td size={1} className="onprem_list_header_icons edit_delete_icons">
                                <div className="onprem-list-view-icon-status ">
                                    <span className="m-0 p-0" onClick={() => setToEdit(listData)}>
                                        <EditIcon />
                                    </span>
                                    <span className="m-0 p-0" onClick={() => setToDelete(listData)}>
                                        <DeleteIcon />
                                    </span>
                                </div>{' '}
                            </Td>
                        </Tr>
                    </div>
                </div>
                {token && showToken(token, () => setShowToken(null), refreshCurrentToken, op, setOp)}
            </div>
        </>
    )
}
