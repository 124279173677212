import { ActionT } from '../kind'

export interface SynonymsValueT {
    id: string
    type: 'synonyms'
    value: string
    synonyms: string[]
    updated: string
}

export interface PatternsValueT {
    id: string
    type: 'patterns'
    value: string
    patterns: string[]
    updated: string
}

export interface SynonymsValueRequestT {
    type: 'synonyms'
    value: string
    synonyms: string[]
}

export interface PatternsValueRequestT {
    type: 'patterns'
    value: string
    patterns: string[]
}
export type EntityValueRequestT = SynonymsValueRequestT | PatternsValueRequestT

export type EntityValueT = SynonymsValueT | PatternsValueT

export interface EntityT {
    id: string
    entity: string
    description: string
    fuzzy_match: boolean
    created: string
    updated: string
    values: EntityValueT[]
    is_system_entity: boolean
}

export const defaultEntity: EntityT = {
    id: 'DUMP',
    entity: '',
    description: '',
    fuzzy_match: false,
    created: '',
    updated: '',
    values: [],
    is_system_entity: false
}

export interface VIEW_T {
    action: ActionT.VIEW
}

export const VIEW: VIEW_T = {
    action: ActionT.VIEW
}

export interface LOADING_T {
    action: ActionT.LOADING
}

export const LOADING: LOADING_T = {
    action: ActionT.LOADING
}

export interface UN_AUTHORIZED_T {
    action: ActionT.UN_AUTHORIZED
}

export interface RESOURCE_NOT_FOUND_T {
    action: ActionT.RESOURCE_NOT_FOUND
    resource: 'WORKSPACE' | 'ASSISTANT' | 'ENTITY'
}

export interface NO_DATA_FOUND_T {
    action: ActionT.NO_DATA_FOUND
    entity: string
    description: string
    showAddPopup: boolean
}

export interface ERROR_T {
    action: ActionT.ERROR
    reason: string
}

export interface ADD_T {
    action: ActionT.ADD
    entity: string
    description: string
}

export interface EDIT_T {
    action: ActionT.EDIT
    id: string
    entity: string
    description: string
}

export interface DELETE_T {
    action: ActionT.DELETE
    data: EntityT
}

export interface MULTIPLE_DELETE_T {
    action: ActionT.MULTIPLE_DELETE
    items: string[]
}

export interface SELECT_T {
    action: ActionT.SELECT
    items: string[]
}

export type PageStateT =
    | VIEW_T
    | LOADING_T
    | UN_AUTHORIZED_T
    | RESOURCE_NOT_FOUND_T
    | NO_DATA_FOUND_T
    | ERROR_T
    | ADD_T
    | EDIT_T
    | DELETE_T
    | MULTIPLE_DELETE_T
    | SELECT_T
