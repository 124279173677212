import produce from 'immer'
import * as R from 'ramda'
/*eslint-disable*/
import React, { createContext, useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components/macro'

import { useJourney } from '@/Journey/JourneyContext'
import { VideoButtonB } from '@/Videos/Videos'
import {
    ApptriggerIcon,
    ArrowIcon,
    DialogIcon,
    EditIconWithCircle,
    ExecutionReportIcon,
    FlowCanvasIcons,
    FlowIcon,
    RightArrowWithOutCircle,
    SaveAutomation,
    TestAutomationIcon,
    TimerNodeIcon,
    UploadToMarketplace,
    WebhookFirstStepIcon,
    WebhookIcon,
    WebhooksIcon
} from '@/common/Icons/Icons'
import {
    ApprovalIcon,
    FunctionNodeIcon,
    IfNodeIcon,
    NotificationNodeIcon,
    NoTriggerIcon,
    WarningAlertIcon
} from '@/common/Icons/Workflow.Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { FlowCanvasHeader, HeaderChild, WorkflowHeaderChild } from '@/common/components/Header'
import { Loader } from '@/common/components/Loader'
import { Modal } from '@/common/components/Modal'
import arrow_icon from '@/common/images/arrow_icon.svg'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import { SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { ButtonWrapper } from '@/common/styled/Dialogue.Dumb'
import { IconButton } from '@/common/styled/Entity.Dumb'

import { generateUniqueId } from '@/common/utils/utils'
import {
    AutomationApi,
    MarketPlaceApi,
    automationAPIURLWithWorkspace,
    putJson,
    stripeGetApi,
    wrapperGetJson,
    logEvent
} from '@/common/utils/api-utils'
import { getProductId } from '@/common/utils/utils'
import { FlowResponseT } from '../types'
import { CanvasLeftPanel } from './CanvasLeftPanel'
import * as CanvasRightPanel from './CanvasRightPanel'
import { AutomationTestPanel } from './TestPanel'
import { NodePosition, Tree as T } from './Tree'
import {
    acknowledgeDeploy,
    automationActivateAction,
    automationCopyAction,
    automationDeActivateAction,
    automationDeployAction,
    automationEditMetaAction,
    connectionDelete
} from './nonCanvasActions'
import {
    ActionInitialState,
    ActionsResponseT,
    AppTriggerResponseT,
    ApprovalResponseT,
    AutomationMeta,
    ConditionResponseT,
    DefaultTree,
    EndResponseInitialState,
    EndResponseT,
    ErrorPage,
    ForEachResponseT,
    FunctionResponseT,
    LeftPanelStateContextT,
    LeftPanelStateT,
    LeftSidePanelDefaultValue,
    Loading,
    NodeDelete,
    NodeDeleteT,
    NodeEdit,
    NodeEditT,
    NodeMode,
    NodeT,
    NodeView,
    Nodes,
    NonCanvasActionModes,
    NonCanvasActions,
    NotFoundPage,
    PopupError,
    PopupLoading,
    ReactSetStateObject,
    RightPanelStateContext,
    RightPanelStateT,
    Tree,
    TreeT,
    TriggerResponseT,
    addNewNodeAsParentToParallelNode,
    addNode,
    deleteNode,
    removeChildrenContextVariables,
    removeChildrenPaths,
    updateErrorHandlingMetaTree,
    updateForEachPathsInChilds,
    updateNewActionPathsInChilds,
    updateNewNodePathsInChilds,
    updateNode,
    updateParallelPathWithChildren,
    WebhookInitialState,
    InputKey,
    WebhookAuthType,
    WebHookResponseT,
    AppTriggerInitialState,
    NoTriggerInitialState,
    deleteErrorHandlingNodes,
    TimerResponseT,
    checkInputHasValue,
    NotificationResponseT
} from './types'
import { noTriggerInitialData } from './TriggerPanel'
import { useSidebar } from '@/SidePanel/SideBarContext'
import { reduceNodes } from './utils'
import { reduceInputs } from './ConditionPopup'
import { TeamsWorkspaceT, WorkspaceT } from '@/Integrations/types'

export const HeaderInner = styled.div`
    width: auto;
    float: left;
    .indent_title_wrapper {
        width: auto;
        float: left;
        display: flex;
    }
    .indent_title_left {
        display: flex;
        align-items: center;
    }
    .indent_title_right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .flow_canvas_header_back_button {
            align-items: flex-start;
            ${Button} {
                svg {
                    fill: #000;
                    color: #000;
                }
            }
        }
    }
    h2 {
        font-size: 18px;
        color: #000;
        margin: 0px;
        font-family: 'Gordita-Medium';
    }
    p {
        font-size: 12px;
        color: #355d8b;
        margin: 0px;
        font-family: 'Gordita-Regular';
        padding-top: 7px;
        line-height: 14px;
    }
    button {
        margin-left: 0px;
        margin-right: 10px;

        span {
            transform: rotate(180deg);
            margin-left: 0;
            margin-right: 3px;
        }
    }
`
const FixedBgWRapper = styled.section`
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 48px;
    right: 0;
    bottom: 0;
    background: #00000047 0% 0% no-repeat padding-box;
    z-index: 1111;
    cursor: auto;

    .automation_logs.automation_logs_runtime_logs {
        float: left;
        position: fixed;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 48px;
        right: 0;
        bottom: 0;
        background: #00000047 0% 0% no-repeat padding-box;
        z-index: 1111;
        cursor: auto;
    }
`

const getPanelType = (x: string) =>
    R.cond<string, string>([
        [R.equals('Action'), R.always('action')],
        [R.equals('Condition'), R.always('condition')],
        [R.equals('Function'), R.always('function')]
    ])(x)

const RightPanel: React.FC = CanvasRightPanel.CanvasRightPanel.Component

export type UploadToMarketPlaceContext = {
    actionState: ReactSetStateObject<NonCanvasActions>
    parentAutomation: AutomationMeta
    parentId: string
    workspaceName: string
}

export const NonCanvasPanelContext = createContext<UploadToMarketPlaceContext>({
    actionState: [] as any,
    parentId: '',
    workspaceName: '',
    parentAutomation: {
        name: '',
        description: '',
        type: 'no_trigger',
        active_status: false,
        deployed_status: 'saved',
        nodesWithoutConnection: [],
        isLinkedToDialog: false
    }
})

export const SidePanelContext = createContext<RightPanelStateContext>({
    rightPanelState: {
        show: false,
        mode: NodeView,
        parentNode: { value: Tree, children: [] },
        currentNode: ActionInitialState,
        approvalAccess: 'loading',
        multiActionAccess: 'loading',
        currentTrigger: "webhook",
        sendNotificationAccess: 'loading'
    },
    setRightPanelState: (_: any) => undefined,
    saveTree: (_: any) => undefined,
    editTreeData: { value: Tree, children: [] },
    setEditTreeData: (_: any) => undefined,
    treeData: { value: Tree, children: [] },
    setTreeData: (_: any) => undefined,
    disableDeployButton: [],
    setDisableDeployButton: (_value: any) => ({}),
    setAutomationMetaType: (type: 'trigger' | 'no_trigger') => undefined,
    active_automation: false,
    hasLinkedDialogs: false,
    automationMeta: {
        name: '',
        description: '',
        type: '',
        active_status: false,
        deployed_status: 'saved',
        nodesWithoutConnection: [],
        isLinkedToDialog: false
    }
})

export const LeftSidePanelContext = createContext<LeftPanelStateContextT>({
    leftPanelState: LeftSidePanelDefaultValue,
    setLeftPanelState: (object: any) => undefined
})

export function FlowCanvas(props: any) {
    const isAssistant = getProductId()=='ASSISTANT'

    const automationId: string = props.match.params.id
    const workspace: string = props.match.params.workspacename
    const journeyData = useJourney()

    const queryClient = useQueryClient()

    const sideBarContext = useSidebar()
    // console.log("DOING :: ",sideBarContext)

    // stripeGetApi(`${workspace}/info/MULTI_STEP_ACCESS`),
    // stripeGetApi(`${workspace}/info/APPROVAL_ACCESS`)
    const _multiStepCached = useQuery(
        'workflow::multi-step-access',
        () => stripeGetApi(`${workspace}/info/MULTI_STEP_ACCESS`),
        {
            onSuccess: _multiActionAccess => {
                // setRightPanelState({
                //     ...rightPanelState,
                //     // approvalAccess: approvalAccess.output == 'yes' ? 'yes' : 'no'
                //     multiActionAccess: multiActionAccess.output == 'yes' ? 'yes' : 'no'
                // })
            },
            staleTime: Infinity,
            refetchOnMount: false
        }
    )

    const _approvalAccessCached = useQuery(
        'workflow::approval-access',
        () => stripeGetApi(`${workspace}/info/APPROVAL_ACCESS`),
        {
            onSuccess: _approvalAccess => {
                // setRightPanelState({
                //     ...rightPanelState,
                //     approvalAccess: approvalAccess.output == 'yes' ? 'yes' : 'no'
                //     // multiActionAccess: actionAccess.output == 'yes' ? 'yes' : 'no'
                // })
            },
            staleTime: Infinity,
            refetchOnMount: false
        }
    )

    const _sendNotificationCached = useQuery(
        'workflow::notifcation-access',
        () => stripeGetApi(`${workspace}/info/TRIGGER_AND_NOTIFICATION_ACCESS`),
        {
            onSuccess: _notificationAccess => {
            },
            staleTime: Infinity,
            refetchOnMount: false
        }
    )

    const _uploadAutomationAccess = useQuery(
        'workflow::marketplace-upload-access',
        () => wrapperGetJson(automationAPIURLWithWorkspace(workspace, MarketPlaceApi.MarketplaceUploadAccess)),
        {
            onSuccess: x => {
                // if (x.data.output.upload_access) {
                //     setUploadAccess(x.data.output.upload_access)
                // }
            },
            staleTime: Infinity,
            refetchOnMount: false
        }
    )

    const _pollingTimeAccess = useQuery(
        'workflow::polling-access',
        () => isAssistant ? Promise.resolve({ output:15 }): stripeGetApi(`${workspace}/info/MINIMUM_POLLING_INTERVAL`),
        {
            staleTime: Infinity,
            refetchOnMount: false
        }
    )

    const [automationMeta, setAutomationMeta] = useState<AutomationMeta>({
        name: '',
        description: '',
        type: '',
        active_status: false,
        deployed_status: 'saved',
        nodesWithoutConnection: [],
        isLinkedToDialog: false
    })

    const [treeData, setTreeData] = useState({
        firstStepDone: false,
        secondStepDone: DefaultTree.children[0].children.length > 0,
        tree: DefaultTree
    })

    const [editTreeData, setEditTreeData] = useState({
        firstStepDone: false,
        secondStepDone: DefaultTree.children[0].children.length > 0,
        tree: DefaultTree
    })

    const [rightPanelState, setRightPanelStateChange] = useState<RightPanelStateT>({
        show: false,
        mode: NodeView,
        parentNode: { value: Tree, children: [] },
        currentNode: ActionInitialState,
        multiActionAccess: 'loading',
        approvalAccess: 'loading',
        sendNotificationAccess: 'loading',
        currentTrigger: "webhook"
    })

    const [leftPanelState, setLeftPanelState] = useState<LeftPanelStateT>(LeftSidePanelDefaultValue)

    const [testingPanelView, setTestingPanelView] = useState(false)

    const [disableDeployButton, setDisableDeployButton] = useState<boolean[]>([false])

    const [showUploadMarketPlaceButton, setUploadAccess] = useState<boolean>(false)

    const setRightPanelState = (x: RightPanelStateT) => {
        setRightPanelStateChange(x)
    }

    const redirectToAutomation = (id: string) => {
        // const location = {
        //     pathname: `/workspace/${workspace}/automation/detail/${id}`,
        //     state: { group: false, flow: true }
        // }
        // props.history.replace(location)
        window.location.href = `/workspace/${workspace}/automation/detail/${id}`
    }

    const [nonCanvasAction, setNonCanvasAction] = useState<NonCanvasActions>(Loading)

    const [setToAutomationMetaEditMode, renderMetaEditModal] = automationEditMetaAction(
        automationId,
        [nonCanvasAction, setNonCanvasAction],
        [automationMeta, setAutomationMeta],
        workspace,
        queryClient
    )
    const [setToDeployAutomationMode, renderDeployAutomation] = automationDeployAction(
        automationId,
        [nonCanvasAction, setNonCanvasAction],
        [automationMeta, setAutomationMeta],
        workspace,
        queryClient
    )

    const [setToActivateAutomationMode, renderActivateAutomation] = automationActivateAction(
        automationId,
        [nonCanvasAction, setNonCanvasAction],
        [automationMeta, setAutomationMeta],
        workspace,
        journeyData,
        queryClient
    )
    const [setToDeActivateAutomationMode, renderDeActivateAutomation] = automationDeActivateAction(
        automationId,
        [nonCanvasAction, setNonCanvasAction],
        [automationMeta, setAutomationMeta],
        workspace,
        journeyData,
        queryClient
    )
    const deactivateModal = renderDeActivateAutomation as any
    const [setToSaveAsAutomationMode, renderSaveAsAutomation] = automationCopyAction(
        automationId,
        redirectToAutomation,
        [nonCanvasAction, setNonCanvasAction],
        [automationMeta, setAutomationMeta],
        workspace,
        queryClient
    )

    const [setToDeleteConnection, deleteConnection, renderDeleteConnection] = connectionDelete(workspace, [
        nonCanvasAction,
        setNonCanvasAction
    ])

    const [linkedWithDialogs, setlinkedWithDialogs] = useState<any[]>([])

    const setAutomationMetaType = (type: 'trigger' | 'no_trigger') => {
        setAutomationMeta({ ...automationMeta, type })
    }

    const setToUploadMode = (id: string, name: string) => {
        setNonCanvasAction({
            mode: NonCanvasActionModes.UploadToMarketPlace,
            name: name,
            id: id
        })
    }

    const [renderAcknowledgeModal] = acknowledgeDeploy([nonCanvasAction, setNonCanvasAction])

    // console.log('_multiStepCached', _multiStepCached)

    useEffect(() => {
        // console.log('use Effect is run ::: _multiStepCached', _multiStepCached.status)
        if (_multiStepCached.status == 'success' && _multiStepCached.data.output) {
            setRightPanelStateChange(rightPanelState => ({
                ...rightPanelState,
                multiActionAccess: _multiStepCached.data.output == 'yes' ? 'yes' : 'no'
            }))
        }
    }, [_multiStepCached.status])

    useEffect(() => {
        // console.log('use Effect is run ::: _approvalAccessCached', _approvalAccessCached.status)
        if (_approvalAccessCached.status == 'success' && _approvalAccessCached.data.output) {
            setRightPanelStateChange(rightPanelState => ({
                ...rightPanelState,
                approvalAccess: _approvalAccessCached.data.output == 'yes' ? 'yes' : 'no'
            }))
        }
    }, [_approvalAccessCached.status])

    useEffect(() => {
        // console.log('use Effect is run ::: _notificationAccessCached', _notificationAccessCached.status)
        if (_sendNotificationCached.status == 'success' && _sendNotificationCached.data.output) {
            setRightPanelStateChange(rightPanelState => ({
                ...rightPanelState,
                sendNotificationAccess: _sendNotificationCached.data.output == 'yes' ? 'yes' : 'no'
            }))
        }
    }, [_sendNotificationCached.status])

    useEffect(() => {
        // console.log('use Effect is run ::: _uploadAutomationAccess', _uploadAutomationAccess.status)
        if (
            _uploadAutomationAccess.status == 'success' &&
            _uploadAutomationAccess.data.data &&
            _uploadAutomationAccess.data.data.output
        ) {
            if (_uploadAutomationAccess.data.data.output.upload_access) {
                setUploadAccess(_uploadAutomationAccess.data.data.output.upload_access)
            }
        }
    }, [_uploadAutomationAccess.status])

    // useEffect(() => {
    //     setEditTreeData(treeData)
    // }, [treeData.tree])

    // useEffect(() => {
    //     Promise.all([
    //         wrapperGetJson(automationAPIURLWithWorkspace(workspace, MarketPlaceApi.MarketplaceUploadAccess)),
    //         stripeGetApi(`${workspace}/info/MULTI_STEP_ACCESS`),
    //         stripeGetApi(`${workspace}/info/APPROVAL_ACCESS`)
    //     ]).then(([x, actionAccess, approvlAccss]) => {
    //         x.data.output.upload_access && setUploadAccess(x.data.output.upload_access)

    //         setRightPanelState({
    //             ...rightPanelState,
    //             approvalAccess: approvlAccss.output == 'yes' ? 'yes' : 'no',
    //             multiActionAccess: actionAccess.output == 'yes' ? 'yes' : 'no'
    //         })
    //     })
    // }, [])

    useEffect(() => {
        setResponseData(props)
    }, [props.state])

    const setResponseData = (props: any) => {
        const nextProps = props
        // console.log("COG1-7574 ::: setResponseData", nextProps)
        // console.log(nonCanvasAction)
        // if (nonCanvasAction.mode !== nextProps.state.type) {
        if (nextProps.state.type == 'loading') {
            setNonCanvasAction(Loading)
        } else if (nextProps.state.type == 'error') {
            if (nextProps.state.error.response && nextProps.state.error.response.status == 404) {
                setNonCanvasAction(NotFoundPage)
                return
            } else {
                setNonCanvasAction(ErrorPage)
                return
            }
        } else if (nextProps.state.type == 'success') {
            const flowList = nextProps.state.data
            // console.log("flowList -1",flowList)

            if (flowList.output && flowList.output.custom_json && Object.keys(flowList.output.custom_json).length > 0) {
                if (flowList.output.deployed_status != 'deployed') {
                    const nextState: {
                        firstStepDone: boolean
                        secondStepDone: boolean
                        tree: TreeT<NodeT>
                    } = produce(treeData, draftState => {
                        draftState.tree = flowList.output.custom_json
                        draftState.secondStepDone = flowList.output.custom_json.children[0].children.length > 0
                        draftState.firstStepDone = true
                    })
                    // TODO : Debug for automation type setting wrong
                    setAutomationMeta({
                        ...automationMeta,
                        name: flowList.output.name,
                        nodesWithoutConnection: flowList.output.nodesWithoutConnection,
                        active_status: flowList.output.active_status,
                        type: flowList.output.type,
                        deployed_status: flowList.output.deployed_status,
                        description: flowList.output.description
                    })

                    setTreeData(nextState)
                    setEditTreeData(nextState)

                    setNonCanvasAction(props.state)
                } else {
                    const allAutomations = flowList.output.allAutomations as FlowResponseT[]

                    const filteredAutomation: FlowResponseT | undefined = allAutomations
                        ? allAutomations.filter(automation => automation.id == flowList.output.id)[0]
                        : undefined
                    const nextState: {
                        firstStepDone: boolean
                        secondStepDone: boolean
                        tree: TreeT<NodeT>
                    } = produce(treeData, draftState => {
                        draftState.tree = flowList.output.custom_json
                        draftState.secondStepDone = flowList.output.custom_json.children[0].children.length > 0
                        draftState.firstStepDone = true
                    })

                    setAutomationMeta({
                        ...automationMeta,
                        name: flowList.output.name,
                        nodesWithoutConnection: flowList.output.nodesWithoutConnection,
                        deployed_status: flowList.output.deployed_status,
                        active_status: flowList.output.active_status,
                        description: flowList.output.description,
                        type: flowList.output.type,
                        isLinkedToDialog:
                            flowList.output.active_status &&
                            filteredAutomation &&
                            filteredAutomation.linkedDialogs &&
                            filteredAutomation.linkedDialogs.length > 0
                                ? true
                                : false
                    })
                    setlinkedWithDialogs(
                        filteredAutomation && filteredAutomation.linkedDialogs ? filteredAutomation.linkedDialogs : []
                    )
                    setTreeData(nextState)
                    setEditTreeData(nextState)
                    setNonCanvasAction(props.state)
                }
            } else {
                const nextState: {
                    firstStepDone: boolean
                    secondStepDone: boolean
                    tree: TreeT<NodeT>
                } = produce(treeData, draftState => {
                    draftState.tree = R.clone(DefaultTree)
                    draftState.secondStepDone = false
                    draftState.firstStepDone = true
                })
                setAutomationMeta({
                    ...automationMeta,
                    active_status: flowList.output.active_status,
                    name: flowList.output.name,
                    nodesWithoutConnection: flowList.output.nodesWithoutConnection,
                    description: flowList.output.description,
                    deployed_status: flowList.output.deployed_status,
                    type: flowList.output.type
                })

                setTreeData(nextState)
                setEditTreeData(nextState)

                setRightPanelState( isAssistant?{
                    ...rightPanelState,
                    show: true,
                    currentTrigger:'no_trigger',
                    currentNode: NoTriggerInitialState,
                    mode:{
                        ...NodeEdit,
                        nodeEditInput: nextState.tree.value
                    }
                }:{
                    ...rightPanelState,
                    show: true,
                    currentTrigger:'application',
                    currentNode: AppTriggerInitialState,
                    mode:{
                        ...NodeEdit,
                        nodeEditInput: nextState.tree.value
                    }
                })
                setNonCanvasAction(props.state)
            }
        }
        // }
    }

    const saveTree = async (
        node:
            | ActionsResponseT
            | TriggerResponseT
            | AppTriggerResponseT
            | ConditionResponseT
            | EndResponseT
            | FunctionResponseT
            | ForEachResponseT
            | ApprovalResponseT
            | WebHookResponseT
            | TimerResponseT
            | NotificationResponseT
    ) => {
        let nodeInput: any = []
        let newTree: any = {}
        let isAction = false
        const flowId = props.match.params.id

        const firstStepNode = treeData.tree.value

        setRightPanelState({ ...rightPanelState, mode: PopupLoading })

        if (node.nodeType == Nodes.End) {
            const endNodeResponse = node as EndResponseT
            const endNode = currentTree.tree.children[1].value
            const successEmail = endNodeResponse.reportMeta.successEmail.value
            const failureEmail = endNodeResponse.reportMeta.failureEmail.value
            const newNode = {
                kind: 'End',
                id: endNode.id,
                icon: endNode.icon,
                name: endNode.name,
                output: null,
                path: [],
                input: nodeInput,
                description: endNode.description,
                meta_data: {
                    successEmail,
                    failureEmail
                }
            } as NodeT
            newTree = updateNode(newNode, treeData.tree)
        }

        if(node.nodeType == Nodes.Timer){
            const timerNodeResponse = node as TimerResponseT

            let input = {};
            if (timerNodeResponse.timerType === 'wait_time') {
            input = {
                type: 'schedule-by-delay',
                delay: {
                by: timerNodeResponse.waitFor,
                period: timerNodeResponse.timerValue,
                },
                date: {
                type: 'without-date',
                },
            };
            } else {
                if (timerNodeResponse.conditionTimeType === 'current_time') {
                    input = {
                    type: 'schedule-by-date',
                    time: timerNodeResponse.hour,
                    timezone: timerNodeResponse.timezone,
                    day: {
                        type: 'without-day',
                    },
                    };
                } else if (timerNodeResponse.conditionTimeType === 'date_time') { 
                    input = {
                        type: 'schedule-by-date',
                        time: timerNodeResponse.hour,
                        timezone: timerNodeResponse.timezone,
                        day: {
                            type: 'with-day',
                            on: timerNodeResponse.currentDay,
                            period: 'month',
                        },
                    }
                }else{
                    input = {
                        type: 'schedule-by-date',
                        time: timerNodeResponse.hour,
                        timezone: timerNodeResponse.timezone,
                        day: {
                            type: 'with-date',
                            on: `${reduceInputs((timerNodeResponse as any).dateVariable)}`,
                            timezone: timerNodeResponse.timezone,
                        },
                        };
                    }
                }
                
            const parentNode = rightPanelState.parentNode

            if (rightPanelState.mode.mode === NodeMode.Add) {
                if (node.parallelPathNode.currentParallelPathNode === null) {
                    const id = generateUniqueId();
                    const newNode = {
                        kind: 'Timer',
                        id: id,
                        icon: TimerNodeIcon,
                        name: "Timer",
                        output: null,
                        path: parentNode.value.kind !== 'Setup' ? parentNode.value.path : [],
                        input: input as any,
                        description: "",
                        meta_data: {
                        }
                    } as NodeT

                    // for adding flow inside for each
                    const subflow = node.subflowMetaData.subflow

                    const subflowMetaData = {
                        addingInsideForEach: subflow.hasSubFlow,
                        forEachNodeId: subflow.hasSubFlow ? subflow.forEachNodeId : '',
                        parentNode: parentNode.value
                    }

                    if (
                        node.parallelPathNode.replacingParallelSibling
                    ) {
                        // to replace position of parallel path sibling node if sibling is a parent with new action node
                        newTree = addNewNodeAsParentToParallelNode(
                            newNode,
                            parentNode.value,
                            node.parallelPathNode.replacingParallelSibling,
                            treeData.tree,
                            {
                                isErrorHandling: false,
                                errorHandlingChilds: []
                            },
                            subflowMetaData
                        )
                    } else {
                        newTree = addNode(
                            newNode,
                            parentNode.value,
                            treeData.tree,
                            {
                                isErrorHandling: false,
                                errorHandlingChilds: []
                            },
                            subflowMetaData
                        )
                    }
                } else {
                    // to replace parallel path node with action node if new node is action node
                    const newNode = {
                        kind: 'Timer',
                        id: node.parallelPathNode.currentParallelPathNode.id.toString(),
                        icon: TimerNodeIcon,
                        name: "Timer",
                        output: null,
                        path: parentNode.value.kind !== 'Setup'? parentNode.value.path : [],
                        input: input as any,
                        description: "",
                        meta_data: {
                        }
                    } as NodeT

                    const subflow = node.subflowMetaData.subflow

                    newTree = node.parallelPathNode.anotherEmptyChildren
                        ? updateParallelPathWithChildren(
                              newNode,
                              parentNode.value,
                              node.parallelPathNode.anotherEmptyChildren,
                              node.parallelPathNode.emptyChildrenPosition,
                              treeData.tree,
                              {
                                isErrorHandling: false,
                                errorHandlingChilds: []
                              },
                              {
                                  addingInsideForEach: subflow.hasSubFlow,
                                  forEachNodeId: subflow.hasSubFlow ? subflow.forEachNodeId : '',
                                  parentNode: parentNode.value
                              }
                          )
                        : updateNode(newNode, treeData.tree === editTreeData.tree ? treeData.tree : editTreeData.tree, {
                              addingInsideForEach: subflow.hasSubFlow,
                              forEachNodeId: subflow.hasSubFlow ? subflow.forEachNodeId : '',
                              parentNode: parentNode.value
                          })
                }        
            } else {
                    const mode = rightPanelState.mode as NodeEditT
                    const newNode = {
                        kind: 'Timer',
                        id: mode.nodeEditInput.id.toString(),
                        icon: TimerNodeIcon,
                        name: "Timer",
                        output: null,
                        path: mode.nodeEditInput.path,
                        input: input as any,
                        description: "",
                        meta_data: {
                        }
                    } as NodeT
                    const subflow = node.subflowMetaData.subflow
    
                    newTree = updateNode(newNode, treeData.tree, {
                        addingInsideForEach: subflow.hasSubFlow,
                        forEachNodeId: subflow.hasSubFlow ? subflow.forEachNodeId : '',
                        parentNode: parentNode.value
                    })
                }

            }

        if(node.nodeType === Nodes.Notification){
            const notificationNodeResponse = node as NotificationResponseT

            const id = generateUniqueId()

            const parentNode = rightPanelState.parentNode

            const channel_id = (notificationNodeResponse.selectedWorkspaceChannel as any).id

            let newNode = {
                kind: 'Notification',
                id: rightPanelState.mode.mode===NodeMode.Edit ? rightPanelState.mode.nodeEditInput.id : id ,
                icon: NotificationNodeIcon,
                name: "Send Notification",
                output: null,
                path: parentNode.value.kind !== 'Setup'
                ? [
                      ...(node.addingInsideForEach
                          ? parentNode.value.path
                          : parentNode.value.path.filter(path => !path.forEachId))
                  ]
                : [],
                input: notificationNodeResponse.actionInputsList.output.input,
                description: "",
                meta_data: {
                },
                notification_type: 'send-and-forget',
                bot_id: notificationNodeResponse.selectedBot?.id,
                chat_channel_type: notificationNodeResponse.selectedChatChannel?.id,
                channel_id: channel_id,
                notification_recipient: notificationNodeResponse.user_type?.id === 'all'? 
                {
                    type: 'broadcast' 
                }:
                {
                    type: 'user',
                     user_email: notificationNodeResponse.user as string
                },
                notification_title: notificationNodeResponse.notification_title,
                notification_input_type:notificationNodeResponse.message_type?.id
            } as NodeT

            const subflow = node.subflowMetaData.subflow

            const subflowMetaData = {
                addingInsideForEach: subflow.hasSubFlow,
                forEachNodeId: subflow.hasSubFlow ? subflow.forEachNodeId : '',
                parentNode: parentNode.value
            }

            if(rightPanelState.mode.mode===NodeMode.Add){
                newTree = addNode(
                    newNode,
                    parentNode.value,
                    treeData.tree,
                    {
                        isErrorHandling: false,
                        errorHandlingChilds: []
                    },
                    subflowMetaData
                )
            } else {
                const mode = rightPanelState.mode as NodeEditT
                newNode = { ...newNode, path : mode.nodeEditInput.path }
                newTree = updateNode(newNode, treeData.tree)
            }
        }

        if (node.nodeType === Nodes.Trigger && node.trigger.triggerMeta.triggerType === 'AppTrigger') {
            const triggerNode = node as AppTriggerResponseT
            nodeInput = triggerNode.actionInputsList.output.input
            let newNode = {
                interval: triggerNode.selectedPollingTime,
                id: treeData.tree.value.id,
                icon: triggerNode.selectedApp.icon,
                name: triggerNode.selectedAction.name,
                output: null,
                path: [],
                input: nodeInput,
                description: triggerNode.selectedAction.description,
                meta_data: {
                    authentication_id: triggerNode.selectedConnection.id,
                    action_id: triggerNode.selectedAction.id,
                    app: { appName: triggerNode.selectedApp.name, appId: triggerNode.selectedApp.id },
                    continueIfError: triggerNode.errorHandling.errorHandling,
                    errors: [...triggerNode.errorHandling.errors],
                 }
            } as any

           newNode =  triggerNode.selectedAction.type == 'trigger' ?
                {
                    ...newNode,
                    kind:'AppTrigger',
                    start:true
                } as NodeT
            : 
                {
                    ...newNode,
                    kind:'EventTrigger',
                } as NodeT

            const contextVariableRemovedTree = editTreeData.tree.value.meta_data && (editTreeData.tree.value.meta_data.action_id != triggerNode.selectedAction.id) ? removeChildrenContextVariables(
                treeData.tree.value.id,
                editTreeData.tree,
                undefined,
                { errorHandling: false, action_id: '' },
                treeData.tree.value.id
            ) : editTreeData.tree

            newTree = updateNode(newNode, contextVariableRemovedTree)
        }

        if (
            node.nodeType === Nodes.Webhook &&
            node.trigger.triggerMeta.triggerType == "Webhook"
          ) {
            // const transFormToProperties = node.trigger.triggerMeta.inputKeys
            //   .filter((key: any) => key.title !== "")
            //   .map((key: any) => ({
            //     [key.uniqueKey]: {
            //       $id: `/properties/${key.uniqueKey}`,
            //       type: key.type.toLowerCase(),
            //       title: key.title,
            //       optional: key.optional,
            //     },
            //   }))
            //   .reduce((acc: any, curr: any) => {
            //     return { ...acc, ...curr };
            //   }, {});
            // const output = {
            //   type: "object",
            //   title: "webhook output schema",
            //   properties: transFormToProperties,
            // };
            if (rightPanelState.mode.mode == NodeMode.Edit) {
              const newNode = {
                ...Tree,
                kind: "WebhookTrigger",
                id: rightPanelState.mode.nodeEditInput.id,
                icon: WebhookIcon,
                name: node.name,
                input: nodeInput,
                // output: output,
                output: rightPanelState.mode.nodeEditInput.output,
                meta_data: {
                  name: node.name,
                  webhookType: node.webhookType,
                  authId:
                    node.authentication.auth == WebhookAuthType.Basic
                      ? node.authentication.authId
                      : "",
                  auth:
                    node.authentication.auth == WebhookAuthType.Basic
                      ? "basic"
                      : "none",
                },
              } as NodeT;
             const updatedTree = treeData.tree.value.kind != 'WebhookTrigger' ? deleteErrorHandlingNodes(treeData.tree.value.id,treeData.tree.children[0].value,treeData.tree) : treeData.tree
            //   const contextVariableRemovedTree =
            //     rightPanelState.mode.nodeEditInput.output &&
            //     !R.equals(
            //       Object.values(
            //         rightPanelState.mode.nodeEditInput.output.properties
            //       ).map((p: any) => ({ title: p.title, type: p.type })),
            //       Object.values(output.properties).map((p: any) => ({
            //         title: p.title,
            //         type: p.type,
            //       }))
            //     )
            //       ? removeChildrenContextVariables(
            //           rightPanelState.mode.nodeEditInput.id,
            //           treeData.tree,
            //           node.trigger.triggerMeta.inputKeys,
            //           { errorHandling: false, action_id: "" }
            //         )
            //       : treeData.tree;
      
            //   const step3VariableRemoved =
            //     treeData.tree.value.kind == "WebhookTrigger"
            //       ? removeValuesFromStep3(
            //           rightPanelState.mode.nodeEditInput.id,
            //           contextVariableRemovedTree,
            //           node.trigger.triggerMeta.inputKeys,
            //           { errorHandling: false, action_id: "" }
            //         )
            //       : contextVariableRemovedTree;
            // console.log(newNode,node)

            newTree = updateNode(newNode, updatedTree);
            } else if (rightPanelState.mode.mode === NodeMode.Add) {
              const id = generateUniqueId();
              const newNode = {
                ...Tree,
                kind: "WebhookTrigger",
                id: id,
                icon: NoTriggerIcon,
                name: "Webhook Trigger",
                input: nodeInput,
                output: { properties:{} },
                // output: output,
                meta_data: {
                  name: node.name,
                  webhookType: node.webhookType,
                  authId:
                    node.authentication.auth == WebhookAuthType.Basic
                      ? node.authentication.authId
                      : "",
                  auth:
                    node.authentication.auth == WebhookAuthType.Basic
                      ? "basic"
                      : "none",
                },
              } as NodeT;
            //   const contextVariableRemovedTree = removeChildrenContextVariables(
            //     id,
            //     treeData.tree,
            //     node.trigger.triggerMeta.inputKeys,
            //     { errorHandling: false, action_id: "" }
            //   );
            //   const step3VariableRemoved =
            //     treeData.tree.value.kind == "WebhookTrigger"
            //       ? removeValuesFromStep3(
            //           id,
            //           contextVariableRemovedTree,
            //           node.trigger.triggerMeta.inputKeys,
            //           { errorHandling: false, action_id: "" }
            //         )
            //       : contextVariableRemovedTree;
                console.log(newNode,node)
              newTree = updateNode(newNode, treeData.tree);
            }

          } else if (node.nodeType === Nodes.Trigger && node.trigger.triggerMeta.triggerType == 'NoTrigger') {
            // const transFormToProperties = node.trigger.triggerMeta.inputKeys
            //     .filter((key: any) => key.title !== '')
            //     .map((key: any) => ({
            //         [key.uniqueKey]: {
            //             $id: `/properties/${key.uniqueKey}`,
            //             type: key.type.toLowerCase(),
            //             title: key.title
            //         }
            //     }))
            //     .reduce((acc: any, curr: any) => {
            //         return { ...acc, ...curr }
            //     }, {})
            // const output = {
            //     type: 'object',
            //     title: 'trigger output schema',
            //     properties: transFormToProperties
            // }
            // if (rightPanelState.mode.mode == NodeMode.Edit) {
            //     const newNode = {
            //         ...Tree,
            //         kind: 'NoTrigger',
            //         id: rightPanelState.mode.nodeEditInput.id,
            //         icon: NoTriggerIcon,
            //         name: 'No Trigger',
            //         input: nodeInput,
            //         output: output
            //     } as NodeT

            //     const contextVariableRemovedTree = removeChildrenContextVariables(
            //         rightPanelState.mode.nodeEditInput.id,
            //         treeData.tree,
            //         node.trigger.triggerMeta.inputKeys,
            //         { errorHandling: false, action_id: '' }
            //     )

            //     newTree = updateNode(newNode, contextVariableRemovedTree)
            // } else if (rightPanelState.mode.mode === NodeMode.Add) {
            //     const id = generateUniqueId()
            //     const newNode = {
            //         ...Tree,
            //         kind: 'NoTrigger',
            //         id: id,
            //         icon: NoTriggerIcon,
            //         name: 'No Trigger',
            //         input: nodeInput,
            //         output: output
            //     } as NodeT

            //     const contextVariableRemovedTree = removeChildrenContextVariables(
            //         id,
            //         treeData.tree,
            //         node.trigger.triggerMeta.inputKeys,
            //         { errorHandling: false, action_id: '' }
            //     )

            //     newTree = updateNode(newNode, contextVariableRemovedTree)
            // }
                const output = treeData.tree.value.output == null? {
                    properties: {},
                    title: "output schema",
                    type: "object"
                } : treeData.tree.value.output
                const newNode = {
                    ...Tree,
                    kind: 'NoTrigger',
                    id: treeData.tree.value.id,
                    icon: NoTriggerIcon,
                    name: 'Triggered by Chatbot',
                    input: [],
                    output: output,
                    meta_data:{}
                } as NodeT

                newTree = updateNode(newNode, treeData.tree)
        } else if (node.nodeType === Nodes.Action) {
            const id = node.actionId.toString()
            isAction = true
            nodeInput = node.actionInputsList.output.input

            const parentNode = rightPanelState.parentNode

            if (rightPanelState.mode.mode === NodeMode.Add) {
                if (node.parallelPathNode.currentParallelPathNode === null) {

                    const newNode = {
                        kind: 'Action',
                        id: id,
                        input: nodeInput,
                        name: node.selectedAction.name,
                        description: node.selectedAction.description,
                        meta_data: {
                            ...node.subflowMetaData,
                            authentication_id: node.selectedConnection.id,
                            agent_id:
                                node.selectedConnection.connectionsDetails.type == 'on_prem'
                                    ? node.selectedConnection.connectionsDetails.value.meta_data.agent
                                    : '',
                            action_id: node.selectedAction.id,
                            app: { appName: node.selectedApp.name, appId: node.selectedApp.id },
                            continueIfError: node.errorHandling.errorHandling,
                            errors: [...node.errorHandling.errors],
                            errorsToHandle: []
                        },
                        icon: node.selectedApp.icon,
                        path:
                            parentNode.value.kind !== 'Setup'
                                ? [
                                      ...(node.addingInsideForEach
                                          ? parentNode.value.path
                                          : parentNode.value.path.filter(path => !path.forEachId)),
                                      {
                                          actionId: node.selectedAction.id,
                                          nodeId: id,
                                          forEachNodeId:
                                              node.addingInsideForEach && node.subflowMetaData.subflow.hasSubFlow
                                                  ? node.subflowMetaData.subflow.forEachNodeId
                                                  : '',
                                          hasErrorhandling: node.errorHandling.errorHandling
                                      }
                                  ]
                                : [
                                      {
                                          actionId: node.selectedAction.id,
                                          nodeId: id,
                                          hasErrorhandling: node.errorHandling.errorHandling
                                      }
                                  ]
                    } as NodeT

                    // for adding flow inside for each
                    const subflow = node.subflowMetaData.subflow

                    const subflowMetaData = {
                        addingInsideForEach: subflow.hasSubFlow,
                        forEachNodeId: subflow.hasSubFlow ? subflow.forEachNodeId : '',
                        parentNode: parentNode.value
                    }

                    if (
                        ((node.addingInsideForEach &&
                            parentNode.value.kind == 'ForEach' &&
                            parentNode.value.subflow &&
                            parentNode.value.subflow.children.length > 1) ||
                            parentNode.children.length > 1) &&
                        node.parallelPathNode.replacingParallelSibling
                    ) {
                        // to replace position of parallel path sibling node if sibling is a parent with new action node
                        newTree = addNewNodeAsParentToParallelNode(
                            newNode,
                            parentNode.value,
                            node.parallelPathNode.replacingParallelSibling,
                            treeData.tree,
                            {
                                isErrorHandling: node.errorHandling.errorHandling,
                                errorHandlingChilds: node.errorHandling.errorHandlingChildrenForAddMode
                            },
                            subflowMetaData
                        )
                    } else if (parentNode.children.length > 0) {
                        const updatedPathTree = updateNewNodePathsInChilds(parentNode.value.id, treeData.tree, {
                            actionId: node.selectedAction.id,
                            nodeId: id,
                            hasErrorhandling: node.errorHandling.errorHandling
                        })
                        newTree = addNode(
                            newNode,
                            parentNode.value,
                            updatedPathTree,
                            {
                                isErrorHandling: node.errorHandling.errorHandling,
                                errorHandlingChilds: node.errorHandling.errorHandlingChildrenForAddMode
                            },
                            subflowMetaData
                        )
                    } else {
                        newTree = addNode(
                            newNode,
                            parentNode.value,
                            treeData.tree,
                            {
                                isErrorHandling: node.errorHandling.errorHandling,
                                errorHandlingChilds: node.errorHandling.errorHandlingChildrenForAddMode
                            },
                            subflowMetaData
                        )
                    }
                } else {
                    // to replace parallel path node with action node if new node is action node
                    const newNode = {
                        ...node.parallelPathNode.currentParallelPathNode,
                        kind: 'Action',
                        id: id,
                        input: nodeInput,
                        name: node.selectedAction.name,
                        description: node.selectedAction.description,
                        meta_data: {
                            ...node.subflowMetaData,
                            authentication_id: node.selectedConnection.id,
                            agent_id:
                                node.selectedConnection.connectionsDetails.type == 'on_prem'
                                    ? node.selectedConnection.connectionsDetails.value.meta_data.agent
                                    : '',
                            action_id: node.selectedAction.id,
                            app: { appName: node.selectedApp.name, appId: node.selectedApp.id },
                            continueIfError: node.errorHandling.errorHandling,
                            errors: [...node.errorHandling.errors],
                            errorsToHandle: []
                        },
                        icon: node.selectedApp.icon,
                        path:
                            parentNode.value.kind !== 'Setup'
                                ? [
                                      ...(node.addingInsideForEach
                                          ? parentNode.value.path
                                          : parentNode.value.path.filter(path => !path.forEachId)),
                                      {
                                          actionId: node.selectedAction.id,
                                          nodeId: node.parallelPathNode.currentParallelPathNode.id,
                                          forEachNodeId:
                                              node.addingInsideForEach && node.subflowMetaData.subflow.hasSubFlow
                                                  ? node.subflowMetaData.subflow.forEachNodeId
                                                  : '',
                                           hasErrorhandling: node.errorHandling.errorHandling
                                      }
                                  ]
                                : [
                                      {
                                          actionId: node.selectedAction.id,
                                          nodeId: node.parallelPathNode.currentParallelPathNode.id,
                                          hasErrorhandling: node.errorHandling.errorHandling
                                      }
                                  ]
                    } as NodeT

                    const subflow = node.subflowMetaData.subflow

                    newTree = node.parallelPathNode.anotherEmptyChildren
                        ? updateParallelPathWithChildren(
                              newNode,
                              parentNode.value,
                              node.parallelPathNode.anotherEmptyChildren,
                              node.parallelPathNode.emptyChildrenPosition,
                              treeData.tree,
                              {
                                  isErrorHandling: node.errorHandling.errorHandling,
                                  errorHandlingChilds: node.errorHandling.errorHandlingChildrenForAddMode
                              },
                              {
                                  addingInsideForEach: subflow.hasSubFlow,
                                  forEachNodeId: subflow.hasSubFlow ? subflow.forEachNodeId : '',
                                  parentNode: parentNode.value
                              }
                          )
                        : updateNode(newNode, treeData.tree === editTreeData.tree ? treeData.tree : editTreeData.tree, {
                              addingInsideForEach: subflow.hasSubFlow,
                              forEachNodeId: subflow.hasSubFlow ? subflow.forEachNodeId : '',
                              parentNode: parentNode.value
                          })
                }
            } else if (rightPanelState.mode.mode === NodeMode.Edit) {
                const mode = rightPanelState.mode as NodeEditT
                const newNode = {
                    kind: 'Action',
                    id: mode.nodeEditInput.id,
                    input: nodeInput,
                    name: node.selectedAction.name,
                    description: node.selectedAction.description,
                    meta_data: {
                        ...node.subflowMetaData,
                        authentication_id: node.selectedConnection.id,
                        agent_id:
                            node.selectedConnection.connectionsDetails.type == 'on_prem'
                                ? node.selectedConnection.connectionsDetails.value.meta_data.agent
                                : '',
                        action_id: node.selectedAction.id,
                        app: { appName: node.selectedApp.name, appId: node.selectedApp.id },
                        continueIfError: node.errorHandling.errorHandling,
                        errors: [...node.errorHandling.errors],
                        errorsToHandle: []
                    },
                    icon: node.selectedApp.icon,
                    path: mode.nodeEditInput.path.map(path => {
                        if (path.nodeId === mode.nodeEditInput.id) {
                            return { ...path, 
                                actionId: node.selectedAction.id,                                           
                                hasErrorhandling: node.errorHandling.errorHandling
                            }
                        }

                        return path
                    })
                } as NodeT

                const updatedPathTree = updateNewActionPathsInChilds(
                    mode.nodeEditInput.id,
                    node.errorHandling.errorHandling ? editTreeData.tree : treeData.tree,
                    {
                        actionId: node.selectedAction.id,
                        nodeId: mode.nodeEditInput.id,
                        hasErrorhandling: node.errorHandling.errorHandling
                    }
                )

                // const actionDoesNotchange = mode.nodeEditInput.path.filter(x => x.actionId === node.selectedAction.id)

                const subflow = node.subflowMetaData.subflow

                if (
                    mode.nodeEditInput.meta_data &&
                    mode.nodeEditInput.meta_data.app &&
                    node.selectedApp.id !== mode.nodeEditInput.meta_data.app.appId
                ) {
                    const contextVariableRemovedTree = removeChildrenContextVariables(
                        mode.nodeEditInput.id,
                        updatedPathTree,
                        undefined,
                        { errorHandling: true, action_id: node.selectedAction.id }
                    )

                    newTree = updateNode(newNode, contextVariableRemovedTree, {
                        addingInsideForEach: subflow.hasSubFlow,
                        forEachNodeId: subflow.hasSubFlow ? subflow.forEachNodeId : '',
                        parentNode: parentNode.value
                    })
                } else if (
                    mode.nodeEditInput.meta_data &&
                    mode.nodeEditInput.meta_data.action_id &&
                    node.selectedAction.id !== mode.nodeEditInput.meta_data.action_id
                ) {
                    const contextVariableRemovedTree = removeChildrenContextVariables(
                        mode.nodeEditInput.id,
                        updatedPathTree,
                        undefined,
                        { errorHandling: true, action_id: node.selectedAction.id }
                    )

                    const updatedErrorHandlingMetaTree = updateErrorHandlingMetaTree(
                        mode.nodeEditInput.id,
                        contextVariableRemovedTree,
                        node
                    )

                    newTree = updateNode(newNode, updatedErrorHandlingMetaTree, {
                        addingInsideForEach: subflow.hasSubFlow,
                        forEachNodeId: subflow.hasSubFlow ? subflow.forEachNodeId : '',
                        parentNode: parentNode.value
                    })
                } else {
                    newTree = updateNode(newNode, updatedPathTree, {
                        addingInsideForEach: subflow.hasSubFlow,
                        forEachNodeId: subflow.hasSubFlow ? subflow.forEachNodeId : '',
                        parentNode: parentNode.value
                    })
                }
            }
        } else if (node.nodeType === Nodes.Function && node.functionInputsList.output.input.length > 0) {
            nodeInput = node.functionInputsList.output.input

            const parentNode = rightPanelState.parentNode

            if (rightPanelState.mode.mode === NodeMode.Add) {
                if (node.parallelPathNode.currentParallelPathNode === null) {
                    const id = generateUniqueId()

                    const newNode = {
                        kind: 'Function',
                        id: id.toString(),
                        input: nodeInput,
                        name: node.selectedFunction.name,
                        description: '',
                        meta_data: {
                            ...node.subflowMetaData,
                            action_id: node.selectedFunction.function_id,
                            app: { appName: node.selectedFunction.name, appId: node.selectedFunction.id }
                        },
                        icon: FunctionNodeIcon,
                        path:
                            parentNode.value.kind !== 'Setup'
                                ? [
                                      ...(node.addingInsideForEach
                                          ? parentNode.value.path
                                          : parentNode.value.path.filter(path => !path.forEachId)),
                                      {
                                          actionId: node.selectedFunction.function_id,
                                          nodeId: id,
                                          forEachNodeId:
                                              node.addingInsideForEach && node.subflowMetaData.subflow.hasSubFlow
                                                  ? node.subflowMetaData.subflow.forEachNodeId
                                                  : ''
                                      }
                                  ]
                                : [
                                      {
                                          actionId: node.selectedFunction.function_id,
                                          nodeId: id
                                      }
                                  ]
                    } as NodeT

                    // for adding flow inside for each
                    const subflow = node.subflowMetaData.subflow

                    const subflowMetaData = {
                        addingInsideForEach: subflow.hasSubFlow,
                        forEachNodeId: subflow.hasSubFlow ? subflow.forEachNodeId : '',
                        parentNode: parentNode.value
                    }

                    if (
                        ((node.addingInsideForEach &&
                            parentNode.value.kind == 'ForEach' &&
                            parentNode.value.subflow &&
                            parentNode.value.subflow.children.length > 1) ||
                            parentNode.children.length > 1) &&
                        node.parallelPathNode.replacingParallelSibling
                    ) {
                        // to replace position of parallel path sibling node if sibling is a parent with new action node
                        newTree = addNewNodeAsParentToParallelNode(
                            newNode,
                            parentNode.value,
                            node.parallelPathNode.replacingParallelSibling,
                            treeData.tree,
                            {
                                isErrorHandling: false,
                                errorHandlingChilds: []
                            },
                            subflowMetaData
                        )
                    } else if (parentNode.children.length > 0) {
                        const updatedPathTree = updateNewNodePathsInChilds(parentNode.value.id, treeData.tree, {
                            actionId: node.selectedFunction.function_id,
                            nodeId: id,
                            hasErrorhandling:false
                        })

                        newTree = addNode(
                            newNode,
                            parentNode.value,
                            updatedPathTree,
                            {
                                isErrorHandling: false,
                                errorHandlingChilds: []
                            },
                            subflowMetaData
                        )
                    } else {
                        newTree = addNode(
                            newNode,
                            parentNode.value,
                            treeData.tree,
                            {
                                isErrorHandling: false,
                                errorHandlingChilds: []
                            },
                            subflowMetaData
                        )
                    }
                } else {
                    // to replace parallel path node with action node if new node is action node
                    const newNode = {
                        ...node.parallelPathNode.currentParallelPathNode,
                        kind: 'Function',
                        id: node.parallelPathNode.currentParallelPathNode.id.toString(),
                        input: nodeInput,
                        name: node.selectedFunction.name,
                        description: '',
                        meta_data: {
                            ...node.subflowMetaData,
                            action_id: node.selectedFunction.function_id,
                            app: { appName: node.selectedFunction.name, appId: node.selectedFunction.id }
                        },
                        icon: FunctionNodeIcon,
                        path:
                            parentNode.value.kind !== 'Setup'
                                ? [
                                      ...(node.addingInsideForEach
                                          ? parentNode.value.path
                                          : parentNode.value.path.filter(path => !path.forEachId)),
                                      {
                                          actionId: node.selectedFunction.function_id,
                                          nodeId: node.parallelPathNode.currentParallelPathNode.id,
                                          forEachNodeId:
                                              node.addingInsideForEach && node.subflowMetaData.subflow.hasSubFlow
                                                  ? node.subflowMetaData.subflow.forEachNodeId
                                                  : ''
                                      }
                                  ]
                                : [
                                      {
                                          actionId: node.selectedFunction.function_id,
                                          nodeId: node.parallelPathNode.currentParallelPathNode.id
                                      }
                                  ]
                    } as NodeT

                    const subflow = node.subflowMetaData.subflow

                    newTree = node.parallelPathNode.anotherEmptyChildren
                        ? updateParallelPathWithChildren(
                              newNode,
                              parentNode.value,
                              node.parallelPathNode.anotherEmptyChildren,
                              node.parallelPathNode.emptyChildrenPosition,
                              treeData.tree,
                              {
                                  isErrorHandling: false,
                                  errorHandlingChilds: []
                              },
                              {
                                  addingInsideForEach: subflow.hasSubFlow,
                                  forEachNodeId: subflow.hasSubFlow ? subflow.forEachNodeId : '',
                                  parentNode: parentNode.value
                              }
                          )
                        : updateNode(newNode, treeData.tree === editTreeData.tree ? treeData.tree : editTreeData.tree, {
                              addingInsideForEach: subflow.hasSubFlow,
                              forEachNodeId: subflow.hasSubFlow ? subflow.forEachNodeId : '',
                              parentNode: parentNode.value
                          })
                }
            } else if (rightPanelState.mode.mode === NodeMode.Edit) {
                const mode = rightPanelState.mode as NodeEditT
                const newNode = {
                    kind: 'Function',
                    id: mode.nodeEditInput.id,
                    input: nodeInput,
                    name: node.selectedFunction.name,
                    description: '',
                    meta_data: {
                        ...node.subflowMetaData,
                        action_id: node.selectedFunction.function_id,
                        app: { appName: node.selectedFunction.name, appId: node.selectedFunction.id }
                    },
                    icon: FunctionNodeIcon,
                    path: mode.nodeEditInput.path.map(path => {
                        if (path.nodeId === mode.nodeEditInput.id) {
                            return { ...path, actionId: node.selectedFunction.function_id }
                        }

                        return path
                    })
                } as NodeT

                const updatedPathTree = updateNewActionPathsInChilds(mode.nodeEditInput.id, treeData.tree, {
                    actionId: node.selectedFunction.function_id,
                    nodeId: mode.nodeEditInput.id,
                    hasErrorhandling:false
                })

                const subflow = node.subflowMetaData.subflow

                if (node.selectedFunction.function_id !== mode.nodeEditInput.meta_data.action_id) {
                    const contextVariableRemovedTree = removeChildrenContextVariables(
                        mode.nodeEditInput.id,
                        updatedPathTree,
                        undefined,
                        { errorHandling: true, action_id: node.selectedFunction.id }
                    )

                    newTree = updateNode(newNode, contextVariableRemovedTree, {
                        addingInsideForEach: subflow.hasSubFlow,
                        forEachNodeId: subflow.hasSubFlow ? subflow.forEachNodeId : '',
                        parentNode: parentNode.value
                    })
                } else {
                    newTree = updateNode(newNode, updatedPathTree, {
                        addingInsideForEach: subflow.hasSubFlow,
                        forEachNodeId: subflow.hasSubFlow ? subflow.forEachNodeId : '',
                        parentNode: parentNode.value
                    })
                }
            }
        } else if (node.nodeType === Nodes.Foreach) {
            const parentNode = rightPanelState.parentNode

            if (rightPanelState.mode.mode === NodeMode.Add) {
                if (node.parallelPathNode.currentParallelPathNode === null) {
                    const id = generateUniqueId()

                    const newNode = {
                        kind: 'ForEach',
                        id: id.toString(),
                        input: [] as any,
                        name: node.name,
                        description: node.description,
                        meta_data: {
                            collection: node.selectedCollection
                        },
                        icon: '',
                        path:
                            parentNode.value.kind !== 'Setup'
                                ? [
                                      ...parentNode.value.path,
                                      {
                                          nodeId: id.toString(),
                                          actionId: '',
                                          forEachId: node.selectedCollection,
                                          forEachName: node.name
                                      }
                                  ]
                                : [                                      {
                                    nodeId: id.toString(),
                                    actionId: '',
                                    forEachId: node.selectedCollection,
                                    forEachName: node.name
                                }],
                        output: {},
                        subflow: { children: [] }
                    } as NodeT

                    if (parentNode.children.length > 1 && node.parallelPathNode.replacingParallelSibling) {
                        // to replace position of parallel path sibling node if sibling is a parent with new action node
                        newTree = addNewNodeAsParentToParallelNode(
                            newNode,
                            parentNode.value,
                            node.parallelPathNode.replacingParallelSibling,
                            treeData.tree,
                            {
                                isErrorHandling: false,
                                errorHandlingChilds: []
                            }
                        )
                    } else {
                        newTree = addNode(newNode, parentNode.value, treeData.tree, {
                            isErrorHandling: false,
                            errorHandlingChilds: []
                        })
                    }
                } else {
                    // to replace parallel path node with action node if new node is action node
                    const newNode = {
                        kind: 'ForEach',
                        id: node.parallelPathNode.currentParallelPathNode.id.toString(),
                        input: [] as any,
                        name: node.name,
                        description: node.description,
                        meta_data: {
                            collection: node.selectedCollection
                        },
                        icon: '',
                        path:
                            parentNode.value.kind !== 'Setup'
                                ? [
                                      ...parentNode.value.path,
                                      {
                                          nodeId: node.parallelPathNode.currentParallelPathNode.id.toString(),
                                          actionId: '',
                                          forEachId: node.selectedCollection,
                                          forEachName: node.name
                                      }
                                  ]
                                : [{
                                    nodeId: node.parallelPathNode.currentParallelPathNode.id.toString(),
                                    actionId: '',
                                    forEachId: node.selectedCollection,
                                    forEachName: node.name
                                }],
                        output: {},
                        subflow: { children: [] }
                    } as NodeT

                    newTree = node.parallelPathNode.anotherEmptyChildren
                        ? updateParallelPathWithChildren(
                              newNode,
                              parentNode.value,
                              node.parallelPathNode.anotherEmptyChildren,
                              node.parallelPathNode.emptyChildrenPosition,
                              treeData.tree,
                              {
                                  isErrorHandling: false,
                                  errorHandlingChilds: []
                              }
                          )
                        : updateNode(newNode, treeData.tree === editTreeData.tree ? treeData.tree : editTreeData.tree)
                }
            } else if (rightPanelState.mode.mode === NodeMode.Edit) {
                const mode = rightPanelState.mode as NodeEditT
                const newNode = {
                    kind: 'ForEach',
                    id: mode.nodeEditInput.id.toString(),
                    input: [] as any,
                    name: node.name,
                    description: node.description,
                    meta_data: {
                        collection: node.selectedCollection
                    },
                    icon: '',
                    path: mode.nodeEditInput.path.map(path => {
                        if (path.nodeId === mode.nodeEditInput.id) {
                            return { ...path, forEachId: node.selectedCollection, forEachName: node.name }
                        }

                        return path
                    }),
                    output: {},
                    subflow: mode.nodeEditInput.subflow
                } as NodeT

                const updatedPathTree = updateForEachPathsInChilds(mode.nodeEditInput.id, treeData.tree, {
                    nodeId: mode.nodeEditInput.id.toString(),
                    actionId: '',
                    forEachId: node.selectedCollection,
                    forEachName: node.name
                })

                if (node.selectedCollection != mode.nodeEditInput.meta_data.collection) {
                    const contextVariableRemovedTree = removeChildrenContextVariables(
                        mode.nodeEditInput.id,
                        updatedPathTree,
                        undefined,
                        { errorHandling: false, action_id: '' }
                    )
                    newTree = updateNode(newNode, contextVariableRemovedTree)
                } else {
                    newTree = updateNode(newNode, updatedPathTree)
                }
            }
        } else if (node.nodeType === Nodes.Condition) {
            const parentNode = rightPanelState.parentNode

            if (rightPanelState.mode.mode === NodeMode.Add) {
                if (node.parallelPathNode.currentParallelPathNode === null) {
                    const id = generateUniqueId()

                    const newNode = {
                        kind: 'Condition',
                        id: id.toString(),
                        input: node.input,
                        name: node.conditionName,
                        description: node.description,
                        meta_data: {
                            ...node.subflowMetaData
                        },
                        icon: IfNodeIcon,
                        path:
                            parentNode.value.kind !== 'Setup'
                                ? [
                                      ...(node.addingInsideForEach
                                          ? parentNode.value.path
                                          : parentNode.value.path.filter(path => !path.forEachId))
                                  ]
                                : []
                    } as NodeT

                    // for adding flow inside for each
                    const subflow = node.subflowMetaData.subflow

                    const subflowMetaData = {
                        addingInsideForEach: subflow.hasSubFlow,
                        forEachNodeId: subflow.hasSubFlow ? subflow.forEachNodeId : '',
                        parentNode: parentNode.value
                    }

                    if (
                        ((node.addingInsideForEach &&
                            parentNode.value.kind == 'ForEach' &&
                            parentNode.value.subflow &&
                            parentNode.value.subflow.children.length > 1) ||
                            parentNode.children.length > 1) &&
                        node.parallelPathNode.replacingParallelSibling
                    ) {
                        // to replace position of parallel path sibling node if sibling is a parent with new condition node
                        newTree = addNewNodeAsParentToParallelNode(
                            newNode,
                            parentNode.value,
                            node.parallelPathNode.replacingParallelSibling,
                            treeData.tree,
                            {
                                isErrorHandling: false,
                                errorHandlingChilds: []
                            },
                            subflowMetaData
                        )
                    } else {
                        newTree = addNode(
                            newNode,
                            parentNode.value,
                            treeData.tree,
                            {
                                isErrorHandling: false,
                                errorHandlingChilds: []
                            },
                            subflowMetaData
                        )
                    }
                } else {
                    // to replace parallel path node with condition node if new node is condition node
                    const newNode = {
                        kind: 'Condition',
                        id: node.parallelPathNode.currentParallelPathNode.id.toString(),
                        input: node.input,
                        name: node.conditionName,
                        description: node.description,
                        meta_data: {
                            ...node.subflowMetaData
                        },
                        icon: IfNodeIcon,
                        path:
                            parentNode.value.kind !== 'Setup'
                                ? [
                                      ...(node.addingInsideForEach
                                          ? parentNode.value.path
                                          : parentNode.value.path.filter(path => !path.forEachId))
                                  ]
                                : []
                    } as NodeT

                    const subflow = node.subflowMetaData.subflow

                    newTree = node.parallelPathNode.anotherEmptyChildren
                        ? updateParallelPathWithChildren(
                              newNode,
                              parentNode.value,
                              node.parallelPathNode.anotherEmptyChildren,
                              node.parallelPathNode.emptyChildrenPosition,
                              treeData.tree,
                              { isErrorHandling: false, errorHandlingChilds: [] },
                              {
                                  addingInsideForEach: subflow.hasSubFlow,
                                  forEachNodeId: subflow.hasSubFlow ? subflow.forEachNodeId : '',
                                  parentNode: parentNode.value
                              }
                          )
                        : updateNode(newNode, treeData.tree === editTreeData.tree ? treeData.tree : editTreeData.tree, {
                              addingInsideForEach: subflow.hasSubFlow,
                              forEachNodeId: subflow.hasSubFlow ? subflow.forEachNodeId : '',
                              parentNode: parentNode.value
                          })
                }
            } else {
                const mode = rightPanelState.mode as NodeEditT
                const metaData = { ...mode.nodeEditInput.meta_data, ...node.subflowMetaData }
                const newNode = {
                    ...mode.nodeEditInput,
                    meta_data: metaData,
                    input: node.input,
                    name: node.conditionName,
                    description: node.description
                } as NodeT
                const subflow = node.subflowMetaData.subflow

                newTree = updateNode(newNode, treeData.tree, {
                    addingInsideForEach: subflow.hasSubFlow,
                    forEachNodeId: subflow.hasSubFlow ? subflow.forEachNodeId : '',
                    parentNode: parentNode.value
                })
            }
        } else if (node.nodeType === Nodes.Approval) {
            const parentNode = rightPanelState.parentNode

            if (rightPanelState.mode.mode === NodeMode.Add) {
                if (node.parallelPathNode.currentParallelPathNode === null) {
                    const id = generateUniqueId()

                    const newNode = {
                        kind: 'Approval',
                        id: id.toString(),
                        input: node.input,
                        name: node.name,
                        description: node.description,
                        meta_data: {
                            ...node.subflowMetaData
                        },
                        icon: ApprovalIcon,
                        path:
                            parentNode.value.kind !== 'Setup'
                                ? [
                                      ...(node.addingInsideForEach
                                          ? parentNode.value.path
                                          : parentNode.value.path.filter(path => !path.forEachId)),
                                      node.addingInsideForEach
                                          ? {
                                                actionId: '',
                                                nodeId: id,
                                                forEachNodeId:
                                                    node.addingInsideForEach && node.subflowMetaData.subflow.hasSubFlow
                                                        ? node.subflowMetaData.subflow.forEachNodeId
                                                        : '',
                                                approvalId: id
                                            }
                                          : {
                                                actionId: '',
                                                nodeId: id,
                                                approvalId: id
                                            }
                                  ]
                                : [
                                      {
                                          actionId: '',
                                          nodeId: id,
                                          approvalId: id
                                      }
                                  ]
                    } as NodeT

                    // for adding flow inside for each
                    const subflow = node.subflowMetaData.subflow

                    const subflowMetaData = {
                        addingInsideForEach: subflow.hasSubFlow,
                        forEachNodeId: subflow.hasSubFlow ? subflow.forEachNodeId : '',
                        parentNode: parentNode.value
                    }

                    if (
                        ((node.addingInsideForEach &&
                            parentNode.value.kind == 'ForEach' &&
                            parentNode.value.subflow &&
                            parentNode.value.subflow.children.length > 1) ||
                            parentNode.children.length > 1) &&
                        node.parallelPathNode.replacingParallelSibling
                    ) {
                        // to replace position of parallel path sibling node if sibling is a parent with new condition node
                        newTree = addNewNodeAsParentToParallelNode(
                            newNode,
                            parentNode.value,
                            node.parallelPathNode.replacingParallelSibling,
                            treeData.tree,
                            {
                                isErrorHandling: false,
                                errorHandlingChilds: []
                            },
                            subflowMetaData
                        )
                    } else {
                        newTree = addNode(
                            newNode,
                            parentNode.value,
                            treeData.tree,
                            {
                                isErrorHandling: false,
                                errorHandlingChilds: []
                            },
                            subflowMetaData
                        )
                    }
                } else {
                    // to replace parallel path node with condition node if new node is condition node
                    const newNode = {
                        kind: 'Approval',
                        id: node.parallelPathNode.currentParallelPathNode.id.toString(),
                        input: node.input,
                        name: node.name,
                        description: node.description,
                        meta_data: {
                            ...node.subflowMetaData
                        },
                        icon: ApprovalIcon,
                        path:
                            parentNode.value.kind !== 'Setup'
                                ? [
                                      ...(node.addingInsideForEach
                                          ? parentNode.value.path
                                          : parentNode.value.path.filter(path => !path.forEachId)),
                                      node.addingInsideForEach
                                          ? {
                                                actionId: '',
                                                nodeId: node.parallelPathNode.currentParallelPathNode.id.toString(),
                                                forEachNodeId:
                                                    node.addingInsideForEach && node.subflowMetaData.subflow.hasSubFlow
                                                        ? node.subflowMetaData.subflow.forEachNodeId
                                                        : '',
                                                approvalId: node.parallelPathNode.currentParallelPathNode.id.toString()
                                            }
                                          : {
                                                actionId: '',
                                                nodeId: node.parallelPathNode.currentParallelPathNode.id.toString(),
                                                approvalId: node.parallelPathNode.currentParallelPathNode.id.toString()
                                            }
                                  ]
                                : [
                                      {
                                          actionId: '',
                                          nodeId: node.parallelPathNode.currentParallelPathNode.id.toString(),
                                          approvalId: node.parallelPathNode.currentParallelPathNode.id.toString()
                                      }
                                  ]
                    } as NodeT

                    const subflow = node.subflowMetaData.subflow

                    newTree = node.parallelPathNode.anotherEmptyChildren
                        ? updateParallelPathWithChildren(
                              newNode,
                              parentNode.value,
                              node.parallelPathNode.anotherEmptyChildren,
                              node.parallelPathNode.emptyChildrenPosition,
                              treeData.tree,
                              { isErrorHandling: false, errorHandlingChilds: [] },
                              {
                                  addingInsideForEach: subflow.hasSubFlow,
                                  forEachNodeId: subflow.hasSubFlow ? subflow.forEachNodeId : '',
                                  parentNode: parentNode.value
                              }
                          )
                        : updateNode(newNode, treeData.tree === editTreeData.tree ? treeData.tree : editTreeData.tree, {
                              addingInsideForEach: subflow.hasSubFlow,
                              forEachNodeId: subflow.hasSubFlow ? subflow.forEachNodeId : '',
                              parentNode: parentNode.value
                          })
                }
            } else {
                const mode = rightPanelState.mode as NodeEditT
                const metaData = { ...mode.nodeEditInput.meta_data, ...node.subflowMetaData }
                const newNode = {
                    ...mode.nodeEditInput,
                    meta_data: metaData,
                    input: node.input,
                    name: node.name,
                    description: node.description
                } as NodeT
                const subflow = node.subflowMetaData.subflow

                newTree = updateNode(newNode, treeData.tree, {
                    addingInsideForEach: subflow.hasSubFlow,
                    forEachNodeId: subflow.hasSubFlow ? subflow.forEachNodeId : '',
                    parentNode: parentNode.value
                })
            }
        }

        function logEventsForAnalytics(node:
            | ActionsResponseT
            | TriggerResponseT
            | AppTriggerResponseT
            | ConditionResponseT
            | EndResponseT
            | FunctionResponseT
            | ForEachResponseT
            | ApprovalResponseT
            | WebHookResponseT
            )
        {
            switch(node.nodeType) {
                case Nodes.Action:
                const isActionHaveDynamicFields = node.actionInputsList.output.input.filter((inp)=>inp.config.kind == 'dictionary').length
                isActionHaveDynamicFields &&  logEvent(workspace, {
                        event:"EVENT::WORKFLOW::ACTION_WITH_DYNAMIC_FIELDS", event_meta:{
                        name: automationMeta.name,
                        description: automationMeta.description
                    }})
                    break;
                case Nodes.Condition:
                    logEvent(workspace, {
                        event:"EVENT::WORKFLOW::ADD_CONDITION", event_meta:{
                        name: automationMeta.name,
                        description: automationMeta.description
                    }})
                    break;
                case Nodes.Approval:
                    logEvent(workspace, {
                        event:"EVENT::WORKFLOW::ADD_APPROVAL", event_meta:{
                        name: automationMeta.name,
                        description: automationMeta.description
                    }})
                    break;
                case Nodes.Foreach:
                    logEvent(workspace, {
                        event:"EVENT::WORKFLOW::ADD_FOREACH", event_meta:{
                        name: automationMeta.name,
                        description: automationMeta.description
                    }})
                    break;
                case Nodes.Function:

                    const getFunctionType = (appName:string) =>{
                        if(appName=='Data Formaters'){
                            return "EVENT::WORKFLOW::ADD_DATA_FORMATTERS"
                        }else if(appName=='Data Operations'){
                            return "EVENT::WORKFLOW::ADD_DATA_OPERATIONS"
                        }else if(appName=='Date and Time Operations'){
                            return "EVENT::WORKFLOW::ADD_DATE_TIME_OPERATIONS"
                        }else{
                            return "EVENT::WORKFLOW::ADD_NUMBER_OPERATION"
                        }
                    }

                    const typeOfOperation = getFunctionType(node.selectedFunction.appName)
                    logEvent(workspace, {
                        event:typeOfOperation, event_meta:{
                        name: automationMeta.name,
                        description: automationMeta.description
                    }})
                    break;
                default:
                    return false
            }
        }

        await putJson(automationAPIURLWithWorkspace(workspace, AutomationApi.Update))()({
            custom_json: newTree,
            id: flowId
        })
            .then(res => {
                setDisableDeployButton([false])
                setTreeData({
                    ...treeData,
                    tree: newTree,
                    firstStepDone: true,
                    secondStepDone: newTree.children && newTree.children[0].children.length > 0
                })
                setEditTreeData({
                    tree: newTree,
                    firstStepDone: true,
                    secondStepDone: newTree.children && newTree.children[0].children.length > 0
                })

                setRightPanelState({
                    ...rightPanelState,
                    show: false,
                    mode: NodeView,
                    parentNode: { value: Tree, children: [] },
                    currentNode: ActionInitialState
                })
                const mode = rightPanelState.mode

                if(mode.mode === NodeMode.Add){
                    node.nodeType != Nodes.Trigger &&  node.nodeType != Nodes.End && node.nodeType != Nodes.Webhook && node.parallelPathNode.currentParallelPathNode === null ? 
                    logEventsForAnalytics(node)
                    : logEvent(workspace, {
                        event:"EVENT::WORKFLOW::ADD_PARALLEL_PATH", event_meta:{
                        name: automationMeta.name,
                        description: automationMeta.description
                     }})
              }

                if (journeyData.type === 'success' && isAction === true) {
                    journeyData.value.send({
                        type: 'EVENT::WORKFLOW::ACTION_SAVED',
                        tag: 'WORKFLOW_GENERIC_EVENT',
                        journeyType: 'Workflow'
                    })
                    isAction = false
                }



                // to remove error from node
                mode.mode == NodeMode.Edit && automationMeta.type != null
                    ? setAutomationMeta({
                          ...automationMeta,
                          nodesWithoutConnection: automationMeta.nodesWithoutConnection.filter(
                              node => mode.nodeEditInput.id != node
                          ),
                          type: res.data.output.type
                      })
                    : setAutomationMeta({
                          ...automationMeta,
                          type: res.data.output.type
                      })
            })
            .catch(err => {
                setRightPanelState({
                    ...rightPanelState,
                    mode: {
                        ...PopupError,
                        error: err.response
                            ? err.response.data.error
                            : 'Something went wrong!<br /> Please try again after sometime.'
                    }
                })
            })
    }

    async function deleteSelectedNode() {
        let newTree: any = {}

        const mode = rightPanelState.mode as NodeDeleteT

        const parentNode = rightPanelState.parentNode

        const subflow =
            mode.nodeEditInput.meta_data && mode.nodeEditInput.meta_data.subflow
                ? mode.nodeEditInput.meta_data.subflow
                : { hasSubFlow: false }

        const pathRemovedTree = removeChildrenPaths(mode.nodeEditInput, treeData.tree)
        const contextVariableRemovedTree = removeChildrenContextVariables(
            mode.nodeEditInput.id,
            pathRemovedTree,
            undefined,
            { errorHandling: false, action_id: '' }
        )
        newTree = deleteNode(
            mode.nodeEditInput,
            mode.parentNode,
            contextVariableRemovedTree,
            mode.nodeEditInput.kind == 'ErrorHandlingCondition',
            {
                addingInsideForEach: subflow.hasSubFlow,
                forEachNodeId: subflow.hasSubFlow ? subflow.forEachNodeId : '',
                parentNode: parentNode.value
            }
        )

        const flowId = props.match.params.id

        const workspace: string = props.match.params.workspacename

        setRightPanelState({
            ...rightPanelState,
            mode: { ...mode, loading: true }
        })

        await putJson(automationAPIURLWithWorkspace(workspace, AutomationApi.Update))()({
            custom_json: newTree,
            id: flowId
        })
            .then(_ => {
                setTreeData({
                    ...treeData,
                    tree: newTree,
                    secondStepDone: newTree.children && newTree.children[0].children.length > 0
                })

                setEditTreeData({
                    ...treeData,
                    tree: newTree,
                    secondStepDone: newTree.children && newTree.children[0].children.length > 0
                })

                setRightPanelState({
                    ...rightPanelState,
                    show: false,
                    mode: NodeView,
                    parentNode: { value: Tree, children: [] },
                    currentNode: ActionInitialState
                })
            })
            .catch(err => {
                setRightPanelState({
                    ...rightPanelState,
                    mode: {
                        ...NodeDelete,
                        error: {
                            error: true,
                            info: err.response
                                ? err.response.data.error
                                : 'Something went wrong!<br /> Please try again after sometime.'
                        }
                    }
                })
            })
    }

    const renderDeleteModal = () => {
        const parentNode = rightPanelState.parentNode

        if (rightPanelState.mode.mode === NodeMode.Delete) {
            const mode = rightPanelState.mode
            return (
                <Modal error={mode.error} showPopupLoader={mode.loading}>
                    <ErrorBoundary
                        error={mode.error}
                        render={(err: any, info: any) => {
                            return (
                                err && (
                                    <Error.Delete
                                        onClick={() => {
                                            setRightPanelState({
                                                ...rightPanelState,
                                                show: false,
                                                mode: NodeView,
                                                parentNode: { value: Tree, children: [] },
                                                currentNode: ActionInitialState
                                            })
                                        }}
                                        info={info}
                                    />
                                )
                            )
                        }}
                    >
                        {mode.nodeEditInput.kind !== 'ParallelPath' ? (
                            <React.Fragment>
                                <h2>Delete {mode.nodeEditInput.name}?</h2>
                                {parentNode.children.length < 2 && mode.currentNode.children.length < 2 ? (
                                    mode.nodeEditInput.kind == 'ForEach' ? (
                                        <p>
                                            Are you sure to delete the For Each option. All the flows added inside For
                                            Each will be deleted{' '}
                                        </p>
                                    ) : (
                                        <p>
                                            Are you sure to delete the selected {getPanelType(mode.nodeEditInput.kind)}
                                        </p>
                                    )
                                ) : (
                                    <p>
                                        Deleting “{mode.nodeEditInput.name}” will delete the flow below this automation
                                        node. Do you want to continue?
                                    </p>
                                )}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <h2>Delete parallel path?</h2>
                                <p>Are you sure to delete the selected path?</p>
                            </React.Fragment>
                        )}
                        <ButtonWrapper margin="margintop">
                            <Button
                                primary
                                type="button"
                                data-dismiss="modal"
                                onClick={() => {
                                    setRightPanelState({
                                        ...rightPanelState,
                                        mode: NodeView
                                    })
                                }}
                            >
                                <label>No</label>
                            </Button>
                            <Button
                                type="button"
                                data-dismiss="modal"
                                onClick={() => {
                                    deleteSelectedNode()
                                }}
                            >
                                <label>Yes</label>
                            </Button>
                        </ButtonWrapper>
                    </ErrorBoundary>
                </Modal>
            )
        } else return null
    }

    const currentTree = rightPanelState.mode.mode == NodeMode.View ? treeData : editTreeData

    if (nonCanvasAction.mode === NonCanvasActionModes.Loading)
        return <Loader.PageLoader className="flow_canvas_page_loader" show={true} />

    const checkAllNodeHasInputs = R.concat(treeData.tree.value.kind != 'WebhookTrigger'?[treeData.tree.value]:[], reduceNodes(treeData.tree.children[0].children[0])).map((c:any)=>{
        return checkInputHasValue(c) && 
     (((c.kind == 'Action'||c.kind=='EventTrigger'||c.kind=='AppTrigger') && c.meta_data && c.meta_data.authentication_id &&
        c.meta_data.authentication_id.length > 0) ||
        automationMeta.nodesWithoutConnection.indexOf(c.id) != -1) || c.kind !== 'Action'
    }).every((val)=>val)

    return (
        <ErrorBoundary
            resourceNotFound={nonCanvasAction.mode === NonCanvasActionModes.NotFound}
            error={{
                error: nonCanvasAction.mode === NonCanvasActionModes.Error,
                info: 'Something went wrong!<br /> Please try again after sometime.'
            }}
            render={(err: any, info: any) => {
                return (
                    err && (
                        <Error.PageLoadingError
                            onClick={() => {
                                setNonCanvasAction(Loading), props.retry()
                            }}
                            btnName="Retry"
                            info={info}
                        />
                    )
                )
            }}
        >
            <SidePanelContext.Provider
                value={{
                    rightPanelState,
                    setRightPanelState,
                    saveTree,
                    editTreeData,
                    setEditTreeData,
                    treeData,
                    setTreeData,
                    disableDeployButton,
                    setDisableDeployButton,
                    setAutomationMetaType,
                    active_automation: automationMeta.active_status,
                    hasLinkedDialogs: automationMeta.isLinkedToDialog,
                    automationMeta
                }}
            >
                <React.Fragment>
                    <div
                        className={`automation_canvas ${
                            journeyData.type === 'success' &&
                            journeyData.value
                                .state()
                                .actions.filter(
                                    x =>
                                        x.name === 'test-workflow' ||
                                        x.name === 'connection-missing' ||
                                        x.name === 'activate-workflow'
                                ).length > 0
                                ? 'tour_automation_canvas'
                                : ''
                        }`}
                    >
                        <div className="flex_canvas_right">
                            <LeftSidePanelContext.Provider
                                value={{
                                    leftPanelState,
                                    setLeftPanelState
                                }}
                            >
                                {leftPanelState.show ? (
                                    <div className="flex_side_menu">
                                        <NonCanvasPanelContext.Provider
                                            value={{
                                                actionState: [nonCanvasAction, setNonCanvasAction],
                                                parentAutomation: automationMeta,
                                                workspaceName: workspace,
                                                parentId: automationId
                                            }}
                                        >
                                            <CanvasLeftPanel />
                                        </NonCanvasPanelContext.Provider>
                                        <span
                                            className="sub_panel_toggle_btn flow_canvas_close_arrow"
                                            onClick={() => {
                                                setLeftPanelState({ ...leftPanelState, show: false })
                                            }}
                                        >
                                            <RightArrowWithOutCircle />
                                        </span>
                                    </div>
                                ) : (
                                    <span
                                        className={`sub_panel_toggle_btn closed_arrow flow_canvas_open_arrow ${sideBarContext.userData  && !sideBarContext.userData.verified ? 'top-103' : ''}`}
                                        onClick={() => {
                                            setLeftPanelState({ ...leftPanelState, show: true })
                                        }}
                                    >
                                        <RightArrowWithOutCircle />
                                    </span>
                                )}
                            </LeftSidePanelContext.Provider>

                            <section className=" automation_entity_cards">
                                <FlowCanvasHeader class_={'flow__header'}>
                                    <WorkflowHeaderChild type="first" key={0}>
                                        <div className="indent_left">
                                            <div className="indent_title autoamtion_p_l_30">
                                                <HeaderInner>
                                                    <div className="indent_title_wrapper">
                                                        <div className="indent_title_left">
                                                            <SmallButton onClick={() => window.history.back()}>
                                                                <span>
                                                                    <ArrowIcon />
                                                                </span>{' '}
                                                                <label>Back</label>
                                                            </SmallButton>
                                                        </div>
                                                        <div className="indent_title_right">
                                                            
                                                            <div className={automationMeta.description.length == 0 ? "flow_canvas_header_back_button description_hide" : "flow_canvas_header_back_button"}>
                                                                {automationMeta.name}
                                                                {rightPanelState.mode.mode == NodeMode.View && (
                                                                    <IconButton
                                                                        onClick={() => setToAutomationMetaEditMode()}
                                                                    >
                                                                        <span>
                                                                            <EditIconWithCircle />
                                                                        </span>
                                                                    </IconButton>
                                                                )}
                                                            </div>
                                                            {automationMeta.description.length > 0 && <p>{automationMeta.description}</p>}
                                                        </div>
                                                    </div>
                                                </HeaderInner>
                                            </div>
                                        </div>
                                    </WorkflowHeaderChild>
                                    <HeaderChild type="second" key={1}>
                                        <div className="flow_canvas_header_right_automation autoamtion_p_r_40">
                                            <div className="flow_canvas_header_back_button">
                                                {automationMeta.active_status && automationMeta.isLinkedToDialog ? (
                                                    <span className="no_input_value">
                                                        <div className="error_input_show">
                                                            <WarningAlertIcon />
                                                            <span className="error_menu_hvr">
                                                                Automation linked with Dialog. You can only modify
                                                                Action connection details.
                                                            </span>
                                                        </div>
                                                    </span>
                                                ) : null}
                                                {/* start/stop *
                                                <div className="trigger_start_stop_btn">
                                                    <span className="start_stop_triggers">
                                                        <label className="switch_triggers">
                                                            <input type="checkbox" id="togBtn" />
                                                            <div className="slider_trigger round_trigger" />
                                                        </label>
                                                    </span>
                                                </div>
                                                {/* start/stop */}
                                                <Button
                                                    primary
                                                    onClick={() => {
                                                        rightPanelState.mode.mode === NodeMode.View &&
                                                            setRightPanelState({
                                                                ...rightPanelState,
                                                                show: true,
                                                                currentNode: EndResponseInitialState,
                                                                mode: {
                                                                    ...NodeEdit,
                                                                    nodeEditInput: currentTree.tree.children[1].value
                                                                }
                                                            })
                                                    }}
                                                    className={
                                                        rightPanelState.mode.mode === NodeMode.View
                                                            ? `button_right ${
                                                                  journeyData.type === 'success' &&
                                                                  journeyData.value
                                                                      .state()
                                                                      .actions.filter(x => x.name === 'test-workflow')
                                                                      .length > 0
                                                                      ? 'negative_z_index_check_1'
                                                                      : ''
                                                              }`
                                                            : `button_right editor_btn_disabled ${
                                                                  journeyData.type === 'success' &&
                                                                  journeyData.value
                                                                      .state()
                                                                      .actions.filter(x => x.name === 'test-workflow')
                                                                      .length > 0
                                                                      ? 'negative_z_index_check_1'
                                                                      : ''
                                                              }`
                                                    }
                                                >
                                                    {' '}
                                                    <span className="header_button">
                                                        <ExecutionReportIcon />
                                                    </span>
                                                    <label>Execution Report</label>
                                                </Button>
                                                <span
                                                    className={
                                                        rightPanelState.mode.mode === NodeMode.View &&
                                                        (treeData.secondStepDone ||
                                                            (treeData.firstStepDone &&
                                                                (currentTree.tree.value.kind == 'AppTrigger' ||
                                                                    currentTree.tree.value.kind == 'EventTrigger'))) &&
                                                        !disableDeployButton.filter(x => x)[0]
                                                            ? `buton_back negative_z_index_check ${
                                                                  journeyData.type === 'success' &&
                                                                  journeyData.value
                                                                      .state()
                                                                      .actions.filter(x => x.name === 'test-workflow')
                                                                      .length > 0
                                                                      ? 'z_index_buton_back'
                                                                      : ''
                                                              }`
                                                            : 'buton_back editor_btn_disabled'
                                                    }
                                                    onClick={() => {
                                                        setRightPanelState({ ...rightPanelState, mode: NodeEdit })
                                                        setTestingPanelView(true)
                                                        if (journeyData.type === 'success') {
                                                            journeyData.value.send({
                                                                type: 'EVENT::WORKFLOW::TO_BE_TESTED',
                                                                tag: 'WORKFLOW_GENERIC_EVENT',
                                                                journeyType: 'Workflow'
                                                            })
                                                        }
                                                    }}
                                                >
                                                    <TestAutomationIcon />
                                                    <span className="buton_name_hover">Test</span>
                                                </span>
                                                <span
                                                    className={
                                                        rightPanelState.mode.mode === NodeMode.View
                                                            ? 'buton_back negative_z_index_check'
                                                            : 'buton_back editor_btn_disabled negative_z_index_check'
                                                    }
                                                    onClick={() => setToSaveAsAutomationMode()}
                                                >
                                                    <SaveAutomation />
                                                    <span className="buton_name_hover negative_z_index_check">
                                                        Duplicate
                                                    </span>
                                                </span>
                                                {/* Canvas Tour Start  */}
                                                {journeyData.type === 'success' &&
                                                journeyData.value
                                                    .state()
                                                    .actions.filter(x => x.name === 'test-workflow').length > 0 ? (
                                                    <React.Fragment>
                                                        <div className="z__index" />
                                                        <div className="tooltip_wrapper margin-left-34">
                                                            <div className="tooltip_wrapper_bg">
                                                                <p>
                                                                    Let’s test your workflow by clicking on the Test
                                                                    icon.
                                                                </p>
                                                                {/* <Button
                                                                    onClick={(event: any) => {
                                                                        event.stopPropagation()
                                                                        if (journeyData.type === 'success') {
                                                                            journeyData.value.send({
                                                                                type: 'EVENT::WORKFLOW::TO_BE_TESTED',
                                                                                tag: 'WORKFLOW_GENERIC_EVENT',
                                                                                journeyType: 'Workflow'
                                                                            })
                                                                        }
                                                                    }}
                                                                >
                                                                    Ok
                                                                </Button> */}
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                ) : null}
                                                {/*Canvas Tour End  */}
                                                <span
                                                    className={
                                                        // disableDeployButton.filter(x => x)[0] ||
                                                        rightPanelState.mode.mode !== NodeMode.View ||
                                                        // automationMeta.active_status ||
                                                        (!automationMeta.active_status && !treeData.secondStepDone) || !checkAllNodeHasInputs
                                                            ? 'home_edit negative_z_index_check show_hide _auto_switch editor_btn_disabled'
                                                            : `show_hide negative_z_index_check _auto_switch ${
                                                                  journeyData.type === 'success' &&
                                                                  journeyData.value
                                                                      .state()
                                                                      .actions.filter(
                                                                          x => x.name === 'activate-workflow'
                                                                      ).length > 0
                                                                      ? 'z_index_buton_back'
                                                                      : ''
                                                              }`
                                                    }
                                                >
                                                    <div
                                                        className="switch_box box_1"
                                                        onClick={() => {
                                                            !automationMeta.active_status
                                                                ? setToActivateAutomationMode()
                                                                : setToDeActivateAutomationMode()
                                                            if (journeyData.type === 'success') {
                                                                journeyData.value.send({
                                                                    type: 'EVENT::WORKFLOW::ACTIVATED',
                                                                    tag: 'WORKFLOW_GENERIC_EVENT',
                                                                    journeyType: 'Workflow'
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            className="switch_1 automation_home_switch_without_hover"
                                                            checked={automationMeta.active_status}
                                                        />
                                                    </div>
                                                </span>
                                                {journeyData.type === 'success' &&
                                                journeyData.value
                                                    .state()
                                                    .actions.filter(x => x.name === 'activate-workflow').length > 0 ? (
                                                    <React.Fragment>
                                                        <div className="z__index" />
                                                        <div className="tooltip_wrapper">
                                                            <div className="tooltip_wrapper_bg">
                                                                <p>
                                                                    Let’s activate the Workflow (to use while building
                                                                    chatbots)
                                                                </p>
                                                                {/* <Button
                                                                    onClick={(event: any) => {
                                                                        event.stopPropagation()
                                                                        if (journeyData.type === 'success') {
                                                                            journeyData.value.send({
                                                                                type: 'EVENT::WORKFLOW::ACTIVATED',
                                                                                tag: 'WORKFLOW_GENERIC_EVENT',
                                                                                journeyType: 'Workflow'
                                                                            })
                                                                        }
                                                                    }}
                                                                >
                                                                    Ok
                                                                </Button> */}
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                ) : null}

                                                {/* <span
                                                    className={
                                                        disableDeployButton.filter(x => x)[0] ||
                                                        rightPanelState.mode.mode !== NodeMode.View ||
                                                        automationMeta.active_status ||
                                                        !treeData.secondStepDone
                                                            ? 'buton_back editor_btn_disabled negative_z_index_check'
                                                            : 'buton_back negative_z_index_check'
                                                    }
                                                    onClick={() => setToDeployAutomationMode()}
                                                >
                                                    <DeployAutomation />
                                                    <span className="buton_name_hover">Deploy</span>
                                                </span> */}
                                                {showUploadMarketPlaceButton && (
                                                    <span
                                                        className={
                                                            automationMeta.deployed_status != 'saved' &&
                                                            rightPanelState.mode.mode == NodeMode.View
                                                                ? 'buton_back'
                                                                : 'buton_back editor_btn_disabled'
                                                        }
                                                        onClick={() =>
                                                            setToUploadMode(automationId, automationMeta.name)
                                                        }
                                                    >
                                                        <UploadToMarketplace />
                                                        <span className="buton_name_hover">Upload</span>
                                                    </span>
                                                )}

                                                <VideoButtonB id={'APP_WORKFLOW_CANVAS_PAGE'} />
                                            </div>
                                        </div>
                                    </HeaderChild>
                                </FlowCanvasHeader>

                                <section className="automation_canvas ">
                                    <div className="automation_canvas_left">
                                        <div
                                            className="automation_canvas_left_auto_width"
                                            id="automation_canvas_left_auto_width"
                                        >
                                            <div className="canvas_fullwidth">
                                                {/* <div className="automation_canvas_left_structure"> */}
                                                <div className="flow_structure">
                                                    <ul className="auto_width_panel" id="auto_width_panel">
                                                        <li className="second_step">
                                                            <SecondStepNode
                                                                node={currentTree.tree.children[0]}
                                                                index={0}
                                                                showEmptyMenu={
                                                                    !currentTree.firstStepDone && !rightPanelState.show
                                                                }
                                                                showStep1Menu={treeData.firstStepDone}
                                                                show={true}
                                                                secondStepChildren={
                                                                    <NonCanvasPanelContext.Provider
                                                                        value={{
                                                                            actionState: [
                                                                                nonCanvasAction,
                                                                                setNonCanvasAction
                                                                            ],
                                                                            parentAutomation: automationMeta,
                                                                            workspaceName: workspace,
                                                                            parentId: automationId
                                                                        }}
                                                                    >
                                                                        <RenderingAllChildren
                                                                            node={currentTree.tree.children[0]}
                                                                            index={0}
                                                                        />
                                                                    </NonCanvasPanelContext.Provider>
                                                                }
                                                                treeData={treeData.tree}
                                                            >
                                                                <TriggerNode
                                                                    automationMeta={automationMeta}
                                                                    TreeData={currentTree.tree}
                                                                    show={true}
                                                                    nodesWithoutConnection={
                                                                        automationMeta.nodesWithoutConnection
                                                                    }
                                                                    // onClick={() => {}}
                                                                    active={rightPanelState.currentNode.nodeType===Nodes.Webhook||rightPanelState.currentNode.nodeType===Nodes.Trigger}
                                                                    onClick={() => {
                                                                        rightPanelState.mode.mode ===
                                                                            NodeMode.View &&
                                                                            setRightPanelState({
                                                                                ...rightPanelState,
                                                                                show: true,
                                                                                currentTrigger: isAssistant ? "no_trigger" : currentTree.tree.value.kind == "WebhookTrigger"
                                                                                    ? "webhook"
                                                                                    : "application",
                                                                                currentNode:
                                                                                isAssistant ? (currentTree.tree.value.kind =="NoTrigger" ? NoTriggerInitialState : AppTriggerInitialState)
                                                                                    : currentTree.tree.value.kind == "AppTrigger" || currentTree.tree.value.kind == "EventTrigger" ? AppTriggerInitialState: {
                                                                                        ...WebhookInitialState,
                                                                                        name:
                                                                                        currentTree.tree.value
                                                                                            .meta_data &&
                                                                                        currentTree.tree.value
                                                                                            .meta_data.name
                                                                                            ? currentTree.tree.value
                                                                                                .meta_data.name
                                                                                            : "",
                                                                                        webhookType:
                                                                                        currentTree.tree.value
                                                                                            .meta_data &&
                                                                                        currentTree.tree.value
                                                                                            .meta_data.webhookType
                                                                                            ? currentTree.tree.value
                                                                                                .meta_data.webhookType
                                                                                            : "GET",
                                                                                        trigger: {
                                                                                        ...WebhookInitialState.trigger,
                                                                                        outputSchema:
                                                                                            currentTree.tree.value
                                                                                            .output,
                                                                                        triggerMeta: {
                                                                                            inputKeys: noTriggerInitialData(
                                                                                            currentTree
                                                                                            ) as (InputKey & {
                                                                                            optional: boolean;
                                                                                            })[],
                                                                                            triggerType: "Webhook",
                                                                                        },
                                                                                        },
                                                                                        authentication:
                                                                                        currentTree.tree.value
                                                                                            .meta_data.auth == "basic"
                                                                                            ? {
                                                                                                auth:
                                                                                                WebhookAuthType.Basic,
                                                                                                authId:
                                                                                                currentTree.tree.value
                                                                                                    .meta_data.authId,
                                                                                                password: "",
                                                                                                username: "",
                                                                                            }
                                                                                            : {
                                                                                                auth:
                                                                                                WebhookAuthType.None,
                                                                                            },
                                                                                    },
                                                                                mode: {
                                                                                ...NodeEdit,
                                                                                nodeEditInput: currentTree.tree.value,
                                                                                },
                                                                            });
                                                                        }}
                                                                    // onClick={() => {
                                                                    //     rightPanelState.mode.mode === NodeMode.View &&
                                                                    //         setRightPanelState({
                                                                    //             ...rightPanelState,
                                                                    //             show: true,
                                                                    //             currentNode:
                                                                    //                 currentTree.tree.value.kind ==
                                                                    //                 'NoTrigger'
                                                                    //                     ? NoTriggerInitialState
                                                                    //                     : AppTriggerInitialState,
                                                                    //             mode: {
                                                                    //                 ...NodeEdit,
                                                                    //                 nodeEditInput:
                                                                    //                     currentTree.tree.value
                                                                    //             }
                                                                    //         })
                                                                    // }}
                                                                />
                                                            </SecondStepNode>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <NonCanvasPanelContext.Provider
                                        value={{
                                            actionState: [nonCanvasAction, setNonCanvasAction],
                                            parentAutomation: automationMeta,
                                            workspaceName: workspace,
                                            parentId: automationId
                                        }}
                                    >
                                        <RightPanel
                                            {...{ ...props, setResponseData: setResponseData }}
                                            showUploadPanel={
                                                nonCanvasAction.mode == NonCanvasActionModes.UploadToMarketPlace
                                            }
                                            deleteConnection={[setToDeleteConnection, deleteConnection]}
                                            _pollingTimeAccess={_pollingTimeAccess.data && _pollingTimeAccess.data.output}
                                        />
                                    </NonCanvasPanelContext.Provider>
                                    {/* Automation testing  panel */}
                                    {testingPanelView && (
                                        <FixedBgWRapper>
                                            <AutomationTestPanel
                                                {...props}
                                                treeData={treeData.tree}
                                                flowName={automationMeta.name}
                                                closeTestingPanel={() => {
                                                    setRightPanelState({ ...rightPanelState, mode: NodeView })
                                                    setTestingPanelView(false)
                                                }}
                                                webhookTrigger={
                                                    currentTree.tree.value.kind == "WebhookTrigger"
                                                }
                                                eventTrigger={
                                                    currentTree.tree.value.kind == "EventTrigger"
                                                }
                                                flowtype={
                                                    currentTree.tree.value.kind == 'AppTrigger' ||
                                                    currentTree.tree.value.kind == 'EventTrigger'
                                                        ? 'trigger'
                                                        : 'notrigger'
                                                }
                                            />
                                        </FixedBgWRapper>
                                    )}
                                    {/* Automation testing  panel */}
                                </section>
                            </section>
                        </div>
                        {renderDeleteModal()}
                        {renderMetaEditModal()}
                        {renderDeployAutomation(automationMeta.type)}
                        {renderActivateAutomation(automationMeta.type)}
                        {deactivateModal(automationMeta.type, linkedWithDialogs.length > 0, {
                            dialog: linkedWithDialogs,
                            automationName: automationMeta.name,
                            workspacename: workspace,
                            history: props.history
                        })}
                        {renderSaveAsAutomation()}
                        {renderAcknowledgeModal()}
                        {renderDeleteConnection()}
                    </div>
                </React.Fragment>
            </SidePanelContext.Provider>
        </ErrorBoundary>
    )
}

function DefaultStepNodes(props: { TreeData: TreeT<NodeT>; show: boolean; color: string; className: string }) {
    const { TreeData, show, color, className } = props

    return (
        <div
            className={
                show ? `flow_tree flow_parent ${className}` : `flow_tree flow_parent editor_btn_disabled ${className}`
            }
        >
            <div className="first_step">
                <span className="bg_tree_flow" style={{ background: color }}>
                    <img src={TreeData.value.icon} alt="" />
                </span>
                <span className="bg_tree_content">{TreeData.value.name}</span>
            </div>
            {(props.className === 'start_step_node' || props.className === 'end_step_node') && (
                <span className="arrow">
                    <img src={arrow_icon} />
                </span>
            )}
        </div>
    )
}

function EndNode(props: { TreeData: TreeT<NodeT>; show: boolean; color: string; onClick: () => void }) {
    const { TreeData, show, color } = props

    return (
        <div
            className={
                show ? `flow_tree flow_parent end_step_node` : `flow_tree flow_parent editor_btn_disabled end_step_node`
            }
            onClick={props.onClick}
        >
            <div className="first_step">
                <span className="bg_tree_flow" style={{ background: color }}>
                    <img src={TreeData.value.icon} alt="" />
                </span>
                <span className="bg_tree_content">{TreeData.value.name}</span>
            </div>
            <span className="arrow">
                <img src={arrow_icon} />
            </span>
        </div>
    )
}

const TriggerNode = (props: {
    TreeData: TreeT<NodeT>
    show: boolean
    onClick: any
    nodesWithoutConnection: string[]
    active:boolean
    automationMeta: AutomationMeta
}) => {
    const { TreeData, show, nodesWithoutConnection,active } = props

    return (
        <div
            className={
                show
                    ? `flow_tree flow_parent start_step_node cursor_auto ${active?"selected_parent_node":""}`
                    : `flow_tree flow_parent editor_btn_disabled start_step_node ${active?"selected_parent_node":""}`
            }
            onClick={props.onClick}
        >
            <div className="first_step">
                {TreeData.value.kind !== 'AppTrigger' && TreeData.value.kind !== 'EventTrigger' ? (
                    <span className="bg_tree_flow" style={{ background: '#C7E3FF' }}>
                        {/* <img src={TreeData.value.icon} alt="" /> */}
                        {getProductId() === "ASSISTANT" ? <FlowCanvasIcons.StartIcon /> : <WebhooksIcon />}
                    </span>
                ) : (
                    <span className="tree_node bg_tree_flow">
                        <img src={TreeData.value.icon} alt="" />
                    </span> 
                )}
                {/* <!--- warning icon --> */}
                    { props.automationMeta.type != null && (TreeData.value.kind === 'AppTrigger' || TreeData.value.kind === 'EventTrigger') &&
                        (!TreeData.value.meta_data ||
                            !TreeData.value.meta_data.authentication_id ||
                            TreeData.value.meta_data.authentication_id.length == 0 ||
                            nodesWithoutConnection.indexOf(TreeData.value.id) != -1) &&
                    <span className="tree_node bg_tree_flow waring_icon">
                    <span className="no_input_value">
                    <div className="error_input_show">
                        <WarningAlertIcon />
                        <span className="error_menu_hvr">Connection details are missing.</span>
                        </div>
                    </span>
                    </span>
                }

                {getProductId() === "ASSISTANT" ? 
                    <span className="bg_tree_content">
                        {/* {!TreeData.value.name.includes('Step 1') ? 'Step 1 - ' + TreeData.value.name : TreeData.value.name} */}
                        {TreeData.value.name}
                    </span>
                    : 
                    <span className="bg_tree_content">
                        {/* {!TreeData.value.name.includes('Step 1') ? 'Step 1 - ' + TreeData.value.name : TreeData.value.name} */}
                        {TreeData.value.name}
                    </span>}
                    
                    
                
                {/* {(TreeData.value.kind == 'AppTrigger' || TreeData.value.kind == 'EventTrigger') &&
                    TreeData.value.meta_data &&
                        (!TreeData.value.meta_data.authentication_id ||
                        TreeData.value.meta_data.authentication_id.length == 0 ||
                        nodesWithoutConnection.indexOf(TreeData.value.id) != -1) && (
                        <span className="no_input_value">
                            <div className="error_input_show">
                                <WarningAlertIcon />
                                <span className="error_menu_hvr">Connection details are missing.</span>
                            </div>
                        </span>
                    )} */}
            </div>
            {/* <span className="arrow">
                <img src={arrowIcon} />
            </span> */}
        </div>
    )
}

function SecondStepNode(props: {
    node: TreeT<NodeT>
    index: number
    show: boolean
    showStep1Menu: boolean
    children: React.ReactChild
    secondStepChildren: React.ReactChild
    showEmptyMenu: boolean
    treeData: TreeT<NodeT>
}) {
    const { node, index, show, showStep1Menu, showEmptyMenu, treeData } = props

    const [showStep1, showStep1Dropdown] = useState(showEmptyMenu)

    return (
        <React.Fragment>
            <div className="step1_vertical_wrapper">
                <div className="start_workflow_if">
                    <div className="start_workflow_if_badge">
                        <p>
                            <i>Start workflow if</i>
                        </p>
                    </div>
                    {props.children}
                </div>
            </div>
            {(treeData.value.meta_data || getProductId()==='ASSISTANT') && <div className="step2_vertical_wrapper">
                <>
                    {node.children.length > 0 && (
                        <div className="then_vertical">
                            <div className="start_workflow_if_badge">
                                <p>
                                    <i>Then</i>
                                </p>
                            </div>
                        </div>
                    )}
                    {show && secondStepChild(node, index, node, node.children.length == 0 ? true : false)}
                    {props.secondStepChildren}
                </>
            </div>}
        </React.Fragment>
    )
}

function secondStepChild(node: TreeT<NodeT>, index: number, parentNode: TreeT<NodeT>, showInitialCreateNode: boolean) {
    return (
        <T.SecondStepChildWithWrap
            hasSingleChildern={node.children.length === 1}
            node={node}
            parentNode={parentNode}
            isOpen={showInitialCreateNode}
            render={(makeDropDownVisible: () => void, MenuElement: React.ReactNode) => {
                return showInitialCreateNode ? (
                    <>
                        <div className="start_workflow_if_border">
                            <p>
                                <i>Then</i>
                            </p>
                            <div className="arrow_menu_dropdown">
                                <span className="arrow_menu">
                                    <div className="line_hover_plus_icon_svg" onClick={makeDropDownVisible}>
                                        <FlowCanvasIcons.ArrowPlusIcon />
                                    </div>
                                </span>
                            </div>
                            {MenuElement}
                        </div>
                    </>
                ) : (
                    <div className={`flow_tree flow_parent second_step_node`}>
                        <div className="line_hover_plus_icon">
                            <div className="line_hover_plus_icon_svg" onClick={makeDropDownVisible}>
                                <FlowCanvasIcons.ArrowPlusIcon />
                            </div>
                        </div>
                        <div className="second_node_first_step">
                            <span className="bg_tree_flow" style={{ background: '#e6edf6' }}>
                                <img src={node.value.icon} alt="" />
                            </span>
                            <span className="bg_tree_content">{node.value.name}</span>
                        </div>
                        {MenuElement}
                    </div>
                )
            }}
        />
    )
}

function RenderingAllChildren(props: { node: TreeT<NodeT>; index: number }) {
    const { node, index } = props

    return node.children.length > 0 ? (
        <div className="panel_tree">
            {node.children.length === 1
                ? singleChild(node.children[0], index, node, false, false)
                : multiChild(node, index, node)}
            {/* Canvas Tour Start  */}

            {/*Canvas Tour End  */}
        </div>
    ) : null
}

function singleChild(
    node: TreeT<NodeT>,
    index: number,
    parentNode: TreeT<NodeT>,
    comingUnderParallelPath: boolean,
    insideForEach?: boolean,
    subflowChildren?: TreeT<NodeT>[]
) {
    const journeyData = useJourney()

    const makeItUnclikable =
        journeyData.type === 'success' &&
        journeyData.value.state().actions.filter(x => x.name === 'connection-missing').length > 0
    return (
        <React.Fragment>
            <T.SingleChildWrap
                hasSingleChildern={node.children.length === 1}
                node={node}
                parentNode={parentNode}
                withArrow={node.value.kind !== 'EmptyAction' && parentNode.value.kind !== 'EmptyAction'}
                insideForEach={insideForEach ? insideForEach : false}
                disableMouseEvent={makeItUnclikable}
            >
                {constructNode(
                    node,
                    index,
                    { kind: 'single' },
                    parentNode,
                    comingUnderParallelPath ? comingUnderParallelPath : false,
                    subflowChildren,
                    insideForEach
                )}
            </T.SingleChildWrap>
            {journeyData.type === 'success' &&
            journeyData.value.state().actions.filter(x => x.name === 'connection-missing').length > 0 ? (
                <React.Fragment>
                    <div className="z__index" />
                    <div className="tooltip_wrapper">
                        <div className="tooltip_wrapper_bg">
                            <p>Connect your application to use this workflow</p>
                            <Button
                                onClick={() => {
                                    if (journeyData.type === 'success') {
                                        journeyData.value.send({
                                            type: 'EVENT::WORKFLOW::CONNECTION_MISSING_ACKNOWLEDGED',
                                            tag: 'WORKFLOW_GENERIC_EVENT',
                                            journeyType: 'Workflow'
                                        })
                                    }
                                }}
                            >
                                <label>Ok</label>
                            </Button>
                        </div>
                    </div>
                </React.Fragment>
            ) : null}
        </React.Fragment>
    )
}

function multiChild(
    nodes: TreeT<NodeT>,
    index: number,
    parentNode: TreeT<NodeT>,
    subflowChildren?: TreeT<NodeT>[],
    insideForEach?: boolean
) {
    return (
        <T.MultiChildWrap parentNode={parentNode}>
            {nodes.children.map((node: TreeT<NodeT>, index: number) => {
                const position: NodePosition = {
                    kind: 'multi',
                    positionType: index % 2 != 0 ? 'odd' : 'even'
                }
                return constructNode(node, index, position, parentNode, true, subflowChildren, insideForEach)
            })}
        </T.MultiChildWrap>
    )
}

function populateForEachChilds(subflow: { children: TreeT<NodeT>[] } | undefined, index: number, node: TreeT<NodeT>) {
    if (!subflow || !subflow.children || subflow.children.length == 0) {
        return null
    } else {
        const childs = subflow as TreeT<NodeT>
        // console.log(subflow)
        return (
            <>
                {childs.children.length == 1
                    ? singleChild(childs.children[0], index, node, false, true, subflow.children)
                    : multiChild(childs, index, node, subflow.children, true)}
            </>
        )
    }
}

function constructNode(
    node: TreeT<NodeT>,
    index: number,
    position: NodePosition,
    parentNode: TreeT<NodeT>,
    comingUnderParallelPath: boolean,
    subflowChildren?: TreeT<NodeT>[],
    insideForEach?: boolean
) {
    if (node.children.length === 0) {
        if (
            node.value.kind === 'EmptyAction' ||
            node.value.kind === 'EmptyFunction' ||
            node.value.kind === 'EmptyForEach' ||
            node.value.kind === 'EmptyApproval' ||
            node.value.kind === 'EmptyTimer' ||
            node.value.kind === 'EmptyNotification'
        ) {
            return (
                <T.Node.EmptyNode
                    key={node.value.id}
                    hasChildern={node.children.length > 0}
                    node={node}
                    parentNode={parentNode}
                />
            )
        } else if (node.value.kind === 'ParallelPath') {
            return (
                <T.Node.EmptyParallelNode
                    key={node.value.id}
                    index={index}
                    hasChildern={node.children.length > 0}
                    node={node}
                    parentNode={parentNode}
                />
            )
        } else if (node.value.kind === 'Condition') {
            return (
                <T.Node.EndNodeWarp
                    key={node.value.id}
                    hasChildern={node.children.length > 0}
                    node={node}
                    parentNode={parentNode}
                    isParallelPath={
                        parentNode.children.length > 1 ||
                        (insideForEach && subflowChildren && subflowChildren.length > 1)
                    }
                >
                    <T.Node
                        node={node}
                        index={index}
                        position={position}
                        parentNode={parentNode}
                        comingUnderParallelPath={comingUnderParallelPath}
                    />
                </T.Node.EndNodeWarp>
            )
        } else if (node.value.kind === 'Timer') {
            return (
                <T.Node.EndNodeWarp
                    key={node.value.id}
                    hasChildern={node.children.length > 0}
                    node={node}
                    parentNode={parentNode}
                    isParallelPath={
                        parentNode.children.length > 1 ||
                        (insideForEach && subflowChildren && subflowChildren.length > 1)
                    }
                >
                    <T.Node
                        node={node}
                        index={index}
                        position={position}
                        parentNode={parentNode}
                        comingUnderParallelPath={comingUnderParallelPath}
                    />
                </T.Node.EndNodeWarp>
            )
        } else if (node.value.kind === 'ErrorHandlingCondition') {
            return (
                <T.Node.EndNodeWarp
                    key={node.value.id}
                    hasChildern={node.children.length > 0}
                    node={node}
                    parentNode={parentNode}
                    isParallelPath={
                        parentNode.children.length > 1 ||
                        (insideForEach && subflowChildren && subflowChildren.length > 1)
                    }
                >
                    <T.Node
                        node={node}
                        index={index}
                        position={position}
                        parentNode={parentNode}
                        comingUnderParallelPath={comingUnderParallelPath}
                    />
                </T.Node.EndNodeWarp>
            )
        } else if (node.value.kind === 'Action' || node.value.kind == 'Function'|| node.value.kind == "Notification") {
            return (
                <T.Node.EndNodeWarp
                    key={node.value.id}
                    hasChildern={node.children.length > 0}
                    node={node}
                    parentNode={parentNode}
                    isParallelPath={
                        parentNode.children.length > 1 ||
                        (insideForEach && subflowChildren && subflowChildren.length > 1)
                    }
                >
                    <T.Node
                        node={node}
                        index={index}
                        position={position}
                        parentNode={parentNode}
                        comingUnderParallelPath={comingUnderParallelPath}
                    />
                </T.Node.EndNodeWarp>
            )
        }
        if (node.value.kind === 'Approval') {
            return (
                <T.Node.EndNodeWarp
                    key={node.value.id}
                    hasChildern={node.children.length > 0}
                    node={node}
                    parentNode={parentNode}
                    isParallelPath={
                        parentNode.children.length > 1 ||
                        (insideForEach && subflowChildren && subflowChildren.length > 1)
                    }
                >
                    <T.Node
                        node={node}
                        index={index}
                        position={position}
                        parentNode={parentNode}
                        comingUnderParallelPath={comingUnderParallelPath}
                    />
                </T.Node.EndNodeWarp>
            )
        } else if (node.value.kind == 'ForEach') {
            return (
                <T.Node.EndNodeWarp
                    key={node.value.id}
                    hasChildern={node.children.length > 0}
                    node={node}
                    parentNode={parentNode}
                    isParallelPath={parentNode.children.length > 1}
                >
                    <T.ForEachNode
                        node={node}
                        index={index}
                        position={position}
                        parentNode={parentNode}
                        comingUnderParallelPath={comingUnderParallelPath}
                    >
                        {populateForEachChilds(node.value.subflow, index, node)}
                    </T.ForEachNode>
                </T.Node.EndNodeWarp>
            )
        } else if (node.value.kind === 'Repeat') {
            return (
                <T.Node.RepeatNode
                    key={node.value.id}
                    hasChildern={node.children.length > 0}
                    node={node}
                    parentNode={parentNode}
                >
                    <T.Node node={node} index={index} position={position} parentNode={parentNode} />
                </T.Node.RepeatNode>
            )
        } else if (node.value.kind === 'EmptyCondition') {
            return (
                <T.Node.EmptyNode
                    key={node.value.id}
                    hasChildern={node.children.length > 0}
                    node={node}
                    parentNode={parentNode}
                />
            )
        }
        return null
    } else if (node.children.length === 1) {
        if (
            node.value.kind === 'EmptyAction' ||
            node.value.kind === 'EmptyFunction' ||
            node.value.kind === 'EmptyForEach' ||
            node.value.kind === 'EmptyNotification'
        ) {
            return (
                <T.Node.Wrap key={node.value.id} node={parentNode}>
                    <T.Node.EmptyNode
                        key={node.value.id}
                        hasChildern={node.children.length > 0}
                        node={node}
                        parentNode={parentNode}
                    />
                    {singleChild(node.children[0], index, node, comingUnderParallelPath)}
                </T.Node.Wrap>
            )
        } else if (node.value.kind == 'ForEach') {
            return (
                <T.Node.Wrap
                    key={node.value.id}
                    node={node}
                    parentNode={parentNode}
                    // isParallelPath={parentNode.children.length > 1}
                    isParallelPath={
                        parentNode.children.length > 1 ||
                        (insideForEach && subflowChildren && subflowChildren.length > 1)
                    }
                >
                    <T.ForEachNode
                        node={node}
                        index={index}
                        position={position}
                        parentNode={parentNode}
                        comingUnderParallelPath={comingUnderParallelPath}
                    >
                        {populateForEachChilds(node.value.subflow, index, node)}
                    </T.ForEachNode>
                    {singleChild(node.children[0], index, node, comingUnderParallelPath)}
                </T.Node.Wrap>
            )
        }
        return (
            <T.Node.Wrap
                key={node.value.id}
                node={node}
                parentNode={parentNode}
                // isParallelPath={parentNode.children.length > 1}
                isParallelPath={
                    parentNode.children.length > 1 || (insideForEach && subflowChildren && subflowChildren.length > 1)
                }
            >
                <T.Node
                    node={node}
                    index={index}
                    position={position}
                    parentNode={parentNode}
                    comingUnderParallelPath={comingUnderParallelPath}
                />
                {singleChild(node.children[0], index, node, comingUnderParallelPath)}
            </T.Node.Wrap>
        )
    } else {
        if (
            node.value.kind === 'EmptyAction' ||
            node.value.kind === 'EmptyFunction' ||
            node.value.kind === 'EmptyForEach'||
            node.value.kind === 'EmptyNotification'
        ) {
            return (
                <T.Node.Wrap key={node.value.id} node={parentNode}>
                    <T.Node.EmptyNode
                        key={node.value.id}
                        hasChildern={node.children.length > 0}
                        node={node}
                        parentNode={parentNode}
                    />
                    {multiChild(node, index, node)}
                </T.Node.Wrap>
            )
        } else if (node.value.kind === 'ParallelPath' && node.value.name.length > 0 && node.children.length > 1) {
            // for handling error nodes
            return (
                <T.Node.Wrap key={node.value.id} node={parentNode}>
                    <T.Node.EmptyNode
                        key={node.value.id}
                        hasChildern={node.children.length > 0}
                        node={node}
                        parentNode={parentNode}
                    />
                    {multiChild(node, index, node)}
                </T.Node.Wrap>
            )
        }
        if (node.value.kind === 'ParallelPath') {
            return (
                <T.Node.Wrap key={node.value.id + index} node={parentNode}>
                    <T.Node.EmptyParallelNode
                        key={node.value.id}
                        hasChildern={node.children.length > 0}
                        node={node}
                        parentNode={parentNode}
                    />
                    {multiChild(node, index, node)}
                </T.Node.Wrap>
            )
        } else if (node.value.kind == 'ForEach') {
            return (
                <T.Node.Wrap
                    key={node.value.id}
                    node={node}
                    parentNode={parentNode}
                    isParallelPath={parentNode.children.length > 1}
                >
                    <T.ForEachNode
                        node={node}
                        index={index}
                        position={position}
                        parentNode={parentNode}
                        comingUnderParallelPath={comingUnderParallelPath}
                    >
                        {populateForEachChilds(node.value.subflow, index, node)}
                    </T.ForEachNode>
                    {multiChild(node, index, node)}
                </T.Node.Wrap>
            )
        }
        return (
            <T.Node.Wrap
                key={node.value.id}
                node={node}
                parentNode={parentNode}
                isParallelPath={parentNode.children.length > 1}
            >
                <T.Node
                    node={node}
                    index={index}
                    position={position}
                    parentNode={parentNode}
                    comingUnderParallelPath={comingUnderParallelPath}
                />
                {multiChild(node, index, node)}
            </T.Node.Wrap>
        )
    }
}
