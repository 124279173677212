import Axios from 'axios'

import { UploadbotT } from '@/Assistant/Construction/Constructor'

import { withAuth } from '@/common/utils/auth-token-api-utils'

const BotMarketPlace = `${process.env.REACT_APP_DIALOG_MARKETPLACE}/botmarketplace`
const UploadDownloadBaseURL = process.env.REACT_APP_DIALOG_API
const getJson = (url: string) => withAuth(() => Axios.get(url))
const postJson = (url: string, data: any) => withAuth(() => Axios.post(url, data))

const getCategories = (workspace: string) => `${BotMarketPlace}/${workspace}/categories`
const searchBot = (workspace: String, searchText: string, category_id: string) =>
    `${BotMarketPlace}/${workspace}/search?searchText=${searchText}&bot_category_id=${category_id}`
const getDialogs = (workspace: string, botId: string) => `${BotMarketPlace}/${workspace}/bot/${botId}/dialogs`
const getIfnode = (workspace: string, botId: string, ifNodeId: string) =>
    `${BotMarketPlace}/${workspace}/bot/${botId}/ifnode/${ifNodeId}`
const downloadBot = (
    workspace: string,
    botId: string
) => `${UploadDownloadBaseURL}/${workspace}/marketplace/download/bot/${botId}
`
const uploadBot = ({ botId, workspace, botName, category, replaceBotId }: UploadbotT) =>
    !replaceBotId
        ? `${UploadDownloadBaseURL}/${workspace}/marketplace/upload/bot/${botId}?upload_as=new&upload_name=${botName}&replace_bot_id=&category_name=${category}`
        : `${UploadDownloadBaseURL}/${workspace}/marketplace/upload/bot/${botId}?upload_as=update&upload_name=${botName}&replace_bot_id=${replaceBotId}&category_name=${category}`
const getBotDetails = (workspace: string, botId: string) => `${BotMarketPlace}/${workspace}/bot/${botId}`
const canAccess = (workspace: string) => `${BotMarketPlace}/${workspace}/upload_access`
const myuploads = (workspace: string) => `${BotMarketPlace}/${workspace}/myuploads`
export const GetBotIfNodes = (workspace: string, botId: string, ifNodeId: string) =>
    getJson(getIfnode(workspace, botId, ifNodeId))
export const GetAllCategories = (workspace: string) => getJson(getCategories(workspace))
export const SearchBots = (workspace: string, searchText: string, category_id: string) =>
    getJson(searchBot(workspace, searchText, category_id))
export const GetAllDialogs = (workspace: string, botId: string) => postJson(getDialogs(workspace, botId), {})
export const DownloadBot = (workspace: string, botId: string) => postJson(downloadBot(workspace, botId), {})
export const GetBotDetails = (workspace: string, botId: string) => getJson(getBotDetails(workspace, botId))
export const UploadBot = (uploadBotObj: UploadbotT) => postJson(uploadBot({ ...uploadBotObj }), {})
export const CanAccess = (workspace: string) => getJson(canAccess(workspace))
export const MyUploads = (Workspace: string) => getJson(myuploads(Workspace))
