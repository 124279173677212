/*eslint-disable*/
import {
  ApprovalIConSidepanel,
  ApptriggerIcon,
  FlowIcon,
  HomeIcon,
  IntegrationIcon,
  MarketPlaceIcon,
  SubPanelIcons,
  TrainingModuleIcon,
  UserTriggerIcon,
  WebhooksIcon,
  SlackIconCC,
  TeamsIcon,
  WidgetIcon,
  DialogIcon,
} from '@/common/Icons/Icons';
import { getProductId } from '@/common/utils/utils';

export type Linktext =
  | 'Welcome'
  | 'Dialog'
  | 'Automation'
  | 'Integration'
  | 'Train Assistant'
  | 'ProfilePaths'
  | 'Marketplace';

export type LinkRoute =
  | 'welcome'
  | 'dialog/homepage'
  | 'automation/view/home'
  | 'integration/chat'
  | 'train-assistant/home'
  | 'marketplace/automation'
  | 'marketplace/automation/select-application';

export type SubLinkRoute =
  | 'welcome'
  | 'assistant/homepage'
  | 'assistant/summary'
  | 'assistant/dialogs'
  | 'dialog/analytics'
  | 'dialog/logs'
  | 'dialog/intent'
  | 'dialog/entity'
  | 'dialog/agent-handoff'
  | 'dialog/settings'
  | 'automation/view/home'
  | 'automation/view/active'
  | 'automation/view/inactive'
  | 'automation/view/approvals'
  | 'automation/view/draft'
  | 'automation/view/trigger'
  | 'automation/view/no-trigger'
  | 'automation/summary'
  | 'automation/logs'
  | 'integration/chat'
  | 'integration/application'
  | 'train-assistant/home'
  | 'marketplace/automation/select-application'
  | 'marketplace/automation/list-automations'
  | 'marketplace/myuploads'
  | 'integration/chat/slack'
  | 'integration/chat/teams'
  | 'integration/chat/widget'

export type SubLinktext =
  | 'Home'
  | 'Intent'
  | 'Entity'
  | 'Agent Handover'
  | 'Summary'
  | 'Logs'
  | 'Settings'
  | 'Draft'
  | 'Active'
  | 'Inactive'
  | 'Approvals'
  | 'Trigger'
  | 'No Trigger'
  | 'Chat Channel'
  | 'Application'
  | 'Automations'
  | 'MyUploads'
  | 'Slack'
  | 'MS Teams'
  | 'Chat Widget';

export type subMenu = {
  displayText: string;
  to: string;
  icon: React.FunctionComponent;
  active: boolean;
  alias?: [SubLinkRoute];
  submenu?: subMenu[];
};

export type NavLinkT = {
  displayText: Linktext;
  to: LinkRoute;
  icon: React.FunctionComponent;
  active: boolean;
  submenu: subMenu[];
};

// export const dialog: NavLinkT = {
//     displayText: 'Dialog',
//     icon: DialogIcon,
//     to: 'dialog/home',
//     active: true,
//     submenu: [
//         {
//             displayText: 'Homepage',
//             icon: SubPanelIcons.HomePage,
//             to: 'dialog/home',
//             active: true
//         },
//         {
//             displayText: 'Intent',
//             icon: IntentIcon,
//             to: 'dialog/intent',
//             active: true
//         },
//         {
//             displayText: 'Entity',
//             icon: EntityIcon,
//             to: 'dialog/entity',
//             active: true
//         },
//         {
//             displayText: 'Analytics',
//             icon: SubPanelIcons.Analytics,
//             to: 'dialog/analytics',
//             active: true
//         },
//         {
//             displayText: 'Logs',
//             icon: SubPanelIcons.logs,
//             to: 'dialog/logs',
//             active: true
//         },
//         {
//             displayText: 'Settings',
//             icon: SubPanelIcons.settings,
//             to: 'dialog/settings',
//             active: true
//         }
//     ]
// }

const flow: NavLinkT = {
  displayText: 'Automation',
  icon: FlowIcon,
  to: 'automation/view/home',
  active: true,
  submenu: [
    {
      displayText: 'Summary',
      icon: SubPanelIcons.SummaryIcon,
      to: 'automation/summary',
      active: true,
    },
    {
      displayText: 'All Workflows',
      icon: FlowIcon,
      to: 'automation/view/home',
      active: true,
    },
    {
      displayText: 'Draft',
      icon: SubPanelIcons.Draft,
      to: 'automation/view/draft',
      active: true,
    },
    {
      displayText: 'Approvals',
      icon: ApprovalIConSidepanel,
      to: 'automation/view/approvals',
      active: true,
    },
    // {
    //     displayText: 'Trigger',
    //     icon: SubPanelIcons.Trigger,
    //     to: 'automation/view/trigger',
    //     active: true
    // },
    // {
    //     displayText: 'No Trigger',
    //     icon: SubPanelIcons.NoTrigger,
    //     to: 'automation/view/no-trigger',
    //     active: true
    // },
    {
      displayText: 'Active',
      icon: SubPanelIcons.Active,
      to: 'automation/view/active',
      active: true,
    },
    {
      displayText: 'Inactive',
      icon: SubPanelIcons.Inactive,
      to: 'automation/view/inactive',
      active: true,
    },

    {
      displayText: 'Logs',
      icon: SubPanelIcons.logs,
      to: 'automation/logs',
      active: true,
    },
    {
      displayText: 'On-prem Connect',
      icon: SubPanelIcons.OnPremICon,
      to: 'automation/onprem-agent',
      active: true,
    },
  ],
};

const WorkflowBaseRoutes = [{
  displayText: 'Summary',
  icon: SubPanelIcons.SummaryIcon,
  to: 'automation/summary',
  active: true,
},
{
  displayText: 'All Workflows',
  icon: FlowIcon,
  to: 'automation/view/home',
  active: true,
},
{
  displayText: 'Draft',
  icon: SubPanelIcons.Draft,
  to: 'automation/view/draft',
  active: true,
},
{
  displayText: 'Approvals',
  icon: ApprovalIConSidepanel,
  to: 'automation/view/approvals',
  active: true,
},
{
  displayText: 'Active',
  icon: SubPanelIcons.Active,
  to: 'automation/view/active',
  active: true,
},
{
  displayText: 'Inactive',
  icon: SubPanelIcons.Inactive,
  to: 'automation/view/inactive',
  active: true,
}]

const DifferntTriggers = getProductId() === 'ASSISTANT'?[{
  displayText: 'App Triggers',
  icon: ApptriggerIcon,
  to: 'automation/view/app-trigger',
  active: true,
},
{
  displayText: 'Chatbot Triggers',
  icon: DialogIcon,
  to: 'automation/view/chatbot-trigger',
  active: true,
}]:[{
  displayText: 'App Triggers',
  icon: ApptriggerIcon,
  to: 'automation/view/app-trigger',
  active: true,
},
{
  displayText: 'Webhooks',
  icon: WebhooksIcon,
  to: 'automation/view/webhook-trigger',
  active: true,
}]

const automateflow: NavLinkT = {
  displayText: 'Automation',
  icon: FlowIcon,
  to: 'automation/view/home',
  active: true,
  submenu: [
    ...WorkflowBaseRoutes,
    ...DifferntTriggers,
    // {
    //   displayText: 'User Triggers',
    //   icon: UserTriggerIcon,
    //   to: 'automation/view/user-trigger',
    //   active: true,
    // },
    {
      displayText: 'On-prem Connect',
      icon: SubPanelIcons.OnPremICon,
      to: 'automation/onprem-agent',
      active: true,
    },
    {
      displayText: 'Logs',
      icon: SubPanelIcons.logs,
      to: 'automation/logs',
      active: true,
    },
  ],
};

const marketPlace: NavLinkT = {
  displayText: 'Marketplace',
  icon: MarketPlaceIcon,
  to: 'marketplace/automation/select-application',
  active: true,
  submenu: [
    {
      displayText: 'Automations',
      icon: FlowIcon,
      to: 'marketplace/automation/select-application',
      alias: ['marketplace/automation/list-automations'],
      active: true,
    },
    {
      displayText: 'MyUploads',
      icon: SubPanelIcons.MyUploadsIcon,
      to: 'marketplace/myuploads',
      active: true,
    },
  ],
};

const integration: NavLinkT = {
  displayText: 'Integration',
  icon: IntegrationIcon,
  to: 'integration/chat',
  active: true,
  submenu: [
    {
      displayText: 'Chat Channels',
      icon: SubPanelIcons.HomePage,
      to: 'integration/chat',
      active: true,
    },
    {
      displayText: 'Slack',
      icon: SlackIconCC,
      to: 'integration/chat/slack',
      active: true,
    },
    {
      displayText: 'MS Teams',
      icon: TeamsIcon,
      to: 'integration/chat/teams',
      active: true,
    },
    {
      displayText: 'Chat Widget',
      icon: WidgetIcon,
      to: 'integration/chat/widget',
      active: true,
    },
  ],
};

const trainingModule: NavLinkT = {
  displayText: 'Train Assistant',
  icon: TrainingModuleIcon,
  to: 'train-assistant/home',
  active: true,
  submenu: [
    {
      displayText: 'Home',
      icon: SubPanelIcons.HomePage,
      to: 'train-assistant/home',
      active: true,
    },
  ],
};
const welcome: NavLinkT = {
  displayText: 'Welcome',
  icon: HomeIcon,
  to: 'welcome',
  active: true,
  submenu: [],
};
export const assistantlinks: NavLinkT[] = [
  welcome,
  automateflow,
  integration,
  trainingModule,
  marketPlace,
];
export const automatelinks: NavLinkT[] = [
  welcome,
  automateflow,
  marketPlace,
];

export const getSideBarLinkTree = () => {
  return getProductId() === "AUTOMATE" ? automatelinks : assistantlinks;
};

export type SideBarT = {
  selectedLink: Linktext | string;
  previousLink: Linktext | boolean;
  selectedSublink: SubLinktext | string;
  showSubpanel: boolean;
};
