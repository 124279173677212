/*eslint-disable*/
import React, { useEffect, useState } from 'react'
import Tooltip from 'react-tooltip-lite'
import styled from 'styled-components/macro'

//ICONS IMPORT
import { AddIcon, CheckIcon, CloseBtnWithCircle, CloseIcon, CrossIcon, DeleteIcon, EditIcon, ErrorIcon, MinusIcon, SearchIcon } from '@/common/Icons/Icons'
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons'
//COMMON COMPONENTS IMPORT
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import Validate, { ErrorContext, ErrorContextT } from '@/common/components/FormValidate'
import InputBox from '@/common/components/InputBox'
import { List } from '@/common/components/List'
import { Loader } from '@/common/components/Loader'
import { Modal } from '@/common/components/Modal'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import { SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { ButtonWrapper, Paragraph } from '@/common/styled/Dialogue.Dumb'
import { CircleButton } from '@/common/styled/Entity.Dumb'
import {
    ErrorMessage,
    Input,
    InputContainer,
    InputContent,
    InputErrorIcon,
    InputWrapper,
    Table,
    TableContainer,
    Tbody,
    Td,
    Textarea,
    Th,
    Thead,
    Tr
} from '@/common/styled/Workflow.Analytics.Dumb'
import { inputTextAreaValidations, intentValidation, newLine } from '@/common/utils/_validation-utils'

//ACTIONS IMPORT
import { formatAMPM, formatDateInCard } from '@/common/utils/utils'
import { ErrorState } from '../DialogueCrew/kind'
import { IntentT, VIEW } from '../Intent/kind'
//TYPE IMPORTS
import { ActionT } from '../kind'
//COMPONENTS IMPORT
import { Header } from './Header'
import { DeleteT, EditT, ExampleT, MoveT, MultipleDeleteT, PageStateT, View } from './kind'

const Wrapper = styled.div`
    width: 100%;
    float: left;
    .intent_examples_list span {
        font-size: 9px;
    }
`
const AddExamplesWrapper = styled.div`
    width: 100%;
    float: left;
    ${ButtonWrapper} {
        margin-top: 0px;
    }
    ${InputWrapper} {
        margin-bottom: 20px;
    }
    ${SmallButton} {
        margin-bottom: 20px;
    }
    .add_del_btn.intent_add_del_btn {
        ${InputWrapper} {
            // margin-bottom: 12px;
        }
    }

    ${InputContainer} {
        margin-bottom: 20px;
        :last-child {
            margin-bottom: 0px;
        }
    }
`
const TabelWrapper = styled.div`
    width: 100%;
    float: left;
    margin-top: 22px;
    .intent_table_row_1 label {
        float: left;
        display: flex;
        position: absolute;
        top: 18px;
        width: 16px;
        height: 16px;
        margin-bottom: 0px;
        left: -22px;
    }
    .option-input.radio {
        top: 6px;
        position: relative;
        margin-right: 0px;
    }

    ${Table} {
        margin-top: 0px;
    }
    ${Thead} {
        ${Tr} {
            padding-left: 32px;
            padding-right: 20px;

            ${Th} {
                :last-child {
                    justify-content: flex-end;
                }
            }
        }
    }
    ${Tbody} {
        border-radius: 0px 0px 10px 10px;
        max-height: calc(100vh - 252px);
        height: auto;

        ${Tr} {
            height: 48px;
            padding-left: 32px;
            padding-right: 20px;
            .entity_span {
                :nth-child(1) {
                    margin-right: 3px;
                    color: #000;
                    font-size: 11px;
                }
                :nth-child(2) {
                    font-size: 9px;
                }
                :last-child {
                    margin-left: 3px;
                    color: #000;
                    font-size: 11px;
                }
            }
            :hover {
                ${Td}:last-child {
                    display: flex;
                }
            }

            ${Td} {
                padding: 0px;
                height: 48px;
                font-size: 12px;
                font-family: 'Gordita-Regular';
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                color: #000;
                cursor: inherit;
                span {
                    margin-right: 0px;
                }
                .intent_table_row_4 span {
                    height: 16px;
                }

                .list_flow_span_example span {
                    font-size: 12px;
                    font-family: 'Gordita-Regular';
                    color: #000;

                    span {
                        color: #f07f31;
                    }
                }
                :nth-child(1) {
                    display: flex;
                    padding-right: 20px;
                    position: relative;
                    cursor: inherit;
                }

                :nth-child(2) {
                    display: flex;
                    padding-right: 20px;
                }
                :last-child {
                    justify-content: flex-end;
                    display: none;
                }
            }
        }
    }
`

interface ExamplesP {
    PageState: PageStateT
    setPageState: Function
    intent: IntentT
    intents: IntentT[]
    saveIntent: Function
    examples: ExampleT[]
    createExample: Function
    removeExample: Function
    editExampleIntent: Function
    moveExample: Function
    moveExampleError: string | null
    setmoveExampleError: Function
    permissions:{
        intentWritePermission: boolean | null;
        exampleWritePermission: boolean | null;
        exampleDeletePermission: boolean | null;
    }
}

const Examples = ({
    PageState,
    intent,
    intents,
    setPageState,
    examples,
    saveIntent,
    createExample,
    removeExample,
    editExampleIntent,
    moveExample,
    moveExampleError,
    setmoveExampleError,
    permissions
}: ExamplesP) => {
    const deleteExample = (exam: ExampleT) => {
        const deleteMultiple: MultipleDeleteT = {
            action: ActionT.MULTIPLE_DELETE,
            selectedItems: PageState.action == ActionT.SELECT ? PageState.selectedItems : []
        }

        const deleteSingle: DeleteT = {
            action: ActionT.DELETE,
            example: exam
        }
        const setMode = PageState.action === ActionT.SELECT ? deleteMultiple : deleteSingle

        setPageState(setMode)
    }
    const setExampleToMoveMode = () => {
        const move: MoveT = {
            action: ActionT.MOVE,
            selectedItems: PageState.action == ActionT.SELECT ? PageState.selectedItems : ['']
        }
        setPageState(move)
        setmoveExampleError(null)
    }

    const selectExample = (example: string) => {
        switch (PageState.action) {
            case ActionT.SELECT:
                const selected: string[] = PageState.selectedItems.includes(example)
                    ? PageState.selectedItems.filter((x: string) => x !== example)
                    : [...PageState.selectedItems, example]

                if (selected.length) {
                    setPageState({
                        ...PageState,
                        selectedItems: selected
                    })
                } else {
                    setPageState(VIEW)
                }
                break
            default:
                const newSelected = [example]
                setPageState({
                    action: ActionT.SELECT,
                    selectedItems: newSelected
                })
                break
        }
    }
    const editExample = (exam: ExampleT) => {
        const actions: EditT = {
            action: ActionT.EDIT,
            exampleid: exam.id,
            intentId: intent.id,
            example: exam.text
        }
        setPageState(actions)
    }
    return PageState.action === ActionT.LOADING ? (
        <Loader.PageLoader show={true} />
    ) : (
        <React.Fragment>
            <ErrorBoundary
                unauthorized={PageState.action === ActionT.UN_AUTHORIZED}
                resourceNotFound={PageState.action === ActionT.RESOURCE_NOT_FOUND}
                error={
                    PageState.action === ActionT.ERROR
                        ? { error: PageState.error, info: PageState.info }
                        : { error: null, info: null }
                }
                render={(err: any, info: any) => {
                    return err && <Error.PageLoadingError btnName="Retry" onClick={fetch} info={info} />
                }}
            >
                <Header
                    intent={intent}
                    PageState={PageState}
                    setPageState={setPageState}
                    deleteExample={deleteExample}
                    setExampleToMoveMode={setExampleToMoveMode}
                    permissions={permissions}

                />
                <Wrapper>
                    <section className="entity_cards" style={{ marginTop: 0, overflow: 'hidden' }}>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="entity_table pad0 intent_examples_list">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <TabelWrapper>
                                                <TableContainer>
                                                    <Table>
                                                        <Thead>
                                                            <Tr>
                                                                <Th size={8}>Example</Th>
                                                                <Th size={2}>Date</Th>
                                                                <Th size={2}></Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            <div style={{ width: '100%', float: 'left' }}>
                                                                {examples.map((example: ExampleT, i: number) => {
                                                                    return (
                                                                        <ExamplesList
                                                                            key={i}
                                                                            PageState={PageState}
                                                                            setPageState={setPageState}
                                                                            example={example}
                                                                            examples={examples.filter(
                                                                                (e, j) => j !== i
                                                                            )}
                                                                            selectExample={selectExample}
                                                                            deleteExample={deleteExample}
                                                                            editExample={() => editExample(example)}
                                                                            editedExampl={editExampleIntent}
                                                                            permissions={permissions}
                                                                        />
                                                                    )
                                                                })}
                                                            </div>
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </TabelWrapper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Wrapper>

                <section className="intent_bottom">
                    <div>&nbsp;</div>
                </section>
            </ErrorBoundary>

            <ExampleModel
                PageState={PageState}
                setPageState={setPageState}
                saveIntent={saveIntent}
                examples={examples}
                createExample={createExample}
                remove={removeExample}
                moveExample={moveExample}
                intent={intent}
                intents={intents.filter(e => e.id !== intent.id)}
                moveExampleError={moveExampleError}
                permissions={permissions}
            />
        </React.Fragment>
    )
}

const ExampleModel = ({
    PageState,
    setPageState,
    saveIntent,
    examples,
    createExample,
    remove,
    intent,
    intents,
    moveExample,
    moveExampleError,
    permissions
}: {
    PageState: PageStateT
    setPageState: Function
    saveIntent: Function
    examples: ExampleT[]
    createExample: Function
    remove: Function
    intent: IntentT
    intents: IntentT[]
    moveExample: Function
    moveExampleError: string | null
    permissions: {
        intentWritePermission: boolean | null;
        exampleWritePermission: boolean | null;
        exampleDeletePermission: boolean | null;
    }
}) => {
    let InputRef: any = null

    const [loading, setLoading] = useState(false)

    const [selected, setSelected] = useState('')

    const [searchKey, setSearchKey] = useState('')

    const [addMoreError, setAddMoreError] = useState(false)

    const [error, setError] = useState<{
        error: any
        info: any
    }>({ error: null, info: null })

    const [keyCode, setKeyCode] = useState(0)

    const addMore = () => {
        setPageState((state: PageStateT) => {
            if (state.action === ActionT.ADD) {
                return { ...state, examples: state.examples.concat(['']) }
            } else return state
        })
    }

    useEffect(() => {
        if (PageState.action === ActionT.ADD) {
            if (PageState.examples.filter(e => e.trim() === '').length === 0) {
                setAddMoreError(false)
            }
        }
    }, [PageState])

    const exampleDelete = () => {
        setLoading(true)
        remove(
            () => setLoading(false),
            (reson: string) => {
                setLoading(false)
                setError({ error: true, info: reson })
            }
        )
    }

    // const searchMoveIntents = (name: string) => {
    //     console.log(name, '::::')
    //     setPageState({
    //         exampleNme: name,
    //         searchingMoveIntent: name.length > 0
    //     })
    // }

    if (PageState.action == ActionT.INTENT_EDIT) {
        const duplicate = intents.filter(i => i.id != PageState.id && i.intent == PageState.intent).length > 0
        return (
            <Modal error={error} showPopupLoader={loading}>
                <ErrorBoundary
                    error={error}
                    render={(err: any, info: any) => {
                        return err && <Error.AddorEdit onClick={() => setError(ErrorState)} info={info} />
                    }}
                >
                    <AddExamplesWrapper>
                        <Validate defaultValue={{ intentName: true }}>
                            <h2>Edit Intent</h2>
                            <Paragraph margin>Enter name and description for this Intent </Paragraph>
                            <div>
                                <InputBox
                                    validationList={intentValidation}
                                    value={PageState.intent}
                                    render={(
                                        value: string,
                                        valid: boolean,
                                        errorMsg: string,
                                        startValidation: () => void,
                                        handleInput: Function
                                    ) => {
                                        return (
                                            <React.Fragment>
                                                <InputWrapper>
                                                    <ErrorMessage>{!valid ? errorMsg : newLine}</ErrorMessage>
                                                    <InputContainer>
                                                        <Input
                                                            primary={!valid ? `primary` : ''}
                                                            type="text"
                                                            name="intentName"
                                                            value={value}
                                                            ref={(node: any) => (InputRef = node)}
                                                            onChange={(e: any) => {
                                                                e.persist()
                                                                setPageState((state: PageStateT) => ({
                                                                    ...state,
                                                                    intent: e.target.value
                                                                }))
                                                                startValidation()
                                                                handleInput('intentName', e.target.value)
                                                            }}
                                                        />
                                                        <InputContent>Intent title</InputContent>
                                                        {!valid && (
                                                            <InputErrorIcon>
                                                                <ErrorIcon />
                                                            </InputErrorIcon>
                                                        )}
                                                    </InputContainer>
                                                </InputWrapper>
                                                {duplicate ? (
                                                    <div className="error_input_show intent">
                                                        <WarningAlertIcon />
                                                        <span className="error_menu_hvr">
                                                            Duplicate intent cannot be saved. Modify the intent name.
                                                        </span>
                                                    </div>
                                                ) : null}
                                            </React.Fragment>
                                        )
                                    }}
                                />

                                <InputBox
                                    validationList={inputTextAreaValidations}
                                    value={PageState.description}
                                    name="intentDescription"
                                    render={(
                                        value: string,
                                        valid: boolean,
                                        errorMsg: string,
                                        startValidation: () => void,
                                        handleInput: Function
                                    ) => {
                                        return (
                                            <React.Fragment>
                                                <InputWrapper>
                                                    <ErrorMessage>{!valid ? errorMsg : newLine}</ErrorMessage>
                                                    <InputContainer>
                                                        <Textarea
                                                            value={value}
                                                            onChange={(e: any) => {
                                                                e.persist()
                                                                startValidation()
                                                                handleInput('intentDescription', e.target.value)
                                                                setPageState((state: PageStateT) => ({
                                                                    ...state,
                                                                    description: e.target.value
                                                                }))
                                                            }}
                                                            onKeyDown={(e: any) => {
                                                                let key = e.keyCode || e.which
                                                                setKeyCode(key)
                                                            }}
                                                        />
                                                        <InputContent topContent>Intent description</InputContent>
                                                        {!valid && (
                                                            <InputErrorIcon>
                                                                <ErrorIcon />
                                                            </InputErrorIcon>
                                                        )}
                                                    </InputContainer>
                                                </InputWrapper>
                                            </React.Fragment>
                                        )
                                    }}
                                />
                                <ErrorContext.Consumer>
                                    {(valid: ErrorContextT) => {
                                        return (
                                            <React.Fragment>
                                                <ButtonWrapper>
                                                    <Button primary type="button" onClick={() => setPageState(View)}>
                                                        <label>Cancel</label>
                                                    </Button>
                                                    <Button
                                                        type="button"
                                                        onClick={() => {
                                                            setLoading(true)
                                                            saveIntent(
                                                                () => {
                                                                    setPageState(View)
                                                                    setLoading(false)
                                                                },
                                                                () => {
                                                                    setError({
                                                                        error: true,
                                                                        info: `Server error!<br />Please try again after sometime.`
                                                                    })
                                                                    setLoading(false)
                                                                }
                                                            )
                                                        }}
                                                        disabled={
                                                            !valid.formValid.formValid ||
                                                            (() => {
                                                                if (
                                                                    intent.intent.trim().toLowerCase() !==
                                                                        PageState.intent.trim().toLowerCase() ||
                                                                    intent.description.trim().toLowerCase() !==
                                                                        PageState.description.trim().toLowerCase()
                                                                ) {
                                                                    return false
                                                                }

                                                                return true
                                                            })()
                                                        }
                                                    >
                                                        <label>Update</label>
                                                    </Button>
                                                </ButtonWrapper>
                                            </React.Fragment>
                                        )
                                    }}
                                </ErrorContext.Consumer>
                            </div>
                        </Validate>
                    </AddExamplesWrapper>
                </ErrorBoundary>
            </Modal>
        )
    } else if (PageState.action == ActionT.ADD) {
        return (
            <Modal error={error} showPopupLoader={loading}>
                <ErrorBoundary
                    error={error}
                    render={(err: any, info: any) => {
                        return err && <Error.AddorEdit onClick={() => setError(ErrorState)} info={info} />
                    }}
                >
                    <Validate defaultValue={{ example: false }}>
                        <h2>Add examples for this Intent </h2>
                        <Paragraph margin>Recommended to add minimum five examples</Paragraph>
                        <AddExamplesWrapper className={'add-example'}>
                            {PageState.examples.map((example: string, i: number) => (
                                <React.Fragment>
                                    <div key={i} className="add_del_btn intent_add_del_btn">
                                        <InputWrapper>
                                            <ErrorMessage>
                                                {addMoreError ? 'Please fill out this field' : newLine}
                                            </ErrorMessage>
                                            <InputContainer>
                                                <Input
                                                    maxLength={255}
                                                    primary={addMoreError ? `primary` : ''}
                                                    className={`full_input_width ${
                                                        PageState.examples
                                                            .concat(
                                                                examples
                                                                    .filter(
                                                                        (data: ExampleT, count: number) => i != count
                                                                    )
                                                                    .map(e => e.text)
                                                            )
                                                            .filter(
                                                                (e: any) =>
                                                                    e.toLowerCase().trim() ==
                                                                    example.toLowerCase().trim()
                                                            ).length > 0
                                                            ? 'form-control-error'
                                                            : ''
                                                    }`}
                                                    // placeholder="Example"
                                                    value={example}
                                                    onChange={(e: any) => {
                                                        e.persist()
                                                        setPageState((state: PageStateT) => ({
                                                            ...state,
                                                            examples:
                                                                state.action === ActionT.ADD
                                                                    ? state.examples.map((ele: string, n: number) =>
                                                                          n == i
                                                                              ? e.target.value.length > 1023
                                                                                  ? ele
                                                                                  : e.target.value
                                                                              : ele
                                                                      )
                                                                    : []
                                                        }))
                                                    }}
                                                />
                                                <InputContent>Example</InputContent>
                                                {addMoreError && (
                                                    <InputErrorIcon>
                                                        <ErrorIcon />
                                                    </InputErrorIcon>
                                                )}
                                            </InputContainer>
                                        </InputWrapper>

                                        {PageState.examples.length > 1 ? (
                                            <span
                                                className="full_input_button"
                                                onClick={() => {
                                                    let examples = PageState.examples as string[]
                                                    let removed = examples.filter((x: string, y: number) => y !== i)
                                                    setPageState((state: PageStateT) => ({
                                                        ...state,
                                                        examples: removed
                                                    }))
                                                }}
                                            >
                                                <span><CloseIcon /></span>
                                            </span>
                                        ) : null}
                                        {examples
                                            .map(e => e.text)
                                            .concat(
                                                PageState.examples.filter((data: string, count: number) => i != count)
                                            )
                                            .filter(
                                                (e: string) => e.toLowerCase().trim() == example.toLowerCase().trim()
                                            ).length > 0 ? (
                                            <div className="error_input_show">
                                                <Tooltip
                                                    className="target customTip"
                                                    zIndex={10000}
                                                    arrowSize={8}
                                                    tagName="span"
                                                    content="Intent example already exists. Duplicate examples cannot be saved. Remove or Modify the example."
                                                    distance={5}
                                                >
                                                    <WarningAlertIcon />
                                                </Tooltip>
                                            </div>
                                        ) : null}
                                    </div>
                                </React.Fragment>
                            ))}

                            {PageState.examples.length < 5 && (
                                <SmallButton
                                    primary
                                    onClick={() => {
                                        if (PageState.examples.filter(e => e.trim() === '').length > 0) {
                                            setAddMoreError(true)
                                        } else {
                                            addMore()
                                        }
                                    }}
                                >
                                    <span>
                                        <AddIcon />
                                    </span>
                                    <label>Add More</label>
                                </SmallButton>
                            )}
                            <ErrorContext.Consumer>
                                {valid => {
                                    return (
                                        <React.Fragment>
                                            <ButtonWrapper>
                                                <Button
                                                    primary
                                                    type="button"
                                                    data-dismiss="modal"
                                                    onClick={() => {
                                                        setPageState(View)
                                                    }}
                                                >
                                                    <label>Cancel</label>
                                                </Button>
                                                <Button
                                                    type="button"
                                                    data-dismiss="modal"
                                                    onClick={() => {
                                                        createExample(
                                                            () => {
                                                                setLoading(false)
                                                                setPageState(View)
                                                            },
                                                            () => {
                                                                setError({
                                                                    error: true,
                                                                    info: `Server error!<br />Please try again after sometime.`
                                                                })
                                                                setLoading(false)
                                                            }
                                                        )
                                                    }}
                                                    className={
                                                        PageState.examples
                                                            .concat(examples.map(e => e.text))
                                                            .reduce(
                                                                (
                                                                    acc: boolean,
                                                                    value: string,
                                                                    count: number,
                                                                    arr: string[]
                                                                ) => {
                                                                    return (
                                                                        arr
                                                                            .filter(
                                                                                (e: any, index: number) =>
                                                                                    index != count
                                                                            )
                                                                            .map((e: any) => e.toLowerCase().trim())
                                                                            .includes(value.toLowerCase().trim()) ||
                                                                        arr
                                                                            .map((e: any) => e.toLowerCase().trim())
                                                                            .includes('') ||
                                                                        acc
                                                                    )
                                                                },
                                                                false
                                                            )
                                                            ? 'editor_btn_disabled'
                                                            : ''
                                                    }
                                                    disabled={PageState.examples
                                                        .concat(examples.map(e => e.text))
                                                        .reduce(
                                                            (
                                                                acc: boolean,
                                                                value: string,
                                                                count: number,
                                                                arr: string[]
                                                            ) => {
                                                                return (
                                                                    arr
                                                                        .filter(
                                                                            (e: any, index: number) => index != count
                                                                        )
                                                                        .map((e: any) => e.toLowerCase().trim())
                                                                        .includes(value.toLowerCase().trim()) ||
                                                                    arr
                                                                        .map((e: any) => e.toLowerCase().trim())
                                                                        .includes('') ||
                                                                    acc
                                                                )
                                                            },
                                                            false
                                                        )}
                                                >
                                                    <label>Save</label>
                                                </Button>
                                            </ButtonWrapper>
                                        </React.Fragment>
                                    )
                                }}
                            </ErrorContext.Consumer>
                        </AddExamplesWrapper>
                    </Validate>
                </ErrorBoundary>
            </Modal>
        )
    } else if (PageState.action == ActionT.MULTIPLE_DELETE || PageState.action == ActionT.DELETE) {
        return (
            <Modal error={error} showPopupLoader={loading}>
                <ErrorBoundary
                    error={error}
                    render={(err: any, info: any) => {
                        return err && <Error.Delete onClick={() => setError(ErrorState)} info={info} />
                    }}
                >
                    {PageState.action == ActionT.MULTIPLE_DELETE ? (
                        PageState.selectedItems &&
                        PageState.selectedItems.length > 0 && (
                            <React.Fragment>
                                <h2>Delete Examples?</h2>
                                <Paragraph>Are you sure to delete selected Examples?</Paragraph>
                            </React.Fragment>
                        )
                    ) : (
                        <React.Fragment>
                            <h2>Delete {PageState.example.text}?</h2>
                            <Paragraph>Are you sure to delete selected Example?</Paragraph>
                        </React.Fragment>
                    )}
                    <ButtonWrapper>
                        <Button primary type="button" data-dismiss="modal" onClick={() => setPageState(View)}>
                            <label>No</label>
                        </Button>
                        <Button type="button" data-dismiss="modal" onClick={() => exampleDelete()}>
                            <label>Yes</label>
                        </Button>
                    </ButtonWrapper>
                </ErrorBoundary>
            </Modal>
        )
    } else if (PageState.action == ActionT.MOVE) {
        return (
            <div className="intent_moveto_poup">
                <div className="right-panel select_flow">
                    <h2>Move example</h2>
                    <p>Select intent from the below list</p>
                    {/* <ErrorBoundary
                error={error}
                render={(err: any, info: any) => {
                    return err && <Error.Move onClick={this.closeError} info={info} />
                }}
            > */}
                    {loading ? (
                        <Loader.PopupLoader show={loading} />
                    ) : (
                        <React.Fragment>
                            <span className="form-input intent_search_input">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Intent"
                                    value={searchKey}
                                    onChange={e => setSearchKey(e.target.value)}
                                />
                                <span className="intent_search_icon">
                                    <SearchIcon />
                                </span>
                            </span>
                            <div className="right_panel_ht">
                                {intents
                                    .filter(e => e.intent.includes(searchKey.trim()))
                                    .map((intent: IntentT, i: number) => {
                                        return (
                                            <MoveCard
                                                key={i}
                                                label={intent.intent}
                                                isSelected={selected === intent.id}
                                                index={i}
                                                selected={selected}
                                                handleSelect={() => {
                                                    setSelected(intent.id)
                                                }}
                                            />
                                        )
                                    })}
                                <div className="margin-bottom-75" />
                            </div>

                            <ButtonWrapper className="right-panel_btn">
                                <Button
                                    primary
                                    type="button"
                                    onClick={() => {
                                        setPageState({
                                            exampleMoveMode: View,
                                            exampleMode: View
                                        })
                                        setSelected('')
                                    }}
                                >
                                    <label>Cancel</label>
                                </Button>
                                <Button
                                    type="button"
                                    onClick={() => {
                                        setSearchKey('')
                                        moveExample(selected, () => {
                                            setSelected('')
                                        })
                                    }}
                                    disabled={selected === ''}
                                    className={selected === '' ? 'editor_btn_disabled' : ''}
                                >
                                    <label>Move</label>
                                </Button>
                            </ButtonWrapper>
                            {moveExampleError && (
                                <div className="error_input_show move_example">
                                    <Tooltip
                                        className="target customTip"
                                        zIndex={100000}
                                        arrowSize={8}
                                        tagName="span"
                                        content={moveExampleError}
                                        distance={5}
                                    >
                                        <WarningAlertIcon />
                                    </Tooltip>
                                </div>
                            )}
                        </React.Fragment>
                    )}
                    {/* </ErrorBoundary> */}
                </div>
            </div>
        )
    }
    return null
}

const MoveCard = ({ index, label, selected, handleSelect, isSelected }: any) => {
    const [show, onshow] = useState(false)

    const handleDrag = () => {
        onshow(x => !x)
    }

    return (
        <div
            className={index == 0 ? 'select_flow_head select_flow_start' : 'select_flow_head'}
            onMouseEnter={handleDrag}
            onMouseLeave={handleDrag}
        >
            <h6>{label}</h6>
            <SelectBox show={selected !== '' || show} isSelected={isSelected} handleSelected={handleSelect} />
        </div>
    )
}

const ExamplesList = ({
    example,
    examples,
    PageState,
    setPageState,
    selectExample,
    deleteExample,
    editExample,
    editedExampl,
    permissions
}: {
    example: ExampleT
    examples: ExampleT[]
    PageState: PageStateT
    selectExample: Function
    setPageState: Function
    deleteExample: Function
    editExample: Function
    editedExampl: Function
    permissions: {
        intentWritePermission: boolean | null;
        exampleWritePermission: boolean | null;
        exampleDeletePermission: boolean | null;
    }
}) => {
    const [selection, setSelection] = useState<boolean>(false)

    const editedExampleName = () => {
        editedExampl()
    }

    const isSelected = (id: string) => {
        if (
            PageState.action === ActionT.SELECT ||
            PageState.action === ActionT.MULTIPLE_DELETE ||
            PageState.action === ActionT.MOVE
        ) {
            return PageState.selectedItems.filter((intent: string) => intent === id).length > 0
        }
        return false
    }

    const showSelection = () =>
        PageState.action === ActionT.SELECT ||
        PageState.action === ActionT.MULTIPLE_DELETE ||
        PageState.action === ActionT.MOVE ||
        selection
    return (
        <React.Fragment>
            {PageState.action === ActionT.EDIT && example.id === PageState.exampleid ? (
                <div className="entity_table_row edit_example" style={{ backgroundColor: '#FFE5D3' }}>
                    <div className="w-100 float-left h-100 d-flex">
                        <div
                            className="col-md-8 entity_table_row_1 intent_example_select pad0"
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            <input
                                className="intent_examples_input"
                                type="text"
                                value={PageState.example}
                                autoFocus
                                onChange={e => setPageState({ ...PageState, example: e.target.value })}
                                maxLength={255}
                            />
                            {examples.filter(
                                e => e.text.toLocaleLowerCase().trim() === PageState.example.toLocaleLowerCase().trim()
                            ).length === 0 ? (
                                <React.Fragment>
                                    <CircleButton
                                        primary
                                        margin
                                        onClick={() => {
                                            editedExampleName()
                                        }}
                                    >
                                        <span>
                                            <CheckIcon />
                                        </span>
                                    </CircleButton>

                                    <CircleButton primary margin onClick={() => setPageState(View)}>
                                        <span>
                                            <CloseIcon />
                                        </span>
                                    </CircleButton>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <CircleButton primary margin onClick={() => setPageState(View)}>
                                        <span>
                                            <CloseIcon />
                                        </span>
                                    </CircleButton>
                                    <p className="duplicate_error">Duplicate example name</p>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <Tr>
                    <List
                        showCheckBox
                        checkBoxShow={showSelection()}
                        render={(show: boolean, ToolBox: any) => {
                            return (
                                <>
                                    <Td size={8} className="entity_table_row_1 intent_table_row_1">
                                        <SelectBox
                                            show={show}
                                            isSelected={isSelected(example.id)}
                                            handleSelected={() => selectExample(example.id)}
                                        />
                                        <h4>{example.text}</h4>
                                    </Td>
                                    <Td size={2} className="entity_table_row_3 intent_table_row_3">
                                        <span className="entity_span">
                                            {formatDateInCard(
                                                example.updated.includes('Z') ? example.updated : example.updated + 'Z'
                                            )}{' '}
                                        </span>
                                        <span>|</span>
                                        <span className="entity_span">
                                            {formatAMPM(
                                                example.updated.includes('Z') ? example.updated : example.updated + 'Z'
                                            )}
                                        </span>
                                    </Td>
                                    <Td size={2}>
                                        {PageState.action !== ActionT.SELECT && (
                                            <div className="entity_table_row_4 text-right intent_table_row_4">
                                                {permissions.exampleWritePermission&& 
                                                <span onClick={() => editExample()}>
                                                    <Tooltip
                                                        className="target customTip "
                                                        zIndex={10000}
                                                        arrowSize={8}
                                                        tagName="span"
                                                        content={'Edit'}
                                                        distance={5}
                                                    >
                                                        <EditIcon />
                                                    </Tooltip>
                                                </span>}
                                                {permissions.exampleDeletePermission&&
                                                <span onClick={() => deleteExample(example)}>
                                                    <Tooltip
                                                        className="target customTip "
                                                        zIndex={10000}
                                                        arrowSize={8}
                                                        tagName="span"
                                                        content={'Delete'}
                                                        distance={5}
                                                    >
                                                        <DeleteIcon />
                                                    </Tooltip>
                                                </span>}
                                            </div>
                                        )}
                                    </Td>
                                </>
                            )
                        }}
                    />
                </Tr>
            )}
        </React.Fragment>
    )
}

type SelectBoxP = {
    show: boolean
    isSelected: boolean
    handleSelected: () => void
}

export const SelectBox: React.FunctionComponent<SelectBoxP> = ({ show, isSelected, handleSelected }) => (
    <label>
        <input
            type="radio"
            className="option-input radio"
            checked={isSelected}
            onClick={handleSelected}
            onChange={() => {}}
            style={{
                visibility: show ? 'visible' : 'hidden',
                border: show && !isSelected ? '#494949 solid 1px' : '#fff'
            }}
        />
    </label>
)

export default Examples
