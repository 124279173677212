/*eslint-disable*/

const R = require('ramda')

import { AskQueryDialogT, AutomationDialogT, DialogT, SlotContainerDialogT } from '../Types/WatsonT'
import { fetchActionConfigs, fetchUIJsonForFlows } from './AsyncOperations'

const actionMap = (arr: any[], result: string[]): string[] =>
    arr.reduce((accumulator, currentvalue): string[] => {
        if (currentvalue.children.length > 0) {
            return actionMap(
                currentvalue.children,
                currentvalue.value.meta_data.action_id
                    ? accumulator.concat(currentvalue.value.meta_data.action_id)
                    : accumulator
            )
        }
        if (currentvalue.value.meta_data.action_id) {
            return accumulator.concat(currentvalue.value.meta_data.action_id)
        }
        return accumulator
    }, result)

const flowUIMap = (uiJSONS: any[], output: string[]): string[] =>
    uiJSONS.reduce((accumulator, currentvalue): string[] => {
        return actionMap(currentvalue.custom_json.children[0].children, accumulator)
    }, output)

const actionConverter = (actions: any[]) =>
    actions.reduce((accumulator, currentvalue) => R.assoc(currentvalue.id, currentvalue, accumulator), {})

export const fetchDialogInfo = async (workspace: string, workspaceWatsonDialogs: DialogT[]) => {
    const workspaceWatsonDialogSort = sortBySibling(workspaceWatsonDialogs)
    const workspaceDialogs = convertOwnDialogJson(workspaceWatsonDialogSort, [])
    const flowNodes = fetchFlowNodes(workspaceDialogs)

    const flowUIJson = await fetchFlowPromise(workspace, flowNodes)
    const actions = await fetchActionConfigs(workspace, flowUIMap(flowUIJson.output, []))
    const ownContextFormat = ownContextFormatter(workspaceWatsonDialogSort, flowUIJson.output)
    console.log('actionConverter(actions.output):::', actionConverter(actions.output))
    return {
        ...ownContextFormat,
        workspaceDialogsWF: workspaceWatsonDialogSort,
        workspaceDialogsOF: workspaceDialogs,
        actionMixture: actionConverter(actions.output)
    }
}

const ownContextFormatter = (workspaceWatsonDialogSort: DialogT[], FlowUIArr: any[]): any =>
    workspaceWatsonDialogSort.reduce(
        (accumulator: any, currentValue: DialogT): any => {
            const workspaceDialogs = convertOwnDialogJson([currentValue], [])

            const workspaceContexts = fetchDialogContext(workspaceDialogs).filter((e: any) => e.variable != '')
            const flowNodes = fetchFlowNodes(workspaceDialogs)

            const flowUIJson = {
                ...accumulator.flowUIJson
            }

            flowUIJson[currentValue.dialog_node] = flowNodes.reduce((acc: any[], value: AutomationDialogT): any => {
                const flowID = value.context.SYS_ACTIONS[1].data.automation
                const currentFlow = FlowUIArr.filter((flow: any) => flow.id == flowID).pop()
                if (currentFlow) {
                    acc[value.dialog_node] = currentFlow
                }
                return acc
            }, [])

            const dialogContext = {
                ...accumulator.dialogContext
            }
            dialogContext[currentValue.dialog_node] = workspaceContexts
            return {
                ...accumulator,
                dialogContext: dialogContext,
                flowUIJson
            }
        },
        { dialogContext: {}, flowUIJson: {} }
    )

const fetchFlowPromise = (workspace: string, source: AutomationDialogT[]): Promise<any> =>
    fetchUIJsonForFlows(
        workspace,
        source.reduce(
            (accumulator: string[], currentValue: AutomationDialogT): string[] =>
                accumulator.concat(currentValue.context.SYS_ACTIONS[1].data.automation),
            []
        )
    )

const fetchDialogContext = (source: DialogT[]): string[] =>
    Object.values(
        source.reduce((accumulator: any, currentValue: DialogT): any => {
            if (currentValue.type == 'slot-container') {
                const trust = currentValue as SlotContainerDialogT
                trust.slots.forEach((slot: any) => {
                    if (slot.variable != '') {
                        const variable =
                            slot.variable.slice(0, 1) == '$'
                                ? slot.variable.slice(1, slot.variable.length)
                                : slot.variable
                        accumulator[variable] = { variable, node: trust }
                    }
                })
            } else if (currentValue.type == 'qa') {
                const trust = currentValue as AskQueryDialogT
                accumulator[trust.variable] = { variable: trust.variable, node: trust }
            }
            return accumulator
        }, {})
    )

export const fetchFlowNodes = (source: DialogT[]): AutomationDialogT[] =>
    source.reduce((accumulator: AutomationDialogT[], currentValue: DialogT): AutomationDialogT[] => {
        if (currentValue.context && currentValue.context.SYS_ACTIONS) {
            return accumulator.concat(currentValue as AutomationDialogT)
        }
        return accumulator
    }, [])

export const convertOwnDialogJson = (source: DialogT[], ownList: DialogT[]) =>
    source.reduce((accumulator: DialogT[], currentValue: DialogT): DialogT[] => {
        const { children } = currentValue
        const data: DialogT[] = accumulator.concat(currentValue)
        if (children && children.length > 0) {
            return convertOwnDialogJson(children, data)
        }
        return data
    }, ownList)

export const sortBySibling = (source: DialogT[]) =>
    source.reduce((accumulator: DialogT[], currentValue: DialogT, index: number, arr: DialogT[]): DialogT[] => {
        if (accumulator.length) {
            const previous_sibling = accumulator.pop()
            if (previous_sibling) {
                const next_sibling = arr
                    .filter((val: DialogT) => previous_sibling.dialog_node == val.previous_sibling)
                    .pop()
                return next_sibling
                    ? accumulator.concat(previous_sibling, next_sibling)
                    : accumulator.concat(previous_sibling)
            }
            return accumulator
        } else {
            const root = arr.filter(value => value.previous_sibling == null).pop()
            return root ? accumulator.concat(root) : []
        }
    }, [])

export const fetchRootDialog = (dialog_node: string, workspaceDataMixture: DialogT[]): any => {
    const parent = workspaceDataMixture.filter(e => e.dialog_node == dialog_node).pop()
    if (parent && parent.parent != null) {
        return fetchRootDialog(parent.parent, workspaceDataMixture)
    } else {
        return parent
    }
}
