/*eslint-disable*/
import React, { useCallback, useEffect, useState } from 'react'

// Common Loaders
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { Loader } from '@/common/components/Loader'
// Async Function
import { getJson } from '@/common/utils/axios-utils'

import { fetchDialogs, watsonApiURL } from './Actions/AsyncOperations'
// Context Proccessing's
import { convertOwnDialogJson, fetchDialogInfo, sortBySibling } from './Actions/ContextManager'
// Import Context
import { PageContext } from './Actions/ReactContext'
//Dialog Editor Components
import { CanvasSidePanel } from './Components/CanvasSidePanel'
import { DialogHeader } from './Components/DialogHeader'
import { DialogNodes } from './Components/DialogNodes'
import { DialogTree } from './Components/DialogTree'
// Types
import { OPERATIONS, PageLoading, PageState, PageView } from './Types/PageT'
import { ErrorState, ErrorT, MOCK_IF_NODE } from './Types/Types'
import { DialogT, RecognizeDialogT } from './Types/WatsonT'

const Ramda = require('ramda')

export const Canvas = (props: any) => {
    const workspace = props.match.params.workspacename

    const dialogNode = props.match.params.node

    const [PageState, setPageState] = useState<PageState>(PageLoading)

    const [error, setError] = useState<ErrorT>(ErrorState)

    const [unauthorized, setUnauthorized] = useState<boolean>(false)

    const [isDetailView, setDetailView] = useState<boolean>(false)

    const [sidePanel, setSidePanel] = useState<boolean>(true)

    const [workspaceDataMixture, setWorkspaceDataMixture] = useState<DialogT[]>([])

    const [workspaceDataWF, setWorkspaceDataWF] = useState<RecognizeDialogT[]>([])

    const [dataMixture, setDataMixture] = useState<DialogT[]>([])

    const [actionMixture, setActionMixture] = useState<any[]>([])

    const [currentDialog, setCurrentDialog] = useState<RecognizeDialogT>(MOCK_IF_NODE)

    const [dialogContext, setDialogContext] = useState({})

    const [flowUIJson, setFlowUIJson] = useState({})

    const [dialogIndex, setDialogIndex] = useState<string>('')

    const [intents, setIntents] = useState([])

    const [entities, setEntities] = useState([])

    const converter = (data: any) => {
        const currentDialog = data.workspaceDialogsWF.filter((e: RecognizeDialogT) => e.dialog_node == dialogNode).pop()
        if (currentDialog) {
            setCurrentDialog(currentDialog)
            if (currentDialog.dialog_node != 'welcome' && currentDialog.dialog_node != 'anything_else') {
                const DialogIndex =
                    data.workspaceDialogsWF
                        .filter((e: RecognizeDialogT) => e.dialog_node != 'welcome')
                        .filter((e: RecognizeDialogT) => e.dialog_node != 'anything_else')
                        .findIndex((e: RecognizeDialogT) => e.dialog_node == dialogNode) + 1

                setDialogIndex(
                    DialogIndex < 9 ? '0' + DialogIndex.toString() : DialogIndex == 9 ? '09' : DialogIndex.toString()
                )
            }
            setFlowUIJson(data.flowUIJson)
            setDialogContext(data.dialogContext)
            setWorkspaceDataWF(data.workspaceDialogsWF)
            setDataMixture(convertOwnDialogJson([currentDialog], []))
            setWorkspaceDataMixture(data.workspaceDialogsOF)
            setActionMixture(data.actionMixture)
            if (currentDialog.conditions == '') {
                setPageState({
                    operation: OPERATIONS.RecognizeDialogPT,
                    input: currentDialog,
                    previousSibling: null,
                    parent: null
                })
            } else {
                setPageState(PageView)
            }
        } else {
            props.history.push(`/workspace/${workspace}/dialog/home`)
        }
    }

    const fetch = () =>
        new Promise((resolved, reject) => {
            fetchDialogs(workspace)
                .then(res => {
                    fetchDialogInfo(workspace, res)
                        .then(data => {
                            converter(data)
                            resolved('success')
                        })
                        .catch(err => {
                            console.error('error:::', err)
                            setError({
                                error: true,
                                info: `Server error!<br />Please try again after sometime.`
                            })
                            reject('error')
                        })
                })
                .catch(err => {
                    reject('error')

                    if (err && err.response && err.response.status == 403) {
                        setUnauthorized(true)
                    } else {
                        setError({
                            error: true,
                            info: `Server error!<br />Please try again after sometime.`
                        })
                    }
                })
        })

    useEffect(() => {
        Promise.all([
            getJson(watsonApiURL(workspace, '/intents')),
            getJson(watsonApiURL(workspace, '/entities')),
            fetch()
        ])
            .then((data: any) => {
                setIntents(data[0].intents)
                setEntities(data[1].entities)
            })
            .catch((err: any) => {
                setPageState(PageView)
                if (err && err.response && err.response.status == 403) {
                    setUnauthorized(true)
                } else {
                    setError({
                        error: true,
                        info: `Server error!<br />Please try again after sometime.`
                    })
                }
            })
    }, [])

    const fetchCallback = (callback: Function) => fetch().then(() => callback())

    const MixChild = (Mixture: any) => (element: RecognizeDialogT) =>
        Ramda.compose(
            sortBySibling,
            Ramda.filter((e: DialogT) => e.parent == element.dialog_node)
        )(Mixture)

    const fetchChild = useCallback(() => MixChild(dataMixture), [dataMixture])()

    const fetchWorkspaceChild = useCallback(() => MixChild(workspaceDataMixture), [workspaceDataMixture])()

    return (
        <>
            {PageState.operation === OPERATIONS.PageLoading ? (
                <Loader.PageLoader show={true} />
            ) : (
                <div className="automation_canvas">
                    <div className="flex_canvas_right">
                        <ErrorBoundary
                            error={error}
                            unauthorized={unauthorized}
                            render={(err: any, info: any) => {
                                return (
                                    err && (
                                        <Error.PageLoadingError
                                            onClick={() => window.location.reload()}
                                            info={info}
                                            btnName="Retry"
                                        />
                                    )
                                )
                            }}
                        >
                            <PageContext.Provider
                                value={{
                                    workspace,
                                    PageState,
                                    setPageState,
                                    isDetailView,
                                    setDetailView,
                                    setHistory: (url: string) => props.history.replace(url),
                                    currentDialog,
                                    DialogIndex: dialogIndex,
                                    DataMixture: dataMixture,
                                    fetchChild,
                                    workspaceDataMixture,
                                    fetchWorkspaceChild,
                                    workspaceDataWF,
                                    intents,
                                    setIntents,
                                    entities,
                                    fetchCallback,
                                    dialogContext,
                                    flowUIJson,
                                    actionMixture
                                }}
                            >
                                <div
                                    className={
                                        sidePanel
                                            ? 'flex_side_menu_dialog dialog_model_overlay'
                                            : 'flex_side_menu_dialog'
                                    }
                                    style={sidePanel ? { display: 'block' } : { display: 'none' }}
                                >
                                    <section className="dialog_canvas_startup_page">
                                        <CanvasSidePanel dialog={currentDialog} />
                                    </section>
                                </div>
                                <span
                                    className={
                                        sidePanel
                                            ? 'sub_panel_toggle_btn dialog_canvas_close_arrow '
                                            : 'sub_panel_toggle_btn dialog_canvas_open_arrow'
                                    }
                                    onClick={() => setSidePanel(!sidePanel)}
                                >
                                    <svg className="rotate_dropdown" viewBox="0 0 240.811 240.811">
                                        <g>
                                            <path
                                                id="Expand_More"
                                                d="M220.088,57.667l-99.671,99.695L20.746,57.655c-4.752-4.752-12.439-4.752-17.191,0 c-4.74,4.752-4.74,12.451,0,17.203l108.261,108.297l0,0l0,0c4.74,4.752,12.439,4.752,17.179,0L237.256,74.859 c4.74-4.752,4.74-12.463,0-17.215C232.528,52.915,224.828,52.915,220.088,57.667z"
                                            />
                                        </g>
                                    </svg>
                                </span>

                                <section className="dialog_entity_cards">
                                    <section className="entity_cards">
                                        <DialogHeader />

                                        <div className="col-md-12">
                                            <section
                                                className={
                                                    isDetailView ? 'dialog_editor dialog_detail' : 'dialog_editor'
                                                }
                                            >
                                                <DialogTree />
                                                <DialogNodes
                                                    deploybot={props.DeployBot}
                                                    isdeployed={props.isdeployed}
                                                    publishBot={props.PublishBot}
                                                    isPublish={props.isPublish}
                                                />
                                            </section>
                                        </div>
                                    </section>
                                    <section>
                                        {PageState.operation !== OPERATIONS.AssistantView ? (
                                            <div className="tryme_popup">
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() =>
                                                        setPageState({
                                                            operation: OPERATIONS.AssistantView
                                                        })
                                                    }
                                                >
                                                    <span>Try Me</span>
                                                </button>
                                            </div>
                                        ) : null}
                                    </section>
                                </section>
                            </PageContext.Provider>
                        </ErrorBoundary>
                    </div>
                </div>
            )}
        </>
    )
}
