import * as ramda from 'ramda';
/*eslint-disable*/
import * as React from 'react';
import Tooltip from 'react-tooltip-lite';
import styled from 'styled-components';

import { getAssistants } from '@/Assistant/Mechanisms/action';
import { JourneyState } from '@/Journey/Journey';
import { RootJourneyContextConsumer } from '@/Journey/JourneyContext';
import {
  AppTriggerCardIconNew,
  DeleteIcon,
  EditIcon,
  ErrorIcon,
  FlowBellIcon,
  SaveAutomation,
  TimerIcon,
  WebhookCardIcon,
} from '@/common/Icons/Icons';
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons';
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary';
import Validate, { ErrorContext } from '@/common/components/FormValidate';
import { PageWrapper } from '@/common/components/Header';
import InputBox from '@/common/components/InputBox';
import { List } from '@/common/components/List';
import { Loader } from '@/common/components/Loader';
import { Modal } from '@/common/components/Modal';
import { ListSelectBox } from '@/common/components/SelectBox';
import lazyGA from '@/common/google-analytics/tracking';
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb';
import { ButtonWrapper, Paragraph } from '@/common/styled/Dialogue.Dumb';
import {
  BotGridsContainer,
  BotWrapper,
  DownloadContent,
  OrSticky,
} from '@/common/styled/GetStarted.Dumb';
import {
  ErrorMessage,
  Input,
  InputContainer,
  InputContent,
  InputErrorIcon,
  InputWrapper,
  Table,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
} from '@/common/styled/Workflow.Analytics.Dumb';
import {
  EmptyValidation,
  flowValidations,
  inputTextAreaValidationsForWorkflow,
  newLine,
} from '@/common/utils/_validation-utils';

import {
  formatAMPM,
  formatDateInCard,
  removeURLParams,
} from '@/common/utils/utils';
import {
  AutomationApi,
  FlowApi,
  automationAPIURLWithWorkspace,
  deleteJson,
  flowApiURL,
  postJson,
  putJson,
} from '@/common/utils/api-utils';
import { getProductId } from '@/common/utils/utils';
import { AutomationMarketplaceDownload } from './AutomationMarketplaceDownload';
import Card from './Card';
import FlowEditorHeader from './FlowEditorHeader';
import GroupCard from './GroupCard';
import { Move } from './Move';
import WorkflowBuilder from './WorkflowBuilder';
import {
  AciveOrDeactive,
  Add,
  AddT,
  CardDisplayT,
  CardTypes,
  DeactivateLinkedDialogT,
  DeleteCards,
  DeleteLinkedAutomationT,
  DeleteSingleCardT,
  EditT,
  ErrorPage,
  FlowFilterE,
  FlowFilterT,
  FlowResponseT,
  FlowsResponseT,
  GridView,
  GroupResponseT,
  Loading,
  Mode,
  Modes,
  MoveT,
  MoveToGroup,
  NoDataFound,
  NoWorkflowsCreatedInitalRediraction,
  SEARCH_KEY,
  SaveAsT,
  SearchT,
  SelectT,
  SortT,
  SortType,
  View,
  ViewTypeT,
  Views,
} from './types';
import {
  defaultGroupName,
  flowsResponseToCards,
  getDuplicateName,
  selectedFlowCards,
  selectedFlowList,
  selectedGroupCards,
  selectedGroupList,
} from './utils';

var qs = require('qs');

function isNumeric(str: string) {
  if (typeof str != 'string') return false; // we only process strings!
  return (
    !isNaN(str as any) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  ); // ...and ensure strings of whitespace fail
}

const FlowListWrapper = styled.div`
  width: 100%;
  float: left;
  .list_flow_span span {
    font-size: 9px;
  }
`;
const TabelWrapper = styled.div`
  width: 100%;
  float: left;
  .list_flow_hr label {
    float: left;
    display: flex;
    position: absolute;
    top: 18px;
    width: 16px;
    height: 16px;
    margin-bottom: 0px;
    left: -22px;
  }
  .entity_cards {
    overflow: hidden;
    height: calc(100vh - 70px);
  }
  .entity_cards._grid_view {
    overflow-y: scroll;
  }

  ${Table} {
    margin-top: 0px;
    background: none;
    box-shadow: none;
  }
  ${Thead} {
    ${Tr} {
      padding-left: 32px;
      padding-right: 20px;

      ${Th} {
        :nth-child(2) {
          padding-right: 20px;
        }
        :nth-child(3) {
          padding-right: 0px;
          justify-content: center;
          // @media (max-width: 1500px) {
          //   padding-right: 0px;
          // }
        }
        :nth-child(4) {
          padding-left: 36px;
          // @media (max-width: 1500px) {
          //   padding-left: 15px;
          // }
        }
        :nth-child(5) {
          position: relative;
          right: 0px;
        }
        :last-child {
          justify-content: flex-end;
        }
      }
    }
  }
  ${Tbody} {
    border-radius: 0px 0px 10px 10px;
    max-height: calc(100vh - 152px);
    height: auto;
    padding-bottom: 60px;
    ${Tr} {
      height: 48px;
      padding-left: 0;
      padding-right: 0;

      .list-full-width {
        width: 100%;
        float: left;
        padding-left: 32px;
        padding-right: 20px;
      }
      .list-view-icon span {
        width: auto;
        height: 18px;
        padding-right: 0px;
      }
      .automation_count_dilaog {
        font-size: 12px;
      }
      .error_input_show span {
        width: 180px;
      }
      .list_flow_span .entity_span {
        :first-child {
          margin-right: 3px;
          color: #000;
          font-size: 9px;
        }
        :last-child {
          margin-left: 3px;
          color: #000;
          font-size: 9px;
        }
      }
      .box_1 {
        padding: 0;
      }
      :hover ${Td} {
        :last-child {
          .list-view-icon span {
            margin-left: 10px;
          }
          .list-view-icon span svg {
            display: flex;
          }
          .list-view-icon span.no_input_value {
            display: flex;
            margin-right: 0px;
          }
        }
      }

      ${Td} {
        padding: 0px;
        height: 48px;
        font-size: 12px;
        font-family: 'Gordita-Regular';
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        color: #000;
        span {
          margin-right: 0px;
        }

        .list_flow_span_example span {
          font-size: 12px;
          font-family: 'Gordita-Regular';
          color: #000;

          span {
            color: #f07f31;
          }
        }
        :nth-child(1) {
          display: flex;
          padding-right: 20px;
          cursor: pointer;
          position: relative;
          font-family: 'Gordita-Medium';
          position: relative;
          padding-right: 20px;
          div {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          label {
            float: left;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            position: absolute;
            top: 23px;
            width: 16px;
            height: 16px;
            margin-bottom: 0px;
            left: -22px;
            visibility: visible;
            margin: 0px;
            .option-input.radio {
              margin: 0px;
              top: 0px;
            }
          }
        }
        :nth-child(2) {
          padding-right: 20px;
          div {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
        :nth-child(3) {
          padding-right: 50px;
          justify-content: center;
        }
        :nth-child(4) {
          padding-left: 20px;
        }
        :last-child {
          .list-view-icon span {
            margin-left: 0px;
          }
          .list-view-icon span svg {
            display: none;
          }
          .list-view-icon span.no_input_value {
            display: flex;
            margin-right: 10px;
            svg {
              display: flex;
            }
          }
        }
      }
      :last-child {
        border-radius: 0px 0px 10px 10px;
        // max-height: calc(100vh - 152px);
        // height: auto;
        padding-bottom: 60px;
        ${Tr} {
            height: 48px;
            padding-left: 0;
            padding-right: 0;

            .list-full-width {
                width: 100%;
                float: left;
                padding-left: 32px;
                padding-right: 20px;
            }
            .list-view-icon span {
                width: auto;
                height: 18px;
                padding-right: 0px;
            }
            .automation_count_dilaog {
                font-size: 12px;
            }
            .error_input_show span {
                width: 180px;
            }
            .list_flow_span .entity_span {
                :first-child {
                    margin-right: 3px;
                    color: #000;
                    font-size: 9px;
                }
                :last-child {
                    margin-left: 3px;
                    color: #000;
                    font-size: 9px;
                }
            }
            .box_1 {
                padding: 0;
            }
            :hover ${Td} {
                :last-child {
                    .list-view-icon span {
                        margin-left: 10px;
                    }
                    .list-view-icon span svg {
                        display: flex;
                    }
                    .list-view-icon span.no_input_value {
                        display: flex;
                        margin-right: 0px;
                    }
                }
            }

            ${Td} {
                padding: 0px;
                height: 48px;
                font-size: 12px;
                font-family: 'Gordita-Regular';
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                color: #000;
                span {
                    margin-right: 0px;
                }

                .list_flow_span_example span {
                    font-size: 12px;
                    font-family: 'Gordita-Regular';
                    color: #000;

                    span {
                        color: #f07f31;
                    }
                }
                :nth-child(1) {
                    display: flex;
                    padding-right: 20px;
                    cursor: pointer;
                    position: relative;
                    font-family: 'Gordita-Medium';
                    position: relative;
                    padding-right: 20px;
                    div {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    label {
                        float: left;
                        display: -webkit-box;
                        display: -webkit-flex;
                        display: -ms-flexbox;
                        display: flex;
                        position: absolute;
                        top: 16px;
                        width: 16px;
                        height: 16px;
                        margin-bottom: 0px;
                        left: -22px;
                        visibility: visible;
                        margin: 0px;
                        .option-input.radio {
                            margin: 0px;
                            top: 6px;
                        }
                    }
                }
                :nth-child(2) {
                    padding-right: 20px;
                    div {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                }
                :nth-child(3) {
                    padding-right: 30px;
                    justify-content: center;
                }
                :nth-child(4) {
                    padding-left: 20px;
                }
                :last-child {
                    .list-view-icon span {
                        margin-left: 0px;
                    }
                    .list-view-icon span svg {
                        display: none;
                    }
                    .list-view-icon span.no_input_value {
                        display: flex;
                        margin-right: 10px;
                        svg {
                            display: flex;
                        }
                    }
                }
            }
            :last-child {
                border-radius: 0px 0px 10px 10px;
            }
        }
    }
  }
`;
// export const AddExamplesWrapper = styled.div`
//     width: 100%;
//     float: left;
//     ${ButtonWrapper} {
//         margin-top: 8px;
//     }
//     ${InputContainer} {
//         margin-bottom: 12px;
//     }
// `
export const BotStartedWrapper = styled.div`
  width: 100%;
  float: left;
  padding: 20px;
  .flow_card_wrapper {
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 80px;

    .flow_card_wrapper_left img {
      margin-bottom: 30px;
      width: auto;
      height: auto;
    }
    .flow_card_wrapper_right {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;

      img {
        width: auto;
        box-shadow: 0px 10px 20px #00000029;
        border: 2px solid #ffffff;
        border-radius: 10px;
      }
    }
  }
  ${BotGridsContainer} {
    grid-template-columns: 100%;
    gap: 10px;
  }
  ${BotWrapper} {
    padding: 40px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
  }
  ${ButtonWrapper} {
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;

    ${DownloadContent} {
      width: auto;
      margin: 0;
      label {
        position: relative;
        top: 1px;
        margin: 0px;
        cursor: pointer;
      }
      span {
        width: 24px;
        height: 24px;
        float: left;
        margin-right: 6px;
      }
    }
  }
  .or_sticky {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    :before {
      content: '';
      position: absolute;
      width: 100%;
      border-bottom: 1px solid #c6c6c6;
      top: 24px;
    }
    ${OrSticky} {
      top: 0px;
      width: 48px;
      height: 48px;
      font-size: 16px;
      label {
        position: relative;
        top: 3px;
        margin: 0px;
      }
      :before {
        display: none;
      }
      :after {
        display: none;
      }
    }
  }
`;
type QueryParamT = {
  showIntialCreationModel: 'true' | undefined;
  redirect_from_not_found: string | undefined | null;
};

type FlowS = FlowsResponseT & {
  mode: Modes;
  cardsToRender: CardDisplayT[];
  searchItem: string;
  sortBy: SortT;
  viewMode: Views;
  showPopupLoader: boolean;
  currentFilter: FlowFilterE;
  homeFilter: FlowFilterE;
  PageError: { error: boolean; info: string };
  automationListWithoutConnection: string[];
  automationListWithApproval: { id: string }[];
  showMarketPlace: boolean;
  showWorkflowNotFound: boolean;
};
export class Flow extends React.Component<any, FlowS> {
  override state: FlowS = {
    automations: [],
    groups: [],
    cardsToRender: [],
    mode: Loading,
    searchItem: SEARCH_KEY,
    sortBy: SortType.Date,
    viewMode: GridView,
    showPopupLoader: false,
    currentFilter: FlowFilterE.Draft,
    homeFilter: FlowFilterE.Home,
    PageError: { error: false, info: '' },
    automationListWithoutConnection: [],
    automationListWithApproval: [],
    showMarketPlace: false,
    showWorkflowNotFound: false,
  };
  workspaceName: string = this.props['match'].params.workspacename;
  flowView = this.props['match'].params.view as FlowFilterT;

  queryParams: QueryParamT = qs.parse(
    this.props['location'].search.substring(1)
  );

  override UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { props } = this;
    if (props['state'].type != nextProps.state.type) {
      if (nextProps.state.type == 'loading') {
        this.setState({ mode: Loading });
      } else if (nextProps.state.type == 'error') {
        this.setState({
          mode: ErrorPage,
          PageError: {
            error: true,
            info: 'There was a problem in loading Page! <br/> Please click on "Retry"',
          },
        });
      } else if (nextProps.state.type == 'success') {
        if (
          this.queryParams.redirect_from_not_found !== undefined &&
          this.queryParams.redirect_from_not_found !== null
        ) {
          this.setState({ showWorkflowNotFound: true });
        }
        const flowList: FlowsResponseT =
          this.state.automations.length == 0 ||
          this.state.automationListWithApproval.length !=
            nextProps.state.data.automationListWithApproval.length
            ? nextProps.state.data
            : this.state;

        const view = nextProps.match.params.view;
        if (flowList.automations.length > 0) {
          this.setState({
            currentFilter: view,
            automationListWithoutConnection:
              flowList.automationListWithoutConnection
                ? flowList.automationListWithoutConnection
                : [],
            automationListWithApproval: flowList.automationListWithApproval
              ? flowList.automationListWithApproval
              : [],
          });
          this.filterData(flowList, view);
        } else {
          if (flowList.groups.length > 0) {
            this.setState({ groups: flowList.groups });
          }
          if (this.queryParams.showIntialCreationModel === 'true') {
            this.setState({ mode: NoWorkflowsCreatedInitalRediraction });
          } else {
            this.setState({ mode: NoDataFound });
          }
        }
      }
    }
    if (nextProps.match.params.view !== props['match'].params.view) {
      this.flowView = nextProps.match.params.view as FlowFilterT;
      //console.log('nextProps.state.type', nextProps.state.type)
      if (nextProps.state.type == 'error') {
        //console.log('error page')
        this.setState({
          mode: ErrorPage,
          PageError: {
            error: true,
            info: 'There was a problem in loading Page! <br/> Please click on "Retry"',
          },
        });
      } else if (nextProps.state.data) {
        const flowList: FlowsResponseT =
          this.state.automations.length == 0
            ? nextProps.state.data
            : this.state;
        const view = nextProps.match.params.view;

        console.log('DOINg :: VIEW', view);

        if (flowList.automations.length > 0) {
          this.setState({ currentFilter: view });
          this.filterData(flowList, view);
        } else {
          if (this.queryParams.showIntialCreationModel === 'true') {
            this.setState({ mode: NoWorkflowsCreatedInitalRediraction });
          } else {
            this.setState({ mode: NoDataFound });
          }
        }
      }
    }
  }

  filterData = (flowList: FlowsResponseT, view: FlowFilterT) => {
    const cardsToRender = flowsResponseToCards(
      flowList,
      this.state.sortBy,
      view
    );
    const crateNew = new URLSearchParams(window.location.search).get(
      'create-workflow'
    );

    this.setState({
      automations: flowList.automations,
      groups: flowList.groups,
      mode: crateNew ? Add : View,
      sortedList: flowList.sortedList ? flowList.sortedList : [],
      cardsToRender,
      homeFilter: FlowFilterE.Home,
    });
  };

  resetToViewMode = () => {
    this.setState({ mode: View });
    // removeURLParams('create-workflow');
  };

  redirectToFlow = (id: string) => {
    const location = {
      pathname: `/workspace/${this.props['match'].params.workspacename}/automation/detail/${id}`,
      state: { group: false, flow: true },
    };
    this.props['history'].push(location);
  };

  redirectToGroup = (id: string) => {
    const location = {
      pathname: `/workspace/${this.props['match'].params.workspacename}/automation/group/${id}`,
      state: { view: this.state.viewMode },
    };
    this.props['history'].push(location);
  };

  setToFlowView = () => {
    const { automations, groups } = this.state;
    const flowsResponse = {
      automations: automations,
      groups: groups,
      automationListWithoutConnection:
        this.state.automationListWithoutConnection,
      automationListWithApproval: this.state.automationListWithApproval,
    };
    this.setState({
      searchItem: SEARCH_KEY,
      ...responseToViewMode(
        flowsResponse,
        this.state.sortBy,
        this.props['match'].params.view,
        this.state.homeFilter
      ),
    });
  };

  resetToGroupViewMode = () => {
    this.setState({ mode: View });
  };

  setToAddMode = () => {
    this.setState({ mode: Add });
  };

  sortByApply = (sortType: SortT) => {
    const { automations, groups } = this.state;
    const flowsResponse = {
      automations: automations,
      groups: groups,
      sortedList: this.state.sortedList ? this.state.sortedList : [],
      automationListWithoutConnection:
        this.state.automationListWithoutConnection,
      automationListWithApproval: this.state.automationListWithApproval,
    };

    this.setState({
      sortBy: sortType,
      ...responseToViewMode(
        flowsResponse,
        sortType,
        this.props['match'].params.view,
        this.state.homeFilter
      ),
    });
  };

  homeFilterApply = (filter: FlowFilterT, forAllFilter: boolean) => {
    const { automations, groups } = this.state;
    const flowsResponse = {
      automations: automations,
      groups: groups,
      sortedList: this.state.sortedList ? this.state.sortedList : [],
      automationListWithoutConnection:
        this.state.automationListWithoutConnection,
      automationListWithApproval: this.state.automationListWithApproval,
    };

    this.setState({
      homeFilter: forAllFilter ? FlowFilterE.Home : filter,
      ...responseToViewMode(
        flowsResponse,
        this.state.sortBy,
        this.props['match'].params.view,
        filter
      ),
    });
  };

  setToDeleteFlow = (card: CardDisplayT) => {
    if (card.linkedDialogs && card.linkedDialogs.length > 0) {
      const toDelete: DeleteLinkedAutomationT = {
        mode: Mode.DeleteLinkedAutomation,
        card: card,
        dialog: card.linkedDialogs,
        flowId: card.id,
      };

      this.setState({ mode: toDelete });
    } else {
      const toDelete: DeleteSingleCardT = {
        mode: Mode.Delete,
        card: card,
        deleteAction: 'Delete',
      };

      this.setState({ mode: toDelete });
    }
  };

  setToDeleteGroup = (card: CardDisplayT) => {
    const toDelete: DeleteSingleCardT = {
      mode: Mode.Delete,
      card: card,
      deleteAction: 'Delete',
    };
    this.setState({ mode: toDelete });
  };

  setToUngroup = (card: CardDisplayT) => {
    const toDelete: DeleteSingleCardT = {
      mode: Mode.Delete,
      card: card,
      deleteAction: 'Ungroup',
    };
    this.setState({ mode: toDelete });
  };

  setToDeleteMultipleFlows = () => {
    const selectMode = this.state.mode as SelectT;
    selectMode.action = DeleteCards;

    this.setState({ mode: selectMode });
  };

  setToEditMode = (card: CardDisplayT) => {
    const mode: EditT = {
      mode: Mode.Edit,
      cardType: CardTypes.Flow,
      name: card.name,
      id: card.id,
      description: card.description,
      error: { error: false, info: '' },
    };

    this.setState({ mode: mode });
  };

  setToSaveAsMode = (card: CardDisplayT) => {
    const name = getDuplicateName(card.name, this.state.automations);

    const mode: SaveAsT = {
      mode: Mode.SaveAs,
      name: name,
      id: card.id,
      description: card.description,
      error: {
        error: false,
        info: '',
      },
    };

    this.setState({ mode: mode });
  };

  setToGroupEditMode = (card: CardDisplayT) => {
    const mode: EditT = {
      mode: Mode.Edit,
      cardType: CardTypes.Group,
      id: card.id,
      name: card.name,
      description: card.description,
      error: { error: false, info: '' },
    };

    this.setState({ mode: mode });
  };
  setToSearchMode = () => {
    const mode: SearchT = {
      mode: Mode.Search,
      query: '',
    };
    this.setState({ mode });
  };

  deleteCard = async () => {
    try {
      const mode = this.state.mode as DeleteSingleCardT;
      if (mode.deleteAction == 'Delete' || mode.deleteAction == 'Ungroup') {
        const { card, deleteAction } = mode;
        const { automations, groups, sortedList } = this.state;
        if (card.cardType == CardTypes.Flow) {
          const flowsAdded = await deleteFlow(
            card.id,
            automations,
            this.workspaceName
          );
          const flowsResponse = {
            automations: flowsAdded,
            groups: groups,
            sortedList: this.state.sortedList ? this.state.sortedList : [],
            automationListWithoutConnection:
              this.state.automationListWithoutConnection,
            automationListWithApproval: this.state.automationListWithApproval,
          };
          this.setState(
            responseToViewMode(
              flowsResponse,
              this.state.sortBy,
              this.props['match'].params.view,
              this.state.homeFilter
            )
          );
        } else if (card.cardType == CardTypes.Group) {
          const sortedListD = sortedList ? sortedList : [];
          if (deleteAction == 'Ungroup') {
            await ungroup(
              card.id,
              groups,
              automations,
              sortedListD,
              this.workspaceName
            );

            // await this.loadData()
          } else {
            const flowsResponse = await deleteGroup(
              card.id,
              groups,
              automations,
              this.workspaceName
            );
            this.setState(
              responseToViewMode(
                {
                  ...flowsResponse,
                  automationListWithoutConnection:
                    this.state.automationListWithoutConnection,
                  automationListWithApproval:
                    this.state.automationListWithApproval,
                },
                this.state.sortBy,
                this.props['match'].params.view,
                this.state.homeFilter
              )
            );
          }
        }
      }
    } catch (err) {
      const toDelete: DeleteSingleCardT = {
        mode: Mode.Delete,
        error: err.response.data.error,
        deleteAction: 'Error',
      };
      this.setState({ mode: toDelete });
    }

    lazyGA().event({
      category: 'Automation',
      action: 'Delete Automation',
    });
  };

  deleteCards = async () => {
    try {
      const mode = this.state.mode as SelectT;
      const { automations, groups } = this.state;
      const flowsS = selectedFlowList(mode.cards);
      const groupS = selectedGroupList(mode.cards);
      const flowsInSeletedGroup = automations
        .filter((flow) => groupS.includes(flow.groupId))
        .map((flow) => flow.id);
      const allDeletedFlows = [...flowsS, ...flowsInSeletedGroup];
      if (flowsS.length > 0) {
        await Promise.all(
          flowsS
            .map((id) => ({
              id: id,
            }))
            .map((deleteData) =>
              deleteJson(
                automationAPIURLWithWorkspace(
                  this.workspaceName,
                  `${FlowApi.DeleteFlow}/${deleteData.id}`
                )
              )(deleteData)
            )
        );
      }
      if (groupS.length > 0) {
        await postJson(flowApiURL(this.workspaceName, FlowApi.DeleteGroups))({
          groups: groupS,
        });
      }

      const remainingFlows = automations.filter(
        (flow) => !allDeletedFlows.includes(flow.id)
      );
      const remainingGroups = groups.filter(
        (group) => !groupS.includes(group.id.toString())
      );

      const flowsResponse = {
        automations: remainingFlows,
        groups: remainingGroups,
        sortedList: this.state.sortedList,
        automationListWithoutConnection:
          this.state.automationListWithoutConnection,
        automationListWithApproval: this.state.automationListWithApproval,
      };

      this.setState(
        responseToViewMode(
          flowsResponse,
          this.state.sortBy,
          this.props['match'].params.view,
          this.state.homeFilter
        )
      );
    } catch (err) {
      const mode = this.state.mode as SelectT;
      mode.error = {
        error: true,
        info: err.response.data.error,
      };
      this.setState({ mode });
    }
    lazyGA().event({
      category: 'Automation',
      action: 'Delete multiple Automations',
    });
  };

  saveCard = async (journey?: JourneyState) => {
    const { mode: modes, automations, groups } = this.state;

    switch (modes.mode) {
      case Mode.NoWorkflowsCreatedInitalRediraction:
        {
          try {
            const { name } = modes;
            const defaultGroupId = groups.filter((x) => x.name === 'Default');
            const flowsAdded = await addFlow(
              name,
              '',
              automations,
              this.workspaceName,
              defaultGroupId.length > 0 ? defaultGroupId[0].id : '1'
            );
            const flowA = flowsAdded[flowsAdded.length - 1];
            const sorted = pushFront(
              {
                ...flowA,
                type: 'automation',
              },
              this.state.sortedList ? this.state.sortedList : []
            );
            const flowsResponse = {
              automations: flowsAdded,
              groups: groups,
              sortedList: sorted,
              automationListWithoutConnection:
                this.state.automationListWithoutConnection,
              automationListWithApproval: this.state.automationListWithApproval,
            };
            this.setState(
              responseToViewMode(
                flowsResponse,
                this.state.sortBy,
                this.props['match'].params.view,
                this.state.homeFilter
              ),
              () => {
                if (journey && journey.type === 'success') {
                  journey.value.send({
                    type: 'EVENT::WORKFLOW::CREATED',
                    tag: 'GENERIC',
                    journeyType: 'Workflow',
                    refId: flowA.id,
                  });
                }
                removeURLParams('create-workflow');
                this.redirectToFlow(flowA.id);
              }
            );

            this.props['invalidate']();
          } catch (err) {
            // this.props.invalidate()
            removeURLParams('create-workflow');
            this.setState({
              mode: {
                ...modes,
                error: {
                  error: true,
                  info:
                    err.data || err.response.data
                      ? err.data
                        ? err.data.error
                        : err.response.data.error
                      : 'Something went wrong!',
                },
              },
            });
          }
        }
        break;
      case Mode.NoDataFound:
      case Mode.Add:
        {
          try {
            const { name, description } = modes;
            const defaultGroupId = groups.filter((x) => x.name === 'Default');

            const flowsAdded = await addFlow(
              name,
              description,
              automations,
              this.workspaceName,
              defaultGroupId.length > 0 ? defaultGroupId[0].id : '1'
            );
            const flowA = flowsAdded[flowsAdded.length - 1];
            const sorted = pushFront(
              {
                ...flowA,
                type: 'automation',
              },
              this.state.sortedList ? this.state.sortedList : []
            );
            const flowsResponse = {
              automations: flowsAdded,
              groups: groups,
              sortedList: sorted,
              automationListWithoutConnection:
                this.state.automationListWithoutConnection,
              automationListWithApproval: this.state.automationListWithApproval,
            };
            this.setState(
              responseToViewMode(
                flowsResponse,
                this.state.sortBy,
                this.props['match'].params.view,
                this.state.homeFilter
              ),
              () => {
                // console.log('workflow created', journey)
                if (journey && journey.type === 'success') {
                  console.log('workflow created', journey.value);
                  journey.value.send({
                    type: 'EVENT::WORKFLOW::CREATED',
                    tag: 'GENERIC',
                    journeyType: 'Workflow',
                    refId: flowA.id,
                  });
                }
                removeURLParams('create-workflow');
                this.redirectToFlow(flowA.id);
              }
            );
            this.props['invalidate']();
          } catch (err) {
            // this.props.invalidate()
            this.setState({
              mode: {
                ...modes,
                error: {
                  error: true,
                  info:
                    err.data || err.response.data
                      ? err.data
                        ? err.data.error
                        : err.response.data.error
                      : 'Something went wrong!',
                },
              },
            });
          }
        }
        break;
      case Mode.SaveAs:
        {
          try {
            const { id, name, description } = modes;
            const defaultGroupId = groups.filter((x) => x.name === 'Default');
            const flowsAdded = await saveFlowAs(
              id,
              name,
              description,
              automations,
              defaultGroupId.length > 0 ? defaultGroupId[0].id : '1',
              this.workspaceName
            );
            const flowA = flowsAdded[flowsAdded.length - 1];
            const sorted = pushFront(
              {
                ...flowA,
                type: 'automation',
              },
              this.state.sortedList ? this.state.sortedList : []
            );
            const automationListWithoutConnection = this.state.automationListWithoutConnection
            const flowsResponse = {
              automations: flowsAdded,
              groups: groups,
              sortedList: sorted,
              automationListWithoutConnection: automationListWithoutConnection.includes(id) ? [...automationListWithoutConnection,flowA.id] : automationListWithoutConnection,
              automationListWithApproval: this.state.automationListWithApproval,
            };
            this.props['invalidate'](id);
            this.setState(
              responseToViewMode(
                flowsResponse,
                this.state.sortBy,
                this.props['match'].params.view,
                this.state.homeFilter
              ),
              () => {
                removeURLParams('create-workflow');
                const location = {
                  pathname: `/workspace/${this.props['match'].params.workspacename}/automation/view/draft`,
                  state: { group: false, flow: true },
                };
                this.props['history'].push(location);
              }
            );
          } catch (err) {
            // this.props.invalidate()
            this.setState({
              mode: {
                ...modes,
                error: {
                  error: true,
                  info:
                    err.data || err.response.data
                      ? err.data
                        ? err.data.error
                        : err.response.data.error
                      : 'Something went wrong!',
                },
              },
            });
          }
        }
        break;
      case Mode.Edit: {
        const { id, name, description } = modes;

        try {
          if (modes.cardType == CardTypes.Flow) {
            const flowsEdited = await editFlow(
              id,
              name,
              description,
              automations,
              this.workspaceName
            );

            const sList = this.state.sortedList ? this.state.sortedList : [];
            const filteredSortFlow = sList.filter(
              (list) => !(list.id == id && list.type == 'automation')
            );
            const toPush = sList.find(
              (list) => list.id == id && list.type == 'automation'
            );

            const sortedFlowList = pushFront(toPush, filteredSortFlow);
            const editResponse = {
              automations: flowsEdited,
              groups: groups,
              sortedList: sortedFlowList,
              automationListWithoutConnection:
                this.state.automationListWithoutConnection,
              automationListWithApproval: this.state.automationListWithApproval,
            };
            this.setState(
              responseToViewMode(
                editResponse,
                this.state.sortBy,
                this.props['match'].params.view,
                this.state.homeFilter
              )
            );
            this.props['invalidate'](id);
          } else {
            const groupsEdited = await editGroup(
              id,
              name,
              description,
              groups,
              this.workspaceName
            );
            const sList = this.state.sortedList ? this.state.sortedList : [];

            const filteredSortGroup = sList.filter(
              (list) => !(list.id == id && list.type == 'group')
            );
            const toPush = sList.find(
              (list) => list.id == id && list.type == 'group'
            );

            const sortedGroupList = pushFront(toPush, filteredSortGroup);
            const editResponse: FlowsResponseT = {
              automations: automations,
              groups: groupsEdited,
              sortedList: sortedGroupList,
              automationListWithoutConnection:
                this.state.automationListWithoutConnection,
              automationListWithApproval: this.state.automationListWithApproval,
            };
            this.setState(
              responseToViewMode(
                editResponse,
                this.state.sortBy,
                this.props['match'].params.view,
                this.state.homeFilter
              )
            );
            this.props['invalidate']();
          }
        } catch (err) {
          this.setState({
            mode: {
              ...modes,
              error: {
                error: true,
                info:
                  err.data || err.response.data
                    ? err.data
                      ? err.data.error
                      : err.response.data.error
                    : 'Something went wrong!',
              },
            },
          });
        }
      }
    }
  };

  openGroupModel = () => {
    const mode = this.state.mode as SelectT;
    this.setState({ mode: { ...mode, showAddGroupModel: true } });
  };

  openEditGroupModel = () => {
    const mode = this.state.mode as SelectT;
    this.setState({ mode: { ...mode, showAddGroupModel: true } });
  };
  onGroupName = (name: string) => {
    const mode = this.state.mode as SelectT;
    this.setState({ mode: { ...mode, groupName: name } });
  };

  onGroupDescription = (description: string) => {
    const mode = this.state.mode as SelectT;
    this.setState({ mode: { ...mode, groupInfo: description } });
  };

  saveGroup = async () => {
    const mode = this.state.mode as SelectT;
    const data = {
      label: mode.groupName,
      info: mode.groupInfo,
      flowids: selectedFlowList(mode.cards),
    };
    let resp: any = await postJson(
      flowApiURL(this.workspaceName, FlowApi.AddGroup)
    )(data);

    const { id } = resp.data;

    const flowids = selectedFlowList(mode.cards);
    const sorted = pushFront(
      { ...data, type: 'group', id },
      this.state.sortedList ? this.state.sortedList : []
    );

    const flowsResponse: FlowsResponseT = {
      automations: this.state.automations.map((e: FlowResponseT) => {
        if (flowids.includes(e.id)) {
          e.groupId = id;
        }
        return e;
      }),
      groups: this.state.groups.concat({
        name: mode.groupName,
        description: mode.groupInfo,
        modified_date: new Date().toString(),
        id: id,
      }),
      sortedList: sorted,
      automationListWithoutConnection:
        this.state.automationListWithoutConnection,
      automationListWithApproval: this.state.automationListWithApproval,
    };

    this.setState({
      automations: flowsResponse.automations,
      groups: flowsResponse.groups,
      sortedList: flowsResponse.sortedList ? flowsResponse.sortedList : [],
      cardsToRender: flowsResponseToCards(flowsResponse, this.state.sortBy),
      mode: View,
    });
  };

  onNameChange = (name: string) => {
    const mode = this.state.mode as AddT;
    mode.error && mode.error.error
      ? this.setState({
          mode: { ...mode, name: name, error: { error: false, info: '' } },
        })
      : this.setState({ mode: { ...mode, name: name } });
  };

  onDescriptionChange = (description: string) => {
    const mode = this.state.mode as AddT;
    this.setState({ mode: { ...mode, description: description } });
  };

  handleSearch = (key: string) => {
    this.setState({ searchItem: key });
  };

  closeSearch = () => {
    this.setState({ searchItem: SEARCH_KEY });
  };

  selectFlows = (card: CardDisplayT) => {
    const modes = this.state.mode;
    const { id } = card;
    switch (modes.mode) {
      case Mode.Select:
        const flowCards = selectedFlowCards(modes.cards);
        const groupCards = selectedGroupCards(modes.cards);
        const flowsS = selectedFlowList(modes.cards);
        const selectedFlows = flowsS.includes(id)
          ? modes.cards.filter(
              (card) => card.id !== id && card.cardType == CardTypes.Flow
            )
          : flowCards.concat(card);
        const cardsSelected = [...groupCards, ...selectedFlows];

        if (cardsSelected.length) {
          this.setState({
            mode: {
              ...modes,
              mode: Mode.Select,

              cards: cardsSelected,
            },
          });
        } else {
          this.setState({ mode: View });
        }
        break;
      default:
        this.setState({
          mode: {
            mode: Mode.Select,
            groupName: '',
            groupInfo: '',
            cards: [card],
            showAddGroupModel: false,
          },
        });
        break;
    }
  };

  selectGroups = (card: CardDisplayT) => {
    const modes = this.state.mode;
    const { id } = card;
    switch (modes.mode) {
      case Mode.Select:
        const flowCards = selectedFlowCards(modes.cards);
        const groupCards = selectedGroupCards(modes.cards);
        const groupS = groupCards.map((card) => card.id);
        const selectedGroups = groupS.includes(id)
          ? groupCards.filter(
              (group) => group.id !== id && group.cardType == CardTypes.Group
            )
          : groupCards.concat(card);

        const cardsSelected = [...flowCards, ...selectedGroups];
        if (cardsSelected.length) {
          this.setState({
            mode: {
              ...modes,
              mode: Mode.Select,
              cards: cardsSelected,
            },
          });
        } else {
          this.setState({ mode: View });
        }
        break;
      default:
        const cards = [card];
        this.setState({
          mode: {
            mode: Mode.Select,
            cards,
            groupName: '',
            groupInfo: '',
            showAddGroupModel: false,
          },
        });
        break;
    }
  };

  changeView = (view: Views) => {
    this.setState({ viewMode: view });
  };

  showSelection = () =>
    this.state.mode.mode == Mode.Select ||
    this.state.mode.mode == Mode.MoveToGroup;

  isSelected = (card: any) => {
    const { mode } = this.state;
    return (
      (mode.mode == Mode.Select &&
        mode.cards.filter(
          (e: any) => e.id == card.id && e.cardType == CardTypes.Flow
        ).length != 0) ||
      (mode.mode == Mode.MoveToGroup &&
        mode.selectedFlows.filter(
          (e: any) => e.id == card.id && e.cardType == CardTypes.Flow
        ).length != 0)
    );
  };

  isGroupSelected = (card: any) => {
    const { mode } = this.state;
    return (
      mode.mode == Mode.Select &&
      mode.cards.filter(
        (e: any) => e.id == card.id && e.cardType == CardTypes.Group
      ).length != 0
    );
  };

  openFlowMoveModal = () => {
    if (this.state.mode.mode === Mode.Select) {
      this.setState({
        mode: {
          ...MoveToGroup,
          groupsList: this.state.groups,
          selectedFlows: this.state.mode.cards,
        },
      });
    }
  };

  selectGroupToMove = (group: GroupResponseT) => {
    const { mode } = this.state;
    if (mode.mode === Mode.MoveToGroup) {
      this.setState({
        mode: {
          ...mode,
          selectedGroupToMove: [group],
        },
      });
    }
  };

  moveToDifferentGroup = async (selectedGroup: string) => {
    const { mode, automations, groups, sortedList } = this.state;
    try {
      if (mode.mode === Mode.MoveToGroup) {
        this.setState({ showPopupLoader: true });
        const mode = this.state.mode as MoveT;
        const flowsS = selectedFlowList(mode.selectedFlows);
        const updatedFlows = automations.filter(
          (flow) => flowsS.indexOf(flow.id) === -1
        );
        const data = {
          id: selectedGroup,
          flows: flowsS,
        };
        await putJson(flowApiURL(this.workspaceName, FlowApi.MoveToGroup))()(
          data
        );
        const flowList = {
          automations: updatedFlows,
          groups,
          sortedList,
          automationListWithoutConnection:
            this.state.automationListWithoutConnection,
          automationListWithApproval: this.state.automationListWithApproval,
        };
        const cardsToRender = flowsResponseToCards(flowList, this.state.sortBy);
        this.setState({
          mode: View,
          automations: updatedFlows,
          groups: groups,
          cardsToRender: cardsToRender,
          showPopupLoader: false,
        });
      }
    } catch (err) {
      if (mode.mode === Mode.MoveToGroup) {
        this.setState({
          mode: {
            ...mode,
            error: true,
            info: 'Server error!<br />Please try again after sometime.',
          },
          showPopupLoader: false,
        });
      }
    }
  };

  moveGroupList = (search: string) => {
    const { mode } = this.state;

    let renderItems: GroupResponseT[] = [];
    if (mode.mode === Mode.MoveToGroup) {
      renderItems = mode.groupsList.filter((x: GroupResponseT) =>
        search.length > 0
          ? x.name.toLowerCase().indexOf(search.toLowerCase()) > -1 &&
            x.name !== 'Default'
          : x.name !== 'Default'
      );
    }

    return renderItems;
  };

  closeError = () => {
    this.setState({ mode: View });
  };

  setActivateOrDeactivateMode = (card: any) => {
    if (card.linkedDialogs && card.linkedDialogs.length > 0) {
      const toDeActivate: DeactivateLinkedDialogT = {
        mode: Mode.DeactivateLinkedDialog,
        card: card,
        dialog: card.linkedDialogs,
        flowId: card.id,
      };
      this.setState({ mode: toDeActivate });
    } else {
      this.setState({ mode: { ...AciveOrDeactive, selectedCard: card } });
    }
  };

  activateOrDeactivate = async () => {
    this.setState({ showPopupLoader: true });
    const view = this.props['match'].params.view as FlowFilterT;
    const { mode } = this.state;
    if (mode.mode === Mode.Activate) {
      const statusToSend =
        mode.selectedCard.active_status === false ? true : false;

      await putJson(
        automationAPIURLWithWorkspace(this.workspaceName, AutomationApi.Deploy)
      )()({
        id: mode.selectedCard.id,
        active_status: statusToSend,
        type: mode.selectedCard.type,
      })
        .then((success) => {
          if (success.data.type == 'success') {
            const flows = this.state.automations;
            const newAutomations = flows.map((x) => {
              if (x.id !== mode.selectedCard.id) {
                return x;
              } else {
                return {
                  ...x,
                  active_status: statusToSend,
                };
              }
            });
            const cards = flowsResponseToCards(
              {
                automations: newAutomations,
                groups: this.state.groups,
                sortedList: this.state.sortedList,
                automationListWithoutConnection:
                  this.state.automationListWithoutConnection,
                automationListWithApproval:
                  this.state.automationListWithApproval,
              },
              this.state.sortBy,
              view,
              this.state.homeFilter
            );

            this.setState({
              showPopupLoader: false,
              cardsToRender: cards,
              automations: newAutomations,
              mode: View,
            });
          }
        })
        .catch((err) => {
          const errorResponse = err.response;
          if (
            errorResponse.data &&
            errorResponse.data.type == 'error' &&
            errorResponse.data.tag &&
            errorResponse.data.tag == 'RESOURCE_CONSUMPTION_LIMIT_EXCEEDED'
          ) {
            this.setState({
              mode: {
                ...mode,
                error: true,
                flowCountError: true,
              },
              showPopupLoader: false,
            });
          } else {
            this.setState({
              mode: {
                ...mode,
                error: true,
                info: errorResponse.data.error, //'Server error!<br />Please try again after sometime.'
              },
              showPopupLoader: false,
            });
          }
        });
    }
  };

  renderModal = () => {
    const { mode, showPopupLoader } = this.state;
    const head = popupText(mode);

    if (
      mode.mode == Mode.Add ||
      mode.mode == Mode.SaveAs ||
      (mode.mode === Mode.NoDataFound && mode.showAddPopup) ||
      (mode.mode == Mode.Edit && mode.cardType == CardTypes.Flow) ||
      (mode.mode == Mode.Edit && mode.cardType == CardTypes.Group)
    ) {
      return (
        <RootJourneyContextConsumer>
          {(journey) => (
            <Modal>
              <form>
                {/* <AddExamplesWrapper> */}
                <Validate
                  defaultValue={
                    mode.mode == Mode.Add || mode.mode === Mode.NoDataFound
                      ? { flowName: false }
                      : { flowName: true }
                  }
                >
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: head.header,
                    }}
                  />
                  <Paragraph margin>{head.subHeader}</Paragraph>
                  <InputBox
                    validationList={
                      mode.mode == Mode.Edit && mode.cardType == CardTypes.Group
                        ? EmptyValidation
                        : flowValidations
                    }
                    value={mode.name}
                    name="flowName"
                    render={(
                      value: string,
                      valid: boolean,
                      errorMsg: string,
                      startValidation: () => void,
                      handleInput: Function
                    ) => {
                      return (
                        <React.Fragment>
                          {!valid && (
                            <ErrorMessage>
                              {!valid ? errorMsg : newLine}
                            </ErrorMessage>
                          )}
                          <InputWrapper>
                            <InputContainer>
                              <Input
                                primary={!valid ? `primary` : ''}
                                type="text"
                                onChange={(event: any) => {
                                  startValidation(),
                                    handleInput('flowName', event.target.value);
                                  this.onNameChange(event.target.value);
                                }}
                                value={value}
                              />
                              <InputContent>Workflow name</InputContent>
                              {!valid && (
                                <InputErrorIcon>
                                  <ErrorIcon />
                                </InputErrorIcon>
                              )}
                            </InputContainer>
                          </InputWrapper>
                        </React.Fragment>
                      );
                    }}
                  />
                  <InputBox
                    validationList={inputTextAreaValidationsForWorkflow}
                    value={mode.description}
                    name={'description'}
                    render={(
                      value: string,
                      valid: boolean,
                      errorMsg: string,
                      startValidation: () => void,
                      handleInput: Function
                    ) => {
                      return (
                        <React.Fragment>
                          {!valid && (
                            <ErrorMessage>
                              {!valid ? errorMsg : newLine}
                            </ErrorMessage>
                          )}
                          <InputWrapper style={{ marginTop: 10 }}>
                            <InputContainer>
                              <Textarea
                                onChange={(event: any) => {
                                  startValidation();
                                  handleInput(
                                    'description',
                                    event.target.value
                                  );
                                  this.onDescriptionChange(event.target.value);
                                }}
                                value={value}
                              />
                              <InputContent>Workflow description</InputContent>
                              {!valid && (
                                <InputErrorIcon>
                                  <ErrorIcon />
                                </InputErrorIcon>
                              )}
                            </InputContainer>
                          </InputWrapper>
                        </React.Fragment>
                      );
                    }}
                  />
                  {(mode.mode == Mode.Add ||
                    mode.mode == Mode.SaveAs ||
                    mode.mode == Mode.Edit ||
                    mode.mode == Mode.NoDataFound) &&
                  mode.error ? (
                    <p className="error_msg">{mode.error.info}</p>
                  ) : null}
                  <ButtonWrapper>
                    <Button
                      primary
                      type="button"
                      onClick={
                        mode.mode !== Mode.NoDataFound
                          ? () => {
                              this.resetToViewMode();
                              removeURLParams('create-workflow');
                            }
                          : () => {
                              this.setState({
                                mode: {
                                  ...mode,
                                  showAddPopup: false,
                                },
                              });
                              removeURLParams('create-workflow');
                            }
                      }
                    >
                      <label>Cancel</label>
                    </Button>
                    <ErrorContext.Consumer>
                      {(valid) => {
                        return (
                          <Button
                            type="button"
                            onClick={() => this.saveCard(journey)}
                            disabled={
                              !valid.formValid.formValid ||
                              ((mode.mode == Mode.Add ||
                                mode.mode == Mode.SaveAs ||
                                mode.mode == Mode.Edit ||
                                mode.mode == Mode.NoDataFound) &&
                              mode.error &&
                              mode.error.error
                                ? true
                                : false)
                            }
                            className={
                              !valid.formValid.formValid ||
                              ((mode.mode == Mode.Add ||
                                mode.mode == Mode.SaveAs ||
                                mode.mode == Mode.Edit ||
                                mode.mode == Mode.NoDataFound) &&
                                mode.error &&
                                mode.error.error)
                                ? 'editor_btn_disabled'
                                : ''
                            }
                          >
                            <label>{head.buttonText}</label>
                          </Button>
                        );
                      }}
                    </ErrorContext.Consumer>
                  </ButtonWrapper>
                </Validate>
                {/* </AddExamplesWrapper> */}
              </form>
            </Modal>
          )}
        </RootJourneyContextConsumer>
      );
    } else if (mode.mode == Mode.Select && mode.showAddGroupModel) {
      return (
        <Modal>
          <Validate defaultValue={{ groupName: false }}>
            <h2>New Group</h2>
            <p>Enter the Name and Description</p>
            <form>
              <InputBox
                validationList={EmptyValidation}
                value={mode.groupName}
                name="groupName"
                render={(
                  value: string,
                  valid: boolean,
                  errorMsg: string,
                  startValidation: () => void,
                  handleInput: Function
                ) => {
                  return (
                    <React.Fragment>
                      <InputWrapper>
                        {!valid && (
                          <ErrorMessage>
                            {!valid ? errorMsg : newLine}
                          </ErrorMessage>
                        )}
                        <InputContainer>
                          <Input
                            primary={!valid ? `primary` : ''}
                            type="text"
                            onChange={(event: any) => {
                              startValidation(),
                                handleInput('groupName', event.target.value);
                              this.onGroupName(event.target.value);
                            }}
                            value={value}
                          />
                          <InputContent>Group Title</InputContent>
                          {!valid && (
                            <InputErrorIcon>
                              <ErrorIcon />
                            </InputErrorIcon>
                          )}
                        </InputContainer>
                      </InputWrapper>
                    </React.Fragment>
                  );
                }}
              />
              <InputBox
                validationList={inputTextAreaValidationsForWorkflow}
                value={mode.groupInfo}
                render={(
                  value: string,
                  valid: boolean,
                  errorMsg: string,
                  startValidation: () => void
                ) => {
                  return (
                    <React.Fragment>
                      <InputWrapper>
                        {!valid && (
                          <ErrorMessage>
                            {!valid ? errorMsg : newLine}
                          </ErrorMessage>
                        )}
                        <InputContainer>
                          <Textarea
                            primary={!valid ? `primary` : ''}
                            className="form-control"
                            placeholder="Group description"
                            onChange={(event: any) => {
                              startValidation();
                              this.onGroupDescription(event.target.value);
                            }}
                            value={value}
                          />
                          <InputContent>Group description</InputContent>
                          {!valid && (
                            <InputErrorIcon>
                              <ErrorIcon />
                            </InputErrorIcon>
                          )}
                        </InputContainer>
                      </InputWrapper>
                    </React.Fragment>
                  );
                }}
              />
              <ButtonWrapper>
                <Button primary type="button" onClick={this.resetToViewMode}>
                  <label>Cancel</label>
                </Button>

                <ErrorContext.Consumer>
                  {(valid) => {
                    return (
                      <Button
                        type="button"
                        onClick={this.saveGroup}
                        disabled={!valid.formValid.formValid}
                      >
                        <label>Add</label>
                      </Button>
                    );
                  }}
                </ErrorContext.Consumer>
              </ButtonWrapper>
            </form>
          </Validate>
        </Modal>
      );
    } else if (mode.mode == Mode.MoveToGroup) {
      return (
        <Move.Popup
          render={(
            searchText,
            onSearchChanged,
            selectedGroup,
            selectGroups
          ) => (
            <Move.Wrapper>
              <Move.Header item="flow" moveTo="group" />
              <Move.Search text={searchText} onChange={onSearchChanged} />
              <ErrorBoundary
                error={
                  mode.error
                    ? { error: true, info: mode.info }
                    : { error: false, info: null }
                }
                render={(err: any, info: any) => {
                  return (
                    err && <Error.Move onClick={this.closeError} info={info} />
                  );
                }}
              >
                {!showPopupLoader ? (
                  <React.Fragment>
                    <Move.CardWrapper>
                      {this.moveGroupList(searchText).length > 0 ? (
                        this.moveGroupList(searchText).map((group, index) => (
                          <Move.Card
                            id={group.id}
                            index={index}
                            label={group.name}
                            info={group.description}
                            isSelected={selectedGroup == group.id}
                            selectMode={selectedGroup !== ''}
                            select={selectGroups}
                          />
                        ))
                      ) : (
                        <Error.NoDataMsg info="No groups to show! :)" />
                      )}

                      <div className="margin-bottom-75" />
                    </Move.CardWrapper>
                    <Move.ButtonWrapper>
                      <Move.CancelB onClick={this.resetToViewMode} />
                      <Move.MoveB
                        onClick={() => this.moveToDifferentGroup(selectedGroup)}
                        disabled={selectedGroup.length === 0 && true}
                      />
                    </Move.ButtonWrapper>
                  </React.Fragment>
                ) : (
                  <Loader.PopupLoader show={showPopupLoader} />
                )}
              </ErrorBoundary>
            </Move.Wrapper>
          )}
        />
      );
    }
    return null;
  };

  renderWorkflowNotFound = () => {
    const { showWorkflowNotFound } = this.state;
    if (showWorkflowNotFound) {
      return (
        <Modal>
          <Paragraph>The requested workflow was not found</Paragraph>
          <ButtonWrapper>
            <Button
              primary
              type="button"
              onClick={() => {
                this.setState({ showWorkflowNotFound: false });
                removeURLParams('redirect_from_not_found');
              }}
            >
              <label>Ok</label>
            </Button>
          </ButtonWrapper>
        </Modal>
      );
    }

    return null;
  };

  renderConfirmModal = () => {
    const { mode, showPopupLoader } = this.state;

    if (mode.mode == Mode.Delete) {
      if (mode.deleteAction == 'Delete' || mode.deleteAction == 'Ungroup') {
        const { card, deleteAction } = mode;
        return (
          <Modal>
            <h2>
              {deleteAction}{' '}
              <span
                style={{
                  color: '#ff7900',
                  paddingLeft: '3px',
                  fontFamily: 'Gordita-Medium',
                }}
              >
                {' '}
                "{card.name}"{' '}
              </span>
            </h2>
            <Paragraph>
              Are you sure to {deleteAction.toLowerCase()} selected{' '}
              {card.cardType.toLowerCase()}?
            </Paragraph>
            <ButtonWrapper>
              <Button primary type="button" onClick={this.resetToViewMode}>
                <label>No</label>
              </Button>
              <Button type="button" onClick={this.deleteCard}>
                <label>Yes</label>
              </Button>
            </ButtonWrapper>
          </Modal>
        );
      } else if (mode.deleteAction == 'Error') {
        const { error } = mode;
        return (
          <Modal>
            <h2>Error in deletion</h2> <p>{error} ?</p>
            <form>
              <Button type="button" onClick={this.resetToViewMode}>
                <label>Okay</label>
              </Button>
            </form>
          </Modal>
        );
      }
    } else if (
      mode.mode == Mode.DeleteLinkedAutomation ||
      mode.mode == Mode.DeactivateLinkedDialog
    ) {
      const { dialog, card } = mode;
      const action =
        mode.mode === Mode.DeleteLinkedAutomation ? 'delete' : 'deactivate';
      return (
        <Modal>
          <div className="dialog-link-modal">
            <div className="center_automation_popup">
              <h4 className="center_popup_automation_h4">
                Are you sure to {action} {card.name}?
              </h4>
              <Paragraph>
                This automation is linked with these dialogs, You must delete{' '}
                {card.name} in Dialog Designer first.
              </Paragraph>
              {dialog.map((e: any, i: number) => (
                <Button
                  className="w-100"
                  primary
                  key={i}
                  onClick={() => {
                    // console.log('e', e)
                    //workspace/nirup/dialog/Test/detail/bb7dcc84-60a6-475b-b48d-6998a790ee9f
                    // console.log('e', e)
                    //workspace/nirup/dialog/Test/detail/bb7dcc84-60a6-475b-b48d-6998a790ee9f
                    this.props['history'].push(
                      `/workspace/${this.workspaceName}/dialog/${e.botName}/detail/${e.id}`
                    );
                  }}
                >
                  <label>{e.title}</label>
                  {/* <span>
                                            {' '}
                                            <ArrowIcon />{' '}
                                        </span> */}
                </Button>
              ))}
              <ButtonWrapper>
                <Button
                  primary
                  type="button"
                  data-dismiss="modal"
                  onClick={this.resetToViewMode}
                >
                  <label>Cancel</label>
                </Button>
              </ButtonWrapper>
            </div>
          </div>
        </Modal>
      );
    } else if (mode.mode == Mode.Select) {
      if (mode.error && mode.error.error === true) {
        return (
          <Modal>
            <h2>Error in selection</h2> <p>{mode.error.info} ?</p>
            <form>
              <Button type="button" onClick={this.resetToViewMode}>
                <label>Okay</label>
              </Button>
            </form>
          </Modal>
        );
      }

      if (mode.action == DeleteCards) {
        return (
          <Modal>
            <h2>Delete</h2>
            <Paragraph>Are you sure to delete selected items?</Paragraph>
            <ButtonWrapper>
              <Button primary type="button" onClick={this.resetToViewMode}>
                <label>No</label>
              </Button>
              <Button type="button" onClick={this.deleteCards}>
                <label>Yes</label>
              </Button>
            </ButtonWrapper>
          </Modal>
        );
      }
    } else if (mode.mode == Mode.Activate && !mode.flowCountError) {
      const headers = {
        no_trigger: {
          active: 'Activate',
          inactive: 'Deactivate',
        },
        trigger: {
          active: 'Start',
          inactive: 'Stop',
        },
      };
      const messages = {
        no_trigger: {
          active: 'Are you sure to activate',
          inactive: 'Are you sure to deactivate',
        },
        trigger: {
          active: 'Are you sure to start',
          inactive: 'Are you sure to stop',
        },
      };

      const status =
        mode.selectedCard.active_status === false ? 'active' : 'inactive';

      const ifTrigger =
        mode.selectedCard.type == 'trigger' ||
        mode.selectedCard.type == 'event_trigger';
      let headerText = ifTrigger
        ? headers['trigger'][status]
        : headers['no_trigger'][status];
      let messageText = ifTrigger
        ? messages['trigger'][status]
        : messages['no_trigger'][status];

      return (
        <Modal
          error={
            mode.error
              ? { error: true, info: mode.info }
              : { error: false, info: null }
          }
          showPopupLoader={showPopupLoader}
        >
          <ErrorBoundary
            error={
              mode.error
                ? { error: true, info: mode.info }
                : { error: false, info: null }
            }
            render={(err: any, info: any) => {
              return (
                err && <Error.AddorEdit onClick={this.closeError} info={info} />
              );
            }}
          >
            {!showPopupLoader ? (
              <React.Fragment>
                <h2>{headerText}</h2>
                <Paragraph>
                  {messageText}{' '}
                  <b style={{ color: '#ff7900' }}>{mode.selectedCard.name}</b>?
                </Paragraph>
                <ButtonWrapper>
                  <Button primary type="button" onClick={this.resetToViewMode}>
                    <label>No</label>
                  </Button>
                  <Button type="button" onClick={this.activateOrDeactivate}>
                    <label>Yes</label>
                  </Button>
                </ButtonWrapper>
              </React.Fragment>
            ) : (
              <Loader.PopupLoader show={true} />
            )}
          </ErrorBoundary>
        </Modal>
      );
    } else if (mode.mode == Mode.Activate && mode.flowCountError) {
      return (
        <section className="center_autoamtion_list">
          <div id="modal-root">
            <div>
              <div className="flow_popup1 alert_popup_message">
                <div data-ml-modal="">
                  <a
                    className="modal-overlay"
                    style={{
                      backgroundColor: 'transparent',
                    }}
                  />
                  <div className="modal-dialog">
                    <div className="modal-content center">
                      <span className="bell_svg">
                        <FlowBellIcon />
                      </span>
                      <Paragraph>
                        Maximum limit reached, deactivate an active automation
                        to make {mode.selectedCard.name} active.
                      </Paragraph>
                      <ButtonWrapper>
                        <Button
                          primary
                          type="button"
                          onClick={() =>
                            this.setState({
                              mode: View,
                            })
                          }
                        >
                          <label>Okay</label>
                        </Button>
                      </ButtonWrapper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
    return null;
  };
  renderAddToDialog = (journey: JourneyState) => {
    const { mode, showPopupLoader } = this.state;
    const head = popupText(mode);
    if (
      journey.type === 'success' &&
      journey.value.state().value.type === 'SUB' &&
      journey.value.state().value['WORKFLOW'] === 'WORKFLOW::ACTIVATED'
    ) {
      return (
        <Modal>
          <h2>Great</h2>
          <Paragraph>
            Now Lets Add this workflow to a new dialog conversation
          </Paragraph>
          <ButtonWrapper>
            <Button
              primary
              type="button"
              onClick={() => {
                journey.value.send({
                  type: 'EVENT::WORKFLOW::END_JOURNEY',
                  tag: 'WORKFLOW_GENERIC_EVENT',
                  journeyType: 'Workflow',
                });
              }}
            >
              <label>No</label>
            </Button>
            <Button
              type="button"
              onClick={(event: any) => {
                event.stopPropagation();
                journey.value.send({
                  type: 'EVENT::WORKFLOW::END_JOURNEY',
                  tag: 'WORKFLOW_GENERIC_EVENT',
                  journeyType: 'Workflow',
                });

                getAssistants(this.props['match'].params.workspacename).then(
                  (bots) => {
                    if (bots.length === 1) {
                      this.props['history'].push(
                        `/workspace/${this.props['match'].params.workspacename}/dialog/${bots[0].name}/dialogs`
                      );
                    } else {
                      this.props['history'].push(
                        `/workspace/${this.props['match'].params.workspacename}/dialog/homepage`
                      );
                    }
                  }
                );
              }}
            >
              <label>Create Dialog</label>
            </Button>
          </ButtonWrapper>
        </Modal>
      );
    }
    return null;
  };
  changeRouteView = (view: FlowFilterT) => {
    this.props['history'].push(
      `/workspace/${
        this.props['match'].params.workspcename
      }/automation/view/${view.toLowerCase()}`
    );
    //console.log('state', this.state.automations)

    const cardsToRender = flowsResponseToCards(
      {
        automations: this.state.automations,
        groups: this.state.groups,
        sortedList: this.state.sortedList ? this.state.sortedList : [],
        automationListWithoutConnection:
          this.state.automationListWithoutConnection,
        automationListWithApproval: this.state.automationListWithApproval,
      },
      this.state.sortBy,
      view
    );

    this.setState({ cardsToRender, mode: View });
  };

  updateFlowActive = (_flow: CardDisplayT, _active: string) => {};

  filterNoDataFound = (
    mode: Modes,
    view: FlowFilterT,
    cardsToRender: CardDisplayT[]
  ) => {
    if (mode.mode === Mode.Error) {
      return {
        show: false,
        noDatainfo: `<p>Zero, zip, zilch, nada. Create an Automation now!</p>`,
        onClick: () => {},
        btnName: 'Retry',
      };
    } else if (view === FlowFilterE.Draft) {
      return {
        show: false,
        noDatainfo: `<p>Zero, zip, zilch, nada. Create an Automation now!</p>`,
        onClick: () => {
          this.setState({
            mode: {
              ...NoDataFound,
              showAddPopup: true,
            },
          });
        },
        btnName: 'Create one',
      };
    } else if (view === FlowFilterE.Trigger) {
      return {
        show: cardsToRender.length == 0,
        noDatainfo: `<p>Zero, zip, zilch, nada. Deploy your first Trigger automation from drafts now!</p>`,
        onClick: () => {
          this.props['history'].push(
            `/workspace/${this.props['match'].params.workspacename}/automation/view/${FlowFilterE.Draft}`
          );
        },
        btnName: 'Deploy',
      };
    } else if (view === FlowFilterE.NoTrigger) {
      return {
        show: cardsToRender.length == 0,
        noDatainfo: `<p>Zero, zip, zilch, nada. Deploy your first No Trigger automation from Drafts now!</p>`,
        onClick: () => {
          this.props['history'].push(
            `/workspace/${this.props['match'].params.workspacename}/automation/view/${FlowFilterE.Draft}`
          );
        },
        btnName: 'Deploy',
      };
    } else if (view === FlowFilterE.Active) {
      return {
        show: cardsToRender.length == 0,
        noDatainfo: `<p>You dont have any active workflows.<br/>
You can deploy workflows from drafts.</p>`,
        onClick: () => {
          this.props['history'].push(
            `/workspace/${this.props['match'].params.workspacename}/automation/view/${FlowFilterE.Draft}`
          );
        },
        btnName: 'Activate',
      };
    } else if (view === FlowFilterE.Approval) {
      return {
        show: cardsToRender.length == 0,
        noDatainfo: `<p> No approval workflows.<br/>
    Create wokflows with approvals to view here.</p>`,
        onClick: () => {
          this.setState({
            mode: {
              ...NoDataFound,
              showAddPopup: true,
            },
          });
        },
        btnName: 'Create one',
      };
    } else if (
      view === FlowFilterE.EventTrigger ||
      view === FlowFilterE.AppTrigger
    ) {
      return {
        show: cardsToRender.length == 0,
        noDatainfo: `<p> No app trigger workflows.<br/>
    Create workflows with app triggers to view here.</p>`,
        onClick: () => {
          this.setState({
            mode: {
              ...NoDataFound,
              showAddPopup: true,
            },
          });
        },
        btnName: 'Create one',
      };
    } else if (view === FlowFilterE.WebhookTrigger) {
      return {
        show: cardsToRender.length == 0,
        noDatainfo: `<p> No webhook workflows.<br/>
    Create wokflows with webhooks to view here.</p>`,
        onClick: () => {
          this.setState({
            mode: {
              ...NoDataFound,
              showAddPopup: true,
            },
          });
        },
        btnName: 'Create one',
      };
    } else if (view === FlowFilterE.UserTrigger) {
      return {
        show: cardsToRender.length == 0,
        noDatainfo: `<p> No user trigger workflows.<br/>
    Create wokflows with user trigger to view here.</p>`,
        onClick: () => {
          this.setState({
            mode: {
              ...NoDataFound,
              showAddPopup: true,
            },
          });
        },
        btnName: 'Create one',
      };
    } else if (view === FlowFilterE.InActive) {
      return {
        show: cardsToRender.length == 0,
        noDatainfo: `<p>You dont have any inactive workflows.<br/>
You can deploy workflows from drafts.</p>`,
        onClick: () => {
          this.props['history'].push(
            `/workspace/${this.props['match'].params.workspacename}/automation/view/${FlowFilterE.Draft}`
          );
        },
        btnName: 'Deploy',
      };
    } else {
      return {
        show: false,
        noDatainfo: `<p>Zero, zip, zilch, nada. Create an Automation now!</p>`,
        onClick: () => {
          this.setState({
            mode: {
              ...NoDataFound,
              showAddPopup: true,
            },
          });
        },
        btnName: 'Create one',
      };
    }
  };

  noTriggerView = (flow: FlowFilterT) =>
    flow == FlowFilterE.Active ||
    flow == FlowFilterE.InActive ||
    flow == FlowFilterE.NoTrigger;

  listViewThirdColumn = (routeFilter: FlowFilterT) => {
    if (this.noTriggerView(routeFilter)) {
      return (
        <React.Fragment>
          <Th size={2}>
            {/* <span style={{paddingRight: 30}}>Linked with Dialog</span> */}
            {getProductId() === 'ASSISTANT' ? (
              <span>Linked with Dialog</span>
            ) : (
              <span>Triggered by</span>
            )}
          </Th>
          <Th size={2}>
            {getProductId() === 'ASSISTANT' ? (
              <span>Type</span>
            ) : (
              <span>With Approval</span>
            )}
          </Th>
          <Th size={2}>
            <span>Date</span>
          </Th>
          <Th size={1.5}><span>Options</span></Th>
        </React.Fragment>
      );
    } else if (routeFilter == FlowFilterE.Draft) {
      return (
        <React.Fragment>
          <Th size={2}>
            {/* <span style={{paddingRight: 30}}>Linked with Dialog</span> */}
            {getProductId() === 'ASSISTANT' ? (
              <span>Linked with Dialog</span>
            ) : (
              <span>Triggered by</span>
            )}
          </Th>
          <Th size={2}>
          {getProductId() === 'ASSISTANT' ? (
              <span>Type</span>
            ) : (
              <span>With Approval</span>
            )}
          </Th>
          <Th size={2} style={{ justifyContent: 'flex-start' }}>
            <span>Date</span>
          </Th>
          <Th size={1.5}>
            <span>Options</span>
          </Th>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Th size={2}>
            {getProductId() === 'ASSISTANT' ? (
              <span>Linked with Dialog</span>
            ) : (
              <span>Triggered by</span>
            )}
          </Th>
          <Th size={2}>
            {getProductId() === 'ASSISTANT' ? (
              <span>Type</span>
            ) : (
              <span>With Approval</span>
            )}
          </Th>
          <Th size={2}>
            <span>Date</span>
          </Th>
          <Th size={1.5}>
            <span>Options</span>
          </Th>
        </React.Fragment>
      );
    }
  };

  showCreateFlowUI = (
    mode: Modes,
    view: FlowFilterT,
    cardsToRender: CardDisplayT[]
  ) => {
    if (
      (view === FlowFilterE.Draft || view === FlowFilterE.Home) &&
      cardsToRender.length === 0
    ) {
      // console.log('return true')
      return true;
    }

    return false;
  };

  render() {
    let {
      mode,
      searchItem,
      cardsToRender,
      viewMode,
      PageError,
      automationListWithoutConnection,
      automationListWithApproval,
    } = this.state;
    // console.log('FLow render', this.state)
    let renderItems: CardDisplayT[] = cardsToRender;
    const cardPadding = mode.mode == Mode.Select ? '18px' : '0px';

    const routeFilter = this.props['match'].params.view;

    if (searchItem != SEARCH_KEY) {
      renderItems = renderItems
        .filter(
          (e) =>
            e.name && e.name.toLowerCase().includes(searchItem.toLowerCase())
        )
        .map(formatTitle(searchItem, cardPadding));
    } else {
      renderItems = renderItems.map(formatTitle(searchItem, cardPadding));
    }
    const Wrapper = PageWrapper as any;
    return (
      <React.Fragment>
        <RootJourneyContextConsumer>
          {(journey) => {
            return this.renderAddToDialog(journey);
          }}
        </RootJourneyContextConsumer>
        {this.renderModal()}
        {this.renderWorkflowNotFound()}
        {this.renderConfirmModal()}
        {mode.mode == Mode.Loading ? (
          <Loader.PageLoader show={mode.mode == Mode.Loading} />
        ) : (
          <React.Fragment>
            <FlowEditorHeader
              sortBy={this.sortByApply}
              homeFilterApply={this.homeFilterApply}
              appliedHomeFilter={this.state.homeFilter}
              sortOption={this.state.sortBy}
              count={cardsToRender.length}
              mode={this.state.mode}
              flowView={this.setToFlowView}
              addGroup={this.openGroupModel}
              deselect={this.resetToViewMode}
              setToAddMode={this.setToAddMode}
              delete={this.setToDeleteMultipleFlows}
              search={this.handleSearch}
              searchKey={this.state.searchItem}
              closeSearch={this.closeSearch}
              viewType={this.state.viewMode}
              changeView={this.changeView}
              openMoveModal={this.openFlowMoveModal}
              changeRouteView={this.changeRouteView}
              routeFilter={this.flowView}
              openMarketPlace={() => this.setState({ showMarketPlace: true })}
              isMarketPlaceOpen={this.state.showMarketPlace}
            />

            <ErrorBoundary
              noDataFound={this.filterNoDataFound(
                mode,
                routeFilter,
                cardsToRender
              )}
              error={
                mode.mode == Mode.Error
                  ? PageError
                  : { error: false, info: null }
              }
              render={(err: any, info: any) => {
                return (
                  err && (
                    <Error.PageLoadingError
                      onClick={() => this.props['retry']()}
                      info={info}
                      btnName="Retry"
                    />
                  )
                );
              }}
            >
              <React.Fragment>
                {this.showCreateFlowUI(mode, routeFilter, cardsToRender) ? (
                  <React.Fragment>
                    <RootJourneyContextConsumer>
                      {(journey) => {
                        if (
                          mode.mode ===
                            Mode.NoWorkflowsCreatedInitalRediraction ||
                          mode.mode === Mode.NoDataFound
                        ) {
                          return (
                            <>
                              <WorkflowBuilder
                                mode={mode}
                                Mode={Mode}
                                flowValidations={flowValidations}
                                newLine={newLine}
                                onNameChange={this.onNameChange}
                                saveCard={this.saveCard}
                                journey={journey}
                                onDownloadWorkflow={() => {
                                  this.setState({
                                    showMarketPlace: true,
                                  });
                                }}
                              />
                              {/* <BotStartedWrapper>
                                                                <BotWrapper>
                                                                    <BotGridsContainer>
                                                                        <Validate
                                                                            defaultValue={
                                                                                mode.mode ==
                                                                                    Mode.NoWorkflowsCreatedInitalRediraction ||
                                                                                Mode.NoDataFound
                                                                                    ? { flowName: false }
                                                                                    : { flowName: true }
                                                                            }
                                                                        >
                                                                            <div className="flow_card_wrapper">
                                                                                <div className="flow_card_wrapper_left">
                                                                                    <img
                                                                                        src={createImg}
                                                                                        alt="workflow"
                                                                                    />

                                                                                    <DropdownContainer>
                                                                                        <DropdownWrapper>
                                                                                            <InputBox
                                                                                                validationList={
                                                                                                    flowValidations
                                                                                                }
                                                                                                value={
                                                                                                    mode.mode ==
                                                                                                        Mode.NoWorkflowsCreatedInitalRediraction ||
                                                                                                    mode.mode ==
                                                                                                        Mode.NoDataFound
                                                                                                        ? mode.name
                                                                                                        : ''
                                                                                                }
                                                                                                name="flowName"
                                                                                                render={(
                                                                                                    value: string,
                                                                                                    valid: boolean,
                                                                                                    errorMsg: string,
                                                                                                    startValidation: () => void,
                                                                                                    handleInput: Function
                                                                                                ) => {
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            <InputWrapper>
                                                                                                                {!valid && (
                                                                                                                    <ErrorMessage>
                                                                                                                        {!valid
                                                                                                                            ? errorMsg
                                                                                                                            : newLine}
                                                                                                                    </ErrorMessage>
                                                                                                                )}
                                                                                                                <InputContainer>
                                                                                                                    <Input
                                                                                                                        primary={
                                                                                                                            !valid
                                                                                                                                ? `primary`
                                                                                                                                : ''
                                                                                                                        }
                                                                                                                        type="text"
                                                                                                                        onChange={(
                                                                                                                            event: any
                                                                                                                        ) => {
                                                                                                                            startValidation(),
                                                                                                                                handleInput(
                                                                                                                                    'flowName',
                                                                                                                                    event
                                                                                                                                        .target
                                                                                                                                        .value
                                                                                                                                )
                                                                                                                            this.onNameChange(
                                                                                                                                event
                                                                                                                                    .target
                                                                                                                                    .value
                                                                                                                            )
                                                                                                                        }}
                                                                                                                        value={
                                                                                                                            value
                                                                                                                        }
                                                                                                                    />
                                                                                                                    <InputContent>
                                                                                                                        Workflow
                                                                                                                        name
                                                                                                                    </InputContent>
                                                                                                                    {!valid && (
                                                                                                                        <InputErrorIcon>
                                                                                                                            <ErrorIcon />
                                                                                                                        </InputErrorIcon>
                                                                                                                    )}
                                                                                                                </InputContainer>
                                                                                                            </InputWrapper>
                                                                                                        </React.Fragment>
                                                                                                    )
                                                                                                }}
                                                                                            />
                                                                                            {(mode.mode ==
                                                                                                Mode.NoWorkflowsCreatedInitalRediraction ||
                                                                                                mode.mode ==
                                                                                                    Mode.NoDataFound) &&
                                                                                            mode.error ? (
                                                                                                <p className="error_msg">
                                                                                                    {mode.error.info}
                                                                                                </p>
                                                                                            ) : null}
                                                                                        </DropdownWrapper>
                                                                                        <ErrorContext.Consumer>
                                                                                            {(valid: any) => {
                                                                                                let isDisabledValue =
                                                                                                    !valid.formValid
                                                                                                        .formValid ||
                                                                                                    ((mode.mode ==
                                                                                                        Mode.Add ||
                                                                                                        mode.mode ==
                                                                                                            Mode.SaveAs ||
                                                                                                        mode.mode ==
                                                                                                            Mode.Edit ||
                                                                                                        mode.mode ==
                                                                                                            Mode.NoDataFound) &&
                                                                                                        mode.error &&
                                                                                                        mode.error
                                                                                                            .error)

                                                                                                return (
                                                                                                    <Button
                                                                                                        margin={'0px'}
                                                                                                        className={
                                                                                                            isDisabledValue
                                                                                                                ? 'editor_btn_disabled'
                                                                                                                : ''
                                                                                                        }
                                                                                                        disabled={
                                                                                                            isDisabledValue
                                                                                                                ? true
                                                                                                                : false
                                                                                                        }
                                                                                                        onClick={async () => {
                                                                                                            await this.saveCard(
                                                                                                                journey
                                                                                                            )
                                                                                                        }}
                                                                                                    >
                                                                                                        {' '}
                                                                                                        Create your
                                                                                                        Workflow
                                                                                                    </Button>
                                                                                                )
                                                                                            }}
                                                                                        </ErrorContext.Consumer>
                                                                                    </DropdownContainer>
                                                                                </div>
                                                                                <div className="flow_card_wrapper_right">
                                                                                    <img
                                                                                        src={videoImg}
                                                                                        alt="workflow"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </Validate>
                                                                        <div className="or_sticky">
                                                                            <OrSticky>
                                                                                <label>OR</label>
                                                                            </OrSticky>
                                                                        </div>
                                                                        <ButtonWrapper>
                                                                            <Button
                                                                                primary
                                                                                onClick={() =>
                                                                                    this.setState({
                                                                                        showMarketPlace: true
                                                                                    })
                                                                                }
                                                                            >
                                                                                <span>
                                                                                    <SupportIcon />
                                                                                </span>
                                                                                <label>
                                                                                    {' '}
                                                                                    Download from Marketplace
                                                                                </label>
                                                                            </Button>
                                                                            <DownloadContent>
                                                                                <a
                                                                                    target="_blank"
                                                                                    href="https://help.workativ.com/knowledgebase/download-workflow/"
                                                                                >
                                                                                    {' '}
                                                                                    <span>
                                                                                        <VideoIcon />
                                                                                    </span>
                                                                                    <label>
                                                                                        How to download workflow from
                                                                                        marketplace
                                                                                    </label>
                                                                                </a>
                                                                            </DownloadContent>
                                                                        </ButtonWrapper>
                                                                    </BotGridsContainer>
                                                                </BotWrapper>
                                                            </BotStartedWrapper> */}
                            </>
                          );
                        }
                        return null;
                      }}
                    </RootJourneyContextConsumer>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <FlowListWrapper>
                      <TabelWrapper>
                        <Wrapper gridViewCard={viewMode.View}>
                          {viewMode.View === ViewTypeT.ListView &&
                            renderItems.length > 0 && (
                              <Table>
                                <React.Fragment>
                                  <Thead>
                                    <Tr>
                                      <Th size={2}>
                                        <span>Name</span>
                                      </Th>
                                      <Th size={3}>
                                        <span>Description</span>
                                      </Th>

                                      {this.listViewThirdColumn(this.flowView)}
                                    </Tr>
                                  </Thead>
                                  <Tbody>
                                    {renderItems.map((card: any, i: number) =>
                                      card.cardType == CardTypes.Flow ? (
                                        viewMode.View == ViewTypeT.ListView ? (
                                          <Tr
                                            onClick={() =>
                                              this.redirectToFlow(card.id)
                                            }
                                          >
                                            <List
                                              key={i}
                                              showCheckBox
                                              checkBoxShow={this.showSelection()}
                                              render={(
                                                show: boolean,
                                                ToolBox: any
                                              ) => {
                                                return (
                                                  <>
                                                    <Td size={2}>
                                                      <ListSelectBox
                                                        show={
                                                          card.deployed_status ==
                                                            'saved' && show
                                                        }
                                                        isSelected={this.isSelected(
                                                          card
                                                        )}
                                                        onClick={(e: any) => {
                                                          this.selectFlows(
                                                            card
                                                          );
                                                        }}
                                                        value={card.name}
                                                      />
                                                      <div>{card.name}</div>
                                                    </Td>
                                                    <Td size={3}>
                                                      <div>
                                                        {card.description}
                                                      </div>
                                                    </Td>
                                                    <Td
                                                      size={2}
                                                      className="list_flow_p autoamtion_connect_dialog"
                                                    >
                                                      {
                                                        <p className="listite_centre">
                                                          <h6 className="listite_centre autoamtion_connect_dialog_listview">
                                                            {/* <span>
                                                                                    {card.type === 'trigger' ||
                                                                                    card.type === 'event_trigger' ? (
                                                                                        <SubPanelIcons.Trigger />
                                                                                    ) : (
                                                                                        <SubPanelIcons.NoTrigger />
                                                                                    )}
                                                                                </span>
                                                                                <span className="trigger_type">
                                                                                    {card.type === 'trigger' ||
                                                                                    card.type === 'event_trigger'
                                                                                        ? 'Trigger'
                                                                                        : 'No Trigger'}
                                                                                </span> */}
                                                            {getProductId() ===
                                                            'AUTOMATE' ? (
                                                              <>
                                                                <span className="bg_tree_flow list_view">
                                                                  {card.type ===
                                                                  'webhook_trigger' ? (
                                                                    <WebhookCardIcon />
                                                                  ) : card.type ===
                                                                    'event_trigger' ? (
                                                                    <AppTriggerCardIconNew />
                                                                  ) : (
                                                                    <TimerIcon />
                                                                  )}
                                                                </span>
                                                                <span className="trigger_type">
                                                                  {card.type ===
                                                                  'webhook_trigger'
                                                                    ? 'Webhook'
                                                                    : 'App Trigger'}
                                                                </span>
                                                              </>
                                                            ) : card.type ===
                                                                'no_trigger' &&
                                                              card.deployed_status !=
                                                                'saved' ? (
                                                              <React.Fragment>
                                                                {/* <span className="trigger_type_span">
                                                                                                                        <LinkedDialogIcon />
                                                                                                                    </span> */}
                                                                <span className="automation_count_dilaog">
                                                                  (
                                                                  {card.linkedDialogs
                                                                    ? card
                                                                        .linkedDialogs
                                                                        .length
                                                                    : 0}
                                                                  )
                                                                </span>
                                                              </React.Fragment>
                                                            ) : null}
                                                          </h6>
                                                        </p>
                                                      }
                                                    </Td>
                                                    {automationListWithApproval.filter(
                                                      (a) => a.id == card.id
                                                    ).length > 0 ? (
                                                      <Td size={1.5}>No</Td>
                                                    ) : (
                                                      <Td size={1.5}>Yes</Td>
                                                    )}
                                                    <Td
                                                      size={2}
                                                      className="list_flow_span"
                                                    >
                                                      <span className="entity_span">
                                                        {formatDateInCard(
                                                          card.modified_date
                                                        )}
                                                      </span>
                                                      <span>|</span>
                                                      <span className="entity_span">
                                                        {formatAMPM(
                                                          card.modified_date
                                                        )}
                                                      </span>
                                                    </Td>
                                                    <Td size={1.5}>
                                                      {this.state.mode.mode !==
                                                        Mode.Select && (
                                                        <ToolBox>
                                                          {automationListWithoutConnection.indexOf(
                                                            card.id
                                                          ) != -1 && (
                                                            <span className="no_input_value">
                                                              <div className="error_input_show">
                                                                <Tooltip
                                                                  className="target customTip"
                                                                  zIndex={10000}
                                                                  arrowSize={8}
                                                                  tagName="label"
                                                                  content="Connection details are missing."
                                                                  distance={3}
                                                                >
                                                                  <WarningAlertIcon />
                                                                </Tooltip>
                                                              </div>
                                                            </span>
                                                          )}
                                                          <span
                                                            onClick={(
                                                              e: any
                                                            ) => {
                                                              e.stopPropagation();
                                                              this.setToSaveAsMode(
                                                                card
                                                              );
                                                            }}
                                                          >
                                                            <SaveAutomation />
                                                          </span>
                                                          <span
                                                            onClick={(
                                                              e: any
                                                            ) => {
                                                              e.stopPropagation();
                                                              this.setToEditMode(
                                                                card
                                                              );
                                                            }}
                                                          >
                                                            <EditIcon />
                                                          </span>
                                                          <span
                                                            onClick={(
                                                              e: any
                                                            ) => {
                                                              e.stopPropagation();
                                                              this.setToDeleteFlow(
                                                                card
                                                              );
                                                            }}
                                                          >
                                                            <DeleteIcon />
                                                          </span>

                                                          {(card.type ===
                                                            'trigger' ||
                                                            card.type ===
                                                              'event_trigger') &&
                                                          card.deployed_status !==
                                                            'saved' ? (
                                                            <span className="start_stop_triggers">
                                                              <label className="switch_triggers">
                                                                <input
                                                                  type="checkbox"
                                                                  id="togBtn"
                                                                  onChange={() =>
                                                                    this.setActivateOrDeactivateMode(
                                                                      card
                                                                    )
                                                                  }
                                                                  checked={
                                                                    // card.active_status ===
                                                                    true
                                                                  }
                                                                />
                                                                <div className="slider_trigger round_trigger">
                                                                  {card.active_status ===
                                                                  true
                                                                    ? 'Stop'
                                                                    : 'Start'}
                                                                </div>
                                                              </label>
                                                            </span>
                                                          ) : null}
                                                          {card.type ===
                                                            'webhook_trigger' &&
                                                          card.deployed_status &&
                                                          card.deployed_status !==
                                                            'saved' ? (
                                                            <span className="show_hide _auto_switch">
                                                              <div className="switch_box box_1">
                                                                <input
                                                                  type="checkbox"
                                                                  className="switch_1"
                                                                  onChange={(
                                                                    e: any
                                                                  ) => {}}
                                                                  onClick={(
                                                                    e: any
                                                                  ) => {
                                                                    e.stopPropagation();
                                                                    this.setActivateOrDeactivateMode(
                                                                      card
                                                                    );
                                                                  }}
                                                                  checked={
                                                                    card.active_status ===
                                                                    true
                                                                  }
                                                                />
                                                              </div>
                                                            </span>
                                                          ) : null}

                                                          {card.deployed_status &&
                                                            card.deployed_status ==
                                                              'saved' && (
                                                              <span className="slider_trigger_draft_listview">
                                                                <div className="slider_trigger_draft">
                                                                  Draft
                                                                </div>
                                                              </span>
                                                            )}
                                                        </ToolBox>
                                                      )}{' '}
                                                    </Td>
                                                  </>
                                                );
                                              }}
                                            />
                                          </Tr>
                                        ) : (
                                          ''
                                        )
                                      ) : (
                                        ''
                                      )
                                    )}
                                  </Tbody>
                                </React.Fragment>
                              </Table>
                            )}

                          {renderItems.map((card: any, i: number) =>
                            card.cardType == CardTypes.Flow ? (
                              viewMode.View !== ViewTypeT.ListView ? (
                                <Card
                                  key={card.id}
                                  card={card}
                                  mode={mode}
                                  click={() => this.redirectToFlow(card.id)}
                                  edit={this.setToEditMode}
                                  saveAs={this.setToSaveAsMode}
                                  delete={this.setToDeleteFlow}
                                  select={this.selectFlows}
                                  view={this.flowView}
                                  setActivateOrDeactivateMode={
                                    this.setActivateOrDeactivateMode
                                  }
                                  hasConnectionError={
                                    automationListWithoutConnection.indexOf(
                                      card.id
                                    ) != -1
                                  }
                                  isApproval={
                                    automationListWithApproval.filter(
                                      (a) => a.id == card.id
                                    ).length > 0
                                  }
                                />
                              ) : (
                                <></>
                              )
                            ) : viewMode.View !== ViewTypeT.ListView ? (
                              <GroupCard
                                key={card.id}
                                card={card}
                                mode={mode}
                                edit={this.setToGroupEditMode}
                                select={this.selectGroups}
                                delete={this.setToDeleteGroup}
                                ungroup={this.setToUngroup}
                                click={() => this.redirectToGroup(card.id)}
                              />
                            ) : (
                              <></>
                              // <List
                              //     key={i}
                              //     showCheckBox
                              //     checkBoxShow={this.showSelection()}
                              //     render={(show: boolean, ToolBox: any) => {
                              //         return (
                              //             <div className="list_full_width_automation list-full-width-group-margin">
                              //                 <div className="list_full_width_child">
                              //                     <div className="col-md-12 list-view-sub list-view-sub_child">
                              //                         <div className="main_sub">
                              //                             <div className="col-md-4 list_flow_hr">
                              //                                 <div className="search_card_list" />
                              //                             </div>
                              //                             <div className="col-md-4 list_flow_p" />
                              //                             <div className="col-md-2  list_flow_span" />
                              //                             <div className="col-md-2 list-view-icon" />
                              //                         </div>
                              //                     </div>
                              //                 </div>
                              //                 <div className="list-full-width">
                              //                     <div className="col-md-12 list-view-sub list-view-sub_child">
                              //                         <div className="main_sub">
                              //                             <div className="col-md-4 list_flow_hr">
                              //                                 <ListSelectBox
                              //                                     show={show}
                              //                                     isSelected={this.isGroupSelected(
                              //                                         card
                              //                                     )}
                              //                                     onClick={() =>
                              //                                         this.selectGroups(card)
                              //                                     }
                              //                                     value={card.name}
                              //                                 />
                              //                                 <div
                              //                                     className="search_card_list"
                              //                                     onClick={() =>
                              //                                         this.redirectToGroup(card.id)
                              //                                     }
                              //                                 >
                              //                                     <h5
                              //                                         style={{
                              //                                             paddingTop: 0
                              //                                         }}
                              //                                     >
                              //                                         {card.name}
                              //                                     </h5>
                              //                                 </div>
                              //                             </div>
                              //                             <div className="col-md-4 list_flow_p">
                              //                                 <p className="listite_centre">
                              //                                     {card.description}
                              //                                 </p>
                              //                             </div>
                              //                             <div className="col-md-2 col-lg-3  list_flow_span">
                              //                                 <span className="entity_span">
                              //                                     {formatDateInCard(
                              //                                         card.modified_date
                              //                                     )}
                              //                                 </span>
                              //                                 <span>|</span>
                              //                                 <span className="entity_span">
                              //                                     {formatAMPM(card.modified_date)}
                              //                                 </span>
                              //                             </div>
                              //                             {this.state.mode.mode !== Mode.Select && (
                              //                                 <ToolBox className="group-tool-box">
                              //                                     <span
                              //                                         className="home_edit"
                              //                                         onClick={event => {
                              //                                             event.stopPropagation()
                              //                                             this.setToUngroup(card)
                              //                                         }}
                              //                                     >
                              //                                         <UnGroupIcon />
                              //                                     </span>
                              //                                     <span
                              //                                         onClick={() =>
                              //                                             this.setToDeleteFlow(card)
                              //                                         }
                              //                                     >
                              //                                         <DeleteIcon />
                              //                                     </span>
                              //                                     <span
                              //                                         onClick={() =>
                              //                                             this.setToGroupEditMode(
                              //                                                 card
                              //                                             )
                              //                                         }
                              //                                     >
                              //                                         <EditIcon />
                              //                                     </span>
                              //                                 </ToolBox>
                              //                             )}
                              //                         </div>
                              //                     </div>
                              //                 </div>
                              //             </div>
                              //         )
                              //     }}
                              // />
                            )
                          )}
                        </Wrapper>
                      </TabelWrapper>
                    </FlowListWrapper>
                    <section className="intent_bottom">
                      <div>&nbsp;</div>
                    </section>
                  </React.Fragment>
                )}
              </React.Fragment>
            </ErrorBoundary>
          </React.Fragment>
        )}
        {/* Modal homepage start */}
        {this.state.showMarketPlace && (
          // <MarketPlaceWrapper {...props} />
          <AutomationMarketplaceDownload
            {...this.props}
            closePopup={() => {
              this.setState({ showMarketPlace: false });
            }}
          />
        )}
        {/* Modal homepage end */}
      </React.Fragment>
    );
  }
}

const addFlow = async (
  name: string,
  description: string,
  automations: FlowResponseT[],
  workSpaceName: string,
  groupId: string
) => {
  const addData = {
    name: name,
    description: description,
    group: groupId,
    deployed_status: 'saved',
    active_status: false,
    workspace: workSpaceName,
  };

  lazyGA().event({
    category: 'Automation',
    action: 'Create an Automation',
  });

  let resp: any = await postJson(
    automationAPIURLWithWorkspace(workSpaceName, AutomationApi.Create)
  )(addData);

  if (!resp.data || !resp.data.output) {
    throw resp;
  }

  const flowAdd: FlowResponseT = {
    deployed_status: 'saved',
    name,
    description,
    active_status: false,
    type: 'no_trigger',
    id: resp.data.output.id,
    modified_date: new Date().toString(),
    groupId,
  };
  return [...automations, flowAdd];
};

const editFlow = async (
  id: string,
  name: string,
  description: string,
  automations: FlowResponseT[],
  workspaceName: string
) => {
  const filtered = automations.filter((flow: FlowResponseT) => flow.id !== id);
  const toEdit = automations.filter((flow: FlowResponseT) => flow.id === id)[0];
  const groupId = defaultGroupName;
  const editData = {
    name: name,
    description: description,
    id,
  };

  const resp = await putJson(
    automationAPIURLWithWorkspace(workspaceName, AutomationApi.Update)
  )()(editData);

  if (!resp.data || !resp.data.output) {
    throw resp;
  }

  const flowEdit: FlowResponseT = {
    id: id,
    description: description,
    name: name,
    active_status: toEdit.active_status, //need to conform
    deployed_status: toEdit.deployed_status, //need to conform
    linkedDialogs: toEdit.linkedDialogs, //need to conform
    type: toEdit.type, //need to conform
    groupId: groupId,
    modified_date: new Date().toString(),
  };
  lazyGA().event({
    category: 'Automation',
    action: 'Update an Automation',
  });
  return [...filtered, flowEdit];
};

const saveFlowAs = async (
  id: string,
  name: string,
  description: string,
  automations: FlowResponseT[],
  _groupId: string,
  workspaceName: string
) => {
  const editData = {
    name: name,
    description: description,
    id,
    active_status: true,
  };
  const response = await postJson(
    automationAPIURLWithWorkspace(workspaceName, AutomationApi.SaveAs)
  )({
    description: description,
    name: name,
    id: id,
  });

  if (!response.data || !response.data.output) {
    throw response;
  }

  const flowAdd: FlowResponseT = {
    deployed_status: 'saved',
    name,
    description,
    active_status: false,
    type: 'no_trigger',
    id: response.data.output,
    modified_date: new Date().toString(),
    groupId: defaultGroupName,
  };
  lazyGA().event({
    category: 'Automation',
    action: 'Copy an Automation',
  });
  return [...automations, flowAdd];
};

const editGroup = async (
  id: string,
  name: string,
  description: string,
  groups: GroupResponseT[],
  workspaceName: string
) => {
  const filtered = groups.filter((group: GroupResponseT) => group.id != id);

  const editData: GroupResponseT = {
    id: id,
    name,
    description,
    modified_date: new Date().toString(),
  };

  await putJson(flowApiURL(workspaceName, FlowApi.UpdateGroup))()(editData);

  return [...filtered, editData];
};

const deleteFlow = async (
  id: string,
  automations: FlowResponseT[],
  workspaceName: string
) => {
  const filtered = automations.filter((flow) => flow.id != id);
  // console.log('automations', automations, filtered)

  const deleteData = {
    id: id,
  };

  await deleteJson(
    automationAPIURLWithWorkspace(workspaceName, `${FlowApi.DeleteFlow}/${id}`)
  )(deleteData);

  return filtered;
};

const ungroup = async (
  id: string,
  groups: GroupResponseT[],
  prevFlows: FlowResponseT[],
  sortedList: any[],
  workspaceName: string
) => {
  const filtered = groups.filter((group) => group.id != id);
  const deleteData = {};
  await deleteJson(flowApiURL(workspaceName, `${FlowApi.UnGroup}/${id}`))(
    deleteData
  );

  const flows = prevFlows.map((flow) =>
    flow.groupId == id ? { ...flow, groupId: '1' } : flow
  );

  return {
    flows,
    groups: filtered,
    sortedList: sortedList,
  };
};

const deleteGroup = async (
  id: string,
  groups: GroupResponseT[],
  prevFlows: FlowResponseT[],
  workspaceName: string
) => {
  const filtered = groups.filter((group) => group.id != id);
  const deleteData = {};
  await deleteJson(flowApiURL(workspaceName, `${FlowApi.DeleteGroup}/${id}`))(
    deleteData
  );
  const automations = prevFlows.filter((flow) => flow.groupId != id);

  return {
    automations,
    groups: filtered,
  };
};

const responseToViewMode = (
  response: FlowsResponseT,
  sortBy: SortT,
  view: FlowFilterT,
  innerFilter: FlowFilterT
) => {
  const cardeToRender = flowsResponseToCards(
    response,
    sortBy,
    view,
    innerFilter
  );
  // console.log('response', response, cardeToRender)
  if (cardeToRender.length === 0) {
    return {
      ...response,
      mode: NoDataFound,
      cardsToRender: cardeToRender,
    };
  }
  return {
    ...response,
    mode: View,
    cardsToRender: cardeToRender,
  };
};

const popupText = (mode: Modes): any => {
  if (mode.mode == Mode.Add || mode.mode === Mode.NoDataFound) {
    return {
      header: 'Create Workflow',
      subHeader: 'Enter name and description',
      buttonText: 'Create',
    };
  } else if (mode.mode == Mode.Edit && mode.cardType == CardTypes.Flow) {
    return {
      header: 'Edit Workflow',
      subHeader: 'Enter name and description',
      buttonText: 'Save',
    };
  } else if (mode.mode == Mode.SaveAs) {
    return {
      header: 'Save Workflow as',
      subHeader: 'Workflow will be saved under Drafts',
      buttonText: 'Save As',
    };
  } else {
    return {
      header: 'Edit Group',
      subHeader: 'Make the required changes and click on Save',
      buttonText: 'Save',
    };
  }
};

const pushFront = (card: any, sortedList: any[]) => {
  if (sortedList) {
    //console.log('card', card)
    const minSortValue = sortedList.length == 0 ? 0 : sortedList[0].sortBy - 1;
    if (card.type == 'automation') {
      const sortedCard = {
        type: card.type,
        id: card.id,
        modified_date: new Date().toString(),
        sortBy: minSortValue,
        linkedDialogs: card.linkedDialogs,
      };
      // console.log('sortedCard', sortedCard)
      return [sortedCard, ...sortedList];
    } else {
      const sortedCard = {
        type: card.type,
        id: card.id,
        modified_date: new Date().toString(),
        sortBy: minSortValue,
        linkedDialogs: card.linkedDialogs,
      };
      // console.log('sortedCard', sortedCard)
      return [sortedCard, ...sortedList];
    }
  }
  return undefined;
};

const formatTitle =
  (search: string, cardPadding: string) => (card: CardDisplayT) => {
    let reg = new RegExp(search, 'gi');
    let label =
      search != SEARCH_KEY
        ? `<h5 style='padding-top:${cardPadding}'>` + card.name + '</h5>'
        : `<h5 style='padding-top:${cardPadding}'>` + card.name + '</h5>';

    return { ...card, displayLabel: label };
  };
