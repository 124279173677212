/*eslint-disable*/
import React from 'react'

export type UserPhotoP = {
    url?: string
}

const DefaultUserPhoto = () => (
    <svg viewBox="0 0 17.1 20" className="default-dp">
        <g>
            <g id="Layer_1-2">
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="4.15" y1="4.4" x2="12.97" y2="4.4">
                    <stop
                        offset="0"
                        style={{
                            stopColor: 'rgb(86, 47, 245)'
                        }}
                    />
                    <stop
                        offset="1"
                        style={{
                            stopColor: 'rgb(42, 141, 255)'
                        }}
                    />
                </linearGradient>
                <path
                    className="st0"
                    d="M8.6,8.8c2.4,0,4.4-2,4.4-4.4S11,0,8.6,0S4.2,2,4.2,4.4c0,0,0,0,0,0C4.2,6.8,6.1,8.8,8.6,8.8z"
                />
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="0" y1="14.89" x2="17.11" y2="14.89">
                    <stop
                        offset="0"
                        style={{
                            stopColor: 'rgb(86, 47, 245)'
                        }}
                    />
                    <stop
                        offset="1"
                        style={{
                            stopColor: 'rgb(42, 141, 255)'
                        }}
                    />
                </linearGradient>
                <path
                    className="st1"
                    d="M8.6,9.8C3.8,9.8,0,13.6,0,18.3C0,19.3,0.7,20,1.7,20l0,0h13.8c0.9,0,1.7-0.7,1.7-1.7 C17.1,13.6,13.3,9.8,8.6,9.8z"
                />
            </g>
        </g>
    </svg>
)

export default (props: UserPhotoP) => (props.url ? <img src={props.url} /> : <DefaultUserPhoto />)
