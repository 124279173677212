/*eslint-disable*/
// dummy cimmit
import { AxiosResponse } from 'axios';
import { EditorState, convertToRaw } from 'draft-js';
import produce from 'immer';
import * as ramda from 'ramda';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  ApptriggerIconNew,
  AppTriggersIcon,
  DownArrowCircle,
  DownarrowWithCircle,
  InfoIcon,
  TimerIcon,
  WebhookIcon,
} from '@/common/Icons/Icons';
import { LoadingSvgImage } from '@/common/Icons/LoadingSvgImage';
import {
  ErrorHandlingSuccessIcon,
  GreenTickIcon,
  NoTriggerIcon,
  WarningAlertIcon,
} from '@/common/Icons/Workflow.Icons';
import { Error } from '@/common/components/ErrorBoundary';
import { Loader } from '@/common/components/Loader';
import {
  Button,
  InputWrapper,
  Input as InputBox,
  InputContent,
  InputContainer,
  Paragraph,
  Header,
  ButtonContainer,
  EmptyDiv,
  Input,
  PopupScrollHeight,
} from '@/common/styled/Workflow.Dumb';

import {
  automationAPIURLWithWorkspace,
  getJson,
  getProductId,
  postJson,
  putJson,
} from '@/common/utils/api-utils';
import { CancelButtonInput, getInitialConnectionValue } from './ActionPanel';
import { SidePanelContext } from './Canvas';
import { CanvasRightPanel } from './CanvasRightPanel';
import { CommonInputs } from './CommonInputs';
import { fetchAutomations, wrapPromise } from './nonCanvasActions';
import {
  ActionConnectionsT,
  ActionInitialState,
  ActionInputsListT,
  ActionsResponseT,
  ApitokenT,
  AppTriggerInitialState,
  AppTriggerResponseT,
  AppsListT,
  AuthType,
  BasicT,
  ConnectionTypeT,
  ContextPopupStateT,
  CustomAuthT,
  DefaultSelectedConnection,
  InputFieldT,
  NewConnection,
  NewConnectionT,
  NewConnectionTypeT,
  NodeAddT,
  NodeEditT,
  NodeMode,
  NodeModes,
  NodeT,
  NodeView,
  OauthtokenT,
  OldConnectionT,
  PopupError,
  ResponseOutput,
  RightPanelStateContext,
  Tree,
  TreeT,
  emptyInputSchema,
  oldConnection,
  addErrorHandlingNodesInAppTrigger,
  NodeEdit,
  WebhookInitialState,
  deleteErrorHandlingNodes,
  CustomOAuthT,
  NoTriggerInitialState,
} from './types';
import {
  configKinds,
  converToEditorFormat,
  convertAsaSingleString,
  convertToServerFormat2,
  emptyEditorState,
  sortAnArrayAlphabetically,
} from './utils';
import { Modal } from '@/common/components/Modal';
import { ButtonWrapper } from '../AutomationMarketplaceDownload';
import { Wrapper } from './TriggerPanel';
import Tooltip from 'react-tooltip-lite';
import { WorkflowOutputTest } from './ConnectPopup';
import { useGenericCachedRun } from '@/common/hooks/useCachedQuery';

const minutes = [
   1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15
];

export type AppTriggerPanelStateT = {
  show: boolean;
  parentNode: TreeT<NodeT>;
  mode: NodeModes;
  currentNode: AppTriggerResponseT;
};

export type TriggerPanelStateT = {
  show: boolean;
  parentNode: TreeT<NodeT>;
  mode: NodeModes;
  currentNode: AppTriggerResponseT;
};

export type ActionPanelStateContextT = {
  actionPanelState: AppTriggerPanelStateT;
  setActionPanelState: (object: any) => void;
  setContextPopupValues: (object: any) => void;
  contextPopupValues: ContextPopupStateT;
  userSwitchedApp: boolean;
  setUserSwitchedApp: (switched: boolean) => void;
};

export const ActionPanelStateContext = createContext<ActionPanelStateContextT>({
  actionPanelState: {
    show: false,
    mode: NodeAddT,
    parentNode: { value: Tree, children: [] },
    currentNode: AppTriggerInitialState,
  },
  setActionPanelState: (_: any) => undefined,
  setContextPopupValues: (_: any) => undefined,
  contextPopupValues: {
    show: false,
    inputWithValues: [],
    currentInput: '',
    isMultiple: { path: '', parentKey: '' },
    collectionPicker: {
      isCollectionPicker: false,
      collectionType: emptyInputSchema,
      selectedCollection: {
        name: '',
        value: {
          $id: '',
          type: 'collection',
          item: {
            type: 'object',
            properties: {},
          },
          title: '',
          properties: {},
        },
      },
      app: ResponseOutput,
    },
  },
  userSwitchedApp: false,
  setUserSwitchedApp: (_: boolean) => undefined,
});

//const TriggerDescription = (_: any) => {
//return (
//<div className="automation_notification_message">
//<FlowCanvasIcons.TriggerDescriptionIcon />
//<span>
//Triggers are what starts the processing of an automation. When the triggers becomes true, then the
//automation will start and validates the conditions and starts the action.
//</span>
//</div>
//)
//}

export const AppTriggerPanel = (props: any) => {
  const { rightPanelState } = useContext(SidePanelContext);
  // console.log('props', props, rightPanelState)

  const [actionPanelState, setActionPanelState] = useState({
    ...rightPanelState,
    currentNode: rightPanelState.currentNode as AppTriggerResponseT,
  });

  const [contextPopupValues, setContextPopupValues] =
    useState<ContextPopupStateT>({
      show: false,
      inputWithValues: [],
      currentInput: '',
      isMultiple: { path: '', parentKey: '' },
      collectionPicker: {
        isCollectionPicker: false,
        collectionType: emptyInputSchema,
        selectedCollection: {
          name: '',
          value: {
            $id: '',
            type: 'collection',
            item: {
              type: 'object',
              properties: {},
            },
            title: '',
            properties: {},
          },
        },
        app: ResponseOutput,
      },
    });

  const [userSwitchedApp, setUserSwitchedApp] = useState(false);

  return (
    <ActionPanelStateContext.Provider
      value={{
        actionPanelState,
        setActionPanelState,
        contextPopupValues,
        setContextPopupValues,
        userSwitchedApp,
        setUserSwitchedApp,
      }}
    >
      <ActionActualComponent {...props} />
    </ActionPanelStateContext.Provider>
  );
};

const ActionActualComponent = (props: any) => {
  // for comparing props
  const ActionElement = useRef<any>(null);

  const {
    rightPanelState,
    setRightPanelState,
    saveTree,
    treeData,
    active_automation,
    setAutomationMetaType,
    setEditTreeData,
    editTreeData,
  } = useContext(SidePanelContext);

  const {
    actionPanelState,
    setActionPanelState,
    contextPopupValues,
    setContextPopupValues,
    userSwitchedApp,
    setUserSwitchedApp,
  } = useContext(ActionPanelStateContext);

  const currentNode = actionPanelState.currentNode as AppTriggerResponseT;

  const [loading, setLoading] = useState(false);

  const [showWarningModal, setShowWarningModal] = useState({
    show: false,
  });

  const [customAuthState, setCustomAuthState] = useState<{
    loading: boolean;
    error: false | string;
  }>({
    loading: false,
    error: false,
  });

  const [previousMode, setPreviousMode] = useState<NodeModes>(NodeView);

  const workspaceName = props.match.params.workspacename;
  const [showOutputTest, setShowOutputTest] = useState(false)
  const actionFetcher = useGenericCachedRun<AppsListT>('actions', () =>
    getJson(automationAPIURLWithWorkspace(workspaceName, '/actions'))
  )

  useEffect(() => {
    const currentMode = rightPanelState.mode;

    // compare props and update changes based on following condition
    if (
      !ActionElement.current ||
      (ActionElement.current &&
        (rightPanelState.mode.mode !== ActionElement.current.mode.mode ||
          (currentMode.mode === NodeMode.Edit &&
            currentMode.nodeEditInput.id !==
            ActionElement.current.mode.nodeEditInput.id)))
    ) {
      if (
        currentMode.mode === NodeMode.Add ||
        currentMode.mode === NodeMode.Edit
      ) {
        // next time to compare with previous props
        ActionElement.current = rightPanelState;

        //show loader while changing modes (such as edit->add or add->edit)
        setLoading(true);

        // update current action panel context state for further child usage
        setActionPanelState({
          ...rightPanelState,
          currentNode: rightPanelState.currentNode as AppTriggerResponseT,
        });

        // call initial API everytime mode changed
        callApplistAPI({
          ...rightPanelState,
          currentNode: rightPanelState.currentNode as AppTriggerResponseT,
        });

        // to set switching app false when changing edit nodes, otherwise it will leads to some issue wherever userSwitchedApp value is used
        setUserSwitchedApp(false);
      } else {
        ActionElement.current = rightPanelState;
        currentMode.mode === NodeMode.Loading
          ? setLoading(true)
          : setLoading(false);

        setActionPanelState({
          ...rightPanelState,
          currentNode: rightPanelState.currentNode as ActionsResponseT,
        });
      }
    } else if (
      rightPanelState.mode.mode === NodeMode.Edit &&
      ActionElement.current.mode.mode === NodeMode.Edit &&
      rightPanelState.mode.nodeEditInput.id ===
      ActionElement.current.mode.nodeEditInput.id
    ) {
      setActionPanelState({
        ...actionPanelState,
        show: true,
      });
    }

    // to use this when clicking error ok button
    setPreviousMode(rightPanelState.mode);
  }, [rightPanelState.mode]);

  // to change ActionElement.current values while updating state in each step
  useEffect(() => {
    ActionElement.current = actionPanelState;

    // to see the details in left side panel while adding or editing
    const { trigger, nodeType, ...rest } = actionPanelState.currentNode;

    setRightPanelState({
      ...rightPanelState,
      currentNode: { ...rightPanelState.currentNode, ...rest },
    });
  }, [actionPanelState.currentNode]);

  // called when the popup mode is add and after action selected
  useEffect(() => {
    (actionPanelState.mode.mode !== NodeMode.Edit || userSwitchedApp) &&
      currentNode.selectedAction.id &&
      currentNode.selectedAction.id.length > 0 &&
      getConnectionsList(currentNode.selectedApp.id, actionPanelState);
  }, [currentNode.selectedAction.id]);

  // called when the popup mode is add and after connection selected
  useEffect(() => {
    (actionPanelState.mode.mode !== NodeMode.Edit || userSwitchedApp) &&
      currentNode.selectedConnection.type === ConnectionTypeT.old &&
      currentNode.selectedConnection.id.length > 0 &&
      getConfigDetails(currentNode.selectedAction.id, actionPanelState);
  }, [currentNode.selectedConnection.type]);

  // called when the popup mode is add and after app has selected
  useEffect(() => {
    (actionPanelState.mode.mode !== NodeMode.Edit || userSwitchedApp) &&
      currentNode.selectedApp.id.length > 0 &&
      (currentNode.selectedApp.type == AuthType.Custom ||
        currentNode.selectedApp.type == AuthType.CustomOauth || currentNode.selectedApp.type == AuthType.PrivateOauth) &&
      getCustomAuthenticationType(currentNode.selectedApp.id, actionPanelState);
  }, [currentNode.selectedApp.id]);

  // -------------- Api call --------------
  async function callApplistAPI(state: AppTriggerPanelStateT) {
    const workspaceName = props.match.params.workspacename;

    await actionFetcher()
      // await getJson(automationAPIURLWithWorkspace(workspaceName, '/actions'))
      .then((unfiltertedAppList: AppsListT) => {
        const latestTriggers = unfiltertedAppList.output.filter(
          (action) =>
            (action.type == 'event_trigger' || action.type == 'trigger') &&
            action.latest
        );
        const actionsLens = ramda.lensProp('output');
        const data = ramda.set(
          actionsLens,
          latestTriggers,
          unfiltertedAppList
        ) as AppsListT;
        if (state.mode.mode === NodeMode.Edit) {
          const currentNode = state.currentNode as AppTriggerResponseT;

          const mode = state.mode as NodeEditT;

          const selectedAction = unfiltertedAppList.output.filter(
            (actionResponse) =>
              actionResponse.action === mode.nodeEditInput.name &&
              actionResponse.id === mode.nodeEditInput.meta_data.action_id
          );

          if (selectedAction.length != 0) {
            const nextState = produce(currentNode, (draftState) => {
              draftState.appsList = data;
              draftState.selectedAction = {
                name: selectedAction[0].action,
                id: selectedAction[0].id,
                description: mode.nodeEditInput.description,
                type: selectedAction[0].type as any,
              };
              draftState.selectedApp = {
                name: selectedAction[0].appName,
                id: selectedAction[0].appId,
                type: selectedAction[0].authentication_type,
                icon: selectedAction[0].icon,
                version: selectedAction[0].version,
              };
              draftState.errorHandling = {
                errorHandling:
                  mode.nodeEditInput.meta_data.continueIfError && true,
                errorHandlingParent: mode.nodeEditInput,
                errors: [
                  ...(mode.nodeEditInput.meta_data &&
                    mode.nodeEditInput.meta_data.errors
                    ? mode.nodeEditInput.meta_data.errors
                    : ActionInitialState.errorHandling.errors),
                ],
                errorHandlingChildrenForAddMode: [],
              };
              draftState.selectedPollingTime = mode.nodeEditInput.interval ? mode.nodeEditInput.interval : props._pollingTimeAccess ? parseInt(props._pollingTimeAccess) : 0
            });

            setActionPanelState({
              ...state,
              currentNode: nextState,
            });

            getConnectionsList(selectedAction[0].appId, {
              ...state,
              currentNode: nextState,
            });
          } else {
            const filtertedOutputList = data.output.filter(
              (app) =>
                (app.type == 'event_trigger' || app.type == 'trigger') &&
                app.latest
            );
            const nextState = produce(currentNode, (draftState) => {
              draftState.appsList = {
                ...data,
                output: filtertedOutputList,
              };
            });

            setActionPanelState({
              ...state,
              currentNode: nextState,
            });

            setLoading(false);
          }
        } else {
          const currentNode = state.currentNode as AppTriggerResponseT;

          const nextState = produce(currentNode, (draftState) => {
            draftState.appsList = data;
          });

          setActionPanelState({
            ...state,
            currentNode: nextState,
          });

          setLoading(false);
        }
      })
      .catch((err) => {
        // console.log('err', err)
        setLoading(false);

        setActionPanelState({
          ...state,
          mode: {
            ...PopupError,
            error: err.response
              ? err.response.data.error
              : 'Something went wrong!<br /> Please try again afer sometime.',
            // error: 'Unable retrive applications list'
          },
        });
      });
  }

  // -------------- Api call --------------
  async function getConnectionsList(
    appId: string,
    state: AppTriggerPanelStateT
  ) {
    const workspaceName = props.match.params.workspacename;

    await postJson(
      automationAPIURLWithWorkspace(workspaceName, `/connection/fetch`)
    )({
      appId,
      auth_type: state.currentNode.selectedApp.type,
    })
      .then((resp) => {
        const response = resp.data as ActionConnectionsT;
        if (state.mode.mode === NodeMode.Edit && state.mode.nodeEditInput.meta_data) {
          const mode = state.mode as NodeEditT;

          const currentNode = state.currentNode as AppTriggerResponseT;

          const selectedConnection = response.output.filter(
            (x) =>
              x.authStoreMap ===
              mode.nodeEditInput.meta_data.authentication_id
          );

          let nextState: AppTriggerResponseT;

          if (!userSwitchedApp) {
            nextState = produce(currentNode, (draftState) => {
              draftState.connections = response;
              selectedConnection.length > 0
                ? (draftState.selectedConnection = {
                  type: ConnectionTypeT.old,
                  id: selectedConnection[0].authStoreMap,
                  selectedConnection: selectedConnection[0].name,
                  connectionsDetails: {
                    type: currentNode.selectedApp.type as any,
                    value: {
                      ...getInitialConnectionValue(
                        currentNode.selectedApp.type
                      ).value,
                      meta_data: selectedConnection[0],
                    },
                  },
                  valid: true,
                  hasError: null,
                  loading: false,
                })
                : null;
            });
          } else {
            nextState = produce(currentNode, (draftState) => {
              draftState.connections = response;
              let connection = ActionInitialState.selectedConnection;

              connection.connectionsDetails = getInitialConnectionValue(
                currentNode.appsList.output.filter(
                  (x) => x.appId === currentNode.selectedApp.id && x.latest
                )[0].authentication_type
              );

              connection = {
                ...connection,
                selectedConnection: 'Connect your application account',
                id: '',
                hasError: null,
                valid: null,
              };

              draftState.selectedConnection = connection;
            });
          }
          setActionPanelState({
            ...state,
            currentNode: nextState,
          });

          // call this to custom authentication fields
          state.currentNode.selectedApp.type == AuthType.Custom ||
          state.currentNode.selectedApp.type == AuthType.CustomOauth || state.currentNode.selectedApp.type == AuthType.PrivateOauth
          ? getCustomAuthenticationType(state.currentNode.selectedApp.id, {
              ...state,
              currentNode: nextState,
            })
            : getConfigDetails(state.currentNode.selectedAction.id, {
              ...state,
              currentNode: nextState,
            });
        } else {
          const currentNode = state.currentNode as AppTriggerResponseT;

          const nextState = produce(currentNode, (draftState) => {
            draftState.connections = response;
          });

          setActionPanelState({
            ...state,
            currentNode: nextState,
          });
        }
      })
      .catch((err) => {
        // console.log('err', err)
        setLoading(false);

        setActionPanelState({
          ...state,
          mode: {
            ...PopupError,
            error: err.response
              ? err.response.data.error
              : 'Something went wrong!<br /> Please try again afer sometime.',
          },
        });
      });
  }

  async function getCustomAuthenticationType(
    appId: string,
    state: AppTriggerPanelStateT
  ) {
    const workspaceName = props.match.params.workspacename;

    await postJson(
      automationAPIURLWithWorkspace(workspaceName, `/actions/auth/config`)
    )({
      appId: appId,
      version: state.currentNode.selectedApp.version,
    })
      .then((resp) => {
        setCustomAuthState({
          ...customAuthState,
          error: false,
        });

        const response = resp.data as {
          type: 'success';
          output: {
            id: string;
            input: InputFieldT[];
          };
        };
        if (state.mode.mode === NodeMode.Edit) {
          const currentNode = state.currentNode as AppTriggerResponseT;
          const mode = state.mode as NodeEditT;

          const nextState = produce(currentNode, (draftState) => {
            let connectionsDetails = draftState.selectedConnection
              .connectionsDetails as CustomAuthT;
            connectionsDetails.customInputs = {
              id: response.output.id,
              inputs:
                userSwitchedApp ||
                  mode.nodeEditInput.meta_data.authentication_id.length == 0
                  ? response.output.input.map((inp) => {
                    return { ...inp, value: '' };
                    })
                  : response.output.input,
            };
          });

          setActionPanelState({
            ...state,
            currentNode: nextState,
          });

          if (!userSwitchedApp) {
            getConfigDetails(state.currentNode.selectedAction.id, {
              ...state,
              currentNode: nextState,
            });
          }
        } else {
          const currentNode = state.currentNode as AppTriggerResponseT;

          const nextState = produce(currentNode, (draftState) => {
            let connectionsDetails = draftState.selectedConnection
              .connectionsDetails as CustomAuthT;
            connectionsDetails.customInputs = {
              id: response.output.id,
              inputs: response.output.input,
            };
          });

          setActionPanelState({
            ...state,
            currentNode: nextState,
          });
        }
      })
      .catch((err) => {
        setLoading(false);

        setCustomAuthState({
          ...customAuthState,
          error: err.response
            ? err.response.data.error
            : 'Something went wrong!<br /> Please try again afer sometime.',
        });

        setActionPanelState({
          ...state,
        });
      });
  }

  // -------------- Api call --------------
  async function getConfigDetails(
    actionId: string,
    state: AppTriggerPanelStateT
  ) {
    const workspaceName = props.match.params.workspacename;

    await getJson(
      automationAPIURLWithWorkspace(
        workspaceName,
        `/actions/${actionId}/config`
      )
    )
      .then((responseData: ActionInputsListT) => {
        if (state.mode.mode === NodeMode.Edit) {
          // console.log("action config", responseData)
          // getting context popup values

          const mode = state.mode as NodeEditT;
          const input = (
            !userSwitchedApp
              ? mode.nodeEditInput.input
              : responseData.output.input
          ) as InputFieldT[];
          const triggerNodeOutput = treeData.tree.value;

          const addedContextValue = produce(state, (draft) => {
            mode.nodeOutputConfig = [];
            draft.mode = mode;
            draft.currentNode.actionInputsList.output.input = input.map(
              (x: InputFieldT) => {
                const { value } = x;

                if (
                  x.config.kind == 'date' ||
                  x.config.kind == 'enum' ||
                  x.config.kind == 'lookup-enum' ||
                  x.config.kind == 'switch'
                ) {
                  return x;
                } else if (value) {
                  return {
                    ...x,
                    value: converToEditorFormat(
                      value as string,
                      [],
                      triggerNodeOutput,
                      x,
                      {
                        inputs: draft.currentNode.actionInputsList.output
                          .input as InputFieldT[],
                        contextPopupValues,
                        setContextPopupValues,
                      }
                    ) as EditorState,
                    inputHasValue: true,
                  };
                } else {
                  return { ...x, value: emptyEditorState as any };
                }
              }
            ) as InputFieldT[];
          });

          setActionPanelState(addedContextValue);

          setLoading(false);
        } else {
          const response: ActionInputsListT = {
            type: responseData.type,
            output: responseData.output,
          };

          const nextState = produce(currentNode, (draftState) => {
            draftState.actionInputsList = {
              ...response,
              output: {
                ...response.output,
                input: response.output.input.map((x) => ({
                  ...x,
                  value: emptyEditorState as any,
                })),
              },
            };
          });

          setActionPanelState({
            ...actionPanelState,
            currentNode: nextState,
          });
        }
      })
      .catch((err) => {
        setLoading(false);

        setActionPanelState({
          ...rightPanelState,
          mode: {
            ...PopupError,
            error: err.response
              ? err.response.data.error
              : 'Something went wrong!<br /> Please try again afer sometime.',
          },
        });
      });
  }

  // disable or enable save button
  function disableSave() {
    function hasValue(currentValue: any) {
      // to check whether if any context has been selected for this input then enable save
      if (
        currentValue.config.kind == 'hidden' ||
        (currentValue.config.kind == 'switch' &&
          currentValue.config.id == 'allow_numbering')
      ) {
        return true;
      } else if (
        configKinds.some((value) => value === currentValue.config.kind)
      ) {
        return (
          currentValue.value !== undefined && currentValue.value.length > 0
        );
      }
      return currentValue.value !== undefined &&
        convertAsaSingleString(
          convertToRaw(currentValue.value.getCurrentContent()).blocks
        ).length > 0 &&
        convertAsaSingleString(
          convertToRaw(currentValue.value.getCurrentContent()).blocks
        ).replace(/\s/g, '').length
        ? true
        : false;
    }

    if (currentNode.actionInputsList && currentNode.actionInputsList.output) {
      const AllrequiredFieldsHasValue = !seperateOptionalFields(
        currentNode.actionInputsList.output.input
          ? currentNode.actionInputsList.output.input
          : [],
        true
      ).every(hasValue);

      return !currentNode.selectedConnection.valid || AllrequiredFieldsHasValue;
    }

    return true;
  }

  function saveNode() {
    const updatedNodeValue = produce(
      actionPanelState.currentNode,
      (draftState) => {
        draftState.actionInputsList.output.input =
          currentNode.actionInputsList.output.input.map((y) => {
            const { inputHasValue, ...rest } = y;

            if (configKinds.some((kind) => kind == y.config.kind)) {
              return { ...rest };
            }

            const convertedValue = rest.value
              ? typeof rest.value == 'string'
                ? rest.value
                : convertAsaSingleString(
                  convertToRaw(rest.value.getCurrentContent()).blocks
                )
              : '';

            if (!rest.value || convertedValue.length === 0) {
              return ramda.omit(['value'])(rest) as InputFieldT;
            }
            const value = rest.value;
            const convertedBlocks =
              typeof value == 'string'
                ? value
                : convertToRaw(value.getCurrentContent())
                  .blocks.map((b) =>
                    convertToServerFormat2(
                      convertToRaw(value.getCurrentContent()).entityMap,
                      b
                    )
                  )
                  .reduce(
                    (acc, ele, i) =>
                      i == 0 ? (acc += ele) : (acc += '\n' + ele),
                    ''
                  );

            return {
              ...rest,
              // value: convertToServerFormat(value as any)
              value: convertedBlocks,
            } as InputFieldT;
          });
      }
    );

    saveTree(updatedNodeValue);
    setAutomationMetaType('trigger');
  }

  const SidePanelContextValue = useContext(SidePanelContext);

  const renderWarningModal = () => {
    if (showWarningModal.show) {
      return (
        <Modal showPopupLoader={false}>
          <React.Fragment>
            <h2>Do you want to continue?</h2>
            <p>Changing the type will affect your workflow model.</p>
          </React.Fragment>

          <ButtonWrapper style={{ marginTop: 5 }}>
            <Button
              className="btn btn-primary variable_update"
              type="button"
              data-dismiss="modal"
              onClick={() => {
                updateTrigger(true)
              }}
            >
              Yes
            </Button>
            <Button
              className="btn btn-success variable_update"
              type="button"
              data-dismiss="modal"
              onClick={() => {
                setShowWarningModal({ show: false });
              }}
            // style={{background: '#fff', border: '1px solid rgb(185, 185, 185)', color: 'rgb(51, 51, 51)'}}
            >
              No
            </Button>
          </ButtonWrapper>
        </Modal>
      );
    } else return null;
  };

  const updateTrigger = (fromWarningModal?: boolean) => {
    if (getProductId() === 'ASSISTANT') {
      if (SidePanelContextValue.automationMeta.type != null && !fromWarningModal) {
        setShowWarningModal({ show: true })
      } else {
        const newTree = editTreeData.tree;

        setRightPanelState({
          ...rightPanelState,
          show: true,
          currentTrigger: 'no_trigger',
          currentNode: NoTriggerInitialState,
          mode: {
            ...NodeEdit,
            nodeEditInput: {
              ...Tree,
              id: newTree.value.id,
              name: 'Triggered by Chatbots',
              kind: 'NoTrigger',
              output: { properties: {} },
              icon: NoTriggerIcon,
              meta_data: {},
            },
          },
        });
        const updatedTree =
          newTree.value.meta_data &&
            newTree.value.meta_data.continueIfError
            ? deleteErrorHandlingNodes(
              newTree.value.id,
              newTree.children[0].value,
              newTree
            )
            : newTree;
        setEditTreeData({
          ...editTreeData,
          tree: {
            ...updatedTree,
            value: {
              ...Tree,
              id: newTree.value.id,
              name: 'Triggered by Chatbot',
              kind: 'NoTrigger',
              output: { properties: {} },
              icon: NoTriggerIcon,
              meta_data: {},
            },
          },
        });
      }
    } else {
      if (treeData.tree.value.meta_data && !fromWarningModal) {
        setShowWarningModal({ show: true })
      } else {
        const newTree = editTreeData.tree;

        setRightPanelState({
          ...rightPanelState,
          show: true,
          currentTrigger: 'webhook',
          currentNode: WebhookInitialState,
          mode: {
            ...NodeEdit,
            nodeEditInput: {
              ...Tree,
              id: newTree.value.id,
              name: 'Triggered by Webhook',
              kind: 'WebhookTrigger',
              output: { properties: {} },
              icon: WebhookIcon,
              meta_data: {},
            },
          },
        });
        const updatedTree =
          newTree.value.meta_data &&
            newTree.value.meta_data.continueIfError
            ? deleteErrorHandlingNodes(
              newTree.value.id,
              newTree.children[0].value,
              newTree
            )
            : newTree;
        setEditTreeData({
          ...editTreeData,
          tree: {
            ...updatedTree,
            value: {
              ...Tree,
              id: newTree.value.id,
              name: 'Triggered by Webhook',
              kind: 'WebhookTrigger',
              output: { properties: {} },
              icon: WebhookIcon,
              meta_data: {},
            },
          },
        });
      }
    }
  }

  // to show loading whenever mode changed
  if (loading) {
    return <Loader.PopupLoader show={true} />;
  } else if (
    actionPanelState.mode.mode === NodeMode.Error ||
    customAuthState.error
  ) {
    return (
      <div className="flow_canvas_popup_error">
        <Error.AddorEdit
          info={
            actionPanelState.mode.mode === NodeMode.Error
              ? actionPanelState.mode.error
              : customAuthState.error
          }
          onClick={() => {
            customAuthState.error
              ? getCustomAuthenticationType(
                currentNode.selectedApp.id,
                actionPanelState
              )
              : cancelCurrentPopup({
                actionPanelState,
                SidePanelContext: SidePanelContextValue,
              });
          }}
          buttonName={customAuthState.error ? 'Retry' : 'Okay'}
        />
      </div>
    );
  }

  // for showing rightpanel popup minize arrow when popup minimized
  if (rightPanelState.show && !actionPanelState.show)
    return (
      <CanvasRightPanel.ToggleArrow
        showDropDown={actionPanelState}
        showOrHidePanel={setActionPanelState}
      />
    );

  const { state, ...rest } = props;

  return (
    <React.Fragment>
      {/* for showing rightpanel popup minize arrow when popup is maximized */}
      {renderWarningModal()}
      {/* <div className="action_popup"> */}
      {/* <Wrapper> */}
      {/* <EmptyDiv> */}
      <InputWrapper>
        <div className="select_application">
          <div className="select_work_form">
            {/* <h4>{'Select a starting point'}</h4> */}
            <InputContainer className="automation_connection">
              <CanvasRightPanel.AppsDropDown
                inputs={{
                  inputs: [
                    {
                      name: 'Application Trigger',
                      value: 'application',
                      icon: ApptriggerIconNew,
                    },
                    getProductId() === 'ASSISTANT' ? {
                      name: 'Triggered by Chatbot',
                      value: 'no_trigger',
                      icon: NoTriggerIcon,
                    } : {
                      name: 'Triggered by Webhook',
                      value: 'webhook',
                      icon: WebhookIcon,
                    },
                  ],
                  key: 'name',
                  idKey: 'value',
                  showIcon: true,
                }}
                addDropDown
                updateSelection={(
                  selectedOperatorValue: 'application' | 'webhook' | 'no_trigger',
                  id: string
                ) => {
                  (id === 'no_trigger' || id === 'webhook') && updateTrigger()
                }}
                render={(makeDropDownVisible: () => void) => (
                  <React.Fragment>
                    <InputBox
                      type="text"
                      value={'Triggered by Application'}
                      onClick={makeDropDownVisible}
                      onChange={() => { }}
                      style={{ paddingLeft: 35, color: 'transparent', textShadow: '0 0 0 #000' }}
                      className={!active_automation ? "" : "editor_btn_disabled"}
                    />
                    <InputContent>Select trigger type</InputContent>

                    <CancelButtonInput rotate onClick={makeDropDownVisible}>
                      <DownarrowWithCircle />
                    </CancelButtonInput>

                    <div className="input_img_left">
                      <img src={ApptriggerIconNew} />
                    </div>
                  </React.Fragment>
                )}
              />
            </InputContainer>
            <p></p>
          </div>
        </div>
        <AppTriggerPanel.SelectApplication {...props} />
      </InputWrapper>

      {currentNode.selectedAction.id &&
        currentNode.selectedAction.id.length > 0 && (
          <AppTriggerPanel.ConnectAccount {...props} />
        )}

      {/* {currentNode.selectedConnection.type == ConnectionTypeT.old &&
                          currentNode.selectedConnection.valid &&
                          currentNode.selectedAction.type == 'trigger' && (
                              <AppTriggerPanel.SelectInterval
                                  {...props}
                                  showInterval={currentNode.selectedAction.id && currentNode.selectedAction.id.length > 0}
                              />
                          )} */}
      {currentNode.selectedConnection.type == ConnectionTypeT.old &&
        currentNode.selectedConnection.valid &&
        currentNode.actionInputsList.output.input.length > 0 && (
          <React.Fragment>
            <div className='trigged_popup_select'>
              <CommonInputs
                state={actionPanelState}
                setContextPopupValues={setContextPopupValues}
                contextPopupValues={contextPopupValues}
                setPanelState={setActionPanelState}
                title="Configure Trigger"
                description="Select/Fill the required fields to start the trigger."
                inputsList={
                  currentNode.actionInputsList &&
                    currentNode.actionInputsList.output
                    ? currentNode.actionInputsList.output.input
                    : []
                }
                workspacename={props.match.params.workspacename}
                {...rest}
              />
              {/* <CanvasRightPanelStep2.ErrorHandling /> <CanvasRightPanelStep2.ActionInfo />*/}
              <AppTriggerPanel.ErrorHandling />
            </div>
          </React.Fragment>
        )}
      {/* </EmptyDiv> */}
      {/* </Wrapper> */}
      <ButtonContainer className="footer_button_fixed">
        <Button
          className="automation_condition_button_btn cancel_btn"
          onClick={() => {
            cancelCurrentPopup({
              actionPanelState,
              SidePanelContext: SidePanelContextValue,
            });
          }}
          style={{ display: treeData.firstStepDone ? 'block' : 'none' }}
        >
          Cancel
        </Button>
        {!active_automation && (
          <Button
            className={
              disableSave()
                ? 'automation_condition_button_btn editor_btn_disabled'
                : 'automation_condition_button_btn'
            }
            onClick={() => {
              if (
                treeData &&
                treeData.tree &&
                treeData.tree.children[0].children.length > 0
              ) {
                props.showWarningModal(() => setShowOutputTest(true));
              } else {
                setShowOutputTest(true)
              }
            }}
            disabled={disableSave()}
          >
            Test
          </Button>
        )}
      </ButtonContainer>
      {showOutputTest &&
        <WorkflowOutputTest
          {...props}
          saveNode={saveNode}
          flowtype={
            'trigger'
          }
          automationId={props.match.params.id}
          workspaceName={props.match.params.workspacename}
          actionPanelState={actionPanelState}
          tree={treeData.tree}
          webhookTrigger={
            treeData.tree.value.kind == "WebhookTrigger"
          }
          goBack={() => setShowOutputTest(false)}
          setActionPanelState={setActionPanelState}
          eventTrigger={
            currentNode.selectedAction.type == "event_trigger"
          }
          treeData={treeData.tree}
          fromAction={false}
        />
      }
      {/* <div className="automation_condition_button">
                    <div className="automation_condition_button_center">
                        <button
                            className="automation_condition_button_btn"
                            onClick={() => {
                                cancelCurrentPopup({
                                    actionPanelState,
                                    SidePanelContext: SidePanelContextValue
                                })
                            }}
                            style={{ display: treeData.firstStepDone ? 'block' : 'none' }}
                        >
                            Cancel
                        </button>
                        {!active_automation && (
                            <button
                                className={
                                    disableSave()
                                        ? 'automation_condition_button_btn editor_btn_disabled'
                                        : 'automation_condition_button_btn'
                                }
                                onClick={() => {
                                    if (treeData && treeData.tree && treeData.tree.children[0].children.length > 0) {
                                        props.showWarningModal(saveNode)
                                    } else {
                                        saveNode()
                                    }
                                }}
                                disabled={disableSave()}
                            >
                                Save
                            </button>
                        )}
                    </div>
                </div> */}
      {/* </div> */}

      {/* {contextPopupValues.show && (
				<ContextPopup
					{...props}
					mode={rightPanelState.mode}
					parentNode={rightPanelState.parentNode}
					actionAppsList={currentNode.appsList}
					triggerNode={treeData.tree.value}
					updateInputValuesWithContext={updateInputValuesWithContext}
					closeContextPopup={() =>
						setContextPopupValues({ ...contextPopupValues, show: false, currentInput: '' })}
				/>
			)} */}
    </React.Fragment>
  );
};

function cancelCurrentPopup(props: {
  actionPanelState: AppTriggerPanelStateT;
  SidePanelContext: RightPanelStateContext;
}) {
  const { actionPanelState, SidePanelContext } = props;

  const {
    rightPanelState,
    setRightPanelState,
    treeData,
    setEditTreeData,
    editTreeData,
  } = SidePanelContext;

  const currentNode = actionPanelState.currentNode;

  const tree = actionPanelState.parentNode.children.filter(
    (x) => x.value.kind === 'ParallelPath'
  );

  setRightPanelState({
    ...rightPanelState,
    show: false,
    mode: NodeView,
    parentNode: { value: Tree, children: [] },

    // to set first step done in left side panel while cancel
    currentNode: ActionInitialState,
  });

  setEditTreeData(treeData);
}

// const Wrapper = (props: any) => (
//     <div className="automation_canvas_right_popup">
//         <div className="automation_canvas_right_popup_form">
//             <div className="automation_rightside_form automation_rightside_step_two">{props.children}</div>
//         </div>
//     </div>
// )

function grouping(array: any, key: string) {
  if (!array || array.length === 0) {
    return [];
  }

  const filteredValue = ramda.groupBy((x: any) => x[key])(array);

  return convertObjectToArray(filteredValue, true);
}

function convertObjectToArray(object: object, appId: boolean) {
  if (!object) return [];
  if (appId) {
    return Object.keys(object).map(function(key) {
      return {
        name: key,
        appId: object[key].length > 0 ? object[key][0].appId : '',
        value: object[key],
      };
    });
  } else {
    return Object.keys(object).map(function(key) {
      return { name: key, value: object[key] };
    });
  }
}

function filterAction(array: any[], selectedAppId: string) {
  if (array.length === 0) return [];
  return array.filter((x: any) => x.appId === selectedAppId).length > 0
    ? array.filter((x: any) => x.appId === selectedAppId)[0].value
    : [];
}

AppTriggerPanel.SelectInterval = (props: any) => {
  const { actionPanelState, setActionPanelState } = useContext(
    ActionPanelStateContext
  );

  const currentNode = actionPanelState.currentNode as AppTriggerResponseT;

  return (
    <InputWrapper className="automation_connection">
      <div className="select_application">
        <div className="select_work_form">
          <h4>Time Interval</h4>
          <InputContainer>
            <CanvasRightPanel.AppsDropDown
              inputs={{
                inputs: [
                  {
                    interval: 'Every 30 minutes',
                    intervalName: 'Every 30 minutes',
                  },
                ],
                key: 'intervalName',
                idKey: 'interval',
              }}
              addDropDown={false}
              render={(makeDropDownVisible: () => void) => (
                <React.Fragment>
                  <InputBox
                    type="text"
                    value={'Every 30 minutes'}
                    onClick={makeDropDownVisible}
                    onChange={() => { }}
                  />
                  <InputContent>Select interval</InputContent>

                  <CancelButtonInput rotate onClick={makeDropDownVisible}>
                    <DownarrowWithCircle />
                  </CancelButtonInput>
                </React.Fragment>
              )}
            />
          </InputContainer>
        </div>
      </div>
    </InputWrapper>
  );
};

AppTriggerPanel.SelectApplication = (_: any) => {
  const { actionPanelState, setActionPanelState, setUserSwitchedApp } =
    useContext(ActionPanelStateContext);

  const currentNode = actionPanelState.currentNode as AppTriggerResponseT;

  const { active_automation } = useContext(SidePanelContext)

  const filteredResponse: any = grouping(
    currentNode.appsList.output,
    'appName'
  );

  const [search, setSearch] = useState({
    searching: false,
    key: '',
    inFocus: false,
  });

  const apps_ref = useRef(null);

  return (
    <React.Fragment>
      <div className="select_application triggered_select_application">
        <div className="select_work_form">
          <InputContainer>
            <CanvasRightPanel.AppsDropDown
              inputs={{
                inputs: [
                  // DefaultSelectedValue,
                  ...(search.searching
                    ? sortAnArrayAlphabetically(
                      filteredResponse,
                      'name'
                    ).filter(
                      (app: any) =>
                        app.name
                          .toLowerCase()
                          .indexOf(search.key.toLowerCase()) > -1
                    )
                    : sortAnArrayAlphabetically(filteredResponse, 'name')),
                ],
                key: 'name',
                idKey: 'appId',
                showIcon: true,
              }}
              addDropDown
              closeCallBack={() => {
                !search.inFocus && setSearch({ ...search, searching: false });
              }}
              updateSelection={(selectedNewApp: string, id: string) => {
                if (NodeMode.Add === actionPanelState.mode.mode) {
                  const nextState = produce(currentNode, (draftState) => {
                    draftState.selectedApp = {
                      name: selectedNewApp,
                      id: id,
                      type: id
                        ? currentNode.appsList.output.filter(
                          (x) => x.appId === id && x.latest
                        )[0].authentication_type
                        : AuthType.None,
                      icon: id
                        ? currentNode.appsList.output.filter(
                          (x) => x.appId === id
                        )[0].icon
                        : '',
                      version: id
                        ? currentNode.appsList.output.filter(
                          (x) => x.appId === id && x.latest
                        )[0].version
                        : '',
                    };
                    draftState.selectedAction =
                      AppTriggerInitialState.selectedAction;

                    let connection =
                      ActionInitialState.selectedConnection as NewConnectionT;

                    connection.connectionsDetails = getInitialConnectionValue(
                      currentNode.appsList.output.filter(
                        (x) => x.appId === id && x.latest
                      )[0].authentication_type
                    );

                    connection = {
                      ...connection,
                      selectedConnection: 'Connect your application account',
                      id: '',
                      hasError: null,
                      valid: null,
                      connectionEditing: false,
                    };

                    draftState.selectedConnection = connection;
                    draftState.actionInputsList =
                      ActionInitialState.actionInputsList;

                    draftState.selectedPollingTime = _._pollingTimeAccess ? parseInt(_._pollingTimeAccess) : 0
                  });

                  setActionPanelState({
                    ...actionPanelState,
                    currentNode: nextState,
                  });
                } else if (
                  selectedNewApp !== 'Select' &&
                  id !== currentNode.selectedApp.id
                ) {
                  const mode = actionPanelState.mode as NodeEditT

                  const nextState = produce(
                    AppTriggerInitialState,
                    (draftState) => {
                      draftState.appsList.output = currentNode.appsList.output;
                      draftState.selectedApp = {
                        name: selectedNewApp,
                        id: id,
                        type: id
                          ? currentNode.appsList.output.filter(
                            (x) => x.appId === id && x.latest
                          )[0].authentication_type
                          : AuthType.None,
                        icon: id
                          ? currentNode.appsList.output.filter(
                            (x) => x.appId === id
                          )[0].icon
                          : '',
                        version: id
                          ? currentNode.appsList.output.filter(
                            (x) => x.appId === id && x.latest
                          )[0].version
                          : '',
                      };

                      draftState.selectedAction =
                        AppTriggerInitialState.selectedAction;

                      let connection =
                        ActionInitialState.selectedConnection as NewConnectionT;

                      connection.connectionsDetails = getInitialConnectionValue(
                        currentNode.appsList.output.filter(
                          (x) => x.appId === id && x.latest
                        )[0].authentication_type
                      );

                      connection = {
                        ...connection,
                        selectedConnection: 'Connect your application account',
                        id: '',
                        hasError: null,
                        valid: null,
                        connectionEditing: false,
                      };

                      draftState.selectedConnection = connection;

                      draftState.actionInputsList =
                        ActionInitialState.actionInputsList;

                      draftState.selectedPollingTime = mode.nodeEditInput.interval ? mode.nodeEditInput.interval : _._pollingTimeAccess ? parseInt(_._pollingTimeAccess) : 0
                    }
                  );

                  setActionPanelState({
                    ...actionPanelState,
                    currentNode: nextState,
                  });

                  setUserSwitchedApp(true);
                }
              }}
              render={(makeDropDownVisible: () => void) => (
                <React.Fragment>
                  <InputBox
                    type="text"
                    value={
                      search.searching
                        ? search.key
                        : currentNode.selectedApp.name
                    }
                    onFocus={() => {
                      setSearch({
                        inFocus: true,
                        searching: true,
                        key:
                          (NodeMode.Edit == actionPanelState.mode.mode &&
                            currentNode.selectedApp.name.toLowerCase() !==
                            'select') ||
                            (currentNode.selectedApp.name.length > 0 &&
                              currentNode.selectedApp.name.toLowerCase() !==
                              'select')
                            ? currentNode.selectedApp.name
                            : '',
                      });
                    }}
                    onBlur={() => {
                      setSearch({
                        ...search,
                        inFocus: false,
                      });
                    }}
                    onClick={() => makeDropDownVisible()}
                    onChange={(e: any) => {
                      setSearch({ ...search, key: e.target.value });
                    }}
                    style={{
                      paddingLeft:
                        currentNode.selectedApp.name === 'Select' ? 10 : 35,
                    }}
                    ref={apps_ref}
                    className={!active_automation ? "" : "editor_btn_disabled"}
                  />
                  {/* <InputContent>Select application
                    <Tooltip
                      className="target_bot"
                      tipContentHover
                      zIndex={5000}
                      arrowSize={5}
                      content={''}
                  >
                      <span>
                          <InfoIcon />
                      </span>
                  </Tooltip>
                  </InputContent> */}
                  <CancelButtonInput
                    rotate
                    onClick={() => {
                      const ref = apps_ref.current;

                      if (ref) {
                        setSearch({
                          inFocus: true,
                          searching: true,
                          key:
                            NodeMode.Edit == actionPanelState.mode.mode ||
                              (currentNode.selectedApp.name.length > 0 &&
                                currentNode.selectedApp.name.toLowerCase() !==
                                'select')
                              ? currentNode.selectedApp.name
                              : '',
                        });

                        const iRef = ref as HTMLInputElement;
                        iRef.focus();
                        makeDropDownVisible();
                      }
                    }}
                  >
                    <DownarrowWithCircle />
                  </CancelButtonInput>
                  <InputContent>
                    Select application
                    {/* <Tooltip
                          className="target customTip _description"
                          zIndex={10000}
                          tagName="span"
                          content="Select the application to create trigger"
                          distance={15}
                          forceDirection={true}
                          direction="up-start"
                      >
                          <InfoIcon />
                      </Tooltip> */}
                  </InputContent>
                  {currentNode.selectedApp.name !== 'Select' && (
                    <div className="input_img_left">
                      <img src={currentNode.selectedApp.icon} />
                    </div>
                  )}
                </React.Fragment>
              )}
            />
          </InputContainer>
        </div>
      </div>

      {currentNode.selectedApp.name !== 'Select' && (
        <div className="automation_global_form triggered_select_application">
          <div className="select_work_form">
            <InputContainer style={{ marginBottom: 0, marginTop: 20 }}>
              <CanvasRightPanel.AppsDropDown
                inputs={{
                  inputs: [
                    // { action: 'Select' },
                    ...filterAction(
                      filteredResponse,
                      currentNode.selectedApp.id
                    ),
                  ],
                  key: 'action',
                  idKey: 'id',
                }}
                addDropDown
                showVersion
                updateSelection={(selectedNewApp: string, id: string) => {
                  if (NodeMode.Add === actionPanelState.mode.mode) {
                    const nextState = produce(currentNode, (draftState) => {
                      draftState.selectedAction = {
                        ...draftState.selectedAction,
                        name: selectedNewApp,
                        id: id,
                        description:
                          selectedNewApp +
                          ' using ' +
                          currentNode.selectedApp.name,
                      };

                      let connection =
                        ActionInitialState.selectedConnection as NewConnectionT;

                      // to change connection type based on app type
                      // no need to change custom auth state while changing action cuz hitting api only changing application
                      connection.connectionsDetails = getInitialConnectionValue(
                        currentNode.selectedApp.type,
                        {
                          fromAction: true,
                          currentNode: actionPanelState.currentNode,
                        }
                      );

                      connection = {
                        ...connection,
                        selectedConnection: 'Connect your application account',
                        id: '',
                        hasError: null,
                        valid: null,
                        connectionEditing: false,
                      };

                      draftState.selectedConnection = connection;
                    });

                    setActionPanelState({
                      ...actionPanelState,
                      currentNode: nextState,
                    });
                  } else if (
                    selectedNewApp !== 'Select' &&
                    id !== currentNode.selectedAction.id
                  ) {
                    const nextState = produce(
                      currentNode,
                      (draftState) => {
                        draftState.appsList.output =
                          currentNode.appsList.output;
                        // draftState.selectedApp = currentNode.selectedApp;
                        draftState.selectedApp = {
                          ...currentNode.selectedApp,
                          type: id
                            ? currentNode.appsList.output.filter(
                              (x) =>
                                x.appId === currentNode.selectedApp.id &&
                                x.latest
                            )[0].authentication_type
                            : AuthType.None,
                          version: id
                            ? currentNode.appsList.output.filter(
                              (x) =>
                                x.appId === currentNode.selectedApp.id &&
                                x.latest
                            )[0].version
                            : '',
                        };

                        draftState.actionInputsList.output.input =
                          currentNode.actionInputsList.output.input.map((x) => {
                            const value = x.value;
                            if (
                              value &&
                              (typeof value == 'string'
                                ? value.length > 0
                                : convertAsaSingleString(
                                  convertToRaw(value.getCurrentContent())
                                    .blocks
                                ).replace(/\s/g, '').length)
                            ) {
                              return {
                                ...x,
                                value: '',
                              };
                            }
                            return x;
                          });
                        draftState.connections = currentNode.connections;
                        draftState.selectedAction = {
                          name: selectedNewApp,
                          id: id,
                          description:
                            selectedNewApp +
                            ' using ' +
                            currentNode.selectedApp.name,
                          type: filterAction(
                            filteredResponse,
                            currentNode.selectedApp.id
                          ).filter((a: any) => a.id == id)[0].type,
                        };

                        let connection =
                          ActionInitialState.selectedConnection as NewConnectionT;

                        // to change connection type based on app type
                        // no need to change custom auth state while changing action cuz hitting api only changing application
                        // connection.connectionsDetails = getInitialConnectionValue(
                        // 	currentNode.selectedApp.type,
                        // 	{ fromAction: true, currentNode: actionPanelState.currentNode }
                        // );

                        const currentAuthType =
                          currentNode.appsList.output.filter(
                            (x) =>
                              x.appId === currentNode.selectedApp.id && x.latest
                          )[0].authentication_type;

                        currentNode.selectedApp.type === currentAuthType
                          ? (connection.connectionsDetails =
                            getInitialConnectionValue(
                              currentNode.selectedApp.type,
                              {
                                fromAction: true,
                                currentNode: actionPanelState.currentNode,
                              }
                            ))
                          : (connection.connectionsDetails =
                            getInitialConnectionValue(
                              currentNode.appsList.output.filter(
                                (x) =>
                                  x.appId === currentNode.selectedApp.id &&
                                  x.latest
                              )[0].authentication_type
                              // { fromAction: true, currentNode: actionPanelState.currentNode }
                            ));

                        connection = {
                          ...connection,
                          selectedConnection: 'Add a new Account',
                          id: '',
                          hasError: null,
                          valid: null,
                          connectionEditing: false,
                        };

                        draftState.selectedConnection = connection;
                      }
                    );

                    setActionPanelState({
                      ...actionPanelState,
                      currentNode: nextState,
                    });
                    setUserSwitchedApp(true);
                  }
                }}
                render={(makeDropDownVisible: () => void) => (
                  <React.Fragment>
                    <InputBox
                      type="text"
                      value={currentNode.selectedAction.name}
                      onClick={makeDropDownVisible}
                      onChange={() => { }}
                      className={!active_automation ? "without_caret_input" : "without_caret_input editor_btn_disabled"}

                    />
                    {currentNode.selectedAction.name != 'Select' && (
                      <>
                        {currentNode.selectedAction.type == 'trigger' ? (
                          <label className="timer_icon_dropdown">
                            <TimerIcon />
                          </label>
                        ) : null}
                        <span className="application_input_version">
                          Version :{' '}
                          {parseInt(
                            currentNode.selectedApp.version.split('v')[1]
                          ) < 10
                            ? '0' +
                            currentNode.selectedApp.version.split('v')[1]
                            : currentNode.selectedApp.version.split('v')[1]}
                        </span>
                      </>
                    )}
                    <CancelButtonInput
                      rotate
                      onClick={() => {
                        makeDropDownVisible();
                      }}
                    >
                      <DownarrowWithCircle />
                    </CancelButtonInput>
                    <InputContent>
                      Select an event that triggers this workflow
                      {/* <Tooltip
                            className="target customTip _description"
                            zIndex={10000}
                            tagName="span"
                            content="Select the Event that triggers this Automation"
                            distance={15}
                            forceDirection={true}
                            direction="up-start"
                        >
                            <InfoIcon />
                        </Tooltip> */}
                    </InputContent>
                  </React.Fragment>
                )}
              />
            </InputContainer>
          </div>
        </div>
      )}

      {currentNode.selectedAction.id.length > 0 && currentNode.selectedAction.type == 'trigger' && (
        <div className="automation_global_form triggered_select_application">
          <div className="select_work_form">
            <InputContainer style={{ marginBottom: 0, marginTop: 20 }}>
              <CanvasRightPanel.AppsDropDown
                inputs={{
                  inputs: minutes.filter((h) => h >= _._pollingTimeAccess).map((h, i) => ({ name: `${h} ${h < 2 ? " Min" : " Mins"}`, value: h })),
                  key: 'name',
                  idKey: 'value',
                }}
                addDropDown
                updateSelection={(selectedNewApp: string, id: number) => {
                  const nextState = produce(
                    currentNode,
                    (draftState) => {
                      draftState.selectedPollingTime = id
                    }
                  );

                  setActionPanelState({
                    ...actionPanelState,
                    currentNode: nextState,
                  });

                }}
                render={(makeDropDownVisible: () => void) => (
                  <React.Fragment>
                    <InputBox
                      type="text"
                      value={currentNode.selectedPollingTime + `${currentNode.selectedPollingTime === 1 ? " Min" : " Mins"}`}
                      onClick={makeDropDownVisible}
                      onChange={() => { }}
                      className={!active_automation ? "without_caret_input" : "without_caret_input editor_btn_disabled"}
                    />
                    <CancelButtonInput
                      rotate
                      onClick={() => {
                        makeDropDownVisible();
                      }}
                    >
                      <DownarrowWithCircle />
                    </CancelButtonInput>
                    <InputContent>
                      Check for the event every
                    </InputContent>
                  </React.Fragment>
                )}
              />
            </InputContainer>
          </div>
        </div>
      )}
      {/* </div> */}
    </React.Fragment>
  );
};

function seperateOptionalFields(array: InputFieldT[], required: boolean) {
  return array.filter((input: InputFieldT) => {
    return required ? input.required : !input.required;
  });
}

AppTriggerPanel.ConnectAccount = (props: any) => {
  const { actionPanelState, setActionPanelState } = useContext(
    ActionPanelStateContext
  );
  const { active_automation } = useContext(SidePanelContext)
  const [setToDeleteConnection] = props.deleteConnection;

  const currentNode = actionPanelState.currentNode as AppTriggerResponseT;

  const workspaceName = props.match.params.workspacename;

  function enableConnectButton() {
    const selectedConnection = currentNode.selectedConnection as NewConnectionT;
    const connectionsDetails =
      selectedConnection.connectionsDetails.value.meta_data;
    const convertedArray = convertObjectToArray(connectionsDetails, false);

    const enable = convertedArray.filter((meta) => {
      return meta.value.length > 0;
    });

    let isCustomAuthValid: any = undefined;

    if (
      currentNode.selectedApp.type == AuthType.Custom ||
      currentNode.selectedApp.type == AuthType.CustomOauth || currentNode.selectedApp.type == AuthType.PrivateOauth
    ) {
      const connections = selectedConnection.connectionsDetails as CustomAuthT;

      isCustomAuthValid =
        connections.customInputs.inputs.filter((inp) => {
          const inpValue = inp.value;
          return (
            inpValue &&
            (typeof inpValue == 'string'
              ? inpValue.length > 0
              : convertAsaSingleString(
                convertToRaw(inpValue.getCurrentContent()).blocks
              ).replace(/\s/g, '').length)
          );
        }).length == connections.customInputs.inputs.length;
    }

    return (
      (enable.length === convertedArray.length ||
        selectedConnection.connectionsDetails.type === AuthType.Oauth) &&
      selectedConnection.connectionsDetails.value.name.length > 0 &&
      (isCustomAuthValid == undefined || isCustomAuthValid)
    );
  }

  function enableRemoveButton() {
    return currentNode.selectedConnection.type !== ConnectionTypeT.new;
  }

  const createConnection = (actionPanelStat: AppTriggerPanelStateT) => {
    const selectedConnection = actionPanelStat.currentNode
      .selectedConnection as NewConnectionT;

    setActionPanelState({
      ...actionPanelStat,
      currentNode: {
        ...currentNode,
        selectedConnection: {
          ...currentNode.selectedConnection,
          loading: true,
        },
      },
    });

    let req = selectedConnection.connectionsDetails.value;

    if (currentNode.selectedApp.type == AuthType.Custom) {
      const connection = currentNode.selectedConnection
        .connectionsDetails as CustomAuthT;
      req = {
        ...req,
        meta_data: ramda.mergeAll(
          connection.customInputs.inputs.map(function(o) {
            return {
              [o.key]: o.value,
            };
          })
        ),
      };
    }

    postJson(
      automationAPIURLWithWorkspace(workspaceName, '/connection/create')
    )(req)
      .then((response) => {
        if (!response.data.error) {
          testingConnectionWithAPI(
            actionPanelStat,
            response.data.output.authStoreMap
          ).then((resp) => {
            if (
              resp.response &&
              resp.response.data &&
              resp.response.data.error &&
              !resp.response.data.credentials
            ) {
              const nextState = produce(
                actionPanelState.currentNode,
                (draftState) => {
                  let connection = draftState.selectedConnection;

                  connection.selectedConnection =
                    draftState.selectedConnection.selectedConnection;
                  connection.valid = false;
                  connection.loading = false;
                  connection.hasError =
                    resp.response &&
                      resp.response.data &&
                      resp.response.data.error
                      ? resp.response.data.error
                      : 'Something went wrong! please try again.';
                  connection.id = draftState.selectedConnection.id;
                  draftState.selectedConnection = connection;
                }
              );

              setActionPanelState({
                ...actionPanelState,
                currentNode: nextState,
              });
            } else {
              if (resp.data) {
                let nextState = produce(currentNode, (draftState) => {
                  let connection =
                    draftState.selectedConnection as OldConnectionT;

                  draftState.connections.output =
                    draftState.connections.output.concat([
                      {
                        authStoreMap: response.data.output.authStoreMap,
                        name: selectedConnection.connectionsDetails.value.name,
                      },
                    ]);

                  draftState.selectedConnection.type = ConnectionTypeT.old;

                  connection.connectionsDetails = draftState.selectedConnection
                    .connectionsDetails as NewConnectionTypeT;

                  connection = {
                    ...connection,
                    selectedConnection:
                      selectedConnection.connectionsDetails.value.name,
                    id: response.data.output.authStoreMap,
                    valid: true,
                    hasError: null,
                    loading: false,
                  };

                  draftState.selectedConnection = connection;
                });
                setActionPanelState({
                  ...actionPanelStat,
                  currentNode: nextState,
                });
              } else {
                let nextState = produce(
                  actionPanelStat.currentNode,
                  (draftState) => {
                    const connection = NewConnection as NewConnectionT;

                    draftState.connections.output =
                      draftState.connections.output.concat([
                        {
                          authStoreMap: response.data.output.authStoreMap,
                          name: selectedConnection.connectionsDetails.value
                            .name,
                        },
                      ]);

                    const isCustomAuth =
                      currentNode.selectedApp.type == AuthType.Custom ||
                      currentNode.selectedApp.type == AuthType.CustomOauth || currentNode.selectedApp.type == AuthType.PrivateOauth
                        ? {
                          fromAction: true,
                          currentNode: actionPanelState.currentNode,
                        }
                        : undefined;

                    connection.connectionsDetails = getInitialConnectionValue(
                      draftState.selectedApp.type,
                      isCustomAuth
                    );

                    connection.selectedConnection =
                      selectedConnection.connectionsDetails.value.name;

                    connection.valid = false;
                    connection.loading = false;

                    connection.connectionEditing = true;

                    connection.hasError =
                      resp.response || resp.data
                        ? resp.response.data.error
                        : resp.data.error
                          ? resp.response.data.error
                          : 'Something went wrong! please try again.';

                    connection.id = response.data.output.authStoreMap;

                    if (!isCustomAuth) {
                      connection.connectionsDetails.value = {
                        name: selectedConnection.connectionsDetails.value.name,
                        appId: draftState.selectedApp.id,
                        type: draftState.selectedApp.type,
                        workspace: workspaceName,
                        meta_data: resp.response.data.credentials,
                      };
                    } else {
                      const customAuthConnectionDetatils = produce(
                        connection.connectionsDetails as CustomAuthT,
                        (draft) => {
                          draft.customInputs.inputs =
                            resp.response.data.credentials;

                          draft.value = {
                            name: selectedConnection.connectionsDetails.value
                              .name,
                            appId: draftState.selectedApp.id,
                            type: draftState.selectedApp.type,
                            workspace: workspaceName,
                            meta_data: ramda.mergeAll(
                              resp.response.data.credentials.map(function(
                                o: InputFieldT
                              ) {
                                return {
                                  [o.key]: o.value,
                                };
                              })
                            ),
                          };
                        }
                      );
                      connection.connectionsDetails =
                        customAuthConnectionDetatils;
                    }

                    draftState.selectedConnection = connection;
                  }
                );

                setActionPanelState({
                  ...actionPanelStat,
                  currentNode: nextState,
                });
              }
            }
          });
        } else {
          setErrorOnConnectionAPIs(response, actionPanelStat);
        }
      })
      .catch((err) => {
        setErrorOnConnectionAPIs(err, actionPanelStat, true);
      });
  };

  const updateConnection = (
    actionPanelStat: AppTriggerPanelStateT,
    connectionId: string
  ) => {
    const selectedConnection = actionPanelStat.currentNode
      .selectedConnection as NewConnectionT;

    const { appId, ...rest } = selectedConnection.connectionsDetails.value;

    setActionPanelState({
      ...actionPanelStat,
      currentNode: {
        ...actionPanelStat.currentNode,
        selectedConnection: {
          ...actionPanelStat.currentNode.selectedConnection,
          loading: true,
        },
      },
    });

    let req = { ...rest, id: connectionId };

    if (currentNode.selectedApp.type == AuthType.Custom) {
      const connection = currentNode.selectedConnection
        .connectionsDetails as CustomAuthT;
      req = {
        ...req,
        meta_data: ramda.mergeAll(
          connection.customInputs.inputs.map(function(o) {
            return {
              [o.key]: o.value,
            };
          })
        ),
      };
    }

    putJson(
      automationAPIURLWithWorkspace(workspaceName, '/connection/update')
    )()(req)
      .then((response) => {
        if (!response.data.error) {
          let nextState = produce(currentNode, (draftState) => {
            let connection = oldConnection as OldConnectionT;

            draftState.connections.output = draftState.connections.output.map(
              (connect) => {
                if (connect.authStoreMap === connectionId)
                  return { ...connect, name: rest.name };
                return connect;
              }
            );

            connection.connectionsDetails = draftState.selectedConnection
              .connectionsDetails as NewConnectionTypeT;

            connection = {
              ...connection,
              selectedConnection: rest.name,
              id: connectionId,
              valid: true,
              hasError: null,
              loading: false,
            };

            draftState.selectedConnection = connection;
          });

          testingConnectionWithAPI(
            {
              ...actionPanelStat,
              currentNode: nextState,
            },
            connectionId
          ).then((resp) => {
            if (
              resp.response &&
              resp.response.data &&
              resp.response.data.error &&
              !resp.response.data.credentials
            ) {
              const nextState = produce(
                actionPanelState.currentNode,
                (draftState) => {
                  let connection = draftState.selectedConnection;

                  connection.selectedConnection =
                    draftState.selectedConnection.selectedConnection;
                  connection.valid = false;
                  connection.loading = false;
                  connection.hasError =
                    resp.response &&
                      resp.response.data &&
                      resp.response.data.error
                      ? resp.response.data.error
                      : 'Something went wrong! please try again.';
                  connection.id = draftState.selectedConnection.id;
                  draftState.selectedConnection = connection;
                }
              );

              setActionPanelState({
                ...actionPanelState,
                currentNode: nextState,
              });
            } else {
              if (resp.data) {
                setActionPanelState({
                  ...actionPanelStat,
                  currentNode: nextState,
                });
              } else {
                let nextState = produce(
                  actionPanelStat.currentNode,
                  (draftState) => {
                    const connection = NewConnection as NewConnectionT;

                    connection.connectionsDetails = getInitialConnectionValue(
                      draftState.selectedApp.type
                    );

                    connection.selectedConnection = rest.name;

                    connection.valid = false;
                    connection.loading = false;

                    connection.connectionEditing = true;

                    connection.hasError =
                      resp.response || resp.data
                        ? resp.response.data.error
                        : resp.data.error
                          ? resp.response.data.error
                          : 'Something went wrong! please try again.';

                    connection.id = connectionId;

                    const isCustomAuth =
                      currentNode.selectedApp.type == AuthType.Custom ||
                      currentNode.selectedApp.type == AuthType.CustomOauth || currentNode.selectedApp.type == AuthType.PrivateOauth
                        ? {
                          fromAction: true,
                          currentNode: actionPanelState.currentNode,
                        }
                        : undefined;

                    if (!isCustomAuth) {
                      connection.connectionsDetails.value = {
                        name: rest.name,
                        appId: draftState.selectedApp.id,
                        type: draftState.selectedApp.type,
                        workspace: workspaceName,
                        meta_data: resp.response.data.credentials,
                      };
                    } else {
                      const customAuthConnectionDetatils =
                        connection.connectionsDetails as CustomAuthT;

                      customAuthConnectionDetatils.customInputs.inputs =
                        resp.response.data.credentials;

                      customAuthConnectionDetatils.value = {
                        name: rest.name,
                        appId: draftState.selectedApp.id,
                        type: draftState.selectedApp.type,
                        workspace: workspaceName,
                        meta_data: ramda.mergeAll(
                          resp.response.data.credentials.map(function(
                            o: InputFieldT
                          ) {
                            return {
                              [o.key]: o.value,
                            };
                          })
                        ),
                      };

                      connection.connectionsDetails =
                        customAuthConnectionDetatils;
                    }

                    draftState.selectedConnection = connection;
                  }
                );

                setActionPanelState({
                  ...actionPanelStat,
                  currentNode: nextState,
                });
              }
            }
          });
        } else {
          setErrorOnConnectionAPIs(response, actionPanelStat);
        }
      })
      .catch((err) => {
        setErrorOnConnectionAPIs(err, actionPanelStat);
      });
  };

  function setErrorOnConnectionAPIs(
    resp: any,
    actionPanelState: AppTriggerPanelStateT,
    beforeConnectionCreateError?: boolean
  ) {
    let nextState = produce(actionPanelState.currentNode, (draftState) => {
      const connection = beforeConnectionCreateError
        ? (draftState.selectedConnection as NewConnectionT)
        : (Object.assign({}, NewConnection) as NewConnectionT);

      let isErrorPresent = false;
      let errorMsg;

      if (resp.response && resp.response.data && resp.response.data.error) {
        isErrorPresent = true;
        errorMsg = resp.response.data.error;
      }

      if (beforeConnectionCreateError && !isErrorPresent) {
        const isCustomAuth =
          currentNode.selectedApp.type == AuthType.Custom ||
          currentNode.selectedApp.type == AuthType.CustomOauth || currentNode.selectedApp.type == AuthType.PrivateOauth
            ? { fromAction: true, currentNode: actionPanelState.currentNode }
            : undefined;

        connection.connectionsDetails = getInitialConnectionValue(
          draftState.selectedApp.type,
          isCustomAuth
        );

        connection.selectedConnection =
          draftState.selectedConnection.selectedConnection;

        connection.connectionsDetails.value.name =
          draftState.selectedConnection.selectedConnection;

        connection.id = draftState.selectedConnection.id;
      }

      connection.valid = false;

      connection.loading = false;

      connection.hasError = errorMsg
        ? errorMsg
        : 'Something went wrong! please try again.';
      draftState.selectedConnection = connection;
    });

    setActionPanelState({
      ...actionPanelState,
      currentNode: nextState,
    });
  }

  function testingConnectionWithAPI(
    actionPanelStat: AppTriggerPanelStateT,
    authStoreMap: string
  ) {
    return postJson(
      automationAPIURLWithWorkspace(workspaceName, '/connection/connect')
    )({
      connectionId: authStoreMap,
      appId: actionPanelStat.currentNode.selectedApp.id,
      version: actionPanelStat.currentNode.selectedApp.version,
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  function testConnection(authStoreMap: string) {
    setActionPanelState({
      ...actionPanelState,
      currentNode: {
        ...currentNode,
        selectedConnection: {
          ...currentNode.selectedConnection,
          loading: true,
        },
      },
    });

    testingConnectionWithAPI(actionPanelState, authStoreMap).then((resp) => {
      if (
        resp.response &&
        resp.response.data &&
        resp.response.data.error &&
        !resp.response.data.credentials
      ) {
        const nextState = produce(
          actionPanelState.currentNode,
          (draftState) => {
            let connection = draftState.selectedConnection;

            connection.selectedConnection =
              draftState.selectedConnection.selectedConnection;
            connection.valid = false;
            connection.loading = false;
            connection.hasError =
              resp.response && resp.response.data && resp.response.data.error
                ? resp.response.data.error
                : 'Something went wrong! please try again.';
            connection.id = draftState.selectedConnection.id;
            draftState.selectedConnection = connection;
          }
        );

        setActionPanelState({
          ...actionPanelState,
          currentNode: nextState,
        });
      } else {
        if (resp.data) {
          const nextState = produce(currentNode, (draftState) => {
            const connection = draftState.selectedConnection as OldConnectionT;
            connection.valid = true;
            connection.hasError = null;
            connection.loading = false;
            draftState.selectedConnection = connection;
          });
          setActionPanelState({
            ...actionPanelState,
            currentNode: nextState,
          });
        } else {
          const nextState = produce(
            actionPanelState.currentNode,
            (draftState) => {
              const connection = NewConnection as NewConnectionT;

              const isCustomAuth =
                currentNode.selectedApp.type == AuthType.Custom ||
                currentNode.selectedApp.type == AuthType.CustomOauth || currentNode.selectedApp.type == AuthType.PrivateOauth
                  ? {
                    fromAction: true,
                    currentNode: actionPanelState.currentNode,
                  }
                  : undefined;

              connection.connectionsDetails = getInitialConnectionValue(
                draftState.selectedApp.type,
                isCustomAuth
              );

              connection.selectedConnection =
                draftState.selectedConnection.selectedConnection;

              connection.valid = false;
              connection.loading = false;

              connection.hasError =
                resp.response && resp.response.data && resp.response.data.error
                  ? resp.response.data.error
                  : 'Something went wrong! please try again.';

              connection.id = draftState.selectedConnection.id;

              if (!isCustomAuth) {
                connection.connectionsDetails.value = {
                  name: draftState.selectedConnection.selectedConnection,
                  appId: draftState.selectedApp.id,
                  type: draftState.selectedApp.type,
                  workspace: workspaceName,
                  meta_data: resp.response.data.credentials,
                };
              } else {
                connection.connectionsDetails = produce(
                  connection.connectionsDetails as CustomAuthT,
                  (draft) => {
                    draft.customInputs.inputs = resp.response.data.credentials;

                    draft.value = {
                      name: draftState.selectedConnection.selectedConnection,
                      appId: draftState.selectedApp.id,
                      type: draftState.selectedApp.type,
                      workspace: workspaceName,
                      meta_data: ramda.mergeAll(
                        resp.response.data.credentials.map(function(
                          o: InputFieldT
                        ) {
                          return {
                            [o.key]: o.value,
                          };
                        })
                      ),
                    };
                  }
                );
              }

              connection.connectionEditing = true;
              draftState.selectedConnection = connection;
            }
          );
          setActionPanelState({
            ...actionPanelState,
            currentNode: nextState,
          });
        }
      }
    });
  }

  function populateCustomAuthUI() {
    const selectedConnection = currentNode.selectedConnection as NewConnectionT;

    const connectionsDetails =
      selectedConnection.connectionsDetails as CustomAuthT;

    return (
      <React.Fragment>
        <div className="select_work_form">
          {/* <h4>Name for this Account</h4> */}
          {selectedConnection.error.show && (
            <p
              className="error_msg"
              style={{ color: 'orange', float: 'right' }}
            >
              {selectedConnection.error.info}
            </p>
          )}
          <InputContainer>
            <InputBox
              type="text"
              onChange={(e: any) => {
                const name = currentNode.connections.output.filter(
                  (x) => x.name.toLowerCase() === e.target.value.toLowerCase()
                );

                const nextState = produce(selectedConnection, (draftState) => {
                  draftState.connectionsDetails.value.name = e.target.value;
                  draftState.connectionsDetails.value.type =
                    currentNode.selectedApp.type;
                  draftState.valid =
                    name.length > 0 && !draftState.connectionEditing
                      ? false
                      : null;
                  draftState.hasError =
                    name.length > 0 && !draftState.connectionEditing
                      ? 'Duplicate connection name'
                      : null;
                });

                setActionPanelState({
                  ...actionPanelState,
                  currentNode: {
                    ...currentNode,
                    selectedConnection: nextState,
                  },
                });
              }}
              // placeholder="Enter a name for this Account"
              value={currentNode.selectedConnection.connectionsDetails.value.name}
              padTop
            />
            <InputContent>
              Name for this account
              <Tooltip
                className="target customTip _description"
                zIndex={10000}
                tagName="span"
                content="Provide a name for the account."
                distance={15}
                forceDirection={true}
                direction="up-start"
              >
                <InfoIcon />
              </Tooltip>
            </InputContent>
          </InputContainer>
          <p></p>
        </div>
        {connectionsDetails.customInputs.inputs.map((inp, i) => {
          return (
            <div className="select_work_form" key={i}>
              {/* <h4>{inp.displayTitle}</h4> */}
              <InputContainer>
                <InputBox
                  type={inp.config.kind}
                  onChange={(e: any) => {
                    const selectedConnection =
                      currentNode.selectedConnection as NewConnectionT;
                    const connectionsDetails = currentNode.selectedConnection
                      .connectionsDetails as CustomAuthT;

                    const name = currentNode.connections.output.filter(
                      (x) =>
                        x.name.toLowerCase() ===
                        connectionsDetails.value.name.toLowerCase()
                    );

                    const nextState = produce(
                      selectedConnection,
                      (draftState) => {
                        connectionsDetails.customInputs.inputs[i].value =
                          e.target.value;
                        draftState.connectionsDetails = connectionsDetails;
                        draftState.connectionsDetails.value.appId =
                          currentNode.selectedApp.id;
                        draftState.connectionsDetails.value.workspace =
                          workspaceName;
                        draftState.valid =
                          name.length > 0 && !draftState.connectionEditing
                            ? false
                            : null;
                        draftState.hasError =
                          name.length > 0 && !draftState.connectionEditing
                            ? 'Duplicate connection name'
                            : null;
                      }
                    );

                    setActionPanelState({
                      ...actionPanelState,
                      currentNode: {
                        ...currentNode,
                        selectedConnection: nextState,
                      },
                    });
                  }}
                  value={inp.value ? (inp.value as string) : ''}
                  // placeholder={`Enter ${inp.displayTitle}`}
                  padTop
                />
                <InputContent>
                  {`Enter ${inp.displayTitle}`}
                  <Tooltip
                    className="target customTip _description"
                    zIndex={10000}
                    tagName="span"
                    content={inp.description}
                    distance={15}
                    forceDirection={true}
                    direction="up-start"
                  >
                    <InfoIcon />
                  </Tooltip>
                </InputContent>
              </InputContainer>
              <p></p>
              {/* <p>{inp.description}</p> */}
            </div>
          );
        })}
      </React.Fragment>
    );
  }
  const deleteConnectionCallback = async (connectionId: string) => {
    let nextState = produce(actionPanelState.currentNode, (draftState) => {
      currentNode.connections.output = currentNode.connections.output.filter(
        (x) => x.authStoreMap !== connectionId
      );
      let connection = ActionInitialState.selectedConnection as NewConnectionT;
      connection.connectionsDetails = getInitialConnectionValue(
        currentNode.selectedApp.type,
        {
          fromAction: true,
          currentNode: actionPanelState.currentNode,
        }
      );

      connection = {
        ...connection,
        selectedConnection: 'Add a new Account',
        id: '',
        hasError: null,
        valid: null,
        connectionEditing: false,
      };
      draftState.selectedConnection = connection;
    });

    props.refetchWorkflowData();

    // const promise = ramda.clone(props.customJsonApi)

    // const response = await promise()

    // // to update tree nodes
    // !response.response && props.setResponseData({ ...props, state: { type: 'success', data: response } })

    setActionPanelState({
      ...actionPanelState,
      currentNode: nextState,
    });
  };
  return (
    // <div className="connection_new_autoamtion">
    <InputWrapper>
      <div className="automation_global_form triggered_select_application">
        <div className="select_work_form">
          <h4>
            Connect application
            {/* <br/><span className="exisitng_account">
                            {' '}
                            (You have {currentNode.connections.output.length} existing accounts)
                        </span> */}
          </h4>
          <InputContainer style={{ marginBottom: 20 }}>
            <CanvasRightPanel.AppsDropDown
              inputs={{
                inputs: [
                  ...currentNode.connections.output,
                  DefaultSelectedConnection,
                ],
                key: 'name',
                idKey: 'authStoreMap',
              }}
              addDropDown
              updateSelection={(selectedConnection: string, id: string) => {
                if (currentNode.selectedConnection.id !== id) {
                  const nextState = produce(currentNode, (draftState) => {
                    let connection = draftState.selectedConnection as
                      | NewConnectionT
                      | OldConnectionT;

                    const isCustomAuth =
                      currentNode.selectedApp.type == AuthType.Custom ||
                      currentNode.selectedApp.type == AuthType.CustomOauth || currentNode.selectedApp.type == AuthType.PrivateOauth
                        ? {
                          fromAction: true,
                          currentNode: actionPanelState.currentNode,
                        }
                        : undefined;

                    if (!isCustomAuth) {
                      connection =
                        selectedConnection !== 'Add a new Account'
                          ? oldConnection
                          : NewConnection;
                    } else {
                      connection =
                        selectedConnection !== 'Add a new Account'
                          ? ({
                            ...oldConnection,
                            connectionsDetails: {
                              ...draftState.selectedConnection
                                .connectionsDetails,
                            },
                          } as OldConnectionT)
                          : NewConnection;
                    }

                    connection.connectionsDetails =
                      selectedConnection === 'Add a new Account'
                        ? // to change connection type based on app type
                        getInitialConnectionValue(
                          currentNode.selectedApp.type,
                          isCustomAuth
                        )
                        : connection.connectionsDetails;

                    connection.selectedConnection = selectedConnection;
                    connection.id = id ? id : '';
                    connection.valid = null;
                    connection.hasError = null;

                    if (selectedConnection === 'Add a new Account') {
                      const currentConnection = connection as NewConnectionT;
                      currentConnection.connectionEditing = false;
                      connection = currentConnection;
                    }

                    draftState.selectedConnection = connection;
                  }, (draft) => {
                    console.log(draft, "draft")
                  });

                  setActionPanelState({
                    ...actionPanelState,
                    currentNode: nextState,
                  });
                }
              }}
              render={(makeDropDownVisible: () => void) => (
                <>
                  {/* <InputContainer> */}
                  <InputBox
                    className={!active_automation ? "without_caret_input" : "without_caret_input editor_btn_disabled"}
                    type="text"
                    value={currentNode.selectedConnection.selectedConnection}
                    onChange={() => { }}
                    onClick={makeDropDownVisible}
                  />
                  <span className="application_input_version">
                    Accounts : {currentNode.connections.output.length}{' '}
                  </span>
                  <CancelButtonInput rotate onClick={makeDropDownVisible}>
                    <DownarrowWithCircle />
                  </CancelButtonInput>
                  <InputContent>
                    Connect your application account
                    <Tooltip
                      className="target customTip _description"
                      zIndex={10000}
                      tagName="span"
                      content="Add a new account or select from existing accounts."
                      distance={15}
                      forceDirection={true}
                      direction="up-start"
                    >
                      <InfoIcon />
                    </Tooltip>
                  </InputContent>
                  {/* </InputContainer> */}
                </>
              )}
            />
          </InputContainer>
        </div>

        {currentNode.selectedConnection.type === ConnectionTypeT.new &&
          (currentNode.selectedApp.type === AuthType.Custom ||
            currentNode.selectedApp.type == AuthType.CustomOauth || currentNode.selectedApp.type == AuthType.PrivateOauth) &&
          populateCustomAuthUI()}

        {currentNode.selectedConnection.type === ConnectionTypeT.new &&
          currentNode.selectedApp.type === AuthType.Basic && (
            <React.Fragment>
              <div className="select_work_form">
                {/* <h4>Name for this Account</h4> */}
                {currentNode.selectedConnection.error.show && (
                  <p
                    className="error_msg"
                    style={{ color: 'orange', float: 'right' }}
                  >
                    {currentNode.selectedConnection.error.info}
                  </p>
                )}
                <InputBox
                  type="text"
                  onChange={(e: any) => {
                    const selectedConnection =
                      currentNode.selectedConnection as NewConnectionT;

                    const name = currentNode.connections.output.filter(
                      (x) =>
                        x.name.toLowerCase() === e.target.value.toLowerCase()
                    );

                    const nextState = produce(
                      selectedConnection,
                      (draftState) => {
                        draftState.connectionsDetails.value.name =
                          e.target.value;
                        draftState.valid =
                          name.length > 0 && !draftState.connectionEditing
                            ? false
                            : null;
                        draftState.hasError =
                          name.length > 0 && !draftState.connectionEditing
                            ? 'Duplicate connection name'
                            : null;
                      }
                    );

                    setActionPanelState({
                      ...actionPanelState,
                      currentNode: {
                        ...currentNode,
                        selectedConnection: nextState,
                      },
                    });
                  }}
                  value={
                    currentNode.selectedConnection.connectionsDetails.value.name
                  }
                  placeholder="Enter a name for this Account"
                  padTop
                />

                <p>Provide a new name for the connected account.</p>
              </div>

              <div className="select_work_form">
                <h4>Username</h4>
                <InputBox
                  type="text"
                  placeholder="Enter user name"
                  onChange={(e: any) => {
                    const selectedConnection =
                      currentNode.selectedConnection as NewConnectionT;
                    const connectionsDetails = currentNode.selectedConnection
                      .connectionsDetails as BasicT;

                    const name = currentNode.connections.output.filter(
                      (x) =>
                        x.name.toLowerCase() ===
                        connectionsDetails.value.name.toLowerCase()
                    );

                    const nextState = produce(
                      selectedConnection,
                      (draftState) => {
                        connectionsDetails.value.meta_data.username =
                          e.target.value;
                        draftState.connectionsDetails = connectionsDetails;
                        draftState.connectionsDetails.value.appId =
                          currentNode.selectedApp.id;
                        draftState.connectionsDetails.value.workspace =
                          workspaceName;
                        draftState.valid =
                          name.length > 0 && !draftState.connectionEditing
                            ? false
                            : null;
                        draftState.hasError =
                          name.length > 0 && !draftState.connectionEditing
                            ? 'Duplicate connection name'
                            : null;
                      }
                    );

                    setActionPanelState({
                      ...actionPanelState,
                      currentNode: {
                        ...currentNode,
                        selectedConnection: nextState,
                      },
                    });
                  }}
                  value={
                    currentNode.selectedConnection.connectionsDetails.type ===
                      AuthType.Basic
                      ? currentNode.selectedConnection.connectionsDetails.value
                        .meta_data.username
                      : ''
                  }
                  padTop
                />
              </div>
              <div className="select_work_form">
                <h4>Password</h4>
                <InputBox
                  type="password"
                  placeholder="Enter password"
                  onChange={(e: any) => {
                    const selectedConnection =
                      currentNode.selectedConnection as NewConnectionT;
                    const connectionsDetails = currentNode.selectedConnection
                      .connectionsDetails as BasicT;

                    const name = currentNode.connections.output.filter(
                      (x) =>
                        x.name.toLowerCase() ===
                        connectionsDetails.value.name.toLowerCase()
                    );

                    const nextState = produce(
                      selectedConnection,
                      (draftState) => {
                        connectionsDetails.value.meta_data.password =
                          e.target.value;
                        draftState.connectionsDetails = connectionsDetails;
                        draftState.connectionsDetails.value.appId =
                          currentNode.selectedApp.id;
                        draftState.connectionsDetails.value.workspace =
                          workspaceName;
                        draftState.valid =
                          name.length > 0 && !draftState.connectionEditing
                            ? false
                            : null;
                        draftState.hasError =
                          name.length > 0 && !draftState.connectionEditing
                            ? 'Duplicate connection name'
                            : null;
                      }
                    );

                    setActionPanelState({
                      ...actionPanelState,
                      currentNode: {
                        ...currentNode,
                        selectedConnection: nextState,
                      },
                    });
                  }}
                  value={
                    currentNode.selectedConnection.connectionsDetails.type ===
                      AuthType.Basic
                      ? currentNode.selectedConnection.connectionsDetails.value
                        .meta_data.password
                      : ''
                  }
                  padTop
                />
              </div>
            </React.Fragment>
          )}

        {currentNode.selectedConnection.type === ConnectionTypeT.new &&
          currentNode.selectedApp.type === AuthType.Apitoken && (
            <React.Fragment>
              <div className="select_work_form">
                {/* <h4>Name for this Account</h4> */}
                {currentNode.selectedConnection.error.show && (
                  <p
                    className="error_msg"
                    style={{ color: 'orange', float: 'right' }}
                  >
                    {currentNode.selectedConnection.error.info}
                  </p>
                )}
                <InputContainer>
                  <InputBox
                    type="text"
                    onChange={(e: any) => {
                      const selectedConnection =
                        currentNode.selectedConnection as NewConnectionT;

                      const name = currentNode.connections.output.filter(
                        (x) =>
                          x.name.toLowerCase() === e.target.value.toLowerCase()
                      );

                      const nextState = produce(
                        selectedConnection,
                        (draftState) => {
                          draftState.connectionsDetails.value.name =
                            e.target.value;
                          draftState.valid =
                            name.length > 0 && !draftState.connectionEditing
                              ? false
                              : null;
                          draftState.hasError =
                            name.length > 0 && !draftState.connectionEditing
                              ? 'Duplicate connection name'
                              : null;
                        }
                      );

                      setActionPanelState({
                        ...actionPanelState,
                        currentNode: {
                          ...currentNode,
                          selectedConnection: nextState,
                        },
                      });
                    }}
                    // placeholder="Enter a name for this Account"
                    value={
                      currentNode.selectedConnection.connectionsDetails.value.name
                    }
                    padTop
                  />

                  <InputContent>
                    Name for this account
                    <Tooltip
                      className="target customTip _description"
                      zIndex={10000}
                      tagName="span"
                      content="Provide a name for the account."
                      distance={15}
                      forceDirection={true}
                      direction="up-start"
                    >
                      <InfoIcon />
                    </Tooltip>
                  </InputContent>
                </InputContainer>
                <p></p>
              </div>

              <div className="select_work_form">
                <h4>API token</h4>
                <InputBox
                  type="text"
                  onChange={(e: any) => {
                    const selectedConnection =
                      currentNode.selectedConnection as NewConnectionT;
                    const connectionsDetails = currentNode.selectedConnection
                      .connectionsDetails as ApitokenT;

                    const name = currentNode.connections.output.filter(
                      (x) =>
                        x.name.toLowerCase() ===
                        connectionsDetails.value.name.toLowerCase()
                    );

                    const nextState = produce(
                      selectedConnection,
                      (draftState) => {
                        connectionsDetails.value.meta_data.api_token =
                          e.target.value;
                        draftState.connectionsDetails = connectionsDetails;
                        draftState.connectionsDetails.value.appId =
                          currentNode.selectedApp.id;
                        draftState.connectionsDetails.value.workspace =
                          workspaceName;
                        draftState.valid =
                          name.length > 0 && !draftState.connectionEditing
                            ? false
                            : null;
                        draftState.hasError =
                          name.length > 0 && !draftState.connectionEditing
                            ? 'Duplicate connection name'
                            : null;
                      }
                    );

                    setActionPanelState({
                      ...actionPanelState,
                      currentNode: {
                        ...currentNode,
                        selectedConnection: nextState,
                      },
                    });
                  }}
                  value={
                    currentNode.selectedConnection.connectionsDetails.type ===
                      AuthType.Apitoken &&
                      currentNode.selectedConnection.connectionsDetails.value
                        .meta_data
                      ? currentNode.selectedConnection.connectionsDetails.value
                        .meta_data.api_token
                      : ''
                  }
                  placeholder="Enter API token"
                  padTop
                />
              </div>
            </React.Fragment>
          )}

        {currentNode.selectedConnection.type === ConnectionTypeT.new &&
          currentNode.selectedApp.type === AuthType.Oauth && (
            <React.Fragment>
              <div className="select_work_form">
                {/* <h4>Name for this Account</h4> */}
                {currentNode.selectedConnection.error.show && (
                  <p
                    className="error_msg"
                    style={{ color: 'orange', float: 'right' }}
                  >
                    {currentNode.selectedConnection.error.info}
                  </p>
                )}
                <InputContainer>
                  <InputBox
                    type="text"
                    onChange={(e: any) => {
                      const selectedConnection =
                        currentNode.selectedConnection as NewConnectionT;

                      const name = currentNode.connections.output.filter(
                        (x) =>
                          x.name.toLowerCase() === e.target.value.toLowerCase()
                      );

                      const nextState = produce(
                        selectedConnection,
                        (draftState) => {
                          draftState.connectionsDetails.value.name =
                            e.target.value;
                          draftState.valid =
                            name.length > 0 && !draftState.connectionEditing
                              ? false
                              : null;
                          draftState.hasError =
                            name.length > 0 && !draftState.connectionEditing
                              ? 'Duplicate connection name'
                              : null;
                        }
                      );

                      setActionPanelState({
                        ...actionPanelState,
                        currentNode: {
                          ...currentNode,
                          selectedConnection: nextState,
                        },
                      });
                    }}
                    value={
                      currentNode.selectedConnection.connectionsDetails.value.name
                    }
                    // placeholder="Enter a name for this Account"
                    padTop
                  />
                  <InputContent>
                    Name for this account
                    <Tooltip
                      className="target customTip _description"
                      zIndex={10000}
                      tagName="span"
                      content="Provide a name for the account."
                      distance={15}
                      forceDirection={true}
                      direction="up-start"
                    >
                      <InfoIcon />
                    </Tooltip>
                  </InputContent>
                </InputContainer>
                <p></p>
              </div>
            </React.Fragment>
          )}

        <div className="connection_new_autoamtion_button apptrigger_button">
          {currentNode.selectedConnection.type === ConnectionTypeT.old && (
            <React.Fragment>
              <Button
                className={
                  !currentNode.selectedConnection.loading &&
                    (currentNode.selectedConnection.hasError ||
                      !currentNode.selectedConnection.valid)
                    ? ' '
                    : 'editor_btn_disabled'
                }
                onClick={() => {
                  testConnection(currentNode.selectedConnection.id);
                }}
                disabled={
                  currentNode.selectedConnection.loading ||
                  currentNode.selectedConnection.valid === true
                }
                style={{ marginTop: 0, marginBottom: 0 }}
              >
                {currentNode.selectedConnection.valid &&
                  !currentNode.selectedConnection.hasError
                  ? 'Connected'
                  : 'Test Connection'}
              </Button>

              {currentNode.selectedConnection.loading && (
                <span className="connect_account_loading automate_connect_account_loading">
                  <img src={LoadingSvgImage} />
                </span>
              )}

              {currentNode.selectedConnection.valid &&
                !currentNode.selectedConnection.hasError ? (
                <span className="connect_account_success automate_connect_account_success">
                  <GreenTickIcon />
                </span>
              ) : (
                currentNode.selectedConnection.valid !== null && (
                  <span className="connect_account_failed">
                    <div className="error_input_show">
                      <WarningAlertIcon />
                      <span className="error_menu_hvr">
                        {currentNode.selectedConnection.hasError}
                      </span>
                    </div>
                  </span>
                )
              )}
            </React.Fragment>
          )}

          {currentNode.selectedConnection.type === ConnectionTypeT.new && (
            <React.Fragment>
              <Button
                className={
                  currentNode.selectedConnection.loading ||
                    !(
                      enableConnectButton() &&
                      currentNode.selectedConnection.valid !== false
                    )
                    ? 'editor_btn_disabled'
                    : ''
                }
                onClick={() => {
                  const selectedConnection = currentNode.selectedConnection as NewConnectionT

                  const callback = !selectedConnection.connectionEditing
                    ? createConnection
                    : updateConnection
                  if (
                      currentNode.selectedApp.type === AuthType.Oauth ||
                      currentNode.selectedApp.type === AuthType.CustomOauth || 
                      currentNode.selectedApp.type == AuthType.PrivateOauth
                ) {
                    listenForExternalToken(currentNode.selectedApp.id, callback, workspaceName, {
                      actionPanelState,
                      setActionPanelState
                    })
                  } else {
                    if (!selectedConnection.connectionEditing) {
                      createConnection(actionPanelState)
                    } else {
                      updateConnection(actionPanelState, currentNode.selectedConnection.id)
                    }
                  }
                }}
                disabled={
                  !(
                    enableConnectButton() &&
                    currentNode.selectedConnection.valid !== false
                  ) || currentNode.selectedConnection.loading
                }
                style={{ marginTop: 0, marginBottom: 0 }}
              >
                {currentNode.selectedConnection.connectionEditing
                  ? 'Update '
                  : 'Connect '}{' '}
              </Button>

              {currentNode.selectedConnection.loading && (
                <span className="connect_account_loading">
                  <img src={LoadingSvgImage} />
                </span>
              )}

              {currentNode.selectedConnection.valid &&
                !currentNode.selectedConnection.hasError ? (
                <span className="connect_account_success">
                  <GreenTickIcon />
                </span>
              ) : (
                currentNode.selectedConnection.valid !== null && (
                  <span className="connect_account_failed">
                    <div className="error_input_show">
                      <WarningAlertIcon />
                      <span className="error_menu_hvr">
                        {currentNode.selectedConnection.hasError}
                      </span>
                    </div>
                  </span>
                )
              )}
            </React.Fragment>
          )}
          <Button
            primary
            className={enableRemoveButton() && !active_automation ? ' ' : '  editor_btn_disabled'}
            onClick={() =>
              setToDeleteConnection(
                currentNode.selectedConnection.id,
                currentNode.selectedConnection.connectionsDetails.type,
                deleteConnectionCallback,
                wrapPromise(
                  fetchAutomations(
                    workspaceName,
                    currentNode.selectedConnection.id
                  )
                ) as any
              )
            }
            style={{ marginLeft: 10, marginTop: 0, marginBottom: 0 }}
          >
            Remove Account
          </Button>
        </div>
      </div>
    </InputWrapper>
    // </div>
  );
};

function listenForExternalToken(
  appId: string,
  callback: any,
  workspace: string,
  actionState: any
) {
  try {
    const { actionPanelState, setActionPanelState } = actionState;

    const currentNode = actionPanelState.currentNode as ActionsResponseT;
    const selectedConnection = currentNode.selectedConnection as NewConnectionT;

    var childWindow: any;

    const receiveMessage = (event: any) => {
      //if (event.origin === 'http://localhost:3000') return

      const nextState = produce(selectedConnection, (draftState) => {
        const connectionsDetails = draftState.connectionsDetails as OauthtokenT;
        connectionsDetails.value = {
          ...connectionsDetails.value,
          appId,
          workspace,

          meta_data: {
            authStoreMap: event.data,
          },
        };
        draftState.connectionsDetails = connectionsDetails;
      });

      setActionPanelState({
        ...actionPanelState,
        currentNode: {
          ...currentNode,
          selectedConnection: nextState,
        },
      });

      if (!selectedConnection.connectionEditing) {
        callback({
          ...actionPanelState,
          currentNode: {
            ...currentNode,
            selectedConnection: nextState,
          },
        });
      } else {
        callback(
          {
            ...actionPanelState,
            currentNode: {
              ...currentNode,
              selectedConnection: nextState,
            },
          },
          selectedConnection.id
        );
      }

      window.removeEventListener('message', receiveMessage, false);
      childWindow.close();
    };

    const openChildWindow = (data: AxiosResponse<any>) => {
      childWindow = window.open(
        data.data.output.authUrl,
        'authwind',
        'width=550,height=500,0,status=0,'
      );
    };
    if (currentNode.selectedApp.type == AuthType.CustomOauth || currentNode.selectedApp.type == AuthType.PrivateOauth) {
      const connection = currentNode.selectedConnection
        .connectionsDetails as CustomOAuthT;

      if (selectedConnection.connectionEditing) {
        let req : any = {}

        if(currentNode.selectedApp.type == AuthType.PrivateOauth){
          req = {
            connection_id: currentNode.selectedConnection.id,
            type: "private_oauth",
            meta_data: ramda.mergeAll(
                connection.customInputs.inputs.map(function(o) {
                    return {
                        [o.key]: o.value
                    }
                })
            )
          }
        }else{
         req = {
            uuid: appId,
            id: currentNode.selectedConnection.id,
            meta_data: ramda.mergeAll(
              connection.customInputs.inputs.map(function (o) {
                return {
                  [o.key]: o.value,
                };
              })
            ),
          };
        }

        putJson(
          automationAPIURLWithWorkspace(
            workspace,
            currentNode.selectedApp.type === AuthType.PrivateOauth? `/connection/oauthredirect/privateoauth`: `/connection/oauthredirect/custom`
          )
        )()(req)
          .then(openChildWindow)
          .catch((error: any) => console.error(error));
      } else {
        let req:any ={};
        if(currentNode.selectedApp.type === AuthType.PrivateOauth){
            req = {
                name: currentNode.selectedConnection.connectionsDetails.value.name,
                type: "private_oauth",
                meta_data: {
                    ...ramda.mergeAll(
                    connection.customInputs.inputs.map(function(o) {
                        return {
                            [o.key]: o.value
                        }
                        })
                    ) as any,
                    appId: currentNode.selectedApp.id,
                    version: currentNode.selectedApp.version
                }
            }
        }else{
          req = {
            uuid: appId,
            id: currentNode.selectedConnection.id,
            meta_data: ramda.mergeAll(
              connection.customInputs.inputs.map(function (o) {
                return {
                  [o.key]: o.value,
                };
              })
            ),
          };
        }

        postJson(
          automationAPIURLWithWorkspace(
            workspace,
            currentNode.selectedApp.type === AuthType.PrivateOauth? `/connection/oauthredirect/privateoauth` : `/connection/oauthredirect/custom`
          )
        )(req)
          .then(openChildWindow)
          .catch((error: any) => console.error(error));
      }
    } else {
      if (selectedConnection.connectionEditing) {
        putJson(
          automationAPIURLWithWorkspace(workspace, `/connection/oauthredirect`)
        )()({
          uuid: appId,
          id: currentNode.selectedConnection.id,
        })
          .then(openChildWindow)
          .catch((error: any) => console.error(error));
      } else {
        postJson(
          automationAPIURLWithWorkspace(workspace, `/connection/oauthredirect`)
        )({
          uuid: appId,
        })
          .then(openChildWindow)
          .catch((error: any) => console.error(error));
      }
    }
    window.addEventListener('message', receiveMessage, false);
  } catch (err) {
    console.log('cahced', err);
  }
}

AppTriggerPanel.ErrorHandling = () => {
  const { actionPanelState, setActionPanelState } = useContext(
    ActionPanelStateContext
  );

  const { editTreeData, setEditTreeData, treeData, hasLinkedDialogs, active_automation } =
    useContext(SidePanelContext);

  const currentNode = actionPanelState.currentNode as AppTriggerResponseT;

  const showErrorHandling = currentNode.errorHandling.errorHandling;

  const [errors, setErrorValue] = useState<
    { name: string; enabled: boolean }[]
  >([]);

  useEffect(() => {
    setErrorValue(currentNode.errorHandling.errors);
  }, [currentNode.errorHandling.errors]);

  const newNode = {
    kind: 'ErrorHandlingCondition',
    id: '',
    name: '',
    description: '',
    meta_data: {
      authentication_id: '',
    },
    icon: ErrorHandlingSuccessIcon,
    input: [],
    output: {},
    path: [],
  } as NodeT;

  function showErrorHandlingInTree() {
    const mode = actionPanelState.mode;

    if (errors.filter((err) => err.enabled).length == 0 && !showErrorHandling) {
      const currentState = {
        ...actionPanelState,
        currentNode: {
          ...currentNode,
          errorHandling: {
            ...currentNode.errorHandling,
            errorHandling: true,
            errors: errors.map((err) => {
              if (err.name == 'success' || err.name == 'failed') {
                return { ...err, enabled: true };
              }

              return err;
            }),
          },
        },
      };

      const parentNode = editTreeData.tree.children[0] as TreeT<NodeT>;

      const nodeNotHasErrorHandling =
        mode.mode == NodeMode.Edit &&
        (parentNode.children.length == 0 ||
          parentNode.children.filter(
            (chil) => currentNode.selectedApp.id == chil.value.meta_data.app_id
          ).length == 0);
      console.log(treeData, editTreeData, editTreeData.tree);
      const newTree =
        !showErrorHandling && nodeNotHasErrorHandling
          ? addErrorHandlingNodesInAppTrigger(
            newNode,
            editTreeData.tree.children[0].value,
            editTreeData.tree.children[0].children,
            editTreeData.tree.value.id,
            currentNode,
            editTreeData.tree
          )
          : treeData.tree;
      console.log(newTree, 'teste');
      setActionPanelState(currentState);
      setEditTreeData({ ...treeData, tree: newTree });
    } else if (!showErrorHandling) {
      // will satisfy when node has two error handling nodes and enabling error handling
      setActionPanelState({
        ...actionPanelState,
        currentNode: {
          ...currentNode,
          errorHandling: {
            ...currentNode.errorHandling,
            errorHandling: !showErrorHandling,
          },
        },
      });
    } else if (showErrorHandling) {
      const newTree = editTreeData.tree;

      setActionPanelState({
        ...actionPanelState,
        currentNode: {
          ...currentNode,
          errorHandling: {
            ...currentNode.errorHandling,
            errorHandling: false,
            errors: errors.map((err) => {
              if (err.name == 'success' || err.name == 'failed') {
                return { ...err, enabled: false };
              }

              return err;
            }),
          },
        },
      });

      const updatedTree =
        errors.filter((err) => err.enabled).length > 0 ||
          (newTree.value.meta_data && newTree.value.meta_data.continueIfError)
          ? deleteErrorHandlingNodes(
            newTree.value.id,
            newTree.children[0].value,
            newTree
          )
          : newTree;

      console.log(updatedTree, newTree);

      setEditTreeData({ ...treeData, tree: updatedTree });
    }
  }
  console.log(currentNode, 'currentNode');
  return (
    <div className="error_handle_autoamtion_bg">
      <div className="error_handle_autoamtion">
        <Header>
          Error handling
          <span className="show_hide _auto_switch">
            <div className="switch_auto">
              <input
                type="checkbox"
                className={`switch_1 ${hasLinkedDialogs || active_automation ? 'disabled_with_blue' : ''
                  }`}
                checked={showErrorHandling}
                onChange={() => {
                  if (hasLinkedDialogs) return;
                  showErrorHandlingInTree();
                }}
              />
            </div>
          </span>
        </Header>
        <Paragraph>
          Enabling error handling will let Workativ continue to the next step of
          the workflow if the action failed.
        </Paragraph>
      </div>
      {showErrorHandling && (
        <div className="error_handle_checkbox">
          {errors.map((err, i) => (
            <div className="error-group" key={i}>
              <input
                type="checkbox"
                id={err.name}
                checked={true}
                className={''}
                disabled={true}
                onChange={(e) => { }}
                onClick={() => { }}
              />
              <label htmlFor={err.name} className={'editor_btn_disabled'}>
                {err.name}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

AppTriggerPanel.ActionInfo = (props: any) => {
  const { actionPanelState, setActionPanelState } = useContext(
    ActionPanelStateContext
  );

  const currentNode = actionPanelState.currentNode as AppTriggerResponseT;

  return (
    <div className="action_information">
      <div className="automation_global_form">
        <div className="select_work_form">
          <h4>Action summary</h4>
          <input
            type="text"
            value={currentNode.selectedAction.description}
            onChange={(e) => {
              const nextState = produce(currentNode, (draftState) => {
                draftState.selectedAction.description = e.target.value;
              });

              setActionPanelState({
                ...actionPanelState,
                currentNode: nextState,
              });
            }}
          />
          <p>
            This description will be displayed as information for the action on
            the canvas page.
          </p>
        </div>
      </div>
    </div>
  );
};
