export interface ROUTER_PROPS_T {
    match: {
        params: {
            workspacename: string
            assistantname: string
            dialogue_node: string
            intent: string
            entity: string
        }
    }
    computedMatch: {
        params: {
            workspacename: string
            assistantname: string
            dialogue_node: string
            intent: string
            entity: string
        }
    }
    history: {
        push: Function
        replace: Function
    }
    location: {
        search: string
    }
    assistantId?: string
}

export enum ActionT {
    VIEW,
    LOADING,
    UN_AUTHORIZED,
    RESOURCE_NOT_FOUND,
    NO_DATA_FOUND,
    ERROR,
    CREATE_FAQ,
    UPDATE_FAQ,
    DEPLOY,

    INTENT_EDIT, //only for intent detail page
    ENTITY_EDIT,

    ADD,
    EDIT,
    DELETE,
    MULTIPLE_DELETE,

    SELECT,
    MOVE
}

export type PageViewsT = GridT | ListT

export enum ViewTypeT {
    GridView,
    ListView
}

export type GridT = {
    View: ViewTypeT.GridView
}

export type ListT = {
    View: ViewTypeT.ListView
}

export const GridView: GridT = { View: ViewTypeT.GridView }

export const ListView: ListT = { View: ViewTypeT.ListView }
