import styled from 'styled-components/macro'

import { FocusContent } from './DraftJs.Dumb'

// const timestring = require('timestring')
export const Wrapper = styled.section`
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 48px;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1111;
    cursor: auto;
`
export const Paragraph = styled.p`
    text-align: left;
    color: #355d8b;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    margin-bottom: ${props => (props.margin ? '0px' : '12px')};
    margin-top: ${props => (props.margin ? '7px' : '0px')};
    width: 100%;
    float: left;
    line-height: 20px;
    cursor: ${props => (props.cursor ? 'pointer' : 'inherit')};
    // border: 1px solid #b9b9b9;
    border-radius: 30px;
    padding: 5px 5px 0 2px;

    svg {
        width: 12px;
        height: 12px;
        margin-right: 4px;
        position: relative;
        top: 2px;
    }
`
export const HeaderLeft = styled.div`
    width: max-content;
    float: left;
    display: flex;
    min-width: ${props => (props.minWidth ? '72%' : '0px')};
    align-items: center;

    .header_img{
        width: 28px;
        height: 28px;
        margin-right: 10px;
    }
`
export const HeaderRight = styled.div`
    width: max-content;
    float: left;
    display: flex;
    align-items: center;
    ${Paragraph} {
        color: #007bff;
        margin-bottom: 0px;
    }
`
export const Header = styled.h4`
    text-align: left;
    color: #000;
    font-size: 18px;
    font-family: 'Gordita-Medium';
    margin-bottom: 0px;
    width: 100%;
    float: left;
    line-height: 12px;
    display: flex;
    justify-content: space-between;
    span {
        margin-left: 6px;
        svg {
            width: 12px;
            height: 12px;
            fill: #999;
            color: #999;
            margin-left: 0px;
            cursor: pointer;
        }
    }
`
export const LinkTag = styled.a`
    position: relative;
    margin-left: 6px;
    svg {
        width: 12px;
        height: 12px;
        fill: rgb(153, 153, 153);
        color: rgb(153, 153, 153);
        margin-left: 0px;
        cursor: pointer;
    }
`
export const Input = styled.input.attrs((boolean: any) => ({
    required: true
}))`
    width: 100%;
    float: left;
    padding: ${(props)=>props.padTop? "26px 7px 8px 12px":"26px 7px 8px 12px"};
    border: 1px solid #b9b9b9;
    border-radius: 6px;
    background: #fff;
    height: 48px;
    font-size: 14px;
    font-family: 'Gordita-Regular';
    color: #000000;

    :hover {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #b9b9b9;
    }
    :focus {
        outline: 0;
        border: 1px solid #eb974e;
        box-shadow: 0px 3px 6px #00000029;
    }
    :active {
        outline: 0;
        border: 1px solid #eb974e;
        box-shadow: none;
    }
`
export const InputContent = styled.div`
    color: #999;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    position: absolute;
    pointer-events: ${props => (props.pointer ? 'inherit' : 'none')};
    left: 12px;
    top: 16px;
    transition: 0.26s ease-in;

    .target.customTip{
        pointer-events:initial;
        cursor:pointer;
        margin-left:6px;
        position:relative;
    }

    svg{
        width: 12px;
    height: 12px;
    position: relative;
    top: 2px;
    }
    ${Input}:focus ~ &,
    ${Input}:not(:placeholder-shown).input:not(:focus) ~ .label,
    ${Input}:valid ~ & {
        top: 8px;
        font-size: 10px;
        transition: 0.26s ease-out;
        line-height: 10px;

        svg{
        width: 10px;
        height: 10px;
        position: relative;
        top: 2px;
        }
    }
`
export const CancelButton = styled.span`
    width: 18px;
    height: 18px;
    position: absolute;
    right: 12px;
    top: 8px;
    display: none;

    svg {
        width: 18px;
        height: 18px;
    }
`
export const InputContainer = styled.div`
    width: 100%;
    float: left;
    position: relative;
    margin-bottom: 20px;
`
export const Button = styled.button`
    min-width: 90px;
    height: 32px;
    background: ${props => (props.primary ? '#fff' : '#2468f6')};
    border-radius: 18px;
    border: ${props => (props.primary ? '1px solid #b9b9b9' : '1px solid #2468f6')};
    outline: 0;
    font-size: 12px !important;
    padding: 7px 12px;
    font-family: 'Gordita-Medium';
    color: ${props => (props.primary ? '#333' : '#fff')};
    margin: ${props => (props.margin == 'sides' ? '0px 10px' : '10px 0px')};
    cursor: pointer;

    :hover {
        background: #ffe5d3;
        color: #333;
        border: 1px solid #ffe5d3;
    }
    :active {
        background: ${props => (props.primary ? '#fff' : '#2468f6')};
        border: ${props => (props.primary ? '1px solid #b9b9b9' : '1px solid #2468f6')};
        color: ${props => (props.primary ? '#333' : '#fff')};
    }
    :focus {
        background: #ffe5d3;
        color: #333;
        border: 1px solid #eb974e;
    }

    span {
        width: 12px;
        height: 12px;
        float: left;
        margin-right: 6px;
        display: flex;
        align-items: center;
        position: relative;
        top: 0px;
        cursor: pointer;
        svg {
            width: 12px;
            height: 12px;
            fill: #333;
            color: #333;
            margin-right: 0px;
        }
    }
    label {
        margin-bottom: 0px;
        position: relative;
        // top: 1px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            margin-bottom: -2px;
        }
    }
    label.condition_button_style {
        justify-content: center;
    }
    svg {
        width: 12px;
        height: 12px;
        margin-right: 6px;
        position: relative;
        bottom: 1px;
    }
`
export const InputWrapper = styled.div`
    width: 100%;
    float: left;
    background: #f4f4f4 0% 0% no-repeat padding-box;
    padding: 20px;
    margin-bottom: 10px;

    :last-child {
        margin-bottom: 0px;
    }

    ${InputContainer} {
        :last-child {
            margin-bottom: 0px;
        }
    }
    ${Header} {
        text-align: left;
        color: #000;
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 6px;
        justify-content: left;
        svg {
            position: relative;
            top: 1px;
        }
    }

    h4{
        font-family: 'Gordita-Medium';
        font-size: 16px;
        color: #000;
        line-height: normal;
    }
    p{
        color: rgb(53, 93, 139);
        font-size: 12px;
        font-family: Gordita-Regular;
        margin-bottom: 10px;
    }
`
export const PopupContainer = styled.div`
    width: 100%;
    float: left;
    .popup_inside_loader{
        display: flex;
        align-items: center;
        justify-content: center;
    }
`
export const DropdownLi = styled.div`
    width: 100%;
    float: left;
    display: inline-flex;
    align-items: center;
    border-bottom: 1px solid #b6b6b6;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    height: 36px;
    padding: 0 10px;
    background: #fff;
    cursor: pointer !important;
    color: #000;

    img {
        width: 14px;
        height: 14px;
        margin-right: 7px;
    }

    :first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        background: #fff;
    }
    :last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        background: #fff;
        border-bottom: 0.5px solid #fff;
    }
    :hover {
        background: #ffe5d3;
        color: #333;
        border-bottom: 1px solid #ffe5d3;
    }
`
export const DropdownUl = styled.div`
    width: 100%;
    position: absolute;
    margin-top: 22px;
    right: 0px;
    left: 0%;
    cursor: pointer;
    padding: 0px;
    overflow-y: scroll;
    max-height: 215px;
    margin-bottom: 0px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    z-index: 2;
    margin-bottom: 0px;
`
export const DropdownUlWrapper = styled.div`
    width: 100%;
    float: left;
    position: relative;
    margin-bottom: 0px;
`
export const DropdownWrapper = styled.div`
    width: 100%;
    float: left;
    ${InputContainer} {
        ${Input} {
            padding-right: 40px;
        }
        ${CancelButton} {
            width: 16px;
            height: 16px;
            display: flex;
            right: 10px;
            top: 16px;
            svg {
                width: 16px;
                height: 16px;
                color: #555555;
                fill: #555555;
            }
        }
    }
`
export const DraftInputWrapper = styled.div`
    width: 100%;
    float: left;

    .dialog_input_edit_true.dialog_context_input_edit_true {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #b9b9b9;
        border-radius: 6px;
        min-height: 48px;
        padding: 20px 12px 3px 12px;
        cursor: text;
        position: relative;
    }
    .DraftEditor-root {
        font-size: 12px;
        font-family: 'Gordita-Regular';
        color: #000000;
    }

    .DraftEditor-editorContainer {
        min-height: 18px;
    }
    ${FocusContent} {
        width: auto;
        float: left;
        color: #999999;
        font-size: 12px;
        font-family: 'Gordita-Regular';
        position: absolute;
        left: 12px;
        top: 20px;
        line-height: 10px;
        transition: 0.26s ease-in;
        span {
            font-size: 12px;
        }
    }
    .input_selected_dialog ${FocusContent} {
        top: 8px;
        font-size: 10px;
        transition: 0.26s ease-out;
        line-height: 10px;
        span {
            font-size: 10px;
        }
    }
    .focus_input ${FocusContent} {
        top: 8px;
        font-size: 10px;
        transition: 0.26s ease-out;
        line-height: 10px;
        span {
            font-size: 10px;
        }
    }
`
export const HoverInputContainer = styled.div`
    width: 100%;
    float: left;
    ${InputContainer} {
        :last-child {
            margin-bottom: 12px;
        }
        :hover {
            ${CancelButton} {
                display: flex;
                cursor: pointer;
            }
            ${Input} {
                box-shadow: 0px 3px 6px #00000029;
                border: 1px solid #eb974e;
                transition: 0.5s ease-in;
            }
        }
    }
`
export const PopupWrapper = styled.div`
    width: 480px;
    float: right;
    height: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    position: fixed;
    display: flex;
    height: calc(100vh - 66px);
    right: 2px;
    padding: 20px;
    z-index: 9999;
    bottom: 2px;
    flex-direction: column;
    .loader_center {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
`
export const EmptyDiv = styled.div`
    width: 100%;
    float: left;
`
export const PopupScrollHeight = styled.div`
    width: 100%;
    float: left;
    height: calc(100vh - 230px);
    overflow-y: scroll;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ::-webkit-scrollbar {
        width: 6px;
        display: block;
    }
    ::-webkit-scrollbar-thumb {
        background: #ccc;
        height: 80px;
        border-radius: 5px;
    }
    ::-webkit-scrollbar-track {
        background: inherit;
    }
`
export const ButtonContainer = styled.div`
    width: 100%;
    float: left;
    position: relative;
    bottom: 0;
    background: inherit;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    ${Button} {
        margin: 0px;
        :first-child {
            margin-right: 20px;
        }
    }
    .btn.btn-success.variable_update{
        background: #fff; 
        border: 1px solid rgb(185, 185, 185);
        color: rgb(51, 51, 51);
        :hover{
            background: #ffe5d3;
            color: #333;
            border: 1px solid #ffe5d3;
        }
    }
`
export const ActionPanelPopup = styled.div`
    width: 100%;
    float: left;
    .flow_canvas_popup_error {
        height: 89vh;

        p {
            font-family: 'Gordita-Medium';
            font-size: 16px;
            color: #000;
        }
    }

    ${DropdownUlWrapper} {
        margin-bottom: 0px;
    }
    .connection_new_autoamtion {
        ${InputContainer} {
            margin-bottom: 20px;
        }
    }

    .dropdown_app_icon {
        img {
            width: 24px;
            height: 24px;
        }
    }
    .application_dropdown_content {
        font-size: 12px;
        color: #000;
        margin-left: 6px;
        position: relative;
        top: 1px;
        line-height: 14px;
    }
    .input_img_left {
        position: absolute;
        margin-top: 0;
        width: 18px;
        height: 18px;
        cursor: pointer;
        float: left;
        margin-left: 12px;
        top: 20px;
    }
    .automation_scroly {
        margin-top: -22px;
    }

    .shuedular_input.ask_ques_editor_input{
        .automation_scroly{
            margin-top: 0px;
        }
    }
    
    .autoamtion_connection {
        .automation_scroly {
            margin-top: -20px;
        }
    }

    .autoamtion_connection.scheduler_popup{
        .automation_scroly {
            margin-top: 0px;
        }
    }

    .connection_new_autoamtion_button.action_popup{
        .connect_account_success,
        .connect_account_loading,
        .connect_account_failed{
            left: -9px;
        }
    }

    .connection_new_autoamtion_button {
        padding: 0px;
        background: inherit;
        .connect_account_success,
        .connect_account_loading,
        .connect_account_failed {
            width: 18px;
            height: 18px;
            position: relative;
            left: 6px;
            top: -2px;
            .error_input_show {
                position: relative;
                left: 0px;
                bottom: 0px;
                svg {
                    width: 16px;
                    height: 16px;
                    top: 6px;
                }
            }
        }
        .connect_account_success{
            top: -10px;
            left: -8px;
        }
        
    }
    .error_handle_autoamtion {
        h4 {
            font-family: 'Gordita-Medium';
            font-size: 16px;
            color: #000;
            margin-bottom: 6px;
            margin-top: 0px;
            line-height: 18px;
            display: flex;
            justify-content: flex-start;
        }
        p {
            font-family: 'Gordita-Regular';
            font-size: 11px;
            color: #355d8b;
            margin-bottom: 0px;
            margin-top: 0px;
            line-height: 16px;
        }
    }
    .target.customTip {
        pointer-events: initial;
        cursor: pointer;
        margin-left: 6px;
        position: relative;

        svg {
            width: 12px;
            height: 12px;
            position: relative;
            top: 2px;
        }
    }
    .target.customTip._description svg {
        width: 9px;
        height: 9px;
        position: relative;
        top: 1px;
        right: 3px;
    }

`
