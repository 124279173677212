/*eslint-disable*/
import * as React from 'react'

import emptyImg from '../images/empty_img.png'
import pageError from '../images/page_error.png'
import resourceNotFOund from '../images/resourcenotfound.svg'
import unAuthorisedErrorImg from '../images/unauthorized_401.png'
import { Button } from '../styled/Workflow.Dumb'
import { redirectWorkspace } from '../utils/utils'

// import { RetryIcon } from './Icons'
// import { PageWrapper } from './Header'
let Parser = require('html-react-parser')

export class ErrorBoundary extends React.Component<any> {
    state = {
        error: false,
        errorInfo: `Server error!<br />Please try again after sometime.`
    }
    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { error: true }
    }

    // componentDidCatch(error: any, info: any) {
    //     // You can also log the error to an error reporting service
    //     this.setState({
    //         error: error,
    //         errorInfo: info
    //     });
    //

    render() {
        let { error, info } = this.props.error
        info = info ? info : this.state.errorInfo
        const {
            noDataFound,
            unauthorized,
            resourceNotFound,
            notDeployed,
            notDeployedFn,
            notDeployedTitle,
            notDeployedDescription
        } = this.props
        if (notDeployed) {
            return (
                <Error.notDeployedYet
                    onClick={notDeployedFn}
                    title={notDeployedTitle}
                    description={notDeployedDescription}
                />
            )
        } else if (resourceNotFound) {
            return <Error.ResourceNotFound onClick={redirectWorkspace} />
        } else if (unauthorized) {
            return <Error.Unauthorized onClick={redirectWorkspace} />
        } else if (noDataFound) {
            const { show, noDatainfo, onClick, btnName, ButtonElement, firstParahText } = noDataFound
            if (noDataFound && show) {
                return (
                    <Error.NoDataMsg
                        info={noDatainfo}
                        onClick={onClick}
                        btnName={btnName}
                        ButtonElement={ButtonElement}
                        firstParahText={firstParahText}
                    />
                )
            }
        }

        if (this.state.error || error) {
            // You can render any custom fallback UI
            return this.props.render && this.props.render(error, info)
        }

        return this.props.children
    }
}

type ErrorT = {
    PageLoadingError: React.SFC<any>
    AddorEdit: React.SFC<any>
    Delete: React.SFC<any>
    Move: React.SFC<any>
    NoDataMsg: React.SFC<any>
    Unauthorized: React.SFC<any>
    ResourceNotFound: React.SFC<any>
    notDeployedYet: React.SFC<any>
}

export const ErrorWrapper = (props: any) => (
    <section className="entity_cards entity_cards_without_scroll">
        <div className="col-md-12">
            <div className="row">
                <div className="nodata_found">
                    <div className="nodata_found_height">
                        <div className="nodata_found_width">
                            <div className="row">
                                <div className="col-md-12 col-lg-12 nodata_found_width_left">
                                    <div className="expired_alert_left_bottom_img">{props.children}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export const Error: ErrorT = {
    notDeployedYet: ({ onClick, title, description }) => (
        <div className="no_data_found">
            <ErrorWrapper>
                <img src={emptyImg} text-align="middle" alt="" title="" />
                <p>
                    {' '}
                    {title ? title : 'No logs available!'} <br />
                    {description ? description : 'Publish and use your bot to view logs.'}
                </p>
                <div className="nodata_found_button">
                    <Button type="submit" className="btn btn-primary" onClick={() => onClick()}>
                        <label>{title ? 'Go to bots home' : 'Go to homepage'}</label>
                    </Button>
                </div>
            </ErrorWrapper>
        </div>
    ),
    PageLoadingError: ({ onClick, info, btnName }) => (
        <div className="empty_automation_image">
            <ErrorWrapper>
                <img src={pageError} text-align="middle" alt="nodata_found" title="nodata_found" />
                <p className="first_parah_error_text">OOPS!</p>
                <p>We caught up by a glitch</p>
                {/* <p>{Parser(info)}</p> */}
                <div className="nodata_found_button">
                    <Button type="submit" onClick={onClick}>
                        <label>{btnName}</label>
                    </Button>
                </div>
            </ErrorWrapper>
        </div>
    ),
    AddorEdit: ({ onClick, info, buttonName }) => (
        <div className="error_style">
            <p>{Parser(info)}</p>
            <Button primary onClick={onClick}>
                <label>{buttonName ? buttonName : 'Okay'}</label>
            </Button>
        </div>
    ),
    Delete: ({ onClick, info, className }) => (
        <div className={className ? `error_style ${className}` : 'error_style'}>
            <p>{Parser(info)}</p>
            <Button primary onClick={onClick}>
                <label>Okay</label>
            </Button>
        </div>
    ),
    Move: ({ onClick, info }) => (
        <div className="error_style_move">
            <p>{Parser(info)}</p>
            <Button primary onClick={onClick}>
                Okay
            </Button>
        </div>
    ),
    Unauthorized: ({ onClick }) => (
        <ErrorWrapper>
            <img src={unAuthorisedErrorImg} text-align="middle" alt="" title="" />
            <p>You don't have access to this workspace</p>
            <div className="nodata_found_button">
                <Button type="submit" onClick={onClick}>
                    <label>Go to home</label>
                </Button>
            </div>
        </ErrorWrapper>
    ),
    ResourceNotFound: ({ onClick }) => (
        <ErrorWrapper>
            <img src={resourceNotFOund} text-align="middle" alt="" title="" />
            <p>Resource not found</p>
            <div className="nodata_found_button">
                <Button primary type="submit" onClick={() => window.history.back()}>
                    <label>Go back</label>
                </Button>
            </div>
        </ErrorWrapper>
    ),
    NoDataMsg: ({ info, onClick, btnName, ButtonElement, firstParahText }) => (
        <div className="empty_automation_image" data-testid="NO-DATA-MESSAGE">
            <ErrorWrapper>
                <img src={emptyImg} text-align="middle" alt="" title="" />
                {firstParahText && <p className="first_parah_text">{firstParahText}</p>}
                <p className="empty_automation_para">{Parser(info)}</p>
                <div className="nodata_found_button">
                    {btnName ? (
                        <Button primary type="submit" onClick={onClick}>
                            <label>{btnName}</label>
                        </Button>
                    ) : (
                        ButtonElement
                    )}
                </div>
            </ErrorWrapper>
        </div>
    )
}
