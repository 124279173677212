/*eslint-disable*/
import React from 'react'
import Downshift from 'downshift'

interface DropDownP {
    isOpen: boolean
    onOuterClick: Function
    children: React.ReactChild
}

export const DropDown = (props: DropDownP) =>
    props.isOpen ? (
        <Downshift isOpen={props.isOpen} onOuterClick={() => props.onOuterClick()}>
            {() => props.children}
        </Downshift>
    ) : null

interface ButtonP {
    onSave: Function
    onCancel: Function
    disable: boolean
}

export const Button = (props: ButtonP) => (
    <div className="right-panel_btn">
        <button className="btn btn-primary" type="button" data-dismiss="modal" onClick={() => props.onCancel()}>
            Cancel
        </button>

        <button
            className={props.disable ? 'btn btn-success editor_btn_disabled' : 'btn btn-success'}
            type="button"
            data-dismiss="modal"
            onClick={() => props.onSave()}
        >
            Save
        </button>
    </div>
)
