// React common import's
/*eslint-disable*/
import React, { useContext, useState } from 'react'

// Icon's import
import {
    AddIcon,
    DeleteIcon,
    DialogIcon,
    EditIcon,
    ExitIcon,
    FlowIcon,
    JumpIcon,
    MoreIcon,
    NextIconCanvas,
    OptionIcon,
    QaIcon,
    ResponseIcon,
    SlotsIcon
} from '@/common/Icons/Icons'

// Page common context import's
import { DialogTreeContext, PageContext } from '../Actions/ReactContext'
// types import
import { OPERATIONS } from '../Types/PageT'
import {
    AskQueryDialogT,
    AutomationDialogT,
    DialogT,
    ExitDialogT,
    JumpDialogT,
    OptionsResponseDialogT,
    RecognizeDialogT,
    SlotContainerDialogT,
    TextResponseDialogT
} from '../Types/WatsonT'
// Page common thinks import
import { DropDown } from './Common'
import { DraftContent } from './DraftContent'
import {
    CreateAutomationDialog,
    CreateExitDialog,
    CreateJumpToDialog,
    CreateOptionsDialog,
    CreateQueryDialog,
    CreateRecognizeDialog,
    CreateSlotContainerDialog,
    CreateTextDialog,
    Designer,
    EditDelete,
    EditDialog,
    RequireHook,
    isSelected
} from './TreeHelper'

const R = require('ramda')

export const DialogTree = () => {
    const [appendChild, setAppendChild] = useState<string | null>(null)
    const [addChildInLast, setAddChildInLast] = useState<string | null>(null)
    const [addChildInBetween, setAddChildInBetween] = useState<string | null>(null)

    const PageContextD = useContext(PageContext)
    const DialogTreeContextD = useContext(DialogTreeContext)

    return (
        <PageContext.Consumer>
            {({ currentDialog, fetchChild }) => (
                <ul className="tree zontal_lines">
                    <DialogTreeContext.Provider
                        value={{
                            appendChild,
                            setAppendChild,
                            addChildInLast,
                            setAddChildInLast,
                            addChildInBetween,
                            setAddChildInBetween,
                            JumpToDialog: (previousSibling: DialogT, nextSibling: DialogT, parent: RecognizeDialogT) =>
                                CreateJumpToDialog(
                                    PageContextD,
                                    DialogTreeContextD,
                                    previousSibling,
                                    nextSibling,
                                    parent
                                ),
                            AutomationDialog: (
                                previousSibling: DialogT,
                                nextSibling: DialogT,
                                parent: RecognizeDialogT
                            ) =>
                                CreateAutomationDialog(
                                    PageContextD,
                                    DialogTreeContextD,
                                    previousSibling,
                                    nextSibling,
                                    parent
                                ),
                            ExitDialog: (previousSibling: DialogT, nextSibling: DialogT, parent: RecognizeDialogT) =>
                                CreateExitDialog(
                                    PageContextD,
                                    DialogTreeContextD,
                                    previousSibling,
                                    nextSibling,
                                    parent
                                ),
                            OptionsDialog: (previousSibling: DialogT, nextSibling: DialogT, parent: RecognizeDialogT) =>
                                CreateOptionsDialog(
                                    PageContextD,
                                    DialogTreeContextD,
                                    previousSibling,
                                    nextSibling,
                                    parent
                                ),
                            RecognizeDialog: (
                                previousSibling: DialogT,
                                nextSibling: DialogT,
                                parent: RecognizeDialogT
                            ) =>
                                CreateRecognizeDialog(
                                    PageContextD,
                                    DialogTreeContextD,
                                    previousSibling,
                                    nextSibling,
                                    parent
                                ),
                            TextDialog: (previousSibling: DialogT, nextSibling: DialogT, parent: RecognizeDialogT) =>
                                CreateTextDialog(
                                    PageContextD,
                                    DialogTreeContextD,
                                    previousSibling,
                                    nextSibling,
                                    parent
                                ),
                            QueryDialog: (previousSibling: DialogT, nextSibling: DialogT, parent: RecognizeDialogT) =>
                                CreateQueryDialog(
                                    PageContextD,
                                    DialogTreeContextD,
                                    previousSibling,
                                    nextSibling,
                                    parent
                                ),
                            SlotContainerDialog: (
                                previousSibling: DialogT,
                                nextSibling: DialogT,
                                parent: RecognizeDialogT
                            ) =>
                                CreateSlotContainerDialog(
                                    PageContextD,
                                    DialogTreeContextD,
                                    previousSibling,
                                    nextSibling,
                                    parent
                                ),
                            EditDialog: (node: DialogT) => EditDialog(PageContextD, DialogTreeContextD, node)
                        }}
                    >
                        <li className="first_add_node">
                            <TreeRow dialog={currentDialog} className={'col-md-12 parent_node parent_node_left'} />
                            <ul className="">
                                {fetchChild(currentDialog)
                                    .filter((e: DialogT) => e.type == 'if')
                                    .map((node: RecognizeDialogT, index: number) => (
                                        <Presentation key={index} dialog={node} />
                                    ))}
                            </ul>
                        </li>
                    </DialogTreeContext.Provider>
                </ul>
            )}
        </PageContext.Consumer>
    )
}

type PresentationP = {
    dialog: RecognizeDialogT
}

const Presentation = ({ dialog }: PresentationP) => (
    <PageContext.Consumer>
        {({ fetchChild }) => (
            <li>
                <input type="checkbox" checked onChange={() => {}} />
                <TreeRow dialog={dialog} className={'col-md-12 parent_node'} />
                <ul className="">
                    {fetchChild(dialog)
                        .filter((e: DialogT) => e.type == 'if')
                        .map((node: RecognizeDialogT, index: number) => (
                            <Presentation key={index.toString()} dialog={node} />
                        ))}
                </ul>
            </li>
        )}
    </PageContext.Consumer>
)

type TreeRowP = {
    dialog: RecognizeDialogT
    className: string
}

const TreeRow = ({ dialog, className }: TreeRowP) => (
    <PageContext.Consumer>
        {({ isDetailView, fetchChild }) => (
            <label className="tree_label">
                <div className="first">
                    <div
                        className={
                            isDetailView == false
                                ? `${className}`
                                : `${className} dialog_detail_account dialog_detail_box_shadow`
                        }
                    >
                        {isDetailView ? <DialogDetailCard dialog={dialog} /> : <DialogCard dialog={dialog} />}
                    </div>

                    {fetchChild(dialog)
                        .filter((e: any) => e.type !== 'if')
                        .map(
                            (
                                node:
                                    | TextResponseDialogT
                                    | OptionsResponseDialogT
                                    | SlotContainerDialogT
                                    | AskQueryDialogT
                                    | JumpDialogT
                                    | AutomationDialogT
                                    | ExitDialogT,
                                index: number
                            ) =>
                                isDetailView ? (
                                    <div key={index} className="dialog_tree_render ">
                                        <MedianHook parent={dialog} dialog={node} />
                                        <DialogDetailNode dialog={node} />
                                    </div>
                                ) : (
                                    <div key={index} className="dialog_tree_render ">
                                        <MedianHook parent={dialog} dialog={node} />
                                        <DialogNode dialog={node} />
                                    </div>
                                )
                        )}

                    {RequireHook(dialog, fetchChild) ? <FinalHook dialog={dialog} /> : null}
                </div>
            </label>
        )}
    </PageContext.Consumer>
)

interface DialogNodeP {
    dialog:
        | TextResponseDialogT
        | OptionsResponseDialogT
        | SlotContainerDialogT
        | AskQueryDialogT
        | JumpDialogT
        | AutomationDialogT
        | ExitDialogT
}

const DialogNode = ({ dialog }: DialogNodeP) => (
    <DialogTreeContext.Consumer>
        {({ EditDialog }) => (
            <PageContext.Consumer>
                {({ PageState, setPageState }) => (
                    <div className={isSelected(PageState, dialog.dialog_node) ? 'child_node_selected' : 'child_node'}>
                        <Designer dialog={dialog} onClick={() => EditDialog(dialog)} />

                        <h4 onClick={() => EditDialog(dialog)}>
                            {dialog.type === 'action' &&
                                dialog.jump_to &&
                                dialog.jump_to.dialog_node == 'feedback' &&
                                'Exit'}
                            {dialog.type === 'action' &&
                                dialog.jump_to &&
                                dialog.jump_to.dialog_node == 'exit' &&
                                'Exit'}
                            {dialog.type === 'action' &&
                                dialog.context.SYS_ACTIONS &&
                                dialog.context.SYS_ACTIONS.length == 2 &&
                                'Automation'}
                            {dialog.type === 'action' &&
                                dialog.metadata.hide_jump_to == false &&
                                dialog.jump_to &&
                                dialog.jump_to.dialog_node != 'feedback' &&
                                dialog.jump_to.dialog_node != 'exit' &&
                                'Jump To'}
                            {dialog.type === 'action' &&
                                dialog.metadata.hide_jump_to == false &&
                                dialog.jump_to == null &&
                                'Jump To'}
                            {dialog.type === 'text-response' && 'Send a Message'}
                            {dialog.type === 'qa' && 'Ask a Question'}
                            {dialog.type === 'option-response' && 'Options'}
                            {dialog.type === 'slot-container' && 'Verify User Details'}
                        </h4>

                        <EditDelete
                            onEdit={() => EditDialog(dialog)}
                            onDelete={() => setPageState({ operation: OPERATIONS.DeleteView, input: dialog })}
                        />
                    </div>
                )}
            </PageContext.Consumer>
        )}
    </DialogTreeContext.Consumer>
)

interface DialogCardP {
    dialog: RecognizeDialogT
}

const DialogCard = ({ dialog }: DialogCardP) => {
    const wrapperContext = useContext(PageContext)
    const childrens = wrapperContext.fetchChild(dialog)
    const previousSibling = childrens.filter((e: DialogT) => e.type != 'if').length
        ? childrens.filter((e: DialogT) => e.type != 'if').pop()
        : null
    const nextSibling = childrens.filter((e: DialogT) => e.type == 'if').length
        ? childrens.filter((e: DialogT) => e.type == 'if')[0]
        : null

    return (
        <PageContext.Consumer>
            {({ setPageState }) => (
                <DialogTreeContext.Consumer>
                    {({ appendChild, setAppendChild, RecognizeDialog, EditDialog }) => (
                        <React.Fragment>
                            {appendChild == null || appendChild != dialog.dialog_node ? (
                                <span onClick={() => setAppendChild(dialog.dialog_node)}>
                                    <MoreIcon />
                                </span>
                            ) : (
                                <DropDown
                                    isOpen={appendChild == dialog.dialog_node}
                                    onOuterClick={() => setAppendChild(null)}
                                >
                                    <span>
                                        <div className="dropdown_right dilaog_addchild_hover">
                                            <ul>
                                                <li
                                                    onClick={() =>
                                                        RecognizeDialog(previousSibling, nextSibling, dialog)
                                                    }
                                                >
                                                    Add Child Node
                                                </li>
                                            </ul>
                                        </div>
                                    </span>
                                </DropDown>
                            )}

                            {dialog.dialog_node == 'welcome' || dialog.dialog_node == 'fallback' ? (
                                <span />
                            ) : (
                                <span onClick={() => setPageState({ operation: OPERATIONS.DeleteView, input: dialog })}>
                                    <DeleteIcon />
                                </span>
                            )}
                            {dialog.dialog_node == 'welcome' || dialog.dialog_node == 'fallback' ? (
                                <span />
                            ) : (
                                <span onClick={() => EditDialog(dialog)}>
                                    <EditIcon />
                                </span>
                            )}

                            <div
                                className="parent_node_no"
                                onClick={() => {
                                    dialog.dialog_node === 'welcome' || dialog.dialog_node === 'fallback'
                                        ? null
                                        : EditDialog(dialog)
                                }}
                            >
                                <span className="parent_svg">
                                    <DialogIcon />
                                </span>
                            </div>

                            <h4
                                onClick={() => {
                                    dialog.dialog_node === 'welcome' || dialog.dialog_node === 'fallback'
                                        ? null
                                        : EditDialog(dialog)
                                }}
                            >
                                {dialog.title}
                            </h4>
                        </React.Fragment>
                    )}
                </DialogTreeContext.Consumer>
            )}
        </PageContext.Consumer>
    )
}

interface MedianHookP {
    dialog: DialogT
    parent: RecognizeDialogT
}

const MedianHook = ({ dialog, parent }: MedianHookP) => (
    <PageContext.Consumer>
        {({ isDetailView, fetchChild }) => (
            <DialogTreeContext.Consumer>
                {({ addChildInBetween, setAddChildInBetween, TextDialog, QueryDialog, SlotContainerDialog }) =>
                    addChildInBetween != dialog.dialog_node ? (
                        <div className={isDetailView ? 'dialog_tree_next dialog_tree_next_detail' : 'dialog_tree_next'}>
                            <div className="dialog_tree_next_toggle">
                                <div className="downarrow_hide_right">
                                    <NextIconCanvas />
                                </div>
                                <div className="dialog_tree_next_hover">
                                    <div className="child_node_button">
                                        <div className="parent_node_no">
                                            <span
                                                className="parent_svg"
                                                onClick={e => setAddChildInBetween(dialog.dialog_node)}
                                            >
                                                <AddIcon />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <React.Fragment>
                            <div
                                className={
                                    isDetailView ? 'dialog_tree_next dialog_tree_next_detail' : 'dialog_tree_next'
                                }
                            >
                                <div className="dialog_tree_next_toggle">
                                    <div className="dialog_tree_next_hover" style={{ display: 'block' }}>
                                        <div className="child_node_button">
                                            <div className="parent_node_no">
                                                <span className="parent_svg" onClick={e => setAddChildInBetween(null)}>
                                                    <AddIcon />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <DropDown
                                isOpen={addChildInBetween == dialog.dialog_node}
                                onOuterClick={() => setAddChildInBetween(null)}
                            >
                                <div className="dialog_tree_render dropdown_content plus_dropdown_list dialog_options_dynamic">
                                    <div className="drpdown_dialog">
                                        <div
                                            className="drodown_li_span"
                                            onClick={() => {
                                                console.log('drpdown_dialog click')
                                                const childrens = fetchChild(parent)

                                                const currentDialog = childrens
                                                    .filter((e: DialogT) => e.dialog_node == dialog.dialog_node)
                                                    .pop()
                                                const previousSibling = childrens
                                                    .filter(
                                                        (e: DialogT) => e.dialog_node == currentDialog.previous_sibling
                                                    )
                                                    .pop()

                                                setAddChildInBetween(null)
                                                TextDialog(
                                                    previousSibling ? previousSibling : null,
                                                    currentDialog,
                                                    parent
                                                )
                                            }}
                                        >
                                            <span>
                                                <ResponseIcon />
                                            </span>
                                            <label>Send a Message</label>
                                        </div>

                                        <div
                                            className="drodown_li_span"
                                            onClick={() => {
                                                const childrens = fetchChild(parent)
                                                const currentDialog = childrens
                                                    .filter((e: DialogT) => e.dialog_node == dialog.dialog_node)
                                                    .pop()
                                                const previousSibling = childrens
                                                    .filter(
                                                        (e: DialogT) => e.dialog_node == currentDialog.previous_sibling
                                                    )
                                                    .pop()

                                                setAddChildInBetween(null)
                                                QueryDialog(
                                                    previousSibling ? previousSibling : null,
                                                    currentDialog,
                                                    parent
                                                )
                                            }}
                                        >
                                            <span>
                                                <QaIcon />
                                            </span>
                                            <label>Ask a Question</label>
                                        </div>
                                        <div
                                            className="drodown_li_span"
                                            onClick={() => {
                                                const childrens = fetchChild(parent)
                                                const currentDialog = childrens
                                                    .filter((e: DialogT) => e.dialog_node == dialog.dialog_node)
                                                    .pop()
                                                const previousSibling = childrens
                                                    .filter(
                                                        (e: DialogT) => e.dialog_node == currentDialog.previous_sibling
                                                    )
                                                    .pop()

                                                setAddChildInBetween(null)
                                                SlotContainerDialog(
                                                    previousSibling ? previousSibling : null,
                                                    currentDialog,
                                                    parent
                                                )
                                            }}
                                        >
                                            <span>
                                                <SlotsIcon />
                                            </span>
                                            <label>Verify User Details</label>
                                        </div>
                                    </div>
                                </div>
                            </DropDown>
                        </React.Fragment>
                    )
                }
            </DialogTreeContext.Consumer>
        )}
    </PageContext.Consumer>
)

interface FinalHookP {
    dialog: RecognizeDialogT
}
const FinalHook = ({ dialog }: FinalHookP) => (
    <PageContext.Consumer>
        {({ fetchChild }) => (
            <>
                <DialogTreeContext.Consumer>
                    {({
                        addChildInLast,
                        setAddChildInLast,
                        JumpToDialog,
                        AutomationDialog,
                        ExitDialog,
                        OptionsDialog,
                        TextDialog,
                        QueryDialog,
                        SlotContainerDialog
                    }) =>
                        addChildInLast == null || addChildInLast != dialog.dialog_node ? (
                            <div className="dialog_tree_render" onClick={() => setAddChildInLast(dialog.dialog_node)}>
                                <div className="dialog_tree_next">
                                    <div className="dialog_tree_next_toggle">
                                        <div className="downarrow_hide_right">
                                            <NextIconCanvas />
                                        </div>
                                        <div className="dialog_tree_next_hover">
                                            <div className="child_node_button">
                                                <div className="parent_node_no parent_node_no_select">
                                                    <span className="parent_svg">
                                                        <AddIcon />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <DropDown
                                isOpen={addChildInLast == dialog.dialog_node}
                                onOuterClick={() => setAddChildInLast(null)}
                            >
                                <div className="dialog_tree_render dropdown_content plus_dropdown_list">
                                    <div className="dropdown_content_add_dropdown">
                                        <div className="child_node_button">
                                            <div className="parent_node_no">
                                                <span className="parent_svg">
                                                    <AddIcon />
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="drpdown_dialog">
                                        <div
                                            className="drodown_li_span"
                                            onClick={() => {
                                                const childrens = fetchChild(dialog)
                                                const previousSibling = childrens.filter((e: DialogT) => e.type != 'if')
                                                    .length
                                                    ? childrens.filter((e: DialogT) => e.type != 'if').pop()
                                                    : null
                                                const nextSibling = childrens.filter((e: DialogT) => e.type == 'if')
                                                    .length
                                                    ? childrens.filter((e: DialogT) => e.type == 'if')[0]
                                                    : null

                                                setAddChildInLast(null)

                                                TextDialog(previousSibling, nextSibling, dialog)
                                            }}
                                        >
                                            <span>
                                                <ResponseIcon />
                                            </span>
                                            <label>Send a Message</label>
                                        </div>

                                        <div
                                            className="drodown_li_span"
                                            onClick={() => {
                                                const childrens = fetchChild(dialog)
                                                const previousSibling = childrens.filter((e: DialogT) => e.type != 'if')
                                                    .length
                                                    ? childrens.filter((e: DialogT) => e.type != 'if').pop()
                                                    : null
                                                const nextSibling = childrens.filter((e: DialogT) => e.type == 'if')
                                                    .length
                                                    ? childrens.filter((e: DialogT) => e.type == 'if')[0]
                                                    : null

                                                setAddChildInLast(null)

                                                QueryDialog(previousSibling, nextSibling, dialog)
                                            }}
                                        >
                                            <span>
                                                <QaIcon />
                                            </span>
                                            <label>Ask a Question</label>
                                        </div>

                                        <div
                                            className="drodown_li_span"
                                            onClick={() => {
                                                const childrens = fetchChild(dialog)
                                                const previousSibling = childrens.filter((e: DialogT) => e.type != 'if')
                                                    .length
                                                    ? childrens.filter((e: DialogT) => e.type != 'if').pop()
                                                    : null
                                                const nextSibling = childrens.filter((e: DialogT) => e.type == 'if')
                                                    .length
                                                    ? childrens.filter((e: DialogT) => e.type == 'if')[0]
                                                    : null

                                                setAddChildInLast(null)

                                                OptionsDialog(previousSibling, nextSibling, dialog)
                                            }}
                                        >
                                            <span>
                                                <OptionIcon />
                                            </span>
                                            <label>Options</label>
                                        </div>

                                        <div
                                            className="drodown_li_span"
                                            onClick={() => {
                                                const childrens = fetchChild(dialog)
                                                const previousSibling = childrens.filter((e: DialogT) => e.type != 'if')
                                                    .length
                                                    ? childrens.filter((e: DialogT) => e.type != 'if').pop()
                                                    : null
                                                const nextSibling = childrens.filter((e: DialogT) => e.type == 'if')
                                                    .length
                                                    ? childrens.filter((e: DialogT) => e.type == 'if')[0]
                                                    : null

                                                setAddChildInLast(null)

                                                JumpToDialog(previousSibling, nextSibling, dialog)
                                            }}
                                        >
                                            <span>
                                                <JumpIcon />
                                            </span>
                                            <label>Jump</label>
                                        </div>
                                        <div
                                            className="drodown_li_span"
                                            onClick={() => {
                                                const childrens = fetchChild(dialog)
                                                const previousSibling = childrens.filter((e: DialogT) => e.type != 'if')
                                                    .length
                                                    ? childrens.filter((e: DialogT) => e.type != 'if').pop()
                                                    : null
                                                const nextSibling = childrens.filter((e: DialogT) => e.type == 'if')
                                                    .length
                                                    ? childrens.filter((e: DialogT) => e.type == 'if')[0]
                                                    : null

                                                setAddChildInLast(null)

                                                SlotContainerDialog(previousSibling, nextSibling, dialog)
                                            }}
                                        >
                                            <span>
                                                <SlotsIcon />
                                            </span>
                                            <label>Verify User Details</label>
                                        </div>

                                        <div
                                            className="drodown_li_span"
                                            onClick={() => {
                                                const childrens = fetchChild(dialog)
                                                const previousSibling = childrens.filter((e: DialogT) => e.type != 'if')
                                                    .length
                                                    ? childrens.filter((e: DialogT) => e.type != 'if').pop()
                                                    : null
                                                const nextSibling = childrens.filter((e: DialogT) => e.type == 'if')
                                                    .length
                                                    ? childrens.filter((e: DialogT) => e.type == 'if')[0]
                                                    : null

                                                setAddChildInLast(null)

                                                AutomationDialog(previousSibling, nextSibling, dialog)
                                            }}
                                        >
                                            <span>
                                                <FlowIcon />
                                            </span>
                                            <label>Call an Automation</label>
                                        </div>

                                        <div
                                            className="drodown_li_span"
                                            onClick={() => {
                                                const childrens = fetchChild(dialog)
                                                const previousSibling = childrens.filter((e: DialogT) => e.type != 'if')
                                                    .length
                                                    ? childrens.filter((e: DialogT) => e.type != 'if').pop()
                                                    : null
                                                const nextSibling = childrens.filter((e: DialogT) => e.type == 'if')
                                                    .length
                                                    ? childrens.filter((e: DialogT) => e.type == 'if')[0]
                                                    : null

                                                setAddChildInLast(null)

                                                ExitDialog(previousSibling, nextSibling, dialog)
                                            }}
                                        >
                                            <span>
                                                <ExitIcon />
                                            </span>
                                            <label>Exit</label>
                                        </div>
                                    </div>
                                </div>
                            </DropDown>
                        )
                    }
                </DialogTreeContext.Consumer>

                <div className="final_hook" />
            </>
        )}
    </PageContext.Consumer>
)

type CardP = {
    dialog: RecognizeDialogT
}
function DialogDetailCard({ dialog }: CardP) {
    // const { Junction, Choose, Expand, Collapse, DesignJunction, Remove, Improve } = props

    // const parsed = parser(Junction.conditions as never)

    // const values = parsed.filter((e: any) => typeof e === 'string' || e.type != 'Combinator')

    const wrapperContext = useContext(PageContext)
    const childrens = wrapperContext.fetchChild(dialog)
    const previousSibling = childrens.filter((e: DialogT) => e.type != 'if').length
        ? childrens.filter((e: DialogT) => e.type != 'if').pop()
        : null
    const nextSibling = childrens.filter((e: DialogT) => e.type == 'if').length
        ? childrens.filter((e: DialogT) => e.type == 'if')[0]
        : null

    const conditions =
        dialog.conditions == 'welcome' || dialog.conditions == 'anything_else'
            ? [
                  {
                      name: dialog.conditions == 'welcome' ? 'welcome' : 'anything_else',
                      operator: 2,
                      type: 'Intent'
                  }
              ]
            : dialog.metadata.RICH_TEXT
            ? dialog.metadata.RICH_TEXT.filter(e => e.type != 'COMBINATOR')
            : [
                  {
                      name: 'NO CONDITION',
                      operator: 2,
                      type: 'NO_CONDITION'
                  }
              ]

    return (
        <PageContext.Consumer>
            {({ setPageState }) => (
                <DialogTreeContext.Consumer>
                    {({ appendChild, setAppendChild, RecognizeDialog, EditDialog }) => (
                        <React.Fragment>
                            <div className="dialog_detail_account_svgright">
                                {appendChild == null || appendChild != dialog.dialog_node ? (
                                    <span onClick={() => setAppendChild(dialog.dialog_node)}>
                                        <MoreIcon />
                                    </span>
                                ) : (
                                    <DropDown
                                        isOpen={appendChild == dialog.dialog_node}
                                        onOuterClick={() => setAppendChild(null)}
                                    >
                                        <span>
                                            <div className="dropdown_right dilaog_addchild_hover">
                                                <ul>
                                                    <li
                                                        onClick={() =>
                                                            RecognizeDialog(previousSibling, nextSibling, dialog)
                                                        }
                                                    >
                                                        Add Child Node
                                                    </li>
                                                </ul>
                                            </div>
                                        </span>
                                    </DropDown>
                                )}

                                {dialog.conditions === 'welcome' || dialog.conditions === 'anything_else' ? (
                                    <span />
                                ) : (
                                    <span
                                        onClick={() =>
                                            setPageState({ operation: OPERATIONS.DeleteView, input: dialog })
                                        }
                                    >
                                        <DeleteIcon />
                                    </span>
                                )}
                                {dialog.conditions === 'welcome' || dialog.conditions === 'anything_else' ? (
                                    <span />
                                ) : (
                                    <span onClick={() => EditDialog(dialog)}>
                                        <EditIcon />
                                    </span>
                                )}
                            </div>
                            <div className="parent_node_no" onClick={() => EditDialog(dialog)}>
                                <span className="parent_svg">
                                    <DialogIcon />
                                </span>
                            </div>
                            <h4 className="parent_h4" onClick={() => EditDialog(dialog)}>
                                {dialog.title}
                            </h4>

                            <div className="border-bottom_flow" />

                            {conditions.length > 0 ? (
                                <div className="dialog_detail_borderbottom" onClick={() => EditDialog(dialog)}>
                                    {conditions[0].type == 'Variable' ? <h6>Condition</h6> : null}
                                    {conditions[0].type == 'Intent' ? <h6>Intent</h6> : null}
                                    {conditions[0].type == 'Entity' ? <h6>Entity</h6> : null}
                                    {conditions[0].type == 'NO_CONDITION' ? <h6>No Condition</h6> : null}

                                    {conditions[0].type == 'NO_CONDITION' ? <p>recognise the dialog</p> : null}
                                    {conditions[0].type == 'Intent' ? <p>{conditions[0].name} is recognised</p> : null}
                                    {conditions[0].type == 'Entity' ? (
                                        <p>
                                            {conditions[0].operator == 'IS_PRESENT'
                                                ? `'${conditions[0].name}' is recognised`
                                                : null}
                                            {conditions[0].operator == 'IS'
                                                ? `'${conditions[0].name}' value is '${conditions[0].value}'`
                                                : null}
                                            {conditions[0].operator == 'IS_NOT'
                                                ? `'${conditions[0].name}' value is not '${conditions[0].value}'`
                                                : null}
                                        </p>
                                    ) : null}

                                    {conditions[0].type == 'Variable' ? (
                                        <>
                                            <DraftContent
                                                rawMeta={conditions[0].LHS_META}
                                                setRawMeta={(meta: any) => {}}
                                            />
                                            {conditions[0].operator}
                                            <DraftContent
                                                rawMeta={conditions[0].RHS_META}
                                                setRawMeta={(meta: any) => {}}
                                            />
                                        </>
                                    ) : null}
                                </div>
                            ) : (
                                <div className="dialog_detail_borderbottom" onClick={() => EditDialog(dialog)}>
                                    <h6>No Condition Found</h6>
                                </div>
                            )}
                            {conditions.slice(1, conditions.length).length > 0 ? (
                                <div className="add_respond" onClick={() => EditDialog(dialog)}>
                                    <span>+{conditions.slice(1, conditions.length).length}</span>
                                </div>
                            ) : null}
                        </React.Fragment>
                    )}
                </DialogTreeContext.Consumer>
            )}
        </PageContext.Consumer>
    )
}

function DialogDetailNode({ dialog }: DialogNodeP) {
    const ExitNode = (data: ExitDialogT, EditDialog: Function, feedback: boolean) => (
        <React.Fragment>
            <div className="get_details_bottom" onClick={() => EditDialog(dialog)}>
                <div className="get_detils_data bottom_get_details_table">
                    <p className="get_detils_data_p">
                        <DraftContent rawMeta={data.metadata.RICH_TEXT} setRawMeta={(meta: any) => {}} />
                    </p>
                    <h5>Need Feed Back</h5>
                    <h6>{feedback ? 'Yes' : 'NO'}</h6>
                </div>
            </div>
        </React.Fragment>
    )

    const FlowNode = (data: AutomationDialogT, EditDialog: Function) => (
        <React.Fragment>
            <div className="get_details_bottom2" onClick={() => EditDialog(dialog)}>
                <div className="get_detils_data get_detils_data_p_tab-cell">
                    <p className="get_detils_data_p">{data.metadata.automationName}</p>
                </div>
            </div>
        </React.Fragment>
    )
    const JumpNode = (data: JumpDialogT, EditDialog: Function) => (
        <React.Fragment>
            <div className="get_details_bottom2" onClick={() => EditDialog(dialog)}>
                <div className="get_detils_data get_detils_data_p_tab-cell">
                    <p className="get_detils_data_p">Jump Node</p>
                </div>
            </div>
        </React.Fragment>
    )
    return (
        <DialogTreeContext.Consumer>
            {({ EditDialog }) => (
                <PageContext.Consumer>
                    {({ PageState, setPageState }) => (
                        <div className="dialog_tree_render respond_dialog_page">
                            <div className="col-md-12 parent_node parent_node_left dialog_detail_account child_node_flowedi">
                                <div className="dialog_detail_account_svgright">
                                    <span className="icon_detail_right" onClick={() => EditDialog(dialog)}>
                                        <EditIcon />
                                    </span>
                                    <span
                                        className="icon_detail_right"
                                        onClick={() =>
                                            setPageState({ operation: OPERATIONS.DeleteView, input: dialog })
                                        }
                                    >
                                        <DeleteIcon />
                                    </span>
                                </div>
                                <div className="dialog_detail_child_no">
                                    <Designer dialog={dialog} onClick={() => EditDialog(dialog)} />
                                </div>
                                <h4 onClick={() => EditDialog(dialog)}>
                                    {dialog.type === 'action' &&
                                        dialog.jump_to &&
                                        dialog.jump_to.dialog_node == 'feedback' &&
                                        'exit'}
                                    {dialog.type === 'action' &&
                                        dialog.jump_to &&
                                        dialog.jump_to.dialog_node == 'exit' &&
                                        'exit'}
                                    {dialog.type === 'action' &&
                                        dialog.context.SYS_ACTIONS &&
                                        dialog.context.SYS_ACTIONS.length == 2 &&
                                        'Automation'}
                                    {dialog.type === 'action' &&
                                        dialog.metadata.hide_jump_to == false &&
                                        dialog.jump_to &&
                                        dialog.jump_to.dialog_node != 'feedback' &&
                                        dialog.jump_to.dialog_node != 'exit' &&
                                        'Jump To'}
                                    {dialog.type === 'text-response' && 'Send a Message'}
                                    {dialog.type === 'qa' && 'Ask a Question'}
                                    {dialog.type === 'option-response' && 'Options'}
                                    {dialog.type === 'slot-container' && 'Verify User Details'}
                                </h4>
                                <div className="border-bottom_flow" />

                                {dialog.type === 'action' &&
                                    dialog.context.SYS_ACTIONS &&
                                    dialog.context.SYS_ACTIONS.length == 2 &&
                                    FlowNode(dialog as AutomationDialogT, EditDialog)}
                                {dialog.type === 'action' &&
                                    dialog.jump_to &&
                                    dialog.jump_to.dialog_node == 'feedback' &&
                                    ExitNode(dialog as ExitDialogT, EditDialog, true)}
                                {dialog.type === 'action' &&
                                    dialog.jump_to &&
                                    dialog.jump_to.dialog_node == 'exit' &&
                                    ExitNode(dialog as ExitDialogT, EditDialog, false)}

                                {dialog.type === 'action' &&
                                    dialog.metadata.hide_jump_to == false &&
                                    dialog.jump_to &&
                                    dialog.jump_to.dialog_node != 'feedback' &&
                                    dialog.jump_to.dialog_node != 'exit' &&
                                    JumpNode(dialog as JumpDialogT, EditDialog)}

                                {dialog.type === 'text-response' ? (
                                    <React.Fragment>
                                        <div
                                            onClick={() => EditDialog(dialog)}
                                            className="dialog_detail_borderbottom dialog_detail_changes"
                                        >
                                            <DraftContent
                                                rawMeta={dialog.metadata.RICH_TEXT[0]}
                                                setRawMeta={(meta: any) => {}}
                                            />
                                        </div>

                                        {dialog.values.slice(1, dialog.values.length).length > 0 ? (
                                            <div className="add_respond" onClick={() => EditDialog(dialog)}>
                                                <span>+{dialog.values.slice(1, dialog.values.length).length}</span>
                                            </div>
                                        ) : null}
                                    </React.Fragment>
                                ) : null}
                                {dialog.type === 'option-response' ? (
                                    <div className="get_details_bottom1" onClick={() => EditDialog(dialog)}>
                                        <div className="get_detils_data  options_data_h5">
                                            <h4 className="get_detils_data_h4 ">
                                                <DraftContent
                                                    rawMeta={dialog.metadata.RICH_TEXT}
                                                    setRawMeta={(meta: any) => {}}
                                                />
                                            </h4>
                                            {dialog.options.slice(0, 1).map((e: any, index: number) => (
                                                <h5 className="get_detils_data_h5" key={index}>
                                                    <span>
                                                        <label>0{R.add(index, 1)}</label>
                                                    </span>
                                                    <p>{e.label}</p>
                                                </h5>
                                            ))}
                                            {dialog.options.slice(1, dialog.options.length).length > 0 ? (
                                                <div className="add_respond">
                                                    <span>
                                                        +{dialog.options.slice(1, dialog.options.length).length}
                                                    </span>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                ) : null}
                                {dialog.type === 'qa' ? (
                                    <React.Fragment>
                                        <div className="get_details_bottom1" onClick={() => EditDialog(dialog)}>
                                            <div className="get_detils_data">
                                                <h4 className="dialog_detail_changes">
                                                    <DraftContent
                                                        rawMeta={dialog.metadata.RICH_TEXT}
                                                        setRawMeta={(meta: any) => {}}
                                                    />
                                                    <h5>Save answer as : </h5>
                                                    <h6 className="get_detils_data_h6">{dialog.variable}</h6>
                                                </h4>
                                            </div>
                                        </div>
                                        {dialog.question.options ? (
                                            <div className="add_respond" onClick={() => EditDialog(dialog)}>
                                                <span>+{dialog.question.options.length}</span>
                                            </div>
                                        ) : null}
                                    </React.Fragment>
                                ) : null}
                                {dialog.type === 'slot-container' ? (
                                    <React.Fragment>
                                        {dialog.slots.slice(0, 1).map((e: any, index: number) => (
                                            <div
                                                className="get_details_bottom"
                                                key={index}
                                                onClick={() => EditDialog(dialog)}
                                            >
                                                <div className="get_detils_data bottom_get_details_table">
                                                    <p className="get_detils_data_p">{e.prompt}</p>
                                                    <h5>Save it as : </h5>
                                                    <h6>{e.variable.slice(1)}</h6>
                                                </div>
                                            </div>
                                        ))}
                                        {dialog.slots.slice(1, dialog.slots.length).length > 0 ? (
                                            <div className="add_respond" onClick={() => EditDialog(dialog)}>
                                                <span>+{dialog.slots.slice(1, dialog.slots.length).length}</span>
                                            </div>
                                        ) : null}
                                    </React.Fragment>
                                ) : null}
                                {/* {dialog.type === 'flow_call' ? (
                                    <React.Fragment>
                                        <div className="get_details_bottom2" onClick={() => Improve(dialog)}>
                                            <div className="get_detils_data get_detils_data_p_tab-cell">
                                                <p className="get_detils_data_p">{GainFlow(Junction.flow)}</p>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ) : null} */}
                            </div>
                        </div>
                    )}
                </PageContext.Consumer>
            )}
        </DialogTreeContext.Consumer>
    )
}
