/*eslint-disable*/

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { AssistantT } from '@/Assistant/Construction/kind';
import { getAssistants } from '@/Assistant/Mechanisms/action';
import { VideoButtonB } from '@/Videos/Videos';
import { AddIcon, ArrowIcon, WidgetIcon } from '@/common/Icons/Icons';
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary';
import { Header, HeaderChild } from '@/common/components/Header';
import { Loader } from '@/common/components/Loader';
import { Modal } from '@/common/components/Modal';
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb';
import { ButtonWrapper as DeleteButtonWrapper } from '@/common/styled/Dialogue.Dumb';

import { deleteJson, widgetIntegrationURL } from '@/common/utils/api-utils';
import { WidgetList } from './WidgetList';
import {WidgetTable} from './WidgteTable'
import {mockData } from './types';
import {
  Action,
  ErrorPage,
  Loading,
  Modes,
  NoDataFound,
  UnauthorizedPage,
  View,
  Edit,
  Create
} from './types';
import { MOCK_WIDGET } from '@/common/utils/utils';
import { WidgetManipulate } from './WidgetManiPulation';
import { WidgetManipulationWrapper } from './WidgetManipulationWrapper';
import { usePermissions } from '@/common/utils/auth-token-api-utils';

export const Pre = styled.pre`
  text-align: left;
  padding: 1.2em;
  border-radius: 6px;
  position: relative;

  & .token-line {
    line-height: 1.3em;
    height: 1.3em;
  }
`;

export const LineNo = styled.span`
  display: inline-block;
  width: 2em;
  user-select: none;
  opacity: 0.3;
`;

const CopyIcon = styled.span`
  width: 18px;
  height: 18px;
  position: absolute;
  right: 12px;
  fill: #fff;
  top: 10px;
  cursor: pointer;
`;

const BodyWrapper = styled.div`
  width: 100%;
  float: left;
  padding-left: 50px;
  padding-right: 40px;
  height: 100%;
`;

const Box = styled.div`
width: 48%;
float: left;
border: 1px solid #d1d1d1;
margin-bottom: 20px;
border-radius: 18px;
min-height: 40px;
background: #fff;
margin-top: 20px;
}
`;
const Row = styled.div`
  padding: 30px 0px 0px 0px;
  width: 100%;
  float: left;
  margin: 0px;
  height: calc(100vh - 130px);
`;
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 20px;
  align-items: center;
  width: 100%;
  float: left;
  margin-bottom: 12px;
  position: relative;
`;

const SvgIcon = styled.div`
  width: auto;
  float: left;
  width: 24px;
  height: 24px;
  float: left;
  position: relative;
  display: flex;
  align-items: center;

  svg {
    width: 18px;
    height: 18px;
    position: relative;
    top: 2px;
    cursor: pointer;
  }
  .side_menu_hvr {
    position: absolute;
    color: #fff;
    background: #232a44;
    left: -15px;
    margin-top: 0px;
    padding: 2px 10px;
    border-radius: 3px;
    visibility: hidden;
    font-size: 10px;
    font-family: 'Gordita-Regular';
    top: -22px;
  }
  :hover .side_menu_hvr {
    visibility: visible;
  }
`;
const HeaderDescriptions = styled.h4`
  font-size: 16px;
  font-family: 'Gordita-Medium';
  color: #000;
  margin-bottom: 0px;
`;

const OverallHeightWarpper = styled.div`
  width: 100%;
  float: left;
  padding: 0px 20px;
  height: calc(100vh - 230px);
  overflow-y: scroll;
`;

const SectionWrapper = styled.div`
  width: 100%;
  float: left;
  background: #f4f4f4;
  padding: 20px;
  margin-bottom: 10px;
`;
const FormWrapper = styled.div`
  flex-direction: column;
  width: 100%;
  float: left;
  padding: 0px;
  margin-bottom: 10px;
  display: flex;
`;
const FormHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  float: left;
  margin: 0px;
  font-family: 'Gordita-Medium';
  font-size: 12px;
  color: #000;
  margin-bottom: 10px;
  color: ${(props) => (props.color ? props.color : '#494949')};
`;
const FormWithoutInput = styled.div`
  margin: 0px;
  border: 1px solid #d1d1d1;
  width: 100%;
  height: 30px;
  padding-left: 10px;
  display: inline-flex;
  font-family: 'Gordita-Regular';
  align-items: center;
  font-size: 12px;
  border-radius: 6px;
  color: #000;
  background: #fff;
`;
const FormWithInput = styled.input`
  width: 100%;
  float: left;
  height: 31px;
  font-size: 10px;
  padding: 0px 10px;
  font-family: 'Gordita-Medium';
  border-radius: 6px;
  border: 0.5px solid #ccc;
  outline: 0;
  background: #fff;
`;
const DrodownMenu = styled.div`
  width: 100%;
  float: left;
  position: relative;
`;
const DrodownMenuOpen = styled.div`
  width: 100%;
  position: absolute;
  margin-top: 30px;
  border-radius: 8px;
  right: 0px;
  left: 0%;
  cursor: pointer;

  ul {
    padding: 0px;
    height: 100%;
    overflow-y: scroll;
    max-height: 305px;
    margin-bottom: 0px;
    box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
    border-radius: 6px;
    position: relative;
    width: 100%;
    float: left;
    z-index: 1;
  }
  li {
    width: 100%;
    float: left;
    display: inline-flex;
    align-items: center;
    border-bottom: 0.5px solid #e6e6e6;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    height: 30px;
    padding: 0 10px;
    background: #fff;
    cursor: pointer;
    :hover {
      background: #0a89e8;
      color: #fff;
      border-bottom: 0.5px solid #0a89e8;
    }

    :first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      background: #fff;
      :hover {
        background: #0a89e8;
        color: #fff;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom: 0.5px solid #0a89e8;
      }
    }
    :last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      background: #fff;
      border: none;
      :hover {
        background: #0a89e8;
        color: #fff;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border-bottom: 0.5px solid #0a89e8;
      }
    }
  }
`;
const Paragraph = styled.p`
  font-family: 'Gordita-Regular';
  font-size: 12px;
  margin-bottom: 0px;
  margin-top: 5px;
  width: 100%;
  color: ${(props) => (props.color ? props.color : '#607794')};
`;
const LogoWrapper = styled.div`
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
  button {
    width: 140px;
    height: 30px;
    font-family: 'Gordita-Medium';
    font-size: 12px;
    text-align: center;
    padding: 0 10px;
    border-radius: 18px;
    border: 0.5px solid #0a89e8;
    background: #0a89e8;
    color: #fff;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    float: left;
    margin-left: 20px;
    margin-top: 5px;
  }
`;
const LogoUpload = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
const ButtonUpload = styled.div`
  width: 88%;
  float: left;
  display: flex;
  flex-direction: column;

  button:last-child {
    background: none;
    border: none;
    color: #0a89e8;
    cursor: default;
    height: auto;
    margin-top: 5px;

    span {
      cursor: pointer;
    }
  }
`;
const LogoSize = styled.div`
  width: 64px;
  height: 64px;
  float: left;
  border: 1px solid #607794;
  border-radius: 50%;
  padding: 12px;

  img {
    width: 100%;
    height: 100%;
  }
`;

const ColourWrapper = styled.div`
  width: 100%;
  flaot: left;
`;
const ColourWrapperInputs = styled.div`
  width: 100%;
  float: left;

  ${FormWrapper} {
    width: 50%;
    float: left;
    position: relative;

    :first-child {
      padding-right: 8px;
    }
    :last-child {
      padding-left: 8px;
    }
    ${FormHeader} {
      font-family: 'Gordita-Regular';
      position: relative;
      .info_icon {
        width: auto;
        height: 100%;
        margin-left: 5px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;

        .side_menu_hvr {
          position: absolute;
          color: #fff;
          background: #232a44;
          left: inherit;
          margin-top: 0px;
          padding: 2px 10px;
          border-radius: 3px;
          display: none;
          font-size: 12px;
          font-family: 'Gordita-Regular';
          top: -25px;
          height: auto;
          right: -35px;
          width: max-content;
        }
        svg {
          width: 12px;
          height: 12px;
        }
        :hover svg ~ .side_menu_hvr {
          display: flex;
        }
      }
    }
  }
`;
const ColourPickerDot = styled.span`
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid #ccc;
    background:${(props) => (props.background ? props.background : '#fff')};
    position: absolute;
    right: 15px;
    top: 39px;
`;

const WidgetFormToggle = styled.section`
width:100%:
float:left;
`;
const WidgetToggleHeader = styled.div`
  width: 100%;
  float: left;
  border-bottom: 1px solid #ccc;
  padding: 20px 20px 0px 20px;
  background: #f4f4f4;

  .arrow_up_form {
    margin-top: 0px;
    left: 0px;

    svg {
      transform: rotate(90deg);
    }
  }

  ${FormHeader} {
    width: auto;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  float: left;

  .teams_add_workspace_btn svg {
    width: 22px;
    height: 22px;
  }
  .slack_button span {
    left: 0px;
  }
`;

export type workspace = {
  id: string;
  name: string;
  description: string;
  bot_id: string;
  workspace: string;
  is_active: boolean;
  logo_url: string;
  primary_color: string;
  secondary_color: string;
  accent_color: string;
  button_color: string;
  is_secured: boolean;
  public_key: null;
  updated_at: string;
  snippet:string
};

export const Widget = (props: any) => {
  const [state, setState] = useState<{
    bots: AssistantT[];
    workspaces: workspace[];
    PageState: Modes;
    mode: 'view' | 'delete'| 'Edit';
    details: workspace & { botName: string };
    PageError: any;
    popup: {
      showPopupLoader: boolean;
      modalError: { error: boolean; info: null | string };
    };
    deleteDetails: null | workspace;
    selectedWorkspace:workspace | [];
  }>({
    bots: [],
    workspaces: [],
    PageState: Loading,
    mode: 'view',
    details: {
      id: '',
      name: '',
      description: '',
      bot_id: '',
      workspace: '',
      is_active: false,
      logo_url: '',
      primary_color: '',
      secondary_color: '',
      accent_color: '',
      button_color: '',
      is_secured: false,
      public_key: null,
      updated_at: '',
      botName: '',
      snippet:'',
    },
    PageError: {},
    popup: { showPopupLoader: false, modalError: { error: false, info: null } },
    deleteDetails: null,
    selectedWorkspace:[],
  });

  const widgetWritePermission = usePermissions(props.match.params.workspacename,`CHAT_CHANNEL_MODULE/CHANNEL/WIDGET`,"WRITE")

  const [value, setValue]=useState<string>('');
  const setvaluefunction=(data:string)=>{
    setValue(data)
  }
  const setWorkspace = (value:workspace[])=>{
    console.log(value,"workspacewidget")
    setState({...state, workspaces:value,PageState:View,selectedWorkspace:[]})
  }
  useEffect(() => {
    if (props.state.type === 'loading') {
      setState({ ...state, PageState: Loading });
    } else if (props.state.type === 'error') {
      if (MOCK_WIDGET === false) {
        if (
          props.state.error.response &&
          props.state.error.response.status === 403
        ) {
          setState({
            ...state,
            PageState: UnauthorizedPage,
          });
        } else {
          setState({
            ...state,
            PageState: ErrorPage,
            PageError: {
              error: true,
              info: 'There was a problem in loading Page! <br/> Please click on "Retry"',
            },
          });
        }
      } else {
        setState({
          ...state,
          PageState: View,
          workspaces: mockData as any,
          bots: [],
        });
      }
    } else if (props.state.type === 'success') {
      getAssistants(props.match.params.workspacename)
        .then((data) => {
          const res = props.state.data;
          console.log(res, 'widgetres')
          if (res.length > 0) {
            setState({
              ...state,
              workspaces: res,
              PageState: View,
              bots: data,
            });
          } else {
            setState({
              ...state,
              workspaces: res,
              PageState: NoDataFound,
              bots: data,
            });
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 403) {
            setState({
              ...state,
              PageState: UnauthorizedPage,
            });
          } else {
            setState({
              ...state,
              PageState: ErrorPage,
              PageError: {
                error: true,
                info: 'There was a problem in loading Page! <br/> Please click on "Retry"',
              },
            });
          }
        });
    }
  }, [props.state]);
  
  function deleteWorkspace() {
    if (state.deleteDetails) {
      setState({ ...state, popup: { ...state.popup, showPopupLoader: true } });

      deleteJson(
        widgetIntegrationURL(
          `${props.match.params.workspacename}/${state.deleteDetails.bot_id}`,
          `/widget/delete/${state.deleteDetails.id}`
        )
      )()
        .then((res) => {
          const updatedWorkSpaces = state.workspaces.filter(
            (w) => w.id !== (state.deleteDetails ? state.deleteDetails.id : '')
          );

          updatedWorkSpaces.length === 0
            ? setState({
                ...state,
                mode: 'view',
                deleteDetails: null,
                PageState: NoDataFound,
                workspaces: updatedWorkSpaces,
              })
            : setState({
                ...state,
                mode: 'view',
                deleteDetails: null,
                workspaces: updatedWorkSpaces,
              });
        })
        .catch((err) => {
          setState({
            ...state,
            popup: {
              showPopupLoader: false,
              modalError: {
                info: 'Something went wrong! Please try again.',
                error: true,
              },
            },
          });
        });
    }
  }

  function renderDeleteModal() {
    if (state.mode === 'delete' && state.deleteDetails) {
      return (
        <Modal showPopupLoader={state.popup.showPopupLoader}>
          <ErrorBoundary
            error={state.popup.modalError}
            render={(err: any, info: any) => {
              return (
                err && (
                  <Error.Delete
                    onClick={() =>
                      setState({
                        ...state,
                        mode: 'view',
                        deleteDetails: null,
                        popup: {
                          showPopupLoader: false,
                          modalError: {
                            info: null,
                            error: false,
                          },
                        },
                      })
                    }
                    info={info}
                  />
                )
              );
            }}
          >
            <React.Fragment>
              <h2>Delete Widget </h2>
              <br></br>
              <p>
                Are you sure you want to delete {state.deleteDetails.name}{' '}
                integration?{' '}
              </p>{' '}
            </React.Fragment>

            <DeleteButtonWrapper>
              <Button
                // className="btn btn-success"
                type="button"
                primary
                onClick={() =>
                  setState({ ...state, mode: 'view', deleteDetails: null })
                }
              >
                <label> No</label>
              </Button>
              <Button type="button" onClick={() => deleteWorkspace()}>
                <label>Yes</label>
              </Button>
            </DeleteButtonWrapper>
          </ErrorBoundary>
        </Modal>
      );
    }

    return null;
  }
  console.log(state.PageState,'pagestate')

  const cancelWidget=()=>{
    state.workspaces.length>0 ? setState({ ...state, PageState:View, selectedWorkspace:[] }) :
    setState({ ...state, PageState:NoDataFound, selectedWorkspace:[] });
    setValue('');
  }
  const setToEditMode = (workspace:workspace)=>{
    
    setState({ ...state, PageState:Edit,selectedWorkspace: workspace});
    // console.log(state.PageState.action,state.selectedWorkspace,'pagestate')
  }
  const createNew = ()=>{
    setState({ ...state, PageState:Create});
  }
  if (state.PageState.action === Action.Loading)
    return <Loader.PageLoader show={true} />;

  return (
    <>
      <Header>
        {[
          <HeaderChild type="first">
            {/* <span
              className="buton_back inner_pages"
              onClick={() => window.history.back()}
            >
              <ArrowIcon /> 
              <span>Back</span>
            </span> */}
            <div className="indent_left">
              <div className="indent_title">Widget Chatbot</div>
              <div className="indent_sub_title">
              Catalog (
                      {state.workspaces.length == 0
                        ? '0'
                        : state.workspaces.length < 10
                        ? '0' + state.workspaces.length
                        : state.workspaces.length}
                      )
                    </div>
            </div>
            {/* {state.PageState.action === Action.View && ( */}
              {widgetWritePermission.value&&<div className="indent_right">
                <Button
                  priamry
                  // onClick={() => {
                  //   props.history.push(
                  //     `/workspace/${props.match.params.workspacename}/integration/widget/create`
                  //   );
                  // }}
                  onClick={createNew}
                  className="widget_button"
                >
                  <span className="header_button">
                    <AddIcon />
                  </span>
                  <label>Create New</label>
                </Button>
              </div>}
            {/* )} */}
            
          </HeaderChild>,
          <HeaderChild type="second">
            {/* {renderViewButton()} */}

            <VideoButtonB id={'DEPLOY_BOT_AS_CHAT_WIDGET_IN_WEBPAGE'} />
          </HeaderChild>,
        ]}
      </Header>
      <ErrorBoundary
        unauthorized={state.PageState.action === Action.Unauthorized}
        error={
          state.PageState.action !== Action.ErrorPage
            ? { error: null, info: null }
            : {
                error: true,
                info: state.PageError.info,
              }
        }
        noDataFound={{
          show: state.PageState.action === Action.NoDataFound,
          noDatainfo:
            '<p>Extend your chatbot experience on your slef-help portal.<br/>Deploy your chatbot as chat widget on your webpage with simple steps. Click create new widget to get started. </p>',
          // onClick: () => {
          //   props.history.push(
          //     `/workspace/${props.match.params.workspacename}/integration/widget/create`
          //   );
          // },
          onClick:()=>{createNew()},

          ButtonElement: (
            <ButtonWrapper>
              <button
                className="btn btn-primary slack_button teams_add_workspace_btn"
                // onClick={() => {
                //   props.history.push(
                //     `/workspace/${props.match.params.workspacename}/integration/widget/create`
                //   );
                // }}
                onClick={createNew}
              >
                <AddIcon />
                <span>Create new Widget</span>
              </button>
            </ButtonWrapper>
          ),
        }}
        render={(err: any, info: any) => {
          return (
            err && (
              <Error.PageLoadingError
                onClick={() => {
                  props.retry();
                }}
                info={info}
                btnName={'Retry'}
              />
            )
          );
        }}
        
      > 
      
        <WidgetTable
          list={state.workspaces}
          setToEditMode={setToEditMode}
          // {(details: workspace) => {
          //   props.history.push(
          //     `/workspace/${props.match.params.workspacename}/integration/widget/${details.id}`
          //   );
          // }}
          setToAddMode={() => {
            props.history.push(
              `/workspace/${props.match.params.workspacename}/integration/chat/widget/create`
            );
          }}
          setToDeleteMode={(details: workspace) => {
            setState({ ...state, mode: 'delete', deleteDetails: details });
          }}
          setvaluefunction={setvaluefunction}
          value={value}
          workspacename={props.match.params.workspacename}
        />
        
        {state.PageState.action === Action.Edit && 
          Object.keys(state.selectedWorkspace).length > 0 &&(
            <WidgetManipulate props={state.selectedWorkspace} type={"Update"} cancel={cancelWidget} setWorkspace={setWorkspace}/>
              )
            }
        {state.PageState.action === Action.Create && (
            <WidgetManipulate props={props.match.params.workspacename} type={"Create"} cancel={cancelWidget} setWorkspace={setWorkspace}/>
              )
            }
        {renderDeleteModal()}
      </ErrorBoundary>
    </>
  );
};
