/*eslint-disable*/

import { useCombobox } from 'downshift'
import React, { useEffect, useRef, useState } from 'react'
import Tooltip from 'react-tooltip-lite'

import { DialogueT } from '@/Assistant/Dialogue/kind'
import { sortBySibling } from '@/Assistant/DialogueCrew/action'
import { useJourney } from '@/Journey/JourneyContext'
import { VideoLink } from '@/Videos/Videos'
import { DropdownWithCircle, InfoIcon, QaIconWithCircle } from '@/common/Icons/Icons'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import {
    CancelButton,
    DropdownLi,
    DropdownUl,
    DropdownUlWrapper,
    DropdownWrapper,
    Input,
    InputContainer,
    InputContent
} from '@/common/styled/Workflow.Analytics.Dumb'
import { sortAnArrayByDate } from '@/common/utils/common.utils'

import { removeURLParams } from '@/common/utils/utils'
import { BgBox, ButtonWrapper, Header, HeaderLeft, HeaderRight, Paragraph, PopUpRight } from './BotMarketplace'
import { BotSearch, DialogSearch } from './BotMarketplaceSearch'
import { GetAllCategories, GetAllDialogs, GetBotIfNodes, SearchBots } from './MarketPlaceUtils'

type CategoryT = {
    name: string
    id: string
}
export type BotSearchT = {
    SearchList: Function
    inputValue: string
    setInputValue: Function
    renderCards: { isDialog: boolean; error: any; loading: boolean }
    categories: CategoryT[]
    category: CategoryT
    onChangeCategory: Function
    bots: BotT[]
    searchBot: string
    setSearchBot: Function
    getdialogs: Function
}
export type BotT = CategoryT & {
    decription: string
    downloaded_count: number
    createdTime: string
}
export type DialogT = {
    id: string
    title: string
    botId: string
    conditions: {
        type: string
    }
    createdTime: string
    decription: string
}
export type DialogSearchT = WrapperT & {
    renderCards: { isDialog: boolean; error: string; loading: boolean }
    setRenderCards: Function
    SearchList: Function
    bots: BotT[]
    workspace: string
    dialogs: DialogT[]
    getIfNodes: Function
    setOpenPopup: Function
    retry: Function
    botWritePermission:boolean
}
type WrapperT = {
    isActiveCard?: string
    isOpenLeft?: boolean
    isDialog?: boolean
}
export type CardWrapperT = CardBodyT & WrapperT
type CardBodyT = {
    card: any
    getCard: Function
}
type ComboboxCategoryT = {
    inputValue: string
    setInputValue: Function
    categories: CategoryT[]
    value: CategoryT
    onChange: Function
}
type ModelPopupT = {
    isOpenLeft: boolean
    setIsOpenLeft: Function
    workspace: string
    setOpenPopup: Function
    setLeftPopup: Function
    bots: BotT[]
    setBots: Function
    retry: Function
    botWritePermission:boolean
}
export const ModalPopup = (props: ModelPopupT) => {
    const ErrorMessage = 'Something went wrong! Please try again.'
    const { bots, retry, setBots, workspace, setOpenPopup, setLeftPopup, setIsOpenLeft, isOpenLeft } = props
    const journeyData = useJourney()

    const [categories, setCategories] = useState<CategoryT[]>([])
    const [category, setCategory] = useState<CategoryT>({
        id: '',
        name: 'All'
    })
    const [searchBot, setSearchBot] = useState<string>('')
    const [dialogs, setDialogs] = useState<DialogueT[]>([])
    const [inputValue, setInputValue] = useState<string>('')
    const [renderCards, setRenderCards] = useState({
        isDialog: false,
        error: '',
        loading: false
    })
    const onChangeCategory = (value: { name: string; id: string }) => setCategory(value)
    const GetDialogsForBot = (botId: string) => {
        setRenderCards({ isDialog: false, error: '', loading: true })
        GetAllDialogs(workspace, botId)
            .then(res => {
                setDialogs(res.data.output.filter((dialog: any) => dialog.parent === null))
                setRenderCards({
                    ...renderCards,
                    isDialog: true,
                    loading: false
                })
            })
            .catch(error => {
                setRenderCards({
                    isDialog: true,
                    error: ErrorMessage,
                    loading: false
                })
            })
    }
    const [isActiveCard, setIsActiveCard] = useState<string>('')
    const GetBotIfNodeList = (botId: string, ifNodeId: string) => {
        setIsActiveCard(ifNodeId)
        setIsOpenLeft(true)
        setLeftPopup({ metaData: {}, loading: true })
        GetBotIfNodes(workspace, botId, ifNodeId).then(res => {
            let metaData = res.data.output.find((ifnode: any) => ifnode.parent === null)
            setLeftPopup({ metaData, loading: false })
        })
    }
    useEffect(() => {
        GetAllCategories(workspace).then(res => {
            setCategories(res.data.output)
        })
    }, [])
    useEffect(() => {
        SearchBotList()
    }, [searchBot, category])
    const SearchBotList = () => {
        setRenderCards({ isDialog: false, error: '', loading: true })
        SearchBots(workspace, searchBot, category.id)
            .then(res => {
                setBots(res.data.output)
                setRenderCards({ ...renderCards, loading: false })
            })
            .catch(error => {
                setRenderCards({
                    isDialog: false,
                    error: ErrorMessage,
                    loading: false
                })
            })
    }
    return (
        <PopUpRight primary={!isOpenLeft ? 'primary' : null}>
            <Header>
                <HeaderLeft>
                    Bot Marketplace
                    <Tooltip
                        className="target customTip"
                        zIndex={10000}
                        arrowSize={8}
                        tagName="span"
                        content="Select and download the pre-build bot from the list. Modify the downloaded bot as per your requirement and publish the bot to deploy in your chat channels."
                        distance={5}
                    >
                        <InfoIcon />
                    </Tooltip>
                    <Tooltip
                        className="target customTip"
                        zIndex={10000}
                        arrowSize={8}
                        tagName="span"
                        content="View help document"
                        distance={5}
                    >
                        <a href="https://help.workativ.com/knowledgebase/download-bot/" target="_blank">
                            <QaIconWithCircle />
                        </a>
                    </Tooltip>
                </HeaderLeft>
                <HeaderRight>
                    <Paragraph cursor>
                        <VideoLink id={'DOWNLOAD_AND_SET_UP_PRE_BUILT_CHATBOT'} text={'How to'} />
                    </Paragraph>
                </HeaderRight>
            </Header>
            <Paragraph>Select and download pre-built bot</Paragraph>
            <BgBox>
                {!renderCards.isDialog ? (
                    <BotSearch
                        SearchList={SearchBotList}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        renderCards={renderCards}
                        getdialogs={GetDialogsForBot}
                        bots={sortAnArrayByDate(bots, 'updatedTime')}
                        searchBot={searchBot}
                        setSearchBot={setSearchBot}
                        categories={categories}
                        category={category}
                        onChangeCategory={onChangeCategory}
                    />
                ) : (
                    <DialogSearch
                        renderCards={renderCards}
                        setRenderCards={setRenderCards}
                        SearchList={GetBotIfNodeList}
                        isActiveCard={isActiveCard}
                        isOpenLeft={isOpenLeft}
                        bots={bots}
                        workspace={workspace}
                        dialogs={sortBySibling(dialogs as DialogueT[]) as any}
                        getIfNodes={GetBotIfNodeList}
                        setOpenPopup={setOpenPopup}
                        retry={retry}
                        botWritePermission={props.botWritePermission}
                    />
                )}
                <>
                    <div className="shadow_bottom"></div>
                </>
            </BgBox>
            <ButtonWrapper
                onClick={() => {
                    removeURLParams('download-from-marketplace')
                    setOpenPopup(false)
                    if (journeyData.type === 'success') {
                        const interperter = journeyData.value
                        interperter.send({
                            type: 'EVENT::BOT::DOWNLOAD::CANCELLED',
                            tag: 'GENERIC',
                            journeyType: 'Main',
                            refId: ''
                        })
                    }
                }}
            >
                <Button className="justify-content-center" primary>
                    <label>Cancel</label>
                </Button>
            </ButtonWrapper>
        </PopUpRight>
    )
}
export function ComboboxCategory({ inputValue, setInputValue, categories, value, onChange }: ComboboxCategoryT) {
    categories = [{ name: 'All', id: '' }, ...categories]
    const displayItem = (item: CategoryT | null) => (item ? item.name : '')
    const searchBox: any = useRef(null)
    const [inputItems, setInputItems] = useState(categories)
    const {
        isOpen,
        selectedItem,
        getToggleButtonProps,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        getItemProps
    } = useCombobox({
        items: inputItems,
        selectedItem: value,
        itemToString: displayItem,
        onSelectedItemChange: ({ selectedItem }) => {
            onChange(selectedItem)
        },
        inputValue,
        onInputValueChange: ({ inputValue }: any) => {
            setInputItems(
                categories.filter(({ name: item }: { name: string }) =>
                    item.toLowerCase().startsWith(inputValue.toLowerCase())
                )
            )
            setInputValue(inputValue)
        }
    })
    useEffect(() => {
        if (isOpen && searchBox.current) {
            searchBox.current.focus()
            setInputValue('')
            setInputItems(categories)
        }
    }, [isOpen])
    return (
        <DropdownWrapper>
            <InputContainer>
                <div {...getComboboxProps()}>
                    {!isOpen ? (
                        <Input {...getToggleButtonProps()} value={selectedItem && displayItem(selectedItem)} />
                    ) : (
                        <Input
                            {...getInputProps({
                                ref: searchBox
                            })}
                        />
                    )}
                    <InputContent>Select Category</InputContent>
                    <CancelButton>
                        <DropdownWithCircle />
                    </CancelButton>
                </div>
                <DropdownUlWrapper>
                    <DropdownUl {...getMenuProps()}>
                        {isOpen ? (
                            inputItems.length !== 0 ? (
                                inputItems.map((item: CategoryT, index: number) => {
                                    return (
                                        <DropdownLi key={`${item.name}${index}`} {...getItemProps({ item, index })}>
                                            {item.name}
                                        </DropdownLi>
                                    )
                                })
                            ) : (
                                <DropdownLi>No match found</DropdownLi>
                            )
                        ) : null}
                    </DropdownUl>
                </DropdownUlWrapper>
            </InputContainer>
        </DropdownWrapper>
    )
}
