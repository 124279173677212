import {
    DialogT,
    RecognizeDialogT,
    TextResponseDialogT,
    OptionsResponseDialogT,
    AskQueryDialogT,
    SlotContainerDialogT,
    AutomationDialogT,
    JumpDialogT,
    ExitDialogT
} from './WatsonT'

export enum OPERATIONS {
    PageLoading,
    PageView,
    AssistantView,
    DeleteView,

    RecognizeDialogPT,
    TextResponseDialogPT,
    OptionsResponseDialogPT,
    AskQueryDialogPT,
    SlotContainerDialogPT,
    JumpDialogPT,
    AutomationDialogPT,
    ExitDialogPT
}

export type PageLoadingT = {
    operation: OPERATIONS.PageLoading
}

export type PageViewT = {
    operation: OPERATIONS.PageView
}

export type DeleteViewT = {
    operation: OPERATIONS.DeleteView
    input: DialogT
}

export type AssistantViewT = {
    operation: OPERATIONS.AssistantView
}

export type RecognizeDialogPT = {
    operation: OPERATIONS.RecognizeDialogPT
    input: RecognizeDialogT
    previousSibling: DialogT | null
    parent: RecognizeDialogT | null
}

export type TextResponseDialogPT = {
    operation: OPERATIONS.TextResponseDialogPT
    input: TextResponseDialogT
    previousSibling: DialogT | null
    nextSibling: DialogT | null
    parent: RecognizeDialogT
}

export type OptionsResponseDialogPT = {
    operation: OPERATIONS.OptionsResponseDialogPT
    input: OptionsResponseDialogT
    previousSibling: DialogT | null
    nextSibling: RecognizeDialogT | null
    parent: RecognizeDialogT
}

export type AskQueryDialogPT = {
    operation: OPERATIONS.AskQueryDialogPT
    input: AskQueryDialogT
    previousSibling: DialogT | null
    nextSibling: DialogT | null
    parent: RecognizeDialogT
}

export type SlotContainerDialogPT = {
    operation: OPERATIONS.SlotContainerDialogPT
    input: SlotContainerDialogT
    previousSibling: DialogT | null
    nextSibling: DialogT | null
    parent: RecognizeDialogT
}

export type AutomationDialogPT = {
    operation: OPERATIONS.AutomationDialogPT
    input: AutomationDialogT
    previousSibling: DialogT | null
    nextSibling: DialogT | null
    parent: RecognizeDialogT
}

export type JumpDialogPT = {
    operation: OPERATIONS.JumpDialogPT
    input: JumpDialogT
    previousSibling: DialogT | null
    nextSibling: DialogT | null
    parent: RecognizeDialogT
}

export type ExitDialogPT = {
    operation: OPERATIONS.ExitDialogPT
    input: ExitDialogT
    previousSibling: DialogT | null
    nextSibling: DialogT | null
    parent: RecognizeDialogT
}

export type PageState =
    | PageLoadingT
    | PageViewT
    | DeleteViewT
    | AssistantViewT
    | RecognizeDialogPT
    | TextResponseDialogPT
    | OptionsResponseDialogPT
    | AskQueryDialogPT
    | SlotContainerDialogPT
    | AutomationDialogPT
    | JumpDialogPT
    | ExitDialogPT

export const PageView: PageViewT = {
    operation: OPERATIONS.PageView
}

export const PageLoading: PageLoadingT = {
    operation: OPERATIONS.PageLoading
}
