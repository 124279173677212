/*eslint-disable*/
import React, { useContext, useState, createContext, useEffect } from 'react';
import { CanvasRightPanel, CanvasRightPanel as CRP } from './CanvasRightPanel';
import {
  ActionPanelPopup,
  Wrapper as ActionWrapper,
  Button,
  ButtonContainer,
  Input,
  InputContainer,
  InputContent,
  InputWrapper,
  PopupContainer,
  PopupWrapper,
} from '@/common/styled/Workflow.Dumb';
import { CancelButtonInput } from './ActionPanel';
import { DropdownWithCircle, FlowCanvasContextIcons, InfoIcon } from '@/common/Icons/Icons';
import { VideoLink } from '@/Videos/Videos';
import styled from 'styled-components/macro';
import { Textarea } from '@/common/styled/Workflow.Analytics.Dumb';
import { SidePanelContext } from './Canvas';
import {
  ActionInputsListT,
  AppsListT,
  AuthType,
  NodeAddT,
  NodeEditT,
  NodeMode,
  NodeModes,
  NodeT,
  NodeView,
  PopupError,
  RightPanelStateContext,
  TimerInitialState,
  TimerResponseT,
  Tree,
  TreeT,
  updateNode,
  ValueTypeT,
} from './types';
import { Loader } from '@/common/components/Loader';
import { Error } from '@/common/components/ErrorBoundary';
import Tooltip from 'react-tooltip-lite';
import produce from 'immer';
import { configApiCall, ContextPopup } from './ContextPopup';
import { automationAPIURLWithWorkspace, getJson } from '@/common/utils/api-utils';
import { NoTriggerVariable } from '@/common/Icons/Workflow.Icons';
import { buildContextValuesForEdit } from './ConditionPopup';
import { getProductId } from '@/common/utils/api-utils';

const InputGrid = styled.div`
  width: 100%;
  float: left;
`;

const timerTypeInuput = [
  { name: 'Wait for a specified period of time', value: 'wait_time' },
  { name: 'Wait until specific conditions are met', value: 'condition_met' },
];

const timerLimit = [
  { name: 'minutes', value: 'minute' },
  { name: 'hour(s)', value: 'hour' },
  { name: 'day(s)', value: 'day' },
  // { name: 'week(s)', value: 'weeks' },
];

const timeType = [
  { name: 'Current time', value: 'current_time' },
  { name: 'Date & Time', value: 'date_time' },
  { name: 'Specific date', value: 'specific_date' },
];

const hours = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];

const days = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];

const timeZone = ['CET(UTC + 1)', 'IST(UTC +5:30)', 'GMT(UTC + 0)'];

export type InputT =
  | {
      type: 'schedule-by-date';
      time: string;
      timezone: string;
      day: {
        type: 'without-day';
      };
    }
  | {
      type: 'schedule-by-date';
      time: string;
      timezone: string;
      day: {
        type: 'with-day';
        on: number;
        period: 'month' | 'week' | 'year';
      } | {
        on: string,
        timezone: string,
        type: "with-date"
    }
    }
  | {
      type: 'schedule-by-delay';
      delay: {
        by: number;
        period: 'day' | 'hour' | 'minute';
      };
      date: {
        type: 'without-date';
      };
    }
  | {
      type: 'schedule-by-delay';
      delay: {
        by: number;
        period: 'day' | 'hour' | 'minute';
      };
      date: {
        type: 'with-date';
        on: string;
        timezone: string;
      };
    }

export type SchedularPanelStateT = {
  show: boolean;
  parentNode: TreeT<NodeT>;
  mode: NodeModes;
  currentNode: TimerResponseT;
};

export type SchedularPanelStateContextT = {
  schedularPanelState: SchedularPanelStateT;
  setSchedularPanelState: (object: any) => void;
  setContextPopupValues: (object: any) => void
  contextPopupValues: {
      show: boolean
      currentInput: { keyName: string ,value:ValueTypeT[]}
  }

};

export const SchedularPanelStateContext =
  createContext<SchedularPanelStateContextT>({
    schedularPanelState: {
      show: false,
      mode: NodeAddT,
      parentNode: { value: Tree, children: [] },
      currentNode: TimerInitialState,
    },
    setSchedularPanelState: (object: any) => undefined,
    setContextPopupValues: (object: any) => undefined,
    contextPopupValues: {
        show: false,
        currentInput: { keyName: '',value:[] }
    }
  });

export const SchedularPanel = (props: any) => {
  const { rightPanelState } = useContext(SidePanelContext);

  const [schedularPanelState, setSchedularPanelState] = useState({
    ...rightPanelState,
    currentNode: rightPanelState.currentNode as TimerResponseT,
  });

  const [contextPopupValues, setContextPopupValues] = useState({
    show: false,
    currentInput: { keyName: '',value:[] }
})


  return (
    <SchedularPanelStateContext.Provider
      value={{
        schedularPanelState,
        setSchedularPanelState,
        contextPopupValues,
        setContextPopupValues
      }}
    >
      <SchedularActualComponent {...props} />
    </SchedularPanelStateContext.Provider>
  );
};

function SchedularActualComponent(props: any) {
  const { hasLinkedDialogs, automationMeta, active_automation } = useContext(SidePanelContext);
  const { schedularPanelState, setSchedularPanelState,contextPopupValues, setContextPopupValues } = useContext(
    SchedularPanelStateContext
  );
  const { rightPanelState, setRightPanelState, saveTree , treeData} =
    useContext(SidePanelContext);
  const SidePanelContextValue = useContext(SidePanelContext);

  const currentNode = schedularPanelState.currentNode

  const [loading, setLoading] = useState(true)


  useEffect(() => {
    const currentMode = rightPanelState.mode;
    let state = rightPanelState

    if (currentMode.mode === NodeMode.Add) {
      state = {
        ...rightPanelState,
        currentNode: rightPanelState.currentNode as TimerResponseT,
      }
    } else if (currentMode.mode === NodeMode.Edit) {
      const mode = currentMode as NodeEditT;
      const input = mode.nodeEditInput.input as unknown as InputT;

      state = {
        ...rightPanelState,
        currentNode: {
          ...rightPanelState.currentNode,
          timerType:
            input.type === 'schedule-by-delay' ? 'wait_time' : 'condition_met',
          timerValue:
            input.type === 'schedule-by-delay' ? input.delay.period : 'day',
          waitFor: input.type === 'schedule-by-delay' ? input.delay.by : '',
          conditionTimeType:
            input.type === 'schedule-by-date'
              ? (input.day.type === 'with-date'? "specific_date" : 'date_time')
              : 'current_time',
          hour: input.type === 'schedule-by-date' ? input.time : 0,
          timezone:
            input.type === 'schedule-by-date'
              ? input.timezone
              : 'IST(UTC +5:30)',
          currentDay:
            input.type === 'schedule-by-date' && (input.day.type === 'with-day' || input.day.type === 'with-date')
              ? input.day.on
              : 1,
        } as TimerResponseT,
      }
    }
    const mode = rightPanelState.mode

    callApplistAPI({
      ...state,
      currentNode:
          mode.mode == NodeMode.Edit
              ? ({
                    ...state.currentNode,
                    subflowMetaData:
                        mode.nodeEditInput.meta_data && mode.nodeEditInput.meta_data.subflow
                            ? { subflow: mode.nodeEditInput.meta_data.subflow }
                            : { subflow: { hasSubFlow: false } }
                } as TimerResponseT)
              : (state.currentNode as TimerResponseT)
  })

  }, [rightPanelState.mode]);

  useEffect(() => {
    // to see the details in left side panel while adding or editing
    setRightPanelState({
      ...rightPanelState,
      currentNode: schedularPanelState.currentNode,
    });
  }, [schedularPanelState.currentNode]);

  async function callApplistAPI(state: SchedularPanelStateT) {
    const workspaceName = props.match.params.workspacename

    await getJson(automationAPIURLWithWorkspace(workspaceName, '/actions'))
        .then((data: AppsListT) => {
            const currentNode = state.currentNode as TimerResponseT

            if (state.mode.mode === NodeMode.Edit) {
                const mode = state.mode as NodeEditT

                const triggerNodeOutput = treeData.tree.value

                const parentNode =
                    triggerNodeOutput.kind === 'AppTrigger' || triggerNodeOutput.kind === 'EventTrigger'
                        ? ({
                              ...state.parentNode,
                              value: {
                                  ...state.parentNode.value,
                                  path: state.parentNode.value.path
                                      ? [
                                            {
                                                actionId: triggerNodeOutput.meta_data.action_id,
                                                nodeId: triggerNodeOutput.id
                                            }
                                        ].concat(state.parentNode.value.path)
                                      : [
                                            {
                                                actionId: triggerNodeOutput.meta_data.action_id,
                                                nodeId: triggerNodeOutput.id
                                            }
                                        ]
                              }
                          } as TreeT<NodeT>)
                        : state.parentNode

                configApiCall(data, parentNode, false, workspaceName, currentNode.addingInsideForEach).then(
                    configResponse => {
                      const triggerNodeOutput = treeData.tree.value
                      let combiningResponseWithTriggerOutput: any = []

                      if (triggerNodeOutput.kind !== 'AppTrigger' && triggerNodeOutput.kind !== 'EventTrigger') {
                        const triggerOutputResponse: ActionInputsListT[] = [
                                          {
                                  type: 'success',
                                  output: {
                                      name: triggerNodeOutput.name,
                                      input: [],
                                      app: {
                                          appName: triggerNodeOutput.name,
                                          action: triggerNodeOutput.kind,
                                          pathId: {
                                              nodeId: triggerNodeOutput.id,
                                              actionId: ''
                                          },
                                          appId: '',
                                          createdDate: '',
                                          file: '',
                                          type: 'trigger',
                                          authentication_type: AuthType.None,
                                          id: '',
                                          scope: '',
                                          icon: NoTriggerVariable,
                                          latest: false,
                                          version: ''
                                      },
                                      output: triggerNodeOutput.output
                                  }
                              }
                          ]

                          combiningResponseWithTriggerOutput = triggerOutputResponse.concat(configResponse)
                      } else {
                          combiningResponseWithTriggerOutput = configResponse
                      }

                      const input = mode.nodeEditInput.input as unknown as InputT;

                      const popupValues = produce(currentNode, draftState => {
                        if( input.type=="schedule-by-date" && input.day.type=="with-date" ){
                          draftState.dateVariable = buildContextValuesForEdit(input.day.on, combiningResponseWithTriggerOutput,treeData) as any
                        }
                        draftState.appsList = data
                      })

                    setSchedularPanelState({ ...state, currentNode: popupValues })

                    if(input.type=="schedule-by-date" && input.day.type=="with-date"){
                      Promise.resolve('delay effect change due to overlapping state update calls').then(() => {
                          setContextPopupValues({ ...contextPopupValues, currentInput:{keyName:"",value: buildContextValuesForEdit((input.day as any).on, combiningResponseWithTriggerOutput,treeData) as any }})
                      })
                    }

                    setLoading(false)

                    }
                )
            } else {
              const nextState = produce(currentNode, draftState => {
                draftState.appsList = data
              })

              setSchedularPanelState({
                  ...state,
                  currentNode: nextState
              })

              setLoading(false)

            }
        })
        .catch(err => {
            setLoading(false)

            setSchedularPanelState({
                ...state,
                mode: {
                    ...PopupError,
                    error: 'Something went wrong!<br/>Please try again after sometime.'
                }
            })
        })
}

  function cancelCurrentPopup(props: {
    schedularPanelState: SchedularPanelStateT;
    SidePanelContext: RightPanelStateContext;
  }) {
    const { schedularPanelState, SidePanelContext } = props;

    const {
      rightPanelState,
      setRightPanelState,
      treeData,
      setEditTreeData,
      editTreeData,
    } = SidePanelContext;

    const tree = schedularPanelState.parentNode.children.filter(
      (x) => x.value.kind === 'ParallelPath'
    );

    const cancellingNode =
      schedularPanelState.currentNode.parallelPathNode.currentParallelPathNode;

    const currentNode = schedularPanelState.currentNode;

    if (tree.length < 2) {
      setRightPanelState({
        ...rightPanelState,
        show: false,
        mode: NodeView,
        parentNode: { value: Tree, children: [] },
        currentNode: {
          ...currentNode,
          parallelPathNode: {
            ...currentNode.parallelPathNode,
            currentParallelPathNode: null,
          },
        },
      });

      const newTree = cancellingNode
        ? updateNode({ ...cancellingNode, name: '' }, treeData.tree, undefined)
        : treeData.tree;

      setEditTreeData({ ...treeData, tree: newTree });
    } else {
      const node = cancellingNode as NodeT;
      // for while adding new parallel path
      setRightPanelState({
        ...rightPanelState,
        show: false,
        currentNode: {
          ...currentNode,
          parallelPathNode: {
            ...currentNode.parallelPathNode,
            currentParallelPathNode: null,
          },
        },
      });

      const newTree = cancellingNode
        ? updateNode(
            { ...cancellingNode, name: '' },
            editTreeData.tree,
            undefined
          )
        : editTreeData.tree;
      setEditTreeData({ ...editTreeData, tree: newTree });
    }
  }

  function updateInputValuesWithContext(props: {
    path: string
    value: string
    valuePath: string[]
    type: string
    icon: string
    examples: string
}) {
    const { path, value, valuePath, type, icon, examples } = props

    let nextInputValues

      if (contextPopupValues.currentInput.keyName === 'left') {
            nextInputValues = produce(contextPopupValues, draftState => {
                const nodeInput = draftState.currentInput
                draftState.currentInput.value = [
                        {
                            id: path,
                            value: value,
                            type: 'context',
                            valuePath: valuePath,
                            icon: icon
                        }
                    ]

                draftState.show = false
            })

            const nextStateInputValues = produce(schedularPanelState.currentNode, draftState => {
              draftState.dateVariable = [
                      {
                          id: path,
                          value: value,
                          type: 'context',
                          valuePath: valuePath,
                          icon: icon
                      }
                  ]
            })
            setSchedularPanelState({ ...schedularPanelState, currentNode: nextStateInputValues })
        }
        console.log(nextInputValues, 'nextInputValues')
        setContextPopupValues(nextInputValues)
  }

  async function updateVariableFromInput(text: any, old: any, cb: Function) {
    const leftValue: any = contextPopupValues.currentInput.value[0].value == old.title

    if (leftValue) {
        await cb('edit')

        const newValue = produce(contextPopupValues, draft => {
          const left = draft.currentInput.value.map(l => {
            return {
                ...l,
                value: text.title
            }
        })
        draft.currentInput.value = left
      })


        const popupValues = produce(currentNode, draftState => {
            draftState.dateVariable = newValue.currentInput.value as any

        })

      setSchedularPanelState({ ...rightPanelState, currentNode: popupValues })
        Promise.resolve('delay effect change due to overlapping state update calls').then(() => {
          setContextPopupValues(newValue)
        })

    } else {
        await cb('no-edit')
    }
}

  async function removeVariableFromInput(text: any, cb: Function) {

    const leftValue: any = contextPopupValues.currentInput.value[0].value == text.title

    if (leftValue) {
        await cb('delete')
        const newValue = produce(contextPopupValues, draft => {
            const left = draft.currentInput.value.filter(l => l.value != text.title)
            draft.currentInput.value = left
        })

        const popupValues = produce(currentNode, draftState => {
          draftState.dateVariable = newValue.currentInput.value as any

        })

        setSchedularPanelState({ ...rightPanelState, currentNode: popupValues })

        Promise.resolve('delay effect change due to overlapping state update calls').then(() => {
            setContextPopupValues(newValue)
        })
    } else {
        await cb('delete')
    }
}

  function onSave() {
    saveTree(state);
  }

  const state = schedularPanelState.currentNode;

  if (rightPanelState.mode.mode === NodeMode.Loading || loading) {
    return (
      <div className="automation_canvas_right">
        <Wrapper>
          {[
            <div className="popup_inside_loader">
              <Loader.PopupLoader show={true} />
            </div>,
          ]}
        </Wrapper>
      </div>
    );
  } else if (rightPanelState.mode.mode === NodeMode.Error) {
    return (
      <div className="automation_canvas_right">
        <Wrapper>
          {[
            <div className="flow_canvas_popup_error">
              <Error.AddorEdit
                info={
                  'Something went wrong!<br/>Please try again after sometime.'
                }
                onClick={() => {
                  cancelCurrentPopup({
                    schedularPanelState,
                    SidePanelContext: SidePanelContextValue,
                  });
                }}
              />
            </div>,
          ]}
        </Wrapper>
      </div>
    );
  }

  const isAssistant = getProductId() === 'ASSISTANT'

  return (
    //option 1

    <Wrapper>
      <CanvasRightPanel.StepHeader
        title={
          rightPanelState.mode.mode === NodeMode.Edit
            ? 'Edit Timer'
            : 'Add Timer'
        }
        description={'Set timer'}
        // infocontent={<ActionTip />}
        // helpcontent="To help documention of action"
        // helplink="https://help.workativ.com/knowledgebase/add-action/"
      >
        {<VideoLink id={'CREATE_AN_ACTION_IN_APP_WORKFLOW'} text={'How to'} />}
      </CanvasRightPanel.StepHeader>

      <TimerTypeDropDown
        schedularPanelState={schedularPanelState}
        setSchedularPanelState={setSchedularPanelState}
        hasLinkedDialogs={(isAssistant ? automationMeta.type =='no_trigger' ?false : active_automation: active_automation)}
      />

      {state.timerType == 'wait_time' ? (
        <InputGrid>
          <div className="shuedular_input ask_ques_editor_input">
            <InputWrapper
              style={{ padding: '0px 0px 20px 20px', width: '270px' }}
            >
              <InputContainer
                className={(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)?'':"editor_btn_disabled"}
              >
                <Input
                  type="text"
                  value={state.waitFor}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const re = /^[0-9\b]+$/;
                    // if value is not blank, then test the regex

                    if (e.target.value === '' || re.test(e.target.value)) {
                      setSchedularPanelState({
                        ...schedularPanelState,
                        currentNode: { ...state, waitFor: e.target.value },
                      });
                    }
                  }}
                />
                <InputContent>Wait for
                  <Tooltip
                    className="target customTip _description"
                    zIndex={10000}
                    tagName="span"
                    content="Add a number to wait for"
                    distance={15}
                    forceDirection={true}
                    direction="up-start"
                    >
                      <InfoIcon />
                  </Tooltip>
                </InputContent>

              </InputContainer>
            </InputWrapper>
            <InputWrapper
              className="autoamtion_connection scheduler_popup"
              style={{
                padding: '0px 20px 20px 10px',
                marginBottom: 'auto',
                width: '200px',
              }}
            >
              <div className="select_application">
                <div className="">
                  <CanvasRightPanel.AppsDropDown
                    inputs={{
                      inputs: timerLimit,
                      key: 'name',
                      idKey: 'value',
                      showIcon: false,
                    }}
                    addDropDown
                    updateSelection={(
                      selecterTimerType: string,
                      timerValue: string
                    ) => {
                      setSchedularPanelState({
                        ...schedularPanelState,
                        currentNode: { ...state, timerValue: timerValue },
                      });
                    }}
                    render={(makeDropDownVisible: () => void) => (
                      <InputContainer style={{ marginBottom: 0 }}>
                        <Input
                          type="text"
                          className={(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)?'':"editor_btn_disabled"}
                          value={
                            timerLimit.filter(
                              (t) => t.value == state.timerValue
                            )[0].name
                          }
                          onClick={() => makeDropDownVisible()}
                          onChange={(e: any) => {}}
                          style={{ padding: '14px 7px 10px 12px' }}
                        />
                        {/* <InputContent>day(s)</InputContent> */}
                        <CancelButtonInput
                          onClick={() => makeDropDownVisible()}
                        >
                          <DropdownWithCircle />
                        </CancelButtonInput>
                      </InputContainer>
                    )}
                  />
                </div>
              </div>
            </InputWrapper>
            {/* </InputWrapper>
          <InputWrapper
            style={{
              padding: '0px 20px 20px 20px',
              marginBottom: 'auto',
              width: '170px',
            }}
          >
            <InputContainer>
              <Input type="text" />
              <InputContent>day(s) </InputContent>
              <CancelButtonInput>
                <DropdownWithCircle />
              </CancelButtonInput>
            </InputContainer>
          </InputWrapper> */}
          </div>
        </InputGrid>
      ) : (
        <>
          <InputWrapper
            className="autoamtion_connection scheduler_popup_cur_time"
            style={{ marginBottom: 0, padding: '0 20px' }}
          >
            <div className="select_application">
              <div className="">
                <CanvasRightPanel.AppsDropDown
                  inputs={{
                    inputs: timeType,
                    key: 'name',
                    idKey: 'value',
                    showIcon: false,
                  }}
                  addDropDown
                  updateSelection={(
                    selected: string,
                    conditionTimeType: string
                  ) => {
                    conditionTimeType === "specific_date" && currentNode.conditionTimeType != conditionTimeType &&
                     setContextPopupValues({
                      ...contextPopupValues,
                      currentInput: { keyName: '' ,value:[] }
                    })
                    setSchedularPanelState({
                      ...schedularPanelState,
                      currentNode: { ...state, conditionTimeType, dateVariable:[] },
                    });
                  }}
                  render={(makeDropDownVisible: () => void) => (
                    <InputContainer style={{ marginBottom: 20 }}>
                      <Input
                        type="text"
                        className={(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)?'':"editor_btn_disabled"}
                        value={
                          timeType.filter(
                            (t) => t.value === state.conditionTimeType
                          )[0].name
                        }
                        onClick={() => makeDropDownVisible()}
                        onChange={(e: any) => {}}
                      />
                      <InputContent>Set condition</InputContent>
                      <CancelButtonInput onClick={() => makeDropDownVisible()}>
                        <DropdownWithCircle />
                      </CancelButtonInput>
                    </InputContainer>
                  )}
                />
              </div>
            </div>
          </InputWrapper>
          {state.conditionTimeType == 'date_time' && (
            <InputWrapper
              className="autoamtion_connection scheduler_popup_cur_time"
              style={{ marginBottom: 0, padding: '0 20px' }}
            >
              <div className="select_application">
                <div className="">
                  <CanvasRightPanel.AppsDropDown
                    inputs={{
                      inputs: days.map((d) => ({ name: d, value: d })),
                      key: 'name',
                      idKey: 'value',
                      showIcon: false,
                    }}
                    addDropDown
                    updateSelection={(selected: string, currentDay: number) => {
                      setSchedularPanelState({
                        ...schedularPanelState,
                        currentNode: { ...state, currentDay },
                      });
                    }}
                    render={(makeDropDownVisible: () => void) => (
                      <InputContainer style={{ marginBottom: 20 }}>
                        <Input
                          type="text"
                          className={(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)?'':"editor_btn_disabled"}
                          value={state.currentDay}
                          onClick={() => makeDropDownVisible()}
                          onChange={(e: any) => {}}
                        />
                        <InputContent>Current day of month is</InputContent>
                        <CancelButtonInput
                          onClick={() => makeDropDownVisible()}
                        >
                          <DropdownWithCircle />
                        </CancelButtonInput>
                      </InputContainer>
                    )}
                  />
                </div>
              </div>
            </InputWrapper>
          )}
          {state.conditionTimeType == 'specific_date' && (
            <InputWrapper
              className="autoamtion_connection scheduler_popup_cur_time"
              style={{ marginBottom: 0, padding: '0 20px' }}
            >
        <InputContainer style={{ marginBottom: 0 }}>
        <div
            className={'automation_form_req automation_form_req_step_two'}
            style={{ marginBottom: 20 }}
        >
            <div
                className={
                     'form_req_step_two_global_form'
                }
                onClick={() => {
                    if (
                        treeData.tree.value.kind === 'AppTrigger' ||
                        treeData.tree.value.kind === 'EventTrigger' ||
                        treeData.tree.value.kind === 'WebhookTrigger' ||
                        (treeData.tree.value.output &&
                            Object.keys(treeData.tree.value.output).length > 0) ||
                        (schedularPanelState.parentNode.value.path &&
                          schedularPanelState.parentNode.value.path.length > 0)
                    ) {
                        setContextPopupValues({
                            ...contextPopupValues,
                            show: true,
                            currentInput: { keyName: 'left',value:contextPopupValues.currentInput.value }
                        })
                    } else {
                        setContextPopupValues({
                            ...contextPopupValues,
                            currentInput: { keyName: 'left' ,value:contextPopupValues.currentInput.value }
                        })
                    }
                }}
            >
                <div className="automation_global_form_edit _select_a_variable">
                        {contextPopupValues.currentInput.value.map((val, i) => {
                            if (val.type === 'context') {
                                return (
                                    <React.Fragment key={i}>
                                        <span className="conetxt_variable_input">
                                            <div
                                                className="context_popup_buton_content_span"
                                            >
                                                <div className="button_auto">
                                                    <img src={val.icon} />
                                                    {val.value}
                                                </div>

                                                {val.valuePath.length > 0 && (
                                                    <div className="button_auto_hover">
                                                        {val.valuePath.map((value, j) => (
                                                            <React.Fragment key={j}>
                                                                {j !== 0 && (
                                                                    <div>
                                                                        <FlowCanvasContextIcons.NextIcon />
                                                                    </div>
                                                                )}
                                                                <div
                                                                    style={
                                                                        j + 1 ==
                                                                        val.valuePath.length
                                                                            ? {
                                                                                  whiteSpace:
                                                                                      'initial',
                                                                                  width:
                                                                                      'max-content'
                                                                              }
                                                                            : {}
                                                                    }
                                                                >
                                                                    {value}
                                                                </div>
                                                            </React.Fragment>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </span>
                                    </React.Fragment>
                                )
                            } else {
                                return <p key={i}>{val.value}</p>
                            }
                        })}
                    {contextPopupValues.currentInput.value.length>0 && <div
                        className={
                          contextPopupValues.currentInput.value.length ? 'button_auto_input_click' : 'button_auto_input'
                        }
                        // data-text={completedFirstInput ? '' : 'Select a variable'}
                    >
                        Set condition
                    </div>}
                </div>
            </div>
            {/* <p>Select output from previous node</p> */}
        </div>
        {!contextPopupValues.currentInput.value.length&&<InputContent>Set condition</InputContent>}
        </InputContainer>
        </InputWrapper>

          )}
          <InputGrid>
            <div className="shuedular_input ask_ques_editor_input">
              <InputWrapper
                style={{ padding: '0px 5px 20px 20px', width: '250px' }}
              >
                <div className="select_application">
                  <div className="">
                    <CanvasRightPanel.AppsDropDown
                      inputs={{
                        inputs: hours.map((v) => ({
                          name: `${v}h (${v === 0 ? 12 : v < 13 ? v : v % 12}${
                            v < 12 ? 'AM' : 'PM'
                          })`,
                          value: v,
                        })),
                        key: 'name',
                        idKey: 'value',
                        showIcon: false,
                      }}
                      addDropDown
                      updateSelection={(selected: string, hour: number) => {
                        setSchedularPanelState({
                          ...schedularPanelState,
                          currentNode: { ...state, hour },
                        });
                      }}
                      render={(makeDropDownVisible: () => void) => (
                        <InputContainer style={{ marginBottom: 0 }}>
                          <Input
                            type="text"
                            className={(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)?'':"editor_btn_disabled"}
                            value={`${state.hour}h (${
                              state.hour === 0
                                ? 12
                                : state.hour < 13
                                ? state.hour
                                : state.hour % 12
                            }${state.hour < 12 ? 'AM' : 'PM'})`}
                            onClick={() => makeDropDownVisible()}
                            onChange={() => {}}
                          />
                          <InputContent>Time is</InputContent>
                          <CancelButtonInput
                            onClick={() => makeDropDownVisible()}
                          >
                            <DropdownWithCircle />
                          </CancelButtonInput>
                        </InputContainer>
                      )}
                    />
                  </div>
                </div>
              </InputWrapper>
              {/* <InputWrapper
                style={{
                  padding: '0px 20px 20px 5px',
                  marginBottom: 'auto',
                  width: '170px',
                }}
              >
                <InputContainer>
                  <Input type="text" />
                  <InputContent>Timezone </InputContent>
                  <CancelButtonInput>
                    <DropdownWithCircle />
                  </CancelButtonInput>
                </InputContainer>
              </InputWrapper> */}
              <InputWrapper
                style={{
                  padding: '0px 20px 20px 5px',
                  marginBottom: 'auto',
                  width: '230px',
                }}
              >
                <div className="select_application">
                  <div className="">
                    <CanvasRightPanel.AppsDropDown
                      inputs={{
                        inputs: timeZone.map((v) => ({
                          name: v,
                          value: v,
                        })),
                        key: 'name',
                        idKey: 'value',
                        showIcon: false,
                      }}
                      addDropDown
                      updateSelection={(selected: string, timezone: string) => {
                        setSchedularPanelState({
                          ...schedularPanelState,
                          currentNode: { ...state, timezone },
                        });
                      }}
                      render={(makeDropDownVisible: () => void) => (
                        <InputContainer style={{ marginBottom: 0 }}>
                          <Input
                            type="text"
                            className={(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)?'':"editor_btn_disabled"}
                            value={state.timezone}
                            onClick={() => makeDropDownVisible()}
                            onChange={(e: any) => {}}
                          />
                          <InputContent>Timezone</InputContent>
                          <CancelButtonInput
                            onClick={() => makeDropDownVisible()}
                          >
                            <DropdownWithCircle />
                          </CancelButtonInput>
                        </InputContainer>
                      )}
                    />
                  </div>
                </div>
              </InputWrapper>
            </div>
          </InputGrid>
        </>
      )}
      {/*
      <InputWrapper className="autoamtion_connection">
        <div className="select_application">
          <div className="">
            <InputContainer style={{ marginBottom: 20 }}>
              <Input type="text" />
              <InputContent>Notification type</InputContent>
              <CancelButtonInput>
                <DropdownWithCircle />
              </CancelButtonInput>
            </InputContainer>
          </div>
        </div>
        <div className="select_application">
          <div className="">
            <InputContainer style={{ marginBottom: 20 }}>
              <Input type="text" />
              <InputContent>Select bot</InputContent>
              <CancelButtonInput>
                <DropdownWithCircle />
              </CancelButtonInput>
            </InputContainer>
          </div>
        </div>
        <div className="select_application">
          <div className="">
            <InputContainer style={{ marginBottom: 20 }}>
              <Input type="text" />
              <InputContent>Select chat channel</InputContent>
              <CancelButtonInput>
                <DropdownWithCircle />
              </CancelButtonInput>
            </InputContainer>
          </div>
        </div>
        <div className="select_application">
          <div className="">
            <InputContainer style={{ marginBottom: 20 }}>
              <Input type="text" />
              <InputContent>Send notification to</InputContent>
              <CancelButtonInput>
                <DropdownWithCircle />
              </CancelButtonInput>
            </InputContainer>
          </div>
        </div>

        <div className="select_application">
          <div className="">
            <InputContainer style={{ marginBottom: 20 }}>
              <Input type="text" />
              <InputContent>Select notification type</InputContent>
              <CancelButtonInput>
                <DropdownWithCircle />
              </CancelButtonInput>
            </InputContainer>
          </div>
        </div>

        <InputContainer style={{ marginBottom: 20 }}>
          <Input type="text" />
          <InputContent>Notification Title</InputContent>
        </InputContainer>
        <InputContainer>
          <Textarea value={prompt} />
          <InputContent>Message to user</InputContent>
        </InputContainer>
      </InputWrapper> */}

      <ButtonContainer className="footer_button_fixed">
        <div className="popup_footer_button_container">
          <Button
            primary
            className="automation_condition_button_btn"
            onClick={() =>
              cancelCurrentPopup({
                schedularPanelState,
                SidePanelContext: SidePanelContextValue,
              })
            }
          >
            <label>Cancel</label>
          </Button>
          {(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)&&<Button
            className={
              (state.timerType === 'wait_time' && state.waitFor.length > 0) ||
              state.timerType === 'condition_met' && (state.conditionTimeType != "specific_date" || contextPopupValues.currentInput.value.length)
                ? 'automation_condition_button_btn'
                : 'automation_condition_button_btn editor_btn_disabled'
            }
            onClick={() => {
              onSave();
            }}
          >
            <label>Save</label>
          </Button>}
        </div>
      </ButtonContainer>
      {contextPopupValues.show ? (
          <ContextPopup
              {...props}
              mode={rightPanelState.mode}
              tree={treeData.tree}
              parentNode={rightPanelState.parentNode}
              actionAppsList={currentNode.appsList}
              triggerNode={treeData.tree.value}
              updateInputValuesWithContext={updateInputValuesWithContext}
              addingFlowInsideForEach={currentNode.addingInsideForEach}
              removeVariableFromInput={(text: any, cb: Function) =>
                  removeVariableFromInput(text, cb)
              }
              updateVariableFromInput={(newText: any, oldText: any, cb: Function) =>
                updateVariableFromInput(newText, oldText, cb)
              }
              closeContextPopup={() =>
                setContextPopupValues({
                  ...contextPopupValues,
                  show: false,
                  currentInput: { keyName:"",value:contextPopupValues.currentInput.value }
                })
            }
          />)
          :
          <></>
        }
    </Wrapper>

    //option 2

    // <Wrapper>
    //   <CanvasRightPanel.StepHeader
    //     title={'Start your workflow'}
    //     description={'Set timer'}
    //     // infocontent={<ActionTip />}
    //     helpcontent="To help documention of action"
    //     helplink="https://help.workativ.com/knowledgebase/add-action/"
    //   >
    //     {<VideoLink id={'CREATE_AN_ACTION_IN_APP_WORKFLOW'} text={'How to'} />}
    //   </CanvasRightPanel.StepHeader>

    //   <InputWrapper
    //     className="autoamtion_connection"
    //     style={{ marginBottom: 0, padding: '0 20px' }}
    //   >
    //     <div className="select_application">
    //       <div className="">
    //         <InputContainer style={{ marginBottom: 20 }}>
    //           <Input type="text" />
    //           <InputContent>Set condition</InputContent>
    //           <CancelButtonInput>
    //             <DropdownWithCircle />
    //           </CancelButtonInput>
    //         </InputContainer>
    //       </div>
    //     </div>
    //   </InputWrapper>

    //   <InputWrapper
    //     className="autoamtion_connection"
    //     style={{ marginBottom: 0, padding: '0 20px' }}
    //   >
    //     <div className="select_application">
    //       <div className="">
    //         <InputContainer style={{ marginBottom: 20 }}>
    //           <Input type="text" />
    //           <InputContent>Current day of month is</InputContent>
    //           <CancelButtonInput>
    //             <DropdownWithCircle />
    //           </CancelButtonInput>
    //         </InputContainer>
    //       </div>
    //     </div>
    //   </InputWrapper>

    //   <InputGrid>
    //     <div className="shuedular_input ask_ques_editor_input">
    //       <InputWrapper
    //         style={{ padding: '0px 5px 20px 20px', width: '290px' }}
    //       >
    //         <InputContainer>
    //           <Input type="text" />
    //           <InputContent>Time is </InputContent>
    //           <CancelButtonInput>
    //             <DropdownWithCircle />
    //           </CancelButtonInput>
    //         </InputContainer>
    //       </InputWrapper>
    //       <InputWrapper
    //         style={{
    //           padding: '0px 20px 20px 5px',
    //           marginBottom: 'auto',
    //           width: '170px',
    //         }}
    //       >
    //         <InputContainer>
    //           <Input type="text" />
    //           <InputContent>Timezone </InputContent>
    //           <CancelButtonInput>
    //             <DropdownWithCircle />
    //           </CancelButtonInput>
    //         </InputContainer>
    //       </InputWrapper>
    //     </div>
    //   </InputGrid>

    //   <InputWrapper className="autoamtion_connection">
    //     <div className="select_application">
    //       <div className="">
    //         <InputContainer style={{ marginBottom: 20 }}>
    //           <Input type="text" />
    //           <InputContent>Notification type</InputContent>
    //           <CancelButtonInput>
    //             <DropdownWithCircle />
    //           </CancelButtonInput>
    //         </InputContainer>
    //       </div>
    //     </div>
    //     <div className="select_application">
    //       <div className="">
    //         <InputContainer style={{ marginBottom: 20 }}>
    //           <Input type="text" />
    //           <InputContent>Select bot</InputContent>
    //           <CancelButtonInput>
    //             <DropdownWithCircle />
    //           </CancelButtonInput>
    //         </InputContainer>
    //       </div>
    //     </div>
    //     <div className="select_application">
    //       <div className="">
    //         <InputContainer style={{ marginBottom: 20 }}>
    //           <Input type="text" />
    //           <InputContent>Select chat channel</InputContent>
    //           <CancelButtonInput>
    //             <DropdownWithCircle />
    //           </CancelButtonInput>
    //         </InputContainer>
    //       </div>
    //     </div>
    //     <div className="select_application">
    //       <div className="">
    //         <InputContainer style={{ marginBottom: 20 }}>
    //           <Input type="text" />
    //           <InputContent>Select channel in MS Teams</InputContent>
    //           <CancelButtonInput>
    //             <DropdownWithCircle />
    //           </CancelButtonInput>
    //         </InputContainer>
    //       </div>
    //     </div>
    //     <div className="select_application">
    //       <div className="">
    //         <InputContainer style={{ marginBottom: 20 }}>
    //           <Input type="text" />
    //           <InputContent>Send notification to</InputContent>
    //           <CancelButtonInput>
    //             <DropdownWithCircle />
    //           </CancelButtonInput>
    //         </InputContainer>
    //       </div>
    //     </div>
    //     <div className="select_application">
    //       <div className="">
    //         <InputContainer style={{ marginBottom: 20 }}>
    //           <Input type="text" />
    //           <InputContent>Select notification type</InputContent>
    //           <CancelButtonInput>
    //             <DropdownWithCircle />
    //           </CancelButtonInput>
    //         </InputContainer>
    //       </div>
    //     </div>

    //     <InputContainer style={{ marginBottom: 20 }}>
    //       <Input type="text" />
    //       <InputContent>Notification Title</InputContent>
    //     </InputContainer>
    //     <InputContainer>
    //       <Textarea value={prompt} />
    //       <InputContent>Message to user</InputContent>
    //     </InputContainer>
    //   </InputWrapper>

    //   <ButtonContainer className="footer_button_fixed">
    //     <div className="popup_footer_button_container">
    //       <Button primary className="automation_condition_button_btn">
    //         <label>Cancel</label>
    //       </Button>
    //       <Button className="automation_condition_button_btn editor_btn_disabled">
    //         <label>Save</label>
    //       </Button>
    //     </div>
    //   </ButtonContainer>
    // </Wrapper>
  );
}

const Wrapper = (props: { children: React.ReactChild[] }) => (
  <ActionWrapper>
    <ActionPanelPopup>
      <PopupWrapper>
        <PopupContainer>
          <div className="automation_canvas_right_popup">
            <div className="automation_canvas_right_popup_form">
              <div className="automation_rightside_form automation_rightside_step_two edit_action_height">
                {props.children}
              </div>
            </div>
          </div>
        </PopupContainer>
      </PopupWrapper>
    </ActionPanelPopup>
  </ActionWrapper>
);

function TimerTypeDropDown(props: {
  schedularPanelState: SchedularPanelStateT;
  setSchedularPanelState: (state: SchedularPanelStateT) => void;
  hasLinkedDialogs: boolean;
}) {
  const { setSchedularPanelState, schedularPanelState, hasLinkedDialogs } =
    props;
  const state = schedularPanelState.currentNode;
  return (
    <InputWrapper className="autoamtion_connection scheduler_popup" style={{ marginBottom: 0 }}>
      <div className="select_application">
        <div className="">
          <CanvasRightPanel.AppsDropDown
            inputs={{
              inputs: timerTypeInuput,
              key: 'name',
              idKey: 'value',
              showIcon: false,
            }}
            addDropDown
            updateSelection={(selecterTimerType: string, timerType: string) => {
              setSchedularPanelState({
                ...schedularPanelState,
                currentNode: { ...state, timerType },
              });
            }}
            render={(makeDropDownVisible: () => void) => (
              <InputContainer style={{ marginBottom: 0 }}>
                <Input
                  type="text"
                  className={`${hasLinkedDialogs ? 'disabled_with_blue' : ''}`}
                  value={
                    timerTypeInuput.filter((t) => t.value == state.timerType)[0]
                      .name
                  }
                  onClick={() => makeDropDownVisible()}
                  onChange={(e: any) => {}}
                  style={{color: 'transparent', textShadow: '0 0 0 #000'}}
                />
                <InputContent>Timer type</InputContent>
                <CancelButtonInput onClick={() => makeDropDownVisible()}>
                  <DropdownWithCircle />
                </CancelButtonInput>
              </InputContainer>
            )}
          />
        </div>
      </div>
    </InputWrapper>
  );
}
