import React, { useContext, useState } from 'react'
/*eslint-disable*/
import Tooltip from 'react-tooltip-lite'
import styled from 'styled-components/macro'

import { DropdownWrapperRecognise } from '@/DialogEditor/Canvas/Components/Nodes/FaqPopup'
import { AddIcon, CheckColorIcon, CloseColorIcon, CloseIcon, DropdownWithCircle, InfoIcon } from '@/common/Icons/Icons'
import { LoadingSvgImage } from '@/common/Icons/LoadingSvgImage'
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons'
import lazyGA from '@/common/google-analytics/tracking'
import {
    CancelButton,
    DropdownLi,
    DropdownUl,
    DropdownUlWrapper,
    Header,
    Input,
    InputContainer,
    InputContent,
    InputWrapper,
    SmallButton
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { sortAnArrayByDate, sortAnArrayByName } from '@/common/utils/common.utils'

import { DropDown } from '../../../Common/Effective'
//TYPE IMPORT
import { IntentRequestT, IntentT } from '../../../Intent/kind'
//ACTIONS IMPORT
import { createIntent, createIntentExample } from '../../../Mechanisms/action'
//CONTEXT IMPORT
import { DialogueContext } from '../../Context'
import { validateNode } from '../../DialogueMap/Descendant'
import { ActionT } from '../../kind'
import { operatorsT } from './kind'
import { logEvent } from '@/common/utils/api-utils'
import { usePermissions } from '@/common/utils/auth-token-api-utils'

const DropdownInputWrapper = styled.div`
    width: auto;
    float: left;
    position: absolute;
    right: 12px;
    display: flex;
    top: 9px;
`
const DropdownInputIcons = styled.div`
    width: auto;
    float: left;

    span {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;

        svg {
            width: 16px;
            height: 16px;
        }
    }
    span.editor_btn svg {
        width: 8px;
        height: 8px;
    }
`

const IntentCompoenent = ({ condition, onChange, conditionEdit }: any) => {
    const [chooser, setChooser] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('')
    const [keycode, setKeycode] = useState<number>(13)
    const [addExample, setAddExample] = useState<boolean>(false)
    const [examples, setExamples] = useState<string[]>([])
    const { workspaceName, assistantID, setIntents, intents } = useContext(DialogueContext)
    const [loading, setLoading] = useState<boolean>(false)

    const addIntent = () => {
        setLoading(true)
        const intentData: IntentRequestT = {
            description: '',
            intent: search
        }
        // console.log('addd Intent called', search)
        createIntent(workspaceName, assistantID, intentData)
            .then(async (response: IntentT) => {
                // console.log('createIntent done', response)
                setIntents((data: IntentT[]) => data.concat([response]))
                logEvent(workspaceName, {
                    event:"EVENT::INTENT::CREATE_WITH_EXAMPLES", event_meta:{
                        description: "",
                        name: search       
                    }
                })
                lazyGA().event({
                    category: 'Intent',
                    action: 'Create Intent'
                })

                //   console.log('Popdskjd:::', examples)

                // const promise = examples
                //     .filter(string => string !== '')
                //     .map(string => ({
                //         text: string
                //     }))
                //     .map((data: ExampleRequestT) =>
                //         createIntentExample(workspaceName, assistantID, response.id, data, response.intent)
                //     )

                //   const results = await Promise.all(promise.map(p => p.catch(e => e)))
                const results = await createIntentExample(
                    workspaceName,
                    assistantID,
                    response.id,
                    examples.filter(string => string !== ''),
                    response.intent
                ).catch(e => e)
                // console.log('createIntentExample done', results)
                // const notValidResults = results.filter((result: any) => result instanceof Error)
                // console.log('results', results, notValidResults)
                // if (results.length != notValidResults.length) {
                setAddExample(false)
                setChooser(false)
                onChange({
                    ...condition,
                    name: search,
                    operator: operatorsT.IS_PRESENT
                })
                setLoading(false)
                // } else {
                //     setLoading(false)
                // }
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const findDublicate = (data: string[]) => {
        if (data.filter(e => e.trim() == '').length != 0) {
            return false
        } else if (
            data
                .map(e => examples.filter(event => e.toLowerCase().trim() == event.toLowerCase().trim()).length == 1)
                .filter(e => e == true).length != data.length
        ) {
            return false
        }
        return true
    }

    const context = useContext(DialogueContext)
    const showError =
        (context.PageState.action == ActionT.SLOT_RESPONSE ||
            context.PageState.action == ActionT.ATTACH_RESPONSE ||
            (context.PageState.action == ActionT.IF_DIALOGUE &&
                context.PageState.data.conditions &&
                condition.operator != 'SELECT' &&
                context.PageState.data.conditions.value == condition.name)) &&
        context.PageState.data.id.length > 0

    const intentWritePermission = usePermissions(context.workspaceName,`CHAT_BOT_MODULE/BOT/${context.assistant.id}/INTENTS`,"WRITE")


    return (
        <div className="automation_condition_form dialog_recognise_dialog">
            <div className="select_work_form">
                <InputContainer className="mb-0" style={{ marginTop: 20 }}>
                    <Input
                        type="text"
                        value={chooser ? search : condition.name}
                        onKeyDown={(e: any) => setKeycode(e.keyCode)}
                        onChange={(e: any) => {
                            if (keycode != 32 && e.target.value.length < 27) {
                                setSearch(e.target.value)
                            }
                        }}
                        onClick={(e: any) => {
                            if (chooser == false) {
                                setSearch(condition.name)
                            }
                            setChooser(true)
                        }}
                    />
                    {showError && (validateNode(context, condition) || validateNode(context, conditionEdit)) && (
                        <div className="error_input_show">
                            <WarningAlertIcon />
                            <span className="error_menu_hvr">Intent is not available.</span>
                        </div>
                    )}
                    <InputContent>Intent name</InputContent>
                    <CancelButton
                        onClick={(e: any) => {
                            if (chooser == false) {
                                setSearch(condition.name)
                            }
                            setChooser(true)
                        }}
                    >
                        <DropdownWithCircle />
                    </CancelButton>
                    <DropdownUlWrapper>
                        <DropDown isOpen={chooser} onOuterClick={() => setChooser(false)}>
                            <div className="automation_scroly" style={{ top: 0 }}>
                                <DropdownUl>
                                    {sortAnArrayByName(intents,"intent")
                                        .filter((e: IntentT) => e.intent.toLowerCase().includes(search.toLowerCase()))
                                        .map((intent: IntentT, index: number) => (
                                            <DropdownLi
                                                key={index}
                                                onClick={(e: any) => {
                                                    setChooser(false)
                                                    onChange({
                                                        ...condition,
                                                        name: intent.intent,
                                                        operator: operatorsT.IS_PRESENT
                                                    })
                                                }}
                                            >
                                                {intent.intent}
                                            </DropdownLi>
                                        ))}
                                    {intents.filter(e => e.intent.toLowerCase().includes(search.toLowerCase()))
                                        .length == 0 ? (
                                        <DropdownLi
                                            onClick={(e: any) => {
                                                if (search != '' && intentWritePermission.value) {
                                                    setChooser(false)
                                                    setAddExample(true)
                                                    setExamples([''])
                                                }
                                            }}
                                        >
                                            {intentWritePermission.value?
                                                (search == ''
                                                    ? intents.length == 0
                                                        ? 'No intent found, type to create new intent'
                                                        : `Create an intent called '${search}'`
                                                    : `Create an intent called '${search}'`)
                                            :"No intent found"}
                                        </DropdownLi>
                                    ) : null}
                                </DropdownUl>
                            </div>
                        </DropDown>
                    </DropdownUlWrapper>
                </InputContainer>

                {addExample ? (
                    <div className="dialog_scroll">
                        <p>
                            <span className="dialog_add_examples_left intent_compoenent_dialog_content">{search}</span>
                            {loading ? (
                                <span className="dialog_add_span_svg connect_account_loading">
                                    <img src={LoadingSvgImage} />
                                </span>
                            ) : (
                                <>
                                    <div className="get_users_icon">
                                        <div className="right_pop_icons color_icons">
                                            {findDublicate(examples) ? (
                                                <span className="editor_btn" onClick={e => addIntent()}>
                                                    <CheckColorIcon />
                                                </span>
                                            ) : null}
                                            <span className="editor_btn" onClick={e => setAddExample(false)}>
                                                <CloseColorIcon />
                                            </span>
                                        </div>
                                    </div>
                                </>
                            )}
                        </p>
                        {/* <div className="dialog_add_examples">{`Add Examples for '${search}'`}</div> */}
                        <DropdownWrapperRecognise>
                            <Header className="justify-content-start">
                                Add user utterances or examples{' '}
                                <Tooltip
                                    className="target customTip d-flex align-items-center verify-user"
                                    zIndex={10000}
                                    arrowSize={8}
                                    tagName="span"
                                    content={`Add Examples for '${search}'`}
                                    distance={5}
                                    
                                >
                                    <InfoIcon />
                                </Tooltip>
                            </Header>

                            {examples.map((value, count) => (
                                <div className="dialog_scroll_div" key={count}>
                                    <InputWrapper bgColor>
                                        <InputContainer>
                                            <Input
                                                type="text"
                                                value={value}
                                                onChange={(event: any) => {
                                                    const string = event.target.value
                                                    setExamples(data =>
                                                        data.map((val, ind) => (ind == count ? string : val))
                                                    )
                                                }}
                                            />
                                            <InputContent>Example {count + 1}</InputContent>

                                            <DropdownInputWrapper>
                                                {examples
                                                    .filter((e, i) => i != count)
                                                    .filter(e => e.toLowerCase().trim() == value.toLowerCase().trim())
                                                    .length > 0 ? (
                                                    <DropdownInputIcons data-tip data-for="sadFace">
                                                        <span className="error_dublicate_entry">
                                                            <Tooltip
                                                                className="target customTip"
                                                                zIndex={10000}
                                                                arrowSize={8}
                                                                tagName="span"
                                                                content="Duplicate examples are not allowed"
                                                                distance={5}
                                                            >
                                                                <svg
                                                                    x="0px"
                                                                    y="0px"
                                                                    viewBox="0 0 483.5 483.5"
                                                                    xmlSpace="preserve"
                                                                >
                                                                    <path d="M480 425L269.1 29.9c-5.3-9.9-15.6-16.1-26.8-16.1-11.2 0-21.5 6.2-26.8 16.1L3.6 425c-5 9.5-4.8 20.9.7 30 5.5 9.1 15.4 14.8 26.1 14.8h422.7c10.7 0 20.6-5.6 26.1-14.8 5.5-9.2 5.8-20.5.8-30zm-237.8-16c-16.8 0-30.4-13.6-30.4-30.4s13.6-30.4 30.4-30.4c16.8 0 30.4 13.6 30.4 30.4S259 409 242.2 409zm30.5-121.1c0 16.8-13.6 30.4-30.4 30.4-16.8 0-30.4-13.6-30.4-30.4V166.3c0-16.8 13.6-30.4 30.4-30.4 16.8 0 30.4 13.6 30.4 30.4v121.6z" />
                                                                </svg>
                                                            </Tooltip>
                                                        </span>
                                                    </DropdownInputIcons>
                                                ) : null}

                                                {examples.length != 1 ? (
                                                    <DropdownInputIcons>
                                                        <div className="right_pop_icons">
                                                            <span
                                                                className="editor_btn"
                                                                onClick={e =>
                                                                    setExamples(data =>
                                                                        data.filter((val, ind) => ind != count)
                                                                    )
                                                                }
                                                            >
                                                                <CloseIcon />
                                                            </span>
                                                        </div>
                                                    </DropdownInputIcons>
                                                ) : null}
                                            </DropdownInputWrapper>
                                        </InputContainer>
                                    </InputWrapper>
                                </div>
                            ))}
                            {examples.length < 5 && examples.filter(e => e.trim() == '').length == 0 ? (
                                <div className="plus_addmore">
                                    <div className="plus_add_more" onClick={e => setExamples(data => data.concat(''))}>
                                        <SmallButton primary>
                                            <span>
                                                <AddIcon />
                                            </span>
                                            <label>Add More</label>
                                        </SmallButton>
                                    </div>
                                </div>
                            ) : null}
                        </DropdownWrapperRecognise>
                    </div>
                ) : null}
            </div>
            {condition.name != '' ? (
                <div className="automation_condition_form">
                    <div className="select_work_form">
                        <InputWrapper marginTop>
                            <InputContainer>
                                <Input
                                    type="text"
                                    value={`is recognized`}
                                    onChange={() => {}}
                                    style={{ pointerEvents: 'none' }}
                                />
                                <InputContent>Condition</InputContent>
                            </InputContainer>
                        </InputWrapper>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default IntentCompoenent
