import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  CountNumber,
  CountWrapper,
  HeaderContainer,
  TabCardContainer,
} from '@/common/styled/Dialog.BotDetails.Dumb';
import {
  AgentSummaryIcon,
  AgentSummaryIconNew,
  InfoIcon,
  SlackIconCC,
  TeamsIcon,
  UserIconAnalyticsMenu,
  WidgetIcon,
} from '@/common/Icons/Icons';
import Tooltip from 'react-tooltip-lite';
import { OverAllSummaryT } from './BotDetails';
import { AssistantT } from '../Construction/kind';
import {
  useFetchLiveBotTabAgentSummary,
  useFetchLiveBotTabSummary,
  useFetchTop10StatusValues,
  useUpdateTop10StatusValues,
} from './BotSummaryAPI';
import { Top10ValuesCharts } from './QueriesTab';
import { formatAMPM, formatDateInCard } from '@/common/utils/utils';
import { percentageCalculate } from './TabUIElements';

const CardDetailsMicro = [
  {
    header: 'Active users',
    count: '25',
  },
  {
    header: 'User queries',
    count: '50',
  },
  {
    header: 'Queries auto-resolved',
    count: '45',
  },
  {
    header: 'Queries unresolved',
    count: '5',
  },
  {
    header: 'Tickets created',
    count: '45',
  },
];

const cardDetailsChannels = [
  {
    title: 'MS Teams',
    description: 'Live interaction of users with bot from MS Teams',
    icon: <TeamsIcon />,
    CardDetailsMicro,
    channel:"teams"
  },
  {
    title: 'Slack',
    description: 'Live interaction of users with bot from Slack',
    icon: <SlackIconCC />,
    CardDetailsMicro,
    channel:"slack"
  },
  {
    title: 'Widget',
    description: 'Live interaction of users with bot from Widget',
    icon: <WidgetIcon />,
    CardDetailsMicro,
    channel:"widget"
  },
];

const CardDetailsLiveBot = [
  {
    header: 'Active users',
    count: '25',
    // image: <InfoIcon />,
    // hoverContent: 'Total number of unique users who interacted with the bot in a specific time period.'
  },
  {
    header: 'User queries',
    count: '50',
    // image: <InfoIcon />,
    // hoverContent: 'Total number of bot sessions between bot and user in a specific time period.'
  },
  {
    header: 'Queries auto-resolved',
    count: '45',
    image: <InfoIcon />,
    hoverContent: 'User queries auto-resolved by bot using Dialogs and FAQs',
    countpercentage: true,
  },
  {
    header: 'Queries unresolved',
    count: '5',
    image: <InfoIcon />,
    hoverContent:
      'Queries that are responded by the bot but were marked as unresolved',
    countpercentage: true,
  },
  {
    header: 'Tickets created',
    count: '45',
    image: <InfoIcon />,
    hoverContent:
      'Queries that are responded by the bot but marked as ticket created',
  },
];

const CardDetailsAgent = [
  {
    header: 'Active agent channels',
    count: '25',
    image: <InfoIcon />,
    hoverContent: 'Live agents that are active on chat channels',
    countMsImage: <TeamsIcon />,
  },
  {
    header: 'Agent online',
    count: '50',
    image: <InfoIcon />,
    hoverContent: 'Live agents that are currently online',
  },
  {
    header: 'Active agent sessions',
    count: '45',
    image: <InfoIcon />,
    hoverContent: 'Live agent handover sessions in progress',
  },
  {
    header: 'Users in queue',
    count: '5',
    image: <InfoIcon />,
    hoverContent:
      'Users currently in agent handover queue waiting for live agent assistance',
  },
  {
    header: 'Agents idle',
    count: '45',
    image: <InfoIcon />,
    hoverContent: 'Live agents that are currently idle',
  },
];

type topLiveStatusT = {
  dialog: string;
  dialog_id: string;
  status_count: number;
  dialog_type:"faq"|"dialog"
};

type LiveCountT = {
  channel: string;
  active_users: number;
  num_of_queries: number;
  num_of_resolved_queries: number;
  num_of_unresolved_queries: number;
  num_of_ticket_created: number;
};

type TotalCountT = {
  active_users: number;
  num_of_queries: number;
  num_of_resolved_queries: number;
  num_of_unresolved_queries: number;
  num_of_ticket_created: number;
};
type LiveBotReportT = {
  live_count_by_channel: LiveCountT[];
  total_live_count: TotalCountT;
  top_10_live_status: topLiveStatusT[];
};

type LiveBotAgentReportT = {
  active_agent_channel:number;
  agents_idle: number;
  agents_online: number;
  user_queue: number;
  active_agent: number;
};

export function BotDetailsTab(props: {
  botId: string | undefined;
  workspace: string;
  overAllSummary: OverAllSummaryT;
  selectedBot: AssistantT;
  history: any;
  match: any;
  setTabLoading:(loading:boolean)=>void
}) {
  const { overAllSummary, history, match, setTabLoading } = props;

  const [currentTab, setCurrentTab] = useState<
    'resolved' | 'faq_answered' | 'ticket_created' | 'unresolved'
  >('resolved');

  const {
    liveBotSummary,
    liveBotSummaryStatus,
    liveBotSummaryRetry,
    dataUpdatedAt,
    isRefetching,
    isFetching
  } = useFetchLiveBotTabSummary(
    props.workspace,
    props.botId ? props.botId : '',
    {
      enabled: !!props.botId,
      onSuccess:(data:any)=>{
        setTabLoading(false)
      }
    }
  );

  const { top10StatusValues } = useFetchTop10StatusValues(
    props.workspace,
    props.botId ? props.botId : '',
    null,
    {
      enabled: !!props.botId,
    },
    'top10LiveBotStatusValues',
    'resolved'
  );

  const { liveBotAgentSummary } = useFetchLiveBotTabAgentSummary(props.workspace,
    props.botId ? props.botId : '',
    {
      enabled: !!props.botId,
  })

  // for local
  // const liveBotAgentSummary = {
  //   active_agent_channel:0,
  //   agents_idle: 2,
  //   agents_online: 2,
  //   user_queue: 3,
  //   active_agent: 4,
  // };

//   const top10StatusValues = [
//     {
//         "dialog": "Fallwwback",
//         "dialog_id": "f79613s3-b136-43a9-bbc1-b15b7dfa6e7c",
//         "status_count": 1
//     },
//     {
//       "dialog": "Fallback",
//       "dialog_id": "f79613ss3-b136-43a9-bbc1-b15b7dfa6e7c",
//       "status_count": 4
//   },
//   ]

//     const liveBotSummary = {
//     "live_count_by_channel": [
//         {
//             "active_users": 1,
//             "num_of_queries": 1,
//             "num_of_resolved_queries": 3,
//             "num_of_unresolved_queries": 5,
//             "num_of_ticket_created": 1,
//             "channel": "teams"
//         }
//     ],
//     "total_live_count": {
//         "active_users": 1,
//         "num_of_queries": 6,
//         "num_of_resolved_queries": 2,
//         "num_of_unresolved_queries": 4,
//         "num_of_ticket_created": 5
//     },
//     "top_10_live_status": [
//         {
//             "dialog": "Fallback",
//             "dialog_id": "f79613c3-b136-43a9-bbc1-b15b7dfa6e7c",
//             "status_count": 1
//         }
//     ]
// } as any



  const { top10StatusValuesMutate } = useUpdateTop10StatusValues(
    'top10LiveBotStatusValues'
  );

  

  useEffect(() => {
    console.log(top10StatusValues, 'top10StatusValues');
    top10StatusValues &&
      top10StatusValuesMutate({
        botId: props.botId,
        workspace: props.workspace,
        date: null,
        status: currentTab,
      });
  }, [currentTab]);

  function retnUserValues(i: number) {
    if (!liveBotSummary) return 0;
    const s = liveBotSummary as LiveBotReportT;
    switch (i) {
      case 0:
        return s.total_live_count.active_users;
      case 1:
        return s.total_live_count.num_of_queries;
      case 2:
        return s.total_live_count.num_of_resolved_queries;
      case 3:
        return s.total_live_count.num_of_unresolved_queries;
      default:
        return s.total_live_count.num_of_ticket_created;
    }
  }

  function retnChannelValues(i: number, channel: string) {
    const s = liveBotSummary as LiveBotReportT;

    const currentChannelValues = s && s.live_count_by_channel.filter(
      (s) => s.channel === channel
    )[0];

    if (!liveBotSummary || s.live_count_by_channel.length === 0 || !currentChannelValues) return 0;

    switch (i) {
      case 0:
        return currentChannelValues.active_users;
      case 1:
        return currentChannelValues.num_of_queries;
      case 2:
        return currentChannelValues.num_of_resolved_queries;
      case 3:
        return currentChannelValues.num_of_unresolved_queries;
      default:
        return currentChannelValues.num_of_ticket_created;
    }
  }

  function retnAgentValues(i: number) {
    const s = liveBotAgentSummary as LiveBotAgentReportT;

    if (!liveBotAgentSummary) return 0;

    switch (i) {
      case 0:
        return s.active_agent_channel;
      case 1:
        return s.agents_online;
      case 2:
        return s.active_agent;
      case 3:
        return s.user_queue;
      default:
        return s.agents_idle;
    }
  }

  function returnPercentage(i:number){
    const lbs = liveBotSummary as LiveBotReportT
    if(lbs.total_live_count.num_of_queries === 0){
        return 0
    }
    if(i===2){
        return (lbs.total_live_count.num_of_resolved_queries/ lbs.total_live_count.num_of_queries) * 100
    }else if(i===3){
        return (lbs.total_live_count.num_of_unresolved_queries/ lbs.total_live_count.num_of_queries) * 100
    }
  }


  return (
    <div className="bot_sessions_container live_bot_container">
      {isRefetching && 
            setTabLoading(true)
      }
      {isFetching && 
            setTabLoading(true)}
      <div className="bot_sessions_heaader">
        <div>
          <h5>Explore how users are interacting with the bot right now</h5>
        </div>
        <div>
          This report was generated on{' '}
          {formatDateInCard((liveBotSummary?new Date(dataUpdatedAt):new Date()).toString(), true)} at{' '}
          {formatAMPM((liveBotSummary?new Date(dataUpdatedAt):new Date()).toString(), true)} -{' '}
          <span onClick={() => {
            setTabLoading(true)
            liveBotSummaryRetry()
          }}>Refresh</span>
        </div>
      </div>

      <div className="tab_section_wrapper live_bot_section">
        <div className="tab_section_header live_bot sub_header">
          <div>
            <span className="tab_section_header_icon">
              <UserIconAnalyticsMenu />
            </span>
            <h5>
              Users
              <span>
                (Live interaction of users with bot from all chat channels)
              </span>
            </h5>
          </div>
          <div className="tab_dropdown_date"></div>
        </div>
        <HeaderContainer>
          <TabCardContainer className="tab_user_queris_two live_bot">
            {CardDetailsLiveBot.map((data, i) => (
              <Card className="live_bot_card">
                <CardHeader style={{ color: '#355D8B' }}>
                  {data.header}{' '}
                  <Tooltip
                    className="target_bot"
                    tipContentHover
                    zIndex={5000}
                    arrowSize={5}
                    tagName="span"
                    content={data.hoverContent}
                  >
                    <span>{data.image}</span>
                  </Tooltip>
                </CardHeader>
                <CountNumber>
                  <CountWrapper>
                    {retnUserValues(i)}
                    <span className="header_count_percentage">
                      {data.countpercentage && liveBotSummary ? percentageCalculate(returnPercentage(i)?returnPercentage(i) as number:0)+"%"  :""}
                    </span>
                  </CountWrapper>
                </CountNumber>
              </Card>
            ))}
          </TabCardContainer>
        </HeaderContainer>
      </div>

      {cardDetailsChannels.map((channel, i) => (
        <div className="tab_section_wrapper live_bot_section" key={i}>
          <div className="tab_section_header live_bot sub_header">
            <div>
              <span className="tab_section_header_icon">{channel.icon}</span>
              <h5>
                {channel.title}
                <span>({channel.description})</span>
              </h5>
            </div>
            <div className="tab_dropdown_date"></div>
          </div>
          <HeaderContainer>
            <TabCardContainer className="tab_user_queris_two live_bot">
              {channel.CardDetailsMicro.map((data, j) => (
                <Card className={`live_bot_card ${channel.channel}`}>
                  <CardHeader style={{ color: '#355D8B' }}>
                    {data.header}
                  </CardHeader>
                  <CountNumber>
                    <CountWrapper>
                      {retnChannelValues(j, channel.channel)}
                    </CountWrapper>
                  </CountNumber>
                </Card>
              ))}
            </TabCardContainer>
          </HeaderContainer>
        </div>
      ))}

      <div className="tab_section_wrapper live_bot_section">
        <div className="tab_section_header live_bot sub_header">
          <div>
            <span className="tab_section_header_icon">
              <AgentSummaryIconNew />
            </span>
            <h5>
              Agent<span>(Live interaction of users with bot from Agent)</span>
            </h5>
          </div>
          <div className="tab_dropdown_date"></div>
        </div>
        <HeaderContainer>
          <TabCardContainer className="tab_user_queris_two live_bot">
            {CardDetailsAgent.map((data, i) => (
              <Card className="live_bot_card agent">
                <CardHeader style={{ color: '#355D8B' }}>
                  {data.header}{' '}
                  <Tooltip
                    className="target_bot"
                    tipContentHover
                    zIndex={5000}
                    arrowSize={5}
                    tagName="span"
                    content={data.hoverContent}
                  >
                    <span>{data.image}</span>
                  </Tooltip>
                </CardHeader>
                <CountNumber>
                  <CountWrapper>{retnAgentValues(i)}</CountWrapper>
                  <CountWrapper>{data.countMsImage}</CountWrapper>
                </CountNumber>
              </Card>
            ))}
          </TabCardContainer>
        </HeaderContainer>
      </div>

      <div className="tab_section_wrapper">
        <div className="tab_section_header">
          <div>
            <h5>How most frequent user queries were handled</h5>
            {/* <p></p> */}
          </div>
        </div>
        <div className="tab_menu tab_section">
          <a
            className={currentTab === 'resolved' ? 'active' : ''}
            onClick={() => setCurrentTab('resolved')}
          >
            Auto-resolved using Dialogs{' '}
          </a>
          <a
            className={currentTab === 'faq_answered' ? 'active' : ''}
            onClick={() => setCurrentTab('faq_answered')}
          >
            Auto-resolved using FAQs
          </a>
          <a
            className={currentTab === 'ticket_created' ? 'active' : ''}
            onClick={() => setCurrentTab('ticket_created')}
          >
            Ticket created
            {/* Ticket created for queries */}
          </a>
          <a
            className={currentTab === 'unresolved' ? 'active' : ''}
            onClick={() => setCurrentTab('unresolved')}
          >
            Unresolved
          </a>
        </div>
        <Top10ValuesCharts
          top10ValuesForAgent={
            top10StatusValues
              ? (top10StatusValues as topLiveStatusT[])
              : []
          }
          history={history}
          match={match}
        />
      </div>
    </div>
  );
}
