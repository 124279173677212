/*eslint-disable*/
import React, { useEffect, useState } from 'react'

import { sortAnArrayAlphabetically } from '@/Flows/canvas/utils'
// IMPORT GOOGLE ANALYTICS TRACKER
import lazyGA from '@/common/google-analytics/tracking'

import { AssistantT } from '../Construction/kind'
//ACTION IMPORTS
import { createIntent, deleteIntent, getAssistants, getIntents, updateIntent } from '../Mechanisms/action'
//TYPE IMPORTS
import { ActionT, ROUTER_PROPS_T } from '../kind'
//COMPONENTS IMPORT
import Intents from './Intents'
import { IntentRequestT, IntentT, LOADING, PageStateT, VIEW } from './kind'
import { logEvent } from '@/common/utils/api-utils'
import { useComputed, useSignal } from '@preact/signals-react'
import { usePermissionSignal } from '@/common/utils/auth-token-api-utils'

const AssistantIntent = (props: ROUTER_PROPS_T) => {
    const [PageState, setPageState] = useState<PageStateT>(LOADING)

    const [intents, setIntents] = useState<IntentT[]>([])

    const [assistantID, setAssistantID] = useState<string>('')

    const { workspacename, assistantname } = props.match.params

    const moduleName$= useSignal<string>("")

    const intentWritePermission = usePermissionSignal(workspacename,moduleName$,"WRITE")

    const intentDeletePermission = usePermissionSignal(workspacename,moduleName$,"DELETE")

    const loadPage = () =>
        getAssistants(workspacename)
            .then((res: AssistantT[]) => {
                const assistant = res.reduce((accum: string, val: AssistantT) => {
                    if (val.name == assistantname) return val.id
                    else return accum
                }, '')
                if (assistant === '') {
                    setPageState({
                        action: ActionT.RESOURCE_NOT_FOUND,
                        resource: 'WORKSPACE'
                    })
                } else {
                    moduleName$.value = `CHAT_BOT_MODULE/BOT/${assistant}/INTENTS`

                    setAssistantID(assistant)
                    getIntents(workspacename, assistant)
                        .then((data: IntentT[]) => {
                            if (data.length == 0)
                                setPageState({
                                    action: ActionT.NO_DATA_FOUND,
                                    loading: false,
                                    intent: '',
                                    description: ''
                                })
                            else setPageState(VIEW)
                            setIntents(sortAnArrayAlphabetically(data, 'intent'))
                        })
                        .catch((_: {}) => {
                            setPageState({
                                action: ActionT.ERROR,
                                reason: 'There was a problem in loading Page! <br/> Please click on "Retry"'
                            })
                        })
                }
            })
            .catch(error => {
                if (error.response && error.response.status == 403) {
                    setPageState({
                        action: ActionT.UN_AUTHORIZED
                    })
                } else {
                    setPageState({
                        action: ActionT.ERROR,
                        reason: 'There was a problem in loading Page! <br/> Please click on "Retry"'
                    })
                }
            })

    useEffect(() => {
        loadPage()
    }, [])

    const removeIntent = (successCallback: Function, failureCallback: Function) => {
        switch (PageState.action) {
            case ActionT.MULTIPLE_DELETE:
                Promise.all(
                    PageState.items
                        .reduce((accum: any[], val: string) => {
                            const index = intents.findIndex(e => e.id === val)
                            if (index === -1) return accum
                            return accum.concat([
                                {
                                    id: val,
                                    intent: intents[index].intent
                                }
                            ])
                        }, [])
                        .map(e => deleteIntent(workspacename, assistantID, e.id, e.intent))
                )
                    .then((_: {}) => {
                        successCallback()
                        const list = intents.reduce((accum: IntentT[], value: IntentT): IntentT[] => {
                            if (
                                PageState.action === ActionT.MULTIPLE_DELETE &&
                                PageState.items.findIndex((data: string) => data === value.id) === -1
                            )
                                return accum.concat(value)
                            else return accum
                        }, [])
                        if (list.length == 0) {
                            setPageState({
                                action: ActionT.NO_DATA_FOUND,
                                loading: false,
                                intent: '',
                                description: ''
                            })
                        } else setPageState(VIEW)
                        setIntents(list)
                    })
                    .catch(() => {
                        failureCallback('Server error!<br />Please try again after sometime.')
                    })
                break
            case ActionT.DELETE:
                deleteIntent(workspacename, assistantID, PageState.data.id, PageState.data.intent)
                    .then((_: {}) => {
                        successCallback()
                        const list = intents.filter((data: IntentT) => data.id !== PageState.data.id)
                        if (list.length == 0) {
                            setPageState({
                                action: ActionT.NO_DATA_FOUND,
                                loading: false,
                                intent: '',
                                description: ''
                            })
                        } else setPageState(VIEW)
                        setIntents(list)
                    })
                    .catch(() => {
                        failureCallback('Server error!<br />Please try again after sometime.')
                    })
                break
        }
    }

    const saveIntent = (successCallback: Function, failureCallback: Function) => {
        switch (PageState.action) {
            case ActionT.NO_DATA_FOUND:
                const intentData: IntentRequestT = {
                    description: PageState.description,
                    intent: PageState.intent
                }

                createIntent(workspacename, assistantID, intentData)
                    .then((response: IntentT) => {
                        // setIntents(data => data.concat([response]))
                        // successCallback()
                        // setPageState(VIEW)
                        logEvent(workspacename, {
                            event:"EVENT::INTENT::CREATE_WITH_EXAMPLES", event_meta:{
                                description: PageState.description,
                                name: PageState.intent          
                            }
                        }).finally(()=>{
                            editIntent(response.intent)
                        })
                        lazyGA().event({
                            category: 'Intent',
                            action: 'Create Intent'
                        })
                    })
                    .catch(() => {
                        failureCallback(`Server error!<br />Please try again after sometime.`)
                    })
                break
            case ActionT.ADD:
                const intentAdd: IntentRequestT = {
                    description: PageState.description,
                    intent: PageState.intent
                }

                createIntent(workspacename, assistantID, intentAdd)
                    .then((response: IntentT) => {
                        // setIntents(data => data.concat([response]))
                        // setPageState(VIEW)
                        // successCallback()

                        logEvent(workspacename, {
                            event:"EVENT::INTENT::CREATE_WITH_EXAMPLES", event_meta:{
                                description: PageState.description,
                                name: PageState.intent          
                            }
                        }).finally(()=>{
                            editIntent(response.intent)
                        })

                        lazyGA().event({
                            category: 'Intent',
                            action: 'Create Intent'
                        })
                    })
                    .catch(error => {
                        failureCallback(`Server error!<br />Please try again after sometime.`)
                    })
                break

            case ActionT.EDIT:
                const intentEdit: IntentRequestT = {
                    intent: PageState.intent,
                    description: PageState.description
                }

                updateIntent(workspacename, assistantID, PageState.id, intentEdit)
                    .then((response: IntentT) => {
                        setIntents((data: IntentT[]) =>
                            sortAnArrayAlphabetically(
                                data.map((intent: IntentT) =>
                                    PageState.action === ActionT.EDIT && intent.id == PageState.id ? response : intent
                                ),
                                'intent'
                            )
                        )
                        setPageState(VIEW)
                        successCallback()
                    })
                    .catch(() => {
                        failureCallback(`Server error!<br />Please try again after sometime.`)
                    })
                break
        }
    }

    const editIntent = (intent: string) =>
        props.history.push(`/workspace/${workspacename}/dialog/${assistantname}/intent/${intent}`)

    return (
        <Intents
            PageState={PageState}
            setPageState={setPageState}
            intents={intents}
            retry={loadPage}
            editIntent={editIntent}
            removeIntent={removeIntent}
            saveIntent={saveIntent}
            permissions={{write:intentWritePermission.value,delete:intentDeletePermission.value}}
        />
    )
}

export default AssistantIntent
