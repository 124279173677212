/*eslint-disable*/
import React, { useEffect, useState } from 'react'

//TYPE IMPORTS
import { AssistantT } from '../Construction/kind'
import { DefaultIntent, IntentRequestT, IntentT } from '../Intent/kind'
//ACTIONS IMPORT
import {
    createIntentExample,
    deleteIntentExample,
    getAssistants,
    getIntentExample,
    getIntents,
    updateIntent,
    updateIntentExample
} from '../Mechanisms/action'
import { ActionT, ROUTER_PROPS_T } from '../kind'
// COMPONENT IMPORT
import Examples from './Example'
import { ExampleT, Loading, PageStateT, View } from './kind'
import { usePermissionSignal } from '@/common/utils/auth-token-api-utils'
import { useComputed, useSignal } from '@preact/signals-react'

const AssistantIntentExamples = (props: ROUTER_PROPS_T) => {
    const { workspacename, assistantname, intent } = props.match.params

    const [assistantID, setAssistantID] = useState<string>('')

    const [intents, setIntents] = useState<IntentT[]>([])

    const [currentIntent, setCurrentIntent] = useState<IntentT>(DefaultIntent)

    const [examples, setExamples] = useState<ExampleT[]>([])

    const [PageState, setPageState] = useState<PageStateT>(Loading)

    const [moveExampleError, setmoveExampleError] = useState<null | string>(null)
    const moduleName$= useSignal<string>("")

    const intentWritePermission = usePermissionSignal(workspacename,moduleName$,"WRITE")

    const selecttedModule$ = useComputed(() => {
        return `${moduleName$.value}/EXAMPLES`
    })  

    const exampleWritePermission = usePermissionSignal(workspacename,selecttedModule$,"WRITE")

    const exampleDeletePermission = usePermissionSignal(workspacename,selecttedModule$,"DELETE")

    const fetch = () => {
        getAssistants(workspacename)
            .then((res: AssistantT[]) => {
                const assistant = res.reduce((accum: string, val: AssistantT) => {
                    if (val.name == assistantname) return val.id
                    else return accum
                }, '')
                if (assistant == '') {
                    setPageState({ action: ActionT.RESOURCE_NOT_FOUND })
                } else {

                    getIntents(workspacename, assistant)
                        .then((data: IntentT[]) => {
                            const intentObj = data.reduce((accum: IntentT, val: IntentT) => {
                                if (val.intent == intent) return val
                                else return accum
                            }, DefaultIntent)

                            if (intentObj.id === '') {
                                setPageState({ action: ActionT.RESOURCE_NOT_FOUND })
                            } else {
                                moduleName$.value = `CHAT_BOT_MODULE/BOT/${assistant}/INTENTS/${intentObj.id}`

                                getIntentExample(workspacename, assistant, intentObj.id)
                                    .then((response: ExampleT[]) => {
                                        setAssistantID(assistant)
                                        setIntents(data)
                                        setCurrentIntent(intentObj)
                                        setExamples(response)
                                        if (response.length == 0) {
                                            setPageState({
                                                action: ActionT.ADD,
                                                examples: ['']
                                            })
                                        } else setPageState(View)
                                    })
                                    .catch((error: any) => {
                                        setPageState({
                                            action: ActionT.ERROR,
                                            error: true,
                                            info: 'There was a problem in loading Page! <br/> Please click on "Retry"'
                                        })
                                    })
                            }
                        })
                        .catch((err: any) => {
                            setPageState({
                                action: ActionT.ERROR,
                                error: true,
                                info: 'There was a problem in loading Page! <br/> Please click on "Retry"'
                            })
                        })
                }
            })
            .catch((error: any) => {
                if (error.response && error.response.status == 403) {
                    setPageState({
                        action: ActionT.UN_AUTHORIZED
                    })
                } else {
                    setPageState({
                        action: ActionT.ERROR,
                        error: true,
                        info: 'There was a problem in loading Page! <br/> Please click on "Retry"'
                    })
                }
            })
    }

    useEffect(fetch, [])

    const saveIntent = (successCallback: Function, failureCallback: Function) => {
        if (PageState.action == ActionT.INTENT_EDIT) {
            const intentEdit: IntentRequestT = {
                intent: PageState.intent,
                description: PageState.description
            }

            updateIntent(workspacename, assistantID, PageState.id, intentEdit)
                .then((_: IntentT) => {
                    successCallback()
                    props.history.replace(
                        `/workspace/${workspacename}/dialog/${assistantname}/intent/${PageState.intent}`
                    )
                    getIntents(workspacename, assistantID)
                        .then((data: IntentT[]) => {
                            const intentObj = data.reduce((accum: IntentT, val: IntentT) => {
                                if (val.intent == PageState.intent) return val
                                else return accum
                            }, DefaultIntent)

                            if (intentObj.id === '') {
                                setPageState({ action: ActionT.RESOURCE_NOT_FOUND })
                            } else {
                                getIntentExample(workspacename, assistantID, intentObj.id)
                                    .then((response: ExampleT[]) => {
                                        setIntents(data)
                                        setCurrentIntent(intentObj)
                                        setExamples(response)
                                        if (response.length == 0) {
                                            setPageState({
                                                action: ActionT.ADD,
                                                examples: ['']
                                            })
                                        } else setPageState(View)
                                    })
                                    .catch((error: any) => {
                                        setPageState({
                                            action: ActionT.ERROR,
                                            error: true,
                                            info: 'There was a problem in loading Page! <br/> Please click on "Retry"'
                                        })
                                    })
                            }
                        })
                        .catch((err: any) => {
                            setPageState({
                                action: ActionT.ERROR,
                                error: true,
                                info: 'There was a problem in loading Page! <br/> Please click on "Retry"'
                            })
                        })
                })
                .catch(() => {
                    failureCallback(`Server error!<br />Please try again after sometime.`)
                })
        }
    }

    const createExample = async (successCallback: Function, failureCallback: Function) => {
        if (PageState.action == ActionT.ADD) {
            // const proms = PageState.examples
            //     .map((data: string) => ({
            //         text: data
            //     }))
            //     .map((data: ExampleRequestT) =>
            //         createIntentExample(workspacename, assistantID, currentIntent.id, data, currentIntent.intent)
            //     )
            try {
                await createIntentExample(
                    workspacename,
                    assistantID,
                    currentIntent.id,
                    PageState.examples,
                    currentIntent.intent
                )

                // const notValidResults = results.filter((result: any) => result instanceof Error)

                // if (results.length != notValidResults.length) {

                // const validResults = results.filter((result: any) => !(result instanceof Error))
                // setExamples(data => data.concat(validResults))
                window.location.reload()
                successCallback()
            } catch (err) {
                failureCallback()
            }
        }
    }

    const removeExample = (successCallback: Function, failureCallback: Function) => {
        switch (PageState.action) {
            case ActionT.MULTIPLE_DELETE:
                Promise.all(
                    PageState.selectedItems.map((exampleID: string) =>
                        deleteIntentExample(
                            workspacename,
                            assistantID,
                            currentIntent.id,
                            exampleID,
                            currentIntent.intent
                        )
                    )
                )
                    .then((_: {}) => {
                        successCallback()
                        const example_list = examples.reduce((accum: ExampleT[], value: ExampleT): ExampleT[] => {
                            if (
                                PageState.action === ActionT.MULTIPLE_DELETE &&
                                PageState.selectedItems.findIndex((data: string) => data === value.id) === -1
                            )
                                return accum.concat(value)
                            else return accum
                        }, [])
                        if (example_list.length == 0) {
                            setPageState({
                                action: ActionT.ADD,
                                examples: ['']
                            })
                        } else setPageState(View)
                        setExamples(example_list)
                    })
                    .catch(() => {
                        failureCallback('Server error!<br />Please try again after sometime.')
                    })
                break
            case ActionT.DELETE:
                deleteIntentExample(
                    workspacename,
                    assistantID,
                    currentIntent.id,
                    PageState.example.id,
                    currentIntent.intent
                )
                    .then((_: {}) => {
                        successCallback()
                        const example_list = examples.filter((data: ExampleT) => data.id !== PageState.example.id)
                        if (example_list.length == 0) {
                            setPageState({
                                action: ActionT.ADD,
                                examples: ['']
                            })
                        } else setPageState(View)
                        setExamples(example_list)
                    })
                    .catch(() => {
                        failureCallback('Server error!<br />Please try again after sometime.')
                    })
                break
        }
    }

    const editExampleIntent = () => {
        if (PageState.action == ActionT.EDIT) {
            updateIntentExample(
                workspacename,
                assistantID,
                currentIntent.id,
                PageState.exampleid,
                {
                    text: PageState.example
                },
                currentIntent.intent
            )
                .then(res => {
                    setExamples((data: ExampleT[]) =>
                        data.map((exam: ExampleT) => (exam.id === PageState.exampleid ? { ...res } : exam))
                    )
                    setPageState(View)
                })
                .catch(() => {
                    console.log('err:::::')
                })
        }
    }

    const moveExample = (selected: string, cb: Function) => {
        if (PageState.action == ActionT.MOVE) {
            const selectedExamples = examples
                .map(e => (PageState.selectedItems.includes(e.id) ? e.text : null))
                .filter(Boolean)

            createIntentExample(
                workspacename,
                assistantID,
                selected,
                selectedExamples as string[],
                currentIntent.intent
            )
                .then(() => {
                    Promise.all([
                        ...PageState.selectedItems.map((exampleID: string) =>
                            deleteIntentExample(
                                workspacename,
                                assistantID,
                                currentIntent.id,
                                exampleID,
                                currentIntent.intent
                            )
                        )
                    ]).then((_: {}) => {
                        setPageState(View)
                        setExamples(exam => exam.filter(e => !PageState.selectedItems.includes(e.id)))
                        cb()
                    })
                })
                .catch((error: any) => {
                    setmoveExampleError(
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : 'Something went wrong! Please try again.'
                    )
                })
        }
    }

    return (
        <Examples
            PageState={PageState}
            intent={currentIntent}
            intents={intents}
            examples={examples}
            saveIntent={saveIntent}
            setPageState={setPageState}
            createExample={createExample}
            removeExample={removeExample}
            editExampleIntent={editExampleIntent}
            moveExample={moveExample}
            moveExampleError={moveExampleError}
            setmoveExampleError={setmoveExampleError}
            permissions={{intentWritePermission:intentWritePermission.value,exampleWritePermission:exampleWritePermission.value,exampleDeletePermission:exampleDeletePermission.value}}
        />
    )
}

export default AssistantIntentExamples
