/*eslint-disable*/
import React, { useEffect, useState } from 'react'

// import { RunIcon, EditIcon, CheckIcon } from '@/common/Icons'
import { CloseBrace, OpenBrace, RunIcon } from '@/common/Icons/Icons'

import { FlowApi, flowApiURL, getJson, postJson } from '@/common/utils/api-utils'
import { Config, Node, NodeConfig, NodeResponseT, NormalOrDevNode } from './types'

const ramda = require('ramda')

export function Run(props: any) {
    let duration: any

    const [config, setConfig] = useState<any>({})
    const [defaultInputValues, setDefaultValues] = useState({})
    const [buttonEnabled, setEnable] = useState(false)
    const [executionDetails, setExecutionDetails] = useState({})
    const [elapsedTime, setElapsedTime] = useState<any>(0)
    const [currentView, setView] = useState('normal')
    const [logs, setLogs] = useState<any>({})
    const [currentNode, setCurrentNode] = useState({
        node: 'AutomationInput',
        value: [
            {
                node: '',
                outputs: [
                    {
                        executionId: '',
                        flowId: '',
                        insertedTime: '',
                        level: '',
                        logType: '',
                        message: '',
                        nodeId: '',
                        nodeName: '',
                        schema: {}
                    }
                ]
            }
        ],
        nodeName: ''
    })
    const [Loading, setLoading] = useState(false)

    useEffect(() => {
        callApi()
    }, [])
    const workspaceName = props.match.params.workspacename
    // console.log('wname', workspaceName)
    const callApi = async () => {
        const configuration = await getJson(
            flowApiURL(workspaceName, FlowApi.AddFlow + '/config/' + props.match.params.id)
        )
        setConfig(configuration)
    }

    const enableButton = (disabled: boolean, value: object) => {
        setDefaultValues(value)
        setEnable(disabled)
    }

    const changeView = (view: string) => {
        setView(view)
        if (currentNode.node === 'node') {
            const currentNodeInput =
                view === 'normal'
                    ? {
                          ...currentNode,
                          value: logs.user.filter((x: Node) => x.node === currentNode.nodeName)
                      }
                    : {
                          ...currentNode,
                          value: logs.dev.filter((x: Node) => x.node === currentNode.nodeName)
                      }
            setCurrentNode(currentNodeInput)
        }
    }

    function findDistinctValue(array: NodeResponseT[], usrOrDev: string) {
        const distinct = (val: NodeResponseT, index: number, self: NodeResponseT[]) => {
            return self.filter((node: NodeResponseT) => node.nodeName === val.nodeName)
        }

        const firstLvlFilter = array.filter(
            (input: NodeResponseT) =>
                input.logType.includes(usrOrDev) && input.nodeName !== 'input' && input.nodeName !== 'output'
        )
        const filteredValue = firstLvlFilter.filter(distinct)
        const ouput = ramda.groupBy((x: NodeResponseT) => x.nodeName)(filteredValue)
        return Object.entries(ouput).map(nodes => {
            return { node: nodes[0], outputs: nodes[1] }
        })
    }

    const run = () => {
        duration = new Date()

        //show Loading
        setLoading(true)
        postJson(flowApiURL(workspaceName, FlowApi.AddFlow + '/execute'))({
            flow: props.match.params.id,
            platform: 'test',
            input: defaultInputValues
        })
            .then(async response => {
                const currentDate: any = new Date()
                const elapsedTime = ((currentDate - duration) / 1000) % 60

                setExecutionDetails(response.data.message)
                //set api duration
                setElapsedTime(elapsedTime)

                let data = await getJson(
                    flowApiURL(workspaceName, '/flows/fetch/logs/' + response.data.message.executionId)
                )
                //disable Loading
                setLoading(false)

                let logs = {
                    dev: findDistinctValue(data.logs, 'dev:'),
                    user: findDistinctValue(data.logs, 'user:')
                }
                setLogs(logs)
            })
            .catch(err => {
                setLoading(false)
            })
    }

    const changeNode = (currentNode: NormalOrDevNode) => {
        if (currentNode.node !== 'node') {
            setCurrentNode(currentNode)
        } else {
            const currentNodeInput =
                currentView === 'normal'
                    ? {
                          ...currentNode,
                          value: logs.user.filter((x: Node) => x.node === currentNode.nodeName)
                      }
                    : {
                          ...currentNode,
                          value: logs.dev.filter((x: Node) => x.node === currentNode.nodeName)
                      }
            setCurrentNode(currentNodeInput)
        }
    }

    const switchOutputView = (value: string) => {
        if (Loading) {
            return (
                <div className="console_bg_head_right flow-loader">
                    <div className="automation_output flow_group_home">
                        <div className="console-data-description">
                            <div className="fill_values">
                                <p>Executing flow ...</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        switch (value) {
            case 'node':
                return currentView === 'normal' ? (
                    <NodeWithNormalView data={currentNode} flow={config} />
                ) : (
                    <NodeWithDeveloperView data={currentNode} flow={config} />
                )
            case 'AutomationOutput':
                return <AutomationOutput executionDetails={executionDetails} currentView={currentView} />
            default:
                return (
                    <AutomationInput
                        data={config}
                        enableButton={enableButton}
                        defaultInputValues={defaultInputValues}
                    />
                )
        }
    }

    return (
        <section className="cogni_cards fixed-top">
            <div className="col-md-12 col-lg-12 col-sm-12 col-12 intent_home button_green flow_group_home">
                <div className="row">
                    <Header nodes={config} onClick={() => window.history.back()} />
                    <RunButton disable={!buttonEnabled} onClick={run} />
                </div>
            </div>
            <NodeList nodes={config} executionDetails={executionDetails} elapsedTime={elapsedTime} />
            <div className="console_bg_head">
                <RadioButtons currentView={currentView} setView={changeView} />
                <div className="console_bg_head_width">
                    <LeftTabs
                        logs={logs}
                        currentView={currentView}
                        changeNode={changeNode}
                        executionDetails={executionDetails}
                        executed={Object.keys(executionDetails).length > 0}
                    />
                    <div className="consolor_bg_right_head">
                        <div className="console_bg_head_right_left">
                            <div className="console-bg-workplace">
                                <div className="container-fluid tab-console-bg">
                                    <div className="pane_width_top">{switchOutputView(currentNode.node)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

type HeaderP = {
    nodes: NodeConfig
    onClick: () => void
}

export function Header(props: HeaderP) {
    return (
        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 indent_dialog_left">
            <div className="indent_left">
                <div className="indent_smallest_title" onClick={props.onClick}>
                    Automation
                    <span className="flowditor_sub_title">Editor</span>
                </div>
                <div className="indent_main_title">{props && props.nodes && props.nodes.name}</div>
                <div className="indent_paragraph">
                    <p>Fill the input values and click on Run button to test the automation</p>
                </div>
            </div>
            <div className="indent_right" />
        </div>
    )
}

type NodeListP = {
    nodes: NodeConfig
    elapsedTime: number
    executionDetails: any
}

export function NodeList(props: NodeListP) {
    return (
        <div className="col-md-12 p-l-130">
            <div className="row">
                <div className="col-md-3 intent_list_description">
                    <span>Name</span>
                </div>
                <div className="col-md-3 intent_list_description">
                    <span>Duration</span>
                </div>
                <div className="col-md-3 intent_list_description">
                    <span>Status</span>
                </div>
                <div className="col-md-2 intent_list_description">
                    <span>Error</span>
                </div>
                <div className="list-full-width">
                    <div className="col-md-12 list-view-sub">
                        <div className="main_sub">
                            <div className="col-md-3 list_flow_hr">
                                <div className="search_card_list">
                                    <h5>{props && props.nodes && props.nodes.name}</h5>
                                </div>
                            </div>
                            <div className="col-md-3 list_flow_p">
                                <p className="listite_centre">
                                    {props && props.elapsedTime !== 0 ? props.elapsedTime + ' Sec' : '---'}
                                </p>
                            </div>
                            <div className="col-md-3  list_flow_span">
                                <span className="entity_span">
                                    {props && props.executionDetails && props.executionDetails.success !== undefined
                                        ? props.executionDetails.success === true
                                            ? 'Success'
                                            : 'Failed'
                                        : '---'}
                                </span>
                            </div>
                            <div className="col-md-3 list_flow_span">
                                <span>
                                    {props && props.executionDetails && props.executionDetails.success !== undefined
                                        ? props.executionDetails.success === true
                                            ? 'No Error'
                                            : 'Error'
                                        : '---'}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

type RunButtonP = {
    onClick: () => void
    disable: boolean
}

export const RunButton = (props: RunButtonP) => {
    return (
        <div className="col-md-2 col-lg-2 col-sm-2 col-xs-12 intent_dialog_right">
            <div className="flow_sortby_dropdown">
                <button className="btn btn-primary" disabled={props.disable} onClick={props.onClick}>
                    <RunIcon />
                    <span>Run</span>
                </button>
            </div>
        </div>
    )
}

type RadioButtonsP = {
    setView: (view: string) => void
    currentView: string
}

export function RadioButtons(props: RadioButtonsP) {
    const changeView = (view: string) => {
        props.setView(view)
    }

    return (
        <div className="right_panel_radio">
            <div className="col-md-12 entity_radio">
                <div className="radio">
                    <label>
                        <input
                            type="radio"
                            className="radio-warning"
                            name="view"
                            checked={props.currentView === 'normal'}
                            onClick={e => changeView('normal')}
                        />
                    </label>
                    <span>Normal View</span>
                </div>
                <div className="radio">
                    <label>
                        <input
                            type="radio"
                            name="view"
                            checked={props.currentView === 'developer'}
                            onClick={e => changeView('developer')}
                        />
                    </label>
                    <span>Developer View</span>
                </div>
            </div>
        </div>
    )
}

type LeftTabsP = {
    logs: any
    changeNode: (input: any) => void
    executed: boolean
    executionDetails: any
    currentView: string
}

export function LeftTabs(props: LeftTabsP) {
    // Declare a new state variable, which we'll call "count"
    const [currentTab, changeTab] = useState('automationInput')

    const hasError = (outputs: NodeResponseT[]) => {
        const hasError = outputs.filter((x: NodeResponseT) => x.logType === 'dev:error')
        return hasError.length > 0
    }

    useEffect(() => {
        if (props.logs && props.logs.dev && props.logs.dev.length > 0) {
            const errorNode = props.logs.dev.filter((node: Node) => hasError(node.outputs))[0]
            if (errorNode) {
                changeTab(errorNode.node)
                props.changeNode({
                    node: 'node',
                    value: {},
                    nodeName: errorNode.node
                })
                const htmlNode = document.getElementById(errorNode.node)
                if (htmlNode !== null) {
                    htmlNode.scrollIntoView({ behavior: 'smooth' })
                }
            }
        }
    }, [props.logs])

    return (
        <div className="console_bg_head_left">
            <h4>Automations</h4>
            <div
                className={
                    currentTab === 'automationInput'
                        ? 'console_bg_head_left_img console_bg_head_left_img_click'
                        : 'console_bg_head_left_img'
                }
                onClick={() => {
                    changeTab('automationInput')
                    props.changeNode({ node: 'AutomationInput', value: {} })
                }}
            >
                <h6>Automation Input</h6>
            </div>
            {props.logs &&
                props.logs.dev &&
                props.logs.dev.map((node: Node, i: number) => {
                    return (
                        <div
                            className={
                                hasError(node.outputs)
                                    ? 'console_bg_head_left_img console_bg_head_left_error'
                                    : currentTab === node.node
                                    ? 'console_bg_head_left_img console_bg_head_left_img_click'
                                    : 'console_bg_head_left_img'
                            }
                            id={node.node}
                            key={i}
                            onClick={() => {
                                changeTab(node.node)
                                props.changeNode({
                                    node: 'node',
                                    value: {},
                                    nodeName: node.node
                                })
                            }}
                        >
                            <h6>{node.node}</h6>
                        </div>
                    )
                })}
            {props.executed && (
                <div
                    className={
                        currentTab === 'automationOutput'
                            ? 'console_bg_head_left_img console_bg_head_left_img_click'
                            : 'console_bg_head_left_img'
                    }
                    onClick={() => {
                        props.changeNode({
                            node: 'AutomationOutput',
                            value: {}
                        })
                        changeTab('automationOutput')
                    }}
                >
                    <h6>Automation Output</h6>
                </div>
            )}
        </div>
    )
}

type AutomationInputP = {
    data: any
    defaultInputValues: any
    enableButton: (enabled: boolean, propertyValues: object) => void
}

export const AutomationInput = (props: AutomationInputP) => {
    const [propertyValues, setPropertyValues] = useState({})

    useEffect(() => {
        const { data, defaultInputValues } = props
        let defaultValues = {}
        if (defaultInputValues && Object.keys(defaultInputValues).length === 0) {
            data &&
                data.config &&
                data.config.map((value: Config) => {
                    if (value.type === 'input') {
                        value.properties &&
                            value.properties.map((property: string, i: number) => {
                                defaultValues[property] = ''
                            })
                        setPropertyValues(defaultValues)
                    }
                })
        } else if (defaultInputValues && Object.keys(defaultInputValues).length > 0) {
            setPropertyValues(defaultInputValues)
        }
    }, [props.data])

    const handleInputChange = (input: string, property: string) => {
        const { enableButton } = props

        propertyValues[property] = input
        setPropertyValues(propertyValues)

        const enabled = Object.values(propertyValues).every(val => val !== '')
        enableButton(enabled, propertyValues)
    }

    const { data } = props

    return data && data.config
        ? data.config.map((value: Config) => {
              if (value.type !== 'input') return null
              return (
                  <div>
                      {value.properties
                          ? value.properties.map((property: string, i: number) => (
                                <div className="automation_input flow_group_home" key={i}>
                                    <div className="console-data-description">
                                        <div className="fill_values">
                                            <div className="input_values_scroll">
                                                <div className="input_values">
                                                    <div className="fill_input fill_bottom_space">
                                                        <p>{property} </p>
                                                    </div>
                                                    <div className="fill_0utput">
                                                        <div className="if_dropdown">
                                                            <input
                                                                type="text"
                                                                className="form-control input_fixed_editor"
                                                                placeholder={'Please enter ' + property}
                                                                defaultValue={propertyValues[property]}
                                                                onChange={e =>
                                                                    handleInputChange(e.target.value, property)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <div className="inter_svg">
                                                        <EditIcon />
                                                    </div>
                                                    <div className="inter_svg">
                                                        <CheckIcon />
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                          : null}
                  </div>
              )
          })
        : null
}

type AutomationOutputP = {
    executionDetails: any
    currentView: string
}

export const AutomationOutput = (props: AutomationOutputP) => {
    if (props.currentView === 'normal') {
        return (
            <div className="console_bg_head_right">
                <div className="automation_output flow_group_home">
                    <div className="console-data-description">
                        <div className="fill_values">
                            <div className="input_values_scroll">
                                {props.executionDetails && props.executionDetails.success !== undefined ? (
                                    props.executionDetails.success === true ? (
                                        Object.entries(props.executionDetails).map((detail: any, i: number) => {
                                            if (
                                                detail[0] !== 'executionId' &&
                                                detail[0] !== 'success' &&
                                                detail[0] !== 'error'
                                            ) {
                                                return (
                                                    <div className="input_values" key={i}>
                                                        <div className="fill_input fill_bottom_space">
                                                            <p>{detail[0]} :</p>
                                                        </div>
                                                        <div className="fill_0utput">
                                                            <div className="if_dropdown">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={detail[1]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            return null
                                        })
                                    ) : (
                                        <div className="input_values">
                                            <div className="fill_input fill_bottom_space">
                                                <p>Error :</p>
                                            </div>
                                            <div className="fill_0utput">
                                                <div className="if_dropdown">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={props.executionDetails.error}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="console_bg_head_right">
                <div className="automation_output flow_group_home">
                    <div className="console-data-description">
                        <div className="fill_values">
                            <div className="input_values_scroll">
                                <div className="tab_pane_ul">
                                    <div className="tab-pane_svg">
                                        <OpenBrace />
                                    </div>
                                    <ul>
                                        {Object.entries(props.executionDetails).map((input, i) => (
                                            <li className="developer_li_left" key={i}>
                                                {`"${input[0]}"`} :{' '}
                                                <span className="developer_li_right">{`"${input[1]}"`}</span>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="tab-pane_svg">
                                        <CloseBrace />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

type NodeWithNormalViewP = {
    data: NormalOrDevNode
    flow: NodeConfig
}

export const NodeWithNormalView = (props: NodeWithNormalViewP) => {
    // console.log(props)
    return (
        <div className="console_bg_head_right">
            <div className="console-bg-workplace">
                <div className="container-fluid tab-console-bg">
                    <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" data-toggle="tab">
                                Logs
                            </a>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div id="elements" className="tab-pane active">
                            <div className="tab-pane_width">
                                <div className="pane_width_top">
                                    <p>
                                        Executing{' '}
                                        <b>{props.data && props.data.value.length > 0 && props.data.value[0].node}</b>{' '}
                                        automation.
                                    </p>
                                    {props.data &&
                                        props.data.value.length > 0 &&
                                        props.data.value[0].outputs.map((node: NodeResponseT, i: number) => {
                                            if (node.logType === 'user:authorize') {
                                                return (
                                                    <p
                                                        key={i}
                                                        style={{
                                                            marginTop: 10
                                                        }}
                                                    >
                                                        {node.message}.
                                                    </p>
                                                )
                                            } else if (node.logType === 'user:input') {
                                                return (
                                                    <div className="tab_pane_ul" key={i}>
                                                        <h4>Input Provided:</h4>
                                                        <ul>
                                                            {Object.entries(node.schema).map((input: any, i) => (
                                                                <li className="console_input" key={i}>
                                                                    <span className="consoloe_input_no" />
                                                                    {i + 1}. {input[0]} : <span>{input[1]}</span>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )
                                            } else if (node.logType === 'user:output') {
                                                return (
                                                    <div className="tab_pane_ul" key={i}>
                                                        <h4>Output:</h4>
                                                        <ul>
                                                            <li className="console_input">
                                                                <b>
                                                                    {props.data &&
                                                                        props.data.value.length > 0 &&
                                                                        props.data.value[0].node}
                                                                </b>{' '}
                                                                executed successfully.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )
                                            } else if (node.logType === 'user:error') {
                                                return (
                                                    <div className="tab_pane_ul" key={i}>
                                                        <h4>Output:</h4>
                                                        <ul>
                                                            <li className="console_input">
                                                                <b>
                                                                    {props.data &&
                                                                        props.data.value.length > 0 &&
                                                                        props.data.value[0].node}
                                                                </b>{' '}
                                                                execution failed.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )
                                            } else return null
                                        })}
                                    {/* <div className="tab_pane_ul">
                                        <h4>Error:</h4>
                                        <p>Email ID does not exist.</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

type NodeWithDeveloperViewP = {
    data: NormalOrDevNode
    flow: NodeConfig
}

export const NodeWithDeveloperView = (props: NodeWithDeveloperViewP) => {
    const [currentTab, setTab] = useState('input')

    return (
        <div className="content_developer">
            <div className="console_bg_head_right">
                <div className="console-bg-workplace">
                    <div className="container-fluid tab-console-bg">
                        <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item" onClick={() => setTab('input')}>
                                <a
                                    className={currentTab === 'input' ? 'nav-link active' : 'nav-link'}
                                    data-toggle="tab"
                                >
                                    Input
                                </a>
                            </li>
                            <li className="nav-item" onClick={() => setTab('output')}>
                                <a
                                    className={currentTab === 'output' ? 'nav-link active' : 'nav-link'}
                                    data-toggle="tab"
                                >
                                    Output
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {currentTab === 'input' && (
                <div className="tab_pane_ul">
                    <div className="tab-pane_svg">
                        <OpenBrace />
                    </div>
                    <ul>
                        {props.data &&
                            props.data.value &&
                            props.data.value[0].outputs.map((input: NodeResponseT) => {
                                if (input.logType === 'dev:input') {
                                    return Object.entries(input.schema).map((input, i) => (
                                        <li className="developer_li_left" key={i}>
                                            {`"${input[0]}"`} :{' '}
                                            <span className="developer_li_right">{`"${input[1]}"`}</span>
                                        </li>
                                    ))
                                }
                                return null
                            })}
                    </ul>
                    <div className="tab-pane_svg">
                        <CloseBrace />
                    </div>
                </div>
            )}
            {currentTab === 'output' && (
                <div className="tab_pane_ul">
                    <div className="tab-pane_svg">
                        <OpenBrace />
                    </div>
                    <ul>
                        {props.data &&
                            props.data.value &&
                            props.data.value[0].outputs.map((input: NodeResponseT) => {
                                if (input.logType === 'dev:output') {
                                    return Object.entries(input.schema).map((input, i) => (
                                        <li className="developer_li_left" key={i}>
                                            {`"${input[0]}"`} :{' '}
                                            <span className="developer_li_right">{`"${input[1]}"`}</span>
                                        </li>
                                    ))
                                }
                                return null
                            })}
                    </ul>
                    <div className="tab-pane_svg">
                        <CloseBrace />
                    </div>
                </div>
            )}
        </div>
    )
}
