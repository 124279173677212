/*eslint-disable*/

/*eslint-disable*/
import * as React from 'react'

import { DeleteIcon, EditIcon } from '@/common/Icons/Icons'
//import FlowEditorHeader from './FlowEditorHeader'
import { NewCard } from '@/common/components/Card'
import { Error } from '@/common/components/ErrorBoundary'
import Validate, { ErrorContext } from '@/common/components/FormValidate'
import InputBox from '@/common/components/InputBox'
import { List } from '@/common/components/List'
import { Modal } from '@/common/components/Modal'
import { ListSelectBox } from '@/common/components/SelectBox'
import { flowValidations, inputTextAreaValidations, newLine } from '@/common/utils/_validation-utils'

import { formatAMPM, formatDateInCard } from '@/common/utils/utils'
import { FlowApi, deleteJson, flowApiURL, getJson, postJson, putJson } from '@/common/utils/api-utils'
import Card from './Card'
import GroupHeader from './GroupHeader'
import { Move } from './Move'
import {
    Add,
    AddT,
    CardDisplayT,
    CardTypes,
    DeleteCards,
    DeleteSingleCardT,
    EditT,
    FlowFilterE,
    FlowResponseT,
    FlowsResponseT,
    GroupResponseT,
    Mode,
    Modes,
    MoveCards,
    SEARCH_KEY,
    SelectT,
    UnGroupCards,
    View,
    ViewTypeT
} from './types'
import { selectedFlowCards, selectedFlowList } from './utils'
import { responseToFlowCards } from './utils'

//import { defaultGroupId } from './utils'

type GroupS = FlowsResponseT & {
    mode: Modes
    group: GroupResponseT | null
    cardsToRender: CardDisplayT[]
    searchItem: string
}

const groupData = (groups: GroupResponseT[], groupId: string) => {
    const selectedGroup = groups.filter(group => group.id == groupId)
    return selectedGroup.length != 0
        ? selectedGroup[0]
        : {
              id: '0',
              name: '',
              description: '',
              modified_date: new Date().toString()
          }
}

export class Group extends React.Component<any, GroupS> {
    state: GroupS = {
        automations: [],
        groups: [],
        cardsToRender: [],
        automationListWithoutConnection: [],
        automationListWithApproval: [],
        mode: View,
        group: null,
        searchItem: SEARCH_KEY
    }
    workspaceName: string = this.props.match.params.workspacename
    setToAddMode = () => {
        this.setState({ mode: Add })
    }
    setToEditMode = (card: CardDisplayT) => {
        const mode: EditT = {
            mode: Mode.Edit,
            cardType: CardTypes.Flow,
            name: card.name,
            id: card.id,
            description: card.description,
            error: {
                error: false,
                info: ''
            }
        }

        this.setState({ mode: mode })
    }
    setToDeleteMultipleFlows = () => {
        const selectMode = this.state.mode as SelectT
        selectMode.action = DeleteCards

        this.setState({ mode: selectMode })
    }

    setToUngroupFlows = () => {
        const selectMode = this.state.mode as SelectT
        selectMode.action = UnGroupCards

        this.setState({ mode: selectMode })
    }

    setToMoveFlows = () => {
        const selectMode = this.state.mode as SelectT
        selectMode.action = MoveCards

        this.setState({ mode: selectMode })
    }

    goBack = () => {
        const location = {
            pathname: `/workspace/${this.props.match.params.workspacename}/flow`
        }
        this.props.history.push(location)
    }

    deleteCards = async () => {
        const mode = this.state.mode as SelectT
        const { automations, group } = this.state
        const flowsS = selectedFlowList(mode.cards)
        const allDeletedFlows = [...flowsS]

        if (flowsS.length > 0) {
            await postJson(flowApiURL(this.workspaceName, FlowApi.DeleteFlows))({ flows: flowsS })
        }
        const remainingFlows = automations.filter(flow => !allDeletedFlows.includes(flow.id))

        const flowsResponse = {
            automations: remainingFlows,
            groups: [],
            automationListWithoutConnection: this.state.automationListWithoutConnection,
            automationListWithApproval: this.state.automationListWithApproval
        }
        if (group) {
            this.setState(responseToViewMode(flowsResponse, group))
        }
    }

    ungroupCards = async () => {
        const mode = this.state.mode as SelectT
        const { automations, group } = this.state
        const flowsS = selectedFlowList(mode.cards)
        const allDeletedFlows = [...flowsS]
        const remainingFlows = automations.filter(flow => !allDeletedFlows.includes(flow.id))
        if (flowsS.length > 0) {
            await postJson(flowApiURL(this.workspaceName, FlowApi.UnGroupFlows))({
                flows: flowsS
            })
        }

        // const prevFlows = mode.cards.map(flow => ({ ...flow, groupId: '1' }))

        const flowsResponse = {
            // flows: [...prevFlows, ...remainingFlows],
            automations: [...remainingFlows],
            groups: [],
            automationListWithoutConnection: this.state.automationListWithoutConnection,
            automationListWithApproval: this.state.automationListWithApproval
        }
        if (group) {
            this.setState(responseToViewMode(flowsResponse, group))
        }
    }

    saveCard = async () => {
        const { mode: modes, automations, group } = this.state
        switch (modes.mode) {
            case Mode.Add:
                {
                    const { name, description } = modes
                    const flowsAdded = await addFlow(
                        name,
                        description,
                        automations,
                        group ? group.id : '1',
                        this.workspaceName
                    )
                    const flowsResponse = {
                        automations: flowsAdded,
                        groups: [],
                        automationListWithoutConnection: this.state.automationListWithoutConnection,
                        automationListWithApproval: this.state.automationListWithApproval
                    }
                    if (group) {
                        this.setState(responseToViewMode(flowsResponse, group))
                    }
                }
                break
            case Mode.Edit: {
                const { id, name, description } = modes
                const flowsEdited = await editFlow(
                    id,
                    name,
                    description,
                    automations,
                    group ? group.id : '1',
                    this.workspaceName
                )
                const editResponse = {
                    automations: flowsEdited,
                    groups: [],
                    automationListWithoutConnection: this.state.automationListWithoutConnection,
                    automationListWithApproval: this.state.automationListWithApproval
                }
                if (group) {
                    this.setState(responseToViewMode(editResponse, group))
                }
            }
        }
    }

    selectFlows = (card: CardDisplayT) => {
        const modes = this.state.mode
        const { id } = card
        switch (modes.mode) {
            case Mode.Select:
                const flowCards = selectedFlowCards(modes.cards)
                const flowsS = selectedFlowList(modes.cards)
                const selectedFlows = flowsS.includes(id)
                    ? modes.cards.filter(card => card.id !== id)
                    : flowCards.concat(card)
                const cardsSelected = [...selectedFlows]

                if (cardsSelected.length) {
                    this.setState({
                        mode: {
                            ...modes,
                            mode: Mode.Select,
                            cards: cardsSelected
                        }
                    })
                } else {
                    this.setState({ mode: View })
                }
                break
            default: {
                // console.log('card', card)
                this.setState({
                    mode: {
                        mode: Mode.Select,
                        groupName: '',
                        groupInfo: '',
                        cards: [card],
                        showAddGroupModel: false
                    }
                })
                break
            }
        }
    }

    setToDeleteGroup = (card: CardDisplayT) => {
        // console.log('delete set')
        const toDelete: DeleteSingleCardT = {
            mode: Mode.Delete,
            card: card,
            deleteAction: 'Delete'
        }
        this.setState({ mode: toDelete })
    }

    redirectToFlow = (id: string) => {
        const { group } = this.state
        if (group) {
            const location = {
                pathname: `/workspace/${this.props.match.params.workspacename}/automation/detail/${id}`,
                state: { group: true, flow: false, groupId: group.id }
            }
            this.props.history.push(location)
        }
    }

    onNameChange = (name: string) => {
        const mode = this.state.mode as AddT
        this.setState({ mode: { ...mode, name: name } })
    }
    onDescriptionChange = (description: string) => {
        const mode = this.state.mode as AddT
        this.setState({ mode: { ...mode, description: description } })
    }

    setToDeleteFlow = (card: CardDisplayT) => {
        const toDelete: DeleteSingleCardT = {
            mode: Mode.Delete,
            card: card,
            deleteAction: 'Delete'
        }
        this.setState({ mode: toDelete })
    }

    async componentDidMount() {
        const flowList: FlowsResponseT = await getJson(flowApiURL(this.workspaceName, FlowApi.AllFlows))
        let groupId = this.props.match.params.groupId
        const cardsToRender = responseToFlowCards(flowList, groupId)
        const group = groupData(flowList.groups, groupId)
        // console.log('flowList', flowList)
        this.setState({
            automations: flowList.automations,
            groups: flowList.groups,
            group,
            cardsToRender: cardsToRender,
            automationListWithoutConnection: flowList.automationListWithoutConnection
        })
    }

    resetToViewMode = () => {
        this.setState({ mode: View })
    }

    deleteCard = async () => {
        const mode = this.state.mode as DeleteSingleCardT
        if (mode.deleteAction != 'Error') {
            const { card } = mode
            const { automations, group } = this.state
            const flowsAdded = await deleteFlow(card.id, automations, this.workspaceName)
            const flowsResponse = {
                automations: flowsAdded,
                groups: [],
                automationListWithoutConnection: this.state.automationListWithoutConnection,
                automationListWithApproval: this.state.automationListWithApproval
            }
            if (group) {
                this.setState(responseToViewMode(flowsResponse, group))
            }
        }
    }
    renderModal = () => {
        const { mode } = this.state
        const head = popupText(mode)
        if (mode.mode == Mode.Add || mode.mode == Mode.Edit) {
            return (
                <Modal close={this.resetToViewMode}>
                    <h2 dangerouslySetInnerHTML={{ __html: head.header }} />
                    <p>{head.subHeader}</p>
                    <form>
                        <Validate defaultValue={mode.mode === Mode.Add ? { flowName: false } : { flowName: true }}>
                            <InputBox
                                validationList={flowValidations}
                                value={mode.name}
                                name="flowName"
                                render={(
                                    value: string,
                                    valid: boolean,
                                    errorMsg: string,
                                    startValidation: () => void,
                                    handleInput: Function
                                ) => {
                                    return (
                                        <React.Fragment>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Automation Name"
                                                onChange={event => {
                                                    startValidation(), handleInput('flowName', event.target.value)
                                                    this.onNameChange(event.target.value)
                                                }}
                                                value={value}
                                            />
                                            <p className="error_msg">{!valid ? errorMsg : newLine}</p>
                                        </React.Fragment>
                                    )
                                }}
                            />
                            <InputBox
                                validationList={inputTextAreaValidations}
                                value={mode.description}
                                render={(
                                    value: string,
                                    valid: boolean,
                                    errorMsg: string,
                                    startValidation: () => void
                                ) => {
                                    return (
                                        <React.Fragment>
                                            <textarea
                                                className="form-control"
                                                placeholder="Automation Description"
                                                onChange={event => {
                                                    startValidation()
                                                    this.onDescriptionChange(event.target.value)
                                                }}
                                                value={value}
                                            />
                                            <p className="error_msg">{!valid ? errorMsg : newLine}</p>
                                        </React.Fragment>
                                    )
                                }}
                            />
                            <button className="btn btn-primary" type="button" onClick={this.resetToViewMode}>
                                Cancel
                            </button>
                            <ErrorContext.Consumer>
                                {valid => {
                                    return (
                                        <button
                                            className="btn btn-success"
                                            type="button"
                                            onClick={this.saveCard}
                                            disabled={!valid.formValid.formValid}
                                        >
                                            {head.buttonText}
                                        </button>
                                    )
                                }}
                            </ErrorContext.Consumer>
                        </Validate>
                    </form>
                </Modal>
            )
        }
        return null
    }
    renderConfirmModal = () => {
        const { mode } = this.state
        if (mode.mode == Mode.Delete && mode.deleteAction != 'Error') {
            const { card, deleteAction } = mode
            return (
                <Modal close={this.resetToViewMode}>
                    <h2>
                        {deleteAction} ‘{card.name}’
                    </h2>
                    <p>
                        Are you sure to {deleteAction.toLowerCase()} selected {card.cardType.toLowerCase()} ?
                    </p>
                    <form>
                        <button className="btn btn-primary" type="button" onClick={this.deleteCard}>
                            Yes
                        </button>
                        <button className="btn btn-success" type="button" onClick={this.resetToViewMode}>
                            No
                        </button>
                    </form>
                </Modal>
            )
        } else if (mode.mode == Mode.Select) {
            if (mode.action == DeleteCards) {
                return (
                    <Modal close={this.resetToViewMode}>
                        <h2>Delete</h2>
                        <p>Are you sure to delete selected items?</p>
                        <form>
                            <button className="btn btn-primary" type="button" onClick={this.resetToViewMode}>
                                No
                            </button>
                            <button className="btn btn-success" type="button" onClick={this.deleteCards}>
                                Yes
                            </button>
                        </form>
                    </Modal>
                )
            } else if (mode.action == UnGroupCards) {
                return (
                    <Modal close={this.resetToViewMode}>
                        <h2>Delete</h2>
                        <p>Are you sure to ungroup selected items?</p>
                        <form>
                            <button className="btn btn-primary" type="button" onClick={this.resetToViewMode}>
                                No
                            </button>
                            <button className="btn btn-success" type="button" onClick={this.ungroupCards}>
                                Yes
                            </button>
                        </form>
                    </Modal>
                )
            }
        }
        return null
    }

    moveGroupList = (search: string) => {
        const { group: mainGroup, groups } = this.state
        return groups
            .filter(group => group.id != '1' && mainGroup && mainGroup.id != group.id)
            .filter(e => e.name && e.name.toLowerCase().includes(search.toLowerCase()))
    }
    moveToDifferentGroup = async (selectedGroup: string) => {
        const { group, groups, automations } = this.state
        if (group) {
            const mode = this.state.mode as SelectT
            const flowsS = selectedFlowList(mode.cards)
            const updatedFlows = automations.map(flow => {
                if (flowsS.indexOf(flow.id) != -1) {
                    return { ...flow, groupId: selectedGroup }
                }
                return flow
            })
            const data = {
                id: selectedGroup,
                flows: flowsS
            }
            await putJson(flowApiURL(this.workspaceName, FlowApi.MoveToGroup))()(data)
            const flowList = {
                automations: updatedFlows,
                groups,
                automationListWithoutConnection: this.state.automationListWithoutConnection,
                automationListWithApproval: this.state.automationListWithApproval
            }
            let groupId = group.id
            const cardsToRender = responseToFlowCards(flowList, groupId)
            this.setState({
                mode: View,
                automations: automations,
                groups: groups,
                group,
                cardsToRender: cardsToRender
            })
        }
    }

    renderMoveModal = () => {
        const { mode } = this.state
        if (mode.mode == Mode.Select && mode.action == MoveCards) {
            return (
                <Move.Popup
                    render={(searchText, onSearchChanged, selectedGroup, selectGroups) => (
                        <Move.Wrapper>
                            <Move.Header item="flow" moveTo="group" />
                            <Move.Search text={searchText} onChange={onSearchChanged} />
                            <Move.CardWrapper>
                                {this.moveGroupList(searchText).length > 0 ? (
                                    this.moveGroupList(searchText).map((group, index) => (
                                        <Move.Card
                                            id={group.id}
                                            index={index}
                                            label={group.name}
                                            info={group.description}
                                            isSelected={selectedGroup == group.id}
                                            selectMode={selectedGroup !== ''}
                                            select={selectGroups}
                                        />
                                    ))
                                ) : (
                                    <Error.NoDataMsg info="No groups to show! :)" />
                                )}
                                <div className="margin-bottom-75" />
                            </Move.CardWrapper>
                            <Move.ButtonWrapper>
                                <Move.CancelB onClick={this.resetToViewMode} />
                                <Move.MoveB onClick={() => this.moveToDifferentGroup(selectedGroup)} />
                            </Move.ButtonWrapper>
                        </Move.Wrapper>
                    )}
                />
            )
        }

        return null
    }

    handleSearch = (key: string) => {
        this.setState({ searchItem: key })
    }

    showSelection = () => this.state.mode.mode === Mode.Select

    isSelected = (card: any) => {
        const { mode } = this.state
        return (
            mode.mode == Mode.Select &&
            mode.cards.filter((e: any) => e.id == card.id && e.cardType == CardTypes.Flow).length != 0
        )
    }

    render() {
        const name = this.state.group ? this.state.group.name : ''
        let flowCount = this.state.automations.length
        const { searchItem, cardsToRender, mode, automationListWithoutConnection } = this.state
        const cardPadding = mode.mode == Mode.Select ? '18px' : '0px'
        let renderItems: CardDisplayT[] = cardsToRender
        if (searchItem != SEARCH_KEY) {
            renderItems = cardsToRender
                .filter(e => e.name && e.name.toLowerCase().includes(searchItem.toLowerCase()))
                .map(formatTitle(searchItem, cardPadding))
        } else {
            renderItems = cardsToRender.map(formatTitle(searchItem, cardPadding))
        }

        const view = this.props.location.state.view

        return (
            <React.Fragment>
                {this.renderConfirmModal()}
                {this.renderModal()}
                {this.renderMoveModal()}
                <GroupHeader
                    name={name}
                    flowCount={flowCount}
                    mode={mode}
                    deselect={this.resetToViewMode}
                    delete={this.setToDeleteMultipleFlows}
                    move={this.setToMoveFlows}
                    ungroup={this.setToUngroupFlows}
                    flowView={this.goBack}
                    addMode={this.setToAddMode}
                    search={this.handleSearch}
                    searchKey={this.state.searchItem}
                />
                <section className="entity_cards flow_group_cards">
                    <div className="col-md-12">
                        <div className="row">
                            {searchItem === SEARCH_KEY && view.View === ViewTypeT.GridView ? (
                                <NewCard onClick={this.setToAddMode} disabled={this.state.mode.mode == Mode.Select} />
                            ) : null}
                            {renderItems.map((card: CardDisplayT, i: number) =>
                                view.View === ViewTypeT.GridView ? (
                                    <Card
                                        key={card.id}
                                        card={card}
                                        mode={this.state.mode}
                                        click={() => this.redirectToFlow(card.id)}
                                        edit={this.setToEditMode}
                                        saveAs={this.setToEditMode}
                                        view={FlowFilterE.Draft}
                                        delete={this.setToDeleteGroup}
                                        select={this.selectFlows}
                                        hasConnectionError={automationListWithoutConnection.indexOf(card.id) != -1}
                                        isApproval={false}
                                    />
                                ) : (
                                    <List
                                        key={i}
                                        showCheckBox
                                        checkBoxShow={this.showSelection()}
                                        render={(show: boolean, ToolBox: any) => {
                                            return (
                                                <div className="list-full-width">
                                                    <div className="col-md-12 list-view-sub">
                                                        <div className="main_sub">
                                                            <div className="col-md-4 list_flow_hr">
                                                                <ListSelectBox
                                                                    show={show}
                                                                    isSelected={this.isSelected(card)}
                                                                    onClick={() => this.selectFlows(card)}
                                                                    value={card.name}
                                                                />
                                                                <div
                                                                    className="search_card_list"
                                                                    onClick={() => this.redirectToFlow(card.id)}
                                                                >
                                                                    <h5
                                                                        style={{
                                                                            paddingTop: 0
                                                                        }}
                                                                    >
                                                                        {card.name}
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 list_flow_p">
                                                                <p className="listite_centre">{card.description}</p>
                                                            </div>
                                                            <div className="col-md-3  list_flow_span">
                                                                <span className="entity_span">
                                                                    {formatDateInCard(card.modified_date)}
                                                                </span>
                                                                <span>|</span>
                                                                <span className="entity_span">
                                                                    {formatAMPM(card.modified_date)}
                                                                </span>
                                                            </div>
                                                            {this.state.mode.mode !== Mode.Select && (
                                                                <ToolBox>
                                                                    <span onClick={() => this.setToEditMode(card)}>
                                                                        <EditIcon />
                                                                    </span>
                                                                    <span onClick={() => this.setToDeleteFlow(card)}>
                                                                        <DeleteIcon />
                                                                    </span>
                                                                </ToolBox>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    />
                                )
                            )}
                        </div>
                    </div>
                </section>
                <section className="intent_bottom">
                    <div>&nbsp;</div>
                </section>
            </React.Fragment>
        )
    }
}
const popupText = (mode: Modes): any => {
    if (mode.mode == Mode.Add) {
        return {
            header: 'Create Workflow',
            subHeader: 'Enter name and description',
            buttonText: 'Create'
        }
    } else if (mode.mode == Mode.Edit && mode.cardType == CardTypes.Flow) {
        return {
            header: 'Edit Workflow',
            subHeader: 'Enter name and description',
            buttonText: 'Save'
        }
    } else {
        return {
            header: 'Edit Group',
            subHeader: 'Make the required changes and click on Save',
            buttonText: 'Save'
        }
    }
    return {
        header: 'Flow',
        subHeader: '',
        buttonText: 'Save'
    }
}

const addFlow = async (
    name: string,
    description: string,
    flows: FlowResponseT[],
    groupId: string,
    workspaceName: string
) => {
    const addData = {
        label: name,
        info: description,
        group: groupId
    }
    let resp: any = await postJson(flowApiURL(workspaceName, FlowApi.AddFlow))(addData)

    const { id: addedId } = resp.data

    const flowAdd: FlowResponseT = {
        groupId: groupId,
        id: addedId,
        type: 'no_trigger',
        description: description,
        active_status: true, //need to conform
        deployed_status: 'saved', //need to conform
        modified_date: new Date().toString(),
        name: name
    }
    return [...flows, flowAdd]
}
const responseToViewMode = (response: FlowsResponseT, group: GroupResponseT) => {
    return {
        ...response,
        mode: View,
        cardsToRender: flowsResponseToCards(response, group)
    }
}
const flowsResponseToCards = (flowList: FlowsResponseT, group: GroupResponseT) => {
    const { id } = group
    let flowsToRender = flowList.automations.filter(flow => flow.groupId == id).map(flowToCard)

    return [...flowsToRender]
}

const flowToCard = (flow: FlowResponseT): CardDisplayT => ({
    ...flow,
    displayLabel: flow.name,
    cardType: CardTypes.Flow
})
const editFlow = async (
    id: string,
    name: string,
    description: string,
    flows: FlowResponseT[],
    groupId: string,
    workspaceName: string
) => {
    const filtered = flows.filter((flow: FlowResponseT) => flow.id !== id)
    const editData = {
        label: name,
        info: description,
        id
    }

    await putJson(flowApiURL(workspaceName, FlowApi.UpdateFlow))()(editData)

    const flowEdit: FlowResponseT = {
        id: id,
        description: description,
        name: name,
        type: 'no_trigger',
        groupId: groupId,
        active_status: true, //need to conform
        deployed_status: 'saved', //need to conform
        modified_date: new Date().toString()
    }
    return [...filtered, flowEdit]
}
const deleteFlow = async (id: string, flows: FlowResponseT[], workspaceName: string) => {
    const filtered = flows.filter(flow => flow.id != id)

    const deleteData = {
        id: id
    }

    await deleteJson(flowApiURL(workspaceName, `${FlowApi.DeleteFlow}/${id}`))(deleteData)

    return filtered
}

const formatTitle = (search: string, cardPadding: string) => (card: CardDisplayT) => {
    let reg = new RegExp(search, 'gi')
    let label =
        search != SEARCH_KEY
            ? `<h5 style='padding-top:${cardPadding}'>` + card.name + '</h5>'
            : `<h5 style='padding-top:${cardPadding}'>` + card.name + '</h5>'
    // console.log('label', label)
    return { ...card, displayLabel: label }
}
