/*eslint-disable*/
import React from 'react';

import { useFetchFromApi } from '@/common/hooks/usefetch-from-api';

import {
  agenHandoffUrl,
  postJson,
  stripeGetApi,
} from '@/common/utils/api-utils';
import { getAssistants } from '../Mechanisms/action';
import { AgentHandoff } from './AgentHandoff';

const apiToCall = (workspacename: string, assistantname: string) => {
  const agentAccess = stripeGetApi(`${workspacename}/info/AGENT_HANDOFF_ACCESS`).then(
      (resp: { output: string; type: 'success' }) => {
          return resp.output == 'yes' ? true : false
      }
  )
  // const agentAccess = true;
  // .catch(_ => {
  //     //console.log('err', _)
  //     return Promise.reject({ type: 'error', error: _ })
  // })
  const agents = getAssistants(workspacename).then(data => {
      const assistant = data.filter(b => b.name == assistantname)[0]
      return postJson(agenHandoffUrl(workspacename, `/${assistant.id}/agent/getAgent/`))({ bot_id: assistant.id })
      // return Promise.resolve({data:[]})
          .then(resp => resp.data)
          .then(agent => {
              return { bots: data, agent }
          })
  })
  // .catch(_ => {
  //     //console.log('err', _)
  //     return Promise.reject({ type: 'error', error: _ })
  // })

  // For Local
  const localagents = Promise.resolve({
    bots: [
      {
        id: 'd11b2b26-c274-4c69-a6ad-94483dc44820',
        name: 'ManjulaBot',
        created: '2021-06-22T08:54:51',
        updated: '2021-06-25T05:00:29',
        deployed: '2021-06-24T12:33:00',
        welcome_node: '1557ebcd-19a8-4004-ae09-24f8e26717ce',
        fallback_node: 'd6c7efd3-5a7e-4ad9-9709-d69028e66a64',
      },
    ],
    agent: [
      {
        id: '15721742-6409-459e-a6e3-54c26d5a2dbb',
        workspace: 'nirup',
        bot_id: 'd11b2b26-c274-4c69-a6ad-94483dc44820',
        client_id: 'tese',
        client_secrect: 'tese',
        is_active: false,
        is_authorized: true,
        tenant: 'tese',
        teams_deploy: {
          added_by: 'Abinaya Kumarasamy',
          date_added: '2022-11-09T09:49:18.000Z',
          email_id: 'abinaya.k@workativ.com',
          is_active: true,
          team_id: '56d1e68c-3f63-4b04-b997-1a3d288ff133',
          team_name: 'PP Test',
        },
        bot_data: {
          bot_full_desc: 'To test the agent bot',
          bot_full_name: 'Abinaya',
          bot_name: 'RegressionFAQMarch',
          bot_short_desc: 'To test the agent bot',
          is_active: true,
          is_generated: true,
        },
        tenant_type: 'multi_tenant',
      },
      {
        id: '17aa24af-c61f-44a8-87a3-1b165beb7e77',
        workspace: 'vinoddev',
        bot_id: '00318c03-cfa3-4f30-80d2-069de4654488',
        client_id: '65b1c05e-4e56-4912-a2ee-34b8f370939d',
        client_secrect: 'jfO8Q~4kCg2U4E9mx_PAM_3AeuuC8Jts6INrZa4F',
        name: 'IT Agents',
        description: '',
        is_active: false,
        is_authorized: true,
        tenant: 'fe8f0076-8dbf-4684-9f73-b389c33b2158',
        teams_deploy: null,
        bot_data: null,
      },
      {
        id: '17aa24af-c61f-44a8-87a3-1b165beb7251',
        workspace: 'vinoddev',
        bot_id: '00318c03-cfa3-4f30-80d2-069de4654488',
        client_id: '65b1c05e-4e56-4912-a2ee-34b8f370939d',
        client_secrect: 'jfO8Q~4kCg2U4E9mx_PAM_3AeuuC8Jts6INrZa4F',
        name: 'IT Agents',
        description: '',
        is_active: false,
        is_authorized: false,
        tenant: 'fe8f0076-8dbf-4684-9f73-b389c33b2158',
        teams_deploy: null,
        bot_data: null,
      },
    ],
  });
  return Promise.all([agents, agentAccess])
    .then(([agents, hasAccess]: [any, boolean]) => {
      return {
        ...agents,
        hasAgentaccess: hasAccess,
      };
    })
    .catch((_) => {
      //console.log('err', _)
      return Promise.reject({ type: 'error', error: _ });
    });
};
export function AgentHandoffWrapper(props: any) {
  const workspacename = props.match.params.workspacename;
  const assistantname = props.match.params.assistantname;

  const [state, setState, promiseRef] = useFetchFromApi(() =>
    apiToCall(workspacename, assistantname)
  );
  // console.log(state, 'AgentHandoffWrapper');
  const retry = () => {
    const setStateDispatch = setState as React.Dispatch<any>;
    setStateDispatch(
      promiseRef.current
        ? promiseRef.current.then((response: any) => response.data)
        : null
    );
  };

  return <AgentHandoff state={state} retry={retry} {...props} />;
}
