import '@draft-js-plugins/emoji/lib/plugin.css'
import '@draft-js-plugins/static-toolbar/lib/plugin.css'

import Downshift from 'downshift'
import {
    CompositeDecorator,
    EditorState,
    Entity,
    Modifier,
    SelectionState,
    convertFromRaw,
    convertToRaw
} from 'draft-js'
/*eslint-disable*/
import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import Tooltip from 'react-tooltip-lite'

import { InputWrapper } from '@/DialogEditor/Canvas/Components/WelcomePageWithJourney'
import { CheckColorIcon, CloseColorIcon, EmojiIcon, FlowCanvasContextIcons, InfoIcon, LinkIcon } from '@/common/Icons/Icons'
import { Input, InputContainer, InputContent } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { FocusContent } from '@/common/styled/DraftJs.Dumb'
import {
    BoldButton,
    ItalicButton,
    OrderedListButton,
    UnderlineButton,
    UnorderedListButton,
    createInlineStyleButton
} from '@draft-js-plugins/buttons'
import DraftEditor from '@draft-js-plugins/editor'
import createEmojiPlugin from '@draft-js-plugins/emoji'
import createToolbarPlugin, { Separator } from '@draft-js-plugins/static-toolbar'

import {
    DialogueContext,
    DialogueContextT,
    EntityVariableT,
    VariableContext,
    VariableContextForFAQ,
    VariableContextForFAQT,
    VariableContextT,
    VariableT
} from '../DialogueCrew/Context'
import { findPathViaRes } from '../DialogueCrew/Variable'
import { DraftJST } from './Action'
import { EmojiPopup } from './EmojiPopup'
const draftJsUtils = require("draftjs-utils");

// const emojiPlugin = createEmojiPlugin({
//     selectGroups: [{ title: 'Society', icon: '☺', categories: ['people'] }]
// })
// const { EmojiSelect } = emojiPlugin
// const staticToolbarPlugin = createToolbarPlugin()
// const { Toolbar } = staticToolbarPlugin
// const plugins = [staticToolbarPlugin, emojiPlugin]

var IsValidURL = (url: string) =>
    url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)

const Conductor = require('draftjs-conductor')

const TokenSpan = (props: any) => {
    const info: any = props.contentState.getEntityMap().get(props.entityKey).data
    return info.emojiUnicode ? (
        <span className='emoji_parent_span'>
            {props.children}
        </span>
    ) : (
        <span className="buton_dialog_auto_hvr" data-offset-key={props.offsetkey}>
            <Tooltip
                className="target customTip _hover_tooltip"
                zIndex={10000}
                arrowSize={0}
                tagName="span"
                content={
                    info.dummypath &&
                    info.dummypath.map((path: any, index: number, arr: any[]) => (
                        <React.Fragment key={`${index}`}>
                            <div>{path}</div>
                            {index + 1 != arr.length ? (
                                <div>
                                    <FlowCanvasContextIcons.NextIcon />
                                </div>
                            ) : null}
                        </React.Fragment>
                    ))
                }
                distance={5}
            >
                <button className="button_dialog">
                    <img src={info.icon} />
                    {props.children}
                </button>
            </Tooltip>
        </span>
    )
}

export const Link = (props: any) => {
    const { url, linkText, target } = props.contentState.getEntity(props.entityKey).getData()
    console.log(props)

    return (
        <a style={{ color: '#006cb7', textDecoration: 'underline' }} href={url} target={target} className="link-button" onClick={()=>{
            props.callback(url, linkText, props.entityKey)
        }}>
            {props.children || linkText}
            {/* {linkText || props.children} */}
        </a>
    )
}

export function findLinkEntities(contentBlock: any, callback: any, contentState: any) {
    contentBlock.findEntityRanges((character: any) => {
        const entitykey = character.getEntity()
        return entitykey !== null && contentState.getEntity(entitykey).getType() === 'LINK'
    }, callback)
}

const getEntityStrategy = (contentBlock: any, callback: any, contentState: any) => {
    contentBlock.findEntityRanges((character: any) => {
        const entityKey = character.getEntity()
        if (entityKey === null) {
            return false
        }
        return contentState.getEntity(entityKey).getMutability() === 'IMMUTABLE'
    }, callback)
}

interface EditorP {
    DraftMap: DraftJST
    setDraftMap: Function
    removedElement?: Function
    className?: string
    updated?: any
    focus?: string
    focusContent?: string
    focusTooltip?: boolean
    focusTooltipContent?: string
    automation?: boolean
    hideToolbar?:boolean
}
const Editor = (props: EditorP) => {
    const pasteRef = useRef<null | "paste">(null)
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty())

    const [editorTime, setEditorTime] = React.useState<null | number>(null)

    const [showToolTip, setshowToolTip] = useState(false)
    const [state, setState] = useState({ focus: false })
    const [showUrlBox, setShowUrlBox] = useState(false)
    const [showEmojiPopup, setShowEmojiPopup] = useState(false)

    const [hyperLink, setHyperLink] = useState('')
    const [linkText, setLinkText] = useState('')
    const [currentModifyKey,setCurrentModifyKey] = useState<{
        blockKey: null;
    }|{
        blockKey: string;
    }>({
        blockKey:null
    })

    let decorator =  new CompositeDecorator([
        {
            strategy: getEntityStrategy,
            component: TokenSpan
        },
        {
            strategy: findLinkEntities,
            component: (props:any)=> <Link callback={(url:string, linkText:string, blockKey:string)=>{
                console.log("coming",linkText)
                setHyperLink(url)
                setLinkText(linkText)
                setShowUrlBox(true)
                setCurrentModifyKey({ blockKey })
               !state.focus && setState({ focus: true })
               !state.focus && editorRef.current && (editorRef.current as any).focus()
            }} {...props}/>,
            // props:{
            //     setSelection:setSelection
            // }
        }
    ])



    const context: VariableContextT = React.useContext(VariableContext)
    const variableContextFAQ: VariableContextForFAQT = React.useContext(VariableContextForFAQ)

    const { workspaceDescendents, assistant }: DialogueContextT = React.useContext(DialogueContext)

    const [{ plugins, Toolbar,EmojiSelect }] = React.useState(() => {
        const toolbarPlugin = createToolbarPlugin();
        const emojiPlugin = createEmojiPlugin({
            selectGroups: [{ title: 'Society', icon: '☺', categories: ['people'] }]
        })
        const { EmojiSelect } = emojiPlugin
        
        const { Toolbar } = toolbarPlugin;
        const plugins = [toolbarPlugin,emojiPlugin];
        return {
          plugins,
          Toolbar,
          EmojiSelect
        };
      });

      const editorRef = React.useRef(null);

    const InsertContext = (value: string, information: VariableT) => {
        const currentContent = editorState.getCurrentContent()
        const selection = editorState.getSelection()
        const newSelection = selection.set('anchorOffset', selection.getFocusOffset()) as SelectionState
        const entityKey = Entity.create('VARIABLE', 'IMMUTABLE', { ...information })
        const textWithEntity = Modifier.insertText(currentContent, newSelection, value, undefined, entityKey)
        setEditorState(EditorState.push(editorState, textWithEntity, 'insert-characters'))
    }

    const insertEntity = (value: string, information: EntityVariableT) => {
        const currentContent = editorState.getCurrentContent()
        const selection = editorState.getSelection()
        const entityKey = Entity.create('ENTITY-VARIABLE', 'IMMUTABLE', { ...information })
        const textWithEntity = Modifier.insertText(currentContent, selection, value, undefined, entityKey)
        setEditorState(EditorState.push(editorState, textWithEntity, 'insert-characters'))
    }

    const insertEmoji = (value: string, information: { emojiUnicode:string }) => {
        const currentContent = editorState.getCurrentContent()
        const selection = editorState.getSelection()
        const entityKey = Entity.create('emoji', 'IMMUTABLE', { ...information })
        const textWithEntity = Modifier.insertText(currentContent, selection, value, undefined, entityKey)
        setEditorState(EditorState.push(editorState, textWithEntity, 'insert-characters'))
    }

    const { getEntityRange, getSelectionEntity } = draftJsUtils

    const generateLink = (hyperLink: any, linkDisplayText: any) => {
        let link = hyperLink
        if (hyperLink.includes('http://')) {
            if (!hyperLink.includes('https://')) {
                link = `http://${hyperLink}`
            }
        }

            if(currentModifyKey.blockKey){
                const contentState = editorState.getCurrentContent()

                const contentStateUpdated = contentState.mergeEntityData(
                    currentModifyKey.blockKey,
                  {
                    url: link,
                    target: '_blank',
                    linkText: linkDisplayText
                })
            

                let selection:any = editorState.getSelection();

                const entityRange = getEntityRange(
                  editorState,
                  getSelectionEntity(editorState)
                );
              
                const isBackward = selection.getIsBackward();
              
                if (isBackward) {
                    selection = selection.merge({
                      anchorOffset: entityRange.end,
                      focusOffset: entityRange.start
                    });
                  } else {
                    selection = selection.merge({
                      anchorOffset: entityRange.start,
                      focusOffset: entityRange.end
                    });
                  }
              
                const updatedEditorWithText = Modifier.replaceText(
                  contentStateUpdated,
                  selection,
                  linkDisplayText,
                  editorState.getCurrentInlineStyle(),
                  currentModifyKey.blockKey
                );
              
                const newEditorState = EditorState.push(
                  editorState,
                  updatedEditorWithText,
                  "insert-characters"
                );
                setEditorState(newEditorState)
                setHyperLink('')
                setLinkText('')
                setCurrentModifyKey({
                    blockKey:null
                })
            } else{
                const currentContent = editorState.getCurrentContent()
                const selection = editorState.getSelection()

                currentContent.createEntity('LINK', 'MUTABLE', {
                    url: link,
                    target: '_blank',
                    linkText: linkDisplayText
                })

                const entityKey = currentContent.getLastCreatedEntityKey()

                const textWithEntity = Modifier.replaceText(currentContent, selection, linkDisplayText, undefined, entityKey)
                const newState = EditorState.createWithContent(textWithEntity, decorator)
                console.log(convertToRaw(newState.getCurrentContent()))
                setEditorState(newState)
                setHyperLink('')
                setLinkText('')
            }
    }

    React.useEffect(() => {
        if (context.variable !== null && context.selectedEditor === editorTime && editorTime != null) {
            if (context.variable.type === 'context') {
                const { variable, path, dummypath, icon, dataType, type } = context.variable

                InsertContext(variable, {
                    type: 'context',
                    variable,
                    dataType,
                    dummypath,
                    path,
                    icon
                })
            } else if (context.variable.type === 'entity') {
                const { name, dummypath, icon, dataType, type } = context.variable

                insertEntity(name, {
                    type: 'entity',
                    name,
                    dataType,
                    dummypath,
                    icon
                })
            }

            context.assignVariable(null)
        }
    }, [context])

    React.useEffect(() => {
        if (context.selectedEditor !== editorTime) {
            setEditorTime(null)
        }
    }, [context.selectedEditor])

    React.useEffect(() => {
        if (context.popupExposed == false) {
            if (editorTime !== null) {
                setTimeout(() => setEditorTime(null))
            }
        }
    }, [context.popupExposed])

    const findVariableNode = (value: string) =>
        workspaceDescendents.reduce((accum: any, if_node: any) => {
            if (accum !== null) return accum

            if (if_node.type == 'faq_node') {
                return accum
            }

            const data = if_node.responses
                .filter((res_node: any) => {
                    if (res_node.type === 'ask_a_question') {
                        return res_node.variable === value
                    } else if (res_node.type === 'slot_container') {
                        return res_node.slots.findIndex((e: any) => e.variable === value) !== -1
                    } else if (res_node.type === 'automation') {
                        return res_node.output_variable === value
                    } else if (res_node.type === 'file_upload') {
                        return res_node.variable === value
                    } else return false
                })
                .pop()
            if (data) {
                if (data.type === 'ask_a_question' || data.type === 'automation' || data.type === 'file_upload') {
                    return data
                } else if (data.type === 'slot_container') {
                    return {
                        ...data,
                        slots: data.slots[data.slots.findIndex((e: any) => e.variable === value)]
                    }
                }
            }
            return accum
        }, null)

    const reduceEntityMap = (map: any) => {
        const entityMap = Object.entries(map.entityMap)
            .reduce((acuum: any[][], val: any[]): any[][] => {
                const [key, value] = val

                // console.log('value.data', value.data)

                if (value.data.type === 'VARIABLE') {
                    if (value.data.path.length === 1) {
                        if (
                            (value.data.variable && value.data.variable.startsWith('SYS_')) ||
                            (value.data.value && value.data.value.startsWith('SYS_'))
                        ) {
                            return [
                                ...acuum,
                                [
                                    key,
                                    {
                                        ...value,
                                        data: {
                                            ...value.data,
                                            dummypath: [assistant.name, ...value.data.dummypath]
                                        }
                                    }
                                ]
                            ]
                        } else {
                            if (value.data.variable && findVariableNode(value.data.variable) === null) {
                                return [...acuum, val]
                            } else if (value.data.value && findVariableNode(value.data.value) === null) {
                                return [...acuum, val]
                            }

                            const node = value.data.variable
                                ? findVariableNode(value.data.variable)
                                : findVariableNode(value.data.value)
                            return [
                                ...acuum,
                                [
                                    key,
                                    {
                                        ...value,
                                        data: {
                                            ...value.data,
                                            dummypath: [
                                                assistant.name,
                                                ...findPathViaRes(workspaceDescendents, node.id, []),
                                                node.type === 'ask_a_question'
                                                    ? 'Ask a question'
                                                    : 'Verify user details',
                                                ...value.data.dummypath
                                            ]
                                        }
                                    }
                                ]
                            ]
                        }
                    } else if (value.data.path.length > 1) {
                        const flowExutionId = value.data.path[0]
                        const extraPath =
                            value.data.path.length === 5
                                ? ['success']
                                : value.data.path.slice(5, value.data.path.length)

                        const automationNode = findVariableNode(flowExutionId)
                        if (flowExutionId.startsWith('SYS_') && automationNode !== null) {
                            return [
                                ...acuum,
                                [
                                    key,
                                    {
                                        ...value,
                                        data: {
                                            ...value.data,
                                            dummypath: [
                                                assistant.name,
                                                ...findPathViaRes(workspaceDescendents, automationNode.id, []),
                                                automationNode.name,
                                                ...extraPath
                                            ]
                                        }
                                    }
                                ]
                            ]
                        }
                    }
                }

                return [...acuum, val]
            }, [])

            .reduce((accum, [key, value]) => {
                return {
                    ...accum,
                    [key]: value
                }
            }, {})

        return { ...map, entityMap }
    }

    React.useEffect(() => {
        // console.log('EDITOR ::: props.updated')
        setEditorState(EditorState.createWithContent(convertFromRaw(reduceEntityMap(props.DraftMap as any)), decorator))
    }, [props.updated])

    React.useEffect(() => {
        if (props.removedElement) {
            setEditorState(
                EditorState.createWithContent(convertFromRaw(reduceEntityMap(props.DraftMap as any)), decorator)
            )
            props.removedElement()
        }
    }, [props.removedElement])

    React.useEffect(() => {
        // console.log('EDITOR ::: editorState',editorState.getCurrentContent())
        props.setDraftMap(convertToRaw(editorState.getCurrentContent()))
    }, [editorState])

    const handleFocus = () => {
        if (editorTime != null) {
            const datetime = Date.now()
            setEditorTime(datetime)
            context.setSelectedEditor(datetime)
            context.assignPopupExposed(true)
            props.automation && context.setForAutomationVariables('input')
            context.setDisplayStatusOfEntityListInVariable('SHOW')
        } else {
            setTimeout(() => {
                const datetime = Date.now()
                setEditorTime(datetime)
                context.setSelectedEditor(datetime)
                context.assignPopupExposed(true)
                props.automation && context.setForAutomationVariables('input')
                context.setDisplayStatusOfEntityListInVariable('SHOW')
            })
        }

        setState({ focus: true })
    }

    function handlePastedText(text: string, html: string, editorState: EditorState) {

        if(IsValidURL(text)){
            pasteRef.current = 'paste'
            const newState = Modifier.replaceText(editorState.getCurrentContent(), editorState.getSelection(), text.trim());
            setEditorState(EditorState.push(editorState, newState, 'insert-fragment'))
            return true
        }

        let newState = Conductor.handleDraftEditorPastedText(html, editorState)

        // console.log('EDITOR ::: handlePastedText', newState)

        if (newState) {
            setEditorState(newState)
            return true
        }

        return false
    }

    const Draft = DraftEditor as any

    const LinkButton = createInlineStyleButton({
        style: 'link-button',
        children: (
            <button onClick={() => {
                context.setUrlisOpen(!showUrlBox)
                variableContextFAQ.setUrlisOpen(!showUrlBox)
                setShowUrlBox(!showUrlBox)}}>
                <LinkIcon />
            </button>
        )
    })

    const EmojiButton = createInlineStyleButton({
        style: 'emoji-button',
        children: (
            <button onClick={() => {
                setShowEmojiPopup(!showEmojiPopup)}}>
                <EmojiIcon />
            </button>
        )
    })

    useEffect(() => {
        const selectionState = editorState.getSelection()

        if (showUrlBox && !selectionState.isCollapsed()) {
            const selectionState = editorState.getSelection()
            const anchorKey = selectionState.getAnchorKey()
            const currentContent = editorState.getCurrentContent()
            const currentContentBlock = currentContent.getBlockForKey(anchorKey)
            const start = selectionState.getStartOffset()
            const end = selectionState.getEndOffset()
            const selectedText = currentContentBlock.getText().slice(start, end)

            if (!selectionState.isCollapsed()) {
                const startKey = editorState.getSelection().getStartKey()
                const startOffset = editorState.getSelection().getStartOffset()
                const blockWithLinkAtBeginning = currentContent.getBlockForKey(startKey)
                const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset)

                let url = ''
                if (linkKey) {
                    const linkInstance = currentContent.getEntity(linkKey)
                    url = linkInstance.getData().url
                }
                setHyperLink(url)
            }
            setLinkText(selectedText)
        }
    }, [showUrlBox])


    return (
        <>
            <div
                className={
                    editorTime == null
                        ? `dialog_input_edit_true dialog_context_input_edit_true ${
                              props.className ? props.className : ''
                          } ${props.focus ? props.focus : ''}`
                        : `dialog_input_edit_true dialog_context_input_edit_true input_selected_dialog ${
                              props.className ? props.className : ''
                          } ${props.focus ? props.focus : ''}`
                }
                onClick={handleFocus}
                id={editorTime ? editorTime.toString() : ''}
            >
                <>
                    <FocusContent>
                        {props.focusContent}{' '}
                        {props.focusTooltip && (
                            <Tooltip
                                className="target customTip"
                                zIndex={10000}
                                arrowSize={8}
                                isOpen={showToolTip}
                                tagName="span"
                                content={props.focusTooltipContent}
                                distance={5}
                            >
                                <span
                                    style={{ right: 5, zIndex: 1 }}
                                    onMouseLeave={() => setshowToolTip(false)}
                                    onMouseEnter={() => setshowToolTip(true)}
                                >
                                    <InfoIcon />
                                </span>
                            </Tooltip>
                        )}
                    </FocusContent>
                    <Draft
                        onCopy={Conductor.onDraftEditorCopy}
                        onCut={Conductor.onDraftEditorCut}
                        editorState={editorState}
                        onChange={(event: any) => {
                            if(pasteRef.current == 'paste'){
                                pasteRef.current = null
                            }else {
                                setEditorState(event)
                            }
                        }}
                        // handlePastedText={(text: string, html: string | undefined, editorState: any) => 'handled'}
                        handlePastedText={handlePastedText}
                        editorKey="CustomInlineToolbarEditor"
                        plugins={plugins}
                        ref={(editor: any) => (editorRef.current = editor)}
                    />
                </>
            </div>
            <div className="message_toolbar_input">
                {state.focus && !props.hideToolbar&& (
                    <Downshift
                        isOpen={true}
                        onOuterClick={() => {
                            setShowUrlBox(false)
                            setState({ focus: false })
                            setHyperLink('')
                            setLinkText('')
                            context.setUrlisOpen(false)
                            variableContextFAQ.setUrlisOpen(false)

                        }}
                    >
                        {() => (
                            <div id="editor-input" className="message_toolbar_container">
                                <div className="message_toolbar">
                                    <Toolbar>
                                        {externalProps => (
                                            <React.Fragment>
                                                <div className="toolbar_parent">
                                                <div className="toolbar_child_one">
                                                    <BoldButton {...externalProps} />
                                                    <ItalicButton {...externalProps} />
                                                    <UnderlineButton {...externalProps} />
                                                    <Separator />
                                                    <UnorderedListButton {...externalProps} />
                                                    <OrderedListButton {...externalProps} />
                                                    {/* <OrderedListButton {...externalProps} /> */}
                                                    <LinkButton {...externalProps} />
                                                    <EmojiButton {...externalProps}/>
                                                </div>
                                                {showEmojiPopup&&
                                                <Downshift isOpen={true}
                                                onOuterClick={() => setShowEmojiPopup(false)}>
                                                    {() => (
                                                        <span>
                                                            <EmojiPopup onSelected={(emoji:any)=>{
                                                                insertEmoji(emoji,{emojiUnicode:emoji})
                                                                console.log(emoji,"emoji")
                                                            }}/>
                                                           
                                                        </span>
                                                    )}
                                                    </Downshift>
                                                }
                                                {/* <div className="toolbar_child_two">
                                                    <EmojiSelect closeOnEmojiSelect />
                                                    <EmojiSuggestions/>
                                                    {showEmojiPopup&&
                                                    <EmojiPopup onSelected={(emoji:any)=>{
                                                        console.log(emoji,"emoji")
                                                    }}/>
                                                    }
                                                    <div className="toolbar_child_two_bg"></div>
                                                </div> */}
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </Toolbar>
                                </div>
                                {showUrlBox && (
                                    <Downshift
                                        isOpen={true}
                                        onOuterClick={() => {
                                            setShowUrlBox(false)
                                            context.setUrlisOpen(false)
                                            variableContextFAQ.setUrlisOpen(false)
                                            // setState({ focus: false })
                                            setHyperLink('')
                                            setLinkText('')
                                            setCurrentModifyKey({
                                                blockKey:null
                                            })
                                        }}
                                    >
                                        {() => (
                                            <span>
                                                <InputWrapper
                                                    style={{
                                                        position: 'relative',
                                                        top: 95,
                                                        left: 20
                                                    }}
                                                >
                                                    <span
                                                       className={
                                                           linkText.length == 0 || IsValidURL(hyperLink) === null
                                                               ? 'editor_btn_disabled'
                                                               : ''
                                                       }
                                                       style={{
                                                           position: 'absolute',
                                                           bottom: 86,
                                                           zIndex: 1000,
                                                           cursor:'pointer',
                                                           left: -20
                                                       }}
                                                       onClick={()=>{
                                                            setShowUrlBox(false)
                                                            context.setUrlisOpen(false)
                                                            variableContextFAQ.setUrlisOpen(false)
                                                            setHyperLink('')
                                                            setLinkText('')
                                                            setCurrentModifyKey({
                                                                blockKey:null
                                                            })
                                                       }}
                                                   >
                                                       <CloseColorIcon />
                                                   </span>
                                                    <span
                                                        onClick={() => {
                                                            context.setUrlisOpen(false)
                                                            variableContextFAQ.setUrlisOpen(false)
                                                            setShowUrlBox(false)
                                                            generateLink(hyperLink, linkText)
                                                        }}
                                                        className={
                                                            linkText.length == 0 || IsValidURL(hyperLink) === null
                                                                ? 'editor_btn_disabled'
                                                                : ''
                                                        }
                                                        style={{
                                                            position: 'absolute',
                                                            bottom: 86,
                                                            zIndex: 1000,
                                                            cursor:'pointer',
                                                            left: 5
                                                        }}
                                                    >
                                                        <CheckColorIcon />
                                                    </span>

                                                        <div className="message_tool_link">
                                                            <InputContainer className="url_input_box">
                                                                <Input
                                                                    type="text"
                                                                    value={linkText}
                                                                    onChange={(e: any) => setLinkText(e.target.value)}
                                                                />
                                                                <InputContent>Text</InputContent>
                                                            </InputContainer>

                                                            <InputContainer className="url_input_box">
                                                                <Input
                                                                    type="text"
                                                                    value={hyperLink}
                                                                    onChange={(e: any) => setHyperLink(e.target.value)}
                                                                />
                                                                <InputContent>URL </InputContent>
                                                            </InputContainer>
                                                        </div>
                                                </InputWrapper>
                                            </span>
                                        )}
                                    </Downshift>
                                )}
                            </div>
                        )}
                    </Downshift>
                )}
            </div>

            
        </>
        // <SimpleEmojiEditor onChange={(event: any) => setEditorState(event)} />
    )
}

export default Editor
