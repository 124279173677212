import React from 'react'
import { CSSProperties, useState } from 'react'

interface ImageStyle {
    thumbnail: CSSProperties
    fullSize: CSSProperties
}

interface ImageOnLoadType {
    handleImageOnLoad: () => void
    css: ImageStyle
}

function useImageOnLoad(): ImageOnLoadType {
    const [isLoaded, setIsLoaded] = useState<boolean>(false)

    // Triggered when full image will be loaded.
    const handleImageOnLoad = () => {
        setIsLoaded(true)
    }

    const css: ImageStyle = {
        // Thumbnail style.
        thumbnail: {
            visibility: isLoaded ? 'hidden' : 'visible',
            display: isLoaded ? 'none' : 'block',
            filter: 'blur(8px)',
            transition: 'visibility 0ms ease-out 500ms'
        },
        // Full image style.
        fullSize: {
            opacity: isLoaded ? 1 : 0,
            transition: 'opacity 500ms ease-in 0ms'
        }
    }

    return { handleImageOnLoad, css }
}

// export default useImageOnLoad

export default function ImageWithPlaceHolder(props: { src: string }) {
    const { handleImageOnLoad, css } = useImageOnLoad()

    const style: { [key: string]: CSSProperties } = {
        wrap: {
            position: 'relative',
            minWidth: 50,
            minHeight: 50,
            margin: 'auto'
        },
        image: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: `100%`,
            height: `100%`
        }
    }

    return (
        <div style={style.wrap}>
            {/* Small image load fast */}
            <img
                className="img-thumbnail"
                src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' viewBox%3D'0 0 200 150'%2F%3E"
                style={{ ...css.thumbnail }}
                alt="thumbnail"
            />
            {/* Full size image */}
            <img
                onLoad={handleImageOnLoad}
                className="img-thumbnail"
                style={{ ...css.fullSize }}
                src={props.src}
                alt="fullImage"
            />
        </div>
    )
}
