/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react'

import { DownArrowCircle } from '@/common/Icons/Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { Loader } from '@/common/components/Loader'
import lazyGA from '@/common/google-analytics/tracking'

import logo from '@/common/images/logo.png'
import {
    createActionDialog,
    createRecogniseDialog,
    fetchAutomationConfig,
    fetchAutomationList,
    fetchFlowOutputVariable,
    updateDailouge
} from '../../Actions/AsyncOperations'
import { PageContext } from '../../Actions/ReactContext'
import { AutomationDialogPT, PageView } from '../../Types/PageT'
import { AutomationT, ErrorT, MOCK_AUTOMATION, MOCK_IF_NODE } from '../../Types/Types'
import {
    AskQueryDialogT,
    AutomationDialogT,
    JumpToSelectorT,
    RecognizeDialogT,
    SlotContainerDialogT,
    TextResponseDialogT
} from '../../Types/WatsonT'
import { Button, DropDown } from '../Common'
import { DraftContent, metaParser } from '../DraftContent'
import { conditionTYPES, operatorsT } from './RecogniseDialog'

const Ramda = require('ramda')

export const Automation = () => {
    const [loading, setLoading] = useState<boolean>(false)

    const [error, setError] = useState<ErrorT>({ error: false, info: '' })

    const [changeFlow, setChangeFlow] = useState<boolean>(false) // flow change drop down open or close

    const [automations, setAutomations] = useState<AutomationT[]>([]) // flow list

    const [selectedAutomation, setSelectedAutomation] = useState<AutomationT>(MOCK_AUTOMATION)

    const [automationName, setAutomationName] = useState<string>('') // automation name

    const [successChildNode, setSuccessChildNode] = useState<boolean>(true)

    const [failureChildNode, setFailureChildNode] = useState<boolean>(true)

    const [outputVariable, setOutputVariable] = useState<string>('') // output variable

    const [inputObjMeta, setInputObjMeta] = useState<any>({}) // own input format

    const [automationConfig, setAutomationConfig] = useState<any[]>([]) // flow input format

    const { workspace, PageState } = useContext(PageContext)

    useEffect(() => {
        const { input } = PageState as AutomationDialogPT
        setLoading(true)
        fetchAutomationList(workspace)
            .then(data => {
                console.log('automations list:::', data.output.automations)
                setAutomations(data.output.automations)
                if (input.dialog_node != '') {
                    const selectAutomation = data.output.automations.filter(
                        (flow: AutomationT) => flow.id == input.context.SYS_ACTIONS[1].data.automation
                    )[0]
                    if (selectAutomation) {
                        setSelectedAutomation(selectAutomation)
                        setInputObjMeta(input.metadata.RICH_TEXT)
                        setOutputVariable(input.context.SYS_ACTIONS[1].data.output_variable)
                        setAutomationName(input.metadata.automationName)
                        fetchAutomationConfig(workspace, selectAutomation.id).then(data => {
                            setAutomationConfig(data.output)
                            setFailureChildNode(false)
                            setSuccessChildNode(false)
                        })
                    }
                } else {
                    fetchFlowOutputVariable()
                        .then(variable => {
                            console.log('output variable:::', variable)
                            setOutputVariable(variable)
                        })
                        .catch(error => {
                            setLoading(false)
                            setError({ error: true, info: 'Something went wrong. Please try again!' })
                        })
                }
                setLoading(false)
            })
            .catch(error => {
                setError({ error: true, info: 'Something went wrong. Please try again!' })
                setLoading(false)
            })
    }, [])

    const changeAutomation = (automation: AutomationT) => {
        setInputObjMeta({})
        fetchAutomationConfig(workspace, automation.id).then(data => {
            console.log('automation config:::', data)
            const input = Object.keys(data.output).reduce(
                (acc: any, key: string) =>
                    Ramda.assoc(
                        key,
                        {
                            blocks: [
                                {
                                    text: '',
                                    type: 'unstyled'
                                }
                            ],
                            entityMap: {}
                        },
                        acc
                    ),
                {}
            )
            setInputObjMeta(input)
            setSelectedAutomation(automation)
            setAutomationConfig(data.output)
        })
    }

    const onSave = async (fetchCallback: Function) => {
        setLoading(true)
        try {
            const { input, previousSibling, parent } = PageState as AutomationDialogPT
            const result: AutomationDialogT = {
                ...input,
                context: {
                    SYS_ACTIONS: [
                        {
                            action: 'flush_response',
                            data: {}
                        },
                        {
                            action: 'execute_flow',
                            data: {
                                output_variable: outputVariable,
                                automation: selectedAutomation.id,
                                automationName: automationName,
                                automationNode: selectedAutomation,
                                input: Object.keys(inputObjMeta).reduce(
                                    (acc: any, key: string) => Ramda.assoc(key, metaParser(inputObjMeta[key]), acc),
                                    {}
                                )
                            }
                        }
                    ]
                },
                metadata: { hide_jump_to: true, automationName: automationName, RICH_TEXT: inputObjMeta }
            }
            let flowResult
            if (input.dialog_node == '') {
                flowResult = await createActionDialog(workspace, result)

                if (previousSibling) {
                    await updateDailouge(workspace, {
                        ...previousSibling,
                        type: previousSibling.type,
                        jump_to: {
                            dialog_node: flowResult.dialog_node,
                            selector: JumpToSelectorT.body
                        },
                        metadata: { ...previousSibling.metadata, hide_jump_to: true }
                    } as TextResponseDialogT | AskQueryDialogT | SlotContainerDialogT)
                } else if (parent) {
                    await updateDailouge(workspace, {
                        ...parent,
                        type: parent.type,
                        jump_to: {
                            dialog_node: flowResult.dialog_node,
                            selector: JumpToSelectorT.body
                        },
                        metadata: { ...parent.metadata, hide_jump_to: true }
                    } as RecognizeDialogT)
                }
                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Automation Node Updated'
                })
            } else {
                flowResult = await updateDailouge(workspace, result)
                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Automation Node Created'
                })
            }

            const SUCCESS_RICH_META = [
                {
                    type: conditionTYPES.CONTEXT,
                    LHS: 'success',
                    LHS_META: {
                        value: `$${outputVariable}.output.executionContext.output.success`,
                        visible: 'success',
                        path: [],
                        icon: logo,
                        dataType: 'boolean'
                    },
                    operator: operatorsT.IS_TRUE,
                    RHS: '',
                    RHS_META: {
                        blocks: [
                            {
                                text: '',
                                type: 'unstyled'
                            }
                        ],
                        entityMap: {}
                    }
                }
            ]
            const FAILURE_RICH_META = [
                {
                    type: conditionTYPES.CONTEXT,
                    LHS: 'success',
                    LHS_META: {
                        value: `$${outputVariable}.output.executionContext.output.success`,
                        visible: 'success',
                        path: [],
                        icon: logo,
                        dataType: 'boolean'
                    },
                    operator: operatorsT.IS_FALSE,
                    RHS: '',
                    RHS_META: {
                        blocks: [
                            {
                                text: '',
                                type: 'unstyled'
                            }
                        ],
                        entityMap: {}
                    }
                }
            ]

            if (successChildNode && failureChildNode) {
                const DummySuccessDialog: RecognizeDialogT = {
                    ...MOCK_IF_NODE,
                    type: 'if',
                    conditions: `$${outputVariable}.output.executionContext.output.success == true`,
                    title: 'success',
                    parent: flowResult.parent,
                    previous_sibling: flowResult.dialog_node
                }
                const successResult = await createRecogniseDialog(workspace, DummySuccessDialog)

                await updateDailouge(workspace, {
                    // update flow node for jump to case
                    ...flowResult,
                    jump_to: {
                        dialog_node: successResult.dialog_node,
                        selector: JumpToSelectorT.user_input
                    },
                    metadata: { ...flowResult.metadata, hide_jump_to: true }
                })

                await updateDailouge(workspace, {
                    // update success node for rich text
                    ...successResult,
                    metadata: { ...successResult.metadata, hide_jump_to: true, RICH_TEXT: SUCCESS_RICH_META }
                })

                const DummyFailureDialog: RecognizeDialogT = {
                    ...MOCK_IF_NODE,
                    type: 'if',
                    conditions: `$${outputVariable}.output.executionContext.output.success == false`,
                    title: 'failure',
                    parent: successResult.parent,
                    previous_sibling: successResult.dialog_node
                }
                const failureResult = await createRecogniseDialog(workspace, DummyFailureDialog)

                await updateDailouge(workspace, {
                    // update failure node for rich text
                    ...failureResult,
                    metadata: {
                        ...failureResult.metadata,
                        hide_jump_to: true,
                        RICH_TEXT: FAILURE_RICH_META
                    }
                })
            } else if (successChildNode) {
                const DummySuccessDialog: RecognizeDialogT = {
                    ...MOCK_IF_NODE,
                    type: 'if',
                    conditions: `$${outputVariable}.output.executionContext.output.success  == true`,
                    title: 'success',
                    parent: flowResult.parent,
                    previous_sibling: flowResult.dialog_node
                }
                const successResult = await createRecogniseDialog(workspace, DummySuccessDialog)

                await updateDailouge(workspace, {
                    // update flow node for jump to case
                    ...flowResult,
                    jump_to: {
                        dialog_node: successResult.dialog_node,
                        selector: JumpToSelectorT.user_input
                    },
                    metadata: { ...flowResult.metadata, hide_jump_to: true }
                })

                await updateDailouge(workspace, {
                    // update success node for rich text
                    ...successResult,
                    metadata: { ...successResult.metadata, hide_jump_to: true, RICH_TEXT: SUCCESS_RICH_META }
                })
            } else if (failureChildNode) {
                const DummyFailureDialog: RecognizeDialogT = {
                    ...MOCK_IF_NODE,
                    type: 'if',
                    conditions: `$${outputVariable}.output.executionContext.output.success == false`,
                    title: 'failure',
                    parent: flowResult.parent,
                    previous_sibling: flowResult.dialog_node
                }
                const failureResult = await createRecogniseDialog(workspace, DummyFailureDialog)

                await updateDailouge(workspace, {
                    // update flow node for jump to case
                    ...flowResult,
                    jump_to: {
                        dialog_node: failureResult.dialog_node,
                        selector: JumpToSelectorT.user_input
                    },
                    metadata: { ...flowResult.metadata, hide_jump_to: true }
                })

                await updateDailouge(workspace, {
                    // update failure node for rich text
                    ...failureResult,
                    metadata: {
                        ...failureResult.metadata,
                        hide_jump_to: true,
                        RICH_TEXT: FAILURE_RICH_META
                    }
                })
            }

            fetchCallback(() => setLoading(false))
        } catch (error) {
            setLoading(false)
            setError({ error: true, info: 'Something went wrong. Please try again!' })
        }
    }

    return (
        <PageContext.Consumer>
            {({ setPageState, fetchCallback }) => (
                <AutomationWrapper>
                    {loading ? (
                        <div className="popup_loader">
                            <Loader.PopupLoader show={loading} />
                        </div>
                    ) : (
                        <ErrorBoundary
                            error={error}
                            render={(err: any, info: any) => {
                                return err && <Error.Delete onClick={() => setPageState(PageView)} info={info} />
                            }}
                        >
                            <React.Fragment>
                                <div className="dialog_call_automation_popup">
                                    <div className="autoamtion_connection">
                                        <div className="select_application">
                                            <div className="select_work_form">
                                                <h4>Select Workflow Automation </h4>
                                                <input
                                                    type="text"
                                                    value={selectedAutomation.name ? selectedAutomation.name : 'select'}
                                                    onChange={e => {}}
                                                    onClick={() => setChangeFlow(true)}
                                                />
                                                <div className="arrow_up_form" onClick={() => setChangeFlow(true)}>
                                                    <DownArrowCircle />
                                                </div>
                                                <p>Select workflow automation from the list</p>
                                                <DropDown
                                                    isOpen={changeFlow == true}
                                                    onOuterClick={() => setChangeFlow(false)}
                                                >
                                                    <div
                                                        className="automation_scroly"
                                                        role="combobox"
                                                        aria-expanded="true"
                                                        aria-haspopup="listbox"
                                                        aria-owns="downshift-5-menu"
                                                        aria-labelledby="downshift-5-label"
                                                    >
                                                        <ul>
                                                            {automations.map(
                                                                (automation: AutomationT, index: number) => (
                                                                    <li
                                                                        key={index}
                                                                        onClick={event => {
                                                                            changeAutomation(automation)
                                                                            setChangeFlow(false)
                                                                        }}
                                                                    >
                                                                        {automation.name}
                                                                    </li>
                                                                )
                                                            )}
                                                            {automations.length == 0 ? (
                                                                <li>No active Automations</li>
                                                            ) : null}
                                                        </ul>
                                                    </div>
                                                </DropDown>
                                            </div>
                                        </div>
                                        <div className="automation_global_form">
                                            <div className="select_work_form">
                                                <h4>Name this workflow automation</h4>
                                                <input
                                                    type="text"
                                                    value={automationName}
                                                    placeholder="Enter Name"
                                                    onChange={e => setAutomationName(e.target.value)}
                                                />
                                                <p>Keep a unique name to identify easily.</p>
                                            </div>
                                        </div>
                                        <div className="dialog_call_automation_checkbox">
                                            <div className="select_work_form">
                                                <h4>Create Sub-dialogs for</h4>
                                                <div className="checkbox_inline_width">
                                                    <label className="checkbox_inline">
                                                        <input
                                                            className="checkbox_inline_input"
                                                            type="checkbox"
                                                            checked={successChildNode}
                                                            onChange={e => {}}
                                                            onClick={e => setSuccessChildNode(bool => !bool)}
                                                        />
                                                        <span>Success</span>
                                                    </label>
                                                    <label className="checkbox_inline">
                                                        <input
                                                            className="checkbox_inline_input"
                                                            type="checkbox"
                                                            checked={failureChildNode}
                                                            onChange={e => {}}
                                                            onClick={e => setFailureChildNode(bool => !bool)}
                                                        />
                                                        <span>Failed</span>
                                                    </label>
                                                </div>
                                                <p>
                                                    Check boxes to create child dialogs for workflow automation
                                                    ‘success’ and ‘failure’ scenarios.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {Object.keys(inputObjMeta).length > 0 ? (
                                        <div className="dialog_call_automation_height">
                                            <div className="test_buton_result">
                                                <div className="test_buton_view">
                                                    <h6>Input</h6>
                                                    <p>
                                                        Provide input values to send to Workflow Builder for this
                                                        workflow execution.
                                                    </p>

                                                    {Object.keys(inputObjMeta).map((input: any, index: number) => {
                                                        return (
                                                            <FlowInput
                                                                key={index}
                                                                input={
                                                                    automationConfig[input]
                                                                        ? automationConfig[input].title
                                                                        : input
                                                                }
                                                                value={inputObjMeta[input]}
                                                                onChange={(value: string) =>
                                                                    setInputObjMeta((automation: any) =>
                                                                        Ramda.assoc(input, value, automation)
                                                                    )
                                                                }
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <Button
                                    disable={
                                        automationName == '' ||
                                        selectedAutomation.id == '' ||
                                        Object.keys(inputObjMeta).filter(key => metaParser(inputObjMeta[key]) == '')
                                            .length > 0
                                    }
                                    onCancel={() => setPageState(PageView)}
                                    onSave={() => onSave(fetchCallback)}
                                />
                            </React.Fragment>
                        </ErrorBoundary>
                    )}
                </AutomationWrapper>
            )}
        </PageContext.Consumer>
    )
}

interface AutomationWrapperP {
    children: React.ReactChild
}

const AutomationWrapper = (props: AutomationWrapperP) => (
    <div className="right-panel slots_panel">
        <h5>Call an Automation</h5>
        <p className="call_automation_para">Select workflow automation to execute in this dialog </p>
        {props.children}
    </div>
)

interface FlowInputP {
    input: string
    value: any
    onChange: Function
}

export const FlowInput = ({ input, value, onChange }: FlowInputP) => (
    <div className="automation_form_req automation_form_req_step_two">
        <h4>{input}</h4>

        <div className="">
            <div className="automation_global_form_edit">
                <DraftContent rawMeta={value} setRawMeta={onChange} />
            </div>
        </div>
    </div>
)
