import { AutomationT } from './Types'

export interface NewDialogT {
    type: 'if' | 'text-response' | 'option-response' | 'qa' | 'slot-container' | 'slot' | 'action'
    parent: string | null
    previous_sibling: string | null
}

export enum JumpToSelectorT {
    user_input = 'user_input',
    condition = 'condition',
    body = 'body'
}

export type JumpToT = {
    dialog_node: string
    selector: JumpToSelectorT
}

export interface DialogMetaT {
    hide_jump_to: boolean
}

export interface DialogT extends NewDialogT {
    dialog_node: string
    children: DialogT[]
    metadata: DialogMetaT
    context: any
    jump_to: JumpToT
    created: string
    updated: String
}

export interface ActionDialogT extends DialogT {
    type: 'action'
}

// -----------------------------------------------------------------------------------------

// IF NODE TYPE START

export interface NewRecognizeDialogT extends NewDialogT {
    type: 'if'
    title: string
    conditions: string
}

export interface RecognizeMetaT extends DialogMetaT {
    RICH_TEXT: any[]
}

export interface RecognizeDialogT extends DialogT {
    type: 'if'
    title: string
    conditions: string
    metadata: RecognizeMetaT
}
// IF NODE TYPE END

// -----------------------------------------------------------------------------------------

// SEND MESSAGE NODE TYPE START
export enum TextResponseDialogSelectionE {
    sequential = 'sequential',
    random = 'random'
}

export interface TextResponseMetaT extends DialogMetaT {
    RICH_TEXT: any[]
}

export interface TextResponseDialogT extends DialogT {
    type: 'text-response'
    selection_policy: TextResponseDialogSelectionE
    values: string[]
    metadata: TextResponseMetaT
}
// SEND MESSAGE NODE TYPE END

// -----------------------------------------------------------------------------------------

// OPTIONS MESSAGE NODE TYPE START
export interface OptionsT {
    label: string
    value: string
}

export interface OptionsMetaT extends DialogMetaT {
    RICH_TEXT: any
}

export interface OptionsResponseDialogT extends DialogT {
    type: 'option-response'
    option_title: string
    options: OptionsT[]
    metadata: OptionsMetaT
}

// OPTIONS MESSAGE NODE TYPE END

// -----------------------------------------------------------------------------------------

// GET DETAILS NODE TYPE START
export interface SlotDialogT extends NewDialogT {
    type: 'slot'
    dialog_node: string
    variable: string
    condition: string
    prompt: string
}

export interface SlotContainerDialogT extends DialogT {
    type: 'slot-container'
    slots: SlotDialogT[]
}

// GET DETAILS NODE TYPE END

// -----------------------------------------------------------------------------------------

//QUESTION AND ANSWER NODE TYPE START
export interface QuestionT {
    text: string
}

export interface OptionsQueryT {
    text: string
    options: OptionsT[]
}

export interface QueryMetaT extends DialogMetaT {
    RICH_TEXT: any
}

export interface AskQueryDialogT extends DialogT {
    type: 'qa'
    // question: QuestionT | OptionsQueryT
    question: any
    variable: string
    metadata: QueryMetaT
}
//QUESTION AND ANSWER NODE TYPE END

// -----------------------------------------------------------------------------------------

//JUMP NODE TYPE START

export interface JumpDialogT extends ActionDialogT {}

//JUMP NODE TYPE END

// -----------------------------------------------------------------------------------------

// AUTOMATION NODE TYPE START
export type FlowDataT = {
    output_variable: string
    automation: string
    automationNode: AutomationT
    automationName: string
    input: any
}

export type ExecuteFlowT = {
    action: 'execute_flow'
    data: FlowDataT
}

export type FlushResponseT = {
    action: 'flush_response'
    data: any
}

export type FlowContextT = {
    SYS_ACTIONS: [FlushResponseT, ExecuteFlowT]
}

type FlowMetaT = {
    hide_jump_to: true
    automationName: string
    RICH_TEXT: any
}

export interface AutomationDialogT extends ActionDialogT {
    context: FlowContextT
    metadata: FlowMetaT
}
// AUTOMATION NODE TYPE END

// -----------------------------------------------------------------------------------------

//EXIT NODE TYPE START

export enum ExitJumpDialogT {
    feedback = 'feedback',
    exit = 'exit'
}

export interface ExitJumpToT {
    dialog_node: ExitJumpDialogT
    selector: JumpToSelectorT.body
}

export interface ExitContextT {
    SYS_EXIT_MESSAGE: string
}

export interface ExitMetaT extends DialogMetaT {
    RICH_TEXT: any
}
export interface ExitDialogT extends ActionDialogT {
    context: ExitContextT
    jump_to: ExitJumpToT
    metadata: ExitMetaT
}

//EXIT NODE TYPE END

// -----------------------------------------------------------------------------------------
