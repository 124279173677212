/*eslint-disable*/
import React from 'react'

import UserPhoto from './UserPhoto'
import { UserP } from './types.user'
// import { SmallButton, Td, Tr } from './ManageUsers.Component'
// import { InfoIcon, UserIconAnalyticsMenu } from '@/common/Icons/Icons'
// import Tooltip from 'react-tooltip-lite'

const StarIcon = () => (
    <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 60 60"
        enableBackground="new 0 0 60 60"
        xmlSpace="preserve"
    >
        <g>
            <circle fill="#FF7900" className="star0" cx="30" cy="30" r="30" />
        </g>
        <polygon
            className="star1"
            fill="#FFFFFF"
            points="46.9,26.2 35.7,23.9 30,13.9 24.3,23.9 13.1,26.2 20.8,34.7 19.6,46.1 30,41.4 40.4,46.1 39.2,34.7 "
        />
    </svg>
)

export const UserIntro = (userP: {
    user: {
        first_name: string
        last_name: string
        email: string
    }
}) => (
    <React.Fragment>
        <h4>
            {userP.user.first_name} {userP.user.last_name}
        </h4>
        <p>{userP.user.email}</p>
    </React.Fragment>
)

const UserToggleSwitch = (props: { valid: boolean; onChange: () => any }) => {
    return (
        <label className="switch">
            <input type="checkbox" onChange={props.onChange} checked={props.valid} />
            <span className="slider round" />
        </label>
    )
}

export const User = (userP: UserP) => {
    const isAdmin = userP.user.WorkspaceAccess.accessLevel === 'admin'
    const enabled = userP.user.WorkspaceAccess.valid
    const isVerified = userP.user.verified
    const bgClass = isAdmin
        ? 'col-lg-4 automation_profile_user'
        : 'col-lg-4 automation_profile_user automation_profile_user_bg'
    const topIconClass = isAdmin
        ? 'automation_profile_user_svg'
        : 'automation_profile_user_svg automation_profile_user_svg1'
    return (
        <div className={bgClass}>
            <div className={topIconClass}>
                {isAdmin ? (
                    <StarIcon />
                ) : userP.showAccessBtn ? (
                    <UserToggleSwitch
                        valid={enabled}
                        onChange={() =>
                            enabled ? userP.callbacks.disableUser(userP.user) : userP.callbacks.enableUser(userP.user)
                        }
                    />
                ) : null}
            </div>
            <div className="automation_profile_user_imag">
                <span>
                    <UserPhoto />
                </span>

                <UserIntro user={userP.user} />

                {!isAdmin && userP.showAccessBtn ? (
                    <h6 onClick={() => userP.callbacks.removeUser(userP.user)}>
                        {isVerified ? 'Remove User' : 'Cancel Invite'}
                    </h6>
                ) : null}
            </div>
        </div>
    //     <Tr>
    //   <Td size={3}>
    //     <div className="td_user_container">
    //       <div className="td_user_img">
    //         {' '}
    //         <UserIconAnalyticsMenu />{' '}
    //       </div>
    //       <div className="td_user_mail">{userP.user.email}</div>
    //     </div>
    //   </Td>

    //   <Td size={2}>
    //     <div className="td_header">{userP.user.first_name}</div>
    //   </Td>
    //   <Td size={2}>
    //     <div className="td_header">{userP.user.last_name}</div>
    //   </Td>
    //   <Td size={2}>
    //     <div className="td_header">
    //       {userP.user.WorkspaceAccess.accessLevel=="admin"?"Workspace owner":"Admin"}
    //       <Tooltip
    //         className="target customTip"
    //         zIndex={10000}
    //         arrowSize={8}
    //         tagName="span"
    //         content="Chatbot Admin Role"
    //         distance={8}
    //       >
    //         <InfoIcon />
    //       </Tooltip>
    //     </div>
    //   </Td>
    //   {/* {console.log(userP.user, 'user')} */}
    //   {/* <Td size={1.5}>
    //     <div className="td_header">{userP.user.MobileNumberVsUser &&
    //         `+${userP.user.MobileNumberVsUser.code} ${userP.user.MobileNumberVsUser.mobileNo}`}</div>
    //         </Td>
    //     <Td size={1}>
    //         <div className="td_header">{userP.user.CountryCode && userP.user.CountryCode.name}</div>
    //     </Td> */}
    //   <Td size={2}>
    //     <div className="td_header">
    //       <div className="manage_user_status_section active_user">
    //           {isVerified?
    //           <div className="show_hide">
    //             <div className="switch_box box_1">
    //               <input
    //                 type="checkbox"
    //                 className={`switch_1`}
    //                 disabled={userP.user.WorkspaceAccess.accessLevel=="admin"||!userP.showAccessBtn}
    //                 checked={userP.user.WorkspaceAccess.valid}
    //                 onChange={() =>
    //                   enabled
    //                     ? userP.callbacks.disableUser(userP.user)
    //                     : userP.callbacks.enableUser(userP.user)
    //                 }
    //                 style={userP.user.WorkspaceAccess.accessLevel=="admin"||!userP.showAccessBtn?{opacity:0.3,pointerEvents:"none"}:{}}
    //               />
    //             </div>
    //           </div> 
    //           : "Invited"}
    //         {/* <span>Has workspace access</span> */}
    //       </div>
    //     </div>
    //   </Td>
    //   <Td size={1}>
    //     {!isAdmin && userP.showAccessBtn && (
    //       <SmallButton
    //         primary
    //         onClick={() => userP.callbacks.removeUser(userP.user)}
    //       >
    //         <label>{isVerified ? 'Remove User' : 'Cancel Invite'}</label>
    //       </SmallButton>
    //     )}
    //   </Td>
    // </Tr>
    )
}

export default User
