/*eslint-disable*/
import React from 'react'

import { ArrowIcon, DeleteIcon, EditIcon, FlowIcon, TimerIcon } from '@/common/Icons/Icons'
import { HeaderChild, Header as THeader } from '@/common/components/Header'
import {
    BackButton,
    Card,
    CardBottom,
    CardDate,
    CardICons,
    CardTime,
    Count,
    DateTime,
    Description,
    Downloads,
    Header,
    HeaderImage,
    HeaderWithImage,
    ImageCircle,
    ImageSpan,
    SVGicons
} from '@/common/styled/Marketplace.Dumb'

import { formatAMPM, formatDateInCard } from '@/common/utils/utils'
import { padWithZero } from './utils'

export function EditAndDeleteIcons(props: any) {
    return (
        <>
            <SVGicons>
                <EditIcon />
            </SVGicons>
            <SVGicons>
                <DeleteIcon />
            </SVGicons>
        </>
    )
}
export const MarketPlaceCardNew = (props: any) => {
    const { image, description, date, count, automationName } = props
    return (
        <Card onClick={props.onClick}>
            <CardICons>{props.children}</CardICons>
            <HeaderWithImage>
                {/* {image && ( */}
                <ImageCircle>
                    <FlowIcon />
                </ImageCircle>
                {/* )} */}
                <Header>{description}</Header>
                {/* <Description> {description}</Description> */}
            </HeaderWithImage>
            <CardBottom>
                <DateTime>
                    <SVGicons>
                        <TimerIcon />
                    </SVGicons>
                    <CardDate>{formatDateInCard(date.toString())}</CardDate>{' '}
                    <CardTime>{formatAMPM(date.toString())}</CardTime>
                </DateTime>
                <Downloads>
                    Downloads: <Count>{padWithZero(count)}</Count>
                </Downloads>
            </CardBottom>
        </Card>
    )
}

export const MarketPlaceCard = (props: any) => {
    const { image, description, date, count, automationName } = props
    return (
        <Card>
            <CardICons>{props.EditAndDeleteIcons ? <EditAndDeleteIcons /> : null}</CardICons>
            <HeaderWithImage>
                {image && (
                    <ImageSpan>
                        <HeaderImage src={image} />
                    </ImageSpan>
                )}
                <Header>{automationName}</Header>
                <Description> {description}</Description>
            </HeaderWithImage>
            <CardBottom>
                <DateTime>
                    Updated: <CardDate>{formatDateInCard(date.toString())}</CardDate>{' '}
                    <CardTime>{formatAMPM(date.toString())}</CardTime>
                </DateTime>
                <Downloads>
                    Downloads: <Count>{padWithZero(count)}</Count>
                </Downloads>
            </CardBottom>
        </Card>
    )
}

export function MarketPlaceHeader(props: any) {
    return (
        <div className="marketplace_head">
            <THeader>
                <HeaderChild type="first">
                    {props.showBackButton === true && (
                        <BackButton
                            onClick={() => {
                                window.history.back()
                                props.clearSelection()
                            }}
                        >
                            <span>
                                <ArrowIcon />
                            </span>{' '}
                            <label>Back</label>
                        </BackButton>
                    )}
                    <div className="indent_left">
                        <div className="indent_title">{props.title}</div>
                        <p className="content_header">{props.content}</p>
                    </div>
                </HeaderChild>

                <React.Fragment>{props.children}</React.Fragment>
            </THeader>
        </div>
    )
}
type MarketPlaceHeaderT = React.PropsWithChildren<{
    backButton: { show: 'no' } | { show: 'yes'; action: () => void }
    title: string
    content: string
}>
export function MarketPlaceHeaderTyped(props: MarketPlaceHeaderT) {
    const renderBackButton = () => {
        const backButton = props.backButton
        if (backButton.show === 'yes') {
            return (
                <BackButton
                    onClick={() => {
                        backButton.action()
                    }}
                >
                    <span>
                        <ArrowIcon />
                    </span>{' '}
                    <label>Back</label>
                </BackButton>
            )
        }

        return null
    }

    return (
        <div className="marketplace_head">
            <THeader>
                <HeaderChild type="first">
                    {renderBackButton()}
                    <div className="indent_left">
                        <div className="indent_title">{props.title}</div>
                        <p className="content_header">{props.content}</p>
                    </div>
                </HeaderChild>

                <React.Fragment>{props.children}</React.Fragment>
            </THeader>
        </div>
    )
}
