/*eslint-disable*/
import React from 'react'

export interface CollectionT {
    what: string
    info: string
    where: string[]
    icon: string
    dataType: string
}

export interface InformationT {
    what: string
    where: string[]
    info: string
    dataType: string
}

export interface EnvironmentT {
    selected: null | number
    setSelected: Function
    exposed: boolean
    collection: CollectionT | null
    information: InformationT | null
    assignExposed: Function
    assignCollection: Function
    assignInformation: Function
}

export const Environment = React.createContext<EnvironmentT>({
    selected: null,
    setSelected: () => {},
    exposed: false,
    collection: null,
    information: null,
    assignExposed: () => {},
    assignCollection: () => {},
    assignInformation: () => {}
})
