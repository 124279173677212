import { RawDraftContentBlock } from 'draft-js'
import React, { useContext, useEffect, useState } from 'react'

import { convertAsaSingleString } from '@/Flows/canvas/utils'
import {
    AddIcon,
    CloseIcon,
    DownArrowCircle,
    DownarrowWithCircle,
    DropdownWithCircle,
    FlowCanvasContextIcons,
    MinusIcon,
    NextIconCanvas,
    NodeHoverMenuIcons,
    Tick
} from '@/common/Icons/Icons'
import {
    CancelButton,
    DropdownLi,
    DropdownUl,
    DropdownUlWrapper,
    Input,
    InputContainer,
    InputContent,
    InputWrapper
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { DraftInputWrapper } from '@/common/styled/DraftJs.Dumb'

import { Button, DropDown } from '../../../Common/Effective'
import { DraftJST, ExpressionTypeE, converterToEditor } from '../../../Editor/Action'
import Editor from '../../../Editor/Editor'
import { findPathViaRes } from '../..//Variable'
import { ContextVariableT, DialogueContext, DialogueContextT, VariableT } from '../../Context'
import SelectContextVariable from './SelectContextVariable'
import { conditionT, conditionTYPES, operatorsT, variableConditionT } from './kind'

const ContextCondition = ({ condition, onChange }: { condition: variableConditionT; onChange: Function }) => {
    console.log('ContextCondition:::', condition, onChange)
    const [conditionChooser, setConditionChooser] = useState<boolean>(false)

    const { workspaceDescendents }: DialogueContextT = React.useContext(DialogueContext)

    const findVariableNode = (value: string) =>
        workspaceDescendents.reduce((accum: any, if_node: any) => {
            if (accum !== null) return accum

            if (if_node.type == 'faq_node') {
                return accum
            }

            // console.log('if_node', if_node)
            const data = if_node.responses
                .filter((res_node: any) => {
                    if (res_node.type === 'ask_a_question') {
                        return res_node.variable === value
                    } else if (res_node.type === 'slot_container') {
                        return res_node.slots.findIndex((e: any) => e.variable === value) !== -1
                    } else if (res_node.type === 'automation') {
                        return res_node.output_variable === value
                    } else if (res_node.type === 'file_upload') {
                        return res_node.output_variable === value
                    }
                    return false
                })
                .pop()
            if (data) {
                if (data.type === 'ask_a_question' || data.type === 'automation' || data.type === 'file_upload') {
                    return data
                } else if (data.type === 'slot_container') {
                    return {
                        ...data,
                        slots: data.slots[data.slots.findIndex((e: any) => e.variable === value)]
                    }
                }
            }
            return accum
        }, null)

    const reduceEntityMap = (map: any) => {
        if (map.path.length === 1) {
            if (map.variable.startsWith('SYS_')) {
                return {
                    ...map,
                    dummypath: ['Assistant', ...map.dummypath]
                }
            } else {
                if (findVariableNode(map.variable) === null) return map
                const node = findVariableNode(map.variable)

                return {
                    ...map,
                    dummypath: [
                        'Assistant',
                        ...findPathViaRes(workspaceDescendents, node.id, []),
                        node.type === 'ask_a_question'
                            ? 'Ask a question'
                            : node.type === 'file_upload'
                            ? 'Upload Attachment'
                            : 'Verify user details',
                        ...map.dummypath
                    ]
                }
            }
        } else if (map.path.length > 1) {
            if (!map.path.includes('errorContext')) {
                const flowExutionId = map.path[0]
                const extraPath = map.path.length === 5 ? ['success'] : map.path.slice(5, map.path.length)
                const automationNode = findVariableNode(flowExutionId)
                if (flowExutionId.startsWith('SYS_') && automationNode !== null) {
                    return {
                        ...map,
                        dummypath: [
                            'Assistant',
                            ...findPathViaRes(workspaceDescendents, automationNode.id, []),
                            automationNode.name,
                            ...extraPath
                        ]
                    }
                }
            } else {
                return map
            }
        }
        return map
    }
    return (
        <React.Fragment>
            {/* LHS PART */}
            <div className="automation_condition_form">
                <div className="select_work_form">
                    <SelectContextVariable
                        rawMeta={
                            (condition.LHS as ContextVariableT).variable
                                ? reduceEntityMap(condition.LHS)
                                : condition.LHS
                        }
                        setRawMeta={(meta: VariableT) => {
                            onChange({
                                ...condition,
                                LHS: meta,
                                operator: operatorsT.SELECT,
                                RHS: converterToEditor({
                                    type: ExpressionTypeE.TEXT,
                                    value: ''
                                })
                            })
                        }}
                    />
                </div>
            </div>
            {Object.keys(condition.LHS).length && (condition.LHS as ContextVariableT).variable !== '' ? (
                <React.Fragment>
                    <div className="automation_condition_form">
                        <div className="select_work_form">
                            <InputContainer style={{ marginTop: 20, marginBottom: 0 }}>
                                <Input
                                    type="text"
                                    value={
                                        condition.operator === operatorsT.EQUALS_TEXT
                                            ? 'equals to (text)'
                                            : condition.operator === operatorsT.DOES_NOT_EQUALS_TEXT
                                            ? 'does not equals to (text)'
                                            : condition.operator === operatorsT.IS_PRESENT
                                            ? 'is present'
                                            : condition.operator === operatorsT.IS_NOT_PRESENT
                                            ? 'is not present'
                                            : condition.operator === operatorsT.GREATER_THAN
                                            ? 'greater than'
                                            : condition.operator === operatorsT.LESS_THAN
                                            ? 'less than'
                                            : condition.operator === operatorsT.IS_TRUE
                                            ? 'is true'
                                            : condition.operator === operatorsT.IS_FALSE
                                            ? 'is false'
                                            : condition.operator === operatorsT.EQUALS_NUMBER
                                            ? 'equals to (number)'
                                            : condition.operator === operatorsT.DOES_NOT_EQUALS_NUMBER
                                            ? 'does not equals to (number)'
                                            : condition.operator === operatorsT.CONTAINS
                                            ? 'contains (text)'
                                            : condition.operator === operatorsT.DOES_NOT_CONTAINS
                                            ? 'does not contains (text)'
                                            : ''
                                    }
                                    onChange={(event: any) => onChange({ ...condition, name: event.target.value })}
                                    onClick={(e: any) => setConditionChooser(true)}
                                />
                                <InputContent>Select Logic</InputContent>
                                <CancelButton onClick={(e: any) => setConditionChooser(true)}>
                                    <DropdownWithCircle />
                                </CancelButton>
                            </InputContainer>

                            {conditionChooser ? (
                                <DropdownUlWrapper>
                                    <DropDown isOpen={conditionChooser} onOuterClick={() => setConditionChooser(false)}>
                                        <div className="automation_scroly verify_user_popup">
                                            <DropdownUl>
                                                {condition.LHS.dataType == 'string' ||
                                                condition.LHS.dataType == 'all' ? (
                                                    <React.Fragment>
                                                        <DropdownLi
                                                            onClick={() => {
                                                                setConditionChooser(false)
                                                                onChange({
                                                                    ...condition,
                                                                    operator: operatorsT.EQUALS_TEXT
                                                                })
                                                            }}
                                                        >
                                                            equals to (text)
                                                        </DropdownLi>
                                                        <DropdownLi
                                                            onClick={() => {
                                                                setConditionChooser(false)
                                                                onChange({
                                                                    ...condition,
                                                                    operator: operatorsT.DOES_NOT_EQUALS_TEXT
                                                                })
                                                            }}
                                                        >
                                                            does not equals to (text)
                                                        </DropdownLi>
                                                        <DropdownLi
                                                            onClick={() => {
                                                                setConditionChooser(false)
                                                                onChange({
                                                                    ...condition,
                                                                    operator: operatorsT.CONTAINS
                                                                })
                                                            }}
                                                        >
                                                            contains (text)
                                                        </DropdownLi>
                                                        <DropdownLi
                                                            onClick={() => {
                                                                setConditionChooser(false)
                                                                onChange({
                                                                    ...condition,
                                                                    operator: operatorsT.DOES_NOT_CONTAINS
                                                                })
                                                            }}
                                                        >
                                                            does not contains (text)
                                                        </DropdownLi>
                                                    </React.Fragment>
                                                ) : null}
                                                {condition.LHS.dataType === 'number' ||
                                                condition.LHS.dataType === 'all' ? (
                                                    <React.Fragment>
                                                        <DropdownLi
                                                            onClick={() => {
                                                                setConditionChooser(false)
                                                                onChange({
                                                                    ...condition,
                                                                    operator: operatorsT.EQUALS_NUMBER
                                                                })
                                                            }}
                                                        >
                                                            equals to (number)
                                                        </DropdownLi>
                                                        <DropdownLi
                                                            onClick={() => {
                                                                setConditionChooser(false)
                                                                onChange({
                                                                    ...condition,
                                                                    operator: operatorsT.DOES_NOT_EQUALS_NUMBER
                                                                })
                                                            }}
                                                        >
                                                            does not equals to (number)
                                                        </DropdownLi>

                                                        <DropdownLi
                                                            onClick={() => {
                                                                setConditionChooser(false)
                                                                onChange({
                                                                    ...condition,
                                                                    operator: operatorsT.GREATER_THAN
                                                                })
                                                            }}
                                                        >
                                                            greater than
                                                        </DropdownLi>
                                                        <DropdownLi
                                                            onClick={() => {
                                                                setConditionChooser(false)
                                                                onChange({
                                                                    ...condition,
                                                                    operator: operatorsT.LESS_THAN
                                                                })
                                                            }}
                                                        >
                                                            less than
                                                        </DropdownLi>
                                                    </React.Fragment>
                                                ) : null}
                                                {condition.LHS.dataType == 'boolean' ||
                                                condition.LHS.dataType == 'all' ? (
                                                    <React.Fragment>
                                                        <DropdownLi
                                                            onClick={() => {
                                                                setConditionChooser(false)
                                                                onChange({ ...condition, operator: operatorsT.IS_TRUE })
                                                            }}
                                                        >
                                                            is true
                                                        </DropdownLi>
                                                        <DropdownLi
                                                            onClick={() => {
                                                                setConditionChooser(false)
                                                                onChange({
                                                                    ...condition,
                                                                    operator: operatorsT.IS_FALSE
                                                                })
                                                            }}
                                                        >
                                                            is false
                                                        </DropdownLi>
                                                    </React.Fragment>
                                                ) : null}
                                            </DropdownUl>
                                        </div>
                                    </DropDown>
                                </DropdownUlWrapper>
                            ) : null}
                        </div>
                    </div>
                    {condition.operator !== operatorsT.SELECT
                        ? condition.operator !== operatorsT.IS_TRUE &&
                          condition.operator !== operatorsT.IS_FALSE &&
                          condition.operator !== operatorsT.IS_PRESENT &&
                          condition.operator !== operatorsT.IS_NOT_PRESENT && (
                              <div className="automation_condition_form">
                                  <div className="select_work_form for_faq padding-top-20">
                                      <DraftInputWrapper>
                                          <Editor
                                              focusContent={'Condition to validate'}
                                              DraftMap={condition.RHS as DraftJST}
                                              setDraftMap={(meta: any) => onChange({ ...condition, RHS: meta })}
                                              focus={
                                                  typeof condition.RHS != 'boolean' &&
                                                  convertAsaSingleString(
                                                      condition.RHS.blocks as RawDraftContentBlock[]
                                                  ).replace(/\s/g, '').length
                                                      ? 'focus_input'
                                                      : ''
                                              }
                                          />
                                      </DraftInputWrapper>
                                  </div>
                              </div>
                          )
                        : null}
                </React.Fragment>
            ) : null}
        </React.Fragment>
    )
}

export default ContextCondition
