import produce from 'immer'
/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react'
import Tooltip from 'react-tooltip-lite'

import { InfoIcon, QaIconWithCircle } from '@/common/Icons/Icons'
import {
    Button,
    ButtonContainer,
    EmptyDiv,
    Wrapper as EndPanelWrapper,
    Header,
    HeaderLeft,
    Input,
    InputContainer,
    InputContent,
    InputWrapper,
    LinkTag,
    Paragraph,
    PopupContainer,
    PopupScrollHeight,
    PopupWrapper
} from '@/common/styled/Workflow.Dumb'
import { validateEmail } from '@/common/utils/common.utils'

import { SidePanelContext } from './Canvas'
import { ActionInitialState, NodeView, Nodes, Tree } from './types'
import { getProductId } from '@/common/utils/api-utils'

const R = require('ramda')

export type ReportInput = {
    successEmail: {
        valid: boolean
        value: string
    }
    failureEmail: {
        valid: boolean
        value: string
    }
}

export const EndPanel = (props: any) => {
    const { rightPanelState, setRightPanelState, treeData, setEditTreeData, saveTree } = useContext(SidePanelContext)

    const saveEndNode = () => {
        const nextState = produce(rightPanelState.currentNode, draftState => {
            if (draftState.nodeType == Nodes.End) {
                draftState.reportMeta = reportEmailList
            }
        })

        setRightPanelState({
            ...rightPanelState,
            currentNode: nextState,
            show: false,
            mode: NodeView,
            parentNode: { value: Tree, children: [] }
        })
        saveTree(nextState)
    }

    const initialReportData = () => {
        const endNode = treeData.tree.children[1].value
        if (endNode.meta_data && (endNode.meta_data.successEmail || endNode.meta_data.failureEmail)) {
            return {
                successEmail: {
                    valid: validateEmail(endNode.meta_data.successEmail),
                    value: endNode.meta_data.successEmail
                },
                failureEmail: {
                    valid: validateEmail(endNode.meta_data.failureEmail),
                    value: endNode.meta_data.failureEmail
                }
            }
        }
        return {
            successEmail: {
                valid: false,
                value: ''
            },
            failureEmail: {
                valid: false,
                value: ''
            }
        }
    }

    const [reportEmailList, setEmail] = useState<ReportInput>(initialReportData())

    useEffect(() => {
        setRightPanelState({
            ...rightPanelState,
            currentNode: { ...rightPanelState.currentNode, reportMeta: reportEmailList }
        })
    }, [reportEmailList])

    const changeSuccessEmail = (value: string, isValid: boolean) => {
        setEmail({ ...reportEmailList, successEmail: { value, valid: isValid } })
    }
    const changeFailureEmail = (value: string, isValid: boolean) => {
        setEmail({ ...reportEmailList, failureEmail: { value, valid: isValid } })
    }
    const enableSave = () => {
        const successEmail = reportEmailList.successEmail
        const failiureEmail = reportEmailList.failureEmail

        if (successEmail.value.length > 0 && failiureEmail.value.length > 0) {
            return successEmail.valid && failiureEmail.valid
        } else if (successEmail.value.length > 0) {
            return successEmail.valid
        } else if (successEmail.value.length > 0) {
            return failiureEmail.valid
        } else {
            return successEmail.value.length == 0 || failiureEmail.value.length == 0
        }
    }

    const resetRightPanel = () => {
        setRightPanelState({
            ...rightPanelState,
            currentNode: ActionInitialState,
            show: false,
            mode: NodeView,
            parentNode: { value: Tree, children: [] }
        })
        setEditTreeData(treeData)
    }

    return (
        <>
            <EndPanelWrapper>
                <PopupWrapper>
                    <PopupContainer>
                        <Wrapper>
                            {/* <CRP.StepHeader title={'Step 3'} description={'Exit from this workflow.'} /> */}
                            <ReportForm.Header />
                            <PopupScrollHeight style={{height: 'calc(100vh - 145px)'}}>
                                <EmptyDiv>
                                    <InputWrapper>
                                        <ReportForm.Wrapper>
                                            <ReportForm.SuccesBlock
                                                onEmailChange={(event: any) => {
                                                    const typedValue = event.target.value
                                                    changeSuccessEmail(event.target.value, validateEmail(typedValue))
                                                }}
                                                emailValue={reportEmailList.successEmail}
                                            />
                                            <ReportForm.FailureBlock
                                                onEmailChange={(event: any) => {
                                                    const typedValue = event.target.value
                                                    changeFailureEmail(event.target.value, validateEmail(typedValue))
                                                }}
                                                emailValue={reportEmailList.failureEmail}
                                            />
                                        </ReportForm.Wrapper>
                                    </InputWrapper>
                                </EmptyDiv>
                                <ButtonContainer>
                                    <Button primary onClick={resetRightPanel}>
                                        Cancel
                                    </Button>
                                    <Button className={enableSave() ? '' : 'editor_btn_disabled'} onClick={saveEndNode}>
                                        Save
                                    </Button>
                                </ButtonContainer>
                            </PopupScrollHeight>
                        </Wrapper>
                    </PopupContainer>
                </PopupWrapper>
            </EndPanelWrapper>
        </>
    )
}

const Wrapper = (props: any) => (
    <div className="automation_canvas_right">
        <div className="automation_canvas_right_popup automation_canvas_right_popup_notrigger">
            <div className="automation_canvas_right_popup_form">
                <div className="automation_rightside_form">{props.children}</div>
            </div>
        </div>
    </div>
)

const ReportForm = () => {}

ReportForm.Header = (_: any) => (
    <React.Fragment>
        {getProductId() === "ASSISTANT" ? ( 
            <Header style={{lineHeight: '20px'}}>
            <HeaderLeft>
                Execution report
                <Tooltip
                    className="target customTip"
                    zIndex={10000}
                    arrowSize={8}
                    tagName="span"
                    content="Send workflow execution report and status to desired email id. You may use different email ids to
monitor workflow success and or failure status."
                    distance={5}
                >
                    <InfoIcon />
                </Tooltip>
                <LinkTag href={'https://help.workativ.com/knowledgebase/step-3-end/'} target="_blank">
                    <QaIconWithCircle />
                </LinkTag>
            </HeaderLeft>
            </Header>
        ) : ( 
            <Header style={{lineHeight: '20px'}}>
            <HeaderLeft>
                Execution report
                <Tooltip
                    className="target customTip"
                    zIndex={10000}
                    arrowSize={8}
                    tagName="span"
                    content="Send workflow execution report and status to desired email id. You may use different email ids to
                             monitor workflow success and or failure status."
                    distance={5}
                >
                    <InfoIcon />
                </Tooltip>
                <a
                        target="_blank"
                        href="https://help.workativ.com/knowledgebase/execution-report/"
                        >
                    <Tooltip
                        className="target customTip"
                        zIndex={10000}
                        arrowSize={8}
                        tagName="span"
                        content={"Help document"}
                        distance={5}
                    >
                        
                            <QaIconWithCircle />
                    </Tooltip>
                </a>
                    
                {/* <LinkTag href={'https://help.workativ.com/knowledgebase/step-3-end/'} target="_blank">
                    <QaIconWithCircle />
                </LinkTag> */}
            </HeaderLeft>
            </Header>
        ) }

        {/* <Header style={{lineHeight: '20px'}}>
            <HeaderLeft>
                Execution report
                <Tooltip
                    className="target customTip"
                    zIndex={10000}
                    arrowSize={8}
                    tagName="span"
                    content="Send workflow execution report and status to desired email id. You may use different email ids to
monitor workflow success and or failure status."
                    distance={5}
                >
                    <InfoIcon />
                </Tooltip>
                <LinkTag href={'https://help.workativ.com/knowledgebase/step-3-end/'} target="_blank">
                    <QaIconWithCircle />
                </LinkTag>
            </HeaderLeft>
        </Header> */}
        <Paragraph>Send workflow execution report to the admin.</Paragraph>
    </React.Fragment>
)
ReportForm.Wrapper = (props: any) => (
    <>
        <div className="test_buton_view">{props.children}</div>
    </>
)
ReportForm.SuccesBlock = (props: any) => (
    <InputContainer style={{ marginBottom: 10 }}>
        <Input
            type="text"
            className="button_auto_input"
            value={props.emailValue.value}
            onChange={props.onEmailChange}
        />
        <InputContent>Success</InputContent>
        <Paragraph style={{ marginTop: 2 }}>Send report to this email on success</Paragraph>
    </InputContainer>
)
ReportForm.FailureBlock = (props: any) => (
    <InputContainer>
        <Input type="text" value={props.emailValue.value} onChange={props.onEmailChange} />
        <InputContent>Failed</InputContent>

        <Paragraph style={{ marginTop: 2 }}>Send report to this email on failure </Paragraph>
    </InputContainer>
)

//1 == 2 ? 'automation_condition_button_btn editor_btn_disabled' : 'automation_condition_button_btn'
