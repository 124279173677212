import { RawDraftContentBlock } from 'draft-js'
/*eslint-disable*/
import React, { useContext, useState } from 'react'
import Tooltip from 'react-tooltip-lite'
import styled from 'styled-components/macro'

import { convertAsaSingleString } from '@/Flows/canvas/utils'
import { VideoLink } from '@/Videos/Videos'
import { AddIcon, CloseIcon, InfoIcon, QaIconWithCircle } from '@/common/Icons/Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { Loader } from '@/common/components/Loader'
//COMMON MODULE IMPORTS
import lazyGA from '@/common/google-analytics/tracking'
import {
    EmptyDiv,
    Header,
    HeaderLeft,
    Input,
    InputContainer,
    InputContent,
    InputWrapper,
    Paragraph,
    PopupContainer,
    PopupScrollHeight,
    PopupWrapper,
    SmallButton,
    Wrapper
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { DraftInputWrapper } from '@/common/styled/DraftJs.Dumb'
import { HeaderRight } from '@/common/styled/Subscription.Xtra.Dumb'

import { Button } from '../../Common/Effective'
// TYPE IMPORTS
import { OptionsValueT, QuestionT } from '../../Dialogue/kind'
import { DraftJST, converterToEditor, converterToServer } from '../../Editor/Action'
//Rich Editor Imports
import Editor from '../../Editor/Editor'
//ACTION IMPORTS
import { createResponseDialogue, updateResponseDialogue } from '../../Mechanisms/action'
//CONTEXT IMPORTS
import { DialogueContext, VariableContext, VariableContextT } from '../Context'
import { ErrorState, ErrorT, QUESTION_RESPONSE, VIEW } from '../kind'
import { logEvent } from '@/common/utils/api-utils'
import { useScrollHook } from '@/common/hooks/useScrollHook'

const QuestionTips = () => (
    <>
        <p>
            <a
                target="_blank"
                className="sidepanel_href_color"
                href="https://help.workativ.com/index.php/knowledgebase/ask-a-question/"
            >
                Ask question
            </a>{' '}
            makes the bot prompt the end-user with a question and save the user's response in a 
            <b>context variable</b>.{' '}
        </p>
        <p>
            Bot can present <b>simple</b> question or an <b>options-based</b> question to the user.
        </p>
        <p>
            For example,
            <br />
            <b>Bot:</b> Please provide your mobile number.
            <br />
            <b>User:</b> My mobile number is 1234567890.
        </p>
        <p>
            The bot will then save, <b>"My mobile number is 1234567890"</b> in a context variable set by you e.g.Mobile
        </p>{' '}
        <p>
            <b>Tip:</b> Use <b>‘Verify user details’</b> function if you want the bot to validate user’s answer before
            saving.
        </p>
    </>
)

const MultipleQuestionWrapper = styled.div`
    width: 100%;
    float: left;
    position: relative;

    .parent_node_no {
        position: absolute;
        width: 18px;
        height: 18px;
        margin: 0px;
        right: 12px;
        top: 14px;

        span {
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            border: 1px solid #999;
            cursor: pointer;

            svg {
                width: 8px;
                height: 8px;
                color: #999;
                fill: #999;
            }
        }

        :hover {
            span {
                background: #ffe5d3;
                border: 1px solid #ffe5d3;

                svg {
                    fill: #333;
                    color: #333;
                }
            }
        }
    }
`

const Question = () => {
    const [loader, setLoader] = useState<boolean>(false)

    const [error, setError] = useState<ErrorT>(ErrorState)

    const conte = useContext(DialogueContext)
    const { workspaceName, assistantID, setPageState, PageState, fetchCallback, dialogueName, dialogueNode } = conte

    const variableContext: VariableContextT = React.useContext(VariableContext)

    const { data } = PageState as QUESTION_RESPONSE

    const [draft, setDraft] = useState<DraftJST>(converterToEditor(data.question, conte, dialogueNode))

    const [keycode, setKeycode] = useState<number>(13)

    const [metacode, setMetacode] = useState<boolean>(false)

    const [maskInput, setMaskInput] = useState<boolean>(data.mask_input ? data.mask_input : false)

    const [variable, setVariable] = useState<string>(data.variable)

    const [type, setType] = useState<boolean>(data.options.length ? true : false)

    const [options, setOptions] = useState<string[]>(
        data.options ? data.options.map((val: OptionsValueT) => val.value.value) : ['']
    )

    const [scrollableDivRef, scrollTo] = useScrollHook()

    const save = async () => {
        setLoader(true)
        try {
            if (data.id == '') {
                await createResponseDialogue(
                    workspaceName,
                    assistantID,
                    data.parent,
                    {
                        ...data,
                        question: converterToServer(draft),
                        options:
                            type == true
                                ? options.map((value: string) => ({
                                      label: {
                                          type: 'text',
                                          value: value
                                      },
                                      value: {
                                          type: 'text',
                                          value: value
                                      }
                                  }))
                                : [],
                        mask_input: maskInput,
                        variable
                    } as QuestionT,
                    dialogueName
                )

                logEvent(workspaceName, {
                    event:"EVENT::DIALOG::ADD_ASK_A_QUESTION", event_meta:{
                    name: dialogueName,
                    description: ""
                }})

                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Send Message Node Created'
                })
            } else {
                await updateResponseDialogue(
                    workspaceName,
                    assistantID,
                    data.parent,
                    data.id,
                    {
                        ...data,
                        question: converterToServer(draft),
                        options:
                            type == true
                                ? options.map((value: string) => ({
                                      label: {
                                          type: 'text',
                                          value: value
                                      },
                                      value: {
                                          type: 'text',
                                          value: value
                                      }
                                  }))
                                : [],
                        mask_input: maskInput,
                        variable
                    } as QuestionT,
                    dialogueName
                )
            }
            fetchCallback(() => setLoader(false))
        } catch (error) {
            setLoader(false)
            console.error(error)
            setError({ error: true, info: 'Something went wrong. Please try again!' })
        }
    }

    const labelHandleChange = (value: string, index: number) => {
        setOptions(option =>
            option.map((val: string, i: number) => {
                if (i == index) {
                    return value
                }
                return val
            })
        )
    }

    const removeOption = (index: number) => setOptions(option => option.filter((e: any, i: number) => i != index))

    const addMore = () => setOptions(option => option.concat(['']))

    return (
        <Wrapper>
            <PopupWrapper>
                <PopupContainer>
                    <Header>
                        <HeaderLeft minWidth={300}>
                            Ask question
                            <Tooltip
                                className="target customTip"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content={<QuestionTips />}
                                distance={5}
                            >
                                <InfoIcon />
                            </Tooltip>
                            <a href="https://help.workativ.com/knowledgebase/ask-a-question" target="_blank">
                                <Tooltip
                                    className="target customTip"
                                    zIndex={10000}
                                    arrowSize={8}
                                    tagName="span"
                                    content="View help document"
                                    distance={5}
                                >
                                    <QaIconWithCircle />
                                </Tooltip>
                            </a>
                        </HeaderLeft>
                        <HeaderRight>
                            <VideoLink id={'ASK_QUESTION'} text={'How to'} />
                        </HeaderRight>
                    </Header>
                    <Paragraph>Ask question to user and save response as input variable.</Paragraph>
                    <PopupScrollHeight ref={scrollableDivRef}>
                        <ErrorBoundary
                            error={error}
                            render={(err: any, info: any) => {
                                return err && <Error.Delete onClick={() => setPageState(VIEW)} info={info} />
                            }}
                        >
                            {loader ? (
                                <div className="popup_loader">
                                    <Loader.PopupLoader show={loader} />
                                </div>
                            ) : (
                                <React.Fragment>
                                    <EmptyDiv>
                                        <EmptyDiv>
                                            <div className="right_panel_radio">
                                                <div className="col-md-12 entity_radio">
                                                    <Paragraph className="recognize_dialog_fontsize row">
                                                        Do not store user answer anywhere{' '}
                                                        <div className="show_hide">
                                                            <div className="switch_box box_1" style={{ marginLeft: 5 }}>
                                                                <input
                                                                    type="checkbox"
                                                                    className={`switch_1`}
                                                                    checked={maskInput}
                                                                    onChange={() => {
                                                                        setMaskInput(!maskInput)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Paragraph>
                                                </div>
                                            </div>
                                        </EmptyDiv>
                                        <div className="right_panel_radio">
                                            <div className="col-md-12 entity_radio">
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            className="radio-warning"
                                                            checked={!type}
                                                            onChange={() => setType(false)}
                                                        />
                                                    </label>
                                                    <span>Simple Question</span>
                                                </div>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            checked={type}
                                                            onChange={() => {
                                                                setType(true)
                                                                setOptions(option => (option.length ? option : ['']))
                                                            }}
                                                        />
                                                    </label>
                                                    <span>Question with multiple options</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={type ? 'options_question' : 'simple_question'}>
                                            <div className="">
                                                <div className="qeustions_wrapper">
                                                    <InputContainer>
                                                        <DraftInputWrapper>
                                                            <Editor
                                                                focusContent={'Type your question'}
                                                                DraftMap={draft}
                                                                setDraftMap={setDraft}
                                                                focus={
                                                                    convertAsaSingleString(
                                                                        draft.blocks as RawDraftContentBlock[]
                                                                    ).replace(/\s/g, '').length
                                                                        ? 'focus_input'
                                                                        : ''
                                                                }
                                                            />
                                                        </DraftInputWrapper>
                                                    </InputContainer>
                                                </div>
                                            </div>
                                            {type && options ? (
                                                <React.Fragment>
                                                    <Header className="question_span">Add Options</Header>
                                                    {options.map((value: string, index: number) => (
                                                        <MultipleQuestionWrapper key={index}>
                                                            <InputContainer>
                                                                <Input
                                                                    type="text"
                                                                    value={value}
                                                                    maxLength={80}
                                                                    onChange={(e: any) => {
                                                                        labelHandleChange(e.target.value, index)
                                                                        scrollTo('bottom',index === options.length -1)
                                                                     }
                                                                    }
                                                                />
                                                                <InputContent>
                                                                    Option{' '}
                                                                    {index + 1 < 9
                                                                        ? '' + (index + 1).toString()
                                                                        : index + 1 == 9
                                                                        ? '9'
                                                                        : index + 1}{' '}
                                                                </InputContent>
                                                            </InputContainer>

                                                            {options.length > 1 ? (
                                                                <div className="parent_node_no">
                                                                    <span
                                                                        className="parent_svg"
                                                                        onClick={() => removeOption(index)}
                                                                    >
                                                                        <CloseIcon />
                                                                    </span>
                                                                </div>
                                                            ) : null}
                                                            {/* <label className="queston_label_editor">
                                                                {index + 1 < 9
                                                                    ? '0' + (index + 1).toString()
                                                                    : index + 1 == 9
                                                                    ? '09'
                                                                    : index + 1}
                                                            </label> */}
                                                        </MultipleQuestionWrapper>
                                                    ))}
                                                    {options.length < 20 &&
                                                    options.filter(data => data == '').length == 0 ? (
                                                        <div className="plus_addmore">
                                                            <div className="plus_add_more">
                                                                <SmallButton primary onClick={() => {
                                                                    addMore(); 
                                                                    scrollTo('bottom')
                                                                    }
                                                                    }>
                                                                    <span>
                                                                        <AddIcon />
                                                                    </span>
                                                                    <label>Add More</label>
                                                                </SmallButton>
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </React.Fragment>
                                            ) : null}
                                            <div className="">
                                                <InputWrapper className="p-0">
                                                    <InputContainer>
                                                        <Input
                                                            type="text"
                                                            value={variable}
                                                            onKeyDown={(e: any) => {
                                                                setKeycode(e.keyCode)
                                                                if (e.metaKey || e.ctrlKey) setMetacode(true)
                                                                else setMetacode(false)
                                                            }}
                                                            onChange={(e: any) => {
                                                                if (keycode != 32 && metacode == false) {
                                                                    setVariable(e.target.value.replace(/[^\w\s]/gi, ''))
                                                                }
                                                            }}
                                                        />
                                                        <InputContent>Save users response as</InputContent>
                                                    </InputContainer>
                                                </InputWrapper>
                                            </div>
                                        </div>
                                    </EmptyDiv>

                                    <Button
                                        className="footer_button_fixed"
                                        disable={
                                            draft.blocks[0].text === '' ||
                                            variable === '' ||
                                            (type ? options.filter(val => val === '').length > 0 : false)|| variableContext.isUrlOpen
                                        }
                                        onCancel={() => setPageState(VIEW)}
                                        onSave={save}
                                    />
                                </React.Fragment>
                            )}
                        </ErrorBoundary>
                    </PopupScrollHeight>
                </PopupContainer>
            </PopupWrapper>
        </Wrapper>
    )
}

export default Question
