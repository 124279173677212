/*eslint-disable*/
import React from 'react'

import { ExistT as EntityT } from '../../Entity/types'
import { IntentT } from '../../Intent/types'
import { PageLoading, PageState } from '../Types/PageT'
import { MOCK_IF_NODE } from '../Types/Types'
// Types Import
import { DialogT, RecognizeDialogT } from '../Types/WatsonT'

export interface PageContextT {
    workspace: string // current workspace name

    PageState: PageState // current page state
    setPageState: Function // set page state :: function

    isDetailView: boolean // current canvas view ? detail or icon view :: boolean
    setDetailView: Function // set current canvas view :: function

    setHistory: Function // change router history :: function

    currentDialog: RecognizeDialogT // current root if node :: DialogT

    DialogIndex: string // current root if node index :: string

    DataMixture: DialogT[] // current dialog all nodes :: DialogT[]
    fetchChild: Function // fetch current dialog node child :: function

    workspaceDataMixture: DialogT[] // workspace dialog all nodes :: DialogT[]
    fetchWorkspaceChild: Function // fetch workspace dialog node child :: function

    workspaceDataWF: RecognizeDialogT[] // workspace dialog all nodes watson format :: DialogT[]

    intents: IntentT[] // workspace intents list
    setIntents: Function
    entities: EntityT[] // workspace entities list

    fetchCallback: Function // fetch all data for server :: function

    dialogContext: any // own format for workspace dialog context mixing :: object [key:: workspace id, value:: string[] ]
    flowUIJson: any // own format for workspace dialog flow mixing

    actionMixture: any // own format for workspace dialog flow actions objext
}

export const PageContext = React.createContext<PageContextT>({
    workspace: '',
    PageState: PageLoading,
    setPageState: () => {},
    isDetailView: false,
    setHistory: () => {},
    setDetailView: () => {},
    currentDialog: MOCK_IF_NODE,
    DialogIndex: '',
    DataMixture: [],
    fetchChild: () => {},
    workspaceDataMixture: [],
    fetchWorkspaceChild: () => {},
    workspaceDataWF: [],
    dialogContext: {},
    flowUIJson: {},
    actionMixture: {},
    fetchCallback: () => {},
    intents: [],
    setIntents: () => {},
    entities: []
})

export interface DialogTreeContextT {
    appendChild: string | null
    setAppendChild: Function
    addChildInLast: string | null
    setAddChildInLast: Function
    addChildInBetween: string | null
    setAddChildInBetween: Function

    JumpToDialog: Function
    AutomationDialog: Function
    ExitDialog: Function

    RecognizeDialog: Function
    TextDialog: Function
    QueryDialog: Function
    OptionsDialog: Function
    SlotContainerDialog: Function

    EditDialog: Function
}

export const DialogTreeContext = React.createContext<DialogTreeContextT>({
    appendChild: null,
    setAppendChild: () => {},
    addChildInLast: null,
    setAddChildInLast: () => {},
    addChildInBetween: null,
    setAddChildInBetween: () => {},

    JumpToDialog: () => {},
    AutomationDialog: () => {},
    ExitDialog: () => {},

    RecognizeDialog: () => {},
    TextDialog: () => {},
    QueryDialog: () => {},
    OptionsDialog: () => {},
    SlotContainerDialog: () => {},

    EditDialog: () => {}
})

export interface FlowTreeContextT {
    selectedDialog: string

    selectedFlow: string
    setSelectedFlow: Function

    selectedFlowNode: string
    setSelectedFlowNode: Function

    selectedAction: any
    setSelectedAction: Function

    output: any
    setOutput: Function

    selectAction: Function
}

export const FlowTreeContext = React.createContext<FlowTreeContextT>({
    selectedDialog: '',

    selectedFlow: '',
    setSelectedFlow: () => {},

    selectedFlowNode: '',
    setSelectedFlowNode: () => {},

    selectedAction: {},
    setSelectedAction: () => {},

    output: {},
    setOutput: () => {},

    selectAction: () => {}
})
