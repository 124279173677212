import { RawDraftContentBlock } from 'draft-js'
/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react'
import Tooltip from 'react-tooltip-lite'
import styled from 'styled-components/macro'

import { convertAsaSingleString } from '@/Flows/canvas/utils'
import { VideoLink } from '@/Videos/Videos'
import { AddIcon, CloseBtnWithCircle, CloseIcon, DeleteIcon, InfoIcon, QaIconWithCircle } from '@/common/Icons/Icons'
//ICONS IMPORTS
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
//COMMON MODULE IMPORTS
import { Loader } from '@/common/components/Loader'
import lazyGA from '@/common/google-analytics/tracking'
import {
    Header,
    HeaderLeft,
    Paragraph,
    PopupContainer,
    PopupScrollHeight,
    PopupWrapper,
    SmallButton,
    Wrapper
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { DraftInputWrapper } from '@/common/styled/DraftJs.Dumb'
import { HeaderRight } from '@/common/styled/Subscription.Xtra.Dumb'

import { Button } from '../../Common/Effective'
// TYPE IMPORTS
import { TextNodeT } from '../../Dialogue/kind'
import { DraftJST, ExpressionTypeE, converterToEditor, converterToServer } from '../../Editor/Action'
//Rich Editor Imports
import Editor from '../../Editor/Editor'
//ACTION IMPORTS
import { createResponseDialogue, updateResponseDialogue } from '../../Mechanisms/action'
///CONTEXT IMPORTS
import { DialogueContext, VariableContext, VariableContextT } from '../Context'
import { ErrorState, ErrorT, TEXT_RESPONSE, VIEW } from '../kind'
import { logEvent } from '@/common/utils/api-utils'
import {useScrollHook} from "@/common/hooks/useScrollHook"



const SendMessageTips = () => (
    <>
        <p>
            <a
                className="sidepanel_href_color"
                target="_blank"
                href="https://help.workativ.com/index.php/knowledgebase/send-message/"
            >
                Send message
            </a>{' '}
            makes the bot respond to a user's input or query with a text message.
        </p>
        <p>
            You can specify multiple messages in the ‘send message’ pop up. And make the bot display messages either
            <b> sequentially</b> or <b>randomly</b>, to break monotony.
        </p>
    </>
)

const SendmMessageWrapper = styled.div`
    width: 100%;
    float: left;
    position: relative;
    background: #f4f4f4;
    padding: 20px;
    margin-bottom: 10px;
    .right_pop_icons {
        float: right;
        width: max-content;
        position: absolute;
        right: 32px;
        margin-top: 7px;
        z-index: 1;
        span {
            width: 18px;
            height: 18px;
            border: 1px solid #999;
            border-radius: 50%;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 6px;
            svg {
                fill: #999;
                cursor: pointer;
                width: 8px;
                height: 8px;
            }
            :hover {
                background: #ffe5d3;
                border: 1px solid #ffe5d3;
                svg {
                    color: #333;
                }
            }
        }
    }
`
const SaveMessageWrapper = styled.div`
    width: 100%;
    float: left;
    position: relative;
    background: #f4f4f4;
    padding: 20px;
    margin-bottom: 10px;

    .plus_add_more {
        position: absolute;
        right: 32px;
        z-index: 1;
        width: auto;
        top: 27px;
        display: none;
    }
    :hover {
        .plus_add_more {
            display: flex;
        }
    }
`
const TextResponse = () => {
    const [loading, setLoading] = useState<boolean>(false)

    const [alreadyOpen, setAlreadyOpen] = useState<boolean>(false)
    const [updated, setIsupdate] = useState<number>(Math.random())

    const [error, setError] = useState<ErrorT>(ErrorState)
    const context = useContext(DialogueContext)

    const { workspaceName, assistantID, setPageState, PageState, fetchCallback, dialogueName } = context
    const { data } = PageState as TEXT_RESPONSE

    const [selectionPolicy, setSelectionPolicy] = useState<'sequential' | 'random'>(data.selection_policy)

    const [values, setValus] = useState<DraftJST[]>(
        data.values.map(e => converterToEditor(e, context, context.dialogueNode))
    )

    const variableContext: VariableContextT = React.useContext(VariableContext)

    const [scrollableDivRef, scrollTo] = useScrollHook()

    const handleSave = async () => {
        console.log(values.map(e => converterToServer(e)),"values")
        // return
        setLoading(true)
        try {
            if (data.id == '') {
                await createResponseDialogue(
                    workspaceName,
                    assistantID,
                    data.parent,
                    {
                        ...data,
                        selection_policy: selectionPolicy,
                        values: values.map(e => converterToServer(e))
                    } as TextNodeT,
                    dialogueName
                )

                logEvent(workspaceName, {
                    event:"EVENT::DIALOG::ADD_SEND_MESSAGE", event_meta:{
                    name: dialogueName,
                    description: ""
                }})

                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Send Message Node Created'
                })
            } else {
                await updateResponseDialogue(
                    workspaceName,
                    assistantID,
                    data.parent,
                    data.id,
                    {
                        ...data,
                        selection_policy: selectionPolicy,
                        values: values.map(e => converterToServer(e))
                    } as TextNodeT,
                    dialogueName
                )
            }
            fetchCallback(() => setLoading(false))
        } catch (error) {
            setLoading(false)
            console.error(error)
            setError({ error: true, info: 'Something went wrong. Please try again!' })
        }
    }

    const countSet = (count: number) => (count < 10 ? '0' + count.toString() : count.toString())

    return (
        <Wrapper>
            <PopupWrapper>
                <PopupContainer>
                    <Header>
                        <HeaderLeft minWidth={300}>
                            Send message
                            <Tooltip
                                className="target customTip"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content={<SendMessageTips />}
                                distance={5}
                            >
                                <InfoIcon />
                            </Tooltip>
                            <a href="https://help.workativ.com/knowledgebase/send-message/" target="_blank" rel="noopener" title='Send message'>
                                <Tooltip
                                    className="target customTip"
                                    zIndex={10000}
                                    arrowSize={8}
                                    tagName="span"
                                    content="View help document"
                                    distance={5}
                                >
                                    <QaIconWithCircle />
                                </Tooltip>
                            </a>
                        </HeaderLeft>
                        <HeaderRight>
                            <VideoLink id={'SEND_MESSAGE'} text={'How to'} />
                        </HeaderRight>
                    </Header>
                    <Paragraph>Make your chatbot send text message to user.</Paragraph>
                    <PopupScrollHeight ref={scrollableDivRef}>
                        <ErrorBoundary
                            error={error}
                            render={(err: any, info: any) => {
                                return err && <Error.Delete onClick={() => setPageState(VIEW)} info={info} />
                            }}
                        >
                            {loading ? (
                                <div className="popup_loader">
                                    <Loader.PopupLoader show={loading} />
                                </div>
                            ) : (
                                <React.Fragment>
                                    <div className="">
                                        <div className="right_panel_radio">
                                            <div className="col-md-12 entity_radio">
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            className="radio-warning"
                                                            onChange={() => {
                                                                setSelectionPolicy('sequential')
                                                            }}
                                                            checked={selectionPolicy == 'sequential'}
                                                        />
                                                    </label>
                                                    <span>Display sequentially</span>
                                                </div>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            onChange={() => {
                                                                setSelectionPolicy('random')
                                                            }}
                                                            checked={selectionPolicy == 'random'}
                                                        />
                                                    </label>
                                                    <span>Display randomly</span>
                                                </div>
                                            </div>
                                        </div>

                                        {values.map((text: DraftJST, i: number) => (
                                            <TextResponseCard
                                                key={i}
                                                index={countSet(i + 1)}
                                                values={values}
                                                value={text}
                                                onchange={(newValue: DraftJST) =>{
                                                    scrollTo(
                                                      'bottom',
                                                      values.length - 1 === i
                                                    );
                                                    setValus(
                                                      values.map(
                                                        (
                                                          value: DraftJST,
                                                          index: number
                                                        ) =>
                                                          index == i
                                                            ? newValue
                                                            : value
                                                      )
                                                    );
                                                }
                                            }
                                                updated={updated}
                                                deleteMe={() => {
                                                    setValus(values.filter((_: DraftJST, index: number) => index != i))
                                                    setIsupdate(Math.random())
                                                }}
                                                isSomeOneOpen={alreadyOpen}
                                                heyImOpen={setAlreadyOpen}
                                            />
                                        ))}
                                        {values.filter(
                                            data =>
                                                !convertAsaSingleString(data.blocks as RawDraftContentBlock[]).replace(
                                                    /\s/g,
                                                    ''
                                                ).length
                                        ).length == 0 && values.length < 100 ? (
                                            <div className="send_message_addmore">
                                                <div
                                                    className="plus_add_more"
                                                    onClick={() => {
                                                        scrollTo('bottom')
                                                        setValus(
                                                            values.concat(
                                                                converterToEditor({
                                                                    type: ExpressionTypeE.TEXT,
                                                                    value: ''
                                                                })
                                                            )
                                                        )
                                                    }
                                                }
                                                >
                                                    <SmallButton primary>
                                                        <span>
                                                            <AddIcon />
                                                        </span>

                                                        <label>Add More</label>
                                                    </SmallButton>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <Button
                                    className="footer_button_fixed"
                                        // disable={
                                        //     values.filter(data => data.blocks[0].text !== '').length !== values.length ||
                                        //     alreadyOpen
                                        // }
                                        disable={
                                            values.filter(data => {
                                                // console.log(
                                                //     convertAsaSingleString(
                                                //         data.blocks as RawDraftContentBlock[]
                                                //     ).replace(/\s/g, ''),
                                                //     'convertAsaSingleString'
                                                // )
                                                return convertAsaSingleString(
                                                    data.blocks as RawDraftContentBlock[]
                                                ).replace(/\s/g, '').length
                                            }).length !== values.length
                                            || variableContext.isUrlOpen
                                        }
                                        onCancel={() => setPageState(VIEW)}
                                        onSave={handleSave}
                                    />
                                </React.Fragment>
                            )}
                        </ErrorBoundary>
                    </PopupScrollHeight>
                </PopupContainer>
            </PopupWrapper>
        </Wrapper>
    )
}

const TextResponseCard = ({
    value,
    values,
    index,
    deleteMe,
    onchange,
    isSomeOneOpen,
    heyImOpen,
    updated
}: {
    value: DraftJST
    values: DraftJST[]
    index: string
    deleteMe: Function
    onchange: Function
    isSomeOneOpen: boolean
    heyImOpen: Function
    updated: number
}) => {
    const [mode, setMode] = useState<'view' | 'edit'>('view')

    const [initValue, setInitValue] = useState<DraftJST>()

    const [draft, setDraft] = useState<DraftJST>(value)
    const [updatedNewValue, setUpdated] = useState(updated)

    // useEffect(() => {
    //     setInitValue(value)
    // }, [])

    useEffect(() => {
        setUpdated(updated)
    }, [updated])

    return (
        <div className="text_response">
            {/* {mode == 'view' ? ( */}
            <SaveMessageWrapper>
                <div className="plus_add_more">
                    {!isSomeOneOpen ? (
                        <div className="right_pop_icons">
                            {values.length > 1 ? (
                                <span className="send_message_close_icon" onClick={() => deleteMe()}>
                                    <CloseIcon />
                                </span>
                            ) : null}
                        </div>
                    ) : null}
                </div>
                <DraftInputWrapper>
                    <Editor
                        DraftMap={value}
                        setDraftMap={onchange}
                        focusContent={`Message ${index}`}
                        updated={updatedNewValue}
                        focus={
                            convertAsaSingleString(value.blocks as RawDraftContentBlock[]).replace(/\s/g, '').length
                                ? 'focus_input'
                                : ''
                        }
                        
                    />
           
                </DraftInputWrapper>
            </SaveMessageWrapper>
            {/* ) : (
                <SendmMessageWrapper>
                    <div className="right_pop_content">
                        <div className="textarea_dropdown">
                            <div className="send_msg_dialog">
                                <div className="right_pop_icons">
                                    <span
                                        onClick={() => {
                                            setMode('view')
                                            heyImOpen(false)
                                            onchange(draft)
                                        }}
                                    >
                                        <CheckIcon />
                                    </span>
                                    {values.length > 1 ? (
                                        <span
                                            onClick={() => {
                                                if (initValue && initValue.blocks[0].text === '') {
                                                    heyImOpen(false)
                                                    deleteMe()
                                                } else {
                                                    setMode('view')
                                                    heyImOpen(false)
                                                    onchange(initValue)
                                                    if (initValue) setDraft(initValue)
                                                }
                                            }}
                                        >
                                            <CrossIcon />
                                        </span>
                                    ) : null}
                                </div>
                                <DraftInputWrapper>
                                    <Editor
                                        DraftMap={draft}
                                        setDraftMap={setDraft}
                                        focusContent={`Message ${index}`}
                                        focus={
                                            convertAsaSingleString(draft.blocks as RawDraftContentBlock[]).replace(
                                                /\s/g,
                                                ''
                                            ).length
                                                ? 'focus_input'
                                                : ''
                                        }
                                    />
                                </DraftInputWrapper>
                            </div>
                        </div>
                    </div>
                </SendmMessageWrapper>
            )} */}
        </div>
    )
}

export default TextResponse
