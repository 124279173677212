/*eslint-disable*/
import React from 'react';

import { useFetchFromApi } from '@/common/hooks/usefetch-from-api';
import { MOCK_WIDGETV, WidegetResponse } from './MockFile';
import { getJson, widgetIntegrationURL } from '@/common/utils/api-utils';
import { Widget } from './Widget';

export function WidgetWrapper(props: any) {
  const workspacename = props.match.params.workspacename;
  // const mockValue = Promise.resolve(WidegetResponse);

  const apiToCall = () => 
  getJson(widgetIntegrationURL(workspacename, '/widget/list/'))

  const [state, setState, promiseRef] = useFetchFromApi(apiToCall);

  const retry = () => {
    const setStateDispatch = setState as React.Dispatch<any>;
    setStateDispatch(
      promiseRef.current
        ? promiseRef.current.then((response: any) => response.data)
        : null
    );
  };

  console.log(state);
  return <Widget state={state} retry={retry} {...props} />;
}
