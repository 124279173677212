/*eslint-disable*/
import React, { useState } from 'react'

import { VideoButtonB } from '@/Videos/Videos'
//ICONS IMPORT
import { AddIcon, CloseIcon, DeleteIcon, GroupIcon, ListviewIcon, SearchIcon } from '@/common/Icons/Icons'
//COMMON COMPONENTS IMPORT
import { Header as CommonHeader, HeaderChild } from '@/common/components/Header'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'

import { ActionT, GridView, ListView, PageViewsT, ViewTypeT } from '../kind'
//TYPES IMPORT
import { PageStateT } from './kind'

const buttonStyle = {
    transform: 'matrix(-1, 0, 0, -1, 0, 0)'
}

const pStyle = {
    fill: 'none',
    stroke: '#ffffff'
}

const Header = ({
    count,
    PageState,
    showModal,
    viewType,
    search,
    closeSearch,
    setDeleteMode,
    changeView,
    permissions,
    ...props
}: {
    count: Number
    changeView: Function
    PageState: PageStateT
    deselect: Function
    showModal: Function
    viewType: PageViewsT
    search: Function
    closeSearch: Function
    setDeleteMode: Function
    permissions: {
        write: boolean | null;
        delete: boolean | null;
    }
}) => {
    const [inputFocus, setInputFocus] = useState<boolean>(false)

    return (
        <CommonHeader>
            <HeaderChild type="first">
                <div className="indent_left">
                    <div className="indent_title">Intent</div>
                    <div className="indent_sub_title">Catalog ({count})</div>
                </div>

                <div className="indent_right">
                    {((PageState.action === ActionT.SELECT && PageState.items.length > 0) ||
                        (PageState.action === ActionT.MULTIPLE_DELETE && PageState.items.length > 0)) && (
                        <Button
                            primary
                            style={{ background: '#FFE5D3' }}
                            className="btn btn-primary btn_large"
                            data-testid="SELECTED-ITEMS"
                            onClick={() => props.deselect()}
                        >
                            <label>
                                <b>
                                    {PageState.items.length > 9
                                        ? PageState.items.length
                                        : PageState.items.length == 9
                                        ? '09'
                                        : `0${PageState.items.length}`}
                                </b>{' '}
                                Intents selected
                            </label>
                            <span>
                                <CloseIcon />
                            </span>
                        </Button>
                    )}
                    {PageState.action !== ActionT.SELECT &&
                        PageState.action !== ActionT.MULTIPLE_DELETE &&
                        PageState.action !== ActionT.NO_DATA_FOUND &&
                        !inputFocus && permissions.write && (
                            <Button
                                primary
                                className="btn btn-primary"
                                data-testid="CREATE-BUTTON"
                                onClick={() => showModal()}
                            >
                                <span className="header_button"> 
                                    <AddIcon />
                                </span>
                                <label>Create New</label>
                            </Button>
                        )}
                </div>
            </HeaderChild>
            <HeaderChild type="second">
                {PageState.action === ActionT.SELECT && permissions.delete && (
                    <button className="btn btn-primary" data-testid="DELETE-BUTTON" onClick={() => setDeleteMode(null)}>
                        <DeleteIcon />
                        <span>Delete</span>
                    </button>
                )}
                {PageState.action === ActionT.VIEW ? (
                    viewType.View === ViewTypeT.GridView ? (
                        <button
                            className="btn btn-primary"
                            data-testid="LIST-VIEW-BUTTON"
                            onClick={() => changeView(ListView)}
                        >
                            <ListviewIcon />
                            <span>List View</span>
                        </button>
                    ) : (
                        <button
                            className="btn btn-primary"
                            data-testid="CARD-VIEW-BUTTON"
                            onClick={() => changeView(GridView)}
                        >
                            <GroupIcon />
                            <span>Card View</span>
                        </button>
                    )
                ) : null}

                {!inputFocus && PageState.action !== ActionT.NO_DATA_FOUND ? (
                    <span className="search_icon_svg">
                        <input
                            type="search"
                            placeholder="Search"
                            data-testid="INPUT-SMALL-BOX"
                            value=""
                            onFocus={() => setInputFocus(true)}
                        />
                        <SearchIcon />
                    </span>
                ) : (
                    PageState.action !== ActionT.NO_DATA_FOUND && (
                        <span className="search_click">
                            <input
                                className="search_click"
                                type="search"
                                placeholder="Search"
                                data-testid="INPUT-BIG-BOX"
                                autoFocus={true}
                                onChange={e => search(e.target.value)}
                            />
                            <span
                                data-testid="BIG-INPUT-CLOSE-SPAN"
                                onClick={() => {
                                    closeSearch()
                                    setInputFocus(false)
                                }}
                            >
                                <CloseIcon />
                            </span>
                        </span>
                    )
                )}
                <VideoButtonB id={'CREATE_INTENT_WITH_EXAMPLES_HOW_IT_WORKS'} />
            </HeaderChild>
        </CommonHeader>
    )
}

export default Header
