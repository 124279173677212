import { CompositeDecorator, Editor, EditorState, Entity, Modifier, convertFromRaw, convertToRaw } from 'draft-js'
/*eslint-disable*/
import React from 'react'
import Tooltip from 'react-tooltip-lite'
import { debounce } from 'throttle-debounce'

import { FlowCanvasContextIcons } from '@/common/Icons/Icons'

import logo from '@/common/images/logo.png'
import { Environment, EnvironmentT } from '../Actions/EnvironmentBlock'

const TokenSpan = (props: any) => {
    const info: any = props.contentState.getEntityMap().get(props.entityKey).data
    console.log('props.children:::', props.children)

    return (
        <span className="buton_dialog_auto_hvr" data-offset-key={props.offsetkey}>
            <Tooltip
                className="target customTip _hover_tooltip"
                zIndex={10000}
                arrowSize={0}
                tagName="span"
                content={info.path.map((path: any, index: number, arr: any[]) => (
                    <React.Fragment key={`${index}`}>
                        <div>{path}</div>
                        {index + 1 != arr.length ? (
                            <div>
                                <FlowCanvasContextIcons.NextIcon />
                            </div>
                        ) : null}
                    </React.Fragment>
                ))}
                distance={5}
            >
                <button className="button_dialog">
                    <img src={info.icon} />
                    {props.children}
                </button>
            </Tooltip>
        </span>
    )
}

const getEntityStrategy = (contentBlock: any, callback: any, contentState: any) => {
    contentBlock.findEntityRanges((character: any) => {
        const entityKey = character.getEntity()
        if (entityKey === null) {
            return false
        }
        return contentState.getEntity(entityKey).getMutability() === 'IMMUTABLE'
    }, callback)
}

const decorator = new CompositeDecorator([
    {
        strategy: getEntityStrategy,
        component: TokenSpan
    }
])

export const DraftContent = (props: any) => {
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty())

    const [time, setTime] = React.useState<null | number>(null)

    const EnvironmentContext: EnvironmentT = React.useContext(Environment)

    const onFocus = () => {
        const datetime = Date.now()
        setTime(datetime)
        EnvironmentContext.setSelected(datetime)
        EnvironmentContext.assignExposed(true)
    }

    React.useEffect(() => {
        if (EnvironmentContext.collection != null && EnvironmentContext.selected === time && time != null) {
            const { what, where, info, icon } = EnvironmentContext.collection
            const meta = {
                value: what,
                visible: info,
                path: where,
                icon
            }
            InsertContextVariable(info, meta)
            EnvironmentContext.assignCollection(null)
            // setTime(null)
            // EnvironmentContext.setSelected(null)
        } else if (EnvironmentContext.information != null && EnvironmentContext.selected === time && time != null) {
            const { what, where, info, dataType } = EnvironmentContext.information
            const meta = {
                value: what,
                visible: info,
                path: where,
                icon: logo,
                dataType
            }
            InsertContextVariable(info, meta)
            EnvironmentContext.assignInformation(null)
            // setTime(null)
            //EnvironmentContext.setSelected(null)
        }
    }, [EnvironmentContext])

    React.useEffect(() => {
        if (EnvironmentContext.selected != time) {
            setTime(null)
        }
    }, [EnvironmentContext.selected])

    React.useEffect(() => {
        if (EnvironmentContext.exposed == false) {
            if (time != null) {
                setTime(null)
            }
        }
    }, [EnvironmentContext.exposed])

    React.useEffect(() => {
        setEditorState(EditorState.createWithContent(convertFromRaw(props.rawMeta), decorator))
    }, [])

    React.useEffect(() => {
        props.setRawMeta(convertToRaw(editorState.getCurrentContent()))
    }, [editorState])

    const InsertContextVariable = (
        value: string,
        meta: { value: string; visible: string; path: string[]; icon: string }
    ) => {
        const currentContent = editorState.getCurrentContent()
        const selection = editorState.getSelection()
        const entityKey = Entity.create('VARIABLE', 'IMMUTABLE', { ...meta })
        const textWithEntity = Modifier.insertText(currentContent, selection, value, undefined, entityKey)
        setEditorState(EditorState.push(editorState, textWithEntity, 'insert-characters'))
    }

    const onChange = (context: any) => {
        setEditorState(context)
    }

    const debouncedChange = debounce(0, false, onChange as any)
    return (
        <div
            className={
                time == null
                    ? 'dialog_input_edit_true dialog_context_input_edit_true'
                    : 'dialog_input_edit_true dialog_context_input_edit_true input_selected_dialog'
            }
            onClick={onFocus}
        >
            <Editor
                editorState={editorState}
                onChange={debouncedChange}
                handlePastedText={(text: string, html: string | undefined, editorState: any) => 'handled'}
            />
        </div>
    )
}

export const metaParser = (rawMeta: any) =>
    rawMeta.blocks.reduce((accumulator: any, value: any) => {
        const text = value.text
        const mixture = value.entityRanges
            ? value.entityRanges.reduce((acc: any, val: any) => {
                  return acc.concat({
                      start: val.offset,
                      end: val.offset + val.length,
                      value: rawMeta.entityMap[val.key].data.value
                  })
              }, [])
            : []
        let newText = ''
        mixture.reduce((acc: any, val: any, index: any, arr: any) => {
            if (acc == null) {
                newText = text.slice(0, val.start) + val.value
            } else {
                newText = newText + text.slice(acc.end, val.start) + val.value
            }

            if (index == arr.length - 1) {
                newText = newText + text.slice(val.end)
            }
            return newText == '' ? text : val
        }, null)
        return accumulator.concat(newText == '' ? text : newText)
    }, '')

export const SelectContextVariable = ({ rawMeta, setRawMeta }: any) => {
    const [input, setInput] = React.useState(false)

    const EnvironmentContext: EnvironmentT = React.useContext(Environment)

    React.useEffect(() => {
        if (EnvironmentContext.collection != null && input) {
            const { what, where, info, icon, dataType } = EnvironmentContext.collection
            const meta = {
                value: what,
                visible: info,
                path: where,
                icon,
                dataType
            }
            setRawMeta(meta)
            EnvironmentContext.assignCollection(null)
            setInput(false)
            EnvironmentContext.assignExposed(false)
        } else if (EnvironmentContext.information != null && input) {
            const { what, where, info, dataType } = EnvironmentContext.information
            const meta = {
                value: what,
                visible: info,
                path: where,
                icon: logo,
                dataType
            }
            setRawMeta(meta)
            EnvironmentContext.assignInformation(null)
            setInput(false)
            EnvironmentContext.assignExposed(false)
        }
    }, [EnvironmentContext])

    const onFocus = () => {
        setInput(true)
        EnvironmentContext.assignExposed(true)
    }

    return (
        <div className="onfocus_recognise_input" onClick={onFocus}>
            {rawMeta.visible ? (
                <span className="buton_dialog_auto_hvr">
                    <Tooltip
                        className="target customTip _hover_tooltip"
                        zIndex={10000}
                        arrowSize={0}
                        tagName="span"
                        content={rawMeta.path.map((path: any, index: number, arr: any[]) => (
                            <React.Fragment key={`${index}`}>
                                <div>{path}</div>
                                {index + 1 != arr.length ? (
                                    <div>
                                        <FlowCanvasContextIcons.NextIcon />
                                    </div>
                                ) : null}
                            </React.Fragment>
                        ))}
                        distance={5}
                    >
                        <button className="button_dialog">
                            <img src={rawMeta.icon} style={{ width: 18, marginRight: 5 }} />
                            {rawMeta.visible}
                        </button>
                    </Tooltip>
                </span>
            ) : null}
            <div className={rawMeta.visible ? 'button_auto_input' : 'button_auto_input_click'}>Select a collection</div>
        </div>
    )
}
