/*eslint-disable*/
import React from 'react'
import { AgentHandoffWorkspace } from '../AgentHandoff/types'

//TYPE IMPORTS
import { AssistantT, DumpAssistant } from '../Construction/kind'
import { DialogueT, IfNodeT } from '../Dialogue/kind'
import { EntityT } from '../Entity/kind'
import { IntentT } from '../Intent/kind'
import { LOADING, PageStateT } from './kind'

const STRING: string = ''
const FUNCTION = () => {}

export interface DialogueContextT {
    navigateHomePage: Function
    dialogueName: string
    dialogueNode: string
    workspaceName: string
    assistantID: string
    assistant: AssistantT

    intents: IntentT[]
    entities: EntityT[]
    setIntents: React.Dispatch<React.SetStateAction<IntentT[]>>

    PageState: PageStateT
    setPageState: React.Dispatch<React.SetStateAction<PageStateT>>

    fetchChild: Function
    fetchChild2?: Function
    fetchCallback: Function

    dialogueContext: any
    automationContext: any
    workspaceDescendents: DialogueT[]
    actionMixture: any[]
    rootDialogs: DialogueT[]
    isNull?: boolean
    ismarketplacebot?: string
    parentIfnode: IfNodeT | {}
    agents:AgentHandoffWorkspace[]
}

export const DialogueContext = React.createContext<DialogueContextT>({
    navigateHomePage: FUNCTION,
    dialogueName: STRING,
    dialogueNode: STRING,
    workspaceName: STRING,
    assistantID: STRING,
    assistant: DumpAssistant,

    intents: [],
    entities: [],
    setIntents: FUNCTION,

    PageState: LOADING,
    setPageState: FUNCTION,

    fetchChild: FUNCTION,
    fetchChild2: FUNCTION,
    fetchCallback: FUNCTION,

    dialogueContext: {},
    automationContext: {},
    workspaceDescendents: [],
    actionMixture: [],
    rootDialogs: [],
    isNull: false,
    parentIfnode: {},
    agents:[]
})

export const DescendantContext = React.createContext<{
    appendChild: string | null
    setAppendChild: Function
    addChildInLast: string | null
    setAddChildInLast: Function
    addChildInBetween: string | null
    setAddChildInBetween: Function

    CreateTextNode: Function
    CreateIfNode: Function
    CreateOptionNode: Function
    CreateQuestionNode: Function
    CreateAdaptiveNode: Function
    CreateAttachmentNode: Function
    JumpNode: Function
    CreateVariableNode: Function
    ExitNode: Function
    CreateSlotContainerNode: Function
    CreateAutomationNode: Function
    EditResponse: Function
    CreateAgentHandoffNode: Function
    PasteCopiedFunction:Function
}>({
    appendChild: null,
    setAppendChild: FUNCTION,
    addChildInLast: null,
    setAddChildInLast: FUNCTION,
    addChildInBetween: null,
    setAddChildInBetween: FUNCTION,
    CreateVariableNode: FUNCTION,
    CreateTextNode: FUNCTION,
    CreateIfNode: FUNCTION,
    CreateOptionNode: FUNCTION,
    CreateQuestionNode: FUNCTION,
    CreateAdaptiveNode: FUNCTION,
    JumpNode: FUNCTION,
    ExitNode: FUNCTION,
    CreateSlotContainerNode: FUNCTION,
    CreateAutomationNode: FUNCTION,
    CreateAttachmentNode: FUNCTION,
    CreateAgentHandoffNode: FUNCTION,
    EditResponse: FUNCTION,
    PasteCopiedFunction:FUNCTION
})

export interface ContextVariableT {
    type: 'context'
    variable: string
    path: string[]
    dummypath: string[]
    icon: string
    dataType: string
    collection?: any
}

export interface EntityVariableT {
    type: 'entity'
    name: string
    icon: string
    dummypath: string[]
    dataType: string
}

export type VariableT = ContextVariableT | EntityVariableT

export interface VariableContextT {
    selectedEditor: null | number // Endha editor open agirukku nu check kku
    setSelectedEditor: Function

    popupExposed: boolean // context popup open la irukka close ah irukka
    assignPopupExposed: Function

    variable: VariableT | null
    assignVariable: React.Dispatch<React.SetStateAction<VariableT | null>>

    displayStatusOfEntityListInVariable: 'SHOW' | 'HIDE'

    setDisplayStatusOfEntityListInVariable: React.Dispatch<React.SetStateAction<'SHOW' | 'HIDE'>>
    forAutomationVariables: 'collection' | 'variable' | 'input'
    setForAutomationVariables: Function
    isUrlOpen:boolean
    setUrlisOpen:Function
}

export const VariableContext = React.createContext<VariableContextT>({
    selectedEditor: null,
    setSelectedEditor: FUNCTION,

    popupExposed: false,
    assignPopupExposed: FUNCTION,

    variable: null,
    assignVariable: FUNCTION,

    displayStatusOfEntityListInVariable: 'SHOW',

    setDisplayStatusOfEntityListInVariable: FUNCTION,
    forAutomationVariables: 'variable',
    setForAutomationVariables: FUNCTION,
    isUrlOpen:false,
    setUrlisOpen:FUNCTION
})

export interface VariableContextForFAQT {
    isUrlOpen:boolean
    setUrlisOpen:Function
}

export const VariableContextForFAQ = React.createContext<VariableContextForFAQT>({
    isUrlOpen:false,
    setUrlisOpen:FUNCTION
})