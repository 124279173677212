import classNames from 'classnames';
/*eslint-disable*/
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { AssistantT } from '@/Assistant/Construction/kind';
import { getAssistants } from '@/Assistant/Mechanisms/action';
import { PlanT } from '@/DialogEditor/Canvas/Components/WelcomePageWithJourney';
import { NotificationPanel } from '@/Notification/Notification';
import { useNotifications } from '@/Notification/userNotifications';
import { defaultProfileData } from '@/Profile/types';
import { Workspaces } from '@/Workspace/workspace';
import {
  DialogIcon,
  EntityIcon,
  HelpDocIconV2,
  IntentIcon,
  NotificationIcon,
  RightArrowWithOutCircle,
  SubPanelIcons,
  SupportIcon,
  TourIcon,
  UserIconAnalyticsMenu,
} from '@/common/Icons/Icons';
import { useConditionalEffect } from '@/common/hooks/useConditionalEffect';
import useToggleOnClickWithOutsideClickHandled from '@/common/hooks/useToggleOnClickWithOutsideClickHandled';
import { defaultState } from '@/common/types/WorkspacesT';
import { sortAnArrayByDate } from '@/common/utils/common.utils';

import logo_img from '@/common/images/logo.png';
import { getJson, loginApiURL, stripeGetApi } from '@/common/utils/api-utils';
import { SideBarContext } from './SideBarContext';
import { isNotAutomate } from '@/common/utils/utils';
import {
  Linktext,
  NavLinkT,
  SideBarT,
  subMenu,
  getSideBarLinkTree,
} from './SideBarTypes';
import { TourSideBars } from './TourSideBar';

type Panels = 'Profile' | 'Notification' | 'None';

const ProfilePathstoShowSelected = ['profile', 'users', 'billing'];

const SupportPathstoShowSelected = ['support'];

const usePrevious = (value: any) => {
  const ref = React.useRef<any>();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export function SidebarComponent(props: any) {
  // console.log('props', props)
  const [links, setLinks] = useState<NavLinkT[]>(() => getSideBarLinkTree());

  const [selectedBot, setSelectedBot] = useState<string>('');

  const { setSubPanelOpen } = useContext(SideBarContext);

  const [selectedLinks, setSelectedLink] = useState<SideBarT>({
    selectedLink: '',
    selectedSublink: '',
    showSubpanel: false,
    previousLink: false,
  });

  const previousSideBar = usePrevious(selectedLinks) as SideBarT;

  const [subPanelClosedManually, setSubpanelValue] = useState<any>(undefined);

  useLayoutEffect(() => {
    const { defaultDisplayText, defaultSublink, showSubpanel } = (() => {
      const splittedPath = props.match.path.split('/');

      if (splittedPath.length > 0 && splittedPath[0] !== '*') {
        const defaultRoute = links.filter(
          (x) =>
            x.displayText.toLowerCase() ===
            (!splittedPath[3].includes('-')
              ? splittedPath[3].toLowerCase()
              : splittedPath[3].replace('-', ' ').toLowerCase())
        );
        if (defaultRoute.length > 0) {
          const subMenu = defaultRoute[0].submenu.filter((subMenu) => {
            const isAliasRoute = (sMenu: subMenu) =>
              sMenu.alias
                ? sMenu.alias.filter((alias) =>
                    props.match.url
                      .toLowerCase()
                      .includes('/' + alias.toLowerCase())
                  ).length > 0
                : sMenu.submenu &&
                  sMenu.submenu.filter((alias) => {
                    return props.match.url
                      .toLowerCase()
                      .indexOf('/' + alias.to.toLowerCase());
                  }).length > 0;
            return (
              props.match.url
                .toLowerCase()
                .includes('/' + subMenu.to.toLowerCase()) ||
              isAliasRoute(subMenu)
            );
          });

          return {
            defaultDisplayText: defaultRoute[0].displayText,
            defaultSublink: subMenu.length > 0 ? subMenu[0].displayText : '',
            showSubpanel:
              subPanelClosedManually === undefined
                ? subMenu.length > 0 && true
                : !subPanelClosedManually,
          };
        } else {
          const lastvalue = splittedPath.filter(
            (_path: any, i: number) => i + 1 === splittedPath.length
          );
          const lastBefore = splittedPath[splittedPath.length - 2];

          const isPartOfProfileMenu =
            ProfilePathstoShowSelected.indexOf(lastvalue[0]) >= 0 ||
            lastBefore == 'billing';

          // console.log('lastvalue', lastvalue, lastBefore, splittedPath)

          return {
            defaultDisplayText: isPartOfProfileMenu
              ? 'ProfilePaths'
              : SupportPathstoShowSelected.indexOf(lastvalue[0]) >= 0
              ? 'Support'
              : 'Dialog',
            defaultSublink: '',
            showSubpanel:
              [
                'dialogs',
                'intent',
                'logs',
                'entity',
                'agent-handoff',
                'settings',
                'summary',
              ].indexOf(lastvalue[0]) != -1
                ? true
                : false,
          };
        }
      }
      return {
        defaultDisplayText: 'Dialog',
        defaultSublink: '',
        showSubpanel: false,
      };
    })();

    const showingSubPanel =
      props.match.path.includes('welcome') ||
      props.match.path.includes('/marketplace/automation/') ||
      props.match.path.includes('/automation/detail/') ||
      props.match.path.includes('dialog/:assistantname/detail/:dialogue_node');

    setSelectedLink({
      selectedLink: defaultDisplayText,
      selectedSublink: defaultSublink,
      showSubpanel: showingSubPanel
        ? false
        : !subPanelClosedManually
        ? showSubpanel
        : false,
      previousLink: false,
    });

    // props.match.path.includes('/help/videos') && setPanel('HelpVideos');
  }, [props.match.params]);
  useEffect(() => {
    // console.log('selectedLinks', selectedLinks, previousSideBar)
    if (
      selectedLinks.selectedLink === 'Dialog' &&
      (previousSideBar.selectedSublink == '' ||
        previousSideBar.selectedSublink == 'Home')
    ) {
      // console.log(
      //     'selectedLinks ::: setting bot as ',
      //     props.match.params.assistantname ? props.match.params.assistantname : selectedBot
      // )
      setSelectedBot(
        props.match.params.assistantname
          ? props.match.params.assistantname
          : selectedBot
      );
    }
  }, [selectedLinks]);
  const workSpaceName = props.match.params.workspacename;

  const [profileData, setProfileData] = useState(defaultProfileData);
  const [plan, setPlan] = useState<PlanT | {}>({});

  const [Assistant, setAssistant] = useState<AssistantT[]>([]);

  const [SelectedAssistant, setSelectedAssistant] = useState<string>('');

  const [panel, setPanel] = useState<Panels>('None');

  const [state, setState] = useState(defaultState);

  const [showHelpTab, setShowHelpTab] = useState(false);

  var url = new URL(window.location as any);
  var c = url.searchParams.get('showtour');

  const [showTour, setTourState] = useState(c && c == 'true' ? true : false);

  let profileReference: any = useRef();
  let notificationRef: any = useRef();
  let sidePanelWrapperRef = useRef<HTMLDivElement | null>();
  //   let sidePanelDialogIndexRef = useRef<number>(0);
  const scrollToBottom = (index: number) => {
    if (sidePanelWrapperRef.current) {
      setTimeout(() => {
        if (index > 10) {
          //   console.log('DOING :: 1');
          sidePanelWrapperRef.current?.scrollTo({
            top: sidePanelWrapperRef.current?.scrollHeight,
            behavior: 'smooth',
          });
        } else {
          //   console.log('DOING :: 2');
          sidePanelWrapperRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }, 0);

      //   scrollTop(1000000);
      //   sidePanelWrapperRef.current?.scrollTo({
      //     top: sidePanelWrapperRef.current?.offsetTop,
      //     behavior: 'smooth',
      //   });
    }

    // sidePanelWrapperRef.current?.scrollIntoView({ behavior: 'smooth' })
  };
  let emailLiRef: any = useRef();

  // to use subpanel open value in routes
  useEffect(() => {
    setSubPanelOpen(selectedLinks.showSubpanel);
  }, [selectedLinks.showSubpanel]);

  useToggleOnClickWithOutsideClickHandled(
    profileReference,
    () => {
      // setPanel('None')
      // props.match.path.includes('/help/videos')
      //   ? setPanel('HelpVideos')
      //   : setPanel('None');

      if (!selectedLinks.showSubpanel) closeFlowCanvasLeftPanel(false);
      panel === 'Profile' &&
        typeof selectedLinks.previousLink !== 'boolean' &&
        setSelectedLink({
          ...selectedLinks,
          selectedLink: selectedLinks.previousLink,
        });
    },
    () => {
      setPanel(panel === 'Profile' ? 'None' : 'Profile');

      // setPanel(panel === 'Profile' ? 'None' : 'Profile')
      panel === 'Profile' &&
        typeof selectedLinks.previousLink !== 'boolean' &&
        setSelectedLink({
          ...selectedLinks,
          selectedLink: selectedLinks.previousLink,
        });
      closeFlowCanvasLeftPanel(false);
    },
    [notificationRef, emailLiRef]
  );

  const closeSidePanelWithSubPanelCheck = () => {
    // props.match.path.includes('/help/videos')
    //   ? setPanel('HelpVideos')
    //   : setPanel('None');
    setPanel('None');
    if (!selectedLinks.showSubpanel) closeFlowCanvasLeftPanel(false);
    console.log('panel', panel, selectedLinks.previousLink);
    if (
      panel === 'Notification' &&
      typeof selectedLinks.previousLink !== 'boolean'
    ) {
      setSelectedLink({
        ...selectedLinks,
        selectedLink: selectedLinks.previousLink,
      });
    }
  };

  useToggleOnClickWithOutsideClickHandled(
    notificationRef,
    closeSidePanelWithSubPanelCheck,
    () => {
      setPanel(panel === 'Notification' ? 'None' : 'Notification');
      closeFlowCanvasLeftPanel(panel === 'Notification' ? false : true);
      panel === 'Notification' &&
        typeof selectedLinks.previousLink !== 'boolean' &&
        setSelectedLink({
          ...selectedLinks,
          selectedLink: selectedLinks.previousLink,
        });
    },
    [profileReference, emailLiRef]
  );
  const [
    notifications,
    { markAllRead, markSeen, notificationClick, fetchNotification },
    notificationCount,
    hasMore,
  ] = useNotifications(panel !== 'Notification', workSpaceName);

  useConditionalEffect(markSeen, { panel }, (curr: any, prev: any) => {
    if (prev == undefined) {
      return true;
    }
    // console.log('run effect condition', curr, prev)
    if (prev.panel == 'Notification' && curr.panel != 'Notification') {
      return false;
    }
    return true;
  });

  useEffect(() => {
    if (panel !== 'None') {
      setSelectedLink({ ...selectedLinks, showSubpanel: false });
    }
  }, [panel]);

  useEffect(() => {
    callProfileApi();
    callWorkspacesApi();

    if (isNotAutomate()) {
      callAssistantApi();
    }
  }, []);

  // useEffect(() => {
  //     callAssistantApi()
  // }, [props.match.params])

  useEffect(() => {
    const IntentDetailHeaderClass: any = document.getElementsByClassName(
      'intent_header_background'
    );
    const SubmenuClass = document.getElementsByClassName('closed_sub_menu');
    if (SubmenuClass.length > 0 && IntentDetailHeaderClass.length > 0) {
    } else if (IntentDetailHeaderClass.length > 0) {
    }
  });

  async function callAssistantApi() {
    await getAssistants(workSpaceName)
      .then((res) => {
        setAssistant(res);
        const dialog: NavLinkT = {
          displayText: 'Dialog',
          icon: DialogIcon,
          to: 'dialog/homepage',
          active: true,
          submenu: [
            {
              displayText: 'Home',
              icon: SubPanelIcons.HomePage,
              to: 'dialog/homepage',
              active: true,
            },
            ...sortAnArrayByDate(res, 'created').map((assis) => ({
              displayText: assis.name,
              icon: DialogIcon,
              to: `assistant/${assis.name}/dialog/homepage`,
              active: true,
              submenu: [
                {
                  displayText: 'Summary',
                  icon: SubPanelIcons.SummaryIcon,
                  to: `dialog/${assis.name}/summary`,
                  active: true,
                },
                {
                  displayText: 'Dialogs',
                  icon: SubPanelIcons.BotIcon,
                  to: `dialog/${assis.name}/dialogs`,
                  active: true,
                },
                {
                  displayText: 'Intent',
                  icon: IntentIcon,
                  to: `dialog/${assis.name}/intent`,
                  active: true,
                },
                {
                  displayText: 'Entity',
                  icon: EntityIcon,
                  to: `dialog/${assis.name}/entity`,
                  active: true,
                },
                {
                  displayText: 'Logs',
                  icon: SubPanelIcons.logs,
                  to: `dialog/${assis.name}/logs`,
                  active: true,
                },
                {
                  displayText: 'Agent Handover',
                  icon: SubPanelIcons.AgentHandoff,
                  to: `dialog/${assis.name}/agent-handoff`,
                  active: true,
                },
                {
                  displayText: 'Settings',
                  icon: SubPanelIcons.settings,
                  to: `dialog/${assis.name}/settings`,
                  active: true,
                },
              ],
            })),
          ],
        };
        const rawLink = getSideBarLinkTree();

        setLinks([...rawLink.slice(0, 1), dialog, ...rawLink.slice(1)]);
      })
      .catch((e) => {
        //console.log(e)
      });
  }

  async function callProfileApi() {
    const apis = [
      getJson(loginApiURL('/user_info')),
      stripeGetApi(`${workSpaceName}/subscription/current/lite`),
    ];

    Promise.all(apis)
      .then(([user, plan]) => {
        setProfileData(user);
        setPlan(plan.output);
        props.setUserData(user);
      })
      .catch((e) => {
        //console.log(e)
      });
  }

  async function callWorkspacesApi() {
    let updatedState = { ...state };

    await getJson(loginApiURL('/accessible_workspaces'))
      .then((e) => {
        updatedState = {
          ...updatedState,
          workspaces: e,
          defaultWs: '',
          viewWorkspaces: e.length == 1,
        };
        setState(updatedState);
      })
      .catch((e) => {
        // console.log(e)
      });

    await getJson(loginApiURL('/get_default_workspace'))
      .then((e) => {
        setState({
          ...updatedState,
          defaultWs:
            e.default_workspaceId === null ? '' : e.default_workspaceId,
        });
      })
      .catch((e) => {
        // console.log(e)
      });
  }

  const activeClass = (linkText: Linktext) => {
    const classList =
      linkText !== selectedLinks.selectedLink || panel != 'None'
        ? ['item']
        : ['item', 'selected'];
    return classNames(classList);
  };

  const handleChange = (linkText: Linktext) => {
    setSelectedLink({
      ...selectedLinks,
      selectedLink: linkText,
      showSubpanel: true,
      previousLink: selectedLinks.selectedLink as any,
    });

    //to close workspace or notification popup
    if (panel !== 'None') {
      setPanel('None');
    }

    closeFlowCanvasLeftPanel(true);
  };

  function closeFlowCanvasLeftPanel(close: boolean) {
    const menu: any = document.querySelectorAll(
      '.automation_canvas .flex_side_menu'
    );
    const marketPlaceSideMneu: any = document.querySelectorAll(
      '.market_place_wrapper .flex_side_menu'
    );

    if (close && menu && menu.length > 0) {
      menu[0].style.display = 'none';
    } else if (menu && menu.length > 0) {
      menu[0].style.display = 'block';
    }

    if (close && marketPlaceSideMneu && marketPlaceSideMneu.length > 0) {
      marketPlaceSideMneu[0].style.display = 'none';
    } else if (marketPlaceSideMneu && marketPlaceSideMneu.length > 0) {
      marketPlaceSideMneu[0].style.display = 'block';
    }
  }

  function createTab(link: NavLinkT, i: number) {
    function hover(displayText: string) {
      switch (displayText) {
        case 'Dialog':
          return 'Chatbot';
        case 'Automation':
          return 'Automations';
        case 'Integration':
          return 'Omni-channel';
        case 'Train Assistant':
          return 'Training';
        default:
          return displayText;
      }
    }
    return (
      <div
        key={i}
        className={activeClass(link.displayText)}
        onClick={() => {
          if (
            link.displayText !== selectedLinks.selectedLink &&
            link.to == 'welcome' &&
            !props.match.path.includes('/welcome')
          ) {
            setSelectedLink({
              ...selectedLinks,
              selectedLink: link.displayText,
              showSubpanel: false,
              previousLink: selectedLinks.selectedLink as any,
            });

            //to close workspace or notification popup
            if (panel !== 'None') {
              setPanel('None');
            }

            closeFlowCanvasLeftPanel(true);

            props.history.push(`/workspace/${workSpaceName}/welcome`);
          } else if (link.to != 'welcome') {
            if (
              link.displayText === selectedLinks.selectedLink &&
              selectedLinks.showSubpanel
            )
              return;
            handleChange(link.displayText);
          }
        }}
      >
        <div className="img">
          <link.icon />
        </div>
        <span className="side_menu_hvr">{hover(link.displayText)}</span>
      </div>
    );
  }

  function bottomBar() {
    return (
      <div className="item-group bottom_bar_sidemenu">
        <div
          className={
            selectedLinks.selectedLink === 'Support' && panel === 'None'
              ? 'item support_selected'
              : 'item'
          }
          onClick={() => {
            props.history.push(`/workspace/${workSpaceName}/support`);
          }}
        >
          <div className="img">
            <SupportIcon />
          </div>
          <span className="side_menu_hvr">Support</span>
        </div>
        {/* <a>
          <div
            className={
              panel == 'HelpVideos' ? 'item notification_selected' : 'item'
            }
            onClick={() => {
              setPanel('HelpVideos');
              props.history.push(`/workspace/${workSpaceName}/help/videos`);
            }}
          >
            <div className="img ">
              <TourIcon />
            </div>
            <span className="side_menu_hvr">Help Videos</span>
          </div>
        </a> */}
        <a
          href="https://help.workativ.com/kb/workativ-automate/"
          target="_blank"
        >
          <div className={'item'}>
            <div className="img">
              <HelpDocIconV2 />
            </div>
            <span className="side_menu_hvr">Help Articles</span>
          </div>
        </a>
        {/* 
                <div className={'item help_button_box'}>
                    <div className="img" onClick={() => setShowHelpTab(true)}>
                        <HelpIconMenu />
                    </div>
                    {showHelpTab && (
                        <Downshift isOpen={true} onOuterClick={() => setShowHelpTab(false)}>
                            {() => (
                                <div className="sidebar_help_button_box">
                                    <a
                                        className="sidebar_help_button"
                                        onClick={() => props.history.push(`/workspace/${workSpaceName}/help/videos`)}
                                    >
                                        <VideoIcon />
                                        <p>Help Videos</p>
                                    </a>
                                    <a
                                        href="https://help.workativ.com/"
                                        target="_blank"
                                        className="sidebar_help_button"
                                    >
                                        <HelpDocIcon />
                                        <p>Help Document</p>
                                    </a>
                                </div>
                            )}
                        </Downshift>
                    )}
                    {!showHelpTab && <span className="side_menu_hvr">Help</span>}
                </div> */}
        <div
          className={
            panel === 'Notification' ? 'item notification_selected' : 'item'
          }
          ref={notificationRef}
        >
          <div className="img">
            <NotificationIcon />
            {notificationCount > 0 ? (
              <div className="count_notification">
                <span className="count_not_number"></span>
              </div>
            ) : null}
          </div>
          <span className="side_menu_hvr">Notifications</span>
        </div>
        <div
          className={
            selectedLinks.selectedLink === 'ProfilePaths' || panel === 'Profile'
              ? 'item profile_selected'
              : 'item'
          }
          ref={profileReference}
        >
          <div className="img ">
            <div className="img_width">
              {!profileData.image ? (
                <span>
                  <UserIconAnalyticsMenu />
                </span>
              ) : (
                <img src={profileData.image} alt="user" />
              )}
            </div>
          </div>
          <span className="side_menu_hvr">Profile</span>
        </div>
      </div>
    );
  }

  const showSelected = (subLink: subMenu) => {
    const splittedPath = props.match.path.split('/');
    if (subLink.to === 'dialog/homepage') {
      return props.match.path === '/workspace/:workspacename/dialog/homepage';
    } else if (subLink.to.includes('dialog/homepage')) {
      return props.match.params.assistantname === subLink.displayText;
    } else if (subLink.to.includes('dialog/assistant')) {
      return props.match.url.includes('dialog/assistant');
    } else if (subLink.to.includes('dialog/settings')) {
      return props.match.url.includes('dialog/settings');
    }
    return (
      selectedLinks.selectedSublink === subLink.displayText &&
      selectedLinks.selectedLink.toLowerCase() ===
        (!splittedPath[3].includes('-')
          ? splittedPath[3].toLowerCase()
          : splittedPath[3].replace('-', ' ').toLowerCase())
    );
  };

  const showBotSelected = (subLink: subMenu) => {
    return props.match.url.includes(subLink.to);
  };

  const filterArrary = (links: NavLinkT[], selectedLink: string) => {
    const currentRoute = links.filter((x) => {
      return x.displayText === selectedLinks.selectedLink;
    });
    return currentRoute;
  };

  const menuHeader = (selectedLink: string) => {
    switch (selectedLink) {
      case 'Dialog':
        return 'Chatbot Builder';
      case 'Automation':
        return 'Workflow Builder';
      case 'Integration':
        return 'Omni-channel';
      case 'Train Assistant':
        return 'Training';
      default:
        return selectedLink;
    }
  };

  const filteredSubpanelArray = filterArrary(
    links,
    selectedLinks.selectedLink
  )[0];

  // disable show tour for automate Wauto-768
  // if (showTour)
  //   return (
  //     <TourSideBars
  //       closeTour={() => {
  //         setTourState(false);
  //         setPanel('None');
  //       }}
  //       {...props}
  //     />
  //   );

  return (
    <React.Fragment>
      <div className="flex_menu">
        <div className="sidepanel_menu_left">
          <div className="sidepanel_menu_left_logo">
            <img src={logo_img} />
          </div>
          <nav>
            <div className="sidepeanel_settings">
              <div className="item-group">
                {links.map((link, index) => createTab(link, index))}
              </div>
              {bottomBar()}
            </div>
          </nav>
        </div>
        {panel == 'Profile' && (
          <div>
            <Workspaces
              {...props}
              closePopup={() => {
                // props.match.path.includes('/help/videos')
                //   ? setPanel('HelpVideos')
                //   : setPanel('None');

                setPanel('None');
              }}
              selectedLink={selectedLinks.selectedLink}
              state={state}
              setState={setState}
              profileData={profileData}
              emailLiRef={emailLiRef}
              plan={plan}
            />
          </div>
        )}

        <div>
          <NotificationPanel
            show={panel == 'Notification'}
            notifications={notifications}
            markAllRead={markAllRead}
            notificationClick={notificationClick}
            fetchNotification={() => fetchNotification('scroll')}
            closePopup={closeSidePanelWithSubPanelCheck}
            hasMore={hasMore}
            workspace={props.match.params.workspacename}
          />
        </div>
      </div>
      {links &&
      selectedLinks.selectedLink !== 'ProfilePaths' &&
      selectedLinks.selectedLink !== 'Support' &&
      panel != 'Notification' &&
      selectedLinks.showSubpanel ? (
        <div className="flex_side_menu">
          <div className="sidepanel_menu_right">
            <div className="sidepanel_menu_right_head">
              <h4>{menuHeader(selectedLinks.selectedLink)}</h4>
              <span
                className="sub_panel_toggle_btn"
                onClick={() => {
                  setSelectedLink({
                    ...selectedLinks,
                    showSubpanel: !selectedLinks.showSubpanel,
                    selectedLink: selectedLinks.previousLink
                      ? (selectedLinks.previousLink as any)
                      : selectedLinks.selectedLink,
                  });

                  setSubpanelValue(true);

                  closeFlowCanvasLeftPanel(false);
                }}
              >
                <RightArrowWithOutCircle />
              </span>
            </div>
            <div
              className="sidepanel_menu_right_menu"
              ref={sidePanelWrapperRef}
            >
              {filteredSubpanelArray &&
                filteredSubpanelArray.submenu.map(
                  (subLink: subMenu, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <div
                          className={
                            showSelected(subLink)
                              ? 'side_menu_li side_menu_li_active'
                              : 'side_menu_li'
                          }
                          key={index}
                          onClick={() => {
                            if (selectedLinks.selectedLink === 'Dialog') {
                              setSelectedBot((link) =>
                                link === subLink.displayText
                                  ? ''
                                  : subLink.displayText
                              );
                            }
                            panel !== 'None' &&
                            typeof selectedLinks.previousLink !== 'boolean'
                              ? setSelectedLink({
                                  ...selectedLinks,
                                  selectedLink: selectedLinks.previousLink,
                                  selectedSublink: subLink.displayText,
                                })
                              : setSelectedLink({
                                  ...selectedLinks,
                                  selectedSublink: subLink.displayText,
                                });
                            if (subLink.submenu) return;
                            console.log('DOING ::: SUBLINK', subLink);
                            props.history.push(
                              `/workspace/${props.match.params.workspacename}/${subLink.to}`
                            );
                          }}
                        >
                          <subLink.icon />
                          <p>{subLink.displayText}</p>
                          {selectedLinks.selectedLink === 'Dialog' ? (
                            index === 0 ||
                            index ===
                              filteredSubpanelArray.submenu
                                .length ? null : selectedBot ===
                              subLink.displayText ? (
                              <span>
                                <RightArrowWithOutCircle />
                              </span>
                            ) : (
                              <span
                                style={{
                                  transform: 'rotate(-90deg)',
                                }}
                              >
                                <RightArrowWithOutCircle />
                              </span>
                            )
                          ) : null}
                        </div>
                        {subLink.submenu &&
                          selectedBot === subLink.displayText &&
                          subLink.submenu.map(
                            (subLinkchild: subMenu, index: number) => (
                              <div
                                className={
                                  showBotSelected(subLinkchild)
                                    ? 'side_menu_li side_menu_li_center side_menu_li_active'
                                    : 'side_menu_li side_menu_li_center'
                                }
                                key={index}
                                onClick={() => {
                                  panel !== 'None' &&
                                  typeof selectedLinks.previousLink !==
                                    'boolean'
                                    ? setSelectedLink({
                                        ...selectedLinks,
                                        selectedLink:
                                          selectedLinks.previousLink,
                                        selectedSublink:
                                          subLinkchild.displayText,
                                      })
                                    : setSelectedLink({
                                        ...selectedLinks,
                                        selectedSublink:
                                          subLinkchild.displayText,
                                      });
                                  props.history.push(
                                    `/workspace/${props.match.params.workspacename}/${subLinkchild.to}`
                                  );
                                }}
                              >
                                <subLinkchild.icon />
                                <p>{subLinkchild.displayText}</p>
                              </div>
                            )
                          )}
                      </React.Fragment>
                    );
                  }
                )}
            </div>
          </div>
        </div>
      ) : (
        selectedLinks.selectedLink !== 'ProfilePaths' &&
        selectedLinks.selectedLink !== 'Support' &&
        panel != 'Notification' &&
        !props.match.path.includes('/welcome') &&
        !props.match.path.includes('/automation/detail/') &&
        !props.match.path.includes('/marketplace/automation/') &&
        !props.match.path.includes('/automation/market/detail/') &&
        props.match.path !==
          '/workspace/:workspacename/dialog/:assistantname/detail/:dialogue_node' && (
          <div className="flex_side_menu closed_sub_menu">
            <div className="sidepanel_menu_right">
              <div className="sidepanel_menu_right_head">
                <span
                  className="sub_panel_toggle_btn closed_arrow"
                  onClick={() => {
                    setSelectedLink({
                      ...selectedLinks,
                      showSubpanel: !selectedLinks.showSubpanel,
                    });
                    setSubpanelValue(false);
                  }}
                >
                  <RightArrowWithOutCircle />
                </span>
              </div>
            </div>
          </div>
        )
      )}
    </React.Fragment>
  );
}

export const Sidebar = SidebarComponent;
