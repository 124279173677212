import * as R from 'ramda'

//TYPE IMPORTS
import {
    AdaptiveT,
    AgentHandoffT,
    AttachmentT,
    AutomationT,
    DialogueT,
    IfNodeT,
    OptionT,
    QuestionT,
    ResponseNodeE,
    SlotContainerT,
    TextNodeT,
    VariableValueT
} from '../../Dialogue/kind'
import { ExpressionT, ExpressionTypeE, converterToEditor } from '../../Editor/Action'
import { DialogueContextT } from '../Context'
import { sortBySibling } from '../action'
import { ActionT } from '../kind'
import { copyIfNode } from '@/Assistant/Mechanisms/action'

export const CreateAttachmentNode = (context: DialogueContextT, previousSibling: DialogueT, parent: DialogueT) => {
    const proof: AttachmentT = {
        id: '',
        type: 'file_upload',
        parent: parent.uuid,

        prompt: {
            type: ExpressionTypeE.TEXT,
            value: ''
        },
        category: 'Image',
        variable: '',
        previous_sibling: previousSibling ? previousSibling.id : null,
        file_exit_conditions: [],
        allow_digression: false,
        error_message: {
            type: ExpressionTypeE.TEXT,
            value: ''
        }
    }

    context.setPageState({
        action: ActionT.ATTACH_RESPONSE,
        data: proof
    })
}

export const CreateAdaptiveNode = (context: DialogueContextT, previousSibling: DialogueT, parent: DialogueT) => {
    const proof: AdaptiveT = {
        id: '',
        type: 'adaptive_card',
        title: {
            type: ExpressionTypeE.TEXT,
            value: ''
        },
        parent: parent.uuid,
        previous_sibling: previousSibling ? previousSibling.id : null,
        image: null,
        description: { blocks: [], entityMap: {} },
        fields: [
            {
                label: {
                    type: 'text',
                    value: ''
                },
                value: { blocks: [], entityMap: {} }
            }
        ],
        action_buttons: [
            {
                label: {
                    type: 'text',
                    value: ''
                },
                value: { blocks: [], entityMap: {} }
            }
        ],
        collection: { path: null }
    }

    context.setPageState({
        action: ActionT.ADAPTIVE_RESPONSE,
        data: proof
    })
}

export const CreateAgentHandoffNode = (context: DialogueContextT, previousSibling: DialogueT, parent: DialogueT) => {
    const proof: AgentHandoffT = {
        id: '',
        type: 'agent_handoff',
        parent: parent.uuid,
        previous_sibling: previousSibling ? previousSibling.id : null,
        agent_id: '',
        platform: '',
        agent_message:{ type:ExpressionTypeE.TEXT, value:""}
    }

    context.setPageState({
        action: ActionT.AGENT_HANDOFF_RESPONSE,
        data: proof
    })
}

export const PasteCopiedFunction = async (context: DialogueContextT, dialogue: ResponseNodeE,previousSibling: ResponseNodeE, parent: IfNodeT, setCopiedData:Function) => {
    const {fetchCallback} = context
                const req = {
                              node_id: dialogue.id,
                              previous_sibling_id: previousSibling?previousSibling.id:null,
                              parent: parent.uuid
                          } as {
                            node_id: string;
                            previous_sibling_id: null;
                            parent: string;
                        } | {
                            node_id: string;
                            previous_sibling_id: string;
                            parent: null;
                        }

                console.log(req, 'isDefaultNode')

    await copyIfNode(context.workspaceName, context.assistantID, req,"copy_res_node")
    setCopiedData(null)
    fetchCallback(() => {})


}

export const EditDialog = (context: DialogueContextT, proof: ResponseNodeE) => {
    switch (proof.type) {
        case 'text':
            context.setPageState({
                action: ActionT.TEXT_RESPONSE,
                data: proof
            })
            return null
        case 'option':
            context.setPageState({
                action: ActionT.OPTIONS_RESPONSE,
                data: proof
            })
            return null
        case 'adaptive_card':
            // console.log(
            //     (Object.values((context.automationContext as any)[(context.parentIfnode as IfNodeT).id]) as any)[0],
            //     context.actionMixture[
            //         (Object.values((context.automationContext as any)[(context.parentIfnode as IfNodeT).id]) as any)[0]
            //             .custom_json.children[0].children[0].value.meta_data.action_id
            //     ],
            //     context.actionMixture[
            //         (Object.values((context.automationContext as any)[(context.parentIfnode as IfNodeT).id]) as any)[0]
            //             .custom_json.children[0].children[0].value.meta_data.action_id
            //     ].output.properties[R.last(collection.path) as any],
            //     R.last(collection.path) as any,
            //     'console.log'
            // )
            const reduceChilds = (arr: IfNodeT[]): any => {
                return arr.reduce((accum: any, val) => {
                    const result = (val as IfNodeT).responses.filter((e: any) => {
                        return (
                            e.output_variable && e.output_variable == (proof.collection as { path: string[] }).path[0]
                        )
                    })
                    if (result.length && !accum) {
                        return result[0]
                    } else if (context.fetchChild(val).length > 0 && !accum) {
                        return reduceChilds(context.fetchChild(val))
                    }
                    return accum
                }, null)
            }
            const app = proof.collection
                ? reduceChilds(
                      sortBySibling(
                          context.workspaceDescendents.filter(
                              dialog => dialog.type === 'faq_node' || dialog.parent === null
                          )
                      ).filter(w => w.type == 'if_node') as IfNodeT[]
                  )
                : undefined
            let selectedAction, selectedCollection

            const appId =
                proof.collection && app && (context.automationContext as any)[(app as any).parent]
                    ? Object.keys((context.automationContext as any)[(app as any).parent])[0]
                    : undefined
            const selectedApp =
                proof.collection && app
                    ? (context.automationContext as any)[(app as any).parent]
                        ? (Object.values((context.automationContext as any)[(app as any).parent]) as any)[0]
                        : Object.values(context.automationContext).reduce((acc, v: any) => {
                              const auto = v[(app as any).id]
                              if (auto) {
                                  return auto
                              }
                              return acc
                          }, null)
                    : undefined
            if (selectedApp) {
                selectedAction =
                    context.actionMixture[selectedApp.custom_json.children[0].children[0].value.meta_data.action_id]
                selectedCollection = selectedAction.output.properties[R.last((proof.collection as any).path) as any]
            }
            context.setPageState({
                action: ActionT.ADAPTIVE_RESPONSE,
                data: {
                    ...proof,
                    action_buttons: proof.action_buttons.map(b => ({
                        label: b.title,
                        value: converterToEditor(b.value as ExpressionT, context, context.dialogueNode)
                    })),
                    fields: proof.fields.map(b => ({
                        label: b.title,
                        value: converterToEditor(b.value as ExpressionT, context, context.dialogueNode)
                    })),
                    description: converterToEditor(proof.description, context, context.dialogueNode),
                    image: proof.image,
                    collection:
                        proof.collection && selectedApp ? selectedCollection?(proof.collection as { path: string[] }):{path:[]} : { path: null },
                    // collection: collection ? (collection as { path: string[] }) : { path: null },
                    collectionData:
                        selectedApp && selectedCollection
                            ? {
                                  values: { name: selectedCollection.title, value: selectedCollection },
                                  app: {
                                      ...selectedAction,
                                      appName: selectedApp.name,
                                      appId: appId,
                                      action: selectedAction.name
                                  }
                              }
                            : {
                                  values: {
                                      name: '',
                                      value: {
                                          $id: '',
                                          type: 'collection',
                                          item: {
                                              type: 'object',
                                              properties: {}
                                          },
                                          title: '',
                                          properties: {}
                                      }
                                  },
                                  app: {}
                              }
                }
            })
            return null
        case 'file_upload':
            context.setPageState({
                action: ActionT.ATTACH_RESPONSE,
                data: proof as AttachmentT
            })
            return null

        case 'ask_a_question':
            context.setPageState({
                action: ActionT.QUESTION_RESPONSE,
                data: proof as QuestionT
            })
            return null
        case 'slot_container':
            context.setPageState({
                action: ActionT.SLOT_RESPONSE,
                data: proof as SlotContainerT
            })
            return null
        case 'automation':
            context.setPageState({
                action: ActionT.FLOW_DIALOGUE,
                data: proof as AutomationT
            })
            return null
        case 'agent_handoff':
            context.setPageState({
                action: ActionT.AGENT_HANDOFF_RESPONSE,
                data: proof as AgentHandoffT
            })
            return null
        case 'context_variable':
            context.setPageState({
                action: ActionT.VARIABLE_RESPONSE,
                data: proof
            })
            return null

        default:
            console.error(`Sorry, we are find this type of node :(`)
            return null
    }
}

export const CreateTextNode = (context: DialogueContextT, previousSibling: ResponseNodeE, parent: DialogueT) => {
    const proof: TextNodeT = {
        id: '',
        type: 'text',
        selection_policy: 'sequential',
        values: [
            {
                type: ExpressionTypeE.TEXT,
                value: ''
            }
        ],
        previous_sibling: previousSibling ? previousSibling.id : null,
        parent: parent.uuid
    }

    context.setPageState({
        action: ActionT.TEXT_RESPONSE,
        data: proof
    })
}

export const CreateIfNode = (context: DialogueContextT, previousSibling: DialogueT, parent: DialogueT) => {
    const proof: IfNodeT = {
        type: 'if_node',
        id: '',
        uuid: '',
        description: '',
        title: '',
        conditions: {},
        responses: [],
        parent: parent.uuid,
        previous_sibling: previousSibling ? previousSibling.uuid : null,
        next_step: 'wait_for_user_input',
        created: '',
        updated: '',
        allow_digression: false,
        allow_return_from_here: false,
        allow_digression_into: false
    }

    context.setPageState({
        action: ActionT.IF_DIALOGUE,
        data: proof
    })
}

export const CreateVariableNode = (context: DialogueContextT, previousSibling: DialogueT, parent: DialogueT) => {
    const proof: VariableValueT = {
        id: '',
        type: 'context_variable',
        parent: parent.uuid,
        previous_sibling: previousSibling ? previousSibling.id : null,
        variables: []
    }

    context.setPageState({
        action: ActionT.VARIABLE_RESPONSE,
        data: proof
    })
}

export const CreateOptionNode = (context: DialogueContextT, previousSibling: DialogueT, parent: DialogueT) => {
    // console.log('parent::::', parent)
    const proof: OptionT = {
        id: '',
        type: 'option',
        prompt_message: {
            type: ExpressionTypeE.TEXT,
            value: ''
        },
        parent: parent.uuid,
        previous_sibling: previousSibling ? previousSibling.id : null,
        options: [
            {
                label: {
                    type: 'text',
                    value: ''
                },
                value: {
                    type: ExpressionTypeE.TEXT,
                    value: ''
                }
            }
        ]
    }

    context.setPageState({
        action: ActionT.OPTIONS_RESPONSE,
        data: proof
    })
}

export const CreateQuestionNode = (context: DialogueContextT, previousSibling: DialogueT, parent: DialogueT) => {
    const proof: QuestionT = {
        id: '',
        type: 'ask_a_question',

        parent: parent.uuid,
        previous_sibling: previousSibling ? previousSibling.id : null,
        options: [],
        question: {
            type: ExpressionTypeE.TEXT,
            value: ''
        },
        mask_input: false,
        variable: ''
    }

    context.setPageState({
        action: ActionT.QUESTION_RESPONSE,
        data: proof
    })
}

export const CreateSlotContainerNode = (context: DialogueContextT, previousSibling: DialogueT, parent: DialogueT) => {
    const proof: SlotContainerT = {
        id: '',
        type: 'slot_container',

        parent: parent.uuid,
        previous_sibling: previousSibling ? previousSibling.id : null,
        slots: [
            {
                allow_digression: false,
                condition: {
                    type: 'EntityExpression',
                    entity: ''
                },
                save_as: 'literal',
                variable: '',
                prompt: '',
                slot_exit_conditions: [],
                supress_prompt_if_variable_not_empty: false
            }
        ]
    }

    context.setPageState({
        action: ActionT.SLOT_RESPONSE,
        data: proof
    })
}

export const CreateAutomationNode = (context: DialogueContextT, previousSibling: DialogueT, parent: DialogueT) => {
    const proof: AutomationT = {
        id: '',
        type: 'automation',
        parent: parent.uuid,
        previous_sibling: previousSibling ? previousSibling.id : null,
        name: '',
        automation_name: '',
        automation: '',
        input: {},
        output_variable: '',
        automation_type: 'approval'
    }

    context.setPageState({
        action: ActionT.FLOW_DIALOGUE,
        data: proof
    })
}
