/*eslint-disable*/
import React, { useContext, useState } from 'react'

import { AddIcon, MinusIcon } from '@/common/Icons/Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { Loader } from '@/common/components/Loader'
import lazyGA from '@/common/google-analytics/tracking'

import { createOptionalResponseDialog, createRecogniseDialog, updateDailouge } from '../../Actions/AsyncOperations'
import { PageContext } from '../../Actions/ReactContext'
import { OptionsResponseDialogPT, PageView } from '../../Types/PageT'
import {
    AskQueryDialogT,
    DialogT,
    JumpToSelectorT,
    OptionsResponseDialogT,
    OptionsT,
    RecognizeDialogT,
    SlotContainerDialogT,
    TextResponseDialogT
} from '../../Types/WatsonT'
import { Button } from '../Common'
import { DraftContent, metaParser } from '../DraftContent'

export const RespondWithOptions = () => {
    const [loader, setLoader] = useState<boolean>(false)

    const [error, setError] = useState<any>({ error: null, info: null })

    const { workspace, setPageState, PageState, fetchCallback } = useContext(PageContext)

    const { input } = PageState as OptionsResponseDialogPT

    const [titleMeta, setTitleMeta] = useState(input.metadata.RICH_TEXT)

    const [needChildNode, setNeedChildNode] = useState<boolean>(input.dialog_node == '')

    const [options, setOptions] = useState<OptionsT[]>(
        input.options.length ? input.options : [{ label: '', value: '' }]
    )

    const save = async () => {
        try {
            //show Loading
            setLoader(true)

            const { input, previousSibling, nextSibling, parent } = PageState as OptionsResponseDialogPT
            const title = metaParser(titleMeta)
            if (input.dialog_node == '') {
                const Create_response: OptionsResponseDialogT = await createOptionalResponseDialog(workspace, {
                    ...input,
                    option_title: title,
                    options: options,
                    metadata: { ...input.metadata, RICH_TEXT: titleMeta }
                })
                const response: OptionsResponseDialogT = await updateDailouge(workspace, {
                    ...Create_response,
                    metadata: { ...input.metadata, RICH_TEXT: titleMeta }
                } as OptionsResponseDialogT)

                if (previousSibling) {
                    await updateDailouge(workspace, {
                        ...previousSibling,
                        jump_to: {
                            dialog_node: response.dialog_node,
                            selector: JumpToSelectorT.body
                        },
                        metadata: { ...previousSibling.metadata, hide_jump_to: true }
                    } as TextResponseDialogT | AskQueryDialogT | SlotContainerDialogT)
                } else if (parent) {
                    await updateDailouge(workspace, {
                        ...parent,
                        jump_to: {
                            dialog_node: response.dialog_node,
                            selector: JumpToSelectorT.body
                        },
                        metadata: { ...parent.metadata, hide_jump_to: true }
                    } as RecognizeDialogT)
                }
                if (needChildNode) {
                    let previous_sibling = response as DialogT
                    for (let i = 0; i < options.length; i++) {
                        const result: DialogT = await createRecogniseDialog(workspace, {
                            type: 'if',
                            conditions: '',
                            title: options[i].label,
                            parent: parent.dialog_node,
                            previous_sibling: previous_sibling ? previous_sibling.dialog_node : null
                        } as RecognizeDialogT)

                        previous_sibling = result

                        if (i == 0) {
                            await updateDailouge(workspace, {
                                ...response,
                                jump_to: {
                                    dialog_node: result.dialog_node,
                                    selector: JumpToSelectorT.user_input
                                },
                                metadata: { ...response.metadata, hide_jump_to: true }
                            } as OptionsResponseDialogT)
                        }
                    }
                } else if (nextSibling) {
                    await updateDailouge(workspace, {
                        ...response,
                        jump_to: {
                            dialog_node: nextSibling.dialog_node,
                            selector: JumpToSelectorT.user_input
                        },
                        metadata: { ...response.metadata, hide_jump_to: true }
                    } as OptionsResponseDialogT)
                }
                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Options Node Created'
                })
            } else {
                await updateDailouge(workspace, {
                    ...input,
                    option_title: title,
                    options: options,
                    metadata: { ...input.metadata, RICH_TEXT: titleMeta }
                })

                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Options Dialog Updated'
                })
            }

            fetchCallback(() => setLoader(false))
        } catch (err) {
            setError({
                error: true,
                info: `Server error!<br />Please try again after sometime.`
            })
            setLoader(false)
        }
    }

    const countSet = (count: number) => (count < 10 ? '0' + count.toString() : count)

    const valueHandleChange = (value: string, index: number) =>
        setOptions(options =>
            options.map((option, count) =>
                count == index
                    ? {
                          ...option,
                          value: value
                      }
                    : option
            )
        )
    const lableHandleChange = (value: string, index: number) =>
        setOptions(options =>
            options.map((option, count) =>
                count == index
                    ? {
                          ...option,
                          label: value
                      }
                    : option
            )
        )

    return (
        <div className="right-panel options_editor options_popup_show">
            <ErrorBoundary
                error={error}
                render={(err: any, info: any) => {
                    return err && <Error.Delete onClick={() => setPageState(PageView)} info={info} />
                }}
            >
                <h5>Options</h5>
                <p>Make your chatbot display clickable options to the user </p>
                {loader ? (
                    <div className="popup_loader">
                        <Loader.PopupLoader show={loader} />
                    </div>
                ) : (
                    <React.Fragment>
                        {input.dialog_node == '' ? (
                            <div className="col-lg-md-12 col-md-12 col-xs-12 popup_show_hide">
                                <button className="btn btn-primary intent_examples_switch show_hide">
                                    <div className="switch_box box_1">
                                        <input
                                            type="checkbox"
                                            className="switch_1"
                                            checked={needChildNode}
                                            onChange={() => setNeedChildNode(needChildNode => !needChildNode)}
                                        />
                                    </div>
                                    <span>Create Sub-dialogs for each option</span>
                                </button>
                            </div>
                        ) : null}

                        <div className="right_panel_ht">
                            <h6 className="options_popup_h6">Prompt user with a question </h6>
                            <span className="form-input">
                                <DraftContent rawMeta={titleMeta} setRawMeta={setTitleMeta} />
                            </span>

                            <div className="opations_label">
                                <div className="options_panel">
                                    <h6>Options</h6>
                                </div>
                                <div className="options_value">
                                    <h6>Value</h6>
                                </div>
                            </div>
                            {options.map((option, index) => {
                                return (
                                    <div className="opations_label1" key={index}>
                                        <div className="options_panel">
                                            <div className="if_dropdown">
                                                <div className="options_editor_count">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Option"
                                                        value={option.label}
                                                        onChange={event => {
                                                            if (option.label.length < 26) {
                                                                lableHandleChange(event.target.value, index)
                                                            } else if (option.label > event.target.value) {
                                                                lableHandleChange(event.target.value, index)
                                                            }
                                                        }}
                                                    />
                                                    <span>{countSet(index + 1)}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="options_value">
                                            <div className="if_dropdown">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Value"
                                                    value={option.value}
                                                    onChange={event => valueHandleChange(event.target.value, index)}
                                                />
                                                {options.length > 1 ? (
                                                    <div
                                                        className="parent_node_no"
                                                        onClick={() =>
                                                            setOptions(options =>
                                                                options.filter((option, count) => count != index)
                                                            )
                                                        }
                                                    >
                                                        <span className="parent_svg">
                                                            <MinusIcon />
                                                        </span>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                            {options.filter(data => data.value == '' || data.label == '').length == 0 &&
                            options.length < 20 ? (
                                <div className="plus_addmore">
                                    <div
                                        className="plus_add_more"
                                        onClick={() => setOptions(options => options.concat({ label: '', value: '' }))}
                                    >
                                        <h4>
                                            <div className="parent_node_no">
                                                <span className="parent_svg">
                                                    <AddIcon />
                                                </span>
                                            </div>
                                            Add More
                                        </h4>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <Button
                            disable={
                                options.filter(data => data.value != '' && data.label != '').length != options.length ||
                                metaParser(titleMeta) == ''
                            }
                            onCancel={() => setPageState(PageView)}
                            onSave={save}
                        />
                    </React.Fragment>
                )}
            </ErrorBoundary>
        </div>
    )
}
