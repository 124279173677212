import * as R from 'ramda'
/*eslint-disable*/
import React, { useEffect, useState } from 'react'

import {
    AddIcon,
    ArrowIcon,
    CloseIcon,
    DeleteIcon,
    EditIcon,
    EditIconWithCircle,
    ErrorIcon,
    MinusIcon
} from '@/common/Icons/Icons'
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import Validate, { ErrorContext } from '@/common/components/FormValidate'
import { HeaderChild } from '@/common/components/Header'
import InputBox from '@/common/components/InputBox'
import { List } from '@/common/components/List'
import { Loader } from '@/common/components/Loader'
import { Modal } from '@/common/components/Modal'
import { SelectBox } from '@/common/components/SelectBox'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import { SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { ButtonWrapper, Paragraph } from '@/common/styled/Dialogue.Dumb'
import { AddExamplesWrapper, BackButton, IconButton, TableWrapper } from '@/common/styled/Entity.Dumb'
import {
    ErrorMessage,
    Input,
    InputContainer,
    InputContent,
    InputErrorIcon,
    InputWrapper,
    Table,
    TableContainer,
    Tbody,
    Td,
    Textarea,
    Th,
    Thead,
    Tr
} from '@/common/styled/Workflow.Analytics.Dumb'
import { entityValidation, inputTextAreaValidations, newLine } from '@/common/utils/_validation-utils'

import { formatAMPM, formatDateInCard } from '@/common/utils/utils'
import { AssistantT } from '../Construction/kind'
import { EntityT, EntityValueT, defaultEntity } from '../Entity/kind'
import {
    createEntityValue,
    deleteEntityValue,
    getAssistants,
    getEntities,
    updateEntity,
    updateEntityValue
} from '../Mechanisms/action'
import { ActionT, ROUTER_PROPS_T } from '../kind'
import { ADD_T, EDIT_T, LOADING, PageStateT, VIEW } from './kind'
import { sortAnArrayByName } from '@/common/utils/common.utils'
import { usePermissionSignal } from '@/common/utils/auth-token-api-utils'
import { useComputed, useSignal } from '@preact/signals-react'

const AssistantEntityValues = (props: ROUTER_PROPS_T) => {
    let climaxPart: any

    const { workspacename, assistantname, entity } = props.match.params

    const [PageState, setPageState] = useState<PageStateT>(LOADING)

    const [assistant, setAssistant] = useState<string>('')

    const [entities, setEntities] = useState<EntityT[]>([])

    const [currentEntity, setCurrentEntity] = useState<EntityT>(defaultEntity)

    const [modelError, setModelError] = useState({ error: false, info: '' })

    const [modelLoader, setModelLoader] = useState<boolean>(false)
    const [addMoreError, setAddMoreError] = useState(false)
    const [inFoucs, setInFocus] = useState<null | number>(null)

    const moduleName$= useSignal<string>("")

    const entityWritePermission = usePermissionSignal(workspacename,moduleName$,"WRITE")

    const selecttedModule$ = useComputed(() => {
        return `${moduleName$.value}/ENTITY_VALUE`
    })  

    const entityValueWritePermission = usePermissionSignal(workspacename,selecttedModule$,"WRITE")

    const entityValueDeletePermission = usePermissionSignal(workspacename,selecttedModule$,"DELETE")

    // Working
    useEffect(() => {
        scrollBase()
    }, [PageState])

    // Working
    const scrollBase = () => {
        climaxPart ? climaxPart.scrollIntoView({ behavior: 'smooth' }) : null
    }

    const retry = () => {
        setPageState(LOADING)
        getAssistants(workspacename)
            .then((res: AssistantT[]) => {
                const assistant = res.reduce((accum: string, val: AssistantT) => {
                    if (val.name == assistantname) return val.id
                    else return accum
                }, '')
                if (assistant === '') {
                    setPageState({
                        action: ActionT.RESOURCE_NOT_FOUND,
                        resource: 'WORKSPACE'
                    })
                } else {

                    getEntities(workspacename, assistant).then((data: EntityT[]) => {
                        const current = data.filter(e => e.entity === entity).pop()
                        if (current) {
                            current.values.length == 0
                                ? setPageState({
                                      action: ActionT.ADD,
                                      value: {
                                          type: 'synonyms',
                                          value: '',
                                          synonyms: ['']
                                      }
                                  })
                                : setPageState(VIEW)
                            setAssistant(assistant)
                            setEntities(data)
                            setCurrentEntity(current)
                            moduleName$.value = `CHAT_BOT_MODULE/BOT/${assistant}/ENTITY/${current.id}`

                        } else {
                            setPageState({ action: ActionT.RESOURCE_NOT_FOUND, resource: 'ENTITY' })
                        }
                    })
                }
            })
            .catch(error => {
                if (error.response && error.response.status == 403) {
                    setPageState({
                        action: ActionT.UN_AUTHORIZED
                    })
                } else {
                    setPageState({
                        action: ActionT.ERROR,
                        reason: 'There was a problem in loading Page! <br/> Please click on "Retry"'
                    })
                }
            })
    }

    useEffect(() => {
        retry()
    }, [])

    const nameHandleChage = (name: string) => {
        if (
            PageState.action === ActionT.ENTITY_EDIT ||
            PageState.action === ActionT.ADD ||
            PageState.action === ActionT.EDIT
        ) {
            setPageState(state => ({ ...state, entity: name }))
        }
    }

    const save = async () => {
        setModelLoader(true)
        if (PageState.action == ActionT.ENTITY_EDIT) {
            try {
                const result = await updateEntity(workspacename, assistant, PageState.id, {
                    entity: PageState.entity,
                    description: PageState.description,
                    fuzzy_match: true
                })
                props.history.replace(`/workspace/${workspacename}/dialog/${assistantname}/entity/${PageState.entity}`)

                getEntities(workspacename, assistant).then((data: EntityT[]) => {
                    const current = data.filter(e => e.entity === PageState.entity).pop()
                    if (current) {
                        current.values.length == 0
                            ? setPageState({
                                  action: ActionT.ADD,
                                  value: {
                                      type: 'synonyms',
                                      value: '',
                                      synonyms: ['']
                                  }
                              })
                            : setPageState(VIEW)
                        setAssistant(assistant)
                        setEntities(data)
                        setCurrentEntity(current)
                        setModelLoader(false)
                    } else {
                        setModelLoader(false)
                        setPageState({ action: ActionT.RESOURCE_NOT_FOUND, resource: 'ENTITY' })
                    }
                })
            } catch (err) {
                console.log('::::::: editEntity ERR')
            }
        } else if (PageState.action == ActionT.ADD) {
            if (PageState.value.type === 'synonyms') {
                try {
                    const result: EntityValueT = await createEntityValue(
                        workspacename,
                        assistant,
                        currentEntity.id,
                        {
                            type: 'synonyms',
                            value: PageState.value.value,
                            synonyms: PageState.value.synonyms
                        },
                        currentEntity.entity
                    )
                    setCurrentEntity(data => ({
                        ...data,
                        values: data.values.concat([result])
                    }))
                    setPageState(VIEW)
                    setModelLoader(false)
                } catch (err) {
                    let error = err as any
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.type &&
                        error.response.data.type === 'EntityValueSynonymDuplicationErr'
                    ) {
                        setModelError({
                            error: true,
                            info: `Duplicate Synonyms!<br /> ${error.response.data.message}.`
                        })
                        setModelLoader(false)
                    }
                }
            } else if (PageState.value.type === 'patterns') {
                const result: EntityValueT = await createEntityValue(
                    workspacename,
                    assistant,
                    currentEntity.id,
                    {
                        type: 'patterns',
                        value: PageState.value.value,
                        patterns: PageState.value.patterns
                    },
                    currentEntity.entity
                )
                setCurrentEntity(data => ({
                    ...data,
                    values: data.values.concat([result])
                }))
                setPageState(VIEW)
                setModelLoader(false)
            }
        }
    }

    const entityEditModeDisable = () => {
        if (PageState.action != ActionT.ENTITY_EDIT) {
            return true
        } else {
            return PageState.entity == currentEntity.entity && PageState.description == currentEntity.description
        }
    }

    const editValueModeDisable = () => {
        if (PageState.action != ActionT.EDIT) {
            return false
        } else {
            const state = PageState as EDIT_T
            return R.equals(entities.filter((e, i) => e.entity == currentEntity.entity)[0].values[0], state.value)
        }
    }

    const infoHandleChage = (description: string) => {
        if (PageState.action === ActionT.ENTITY_EDIT) {
            setPageState(state => ({ ...state, description: description }))
        }
    }

    const valueNameHandle = (name: string) => {
        if (PageState.action === ActionT.ADD || PageState.action === ActionT.EDIT) {
            const val = PageState.value
            setPageState(state => ({ ...state, value: { ...val, value: name } } as ADD_T))
        }
    }

    const synonymsHandleChange = () => {
        if (PageState.action === ActionT.ADD && PageState.value.type !== 'synonyms') {
            setPageState(state => ({
                ...state,
                value: {
                    value: PageState.value.value,
                    type: 'synonyms',
                    synonyms: ['']
                } as any
            }))
        } else if (PageState.action === ActionT.EDIT && PageState.value.type !== 'synonyms') {
            // setPageState(state => ({
            //     ...state,
            //     value: {
            //         value: PageState.value.value,
            //         type: 'patterns',
            //         patterns: []
            //     }
            // }))
        }
    }

    const patternsHandleChange = () => {
        if (PageState.action === ActionT.ADD && PageState.value.type !== 'patterns') {
            setPageState(state => ({
                ...state,
                value: {
                    value: PageState.value.value,
                    type: 'patterns',
                    patterns: ['']
                } as any
            }))
        } else if (PageState.action === ActionT.EDIT && PageState.value.type !== 'patterns') {
            //   setPageState(state => ({
            //         ...state,
            //         value: {
            //             value: PageState.value.value,
            //             type: 'patterns',
            //             patterns: []
            //         }
            //     }))
        }
    }

    const removeValue = (index: number) => {
        if (PageState.action === ActionT.ADD || PageState.action === ActionT.EDIT) {
            if (PageState.value.type === 'patterns') {
                const patterns = PageState.value.patterns.filter((x: string, y: number) => y != index)
                setPageState(state => ({
                    ...state,
                    value: {
                        ...PageState.value,
                        patterns
                    } as any
                }))
            } else {
                const synonyms = PageState.value.synonyms.filter((x: string, y: number) => y != index)
                setPageState(state => ({
                    ...state,
                    value: {
                        ...PageState.value,
                        synonyms
                    } as any
                }))
            }
        }
    }

    const addAnotherValue = () => {
        if (PageState.action === ActionT.ADD || PageState.action == ActionT.EDIT) {
            if (
                PageState.value.type === 'synonyms' &&
                PageState.value.synonyms.filter(e => e.trim() === '').length === 0
            ) {
                const val: string[] = PageState.value.synonyms.concat([''])
                setPageState(
                    state =>
                        ({
                            ...state,
                            value: { ...PageState.value, synonyms: val }
                        } as any)
                )
                setAddMoreError(false)
            } else if (
                PageState.value.type === 'patterns' &&
                PageState.value.patterns.filter(e => e.trim() === '').length === 0
            ) {
                const val: string[] = PageState.value.patterns.concat([''])
                setPageState(
                    state =>
                        ({
                            ...state,
                            value: { ...PageState.value, patterns: val }
                        } as any)
                )
                setAddMoreError(false)
            } else if (
                (PageState.value.type === 'patterns' &&
                    PageState.value.patterns.filter(e => e.trim() === '').length > 0) ||
                (PageState.value.type === 'synonyms' &&
                    PageState.value.synonyms.filter(e => e.trim() === '').length > 0)
            ) {
                setAddMoreError(true)
            } else {
                climaxPart ? climaxPart.scrollIntoView({ behavior: 'smooth' }) : null
            }
        }
    }

    const deleteEntity = () => {
        switch (PageState.action) {
            case ActionT.MULTIPLE_DELETE:
                Promise.all(
                    PageState.items.map((exampleID: string) =>
                        deleteEntityValue(workspacename, assistant, currentEntity.id, exampleID, currentEntity.entity)
                    )
                )
                    .then((_: {}) => {
                        // if (entities.values.length == 0) {
                        //     setPageState({
                        //         action: ActionT.ADD,
                        //         value: {
                        //             type: 'synonyms',
                        //             value: '',
                        //             synonyms: ['']
                        //         }
                        //     })
                        // } else setPageState(VIEW)
                        // setCurrentEntity(data => ({
                        //     ...data,
                        //     values: data.values.filter(e => !PageState.items.includes(e.id))
                        // }))
                        getEntities(workspacename, assistant).then((data: EntityT[]) => {
                            const current = data.filter(e => e.entity === currentEntity.entity).pop()
                            if (current) {
                                current.values.length == 0
                                    ? setPageState({
                                          action: ActionT.ADD,
                                          value: {
                                              type: 'synonyms',
                                              value: '',
                                              synonyms: ['']
                                          }
                                      })
                                    : setPageState(VIEW)
                                setEntities(data)
                                setCurrentEntity(current)
                            } else {
                                setPageState({ action: ActionT.RESOURCE_NOT_FOUND, resource: 'ENTITY' })
                            }
                        })
                    })
                    .catch(() => {
                        console.log('Server error!<br />Please try again after sometime.')
                    })
                break
            case ActionT.DELETE:
                deleteEntityValue(workspacename, assistant, currentEntity.id, PageState.value.id, currentEntity.entity)
                    .then((_: {}) => {
                        getEntities(workspacename, assistant).then((data: EntityT[]) => {
                            const current = data.filter(e => e.entity === currentEntity.entity).pop()
                            if (current) {
                                current.values.length == 0
                                    ? setPageState({
                                          action: ActionT.ADD,
                                          value: {
                                              type: 'synonyms',
                                              value: '',
                                              synonyms: ['']
                                          }
                                      })
                                    : setPageState(VIEW)
                                setEntities(data)
                                setCurrentEntity(current)
                            } else {
                                setPageState({ action: ActionT.RESOURCE_NOT_FOUND, resource: 'ENTITY' })
                            }
                        })
                    })
                    .catch(() => {
                        console.log('Server error!<br />Please try again after sometime.')
                    })
                break
        }
    }

    const editEntityExample = () => {
        if (PageState.action == ActionT.EDIT) {
            setModelLoader(true)
            if (PageState.value.type === 'synonyms') {
                updateEntityValue(
                    workspacename,
                    assistant,
                    currentEntity.id,
                    PageState.value.id,
                    {
                        type: 'synonyms',
                        value: PageState.value.value,
                        synonyms: PageState.value.synonyms
                    } as any,
                    currentEntity.entity
                )
                    .then(res => {
                        getEntities(workspacename, assistant).then((data: EntityT[]) => {
                            const current = data.filter(e => e.entity === entity).pop()
                            if (current) {
                                current.values.length ? setPageState(VIEW) : setEntities(data)
                                setCurrentEntity(current)
                            } else {
                                setPageState({ action: ActionT.RESOURCE_NOT_FOUND, resource: 'ENTITY' })
                            }
                            setModelLoader(false)
                        })
                    })
                    .catch(error => {
                        if (
                            error.response &&
                            error.response.data &&
                            error.response.data.type &&
                            error.response.data.type === 'EntityValueSynonymDuplicationErr'
                        ) {
                            console.log(error.response.data)
                            setModelError({
                                error: true,
                                info: `Duplicate Synonyms!<br /> ${error.response.data.message}.`
                            })
                        } else {
                            setModelError({
                                error: true,
                                info: `Something went wrong....!`
                            })
                        }
                        setModelLoader(false)
                    })
            } else {
                updateEntityValue(
                    workspacename,
                    assistant,
                    currentEntity.id,
                    PageState.value.id,
                    {
                        type: 'patterns',
                        value: PageState.value.value,
                        patterns: PageState.value.patterns
                    } as any,
                    currentEntity.entity
                )
                    .then(() => {
                        getEntities(workspacename, assistant).then((data: EntityT[]) => {
                            const current = data.filter(e => e.entity === entity).pop()
                            if (current) {
                                current.values.length ? setPageState(VIEW) : setEntities(data)
                                setCurrentEntity(current)
                            } else {
                                setPageState({ action: ActionT.RESOURCE_NOT_FOUND, resource: 'ENTITY' })
                            }
                            setModelLoader(false)
                        })
                    })
                    .catch(() => {
                        console.log('::::::: editEntity ERR')
                        setModelError({
                            error: true,
                            info: `Something went wrong....!`
                        })
                        setModelLoader(false)
                    })
            }
        }
    }

    const selectEntityExample = (example: string) => {
        switch (PageState.action) {
            case ActionT.SELECT:
                const selected: string[] = PageState.items.includes(example)
                    ? PageState.items.filter((x: string) => x !== example)
                    : [...PageState.items, example]

                if (selected.length) {
                    setPageState({
                        ...PageState,
                        items: selected
                    })
                } else {
                    setPageState(VIEW)
                }
                break
            default:
                const newSelected = [example]
                setPageState({
                    action: ActionT.SELECT,
                    items: newSelected
                })
                break
        }
    }

    function checkDuplicate(e: string, index: number) {
        if (PageState.action === ActionT.ADD || PageState.action === ActionT.EDIT) {
            const values = PageState.value.type === 'synonyms' ? PageState.value.synonyms : PageState.value.patterns

            return values.filter((s, i) => i != index && e == s).length > 0
        }

        return false
    }

    const renderConfirmModal = () => {
        if (PageState.action === ActionT.ADD || PageState.action === ActionT.EDIT) {
            const values = PageState.value.type === 'synonyms' ? PageState.value.synonyms : PageState.value.patterns
            return (
                <Modal error={modelError} showPopupLoader={modelLoader}>
                    <ErrorBoundary
                        error={modelError}
                        render={(err: any, info: any) => {
                            return (
                                err && (
                                    <Error.AddorEdit
                                        onClick={() => {
                                            setModelError({ error: false, info: '' })
                                            setPageState(VIEW)
                                        }}
                                        info={info}
                                    />
                                )
                            )
                        }}
                    >
                        <Validate defaultValue={{ value: PageState.action === ActionT.EDIT ? true : false }}>
                            {PageState.action === ActionT.EDIT ? <h2>Edit Value </h2> : <h2>Add Value </h2>}
                            <Paragraph margin>Provide value details and save </Paragraph>{' '}
                            <div>
                                <InputBox
                                    validationList={entityValidation.concat({
                                        validationFn: (val: string) =>
                                            currentEntity.values.filter(
                                                (value: any) => value.value.toLowerCase() == val.toLowerCase()
                                            ).length > 0,
                                        Error: 'Value already exists'
                                    })}
                                    value={PageState.value.value}
                                    name="value"
                                    render={(
                                        value: string,
                                        valid: boolean,
                                        errorMsg: string,
                                        startValidation: () => void,
                                        handleInput: Function
                                    ) => {
                                        return (
                                            <React.Fragment>
                                                {/* <div className="entity_value_name">
                                                    <span>Value Name</span>
                                                </div> */}
                                                <InputWrapper>
                                                    <ErrorMessage>{!valid ? errorMsg : newLine}</ErrorMessage>
                                                    <InputContainer style={{ marginBottom: '12px' }}>
                                                        <Input
                                                            primary={!valid ? `primary` : ''}
                                                            type="text"
                                                            onChange={(event: any) => {
                                                                startValidation(),
                                                                    handleInput('value', event.target.value)
                                                                valueNameHandle(event.target.value)
                                                            }}
                                                            value={value}
                                                        />
                                                        <InputContent>Enter value name</InputContent>
                                                        {!valid && (
                                                            <InputErrorIcon>
                                                                <ErrorIcon />
                                                            </InputErrorIcon>
                                                        )}
                                                    </InputContainer>
                                                </InputWrapper>
                                            </React.Fragment>
                                        )
                                    }}
                                />

                                <div className="col-md-12 entity_radio" style={{ marginBottom: '12px' }}>
                                    {PageState.action !== ActionT.EDIT || PageState.value.type === 'synonyms' ? (
                                        <div className="radio entity_radio_flex">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="cshvhcv"
                                                    className="radio-warning"
                                                    onChange={synonymsHandleChange}
                                                    checked={PageState.value.type === 'synonyms'}
                                                />
                                            </label>
                                            <span>Synonyms</span>
                                        </div>
                                    ) : null}
                                    {PageState.action !== ActionT.EDIT || PageState.value.type === 'patterns' ? (
                                        <div className="radio entity_radio_flex">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="cshvhcv"
                                                    onChange={patternsHandleChange}
                                                    checked={PageState.value.type === 'patterns'}
                                                />
                                            </label>
                                            <span>Regex</span>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="synonyms_wrapper">
                                    {PageState.value.type === 'synonyms'
                                        ? PageState.value.synonyms &&
                                          PageState.value.synonyms.length > 0 &&
                                          PageState.value.synonyms.map((e: string, index: number) => {
                                              return (
                                                  <AddExamplesWrapper>
                                                      <div className="add_del_btn_width entity_add_value" key={index}>
                                                          <div className="add_del_btn">
                                                              <InputWrapper>
                                                                  {/* {((addMoreError && e.length == 0) ||
                                                                      checkDuplicate(e, index)) && (
                                                                      <ErrorMessage>
                                                                          {' '}
                                                                          {addMoreError
                                                                              ? 'Please fill out the empty field'
                                                                              : inFoucs == index &&
                                                                                checkDuplicate(e, index)
                                                                              ? 'Duplicate synonym'
                                                                              : newLine}
                                                                      </ErrorMessage>
                                                                  )} */}
                                                                  <InputContainer>
                                                                      <Input
                                                                          primary={addMoreError ? `primary` : ''}
                                                                          type="text"
                                                                          maxLength={63}
                                                                          onFocus={() => setInFocus(index)}
                                                                          onBlur={() =>
                                                                              !checkDuplicate(e, index) &&
                                                                              setInFocus(null)
                                                                          }
                                                                          onChange={(event: any) => {
                                                                              setAddMoreError(false)
                                                                              const text = event.target.value
                                                                              setPageState(state => ({
                                                                                  ...state,
                                                                                  value: {
                                                                                      ...PageState.value,
                                                                                      synonyms:
                                                                                          PageState.value.type ===
                                                                                          'synonyms'
                                                                                              ? PageState.value.synonyms.map(
                                                                                                    (
                                                                                                        val: string,
                                                                                                        i: number
                                                                                                    ) =>
                                                                                                        i === index
                                                                                                            ? text
                                                                                                            : val
                                                                                                )
                                                                                              : []
                                                                                  } as any
                                                                              }))
                                                                          }}
                                                                          value={e}
                                                                      />
                                                                      <InputContent>Add a Synonym</InputContent>
                                                                      {/* {addMoreError && e.length == 0 && (
                                                                          <InputErrorIcon>
                                                                              <ErrorIcon />
                                                                          </InputErrorIcon>
                                                                      )} */}
                                                                      {((addMoreError && e.length == 0) ||
                                                                      (checkDuplicate(e, index) && inFoucs == index)) && <div className="error_input_show dialog_node svg-15px">
                                                                        <WarningAlertIcon />
                                                                        <span className="error_menu_hvr">{
                                                                            addMoreError
                                                                            ? 'Please fill out the empty field'
                                                                            :'Duplicate synonym'
                                                                        }</span>
                                                                    </div>
                                                                    }
                                                                  </InputContainer>
                                                              </InputWrapper>
                                                              {PageState.value.type === 'synonyms' &&
                                                              PageState.value.synonyms.length > 1 ? (
                                                                  <span
                                                                      className="input_remove_value"
                                                                      onClick={() => removeValue(index)}
                                                                      style={
                                                                          (addMoreError && e.length == 0) ||
                                                                          (inFoucs == index && checkDuplicate(e, index))
                                                                              ? { }
                                                                              : {}
                                                                      }
                                                                  >
                                                                      <span>
                                                                        <CloseIcon />
                                                                      </span>
                                                                      
                                                                  </span>
                                                              ) : null}
                                                          </div>
                                                      </div>
                                                  </AddExamplesWrapper>
                                              )
                                          })
                                        : PageState.value.patterns &&
                                          PageState.value.patterns.length > 0 &&
                                          PageState.value.patterns.map((e: any, index: number) => {
                                              return (
                                                  <AddExamplesWrapper>
                                                      <div className="add_del_btn_width entity_add_value" key={index}>
                                                          <div className="add_del_btn">
                                                              <span className="entity_add_value_span">
                                                                  <InputWrapper>
                                                                      {((addMoreError && e.length == 0) ||
                                                                          checkDuplicate(e, index)) && (
                                                                          <ErrorMessage>
                                                                              {' '}
                                                                              {addMoreError
                                                                                  ? 'Please fill out the empty field'
                                                                                  : inFoucs == index &&
                                                                                    checkDuplicate(e, index)
                                                                                  ? 'Duplicate regex'
                                                                                  : newLine}
                                                                          </ErrorMessage>
                                                                      )}
                                                                      <InputContainer>
                                                                          <Input
                                                                              primary={addMoreError ? `primary` : ''}
                                                                              type="text"
                                                                              maxLength={511}
                                                                              onFocus={() => setInFocus(index)}
                                                                              onBlur={() =>
                                                                                  !checkDuplicate(e, index) &&
                                                                                  setInFocus(null)
                                                                              }
                                                                              onChange={(event: any) => {
                                                                                  setAddMoreError(false)
                                                                                  const text = event.target.value
                                                                                  setPageState(state => ({
                                                                                      ...state,
                                                                                      value: {
                                                                                          ...PageState.value,
                                                                                          patterns:
                                                                                              PageState.value.type ===
                                                                                              'patterns'
                                                                                                  ? PageState.value.patterns.map(
                                                                                                        (
                                                                                                            val: string,
                                                                                                            i: number
                                                                                                        ) =>
                                                                                                            i === index
                                                                                                                ? text
                                                                                                                : val
                                                                                                    )
                                                                                                  : []
                                                                                      } as any
                                                                                  }))
                                                                              }}
                                                                              value={e}
                                                                          />
                                                                          <InputContent>Add a Regex</InputContent>
                                                                          {/* {addMoreError && e.length == 0 && (
                                                                              <InputErrorIcon>
                                                                                  <ErrorIcon />
                                                                              </InputErrorIcon>
                                                                          )} */}
                                                                      </InputContainer>
                                                                  </InputWrapper>
                                                              </span>
                                                              {PageState.value.type === 'patterns' &&
                                                              PageState.value.patterns.length > 1 ? (
                                                                  <span
                                                                      className="input_remove_value"
                                                                      onClick={() => removeValue(index)}
                                                                      style={
                                                                          (addMoreError && e.length == 0) ||
                                                                          (inFoucs == index && checkDuplicate(e, index))
                                                                              ? { top: 30 }
                                                                              : {}
                                                                      }
                                                                  >
                                                                      <MinusIcon />
                                                                  </span>
                                                              ) : null}
                                                          </div>
                                                      </div>
                                                  </AddExamplesWrapper>
                                              )
                                          })}
                                    <div
                                        style={{
                                            float: 'left',
                                            clear: 'both'
                                        }}
                                        ref={el => {
                                            climaxPart = el
                                        }}
                                    />
                                </div>
                                {/* <p className="error_msg">
                                    {addMoreError ? 'Please fill out the empty field' : newLine}
                                </p>
                                 */}

                                {PageState.value.type === 'patterns'
                                    ? PageState.value.patterns.length < 25 &&
                                      R.uniq(values).length == values.length && (
                                          <SmallButton style={{ marginBottom: 20 }} primary onClick={addAnotherValue}>
                                              <span>
                                                  <AddIcon />
                                              </span>
                                              <label>Add More</label>
                                          </SmallButton>
                                      )
                                    : null}
                                {PageState.value.type === 'synonyms'
                                    ? PageState.value.synonyms.length < 25 &&
                                      R.uniq(values).length == values.length && (
                                          // !PageState.value.synonyms &&
                                          <SmallButton primary style={{ marginBottom: 20 }} onClick={addAnotherValue}>
                                              <span>
                                                  <AddIcon />
                                              </span>
                                              <label>Add More</label>
                                          </SmallButton>
                                      )
                                    : null}

                                <ErrorContext.Consumer>
                                    {valid => {
                                        return (
                                            <React.Fragment>
                                                <ButtonWrapper>
                                                    <Button
                                                        primary
                                                        type="button"
                                                        onClick={() => {
                                                            setAddMoreError(false)
                                                            setPageState(VIEW)
                                                        }}
                                                    >
                                                        <label>Cancel</label>
                                                    </Button>
                                                    {PageState.action == ActionT.EDIT ? (
                                                        <Button
                                                            type="button"
                                                            onClick={() => editEntityExample()}
                                                            disabled={
                                                                (() => {
                                                                    return !(
                                                                        (PageState.value.type === 'patterns' &&
                                                                            PageState.value.patterns.filter(
                                                                                e => e.trim() === ''
                                                                            ).length === 0) ||
                                                                        (PageState.value.type === 'synonyms' &&
                                                                            PageState.value.synonyms.filter(
                                                                                e => e.trim() === ''
                                                                            ).length === 0 &&
                                                                            PageState.value.value.trim() !== '')
                                                                    )
                                                                })() ||
                                                                editValueModeDisable() ||
                                                                R.uniq(values).length != values.length||
                                                                !valid.formValid.formValid
                                                            }
                                                            className={
                                                                (() => {
                                                                    return !(
                                                                        (PageState.value.type === 'patterns' &&
                                                                            PageState.value.patterns.filter(
                                                                                e => e.trim() === ''
                                                                            ).length === 0) ||
                                                                        (PageState.value.type === 'synonyms' &&
                                                                            PageState.value.synonyms.filter(
                                                                                e => e.trim() === ''
                                                                            ).length === 0 &&
                                                                            PageState.value.value.trim() !== '')
                                                                    )
                                                                })() ||
                                                                editValueModeDisable() ||
                                                                R.uniq(values).length != values.length ||
                                                                !valid.formValid.formValid
                                                                    ? 'editor_btn_disabled'
                                                                    : ''
                                                            }
                                                        >
                                                            <label>Save</label>
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            type="button"
                                                            onClick={save}
                                                            disabled={
                                                                (() => {
                                                                    return !(
                                                                        (PageState.value.type === 'patterns' &&
                                                                            PageState.value.patterns.filter(
                                                                                e => e.trim() === ''
                                                                            ).length === 0) ||
                                                                        (PageState.value.type === 'synonyms' &&
                                                                            PageState.value.synonyms.filter(
                                                                                e => e.trim() === ''
                                                                            ).length === 0 &&
                                                                            PageState.value.value.trim() !== '')
                                                                    )
                                                                })() || R.uniq(values).length != values.length ||
                                                                !valid.formValid.formValid
                                                            }
                                                            className={
                                                                (() => {
                                                                    return !(
                                                                        (PageState.value.type === 'patterns' &&
                                                                            PageState.value.patterns.filter(
                                                                                e => e.trim() === ''
                                                                            ).length === 0) ||
                                                                        (PageState.value.type === 'synonyms' &&
                                                                            PageState.value.synonyms.filter(
                                                                                e => e.trim() === ''
                                                                            ).length === 0 &&
                                                                            PageState.value.value.trim() !== '')
                                                                    )
                                                                })() || R.uniq(values).length != values.length||
                                                                !valid.formValid.formValid
                                                                    ? 'editor_btn_disabled'
                                                                    : ''
                                                            }
                                                        >
                                                            <label>Save</label>
                                                        </Button>
                                                    )}
                                                </ButtonWrapper>
                                            </React.Fragment>
                                        )
                                    }}
                                </ErrorContext.Consumer>
                            </div>
                        </Validate>
                    </ErrorBoundary>
                </Modal>
            )
        } else if (PageState.action === ActionT.ENTITY_EDIT) {
            const duplicate =
                entities.filter(e => e.entity != currentEntity.entity && e.entity == PageState.entity).length > 0
            return (
                <Modal error={modelError} showPopupLoader={modelLoader}>
                    <ErrorBoundary
                        error={modelError}
                        render={(err: any, info: any) => {
                            return (
                                err && (
                                    <Error.AddorEdit
                                        onClick={() => setModelError({ error: false, info: '' })}
                                        info={info}
                                    />
                                )
                            )
                        }}
                    >
                        <Validate defaultValue={{ entityName: true }}>
                            <h2>Update Entity </h2>
                            <Paragraph margin>Enter the Name and Description</Paragraph>{' '}
                            <AddExamplesWrapper>
                                <InputBox
                                    validationList={entityValidation}
                                    value={PageState.entity}
                                    name="entityName"
                                    render={(
                                        value: string,
                                        valid: boolean,
                                        errorMsg: string,
                                        startValidation: () => void,
                                        handleInput: Function
                                    ) => {
                                        return (
                                            <React.Fragment>
                                                <InputWrapper>
                                                    <InputContainer>
                                                        <Input
                                                            type="text"
                                                            // ref={node => (this.entityInputRef = node)}
                                                            onChange={(event: any) => {
                                                                startValidation(),
                                                                    handleInput('entityName', event.target.value)
                                                                nameHandleChage(event.target.value)
                                                            }}
                                                            value={value}
                                                        />
                                                        <InputContent>Entity Name</InputContent>
                                                    </InputContainer>
                                                </InputWrapper>

                                                <p className="error_msg">{!valid ? errorMsg : newLine}</p>
                                                {duplicate ? (
                                                    <div className="error_input_show intent">
                                                        <WarningAlertIcon />
                                                        <span className="error_menu_hvr">
                                                            Duplicate entity cannot be saved. Modify the entity name.
                                                        </span>
                                                    </div>
                                                ) : null}
                                            </React.Fragment>
                                        )
                                    }}
                                />

                                <InputBox
                                    validationList={inputTextAreaValidations}
                                    value={PageState.description}
                                    name={'entityDescription'}
                                    render={(
                                        value: string,
                                        valid: boolean,
                                        errorMsg: string,
                                        startValidation: () => void,
                                        handleInput: Function
                                    ) => {
                                        return (
                                            <React.Fragment>
                                                <InputWrapper>
                                                    <InputContainer>
                                                        <Textarea
                                                            onChange={(event: any) => {
                                                                startValidation()
                                                                handleInput('entityDescription', event.target.value)
                                                                infoHandleChage(event.target.value)
                                                            }}
                                                            value={value}
                                                            // onKeyDown={e => {
                                                            //     let keyCode = e.keyCode || e.which
                                                            //     this.setState({
                                                            //         keycode: keyCode
                                                            //     })
                                                            // }}
                                                        />
                                                        <InputContent>Entity description</InputContent>
                                                    </InputContainer>
                                                </InputWrapper>
                                                <p className="error_msg">{!valid ? errorMsg : newLine}</p>
                                            </React.Fragment>
                                        )
                                    }}
                                />
                                <ErrorContext.Consumer>
                                    {valid => {
                                        return (
                                            <React.Fragment>
                                                <ButtonWrapper>
                                                    <Button
                                                        primary
                                                        type="button"
                                                        onClick={() => {
                                                            setPageState(VIEW)
                                                        }}
                                                    >
                                                        <label>Cancel</label>
                                                    </Button>
                                                    <Button
                                                        type="button"
                                                        onClick={save}
                                                        disabled={!valid.formValid.formValid || entityEditModeDisable()}
                                                    >
                                                        <label>Update</label>
                                                    </Button>
                                                </ButtonWrapper>
                                            </React.Fragment>
                                        )
                                    }}
                                </ErrorContext.Consumer>
                            </AddExamplesWrapper>
                        </Validate>
                    </ErrorBoundary>
                </Modal>
            )
        } else if (PageState.action === ActionT.DELETE || PageState.action === ActionT.MULTIPLE_DELETE) {
            // entity value delete popup should open
            return (
                <Modal error={modelError} showPopupLoader={modelLoader}>
                    <ErrorBoundary
                        error={modelError}
                        render={(err: any, info: any) => {
                            return (
                                err && (
                                    <Error.Delete
                                        onClick={() => setModelError({ error: false, info: '' })}
                                        info={info}
                                    />
                                )
                            )
                        }}
                    >
                        {PageState.action === ActionT.MULTIPLE_DELETE ? (
                            <React.Fragment>
                                <h2>Delete Values?</h2>
                                <Paragraph>
                                    Are you sure you want to delete selected Values?
                                    <br />
                                    Dialog may not work properly if the Entity Value is mapped in a Dialog conversation.
                                </Paragraph>{' '}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <h2>Delete {PageState.value.value}?</h2>
                                <Paragraph>
                                    Are you sure you want to delete selected value?
                                    <br />
                                    Dialog may not work properly if the Entity Value is mapped in a Dialog conversation.
                                </Paragraph>{' '}
                            </React.Fragment>
                        )}

                        <div>
                            <ButtonWrapper>
                                <Button primary type="button" onClick={() => setPageState(VIEW)}>
                                    <label>No</label>
                                </Button>
                                <Button
                                    type="button"
                                    onClick={() => {
                                        deleteEntity()
                                    }}
                                >
                                    <label>Yes</label>
                                </Button>
                            </ButtonWrapper>
                        </div>
                    </ErrorBoundary>
                </Modal>
            )
        }
        return null
    }

    return PageState.action === ActionT.LOADING ? (
        <Loader.PageLoader show={true} />
    ) : (
        <ErrorBoundary
            unauthorized={PageState.action === ActionT.UN_AUTHORIZED}
            resourceNotFound={PageState.action === ActionT.RESOURCE_NOT_FOUND}
            error={
                PageState.action === ActionT.ERROR
                    ? { error: true, info: PageState.reason }
                    : { error: null, info: null }
            }
            render={(err: any, info: any) => {
                return err && <Error.PageLoadingError onClick={retry} btnName="Retry" info={info} />
            }}
        >
            <React.Fragment>
                <section className="cogni_cards">
                    <div className="flow_canvas1 intent_inner_page">
                        <div className="flex_canvas1">
                            <div className="flex_menu1 flow_group_home">
                                <div className="indent_dialog_left">
                                    <div className="indent_title">
                                        <BackButton onClick={() => window.history.back()}>
                                            <span>
                                                <ArrowIcon />
                                            </span>{' '}
                                            <label>Back</label>
                                        </BackButton>
                                        <div className="buton_back_content">
                                            <div>
                                                <h2>{currentEntity.entity}</h2>
                                                {currentEntity.is_system_entity === false ? (
                                                    <p>{currentEntity.description}</p>
                                                ) : (
                                                    <p>{JSON.parse(currentEntity.description).original_description}</p>
                                                )}
                                            </div>
                                            {currentEntity.is_system_entity === false && entityWritePermission.value? (
                                                <IconButton
                                                    onClick={() =>
                                                        setPageState({
                                                            action: ActionT.ENTITY_EDIT,
                                                            id: currentEntity.id,
                                                            description: currentEntity.description,
                                                            entity: currentEntity.entity
                                                        })
                                                    }
                                                >
                                                    <span>
                                                        <EditIconWithCircle />
                                                    </span>
                                                </IconButton>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="intent_dialog_right">
                                    {currentEntity.is_system_entity === false ? (
                                        <div>
                                            {(PageState.action === ActionT.SELECT ||
                                                (PageState.action === ActionT.MULTIPLE_DELETE &&
                                                    PageState.items.length > 0)) && entityValueDeletePermission.value && (
                                                <Button
                                                    primary
                                                    onClick={() => {
                                                        setPageState({
                                                            action: ActionT.MULTIPLE_DELETE,
                                                            items: PageState.items
                                                        })
                                                    }}
                                                >
                                                    <span>
                                                        <DeleteIcon />
                                                    </span>
                                                    <label>Delete</label>
                                                </Button>
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flow_canvas1">
                        <div className="flex_canvas1">
                            <div className="flex_menu1 flow_group_home intent_header_background">
                                <HeaderChild type="first">
                                    <section className="entity__header">
                                        <div className="entity__header_width">
                                            <div className="entity__header_top">
                                                <h4 className="indent_title">Values</h4>
                                                {PageState.action !== ActionT.SELECT &&
                                                    currentEntity.is_system_entity === false &&
                                                    PageState.action !== ActionT.MULTIPLE_DELETE && entityValueWritePermission.value && (
                                                        <SmallButton
                                                            primary
                                                            style={{ margin: 0 }}
                                                            onClick={() => {
                                                                setPageState({
                                                                    action: ActionT.ADD,
                                                                    value: {
                                                                        type: 'synonyms',
                                                                        value: '',
                                                                        synonyms: ['']
                                                                    }
                                                                })
                                                            }}
                                                        >
                                                            <span>
                                                                <AddIcon />
                                                            </span>
                                                            <label>Add</label>
                                                        </SmallButton>
                                                    )}
                                            </div>

                                            <div className="entity__header_bottom">
                                                {currentEntity.is_system_entity === false ? (
                                                    <p>
                                                        Specify patterns for this entity. A pattern can be a Synonym or
                                                        Regex
                                                    </p>
                                                ) : (
                                                    <p>You cannot edit system entities</p>
                                                )}
                                            </div>
                                        </div>
                                    </section>
                                </HeaderChild>
                                <HeaderChild type="second">
                                    <div style={{ marginRight: 30 }} />
                                </HeaderChild>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
            {currentEntity.is_system_entity === false ? (
                <section className="entity_cards" style={{ marginTop: 0 }}>
                    <div className="col-md-12">
                        <div className="row">
                            <TableWrapper>
                                <TableContainer>
                                    <Table>
                                        <Thead>
                                            <Tr>
                                                <Th size="4">Name</Th>
                                                <Th size="2">Type</Th>
                                                <Th size="3.5">Values</Th>
                                                <Th size="1.5">Date</Th>
                                                <Th size="1"></Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {currentEntity &&
                                                currentEntity.values &&
                                                sortAnArrayByName(currentEntity.values,"value").map((val: EntityValueT, index: number) => (
                                                    <ValueList
                                                        key={index}
                                                        value={val}
                                                        edit={() => {
                                                            setPageState({
                                                                action: ActionT.EDIT,
                                                                value: val
                                                            })
                                                        }}
                                                        delete={() =>
                                                            setPageState({
                                                                action: ActionT.DELETE,
                                                                value: val
                                                            })
                                                        }
                                                        select={() => {
                                                            if (PageState.action === ActionT.SELECT) {
                                                                setPageState({
                                                                    action: ActionT.SELECT,
                                                                    items: PageState.items.concat([val.id])
                                                                })
                                                            } else {
                                                                setPageState({
                                                                    action: ActionT.SELECT,
                                                                    items: [val.id]
                                                                })
                                                            }
                                                        }}
                                                        PageState={PageState}
                                                        selectEntityExample={selectEntityExample}
                                                        permissions={{entityWritePermission:entityWritePermission.value,entityValueWritePermission:entityValueWritePermission.value,entityValueDeletePermission:entityValueDeletePermission.value}}
                                                    />
                                                ))}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </TableWrapper>
                        </div>
                    </div>
                </section>
            ) : (
                <section className="entity_cards default_entity" style={{ marginTop: 0 }}>
                    <div className="col-md-12">
                        <h4>Recognizes the following formats:</h4>

                        <div className="row">
                            <ul>
                                {JSON.parse(currentEntity.description).pattern.map((pattern: any, i: number) => (
                                    <li key={i}>{pattern}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </section>
            )}
            <section className="intent_bottom">
                <div>&nbsp;</div>
            </section>
            {renderConfirmModal()}
        </ErrorBoundary>
    )
}

const ValueList = ({
    value,
    PageState,
    select,
    selectEntityExample,
    permissions,
    ...rest
}: {
    value: EntityValueT
    PageState: PageStateT
    select: Function
    selectEntityExample: Function
    edit: Function
    delete: Function
    permissions: {
        entityWritePermission: boolean | null;
        entityValueWritePermission: boolean | null;
        entityValueDeletePermission: boolean | null;
    }
}) => {
    const showSelection = () =>
        PageState.action == ActionT.SELECT ||
        (PageState.action == ActionT.MULTIPLE_DELETE && PageState.items.length > 0)

    const isSelected = () => {
        if (PageState.action === ActionT.SELECT || PageState.action === ActionT.MULTIPLE_DELETE) {
            return PageState.items.filter((intent: string) => intent === value.id).length > 0
        }
        return false
    }

    const converter = (valus: string[]) => valus.join()

    return (
        <Tr>
            <List
                showCheckBox
                checkBoxShow={showSelection()}
                render={(show: boolean, ToolBox: any) => {
                    return (
                        <>
                            <Td size={4}>
                                <SelectBox
                                    show={show}
                                    handleSelected={() => selectEntityExample(value.id)}
                                    isSelected={isSelected()}
                                />
                                {value.value}
                            </Td>

                            <Td size={2}>{value.type == 'patterns' ? 'Regex' : 'Synonyms'}</Td>
                            <Td size={3.5}>
                                <p
                                    // style={{
                                    //     overflow: 'hidden',
                                    //     marginTop: 15,
                                    //     whiteSpace: 'normal',
                                    //     wordBreak: 'break-all',
                                    //     paddingRight: 5
                                    // }}
                                    className="synonyms_p"
                                >
                                    {value.type === 'synonyms'
                                        ? converter(value.synonyms ? value.synonyms : [])
                                        : converter(value.patterns ? value.patterns : [])}
                                </p>
                            </Td>
                            <Td size={1.5}>
                                <span className="entity_span">
                                    {formatDateInCard(
                                        value.updated.includes('Z') ? value.updated : value.updated + 'Z'
                                    )}
                                </span>
                                <span className="entity_span">|</span>
                                <span className="entity_span">
                                    {formatAMPM(value.updated.includes('Z') ? value.updated : value.updated + 'Z')}
                                </span>
                            </Td>
                            <Td size={1}>
                                {PageState.action !== ActionT.SELECT && PageState.action !== ActionT.MULTIPLE_DELETE && (
                                    <div className="entity_table_row_1 intent_table_row_1">
                                        {permissions.entityValueWritePermission&&
                                        <span className="home_edit" onClick={() => rest.edit()}>
                                            <EditIcon />
                                        </span>}
                                        {permissions.entityValueDeletePermission && 
                                        <span className="home_edit" onClick={() => rest.delete()}>
                                            <DeleteIcon />
                                        </span>}
                                    </div>
                                )}
                            </Td>
                        </>
                    )
                }}
            />
        </Tr>
    )
}

export default AssistantEntityValues
