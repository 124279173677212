import Downshift from 'downshift';
import Highlight, { defaultProps } from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/nightOwl';
import * as R from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';
import { HeaderRight } from '@/common/styled/FaqPopup.Dumb';
import { VideoLink } from '@/Videos/Videos';

import { AssistantT } from '@/Assistant/Construction/kind';
import { getAssistants } from '@/Assistant/Mechanisms/action';
import { makeEventEntry } from '@/Journey/JourneyMachine';
import {
  ArrowIcon,
  AttachmentIcon,
  CloseIcon,
  DeleteIcon,
  DownArrowCircle,
  EditIconCircle,
  ExitIcon,
  HelpIcon,
  InfoIcon,
  RestartIcon,
  UserIconAnalytics,
  WidgetSendIcon,
  QaIconWithCircle,AvatarBot1,AvatarBot2,AvatarBot3,AvatarBot4,AvatarBot5,AvatarBot6,CopyIocn, CheckIcon,SuccessIcon
} from '@/common/Icons/Icons';
import { LoadingSvgImage } from '@/common/Icons/LoadingSvgImage';
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons';
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary';
import { Header, HeaderChild } from '@/common/components/Header';
import { Loader } from '@/common/components/Loader';
import { Modal, ModalSnippet } from '@/common/components/Modal';
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb';
import { ButtonContainer } from '@/common/styled/Dialog.Canvas.Nodes.Dumb';
import {
  EmptyDiv,
  HeaderLeft,
  Paragraph as ParaG,
  PopupContainer,
  PopupScrollHeight,
  PopupWrapper,
  SmallButton,
  Wrapper,
  Header as PopupHeader,
  Input,
  InputContainer,
  InputContent,
  InputWrapper,
  Textarea,
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb';
import adaptive_logo from '@/common/images/adaptive_logo.png';
import logoImg from '@/common/images/logo.png';
import { postJson, widgetIntegrationURL,getJson } from '@/common/utils/api-utils';
import { workspace } from './Widget';
import { MOCK_WIDGET } from '../../../shared/common/src/utils/utils';
import { mockData } from './types';
import {
  Action,
  ErrorPage,
  Loading,
  Modes,
  UnauthorizedPage,
  View,
} from './types';


export const Pre = styled.pre`
  text-align: left;
  padding: 1.2em;
  border-radius: 6px;
  position: relative;
  max-height: 100%;
  overflow: scroll;
  position: relative;
  padding-top: 40px;
  background: #0d2167 !important;

  & .token-line {
    line-height: 1.3em;
    height: 1.3em;
  }
`;

export const LineNo = styled.span`
  display: inline-block;
  width: 2em;
  user-select: none;
  opacity: 0.3;
`;

const CopyIcon = styled.span`
  width: 18px;
  height: 18px;
  position: relative;
  fill: rgb(255, 255, 255);
  top: -26px;
  cursor: pointer;
  right: 0px;
  float: right;
  background: #000;
  svg {
    width: 18px;
    height: 18px;
    position: fixed;
    z-index: 99;
    background: #011627;
  }
`;

const BodyWrapper = styled.div`
  width: 100%;
  // float: left;
  padding-left: 50px;
  padding-right: 40px;
  height: 100%;

  // .col-md-12.p-0.d-grid {
  //   display: grid;
  //   grid-template-columns: 48.3% 12% 480px;

  //   @media (max-width: 1736px) {
  //     justify-content: flex-start;
  //     grid-template-columns: 52.3% 8% 480px;
  //   }
  //   @media (max-width: 1600px) {
  //     justify-content: space-between;
  //     grid-template-columns: 53.3% 60px 480px;
  //   }
  //   @media (max-width: 1400px) {
  //     justify-content: space-between;
  //     grid-template-columns: 53% 40px 380px;
  //   }
  }
`;

const Box = styled.div`
  width: 100%;
  float: left;
  border: 1px solid #d1d1d1;
  margin-bottom: 0px;
  border-radius: 18px;
  min-height: 40px;
  background: #fff;
  margin-top: 40px;

  @media (max-width: 1736px) {
    margin-top: 20px;
  }
  @media (max-width: 1600px) {
    margin-top: 20px;
  }
`;
const Row = styled.div`
  padding: 30px 0px 0px 0px;
  width: 100%;
  float: left;
  margin: 0px;
  height: 100%;
  position: relative;

  @media (max-width: 1736px) {
    padding: 15px 0px 0px 0px;
  }
  @media (max-width: 1600px) {
    padding: 15px 0px 0px 0px;
  }
`;
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 20px;
  align-items: center;
  width: 100%;
  float: left;
  margin-bottom: 12px;
  position: relative;
`;

const SvgIcon = styled.div`
  width: auto;
  float: left;
  height: 24px;
  float: left;
  position: relative;
  display: flex;
  align-items: center;

  .edit_icon_widget {
    margin-right: 7px;
  }

  svg {
    width: 18px;
    height: 18px;
    position: relative;
    top: 2px;
    cursor: pointer;
  }
  .side_menu_hvr {
    position: absolute;
    color: #fff;
    background: #232a44;
    left: -15px;
    margin-top: 0px;
    padding: 2px 10px;
    border-radius: 3px;
    visibility: hidden;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    top: -22px;
  }
  :hover .side_menu_hvr {
    visibility: visible;
  }
`;
const HeaderDescriptions = styled.h4`
  font-size: 18px;
  font-family: 'Gordita-Medium';
  color: #494949;
  margin-bottom: 0px;

  @media (max-width: 1600px) {
    font-size: 14px;
  }
`;

const SectionWrapper = styled.div`
  width: 100%;
  float: left;
  background: #f4f4f4;
  padding: 20px;
  margin-bottom: 10px;

  @media (max-width: 1736px) {
    padding: 20px;
  }
  @media (max-width: 1600px) {
    padding: 20px;
  }
  h4{
    font-family: 'Gordita-Medium';
    font-size: 14px;
    color: #000;
    margin-bottom: 12px;
  }
`;
const OverallHeightWarpper = styled.div`
  width: 100%;
  float: left;
  padding: 0px 20px;
  height: 100%;
  overflow-y: inhrit;
  padding-bottom: 20px;

  @media (max-width: 1736px) {
    padding-bottom: 10px;
    height: calc(100vh - 22vh);
  }
`;

const FormWrapper = styled.div`
  flex-direction: row;
  width: 100%;
  float: left;
  padding: 0px;
  margin-bottom: 0px;
  display: flex;

  .buton_wrapper {
    width: 100%;
    float: left;
    display: flex;
    justify-content: flex-end;

    button {
      width: max-content;
      height: 24px;
      font-family: 'Gordita-Medium';
      font-size: 12px;
      text-align: center;
      padding: 0 15px;
      border-radius: 4px;
      border: 0.5px solid #0a89e8;
      background: #0a89e8;
      color: #fff;
      outline: 0;
      display: flex;
      align-items: center;
      cursor: pointer;

      :last-child {
        border: none;
        background: #fff;
        color: #0a89e8;
        margin-right: 12px;
      }
    }
  }
`;
const FormHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  float: left;
  font-family: 'Gordita-Medium';
  font-size: ${(props) => (props.fontsize ? props.fontsize : 16)}px;
  margin-bottom: 0px;
  color: ${(props) => (props.color ? props.color : '#000')};
  // max-width: max-content;
  margin-right: 12px;
  margin-bottom: 10px;
  // justify-content: space-between;
  max-width: none;

  // @media (max-width: 1400px) {
  //   font-size: 16px;
  // }

  .target.customTip{
    width: 12px;
    top: 1px;
    margin-left: 5px;
  }

  .target.customTip svg{
    color: #999;
  }

  .show_hide._auto_switch{
    position: absolute;
    right: 0;
  }
`;
const FormWithoutInput = styled.div`
  margin: 0px;
  border: 1px solid #d1d1d1;
  width: 100%;
  height: 30px;
  padding-left: 10px;
  display: inline-flex;
  font-family: 'Gordita-Regular';
  align-items: center;
  font-size: 14px;
  border-radius: 6px;
  color: #494949;
  background: #fff;
`;
const FormWithInput = styled.input`
  width: 100%;
  float: left;
  font-size: 12px;
  padding: 26px 12px 6px 12px;
  font-family: 'Gordita-Medium';
  border-radius: 6px;
  border: 0.5px solid #ccc;
  outline: 0;
  background: #fff;
  position: relative;
  height: 48px;
`;
const DrodownMenu = styled.div`
  width: 100%;
  float: left;
  position: relative;
`;
const DrodownMenuOpen = styled.div`
  width: 100%;
  position: absolute;
  margin-top: 48px;
  border-radius: 8px;
  right: 0px;
  left: 0%;
  cursor: pointer;

  ul {
    padding: 0px;
    height: 100%;
    overflow-y: scroll;
    max-height: 305px;
    margin-bottom: 0px;
    box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
    border-radius: 6px;
    position: relative;
    width: 100%;
    float: left;
    z-index: 5;
  }
  li {
    width: 100%;
    float: left;
    display: inline-flex;
    align-items: center;
    border-bottom: 0.5px solid #e6e6e6;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    height: 30px;
    padding: 0 10px;
    background: #fff;
    cursor: pointer;  
    color: #000;
    :hover {
      background: #ffe5d3;
      color: #333;
      border-bottom: 0.5px solid #ffe5d3;
    }

    :first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      background: #fff;
      :hover {
        background: #ffe5d3;
        color: #333;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom: 0.5px solid #ffe5d3;
      }
    }
    :last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      background: #fff;
      border: none;
      :hover {
        background: #ffe5d3;
        color: #333;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border-bottom: 0.5px solid #ffe5d3;
      }
    }
  }
`;
const Paragraph = styled.p`
  font-family: 'Gordita-Regular';
  font-size: 11px;
  margin: 10px 0 10px 3px;
  width: 100%;
  color: ${(props) => (props.color ? props.color : '#607794')};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const LogoWrapper = styled.div`
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
`;
const LogoUpload = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 5px 0 10px 0;
  position: relative;
  right: 6px;
}
`;
const ButtonUpload = styled.div`
  width: 36px;
  float: left;
  display: flex;
  flex-direction: column;
  position: relative;

  .connect_account_loading {
    width: auto;
    float: left;
    position: absolute;
    right: -30px;
    top: 8px;
  }
  .connect_account_failed .error_input_show {
    position: absolute;
    width: 15px;
    height: 15px;
    float: left;
    left: inherit;
    margin-top: 0px;
    bottom: 4px;
    right: -24px;
  }
  .error_menu_hvr {
    margin-left: -108px;
    margin-top: 0px;
    margin-bottom: 22px;
  }

  .buton_remove {
    background: none;
    border: none;
    color: #0a89e8;
    cursor: default;
    height: auto;
    margin-top: 5px;

    span {
      cursor: pointer;
    }
  }
`;
const LogoSize = styled.div`
    width: 48px;
    height: 48px;
    float: left;
    // border: 1px solid #607794;
    // border-radius: 50%;
    padding: 2px;
    position: relative;

    @media (max-width:1500px){
        width: 48px;
        height: 48px
        padding: 2px;
    }

    img {
        width: 100%;
        height: 100%;
        border: 1px solid #CECECE;
        border-radius: 50%;
    }

    svg {
        width: 48px;
        height: 48px;
    }
`;

const ColourWrapper = styled.div`
  width: 100%;
  flaot: left;
`;
const ColourPickerDot = styled.span`
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid #ccc;
    background:${(props) => (props.background ? props.background : '#fff')}
    position: absolute;
    right: 15px;
    top: 33px;
    z-index: 0;
    cursor: pointer;

    @media(max-width:1920px){
      top: 15px;
    }
    @media(max-width:1600px){
        top: 16px;
    }
    @media(max-width:1400px){
        top: 18px;
    }
`;
const ColourWrapperInputs = styled.div`
  width: 100%;
  float: left;
  display: flex;
  flex-wrap: wrap;

  ${InputContainer} {
    width: 50%;
    float: left;
    position: relative;

    .sketch-picker {
      padding-bottom: 40px !important;
      width: 240px !important;
      
      .buton_wrapper button span{
        position: relative;
        top: 1px;
      }
    }

    :first-child {
      padding-right: 5px;
    }
    :nth-child(2) {
      padding-left: 5px;
      ${ColourPickerDot} {
        right: 12px;
      }
    }
    :nth-child(3) {
      padding-right: 5px;
    }
    :last-child {
      padding-left: 5px;
      margin-bottom: 10px
    }
    ${FormHeader} {
      font-family: 'Gordita-Regular';
      position: relative;
      .info_icon {
        width: auto;
        height: 100%;
        margin-left: 5px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;

        .side_menu_hvr {
          position: absolute;
          color: #fff;
          background: #232a44;
          left: inherit;
          margin-top: 0px;
          padding: 2px 10px;
          border-radius: 3px;
          display: none;
          font-size: 12px;
          font-family: 'Gordita-Regular';
          top: -25px;
          height: auto;
          right: -35px;
          width: max-content;
        }
        svg {
          width: 12px;
          height: 12px;
        }
        :hover svg ~ .side_menu_hvr {
          display: flex;
        }
      }
    }
    .buton_wrapper{
      position: relative;
      bottom: 32px;
      display: flex;
      justify-content: flex-start;
      gap: 8px;
      left: 10px;
      
      button{
        background: #007BFF;
        color: #fff;
        border-radius: 18px;
        padding: 2px 8px;
        min-width: 50px;

        :hover{
          border: 0.5px solid #ffe5d3;
          background: #ffe5d3;
          color: #333;
          box-shadow: rgb(0 0 0 / 16%) 0 3px 6px;
        }
      }
    }
  }
`;
const Form50Wrapper = styled.div`
  width: 100%;
  float: left;
  display: grid;
  grid-template-columns: 48% 48%;
  grid-gap: 20px;
  align-items: center;

  ${FormWrapper} {
    width: 100%;
    float: left;
    flex-direction: column;
    position: relative;

    ${FormHeader} {
      margin-bottom: 10px;

      p {
        margin-top: 3px;
      }
      @media (max-width: 1736px) {
        margin-bottom: 10px;
      }

      @media (max-width: 1600px) {
        margin-bottom: 5px;
      }
      @media (max-width: 1400px) {
        font-size: 12px;
        p {
          font-size: 11px;
          white-space: pre;
        }
      }
    }
  }
`;
const Form50WrapperLogo = styled.div`
    width: 100%;
    float: left;
    display: grid;
    margin-top:20px
    grid-template-columns: 30% 66%;
    grid-gap: 20px;

    @media (max-width:1600px){
        margin-top:10px
    }
    
    ${FormWrapper}{
        :nth-child(1){
            margin-bottom:12px;
        }
        :nth-child(2){
            margin-bottom:12px;
        }
        :nth-child(4){
            button{
                background: #007BFF 0% 0% no-repeat padding-box;
                border-radius: 19px;
                width: 120px;
                height: 32px;
                font-size: 16px;
                font-family: 'Gordita-Bold';
                color: #fff;
                outline: 0;
                position: relative;
                top: 20px;
                border: 1px solid#007BFF;
                cursor: pointer;
                margin-top: 8px;

                @media (max-width:1400px){
                    width: max-content;
                    padding:0px 20px;
                    font-size: 13px;
                    height:28px;
                    top:17px;
                }
            }

            
        }
    }
    ${FormHeader}{
        margin-bottom:12px;
        @media (max-width:1600px){
            margin-bottom:5px;
        }
    }
    ${LogoWrapper}{
        ${FormHeader}{
            margin-right:0px;
            max-width: 100%;
        }
    }
    ${ButtonUpload}{
        align-items: center;
        padding-left: 15px;
        display: flex;
        flex-direction: row;
        .selectFileButton{
            height:18px;
        }
    }
    .upload_icon svg{
        width:18px;
        height:18px;
        fill:#666666;
        cursor:pointer;
        transform: rotate(-90deg);
        margin-right:10px;
    }
    .remove_icon svg{
        width:18px;
        height:18px;
        color:#666666;
        cursor:pointer;
    }
    ${FormWrapper} {
        width: 50%;
        float: left;
        flex-direction: column;
        position: relative;

        ${FormHeader} {
            margin-bottom: 10px;
            @media (max-width:1600px){
                margin-bottom: 5px;
            }
          
        }
    }
`;
const WidgetFormToggle = styled.section`
  width: 100%;
  float: left;
  position: relative;
  ${SectionWrapper} {
    // border-top: 1px solid #ccc;
  }
  margin-top: 0px;
  height: ${(props: any) => (props.height ? 'calc(100vh - 330px)' : null)};
  overflow-y: ${(props: any) => (props.height ? 'scroll' : 'inherit')};

  @media (max-width: 1800px) {
    height: ${(props: any) => (props.height ? 'calc(100vh - 330px)' : null)};
  }
  @media (max-width: 1600px) {
    height: ${(props: any) => (props.height ? 'calc(100vh - 280px)' : null)};
  }
  @media (max-width: 1400px) {
    height: ${(props: any) => (props.height ? 'calc(100vh - 250px)' : null)};
  }
`;
const WidgetToggleHeader = styled.div`
  width: 100%;
  float: left;
  padding: 20px 20px 0px 20px;
  background: #f4f4f4;

  .arrow_up_form {
    margin-top: 0px;
    left: 0px;
    width: 18px;
    height: 18px;

    svg {
      transform: rotate(90deg);
    }
  }

  ${FormHeader} {
    width: auto;
    padding-bottom: 15px;
    @media (max-width: 1736px) {
      padding-bottom: 10px;
    }

    @media (max-width: 1600px) {
      padding-bottom: 5px;
    }
  }
  @media (max-width: 1736px) {
    padding: 15px 15px 0px 15px;
  }

  @media (max-width: 1600px) {
    padding: 10px 10px 0px 10px;
  }
`;

const WidgetUpdateButton = styled.div`
  button {
    font-size: 12px !important;
    justify-content: center;
    top: 5px !important;
    width: max-content !important;

    label{
      color: rgb(73, 73, 73);
    }
  }

`;

const popover = {
  position: 'absolute',
  zIndex: '2',
  left: '-20px',
} as any;

const cover = {
  position: 'fixed',
  top: '0px',
  right: '0px',
  bottom: '0px',
  left: '0px',
} as any;

export const WidgetManipulate = (props: any) => {
  const [state, setState] = useState<{
    bots: AssistantT[];
    PageState: Modes;
    selecteBot: { id: string; name: string };
    showBotsDropDown: boolean;
    widgetName: string;
    widgetDes: string;
    is_secured: boolean;
    mode: 'add' | 'edit';
    workspaceId: string;
    public_key: string;
    PageError: any;
    popup: {
      showPopupLoader: boolean;
      modalError: { error: boolean; info: null | string };
    };
    snippet: string;
    logoError: {
      error: boolean;
      info: string;
      loading: boolean;
    };
  }>({
    bots: [],
    PageState: View,
    workspaceId: '',
    selecteBot: { id: '', name: '' },
    showBotsDropDown: false,
    widgetName: '',
    widgetDes: '',
    is_secured: false,
    mode: 'add',
    public_key: '',
    PageError: {},
    popup: { showPopupLoader: false, modalError: { error: false, info: null } },
    snippet: exampleCode,
    logoError: {
      error: false,
      info: '',
      loading: false,
    },
  });
  const selectedWorkspace:workspace = props.props
  // console.log(props,selectedWorkspace.workspace)
  // console.log(state.PageState,state.mode,'PageState')

  const [defaultState, setdDefaultState] = useState<{
    selecteBot: { id: string; name: string };
    widgetName: string;
    widgetDes: string;
    is_secured: boolean;
    public_key: string;
    colors: {
      primary_color: string;
      secondary_color: string;
      accent_color: string;
      button_color: string;
    };
    logo_url: string;
  }>({
    selecteBot: { id: '', name: '' },
    widgetName: 'Workativ Assistant',
    widgetDes: 'Your workplace assistant',
    is_secured: false,
    public_key: '',
    colors: {
      primary_color: '#000000',
      secondary_color: '#E3F6FF',
      accent_color: '#FFE5D3',
      button_color: '#E3F6FF',
    },
    logo_url:
      'https://ac-image.s3.amazonaws.com/8/8/7/3/4/1/home/admin/images_for_product/logo.png?r=1663289093',
  });

  const [colorPicker, setColorPicker] = useState({
    showPicker: '',
    prevColor: '',
    colors: {
      primary_color: '#000000',
      secondary_color: '#E3F6FF',
      accent_color: '#FFE5D3',
      button_color: '#E3F6FF',
    },
  });

  const [file, setFileValue] = useState(
    // 'https://ac-image.s3.amazonaws.com/8/8/7/3/4/1/home/admin/images_for_product/logo.png?r=1663289093'
    "https://assistant-widget-logo.s3.amazonaws.com/bot_1.svg"
  );
  const [enableEditMode, setEnableEditMode] = useState(false);

  const inpRef = useRef<null | HTMLInputElement>(null);
  const [show, setShow] = useState(true);

  const [classname, showClassName] = useState(true);

  const [firstSection, setFirstSection] = useState(true);

  const avatarArray = [
    {
      name:"AvatarBot1",
      icon:AvatarBot1,
      url: "https://assistant-widget-logo.s3.amazonaws.com/bot_1.svg"
    },
     {
      name:"AvatarBot2",
      icon:AvatarBot2,
      url: "https://assistant-widget-logo.s3.amazonaws.com/bot_2.svg"
    },
    {
      name:"AvatarBot3",
      icon:AvatarBot3,
      url:"https://assistant-widget-logo.s3.amazonaws.com/bot_3.svg"
    },
    {
      name:"AvatarBot4",
      icon:AvatarBot4,
      url:"https://assistant-widget-logo.s3.amazonaws.com/bot_4.svg"
    },
    {
      name:"AvatarBot5",
      icon:AvatarBot5,
      url:"https://assistant-widget-logo.s3.amazonaws.com/bot_5.svg"
    },
    {
      name:"AvatarBot6",
      icon:AvatarBot6,
      url:"https://assistant-widget-logo.s3.amazonaws.com/bot_6.svg"
    },
  ]
  useEffect(() => {
    // if (props.state.type == 'loading') {
    //   setState({ ...state, PageState: Loading });
    // } else if (props.state.type == 'error') {
    //   if (MOCK_WIDGET === false) {
    //     if (
    //       props.state.error.response &&
    //       props.state.error.response.status == 403
    //     ) {
    //       setState({
    //         ...state,
    //         PageState: UnauthorizedPage,
    //       });
    //     } else {
    //       setState({
    //         ...state,
    //         PageState: ErrorPage,
    //         PageError: {
    //           error: true,
    //           info: 'There was a problem in loading Page! <br/> Please click on "Retry"',
    //         },
    //       });
    //     }
    //   } else {
    //     getAssistants(props.match.params.workspacename)
    //       .then((data) => {
    //         console.log(data,'botdata')
    //         const res = mockData;
    //         const details = res.filter(
    //           (w: workspace) => w.id == props.match.params.id
    //         )[0];
    //         if (props.match.params.id != 'create' && details) {
    //           const {
    //             id,
    //             name,
    //             bot_id,
    //             description,
    //             is_secured,
    //             primary_color,
    //             secondary_color,
    //             accent_color,
    //             button_color,
    //             logo_url,
    //             public_key,
    //             snippet,
    //           } = details;

    //           setState({
    //             ...state,
    //             mode: 'edit',
    //             selecteBot: {
    //               id: bot_id,
    //               name: data.filter((b) => b.id == bot_id)[0]
    //                 ? data.filter((b) => b.id == bot_id)[0].name
    //                 : '',
    //             },
    //             workspaceId: id,
    //             showBotsDropDown: false,
    //             widgetName: name,
    //             widgetDes: description,
    //             is_secured: is_secured,
    //             PageState: View,
    //             bots: data,
    //             public_key: public_key,
    //             snippet,
    //           });

    //           setdDefaultState({
    //             selecteBot: {
    //               id: bot_id,
    //               name: data.filter((b) => b.id == bot_id)[0]
    //                 ? data.filter((b) => b.id == bot_id)[0].name
    //                 : '',
    //             },
    //             widgetName: name,
    //             widgetDes: description,
    //             is_secured: is_secured,
    //             public_key: public_key as any,
    //             colors: {
    //               primary_color,
    //               secondary_color,
    //               accent_color,
    //               button_color,
    //             },
    //             logo_url: logo_url,
    //           });
    //           setColorPicker({
    //             ...colorPicker,
    //             colors: {
    //               primary_color,
    //               secondary_color,
    //               accent_color,
    //               button_color,
    //             },
    //           });
    //           setFileValue(logo_url);
    //           setEnableEditMode(true);
    //         } else {
    //           setState({ ...state, PageState: View, bots: data });
    //         }
    //       })
    //       .catch((err) => {
    //         if (err.response && err.response.status == 403) {
    //           setState({
    //             ...state,
    //             PageState: UnauthorizedPage,
    //           });
    //         } else {
    //           setState({
    //             ...state,
    //             PageState: ErrorPage,
    //             PageError: {
    //               error: true,
    //               info: 'There was a problem in loading Page! <br/> Please click on "Retry"',
    //             },
    //           });
    //         }
    //       });
    //   }
    // } else if (props.state.type == 'success') {
      if(props.type == 'Update'){
      getAssistants(selectedWorkspace.workspace)
        .then((data) => {
          const details:workspace = selectedWorkspace
          // const res = props.state.data;
          // const details = res.filter(
          //   (w: workspace) => w.id == props.match.params.id
          // )[0];
          if (props.type != 'create' && details) {
            const {
              id,
              name,
              bot_id,
              description,
              is_secured,
              primary_color,
              secondary_color,
              accent_color,
              button_color,
              logo_url,
              public_key,
              snippet,
            } = details;
            setState({
              ...state,
              mode: 'edit',
              selecteBot: {
                id: bot_id,
                name: data.filter((b) => b.id == bot_id)[0]
                  ? data.filter((b) => b.id == bot_id)[0].name
                  : '',
              },
              workspaceId: id,
              showBotsDropDown: false,
              widgetName: name,
              widgetDes: description,
              is_secured: is_secured,
              PageState: View,
              bots: data,
              public_key: public_key,
              snippet,
            });
            setdDefaultState({
              selecteBot: {
                id: bot_id,
                name: data.filter((b) => b.id == bot_id)[0]
                  ? data.filter((b) => b.id == bot_id)[0].name
                  : '',
              },
              widgetName: name,
              widgetDes: description,
              is_secured: is_secured,
              public_key: public_key as any,
              colors: {
                primary_color,
                secondary_color,
                accent_color,
                button_color,
              },
              logo_url: logo_url,
            });
            setColorPicker({
              ...colorPicker,
              colors: {
                primary_color,
                secondary_color,
                accent_color,
                button_color,
              },
            });
            setFileValue(logo_url);
            setEnableEditMode(true);
          } else {
            setState({ ...state, PageState: View, bots: data });
          }
        })
        .catch((err) => {
          if (err.response && err.response.status == 403) {
            setState({
              ...state,
              PageState: UnauthorizedPage,
            });
          } else {
            setState({
              ...state,
              PageState: ErrorPage,
              PageError: {
                error: true,
                info: 'There was a problem in loading Page! <br/> Please click on "Retry"',
              },
            });
          }
        });
    } else if (props.type == 'Create'){
      getAssistants(props.props)
        .then((data) => {
          setState({ ...state, PageState: View, bots: data, mode:'add' });
        })
        .catch((err) => {
          if (err.response && err.response.status == 403) {
            setState({
              ...state,
              PageState: UnauthorizedPage,
            });
          } else {
            setState({
              ...state,
              PageState: ErrorPage,
              PageError: {
                error: true,
                info: 'There was a problem in loading Page! <br/> Please click on "Retry"',
              },
            });
          }
        });
    }
  }, [props.props]);

  if (state.PageState.action === Action.Loading)
    return <Loader.PageLoader show={true} />;
  
  const onFileChange = (event: any) => {
    const input = document.getElementById('___SelectFileButton___') as any;
    const file = input && input.files[0];
    const fsize = file && Math.round(file.size / 1024);

    const fileType = file['type'];
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    if (input && fsize && validImageTypes.includes(fileType) && fsize < 1024) {
      onFileUpload(event);
      setState({
        ...state,
        logoError: { error: false, info: '', loading: true },
      });
    } else {
      setState({
        ...state,
        logoError: {
          error: true,
          info: 'Please upload an image that is below 1mb',
          loading: false,
        },
      });
    }
  };
  const setAvatar=(name:string)=>{
    const file = avatarArray.filter((avatar)=>
      avatar.name === name
    )
    const fileUrl= file[0]?.url
    setFileValue(fileUrl)
  }

  const onFileUpload = (event: any) => {
    setState({ ...state, popup: { ...state.popup, showPopupLoader: true } });
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('category', 'image');
    postJson(
      widgetIntegrationURL(
        `${selectedWorkspace.workspace}/${state.selecteBot.id}`,
        `/file_upload`
      )
    )(formData)
      .then((res) => {
        setFileValue(res.data.publicurl);
        setState({
          ...state,
          logoError: { error: false, info: '', loading: false },
        });
      })
      .catch((err) => {
        setState({
          ...state,
          logoError: {
            error: true,
            info: 'Something went wrong! Please try again.',
            loading: false,
          },
        });
      });
  };

  function createWorkspace() {
    setState({ ...state, popup: { ...state.popup, showPopupLoader: true } });
    postJson(
      widgetIntegrationURL(
        `${props.props}/${state.selecteBot.id}`,
        '/widget/save'
      )
    )({
      name: state.widgetName,
      description: state.widgetDes, 
      is_active: true,
      logo_url: file,
      primary_color: colorPicker.colors.primary_color,
      secondary_color: colorPicker.colors.secondary_color,
      accent_color: colorPicker.colors.accent_color,
      button_color: colorPicker.colors.button_color,
      is_secured: state.is_secured,
      public_key: state.is_secured ? state.public_key : '',
    })
    .then((data)=> 
        getJson(widgetIntegrationURL(props.props, '/widget/list/'))
      )
      .then((res) => {
        // console.log(res, 'workspacewidget')
        props.setWorkspace(res)
        setState({
          ...state,
          mode: 'edit',
          workspaceId: res.data.id,
          snippet: res.data.snippet,
          popup: { ...state.popup, showPopupLoader: false },
        });
        setdDefaultState({
          selecteBot: state.selecteBot,
          widgetName: state.widgetName,
          widgetDes: state.widgetDes,
          is_secured: state.is_secured,
          public_key: state.is_secured ? state.public_key : '',
          colors: colorPicker.colors,
          logo_url: file,
        });
        // props.cancel()
        // window.location.reload()
      })
      .catch((err) => {
        setState({
          ...state,
          popup: {
            showPopupLoader: false,
            modalError: {
              info: 'Something went wrong! Please try again.',
              error: true,
            },
          },
        });
      });
  }
  function handleClickSCroll() {
    const element = document.getElementById('scrollup')
    element?.scrollIntoView({behavior:"smooth"})
  }

  function makeEventEntryForDeploy() {
    makeEventEntry(selectedWorkspace.workspace, {
      event: 'EVENT::CHATCHANNEL::DEPLOYED',
      refId: state.selecteBot.id,
    });
  }

  function updateWorkspace() {
    setState({ ...state, popup: { ...state.popup, showPopupLoader: true } });

    postJson(
      widgetIntegrationURL(
        `${selectedWorkspace.workspace}/${state.selecteBot.id}`,
        `/widget/edit/${state.workspaceId}`
      )
    )({
      name: state.widgetName,
      description: state.widgetDes,
      is_active: false,
      logo_url: file,
      primary_color: colorPicker.colors.primary_color,
      secondary_color: colorPicker.colors.secondary_color,
      accent_color: colorPicker.colors.accent_color,
      button_color: colorPicker.colors.button_color,
      is_secured: state.is_secured,
      public_key: state.is_secured ? state.public_key : '',
      bot_id: state.selecteBot.id,
    })
    .then((data)=> getJson(widgetIntegrationURL(selectedWorkspace.workspace, '/widget/list/')))
      .then((res) => {
        // console.log(res, 'workspacewidget')
        props.setWorkspace(res)
        setState({
          ...state,
          mode: 'edit',
          workspaceId: res.data.id,
          popup: { ...state.popup, showPopupLoader: false },
        });
        setdDefaultState({
          selecteBot: state.selecteBot,
          widgetName: state.widgetName,
          widgetDes: state.widgetDes,
          is_secured: state.is_secured,
          public_key: state.is_secured ? state.public_key : '',
          colors: colorPicker.colors,
          logo_url: file,
        });
        setEnableEditMode(false);
        // props.cancel()
        // window.location.reload()
      })
      .catch((err) => {
        setState({
          ...state,
          popup: {
            showPopupLoader: false,
            modalError: {
              info: 'Something went wrong! Please try again.',
              error: true,
            },
          },
        });
      });
  }

  const disableFields = state.mode == 'edit' && !enableEditMode;
  const disableUpdate = () => {
    
       // should return false for being disabled in edit mode
  
    const toReturn = (defaultState.is_secured === false
      ? state.is_secured === true
        ? !(state.public_key === '' || state.public_key === null)
        : true
      : true);
    return toReturn;
  
  };

  // should return true for being disabled in edit mode
  const disableInitialSave = () => {
    const toReturn =
      state.mode == 'edit' &&
      R.equals(defaultState.colors, colorPicker.colors) &&
      defaultState.logo_url == file &&
      defaultState.selecteBot.id == state.selecteBot.id &&
      defaultState.widgetDes == state.widgetDes &&
      defaultState.widgetName == state.widgetName &&
      // defaultState.public_key == state.public_key
      // defaultState.is_secured == state.is_secured &&
      (defaultState.is_secured === true
        ? state.is_secured === true
          ? defaultState.public_key == state.public_key ||
            state.public_key === '' ||
            state.public_key === null
          : false
        : true) &&
      (defaultState.is_secured === false
        ? state.is_secured === true
          ? state.public_key === '' || state.public_key === null
          : true
        : true);
    return toReturn;
  };



  // const disableUpdate =()=>{
  //   // const [retBool, setRetBool] = useState(false)
  //   // if((state.is_secured == true) && (state.public_key?.length ==0)) {
  //   //   setRetBool(false)
  //   // // } 
  //   // // if((state.is_secured == true) && (state.public_key?.length > 0)) {
  //   // //     if (defaultState.public_key == state.public_key) {
  //   // //     setRetBool(false)
  //   // //   }
  //   // //   else{setRetBool(true)
  //   // //   }

  //   // } else if((defaultState.is_secured != state.is_secured)&& (state.public_key?.length > 0)){
        
  //   //       setRetBool(true)
  //   //     }
  //     // const retValue = (state.is_secured == true) && (state.public_key?.length ==0)
  //   // ? false : ((defaultState.public_key == state.public_key) ? false : true ) 
  

  //   const retValue = (state.is_secured == true) && (state.public_key?.length >0) 
  //   ?  true: (
  //     // defaultState.public_key == state.public_key)
  //     defaultState.is_secured !== state.is_secured )
  //   return retValue
  //  }

  // const disableInitialSave = () => {
  //   return (
  //     state.mode == 'edit' &&
  //     R.equals(defaultState.colors, colorPicker.colors) &&
  //     defaultState.logo_url == file &&
  //     defaultState.selecteBot.id == state.selecteBot.id &&
  //     defaultState.widgetDes == state.widgetDes &&
  //     defaultState.widgetName == state.widgetName &&
  //     defaultState.public_key == state.public_key 
  //     // defaultState.is_secured == state.is_secured
  //   );
  // };

  // console.log(disableUpdate(),disableInitialSave(),'disableUpdate')
  
  return (
    <ErrorBoundary
      unauthorized={state.PageState.action === Action.Unauthorized}
      error={
        state.PageState.action !== Action.ErrorPage
          ? { error: null, info: null }
          : {
              error: true,
              info: state.PageError.info,
            }
      }
      render={(err: any, info: any) => {
        return (
          err && (
            <Error.PageLoadingError
              onClick={() => {
                props.retry();
              }}
              info={info}
              btnName={'Retry'}
            />
          )
        );
      }}
    >
      <Header>
        {[
          <HeaderChild type="first">
            {/* <span
              className="buton_back inner_pages"
              onClick={() => window.history.back()}
            >
              <ArrowIcon /> 
              <span>Back</span>
            </span> */}
            <div className="indent_left">
              <div className="indent_title">Widget Integration</div>
              {/* <p className="content_header">
                                Deploy your chatbot on your favourite chat applications.{' '}
                            </p> */}
            </div>
          </HeaderChild>,
        ]}
      </Header>
      <BodyWrapper className="widget_wrapper">
        <div className="col-md-12 p-0 d-grid">
          {/* <Box className="widget_form">
            <Row className="Parent"> */}
              <Wrapper className="create_dialog_popup">
                <PopupWrapper>
                  <PopupHeader>
                            <HeaderLeft minWidth={400}>
                              Chatbot deployment
                              <Tooltip
                                className="target customTip"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content={"dbvdfsbvdfsbvib iuobfvb ds"}
                                distance={5}
                              >
                                <InfoIcon />
                              </Tooltip>
                              <a
                                href="https://help.workativ.com/knowledgebase/widget/"
                                target="_blank"
                              >
                                <Tooltip
                                  className="target customTip"
                                  zIndex={10000}
                                  arrowSize={8}
                                  tagName="span"
                                  content="View help document"
                                  distance={5}
                                >
                                  <QaIconWithCircle />
                                </Tooltip>
                              </a>
                            </HeaderLeft>
                            
                            <HeaderRight>
                              {/* <VideoLink
                                id={'SET_CONDITIONS_FOR_A_DIALOG'}
                                text={'How to create'}
                              /> */}
                            </HeaderRight>
                          </PopupHeader>
                          <Paragraph>Deploy chatbot as chat widget</Paragraph>
                        {/* <HeaderWrapper>
                          <HeaderDescriptions>
                            Widget Integration Details{' '}
                          </HeaderDescriptions>
                          <>
                            <div className="icon_right">
                              <SvgIcon>
                                {!enableEditMode && state.mode != 'add' && (
                                  <span
                                    className="edit_icon_widget"
                                    onClick={() => setEnableEditMode(true)}
                                  >
                                    <EditIconCircle />
                                  </span>
                                )}
                              </SvgIcon>
                              <SvgIcon>
                                <a
                                  href={'https://help.workativ.com/knowledgebase/widget/'}
                                  target="_blank"
                                >
                                  <HelpIcon />
                                </a>
                                <span className="side_menu_hvr">Help</span>
                              </SvgIcon>
                            </div>
                          </>
                        </HeaderWrapper> */}
              <OverallHeightWarpper
                className={
                  state.mode == 'edit' && classname ? 'overflow_height' : ''
                }
                style={{padding: 0}}
              >
                <SectionWrapper>
                  <FormWrapper>
                    {/* <FormHeader>Select Chatbot</FormHeader> */}
                    {/* <FormWithoutInput>VinodDev_IT_Bot1</FormWithoutInput> */}
                    <DrodownMenu>
                      <Input
                        placeholder="Select"
                        value={state.selecteBot.name}
                        onClick={() => {
                          setState({
                            ...state,
                            showBotsDropDown: true,
                          });
                        }}
                        className={disableFields ? 'editor_btn_disabled' : ''}
                      />
                      <InputContent style={{top: 10, fontSize: 10}} >Select your chatbot to deploy</InputContent>
                      <div className="arrow_up_form">
                        <DownArrowCircle />
                      </div>
                      {state.showBotsDropDown && (
                        <Downshift
                          isOpen={true}
                          onOuterClick={() => {
                            setState({
                              ...state,
                              showBotsDropDown: false,
                            });
                          }}
                        >
                          {() => (
                            <span>
                              <DrodownMenuOpen>
                                <ul>
                                  {state.bots
                                    .filter((b) => b.published_at != null)
                                    .map((bot, k) => (
                                      <li
                                        key={k}
                                        onClick={() => {
                                          setState({
                                            ...state,
                                            selecteBot: {
                                              name: bot.name,
                                              id: bot.id,
                                            },
                                            showBotsDropDown: false,
                                          });
                                        }}
                                      >
                                        {bot.name}
                                      </li>
                                    ))}
                                </ul>
                              </DrodownMenuOpen>
                            </span>
                          )}
                        </Downshift>
                      )}
                    </DrodownMenu>
                  </FormWrapper>
                </SectionWrapper>
                <WidgetFormToggle height>
                  {/* <WidgetToggleHeader>
                    <FormHeader>Configure Widget Branding</FormHeader> */}
                    {/* <div className="arrow_up_form" onClick={() => setShow(!show)}>
                                            <RightArrowWithCircle />
                                        </div> */}
                  {/* </WidgetToggleHeader> */}
                  {show && (
                    <SectionWrapper>
                      <h4 style ={{fontSize: 15 }}>Configure widget branding</h4> 
                      {/* <Form50Wrapper> */}
                        <InputContainer className="dialog_name_alert_content">
                          {/* <FormHeader style={{ marginTop: 3 }}>
                            Widget Name
                          </FormHeader> */}
                          <Input
                            value={state.widgetName}
                            maxLength ="25"
                            onChange={(e: any) => {
                              setState({
                                ...state,
                                widgetName: e.target.value,
                                popup: {
                                  showPopupLoader: false,
                                  modalError: { error: false, info: null },
                                },
                              });
                            }}
                            className={
                              disableFields ? 'editor_btn_disabled' : ''
                            }
                          />
                          <InputContent>Widget Name</InputContent>
                        </InputContainer>
                        <InputContainer>
                          {/* <FormHeader>
                            Description{' '}
                            <Paragraph style={{ marginLeft: '5px' }}>
                              {' '}
                              Maximum 60 characters
                            </Paragraph>
                          </FormHeader> */}
                          <Input
                            value={state.widgetDes}
                            maxLength ="60"
                            onChange={(e: any) => {
                              setState({
                                ...state,
                                widgetDes: e.target.value,
                                popup: {
                                  showPopupLoader: false,
                                  modalError: { error: false, info: null },
                                },
                              });
                            }}
                            className={
                              disableFields ? 'editor_btn_disabled' : ''
                            }
                          />
                          <InputContent>
                              Description (Maximum 60 characters)
                            </InputContent>
                        </InputContainer>
                      {/* </Form50Wrapper> */}
                      {/* <Form50WrapperLogo> */}
                        <LogoWrapper>
                          <div className="widget_logo_container" >
                            {/* style= {{display:"flex",flexDirection:"column"}}> */}
                            <div className="widget_logo_title">
                            {/* style= {{display:"flex"}}> */}
                            <h4 style={{marginBottom: 0}}>Logo</h4> 
                            <LogoSize>
                              <img alt={"logo"} src={file ? file : "https://assistant-widget-logo.s3.amazonaws.com/bot_1.svg"} />
                            </LogoSize>
                            </div>
                            <Paragraph style={{margin: '2px 0'}}>Choose an Avatar or upload your logo. Max 64 x 64 pixels. Max 1MB </Paragraph>
                            <div className="widget_logo_box">
                            {avatarArray?.map((avatar,i)=>(
                              <LogoSize className={
                                avatar.url === file ? "widget_logo_tickIocn_show" :"widget_logo_tickIocn_hide"
                              }style={{cursor:"pointer"}} onClick={()=>setAvatar(avatar.name)} >
                                {avatar.icon()}
                                <span>
                                  <CheckIcon/>
                                </span>
                              </LogoSize>
                            ))}
                                                        
                            <ButtonUpload>
                              <span className="selectFileButton">
                                <input
                                  id="___SelectFileButton___"
                                  type="file"
                                  onChange={onFileChange}
                                  hidden
                                  ref={inpRef}
                                />
                                <span
                                  onClick={() => {
                                    if (
                                      inpRef &&
                                      inpRef.current &&
                                      state.selecteBot.id.length > 0
                                    ) {
                                      inpRef.current.click();
                                    }
                                  }}
                                  className={
                                    disableFields ||
                                    state.selecteBot.id.length == 0
                                      ? 'editor_btn_disabled'
                                      : ''
                                  }
                                >
                                  {/* <button
                                                                        disabled={
                                                                            !inpRef ||
                                                                            !inpRef.current ||
                                                                            state.selecteBot.id.length == 0
                                                                        }
                                                                    >
                                                                        Upload
                                                                    </button> */}
                                  <span className="upload_icon">
                                    <svg viewBox="0 0 512 512">
                                      <path d="M255.15 468.625H63.787c-11.737 0-21.262-9.526-21.262-21.262V64.638c0-11.737 9.526-21.262 21.262-21.262H255.15c11.758 0 21.262-9.504 21.262-21.262S266.908.85 255.15.85H63.787C28.619.85 0 29.47 0 64.638v382.724c0 35.168 28.619 63.787 63.787 63.787H255.15c11.758 0 21.262-9.504 21.262-21.262 0-11.758-9.504-21.262-21.262-21.262z"></path>
                                      <path d="M505.664 240.861L376.388 113.286c-8.335-8.25-21.815-8.143-30.065.213s-8.165 21.815.213 30.065l92.385 91.173H191.362c-11.758 0-21.262 9.504-21.262 21.262 0 11.758 9.504 21.263 21.262 21.263h247.559l-92.385 91.173c-8.377 8.25-8.441 21.709-.213 30.065a21.255 21.255 0 0015.139 6.336c5.401 0 10.801-2.041 14.926-6.124l129.276-127.575A21.303 21.303 0 00512 255.998c0-5.696-2.275-11.118-6.336-15.137z"></path>
                                    </svg>
                                  </span>
                                </span>
                              </span>
                              {state.logoError.loading && (
                                <span className="connect_account_loading">
                                  <img alt="loading" src={LoadingSvgImage} />
                                </span>
                              )}
                              {state.logoError.error && (
                                <span className="connect_account_failed">
                                  <div className="error_input_show">
                                    <WarningAlertIcon />
                                    <span className="error_menu_hvr">
                                      {state.logoError.info}
                                    </span>
                                  </div>
                                </span>
                              )}
                              {/* <button>Upload</button> */}
                              {/* {file && (
                                <button
                                  title={"delete"}
                                  className={
                                    disableFields
                                      ? 'editor_btn_disabled buton_remove'
                                      : 'buton_remove'
                                  }
                                  onClick={() => setFileValue('')}
                                >
                                  <span className="remove_icon">
                                    <DeleteIcon />
                                  </span>
                                </button>
                              )} */}
                            </ButtonUpload>
                            </div>
                          </div>
                          
                        </LogoWrapper>
                        <ColourWrapper>
                          <FormHeader>Colours</FormHeader>
                          <ColourWrapperInputs>
                          <InputContainer>
                              <>
                                {/* <FormHeader fontsize={10}>
                                  Header and description
                                </FormHeader> */}
                                {colorPicker.showPicker == 'header' && (
                                  <div style={popover}>
                                    <div
                                      style={cover}
                                      onClick={() => {
                                        setColorPicker({
                                          ...colorPicker,
                                          showPicker: '',
                                        });
                                      }}
                                    />
                                    <SketchPicker
                                      color={colorPicker.colors.primary_color}
                                      onChangeComplete={(color, event) => {
                                        setColorPicker({
                                          ...colorPicker,
                                          colors: {
                                            ...colorPicker.colors,
                                            primary_color: color.hex,
                                          },
                                        });
                                      }}
                                      presetColors={[ {color:"#D0021B",title:"F5A623"}, 
                                                      {color:"#F5A623",title:"F5A623"},
                                                      {color:"#F8E71C",title:"F8E71C"},
                                                      {color:"#8B572A",title:"8B572A"},
                                                      {color:"#7ED321",title:"7ED321"},
                                                      {color:"#417505",title:"417505"},
                                                      {color:"#BD10E0",title:"BD10E0"},
                                                      {color:"#9013FE",title:"9013FE"},
                                                      {color:"#4A90E2",title:"4A90E2"}
                                                    ]}
                                    />
                                    <div
                                      className="buton_wrapper"
                                      // style={{
                                      //   position: 'absolute',
                                      //   bottom: 10,
                                      // }}
                                    >
                                      <button
                                        onClick={() => {
                                          setColorPicker({
                                            ...colorPicker,
                                            showPicker: '',
                                          });
                                        }}
                                      >
                                        <span>Ok</span>
                                      </button>
                                      <button
                                        onClick={() => {
                                          setColorPicker({
                                            ...colorPicker,
                                            showPicker: '',
                                            colors: {
                                              ...colorPicker.colors,

                                              primary_color:
                                                colorPicker.prevColor,
                                            },
                                            prevColor: '',
                                          });
                                        }}
                                      >
                                        <span>Cancel</span>
                                      </button>
                                    </div>
                                  </div>
                                )}
                                
                                <FormWithInput
                                  placeholder="choose color"
                                  value={colorPicker.colors.primary_color}
                                  className={
                                    disableFields ? 'editor_btn_disabled' : ''
                                  }
                                />
                              </>
                              <InputContent style={{top: 8, fontSize: 10}}>Header and description</InputContent>
                              <ColourPickerDot
                                  background={colorPicker.colors.primary_color}
                                  onClick={() => {
                                    handleClickSCroll(),
                                    setColorPicker({
                                      ...colorPicker,
                                      showPicker: 'header',
                                      prevColor:
                                        colorPicker.colors.primary_color,
                                    });

                                    setState({
                                      ...state,
                                      popup: {
                                        showPopupLoader: false,
                                        modalError: {
                                          error: false,
                                          info: null,
                                        },
                                      },
                                    });
                                  }}
                                  className={
                                    disableFields ? 'editor_btn_disabled' : ''
                                  }
                                />
                          </InputContainer>
                            {/* <FormWrapper>
                              <>
                                <FormHeader fontsize={10}>
                                  Header and description
                                </FormHeader>
                                <ColourPickerDot
                                  background={colorPicker.colors.primary_color}
                                  onClick={() => {
                                    setColorPicker({
                                      ...colorPicker,
                                      showPicker: 'header',
                                      prevColor:
                                        colorPicker.colors.primary_color,
                                    });

                                    setState({
                                      ...state,
                                      popup: {
                                        showPopupLoader: false,
                                        modalError: {
                                          error: false,
                                          info: null,
                                        },
                                      },
                                    });
                                  }}
                                  className={
                                    disableFields ? 'editor_btn_disabled' : ''
                                  }
                                />
                                
                                {colorPicker.showPicker == 'header' && (
                                  <div style={popover}>
                                    <div
                                      style={cover}
                                      onClick={() => {
                                        setColorPicker({
                                          ...colorPicker,
                                          showPicker: '',
                                        });
                                      }}
                                    />
                                    <SketchPicker
                                      color={colorPicker.colors.primary_color}
                                      onChangeComplete={(color, event) => {
                                        setColorPicker({
                                          ...colorPicker,
                                          colors: {
                                            ...colorPicker.colors,
                                            primary_color: color.hex,
                                          },
                                        });
                                      }}
                                      presetColors={[ {color:"#D0021B",title:"F5A623"}, 
                                                      {color:"#F5A623",title:"F5A623"},
                                                      {color:"#F8E71C",title:"F8E71C"},
                                                      {color:"#8B572A",title:"8B572A"},
                                                      {color:"#7ED321",title:"7ED321"},
                                                      {color:"#417505",title:"417505"},
                                                      {color:"#BD10E0",title:"BD10E0"},
                                                      {color:"#9013FE",title:"9013FE"},
                                                      {color:"#4A90E2",title:"4A90E2"}
                                                    ]}
                                    />
                                    <div
                                      className="buton_wrapper"
                                      style={{
                                        position: 'absolute',
                                        bottom: 10,
                                      }}
                                    >
                                      <button
                                        onClick={() => {
                                          setColorPicker({
                                            ...colorPicker,
                                            showPicker: '',
                                          });
                                        }}
                                      >
                                        <span>Ok</span>
                                      </button>
                                      <button
                                        onClick={() => {
                                          setColorPicker({
                                            ...colorPicker,
                                            showPicker: '',
                                            colors: {
                                              ...colorPicker.colors,

                                              primary_color:
                                                colorPicker.prevColor,
                                            },
                                            prevColor: '',
                                          });
                                        }}
                                      >
                                        <span>Cancel</span>
                                      </button>
                                    </div>
                                  </div>
                                )}
                                 <InputContent>Header and description</InputContent>
                                <FormWithInput
                                  placeholder="choose color"
                                  value={colorPicker.colors.primary_color}
                                  className={
                                    disableFields ? 'editor_btn_disabled' : ''
                                  }
                                />
                              </>
                             
                            </FormWrapper> */}
                            <InputContainer>
                              <FormWithInput
                                    placeholder="color"
                                    value={colorPicker.colors.secondary_color}
                                    className={
                                      disableFields ? 'editor_btn_disabled' : ''
                                    }
                                  />
                              <InputContent style={{top: 8, fontSize: 10}}>User Message box</InputContent>
                              <ColourPickerDot
                                background={colorPicker.colors.secondary_color}
                                onClick={() => {
                                  handleClickSCroll(),
                                  setColorPicker({
                                    ...colorPicker,
                                    showPicker: 'user_message',
                                    prevColor:
                                      colorPicker.colors.secondary_color,
                                  });
                                  setState({
                                    ...state,
                                    popup: {
                                      showPopupLoader: false,
                                      modalError: { error: false, info: null },
                                    },
                                  });
                                }}
                                className={
                                  disableFields ? 'editor_btn_disabled' : ''
                                }
                              />
                              {colorPicker.showPicker == 'user_message' && (
                                <div style={popover}>
                                  <div
                                    style={cover}
                                    onClick={() => {
                                      setColorPicker({
                                        ...colorPicker,
                                        showPicker: '',
                                      });
                                    }}
                                  />
                                  <SketchPicker
                                    color={colorPicker.colors.secondary_color}
                                    onChangeComplete={(color, event) => {
                                      setColorPicker({
                                        ...colorPicker,
                                        colors: {
                                          ...colorPicker.colors,
                                          secondary_color: color.hex,
                                        },
                                      });
                                    }}
                                    presetColors={[ {color:"#D0021B",title:"F5A623"}, 
                                                      {color:"#F5A623",title:"F5A623"},
                                                      {color:"#F8E71C",title:"F8E71C"},
                                                      {color:"#8B572A",title:"8B572A"},
                                                      {color:"#7ED321",title:"7ED321"},
                                                      {color:"#417505",title:"417505"},
                                                      {color:"#BD10E0",title:"BD10E0"},
                                                      {color:"#9013FE",title:"9013FE"},
                                                      {color:"#4A90E2",title:"4A90E2"}
                                                    ]}
                                  />
                                  <div
                                    className="buton_wrapper"
                                    // style={{ position: 'absolute', bottom: 10 }}
                                  >
                                    <button
                                      onClick={() => {
                                        setColorPicker({
                                          ...colorPicker,
                                          showPicker: '',
                                        });
                                      }}
                                    >
                                      Ok
                                    </button>
                                    <button
                                      onClick={() => {
                                        setColorPicker({
                                          ...colorPicker,
                                          showPicker: '',
                                          colors: {
                                            ...colorPicker.colors,
                                            secondary_color:
                                              colorPicker.prevColor,
                                          },
                                          prevColor: '',
                                        });
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              )}
                            </InputContainer>
                            {/* <FormWrapper>
                              <FormHeader fontsize={10}>
                                User Message box
                              </FormHeader>
                              <ColourPickerDot
                                background={colorPicker.colors.secondary_color}
                                onClick={() => {
                                  setColorPicker({
                                    ...colorPicker,
                                    showPicker: 'user_message',
                                    prevColor:
                                      colorPicker.colors.secondary_color,
                                  });
                                  setState({
                                    ...state,
                                    popup: {
                                      showPopupLoader: false,
                                      modalError: { error: false, info: null },
                                    },
                                  });
                                }}
                                className={
                                  disableFields ? 'editor_btn_disabled' : ''
                                }
                              />
                              {colorPicker.showPicker == 'user_message' && (
                                <div style={popover}>
                                  <div
                                    style={cover}
                                    onClick={() => {
                                      setColorPicker({
                                        ...colorPicker,
                                        showPicker: '',
                                      });
                                    }}
                                  />
                                  <SketchPicker
                                    color={colorPicker.colors.secondary_color}
                                    onChangeComplete={(color, event) => {
                                      setColorPicker({
                                        ...colorPicker,
                                        colors: {
                                          ...colorPicker.colors,
                                          secondary_color: color.hex,
                                        },
                                      });
                                    }}
                                    presetColors={[ {color:"#D0021B",title:"F5A623"}, 
                                                      {color:"#F5A623",title:"F5A623"},
                                                      {color:"#F8E71C",title:"F8E71C"},
                                                      {color:"#8B572A",title:"8B572A"},
                                                      {color:"#7ED321",title:"7ED321"},
                                                      {color:"#417505",title:"417505"},
                                                      {color:"#BD10E0",title:"BD10E0"},
                                                      {color:"#9013FE",title:"9013FE"},
                                                      {color:"#4A90E2",title:"4A90E2"}
                                                    ]}
                                  />
                                  <div
                                    className="buton_wrapper"
                                    style={{ position: 'absolute', bottom: 10 }}
                                  >
                                    <button
                                      onClick={() => {
                                        setColorPicker({
                                          ...colorPicker,
                                          showPicker: '',
                                        });
                                      }}
                                    >
                                      Ok
                                    </button>
                                    <button
                                      onClick={() => {
                                        setColorPicker({
                                          ...colorPicker,
                                          showPicker: '',
                                          colors: {
                                            ...colorPicker.colors,
                                            secondary_color:
                                              colorPicker.prevColor,
                                          },
                                          prevColor: '',
                                        });
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              )}

                              <FormWithInput
                                placeholder="color"
                                value={colorPicker.colors.secondary_color}
                                className={
                                  disableFields ? 'editor_btn_disabled' : ''
                                }
                              />
                            </FormWrapper> */}
                            <InputContainer style={{marginBottom: 0}}>
                                <FormWithInput
                                    placeholder="color"
                                    value={colorPicker.colors.accent_color}
                                    className={
                                      disableFields ? 'editor_btn_disabled' : ''
                                    }
                                  />
                                <InputContent style={{top: 8, fontSize: 10}}>Highlights Interactive</InputContent>
                                <ColourPickerDot
                                  background={colorPicker.colors.accent_color}
                                  onClick={() => {
                                    handleClickSCroll(),
                                    setColorPicker({
                                      ...colorPicker,
                                      showPicker: 'highlights',
                                      prevColor:
                                        colorPicker.colors.accent_color,
                                    });
                                    setState({
                                      ...state,
                                      popup: {
                                        showPopupLoader: false,
                                        modalError: {
                                          error: false,
                                          info: null,
                                        },
                                      },
                                    });
                                  }}
                                  className={
                                    disableFields ? 'editor_btn_disabled' : ''
                                  }
                                />
                                {colorPicker.showPicker == 'highlights' && (
                                  <div style={popover}>
                                    <div
                                      style={cover}
                                      onClick={() => {
                                        setColorPicker({
                                          ...colorPicker,
                                          showPicker: '',
                                        });
                                      }}
                                    />
                                    <SketchPicker
                                      color={colorPicker.colors.accent_color}
                                      onChangeComplete={(color, event) => {
                                        setColorPicker({
                                          ...colorPicker,
                                          colors: {
                                            ...colorPicker.colors,
                                            accent_color: color.hex,
                                          },
                                        });
                                      }}
                                      presetColors={[ {color:"#D0021B",title:"F5A623"}, 
                                                      {color:"#F5A623",title:"F5A623"},
                                                      {color:"#F8E71C",title:"F8E71C"},
                                                      {color:"#8B572A",title:"8B572A"},
                                                      {color:"#7ED321",title:"7ED321"},
                                                      {color:"#417505",title:"417505"},
                                                      {color:"#BD10E0",title:"BD10E0"},
                                                      {color:"#9013FE",title:"9013FE"},
                                                      {color:"#4A90E2",title:"4A90E2"}
                                                    ]}
                                    />
                                    <div
                                      className="buton_wrapper"
                                      // style={{
                                      //   position: 'absolute',
                                      //   bottom: 10,
                                      // }}
                                    >
                                      <button
                                        onClick={() => {
                                          setColorPicker({
                                            ...colorPicker,
                                            showPicker: '',
                                          });
                                        }}
                                      >
                                        Ok
                                      </button>
                                      <button
                                        onClick={() => {
                                          setColorPicker({
                                            ...colorPicker,
                                            showPicker: '',
                                            colors: {
                                              ...colorPicker.colors,

                                              accent_color:
                                                colorPicker.prevColor,
                                            },
                                            prevColor: '',
                                          });
                                        }}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                )}
                            </InputContainer>
                           
                             {/* <FormWrapper> */}
                              {/*<>
                                <FormHeader fontsize={10}>
                                  Highlights Interactive{' '}
                                </FormHeader>
                                <ColourPickerDot
                                  background={colorPicker.colors.accent_color}
                                  onClick={() => {
                                    setColorPicker({
                                      ...colorPicker,
                                      showPicker: 'highlights',
                                      prevColor:
                                        colorPicker.colors.accent_color,
                                    });
                                    setState({
                                      ...state,
                                      popup: {
                                        showPopupLoader: false,
                                        modalError: {
                                          error: false,
                                          info: null,
                                        },
                                      },
                                    });
                                  }}
                                  className={
                                    disableFields ? 'editor_btn_disabled' : ''
                                  }
                                />
                                {colorPicker.showPicker == 'highlights' && (
                                  <div style={popover}>
                                    <div
                                      style={cover}
                                      onClick={() => {
                                        setColorPicker({
                                          ...colorPicker,
                                          showPicker: '',
                                        });
                                      }}
                                    />
                                    <SketchPicker
                                      color={colorPicker.colors.accent_color}
                                      onChangeComplete={(color, event) => {
                                        setColorPicker({
                                          ...colorPicker,
                                          colors: {
                                            ...colorPicker.colors,
                                            accent_color: color.hex,
                                          },
                                        });
                                      }}
                                      presetColors={[ {color:"#D0021B",title:"F5A623"}, 
                                                      {color:"#F5A623",title:"F5A623"},
                                                      {color:"#F8E71C",title:"F8E71C"},
                                                      {color:"#8B572A",title:"8B572A"},
                                                      {color:"#7ED321",title:"7ED321"},
                                                      {color:"#417505",title:"417505"},
                                                      {color:"#BD10E0",title:"BD10E0"},
                                                      {color:"#9013FE",title:"9013FE"},
                                                      {color:"#4A90E2",title:"4A90E2"}
                                                    ]}
                                    />
                                    <div
                                      className="buton_wrapper"
                                      style={{
                                        position: 'absolute',
                                        bottom: 10,
                                      }}
                                    >
                                      <button
                                        onClick={() => {
                                          setColorPicker({
                                            ...colorPicker,
                                            showPicker: '',
                                          });
                                        }}
                                      >
                                        Ok
                                      </button>
                                      <button
                                        onClick={() => {
                                          setColorPicker({
                                            ...colorPicker,
                                            showPicker: '',
                                            colors: {
                                              ...colorPicker.colors,

                                              accent_color:
                                                colorPicker.prevColor,
                                            },
                                            prevColor: '',
                                          });
                                        }}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                )}*/}

                                {/* <FormWithInput
                                  placeholder="color"
                                  value={colorPicker.colors.accent_color}
                                  className={
                                    disableFields ? 'editor_btn_disabled' : ''
                                  }
                                />
                              {/* </> */}
                            {/* </FormWrapper>  */}
                            <FormWrapper>
                              {/* <FormHeader></FormHeader> */}
                              {/* <Button
                                                                className={
                                                                    state.selecteBot.id.length == 0 ||
                                                                    file.length == 0 ||
                                                                    disableInitialSave() ||
                                                                    state.popup.modalError.error
                                                                        ? 'editor_btn_disabled'
                                                                        : ''
                                                                }
                                                                type="button"
                                                                onClick={() => {
                                                                    setFirstSection(true)
                                                                    state.mode == 'add'
                                                                        ? createWorkspace()
                                                                        : updateWorkspace()
                                                                }}
                                                            >
                                                                <label>{state.mode == 'add' ? 'Save' : 'Update'}</label>
                                                            </Button> */}
                              {/* <WidgetUpdateButton>
                                <Button
                                  className={
                                    state.selecteBot.id.length == 0 ||
                                    file.length == 0 ||
                                    disableInitialSave() ||
                                    state.popup.modalError.error
                                      ? 'editor_btn_disabled'
                                      : ''
                                  }
                                  type="button"
                                  onClick={() => {
                                    setFirstSection(true);
                                    state.mode == 'add'
                                      ? createWorkspace()
                                      : updateWorkspace();
                                  }}
                                >
                                  {state.mode == 'add' ? 'Save' : 'Update'}
                                </Button>
                              </WidgetUpdateButton> */}
                              {/* <button
                                                                disabled={
                                                                    state.selecteBot.id.length == 0 ||
                                                                    file.length == 0 ||
                                                                    disableInitialSave() ||
                                                                    state.popup.modalError.error
                                                                }
                                                                onClick={() => {
                                                                    setFirstSection(true)
                                                                    state.mode == 'add'
                                                                        ? createWorkspace()
                                                                        : updateWorkspace()
                                                                }}
                                                                className={
                                                                    state.selecteBot.id.length == 0 ||
                                                                    file.length == 0 ||
                                                                    disableInitialSave() ||
                                                                    state.popup.modalError.error
                                                                        ? 'editor_btn_disabled'
                                                                        : ''
                                                                }
                                                            >
                                                                {state.mode == 'add' ? 'Save' : 'Update'}
                                                            </button> */}
                              {firstSection && (
                                <>
                                  {state.popup.modalError.error && (
                                    <span className="connect_account_failed">
                                      <div className="error_input_show">
                                        <WarningAlertIcon />
                                        <span className="error_menu_hvr">
                                          {state.popup.modalError.info}
                                        </span>
                                      </div>
                                    </span>
                                  )}
                                  {state.popup.showPopupLoader && (
                                    <span className="connect_account_loading">
                                      <img
                                        alt="loading"
                                        src={LoadingSvgImage}
                                      />
                                    </span>
                                  )}
                                </>
                              )}
                            </FormWrapper>
                          </ColourWrapperInputs>
                        </ColourWrapper>
                      {/* </Form50WrapperLogo> */}
                    </SectionWrapper>
                  )}
                  {/* Widget Editor */}
                  {/* widget Secure Chat */}
                  <WidgetSecureChat
                    disableFields={disableFields}
                    disableSwitch={state.mode == 'add'}
                    is_secured={state.is_secured}
                    public_key={state.public_key}
                    setState={(is_secured: boolean, public_key: string) => {
                      setState({
                        ...state,
                        is_secured: is_secured,
                        public_key:
                          is_secured == false &&
                          (!defaultState.public_key ||
                            defaultState.public_key.length == 0)
                            ? ''
                            : public_key,
                        popup: {
                          showPopupLoader: false,
                          modalError: { error: false, info: null },
                        },
                      });
                    }}
                    workspaceId={state.workspaceId}
                  />
                </WidgetFormToggle>
              </OverallHeightWarpper>
              <FixedButtonWrapper>
                <WidgetEditor
                  snippet={state.snippet}
                  popup={
                    state.mode == 'add' || !disableInitialSave()
                      ? {
                          showPopupLoader: false,
                          modalError: { error: false, info: null },
                        }
                      : state.popup
                  }
                  enableEditMode={enableEditMode}
                  disableFields={disableFields}
                  setDisableEdit={() => {
                    setEnableEditMode(false);
                  }}
                  addClass={() => {
                    showClassName(!classname);
                  }}
                  onCancel={props.cancel}
                  setWebchat={() =>
                    setState({ ...state, is_secured: false, public_key: '' })
                  }
                  is_secured={state.is_secured}
                  public_key={state.public_key}
                  setState={(is_secured: boolean, public_key: string) => {
                    setState({
                      ...state,
                      is_secured: is_secured,
                      public_key: public_key,
                      popup: {
                        showPopupLoader: false,
                        modalError: { error: false, info: null },
                      },
                    });
                  }}
                  disableSave={state.mode == 'add' || !disableInitialSave()}
                  disableUpdate ={disableUpdate()}
                  onSave={() => {
                    setFirstSection(false);
                    state.mode == 'add'
                        ? createWorkspace()
                        : updateWorkspace()
                  }}
                  mode={state.mode}
                  showButtons={state.mode != 'add'}
                  makeEventEntry={makeEventEntryForDeploy}
                  workSpace={state}
                />
              </FixedButtonWrapper>
               </PopupWrapper>
              </Wrapper>
            {/* </Row>
          </Box> */}
          {/* <div className="d-grid"></div> */}
          <WidgetPreviewCard
            colors={colorPicker.colors}
            name={state.widgetName}
            description={state.widgetDes}
            image={file ? file : logoImg}
          />
        </div>
      </BodyWrapper>
    </ErrorBoundary>
  );
};

const exampleCode = `
<html>
<head></head>
<body> 
    <title>My Test Page</title>
    <p>The body of my page.</p>
    <!-- copied script elements -->
</body>
</html>
`.trim();

const WidgetEditor = (props: {
  is_secured: boolean;
  public_key: string;
  setState: (is_secured: boolean, public_key: string) => void;
  onCancel: () => void;
  onSave: () => void;
  disableSave: boolean;
  disableUpdate:boolean;
  enableEditMode: boolean;
  mode: 'add' | 'edit';
  setDisableEdit: any;
  popup: {
    showPopupLoader: boolean;
    modalError: { error: boolean; info: null | string };
  };
  disableFields: boolean;
  addClass: () => void;
  snippet: string;
  showButtons: boolean;
  setWebchat: Function;
  makeEventEntry: Function;
  workSpace:any;
}) => {
  const [show, setShow] = useState(true);
  const [copied, setCopy] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSnippetModal, setShowSnippetModal] = useState(false);
  const [isDefault, setIsDefault] = useState(true);
  const [initialSwitch, setInitialSwitch] = useState(false);
  const [initialKey, setInitialKey] = useState('');

  useEffect(() => {
    setIsDefault(
      props.mode == 'add' || !props.public_key || props.public_key.length == 0
    );
    setInitialSwitch(props.is_secured);

    setInitialKey(props.public_key);
  }, [props.mode, props.enableEditMode]);

  const copyToClipBoard = (target: string) => {
    const el = document.createElement('textarea') as any;
    el.value = props.snippet;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 2000);
  };

  function renderDeleteModal() {
    // console.log(props.workSpace,'selected')
    if (showModal) {
      return (
        <Modal showPopupLoader={false}>
          <React.Fragment>
            <h2>Are you sure to update widget?</h2>
            <p>
              New code will be generated for the widget to copy and paste it in
              the website{' '}
            </p>{' '}
          </React.Fragment>

          <div className="widget_secure_popup">
            <button
              className="widget_secure_popup_button btn btn-primary"
              type="button"
              onClick={() => {
                setShowModal(false);
                props.onSave();
              }}
            >
             <label>Yes</label> 
            </button>
            <button
              className="widget_secure_popup_button btn btn-success"
              type="button"
              onClick={() => {
                setShowModal(false);
              }}
            >
              <label>No</label>
            </button>
          </div>
        </Modal>
      );
    }

    return null;
  }
  // console.log(props.disableUpdate,props.disableSave, 'updatebtn');
  
  // console.log(props,"PageState")
  return (
    <WidgetFormToggle>
      <ButtonUpdateWrapper>
        <WidgetUpdateButton>
                  <Button
                    onClick={() => {
                      props.onCancel()
                      isDefault
                        ? props.setWebchat()  
                        : props.enableEditMode
                        ? props.setDisableEdit()
                        : window.history.back();

                      props.setState(initialSwitch, initialKey);
                      
                    }}
                    disabled={props.popup.showPopupLoader}
                    type="button"
                  >
                   <label>Cancel</label> 
                  </Button>
                </WidgetUpdateButton>
        {/* {props.showButtons && props.is_secured && ( */}
          {/* <> */}
            {/* {props.public_key &&
              props.public_key.length > 0 &&
              props.enableEditMode && ( */}
                {/* // <button 
                //     onClick={() => {
                //         isDefault
                //             ? props.setWebchat()
                //             : props.enableEditMode
                //             ? props.setDisableEdit()
                //             : window.history.back()

                //         props.setState(initialSwitch, initialKey)
                //     }}
                //     disabled={props.popup.showPopupLoader}
                // >
                //     Cancel
                // </button>  */}
                
              {/* // )} */}
            {/* <button
                            className={
                                props.disableSave ||
                                props.disableFields ||
                                props.popup.showPopupLoader ||
                                !props.public_key ||
                                props.public_key.length == 0 ||
                                props.public_key == initialKey
                                    ? 'editor_btn editor_btn_disabled'
                                    : 'editor_btn'
                            }
                            onClick={() => setShowModal(true)}
                        >
                            {props.mode == 'add' || isDefault ? 'Save' : 'Update'}
                        </button> */}
            <WidgetUpdateButton>
              <Button
              // className = {
              //   props.workSpace.widgetName == '' ||
              //   props.workSpace.widgetDes == ''  ||
              //   props.workSpace.selecteBot.id == ''
              //   ? 'editor_btn editor_btn_disabled'
              //   : 'editor_btn'
              // }
              className={
                props.workSpace.widgetName !== ''&&
                props.workSpace.widgetDes !== '' &&
                props.workSpace.selecteBot.id !== '' &&
                props.disableSave &&
                props.disableUpdate
                ? 'editor_btn '
                    : 'editor_btn editor_btn_disabled'
              }
                // className={
                //   props.disableSave ||
                //   props.disableFields ||
                //   props.popup.showPopupLoader ||
                //   !props.public_key ||
                //   props.public_key.length == 0 ||
                //   props.public_key == initialKey
                //     ? 'editor_btn editor_btn_disabled'
                //     : 'editor_btn'
                // }
                onClick={() => setShowModal(true)}
                type="button"
              >
                <label>
                  {props.mode == 'add' ? 'Save' : 'Update'}
                </label>
              </Button>
            </WidgetUpdateButton>
          {/* </> */}
        {/* //  )}  */}
        <span className="btn_code_snippet">
          {/* <button
                        disabled={props.disableSave || !props.showButtons}
                        onClick={() => {
                            props.makeEventEntry()
                            setShowSnippetModal(true)
                        }}
                        className={
                            props.disableSave ||
                            !props.showButtons ||
                            props.is_secured != initialSwitch ||
                            (props.is_secured && props.public_key != initialKey)
                                ? 'editor_btn_disabled get_code_btn'
                                : 'get_code_btn'
                        }
                    >
                        Get code snippet
                    </button> */}
          <WidgetUpdateButton>
            <Button
              className={
                props.disableSave ||
                !props.showButtons ||
                props.is_secured != initialSwitch ||
                (props.is_secured && props.public_key != initialKey)
                  ? 'editor_btn_disabled get_code_btn'
                  : 'get_code_btn'
              }
              type="button"
              onClick={() => {
                props.makeEventEntry();
                setShowSnippetModal(true);
              }}
            >
              <label>
                Get code snippet
              </label>
            </Button>
            
          </WidgetUpdateButton>
          <Tooltip
              className="target customTip"
              zIndex={10000}
              arrowSize={8}
              tagName="span"
              content={"Copy code snippet and paste it into your web page HTML to deploychat widget."}
              distance={5}
            >
              <InfoIcon />
            </Tooltip>
            
          {/* <span className="info_icon">
            <InfoIcon />
            <span
              className="side_menu_hvr"
              style={{ fontSize: '9px !important' }}
            >
              Copy code snippet and paste it into your web page HTML to deploy
              chat widget.
            </span>
          </span> */}
        </span>
        {props.popup.modalError.error && (
          <span className="connect_account_failed">
            <div className="error_input_show">
              <WarningAlertIcon />
              <span className="error_menu_hvr">
                {props.popup.modalError.info}
              </span>
            </div>
          </span>
        )}
        {props.popup.showPopupLoader && (
          <span className="connect_account_loading">
            <img alt="logo" src={LoadingSvgImage} />
          </span>
        )}
      </ButtonUpdateWrapper>

      {/* Modal Pre tag */}
      {showSnippetModal ? (
        <ModalSnippet>
          <React.Fragment>
            <h2>Chatbot Widget - Code Snippet</h2>
          </React.Fragment>

          {props.mode == 'edit' && (
            <>
              {show && (
                <WidgetPreWrapper>
                  <SectionWrapper>
                    <Highlight
                      {...defaultProps}
                      theme={theme}
                      code={props.snippet}
                      language="jsx"
                    >
                      {({
                        className,
                        style,
                        tokens,
                        getLineProps,
                        getTokenProps,
                      }) => (
                        <Pre className={className} style={style}>
                          {tokens.map((line, i) => (
                            <div {...getLineProps({ line, key: i })}>
                              <LineNo>{i + 1}</LineNo>
                              {line.map((token, key) => (
                                <span {...getTokenProps({ token, key })} />
                              ))}
                            </div>
                          ))}
                        </Pre>
                      )}
                    </Highlight>
                    <ButtonContainer style={{ paddingBottom: 0 }}>
                      <Button
                        primary
                        onClick={() => {
                          setShowSnippetModal(false);
                        }}
                      >
                        <label>Cancel</label>
                      </Button>
                      <Button
                        onClick={() => {
                          copyToClipBoard('dummy code');
                        }}
                      >
                        <label>Copy Code</label>
                      </Button>
                    </ButtonContainer>
                    {copied && (
                      <span className="copy_board">Copied to clipboard</span>
                    )}
                  </SectionWrapper>
                </WidgetPreWrapper>
              )}
            </>
          )}
        </ModalSnippet>
      ) : null}
      {renderDeleteModal()}
    </WidgetFormToggle>
  );
};

const ChatWrapper = styled.div`
  width: 100%;
  float: left;
  border-radius: 6px;
  box-shadow: 0px 10px 20px #00000029;
  background: #f4f4f4;
  height: 100%;
  width: 360px;

  @media (max-width: 1600px) {
    height: 100%;
    width: 380px;
  }
`;
const WidgeBgWrapper = styled.div`
  width: 500px;
  float: left;
  background: #f4f4f4;
  height: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 10px 15px 15px 15px;
  align-items: center;
  position: relative;
  left: 35%;
  margin-top: 30px;
  @media (max-width: 1500px) {
    left: 20%;
  }
  @media (max-width: 1370px) {
    left: 10%;
  }

  .widget_header_top {
    margin-top: 40px;
    @media (max-width: 1736px) {
      margin-top: 10px;
    }

    @media (max-width: 1600px) {
      margin-top: 20px;
    }
    @media (max-width: 1500px) {
      margin-top: 0px;
    }
  }
  ${FormHeader} {
    font-size: 18px;
    justify-content: center;
    width: 200px;
    float: left;
    max-width: 100%;
    margin-bottom: 12px;
  }

  @media (max-width: 1600px) {
    ${FormHeader} {
      width: 200px;
    }
  }
`;
const ChatHeader = styled.div`
  width: 100%;
  height: 94px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  background: ${(props) => (props.background ? props.background : '#00887a')};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  ${FormHeader} {
    width: auto;
    font-size: 14px;
    justify-content: left;
    margin-bottom: 0px;
    color: ${(props) => (props.color ? props.color : '#fff')};
  }
  ${Paragraph} {
    margin-bottom: 0px;
    color: ${(props) => (props.color ? props.color : '#fff')};
    margin-top: 0px;
  }
  ${LogoSize} {
    background: #fff;
    border-radius: 50%;
    display: flex;
    place-content: center;
    padding: 12px;
    width: 64px;
    height: 64px;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 1600px) {
    height: 72px;
    ${LogoSize} {
      width: 48px;
      height: 48px;
    }
  }
`;
const ContentHeaderLeft = styled.div`
  width: 82%;
  display: flex;
  flex-direction: column;
`;
const IconSpan = styled.span`
  color: ${(props) => (props.color ? props.color : '#fff')};
  fill: ${(props) => (props.color ? props.color : '#fff')};
  background: ${(props) => (props.background ? props.background : 'inherit')};
`;
const ContentHeaderRight = styled.div`
  width: 15%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  span:first-child {
    margin-right: 10px;
  }
`;
const ContentHeader = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  position: relative;
  justify-content: space-between;

  span {
    position: relative;
    width: 18px;
    height: 18px;
  }

  svg {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
`;
const WidgetPreview = styled.div`
  width: 33%;
  float: right;
  display: flex;
  height: auto;
  margin-top: 20px;
  position: fixed;
  z-index: 9999;
  margin-top: 60px;
`;

const ChatContentWrapper = styled.div`
  width: 100%;
  float: left;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  // background: #f4f4f4;
  background: #fff;
  height: calc(100vh - 48vh);
  overflow-x: hidden;

  @media (max-width: 1736px) {
    height: calc(100vh - 445px);
    padding: 15px;
  }
  @media (max-width: 1650px) {
    height: calc(100vh - 405px);
    padding: 10px;
  }
  @media (max-width: 1500px) {
    height: calc(100vh - 382px);
    padding: 10px;
  }
  ${LogoSize} {
    background: #fff;
    border-radius: 50%;
    display: flex;
    place-content: center;
    padding: 8px;
    width: 42px;
    height: 42px;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 1600px) {
    ${LogoSize} {
      width: 32px;
      height: 32px;
      padding: 7px;
    }
  }
`;
const MessageBox = styled.div`
  width: 100%;
  float: left;
  display: flex;
`;

const ChatMessage = styled.div`
  width: 75%;
  max-width: max-content;
  padding: 8px;
  margin-left: 12px;
  font-size: 12px;
  font-family: 'Gordita-Regular';
  white-space: pre-wrap;
  box-sizing: border-box;
  word-break: break-word;
  border-radius: 0px 6px 6px 6px;
  color: #000000;
  position: relative;
  background: ${(props) => props.background}!important;
  svg {
    width: 6px;
    height: 6px;
    top: 0px;
    left: -6px;
    position: absolute;
    color: #fff;
  }
  @media (max-width: 1736px) {
    padding: 8px;
  }
  @media (max-width: 1600px) {
    padding: 5px 8px;
  }
  .timestamp {
    color: #333;
    font-size: 10px;
    font-family: 'Gordita-Regular';
  }
`;
const AdaptiveHeader = styled.h4`
  color: ${(props) => props.color};
`;

const AdaptiveParagraph = styled.p`
  color: ${(props) => props.color};
`;

const AssistantChat = styled.div`
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  .tryit_cards_top_right {
    position: relative;
  }
  .timestamp {
    color: #333;
    font-size: 10px;
    font-family: 'Gordita-Regular';
  }
  .tryit_cards .timestamp {
    position: absolute;
    right: 0;
    top: -5px;
  }
  .tryit_cards h4 {
    font-size: 12px;
    margin-top: 7px;
    width: 100%;
  }
  .tryit_cards p {
    font-size: 10px;
  }
  .tryit_cards_top {
    flex-wrap: wrap;
    padding: 0px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 5px #00000029;
    border-radius: 0px 10px 10px 10px;
  }
  .tryit_ticket_details_wrapper button {
    min-height: 28px;
  }
  .tryit_wrapper_card {
    width: 75%;
    margin-left: 12px;
    border: none !important;
    .adaptive_tail_icon {
      position: absolute;
    }
    .adaptive_tail_icon svg {
      width: 6px;
      height: 6px;
      top: 0px;
      margin-left: -5px;
      position: absolute;
    }

    .tryit_cards {
      margin-top: 0px;
      margin-bottom: 20px;
      width: 100%;
      box-shadow: none !important;
    }
    .tryit_cards .tryit_cards_wrapper {
      border: none;
    }
    .d-flex {
      padding: 12px;
    }
    .tryit_ticket_wrapper.tryit_button_wrapper {
      padding-top: 0px;
      display: grid;
      grid-template-columns: 48% 48%;
      grid-gap: 15px;
    }

    .tryit_button_wrapper .tryit_ticket_details_wrapper {
      left: inherit;
      width: max-content;
      width: 100%;

      button {
        margin-top: 0px;
        min-width: unset;
        width: 100%;
        max-width: 100%;
        margin-right: 0px;
        margin-bottom: 6px;
        :hover {
          box-shadow: 0px 3px 5px #00000029;
        }
      }
    }
  }
  ${ChatMessage} {
    width: 75%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 5px #00000029;
    border-radius: 0px 10px 10px 10px;
  }
  .tryit_ticket_details_wrapper .tryit_ticket_details {
    margin-bottom: 8px;
  }
  .tryit_ticket_wrapper h6 {
    font-size: 12px;
  }
`;

const UserChat = styled.div`
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  margin: 20px 0px;
  place-self: flex-end;

  svg {
    width: 6px;
    height: 6px;
    top: -1px;
    left: inherit;
    position: absolute;
    right: -7px;
  }
  :last-child {
    margin-bottom: 0px;
  }
  ${LogoSize} {
    svg {
      width: 100%;
      height: 100%;
      top: 0px;
      left: inherit;
      position: relative;
      right: 0px;
      fill: #617b98;
    }
  }
  ${ChatMessage} {
    margin-left: 0px;
    margin-right: 12px;
    box-shadow: 0px 3px 5px #00000029;
    border-radius: 10px 0px 10px 10px;
    background: ${(props) => props.background}!important;
    color: #000;
    border: 1px solid ${(props) => props.background};
  }
  ${MessageBox} {
    justify-content: flex-end;
  }
`;
const TryitCardsTop = styled.div`
width: 100%;
float: left;
background:${(props) => props.background}!important;
border:none;
padding: 10px;
display: flex;
align-items: flex-start;
border-top-right-radius: .375rem;

${AdaptiveHeader}{
    color: ${(props) => props.color};
}

${AdaptiveParagraph}{
    color: ${(props) => props.color};
}

}
`;

const ButtonWrapper = styled.div`
  width: 100%;
  float: left;
  position: relative;
  left: 12%;
  display: flex;

  @media (max-width: 1700px) {
    left: 16%;
  }
  button {
    min-width: 120px;
    max-width: max-content;
    height: 28px;
    border-radius: 18px;
    background: #d2d1d1;
    color: #000;
    border: inherit;
    outline: 0;
    cursor: pointer;
    margin-top: 12px;
    font-size: 14px;
    font-family: 'Gordita-Regular';
    display: block;
    :hover {
      background: #0094fe;
      color: #fff;
    }
    :first-child {
      margin-right: 12px;
    }
    :last-child {
      margin-bottom: 12px;
    }
  }
  @media (max-width: 1600px) {
    button {
      height: 24px;
      min-width: 64px;
      margin-right: 10px;
      justify-content: center;
      display: flex;
      padding-top: 10px;
      :last-child {
        margin-bottom: 0px;
        margin-right: 0px;
      }
    }
  }
`;

const InputMessage = styled.div`
  width: 100%;
  float: left;
  border-top: 1px solid #ccc;
  height: 60px;
  background: #fff;
  display: flex;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  position: relative;
  top: 0px;

  input {
    width: 100%;
    padding-left: 12px;
    padding-right: 84px;
    border: none;
    font-size: 14px;
    font-family: 'Gordita-Regular';
    color: #000;
    border-radius: 0px 0px 10px 10px;
  }

  span {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 15px;
    top: 18px;

    svg {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }
  .attachment_icon {
    margin-right: 30px;
  }

  @media (max-width: 1600px) {
    height: 53px;
    border-radius: 0px 0px 10px 10px;
    input {
      padding-right: 74px;
    }
    .attachment_icon {
      margin-right: 26px;
    }
    span {
      width: 20px;
      height: 20px;
      top: 15px;
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
`;
const CancelButton = styled.div`
  width: 460px;
  height: 100%;
  float: right;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  ${IconSpan} {
    width: 64px;
    height: 64px;
    float: left;
    border-radius: 50%;
    background: ${(props) => props.background};
    fill: ${(props) => props.color};
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  @media (max-width: 1600px) {
    width: 380px;
    ${IconSpan} {
      width: 54px;
      height: 54px;
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  @media (max-width: 1600px) {
    margin-top: 20px;
  }
`;
const WidgetPreviewCard = (props: {
  name: string;
  description: string;
  colors: {
    primary_color: string;
    secondary_color: string;
    accent_color: string;
    button_color: string;
  };
  image: any;
}) => {
  const [hover, setHover] = useState({ btn: 0 });

  return (
    <WidgetPreview className="widget_preview">
      <WidgeBgWrapper>
        <FormHeader className="widget_header_top">Widget Preview</FormHeader>
        <ChatWrapper>
          <ChatHeaderTop
            backg={props.colors.accent_color}
            color={props.colors.primary_color}
            name={props.name}
            description={props.description}
            image={props.image}
          />
          <ChatContentWrapper>
            <AssistantChat>
              <ChatAssistant image={props.image} optionText={false} />
            </AssistantChat>
            <UserAssistant backg={props.colors.secondary_color} />
            <AssistantChat
              className="tryit_understand_parent"
              backg={props.colors.secondary_color}
            >
              <AdaptiveCard
                backg={props.colors.accent_color}
                color={props.colors.primary_color}
                image={props.image}
              >
                <div className="d-flex ">
                  <div className="tryit_cards_top_left">
                    <img src={adaptive_logo} alt="image" />
                  </div>
                  <div className="tryit_cards_top_right">
                    <div className="timestamp">10:12 am</div>
                    <AdaptiveHeader color={props.colors.primary_color}>
                      Ticket Details
                    </AdaptiveHeader>
                    <AdaptiveParagraph color={props.colors.primary_color}>
                      Please find the ticket details below
                    </AdaptiveParagraph>
                  </div>
                </div>

                <div className="tryit_cards_wrapper">
                  <div className="tryit_cards_left">
                    <div className="tryit_ticket_wrapper tryit_content_wrapper">
                      <div className="tryit_ticket_details_wrapper">
                        <div className="tryit_ticket_details">
                          <h6>Ticket ID: </h6>
                          <p>342423</p>
                        </div>
                        <div className="tryit_ticket_details">
                          <h6>Subject:</h6>
                          <p>Account Locked</p>
                        </div>
                        <div className="tryit_ticket_details">
                          <h6>Description:</h6>
                          <p>
                            I’m locked out of my account. Please unlock my
                            account.
                          </p>
                        </div>
                        <div className="tryit_ticket_details">
                          <h6>Status</h6>
                          <p>In Progress</p>
                        </div>
                      </div>
                    </div>
                    <div className="tryit_ticket_wrapper tryit_button_wrapper">
                      {/* <ButtonWrapper className="tryit_ticket_details_wrapper">
                                                <button
                                                    onMouseEnter={() => setHover({ btn: 3 })}
                                                    onMouseLeave={() => setHover({ btn: 0 })}
                                                    style={{
                                                        color: hover.btn == 3 ? props.colors.primary_color : '#000',
                                                        background:
                                                            hover.btn == 3 ? props.colors.secondary_color : '#fff',
                                                        border: `1px solid ${
                                                            hover.btn == 3 ? props.colors.secondary_color : '#B2B2B2'
                                                        }`
                                                    }}
                                                >
                                                    Print
                                                </button>
                                            </ButtonWrapper> */}
                      <WidgetUpdateButton>
                        <Button
                          onMouseEnter={() => setHover({ btn: 3 })}
                          onMouseLeave={() => setHover({ btn: 0 })}
                          style={{
                            color:
                              hover.btn == 3
                                ? props.colors.primary_color
                                : '#000',
                            background: hover.btn == 3 ? '#ffe5d3' : '#fff',
                            border: `1px solid ${
                              hover.btn == 3 ? '#ffe5d3' : '#B2B2B2'
                            }`,
                            height: 24,
                            minWidth: 64,
                            padding: '3px 12px',
                          }}
                          type="button"
                        >
                          Print
                        </Button>
                      </WidgetUpdateButton>
                    </div>
                  </div>
                </div>
              </AdaptiveCard>
            </AssistantChat>

            <AssistantChat>
              <ChatAssistant image={props.image} optionText={true} />
              {/* <ButtonWrapper>
                                <button
                                    onMouseEnter={() => setHover({ btn: 1 })}
                                    onMouseLeave={() => setHover({ btn: 0 })}
                                    style={{
                                        color: hover.btn == 1 ? props.colors.primary_color : '#000',
                                        background: hover.btn == 1 ? props.colors.secondary_color : '#fff',
                                        border: `1px solid ${
                                            hover.btn == 1 ? props.colors.secondary_color : '#B2B2B2'
                                        }`,
                                        boxShadow: hover.btn == 1 ? '0px 3px 5px #00000029' : 'none'
                                    }}
                                >
                                    Yes
                                </button>
                                <button
                                    onMouseEnter={() => setHover({ btn: 2 })}
                                    onMouseLeave={() => setHover({ btn: 0 })}
                                    style={{
                                        color: hover.btn == 2 ? props.colors.primary_color : '#000',
                                        background: hover.btn == 2 ? props.colors.secondary_color : '#fff',
                                        border: `1px solid ${
                                            hover.btn == 2 ? props.colors.secondary_color : '#B2B2B2'
                                        }`,
                                        boxShadow: hover.btn == 2 ? '0px 3px 5px #00000029' : 'none'
                                    }}
                                >
                                    No
                                </button>
                            </ButtonWrapper> */}
              <ButtonWrapper>
                <WidgetUpdateButton>
                  <Button
                    onMouseEnter={() => setHover({ btn: 1 })}
                    onMouseLeave={() => setHover({ btn: 0 })}
                    style={{
                      color:
                        hover.btn == 1 ? props.colors.primary_color : '#000',
                      background: hover.btn == 1 ? '#ffe5d3' : '#fff',
                      border: `1px solid ${
                        hover.btn == 1 ? '#ffe5d3' : '#B2B2B2'
                      }`,
                      boxShadow:
                        hover.btn == 1 ? '0px 3px 5px #00000029' : 'none',
                      marginRight: 10,
                      padding: '5px 12px',
                      minWidth: 64,
                    }}
                    type="button"
                  >
                    Yes
                  </Button>
                </WidgetUpdateButton>
                <WidgetUpdateButton>
                  <Button
                    onMouseEnter={() => setHover({ btn: 2 })}
                    onMouseLeave={() => setHover({ btn: 0 })}
                    style={{
                      color:
                        hover.btn == 2 ? props.colors.primary_color : '#000',
                      background: hover.btn == 2 ? '#ffe5d3' : '#fff',
                      border: `1px solid ${
                        hover.btn == 2 ? '#ffe5d3' : '#B2B2B2'
                      }`,
                      boxShadow:
                        hover.btn == 2 ? '0px 3px 5px #00000029' : 'none',
                      padding: '3px 12px',
                      minWidth: 64,
                    }}
                    type="button"
                  >
                    No
                  </Button>
                </WidgetUpdateButton>
              </ButtonWrapper>
            </AssistantChat>
          </ChatContentWrapper>
          <InputMessage>
            <input type="text" placeholder="Enter message" />
            <span>
              <WidgetSendIcon />
            </span>
            <span className="attachment_icon">
              <AttachmentIcon />
            </span>
          </InputMessage>
        </ChatWrapper>
        <CancelButtonWrapper
          backg={props.colors.accent_color}
          color={props.colors.primary_color}
        >
          <CloseIcon />
        </CancelButtonWrapper>
      </WidgeBgWrapper>
    </WidgetPreview>
  );
};

const ChatHeaderTop = (props: {
  backg: string;
  color: string;
  name: string;
  description: string;
  image: any;
}) => {
  return (
    <ChatHeader color={props.color} background={props.backg}>
      <LogoSize>
        <img title={'logo'} src={props.image} />
      </LogoSize>
      <ContentHeader>
        <div
          className="d-flex flex-wrap"
          style={{ justifyContent: 'space-between' }}
        >
          <ContentHeaderLeft>
            <FormHeader style={{ fontSize: 12 }}>{props.name}</FormHeader>
            <Paragraph>{props.description}</Paragraph>
          </ContentHeaderLeft>
          <ContentHeaderRight>
            <IconSpan color={props.color} className="relative hover_tab">
              <RestartIcon />
            </IconSpan>
            <IconSpan color={props.color} className="relative hover_tab">
              <ExitIcon />
            </IconSpan>
          </ContentHeaderRight>
        </div>
      </ContentHeader>
    </ChatHeader>
  );
};

const ChatAssistant = (props: { image: any; optionText: boolean }) => {
  return (
    <MessageBox>
      <LogoSize>
        <img alt={'logo'} src={props.image} />
      </LogoSize>
      {!props.optionText ? (
        <ChatMessage>
          <div>Hi there! How can I help you today?</div>
          <div className="timestamp">10:12 am</div>

          <svg x="0px" y="0px" viewBox="0 0 7.9 7.9" xmlSpace="preserve">
            <path d="M7.9 8c0-4.4-3.6-8-8-8h8v8z" fill="currentColor" />
          </svg>
        </ChatMessage>
      ) : (
        <ChatMessage>
          <div>Is there anything else I can help you with? </div>
          <div className="timestamp">10:12 am</div>
          <svg x="0px" y="0px" viewBox="0 0 7.9 7.9" xmlSpace="preserve">
            <path d="M7.9 8c0-4.4-3.6-8-8-8h8v8z" fill="currentColor" />
          </svg>
        </ChatMessage>
      )}
    </MessageBox>
  );
};

const AdaptiveCard = (props: {
  backg: string;
  color: string;
  image: any;
  children: any;
}) => {
  return (
    <MessageBox color={props.color} background={props.backg}>
      <LogoSize>
        <img alt={'logo'} src={props.image} />
      </LogoSize>
      <div className="position-realtive tryit_wrapper_card">
        <div className="tryit_cards">
          <TryitCardsTop
            color={props.color}
            background={props.backg}
            className="tryit_cards_top"
          >
            {props.children}
          </TryitCardsTop>
        </div>
        <span className="adaptive_tail_icon">
          {' '}
          <svg x="0px" y="0px" viewBox="0 0 7.9 7.9" xmlSpace="preserve">
            <path d="M7.9 8c0-4.4-3.6-8-8-8h8v8z" fill={props.backg} />
          </svg>
        </span>
      </div>
    </MessageBox>
  );
};

const CancelButtonWrapper = (props: {
  backg: string;
  color: string;
  children: any;
}) => {
  return (
    <CancelButton>
      <IconSpan
        style={{ backgroundColor: props.backg }}
        backg={props.backg}
        color={props.color}
      >
        {props.children}
      </IconSpan>
    </CancelButton>
  );
};

const UserAssistant = (props: { backg: string }) => {
  return (
    <UserChat background={props.backg}>
      <MessageBox>
        <ChatMessage>
          <div>What is my ticket status</div>
          <div className="timestamp">10:12 am</div>
          <svg x="0px" y="0px" viewBox="0 0 7.9 7.9" xmlSpace="preserve">
            <path d="M-.1 8c0-4.4 3.6-8 8-8h-8v8z" fill={props.backg} />
          </svg>
        </ChatMessage>
        <LogoSize>
          <UserIconAnalytics />
        </LogoSize>
      </MessageBox>
    </UserChat>
  );
};

const PublicKeyWrapper = styled.div`
  width: 100%;
  float: left;

  textarea {
    border: 1px solid #d2d1d1;
    padding: 15px;
    border-radius: 6px;
    width: 100%;
    float: left;
    white-space: pre-wrap;
    word-break: break-all;
    font-family: 'Gordita-Regular';
    font-size: 12px;
    background: #fff;
    resize: none;

    :focus {
      outline: 0;
    }
  }
  ${FormHeader} {
    position: relative;
    .info_icon svg {
      width: 18px;
      height: 18px;
      color: #999;
      cursor: pointer;
      margin-left: 7px;
      transition: 0.8s ease;

      @media (max-width: 1600px) {
        width: 12px;
        height: 12px;
      }
    }
    .side_menu_hvr {
      position: absolute;
      width: max-content;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 10px 20px #00000029;
      padding: 6px 12px;
      font-size: 9px;
      border-radius: 0px;
      color: #315176;
      max-width: 300px;
      bottom: 35px;
      right: -15px;
      font-family: 'Gordita-Regular';
      display: none;

      :before {
        position: absolute;
        content: ' ';
        background: #ffffff 0% 0% no-repeat padding-box;
        bottom: -11px;
        border-width: 6px;
        border-style: solid;
        border-color: transparent transparent white transparent;
        top: inherit;
        right: 20px;
        transform: rotate(180deg);
      }

      @media (max-width: 1500px) {
        font-size: 9px;
        bottom: 30px;
      }
      @media (max-width: 1400px) {
        font-size: 13px;
        bottom: 30px;
      }
    }
    .info_icon svg:hover ~ .side_menu_hvr {
      display: flex;
    }
  }
`;
const HeaderAndPara = styled.div`
  width: 100%;
  float: left;

  ${Paragraph} {
    font-size: 14px;
    color: #494949;
    margin-bottom: 12px;
    width: 100%;
    margin-top: 0px;

    span a {
      width: auto;
    }
  }
  .show_hide input[type='checkbox'].switch_1 {
    appearance: none;
    width: 36 px;
    height: 18 px;
    border-radius: 3em;
    position: relative;
    cursor: pointer;
    outline: none;
  }
  .show_hide input[type='checkbox'].switch_1:after {
    width: 14px;
    height: 14px;
    top: 0px !important;
  }
  .show_hide input[type='checkbox'].switch_1:checked {
    top: 0px !important;
    margin-left: 0px !important;
    border: 0.5px solid #2468f6;
    background: #2468f6;
  }
  .show_hide input[type='checkbox'].switch_1:checked:after {
    left: calc(100% - 14px) !important;
    background: #fff;
  }

  .show_hide span {
    width: 14px;
    font-family: 'Gordita-Regular';
    color: #494949;
    margin-left: 5px;
  }
  .box_1 {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
`;
const ButtonUpdateWrapper = styled.div`
width:100%
float:left;
padding-top:20px;
display: flex;
align-items: center;
justify-content: center;

button {
    width: 140px;
    height: 30px;
    font-family: 'Gordita-Medium';
    font-size: 16px;
    text-align: center;
    padding: 0 10px;
    border-radius: 18px;
    border: 0.5px solid #999999;
    background: #fff;
    color: #494949;
    outline: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;

    :first-child:hover{
        border: 0.5px solid #ffe5d3;
        background: #ffe5d3;
        color: #333;
        fill: #333;
        box-shadow: rgb(0 0 0 / 16%) 0 3px 6px;
        outline: 0;
    }
    
}

.editor_btn {
    background: #007BFF;
    color: #fff;
    font-weight: 600;
    width: 140px;
    border: 1px solid #007BFF;
    // margin: 0px 0 0 20px;
    // margin-left: 20px
}

.error_input_show {
    position: relative;
    width: 15px;
    height: 15px;
    float: left;
    left: 10px;
    margin-top: -7px;
    bottom: 0px;
}
.error_menu_hvr {
    position: absolute;
    margin-left: -85px;
    margin-top: -40px;
    margin-bottom:0px;
    text-align:center;
}
.connect_account_loading img{
    margin-left: 7px;
}
`;
const WidgetSecureChat = (props: {
  is_secured: boolean;
  public_key: string;
  setState: (is_secured: boolean, public_key: string) => void;
  disableFields: boolean;
  disableSwitch: boolean;
  workspaceId: string;
}) => {
  const [show, setShow] = useState(true);
  const [showCopied,setShowCopied] = useState(false)
  const copyToClipBoard = (target: string) => {
        var copyText = document.getElementById(target)

        if (copyText) {
            const el = copyText as HTMLInputElement
            el.select()
            el.setSelectionRange(0, 99999)
            document.execCommand('copy')
            setShowCopied(true)
            setTimeout(()=>setShowCopied(false),1000)
            // target == 'redirectionInput'
            //     ? setState({ ...state, redirectionClipBoard: el.value, endpointClipBoard: null })
            //     : setState({ ...state, endpointClipBoard: el.value, redirectionClipBoard: null })
        }
    }
  return (
    <WidgetSecureTop>
      <WidgetFormToggle>
        {show && (
          <SectionWrapper>
            <WidgetSecureWrapper>
              <HeaderAndPara>
                <FormHeader>
                    <span>
                      Secure your web chat{' '}
                    </span>
                    <Tooltip
                      className="target customTip"
                      zIndex={10000}
                      arrowSize={8}
                      tagName="span"
                      content={"Enable security for the chatbot widget to verify the messages sent to the bot are coming from authorized users only."}
                      distance={5}
                    >
                      <InfoIcon />
                    </Tooltip>
                  <span className="show_hide _auto_switch">
                    <div className="switch_box box_1">
                      <input
                        title="secure chatbot"
                        type="checkbox"
                        className={
                          props.disableFields || props.disableSwitch
                            ? 'editor_btn_disabled switch_1 widget_switch'
                            : 'switch_1 widget_switch'
                        }
                        checked={props.is_secured}
                        onClick={() =>
                          props.setState(!props.is_secured, props.public_key)
                        }
                      />
                      {/* <span>{props.is_secured ? 'On' : 'Off'}</span> */}
                    </div>
                  </span>
                </FormHeader>
                <Paragraph style={{fontSize: 11, color: '#355D8B', padding: 0}}>
                  Authenticate and authorize your web chat and users.
                </Paragraph>

                {/* <FormHeader>Verify web chat users are who they say they are</FormHeader>
                            <Paragraph>
                                By providing Workativ with a public key verify RS2S6 signed message, we can make sure
                                that web chat traffic is coming from you and that any user information you provide is
                                secure and accurate.
                            </Paragraph> */}
              </HeaderAndPara>
              <InputContainer
                className={props.is_secured ? 'is_secured' : 'btn-deactivaed '}
              >
                <Input 
                  value={props.workspaceId}
                  id={'widgetId'}
                  className={props.disableFields ? 'editor_btn_disabled' : ''}
                />
                <InputContent className="float_content">
                   Widget ID
                </InputContent>
                <span className='widget_copIcon'
                       onClick={(e)=>{
                                e.preventDefault()
                                e.stopPropagation()
                                copyToClipBoard("widgetId")}
                       }
                      > <CopyIocn />
                </span>
                {showCopied && (
                      <span className="copy_function_copied">
                                <SuccessIcon />
                                <p>Copied</p>
                            </span>
                )}
              </InputContainer>
              {/* <FormWrapper
                className={props.is_secured ? 'is_secured' : 'btn-deactivaed '}
              >
                <InputContainer>
                <Input
                  value={props.workspaceId}
                  id="widgetID"
                  />
                  <InputContent>
                    Widget ID:
                    <span 
                    className='info_icon d-flex'
                      style={{cursor:"pointer"}}
                      onClick={(e) =>{
                          e.preventDefault()
                          e.stopPropagation()
                          copyToClipBoard("widgetID")
                      }}
                        >
                      <CopyIocn/>
                    </span>
                  </InputContent>
                </InputContainer>
                {/* <FormHeader>Widget ID:</FormHeader>
                <FormWithInput value={props.workspaceId} /> */}
              {/* </FormWrapper> */}

              <InputContainer
                className={props.is_secured ? 'is_secured' : 'btn-deactivaed '}
                style={{marginBottom: 40}}
              >
                <Input 
                  title="Public key"
                  // style={{ marginBottom: 5, marginTop: 3 }}
                  value={props.public_key}
                  onChange={(e) => {
                    props.setState(props.is_secured, e.target.value);
                  }}
                  id='public_key'
                  className={props.disableFields ? 'editor_btn_disabled' : ''}
                />
                <InputContent className="float_content">
                  Provide public key{' '}
                    <Tooltip
                        className="target customTip _description"
                        zIndex={10000}
                        arrowSize={8}
                        tagName="span"
                        content="Public key is required to ensure web chat traffic and user information is secure and authentic."
                        distance={5}
                    >
                      <InfoIcon />
                    </Tooltip>
                </InputContent>
              </InputContainer>
              <div
                id="scrollup"></div>
              <>
                {/* <PublicKeyWrapper
                  className={
                    props.is_secured ? 'is_secured' : 'btn-deactivaed '
                  }
                >
                  <InputContainer className="dialog_name_alert_content">
                          <Textarea style={{paddingTop:30}}
                            value={props.public_key}
                            onChange={(e) => {
                            props.setState(props.is_secured, e.target.value);
                          }}
                          id={'public_key'}
                           className={props.disableFields ? 'editor_btn_disabled' : ''}
                          />
                          {/* <InputContent>
                          Provide public key{' '}
                          <span className="info_icon d-flex">
                            <InfoIcon />
                            <span className="side_menu_hvr">
                              Public key is required to ensure web chat traffic and
                              user information is secure and authentic.
                            </span>
                          </span>
                            </InputContent> */}
                            {/* <InputContainer>
                            <InputContent>
                            Provide public key{' '}
                            <Tooltip
                                className="target customTip"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content="Public key is required to ensure web chat traffic and
                              user information is secure and authentic."
                                distance={5}
                                >
                                <InfoIcon />
                                </Tooltip>
                            </InputContent>
                        </InputContainer> */}
                  {/* <FormHeader>
                    Provide public key{' '}
                    <span className="info_icon d-flex">
                      <InfoIcon />
                      <span className="side_menu_hvr">
                        Public key is required to ensure web chat traffic and
                        user information is secure and authentic.
                      </span>
                    </span>
                  </FormHeader>
                  <textarea
                    title="Public key"
                    style={{ marginBottom: 5, marginTop: 3 }}
                    value={props.public_key}
                    onChange={(e) => {
                      props.setState(props.is_secured, e.target.value);
                    }}
                    id={'public_key'}
                    className={props.disableFields ? 'editor_btn_disabled' : ''}
                  />
                </PublicKeyWrapper> */}
                {/* <HeaderAndPara>
                                    <FormHeader>Encrypt sensitive information</FormHeader>
                                    <Paragraph>
                                        You can use this public key to encrypt data that you do not want your users to
                                        see. For example, you might want to add to your context if the user is a VIP or
                                        not.{' '}
                                    </Paragraph>
                                </HeaderAndPara>
                                <Paragraph>Workativ provided public key</Paragraph> */}
              </>
            </WidgetSecureWrapper>
          </SectionWrapper>
        )}
      </WidgetFormToggle>
    </WidgetSecureTop>
  );
};

const FixedButtonWrapper = styled.section`
  display: block;
  position: absolute;
  bottom: 0px;
  background: #fff;
  min-height: 40px;
  top: inherit;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  width: 100%;

  ${WidgetFormToggle} {
    margin-top: 0px;
  }

  ${ButtonUpdateWrapper} {
    padding: 15px 20px;
    gap: 20px;
    .get_code_btn {
      width: max-content;
      // margin-left: 20px;
      font-size: 14px;
      height: 32px;
    }
    .btn_code_snippet {
      width: max-content;
      display: flex;
      position: relative;
      .info_icon {
        display: flex;
        align-items: center;
      }
      .info_icon svg {
        width: 12px;
        height: 12px;
        color: #999;
        cursor: pointer;
        margin-left: 7px;
        transition: 0.8s ease;

        @media (max-width: 1600px) {
          width: 12px;
          height: 12px;
        }
      }
      .side_menu_hvr {
        position: absolute;
        width: max-content;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 10px 20px #00000029;
        padding: 6px 12px;
        font-size: 10px;
        border-radius: 0px;
        color: #315176;
        max-width: 300px;
        bottom: 35px;
        right: -15px;
        font-family: 'Gordita-Regular';
        display: none;
        :before {
          position: absolute;
          content: ' ';
          background: #ffffff 0% 0% no-repeat padding-box;
          bottom: -12px;
          border-width: 6px;
          border-style: solid;
          border-color: transparent transparent white transparent;
          top: inherit;
          right: 20px;
          transform: rotate(180deg);
        }

        @media (max-width: 1600px) {
          font-size: 9px;
          max-width: 292px;
          right: -18px;
        }
        @media (max-width: 1400px) {
          font-size: 13px;
        }
      }
      .info_icon svg:hover ~ .side_menu_hvr {
        display: flex;
      }
    }
  }

  ${SectionWrapper} {
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    margin-bottom: 0px;
  }

  ${WidgetToggleHeader}:last-child {
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
  }
`;
const WidgetSecureWrapper = styled.div`
  width: 100%;
  float: left;
  ${HeaderAndPara} {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .switch_box.box_1 {
      margin-bottom: 0px;
      margin-left: 10px;
      display: flex;
      align-items: end;
    }
  }
  ${Paragraph} {
    font-size: 14px;
    margin-top: 10px;
  }
  ${Input}{
    font-size: 12px;
    }
    ${InputContent}{
      .target.customTip{
        width: 12px;
        top: -1px;
    }
  }

`;

const WidgetPreWrapper = styled.div`
  width: 100%;
  float: left;
  ${ButtonWrapper} {
    left: 0px;
    display: flex;
    button {
      margin-top: 5px;
    }
    .btn-success {
      margin-top: 5px;
    }
  }
  .copy_board {
    position: absolute;
    right: 40px;
    top: 34px;
    background: #0e2166;
    color: #fff;
    padding: 2px 10px;
    border-radius: 4px;
    font-family: 'Gordita-Regular';
    font-size: 14px;
  }
  ${SectionWrapper} {
    background: #fff;
    padding: 0px;
    margin-top: 20px;
    margin-bottom: 0px;
  }
`;
const WidgetSecureTop = styled.div`
${SectionWrapper} {
    border:none;
    padding: 20px;
    @media (max-width:1600px){
        // padding: 10px 0px;
    }
}
${FormWrapper}{
    padding:0px 20px
    margin-bottom:20px;
    margin-top:10px;

    @media (max-width:1600px){
        margin-bottom:10px;
        margin-top:0px;
    }
}
${PublicKeyWrapper}{
    padding:0px 20px
}
${HeaderAndPara}{
    // border-bottom:1px solid #E0E0E0;
    ${FormHeader}{
        // padding: 0px 0 0 20px;
        margin-bottom:3px;
    }
    ${Paragraph}{
        padding:0px 20px
        margin:0px;
        margin-bottom:17px;

    @media (max-width:1600px){
        margin-bottom:7px;
    }
    }
}
.info_icon .side_menu_hvr{
    bottom: 30px;
    left: 0px;
    right: inherit;
    max-width: 450px;

    :before{
        top: inherit;
        right: inherit;
        -webkit-transform: rotate(
    180deg
    );
        -ms-transform: rotate(180deg);
        transform: rotate(
    180deg
    );
        left: 27%;
        bottom:-12px;
    }

}
`;
const DilogTips = () => (
  <div className="">
    <p>
      <a
        className="sidepanel_href_color"
        target="_blank"
        href="https://help.workativ.com/knowledgebase/building-a-dialog/"
      >
        Public key
      </a>{' '}
      is required to ensure web chat traffic and
      user information is secure and authentic.
    </p>
    {/* <p>
      {' '}
      You can specify conditions on how the dialog is executed using the 'create
      dialog’ pop up. Based on the user's intent and condition, the bot can then
      initiate an appropriate conversation or take action on user’s intent or
      query.
    </p> */}
    {/* <p> The chatbot can then check and choose the dialog that the user's input satisfies.</p> */}
  </div>
);